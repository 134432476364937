import * as React from 'react';
import { SVGProps } from 'react';

const SvgMembersFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M12 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM5.5 4a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm13 0a2.5 2.5 0 1 0 0 5.001 2.5 2.5 0 0 0 0-5ZM12 21a4.505 4.505 0 0 1-4.5-4.5v-4.75c0-.964.785-1.75 1.75-1.75h5.5c.965 0 1.75.786 1.75 1.75v4.75c0 2.482-2.019 4.5-4.5 4.5Zm-5.5-4.5v-4.75c0-.663.237-1.273.63-1.75H3.75C2.785 10 2 10.787 2 11.75V15a4.005 4.005 0 0 0 5.03 3.866l.01-.004A5.459 5.459 0 0 1 6.5 16.5Zm11 0v-4.75c0-.663-.237-1.273-.63-1.75h3.38c.965 0 1.75.786 1.75 1.75V15a4.005 4.005 0 0 1-5.03 3.866l-.01-.004c.342-.717.54-1.516.54-2.361Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMembersFilled;
