import * as React from 'react';
import { SVGProps } from 'react';

const SvgClapboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 3.00006V19.0001C1 20.1031 1.897 21.0001 3 21.0001H21C22.103 21.0001 23 20.1031 23 19.0001V3.00006H1ZM8.766 8.00006L10.566 5.00006H16.233L14.433 8.00006H8.766ZM3 5.00006H8.234L6.434 8.00006H3V5.00006ZM21.001 19.0001H3V10.0001H21L21.001 19.0001ZM16.766 8.00006L18.566 5.00006H21V8.00006H16.766Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgClapboard;
