import * as React from "react";

function SvgAppBox(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-box_svg__clip0)" fill="#0075C9">
        <path d="M13.12 8.906a4.66 4.66 0 00-4.118 2.476A4.659 4.659 0 002.09 9.838V5.912a.933.933 0 00-1.865 0v7.732h.002a4.66 4.66 0 008.775 2.107 4.66 4.66 0 104.117-6.845zm-8.235 7.456a2.795 2.795 0 110-5.59 2.795 2.795 0 010 5.59zm8.234 0a2.795 2.795 0 110-5.59 2.795 2.795 0 010 5.59z" />
        <path d="M24.049 16.76l-2.387-3.19 2.387-3.19a.933.933 0 00-1.491-1.12l-2.06 2.753-2.06-2.754a.932.932 0 00-1.491 1.12l2.386 3.191-2.386 3.19a.932.932 0 001.49 1.12l2.06-2.753 2.06 2.754a.933.933 0 001.491-1.12z" />
      </g>
      <defs>
        <clipPath id="app-box_svg__clip0">
          <path
            fill="#fff"
            transform="translate(.224 4.999)"
            d="M0 0h24v13.238H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppBox;
