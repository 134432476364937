import { x } from '@xstyled/styled-components';
import React from 'react';

import { useAuth } from '../../../auth/hooks/useAuth';
import { useOptionalOrganization } from '../../../auth/hooks/useOptionalOrganization';
import { useViewer } from '../../../auth/hooks/useViewer';
import { userInOrganization } from '../../../auth/utils/permissions';
import { GROUPS_SIDEBAR_WIDTH } from '../../../common/layout/Layout';
import { AppMachineOrganization } from '../../../common/machine/appMachine';
import { useCustomDomain } from '../../../organizations/hooks/useCustomDomain';
import { GroupsGlobalCreateButton } from './GroupsGlobalCreateButton';
import { GroupsSidebarPrivateGroupsList } from './GroupsSidebarGroupList';
import { GroupsSidebarHeading } from './GroupsSidebarHeading';
import { GroupsSidebarJoinButton } from './GroupsSidebarJoinButton';
import { GroupsSidebarMenuSections } from './GroupsSidebarMenuSections';
import { GroupsSidebarMobileMenu } from './GroupsSidebarMobileMenu';
import { GroupsSidebarTopNavigationList } from './GroupsSidebarTopNavigationList';
import { GroupsSidebarUpsell } from './GroupsSidebarUpsell';
import { GroupsSidebarUserSettingsMenu } from './GroupsSidebarUserSettingsMenu';

export type GroupsSidebarOrganization = AppMachineOrganization;

/**
 * Sidebar for Groups layout
 */
export const GroupsSidebar: React.FC<{
  onClickNavItem?: () => void;
  onSelectSwitchOrganization?: () => void;
}> = ({ onClickNavItem, onSelectSwitchOrganization }) => {
  const { viewer } = useViewer();
  const { hasAuth0Cookie } = useAuth();
  const { organization } = useOptionalOrganization();
  const viewerIsInOrganization = userInOrganization(viewer, organization);
  const { viewingCustomDomain } = useCustomDomain();
  const organizationOrFirstOrganization = organization
    ? organization
    : viewer?.organizationMemberships[0].organization;

  return (
    <x.div zIndex="docked" h="100%" position="relative">
      {organizationOrFirstOrganization && (
        <x.div
          w={{ _: 'auto', 'sm-2': `${GROUPS_SIDEBAR_WIDTH}px` }}
          minHeight="100%"
          boxSizing="border-box"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          backgroundColor="background"
          borderRightWidth={{ _: 0, 'lg-2': 1 }}
          borderLeftWidth={{ _: 0, 'lg-2': 1 }}
          borderStyle="solid"
          borderColor="gray.100"
          pb={viewingCustomDomain ? 16 : 0}
        >
          <x.div display="flex" flexDirection="column" w="full" spaceY={6}>
            <GroupsSidebarHeading
              organization={organizationOrFirstOrganization}
              onSelectSwitchOrganization={onSelectSwitchOrganization}
            />

            {(viewer && !viewerIsInOrganization) ||
            (!viewer && !hasAuth0Cookie) ? (
              <x.div px={6}>
                <GroupsSidebarJoinButton
                  organization={organizationOrFirstOrganization}
                />
              </x.div>
            ) : (
              <x.div px={3}>
                <GroupsGlobalCreateButton
                  organization={organizationOrFirstOrganization}
                />
              </x.div>
            )}

            <x.div spaceY={6} pb={6} px={3}>
              <GroupsSidebarTopNavigationList
                organization={organizationOrFirstOrganization}
                onClickTab={onClickNavItem}
              />
              <GroupsSidebarMenuSections
                organization={organizationOrFirstOrganization}
                onClickGroup={onClickNavItem}
              />
              {viewer ? (
                <GroupsSidebarPrivateGroupsList
                  organization={organizationOrFirstOrganization}
                  onClickGroup={onClickNavItem}
                />
              ) : null}
            </x.div>
          </x.div>
          {viewingCustomDomain && (
            <x.div display={{ _: 'none', sm: 'block' }}>
              <GroupsSidebarUserSettingsMenu />
            </x.div>
          )}
        </x.div>
      )}
      <GroupsSidebarMobileMenu />
      <GroupsSidebarUpsell />
    </x.div>
  );
};
