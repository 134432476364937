import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useEffect } from 'react';

import { IS_PRODUCTION, IS_STAGING } from '../../../config/constants';

const marketingRoutes = ['/', '/pricing', '/about'];

export const FrondInternalEmbedWidget = () => {
  const router = useRouter();

  useEffect(() => {
    // If the widget is already loaded, we need to hide it on non marketing routes
    if (!marketingRoutes.includes(router.pathname)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.Frond?.hide();
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.Frond?.show();
    }
  }, [router]);

  if (!IS_PRODUCTION && !IS_STAGING) return null;

  // Don't load the widget on non marketing routes
  if (!marketingRoutes.includes(router.pathname)) return null;

  return (
    <Script
      src="/embed.js"
      data-community={
        IS_PRODUCTION
          ? '3b612c79-ff23-44dc-9e3e-1a456a949583' // Frond
          : '99698a1d-d469-4aad-9828-a80e968df3c0' // Fronds
      }
    />
  );
};
