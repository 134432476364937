import * as React from "react";

function SvgSocialBehance(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5h5a4 4 0 013.063 6.572A4 4 0 018 19H2V5zm2 12v-4h4a2 2 0 110 4H4zM21 6h-7v2h7V6zm-3.362 11c1.04 0 1.68-.213 2.021-.533.18-.169.255-.356.255-.467h2c0 1.627-1.463 3-4.276 3C14.855 19 13 17.196 13 14c0-2.966 1.58-5 4.5-5s4.5 2.034 4.5 5v1h-6.911c.271 1.377 1.158 2 2.549 2zm2.277-4c-.249-1.31-1.042-2-2.415-2s-2.166.69-2.415 2h4.83zM4 11V7h3a2 2 0 110 4H4z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSocialBehance;
