import { useContext } from 'react';

import { DemoContext } from './DemoProvider';

export const useDemoContext = () => {
  const context = useContext(DemoContext);

  if (context === undefined) {
    throw new Error('useDemoContext must be used within an DemoContext');
  }

  return context;
};
