import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import React from 'react';

import { User } from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import analytics from '../utils/analytics';
import { NextLink } from './NextLink';

export interface UserTokenProps {
  /**
   * User model including firstName field
   */
  user: Pick<User, 'id' | 'firstName' | 'username'>;
  /**
   * Override first name usage
   */
  displayName?: string;
}

export const UserToken = React.forwardRef<
  HTMLButtonElement | null,
  UserTokenProps
>(({ user, displayName, ...props }, ref) => {
  const { organization } = useOrganization();
  return (
    <x.span
      ref={ref}
      backgroundColor={{ _: 'gray.100', hover: 'gray.50' }}
      color="brand.300"
      fontWeight="semibold"
      lineHeight="tall"
      border="default"
      borderRadius="sm"
      borderColor={{ _: 'gray.100', hover: 'brand.300' }}
      py="1px"
      px={1}
      {...props}
    >
      <NextLink
        href={routes.groups
          .organization(organization.shortId)
          .person(user?.username)}
        styleInheritColor
        metadata={{
          event: analytics.events.PROFILE_PAGE_LINK,
          context: 'user_token',
        }}
      >
        {displayName || user?.firstName || user?.username}
      </NextLink>
    </x.span>
  );
});
UserToken.displayName = 'UserToken';

export const UserMentionToken = React.forwardRef<
  HTMLButtonElement | null,
  UserTokenProps
>(({ user, displayName, ...props }, ref) => {
  const { organization } = useOrganization();
  return (
    <x.span ref={ref} color={{ _: 'blue.300', hover: 'blue.400' }} {...props}>
      <NextLink
        href={routes.groups
          .organization(organization.shortId)
          .person(user?.username)}
        styleInheritColor
        metadata={{
          event: analytics.events.PROFILE_PAGE_LINK,
          context: 'user_mention_token',
        }}
      >
        @{displayName || user?.firstName || user?.username}
      </NextLink>
    </x.span>
  );
});
UserMentionToken.displayName = 'UserMentionToken';
