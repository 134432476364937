import { theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import { debounce } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import {
  LocationByNameQuery,
  LocationTypes,
  useLocationByNameQuery,
} from '../../../../../generated/types-and-hooks';
import { Icon } from '../../../common/components/Icon';
import { Input } from '../../../common/components/Input';
import { Text } from '../../../common/components/Text';
import { EventFormData } from './EventForm';

export const EventLocation = () => {
  const form = useFormContext<EventFormData>();
  const [search, setSearch] = useState();
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation('events');

  const { data } = useLocationByNameQuery({
    variables: {
      name: search || '',
    },
    fetchPolicy: 'network-only',
    skip: !search,
  });

  const location = form.watch('location');

  const debouncedOnSearchChange = useMemo(
    () => debounce(({ inputValue }) => setSearch(inputValue), 500),
    [setSearch]
  );

  const handleInputValueChange = (
    changes: UseComboboxStateChange<LocationByNameQuery['locationByName'][0]>
  ) => {
    updateLocation(changes);
    debouncedOnSearchChange(changes);
  };

  const updateLocation = ({
    inputValue,
    selectedItem,
  }: UseComboboxStateChange<LocationByNameQuery['locationByName'][0]>) => {
    if (inputValue) {
      if (selectedItem) {
        // Customize location selected from dropdown
        form.setValue('location', {
          placeId: selectedItem.placeId,
          type: selectedItem.type,
          displayName: inputValue,
          lat: selectedItem.lat,
          lon: selectedItem.lon,
        });
      } else {
        // Add custom location when not selecting from dropdown
        form.setValue('location', {
          placeId: uuid(),
          type: LocationTypes.Custom,
          displayName: inputValue,
          lat: 0,
          lon: 0,
        });
      }
    } else {
      form.setValue('location', null);
    }
  };

  const items = data?.locationByName || [
    {
      placeId: 'online',
      type: LocationTypes.Custom,
      displayName: 'Online',
      lat: 0,
      lon: 0,
    },
  ];

  const {
    isOpen,
    getInputProps,
    getItemProps,
    getMenuProps,
    getComboboxProps,
    highlightedIndex,
    openMenu,
  } = useCombobox<LocationByNameQuery['locationByName'][0]>({
    items,
    itemToString: (item) => item?.displayName || '',
    onInputValueChange: handleInputValueChange,
    initialInputValue: location?.displayName,
    initialSelectedItem: location,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        const { placeId, type, displayName, lat, lon } = selectedItem;
        form.setValue(
          'location',
          {
            placeId,
            type,
            displayName,
            lat,
            lon,
          },
          {
            shouldValidate: true,
            shouldDirty: true,
          }
        );
      }
    },
    defaultIsOpen: false,
  });

  const inputProps = getInputProps({
    ref: inputRef,
  });

  return (
    <x.div>
      <x.div {...getComboboxProps()}>
        <Input
          {...inputProps}
          icon={<Icon name="location" color="gray.300" />}
          placeholder={t('events.location_field_placeholder')}
          onClick={() => {
            inputRef.current?.focus();
            openMenu();
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          fullWidth
        />
      </x.div>
      <x.ul
        {...getMenuProps()}
        p={3}
        boxSizing="border-box"
        position="absolute"
        left={theme.sizes[4]}
        right={theme.sizes[4]}
        backgroundColor="white"
        boxShadow="sm"
        borderRadius="md"
        display={isOpen && items.length ? 'flex' : 'none'}
        flexDirection="column"
        spaceY={1}
        maxH="198px"
        overflowY="scroll"
        zIndex="popover"
      >
        {items.map((item, index) => (
          <x.li key={index} {...getItemProps({ item, index })}>
            <x.div
              display="flex"
              spaceX={2}
              alignItems="center"
              cursor="pointer"
              backgroundColor={{
                _: highlightedIndex === index ? 'brand.50' : 'transparent',
                hover: 'brand.50',
              }}
              borderRadius="sm-md"
              p={1}
            >
              <Text variant="md">{item.displayName}</Text>
            </x.div>
          </x.li>
        ))}
      </x.ul>
    </x.div>
  );
};
