import * as React from "react";

function SvgAppMsExcel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-ms-excel_svg__clip0)">
        <g clipPath="url(#app-ms-excel_svg__clip1)">
          <path
            d="M15.07 11.442L5.58 9.768V22.14c0 .565.458 1.023 1.023 1.023h16.373c.565 0 1.023-.458 1.023-1.023V17.58l-8.93-6.139z"
            fill="#185C37"
          />
          <path
            d="M15.07.837H6.603c-.565 0-1.023.458-1.023 1.023V6.42L15.07 12l5.024 1.675L24 12V6.42L15.07.837z"
            fill="#21A366"
          />
          <path d="M5.581 6.419h9.488V12H5.581V6.42z" fill="#107C41" />
          <path
            opacity={0.1}
            d="M12.372 5.302h-6.79v13.954h6.79a1.026 1.026 0 001.023-1.023V6.325a1.026 1.026 0 00-1.023-1.023z"
            fill="currentColor"
          />
          <path
            opacity={0.2}
            d="M11.814 5.86H5.58v13.954h6.233a1.027 1.027 0 001.023-1.023V6.884a1.026 1.026 0 00-1.023-1.023z"
            fill="currentColor"
          />
          <path
            opacity={0.2}
            d="M11.814 5.86H5.58v12.838h6.233a1.026 1.026 0 001.023-1.023V6.884a1.026 1.026 0 00-1.023-1.023z"
            fill="currentColor"
          />
          <path
            opacity={0.2}
            d="M11.256 5.86H5.58v12.838h5.675a1.026 1.026 0 001.023-1.023V6.884a1.026 1.026 0 00-1.023-1.023z"
            fill="currentColor"
          />
          <path
            d="M1.023 5.86h10.233c.565 0 1.023.459 1.023 1.024v10.233c0 .565-.458 1.023-1.023 1.023H1.023A1.023 1.023 0 010 17.117V6.884C0 6.319.458 5.86 1.023 5.86z"
            fill="url(#app-ms-excel_svg__paint0_linear)"
          />
          <path
            d="M3.169 15.326L5.32 11.99 3.35 8.674h1.586l1.077 2.121c.099.202.167.351.204.45h.014c.07-.16.145-.317.223-.468l1.15-2.102H9.06l-2.023 3.297 2.074 3.354H7.56L6.32 12.996a1.95 1.95 0 01-.149-.31h-.018a1.473 1.473 0 01-.144.301l-1.28 2.338H3.169z"
            fill="#fff"
          />
          <path
            d="M22.977.837h-7.908V6.42H24V1.86c0-.565-.457-1.023-1.022-1.023z"
            fill="#33C481"
          />
          <path d="M15.07 12H24v5.581h-8.93v-5.58z" fill="#107C41" />
        </g>
      </g>
      <defs>
        <clipPath id="app-ms-excel_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
        <clipPath id="app-ms-excel_svg__clip1">
          <path
            fill="#fff"
            transform="translate(0 .837)"
            d="M0 0h24v22.326H0z"
          />
        </clipPath>
        <linearGradient
          id="app-ms-excel_svg__paint0_linear"
          x1={2.133}
          y1={5.061}
          x2={10.146}
          y2={18.939}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18884F" />
          <stop offset={0.5} stopColor="#117E43" />
          <stop offset={1} stopColor="#0B6631" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgAppMsExcel;
