import * as React from "react";

function HelloTemplateTalent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#hello-template-talent-2_svg__clip0)">
        <path
          d="M12 2C8.607 2 7.2 4.177 7.2 6.8c0 .883.422 1.77.422 1.77-.17.098-.448.408-.378.958.131 1.027.576 1.288.86 1.31.108.957 1.136 2.182 1.496 2.36v1.6c-.8 2.4-7.2.8-7.2 6.4h10.208a6.37 6.37 0 01-1.408-4 6.396 6.396 0 014.652-6.151c.016-.07.038-.142.045-.21.283-.021.728-.282.86-1.309.07-.551-.209-.86-.379-.958 0 0 .422-.801.422-1.77 0-1.942-.762-3.6-2.4-3.6 0 0-.568-1.2-2.4-1.2zm5.6 10.398a4.812 4.812 0 00-4.8 4.8c0 2.642 2.159 4.8 4.8 4.8 1.034 0 1.99-.333 2.775-.893l2.26 2.26a.802.802 0 001.143.01.8.8 0 00-.012-1.142l-2.26-2.26c.56-.785.894-1.741.894-2.775 0-2.641-2.158-4.8-4.8-4.8zm0 1.6c1.777 0 3.2 1.424 3.2 3.2 0 1.777-1.423 3.2-3.2 3.2a3.188 3.188 0 01-3.2-3.2c0-1.776 1.424-3.2 3.2-3.2z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="hello-template-talent-2_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HelloTemplateTalent;
