import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, useState } from 'react';

import { IS_DEMO } from '../../../config/constants';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { Avatar } from '../../common/components/Avatar';
import { NavigationButton } from '../../common/components/NavigationButton';
import { NextNavLink } from '../../common/components/NextLink';
import { Text } from '../../common/components/Text';
import { BasePageLayout } from '../../common/layout/Layout';
import { AppMachineOrganization } from '../../common/machine/appMachine';
import { getUserOrganizationName } from '../../common/utils/user';
import { RestrictedDemoActionModal } from '../../demo/RestrictedDemoActionModal';
import { GroupsNavigationHeader } from '../../groups/components/GroupsNavigationHeader';
import { OrganizationAvatar } from '../../organizations/components/OrganizationAvatar';

const SidebarHeader: React.FC<
  PropsWithChildren<{
    avatar?: JSX.Element;
    onClick?: () => void;
    selected?: boolean;
  }>
> = ({ avatar, onClick, selected, children }) => {
  return (
    <x.div
      display="flex"
      alignItems="center"
      cursor="pointer"
      spaceX={2}
      py={1.5}
      px={2}
      backgroundColor={
        !selected
          ? {
              _: 'transparent',
              hover: 'gray.50',
            }
          : undefined
      }
      borderRadius="sm"
      onClick={onClick}
    >
      {avatar}
      <Text variant="md-semibold" color="gray.300">
        {children}
      </Text>
    </x.div>
  );
};

const SidebarNavItem: React.FC<
  PropsWithChildren<{
    href: string;
  }>
> = ({ children, href }) => {
  const router = useRouter();
  const isSelected = router.asPath.startsWith(href);
  return (
    <NextNavLink href={href}>
      <x.div
        px={2}
        py={1.5}
        backgroundColor={{
          _: isSelected ? 'brand.50' : 'transparent',
          hover: !isSelected && 'gray.50',
        }}
        transitionDuration="fast"
        transitionTimingFunction="ease-in-out"
        borderRadius="sm"
      >
        <Text
          variant={isSelected ? 'md-medium' : 'md'}
          color={isSelected ? 'brand.300' : 'gray.300'}
          as="span"
        >
          {children}
        </Text>
      </x.div>
    </NextNavLink>
  );
};

const SidebarOrganization: React.FC<{
  organization: AppMachineOrganization;
}> = ({ organization }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { viewer } = useViewer();
  const { setOrganization } = useOptionalOrganization();
  const orgSelected = router.query.shortId === organization.shortId;
  const numOfOrgsInSidebar =
    viewer?.organizationMemberships?.filter((organizationMembership) => {
      return organizationMembership.roles.find((role) => role.isAdmin);
    }).length || 0;

  const handleClick = () => {
    setOrganization(organization);
    router.push(routes.settings.organization(organization.shortId).details());
  };

  return (
    <x.div>
      <SidebarHeader
        onClick={handleClick}
        selected={orgSelected}
        avatar={<OrganizationAvatar size="s" organization={organization} />}
      >
        {getUserOrganizationName(organization)}
      </SidebarHeader>
      {orgSelected || numOfOrgsInSidebar === 1 ? (
        <x.div pl={8} mt={2} display="flex" flexDirection="column">
          <SidebarNavItem
            href={routes.settings.organization(organization.shortId).details()}
          >
            {t('details')}
          </SidebarNavItem>
          <SidebarNavItem
            href={routes.settings.organization(organization.shortId).plans()}
          >
            {t('plans')}
          </SidebarNavItem>
          <SidebarNavItem
            href={routes.settings
              .organization(organization.shortId)
              .permissions()}
          >
            {t('permissions')}
          </SidebarNavItem>
          <SidebarNavItem
            href={routes.settings.organization(organization.shortId).paywall()}
          >
            {t('paywall')}
          </SidebarNavItem>
          <SidebarNavItem
            href={routes.settings
              .organization(organization.shortId)
              .onboarding()}
          >
            {t('onboarding')}
          </SidebarNavItem>
          <SidebarNavItem
            href={routes.settings.organization(organization.shortId).members()}
          >
            {t('members')}
          </SidebarNavItem>
          <SidebarNavItem
            href={routes.settings.organization(organization.shortId).roles()}
          >
            {t('roles')}
          </SidebarNavItem>
          <SidebarNavItem
            href={routes.settings.organization(organization.shortId).features()}
          >
            {t('features')}
          </SidebarNavItem>
          <SidebarNavItem
            href={routes.settings.organization(organization.shortId).widget()}
          >
            {t('widget')}
          </SidebarNavItem>
          <SidebarNavItem
            href={routes.settings
              .organization(organization.shortId)
              .customDomain()}
          >
            {t('custom_domain')}
          </SidebarNavItem>
        </x.div>
      ) : null}
    </x.div>
  );
};

export const SettingsSidebar: React.FC<{ returnUrl: string }> = ({
  returnUrl,
}) => {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const router = useRouter();

  const handleClickBack = () => {
    router.push(returnUrl);
  };

  return (
    <x.div
      overflowY="scroll"
      spaceY={6}
      px={3}
      py={4}
      w="16rem"
      borderRightWidth={1}
      borderStyle="solid"
      borderColor="gray.100"
      minH="100%"
      boxSizing="border-box"
    >
      <NavigationButton variant="left" onClick={handleClickBack} />

      <x.div>
        <SidebarHeader
          avatar={
            <Avatar
              size="s"
              imageId={viewer?.profileImageId}
              avatar={viewer?.avatar}
              bgColor={viewer?.avatarBgColor}
            />
          }
        >
          {t('account')}
        </SidebarHeader>
        <x.div pl={8} display="flex" flexDirection="column">
          <SidebarNavItem href={routes.settings.account()}>
            {t('account')}
          </SidebarNavItem>
          <SidebarNavItem href={routes.settings.memberships()}>
            {t('memberships')}
          </SidebarNavItem>
          <SidebarNavItem href={routes.settings.billing()}>
            {t('billing')}
          </SidebarNavItem>
          <SidebarNavItem href={routes.settings.notifications()}>
            {t('notifications')}
          </SidebarNavItem>
        </x.div>
      </x.div>
      {viewer?.organizationMemberships?.map((organizationMembership, i) => {
        const { organization } = organizationMembership;
        if (!organizationMembership.roles.find((role) => role.isAdmin)) {
          return null;
        }
        return <SidebarOrganization key={i} organization={organization} />;
      })}
    </x.div>
  );
};

export const SettingsLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const [demoModalHidden, setDemoModalHidden] = useState(false);
  const { organization } = useOptionalOrganization();
  const returnUrl = organization
    ? routes.groups.organization(organization.shortId).feed()
    : routes.groups.organizations();

  if (IS_DEMO) {
    return (
      <RestrictedDemoActionModal
        isOpen={!demoModalHidden}
        onDismiss={() => setDemoModalHidden(true)}
      />
    );
  }

  return (
    <BasePageLayout
      sidebar={<SettingsSidebar returnUrl={returnUrl} />}
      mobileNavigation={<GroupsNavigationHeader />}
    >
      <x.div
        display="flex"
        justifyContent="center"
        pt={{ _: 8, sm: 17 }}
        pb={17}
      >
        <x.div
          w="full"
          px={4}
          pr={{ xl: '16rem' }}
          boxSizing={{ _: 'border-box', sm: 'content-box' }}
        >
          {children}
        </x.div>
      </x.div>
    </BasePageLayout>
  );
};
