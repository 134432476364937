import { Alert } from '@reach/alert';
import { Portal } from '@reach/portal';
import styled, { css } from '@xstyled/styled-components';
import React, { useEffect, useRef, useState } from 'react';

import { Text } from './Text';

export interface ToastProps {
  /**
   * Toast message
   */
  message?: string;
  /**
   * Variant for type of toast
   */
  variant?: 'error';

  /**
   * Time to disappear message
   */
  disappearTime?: number | null;

  /**
   * Optional callback when the message disappears
   */
  onDisappear?: () => void;
}

const AlertWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const errorMixin = css`
  background-color: red.50;
  border-color: red.200;
`;

const StyledAlert = styled(Alert)<ToastProps>`
  padding: 3 6;
  background-color: brand.50;

  border: default;
  border-color: brand.200;
  border-radius: md;

  text-align: center;

  z-index: toast;
  position: fixed;
  top: 6;

  ${(p) => p.variant == 'error' && errorMixin}
`;

export const Toast: React.FC<ToastProps> = ({
  message,
  variant,
  disappearTime = 5000,
  onDisappear,
}) => {
  const [messages, setMessages] = useState<string[]>([]);
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (message) {
      setMessages((previousMessages) => previousMessages.concat([message]));
      if (disappearTime !== null) {
        timeout.current = setTimeout(() => {
          setMessages((prevMessages) => prevMessages.slice(1));
          onDisappear?.();
        }, disappearTime);
      }
    }

    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  }, [message, disappearTime, onDisappear]);

  return (
    <Portal>
      {messages.map((m, i) => {
        return (
          <AlertWrapper key={i}>
            <StyledAlert data-test="toast" variant={variant}>
              <Text variant="sm">{m}</Text>
            </StyledAlert>
          </AlertWrapper>
        );
      })}
    </Portal>
  );
};
