import * as React from "react";
import { SVGProps } from "react";
const SvgUnderline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.365 2.074a.625.625 0 0 0-.615.634v7.084a6.051 6.051 0 0 0 6.042 6.041 6.051 6.051 0 0 0 6.041-6.041V2.708a.624.624 0 1 0-1.25 0v7.084a4.782 4.782 0 0 1-4.791 4.791A4.782 4.782 0 0 1 5 9.792V2.708a.625.625 0 0 0-.635-.634Zm.01 15.01a.624.624 0 1 0 0 1.25h11.25a.624.624 0 1 0 0-1.25H4.375Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgUnderline;
