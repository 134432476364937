import * as React from "react";

function SvgAppMsPowerpoint(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-ms-powerpoint_svg__clip0)">
        <g clipPath="url(#app-ms-powerpoint_svg__clip1)">
          <path
            d="M14.512 13.116L12.838.837h-.125C6.624.855 1.693 5.787 1.675 11.875V12l12.837 1.116z"
            fill="#ED6C47"
          />
          <path
            d="M12.962.837h-.125V12l5.581 2.233L24 12v-.125C23.982 5.787 19.05.855 12.962.837z"
            fill="#FF8F6B"
          />
          <path
            d="M24 12v.123c-.017 6.09-4.95 11.022-11.04 11.04h-.245c-6.09-.018-11.023-4.95-11.04-11.04V12H24z"
            fill="#D35230"
          />
          <path
            opacity={0.1}
            d="M13.396 6.324v11.91a1.027 1.027 0 01-1.022 1.022H4.31a10.947 10.947 0 01-2.634-7.133v-.246a10.914 10.914 0 011.797-6.016c.123-.19.251-.38.39-.559h8.512a1.03 1.03 0 011.022 1.022z"
            fill="currentColor"
          />
          <path
            opacity={0.2}
            d="M12.838 6.882v11.91a1.028 1.028 0 01-1.021 1.021h-7a9.545 9.545 0 01-.508-.557 10.947 10.947 0 01-2.634-7.133v-.246a10.914 10.914 0 011.797-6.016h8.344a1.03 1.03 0 011.022 1.021z"
            fill="currentColor"
          />
          <path
            opacity={0.2}
            d="M12.838 6.882v10.794a1.03 1.03 0 01-1.022 1.022H3.863a10.947 10.947 0 01-2.188-6.575v-.246a10.914 10.914 0 011.797-6.016h8.344a1.03 1.03 0 011.022 1.021z"
            fill="currentColor"
          />
          <path
            opacity={0.2}
            d="M12.28 6.882v10.794a1.03 1.03 0 01-1.022 1.022H3.863a10.947 10.947 0 01-2.188-6.575v-.246a10.914 10.914 0 011.797-6.016h7.786a1.03 1.03 0 011.022 1.021z"
            fill="currentColor"
          />
          <path
            d="M1.023 5.86h10.233c.565 0 1.023.459 1.023 1.024v10.233c0 .565-.458 1.023-1.023 1.023H1.023A1.023 1.023 0 010 17.117V6.884C0 6.319.458 5.86 1.023 5.86z"
            fill="url(#app-ms-powerpoint_svg__paint0_linear)"
          />
          <path
            d="M6.25 8.59a2.812 2.812 0 011.864.545c.447.4.687.983.648 1.581a2.2 2.2 0 01-.32 1.182c-.218.35-.534.628-.907.803a3.084 3.084 0 01-1.366.287H4.874v2.405H3.55V8.589H6.25zm-1.377 3.358h1.141c.362.027.72-.08 1.009-.3.238-.23.363-.551.34-.88 0-.75-.435-1.125-1.306-1.125H4.873v2.305z"
            fill="#fff"
          />
        </g>
      </g>
      <defs>
        <clipPath id="app-ms-powerpoint_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
        <clipPath id="app-ms-powerpoint_svg__clip1">
          <path
            fill="#fff"
            transform="translate(0 .837)"
            d="M0 0h24v22.326H0z"
          />
        </clipPath>
        <linearGradient
          id="app-ms-powerpoint_svg__paint0_linear"
          x1={2.133}
          y1={5.061}
          x2={10.146}
          y2={18.939}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CA4C28" />
          <stop offset={0.5} stopColor="#C5401E" />
          <stop offset={1} stopColor="#B62F14" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgAppMsPowerpoint;
