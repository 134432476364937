import { StateMachine } from '@xstate/fsm';

type XStateEvent<T> = { type: T };

export type VideoRecorderFSMSend = (event: VideoRecorderEvents) => void;

export type VideoRecorderEvents =
  | XStateEvent<VideoRecorderEventNames>
  | {
      type: VideoRecorderEventNames.INITIALIZE_SUCCESS;
      payload: MediaStream;
    }
  | {
      type: VideoRecorderEventNames.UPLOAD_PROGRESSED;
      payload: number;
    }
  | {
      type: VideoRecorderEventNames.SET_CONSTRAINTS;
      payload: MediaStreamConstraints;
    };

export type VideoRecorderContext = {
  percentUploaded: number;
  cameraStream: MediaStream | null;
  constraints: MediaStreamConstraints | null;
};

export interface VideoRecorderStateSchema {
  value: VideoRecorderStates;
  context: VideoRecorderContext;
}

export enum VideoRecorderEventNames {
  PERMISSIONS_PROMPT = 'PERMISSIONS_PROMPT',
  PERMISSIONS_DENIED = 'PERMISSIONS_DENIED',
  PERMISSIONS_REQUESTED = 'PERMISSIONS_REQUESTED',
  INITIALIZE_REQUEST = 'INITIALIZE_REQUEST',
  INITIALIZE_SUCCESS = 'INITIALIZE_SUCCESS',
  INITIALIZE_FAILURE = 'INITIALIZE_FAILURE',
  RECORDING_STARTED = 'RECORDING_STARTED',
  COUNTDOWN_STARTED = 'COUNTDOWN_STARTED',
  RECORDING_PAUSED = 'RECORDING_PAUSED',
  RECORDING_STOPPED = 'RECORDING_STOPPED',
  RECORDING_RESUMED = 'RECORDING_RESUMED',
  RECORDING_RESTARTED = 'RECORDING_RESTARTED',
  SET_CONSTRAINTS = 'SET_CONSTRAINTS',
  UPLOAD_STARTED = 'UPLOAD_STARTED',
  UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
  UPLOAD_PROGRESSED = 'UPLOAD_PROGRESSED',
  UPLOAD_FAILURE = 'UPLOAD_FAILURE',
  UNSUPPORTED_BROWSER_DETECTED = 'UNSUPPORTED_BROWSER_DETECTED',
  CAMERA_NOT_FOUND = 'CAMERA_NOT_FOUND',
  LIVE_VIDEO_READY = 'LIVE_VIDEO_READY',
  REVIEW_VIDEO_READY = 'REVIEW_VIDEO_READY',
}

export enum VideoRecorderStates {
  LOADING = 'LOADING',
  PERMISSIONS_PROMPT = 'PERMISSIONS_PROMPT',
  PERMISSIONS_DENIED = 'PERMISSIONS_DENIED',
  PERMISSIONS_REQUESTED = 'PERMISSIONS_REQUESTED',
  INITIALIZING = 'INITIALIZING',
  INITIALIZED = 'INITIALIZED',
  RECORDING = 'RECORDING',
  PAUSED = 'PAUSED',
  REVIEW = 'REVIEW',
  COUNTDOWN = 'COUNTDOWN',
  UPLOADING = 'UPLOADING',
  UPLOAD_FAILED = 'UPLOAD_FAILED',
  PROCESSING = 'PROCESSING',
  UNSUPPORTED = 'UNSUPPORTED',
  NO_CAMERA = 'NO_CAMERA',
  TRANSITION_TO_RECORD = 'TRANSITION_TO_RECORD',
}

/**
 * Actions triggered in response to state transitions
 */
export enum VideoRecorderActions {
  INITIALIZE_STREAM = 'INITIALIZE_STREAM',
  PLAY_RECORDING = 'PLAY_RECORDING',
  REQUEST_PERMISSIONS = 'REQUEST_PERMISSIONS',
}

export type VideoCreationMetadata = {
  id: string;
  playbackId: string;
  aspect?: number;
  mirror: boolean;
};

export type VideoRecorderFSMState = StateMachine.State<
  VideoRecorderContext,
  VideoRecorderEvents,
  VideoRecorderStateSchema
>;
