import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { FC, ReactElement } from 'react';

import { Avatar } from '../Avatar';
import { FrondLogoMinimal } from '../FrondLogoMinimal';
import { Icon } from '../Icon';
import { Text } from '../Text';
import {
  AudienceSelection,
  AudienceSelectionEmail,
  AudienceSelectionEveryone,
  AudienceSelectionUser,
} from './AudienceSelectorTypes';

type AudienceSelectorMenuItemProps = {
  audienceSelection: AudienceSelection;
  renderMenuItemStatus?: (
    audience: AudienceSelection
  ) => React.ReactElement | null;
};

/**
 * List item in the Audience Selector combobox
 */
export const AudienceSelectorMenuItem: FC<AudienceSelectorMenuItemProps> = ({
  audienceSelection,
  renderMenuItemStatus,
}) => {
  const { type } = audienceSelection;

  if (type === 'email') {
    return (
      <ASMIEmail
        as={audienceSelection}
        renderMenuItemStatus={renderMenuItemStatus}
      />
    );
  }
  if (type === 'user') {
    return (
      <ASMIUser
        as={audienceSelection}
        renderMenuItemStatus={renderMenuItemStatus}
      />
    );
  }
  if (type === 'everyone') {
    return <ASMIEveryone as={audienceSelection} />;
  }

  return null;
};

/****************
 * Sub components
 */

const ASMIUser: FC<{
  as: AudienceSelectionUser;
  renderMenuItemStatus?: (
    audience: AudienceSelection
  ) => React.ReactElement | null;
}> = ({ as, renderMenuItemStatus }) => {
  const { name, username, avatar, avatarBgColor, profileImageId } = as.payload;
  const status = renderMenuItemStatus?.(as);

  const image = (
    <Avatar
      avatar={avatar}
      bgColor={avatarBgColor}
      imageId={profileImageId}
      size="s"
    />
  );
  return <ASMILayout label={name || username} image={image} status={status} />;
};

const ASMIEmail: FC<{
  as: AudienceSelectionEmail;
  renderMenuItemStatus?: (
    audience: AudienceSelection
  ) => React.ReactElement | null;
}> = ({ as, renderMenuItemStatus }) => {
  const email = as.payload;
  const image = <Icon name="plus" />;
  const status = renderMenuItemStatus?.(as);

  return <ASMILayout label={email} image={image} status={status} />;
};

const ASMIEveryone: FC<{
  as: AudienceSelectionEveryone;
}> = () => {
  const { t } = useTranslation();
  const image = <FrondLogoMinimal w={6} h={6} />;
  return <ASMILayout label={t('everyone_on_frond')} image={image} />;
};

const ASMILayout: FC<{
  label: string | ReactElement;
  image?: ReactElement;
  status?: ReactElement | null;
}> = ({ label, image, status }) => {
  return (
    <x.div
      display="flex"
      spaceX={2}
      alignItems="center"
      cursor="pointer"
      backgroundColor={{
        hover: 'brand.50',
      }}
      borderRadius="sm-md"
      p={1}
    >
      <x.div w={6} display="flex">
        {image}
      </x.div>
      <Text variant="md">{label}</Text>
      {status && (
        <x.div flexGrow={1} display="flex" justifyContent="flex-end">
          {status}
        </x.div>
      )}
    </x.div>
  );
};
