import * as React from "react";

function SvgBear(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.45 36.875h-6.875a7.85 7.85 0 01-6.688-3.75l-5-8.2A9.156 9.156 0 014.5 17.55l1.587-4.181c.168-.43.364-.847.588-1.25A9.144 9.144 0 0112.337 7.8a9.006 9.006 0 012.25-.3h10.825c.761 0 1.52.097 2.256.287a9.137 9.137 0 015.625 4.307c.224.403.42.82.588 1.25l1.587 4.181a9.137 9.137 0 01-.362 7.325v.044l-4.975 8.231a7.85 7.85 0 01-6.681 3.75z"
        fill="#212121"
      />
      <path
        d="M14.587 8.75a7.888 7.888 0 00-6.825 3.969 9.07 9.07 0 00-.506 1.087l-1.594 4.175a7.88 7.88 0 00.313 6.325l4.956 8.125a6.594 6.594 0 005.625 3.163h6.875a6.594 6.594 0 005.625-3.163l4.969-8.181a7.883 7.883 0 00.3-6.3l-1.575-4.144a8.98 8.98 0 00-.513-1.093 7.882 7.882 0 00-4.875-3.707 7.781 7.781 0 00-1.944-.25l-10.83-.006z"
        fill="#A1887F"
      />
      <path
        d="M32.782 13.031a.626.626 0 01-.544-.312A7.888 7.888 0 0027.357 9a.626.626 0 01-.463-.519 2.555 2.555 0 01-.019-.356 5 5 0 116.038 4.894l-.131.012z"
        fill="#212121"
      />
      <path
        d="M28.125 7.919a9.15 9.15 0 014.944 3.75 3.75 3.75 0 10-4.944-3.75z"
        fill="#795548"
      />
      <path
        d="M7.219 13.031h-.132a5 5 0 116.038-4.906c.008.119.008.238 0 .356a.625.625 0 01-.463.52 7.888 7.888 0 00-4.88 3.718.625.625 0 01-.563.312z"
        fill="#212121"
      />
      <path
        d="M8.125 4.375a3.75 3.75 0 00-1.194 7.306 9.15 9.15 0 014.944-3.75 3.75 3.75 0 00-3.75-3.556z"
        fill="#795548"
      />
      <path
        d="M20.931 21.875H19.07a3.444 3.444 0 00-3.444 3.444v5.612a3.444 3.444 0 003.444 3.444h1.862a3.444 3.444 0 003.444-3.444V25.32a3.444 3.444 0 00-3.444-3.444z"
        fill="#212121"
      />
      <path
        d="M20.931 23.125H19.07a2.194 2.194 0 00-2.194 2.194v5.612c0 1.212.982 2.194 2.194 2.194h1.862a2.194 2.194 0 002.194-2.194V25.32a2.194 2.194 0 00-2.194-2.194z"
        fill="#BDBDBD"
      />
      <path
        d="M21.25 31.875h-2.5a.624.624 0 110-1.25h2.5a.624.624 0 110 1.25z"
        fill="#212121"
      />
      <path
        d="M20 31.875a.624.624 0 01-.625-.625V27.5a.624.624 0 111.25 0v3.75a.624.624 0 01-.625.625z"
        fill="#212121"
      />
      <path
        d="M20 27.706a1.762 1.762 0 01-1.163-.406l-.937-.831a1.087 1.087 0 01-.331-1.213 1.532 1.532 0 011.493-.88h1.875a1.532 1.532 0 011.494.874 1.088 1.088 0 01-.331 1.213l-.938.83c-.325.274-.738.42-1.162.413z"
        fill="#212121"
      />
      <path
        d="M18.862 25.625l.807.719a.625.625 0 00.668 0l.8-.719a.713.713 0 00-.2 0h-1.875a.736.736 0 00-.2 0z"
        fill="#616161"
      />
      <path
        d="M15.938 20.625a.937.937 0 100-1.875.937.937 0 000 1.875zM24.063 20.625a.937.937 0 100-1.875.937.937 0 000 1.875z"
        fill="#212121"
      />
    </svg>
  );
}

export default SvgBear;
