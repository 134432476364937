import { StatefulChatClient } from '@azure/communication-react';
import { routes } from '@frond/shared';
import { assign, createMachine, StateNodeConfig } from 'xstate';

import {
  JoinPolicy,
  OrganizationExtendedFragment,
  ViewerQuery,
} from '../../../../generated/types-and-hooks';
import { ORGANIZATION_SHORT_ID } from '../../../config/constants';
import { getCookie } from '../../auth/utils/cookies';
import {
  isAccountSettingsRoute,
  isAuthCallbackRoute,
  isCommunityPickerRoute,
  isCommunityRoute,
  isCommunitySettingsRoute,
  isProtectedRoute,
  userInOrganization,
} from '../../auth/utils/permissions';
import { shouldDisplayOnboarding } from '../utils/routing.utils';

type AppMachineUser = ViewerQuery['viewer'];

export type AppMachineOrganization = OrganizationExtendedFragment;

export type AppContext = {
  user?: AppMachineUser;
  organization?: AppMachineOrganization | null;
  inviteCode?: string;
  shortId?: string;
  pathname?: string;
  isAuthenticated?: boolean;
  isAuthenticatedLoading?: boolean;
  error?: string;
  chatClient?: StatefulChatClient;
  isRouterReady?: boolean;
  isDemo?: boolean;
};

export enum AppState {
  INITIALIZE = 'INITIALIZE',
  INITIALIZE_USER = 'INITIALIZE_USER',
  LOGIN = 'LOGIN',
  COMMUNITY = 'COMMUNITY',
  ERROR_401 = 'ERROR_401',
  ERROR_404 = 'ERROR_404',
  ERROR_500 = 'ERROR_500',
  AUTH_ERROR = 'AUTH_ERROR',
  UNPROTECTED = 'UNPROTECTED',
  AUTH_CALLBACK = 'AUTH_CALLBACK',
  REDIRECTING = 'REDIRECTING',
}

type AppTypestate = {
  value:
    | AppState
    | 'INITIALIZE_USER.loading-auth'
    | 'INITIALIZE_USER.loading-user'
    | 'AUTH_CALLBACK.loading-auth'
    | 'AUTH_CALLBACK.loading-user'
    | 'AUTH_CALLBACK.loading-user-successful.onboarding'
    | 'AUTH_CALLBACK.loading-user-successful.auth-redirect'
    | 'INITIALIZE_USER.loading-user-successful.onboarding'
    | 'INITIALIZE_USER.loading-user-successful.settings'
    | 'UNPROTECTED.loading-user-successful.onboarding'
    | 'LOGIN.loading-user'
    | 'LOGIN.loading-auth'
    | 'COMMUNITY.loading-community'
    | 'COMMUNITY.loading-auth'
    | 'COMMUNITY.loading-router'
    | 'COMMUNITY.loading-community-successful.view-community'
    | 'COMMUNITY.loading-community-successful.view-community.routing'
    | 'COMMUNITY.loading-community-successful.view-community.routing.home'
    | 'COMMUNITY.loading-community-successful.view-community.routing.group'
    | 'COMMUNITY.loading-community-successful.view-community.routing.post'
    | 'COMMUNITY.loading-community-successful.view-community.routing.members'
    | 'COMMUNITY.loading-community-successful.invite'
    | 'COMMUNITY.loading-community-successful.request-to-join'
    | 'COMMUNITY.loading-community-successful.settings'
    | 'COMMUNITY.loading-community-successful.resetting-organization'
    | 'REDIRECTING';
  context: AppContext;
};

export type AppEvent =
  | {
      type: 'LOGOUT';
    }
  | {
      type: 'ROUTE_CHANGE_START';
      pathname: string;
    }
  | {
      type: 'ROUTE_CHANGE_COMPLETE';
      pathname: string;
    }
  | {
      type: 'SET_ERROR';
      error: string;
    }
  | {
      type: 'SET_AUTH_ERROR';
      error: string;
    }
  | {
      type: 'SET_AUTH';
      isAuthenticated: boolean;
      isAuthenticatedLoading: boolean;
      error?: Error;
    }
  | {
      type: 'SET_INVITE_CODE';
      inviteCode: string;
    }
  | {
      type: 'SET_ROUTER_READY';
      isReady: boolean;
    }
  | {
      type: 'SET_SHORT_ID';
      shortId: string;
    }
  | {
      type: 'SET_PATHNAME';
      pathname: string;
    }
  | {
      type: 'SET_ORGANIZATION';
      organization?: AppMachineOrganization;
    }
  | {
      type: 'RESET_ORGANIZATION';
    }
  | {
      type: 'UPDATE_USER';
      user: AppMachineUser;
    }
  | {
      type: 'UPDATE_ORGANIZATION';
      organization: AppMachineOrganization;
    }
  | {
      type: 'SET_CHAT_CLIENT';
      chatClient: StatefulChatClient;
    };

export const initialAppContext: AppContext = {};

const ERROR_MESSAGES_TO_LOGOUT = ['Unauthorized'];
export const ERROR_MESSAGES_TO_IGNORE = ['Popup closed'];

const CommonAuthStateNode: StateNodeConfig<AppContext, any, AppEvent> = {
  initial: 'loading-auth',
  states: {
    'loading-auth': {
      always: [
        {
          target: 'loading-user-successful',
          cond: (context) => !!context.user && !!context.isAuthenticated,
        },
        {
          target: 'loading-user',
          cond: (context) =>
            !context.user &&
            !!context.isAuthenticated &&
            !context.isAuthenticatedLoading,
        },
      ],
      on: {
        SET_AUTH: [
          /**
           * Load user when user is authenticated.
           */
          {
            target: 'loading-user',
            actions: assign({
              isAuthenticated: (_, event) => event.isAuthenticated,
              isAuthenticatedLoading: (_, event) =>
                event.isAuthenticatedLoading,
            }),
            cond: (_, event) => {
              return !!event.isAuthenticated && !event.isAuthenticatedLoading;
            },
          },
          /**
           * When visiting a protected route and user is not logged in,
           * redirect to login.
           */
          {
            target: '#app.LOGIN',
            actions: assign({
              isAuthenticated: (_, event) => event.isAuthenticated,
              isAuthenticatedLoading: (_, event) =>
                event.isAuthenticatedLoading,
            }),
            cond: (context, event) => {
              return (
                !event.isAuthenticated &&
                !event.isAuthenticatedLoading &&
                !!context.pathname &&
                isProtectedRoute(context.pathname)
              );
            },
          },
        ],
      },
    },
    'loading-user': {
      invoke: {
        src: 'initializeUser',
        onError: {
          target: '#app.ERROR_404',
          actions: assign({
            error: (_, { data, type }) => {
              if (type === 'error.platform') {
                return data;
              }
              return data.message;
            },
          }),
        },
        onDone: {
          target: 'loading-user-successful',
          actions: assign({
            user: (_, { data }) => data.data.viewer,
          }),
        },
      },
    },
    'loading-user-successful': {
      initial: 'logged-in',
      states: {
        'logged-in': {
          invoke: {
            src: 'watchUserQuery',
          },
          on: {
            /**
             * Redirecting state prevents flashes of the layout when routing between paths.
             * For instance, when the user is signed in, creates a community under /community/new
             * and is redirected to /[shortId]/home.
             */
            ROUTE_CHANGE_START: {
              target: '#app.REDIRECTING',
              cond: (context) => {
                return (
                  !!context.pathname &&
                  (!isProtectedRoute(context.pathname) ||
                    isCommunityPickerRoute(context.pathname))
                );
              },
            },
          },
          always: [
            /**
             * When viewing the demo and user is coming from a non-protected route, e.g. landing page
             * or a viewer protected route, e.g. community picker, redirect to the community route.
             * The demo community route is set in the child component rendering states.
             */
            {
              target: '#app.REDIRECTING',
              cond: (context) =>
                !!context.isDemo &&
                !!context.pathname &&
                !isCommunityRoute(context.pathname),
            },
            {
              target: 'auth-redirect',
              cond: (context) => {
                return (
                  !!context.pathname && isAuthCallbackRoute(context.pathname)
                );
              },
            },
            /**
             * Set to community state when viewing a community route.
             */
            {
              target: '#app.COMMUNITY',
              cond: 'isViewingCommunityRoute',
            },
            /**
             * Onboarding is displayed when user is successfully logged in and
             * they have either not created a community or completed onboarding.
             */
            {
              target: 'onboarding',
              cond: 'shouldDisplayOnboarding',
            },
            /**
             * When user is viewing account settings transition to settings state to
             * show respective layout.
             */
            {
              target: 'settings',
              cond: 'isViewingAccountSettingsRoute',
            },
          ],
        },
        'auth-redirect': {},
        onboarding: {},
        settings: {},
      },
    },
  },
};

export const appMachine = createMachine<AppContext, AppEvent, AppTypestate>(
  {
    id: 'app',
    predictableActionArguments: true,
    initial: AppState.INITIALIZE,
    context: initialAppContext,
    on: {
      SET_INVITE_CODE: {
        actions: assign({
          inviteCode: (_, event) => event.inviteCode,
        }),
      },
      LOGOUT: {
        target: AppState.REDIRECTING,
        actions: [
          'deleteShortIdCookie',
          assign({
            isAuthenticated: () => false,
            isAuthenticatedLoading: () => false,
            user: undefined,
          }),
        ],
      },
      SET_ERROR: AppState.ERROR_404,
      SET_AUTH_ERROR: {
        target: AppState.AUTH_ERROR,
        actions: assign({
          error: (_, event) => event.error,
        }),
      },
      UPDATE_USER: {
        actions: assign({
          user: (_, event) => event.user,
        }),
        cond: (_, event) => !!event.user,
      },
      SET_ROUTER_READY: {
        actions: assign({
          isRouterReady: (_, event) => event.isReady,
        }),
        cond: (_, event) => !!event.isReady,
      },
      SET_PATHNAME: {
        actions: assign({
          pathname: (_, event) => event.pathname,
        }),
      },
      SET_ORGANIZATION: {
        target: '#app.COMMUNITY.loading-community-successful.view-community',
        actions: [
          'setShortIdCookie',
          assign({
            organization: (_, event) => event.organization,
          }),
        ],
      },
      SET_SHORT_ID: {
        actions: [
          assign({
            shortId: (_, event) => event.shortId,
          }),
        ],
        cond: (_, event) => !!event.shortId,
      },
      /**
       * When the user is either leaving or deleting the community they are
       * currently viewing we need to set the logged-in state. Otherwise, we
       * keep showing the community view.
       */
      RESET_ORGANIZATION: {
        target: AppState.REDIRECTING,
        actions: [
          'deleteShortIdCookie',
          assign({
            shortId: undefined,
            organization: undefined,
          }),
        ],
      },
      UPDATE_ORGANIZATION: {
        actions: assign({
          organization: (_, event) => event.organization,
        }),
        cond: (_, event) => !!event.organization,
      },
      SET_CHAT_CLIENT: {
        actions: assign({
          chatClient: (_, event) => event.chatClient,
        }),
      },
      /**
       * Reinitialize state when route change is complete
       */
      ROUTE_CHANGE_COMPLETE: {
        target: AppState.INITIALIZE,
      },
    },
    states: {
      [AppState.INITIALIZE]: {
        always: [
          /**
           * When signin in via email we need to make sure we keep showing
           * the auth callback page until the user is authenticated and loaded.
           */
          {
            target: AppState.AUTH_CALLBACK,
            cond: 'isViewingAuthCallbackRoute',
          },
          /**
           * Set unprotected state when viewing an unprotected route.
           */
          {
            target: AppState.UNPROTECTED,
            cond: 'isViewingUnprotectPath',
          },
          /**
           * Set to community state when viewing a community route.
           */
          {
            target: AppState.COMMUNITY,
            cond: 'isViewingCommunityRoute',
          },
          /**
           * Set initialize user state and set state depending on auth and route.
           */
          {
            target: AppState.INITIALIZE_USER,
          },
        ],
      },
      [AppState.AUTH_CALLBACK]: CommonAuthStateNode,
      [AppState.UNPROTECTED]: CommonAuthStateNode,
      [AppState.LOGIN]: CommonAuthStateNode,
      [AppState.INITIALIZE_USER]: CommonAuthStateNode,
      [AppState.COMMUNITY]: {
        initial: 'loading-auth',
        on: {
          SET_AUTH: [
            {
              target: '#app.INITIALIZE_USER.loading-user',
              actions: assign({
                isAuthenticated: (_, event) => event.isAuthenticated,
                isAuthenticatedLoading: (_, event) =>
                  event.isAuthenticatedLoading,
              }),
              cond: (_, event) => {
                return !!event.isAuthenticated && !event.isAuthenticatedLoading;
              },
            },
            {
              target: '.loading-router',
              actions: assign({
                isAuthenticated: (_, event) => event.isAuthenticated,
                isAuthenticatedLoading: (_, event) =>
                  event.isAuthenticatedLoading,
              }),
              cond: (_, event) => {
                return !event.isAuthenticated && !event.isAuthenticatedLoading;
              },
            },
            {
              target: '.loading-router',
              cond: (_, event) => {
                return !event.isAuthenticatedLoading;
              },
            },
            {
              target: AppState.ERROR_401,
              actions: assign({
                error: (_, event) => event.error?.message,
              }),
              cond: 'hasSignInError',
            },
          ],
        },
        states: {
          'loading-auth': {
            always: [
              {
                target: 'loading-router',
                cond: (context) => !context.isAuthenticatedLoading,
              },
            ],
          },
          'loading-router': {
            always: [
              {
                target: 'loading-community',
                cond: (context) =>
                  !!context.isRouterReady && !context.organization,
              },
              {
                target: 'loading-community-successful',
                cond: (context) =>
                  !!context.isRouterReady && !!context.organization,
              },
            ],
            on: {
              SET_ROUTER_READY: {
                target: 'loading-community',
                actions: assign({
                  isRouterReady: (_, event) => event.isReady,
                }),
                cond: (_, event) => !!event.isReady,
              },
            },
          },
          'loading-community': {
            invoke: {
              src: 'initializeOrganization',
              onError: {
                target: '#app.ERROR_404',
                actions: assign({
                  error: (_, { data }) => data.message,
                }),
              },
              onDone: {
                target: '#app.COMMUNITY.loading-community-successful',
                actions: assign({
                  organization: (_, { data }) =>
                    data.data.organizationByShortId,
                }),
              },
            },
          },
          'loading-community-successful': {
            invoke: [
              {
                src: 'watchUserQuery',
              },
              {
                src: 'watchOrganizationQuery',
              },
            ],
            initial: 'view-community',
            states: {
              'view-community': {
                states: {
                  routing: {
                    states: {
                      home: {},
                      post: {},
                      group: {},
                      members: {},
                      // TODO: add routing for inbox to AppStates
                      inbox: {},
                      // TODO: add routing for messages to AppStates
                      messages: {},
                    },
                  },
                },
                on: {
                  /**
                   * Set to initial community state when viewing another community route
                   * to load the community. For instance, when viewing /community1/settings
                   * and switching to /community2/settings.
                   */
                  SET_SHORT_ID: {
                    target: '#app.COMMUNITY',
                    actions: [
                      assign({
                        shortId: (_, event) => event.shortId,
                      }),
                    ],
                  },
                  /**
                   * Just set new community when switching communitys via sidebar or
                   * community picker.
                   */
                  SET_ORGANIZATION: {
                    actions: [
                      'setShortIdCookie',
                      assign({
                        organization: (_, event) => event.organization,
                      }),
                    ],
                  },
                  /**
                   * Redirecting state prevents flashes of the layout when routing between paths.
                   * For instance, when the user is signed in, creates a community under /community/new
                   * and is redirected to /[shortId]/home.
                   */
                  ROUTE_CHANGE_START: [
                    {
                      target: '#app.REDIRECTING',
                      cond: (context) => {
                        return (
                          !!context.pathname &&
                          (!isProtectedRoute(context.pathname) ||
                            isCommunityPickerRoute(context.pathname))
                        );
                      },
                    },
                    {
                      target: '.routing.home',
                      cond: (context, event) => {
                        return (
                          !!context.organization &&
                          !!event.pathname.startsWith(
                            routes.groups
                              .organization(context.organization?.shortId)
                              .feed()
                          )
                        );
                      },
                    },
                    {
                      target: '.routing.group',
                      cond: (context, event) => {
                        return (
                          !!context.organization &&
                          !!event.pathname.startsWith(
                            routes.groups
                              .organization(context.organization?.shortId)
                              .group('')
                          )
                        );
                      },
                    },
                    {
                      target: '.routing.post',
                      cond: (context, event) => {
                        return (
                          !!context.organization &&
                          !!event.pathname.startsWith(
                            routes.groups
                              .organization(context.organization?.shortId)
                              .post('')
                          )
                        );
                      },
                    },
                    {
                      target: '.routing.messages',
                      cond: (context, event) => {
                        return (
                          !!context.organization &&
                          !!event.pathname.startsWith(
                            routes.groups
                              .organization(context.organization?.shortId)
                              .messages()
                          )
                        );
                      },
                    },
                    {
                      target: '.routing.inbox',
                      cond: (context, event) => {
                        return (
                          !!context.organization &&
                          !!event.pathname.startsWith(
                            routes.groups
                              .organization(context.organization?.shortId)
                              .inbox()
                          )
                        );
                      },
                    },
                    {
                      target: '.routing.members',
                      cond: (context, event) => {
                        return (
                          !!context.organization &&
                          !!event.pathname.startsWith(
                            routes.groups
                              .organization(context.organization?.shortId)
                              .team()
                          )
                        );
                      },
                    },
                  ],
                },
                always: [
                  {
                    target: 'invite',
                    cond: 'isViewingRequestToJoinCommunityWithInvite',
                  },
                  {
                    target: 'request-to-join',
                    cond: 'isViewingRequestToJoinCommunity',
                  },
                  {
                    target: 'invite',
                    cond: 'isViewingUnrestrictedCommunity',
                  },
                  {
                    target: 'invite',
                    actions: 'deleteShortIdCookie',
                    cond: 'isViewingUnrestrictedCommunityPreviouslySelected',
                  },
                  {
                    target: 'invite',
                    cond: 'isViewingRestrictedCommunityWithInvite',
                  },
                  {
                    target: '#app.ERROR_401',
                    cond: 'isViewingRestrictedCommunity',
                  },
                  {
                    target: '#app.ERROR_404',
                    actions: 'deleteShortIdCookie',
                    cond: 'isViewingNonExistingCommunityPreviouslySelected',
                  },
                  {
                    target:
                      '#app.INITIALIZE_USER.loading-user-successful.onboarding',
                    cond: 'shouldDisplayOnboarding',
                  },
                  {
                    target: '#app.ERROR_404',
                    cond: 'isViewingNonExistingCommunity',
                  },
                  {
                    target: 'settings',
                    cond: 'isViewingCommunitySettingsRoute',
                  },
                ],
              },
              'request-to-join': {
                on: {
                  /**
                   * When the user has requested to join a community and clicks on either
                   * "View commmunities" or "Create a new community" a route change is initiated
                   * and we need to set the redirecting state. Otherwise, we keep showing
                   * the request to join community view when selecting a community.
                   */
                  ROUTE_CHANGE_START: {
                    target: '#app.REDIRECTING',
                    actions: assign({
                      shortId: undefined,
                      organization: undefined,
                    }),
                  },
                },
              },
              invite: {},
              settings: {
                on: {
                  /**
                   * Just set new community when switching communitys via sidebar
                   */
                  SET_ORGANIZATION: {
                    actions: [
                      'setShortIdCookie',
                      assign({
                        organization: (_, event) => event.organization,
                      }),
                    ],
                  },
                },
              },
            },
          },
        },
      },
      [AppState.ERROR_401]: {},
      [AppState.ERROR_404]: {
        always: {
          actions: [
            'deleteShortIdCookie',
            assign({
              isAuthenticated: () => false,
              isAuthenticatedLoading: () => false,
            }),
          ],
          cond: (context) =>
            !!context.error &&
            !!ERROR_MESSAGES_TO_LOGOUT.includes(context.error),
        },
      },
      [AppState.ERROR_500]: {},
      [AppState.AUTH_ERROR]: {},
      [AppState.REDIRECTING]: {},
    },
  },
  {
    guards: {
      hasSignInError: (_, event) => {
        if (event.type === 'SET_AUTH') {
          return (
            !!event.error &&
            !ERROR_MESSAGES_TO_IGNORE.includes(event.error.message)
          );
        }
        return false;
      },
      isViewingUnprotectPath: (context) => {
        return !!context.pathname && !isProtectedRoute(context.pathname);
      },
      shouldDisplayOnboarding: (context) => {
        return !!context.user && shouldDisplayOnboarding(context.user);
      },
      isViewingNonExistingCommunity: (context) => {
        return !context.organization;
      },
      isViewingNonExistingCommunityPreviouslySelected: (context) => {
        return (
          !context.organization &&
          context.shortId === getCookie(ORGANIZATION_SHORT_ID)
        );
      },
      isViewingRequestToJoinCommunity: (context) => {
        return (
          !!context.organization &&
          context.organization.joinPolicy === JoinPolicy.RequestToJoin &&
          !userInOrganization(context.user, context.organization)
        );
      },
      isViewingRequestToJoinCommunityWithInvite: (context) => {
        return (
          !!context.inviteCode &&
          !!context.organization &&
          context.organization.joinPolicy === JoinPolicy.RequestToJoin &&
          !userInOrganization(context.user, context.organization)
        );
      },
      isViewingUnrestrictedCommunity: (context) => {
        return (
          !!context.organization &&
          !context.organization.isPublic &&
          context.organization.joinPolicy === JoinPolicy.Unrestricted &&
          !userInOrganization(context.user, context.organization)
        );
      },
      isViewingUnrestrictedCommunityPreviouslySelected: (context) => {
        return (
          !!context.organization &&
          !context.organization.isPublic &&
          context.organization.joinPolicy === JoinPolicy.Unrestricted &&
          !userInOrganization(context.user, context.organization) &&
          context.shortId === getCookie(ORGANIZATION_SHORT_ID)
        );
      },
      isViewingRestrictedCommunity: (context) => {
        return (
          !!context.organization &&
          context.organization.joinPolicy === JoinPolicy.Restricted &&
          !userInOrganization(context.user, context.organization)
        );
      },
      isViewingRestrictedCommunityWithInvite: (context) => {
        return (
          !!context.inviteCode &&
          !!context.organization &&
          context.organization.joinPolicy === JoinPolicy.Restricted &&
          !userInOrganization(context.user, context.organization)
        );
      },
      isViewingCommunityRoute: (context) => {
        return !!context.pathname && isCommunityRoute(context.pathname);
      },
      isViewingCommunitySettingsRoute: (context) => {
        return !!context.pathname && isCommunitySettingsRoute(context.pathname);
      },
      isViewingAccountSettingsRoute: (context) => {
        return !!context.pathname && isAccountSettingsRoute(context.pathname);
      },
      isViewingAuthCallbackRoute: (context) => {
        return !!context.pathname && isAuthCallbackRoute(context.pathname);
      },
    },
  }
);
