import * as React from 'react';
import { SVGProps } from 'react';

const SvgProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M12 2C9.247 2 7 4.249 7 7c0 2.753 2.247 5 5 5 2.752 0 5-2.247 5-5 0-2.752-2.248-5-5-5Zm0 1.5c1.942 0 3.5 1.559 3.5 3.5 0 1.943-1.558 3.5-3.5 3.5A3.489 3.489 0 0 1 8.5 7c0-1.941 1.558-3.5 3.5-3.5ZM6.25 14A2.261 2.261 0 0 0 4 16.25v.6c0 1.47.932 2.79 2.354 3.697C7.777 21.453 9.722 22 12 22s4.223-.548 5.645-1.454C19.069 19.64 20 18.32 20 16.85v-.6A2.261 2.261 0 0 0 17.75 14H6.25Zm0 1.5h11.5c.423 0 .75.327.75.75v.6c0 .833-.536 1.715-1.661 2.432-1.125.717-2.805 1.219-4.839 1.219-2.034 0-3.714-.502-4.839-1.22-1.125-.716-1.661-1.598-1.661-2.43v-.6c0-.424.327-.75.75-.75Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgProfile;
