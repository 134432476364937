type GroupTypes =
  | 'COURSE'
  | 'EVENT'
  | 'GENERAL'
  | 'REGULAR'
  | 'WELCOME'
  | 'course'
  | 'event'
  | 'general'
  | 'regular'
  | 'welcome';

export const formatGroupName = (
  group: {
    name: string;
    emoji: string;
  } & (
    | {
        groupType: GroupTypes;
      }
    | {
        type: GroupTypes;
      }
  )
) => {
  const groupType = 'groupType' in group ? group.groupType : group.type;

  return `${
    group.emoji && groupType !== 'EVENT' && groupType !== 'event'
      ? `${group.emoji} `
      : ''
  }${group.name}`;
};
