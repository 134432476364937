import Fuse, { FuseOptionKey } from 'fuse.js';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export interface ClientSearchReturnValues<T extends Record<string, unknown>> {
  results: T[] | undefined;
  setSearchTerm: (term: string) => void;
  searchTerm?: string;
  handleSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updateSearchData: Dispatch<SetStateAction<T[] | undefined>>;
  updateSearchKeys: Dispatch<SetStateAction<FuseOptionKey<T>[] | undefined>>;
}

const fuzzySearch = <T extends Record<string, unknown>>(
  searchTerm: string | undefined,
  fuse?: Fuse<T>
): T[] | undefined => {
  return searchTerm && fuse
    ? fuse.search(searchTerm)?.map((res) => res.item)
    : undefined;
};

export const useClientSearch = <T extends Record<string, unknown>>(
  data?: T[],
  keys?: FuseOptionKey<T>[]
): ClientSearchReturnValues<T> => {
  const fuseRef = useRef<Fuse<T>>();
  const [fuseData, setFuseData] = useState(data);
  const [fuseKeys, setFuseKeys] = useState(keys);
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  useEffect(() => {
    if (fuseData && fuseKeys && data) {
      fuseRef.current = new Fuse(data, {
        keys: fuseKeys,
        threshold: 0.4,
      });
    }
  }, [fuseKeys, fuseData, data]);

  const results = fuzzySearch(searchTerm, fuseRef.current);

  if (searchTerm && (!fuseData || !fuseKeys)) {
    throw new Error('Fuse search requires data and keys');
  }

  return {
    searchTerm,
    setSearchTerm,
    handleSearchInput: useCallback(({ target: { value } }) => {
      setSearchTerm(value);
    }, []),
    results,
    updateSearchData: setFuseData,
    updateSearchKeys: setFuseKeys,
  };
};
