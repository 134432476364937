import * as React from "react";

function SvgRabbit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.194 36.875h-2.387a3.187 3.187 0 01-3.125-2.744c-3.45-1.456-5.625-4.512-5.625-7.881-.001-1.278.315-2.536.918-3.663a9.087 9.087 0 012.15-2.687 5.206 5.206 0 015.207-4.9h3.337a5.187 5.187 0 013.675 1.525c.233.235.442.493.625.769.53.771.844 1.672.906 2.606A8.28 8.28 0 0130 26.25a7.944 7.944 0 01-1.7 4.881 9.794 9.794 0 01-3.925 2.994 3.188 3.188 0 01-3.18 2.75z"
        fill="#212121"
      />
      <path
        d="M18.331 16.25a3.881 3.881 0 00-1.937.506 3.93 3.93 0 00-2.019 3.45.624.624 0 01-.244.494 7.893 7.893 0 00-2.112 2.5 6.506 6.506 0 00-.769 3.05c0 2.962 2.05 5.656 5.219 6.875a.625.625 0 01.406.581 1.938 1.938 0 001.931 1.919h2.388a1.932 1.932 0 001.931-1.931.626.626 0 01.4-.582 8.619 8.619 0 003.781-2.743 6.7 6.7 0 001.444-4.119 7.088 7.088 0 00-2.881-5.55.625.625 0 01-.244-.494 3.97 3.97 0 00-.669-2.212 4.082 4.082 0 00-1.35-1.244 3.881 3.881 0 00-1.937-.506l-3.338.006z"
        fill="#EEE"
      />
      <path
        d="M14.526 18.275h-.056a.626.626 0 01-.52-.375l-1.068-2.5a11.612 11.612 0 01.713-10.519l.868-1.45a.625.625 0 011.069 0l.869 1.45a11.606 11.606 0 01.712 10.519l-.456 1.062a.625.625 0 01-.269.3 3.974 3.974 0 00-1.35 1.25.625.625 0 01-.512.263z"
        fill="#212121"
      />
      <path
        d="M15 4.969l-.331.556a10.356 10.356 0 00-.625 9.375l.668 1.569c.278-.264.584-.497.913-.694L16 14.9a10.357 10.357 0 00-.625-9.375L15 4.969z"
        fill="#B0BEC5"
      />
      <path
        d="M25.475 18.275a.626.626 0 01-.512-.269 4.09 4.09 0 00-1.356-1.25.625.625 0 01-.263-.294l-.456-1.062a11.613 11.613 0 01.706-10.519l.869-1.45a.625.625 0 011.068 0l.87 1.45a11.612 11.612 0 01.712 10.519l-1.07 2.5a.624.624 0 01-.512.375h-.056z"
        fill="#212121"
      />
      <path
        d="M24.406 15.781c.32.2.617.432.888.694l.675-1.575a10.357 10.357 0 00-.625-9.375L25 4.969l-.331.556a10.356 10.356 0 00-.625 9.375l.362.881z"
        fill="#B0BEC5"
      />
      <path
        d="M20.625 34.375h-1.25a.624.624 0 110-1.25h1.25a.624.624 0 110 1.25z"
        fill="#212121"
      />
      <path
        d="M20 34.375a.624.624 0 01-.625-.625v-2.5a.624.624 0 111.25 0v2.5a.624.624 0 01-.625.625z"
        fill="#212121"
      />
      <path
        d="M20 31.563a2.943 2.943 0 01-2.375-1.188.625.625 0 111-.75 1.718 1.718 0 002.75 0 .623.623 0 01.875-.125.623.623 0 01.125.875A2.943 2.943 0 0120 31.563zm-3.75-4.688a.624.624 0 01-.625-.625V25a.624.624 0 111.25 0v1.25a.624.624 0 01-.625.625zm7.5 0a.624.624 0 01-.625-.625V25a.624.624 0 111.25 0v1.25a.624.624 0 01-.625.625z"
        fill="#212121"
      />
    </svg>
  );
}

export default SvgRabbit;
