import * as React from 'react';
import { SVGProps } from 'react';

const SvgAspectFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M6.75 12.75v3a1.5 1.5 0 0 0 1.5 1.5h3v-1.5h-3v-3h-1.5ZM17.25 11.25v-3a1.5 1.5 0 0 0-1.5-1.5h-3v1.5h3v3h1.5Z"
      fill="#333"
    />
  </svg>
);

export default SvgAspectFilled;
