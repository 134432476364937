import * as React from "react";
import { SVGProps } from "react";
const SvgEventCreated = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 20V6h18v14c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1Z"
      fill="url(#EventCreated_svg__a)"
    />
    <path
      d="M21 4v3H3V4c0-.55.45-1 1-1h16c.55 0 1 .45 1 1Z"
      fill="url(#EventCreated_svg__b)"
    />
    <path
      opacity={0.05}
      d="M17.75 6.25a1.5 1.5 0 0 1-1.5-1.5V3h3v1.75a1.5 1.5 0 0 1-1.5 1.5Z"
      fill="currentColor"
    />
    <path
      opacity={0.07}
      d="M17.75 5.875a1.125 1.125 0 0 1-1.125-1.125V3h2.25v1.75c0 .622-.503 1.125-1.125 1.125Z"
      fill="currentColor"
    />
    <path
      opacity={0.05}
      d="M6.25 6.25a1.5 1.5 0 0 1-1.5-1.5V3h3v1.75a1.5 1.5 0 0 1-1.5 1.5Z"
      fill="currentColor"
    />
    <path
      opacity={0.07}
      d="M6.25 5.875A1.125 1.125 0 0 1 5.125 4.75V3h2.25v1.75c0 .622-.503 1.125-1.125 1.125Z"
      fill="currentColor"
    />
    <path
      d="M6.25 5.5a.75.75 0 0 1-.75-.75v-2a.75.75 0 0 1 1.5 0v2a.75.75 0 0 1-.75.75Z"
      fill="#3CCBF4"
    />
    <path
      d="M19 9.75v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25ZM19 13.25v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25ZM15 16.75v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Z"
      fill="#fff"
    />
    <path
      d="M17.75 5.5a.75.75 0 0 1-.75-.75v-2a.75.75 0 0 1 1.5 0v2a.75.75 0 0 1-.75.75Z"
      fill="#3CCBF4"
    />
    <path
      d="M19 13a6 6 0 0 0-6 6c0 .703.127 1.373.349 2H20c.55 0 1-.45 1-1v-6.652A5.983 5.983 0 0 0 19 13Z"
      fill="url(#EventCreated_svg__c)"
    />
    <path
      d="M19 24a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
      fill="url(#EventCreated_svg__d)"
    />
    <path
      d="M19.25 21.5h-.5a.25.25 0 0 1-.25-.25v-4.5a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v4.5a.25.25 0 0 1-.25.25Z"
      fill="#fff"
    />
    <path
      d="M16.5 19.25v-.5a.25.25 0 0 1 .25-.25h4.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-4.5a.25.25 0 0 1-.25-.25Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="EventCreated_svg__a"
        x1={3.207}
        y1={6.831}
        x2={16.689}
        y2={16.677}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28AFEA" />
        <stop offset={1} stopColor="#047ED6" />
      </linearGradient>
      <linearGradient
        id="EventCreated_svg__b"
        x1={3.21}
        y1={3.304}
        x2={20.599}
        y2={6.84}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0077D2" />
        <stop offset={1} stopColor="#0B59A2" />
      </linearGradient>
      <linearGradient
        id="EventCreated_svg__d"
        x1={17.045}
        y1={15.585}
        x2={22.014}
        y2={24.265}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#21AD64" />
        <stop offset={1} stopColor="#088242" />
      </linearGradient>
      <radialGradient
        id="EventCreated_svg__c"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.01 19.021) scale(5.8785)"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
    </defs>
  </svg>
);
export default SvgEventCreated;
