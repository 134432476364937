import * as React from "react";

function SvgAppRippling(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-rippling_svg__clip0)">
        <path
          d="M20.494 7.961c0-1.996-1.006-3.645-2.886-5.03h4.37a6.321 6.321 0 012.475 5.03c0 2.03-.94 3.844-2.474 5.031 1.419.594 2.227 2.045 2.227 4.124v3.959h-3.959v-3.959c0-1.98-.94-3.365-2.64-4.124 1.881-1.385 2.887-3.035 2.887-5.03zm-8.577 0c0-1.996-1.006-3.645-2.886-5.03h4.37a6.322 6.322 0 012.475 5.03c0 2.03-.94 3.844-2.474 5.031 1.418.594 2.226 2.045 2.226 4.124v3.959H11.67v-3.959c0-1.98-.94-3.365-2.64-4.124 1.88-1.385 2.887-3.035 2.887-5.03zm-8.577 0c0-1.996-1.006-3.645-2.887-5.03h4.371a6.321 6.321 0 012.474 5.03c0 2.03-.94 3.844-2.474 5.031 1.419.594 2.227 2.045 2.227 4.124v3.959H3.092v-3.959c0-1.98-.94-3.365-2.639-4.124 1.88-1.385 2.887-3.035 2.887-5.03"
          fill="#100F0D"
        />
      </g>
      <defs>
        <clipPath id="app-rippling_svg__clip0">
          <path fill="#fff" transform="translate(.453)" d="M0 0h24v24.004H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppRippling;
