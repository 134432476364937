import {
  ELEMENT_H1,
  ELEMENT_LINK,
  ELEMENT_PARAGRAPH,
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  MyEditor,
  MyLinkElement,
  ZIndices,
} from '@frond/shared';
import {
  collapseSelection,
  findNode,
  getPluginType,
  isMarkActive,
  isUrl,
  removeMark,
  setNodes,
  someNode,
  toggleMark,
  toggleNodeType,
  unwrapNodes,
  wrapNodes,
} from '@udecode/plate-common';
import {
  createVirtualElement,
  getRangeBoundingClientRect,
} from '@udecode/plate-floating';
import { useFloatingToolbar } from '@udecode/plate-ui-toolbar';
import styled, { th, x } from '@xstyled/styled-components';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { BaseSelection, Selection } from 'slate';

import { Button } from '../Button';
import { Icon, IconNames } from '../Icon';
import { Input } from '../Input';
import { ALL_MARKS } from './ComposerElement';
import { useMyPlateEditorState } from './utils/editor.utils';

const clearAllStyles = (editor?: MyEditor) => {
  if (editor) {
    ALL_MARKS.forEach((mark) => {
      removeMark(editor, { key: mark });
    });
  }
};

const getLinkNode = (editor?: MyEditor, selection?: Selection) => {
  if (editor && selection) {
    const node = findNode(editor, {
      at: selection,
      match: (n) => 'type' in n && n.type === ELEMENT_LINK,
    });

    if (node) {
      const [block] = node;
      if ('type' in block && block.type === ELEMENT_LINK) {
        return node;
      }
    }
  }
};

const ToolbarButton: React.FC<{
  active?: boolean;
  icon: IconNames;
  onClick: () => void;
}> = ({ active, icon, onClick }) => {
  const handleMouseDown = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    onClick();
  };

  return (
    <x.button
      type="button"
      color={{
        _: active ? 'white' : 'brand.100',
        hover: 'white',
      }}
      onMouseDown={handleMouseDown}
      padding={2}
      margin={0}
      outline="none"
      border="none"
      cursor="pointer"
      background="transparent"
      fontSize="md"
      transitionProperty="color"
      transitionTimingFunction="linear"
      transitionDuration="faster"
    >
      <Icon name={icon} size="5" />
    </x.button>
  );
};

export const applyMark = (mark: string, editor: MyEditor) => {
  toggleNodeType(editor, { activeType: ELEMENT_PARAGRAPH });
  toggleMark(editor, {
    key: getPluginType(editor, mark),
    clear: getPluginType(editor, mark),
  });
};

export const ToolbarButtons: React.FC<{
  onLinkClick: () => void;
}> = ({ onLinkClick }) => {
  const editor = useMyPlateEditorState();

  return (
    <x.div
      padding="2px 1"
      backgroundColor="brand.300"
      borderRadius="md"
      display="flex"
      alignItems="center"
      userSelect="none"
    >
      <ToolbarButton
        active={isMarkActive(editor, getPluginType(editor, MARK_BOLD))}
        icon="bold"
        onClick={() => applyMark(MARK_BOLD, editor)}
      />

      <ToolbarButton
        active={isMarkActive(editor, getPluginType(editor, MARK_UNDERLINE))}
        icon="underline"
        onClick={() => applyMark(MARK_UNDERLINE, editor)}
      />
      <ToolbarButton
        active={isMarkActive(editor, getPluginType(editor, MARK_ITALIC))}
        icon="italic"
        onClick={() => applyMark(MARK_ITALIC, editor)}
      />

      <ToolbarButton
        active={
          !!editor.selection &&
          someNode(editor, { match: { type: ELEMENT_H1 } })
        }
        icon="h"
        onClick={() => {
          clearAllStyles(editor);
          toggleNodeType(editor, { activeType: ELEMENT_H1 });
        }}
      />
      <ToolbarButton
        active={isMarkActive(editor, getPluginType(editor, MARK_STRIKETHROUGH))}
        icon="strikethrough"
        onClick={() => applyMark(MARK_STRIKETHROUGH, editor)}
      />
      <ToolbarButton
        active={isMarkActive(editor, getPluginType(editor, MARK_CODE))}
        icon="code"
        onClick={() => {
          applyMark(MARK_CODE, editor);
        }}
      />
      <ToolbarButton
        icon="clear"
        onClick={() => {
          toggleNodeType(editor, { activeType: ELEMENT_PARAGRAPH });
          clearAllStyles(editor);
        }}
      />
      <ToolbarButton
        active={!!getLinkNode(editor, editor.selection)}
        icon="link"
        onClick={onLinkClick}
      />
    </x.div>
  );
};

const Portal: React.FC<PropsWithChildren> = ({ children }) => {
  return ReactDOM.createPortal(<>{children}</>, document.body);
};

const StyledInput = styled(Input)`
  input {
    padding-top: 2;
    padding-right: 2;
    padding-bottom: 2;
    padding-left: 4;
    border: none;
    ${th('typographyStyles.text.sm-semibold')}
  }
`;

const StyledDiv = styled.divBox`
  &:focus-within {
    border-color: blue.300;
  }
`;

export const ToolbarEditLink: React.FC<{
  editor?: MyEditor;
  selection: Selection;
  initialValue?: string;
  onHide?: () => void;
}> = ({ editor, selection, initialValue, onHide }) => {
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(initialValue || '');

  useEffect(() => {
    // Autofocus
    inputRef.current?.focus();
  }, [ref, onHide]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const updateLinkNode = () => {
    if (selection && editor && value !== '' && isUrl(value)) {
      const linkNode = getLinkNode(editor, selection);

      if (linkNode) {
        setNodes(
          editor,
          { type: ELEMENT_LINK, url: value },
          {
            at: selection,
            match: (n) => 'type' in n && n.type === ELEMENT_LINK,
          }
        );
      } else {
        wrapNodes(
          editor,
          { type: ELEMENT_LINK, url: value, children: [] },
          { at: selection, split: true, voids: true }
        );
      }
      onHide?.();
    }
  };

  const removeLinkNode = () => {
    const node = getLinkNode(editor, selection);
    if (editor && node) {
      const [, path] = node;
      unwrapNodes(editor, {
        at: path,
        match: (n) => 'type' in n && n.type === ELEMENT_LINK,
        split: true,
      });
      onHide?.();
    }
  };

  const openLinkNode = () => {
    const node = getLinkNode(editor, selection);
    if (editor && node) {
      const [block] = node;
      window.open((block as MyLinkElement).url, '_blank');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      updateLinkNode();
    }
  };

  return (
    <StyledDiv
      ref={ref}
      padding={0}
      backgroundColor="white"
      border="default"
      borderRadius="md"
      borderColor={{
        _: 'gray.300',
        hover: 'blue.300',
      }}
      display="flex"
      alignItems="center"
      userSelect="none"
      boxShadow="sm"
      color="gray.500"
    >
      <StyledInput
        ref={inputRef}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Enter link url"
      />
      {!!getLinkNode(editor, selection) && (
        <x.div
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyItems="center"
          paddingRight={2}
          spaceX={1}
        >
          <Button
            variant="icon"
            leftElement={
              <Icon name="external-link" size="4" color="gray.500" />
            }
            onClick={openLinkNode}
          />
          <Button
            variant="icon"
            leftElement={<Icon name="close" size="4" color="gray.500" />}
            onClick={removeLinkNode}
          />
        </x.div>
      )}
    </StyledDiv>
  );
};

type ComposerToolbarProps = {
  zIndex?: keyof ZIndices;
};

export const Toolbar: React.FC<ComposerToolbarProps> = ({ zIndex }) => {
  const [editSelection, setEditSelection] = useState<BaseSelection | null>(
    null
  );
  const editor = useMyPlateEditorState();
  const ref = React.useRef<HTMLDivElement | null>(null);

  const handleHideEditLink = useCallback(() => {
    setEditSelection(null);
    if (editor) {
      collapseSelection(editor);
    }
  }, [editor, setEditSelection]);

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handleHideEditLink?.();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handleHideEditLink]);

  const getBoundingClientRect = useCallback(
    () =>
      (editor &&
        getRangeBoundingClientRect(
          editor,
          editSelection || editor.selection
        )) ??
      createVirtualElement(),
    [editor, editSelection]
  );

  const { floating, style } = useFloatingToolbar({
    floatingOptions: {
      placement: 'top',
      getBoundingClientRect,
      ...(editSelection && {
        open: true,
      }),
    },
  });

  const handleLinkClick = () => {
    if (editor?.selection) {
      setEditSelection(editor.selection);
    }
  };

  if (!open) return null;

  return (
    <x.div
      display={{ _: 'none', sm: 'block' }}
      ref={floating}
      zIndex={zIndex || 'tooltip'}
      style={style}
    >
      <div ref={ref}>
        {editSelection && editor ? (
          <ToolbarEditLink
            editor={editor}
            selection={editSelection}
            onHide={handleHideEditLink}
            initialValue={
              (getLinkNode(editor, editor.selection)?.[0] as MyLinkElement)?.url
            }
          />
        ) : (
          <ToolbarButtons onLinkClick={handleLinkClick} />
        )}
      </div>
    </x.div>
  );
};

Toolbar.displayName = 'Toolbar';

export const ComposerToolbar: React.FC<ComposerToolbarProps> = ({ zIndex }) => {
  return (
    <Portal>
      <Toolbar zIndex={zIndex} />
    </Portal>
  );
};
