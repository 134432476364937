import { MyValue, validateContentIsNotEmpty } from '@frond/shared';
import styled, { th, x } from '@xstyled/styled-components';

import {
  PostColorTheme,
  PostCoreFragment,
} from '../../../../generated/types-and-hooks';
import { ComposerBody } from '../../common/components/Composer';
import { isEmojiOnly } from '../../questions/utils/answers';

export const PostComposerWrapper = styled.divBox<{
  color: string;
  isEmojiOnly: boolean;
}>`
  word-break: break-word;

  p,
  span,
  code {
    color: ${(p) => th(`colors.${p.color}`)};
    ${(p) => p.isEmojiOnly && th('typographyStyles.heading.3xl')}
  }

  a,
  a span {
    color: blue.300 !important;

    :hover {
      color: blue.400 !important;
    }
  }
`;

type PostCardContentProps = {
  post: PostCoreFragment;
};

export const PostCardContent = ({ post }: PostCardContentProps) => {
  const postHasText = validateContentIsNotEmpty(post.content as MyValue);

  const textColorLevel =
    post.colorTheme === PostColorTheme.Gray ? '500' : '400';

  if (!postHasText) return null;

  return (
    <x.div position="relative">
      <PostComposerWrapper
        isEmojiOnly={isEmojiOnly(post.content as MyValue)}
        color={`${post.colorTheme.toLowerCase()}.${textColorLevel}`}
      >
        <ComposerBody trimOuterWhitespace content={post.content as MyValue} />
      </PostComposerWrapper>
    </x.div>
  );
};
