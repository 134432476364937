import { CustomDomainStatus } from '../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';

export const useCustomDomain = () => {
  const { organization } = useOptionalOrganization();

  const hasCustomDomain = !!(
    organization?.customDomain?.status === CustomDomainStatus.Active
  );

  const viewingCustomDomain =
    hasCustomDomain &&
    window.location.hostname === organization.customDomain?.name;

  return {
    hasCustomDomain,
    viewingCustomDomain,
  };
};
