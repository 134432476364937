import { MyAllChildren, MyEditor, MyElement, RichText } from '@frond/shared';
import {
  getNodeEntries,
  getPoint,
  TNode,
  useEventPlateId,
} from '@udecode/plate-common';
import styled, { css, x } from '@xstyled/styled-components';
import { isEqual } from 'lodash';
import { NodeEntry, Range } from 'slate';

import { isInternalURL } from '../../utils';
import analytics from '../../utils/analytics';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Link } from '../Link';
import { NextLink } from '../NextLink';
import { Text } from '../Text';
import { Tooltip, TooltipProps } from '../Tooltip';
import { ComposerElementProps } from './ComposerElement';
import { ToolbarEditLink } from './ComposerToolbar';
import { useMyEditorState, useMyPlateEditorState } from './utils/editor.utils';

const StyledTooltip = styled(Tooltip)<
  TooltipProps & {
    editable?: boolean;
  }
>`
  &&[data-reach-tooltip] {
    pointer-events: all;

    ${(p) =>
      p.editable &&
      css`
        padding: 0;
        background-color: transparent;
        border: none;
        box-shadow: none;
      `}
  }
`;

const getNodeByElement = (
  editor: MyEditor,
  element?: MyElement
): NodeEntry<TNode> | undefined | null => {
  try {
    const nodeEntries = getNodeEntries(editor, {
      at: [],
      match: (n) => isEqual(n, element),
    });
    for (const [node, path] of nodeEntries) {
      return [node, path];
    }
  } catch (error) {
    return null;
  }
};

const ComposerLinkElementTooltipEditable: React.FC<{
  element?: MyElement;
}> = ({ element }) => {
  const editor = useMyEditorState();
  const node = getNodeByElement(editor, element);

  if (!node) return null;

  const [block, path] = node;
  const anchor = getPoint(editor, path, { edge: 'start' });
  const focus = getPoint(editor, path, { edge: 'end' });
  const range = {
    anchor,
    focus,
  };

  if (!range) return null;

  return (
    <ToolbarEditLink
      editor={editor}
      selection={range}
      initialValue={block.url as string}
    />
  );
};

const ComposerLinkElementCore: React.FC<
  ComposerElementProps & {
    tooltip?: boolean;
    editable?: boolean;
  }
> = (props) => {
  const { attributes, children, element, tooltip, editable } = props;

  if (!element || !('url' in element)) return null;

  const linkElement = (
    <span {...attributes}>
      {isInternalURL(element.url as string) ? (
        <NextLink
          href={element.url as string}
          metadata={{ event: analytics.events.COMPOSER_LINK }}
        >
          {children}
        </NextLink>
      ) : (
        <Link
          target={'_blank'}
          rel={'noopener'}
          href={element.url as string}
          metadata={{ event: analytics.events.COMPOSER_LINK }}
        >
          {children}
        </Link>
      )}
    </span>
  );

  return !tooltip ? (
    linkElement
  ) : (
    <StyledTooltip
      aria-label={
        (element.url as string) ||
        ((element.children as MyAllChildren)[0] as RichText).text
      }
      persistTooltipOnMouseOver={true}
      editable={editable}
      maxWidth="auto"
      label={
        editable ? (
          <ComposerLinkElementTooltipEditable element={element} />
        ) : (
          <x.div display="flex" flexDirection="row" w="100%" spaceX={2}>
            <Text maxWidth="lg" variant="sm-semibold" truncate>
              {element?.url as string}
            </Text>
            <x.div
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyItems="center"
            >
              <Button
                variant="icon"
                leftElement={
                  <Icon
                    name="external-link"
                    size="4"
                    color={editable ? 'gray.500' : 'white'}
                  />
                }
                onClick={() => window.open(element.url as string, '_blank')}
              />
            </x.div>
          </x.div>
        )
      }
      size="small"
    >
      {linkElement}
    </StyledTooltip>
  );
};

const ComposerLinkElementEditable: React.FC<ComposerElementProps> = (props) => {
  const { element } = props;

  const editor = useMyEditorState();
  const plateEditor = useMyPlateEditorState(useEventPlateId('focus'));

  const node = getNodeByElement(editor, element);

  const hasSelection = () => {
    if (plateEditor?.selection && node) {
      const [, path] = node;
      return Range.includes(plateEditor.selection, path);
    } else {
      return false;
    }
  };

  return (
    <ComposerLinkElementCore {...props} tooltip={!hasSelection()} editable />
  );
};

export const ComposerLinkElement: React.FC<ComposerElementProps> = (props) => {
  const { readonly } = props;

  if (readonly) {
    return <ComposerLinkElementCore {...props} tooltip />;
  } else {
    return <ComposerLinkElementEditable {...props} />;
  }
};
