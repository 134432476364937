import { useApolloClient } from '@apollo/client';
import {
  convertContentToText,
  MyRootBlock,
  routes,
  theme,
  URL_TRANSFORMATION,
} from '@frond/shared';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { isUrl } from '@udecode/plate-common';
import styled, { th, x } from '@xstyled/styled-components';
import { xor } from 'lodash';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';

import {
  GroupsMenuSectionsStateDocument,
  GroupTypes,
  MenuItemCoreFragment,
  MenuItemCoreFragmentDoc,
  MenuSectionCoreFragment,
  MenuSectionCoreFragmentDoc,
  MenuSectionsDocument,
  MenuSectionsQuery,
  MenuSectionTypes,
  refetchMenuSectionsQuery,
  useCreateMenuItemUrlMutation,
  useCreateMenuSectionMutation,
  useDeleteMenuItemMutation,
  useDeleteMenuSectionMutation,
  useGroupsMenuSectionsStateQuery,
  useMenuSectionsQuery,
  useReorderMenuItemsMutation,
  useReorderMenuSectionsMutation,
  useUpdateMenuItemMutation,
  useUpdateMenuSectionMutation,
} from '../../../../../generated/types-and-hooks';
import { useIsViewerAdmin } from '../../../auth/hooks/useViewer';
import { Icon } from '../../../common/components/Icon';
import { Input } from '../../../common/components/Input';
import { Text } from '../../../common/components/Text';
import { Tooltip } from '../../../common/components/Tooltip';
import { topCenter } from '../../../common/utils/position';
import { useCanViewerCreateGroups } from '../../../organizations/hooks/useCanViewerCreateGroups';
import { useOrganizationEntitlements } from '../../../organizations/hooks/useOrganizationEntitlements';
import {
  StyledDropdownMenuContent,
  StyledDropdownMenuItem,
} from '../../../posts/components/PostCard';
import { GroupCreateModal } from '../GroupCreateModal';
import { GroupsSidebarOrganization } from './GroupsSidebar';
import {
  GroupsSidebarGroupLink,
  GroupsSidebarLinkList,
  GroupsSidebarNavigationLink,
} from './GroupsSidebarLink';

export const NUM_GROUPS_FOR_SIDEBAR = 25;

const useGroupsMenuSectionsState = () => {
  const { cache } = useApolloClient();
  const { data } = useGroupsMenuSectionsStateQuery();

  const menuSectionIdsCollapsed =
    data?.groupsMenuSectionsState.menuSectionIdsCollapsed || [];

  const toggleMenuSection = (menuSection: MenuSectionCoreFragment) => {
    const updatedMenuSectionIdsCollapsed = xor(menuSectionIdsCollapsed, [
      menuSection.id,
    ]);

    cache.writeQuery({
      query: GroupsMenuSectionsStateDocument,
      data: {
        groupsMenuSectionsState: {
          menuSectionIdsCollapsed: updatedMenuSectionIdsCollapsed,
        },
      },
    });
  };

  const isExpanded = (menuSection: MenuSectionCoreFragment) => {
    return !menuSectionIdsCollapsed.includes(menuSection.id);
  };

  return {
    toggleMenuSection,
    isExpanded,
  };
};

export const GroupsSidebarMenuSections: FC<{
  organization: GroupsSidebarOrganization;
  onClickGroup?: () => void;
}> = ({ organization, onClickGroup }) => {
  const { data } = useMenuSectionsQuery({
    variables: { organizationId: organization.id },
  });
  const { features } = useOrganizationEntitlements();

  const isAdmin = useIsViewerAdmin(organization);

  const menuSections =
    data?.menuSections.filter((menuSection) => {
      if (menuSection.type === MenuSectionTypes.Courses) {
        if (features.Courses && organization.hasCoursesEnabled) {
          return menuSection;
        }
      } else {
        return menuSection;
      }
    }) || [];

  return isAdmin ? (
    <GroupsSidebarSortableMenuSectionsList
      menuSections={menuSections}
      onClickGroup={onClickGroup}
      organization={organization}
    />
  ) : (
    <GroupsSidebarStaticMenuSectionsList
      menuSections={menuSections}
      onClickGroup={onClickGroup}
      organization={organization}
    />
  );
};

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const StyledInput = styled(Input)`
  width: 100%;
  pointer-events: all;
  background-color: transparent;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  color: gray.400;
  ${th('typographyStyles.text.sm-semibold')};
`;

const StyledEditSectionNameInput = styled(Input)`
  width: 100%;
  pointer-events: all;
  background-color: transparent;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  color: gray.300;

  input {
    padding-top: calc(${theme.sizes[1]} + 1px);
    padding-bottom: calc(${theme.sizes[1]} + 1px);
    padding-left: calc(${theme.sizes[3]} - 1px);
    ${th('typographyStyles.text.md-semibold')};
  }
`;

const GroupsSidebarDropdown: FC<{
  icon: JSX.Element;
  items: {
    name: string;
    divider?: boolean;
    onSelect: () => void;
  }[];
}> = ({ icon, items }) => {
  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <x.button
          cursor="pointer"
          border="none"
          backgroundColor="transparent"
          color="gray.300"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          {icon}
        </x.button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <StyledDropdownMenuContent align="end">
          <x.div py={3} boxShadow="sm" borderRadius="md" spaceY={1}>
            {items.map(({ name, divider, onSelect }, i) => (
              <StyledDropdownMenuItem
                key={i}
                onClick={(event: React.SyntheticEvent<HTMLDivElement>) =>
                  event.stopPropagation()
                }
                onSelect={onSelect}
                asChild={true}
                px={3}
                {...(divider && {
                  pb: 3,
                  mb: 3,
                  borderBottom: 'default',
                  borderColor: 'gray.100',
                })}
              >
                <Text variant="md">{name}</Text>
              </StyledDropdownMenuItem>
            ))}
          </x.div>
        </StyledDropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

const GroupsSidebarMenuSection: FC<{
  menuSection: MenuSectionCoreFragment;
  menuSections: MenuSectionCoreFragment[];
  expanded: boolean;
  isDragging?: boolean;
  onClickGroup?: () => void;
  onEditMenuSectionName?: (editingSectionName: boolean) => void;
  onCreateMenuSection?: (creatingSection: boolean) => void;
  organization: GroupsSidebarOrganization;
}> = ({
  menuSection,
  menuSections,
  expanded,
  isDragging,
  onClickGroup,
  onEditMenuSectionName,
  onCreateMenuSection,
  organization,
}) => {
  const { toggleMenuSection } = useGroupsMenuSectionsState();
  const isAdmin = useIsViewerAdmin(organization);
  const { features } = useOrganizationEntitlements();

  const { t } = useTranslation();
  const canCreateGroups = useCanViewerCreateGroups({ organization });
  const tooltipText = expanded ? t('hide_section') : t('show_section');

  const name = menuSection.name
    ? menuSection.name
    : menuSection.type === MenuSectionTypes.Groups
      ? t('groups')
      : menuSection.type === MenuSectionTypes.Courses
        ? t('courses')
        : '';

  const [showModalVariant, setShowModalVariant] = useState<
    'group' | 'course' | null
  >(null);

  const [url, setUrl] = useState('');
  const [addingLink, setAddingLink] = useState(false);

  const [createSectionName, setCreateSectionName] = useState('');
  const [creatingSection, setCreatingSection] = useState(false);

  const [editingSectionName, setEditingSectionName] = useState(name);
  const [editingSection, setEditingSection] = useState(false);

  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (isDragging) {
      setCreatingSection(false);
      setEditingSection(false);
      setAddingLink(false);
    }
  }, [isDragging]);

  const [mutateCreateMenuItemUrl] = useCreateMenuItemUrlMutation({
    refetchQueries: [
      refetchMenuSectionsQuery({
        organizationId: organization.id,
      }),
    ],
  });

  const [mutateCreateMenuSection] = useCreateMenuSectionMutation({
    refetchQueries: [
      refetchMenuSectionsQuery({
        organizationId: organization.id,
      }),
    ],
  });

  const [mutateUpdateMenuSection] = useUpdateMenuSectionMutation({
    refetchQueries: [
      refetchMenuSectionsQuery({
        organizationId: organization.id,
      }),
    ],
  });

  const [mutateDeleteMenuSection] = useDeleteMenuSectionMutation({
    refetchQueries: [
      refetchMenuSectionsQuery({
        organizationId: organization.id,
      }),
    ],
  });

  const actionSection = isAdmin ? (
    <GroupsSidebarDropdown
      icon={
        <Icon
          color={{ hover: 'brand.300' }}
          transform="rotate(-90deg)"
          name="more"
          size="4"
        />
      }
      items={[
        ...[
          {
            name: t('edit_section'),
            onSelect: () => {
              setEditingSection(true);
              onEditMenuSectionName?.(true);
            },
          },
        ],
        ...(menuSections.length > 1
          ? [
              {
                name: t('delete_section'),
                onSelect: () =>
                  mutateDeleteMenuSection({
                    variables: {
                      input: {
                        sectionId: menuSection.id,
                      },
                    },
                  }),
              },
            ]
          : []),
      ]}
    />
  ) : undefined;

  const action = canCreateGroups ? (
    <GroupsSidebarDropdown
      icon={<Icon color={{ hover: 'brand.300' }} name="plus" size="4" />}
      items={[
        ...[
          ...(isAdmin
            ? [
                {
                  name: t('add_section'),
                  divider: true,
                  onSelect: () => {
                    setCreatingSection(true);
                    onCreateMenuSection?.(true);
                  },
                },
                {
                  name: t('add_link'),
                  onSelect: () => setAddingLink(true),
                },
              ]
            : []),
          {
            name: t('add_group'),
            onSelect: () => setShowModalVariant('group'),
          },
        ],
        ...(features.Courses && organization.hasCoursesEnabled
          ? isAdmin
            ? [
                {
                  name: t('add_course'),
                  onSelect: () => setShowModalVariant('course'),
                },
              ]
            : []
          : []),
      ]}
    />
  ) : undefined;

  const handleUrlBlur = () => {
    if (isUrl(URL_TRANSFORMATION(url))) {
      mutateCreateMenuItemUrl({
        variables: {
          input: {
            sectionId: menuSection.id,
            url,
          },
        },
      });
    }
    setAddingLink(false);
    setUrl('');
  };

  const handleCreateSectionBlur = () => {
    if (createSectionName) {
      mutateCreateMenuSection({
        variables: {
          input: {
            organizationId: organization.id,
            name: createSectionName,
          },
        },
      });
    }
    setCreatingSection(false);
    setCreateSectionName('');
    onCreateMenuSection?.(false);
  };

  const handleUpdateSectionBlur = () => {
    if (editingSectionName) {
      mutateUpdateMenuSection({
        variables: {
          input: {
            sectionId: menuSection.id,
            name: editingSectionName,
          },
        },
        optimisticResponse: {
          updateMenuSection: {
            ...menuSection,
            name: editingSectionName,
          },
        },
      });
    }
    setEditingSection(false);
    onEditMenuSectionName?.(false);
  };

  return (
    <>
      <x.div
        maxHeight={expanded || addingLink ? '100%' : theme.sizes[9]}
        overflowY="hidden"
        overflowX="visible"
        position="relative"
      >
        <GroupsSidebarLinkList>
          <li>
            {editingSection ? (
              <StyledEditSectionNameInput
                value={editingSectionName}
                placeholder={editingSectionName}
                onChange={(event) => {
                  setEditingSectionName(event.target.value);
                }}
                onBlur={handleUpdateSectionBlur}
                onKeyDown={(event) => {
                  if (event.code === 'Enter') {
                    event.preventDefault();
                    handleUpdateSectionBlur();
                  }
                }}
                autoFocus
                fullWidth
              />
            ) : (
              <x.button
                px={3}
                pr={1.5}
                py={1.5}
                backgroundColor={{ _: 'transparent', hover: 'gray.100' }}
                color={{ _: 'gray.300', hover: 'gray.400' }}
                transitionDuration="fast"
                transitionTimingFunction="ease-in-out"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                onClick={() => toggleMenuSection(menuSection)}
                border="none"
                w="full"
                borderRadius="sm"
                cursor="pointer"
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
              >
                <Tooltip
                  aria-label={tooltipText}
                  size="small"
                  label={<Text variant="sm-semibold">{tooltipText}</Text>}
                >
                  <Text variant="md-semibold" as="span" truncate>
                    {name}
                  </Text>
                </Tooltip>
                <x.div display="flex" spaceX={2}>
                  {hover && (
                    <>
                      {actionSection}
                      {action}
                    </>
                  )}
                </x.div>
              </x.button>
            )}
          </li>
          {isAdmin ? (
            <GroupsSidebarSortableList
              organization={organization}
              menuSection={menuSection}
              onClickGroup={onClickGroup}
            />
          ) : (
            <GroupsSidebarStaticList
              organization={organization}
              menuSection={menuSection}
              onClickGroup={onClickGroup}
            />
          )}
          {addingLink && (
            <x.li mt={2}>
              <StyledInput
                icon={<Icon name="link" color="gray.300" />}
                placeholder={`${t('add_link')}...`}
                onChange={(event) => {
                  setUrl(event.target.value);
                }}
                onBlur={handleUrlBlur}
                onKeyDown={(event) => {
                  if (event.code === 'Enter') {
                    event.preventDefault();
                    handleUrlBlur();
                  }
                }}
                autoFocus
                fullWidth
              />
            </x.li>
          )}
          {creatingSection && (
            <x.li mt={6}>
              <Input
                value={createSectionName}
                placeholder={t('add_section')}
                onChange={(event) => {
                  setCreateSectionName(event.target.value);
                }}
                onBlur={handleCreateSectionBlur}
                onKeyDown={(event) => {
                  if (event.code === 'Enter') {
                    event.preventDefault();
                    handleCreateSectionBlur();
                  }
                }}
                autoFocus
                fullWidth
              />
            </x.li>
          )}
        </GroupsSidebarLinkList>
      </x.div>
      <GroupCreateModal
        organization={organization}
        type={GroupTypes.Regular}
        isOpen={showModalVariant === 'group'}
        onDismiss={() => setShowModalVariant(null)}
        menuSectionId={menuSection.id}
      />
      <GroupCreateModal
        organization={organization}
        type={GroupTypes.Course}
        isOpen={showModalVariant === 'course'}
        onDismiss={() => setShowModalVariant(null)}
        menuSectionId={menuSection.id}
      />
    </>
  );
};

type GroupsSidebarListProps = {
  menuSection: MenuSectionCoreFragment;
  onClickGroup?: () => void;
  organization: GroupsSidebarOrganization;
};

const GroupsSidebarGroupEditableLink: React.FC<{
  menuItem: MenuItemCoreFragment;
  organization: GroupsSidebarOrganization;
}> = ({ menuItem, organization }) => {
  const { t } = useTranslation();
  const isAdmin = useIsViewerAdmin(organization);
  const isTagUrl = menuItem.resource?.url?.includes(
    `/${organization.shortId}/tags/`
  );

  const name =
    menuItem.name ||
    menuItem.resource?.title ||
    menuItem.resource?.url ||
    convertContentToText(menuItem.post?.content as MyRootBlock[]) ||
    '';

  const url =
    menuItem.resource?.url ||
    (menuItem.post &&
      routes.groups.organization(organization.shortId).post(menuItem.post.id));

  const editMenuItemTitle = menuItem.resource
    ? t('edit_link_title')
    : t('edit_post_title');

  const deleteMenuItem = menuItem.resource
    ? t('delete_link')
    : t('delete_post');

  const icon = menuItem.resource ? (isTagUrl ? 'tag' : 'earth') : 'post-card';

  const [editingMenuItemName, setEditingMenuItemName] = useState(name);
  const [editingMenuItem, setEditingMenuItem] = useState(false);

  const [mutateDeleteMenuItem] = useDeleteMenuItemMutation({
    refetchQueries: [
      refetchMenuSectionsQuery({ organizationId: organization.id }),
    ],
  });

  const [mutateUpdateMenuItem] = useUpdateMenuItemMutation({
    refetchQueries: [
      refetchMenuSectionsQuery({ organizationId: organization.id }),
    ],
  });

  const handleEditingMenuItemNameBlur = () => {
    if (editingMenuItemName) {
      mutateUpdateMenuItem({
        variables: {
          input: {
            itemId: menuItem.id,
            name: editingMenuItemName,
          },
        },
      });
    }
    setEditingMenuItem(false);
  };

  const action = isAdmin ? (
    <GroupsSidebarDropdown
      icon={
        <Icon
          color={{ hover: 'brand.300' }}
          transform="rotate(-90deg)"
          name="more"
          size="4"
        />
      }
      items={[
        {
          name: editMenuItemTitle,
          onSelect: () => setEditingMenuItem(true),
        },
        {
          name: deleteMenuItem,
          onSelect: () => {
            mutateDeleteMenuItem({
              variables: {
                input: {
                  itemId: menuItem.id,
                },
              },
            });
          },
        },
      ]}
    />
  ) : undefined;

  if (editingMenuItem) {
    return (
      <StyledInput
        value={editingMenuItemName}
        icon={<Icon name="link" color="gray.300" />}
        placeholder={name}
        onChange={(event) => {
          setEditingMenuItemName(event.target.value);
        }}
        onBlur={handleEditingMenuItemNameBlur}
        onKeyDown={(event) => {
          if (event.code === 'Enter') {
            event.preventDefault();
            handleEditingMenuItemNameBlur();
          }
        }}
        autoFocus
        fullWidth
      />
    );
  } else if (name && url) {
    return (
      <GroupsSidebarNavigationLink
        name={name}
        route={url}
        selectedIcon={icon}
        icon={icon}
        action={action}
      />
    );
  } else {
    return <></>;
  }
};

const GroupsSidebarMenuItem: React.FC<{
  menuItem: MenuItemCoreFragment;
  onClickGroup?: () => void;
  organization: GroupsSidebarOrganization;
}> = ({ menuItem, onClickGroup, organization }) => {
  if (menuItem.resource || menuItem.post) {
    return (
      <GroupsSidebarGroupEditableLink
        key={menuItem.id}
        menuItem={menuItem}
        organization={organization}
      />
    );
  } else if (menuItem.group) {
    return (
      <GroupsSidebarGroupLink
        key={menuItem.id}
        name={menuItem.name ? menuItem.name : menuItem.group.name}
        route={
          menuItem.group.groupType === GroupTypes.Course
            ? routes.groups
                .organization(organization.shortId)
                .course(menuItem.group.id)
            : routes.groups
                .organization(organization.shortId)
                .group(menuItem.group.id)
        }
        group={menuItem.group}
        onClick={onClickGroup}
        organization={organization}
      />
    );
  } else {
    return <></>;
  }
};

const GroupsSidebarStaticList: React.FC<GroupsSidebarListProps> = ({
  menuSection,
  organization,
  onClickGroup,
}) => {
  return (
    <>
      {menuSection.menuItems.map((menuItem) => (
        <GroupsSidebarMenuItem
          key={menuItem.id}
          menuItem={menuItem}
          onClickGroup={onClickGroup}
          organization={organization}
        />
      ))}
    </>
  );
};

const GroupsSidebarSortableList: React.FC<GroupsSidebarListProps> = ({
  menuSection,
  onClickGroup,
  organization,
}) => {
  return (
    <>
      <Droppable droppableId={menuSection.id} type="groups">
        {(provided: DroppableProvided) => (
          <x.div {...provided.droppableProps} ref={provided.innerRef} minH={9}>
            {menuSection.menuItems.map((menuItem, index) => (
              <Draggable
                key={menuItem.id}
                draggableId={menuItem.id}
                index={index}
              >
                {(
                  provided: DraggableProvided,
                  snapshot: DraggableStateSnapshot
                ) => (
                  <x.div
                    display="block"
                    borderRadius="sm"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    {...(snapshot.draggingOver && {
                      bg: 'gray.100',
                      opacity: 0.65,
                    })}
                  >
                    <GroupsSidebarMenuItem
                      key={menuItem.id}
                      menuItem={menuItem}
                      onClickGroup={onClickGroup}
                      organization={organization}
                    />
                  </x.div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </x.div>
        )}
      </Droppable>
    </>
  );
};

const GroupsSidebarStaticMenuSectionsList: React.FC<{
  menuSections: MenuSectionCoreFragment[];
  onClickGroup?: () => void;
  organization: GroupsSidebarOrganization;
}> = ({ menuSections, onClickGroup, organization }) => {
  const { isExpanded } = useGroupsMenuSectionsState();

  return (
    <x.div spaceY={6}>
      {menuSections.map((menuSection) => (
        <GroupsSidebarMenuSection
          key={menuSection.id}
          menuSection={menuSection}
          menuSections={menuSections}
          onClickGroup={onClickGroup}
          organization={organization}
          expanded={isExpanded(menuSection)}
        />
      ))}
    </x.div>
  );
};

const GroupsSidebarSortableMenuSectionsList: React.FC<{
  menuSections: MenuSectionCoreFragment[];
  onClickGroup?: () => void;
  organization: GroupsSidebarOrganization;
}> = ({ menuSections, onClickGroup, organization }) => {
  const { t } = useTranslation();
  const { isExpanded } = useGroupsMenuSectionsState();
  const [showTooltip, setShowTooltip] = useState(false);
  const [disableDrag, setDisableDrag] = useState(false);
  const { cache } = useApolloClient();

  const [reorderMenuItems] = useReorderMenuItemsMutation();
  const [reorderMenuSections] = useReorderMenuSectionsMutation();

  useEffect(() => {
    const root = document.querySelector<HTMLElement>('#__next');

    if (!root) return;

    if (showTooltip) {
      root.classList.add('dragging');
    } else {
      root.classList.remove('dragging');
    }
  }, [showTooltip]);

  const handleReorderMenuItems = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const cachedMenuSections = cache.readQuery<MenuSectionsQuery>({
      query: MenuSectionsDocument,
      variables: {
        organizationId: organization.id,
      },
    });

    const menuSection = cachedMenuSections?.menuSections.find(
      (menuSection) => menuSection.id === result.destination?.droppableId
    );

    const menuItems = menuSection?.menuItems || [];

    let itemIds = [];

    if (
      result.draggableId &&
      menuItems.some((menuItem) => menuItem.id === result.draggableId)
    ) {
      // Reorder within the same section
      const items = reorder(
        menuItems,
        result.source.index,
        result.destination.index
      );
      itemIds = items.map((i) => i.id);
    } else {
      // Reorder across sections
      itemIds = menuItems.map((i) => i.id);

      itemIds.splice(result.destination.index, 0, result.draggableId);
    }

    reorderMenuItems({
      variables: {
        input: {
          itemIds,
          sectionId: result.destination.droppableId,
        },
      },
      refetchQueries: [
        refetchMenuSectionsQuery({
          organizationId: organization.id,
        }),
      ],
    });

    const cachedQuery = cache.readQuery<MenuSectionsQuery>({
      query: MenuSectionsDocument,
      variables: {
        organizationId: organization.id,
      },
    });

    if (!cachedQuery) {
      return;
    }

    if (
      result.draggableId &&
      menuItems.some((menuItem) => menuItem.id === result.draggableId)
    ) {
      // Sort the edges separately until the refresh query resolves
      const { menuSections } = cachedQuery;
      const cachedMenuSection = menuSections.find(
        (cachedMenuSection) =>
          cachedMenuSection.id === result.destination?.droppableId
      );
      const cachedMenuItems = cachedMenuSection?.menuItems || [];
      const fromIndex = cachedMenuItems.findIndex(
        (e) => e.id === menuItems[result.source.index].id
      );
      const toIndex = cachedMenuItems.findIndex(
        (e) => e.id === menuItems[result.destination?.index || 0]?.id
      );

      const reorderedMenutItems = reorder(cachedMenuItems, fromIndex, toIndex);

      const updatedCachedMenuSection = {
        ...cachedMenuSection,
        menuItems: reorderedMenutItems,
      };

      cache.writeQuery({
        query: MenuSectionsDocument,
        variables: {
          organizationId: organization.id,
        },
        data: {
          menuSections: menuSections.map((menuSection) => {
            if (menuSection.id === updatedCachedMenuSection.id) {
              return updatedCachedMenuSection;
            }
            return menuSection;
          }),
        },
      });
    } else {
      const menuItem = cache.readFragment<MenuItemCoreFragment>({
        id: `MenuItem:${result.draggableId}`,
        fragment: MenuItemCoreFragmentDoc,
        fragmentName: 'MenuItemCore',
      });

      const sourceMenuSection = cache.readFragment<MenuSectionCoreFragment>({
        id: `MenuSection:${result.source.droppableId}`,
        fragment: MenuSectionCoreFragmentDoc,
        fragmentName: 'MenuSectionCore',
      });

      const destinationMenuSection =
        cache.readFragment<MenuSectionCoreFragment>({
          id: `MenuSection:${result.destination.droppableId}`,
          fragment: MenuSectionCoreFragmentDoc,
          fragmentName: 'MenuSectionCore',
        });

      if (sourceMenuSection) {
        cache.writeFragment<MenuSectionCoreFragment>({
          id: `MenuSection:${result.source.droppableId}`,
          fragment: MenuSectionCoreFragmentDoc,
          fragmentName: 'MenuSectionCore',
          data: {
            ...sourceMenuSection,
            menuItems:
              sourceMenuSection?.menuItems.filter(
                (menuItem) => menuItem.id !== result.draggableId
              ) || [],
          },
        });
      }

      if (destinationMenuSection && menuItem) {
        const menuItems = [...destinationMenuSection.menuItems];
        menuItems.splice(result.destination.index, 0, menuItem);
        cache.writeFragment<MenuSectionCoreFragment>({
          id: `MenuSection:${result.destination.droppableId}`,
          fragment: MenuSectionCoreFragmentDoc,
          fragmentName: 'MenuSectionCore',
          data: {
            ...destinationMenuSection,
            menuItems,
          },
        });
      }
    }
  };

  const handleReorderMenuSections = (
    result: DropResult,
    menuSections: MenuSectionCoreFragment[]
  ) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      menuSections,
      result.source.index,
      result.destination.index
    );

    reorderMenuSections({
      variables: { input: { sectionIds: items.map((i) => i.id) } },
      refetchQueries: [
        refetchMenuSectionsQuery({
          organizationId: organization.id,
        }),
      ],
    });

    const cachedQuery = cache.readQuery<MenuSectionsQuery>({
      query: MenuSectionsDocument,
      variables: {
        organizationId: organization.id,
      },
    });

    if (!cachedQuery) {
      return;
    }

    // Sort the edges separately until the refresh query resolves
    const { menuSections: cachedMenuSections } = cachedQuery;
    const fromIndex = cachedMenuSections.findIndex(
      (e) => e.id === menuSections[result.source.index].id
    );
    const toIndex = cachedMenuSections.findIndex(
      (e) => e.id === menuSections[result.destination?.index || 0]?.id
    );

    const reorderedMenutSections = reorder(
      cachedMenuSections,
      fromIndex,
      toIndex
    );

    cache.writeQuery({
      query: MenuSectionsDocument,
      variables: {
        organizationId: organization.id,
      },
      data: {
        menuSections: reorderedMenutSections,
      },
    });
  };

  return (
    <>
      <Tooltip
        visible={showTooltip}
        aria-label={t('sort_groups')}
        size="small"
        mt="-2"
        position={topCenter}
        label={
          <Text variant="sm-semibold" textAlign="center">
            {t('sort_groups')}
          </Text>
        }
      >
        <x.div position="absolute" w="full" top={0} />
      </Tooltip>
      <DragDropContext
        onDragStart={() => setShowTooltip(true)}
        onDragEnd={(result) => {
          setShowTooltip(false);

          if (!result.destination) {
            return;
          }

          if (result.type === 'sections') {
            handleReorderMenuSections(result, menuSections);
          } else if (result.type === 'groups') {
            handleReorderMenuItems(result);
          }
        }}
      >
        <Droppable droppableId="sections" type="sections">
          {(provided: DroppableProvided) => (
            <x.div
              {...provided.droppableProps}
              ref={provided.innerRef}
              spaceY={6}
            >
              {menuSections?.map((menuSection, index) => (
                <Draggable
                  key={menuSection.id}
                  draggableId={menuSection.id}
                  index={index}
                  isDragDisabled={disableDrag}
                  disableInteractiveElementBlocking
                >
                  {(
                    provided: DraggableProvided,
                    snapshot: DraggableStateSnapshot
                  ) => (
                    <x.div
                      display="block"
                      borderRadius="sm"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      {...(snapshot.draggingOver && {
                        bg: 'gray.100',
                        opacity: 0.65,
                      })}
                    >
                      <GroupsSidebarMenuSection
                        key={menuSection.id}
                        menuSection={menuSection}
                        menuSections={menuSections}
                        onClickGroup={onClickGroup}
                        onEditMenuSectionName={setDisableDrag}
                        onCreateMenuSection={setDisableDrag}
                        organization={organization}
                        expanded={isExpanded(menuSection)}
                        isDragging={snapshot.isDragging}
                      />
                    </x.div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </x.div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
