import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  InMemoryCache,
  split,
  useApolloClient,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import {
  getMainDefinition,
  relayStylePagination,
} from '@apollo/client/utilities';
import * as Sentry from '@sentry/nextjs';
import {
  CachePersistor,
  LocalStorageWrapper,
  persistCache,
} from 'apollo3-cache-persist';
import { GraphQLClient } from 'graphql-request';
import { createClient } from 'graphql-ws';
import { uniqBy } from 'lodash';
import { useRouter } from 'next/router';
import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';

import { getSdk } from '../../../../generated/graphql-request-api-sdk';
import {
  AUTH0_SCOPE,
  BASE_URL,
  IS_DEMO,
  IS_DEV,
  IS_SSR,
} from '../../../config/constants';
import { resetPostHog } from '../../analytics/PostHogAnalytics';
import { useAuth } from '../../auth/hooks/useAuth';
import { useDemoContext } from '../../demo/useDemoContext';
import {
  directMessageFieldPolicy,
  directMessageNotificationCountsFieldPolicy,
  directMessageThreadsFieldPolicy,
} from '../../messages/utils/cache';
import { usePushServiceWorker } from '../../notifications/hooks/usePushServiceWorker';
import { ONBOARDING_STATE_KEY } from '../../onboarding/utils/questions';
import { Toast } from '../components/Toast';
import { AppContext } from '../machine/appContext';

const SCHEMA_VERSION = process.env.NEXT_PUBLIC_COMMIT_SHA || 'dev';
const SCHEMA_VERSION_KEY = 'apollo-schema-version';

export const { link: networkStatusNotifierLink, useApolloNetworkStatus } =
  createNetworkStatusNotifier();

const cache = new InMemoryCache({
  typePolicies: {
    OrganizationRole: {
      keyFields: false,
    },
    Update: {
      fields: {
        reactions: {
          merge: false,
        },
      },
    },
    Comment: {
      fields: {
        reactions: {
          merge: false,
        },
      },
    },
    Post: {
      fields: {
        reactions: {
          merge: false,
        },
      },
    },
    Reaction: {
      fields: {
        createdByUsers: {
          merge: false,
        },
      },
    },
    DirectMessage: {
      fields: {
        reactions: {
          merge(_, incoming) {
            return [...incoming];
          },
        },
      },
    },
    StarterQuestion: {
      keyFields: ['referenceId'],
    },
    User: {
      keyFields: ['username'],
    },
    Group: {
      fields: {
        posts: {
          keyArgs: false,
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;

            const { edges, ...rest } = incoming;
            // We only need to merge the nodes array.
            // The rest of the fields (pagination) should always be overwritten by incoming
            const result = rest;
            result.edges = uniqBy([...edges, ...existing.edges], 'node.__ref');
            return result;
          },
        },
      },
    },
    Mutation: {
      fields: {
        createOrDeleteDirectMessageReaction: {
          ...directMessageFieldPolicy,
        },
      },
    },
    Query: {
      fields: {
        updates: relayStylePagination(),
        events: relayStylePagination(['organizationId', 'past']),
        users: relayStylePagination(['organizationId', 'groupId']),
        allUsers: relayStylePagination(['query']),
        organizations: relayStylePagination(['query']),
        feed: relayStylePagination(['organizationId']),
        postsByGroup: relayStylePagination(['groupId']),
        postsByEvent: relayStylePagination(['eventId']),
        helloPages: relayStylePagination(),
        notifications: relayStylePagination(['organizationId']),
        homeFeed: relayStylePagination([
          'organizationId',
          'username',
          'tag',
          'unseen',
        ]),
        groups: relayStylePagination(['organizationId']),
        directMessage: {
          ...directMessageFieldPolicy,
        },
        directMessageThreads: {
          ...directMessageThreadsFieldPolicy,
        },
        directMessageNotificationCounts: {
          ...directMessageNotificationCountsFieldPolicy,
        },
      },
    },
  },
  possibleTypes: {
    HelloPageBlock: [
      'HelloPageBlockIntroduction',
      'HelloPageBlockNote',
      'HelloPageBlockVideo',
      'HelloPageBlockResource',
    ],
  },
});

const FROND_GRAPHQL_URI = `${process.env.NEXT_PUBLIC_API_BASE_URL}/graphql`;
const STELLATE_GRAPHQL_URI = process.env.NEXT_PUBLIC_STELLATE_API_BASE_URL
  ? `${process.env.NEXT_PUBLIC_STELLATE_API_BASE_URL}/graphql`
  : null;

const PRIORITY_QUERIES = [
  'Viewer',
  'OrganizationByShortId',
  'HomeFeed',
  'Users',
  'Groups',
  'PostsByGroup',
  'CommentConfig',
  'SidebarGroups',
  'MenuSections',
];

export const getHttpLink = (): ApolloLink => {
  const opts = {
    uri: STELLATE_GRAPHQL_URI || FROND_GRAPHQL_URI,
    fetchOptions: {},
  };

  /**
   * Avoid TLS locally for SSR since our
   * local cert isn't trusted by node
   */
  if (IS_DEV && IS_SSR) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires -- needs to be environment dependent
    const https = require('https');
    opts.fetchOptions = {
      agent: new https.Agent({ rejectUnauthorized: false }),
    };
  }

  return split(
    (operation) => {
      // Don't batch priority queries
      if (PRIORITY_QUERIES.includes(operation.operationName)) {
        return true;
      }

      const queryDef = operation.query.definitions[0];

      // Only batch other queries
      return (
        queryDef &&
        queryDef.kind === 'OperationDefinition' &&
        queryDef.operation !== 'query'
      );
    },
    new HttpLink(opts),
    new BatchHttpLink({ ...opts, batchDebounce: true })
  );
};

const apiClient = new GraphQLClient(FROND_GRAPHQL_URI);

export const sdk = getSdk(apiClient);

export const CustomApolloProvider: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const noCache = Boolean(router.query.cache === 'false');
  const { isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth();
  const { organization: demoOrganization } = useDemoContext();
  const [token, setToken] = useState<string>();
  const [hasNetworkError, setHasNetworkError] = useState(false);

  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message }) => {
            if (
              // Either unauthenticated or deleted
              message === 'Unauthenticated' ||
              message.includes('The user does not exist')
            ) {
              Sentry.captureException(
                new Error('Apollo error when authenticating GraphQL.'),
                {
                  extra: {
                    message,
                  },
                  level: 'error',
                }
              );
              logout({
                logoutParams: {
                  returnTo: BASE_URL,
                },
              });
            }
          });
        }
        if (networkError) {
          setHasNetworkError(true);
        }
      }),
    [logout]
  );

  const authLink = useMemo(
    () =>
      setContext((_, { headers }) => {
        if (IS_DEMO) {
          setToken(`demo|${demoOrganization?.shortId}`);
          return {
            headers: {
              ...headers,
              authorization: `Bearer demo|${demoOrganization?.shortId}`,
            },
          };
        }

        const getCtx = (accessToken?: string) => ({
          headers: {
            ...headers,
            ...(accessToken && {
              authorization: `Bearer ${accessToken}`,
            }),
          },
        });

        if (isLoading) return getCtx();

        if (!isLoading && !isAuthenticated) return getCtx();

        /**
         * Conditional is only valid for SSR where the access token will always be
         * null or on the client if the access token has already been set.
         */
        if (IS_SSR) {
          return getCtx();
        }

        return new Promise((resolve) => {
          getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
              scope: AUTH0_SCOPE,
            },
          })
            .then((tkn) => {
              if (tkn !== token) {
                setToken(tkn);
              }

              resolve(getCtx(tkn));
            })
            .catch((error) => {
              Sentry.captureException(error, {
                extra: {
                  message:
                    'Error when getting token from auth0 silently in Apollo',
                },
                level: 'error',
              });
            });
        });
      }),
    [
      isLoading,
      isAuthenticated,
      demoOrganization,
      getAccessTokenSilently,
      token,
    ]
  );

  const retryLink = useMemo(() => new RetryLink(), []);

  const apolloClient = useMemo(() => {
    const links = [authLink, networkStatusNotifierLink, errorLink, retryLink];
    const httpLink = getHttpLink();

    IS_SSR
      ? links.push(httpLink)
      : links.push(
          split(
            ({ query }) => {
              const definition = getMainDefinition(query);
              return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
              );
            },
            new GraphQLWsLink(
              createClient({
                url: FROND_GRAPHQL_URI.replace('https://', 'wss://'),
                connectionParams: {
                  token,
                },
              })
            ),
            httpLink
          )
        );

    return new ApolloClient({
      name: 'web',
      version: process.env.NEXT_PUBLIC_COMMIT_SHA,
      link: from(links),
      cache: noCache ? new InMemoryCache() : cache,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
        },
      },
    });
  }, [authLink, errorLink, noCache, retryLink, token]);

  useEffect(() => {
    const currentVersion = window.localStorage.getItem(SCHEMA_VERSION_KEY);

    const noPersistenceMapper =
      router.query.noPersistenceMapper === 'true' || false;

    persistCache({
      cache,
      storage: new LocalStorageWrapper(window.localStorage),
      maxSize: 512000,
      ...(!noPersistenceMapper && {
        persistenceMapper: async (data: string) => {
          const parsed = JSON.parse(data);
          delete parsed['ROOT_QUERY']['homeFeed'];
          return JSON.stringify(parsed);
        },
      }),
    }).then(() => {
      if (currentVersion !== SCHEMA_VERSION) {
        // We'll want to purge the outdated persisted cache
        // and mark ourselves as having updated to the latest version.
        if (currentVersion) apolloClient.resetStore();
        window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
      }
    });

    const ONE_MIN = 60 * 1000;

    let lostFocusAt: Date | null = null;

    // todo remove, this is for performance investigation
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__APOLLO__ = apolloClient;

    const handleFocus = () => {
      if (lostFocusAt) {
        const now = new Date();
        if (
          now.getTime() - lostFocusAt.getTime() > ONE_MIN &&
          window.navigator.onLine
        ) {
          const queries = apolloClient.getObservableQueries('all');
          queries.forEach((observableQuery) => {
            const queryNamesToSkip = ['StarterQuestions'];
            const queryNameShouldBeSkipped = observableQuery.queryName
              ? queryNamesToSkip.includes(observableQuery.queryName)
              : false;

            const { context } = observableQuery.options;

            if (queryNameShouldBeSkipped || context?.skip) {
              return;
            }

            // Refresh queries that aren't marked as skip only
            observableQuery.resetLastResults();
            observableQuery.refetch();
          });
        }
      }

      lostFocusAt = null;
    };

    const handleBlur = () => {
      lostFocusAt = new Date();
    };

    window.addEventListener('focus', handleFocus);

    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, [apolloClient, router.query.noPersistenceMapper]);

  return (
    <ApolloProvider client={apolloClient}>
      {hasNetworkError ? (
        <Toast
          message="A network error occurred. Please try again later."
          variant="error"
          onDisappear={() => setHasNetworkError(false)}
        />
      ) : null}

      {children}
    </ApolloProvider>
  );
};

export const useSignOut = (): ((callbackUrlPath?: string) => void) => {
  const apolloClient = useApolloClient();
  const { logout } = useAuth();
  const router = useRouter();
  const { revokeSubscription } = usePushServiceWorker();
  const { appService } = useContext(AppContext);

  return async (callbackUrlPath?: string): Promise<void> => {
    if (IS_DEMO) return;

    Sentry.captureMessage('Logging out user.', {
      level: 'info',
      extra: {
        callbackUrlPath: callbackUrlPath,
      },
    });

    appService.send('LOGOUT');

    await revokeSubscription();

    const persistor = new CachePersistor({
      cache: apolloClient.cache,
      storage: window.localStorage,
    });
    persistor.purge();
    logout({
      openUrl: false,
    });
    await apolloClient.clearStore();

    // Clear onboarding on signOut
    localStorage.removeItem(ONBOARDING_STATE_KEY);

    // Reset PostHog session
    resetPostHog();

    router.push(callbackUrlPath || '/');
  };
};
