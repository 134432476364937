import { theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useContext } from 'react';

import { GIPHY_API_KEY } from '../../../config/constants';
import { GiphySearch } from '../../common/components/GiphySearch';
import { SearchContextManager } from '../../common/components/GiphySearchContext';
import { PostComposerStateContext } from './PostComposer';
import { PostComposerSurfaceBg } from './PostComposerSurfaceBg';

export const PostComposerGiphySurface = () => {
  const { postComposerService } = useContext(PostComposerStateContext);

  return (
    <x.div mx={-2}>
      <PostComposerSurfaceBg>
        <SearchContextManager
          shouldDefaultToTrending
          apiKey={GIPHY_API_KEY as string}
        >
          <GiphySearch
            colorVariant="brand"
            onCompleted={(giphy) => {
              postComposerService.send({
                type: 'ADD_MEDIA',
                media: {
                  ...giphy,
                },
              });
            }}
          />
        </SearchContextManager>
      </PostComposerSurfaceBg>

      <x.div
        display={{ _: 'none', 'sm-2': 'flex' }}
        position="absolute"
        bottom={`-${theme.sizes[12]}`}
        w="34.5rem"
        justifyContent="center"
      >
        <x.img
          w="60"
          src="https://res.cloudinary.com/frond/image/upload/v1676458511/static/Poweredby_640px-Black_HorizLogo_comthh.png"
          alt="Giphy attribution"
        />
      </x.div>
    </x.div>
  );
};
