import { theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { th } from '@xstyled/system';
import React, { useMemo } from 'react';

export interface VideoRecordButtonProps {
  recording: boolean;
  onClick: () => void;
  percentElapsed: number;
  disabled: boolean;
  diameter?: number;
  strokeWidth?: number;
}

/**
 * Animated record / stop record button for the video recorder
 */
export const VideoRecordButton: React.FC<VideoRecordButtonProps> = ({
  recording,
  onClick,
  //percentElapsed,
  disabled,
  diameter = 76,
  strokeWidth = 6,
}) => {
  // Cache dimensions once since they don't really change
  const {
    innerRadius,
    radius,
    innerCircumference,
    buttonDiameter,
    reducedButtonDiameter,
  } = useMemo(() => {
    const innerRadius = (diameter - strokeWidth) / 2;

    return {
      innerRadius,
      radius: diameter / 2,
      innerCircumference: 2 * Math.PI * innerRadius,
      buttonDiameter: diameter - 2 * strokeWidth,
      reducedButtonDiameter: innerRadius + strokeWidth + 2,
    };
  }, [diameter, strokeWidth]);

  const trackStroke = recording
    ? 'rgba(255, 255, 255, 0.2)'
    : 'rgba(235, 87, 87, 0.3)';

  // const progressOffset = (100 - percentElapsed) * 0.01 * innerCircumference;

  return (
    <x.div
      w={`${diameter}px`}
      h={`${diameter}px`}
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      cursor="pointer"
      onClick={disabled ? undefined : onClick}
      data-test={disabled ? 'record-button-disabled' : 'record-button'}
    >
      {/* Track */}
      <x.svg
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        borderRadius={th.radius('full')}
        w="100%"
        h="100%"
      >
        <circle
          cx={`${radius}px`}
          cy={`${radius}px`}
          r={`${innerRadius}px`}
          fill="none"
          stroke={trackStroke}
          strokeWidth={`${strokeWidth}px`}
        />
      </x.svg>

      {/* Progress */}
      {recording && (
        <x.svg
          transformOrigin="center center"
          transform="rotate(-90deg)"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          w={`${diameter}px`}
          h={`${diameter}px`}
        >
          <circle
            cx={`${radius}px`}
            cy={`${radius}px`}
            r={`${innerRadius}px`}
            fill="none"
            stroke={theme.colors.gray[300]}
            opacity={0.2}
            strokeWidth={`${strokeWidth}px`}
            strokeDasharray={innerCircumference}
            strokeLinecap="round"
          />
        </x.svg>
      )}

      {/* Inner button */}
      <x.button
        appearance="none"
        transition
        transitionDuration="100"
        transitionProperty="border-radius, width, height, background-color"
        transitionTimingFunction="ease"
        backgroundColor="red.300"
        border="none"
        w={recording ? `${reducedButtonDiameter}px` : `${buttonDiameter + 1}px`}
        h={recording ? `${reducedButtonDiameter}px` : `${buttonDiameter + 1}px`}
        borderRadius={recording ? theme.radii.md : theme.radii.full}
      />
    </x.div>
  );
};
