import {
  AlertDialogContent,
  AlertDialogContentProps,
  AlertDialogDescription,
  AlertDialogLabel,
  AlertDialogOverlay,
  AlertDialogProps,
} from '@reach/alert-dialog';
import styled, { th, x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { FC, MutableRefObject } from 'react';

import { Button } from './Button';
import { Heading } from './Heading';
import { Icon } from './Icon';
import { Input } from './Input';
import { Text } from './Text';

export interface AlertProps {
  /**
   * Text for the heading
   */
  headingText?: string;
  /**
   * Text for the description
   */
  descriptionText?: string;
  /**
   * Element to use in place of description
   */
  descriptionElement?: React.ReactElement;
  /**
   * Text for the cancel button
   */
  cancelButtonText?: string;
  /**
   * Text for the submit button
   */
  submitButtonText?: string;
  /**
   * Submitting
   */
  submitting?: boolean;
  /**
   * Callback for click on cancel
   */
  onCancelClick?: () => void;
  /**
   * Callback for click on submit
   */
  onSubmitClick?: () => void;
  /**
   * Callback for creating non-blocking alerts
   */
  onDismiss?: () => void;
  /**
   * Variant for style of alert
   */
  variant?: 'warning';
  /**
   * Optional icon names for display above the title
   */
  icons?: string[];
  /**
   * Text to confirm deletion
   */
  deleteTextToConfirm?: string;
  /**
   * User would need to type "DELETE" to confirm deletion
   */
  deleteInputConfirmationNeeded?: boolean;
  /**
   * Optional width
   */
  w?: string;
}

const StyledAlertDialogOverlay = styled(AlertDialogOverlay)`
  display: flex;
  align-items: center;
  z-index: modal;
  background: hsla(0, 0%, 0%, 0.3);
  margin: auto;
`;

const StyledAlertDialogContent = styled<
  FC<AlertDialogContentProps & { w?: string }>
>(AlertDialogContent)`
  background-color: white;
  border-radius: md;

  /* Undefined size in theme */
  width: ${(p) => p.w || '368px'};

  text-align: center;
  box-shadow: sm;
`;

const StyledAlertHeading = styled(Heading)<{
  alertVariant?: AlertProps['variant'];
}>`
  margin-bottom: 4;
  color: ${(p) =>
    p.alertVariant === 'warning' ? th.color('red.300') : 'inherit'};
`;

const StyledIconContainer = styled.div`
  margin-bottom: 8;
  display: inline-flex;
`;
const StyledIcon = styled(Icon)`
  margin-left: 4;
  margin-right: 4;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5;

  > * + * {
    margin-left: 3;
  }
`;

export type AlertContainerProps = AlertDialogProps & {
  leastDestructiveRef: MutableRefObject<HTMLButtonElement | null>;
  w?: string;
};

export const AlertContainer: React.FC<AlertContainerProps> = (props) => {
  const { children, ...overlayProps } = props;

  return (
    <StyledAlertDialogOverlay {...overlayProps}>
      <StyledAlertDialogContent data-test="alert" w={props.w}>
        {children}
      </StyledAlertDialogContent>
    </StyledAlertDialogOverlay>
  );
};

export const Alert: React.FC<AlertProps> = (props) => {
  const { t } = useTranslation();
  const { deleteTextToConfirm = t('type_delete') } = props;
  const leastDestructiveRef = React.useRef<HTMLButtonElement | null>(null);
  const [inputValue, setInputValue] = React.useState('');

  return (
    <AlertContainer
      leastDestructiveRef={leastDestructiveRef}
      onDismiss={props.onDismiss}
      w={props.w}
    >
      <AlertDialogLabel>
        {props.icons?.length && (
          <StyledIconContainer>
            {props.icons.map((name) => (
              <StyledIcon name={name} key={name} size="8" />
            ))}
          </StyledIconContainer>
        )}
        {props.headingText && (
          <StyledAlertHeading
            variant={{ _: 'lg', md: 'xl' }}
            alertVariant={props.variant}
          >
            {props.headingText}
          </StyledAlertHeading>
        )}
      </AlertDialogLabel>
      <AlertDialogDescription>
        <x.div spaceY={4}>
          {props.descriptionText && <Text>{props.descriptionText}</Text>}
          {props.descriptionElement}

          {props.deleteInputConfirmationNeeded && (
            <Input
              name="confirmation"
              value={inputValue}
              placeholder={t('type_delete_to_confirm', {
                textToConfirm: deleteTextToConfirm,
              })}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
            />
          )}
        </x.div>
      </AlertDialogDescription>
      {(props.onCancelClick || props.onSubmitClick) && (
        <ButtonWrapper>
          {props.onCancelClick && props.cancelButtonText && (
            <Button
              variant="secondary"
              ref={leastDestructiveRef}
              onClick={props.onCancelClick}
              label={props.cancelButtonText}
            />
          )}
          {props.onSubmitClick && props.submitButtonText && (
            <Button
              data-test="alert-submit"
              variant={props.variant ? props.variant : 'primary'}
              onClick={props.onSubmitClick}
              label={props.submitButtonText}
              loading={props.submitting}
              disabled={
                props.deleteInputConfirmationNeeded &&
                inputValue.toUpperCase() !== deleteTextToConfirm
              }
            />
          )}
        </ButtonWrapper>
      )}
    </AlertContainer>
  );
};
