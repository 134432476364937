import { x } from '@xstyled/styled-components';

import { GroupProfileCard } from '../../groups/components/GroupProfileCard';

export const MembersLoading = () => {
  return (
    <x.div spaceY={2}>
      {[...Array(12).keys()].map((key) => (
        <GroupProfileCard key={key} />
      ))}
    </x.div>
  );
};
