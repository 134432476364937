import React from 'react';

import { useViewer } from '../../auth/hooks/useViewer';
import { Loading } from '../../common/components/Loading';
import { GroupsOnboarding } from './GroupsOnboarding';

export const OnboardingFlow: React.FC = () => {
  const { viewer } = useViewer();

  if (!viewer) {
    return <Loading />;
  }

  return <GroupsOnboarding />;
};

OnboardingFlow.displayName = 'OnboardingFlow';
