import { getCloudinaryUrl } from '@frond/shared';
import { useSelector } from '@xstate/react';
import { styled, x } from '@xstyled/styled-components';
import {
  getAbsoluteZoom,
  getZoomFactor,
} from 'advanced-cropper/extensions/absolute-zoom';
import React, { useContext, useEffect, useRef } from 'react';
import {
  CropperBackgroundWrapperProps,
  FixedCropper,
  FixedCropperRef,
  ImageRestriction,
  isTouchEvent,
  isWheelEvent,
  TransformableImage,
  TransformableImageEvent,
  useMoveImageOptions,
  useScaleImageOptions,
} from 'react-advanced-cropper';

import { Giphy, Image, Video } from '../../../../generated/types-and-hooks';
import { getAnswerNavigationLinkProps } from '../../questions/hooks/useAnswerNavigation';
import { PostCardGiphy } from './PostCardGiphy';
import { PostCardProcessing } from './PostCardProcessing';
import { PostCardVideo } from './PostCardVideo';
import { PostComposerStateContext } from './PostComposer';
import {
  PostComposerImage as PostComposerImageType,
  ProcessingMedia,
} from './postComposerMachine';

type PostCardMediaItemProps = {
  media: Video | Image | Giphy | ProcessingMedia;
  linkProps?: ReturnType<typeof getAnswerNavigationLinkProps>;
  i?: number;
};

export const CropperBackgroundWrapper = ({
  cropper,
  scaleImage = true,
  moveImage = true,
  children,
  className,
  style,
}: CropperBackgroundWrapperProps) => {
  const moveImageOptions = useMoveImageOptions(moveImage);

  const scaleImageOptions = useScaleImageOptions(scaleImage);

  const transitions = cropper.getTransitions();

  const eventsHandler = (
    event: TransformableImageEvent,
    nativeEvent: Event
  ) => {
    if (isTouchEvent(nativeEvent)) {
      if (nativeEvent.touches.length === 1 && !event.active) {
        event.preventDefault();
      }
    } else if (isWheelEvent(nativeEvent)) {
      if (!event.active && !(nativeEvent.ctrlKey || nativeEvent.metaKey)) {
        event.preventDefault();
      }
    }
    if (!event.defaultPrevented) {
      nativeEvent.preventDefault();
      nativeEvent.stopPropagation();
    }
  };

  return (
    <TransformableImage
      className={className}
      style={style}
      onTransform={cropper.transformImage}
      onTransformEnd={cropper.transformImageEnd}
      onEvent={eventsHandler}
      touchMove={moveImageOptions.touch}
      mouseMove={moveImageOptions.mouse}
      touchScale={scaleImageOptions.touch}
      wheelScale={scaleImageOptions.wheel}
      disabled={transitions.active}
    >
      {children}
    </TransformableImage>
  );
};

type PostComposerImageProps = {
  image: PostComposerImageType;
};

const StyledFixedCropper = styled(FixedCropper)`
  background-color: gray.50;
`;

const PostComposerImage = ({ image }: PostComposerImageProps) => {
  const { postComposerService } = useContext(PostComposerStateContext);
  const aspectRatio =
    useSelector(
      postComposerService,
      (state) => state.context.post.aspectRatio
    ) || 1;
  const cropperRef = useRef<FixedCropperRef>(null);
  useEffect(() => {
    if (!cropperRef.current) return;
    const state = cropperRef.current.getState();

    const settings = cropperRef.current.getSettings();

    const currentZoom = getAbsoluteZoom(state, settings);

    cropperRef.current.refresh();

    // Once the cropper is refreshed, we need to zoom back to the previous zoom level
    setTimeout(() => {
      if (!cropperRef.current) return;
      const newState = cropperRef.current.getState();

      const newSettings = cropperRef.current.getSettings();

      cropperRef.current.zoomImage(
        getZoomFactor(newState, newSettings, currentZoom),
        {
          transitions: false,
        }
      );
    }, 0);
  }, [aspectRatio]);

  return (
    <StyledFixedCropper
      ref={cropperRef}
      style={{
        height: '100%',
        cursor: 'all-scroll',
        width: '100%',
      }}
      stencilProps={{
        handlers: false,
        lines: false,
        movable: false,
        resizable: false,
        grid: true,
        aspectRatio,
      }}
      stencilSize={{
        width: Infinity,
        height: Infinity,
      }}
      backgroundWrapperComponent={CropperBackgroundWrapper}
      imageRestriction={ImageRestriction.fillArea}
      src={image.base64 || getCloudinaryUrl({ id: image.publicId })}
      onReady={(cropper) => {
        postComposerService.send({
          type: 'SET_CROPPER_REF',
          cropperRef: cropper,
          mediaId: image.id,
        });

        if (image.coordinates) {
          cropper.setCoordinates(image.coordinates);
        }
      }}
      defaultCoordinates={
        image.coordinates
          ? {
              left: image.coordinates.x,
              top: image.coordinates.y,
              width: image.coordinates.width,
              height: image.coordinates.height,
            }
          : undefined
      }
      onInteractionEnd={(cropper) => {
        const coordinates = cropper.getCoordinates();

        if (coordinates) {
          postComposerService.send({
            type: 'UPDATE_IMAGE_COORDINATES',
            mediaId: image.id,
            coordinates: {
              x: coordinates.left,
              y: coordinates.top,
              width: coordinates.width,
              height: coordinates.height,
            },
          });
        }
      }}
    />
  );
};

export const PostComposerMediaItem = ({
  media,
  linkProps,
  i,
}: PostCardMediaItemProps) => {
  let children;
  if ('publicId' in media) {
    children = <PostComposerImage image={media} />;
  } else if ('giphyUrl' in media) {
    children = <PostCardGiphy giphy={media} linkProps={linkProps} />;
  } else if ('status' in media) {
    children = <PostCardVideo video={media} />;
  } else {
    return <PostCardProcessing media={media} />;
  }

  return i === 0 ? (
    children
  ) : (
    <x.div position="absolute" w="full" maxHeight="100%" h="full">
      {children}
    </x.div>
  );
};
