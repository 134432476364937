import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { DEMO_USER_EMAIL } from '@frond/shared';
import * as Sentry from '@sentry/nextjs';
import auth0 from 'auth0-js';

import { AUTH0_COOKIE_KEY, IS_DEMO, IS_SSR } from '../../../config/constants';

// promisify-ing
async function sendMagicLinkEmail(
  email: string,
  callbackUrlPath?: string,
  inviteCode?: string
): Promise<void> {
  /*
   * auth0-react library does not support magic link with embedded login flow
   * so the only way is to instantiate a new auth0 instance using auth0-js
   */
  let redirectUri = callbackUrlPath
    ? `${window.location.origin}/auth-callback?callbackUrlPath=${callbackUrlPath}`
    : `${window.location.origin}/auth-callback`;

  // Add invite query param when URL doesn't contain it
  if (
    inviteCode &&
    !redirectUri.includes('?invite=') &&
    !redirectUri.includes('&invite=')
  ) {
    redirectUri = callbackUrlPath
      ? `${redirectUri}&invite=${inviteCode}`
      : `${redirectUri}?invite=${inviteCode}`;
  }

  const webAuth = new auth0.WebAuth({
    clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string,
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string,
    audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
    redirectUri,
    responseType: 'code',
  });

  return new Promise((resolve) => {
    webAuth.passwordlessStart(
      {
        connection: 'email',
        send: 'link',
        email: email,
        authParams: {
          redirectUri,
        },
      },
      (err, res) => {
        if (err) {
          Sentry.captureException(err);
        } else {
          resolve(res);
        }
      }
    );
  });
}

type AdditionalAuthFnInterface = {
  sendMagicLinkEmail: (
    email: string,
    callbackUrlPath?: string,
    inviteCode?: string,
    shortId?: string
  ) => Promise<void>;
  hasAuth0Cookie: boolean;
};
type CustomAuthHook = Auth0ContextInterface & AdditionalAuthFnInterface;

const commonFakeUseAuth0HookProperties: Omit<CustomAuthHook, 'user'> = {
  error: undefined,
  isLoading: false,
  isAuthenticated: true,
  handleRedirectCallback: () => new Promise(() => ({})),
  getAccessTokenSilently: () => new Promise<any>((res) => res('')),
  logout: () => new Promise(() => ({})),
  loginWithPopup: () => new Promise(() => ({})),
  getAccessTokenWithPopup: () => new Promise(() => ({})),
  getIdTokenClaims: () => new Promise(() => ({})),
  loginWithRedirect: () => new Promise(() => ({})),
  sendMagicLinkEmail: () => new Promise(() => ({})),
  hasAuth0Cookie: true,
};

export const useAuth = (): CustomAuthHook => {
  const params = useAuth0() as CustomAuthHook;
  params.sendMagicLinkEmail = sendMagicLinkEmail;
  params.hasAuth0Cookie = !IS_SSR && document.cookie.includes(AUTH0_COOKIE_KEY);

  if (IS_DEMO) {
    return {
      ...commonFakeUseAuth0HookProperties,
      user: {
        email: DEMO_USER_EMAIL,
      },
    };
  }

  return params;
};
