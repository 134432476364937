import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MyPlatePlugin,
} from '@frond/shared';

import { applyMark } from '../ComposerToolbar';

export const createInputEventPlugin = (): MyPlatePlugin => ({
  key: 'inputEvent',
  handlers: {
    onDOMBeforeInput: (editor) => {
      return (event) => {
        const inputType = (event as InputEvent).inputType;

        switch (inputType) {
          case 'formatBold': {
            applyMark(MARK_BOLD, editor);
            return true;
          }
          case 'formatItalic': {
            applyMark(MARK_ITALIC, editor);
            return true;
          }
          case 'formatUnderlined': {
            applyMark(MARK_UNDERLINE, editor);
            return true;
          }
          default: {
            return false;
          }
        }
      };
    },
  },
});
