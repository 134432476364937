import * as React from "react";
import { SVGProps } from "react";
const SvgBuild = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.056 2.913a.625.625 0 0 0-.284.065l-3.038 1.45-1.865-.94a1.463 1.463 0 0 0-1.792.388L6.48 7.103c-.506.63-.019 1.647.79 1.647h4.141c.684 0 1.283-.484 1.426-1.152v-.001l.437-2.041 3.038-1.45a.625.625 0 0 0-.255-1.193Zm-5.82 1.67c.024.003.047.01.07.021l1.708.861-.4 1.87a.203.203 0 0 1-.204.165H7.764l2.286-2.84a.203.203 0 0 1 .187-.077ZM6.876 10c-.568 0-1.042.473-1.042 1.042v2.291H3.125a1.05 1.05 0 0 0-1.042 1.042v2.5a1.05 1.05 0 0 0 1.042 1.042h13.75a1.05 1.05 0 0 0 1.042-1.042v-2.5a1.05 1.05 0 0 0-1.042-1.042h-2.708v-2.291c0-.569-.474-1.042-1.042-1.042h-6.25Zm.208 1.25h5.834v2.083H7.083V11.25Zm-3.75 3.333h6.042v2.084H3.333v-2.084Zm7.292 0h6.042v2.084h-6.042v-2.084Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgBuild;
