import * as React from "react";

function SvgPanda(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.788 14.662a.626.626 0 01-.532-.3 14.475 14.475 0 00-6.768-5.775.625.625 0 01-.375-.7 5.938 5.938 0 117.893 6.738.627.627 0 01-.218.037z"
        fill="#212121"
      />
      <path
        d="M26.463 7.65a15.756 15.756 0 016.562 5.625 4.687 4.687 0 10-6.562-5.625z"
        fill="#424242"
      />
      <path
        d="M20 37.5a15.625 15.625 0 110-31.25 15.625 15.625 0 010 31.25z"
        fill="#212121"
      />
      <path
        d="M20 7.5a14.375 14.375 0 100 28.748A14.375 14.375 0 0020 7.5z"
        fill="#E0E0E0"
      />
      <path
        d="M7.213 14.662a.627.627 0 01-.22-.037 5.938 5.938 0 117.895-6.738.625.625 0 01-.375.7 14.475 14.475 0 00-6.77 5.788.625.625 0 01-.53.287z"
        fill="#212121"
      />
      <path
        d="M9.063 4.375a4.681 4.681 0 00-2.088 8.875 15.756 15.756 0 016.563-5.625 4.713 4.713 0 00-4.475-3.25z"
        fill="#424242"
      />
      <path
        d="M20 29.481c-.497 0-.974-.198-1.325-.55l-1.356-1.356a1.876 1.876 0 011.325-3.2h2.712a1.875 1.875 0 011.325 3.2l-1.356 1.356c-.351.352-.828.55-1.325.55z"
        fill="#212121"
      />
      <path
        d="M18.644 25.625a.626.626 0 00-.443 1.069l1.356 1.356a.625.625 0 00.881 0l1.356-1.356a.626.626 0 00-.437-1.069h-2.713z"
        fill="#424242"
      />
      <path
        d="M20 32.694c-1.36-.001-2.7-.35-3.887-1.013l-2.044-1.137a.63.63 0 11.625-1.094l2.043 1.137a6.706 6.706 0 006.557 0l2.043-1.137a.63.63 0 01.625 1.094L23.92 31.68A7.989 7.989 0 0120 32.694zM18.988 17.2a3.638 3.638 0 00-5-1.169l-3.238 1.994a5.625 5.625 0 00-2.625 4.731V24.7a2.125 2.125 0 003.3 1.769l6.494-4.332a3.606 3.606 0 001.069-4.937z"
        fill="#212121"
      />
      <path
        d="M17.925 17.856a2.368 2.368 0 01-.7 3.238l-6.494 4.33a.876.876 0 01-1.356-.724v-1.944a4.375 4.375 0 012.05-3.675l3.237-1.994a2.381 2.381 0 013.263.763"
        fill="#424242"
      />
      <path
        d="M29.75 26.825c-.418 0-.827-.124-1.175-.356l-6.494-4.332A3.613 3.613 0 0121.02 17.2a3.638 3.638 0 015-1.169l3.237 1.994a5.625 5.625 0 012.619 4.731V24.7a2.125 2.125 0 01-2.125 2.125z"
        fill="#212121"
      />
      <path
        d="M24.094 16.744a2.375 2.375 0 00-1.325 4.375l6.493 4.331a.875.875 0 001.363-.75v-1.944a4.338 4.338 0 00-2.05-3.675l-3.238-1.994a2.363 2.363 0 00-1.243-.343z"
        fill="#424242"
      />
      <path
        d="M16.25 20h-.625a.624.624 0 110-1.25h.625a.624.624 0 110 1.25zm8.125 0h-.625a.624.624 0 110-1.25h.625a.624.624 0 110 1.25z"
        fill="#E0E0E0"
      />
    </svg>
  );
}

export default SvgPanda;
