import { x } from '@xstyled/styled-components';

import { Skeleton } from '../../../../common/components/Skeleton';
import { Text } from '../../../../common/components/Text';
import { MemberItemLayout } from './MemberItemLayout';

export const MemberItemSkeleton = () => (
  <MemberItemLayout
    menu={
      <Text flex={1} variant="xs">
        <Skeleton width={50} />
      </Text>
    }
  >
    <x.div display="flex" spaceX={3}>
      <Skeleton width={32} height={32} />
      <div>
        <Text variant="sm-semibold">
          <Skeleton width={50} />
        </Text>
        <Text variant="xs" maxW={275}>
          <Skeleton width={100} />
        </Text>
      </div>
    </x.div>
  </MemberItemLayout>
);
