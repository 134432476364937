import { x } from '@xstyled/styled-components';
import React, { FC } from 'react';

import { VideoType } from '../../../../../generated/graphql-request-api-sdk';
import { Toast } from '../../../common/components/Toast';
import { Visibility } from '../../../video/components/VideoRecorder/VideoLayout';
import { VideoRecorderErrorWrapper } from '../../../video/components/VideoRecorderErrorWrapper';
import {
  recorderIsInRecordContext,
  recorderIsInReviewContext,
  recorderIsInUploadContext,
  useVideoRecorderState,
  VideoRecorderStates,
} from '../../../video/hooks/useVideoRecorderState';
import { QuestionsVideoRecorderRecordView } from './QuestionsVideoRecorderRecordView';
import { QuestionsVideoRecorderReviewView } from './QuestionsVideoRecorderReviewView';

const { RECORDING, PAUSED } = VideoRecorderStates;

const UPLOAD_ERROR_MESSAGE =
  "Something went wrong, the media couldn't be uploaded";
/**
 * Web video recorder to answer a Question
 * You probably want to use QuestionsVideoCaptureContext instead of using this directly
 */
export const QuestionsVideoRecorder: FC<{
  onUploadComplete: (videoId: string) => void;
  onDismiss: () => void;
  loading?: boolean;
  metadata?: { context?: string };
  addMediaButton?: React.ReactElement;
}> = ({ onUploadComplete, onDismiss, loading, metadata, addMediaButton }) => {
  const recorder = useVideoRecorderState({
    videoType: VideoType.Profile,
    showCountdown: true,
    onUploadComplete,
    metadata,
  });

  return (
    <x.div w="100%" style={{ aspectRatio: '1' }}>
      {/*
       * Review a recorded video
       * Reusing the existing review view until FR-2310 is implemented
       */}
      <Visibility hidden={!recorderIsInReviewContext(recorder.state.value)}>
        {recorder.state.value === VideoRecorderStates.UPLOAD_FAILED && (
          <Toast variant="error" message={UPLOAD_ERROR_MESSAGE} />
        )}
        <QuestionsVideoRecorderReviewView
          src={recorder.getVideoReviewObjectURL() || ''}
          onCanPlay={recorder.handleReviewVideoCanPlay}
          onClickRestart={recorder.handleRestartRecordingRequest}
          onClickSave={recorder.handleSaveRecordingRequest}
          uploadContext={recorderIsInUploadContext(recorder.state.value)}
          percentUploaded={recorder.state.context.percentUploaded}
          loading={
            loading || recorder.state.value === VideoRecorderStates.UPLOADING
          }
          addMediaButton={addMediaButton}
          muted={!recorderIsInReviewContext(recorder.state.value)}
        />
      </Visibility>

      {/*
       * Record a new video
       */}
      <Visibility hidden={!recorderIsInRecordContext(recorder.state.value)}>
        <VideoRecorderErrorWrapper
          state={recorder.state.value}
          onRequestPermissions={recorder.handleRequestPermissions}
          onDismiss={onDismiss}
        >
          <QuestionsVideoRecorderRecordView
            countdown={recorder.countdownValue}
            stream={recorder.state.context.cameraStream}
            recording={recorder.state.value === RECORDING}
            paused={recorder.state.value === PAUSED}
            activeVideoInputDeviceId={recorder.getActiveVideoDeviceId()}
            activeAudioInputDeviceId={recorder.getActiveAudioDeviceId()}
            onSelectVideoInputDevice={recorder.handleSelectVideoDevice}
            onSelectAudioInputDevice={recorder.handleSelectAudioDevice}
            onClickRecord={
              [RECORDING, PAUSED].includes(recorder.state.value)
                ? recorder.handleStopRecordingRequest
                : recorder.handleStartCountdownRequest
            }
            onClickPause={
              recorder.state.value === PAUSED
                ? recorder.handleResumeRecordingRequest
                : recorder.handlePauseRecordingRequest
            }
            onTimeElapsed={recorder.handleStopRecordingRequest}
            onCanPlay={recorder.handleLiveVideoCanPlay}
          />
        </VideoRecorderErrorWrapper>
      </Visibility>
    </x.div>
  );
};
