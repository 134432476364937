import * as React from "react";

function SvgElephant(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 36.875a2.969 2.969 0 01-1.1-.206 3.126 3.126 0 01-2.025-2.919V19.375a8.469 8.469 0 01-5.3-8.262 8.438 8.438 0 1111.55 8.262V33.75A3.125 3.125 0 0120 36.875z"
        fill="#212121"
      />
      <path
        d="M20 4.375h-.294a7.256 7.256 0 00-6.875 6.813 7.213 7.213 0 004.875 7.168.625.625 0 01.425.594v14.8a1.875 1.875 0 103.75 0V18.956a.625.625 0 01.425-.593A7.188 7.188 0 0020 4.375z"
        fill="#757575"
      />
      <path
        d="M17.5 16.875a.623.623 0 01-.444-.181l-1.25-1.25a.625.625 0 01.882-.882l1.25 1.25a.626.626 0 01-.438 1.063zm5 0a.624.624 0 01-.444-1.069l1.25-1.25a.625.625 0 01.882.882l-1.25 1.25a.625.625 0 01-.438.187z"
        fill="#212121"
      />
      <path
        d="M13.125 25a10 10 0 110-20c.595 0 1.19.054 1.775.162a.625.625 0 01.306 1.088 7.137 7.137 0 00-2.381 4.956 7.212 7.212 0 004.875 7.169.625.625 0 01.425.594v4.337a.625.625 0 01-.331.55A10.056 10.056 0 0113.125 25z"
        fill="#212121"
      />
      <path
        d="M13.125 6.25a8.75 8.75 0 103.75 16.656v-3.531a8.468 8.468 0 01-5.3-8.262 8.318 8.318 0 011.875-4.875l-.325.012z"
        fill="#BDBDBD"
      />
      <path
        d="M26.875 24.688a10.056 10.056 0 01-4.669-1.157.624.624 0 01-.331-.55v-4.025a.625.625 0 01.425-.593 7.177 7.177 0 002.244-12.37.626.626 0 01.268-1.093 10.002 10.002 0 114.063 19.588 9.951 9.951 0 01-2 .2z"
        fill="#212121"
      />
      <path
        d="M23.125 22.594a8.794 8.794 0 005.5.668 8.75 8.75 0 00-1.75-17.325h-.569a8.43 8.43 0 01-3.181 13.438v3.219z"
        fill="#BDBDBD"
      />
      <path
        d="M17.5 33.75h-6.25a.625.625 0 01-.625-.625v-8.75a.624.624 0 01.181-.444l.157-.156a.626.626 0 01.55-.175 8.75 8.75 0 005.693-.863.625.625 0 01.919.55v9.838a.625.625 0 01-.625.625z"
        fill="#212121"
      />
      <path d="M11.875 32.5h5v-8.231a10 10 0 01-5 .625V32.5z" fill="#9E9E9E" />
      <path
        d="M20 36.875h-1.563a.625.625 0 010-1.25c.141.001.28-.03.407-.094a.626.626 0 01.518 0c.205.071.422.103.638.094a.625.625 0 110 1.25z"
        fill="#212121"
      />
      <path
        d="M21.563 36.875H20a.625.625 0 110-1.25c.214 0 .426-.04.625-.119a.624.624 0 01.519 0 .894.894 0 00.406.094.625.625 0 010 1.25l.012.025zm7.187-3.125H22.5a.625.625 0 01-.625-.625V22.981a.625.625 0 01.919-.55 8.787 8.787 0 005.831.838.624.624 0 01.75.625v9.231a.625.625 0 01-.625.625z"
        fill="#212121"
      />
      <path
        d="M23.125 32.5h5v-7.887a10.186 10.186 0 01-5-.625V32.5z"
        fill="#9E9E9E"
      />
      <path
        d="M18.438 36.875h-8.125a2.188 2.188 0 010-4.375H17.5a.624.624 0 01.625.625v.625a1.875 1.875 0 001.25 1.75.625.625 0 01.069 1.144 2.151 2.151 0 01-1.006.231z"
        fill="#212121"
      />
      <path
        d="M10.313 33.75a.938.938 0 000 1.875H17.5a3.125 3.125 0 01-.625-1.875h-6.563z"
        fill="#616161"
      />
      <path
        d="M29.688 36.875h-8.125a2.15 2.15 0 01-.981-.231.625.625 0 01.106-1.157.656.656 0 01.156-.068l.069-.038a1.825 1.825 0 00.794-.875v-.069a.965.965 0 00.043-.118 2.21 2.21 0 00.082-.388v-.806a.625.625 0 01.625-.625h7.23a2.187 2.187 0 11.012 4.375h-.011z"
        fill="#212121"
      />
      <path
        d="M22.5 35.625h7.188a.938.938 0 000-1.875h-6.563v.288a3.517 3.517 0 01-.131.656c-.044.118-.075.2-.107.268a1.75 1.75 0 01-.13.275c-.076.136-.162.266-.257.388z"
        fill="#616161"
      />
    </svg>
  );
}

export default SvgElephant;
