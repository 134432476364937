import { useApolloClient } from '@apollo/client';
import { SHORT_ID_REGEX } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  ShortIdAvailableDocument,
  useShortIdSuggestionQuery,
} from '../../../../../../generated/types-and-hooks';
import { BASE_URL } from '../../../../../config/constants';
import { Input } from '../../../../common/components/Input';
import { SetupAvailabilityLabel } from '../SetupAvailabilityLabel';

export const OrganizationFormShortId: React.FC = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const apollo = useApolloClient();

  const name = form.watch('name');
  const shortId = form.watch('shortId');

  const { data } = useShortIdSuggestionQuery({
    variables: {
      name: name || '',
    },
    skip: !name,
  });

  useEffect(() => {
    if (
      data?.shortIdSuggestion &&
      data?.shortIdSuggestion.__typename === 'OrganizationShortId' &&
      shortId !== data.shortIdSuggestion.shortId &&
      form.formState.dirtyFields.name &&
      !form.formState.dirtyFields.shortId &&
      !form.formState.defaultValues?.shortId
    ) {
      form.setValue('shortId', data?.shortIdSuggestion.shortId, {
        shouldValidate: true,
      });
    }
  }, [data, form, shortId]);

  /**
   * Tiny component for showing available / not available / invalid status
   */
  const renderAvailabilityLabel = () => {
    if (!shortId || shortId === form.formState.defaultValues?.shortId) return;

    if (form.formState.errors.shortId) {
      return (
        <SetupAvailabilityLabel success={false}>
          {form.formState.errors.shortId?.type === 'availability'
            ? t('unavailable')
            : t('invalid')}
        </SetupAvailabilityLabel>
      );
    }
    return (
      <SetupAvailabilityLabel success>{t('available')}</SetupAvailabilityLabel>
    );
  };

  /**
   * When the user changes the shortId,
   * validate server side whether it's available
   */
  const checkShortIdAvailability = useMemo(() => {
    return async (shortId: string) => {
      try {
        const result = await apollo.query({
          query: ShortIdAvailableDocument,
          variables: { shortId },
        });
        return result.data.shortIdAvailable;
      } catch (e) {
        return false;
      }
    };
  }, [apollo]);

  return (
    <x.div display="flex" flexDirection="column" spaceY={2}>
      <Input
        label={t('community_link')}
        prefix={`${new URL(BASE_URL).host}/`}
        placeholder={shortId}
        {...form.register('shortId', {
          validate: {
            pattern: (value: string) => {
              return new RegExp(SHORT_ID_REGEX).test(value);
            },
            availability: async (value: string) => {
              if (form.formState.defaultValues?.shortId === value) return true;
              return await checkShortIdAvailability(value);
            },
          },
        })}
        fullWidth
      />
      {renderAvailabilityLabel()}
    </x.div>
  );
};
