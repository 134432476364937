import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import { GroupWithMembersFragment } from '../../../../generated/graphql-request-api-sdk';
import { usePublishCourseMutation } from '../../../../generated/types-and-hooks';
import { Alert } from '../../common/components/Alert';
import { Button } from '../../common/components/Button';
import { Text } from '../../common/components/Text';
import { GroupNewPostButton } from './GroupNewPostButton';

export const GroupDraftModeCard = ({
  group,
}: {
  group: GroupWithMembersFragment;
}) => {
  const { t } = useTranslation(['groups', 'common']);
  const [showPublishAlert, setShowPublishAlert] = useState(false);

  const [publishCourse] = usePublishCourseMutation({
    variables: {
      input: {
        courseId: group.id,
      },
    },
    optimisticResponse: {
      publishCourse: {
        __typename: 'Group',
        id: group.id,
        isDraft: false,
      },
    },
  });

  return (
    <x.div p={4} borderRadius={{ 'sm-2': 'md-lg' }} bg="gray.50" spaceY={3}>
      <x.div spaceY={2}>
        <Text variant="md-semibold" color="brand.300">
          {t('groups.course_draft_mode_heading', {
            ns: 'groups',
          })}
        </Text>
        <Text>
          {t('groups.course_draft_mode_description', {
            ns: 'groups',
          })}
        </Text>
      </x.div>
      <x.div spaceX={3}>
        <Button
          label={t('publish_course', {
            ns: 'common',
          })}
          onClick={() => setShowPublishAlert(true)}
        />
        <GroupNewPostButton
          group={group}
          label={t('add_lesson', {
            ns: 'common',
          })}
          variant="secondary"
        />
      </x.div>

      {showPublishAlert && (
        <Alert
          headingText={t('publish_course', {
            ns: 'common',
          })}
          descriptionText={t('publish_course_description', {
            ns: 'common',
          })}
          onCancelClick={() => setShowPublishAlert(false)}
          onSubmitClick={() => {
            publishCourse();
            setShowPublishAlert(false);
          }}
          cancelButtonText={t('cancel', {
            ns: 'common',
          })}
          submitButtonText={t('publish', {
            ns: 'common',
          })}
        />
      )}
    </x.div>
  );
};
