import * as React from "react";

function SvgGorilla(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.725 36.875h-9.45a12.162 12.162 0 01-12.15-12.15v-2.5c-.004-2.792.944-5.5 2.688-7.681a19.919 19.919 0 003.493-6.606 6.788 6.788 0 016.519-4.813h8.35a6.875 6.875 0 016.55 4.819 20.325 20.325 0 003.5 6.606 12.306 12.306 0 012.65 7.656v2.5a12.162 12.162 0 01-12.15 12.169z"
        fill="#212121"
      />
      <path
        d="M15.825 4.375a5.543 5.543 0 00-5.319 3.931 21.181 21.181 0 01-3.712 7.019 11.013 11.013 0 00-2.419 6.881v2.5a10.912 10.912 0 0010.9 10.919h9.45a10.912 10.912 0 0010.9-10.9v-2.5a11.05 11.05 0 00-2.413-6.875A21.591 21.591 0 0129.5 8.356a5.514 5.514 0 00-.594-1.293 5.625 5.625 0 00-4.731-2.688h-8.35z"
        fill="#616161"
      />
      <path
        d="M31.625 12.412a.625.625 0 01-.55-.33 21.556 21.556 0 01-1.575-3.75 5.514 5.514 0 00-.593-1.295.625.625 0 01.187-.856 3.363 3.363 0 011.844-.556 3.437 3.437 0 01.837 6.769l-.15.018z"
        fill="#212121"
      />
      <path
        d="M30.307 6.969c.155.314.284.64.387.975.333 1.055.75 2.081 1.25 3.068a2.187 2.187 0 00-1.625-4.05l-.012.007z"
        fill="#BDBDBD"
      />
      <path
        d="M8.375 12.412h-.15a3.438 3.438 0 01.837-6.787 3.363 3.363 0 011.844.537.625.625 0 01.187.857c-.25.402-.447.835-.587 1.287a21.55 21.55 0 01-1.575 3.75.625.625 0 01-.556.357z"
        fill="#212121"
      />
      <path
        d="M9.063 6.875a2.187 2.187 0 00-.988 4.138c.5-.99.918-2.018 1.25-3.075a6.62 6.62 0 01.388-.975 2.181 2.181 0 00-.65-.088z"
        fill="#BDBDBD"
      />
      <path
        d="M20 34.375a7.5 7.5 0 01-6.075-3.125 7.5 7.5 0 01-1.363-5.381 6.564 6.564 0 01-.83-12.098A6.563 6.563 0 0120 15.844a6.556 6.556 0 117.437 10.05 7.5 7.5 0 01-1.369 5.356A7.5 7.5 0 0120 34.375z"
        fill="#212121"
      />
      <path
        d="M14.687 14.375a5.312 5.312 0 00-1.25 10.469.625.625 0 01.463.744 6.057 6.057 0 00-.15 1.287 6.175 6.175 0 001.187 3.663 6.25 6.25 0 0010.119 0 6.194 6.194 0 001.056-4.957.625.625 0 01.463-.743 5.313 5.313 0 10-6.02-7.5.624.624 0 01-1.118 0 5.288 5.288 0 00-4.75-2.963z"
        fill="#BCAAA4"
      />
      <path
        d="M20 34.375a7.5 7.5 0 01-6.075-3.125.624.624 0 01-.05-.625 6.875 6.875 0 0112.244 0 .625.625 0 01-.05.625A7.5 7.5 0 0120 34.375z"
        fill="#212121"
      />
      <path
        d="M15.175 30.85a6.25 6.25 0 009.637 0 5.625 5.625 0 00-9.637 0z"
        fill="#A1887F"
      />
      <path
        d="M18.75 25a.625.625 0 01-.444-.181l-.625-.625a.625.625 0 01.882-.881l.625.625A.626.626 0 0118.75 25zm2.5 0a.624.624 0 01-.444-1.069l.625-.625a.625.625 0 01.882.881l-.625.625a.625.625 0 01-.438.188zM25 21.25a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75z"
        fill="#212121"
      />
      <path d="M25 20a.625.625 0 100-1.25.625.625 0 000 1.25z" fill="#757575" />
      <path
        d="M15 21.25a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75z"
        fill="#212121"
      />
      <path d="M15 20a.625.625 0 100-1.25.625.625 0 000 1.25z" fill="#757575" />
    </svg>
  );
}

export default SvgGorilla;
