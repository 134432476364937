import { x } from '@xstyled/styled-components';
import { useContext } from 'react';

import { QuestionsVideoRecorder } from '../../questions/components/QuestionsVideoRecorder';
import { PostComposerStateContext } from './PostComposer';

export const PostComposerVideoCaptureSurface = () => {
  const { postComposerService } = useContext(PostComposerStateContext);

  const handleUploadComplete = (videoId: string) => {
    postComposerService.send({
      type: 'ADD_MEDIA',
      media: {
        id: videoId,
      },
    });
    postComposerService.send({
      type: 'REVIEW_POST',
    });
  };

  return (
    <x.div>
      <QuestionsVideoRecorder
        onDismiss={() =>
          postComposerService.send({
            type: 'REVIEW_POST',
          })
        }
        loading={false}
        onUploadComplete={handleUploadComplete}
      />
    </x.div>
  );
};
