import styled, { x } from '@xstyled/styled-components';
import React from 'react';

import { Emoji } from '../../common/components/Emoji';
import { Heading } from '../../common/components/Heading';
import { Link } from '../../common/components/Link';
import { NextLink } from '../../common/components/NextLink';
import { Text } from '../../common/components/Text';
import analytics from '../../common/utils/analytics';

export interface ErrorPageProps {
  /**
   * Emoji used in heading
   */
  emojiUnicode: string;
  /**
   * Error code used in heading
   */
  errorCode: string;
  /**
   * Error message to display to the user
   */
  errorMessage: string;
  /**
   * label for action / link
   */
  actionLabel?: string;
  /**
   * sign out
   */
  action?: () => void;
}

const StyledNotFoundContainer = styled.div`
  margin: 64 0;
  text-align: center;
`;

/**
 * Utility component to display a custom error page
 */
export const ErrorPage: React.FC<ErrorPageProps> = ({
  actionLabel = 'Take me home →',
  ...props
}) => {
  return (
    <StyledNotFoundContainer>
      <x.div display="flex" justifyContent="center" mb={3}>
        <Emoji emojiUnicode={props.emojiUnicode} size={120} />
      </x.div>

      <Heading variant={{ _: '3xl', md: '4xl' }} mb={3}>
        {props.errorCode}
      </Heading>
      <Text>{props.errorMessage}</Text>
      {props.action ? (
        <Link
          onClick={() => props.action?.()}
          metadata={{
            event: analytics.events.ERROR_REDIRECT,
            context: 'general_error',
          }}
        >
          <Text variant="md-semibold">{actionLabel}</Text>
        </Link>
      ) : (
        <NextLink href="/">
          <Text variant="md-semibold">{actionLabel}</Text>
        </NextLink>
      )}
    </StyledNotFoundContainer>
  );
};
