import { x } from '@xstyled/styled-components';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  refetchGroupUnseenPostsQuery,
  refetchNotificationCountsQuery,
  refetchPostQuery,
  useAddPostViewMutation,
  ViewLevels,
  ViewLocations,
} from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';

type PostViewMutatorProps = {
  post: {
    id: string;
    group: {
      id: string;
    };
  };
  viewLevel: ViewLevels;
  viewLocation: ViewLocations;
  delay?: number;
};

export const PostViewMutator: FC<PropsWithChildren<PostViewMutatorProps>> = ({
  post,
  children,
  viewLevel,
  viewLocation,
  delay,
}) => {
  const { ref, inView } = useInView();
  const { organization } = useOrganization();

  const [mutateAddPostView] = useAddPostViewMutation({
    variables: {
      input: {
        viewedId: post.id,
        level: viewLevel,
        location: viewLocation,
      },
    },
    refetchQueries: [
      refetchNotificationCountsQuery({ organizationId: organization.id }),
      refetchPostQuery({ id: post.id }),
      refetchGroupUnseenPostsQuery({ id: post.group.id }),
    ],
  });

  useEffect(() => {
    if (!inView || !document.hasFocus()) {
      return;
    }

    const timer = setTimeout(mutateAddPostView, delay || 0);

    return () => clearTimeout(timer);
  }, [inView, mutateAddPostView, delay]);

  return <x.div ref={ref}>{children}</x.div>;
};
