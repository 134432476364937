import {
  AdminSubscriptionPlanId,
  AdminSubscriptionPlanPriceLookupKey,
} from '../../../../generated/types-and-hooks';

export type DisplayFeatureI18nKey =
  | 'community_templates'
  | 'unlimited_groups'
  | 'direct_messaging'
  | 'privacy_controls'
  | 'private_groups'
  | 'advanced_search'
  | 'community_digests'
  | '50_members'
  | '1_admin'
  | '10mb_image_size_limit'
  | '100mb_video_size_limit'
  | 'unlimited_members'
  | '2_admins'
  | '15mb_image_size_limit'
  | '500mb_video_size_limit'
  | 'polls'
  | 'custom_member_roles'
  | '10_admins'
  | '20mb_image_size_limit'
  | '1gb_video_size_limit'
  | 'community_insights'
  | 'priority_support'
  | '5pct_paywall_fee'
  | '2pct_paywall_fee'
  | '1pct_paywall_fee'
  | 'everything_in_free'
  | 'everything_in_plus'
  | 'courses'
  | 'events'
  | 'custom_domain';

export type DisplayPlanDefinition = {
  planId: AdminSubscriptionPlanId | typeof PLAN_FREE_ID; // free doesn't really exist as a plan
  i18nKey: 'free' | 'plus' | 'pro';
  priceLookupKeys?: {
    yearly: AdminSubscriptionPlanPriceLookupKey;
    monthly: AdminSubscriptionPlanPriceLookupKey;
  };
  features: {
    inherited?: DisplayFeatureI18nKey[];
    introduced: DisplayFeatureI18nKey[];
  };
  inheritedFeatures?: string[];
};

export const PLAN_FREE_ID = 'plan:free';
export const PLAN_FREE: DisplayPlanDefinition = {
  planId: PLAN_FREE_ID,
  i18nKey: 'free',
  features: {
    introduced: [
      'unlimited_members',
      '1_admin',
      '10mb_image_size_limit',
      '100mb_video_size_limit',
      '5pct_paywall_fee',
      'community_templates',
      'unlimited_groups',
      'direct_messaging',
      'privacy_controls',
      'private_groups',
      'advanced_search',
      'community_digests',
    ],
  },
};

export const PLAN_PLUS: DisplayPlanDefinition = {
  planId: AdminSubscriptionPlanId.Plus,
  i18nKey: 'plus',
  priceLookupKeys: {
    monthly: AdminSubscriptionPlanPriceLookupKey.PlusMonthlyUsd,
    yearly: AdminSubscriptionPlanPriceLookupKey.PlusYearlyUsd,
  },
  features: {
    inherited: ['everything_in_free'],
    introduced: [
      '2_admins',
      '15mb_image_size_limit',
      '500mb_video_size_limit',
      '2pct_paywall_fee',
      'polls',
      'custom_member_roles',
      'events',
      'custom_domain',
    ],
  },
};

export const PLAN_PRO: DisplayPlanDefinition = {
  planId: AdminSubscriptionPlanId.Pro,
  i18nKey: 'pro',
  priceLookupKeys: {
    monthly: AdminSubscriptionPlanPriceLookupKey.ProMonthlyUsd,
    yearly: AdminSubscriptionPlanPriceLookupKey.ProYearlyUsd,
  },
  features: {
    inherited: ['everything_in_plus'],
    introduced: [
      '10_admins',
      '20mb_image_size_limit',
      '1gb_video_size_limit',
      '1pct_paywall_fee',
      'community_insights',
      'courses',
      'priority_support',
    ],
  },
};

export const PLANS = [PLAN_FREE, PLAN_PLUS, PLAN_PRO] as const;
