import { ErrorPage } from '../../auth/views/ErrorPage';

export const Error404View: React.FC = () => {
  return (
    <ErrorPage
      errorMessage="Whoops, looks like this page got lost somewhere"
      errorCode="404"
      emojiUnicode="👨‍🚀"
    />
  );
};
