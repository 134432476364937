import { centsToDollars, routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { format, parseISO } from 'date-fns';
import { Trans, useTranslation } from 'next-i18next';

import { NotificationQuery } from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Button } from '../../common/components/Button';
import { Text } from '../../common/components/Text';
import { InboxNotificationCommon } from './InboxNotificationCommon';

const InboxNotificationMemberSubcriptionAction: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation('inbox');
  const { otherUser } = notification;
  const { organization } = useOrganization();

  if (!otherUser) return <></>;

  return (
    <x.div display="flex" spaceX={2}>
      <Button
        variant="secondary"
        label={t('inbox.message_name', {
          name: otherUser.firstName || otherUser.username,
        })}
        href={routes.groups
          .organization(organization.shortId)
          .newMessage(otherUser.username)}
      />
      <Button
        variant="secondary"
        label={t('inbox.view_paywall_details')}
        href={routes.settings.organization(organization.shortId).paywall()}
      />
    </x.div>
  );
};

export const InboxNotificationMemberSubscriptionCreated: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation('inbox');
  const { organization } = useOrganization();
  const { otherUser, memberSubscription, memberSubscriptionPlan } =
    notification;

  if (!memberSubscriptionPlan || !memberSubscription) return <></>;

  if (otherUser) {
    return (
      <InboxNotificationCommon
        icon="badge"
        title={t('inbox.new_subscriber')}
        description={
          <Trans
            i18nKey="inbox.new_subscriber_description"
            t={t}
            values={{
              firstName: otherUser.firstName || otherUser.username,
              email: otherUser.email,
              subscriptionPlanName: memberSubscriptionPlan.name,
            }}
            components={{
              strong: <Text as="span" variant="md-semibold" />,
            }}
          />
        }
        action={
          <InboxNotificationMemberSubcriptionAction
            notification={notification}
          />
        }
      />
    );
  } else {
    return (
      <InboxNotificationCommon
        icon="badge"
        title={t('inbox.subscription_confirmed')}
        description={
          <Trans
            i18nKey="inbox.subscription_purchased_description"
            t={t}
            values={{
              organizationName: organization.name,
              expirationDate: format(
                parseISO(memberSubscription.expiresAt),
                'MMM d, yyyy'
              ),
            }}
            components={{
              strong: <Text as="span" variant="md-semibold" />,
            }}
          />
        }
        action={
          <x.div display="flex" spaceX={2}>
            <Button
              variant="secondary"
              label={t('inbox.edit_subscriptions')}
              href={routes.settings.memberships()}
            />
            <Button
              variant="secondary"
              label={t('inbox.view_billing')}
              href={routes.settings.billing()}
            />
          </x.div>
        }
      />
    );
  }
};

export const InboxNotificationMemberSubscriptionGifted: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation('inbox');
  const { organization } = useOrganization();
  const { otherUser, memberSubscriptionPlan } = notification;

  if (!otherUser || !memberSubscriptionPlan) return <></>;

  return (
    <InboxNotificationCommon
      icon="badge"
      title={t('inbox.subscription_confirmed')}
      description={
        <Trans
          i18nKey="inbox.subscription_gifted_description"
          t={t}
          values={{
            firstName: otherUser.firstName || otherUser.username,
            subscriptionPlanName: memberSubscriptionPlan.name,
            organizationName: organization.name,
          }}
          components={{
            strong: <Text as="span" variant="md-semibold" />,
          }}
        />
      }
      action={<></>}
    />
  );
};

export const InboxNotificationMemberSubscriptionCancelled: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation('inbox');
  const { otherUser, memberSubscription, memberSubscriptionPlan } =
    notification;

  if (!otherUser || !memberSubscription || !memberSubscriptionPlan)
    return <></>;

  return (
    <InboxNotificationCommon
      title={t('inbox.cancelled_subscription')}
      description={t('inbox.cancelled_subscription_description', {
        name: otherUser.firstName || otherUser.username,
        subscriptionPlanName: memberSubscriptionPlan.name,
        expirationDate: format(
          parseISO(memberSubscription.expiresAt),
          'MMM d, yyyy'
        ),
      })}
      action={
        <InboxNotificationMemberSubcriptionAction notification={notification} />
      }
    />
  );
};

export const InboxNotificationMemberSubscriptionPriceChanged: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation('inbox');
  const { memberSubscription, memberSubscriptionPlan } = notification;
  const { organization } = useOrganization();

  if (!memberSubscription || !memberSubscriptionPlan) return <></>;

  return (
    <InboxNotificationCommon
      title={t('inbox.subscription_price_changed')}
      description={t('inbox.subscription_price_changed_description', {
        organizationName: organization.name,
        subscriptionPlanName: memberSubscriptionPlan.name,
        price: centsToDollars(memberSubscriptionPlan.priceMonthlyUsd),
        expirationDate: format(
          parseISO(memberSubscription.expiresAt),
          'MMM d, yyyy'
        ),
      })}
      action={
        <Button
          variant="secondary"
          label={t('inbox.view_memberships')}
          href={routes.settings.memberships()}
        />
      }
    />
  );
};

export const InboxNotificationMemberSubscriptionExpiring: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation('inbox');
  const { memberSubscription, memberSubscriptionPlan } = notification;

  if (!memberSubscription || !memberSubscriptionPlan) return <></>;

  return (
    <InboxNotificationCommon
      title={t('inbox.subscription_expiring')}
      description={t('inbox.subscription_expiring_description', {
        subscriptionPlanName: memberSubscriptionPlan.name,
        expirationDate: format(
          parseISO(memberSubscription.expiresAt),
          'MMM d, yyyy'
        ),
      })}
      action={
        <Button
          variant="secondary"
          label={t('inbox.view_memberships')}
          href={routes.settings.memberships()}
        />
      }
    />
  );
};
