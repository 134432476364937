import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';

import {
  GroupCoreFragment,
  GroupTypes,
} from '../../../../generated/graphql-request-api-sdk';
import { PostTypes } from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Button } from '../../common/components/Button';
import { PostComposer } from '../../posts/components/PostComposer';
import { useCanViewerPostToGroup } from '../hooks/useCanViewerPostToGroup';
import { useGroupMembership } from '../hooks/useGroupMembership';

export const GroupNewPostButton: FC<{
  group: GroupCoreFragment;
  variant?: 'primary' | 'secondary';
  label?: string;
}> = ({ group, label, variant = 'primary' }) => {
  const { t } = useTranslation();
  const canPost = useCanViewerPostToGroup(group);
  const { organization } = useOrganization();
  const { userIsMember } = useGroupMembership({
    group,
    organization,
  });

  const [composerOpen, setComposerOpen] = useState(false);

  if (!canPost || !userIsMember) return null;

  return (
    <>
      <Button
        label={
          label
            ? label
            : group.groupType === GroupTypes.Course
              ? t('add_lesson')
              : t('new_post')
        }
        variant={variant}
        onClick={() => setComposerOpen(true)}
      />
      {composerOpen && (
        <PostComposer
          isOpen
          onDismiss={() => {
            setComposerOpen(false);
          }}
          group={group}
          type={
            group.groupType === GroupTypes.Course ? PostTypes.Lesson : undefined
          }
          canChangeGroup={group.groupType !== GroupTypes.Course}
        />
      )}
    </>
  );
};
