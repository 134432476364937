import * as Sentry from '@sentry/nextjs';
import { filesize } from 'filesize';
import React, { InputHTMLAttributes, useState } from 'react';

import {
  Image,
  useCreateImageMutation,
  useCreateTemporaryImageMutation,
} from '../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { useOrganizationEntitlements } from '../../organizations/hooks/useOrganizationEntitlements';
import { UploadError, uploadFile } from '../utils/cloudinary';

export const DEFAULT_MAX_FILESIZE = 10_000_000; // 10mb

type UseFileUploadReturnType = {
  loading: boolean;
  inputProps: InputHTMLAttributes<HTMLInputElement>;
  id: string;
  deleteFile: () => void;
  uploadSelectedFile: (file: File) => Promise<void>;
  onDrop: (acceptedFiles: File[]) => void;
};

export type UseFileUploadProps = {
  /**
   * Callback progress with percentage
   */
  onUploadProgress?: (progress: number) => void;
  /**
   * Callback with uploaded image ID
   */
  onUploadSuccess?: (
    item:
      | Image
      | {
          url: string;
        }
  ) => void;
  /**
   * Callback file selected
   */
  onFileSelected?: () => void;
  /**
   * Callback error on upload
   */
  onUploadFailure?: (e: Error) => void;
  /**
   * Callback delete image
   */
  onDelete?: () => void;
  /**
   * Input name
   */
  name: string;
  /**
   * Input accept property
   */
  accept?: string;
  /**
   * Callback to check the upload should be allowed
   */
  validateFiles?: (fileList: FileList) => UploadError | undefined;

  temporaryPostToken?: string;
};

type UseFileUpload = (props: UseFileUploadProps) => UseFileUploadReturnType;

type UseCommonFileUpload = (
  props: UseFileUploadProps & {
    userId: string;
    organizationId?: string;
  }
) => UseFileUploadReturnType;

export const usePublicFileUpload: UseCommonFileUpload = ({
  userId,
  organizationId,
  onUploadSuccess,
  onUploadFailure,
  name,
  accept,
  onDelete,
  onUploadProgress,
  onFileSelected,
  temporaryPostToken,
  validateFiles,
}) => {
  const id = name;
  const [loading, setLoading] = useState(false);
  const [createFile] = useCreateImageMutation();
  const [createTemporaryFile] = useCreateTemporaryImageMutation();

  const validate =
    validateFiles ||
    ((files: FileList) => {
      for (const file of files) {
        if (file.size > DEFAULT_MAX_FILESIZE) {
          return new UploadError(
            'The file exceeds the allowed size for your community'
          );
        }
      }
    });

  const deleteFile = () => {
    onDelete?.();
  };

  const uploadSelectedFile = async (file: File) => {
    // short circuiting upload if we're not returning a value
    if (!onUploadSuccess) return;

    setLoading(true);

    try {
      const uploadedFile = await uploadFile(
        file,
        userId,
        organizationId,
        [],
        onUploadProgress
      );

      if (
        uploadedFile.resource_type === 'raw' ||
        uploadedFile.format === 'pdf' ||
        uploadedFile.resource_type === 'video'
      ) {
        onUploadSuccess({
          url: uploadedFile.secure_url,
        });
        setTimeout(() => setLoading(false), 333);
        return;
      }

      const commonInput = {
        publicId: uploadedFile.public_id,
        bytes: uploadedFile.bytes,
        format: uploadedFile.format,
        height: uploadedFile.height,
        width: uploadedFile.width,
      };
      if (temporaryPostToken) {
        const res = await createTemporaryFile({
          variables: {
            input: {
              ...commonInput,
              token: temporaryPostToken,
            },
          },
        });

        if (res.data?.createTemporaryImage) {
          onUploadSuccess(res.data.createTemporaryImage);
        } else {
          onUploadFailure?.(new Error('Failed to upload image'));
        }
      } else {
        const res = await createFile({
          variables: {
            input: {
              ...commonInput,
            },
          },
        });

        if (res.data?.createImage) {
          onUploadSuccess(res.data.createImage);
        } else {
          onUploadFailure?.(new Error('Failed to upload image'));
        }
      }
    } catch (e) {
      Sentry.captureException(e);
      onUploadFailure?.(e as Error);
    }

    setTimeout(() => setLoading(false), 333);
  };

  const onNewFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // short circuiting upload if we're not returning a value
    if (!onUploadSuccess) return;

    if (e.target?.files) {
      // short circuiting upload if we're not returning a value
      if (!onUploadSuccess) return;

      const err = validate(e.target.files);

      if (err) {
        onUploadFailure?.(err);
        return;
      }

      uploadSelectedFile(e.target?.files[0]);
      onFileSelected?.();
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryStr = reader.result;
        if (binaryStr) {
          const fileToUpload = new File([binaryStr], file.name, {
            type: file.type,
          });
          uploadSelectedFile(fileToUpload);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    // Reset value to be able to upload same file again
    (event.target as HTMLInputElement).value = '';
  };

  return {
    loading,
    onDrop,
    inputProps: {
      name,
      id,
      style: {
        width: '0.1px',
        height: '0.1px',
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1,
      },
      type: 'file',
      onClick,
      onChange: onNewFileSelected,
      hidden: true,
      accept,
    },
    id,
    deleteFile,
    uploadSelectedFile,
  };
};

export const useFileUpload: UseFileUpload = (props) => {
  const { viewer } = useViewer();
  const { organization } = useOptionalOrganization();
  const { validateFiles } = useOrganizationFileUploadValidation();

  return usePublicFileUpload({
    ...props,
    userId: viewer?.id || '',
    organizationId: organization?.id,
    validateFiles,
  });
};

export const useOrganizationFileUploadValidation = () => {
  const { quotas } = useOrganizationEntitlements();

  const validateFile = (file: File) => {
    if (file.type.startsWith('image/') && file.size > quotas.ImageUploadSize) {
      return new UploadError(
        `The image couldn’t be uploaded. The file exceeds the allowed size (${filesize(
          quotas.ImageUploadSize
        )}) for your community.`
      );
    }

    if (file.size > quotas.VideoUploadSize) {
      return new UploadError(
        `The file couldn’t be uploaded. The file exceeds the allowed size (${filesize(
          quotas.VideoUploadSize
        )}) for your community.`
      );
    }
  };

  const validateFiles = (files: FileList) => {
    for (const file of files) {
      const err = validateFile(file);
      if (err) {
        return err;
      }
    }
  };

  return { validateFile, validateFiles };
};
