import { User } from '../../../../generated/graphql-request-api-sdk';
import { useIsViewerAdmin } from '../../auth/hooks/useViewer';
import { GroupsSidebarOrganization } from '../../groups/components/GroupsSidebar/GroupsSidebar';

export const useUserCanEditGroup = (
  group: {
    createdBy: {
      id: string;
    };
  },
  organization: GroupsSidebarOrganization,
  user?: Pick<User, 'id'>
) => {
  const isViewerAdmin = useIsViewerAdmin(organization);
  if (!user) {
    return false;
  }
  return group.createdBy.id === user.id || isViewerAdmin;
};
