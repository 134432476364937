import { x } from '@xstyled/styled-components';
import { PropsWithChildren } from 'react';

export const GroupsSidebarUnreadBadge: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <x.div
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor={{ _: 'red.300', sm: 'brand.300' }}
      borderRadius="sm"
      h="1.125rem"
      minWidth="1.125rem"
      boxSizing="border-box"
      px={1}
    >
      <x.span fontSize="xs" fontWeight="semibold" as="span" color="white">
        {children}
      </x.span>
    </x.div>
  );
};
