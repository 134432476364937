import * as React from 'react';
import { SVGProps } from 'react';

const SvgDetails = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M2 19V6.5h20V19c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1Z"
      fill="url(#type=details_svg__a)"
    />
    <path
      d="M19.25 11H7.75c-.15 0-.25-.1-.25-.25v-.5c0-.15.1-.25.25-.25h11.5c.15 0 .25.1.25.25v.5c0 .15-.1.25-.25.25ZM19.25 13.5H7.75c-.15 0-.25-.1-.25-.25v-.5c0-.15.1-.25.25-.25h11.5c.15 0 .25.1.25.25v.5c0 .15-.1.25-.25.25ZM19.25 16H7.75c-.15 0-.25-.1-.25-.25v-.5c0-.15.1-.25.25-.25h11.5c.15 0 .25.1.25.25v.5c0 .15-.1.25-.25.25Z"
      fill="#0F94BF"
    />
    <path
      d="M22 5v1.5H2V5c0-.55.45-1 1-1h18c.55 0 1 .45 1 1Z"
      fill="url(#type=details_svg__b)"
    />
    <path
      d="M4.75 11h.5c.15 0 .25-.1.25-.25v-.5c0-.15-.1-.25-.25-.25h-.5c-.15 0-.25.1-.25.25v.5c0 .15.1.25.25.25ZM4.75 13.5h.5c.15 0 .25-.1.25-.25v-.5c0-.15-.1-.25-.25-.25h-.5c-.15 0-.25.1-.25.25v.5c0 .15.1.25.25.25ZM4.75 16h.5c.15 0 .25-.1.25-.25v-.5c0-.15-.1-.25-.25-.25h-.5c-.15 0-.25.1-.25.25v.5c0 .15.1.25.25.25Z"
      fill="#0F94BF"
    />
    <defs>
      <linearGradient
        id="type=details_svg__a"
        x1={6.535}
        y1={5}
        x2={15.963}
        y2={18.637}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7DD8F3" />
        <stop offset={1} stopColor="#45B0D0" />
      </linearGradient>
      <linearGradient
        id="type=details_svg__b"
        x1={2.404}
        y1={3.39}
        x2={21.375}
        y2={7.247}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0077D2" />
        <stop offset={1} stopColor="#0B59A2" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgDetails;
