// TODO: move into individual component
// See https://linear.app/baseto/issue/B-53/move-css-imports-in-appjsx-to-individual-components
import 'emoji-mart/css/emoji-mart.css';
import '@reach/dialog/styles.css';
import '@reach/tooltip/styles.css';
import '@reach/listbox/styles.css';
import '@reach/menu-button/styles.css';
import 'what-input';
import 'react-advanced-cropper/dist/style.css';

import { Auth0Provider } from '@auth0/auth0-react';
import { inspect } from '@xstate/inspect';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import { useEffect } from 'react';
import { polyfill } from 'seamless-scroll-polyfill';

import {
  AUTH0_SCOPE,
  IS_DEMO,
  IS_PRODUCTION,
  IS_SSR,
} from '../config/constants';
import { GoogleAnalytics } from '../modules/analytics/GoogleAnalytics';
import { GoogleTagManager } from '../modules/analytics/GoogleTagManager';
import { useMatomoAnalytics } from '../modules/analytics/MatomoAnalytics';
import { usePostHogAnalytics } from '../modules/analytics/PostHogAnalytics';
import UmamiAnalytics from '../modules/analytics/UmamiAnalytics';
import { MachineGuard } from '../modules/auth/components/guards/MachineGuard';
import { FrondInternalEmbedWidget } from '../modules/common/components/FrondInternalEmbedWidget';
import { NetworkGuard } from '../modules/common/components/NetworkGuard';
import { CustomApolloProvider } from '../modules/common/hooks/useApollo';
import { useBuildId } from '../modules/common/hooks/useBuildId';
import { useIsomorphicLayoutEffect } from '../modules/common/hooks/useIsomorphicLayoutEffect';
import { usePrevious } from '../modules/common/hooks/usePreviousBoolean';
import { BaseThemeProvider } from '../modules/common/theme';
import { forceSafariReflow } from '../modules/common/utils/browser';
import { NextContext, NextEnv } from '../modules/common/utils/providers';
import { DemoProvider } from '../modules/demo/DemoProvider';

if (typeof window !== 'undefined') {
  const urlParams = new URLSearchParams(window.location.search);
  const debugState = urlParams.get('debugState');
  if (debugState) {
    inspect({
      iframe: false,
    });
  }
}

!IS_SSR && polyfill();

const App: NextPage<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();

  useMatomoAnalytics();
  usePostHogAnalytics(router);

  // FR-2440 -Work around a bug with Safari 'sticking' after a route change
  useIsomorphicLayoutEffect(() => {
    router.events.on('routeChangeComplete', forceSafariReflow);
    return () => router.events.off('routeChangeComplete', forceSafariReflow);
  }, [router.events]);

  // Reload page when new build is deployed
  const buildId = useBuildId();
  const prevBuildId = usePrevious(buildId);
  useEffect(() => {
    if (prevBuildId && buildId && prevBuildId !== buildId) {
      router.reload();
    }
  }, [buildId, prevBuildId, router]);

  return (
    <>
      <Head>
        <title>Frond</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <FrondInternalEmbedWidget />
      <GoogleTagManager />
      <GoogleAnalytics />
      <UmamiAnalytics />
      <Auth0Provider
        authorizationParams={{
          scope: AUTH0_SCOPE as string,
          redirect_uri: !IS_SSR
            ? `${window.location.origin}/callback`
            : undefined,
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
        }}
        domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string}
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string}
        cacheLocation={!IS_SSR ? 'localstorage' : undefined}
        useRefreshTokens
        useRefreshTokensFallback
        sessionCheckExpiryDays={30}
        useCookiesForTransactions={!IS_SSR}
      >
        <BaseThemeProvider>
          <DemoProvider>
            <CustomApolloProvider>
              <NetworkGuard>
                <NextContext.Provider
                  value={{
                    NextEnv: process.env.NEXT_PUBLIC_NODE_ENV as NextEnv,
                  }}
                >
                  <DefaultSeo
                    dangerouslySetAllPagesToNoIndex={!IS_PRODUCTION || IS_DEMO}
                  />
                  <MachineGuard>
                    {/*// eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                    {/*// @ts-expect-error typings wrong */}
                    <Component {...pageProps} />
                  </MachineGuard>
                </NextContext.Provider>
              </NetworkGuard>
            </CustomApolloProvider>
          </DemoProvider>
        </BaseThemeProvider>
      </Auth0Provider>
    </>
  );
};

export default appWithTranslation(App);
