import * as React from "react";
import { SVGProps } from "react";
const SvgAudioOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.09 2.542a1.16 1.16 0 0 0-.782.291v.001L5.465 6.25H2.708A1.885 1.885 0 0 0 .833 8.125v3.75c0 1.028.847 1.875 1.875 1.875h2.757l3.843 3.416c.359.318.868.365 1.253.192.385-.172.689-.584.689-1.064V3.706c0-.48-.303-.891-.688-1.064a1.159 1.159 0 0 0-.472-.1Zm6.668.784a.625.625 0 0 0-.511.946c2.242 3.868 2.25 7.588 0 11.455a.625.625 0 1 0 1.08.629c2.434-4.183 2.425-8.53.001-12.711a.625.625 0 0 0-.57-.319ZM10 3.892v12.216l-3.882-3.45a.625.625 0 0 0-.415-.158H2.708a.616.616 0 0 1-.625-.625v-3.75c0-.353.273-.625.625-.625h2.995c.153 0 .3-.056.415-.158L10 3.892Zm4.898 1.1a.625.625 0 0 0-.552.922c1.441 2.76 1.441 5.412 0 8.172a.624.624 0 0 0 .742.893.623.623 0 0 0 .366-.315c1.6-3.065 1.6-6.263 0-9.328a.625.625 0 0 0-.556-.344Zm-1.91 2.09a.625.625 0 0 0-.538.892c.632 1.383.631 2.676-.003 4.059a.623.623 0 0 0 .544.889.623.623 0 0 0 .592-.367c.766-1.67.767-3.43.004-5.1a.625.625 0 0 0-.599-.373Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgAudioOn;
