import { useTranslation } from 'next-i18next';
import { FC, useState } from 'react';

import { Button } from '../../common/components/Button';
import { ProfileEditModal, ProfileEditModalProps } from './ProfileEditModal';

export type ProfileEditButtonProps = Pick<
  ProfileEditModalProps,
  'onUpdateUser'
>;

export const ProfileEditButton: FC<ProfileEditButtonProps> = ({
  onUpdateUser,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <>
      <Button
        variant="secondary"
        label={t('settings.edit_profile', {
          ns: 'settings',
        })}
        onClick={() => setShowModal(true)}
      />

      <ProfileEditModal
        isOpen={showModal}
        onDismiss={() => setShowModal(false)}
        onUpdateUser={(updatedUserData) => {
          if (onUpdateUser) onUpdateUser(updatedUserData);
          setShowModal(false);
        }}
      />
    </>
  );
};
