import { init } from '@socialgouv/matomo-next';
import { push } from '@socialgouv/matomo-next';
import { useEffect } from 'react';

const MATOMO_URL = process.env.NEXT_PUBLIC_MATOMO_URL;
const MATOMO_SITE_ID = process.env.NEXT_PUBLIC_MATOMO_SITE_ID;

export const useMatomoAnalytics = () => {
  useEffect(() => {
    if (!MATOMO_URL || !MATOMO_SITE_ID) {
      return;
    }
    init({ url: MATOMO_URL, siteId: MATOMO_SITE_ID });
  }, []);
};

export function sendMatomoEvent(name: string) {
  const arr: Array<string | number> = ['trackEvent', 'Event', name];
  push(arr);
}
