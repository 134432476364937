import * as Sentry from '@sentry/nextjs';
import React from 'react';

import { VideoPlayer } from '../../../video/components/VideoPlayer';
import { QuestionsVideoRecorderReviewControls } from './QuestionsVideoRecorderReviewControls';

type QuestionsVideoRecorderReviewViewProps = {
  src: string;
  onCanPlay: () => void;
  onClickRestart: () => void;
  onClickSave: () => void;
  uploadContext: boolean;
  percentUploaded: number;
  loading?: boolean;
  addMediaButton?: React.ReactElement;
  muted: boolean;
};

/**
 * Allows a user to review a previously recorded video
 */
export const QuestionsVideoRecorderReviewView: React.FC<
  QuestionsVideoRecorderReviewViewProps
> = ({
  src,
  onCanPlay,
  onClickRestart,
  onClickSave,
  loading,
  addMediaButton,
  muted,
}) => {
  return (
    <>
      <VideoPlayer
        w="100%"
        borderRadius="1.5rem"
        displayAspectRatio="1"
        backgroundColor="gray.100"
        transform
        autoPlay
        muted={muted}
        src={src}
        scaleX={-1}
        onCanPlay={onCanPlay}
        onError={(e) => {
          Sentry.captureException(e, {
            tags: { videoContext: 'recorder.review' },
          });
        }}
        controls={(controlsProps) => (
          <QuestionsVideoRecorderReviewControls
            loading={loading}
            onClickRerecord={onClickRestart}
            onClickSaveRecording={onClickSave}
            addMediaButton={addMediaButton}
            {...controlsProps}
          />
        )}
      />
    </>
  );
};
