import { MyValue } from '@frond/shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { PropsWithChildren, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import * as yup from 'yup';

import { GroupCoreFragment } from '../../../../../../generated/types-and-hooks';
import { Form } from '../../../../common/components/Form';

// We need to use `title` rather than `name` here because it'll be written to the DOM
// Inputs with name="name" or placeholder="... name ..." autofill in Safari
// https://linear.app/frond/issue/FR-3891/group-title-field-triggers-contact-dropdown-in-safari
const GroupEditAboutFormSchema = yup
  .object({
    title: yup.string().trim().required(),
    emoji: yup.string().nullable(),
    description: yup.array().nullable(),
  })
  .defined();

export type GroupEditAboutFormData = yup.InferType<
  typeof GroupEditAboutFormSchema
>;

export const GroupEditAboutForm: React.FC<
  PropsWithChildren<{
    group?: GroupCoreFragment;
    onSubmit: (data: GroupEditAboutFormData) => void;
  }>
> = ({ group, onSubmit, children }) => {
  const defaultValues = useMemo(
    () => ({
      title: group?.name,
      emoji: group?.emoji,
      description: group?.description as MyValue,
    }),
    [group]
  );

  const form = useForm<GroupEditAboutFormData>({
    resolver: yupResolver(GroupEditAboutFormSchema),
    mode: 'all',
    defaultValues,
  });

  useHotkeys(
    'cmd+enter',
    () => {
      form.handleSubmit(onSubmit)();
    },
    {
      enableOnFormTags: ['INPUT', 'TEXTAREA'],
    }
  );

  return (
    <Form<GroupEditAboutFormData>
      {...form}
      onSubmit={onSubmit}
      flex={1}
      display="flex"
      flexDirection="column"
    >
      {children}
    </Form>
  );
};
