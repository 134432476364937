import * as React from "react";

function SvgSocialDribble(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm8.868-9.454c-1.934-.45-3.741-.565-5.43-.344.842 2.075 1.454 4.105 1.837 6.091a9 9 0 003.593-5.747zm-5.44 6.778c-.36-2.16-1.017-4.39-1.974-6.69-2.688.83-5.04 2.623-7.084 5.388A8.962 8.962 0 0012 21c1.214 0 2.371-.24 3.428-.676zM4.945 17.59c2.207-2.901 4.777-4.836 7.688-5.793a43.42 43.42 0 00-.589-1.185c-.517.138-1.053.257-1.607.358-2.23.408-4.164.487-7.3.443l-.118-.002a8.962 8.962 0 001.926 6.18zM3.377 9.415A9.024 9.024 0 017.95 3.96a58.511 58.511 0 013.107 4.837c-.318.075-.645.143-.98.205-2.018.369-3.813.449-6.701.413zm6.519-6.168a59.075 59.075 0 013.12 4.95c1.917-.742 3.405-1.843 4.418-3.372A8.96 8.96 0 0012 3c-.725 0-1.43.086-2.104.247zM18.9 6.223a8.96 8.96 0 012.087 5.3c-2.262-.487-4.39-.56-6.376-.218a44.653 44.653 0 00-.648-1.331c2.061-.834 3.73-2.06 4.937-3.751z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSocialDribble;
