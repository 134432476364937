import { x } from '@xstyled/styled-components';

import { LoadingPulse } from '../../common/components/LoadingPulse';

export const InboxNotificationLoading = () => {
  return (
    <x.div
      display="flex"
      alignItems="center"
      justifyContent="center"
      w="full"
      h="full"
    >
      <LoadingPulse />
    </x.div>
  );
};
