import * as React from "react";

function SvgCat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.25 11.438a.625.625 0 01-.512-.27 9.963 9.963 0 00-5.35-3.88.625.625 0 01-.225-1.075l3.062-2.594a2.219 2.219 0 013.65 1.694v5.5a.625.625 0 01-.625.625z"
        fill="#212121"
      />
      <path
        d="M26.8 6.463a11.25 11.25 0 013.825 2.6v-3.75a.969.969 0 00-1.6-.744L26.8 6.463z"
        fill="#607D8B"
      />
      <path
        d="M20 36.8a21.25 21.25 0 01-10.625-2.844l-1.513-.875a5.781 5.781 0 01-2.18-7.837 4.588 4.588 0 00.568-2.213v-6.206a11.188 11.188 0 0111.2-11.2h5.1a11.082 11.082 0 013.194.463 11.2 11.2 0 018.006 10.737v6.206c.001.777.199 1.54.575 2.219a5.819 5.819 0 01-2.175 7.837l-1.525.875A21.25 21.25 0 0120 36.8z"
        fill="#212121"
      />
      <path
        d="M17.45 6.875a9.944 9.944 0 00-9.95 9.95v6.206a5.844 5.844 0 01-.73 2.819A4.531 4.531 0 008.47 32l1.53.875a20.074 20.074 0 0020 0L31.514 32a4.562 4.562 0 001.706-6.15 5.845 5.845 0 01-.718-2.819v-6.206a9.95 9.95 0 00-9.95-9.95h-5.1z"
        fill="#CFD8DC"
      />
      <path
        d="M8.125 12.456a.625.625 0 01-.625-.625V5.313a2.219 2.219 0 013.65-1.694l3.225 2.737a.625.625 0 01-.2 1.07 9.931 9.931 0 00-5.513 4.705.624.624 0 01-.537.325z"
        fill="#212121"
      />
      <path
        d="M9.725 4.338a.987.987 0 00-.828.45.95.95 0 00-.147.525v4.456a11.156 11.156 0 014.038-3.125L10.35 4.575a.956.956 0 00-.625-.237z"
        fill="#607D8B"
      />
      <path
        d="M17.5 33.125A3.125 3.125 0 0114.375 30a.624.624 0 111.25 0 1.875 1.875 0 103.75 0 .624.624 0 111.25 0 3.125 3.125 0 01-3.125 3.125z"
        fill="#212121"
      />
      <path
        d="M22.5 33.125A3.125 3.125 0 0119.375 30a.625.625 0 111.25 0 1.875 1.875 0 103.75 0 .625.625 0 111.25 0 3.124 3.124 0 01-3.125 3.125zM20 28.919a2.244 2.244 0 01-1.406-.494l-1.025-.819a1.113 1.113 0 01.694-1.981h3.48a1.112 1.112 0 01.695 1.981l-1.025.819c-.401.32-.9.495-1.413.494z"
        fill="#212121"
      />
      <path
        d="M18.656 26.875l.719.575a1 1 0 001.25 0l.719-.575h-2.688z"
        fill="#757575"
      />
      <path
        d="M20 30.625a.624.624 0 01-.625-.625v-1.25a.624.624 0 111.25 0V30a.624.624 0 01-.625.625zm-5-6.875a2.5 2.5 0 01-2.5-2.5V20a2.5 2.5 0 015 0v1.25a2.5 2.5 0 01-2.5 2.5z"
        fill="#212121"
      />
      <path
        d="M15 18.75A1.25 1.25 0 0013.75 20v1.25a1.25 1.25 0 002.5 0V20A1.25 1.25 0 0015 18.75z"
        fill="#ECEFF1"
      />
      <path
        d="M25 23.75a2.5 2.5 0 01-2.5-2.5V20a2.5 2.5 0 015 0v1.25a2.5 2.5 0 01-2.5 2.5z"
        fill="#212121"
      />
      <path
        d="M25 18.75A1.25 1.25 0 0023.75 20v1.25a1.25 1.25 0 002.5 0V20A1.25 1.25 0 0025 18.75z"
        fill="#ECEFF1"
      />
      <path
        d="M8.75 26.25a.627.627 0 01-.15 0L3.6 25a.643.643 0 01.3-1.25l5 1.25a.63.63 0 11-.15 1.25zm0 2.5h-5a.625.625 0 110-1.25h5a.625.625 0 110 1.25zm-5 4.375a.625.625 0 01-.219-1.212l5-1.875a.625.625 0 11.438 1.168l-5 1.875a.624.624 0 01-.219.044zm26.875-6.875a.63.63 0 11-.15-1.25l5-1.25a.642.642 0 11.3 1.25l-5 1.25a.627.627 0 01-.15 0zm5 2.5h-5a.625.625 0 110-1.25h5a.625.625 0 110 1.25zm0 4.375a.627.627 0 01-.219-.038l-5-1.875a.625.625 0 11.438-1.168l5 1.875a.625.625 0 01-.219 1.206zM15 21.875a.625.625 0 01-.625-.625v-.625a.625.625 0 111.25 0v.625a.625.625 0 01-.625.625zm10 0a.625.625 0 01-.625-.625v-.625a.625.625 0 111.25 0v.625a.625.625 0 01-.625.625z"
        fill="#212121"
      />
    </svg>
  );
}

export default SvgCat;
