import * as React from 'react';
import { SVGProps } from 'react';

const SvgRocket = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 8v3.5l-4 3V20h4a2 2 0 0 0 2 2h2v1h2v-1h2a2 2 0 0 0 2-2h4v-5.5l-4-3V8c0-3.156-1.914-7-5-7-3.086 0-5 3.844-5 7Zm6 12h2V8c0-2.192-1.398-5-3-5S9 5.808 9 8v12h2v-6h2v6Zm-8-4.5L7 14v4H5v-2.5ZM17 18v-4l2 1.5V18h-2Zm-5-7a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgRocket;
