import * as React from "react";

function SvgAppGoogle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-google_svg__clip0)">
        <path
          d="M23.44 12.225c0-.983-.08-1.701-.252-2.445H11.96v4.438h6.59c-.132 1.103-.85 2.764-2.444 3.88l-.023.148 3.55 2.75.246.025c2.26-2.086 3.561-5.155 3.561-8.796z"
          fill="#4285F4"
        />
        <path
          d="M11.96 23.918c3.228 0 5.94-1.063 7.919-2.897l-3.774-2.923c-1.01.704-2.365 1.196-4.145 1.196-3.163 0-5.847-2.086-6.804-4.97l-.14.012-3.691 2.857-.049.134c1.967 3.907 6.006 6.59 10.684 6.59z"
          fill="#34A853"
        />
        <path
          d="M5.156 14.324a7.362 7.362 0 01-.398-2.365c0-.824.146-1.621.385-2.365l-.006-.159-3.738-2.902-.122.058A11.97 11.97 0 000 11.959c0 1.927.465 3.747 1.276 5.368l3.88-3.003z"
          fill="#FBBC05"
        />
        <path
          d="M11.96 4.624c2.245 0 3.76.97 4.624 1.78l3.375-3.294c-2.073-1.927-4.77-3.11-8-3.11C7.283 0 3.244 2.684 1.277 6.59l3.867 3.004c.97-2.884 3.654-4.97 6.817-4.97z"
          fill="#EB4335"
        />
      </g>
      <defs>
        <clipPath id="app-google_svg__clip0">
          <path fill="#fff" d="M0 0h23.453v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppGoogle;
