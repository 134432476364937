import { theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';

import {
  CreateOrUpdateOrganizationMutation,
  useCreateOrUpdateOrganizationMutation,
  useUpdateUserLanguageMutation,
} from '../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { Button } from '../../common/components/Button';
import { Centered } from '../../common/components/Centered';
import { Heading } from '../../common/components/Heading';
import { Image } from '../../common/components/Image';
import { Loading } from '../../common/components/Loading';
import { Text } from '../../common/components/Text';
import { useEffectOnce } from '../../common/hooks/useEffectOnce';
import { useStateCallback } from '../../common/hooks/useStateCallback';
import { safelyParseJson } from '../../common/utils';
import { OnboardingLayout } from '../components/common/OnboardingLayout';
import { FormData as OrganizationFormData } from '../components/common/OrganizationForm/OrganizationForm';
import { StepBreadcrumbs } from '../components/groups/layout/StepBreadcrumbs';
import { OrganizationStep } from '../components/groups/steps/OrganizationStep';
import { ProfileStep } from '../components/groups/steps/ProfileStep';
import {
  GroupsOnboardingFormData,
  TemplatesStep,
} from '../components/groups/steps/TemplatesStep';
import { useUpdateUserOnboarding } from '../hooks/useUpdateUserOnboarding';
import {
  getActiveSteps,
  getNextStep,
  getNumActiveSteps,
  GroupOnboardingSteps,
  ONBOARDING_STATE_KEY,
  OnboardingState,
  STEP_COLOR,
} from '../utils/questions';

export type GroupsOnboardingProps = {
  showCreateOrganization?: boolean;
  onDismiss?: () => void;
};

const CreatingCommunityLoading: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Centered backgroundColor="white" flexDirection="column">
      <x.div mb={8}>
        <Image src="https://res.cloudinary.com/frond/image/upload/v1675096722/static/icons8-rhombus-loader_yif7ww.gif" />
      </x.div>
      <Heading mb={3}>{t('communities.creating')}</Heading>
      <Text variant="md">{t('communities.creating_explanation')}</Text>
    </Centered>
  );
};

export const GroupsOnboarding: React.FC<GroupsOnboardingProps> = ({
  showCreateOrganization = false,
  onDismiss,
}) => {
  const { t, i18n } = useTranslation();
  const finishingOnboarding = useRef(false);
  const { viewer: user } = useViewer();
  const [showCreatingCommunityLoading, setShowCreatingCommunityLoading] =
    useState(false);
  const { organization } = useOptionalOrganization();

  // Update user language as soon as possible
  const [updateLanguage] = useUpdateUserLanguageMutation();

  useEffectOnce(
    () => {
      if (user) {
        updateLanguage({
          variables: {
            input: {
              userId: user.id,
              language: i18n.language,
            },
          },
        });
      }
    },
    () => !!user
  );

  const [onboardingState, setOnboardingState] = useState<OnboardingState>(
    typeof window !== 'undefined'
      ? safelyParseJson(localStorage.getItem(ONBOARDING_STATE_KEY)) ||
          getActiveSteps(user, showCreateOrganization)
      : {}
  );

  const [organizationToCreate, setOrganizationToCreate] =
    useState<OrganizationFormData | null>();

  const [selectedTemplateReferenceId, setSelectedTemplateReferenceId] =
    useStateCallback<string | undefined>(undefined);

  const [mutateOrganization, { loading: organizationLoading }] =
    useCreateOrUpdateOrganizationMutation();

  const handleDismiss = () => {
    setOnboardingState(getActiveSteps(user, showCreateOrganization));
    setShowCreatingCommunityLoading(false);
    finishingOnboarding.current = false;
    onDismiss?.();
  };

  const { updateUserOnboarding, loading: userLoading } =
    useUpdateUserOnboarding({
      onComplete: handleDismiss,
    });

  const finishOnboarding = (templateReferenceId?: string) => {
    finishingOnboarding.current = true;
    localStorage.removeItem(ONBOARDING_STATE_KEY);

    if (organizationToCreate) {
      setShowCreatingCommunityLoading(true);
      mutateOrganization({
        variables: {
          input: {
            ...organizationToCreate,
            templateReferenceId,
          },
        },
        onCompleted: (data: CreateOrUpdateOrganizationMutation) => {
          updateUserOnboarding(data.createOrUpdateOrganization, true);
        },
      });
    } else if (organization) {
      updateUserOnboarding(organization);
    }
  };

  useEffect(() => {
    if (showCreateOrganization) {
      // Reset onboarding when showing community creation flow
      setOnboardingState(getActiveSteps(user, showCreateOrganization));
    }
  }, [user, showCreateOrganization]);

  const goToNewStep = (step: GroupOnboardingSteps) => {
    const newState = { ...onboardingState, currentStep: step };
    localStorage.setItem(
      ONBOARDING_STATE_KEY,
      JSON.stringify({ ...newState, firstRun: false })
    );
    setOnboardingState(newState);
  };

  const stepFinished = (templateReferenceId?: string) => {
    const newCurrentStep = getNextStep(
      onboardingState.currentStep,
      onboardingState.steps
    );
    if (!newCurrentStep) {
      finishOnboarding(templateReferenceId || selectedTemplateReferenceId);
    } else {
      goToNewStep(newCurrentStep);
    }
  };

  const handleOrganizationStepNext = (organization: OrganizationFormData) => {
    setOrganizationToCreate(organization);
    stepFinished();
  };

  const handleJoinGroupsStepNext = (
    templateReferenceId: GroupsOnboardingFormData['templateReferenceId']
  ) => {
    // Ensure stepFinished is called with updated state of templates
    setSelectedTemplateReferenceId(
      templateReferenceId,
      (stateTemplateReferenceId) => stepFinished(stateTemplateReferenceId)
    );
  };

  if (showCreatingCommunityLoading) {
    return <CreatingCommunityLoading />;
  }

  if ((!showCreatingCommunityLoading && finishingOnboarding.current) || !user) {
    return <Loading />;
  }

  const loading = userLoading || organizationLoading;

  let stepComponent;
  switch (onboardingState.currentStep) {
    case GroupOnboardingSteps.ORGANIZATION:
      stepComponent = (
        <OrganizationStep
          onNext={handleOrganizationStepNext}
          loading={loading}
        />
      );
      break;
    case GroupOnboardingSteps.TEMPLATES:
      stepComponent = (
        <TemplatesStep onNext={handleJoinGroupsStepNext} loading={loading} />
      );
      break;
    case GroupOnboardingSteps.PROFILE:
      stepComponent = <ProfileStep onNext={() => stepFinished()} />;
      break;
    default:
      return null;
  }

  const showBreadcrumbs = getNumActiveSteps(onboardingState.steps) > 1;

  const children = (
    <>
      {onDismiss && (
        <Button
          label={t('cancel')}
          variant="secondary"
          onClick={handleDismiss}
          type="button"
          top={`${theme.sizes['10']}`}
          left={`${theme.sizes['10']}`}
          position={{ _: 'initial', sm: 'absolute' }}
          h={{ _: '12', sm: 'initial' }}
          my={{ _: '6', sm: '0' }}
        />
      )}
      {showBreadcrumbs && (
        <StepBreadcrumbs
          activeSteps={onboardingState.steps}
          currentStep={onboardingState.currentStep}
          onPreviousStepClick={goToNewStep}
        />
      )}
      {stepComponent}
    </>
  );

  return (
    <OnboardingLayout
      backgroundColor={STEP_COLOR[onboardingState.currentStep]}
      breadcrumbs={showBreadcrumbs}
    >
      {children}
    </OnboardingLayout>
  );
};
