import { useInterpret, useSelector } from '@xstate/react';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { createContext, useContext, useEffect } from 'react';
import { InterpreterFrom } from 'xstate';

import {
  FeatureEntitlement,
  GroupTypes,
  MemberSubscriptionPlan,
  PostCoreFragment,
  PostTypes,
} from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Text } from '../../common/components/Text';
import { UpsellModal } from '../../common/components/UpsellModal';
import { useEffectOnce } from '../../common/hooks/useEffectOnce';
import { GroupsModal } from '../../groups/components/GroupsModal';
import { PostComposerCropSurface } from './PostComposerCropSurface';
import { PostComposerErrors } from './PostComposerErrors';
import { PostComposerGiphySurface } from './PostComposerGiphySurface';
import {
  initialPostComposerContext,
  postComposerHasNoValuesSelector,
  postComposerMachine,
  PostComposerState,
} from './postComposerMachine';
import { PostComposerQrUploadSurface } from './PostComposerQrUploadSurface';
import {
  PostButton,
  PostComposerReviewSurface,
} from './PostComposerReviewSurface';
import { PostComposerVideoCaptureSurface } from './PostComposerVideoCaptureSurface';

export const PostComposerStateContext = createContext({
  postComposerService: {} as InterpreterFrom<typeof postComposerMachine>,
});

export type PostComposerGroup = {
  id: string;
  name: string;
  emoji: string;
  isPrivate?: boolean | null;
  groupType: GroupTypes;
  unlockedBy?: Pick<MemberSubscriptionPlan, 'id' | 'active'> | null;
};

export type PostComposerProps = {
  isOpen: boolean;
  onDismiss: () => void;
  group?: PostComposerGroup;
  post?: PostCoreFragment;
  initialState?: PostComposerState;
  files?: File[];
  url?: string;
  pollOptions?: string[];
  type?: PostTypes;
  canChangeGroup?: boolean;
};

export const PostComposer = (props: PostComposerProps) => {
  const postComposerService = useInterpret(postComposerMachine, {
    context: {
      ...initialPostComposerContext,
      post: props.post
        ? props.post
        : {
            ...initialPostComposerContext.post,
            media: props.files
              ? props.files.map((file, index) => {
                  return {
                    id: `file-to-upload-${index}`,
                    file,
                    uploadProgress: 0,
                  };
                })
              : [],
          },
      group: props.group,
      postId: props.post?.id,
      type: props.type,
      pollOptions:
        props.post?.poll?.options.map((option) => option.name) ||
        props.pollOptions,
      canChangeGroup: props.canChangeGroup ?? true,
    },
    actions: {
      dismiss: () => {
        props.onDismiss();
      },
    },
  });

  useEffectOnce(
    () => {
      if (props.initialState === PostComposerState.QR_UPLOAD) {
        postComposerService.send({ type: 'START_QR_UPLOAD' });
      }

      if (props.initialState === PostComposerState.VIDEO_CAPTURE) {
        postComposerService.send({ type: 'START_VIDEO_CAPTURE' });
      }

      if (props.initialState === PostComposerState.GIPHY_SEARCH) {
        postComposerService.send({ type: 'START_GIPHY_SEARCH' });
      }

      if (props.url) {
        postComposerService.send({
          type: 'ADD_URL_TO_EMBED',
          url: props.url,
        });
      }
    },
    () => !!props.initialState || !!props.url
  );

  return (
    <PostComposerStateContext.Provider value={{ postComposerService }}>
      <PostComposerSurface {...props} />
    </PostComposerStateContext.Provider>
  );
};

const PostComposerSurface = ({ isOpen, onDismiss }: PostComposerProps) => {
  const { t } = useTranslation();
  const { postComposerService } = useContext(PostComposerStateContext);
  const { organization } = useOrganization();
  const postComposerHasNoValues = useSelector(
    postComposerService,
    postComposerHasNoValuesSelector
  );
  const isPublishing = useSelector(
    postComposerService,
    (state) => state.context.isPublishing
  );
  const state = useSelector(postComposerService, (state) => state.value);
  const type = useSelector(postComposerService, (state) => state.context.type);

  const renderSurface = () => {
    switch (state) {
      case PostComposerState.REVIEW:
        return <PostComposerReviewSurface />;
      case PostComposerState.CROP:
        return <PostComposerCropSurface />;
      case PostComposerState.GIPHY_SEARCH:
        return <PostComposerGiphySurface />;
      case PostComposerState.VIDEO_CAPTURE:
        return <PostComposerVideoCaptureSurface />;
      case PostComposerState.QR_UPLOAD:
        return <PostComposerQrUploadSurface />;
    }
  };

  const handleDismiss = () => {
    if (state === PostComposerState.REVIEW) {
      onDismiss();
    } else {
      postComposerService.send({ type: 'REVIEW_POST' });
    }
  };

  const confirmBeforeDismiss =
    state === PostComposerState.REVIEW && !postComposerHasNoValues;

  useEffect(() => {
    const handleUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
    };

    if (confirmBeforeDismiss) {
      window.addEventListener('beforeunload', handleUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [confirmBeforeDismiss]);

  if (state === PostComposerState.POLL_UPSELL) {
    return (
      <UpsellModal
        feature={FeatureEntitlement.Polls}
        isOpen={isOpen}
        onDismiss={() => postComposerService.send('CLOSE_POLL_UPSELL')}
      />
    );
  }

  return (
    <>
      <PostComposerErrors />
      <GroupsModal
        width="600px"
        variant="composer"
        isOpen={isOpen}
        ariaLabel="Add Post"
        onDismiss={handleDismiss}
        confirmBeforeDismiss={confirmBeforeDismiss}
        showClose={false}
        submitButton={<PostButton isPublishing={isPublishing} />}
      >
        <x.div spaceY={5}>
          {type === PostTypes.NewUser ? (
            <x.div bg="gray.100" borderRadius="md-lg" overflow="hidden" mb={8}>
              <x.div spaceY={3} p={6}>
                <Text variant="md-semibold">{t('introduce_yourself')}</Text>
                <Text>{organization.newMemberIntroductionMessage}</Text>
              </x.div>
            </x.div>
          ) : null}
          <x.div
            bg="white"
            p={{ _: 0, sm: '4 6' }}
            borderRadius={{ _: 0, sm: 'md-lg' }}
          >
            {renderSurface()}
          </x.div>
        </x.div>
      </GroupsModal>
    </>
  );
};
