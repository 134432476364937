import { Language } from '@frond/shared/lib/i18n';
import { useTranslation } from 'next-i18next';
import pluralize from 'pluralize';
import React from 'react';
import { default as ITimeAgo } from 'react-timeago';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deStrings from 'react-timeago/lib/language-strings/de';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import esStrings from 'react-timeago/lib/language-strings/es';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import frStrings from 'react-timeago/lib/language-strings/fr';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import itStrings from 'react-timeago/lib/language-strings/it';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import jaStrings from 'react-timeago/lib/language-strings/ja';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ptStrings from 'react-timeago/lib/language-strings/pt';

export type TimeAgoProps = ITimeAgo.ReactTimeagoProps<any>;

const createEnglishFormatter = (abbreviate?: boolean): ITimeAgo.Formatter => {
  return (value, unit, suffix, epochMiliseconds, nextFormatter) => {
    if (unit === 'second' && suffix === 'ago') {
      return 'Just now';
    } else if (abbreviate && suffix === 'ago') {
      if (unit === 'minute') {
        return `${value}m ago`;
      } else if (unit === 'hour') {
        return `${value}h ago`;
      } else if (unit === 'day') {
        return `${value}d ago`;
      } else if (unit === 'week') {
        return `${value}w ago`;
      } else if (unit === 'month') {
        return `${value}m ago`;
      } else if (unit === 'year') {
        return `${value}y ago`;
      }
    } else if (unit === 'minute' && suffix === 'ago') {
      return `${value} ${pluralize('min', value)} ago`;
    } else if (nextFormatter) {
      return nextFormatter(value, unit, suffix, epochMiliseconds);
    } else {
      return null;
    }
  };
};

const getFormatter = (locale: Language, abbreviate?: boolean) => {
  return {
    en: createEnglishFormatter(abbreviate),
    de: buildFormatter(deStrings),
    es: buildFormatter(esStrings),
    fr: buildFormatter(frStrings),
    it: buildFormatter(itStrings),
    pt: buildFormatter(ptStrings),
    ja: buildFormatter(jaStrings),
  }[locale];
};

/**
 * Component for display relative time strings
 */
export const TimeAgo: React.FC<
  TimeAgoProps & {
    abbreviate?: boolean;
  }
> = ({ abbreviate, ...props }) => {
  const { i18n } = useTranslation();

  const locale = ['en', 'de', 'es', 'fr', 'pt', 'it', 'ja'].includes(
    i18n.language
  )
    ? (i18n.language as Language)
    : 'en';
  const formatter = getFormatter(locale, abbreviate);

  return <ITimeAgo formatter={formatter} {...props} />;
};
