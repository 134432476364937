import * as React from "react";
import { SVGProps } from "react";
const SvgTag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.667 1.667a2.5 2.5 0 0 0-1.768.732L2.4 9.9a2.51 2.51 0 0 0 0 3.535l4.167 4.167a2.5 2.5 0 0 0 3.535 0l7.5-7.5a2.501 2.501 0 0 0 .732-1.768V3.75c0-1.143-.94-2.083-2.083-2.083h-4.583Zm0 1.25h4.583c.465 0 .833.368.833.833v4.583c0 .338-.127.645-.366.884l-7.5 7.5c-.24.24-.545.366-.884.366-.338 0-.644-.126-.883-.366l-4.167-4.166a1.243 1.243 0 0 1 0-1.768l7.5-7.5c.24-.24.545-.366.884-.366Zm2.5 1.666a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgTag;
