import { useTranslation } from 'next-i18next';
import { FC, useState } from 'react';

import { EventCoreFragment } from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Button } from '../../common/components/Button';
import { Icon } from '../../common/components/Icon';
import { useEventAttendance } from '../hooks/useEventAttendance';

export const EventAttendButtonButton: FC<{
  event: EventCoreFragment;
}> = ({ event }) => {
  const { t } = useTranslation(['events']);
  const { organization } = useOrganization();
  const { userIsMember, joinGroup, joiningGroup, leaveGroup, leavingGroup } =
    useEventAttendance({ event, organization });
  const [hovering, setHovering] = useState(false);

  const handleClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    leaveGroup();
  };

  const isPast = new Date(event.startAt).getTime() < new Date().getTime();
  const attending = isPast ? t('events.attended') : t('events.attending');
  const notAttending = isPast
    ? t('events.not_attended')
    : t('events.not_attending');

  if (event.deleted) {
    return <Button variant="warning" label={t('events.cancelled')} disabled />;
  }

  if (userIsMember) {
    return (
      <Button
        variant={hovering ? 'secondary' : 'green'}
        onClick={handleClick}
        onMouseOver={() => setHovering(true)}
        onMouseOut={() => setHovering(false)}
        loading={leavingGroup}
        leftElement={
          !hovering ? <Icon name="check-mark" color="white" /> : null
        }
        label={hovering ? notAttending : attending}
      />
    );
  }

  return (
    <Button
      variant="primary"
      onClick={(e: React.MouseEvent) => {
        joinGroup();
        e.preventDefault();
      }}
      loading={joiningGroup}
      label={t('events.attend')}
      minWidth="125px"
    />
  );
};
