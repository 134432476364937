import { Position } from '@reach/popover';

const EDGE_PADDING = 8;

export class ReachPositionMissingError extends Error {
  constructor() {
    super('Reach popover position missing');
  }
}

export const topCenter: Position = (triggerRect, tooltipRect) => {
  if (!triggerRect || !tooltipRect) {
    throw new ReachPositionMissingError();
  }

  // Center the tooltip and push to the right if it collides on left
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const leftCentered = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - EDGE_PADDING;
  const left =
    Math.min(Math.max(EDGE_PADDING, leftCentered), maxLeft) +
    window.pageXOffset;

  // Position above the button and push to the bottom if it collides on top
  let top =
    triggerRect.top - (tooltipRect.height + EDGE_PADDING) + window.pageYOffset;
  if (triggerRect.top - tooltipRect.height < EDGE_PADDING) {
    top = triggerRect.bottom + EDGE_PADDING + window.pageYOffset;
  }

  return { left: `${left}px`, top: `${top}px` };
};

export const bottomCenter =
  (padding = EDGE_PADDING): Position =>
  (triggerRect, tooltipRect) => {
    if (!triggerRect || !tooltipRect) {
      throw new ReachPositionMissingError();
    }

    // Center the tooltip and push to the right if it collides on left
    const triggerCenter = triggerRect.left + triggerRect.width / 2;
    const leftCentered = triggerCenter - tooltipRect.width / 2;
    const maxLeft = window.innerWidth - tooltipRect.width - padding;
    const left =
      Math.min(Math.max(padding, leftCentered), maxLeft) + window.pageXOffset;

    // Position above the button and push to the bottom if it collides on top
    let top =
      triggerRect.top + (triggerRect.height + padding) + window.pageYOffset;
    if (triggerRect.top - tooltipRect.height < padding) {
      top = triggerRect.bottom + padding + window.pageYOffset;
    }

    return { left: `${left}px`, top: `${top}px` };
  };

export const rightCenter: Position = (triggerRect, tooltipRect) => {
  if (!triggerRect || !tooltipRect) {
    throw new ReachPositionMissingError();
  }

  const left =
    triggerRect.left + triggerRect.width + window.pageXOffset + EDGE_PADDING;

  const centerOffset = (triggerRect.height - tooltipRect.height) / 2;
  // Position above the button and push to the bottom if it collides on top
  const top = triggerRect.top + centerOffset + window.pageYOffset;

  return {
    left: `${left}px`,
    top: `${top}px`,
  };
};

export const rightAlign: Position = (triggerRect, tooltipRect) => {
  if (!triggerRect || !tooltipRect) {
    throw new ReachPositionMissingError();
  }

  // Position above the button and push to the bottom if it collides on top
  let top =
    triggerRect.top - (tooltipRect.height + EDGE_PADDING) + window.pageYOffset;
  if (triggerRect.top - tooltipRect.height < EDGE_PADDING) {
    top = triggerRect.bottom + EDGE_PADDING + window.pageYOffset;
  }

  const left = triggerRect.right - tooltipRect.width + window.pageXOffset;

  return { left: `${left}px`, top: `${top}px` };
};

export const rightBottomAlign: Position = (triggerRect, tooltipRect) => {
  if (!triggerRect || !tooltipRect) {
    throw new ReachPositionMissingError();
  }

  // Position above the button and push to the bottom if it collides on top
  const top =
    triggerRect.top +
    triggerRect.height -
    (tooltipRect.height + EDGE_PADDING) +
    window.pageYOffset;

  const left = triggerRect.right + EDGE_PADDING + window.pageXOffset;

  return { left: `${left}px`, top: `${top}px` };
};

export const bottomRightAlign: Position = (triggerRect, tooltipRect) => {
  if (!triggerRect || !tooltipRect) {
    throw new ReachPositionMissingError();
  }

  let top =
    triggerRect.top + (triggerRect.height + EDGE_PADDING) + window.pageYOffset;
  if (triggerRect.top - tooltipRect.height < EDGE_PADDING) {
    top = triggerRect.bottom + EDGE_PADDING + window.pageYOffset;
  }

  let left = triggerRect.right - tooltipRect.width + window.pageXOffset;

  // Align to left if it collides with window to the left
  if (left < 0) {
    left = triggerRect.left + window.pageXOffset;
  }

  return { left: `${left}px`, top: `${top}px` };
};

export const bottomLeftAlign =
  (padding = EDGE_PADDING): Position =>
  (triggerRect, tooltipRect) => {
    if (!triggerRect || !tooltipRect) {
      throw new ReachPositionMissingError();
    }

    let top =
      triggerRect.top + (triggerRect.height + padding) + window.pageYOffset;
    if (triggerRect.top - tooltipRect.height < padding) {
      top = triggerRect.bottom + padding + window.pageYOffset;
    }

    let left = triggerRect.left + window.pageXOffset;

    // Position the tooltip to left if it collides on right
    if (left + tooltipRect.width > window.innerWidth) {
      left = triggerRect.right - tooltipRect.width + window.pageXOffset;
    }

    // Center the tooltip and push to the right if it collides on left
    if (left < 0) {
      const triggerCenter = triggerRect.left + triggerRect.width / 2;
      const leftCentered = triggerCenter - tooltipRect.width / 2;
      const maxLeft = window.innerWidth - tooltipRect.width - padding;
      left =
        Math.min(Math.max(padding, leftCentered), maxLeft) + window.pageXOffset;
    }

    return { left: `${left}px`, top: `${top}px` };
  };

export const leftAlign: Position = (triggerRect, tooltipRect) => {
  if (!triggerRect || !tooltipRect) {
    throw new ReachPositionMissingError();
  }

  // Position above the button and push to the bottom if it collides on top
  let top =
    triggerRect.top - (tooltipRect.height + EDGE_PADDING) + window.pageYOffset;

  if (triggerRect.top - tooltipRect.height < EDGE_PADDING) {
    top = triggerRect.bottom + EDGE_PADDING + window.pageYOffset;
  }

  return { left: `${triggerRect.left}px`, top: `${top}px` };
};
