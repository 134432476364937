import * as React from "react";
import { SVGProps } from "react";
const SvgItalic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.266 2.91a.624.624 0 0 0-.07.007H7.708a.625.625 0 1 0 0 1.25h3.671L6.864 15.833H3.542a.624.624 0 1 0 0 1.25h3.646a.63.63 0 0 0 .2 0h4.904a.626.626 0 1 0 0-1.25H8.204L12.72 4.167h2.905a.625.625 0 1 0 0-1.25h-3.23a.625.625 0 0 0-.129-.008Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgItalic;
