import * as React from "react";
import { SVGProps } from "react";

const SvgAppSpotify = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#app-spotify_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.765 10.685c-3.84-2.28-10.26-2.52-13.92-1.38-.6.18-1.2-.18-1.38-.72-.18-.6.18-1.2.72-1.38 4.26-1.26 11.28-1.02 15.72 1.62.54.3.72 1.02.42 1.56-.3.42-1.02.6-1.56.3Zm-.12 3.36c-.3.42-.84.6-1.26.3-3.24-1.98-8.16-2.58-11.94-1.38-.48.12-1.02-.12-1.14-.6-.12-.48.12-1.02.6-1.14 4.38-1.32 9.78-.66 13.5 1.62.36.18.54.78.24 1.2Zm-1.44 3.3c-.24.36-.66.48-1.02.24-2.82-1.74-6.36-2.1-10.56-1.14-.42.12-.78-.18-.9-.54-.12-.42.18-.78.54-.9 4.56-1.02 8.52-.6 11.64 1.32.42.18.48.66.3 1.02ZM12.685.005c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.34-12-12-12Z"
        fill="#00DA5A"
      />
    </g>
    <defs>
      <clipPath id="app-spotify_svg__a">
        <path fill="#fff" transform="translate(.685 .005)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAppSpotify;
