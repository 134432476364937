import { Screens } from '@frond/shared';
import {
  FontFamilyProps,
  FontSizeProps,
  FontWeightProps,
  LineHeightProps,
  x,
} from '@xstyled/styled-components';
import { ComponentProps, FC, forwardRef } from 'react';

export const textStyles = {
  'xl-semibold': {
    fontFamily: 'default',
    lineHeight: 'tall',
    fontSize: 'xl',
    fontWeight: 'semibold',
  },
  'lg-semibold': {
    fontFamily: 'default',
    lineHeight: 'tallest',
    fontSize: 'lg',
    fontWeight: 'semibold',
  },
  'lg-bold': {
    fontFamily: 'default',
    lineHeight: 'tallest',
    fontSize: 'lg',
    fontWeight: 'bold',
  },
  lg: {
    fontFamily: 'default',
    lineHeight: 'tallest',
    fontSize: 'lg',
    fontWeight: 'normal',
  },
  'md-semibold': {
    fontFamily: 'default',
    lineHeight: 'tallest',
    fontSize: 'md',
    fontWeight: 'semibold',
  },
  'md-medium': {
    fontFamily: 'default',
    lineHeight: 'tallest',
    fontSize: 'md',
    fontWeight: 'medium',
  },
  'md-s-semibold': {
    fontFamily: 'default',
    lineHeight: 'taller',
    fontSize: 'md-s',
    fontWeight: 'semibold',
  },
  'md-s': {
    fontFamily: 'default',
    lineHeight: 'taller',
    fontSize: 'md-s',
    fontWeight: 'normal',
  },
  md: {
    fontFamily: 'default',
    lineHeight: 'tallest',
    fontSize: 'md',
    fontWeight: 'normal',
  },
  'sm-semibold': {
    fontFamily: 'default',
    lineHeight: 'taller',
    fontSize: 'sm',
    fontWeight: 'semibold',
  },
  'sm-medium': {
    fontFamily: 'default',
    lineHeight: 'taller',
    fontSize: 'sm',
    fontWeight: 'medium',
  },
  sm: {
    fontFamily: 'default',
    lineHeight: 'taller',
    fontSize: 'sm',
    fontWeight: 'normal',
  },
  'xs-bold': {
    fontFamily: 'default',
    lineHeight: 'taller',
    fontSize: 'xs',
    fontWeight: 'bold',
  },
  'xs-medium': {
    fontFamily: 'default',
    lineHeight: 'taller',
    fontSize: 'xs',
    fontWeight: 'medium',
  },
  xs: {
    fontFamily: 'default',
    lineHeight: 'taller',
    fontSize: 'xs',
    fontWeight: 'normal',
  },
  'xs-semibold': {
    fontFamily: 'default',
    lineHeight: 'taller',
    fontSize: 'xs',
    fontWeight: 'semibold',
  },
  'lato-black': {
    fontFamily: 'lato',
    lineHeight: 'taller',
    fontSize: '24',
    fontWeight: 'black',
  },
  'lato-regular': {
    fontFamily: 'lato',
    lineHeight: 'taller',
    fontSize: '15',
    fontWeight: 'normal',
  },
};

export type TextVariant = keyof typeof textStyles;

export type TextProps = ComponentProps<typeof x.div> & {
  /**
   * Text style from our design system
   */
  variant?: TextVariant | { [key in keyof Screens]?: TextVariant };
  /**
   * Truncate the end of the text with ellipsis
   */
  truncate?: boolean;
  /**
   * Specify the HTML tag used to render the element
   */
  as?: 'p' | 'u' | 'i' | 'strong' | 'strike' | 'code' | 'span' | 'label';
};

export const Text: FC<TextProps> = forwardRef(
  ({ as = 'p', variant = 'md', children, truncate = false, ...props }, ref) => {
    let fontFamily: FontFamilyProps['fontFamily'];
    let fontSize: FontSizeProps['fontSize'];
    let fontWeight: FontWeightProps['fontWeight'];
    let lineHeight: LineHeightProps['lineHeight'];

    if (typeof variant === 'object') {
      fontFamily = {
        _: variant._ && textStyles[variant._].fontFamily,
        sm: variant.sm && textStyles[variant.sm].fontFamily,
        md: variant.md && textStyles[variant.md].fontFamily,
        lg: variant.lg && textStyles[variant.lg].fontFamily,
        xl: variant.xl && textStyles[variant.xl].fontFamily,
      };

      fontSize = {
        _: variant._ && textStyles[variant._].fontSize,
        sm: variant.sm && textStyles[variant.sm].fontSize,
        md: variant.md && textStyles[variant.md].fontSize,
        lg: variant.lg && textStyles[variant.lg].fontSize,
        xl: variant.xl && textStyles[variant.xl].fontSize,
      };

      fontWeight = {
        _: variant._ && textStyles[variant._].fontWeight,
        sm: variant.sm && textStyles[variant.sm].fontWeight,
        md: variant.md && textStyles[variant.md].fontWeight,
        lg: variant.lg && textStyles[variant.lg].fontWeight,
        xl: variant.xl && textStyles[variant.xl].fontWeight,
      };

      lineHeight = {
        _: variant._ && textStyles[variant._].lineHeight,
        sm: variant.sm && textStyles[variant.sm].lineHeight,
        md: variant.md && textStyles[variant.md].lineHeight,
        lg: variant.lg && textStyles[variant.lg].lineHeight,
        xl: variant.xl && textStyles[variant.xl].lineHeight,
      };
    } else {
      const styles = textStyles[variant];
      fontFamily = styles.fontFamily;
      fontSize = styles.fontSize;
      fontWeight = styles.fontWeight;
      lineHeight = styles.lineHeight;
    }

    return (
      <x.p
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
        as={as}
        {...(truncate && {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        })}
        ref={ref}
        {...props}
      >
        {children}
      </x.p>
    );
  }
);

Text.displayName = 'Text';
