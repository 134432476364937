import { useTranslation } from 'next-i18next';
import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  GroupWithMembersFragment,
  refetchMenuSectionsQuery,
  useUpdateGroupMutation,
} from '../../../../../../generated/types-and-hooks';
import { Alert } from '../../../../common/components/Alert';
import { Switch } from '../../../../common/components/Switch';
import { Text } from '../../../../common/components/Text';
import { GroupCreateFormData } from '../../GroupCreateModal/GroupCreateForm';
import {
  GroupEditSetting,
  GroupEditSettingPositioning,
} from './GroupEditSetting';

/**
 * This setting has two modalities
 * - immediate - for usage with existing groups
 * - form - for usage for new groups, must be within a form context
 */
export const GroupEditSettingPrivate: React.FC<
  {
    group?: GroupWithMembersFragment;
  } & GroupEditSettingPositioning
> = ({ group, ...positioning }) => {
  if (!group) {
    return <GroupEditSettingPrivateForm {...positioning} />;
  }

  return <GroupEditSettingPrivateImmediate group={group} {...positioning} />;
};

const GroupEditSettingPrivateForm: FC<GroupEditSettingPositioning> = ({
  ...positioning
}) => {
  const form = useFormContext<GroupCreateFormData>();
  const isPrivate = form.watch('isPrivate');

  const handleChange = (enabled: boolean) => {
    form.setValue('isPrivate', enabled);
  };

  return (
    <GroupEditSettingPrivateLayout
      isPrivate={isPrivate || false}
      onCheckedChange={handleChange}
      {...positioning}
    />
  );
};

const GroupEditSettingPrivateImmediate: FC<
  { group: GroupWithMembersFragment } & GroupEditSettingPositioning
> = ({ group, ...positioning }) => {
  const [mutate, { loading }] = useUpdateGroupMutation({
    optimisticResponse: {
      __typename: 'Mutation',
      updateGroup: {
        ...group,
        isPrivate: !group.isPrivate,
      },
    },
    refetchQueries: [
      refetchMenuSectionsQuery({
        organizationId: group.organization.id,
      }),
    ],
  });

  const handleChange = (enabled: boolean) => {
    mutate({ variables: { input: { id: group.id, isPrivate: enabled } } });
  };

  return (
    <GroupEditSettingPrivateLayout
      isPrivate={group.isPrivate || false}
      onCheckedChange={handleChange}
      disabled={loading}
      {...positioning}
    />
  );
};

const GroupEditSettingPrivateLayout: React.FC<
  {
    isPrivate: boolean;
    disabled?: boolean;
    onCheckedChange: (isPrivate: boolean) => void;
  } & GroupEditSettingPositioning
> = ({ isPrivate, disabled, onCheckedChange, first, last }) => {
  const { t } = useTranslation(['common']);
  const [showAlertSwitchToPrivate, setShowAlertSwitchToPrivate] =
    useState<boolean>(false);

  const [showAlertSwitchToPublic, setShowAlertSwitchToPublic] =
    useState<boolean>(false);

  const handleClick = (event: React.MouseEvent) => {
    if (!showAlertSwitchToPrivate && !isPrivate) {
      event.preventDefault();
      setShowAlertSwitchToPrivate(true);
    }

    if (!showAlertSwitchToPublic && isPrivate) {
      event.preventDefault();
      setShowAlertSwitchToPublic(true);
    }
  };

  return (
    <GroupEditSetting
      first={first}
      last={last}
      title={t('groups.make_private', { ns: 'common' })}
      toggle={
        <Switch
          disabled={disabled}
          checked={isPrivate}
          onClick={handleClick}
          onCheckedChange={onCheckedChange}
        />
      }
    >
      <Text variant="md" color="gray.300">
        {t('groups.make_private_explanation', { ns: 'common' })}
      </Text>
      {showAlertSwitchToPrivate && (
        <Alert
          headingText={t('groups.make_group_private', { ns: 'common' })}
          descriptionText={t('groups.make_private_confirmation', {
            ns: 'common',
          })}
          cancelButtonText={t('cancel', { ns: 'common' })}
          submitButtonText={t('groups.make_private', { ns: 'common' })}
          onSubmitClick={() => {
            onCheckedChange(true);
            setShowAlertSwitchToPrivate(false);
          }}
          onCancelClick={() => setShowAlertSwitchToPrivate(false)}
        />
      )}
      {showAlertSwitchToPublic && (
        <Alert
          headingText={t('groups.make_group_public', { ns: 'common' })}
          descriptionText={t('groups.make_public_confirmation', {
            ns: 'common',
          })}
          cancelButtonText={t('cancel', { ns: 'common' })}
          submitButtonText={t('groups.make_public', { ns: 'common' })}
          variant="warning"
          onSubmitClick={() => {
            onCheckedChange(false);
            setShowAlertSwitchToPublic(false);
          }}
          onCancelClick={() => setShowAlertSwitchToPublic(false)}
        />
      )}
    </GroupEditSetting>
  );
};
