import { x } from '@xstyled/styled-components';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Icon } from '../../common/components/Icon';

type FieldValidationIconProps = {
  variant?: 'small';
  name: string;
};

export const FieldValidationIcon: FC<FieldValidationIconProps> = ({
  variant,
  name,
}) => {
  const { formState, watch, trigger } = useFormContext();
  const { isValid, isDirty } = formState;

  const url = watch(name);

  useEffect(() => {
    trigger(name);
  }, [trigger, name]);

  if (!url && !isDirty) return null;

  return (
    <x.div
      position="absolute"
      pr={variant === 'small' ? 2 : 3}
      pointerEvents="none"
      h="full"
      color={isValid ? 'green.200' : 'red.200'}
      top={0}
      right={0}
      fontSize={variant === 'small' ? 'md' : '2xl'}
      display="flex"
      alignItems="center"
    >
      <Icon name={isValid ? 'circle-filled-checked' : 'circle-filled-x'} />
    </x.div>
  );
};
