import React from 'react';

import { Giphy } from '../../../../generated/types-and-hooks';
import { NextLink } from '../../common/components/NextLink';
import { QuestionsVideoPlayerControls } from '../../questions/components/QuestionsVideoPlayer/QuestionsVideoPlayerControls';
import { getAnswerNavigationLinkProps } from '../../questions/hooks/useAnswerNavigation';
import { VideoPlayer } from '../../video/components/VideoPlayer';

export const PostCardGiphy = ({
  giphy,
  linkProps,
}: {
  giphy: Giphy;
  linkProps?: ReturnType<typeof getAnswerNavigationLinkProps> | null;
}) => {
  const vid = (
    <VideoPlayer
      poster={giphy.giphyUrl}
      preload="auto"
      src={giphy.giphyMp4Url}
      playsInline
      loop
      autoPlay
      w="100%"
      h="100%"
      overflow="hidden"
      transform
      controls={QuestionsVideoPlayerControls}
      bindEvents={false}
    />
  );

  if (!linkProps) return vid;
  return (
    <NextLink href={linkProps.href} as={linkProps.as}>
      {vid}
    </NextLink>
  );
};
