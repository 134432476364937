import { useSelector } from '@xstate/react';
import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useContext, useState } from 'react';

import {
  refetchUserRequestToJoinOrganizationQuery,
  useRequestToJoinOrganizationMutation,
  useUserRequestToJoinOrganizationQuery,
} from '../../../generated/types-and-hooks';
import { useAuth } from '../auth/hooks/useAuth';
import { useViewer } from '../auth/hooks/useViewer';
import { SignInPage } from '../auth/views/SignInPage';
import { Button } from '../common/components/Button';
import { Heading } from '../common/components/Heading';
import { Loading } from '../common/components/Loading';
import { Text } from '../common/components/Text';
import { useSignOut } from '../common/hooks/useApollo';
import { AppContext } from '../common/machine/appContext';
import { InviteCommunityLayout } from '../invites/components/InviteCommunityLayout';
import { RequestToJoinSuccess } from './components/RequestToJoinSuccess';

export const RequestToJoinView = () => {
  const { appService } = useContext(AppContext);
  const organization = useSelector(
    appService,
    (state) => state.context.organization
  );
  const { viewer, isViewerLoading } = useViewer();
  const signout = useSignOut();
  const router = useRouter();
  const { isAuthenticated, isLoading } = useAuth();
  const [showSignIn, setShowSignIn] = useState(false);
  const { t } = useTranslation();

  const { data, loading: loadingUserRequestToJoin } =
    useUserRequestToJoinOrganizationQuery({
      variables: {
        organizationId: organization?.id || '',
      },
      skip: !viewer,
    });

  const [mutateCreateRequestToJoin, { loading }] =
    useRequestToJoinOrganizationMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        refetchUserRequestToJoinOrganizationQuery({
          organizationId: organization?.id || '',
        }),
      ],
    });

  const handleClick = () => {
    if (viewer) {
      mutateCreateRequestToJoin({
        variables: {
          input: {
            organizationId: organization?.id || '',
            email: viewer.email,
          },
        },
      });
    } else {
      setShowSignIn(true);
    }
  };

  if (
    loadingUserRequestToJoin ||
    isViewerLoading ||
    (isAuthenticated && isLoading) ||
    !organization
  ) {
    return <Loading />;
  }

  if (data?.userRequestToJoinOrganization) {
    return <RequestToJoinSuccess organization={organization} />;
  }

  if (showSignIn) {
    return <SignInPage callbackUrlPath={router.asPath} />;
  }

  return (
    <InviteCommunityLayout
      title={
        <Heading variant="xl" mb={2}>
          <Trans
            i18nKey="rtj.welcome_to_community"
            ns="common"
            values={{ organizationName: organization.name }}
          >
            Welcome to{' '}
            <Heading variant="xl" color="brand.300" as="span">
              {organization.name}
            </Heading>
          </Trans>
        </Heading>
      }
      organization={organization}
    >
      <Button
        label={t('rtj.request_to_join')}
        onClick={handleClick}
        loading={loading}
        p={4}
        w="full"
        mb={4}
      />
      <Text color="gray.300" variant="sm" px={20}>
        {t('rtj.request_to_join_explanation')}
      </Text>
      {viewer ? (
        <>
          <Text color="gray.300" variant="sm" mt={2}>
            Signed in as {viewer.email}
          </Text>
          <x.button
            onClick={() =>
              signout(`/auth/signin?callbackUrlPath=${router.asPath}`)
            }
            backgroundColor="transparent"
            outline="none"
            border="none"
            cursor="pointer"
          >
            <Text color="gray.300" variant="sm">
              {t('rtj.sign_in_with_another_account')}
            </Text>
          </x.button>
        </>
      ) : (
        <x.button
          onClick={handleClick}
          backgroundColor="transparent"
          outline="none"
          border="none"
          cursor="pointer"
          mt={2}
        >
          <Text color="gray.300" variant="sm">
            {t('rtj.already_a_member')}
          </Text>
        </x.button>
      )}
    </InviteCommunityLayout>
  );
};
