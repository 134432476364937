import { getPlaybackUrl, getThumbnailUrl, theme } from '@frond/shared';
import styled, { x } from '@xstyled/styled-components';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from 'react';

import { Video } from '../../../../generated/types-and-hooks';
import { Icon } from '../../common/components/Icon';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { VideoControlsProps } from '../../video/components/Video/VideoControls';
import {
  VideoSeekbar,
  VideoSeekbarProps,
} from '../../video/components/Video/VideoSeekbar';
import {
  VideoPlayer,
  VideoPlayerRef,
} from '../../video/components/VideoPlayer';
import { PostCardButton } from './PostCardMedia';

const ControlSurface = styled.div<{ buffering: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover > .play-target {
    background-color: whiteAlpha.450;
    color: brand.300;
  }
`;

const StatusContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <x.div
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      pointerEvents="none"
    >
      {children}
    </x.div>
  );
};

const BufferingSpinner: FC<{ buffering: boolean }> = ({ buffering }) => {
  return (
    <x.div
      opacity={buffering ? 1 : 0}
      transitionProperty="opacity"
      transitionDuration="fast"
      borderRadius="full"
      w={18}
      h={18}
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor={theme.colors.blackAlpha[300]}
    >
      <LoadingSpinner size="8" />
    </x.div>
  );
};

const PostLightboxVideoPlayerControls: FC<VideoControlsProps> = ({
  buffering,
  togglePlayback,
  playing,
  played,
}) => {
  return (
    <>
      <ControlSurface buffering={buffering} onClick={togglePlayback}>
        {!playing && !played && (
          <x.button
            border="none"
            borderRadius="full"
            h={16}
            w={16}
            backgroundColor="whiteAlpha.400"
            display="flex"
            alignItems="center"
            justifyContent="center"
            justifySelf="center"
            className="play-target"
            cursor="pointer"
            transitionProperty={theme.transitions.property.colors}
            transitionDuration={theme.transitions.duration.fast}
            transitionTimingFunction={theme.transitions.easing['ease-in-out']}
          >
            <Icon
              name="play"
              size="6"
              transition="default"
              transitionProperty="color"
            />
          </x.button>
        )}
      </ControlSurface>
      <StatusContainer>
        {buffering && <BufferingSpinner buffering={buffering} />}
      </StatusContainer>
    </>
  );
};

const PostLightboxVideoPlayerSeekbar: FC<VideoSeekbarProps> = (props) => {
  return (
    <x.div
      position="absolute"
      bottom={theme.sizes['1.5']}
      right={theme.sizes['20']}
      left={0}
    >
      <VideoSeekbar {...props} />
    </x.div>
  );
};

export const PostLightBoxVideoPlayer: FC<{
  video: Video;
  autoPlay?: boolean;
}> = ({ video, autoPlay }) => {
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(false);
  const [muted, setMuted] = useState(false);

  const videoPlayerRef = useRef<VideoPlayerRef>(null);

  const handlePlaybackStatusChange = useCallback(
    (playing: boolean) => {
      setPlaying(playing);
      playing && setPlayed(true);
    },
    [setPlaying]
  );

  return (
    <>
      <VideoPlayer
        overflow="hidden"
        preload="auto"
        autoPlay={autoPlay}
        poster={
          video.playbackId ? getThumbnailUrl(video.playbackId) : undefined
        }
        src={getPlaybackUrl(video.playbackId || '')}
        transform
        aspect={video.aspect}
        playsInline
        scaleX={video.mirror ? -1 : 1}
        controls={PostLightboxVideoPlayerControls}
        onPlaybackStatusChange={handlePlaybackStatusChange}
        onMutedStatusChange={setMuted}
        ref={videoPlayerRef}
        w="100%"
        h="100%"
        objectFit="cover"
        seekbar={PostLightboxVideoPlayerSeekbar}
      />
      {(playing || played) && (
        <x.div
          spaceX={2}
          display="flex"
          position="absolute"
          right={16}
          bottom={16}
          transform="translateZ(0)"
        >
          <PostCardButton
            iconName={muted ? 'audio-off' : 'audio-on'}
            onClick={() => videoPlayerRef.current?.toggleMuted()}
            variant="default"
          />
          <PostCardButton
            iconName={playing ? 'pause' : 'play'}
            onClick={() => videoPlayerRef.current?.togglePlayback()}
            variant="default"
          />
        </x.div>
      )}
    </>
  );
};
