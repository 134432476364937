import { useTranslation } from 'next-i18next';

import {
  refetchNotificationCountsQuery,
  refetchNotificationsQuery,
  useMarkAllNotificationsReadMutation,
  useNotificationCountsQuery,
} from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Button } from '../../common/components/Button';
import { NUM_NOTIFICATIONS } from '../views/InboxView';

export const MarkAsReadButton = () => {
  const { t } = useTranslation('inbox');
  const { organization } = useOrganization();

  const { data: notificationCountsQuery } = useNotificationCountsQuery({
    variables: { organizationId: organization.id },
  });

  const [markAllNotificationsRead] = useMarkAllNotificationsReadMutation({
    variables: { organizationId: organization.id },
    refetchQueries: [
      refetchNotificationsQuery({
        organizationId: organization.id,
        first: NUM_NOTIFICATIONS,
      }),
      refetchNotificationCountsQuery({ organizationId: organization.id }),
    ],
  });

  return (
    <Button
      variant="secondary"
      label={t('inbox.mark_all_read')}
      disabled={notificationCountsQuery?.notificationCounts.unread === 0}
      onClick={() => markAllNotificationsRead()}
    />
  );
};
