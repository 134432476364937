import { x } from '@xstyled/styled-components';

import {
  NotificationEventNames,
  useNotificationQuery,
} from '../../../../generated/types-and-hooks';
import {
  InboxNotificationAssignedRole,
  InboxNotificationPromoteToAdmin,
} from './InboxNotificationAdmin';
import {
  InboxNotificationEventCancelled,
  InboxNotificationEventDateChanged,
  InboxNotificationEventLocationChanged,
  InboxNotificationEventReminder,
  InboxNotificationEventTimeChanged,
} from './InboxNotificationEvent';
import { InboxNotificationLoading } from './InboxNotificationLoading';
import { InboxNotificationNewMember } from './InboxNotificationMember';
import {
  InboxNotificationRequestToJoin,
  InboxNotificationRequestToJoinInvite,
} from './InboxNotificationRequestToJoin';
import {
  InboxNotificationMemberSubscriptionCancelled,
  InboxNotificationMemberSubscriptionCreated,
  InboxNotificationMemberSubscriptionExpiring,
  InboxNotificationMemberSubscriptionGifted,
  InboxNotificationMemberSubscriptionPriceChanged,
} from './InboxNotificationSubscription';

export const InboxNotificationView: React.FC<{
  id: string;
}> = ({ id }) => {
  const { data, loading } = useNotificationQuery({
    variables: {
      id,
    },
  });

  if (loading || !data) {
    return <InboxNotificationLoading />;
  }

  return (
    <x.div p={5} maxW={600} mx="auto">
      {(() => {
        const notification = data?.notification;
        switch (notification.eventName) {
          case NotificationEventNames.RequestToJoin: {
            return (
              <InboxNotificationRequestToJoin notification={notification} />
            );
          }
          case NotificationEventNames.RequestToJoinInvite: {
            return (
              <InboxNotificationRequestToJoinInvite
                notification={notification}
              />
            );
          }
          case NotificationEventNames.MemberSubscriptionCreated: {
            return (
              <InboxNotificationMemberSubscriptionCreated
                notification={notification}
              />
            );
          }
          case NotificationEventNames.MemberSubscriptionCanceled: {
            return (
              <InboxNotificationMemberSubscriptionCancelled
                notification={notification}
              />
            );
          }
          case NotificationEventNames.MemberSubscriptionPlanPriceChanged: {
            return (
              <InboxNotificationMemberSubscriptionPriceChanged
                notification={notification}
              />
            );
          }
          case NotificationEventNames.MemberSubscriptionExpiry: {
            return (
              <InboxNotificationMemberSubscriptionExpiring
                notification={notification}
              />
            );
          }
          case NotificationEventNames.EventDeleted: {
            return (
              <InboxNotificationEventCancelled notification={notification} />
            );
          }
          case NotificationEventNames.EventDateChanged: {
            return (
              <InboxNotificationEventDateChanged notification={notification} />
            );
          }
          case NotificationEventNames.EventTimeChanged: {
            return (
              <InboxNotificationEventTimeChanged notification={notification} />
            );
          }
          case NotificationEventNames.EventLocationChanged: {
            return (
              <InboxNotificationEventLocationChanged
                notification={notification}
              />
            );
          }
          case NotificationEventNames.EventReminder: {
            return (
              <InboxNotificationEventReminder notification={notification} />
            );
          }
          case NotificationEventNames.UserPromoted: {
            return (
              <InboxNotificationPromoteToAdmin notification={notification} />
            );
          }
          case NotificationEventNames.UserRoleAssignment: {
            return (
              <InboxNotificationAssignedRole notification={notification} />
            );
          }
          case NotificationEventNames.UserJoined: {
            return <InboxNotificationNewMember notification={notification} />;
          }
          case NotificationEventNames.MemberSubscriptionGifted: {
            return (
              <InboxNotificationMemberSubscriptionGifted
                notification={notification}
              />
            );
          }
          default: {
            return <></>;
          }
        }
      })()}
    </x.div>
  );
};
