import { useApolloClient, useSubscription } from '@apollo/client';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';

import {
  DirectMessageNotificationCountsDocument,
  DirectMessageNotificationCountsQuery,
  DirectMessageNotificationCountsQueryVariables,
  GroupUnseenPostsDocument,
  GroupUnseenPostsQuery,
  GroupUnseenPostsQueryVariables,
  HomeFeedLatestContentDocument,
  HomeFeedLatestContentQuery,
  HomeFeedLatestContentQueryVariables,
  NotificationCountsDocument,
  NotificationCountsQuery,
  NotificationCountsQueryVariables,
  NotificationsDocument,
  NotificationsQuery,
  NotificationsQueryVariables,
  PostsByGroupDocument,
  PostsByGroupQuery,
  PostsByGroupQueryVariables,
  useCreateUserFcmPushSubscriptionMutation,
  useNotificationsQuery,
  UserPushEventsDocument,
} from '../../../../generated/types-and-hooks';
import { POSTS_PER_PAGE } from '../../../config/constants';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { userInOrganization } from '../../auth/utils/permissions';
import { useEffectOnce } from '../../common/hooks/useEffectOnce';
import { NUM_NOTIFICATIONS } from '../../inbox/views/InboxView';
import { usePushServiceWorker } from '../hooks/usePushServiceWorker';

export const NotificationsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { viewer } = useViewer();
  const { data } = useSubscription(UserPushEventsDocument, {
    fetchPolicy: 'no-cache',
    skip: !viewer,
  });
  const { organization } = useOptionalOrganization();
  const client = useApolloClient();
  const router = useRouter();

  const event = data?.userPushEvent;
  const { registerSubscription } = usePushServiceWorker();
  const [createUserFCMPushSubscription] =
    useCreateUserFcmPushSubscriptionMutation();

  useEffectOnce(
    () => {
      registerSubscription();
    },
    () => !!viewer
  );

  useEffect(() => {
    const tokenHandler = (event: any) => {
      createUserFCMPushSubscription({
        variables: {
          input: {
            deviceToken: event.detail,
          },
        },
      });
    };

    window.addEventListener('onTokenReceived', tokenHandler);

    return () => {
      window.removeEventListener('onTokenReceived', tokenHandler);
    };
  }, [createUserFCMPushSubscription]);

  const skip =
    !organization ||
    !viewer ||
    (viewer && organization && !userInOrganization(viewer, organization));

  // Preload the inbox data
  useNotificationsQuery({
    variables: {
      organizationId: organization?.id || '',
      first: NUM_NOTIFICATIONS,
    },
    fetchPolicy: 'cache-first',
    skip,
  });

  const queryDirectMessageNotificationCounts = useCallback(
    (organizationId: string) => {
      client.query<
        DirectMessageNotificationCountsQuery,
        DirectMessageNotificationCountsQueryVariables
      >({
        query: DirectMessageNotificationCountsDocument,
        variables: { organizationId },
      });
    },
    [client]
  );

  // Debouce query to prevent rate limiting
  const debounceQueryDirectMessageNotificationCounts = useMemo(
    () => debounce(queryDirectMessageNotificationCounts),
    [queryDirectMessageNotificationCounts]
  );

  /**
   * Refetch data depending on the notification payload
   */
  useEffect(() => {
    /**
     * Handle notifications
     */
    if (event?.__typename === 'PushNotification') {
      const notification = new Notification(event.title, {
        body: event.body,
        icon: event.iconUrl,
        badge:
          'https://res.cloudinary.com/frond/image/upload/dpr_2.0,w_144,f_auto/bmnabizu3dcfdeyllnul',
      });
      event.url && (notification.onclick = () => router.push(event.url));
      return;
    }

    /**
     * Handle refetches
     */
    if (event?.organizationId) {
      const { organizationId, postId, groupId } = event;
      /**
       * Update the notification counts for the inbox
       */
      client.query<NotificationCountsQuery, NotificationCountsQueryVariables>({
        query: NotificationCountsDocument,
        variables: { organizationId },
      });

      /**
       * Update the notification counts for messages
       */
      debounceQueryDirectMessageNotificationCounts(organizationId);

      /**
       * Update the notifications themselves in the inbox
       */
      client.query<NotificationsQuery, NotificationsQueryVariables>({
        query: NotificationsDocument,
        variables: { organizationId, first: NUM_NOTIFICATIONS },
      });

      if (postId) {
        /**
         * Trigger the check for new feed content
         */
        client.query<
          HomeFeedLatestContentQuery,
          HomeFeedLatestContentQueryVariables
        >({
          query: HomeFeedLatestContentDocument,
          variables: { organizationId },
        });
      }

      if (groupId) {
        /**
         * Update the group
         */
        client.query<PostsByGroupQuery, PostsByGroupQueryVariables>({
          query: PostsByGroupDocument,
          variables: { groupId, first: POSTS_PER_PAGE },
        });

        client.query<GroupUnseenPostsQuery, GroupUnseenPostsQueryVariables>({
          query: GroupUnseenPostsDocument,
          variables: { id: groupId },
        });
      }
    }
  }, [event, client, router, debounceQueryDirectMessageNotificationCounts]);

  return (
    <>
      <Script
        id="android-script"
        strategy="beforeInteractive"
      >{`function receiveTokenFromAndroid(token) {
    try {
      if (window.dispatchEvent && token) {
        const event = new CustomEvent('onTokenReceived', { detail: token });
        window.dispatchEvent(event);
      }
    } catch {
      console.error('Error receiving token from Android');
    }
  }`}</Script>

      {children}
    </>
  );
};
