import { ELEMENT_IMAGE, MyNode, MyRootBlock, MyValue } from '@frond/shared';
import { isText } from '@udecode/plate-common';
import { x } from '@xstyled/styled-components';
import React, { forwardRef } from 'react';
import nl2br from 'react-nl2br';
import { Node, Text as SlateText } from 'slate';

import { LineClamp } from '../LineClamp';
import { Text } from '../Text';
import {
  ComposerElement as ComposerElementComponent,
  ComposerLeaf,
} from './ComposerElement';
import { ComposerSummary } from './ComposerSummary';

export interface ComposerBodyProps {
  content: MyValue;
  truncate?: boolean;
  trimOuterWhitespace?: boolean;
  textOnly?: boolean;
  variant?: 'hello';
}

// Helper to identify elements with intended empty content
const isVoidElement = (node: MyRootBlock) => {
  return node.type === ELEMENT_IMAGE;
};

const trimOuterWhitespaceFromContent = (content: MyValue) => {
  let trimmedContent = content.concat([]);

  let startIndexToTrim = 0;

  // Find beginning white space
  for (let i = 0; i < trimmedContent.length; i++) {
    if (
      !isVoidElement(trimmedContent[i]) &&
      Node.string(trimmedContent[i]) == ''
    ) {
      startIndexToTrim++;
    } else {
      break;
    }
  }

  // Trim beginning inner white space
  trimmedContent = trimmedContent.splice(
    startIndexToTrim,
    trimmedContent.length
  );

  let endIndexToTrim: number = trimmedContent.length;

  // Find ending white space
  for (let i = trimmedContent.length - 1; i >= 0; i--) {
    if (
      !isVoidElement(trimmedContent[i]) &&
      Node.string(trimmedContent[i]) == ''
    ) {
      endIndexToTrim--;
    } else {
      break;
    }
  }

  // Trim ending inner white space
  trimmedContent = trimmedContent.splice(0, endIndexToTrim);

  return trimmedContent;
};

/**
 * Lightweight read-only component for rendering Slate content
 */
export const ComposerBody = forwardRef<HTMLDivElement, ComposerBodyProps>(
  (props, ref) => {
    const { truncate, trimOuterWhitespace, textOnly, variant } = props;

    // Trim outer whitespace for both props as trimming benefits truncating as well
    const content =
      truncate || trimOuterWhitespace
        ? trimOuterWhitespaceFromContent(props.content)
        : props.content;

    if (textOnly) {
      return (
        <LineClamp maxLines={5}>{content.map(renderTextOnlyNodes)}</LineClamp>
      );
    }
    return (
      <>
        {truncate ? (
          <LineClamp ref={ref} maxLines={5}>
            {content.slice(0, 5).map(renderSummaryNode)}
          </LineClamp>
        ) : (
          <x.div spaceY={2}>
            {content.map((node, index, array) =>
              renderNode(node, index, array, variant)
            )}
          </x.div>
        )}
      </>
    );
  }
);

ComposerBody.displayName = 'ComposerBody';

const shouldRenderTheFirstElementAsNull = (
  node: MyNode,
  index: number,
  array: MyNode[]
): boolean => {
  return index === 0 && !node.text && array.length > 1;
};

/**
 * Helper function for recursively rendering Slate nodes
 * - Line breaks are replaced with <br /> for visual parity with Slate editor
 * - Empty strings should be preserved (not passed through nl2br)
 */
const renderNode = (
  node: MyNode,
  index: number,
  array: MyNode[],
  variant?: 'hello'
) => {
  if (SlateText.isText(node)) {
    if (shouldRenderTheFirstElementAsNull(node, index, array)) {
      return null;
    }
    return (
      <ComposerLeaf key={index} leaf={node}>
        {node.text ? nl2br(node.text) : ''}
      </ComposerLeaf>
    );
  }
  const children = node.children?.map((node, index, array) =>
    renderNode(node, index, array, variant)
  );
  return (
    <ComposerElementComponent
      key={index}
      stripEmptySpaces
      element={node as any}
      attributes={{
        'data-slate-node': 'element',
        ref: React.createRef(),
      }}
      variant={variant}
      readonly
    >
      {children}
    </ComposerElementComponent>
  );
};

/**
 * This renderer is used for update summary representations ie. in the list view
 * All headings come out the same size, and everything is rendered as inline elements
 * so that we can effectively line clamp
 */
// eslint-disable-next-line react/display-name
const renderSummaryNode = (node: MyNode, index: number, array: MyNode[]) => {
  if (isText(node)) {
    if (shouldRenderTheFirstElementAsNull(node, index, array)) {
      return null;
    }
    return (
      <ComposerLeaf key={index} leaf={node}>
        {node.text ? nl2br(node.text) : <br />}
      </ComposerLeaf>
    );
  }

  const children = node.children?.map(renderSummaryNode);

  return (
    <ComposerSummary key={index} element={node as any}>
      {children}
    </ComposerSummary>
  );
};

const renderTextOnlyNodes = (node: MyNode, index: number, array: MyNode[]) => {
  if (SlateText.isText(node)) {
    if (shouldRenderTheFirstElementAsNull(node, index, array)) {
      return null;
    }
    return <Text as={'span'}>{nl2br(node.text)}</Text>;
  }
  const children = node.children?.map(renderTextOnlyNodes);

  return <Text as={'span'}>{children}</Text>;
};
