import {
  AzureCommunicationTokenCredential,
  CommunicationUserIdentifier,
} from '@azure/communication-common';
import { StatefulChatClient } from '@azure/communication-react';
import * as Sentry from '@sentry/nextjs';
import { useEffect, useState } from 'react';

import { useDirectMessageToken } from '../../../auth/hooks/useDirectMessageToken';
import { useViewer } from '../../../auth/hooks/useViewer';
import { usePrevious } from '../../../common/hooks/usePreviousBoolean';
import { getUserDisplayName } from '../../../common/utils/user';
import { useChatSubscription } from './useChatSubscription';

export const useChatClient = () => {
  const { viewer } = useViewer();
  const [chatClient, setChatClient] = useState<StatefulChatClient>();

  const { directMessageToken, refetch } = useDirectMessageToken();

  const previousChatClient = usePrevious(chatClient);

  const token = directMessageToken?.token;
  const communicationUserId = directMessageToken?.communicationUserId;
  const isTokenExpired =
    !!directMessageToken?.expiresOn &&
    new Date(directMessageToken?.expiresOn).getTime() < new Date().getTime();
  const previousCommunicationUserId = usePrevious(communicationUserId);

  useEffect(() => {
    // Reset chat client if token is expired
    if (isTokenExpired) {
      setChatClient(undefined);

      // Refetch token if expired
      refetch();
    }
  }, [isTokenExpired, refetch]);

  useEffect(() => {
    const initializeChatClient = async () => {
      // Lazy load to avoid SSR issues with using window
      const { createStatefulChatClient, fromFlatCommunicationIdentifier } =
        await import('@azure/communication-react');

      // Do not initialize the chat client if token has not be fetched yet or
      // if the token is not expired and the communicationUserId has not changed
      if (
        !communicationUserId ||
        !token ||
        !viewer ||
        isTokenExpired ||
        (chatClient &&
          communicationUserId &&
          previousCommunicationUserId &&
          communicationUserId === previousCommunicationUserId)
      )
        return;

      const userId = fromFlatCommunicationIdentifier(
        communicationUserId
      ) as CommunicationUserIdentifier;

      const credential = new AzureCommunicationTokenCredential(token);

      // Instantiate the statefulChatClient
      setChatClient(
        createStatefulChatClient({
          userId,
          displayName: getUserDisplayName(viewer),
          endpoint: process.env
            .NEXT_PUBLIC_COMMUNICATION_SERVICES_ENDPOINT as string,
          credential,
        })
      );
    };
    initializeChatClient();
  }, [
    chatClient,
    communicationUserId,
    previousCommunicationUserId,
    token,
    viewer,
    isTokenExpired,
  ]);

  useEffect(() => {
    if (
      !chatClient ||
      isTokenExpired ||
      (previousChatClient &&
        chatClient &&
        (previousChatClient.getState().userId as CommunicationUserIdentifier)
          .communicationUserId ===
          (chatClient.getState().userId as CommunicationUserIdentifier)
            .communicationUserId)
    )
      return;

    const preloadThreadsWithMessages = async () => {
      for await (const page of chatClient.listChatThreads().byPage()) {
        for (const thread of page) {
          if (!thread.deletedOn) {
            const threadClient = await chatClient.getChatThreadClient(
              thread.id
            );
            await threadClient.listMessages().byPage().next();
          }
        }
      }
    };

    try {
      preloadThreadsWithMessages();
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [chatClient, previousChatClient, isTokenExpired]);

  chatClient?.startRealtimeNotifications();

  // Enable chat subscription updating cache
  useChatSubscription({
    chatClient,
  });

  return chatClient;
};
