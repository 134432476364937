import { Node } from 'slate';

import {
  ELEMENT_MENTION,
  ELEMENT_PARAGRAPH,
  MyParagraphElement,
  MyRootBlock,
  MyValue,
} from './plateTypes';
import { ELEMENT_UL } from './plateTypes';
import { ELEMENT_OL } from './plateTypes';

/**
 * Formats a plain string as a composer paragraph
 */
export const createParagraphElement = (
  text = '',
  options?: {
    bold?: boolean;
    color?: string;
  }
): MyRootBlock => {
  return {
    type: ELEMENT_PARAGRAPH,
    children: [
      {
        ...(!!options && options),
        text,
      },
    ],
  };
};

/**
 * Formats a plain string as a composer paragraph
 * and sets it as the first item of a composer element array
 */
export const createParagraphElementArray = (
  text = '',
  options?: {
    bold?: boolean;
    color?: string;
  }
): MyValue => [createParagraphElement(text, options)];

/**
 * Formats a user id as a composer paragraph with a mention node
 */
export const createParagraphElementWithMentionArray = (
  userId: string
): MyParagraphElement[] => {
  return [
    {
      type: ELEMENT_PARAGRAPH,
      children: [
        {
          type: ELEMENT_MENTION,
          user: { id: userId },
          children: [{ text: '' }],
        },
        { text: '' },
      ],
    },
  ];
};

/**
 * Returns text from content with ellipsis when longer than passed length
 */
export const getTrimmedText = (content: MyValue, length: number): string => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const text = content.map((n) => Node.string(n)).join('\n');
  if (text.length > length) {
    return `${text.substring(0, length)}...`;
  }
  return text;
};

export const convertContentToText = (content?: MyValue) => {
  return content
    ?.map((n: MyRootBlock) => {
      if (n.type === ELEMENT_UL) {
        return n.children
          .map((li) => {
            return `- ${Node.string(li)}`;
          })
          .join(' ');
      } else if (n.type === ELEMENT_OL) {
        return n.children
          .map((li, i) => {
            return `${i + 1}. ${Node.string(li)}`;
          })
          .join(' ');
      }
      return Node.string(n);
    })
    .join('\n');
};
