import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  MyPlatePlugin,
  MyValue,
} from '@frond/shared';
import { ExitBreakPlugin, SoftBreakPlugin } from '@udecode/plate-break';
import { TEditableProps } from '@udecode/plate-common';
import { KEYS_HEADING } from '@udecode/plate-heading';
import { CSSProperties } from 'react';

export const optionsSoftBreakPlugin: Partial<MyPlatePlugin<SoftBreakPlugin>> = {
  options: {
    rules: [
      { hotkey: 'shift+enter' },
      {
        hotkey: 'enter',
        query: {
          allow: [ELEMENT_CODE_BLOCK, ELEMENT_BLOCKQUOTE],
        },
      },
    ],
  },
};

export const optionsExitBreakPlugin: Partial<MyPlatePlugin<ExitBreakPlugin>> = {
  options: {
    rules: [
      {
        hotkey: 'mod+enter',
      },
      {
        hotkey: 'mod+shift+enter',
        before: true,
      },
      {
        hotkey: 'enter',
        query: {
          start: true,
          end: true,
          allow: KEYS_HEADING,
        },
        relative: true,
        level: 1,
      },
    ],
  },
};

const style: CSSProperties = {
  overflow: 'hidden',
};

export const editableProps: TEditableProps<MyValue> = {
  spellCheck: true,
  style,
};
