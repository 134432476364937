import { getCloudinaryUrl, theme } from '@frond/shared';
import { useSelector } from '@xstate/react';
import { styled, x } from '@xstyled/styled-components';
import React, { useContext, useRef } from 'react';
import { Cropper, CropperRef } from 'react-advanced-cropper';

import { Button } from '../../common/components/Button';
import { PostComposerStateContext } from './PostComposer';

const StyledCropper = styled(Cropper)`
  background-color: gray.50;

  .advanced-cropper-simple-line {
    border-color: brand.300;
  }

  .advanced-cropper-simple-handler {
    border: 1px solid ${theme.colors.brand[300]};
  }

  .advanced-cropper-simple-line--north {
    border-top-width: 2px;
  }

  .advanced-cropper-simple-line--west {
    border-left-width: 2px;
  }

  .advanced-cropper-simple-line--east {
    border-right-width: 2px;
  }

  .advanced-cropper-simple-line--south {
    border-bottom-width: 2px;
  }
`;

export const PostComposerCropSurface = () => {
  const cropperRef = useRef<CropperRef>(null);
  const { postComposerService } = useContext(PostComposerStateContext);
  const mediaIndex = useSelector(
    postComposerService,
    (state) => state.context.currentMediaIndex
  );
  const media = useSelector(
    postComposerService,
    (state) => state.context.post.media
  );
  const selectedMedia = media[mediaIndex];

  if (!selectedMedia || !('publicId' in selectedMedia)) {
    postComposerService.send({
      type: 'REVIEW_POST',
    });
    return null;
  }

  return (
    <div
      style={{
        width: '100%',
        aspectRatio: selectedMedia.aspectRatio.toString(),
      }}
    >
      <StyledCropper
        src={
          selectedMedia.base64 ||
          getCloudinaryUrl({ id: selectedMedia.publicId })
        }
        defaultSize={({ imageSize, visibleArea }) => {
          return {
            width: (visibleArea || imageSize).width,
            height: (visibleArea || imageSize).height,
          };
        }}
        ref={cropperRef}
      />
      <x.div mt={4} display="flex" justifyContent="end">
        <Button
          label="Crop"
          onClick={() => {
            if (cropperRef.current) {
              const coordinates = cropperRef.current.getCoordinates();
              if (coordinates) {
                postComposerService.send({
                  type: 'CROP_IMAGE',
                  coordinates: {
                    x: coordinates.left,
                    y: coordinates.top,
                    width: coordinates.width,
                    height: coordinates.height,
                  },
                  mediaId: selectedMedia.id,
                });
              }
            }
          }}
        />
      </x.div>
    </div>
  );
};
