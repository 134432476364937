import { DEFAULT_GROUP_TOKEN_BG_COLORS, Screens, theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';

import { Text, TextVariant } from '../../../../common/components/Text';

// 0.5rem - 2px, 1rem - 2px
// to account for border
const TOKEN_PADDING = '6px 14px';

export const GroupEditSettingsToken: React.FC<{
  name: string;
  emoji?: string | null;
  onClick?: (name: string) => void;
  selected?: boolean;
  variant?: TextVariant | { [key in keyof Screens]?: TextVariant };
  randomBackgroundColor?: boolean;
}> = ({
  name,
  emoji,
  onClick,
  selected,
  variant = 'md-semibold',
  randomBackgroundColor,
}) => {
  const getRandomBgColor = () => {
    return DEFAULT_GROUP_TOKEN_BG_COLORS[
      Math.floor(Math.random() * DEFAULT_GROUP_TOKEN_BG_COLORS.length)
    ];
  };

  const backgroundColor = randomBackgroundColor
    ? getRandomBgColor()
    : 'brand.50';

  return (
    <x.div
      mr={1}
      mb={1}
      p={TOKEN_PADDING}
      borderRadius="md"
      borderWidth={1}
      borderStyle="solid"
      borderColor={{
        _: 'transparent',
        ...(onClick && {
          hover: 'brand.300',
        }),
      }}
      transitionProperty={theme.transitions.property.colors}
      transitionDuration={theme.transitions.duration.faster}
      transitionTimingFunction="linear"
      color={selected ? 'white' : 'gray.500'}
      backgroundColor={selected ? 'brand.300' : backgroundColor}
      {...(onClick && {
        cursor: 'pointer',
        onClick: () => onClick?.(name),
      })}
    >
      <Text variant={variant}>{emoji ? `${emoji} ${name}` : name}</Text>
    </x.div>
  );
};
