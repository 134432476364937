import { routes } from '@frond/shared';
import { Trans, useTranslation } from 'next-i18next';

import { NotificationQuery } from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { Button } from '../../common/components/Button';
import { Text } from '../../common/components/Text';
import { InboxNotificationCommon } from './InboxNotificationCommon';

export const InboxNotificationPromoteToAdmin: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation('inbox');
  const { admin } = notification;
  const { organization } = useOrganization();

  if (!admin) return <></>;

  return (
    <InboxNotificationCommon
      icon="event-time"
      title={t('inbox.promote_to_admin')}
      description={
        <Trans
          i18nKey="inbox.promote_to_admin_description"
          t={t}
          values={{
            firstName: admin.firstName || admin.username,
            organizationName: organization.name,
          }}
          components={{
            strong: <Text as="span" variant="md-semibold" />,
          }}
        />
      }
      action={
        <Button
          variant="secondary"
          label={t('inbox.view_community_settings')}
          href={routes.settings.organization(organization.shortId).details()}
        />
      }
    />
  );
};

export const InboxNotificationAssignedRole: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation('inbox');
  const { admin, role } = notification;
  const { viewer } = useViewer();
  const { organization } = useOrganization();

  if (!admin || !role || !viewer) return <></>;

  return (
    <InboxNotificationCommon
      icon="event-time"
      title={t('inbox.assigned_a_role')}
      description={
        <Trans
          i18nKey="inbox.assigned_a_role_description"
          t={t}
          values={{
            firstName: admin.firstName || admin.username,
            roleName: role.label,
          }}
          components={{
            strong: <Text as="span" variant="md-semibold" />,
          }}
        />
      }
      action={
        <Button
          variant="secondary"
          label={t('inbox.view_profile')}
          href={routes.groups
            .organization(organization.shortId)
            .person(viewer.username)}
        />
      }
    />
  );
};
