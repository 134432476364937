import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export const DEFAULT_REMINDER_CRON_EXPRESSION = '0 9 * * 1,2,3,4,5';

import {
  GroupCoreFragment,
  refetchGroupQuery,
  useUpdateGroupMutation,
} from '../../../../../../generated/types-and-hooks';
import { Form } from '../../../../common/components/Form';
import { GroupEditSettingPositioning } from './GroupEditSetting';
import { GroupEditSettingReminder } from './GroupEditSettingReminder';

export const GroupEditSettingsReminderFormSchema = yup
  .object({
    questionEnabled: yup.boolean().nullable(),
    questionText: yup
      .string()
      .nullable()
      .when('questionEnabled', {
        is: (val: boolean | null) => !!val,
        then: (schema) => schema.required(),
      }),
    questionCronExpression: yup
      .string()
      .nullable()
      .when('questionEnabled', {
        is: (val: boolean | null) => !!val,
        then: (schema) => schema.required(),
      }),
  })
  .defined();

export type GroupEditSettingsReminderFormData = yup.InferType<
  typeof GroupEditSettingsReminderFormSchema
>;

export const GroupEditSettingsReminderForm: React.FC<
  {
    group: GroupCoreFragment;
    enabled?: boolean;
    onSuccess?: () => void;
  } & GroupEditSettingPositioning
> = ({ group, enabled, onSuccess, ...positioning }) => {
  const defaultValues = useMemo(
    () => ({
      questionEnabled: enabled || group.questionEnabled,
      questionText: group.questionText,
      questionCronExpression:
        group.questionCronExpression || DEFAULT_REMINDER_CRON_EXPRESSION,
    }),
    [group, enabled]
  );

  const form = useForm<GroupEditSettingsReminderFormData>({
    resolver: yupResolver(GroupEditSettingsReminderFormSchema),
    mode: 'all',
    defaultValues,
  });

  const [mutateUpdateGroup, { loading }] = useUpdateGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      refetchGroupQuery({
        id: group.id,
      }),
    ],
  });

  const questionEnabled = form.watch('questionEnabled');

  useEffect(() => {
    form.reset(defaultValues);
  }, [form, defaultValues]);

  const handleUpdateGroup = useCallback(
    ({ ...data }: GroupEditSettingsReminderFormData) => {
      mutateUpdateGroup({
        variables: {
          input: {
            id: group.id,
            ...data,
          },
        },
        onCompleted: onSuccess,
      });
    },
    [group, mutateUpdateGroup, onSuccess]
  );

  useEffect(() => {
    if (!questionEnabled) {
      form.handleSubmit(handleUpdateGroup)();
    }
  }, [questionEnabled, form, handleUpdateGroup]);

  return (
    <Form<GroupEditSettingsReminderFormData>
      {...form}
      onSubmit={handleUpdateGroup}
      flex={1}
      display="flex"
      flexDirection="column"
    >
      <GroupEditSettingReminder
        showSwitch={!enabled}
        loading={loading}
        {...positioning}
      />
    </Form>
  );
};
