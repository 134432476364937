import { css, styled, useDown, x } from '@xstyled/styled-components';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { PostCoreFragment } from '../../../../generated/types-and-hooks';
import { Icon, IconSizes } from '../../common/components/Icon';
import { getAnswerNavigationLinkProps } from '../../questions/hooks/useAnswerNavigation';
import { useVideoEvents } from '../../video/hooks/useVideoEvents';
import { PostCardMediaCarouselControls } from './PostCardMediaCarouselControls';
import { PostCardMediaItem } from './PostCardMediaItem';
import { PostLightbox } from './PostLightbox';

export const PostMediaCarousel = styled.div`
  display: flex;
  flex: 1;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  transform: translateZ(0);
  margin: 0 -16px;

  @media (min-width: 480px) {
    margin: 0 -24px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PostMediaContainer = styled.div<{
  aspectRatio: number;
  cursor?: 'pointer' | 'auto';
}>`
  position: relative;
  min-width: 100%;
  scroll-snap-align: start;
  line-height: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: ${(p) => p.cursor || 'auto'};

  // Extreme limits for good measure
  min-height: 10px;
  max-height: 4000px;
  ${(p) => css`
    width: 100%;
    height: 100%;
    aspect-ratio: ${p.aspectRatio};
  `}
`;

export const PostCardButton: React.FC<{
  iconName: string;
  iconSize?: IconSizes;
  onClick?: () => void;
  variant?: 'fullscreen' | 'composer' | 'default';
  disabled?: boolean;
}> = ({ iconName, iconSize = '4', onClick, disabled, variant = 'default' }) => {
  return (
    <x.button
      display="flex"
      alignItems="center"
      justifyContent="center"
      border="none"
      borderRadius="full"
      cursor="pointer"
      w={8}
      h={8}
      type="button"
      backgroundColor={variant === 'fullscreen' ? 'gray.300' : 'white'}
      opacity={{ _: 0.8, hover: 0.9 }}
      onClick={onClick}
      transition
      transitionProperty="color"
      transitionDuration="fast"
      transitionTimingFunction="ease-out"
      color={{
        _: variant === 'fullscreen' ? 'white' : 'gray.500',
        hover: 'brand.300',
      }}
      disabled={disabled}
    >
      <Icon name={iconName} size={iconSize} />
    </x.button>
  );
};

type PostCardMediaProps = {
  onDelete?: (index: number) => void;
  post: PostCoreFragment;
  linkProps?: ReturnType<typeof getAnswerNavigationLinkProps>;
};

export const PostCardMedia = ({ post, linkProps }: PostCardMediaProps) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const [fullscreen, setFullscreen] = useState(false);
  const smallScreen = useDown('lg');
  const { pauseAll } = useVideoEvents();

  const handleScroll = useCallback(() => {
    if (!carouselRef.current) return;

    const index = Math.round(
      carouselRef.current.scrollLeft / carouselRef.current.clientWidth
    );

    if (index !== carouselIndex) {
      pauseAll();
    }
    setCarouselIndex(index);
  }, [carouselIndex, pauseAll]);

  const dismissLightbox = (index: number) => {
    setFullscreen(false);

    if (!carouselRef.current) return;

    carouselRef.current.scrollTo({
      left: carouselRef.current.getBoundingClientRect().width * index,
    });
  };

  useEffect(() => {
    const el = carouselRef.current;
    el?.addEventListener('scroll', handleScroll);

    return () => {
      el?.removeEventListener('scroll', handleScroll);
    };
  }, [carouselRef, handleScroll]);

  if (post.media.length === 0) return null;

  return (
    <>
      <x.div position="relative">
        <PostMediaCarousel ref={carouselRef}>
          {post.media.map((media, index) => {
            return (
              <PostMediaContainer
                key={media.id}
                aspectRatio={post.aspectRatio}
                cursor="pointer"
              >
                <PostCardMediaItem
                  media={media}
                  linkProps={linkProps}
                  i={index}
                  objectFit="cover"
                  onClick={() => !smallScreen && setFullscreen(true)}
                />
              </PostMediaContainer>
            );
          })}
        </PostMediaCarousel>
        {!fullscreen && (
          <PostCardMediaCarouselControls
            dots={post.media.length}
            carouselIndex={carouselIndex}
            variant="default"
            onCloseClick={() => setFullscreen(false)}
            onClick={(i) => {
              if (!carouselRef.current) return;
              pauseAll();
              carouselRef.current.scrollTo({
                left: carouselRef.current.getBoundingClientRect().width * i,
                behavior: 'smooth',
              });
            }}
            onPrevClick={() => {
              if (!carouselRef.current) return;
              pauseAll();
              carouselRef.current.scrollBy({
                left: -carouselRef.current.getBoundingClientRect().width,
                behavior: 'smooth',
              });
            }}
            onNextClick={() => {
              if (!carouselRef.current) return;
              pauseAll();
              carouselRef.current.scrollBy({
                left: carouselRef.current.getBoundingClientRect().width,
                behavior: 'smooth',
              });
            }}
          />
        )}
      </x.div>
      <PostLightbox
        isOpen={fullscreen}
        onDismiss={dismissLightbox}
        initialIndex={carouselIndex}
        media={post.media}
        aspectRatio={post.aspectRatio}
      />
    </>
  );
};
