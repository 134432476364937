import * as React from "react";
import { SVGProps } from "react";
const SvgPin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.091 2.083c.505-.01.99.184 1.355.548l3.923 3.923c.416.416.61.989.531 1.572a1.858 1.858 0 0 1-.926 1.375l-4.209 2.406-1.58 3.95a.624.624 0 0 1-1.022.21L7.49 13.394l-4.34 4.34a.625.625 0 1 1-.884-.884l4.34-4.34-2.673-2.673a.626.626 0 0 1 .21-1.022l3.951-1.58 2.404-4.208a1.859 1.859 0 0 1 1.594-.944Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPin;
