import * as React from "react";
import { SVGProps } from "react";

const SvgAppNetflix = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#app-netflix_svg__a)">
      <path
        d="m10.855 13.465-.01 5.06c0 4.81-.01 5.08-.06 5.08-.18 0-1.7.1-2.21.15-.33.03-1 .1-1.48.16-.49.06-.89.1-.9.09 0-.01-.01-5.41-.01-12.01V.005l4.67 13.46Zm8.32-13.45h-4.63l-.01 5.31v5.329l4.63 13.34c.02-.01.02-5.42.02-12.01l-.01-11.97Z"
        fill="#AD080F"
      />
      <path
        d="M19.165 23.995h-.04c-.08 0-.24-.01-.43-.03-1.07-.13-2.48-.26-3.62-.31-.37-.02-.68-.04-.69-.04 0 0-.29-.84-.84-2.41-.53-1.53-1.31-3.77-2.32-6.68l-.37-1.06L6.185.005h4.65l.2.57.88 2.53 7.25 20.89Z"
        fill="#DF0D12"
      />
    </g>
    <defs>
      <clipPath id="app-netflix_svg__a">
        <path fill="#fff" transform="translate(.685 .005)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAppNetflix;
