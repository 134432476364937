const shadows = {
  xs: '0px 0px 2px rgba(0, 0, 0, 0.12)',
  xs20: '0px 0px 2px rgba(0, 0, 0, 0.20)',
  sm: '0 2px 8px 0 rgba(0, 0, 0, 0.12)',
  base: '0 2px 12px 0 rgba(0, 0, 0, 0.12)',
  glossyModal: '2px 2px 30px rgba(0, 0, 0, 0.18)',
  card: '0 5px 8px rgba(0, 0, 0, 0.04)',
  drag: '16px 16px 24px rgba(0, 0, 0, 0.1)',
  question: '0 2px 24px 0 rgba(0, 0, 0, 0.12)',
  questionFocus: '0 2px 24px 0 rgba(136, 103, 202, 0.25)',
};
export type Shadows = typeof shadows;

export default shadows;
