import * as React from "react";
import { SVGProps } from "react";

const SvgAppTiktok = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.685 2.005h-18a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-18a1 1 0 0 0-1-1Zm-3.281 8.725a3.571 3.571 0 0 1-3.319-1.596v5.493a4.062 4.062 0 1 1-4.06-4.06c.085 0 .167.008.25.013v2a2.069 2.069 0 0 0-2.192 2.754 2.067 2.067 0 0 0 1.942 1.365 2.123 2.123 0 0 0 2.16-2.045l.02-9.331h1.914a3.564 3.564 0 0 0 3.285 3.182v2.225Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgAppTiktok;
