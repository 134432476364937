import { radii, theme } from '@frond/shared';

import { EXTERNAL_URL_REDIRECTS } from '../../../config/constants';

export const isInternalURL = (to: string): boolean => {
  try {
    const url = new URL(to, window.location.origin);
    return (
      url.hostname === window.location.hostname &&
      !EXTERNAL_URL_REDIRECTS.includes(url.pathname)
    );
  } catch {
    return false;
  }
};

const REGEX = {
  whitespace: /\s+/g,
  urlHexPairs: /%[\dA-F]{2}/g,
  quotes: /"/g,
};

const specialHexEncode = (match: string) => {
  switch (match) {
    case '%20':
      return ' ';
    case '%3D':
      return '=';
    case '%3A':
      return ':';
    case '%2F':
      return '/';
    default:
      return match.toLowerCase(); // compresses better
  }
};

export const svgStringToImageUrl = (svgString: string): string => {
  return `data:image/svg+xml,${encodeURIComponent(svgString).replace(
    REGEX.urlHexPairs,
    specialHexEncode
  )}`;
};

const remToPx = (rem: string) => {
  const px = Number(rem.replace('rem', '')) * 16;
  return `${px}px`;
};

export const dashedBorderSVGString = ({
  color,
  dasharray,
  radius,
}: {
  color: string;
  dasharray: string[];
  radius: keyof typeof radii;
}): string => `
  <svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'>
    <rect
      width='100%'
      height='100%'
      fill='none'
      rx='${remToPx(theme.radii[radius])}'
      ry='${remToPx(theme.radii[radius])}'
      stroke='${color}'
      stroke-width='${theme.sizes.px}'
      stroke-dasharray='${dasharray.map((num) => remToPx(num)).join(', ')}'
      stroke-dashoffset='0'
      stroke-linecap='square'
    />
  </svg>
`;

export const getDashedBorderUrl = (hover?: boolean): string => `
  url("${svgStringToImageUrl(
    dashedBorderSVGString({
      color: hover ? `${theme.colors.brand[300]}` : `${theme.colors.gray[200]}`,
      dasharray: [`${theme.sizes['4']}`, `${theme.sizes['4']}`],
      radius: 'lg',
    })
  )}")
`;

export const safelyParseJson = (json: string | null) => {
  if (!json) return null;

  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};
