import * as React from "react";

function SvgUserPlaceholder(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#user-placeholder_svg__clip0)">
        <path
          d="M12 0C8.526 0 5.699 2.833 5.699 6.316c0 3.482 2.827 6.316 6.303 6.316 3.475 0 6.303-2.834 6.303-6.316C18.304 2.833 15.476 0 12 0zm0 10.105a3.79 3.79 0 010-7.579 3.79 3.79 0 010 7.58zM23.347 24v-1.263c0-4.875-3.96-8.842-8.824-8.842H9.48c-4.866 0-8.825 3.967-8.825 8.842V24h2.521v-1.263c0-3.483 2.828-6.316 6.304-6.316h5.042c3.475 0 6.303 2.833 6.303 6.316V24h2.521z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="user-placeholder_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgUserPlaceholder;
