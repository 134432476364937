import { MyValue } from '@frond/shared';
import styled, { x } from '@xstyled/styled-components';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from '../../common/components/Button';
import { ComposerBody } from '../../common/components/Composer';

export const GroupDescriptionComposerBodyWrapper = styled.divBox`
  word-break: break-word;

  a,
  a > span {
    color: blue.300;

    :hover {
      color: blue.400;
    }
  }
`;

export const GroupDescriptionComposer = ({
  content,
  initialTruncate = true,
}: {
  content: MyValue;
  initialTruncate?: boolean;
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [truncate, setTruncate] = useState(() => initialTruncate);
  const composerBodyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (composerBodyRef.current) {
      if (
        composerBodyRef.current.scrollHeight >
        composerBodyRef.current.clientHeight
      ) {
        setCollapsed(true);
      }
    }
  }, [composerBodyRef]);
  return (
    <x.div spaceY={3}>
      <GroupDescriptionComposerBodyWrapper>
        <ComposerBody
          ref={composerBodyRef}
          trimOuterWhitespace
          content={content}
          truncate={truncate}
        />
      </GroupDescriptionComposerBodyWrapper>
      {collapsed && truncate && (
        <Button
          label="Show more"
          variant="text"
          color="blue.300"
          onClick={(e: React.MouseEvent) => {
            setTruncate(false);
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      )}
    </x.div>
  );
};
