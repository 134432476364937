import * as React from "react";
import { SVGProps } from "react";
const SvgSubscriptionColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.968 3.75H2.031c-.662 0-1.198.536-1.198 1.198v10.104c0 .662.536 1.198 1.198 1.198h15.937c.662 0 1.198-.536 1.198-1.198V4.948c0-.662-.536-1.198-1.198-1.198Z"
      fill="url(#subscription-color_svg__a)"
    />
    <path
      d="M16.875 7.917h-5.417a.208.208 0 0 1-.208-.209v-.416c0-.115.093-.209.208-.209h5.417c.115 0 .208.094.208.209v.416a.208.208 0 0 1-.208.209ZM16.042 9.583h-4.584a.208.208 0 0 1-.208-.208v-.417c0-.115.093-.208.208-.208h4.584c.115 0 .208.093.208.208v.417a.208.208 0 0 1-.208.208ZM16.042 12.917h-4.584a.208.208 0 0 1-.208-.209v-.416c0-.115.093-.209.208-.209h4.584c.115 0 .208.094.208.209v.416a.208.208 0 0 1-.208.209ZM16.875 11.25h-5.417a.208.208 0 0 1-.208-.208v-.417c0-.115.093-.208.208-.208h5.417c.115 0 .208.093.208.208v.417a.208.208 0 0 1-.208.208Z"
      fill="#7219A4"
    />
    <path
      opacity={0.05}
      d="M7.88 12.924a.568.568 0 0 1-.284-.076l-1.554-.897-1.554.896a.57.57 0 0 1-.726-.135.563.563 0 0 1-.114-.474l.373-1.755-1.333-1.2a.562.562 0 0 1-.16-.599.56.56 0 0 1 .481-.389l1.784-.188.73-1.64a.56.56 0 0 1 .519-.336c.227 0 .426.13.519.337l.73 1.639 1.784.188c.227.024.41.173.48.39a.56.56 0 0 1-.16.597l-1.332 1.2.372 1.756a.566.566 0 0 1-.556.686Z"
      fill="currentColor"
    />
    <path
      opacity={0.07}
      d="M7.88 12.715a.36.36 0 0 1-.18-.048l-1.658-.957-1.658.957a.358.358 0 0 1-.531-.386l.397-1.874-1.422-1.28a.355.355 0 0 1-.101-.38.356.356 0 0 1 .304-.245L4.934 8.3l.78-1.748a.355.355 0 0 1 .328-.213c.144 0 .27.082.328.213l.78 1.749 1.904.2c.143.016.26.11.304.247a.355.355 0 0 1-.102.378l-1.421 1.281.397 1.873a.36.36 0 0 1-.352.435Z"
      fill="currentColor"
    />
    <path
      d="m6.18 6.638.828 1.858 2.023.214c.13.014.182.175.085.262l-1.511 1.362.422 1.99a.151.151 0 0 1-.223.163L6.042 11.47 4.28 12.487a.151.151 0 0 1-.224-.163l.422-1.99-1.51-1.362a.151.151 0 0 1 .084-.262l2.023-.214.829-1.858a.15.15 0 0 1 .276 0Z"
      fill="url(#subscription-color_svg__b)"
    />
    <defs>
      <linearGradient
        id="subscription-color_svg__a"
        x1={2.03}
        y1={2.03}
        x2={19.088}
        y2={19.088}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BD4FF4" />
        <stop offset={0.587} stopColor="#A235EC" />
        <stop offset={1} stopColor="#8C20E5" />
      </linearGradient>
      <linearGradient
        id="subscription-color_svg__b"
        x1={3.775}
        y1={6.815}
        x2={8.172}
        y2={12.699}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA1C" />
        <stop offset={1} stopColor="#FEB705" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgSubscriptionColor;
