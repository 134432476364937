import { User, useUsersQuery } from '../../../../../generated/types-and-hooks';
import { USERS_PER_PAGE } from '../../../../config/constants';
import { GroupsSidebarOrganization } from '../../../groups/components/GroupsSidebar/GroupsSidebar';
import { useClientSearch } from '../../../hello/hooks/useClientSearch';

export const useAudienceSelectorSearch = ({
  useSuggestedUsers = true,
  excludedUserIds = [],
  organization,
}: {
  useSuggestedUsers?: boolean;
  excludedUserIds?: string[];
  organization: GroupsSidebarOrganization;
}) => {
  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useUsersQuery({
    variables: {
      organizationId: organization.id,
      first: USERS_PER_PAGE,
    },
    skip: !useSuggestedUsers,
  });

  const allUsers = usersData?.users.edges.map((e) => e.node) || [];

  const { setSearchTerm, results } = useClientSearch(
    [...allUsers],
    ['name', 'firstName', 'displayName', 'email']
  );

  const filteredResults = (results || []).filter(
    (r) => !(r.__typename === 'User' && excludedUserIds.includes(r.id))
  ) as User[];

  const suggestedUsers = usersData
    ? usersData.users.edges
        .map((e) => e.node)
        .filter((u) => !excludedUserIds.includes(u.id))
    : [];

  return {
    setSearchTerm,
    results: filteredResults,
    allUsers,
    suggestedUsers,
    loading: usersLoading,
    error: usersError,
  };
};
