import { x } from '@xstyled/styled-components';
import { PropsWithChildren } from 'react';

import { Text } from '../../../../common/components/Text';

export type GroupEditSettingPositioning = {
  first?: boolean;
  last?: boolean;
};

export const GroupEditSetting: React.FC<
  PropsWithChildren<
    {
      title?: string;
      toggle?: React.ReactElement;
      disabled?: boolean;
    } & GroupEditSettingPositioning
  >
> = ({ title, first, last, toggle, children, disabled = false }) => {
  return (
    <x.div
      pb={5}
      pt={first ? 0 : 5}
      borderWidth={0}
      borderStyle="solid"
      borderBottomWidth={last ? 0 : '1px'}
      borderBottomColor="gray.100"
      spaceY={2}
    >
      {(title || toggle) && (
        <x.div
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text
            color={disabled ? 'gray.300' : undefined}
            variant="md-semibold"
            as="span"
          >
            {title}
          </Text>
          {toggle}
        </x.div>
      )}
      <x.div>{children}</x.div>
    </x.div>
  );
};
