import * as React from "react";

function SvgPartyBall(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.3 7.52008C5.64375 8.40133 5.4375 9.93883 5.4375 10.8763H7.70625C7.70625 10.3138 7.7625 9.69508 7.85625 9.15133" fill="#FFECB3"/>
      <path d="M8.08125 11.2519H5.0625V10.8769C5.0625 10.4831 5.1 8.45815 6 7.29565C6.13125 7.1269 6.35625 7.0894 6.525 7.22065C6.69375 7.3519 6.73125 7.5769 6.6 7.74565C6.09375 8.42065 5.86875 9.5644 5.83125 10.5019H7.35C7.36875 10.0144 7.425 9.5269 7.5 9.0769C7.5375 8.87065 7.725 8.7394 7.93125 8.7769C8.1375 8.8144 8.26875 9.0019 8.23125 9.20815C8.1375 9.73315 8.08125 10.3331 8.08125 10.8769V11.2519V11.2519Z" fill="#37474F"/>
      <path d="M15.844 7.50133H17.8128C16.669 5.62633 14.5315 4.14508 12.0565 4.14508C13.669 4.14508 15.094 5.62633 15.844 7.50133Z" fill="#FFECB3"/>
      <path d="M18.469 7.87633H15.5815L15.4878 7.63258C14.7378 5.73883 13.3878 4.52008 12.0565 4.52008V3.77008C14.4753 3.77008 16.8003 5.12008 18.1315 7.31383L18.469 7.87633ZM16.0878 7.12633H17.1003C16.519 6.35758 15.7878 5.73883 14.9628 5.28883C15.394 5.79508 15.769 6.41383 16.0878 7.12633Z" fill="#37474F"/>
      <path d="M8.2688 14.0641H6.30005C7.4438 16.1266 9.5813 17.4204 12.0563 17.4204C10.425 17.4204 9.0188 16.1266 8.2688 14.0641Z" fill="#FFECB3"/>
      <path d="M12.0565 17.7954C9.46902 17.7954 7.20027 16.4641 5.98152 14.2516L5.68152 13.6891H8.55027L8.64402 13.9329C9.35652 15.8829 10.6315 17.0454 12.0753 17.0454V17.7954H12.0565ZM6.97527 14.4391C7.53777 15.2454 8.28777 15.9016 9.13152 16.3516C8.68152 15.8454 8.30652 15.1891 8.00652 14.4391H6.97527Z" fill="#37474F"/>
      <path d="M12.0563 4.14508C9.5813 4.14508 7.4438 5.62633 6.30005 7.50133H8.2688C9.0188 5.62633 10.425 4.14508 12.0563 4.14508Z" fill="#FFECB3"/>
      <path d="M8.5313 7.87633H5.6438L5.9813 7.31383C7.2938 5.13883 9.63755 3.77008 12.0563 3.77008V4.52008C10.7251 4.52008 9.37505 5.73883 8.62505 7.63258L8.5313 7.87633ZM7.01255 7.12633H8.02505C8.32505 6.41383 8.7188 5.79508 9.15005 5.28883C8.32505 5.73883 7.5938 6.35758 7.01255 7.12633Z" fill="#37474F"/>
      <path d="M12.0565 17.4204C14.5128 17.4204 16.669 16.1266 17.8128 14.0641H15.844C15.094 16.1266 13.669 17.4204 12.0565 17.4204Z" fill="#FFECB3"/>
      <path d="M12.0565 17.7954V17.0454C13.5003 17.0454 14.7753 15.8829 15.4878 13.9329L15.5815 13.6891H18.4503L18.1503 14.2516C16.9128 16.4641 14.644 17.7954 12.0565 17.7954ZM16.0878 14.4391C15.7878 15.1891 15.4128 15.8266 14.9628 16.3516C15.8253 15.9016 16.5565 15.2641 17.119 14.4391H16.0878Z" fill="#37474F"/>
      <path d="M18.1685 13.3875C18.4872 12.6187 18.6747 11.625 18.6747 10.875H16.406C16.406 11.4375 16.3497 12.0562 16.256 12.6" fill="#FFECB3"/>
      <path d="M18.1686 13.7628C18.1124 13.7628 18.0749 13.7628 18.0186 13.7253C17.8311 13.6503 17.7374 13.4253 17.8124 13.2378C18.0561 12.6566 18.2249 11.9066 18.2624 11.2316H16.7624C16.7436 11.7003 16.6874 12.2066 16.6124 12.6566C16.5749 12.8628 16.3874 12.9941 16.1811 12.9566C15.9749 12.9191 15.8436 12.7316 15.8811 12.5253C15.9749 12.0003 16.0311 11.4003 16.0311 10.8566V10.4816H19.0499V10.8566C19.0499 11.7003 18.8436 12.7316 18.5249 13.5191C18.4499 13.6878 18.3186 13.7628 18.1686 13.7628Z" fill="#37474F"/>
      <path d="M10.4812 10.2388C10.4812 10.4451 10.5 10.6888 10.5 10.8763H12" fill="#FFECB3"/>
      <path d="M12 11.2513H10.125V10.8763C10.125 10.8013 10.125 10.7076 10.125 10.6326C10.125 10.5013 10.1062 10.3701 10.1062 10.2388C10.1062 10.0326 10.275 9.86383 10.4812 9.86383C10.6875 9.86383 10.8562 10.0326 10.8562 10.2388C10.8562 10.3138 10.8562 10.4076 10.8562 10.5013H12C12.2062 10.5013 12.375 10.6701 12.375 10.8763C12.375 11.0826 12.2062 11.2513 12 11.2513Z" fill="#37474F"/>
      <path d="M13.4058 14.0641H10.687C10.9495 16.1266 11.4558 17.4204 12.037 17.4204C12.6183 17.4204 13.1433 16.1266 13.4058 14.0641Z" fill="#FFAB00"/>
      <path d="M12.0561 17.7954C11.0248 17.7954 10.5373 15.7891 10.3311 14.1204C10.3123 14.0079 10.3498 13.9141 10.4248 13.8204C10.4998 13.7454 10.5936 13.6891 10.7061 13.6891H13.4248C13.5373 13.6891 13.6311 13.7266 13.7061 13.8204C13.7811 13.8954 13.8186 14.0079 13.7998 14.1204C13.5748 15.7891 13.0873 17.7954 12.0561 17.7954ZM11.1186 14.4391C11.4186 16.4079 11.9061 17.0454 12.0373 17.0454C12.1686 17.0454 12.6561 16.4079 12.9561 14.4391H11.1186Z" fill="#37474F"/>
      <path d="M13.575 11.5141C13.575 11.3079 13.6125 11.0641 13.6125 10.8766H12" fill="#FFECB3"/>
      <path d="M13.575 11.8891C13.35 11.8891 13.2 11.7204 13.2 11.5141C13.2 11.4391 13.2 11.3454 13.2187 11.2516H12C11.7937 11.2516 11.625 11.0829 11.625 10.8766C11.625 10.6704 11.7937 10.5016 12 10.5016H13.9875V10.8766C13.9875 10.9891 13.9875 11.1204 13.9687 11.2329C13.9687 11.3266 13.95 11.4391 13.95 11.5141C13.95 11.7391 13.7812 11.8891 13.575 11.8891Z" fill="#37474F"/>
      <path d="M10.687 7.50133H13.4058C13.1433 5.62633 12.637 4.14508 12.0558 4.14508C11.4745 4.14508 10.9683 5.62633 10.687 7.50133Z" fill="#FFECB3"/>
      <path d="M13.8372 7.87633H10.256L10.3122 7.44508C10.556 5.77633 11.0435 3.77008 12.0372 3.77008C13.031 3.77008 13.5185 5.77633 13.7622 7.44508L13.8372 7.87633ZM11.1372 7.12633H12.9747C12.656 5.28883 12.2247 4.55758 12.056 4.52008C11.8872 4.55758 11.4372 5.28883 11.1372 7.12633Z" fill="#37474F"/>
      <path d="M18.1688 13.3895C18.0563 13.6333 17.9438 13.8958 17.8125 14.1208Z" fill="#FFECB3"/>
      <path d="M17.8125 14.4962C17.7562 14.4962 17.6812 14.4775 17.625 14.44C17.4375 14.3462 17.3812 14.1025 17.475 13.9337C17.6062 13.7087 17.7187 13.465 17.8125 13.24C17.8875 13.0525 18.1125 12.9587 18.3 13.0337C18.4875 13.1087 18.5812 13.3337 18.5062 13.5212C18.3937 13.7837 18.2625 14.0462 18.1312 14.29C18.0562 14.4212 17.9437 14.4962 17.8125 14.4962Z" fill="#37474F"/>
      <path d="M6.3 14.1191C5.75625 13.1628 5.4375 12.0566 5.4375 10.8566Z" fill="#FFECB3"/>
      <path d="M6.3 14.4941C6.16875 14.4941 6.0375 14.4191 5.98125 14.3066C5.38125 13.2566 5.0625 12.0753 5.0625 10.8566C5.0625 10.6503 5.23125 10.4816 5.4375 10.4816C5.64375 10.4816 5.8125 10.6503 5.8125 10.8566C5.8125 11.9253 6.09375 12.9941 6.61875 13.9316C6.7125 14.1191 6.65625 14.3441 6.46875 14.4378C6.43125 14.4753 6.375 14.4941 6.3 14.4941Z" fill="#37474F"/>
      <path d="M17.8125 7.53845C18.525 8.43845 18.675 9.67595 18.675 10.8572Z" fill="#FFECB3"/>
      <path d="M18.6559 11.2324C18.4497 11.2324 18.2809 11.0637 18.2809 10.8574C18.2809 9.95744 18.2059 8.66369 17.4934 7.76369C17.3622 7.59494 17.3997 7.36994 17.5497 7.23869C17.7184 7.10744 17.9434 7.14494 18.0747 7.29494C18.9184 8.36369 19.0122 9.84494 19.0122 10.8574C19.0309 11.0637 18.8622 11.2324 18.6559 11.2324Z" fill="#37474F"/>
      <path d="M13.4062 7.50165C13.5375 8.43915 13.6125 9.56415 13.6125 10.8766H16.3875C16.3875 9.56415 16.1813 8.43915 15.825 7.50165H13.4062Z" fill="#FFAB00"/>
      <path d="M16.4064 11.2516H13.6126C13.4064 11.2516 13.2376 11.0829 13.2376 10.8766C13.2376 9.63915 13.1626 8.51415 13.0314 7.5579C13.0126 7.4454 13.0501 7.3329 13.1251 7.2579C13.2001 7.1829 13.3126 7.12665 13.4064 7.12665H15.8251C15.9751 7.12665 16.1251 7.2204 16.1814 7.3704C16.5751 8.3829 16.7626 9.56415 16.7626 10.8766C16.7814 11.0829 16.6126 11.2516 16.4064 11.2516ZM13.9876 10.5016H16.0126C15.9751 9.5454 15.8251 8.66415 15.5626 7.87665H13.8376C13.9314 8.66415 13.9876 9.5454 13.9876 10.5016Z" fill="#37474F"/>
      <path d="M13.6122 11.476C13.5934 12.4135 13.5184 13.3135 13.4247 14.0635H15.8434C16.0122 13.6885 16.1622 13.0885 16.2559 12.5448" fill="#FFECB3"/>
      <path d="M16.0685 14.4388H12.9747L13.031 14.0076C13.1435 13.2576 13.1997 12.3763 13.2185 11.4576C13.2185 11.2513 13.406 11.0826 13.5935 11.0826C13.7997 11.0826 13.9685 11.2513 13.9685 11.4576C13.9497 12.2263 13.8935 12.9951 13.8185 13.6513H15.5622C15.6747 13.3513 15.7685 12.9388 15.8622 12.4513C15.8997 12.2451 16.0872 12.1138 16.2935 12.1513C16.4997 12.1888 16.631 12.3763 16.5935 12.5826C16.4997 13.0701 16.3497 13.7451 16.1435 14.1951L16.0685 14.4388Z" fill="#37474F"/>
      <path d="M7.70593 10.8766C7.70593 12.0016 7.91218 13.1266 8.26843 14.0641H10.6872C10.5747 13.1266 10.4997 12.0016 10.4997 10.8766H7.70593Z" fill="#FFECB3"/>
      <path d="M11.1185 14.4391H8.02473L7.93098 14.1954C7.55598 13.2016 7.34973 12.0204 7.34973 10.8766V10.5016H10.8747V10.8766C10.8747 11.9641 10.9497 13.0704 11.081 14.0079L11.1185 14.4391ZM8.53098 13.6891H10.256C10.1622 12.9391 10.1247 12.0954 10.106 11.2516H8.09973C8.11848 12.0954 8.28723 12.9391 8.53098 13.6891Z" fill="#37474F"/>
      <path d="M10.4999 10.1641C10.5187 9.22665 10.5937 8.43915 10.6874 7.50165H8.2687C8.09995 8.06415 7.94995 8.51415 7.8562 9.0579" fill="#FFAB00"/>
      <path d="M10.5002 10.5391C10.2752 10.5391 10.1252 10.3704 10.1252 10.1641C10.1439 9.33915 10.2002 8.62665 10.2752 7.87665H8.55016C8.41891 8.28915 8.30641 8.6829 8.23141 9.11415C8.19391 9.3204 8.00641 9.45165 7.80016 9.41415C7.59391 9.37665 7.46266 9.18915 7.50016 8.9829C7.61266 8.40165 7.76266 7.91415 7.93141 7.38915C7.98766 7.23915 8.11891 7.12665 8.28766 7.12665H10.6877C10.8002 7.12665 10.8939 7.16415 10.9689 7.2579C11.0439 7.3329 11.0814 7.4454 11.0627 7.5579C10.9689 8.4579 10.8939 9.2454 10.8752 10.2016C10.8752 10.3891 10.7064 10.5391 10.5002 10.5391Z" fill="#37474F"/>
      <path d="M14.0625 3.5625H9.75C9.54375 3.5625 9.375 3.39375 9.375 3.1875C9.375 2.98125 9.54375 2.8125 9.75 2.8125H14.0625C14.2688 2.8125 14.4375 2.98125 14.4375 3.1875C14.4375 3.39375 14.2688 3.5625 14.0625 3.5625Z" fill="#37474F"/>
      <path d="M12 3.1875C11.7937 3.1875 11.625 3.01875 11.625 2.8125V0.5625C11.625 0.35625 11.7937 0.1875 12 0.1875C12.2062 0.1875 12.375 0.35625 12.375 0.5625V2.8125C12.375 3.01875 12.2062 3.1875 12 3.1875Z" fill="#37474F"/>
      <path d="M2.45581 4.40758C2.36206 4.40758 2.26831 4.37008 2.19331 4.29508C2.04331 4.14508 2.04331 3.92008 2.19331 3.77008L4.04956 1.91383C4.19956 1.76383 4.42456 1.76383 4.57456 1.91383C4.72456 2.06383 4.72456 2.28883 4.57456 2.43883L2.71831 4.29508C2.64331 4.37008 2.54956 4.40758 2.45581 4.40758Z" fill="#37474F"/>
      <path d="M4.31206 4.40758C4.21831 4.40758 4.12456 4.37008 4.04956 4.29508L2.19331 2.43883C2.04331 2.28883 2.04331 2.06383 2.19331 1.91383C2.34331 1.76383 2.56831 1.76383 2.71831 1.91383L4.57456 3.77008C4.72456 3.92008 4.72456 4.14508 4.57456 4.29508C4.49956 4.37008 4.40581 4.40758 4.31206 4.40758Z" fill="#37474F"/>
      <path d="M20.2126 9.52367C20.1189 9.52367 20.0251 9.48617 19.9501 9.41117C19.8001 9.26117 19.8001 9.03617 19.9501 8.88617L21.8064 7.02992C21.9564 6.87992 22.1814 6.87992 22.3314 7.02992C22.4814 7.17992 22.4814 7.40492 22.3314 7.55492L20.4751 9.41117C20.4001 9.48617 20.3064 9.52367 20.2126 9.52367Z" fill="#37474F"/>
      <path d="M22.0689 9.52367C21.9751 9.52367 21.8814 9.48617 21.8064 9.41117L19.9501 7.55492C19.8001 7.40492 19.8001 7.17992 19.9501 7.02992C20.1001 6.87992 20.3251 6.87992 20.4751 7.02992L22.3314 8.88617C22.4814 9.03617 22.4814 9.26117 22.3314 9.41117C22.2564 9.48617 22.1626 9.52367 22.0689 9.52367Z" fill="#37474F"/>
      <path d="M18.9375 6.1875C18.7312 6.1875 18.5625 6.01875 18.5625 5.8125V3.9375C18.5625 3.73125 18.7312 3.5625 18.9375 3.5625C19.1438 3.5625 19.3125 3.73125 19.3125 3.9375V5.8125C19.3125 6.01875 19.1438 6.1875 18.9375 6.1875Z" fill="#37474F"/>
      <path d="M19.875 5.25H18C17.7937 5.25 17.625 5.08125 17.625 4.875C17.625 4.66875 17.7937 4.5 18 4.5H19.875C20.0813 4.5 20.25 4.66875 20.25 4.875C20.25 5.08125 20.0813 5.25 19.875 5.25Z" fill="#37474F"/>
      <path d="M3.5625 7.875C3.35625 7.875 3.1875 7.70625 3.1875 7.5V5.625C3.1875 5.41875 3.35625 5.25 3.5625 5.25C3.76875 5.25 3.9375 5.41875 3.9375 5.625V7.5C3.9375 7.70625 3.76875 7.875 3.5625 7.875Z" fill="#37474F"/>
      <path d="M4.5 6.9375H2.625C2.41875 6.9375 2.25 6.76875 2.25 6.5625C2.25 6.35625 2.41875 6.1875 2.625 6.1875H4.5C4.70625 6.1875 4.875 6.35625 4.875 6.5625C4.875 6.76875 4.70625 6.9375 4.5 6.9375Z" fill="#37474F"/>
      <path d="M1.98706 22.4826C1.89331 22.4826 1.79956 22.4451 1.72456 22.3701C1.57456 22.2201 1.57456 21.9951 1.72456 21.8451L6.09331 17.4763C6.24331 17.3263 6.46831 17.3263 6.61831 17.4763C6.76831 17.6263 6.76831 17.8513 6.61831 18.0013L2.24956 22.3701C2.17456 22.4451 2.08081 22.4826 1.98706 22.4826Z" fill="#37474F"/>
      <path d="M6.54324 23.8513C6.48699 23.8513 6.43074 23.8326 6.39324 23.8138C6.20574 23.7201 6.13074 23.5138 6.20574 23.3076L8.32449 18.6951C8.41824 18.5076 8.64324 18.4326 8.83074 18.5076C9.01824 18.6013 9.09324 18.8076 9.01824 19.0138L6.88074 23.6263C6.82449 23.7763 6.69324 23.8513 6.54324 23.8513Z" fill="#37474F"/>
      <path d="M1.63074 17.5712C1.49949 17.5712 1.38699 17.515 1.31199 17.3837C1.19949 17.215 1.25574 16.9712 1.44324 16.8587L4.44325 15.0025C4.612 14.89 4.85574 14.9462 4.96824 15.1337C5.08074 15.3025 5.02449 15.5462 4.83699 15.6587L1.81824 17.515C1.76199 17.5525 1.70574 17.5712 1.63074 17.5712Z" fill="#37474F"/>
      <path d="M22.1247 22.4826C22.031 22.4826 21.9372 22.4451 21.8622 22.3701L17.4935 18.0014C17.3435 17.8514 17.3435 17.6264 17.4935 17.4764C17.6435 17.3264 17.8685 17.3264 18.0185 17.4764L22.3872 21.8451C22.5372 21.9951 22.5372 22.2201 22.3872 22.3701C22.3122 22.4451 22.2185 22.4826 22.1247 22.4826Z" fill="#37474F"/>
      <path d="M17.5683 23.8493C17.4183 23.8493 17.2871 23.7743 17.2308 23.6243L15.1121 19.0118C15.0183 18.8243 15.1121 18.5993 15.2996 18.5056C15.4871 18.4118 15.7121 18.5056 15.8058 18.6931L17.9246 23.3056C18.0183 23.4931 17.9246 23.7181 17.7371 23.8118C17.6621 23.8493 17.6246 23.8493 17.5683 23.8493Z" fill="#37474F"/>
      <path d="M22.4804 17.5687C22.4054 17.5687 22.3492 17.55 22.2929 17.5125L19.2742 15.6562C19.1054 15.5437 19.0492 15.3187 19.1429 15.1312C19.2554 14.9625 19.4804 14.9062 19.6679 15L22.6867 16.8562C22.8554 16.9687 22.9117 17.1937 22.8179 17.3812C22.7242 17.4937 22.6117 17.5687 22.4804 17.5687Z" fill="#37474F"/>
    </svg>
  );
}

export default SvgPartyBall;
