import * as React from "react";

function SvgAppZendesk(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-zendesk_svg__clip0)">
        <path
          d="M11.485 5.676v7.46H5.308l6.177-7.46zm0-2.676A3.085 3.085 0 018.4 6.086 3.09 3.09 0 015.308 3h6.177zm1.018 10.136a3.085 3.085 0 116.17 0h-6.17zm0-2.676V3h6.177l-6.177 7.46zm3.455 9.052a1.22 1.22 0 00.86-.33l.422.455c-.277.29-.667.502-1.275.502-1.037 0-1.705-.687-1.705-1.619a1.587 1.587 0 011.553-1.618h.053c1.03 0 1.612.78 1.56 1.87H15c.086.455.403.746.958.74zm.74-1.25c-.066-.422-.317-.733-.819-.733-.47 0-.793.265-.879.734h1.698zM.002 19.479l1.87-1.902H.048v-.595h2.69v.608l-1.87 1.903h1.896v.594H.002v-.607zm4.863.034c.317.006.628-.113.852-.33l.423.455c-.277.29-.667.502-1.275.502-1.038 0-1.705-.687-1.705-1.619a1.587 1.587 0 011.553-1.618h.053c1.03 0 1.612.78 1.559 1.87H3.907c.086.455.403.746.958.74zm.74-1.25c-.066-.422-.317-.733-.82-.733-.469 0-.792.265-.878.734h1.698zm4.77.265c0-.991.74-1.612 1.56-1.612a1.36 1.36 0 011.037.462v-1.83h.66v4.533h-.66v-.43c-.258.31-.641.496-1.044.49-.793 0-1.553-.628-1.553-1.613zm2.63-.007a.988.988 0 00-1.011-.958.987.987 0 00-.958 1.011c.013.535.45.958.984.958.569 0 .985-.449.985-1.01zm4.856.9l.601-.312c.159.291.47.463.8.456.377 0 .568-.191.568-.41 0-.25-.363-.303-.753-.382-.529-.113-1.077-.285-1.077-.925 0-.49.469-.945 1.202-.939.582 0 1.011.232 1.256.602l-.555.303a.824.824 0 00-.7-.356c-.357 0-.536.171-.536.37 0 .224.284.284.734.383.508.112 1.09.277 1.09.925 0 .43-.377 1.004-1.262.998-.648.006-1.104-.251-1.368-.714zm4.42-.721l-.522.575v.806h-.66v-4.533h.66v2.967l1.401-1.54h.806l-1.216 1.328L24 20.08h-.747l-.972-1.381zM8.38 16.909c-.786 0-1.447.508-1.447 1.354v1.817h.674v-1.731c0-.51.29-.813.793-.813.502 0 .746.304.746.813v1.73h.668v-1.816c.006-.846-.655-1.355-1.434-1.355z"
          fill="#03363D"
        />
      </g>
      <defs>
        <clipPath id="app-zendesk_svg__clip0">
          <path fill="#fff" transform="translate(0 3)" d="M0 0h24v17.146H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppZendesk;
