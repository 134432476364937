import { comboboxActions, comboboxSelectors } from '@udecode/plate-combobox';

export const mentionOnChangeHandler = () => () => {
  const filteredItems = comboboxSelectors.filteredItems();
  const text = comboboxSelectors.text();
  const isOpen = comboboxSelectors.isOpen();
  if (!filteredItems.length && text !== '' && isOpen) {
    comboboxActions.floatingOptions({
      open: false,
    });
  } else {
    comboboxActions.floatingOptions({
      open: true,
    });
  }
};
