import styled, { css, keyframes } from '@xstyled/styled-components';

export interface LoadingPulseProps {
  /**
   * boolean to animate spinner
   * used in stories so chromatic doesn't show changes
   */
  animate?: boolean;
}

const pulsing = keyframes`
  50% {
    transform: scale(0.75);
    opacity: 0.2;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const Pulse = styled.span<{
  animate: boolean;
  offset: number;
}>`
  display: inline-block;
  background-color: purple.300;
  width: 2;
  height: 2;
  margin: 0.125rem;
  border-radius: full;
  animation: ${(p) => css`
    ${pulsing} 0.7s ${p.offset}s infinite linear normal both ${p.animate
      ? 'running'
      : 'stopped'};
  `};
`;

export const LoadingPulse: React.FC<LoadingPulseProps> = ({
  animate = true,
}) => {
  return (
    <span>
      <Pulse animate={animate} offset={0} />
      <Pulse animate={animate} offset={0.35} />
      <Pulse animate={animate} offset={0} />
    </span>
  );
};

LoadingPulse.displayName = 'LoadingPulse';
