import { GoogleAnalytics as GoogleAnalyticsOrig } from '@next/third-parties/google';

export const GoogleAnalytics = () => {
  const gaId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || '';

  if (!gaId) return null;

  return <GoogleAnalyticsOrig gaId={gaId} />;
};

export function sendGAEvent(name: string, data: object) {
  const key = 'gtag';
  const gtag = window[key as keyof typeof window];
  gtag?.('event', name, data);
}
