import { SystemProps } from '@xstyled/system';
import { useCombobox, useMultipleSelection } from 'downshift';
import find from 'lodash/find';
import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import isEmailValidator from 'validator/lib/isEmail';
import { string } from 'yup';

import { GroupsSidebarOrganization } from '../../../groups/components/GroupsSidebar/GroupsSidebar';
import { AudienceSelectorInput } from './AudienceSelectorInput';
import { AudienceSelection } from './AudienceSelectorTypes';
import {
  createAudienceSelection,
  createAudienceSelectionEmail,
  createAudienceSelectionEveryone,
  createAudienceSelectionUser,
  getAudienceSelectionId,
} from './AudienceSelectorUtils';
import { useAudienceSelectorSearch } from './useAudienceSelectorSearch';

type AudienceSelectorProps = {
  initialAudience?: AudienceSelection[];
  onChange: (audience: AudienceSelection[]) => void;
  renderMenuItemStatus?: (
    audience: AudienceSelection
  ) => React.ReactElement | null;
  renderTokenElement?: (
    audience: AudienceSelection,
    onRemove: () => void,
    onUpdate: (as: AudienceSelection) => void
  ) => React.ReactElement | null;
  initialIsOpen?: boolean;
  showSelectedTokens?: boolean;
  showSuggestedEveryoneGroup?: boolean;
  showSuggestedUsers?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  excludedUserIds?: string[];
  isValidSelection?: (audienceSelection: AudienceSelection) => boolean;
  filterInitialSuggestions?: (
    initialSuggestions: AudienceSelection[]
  ) => AudienceSelection[];
  organization: GroupsSidebarOrganization;
  allowEmails?: boolean;
};

export const AudienceSelector: FC<
  PropsWithChildren<AudienceSelectorProps & SystemProps>
> = (props) => {
  const {
    initialAudience,
    onChange,
    renderMenuItemStatus,
    renderTokenElement,
    initialIsOpen,
    showSelectedTokens = true,
    showSuggestedEveryoneGroup = true,
    showSuggestedUsers = true,
    placeholder,
    children,
    isValidSelection = () => true,
    autoFocus,
    filterInitialSuggestions,
    excludedUserIds,
    organization,
    allowEmails = true,
    ...systemProps
  } = props;
  const { setSearchTerm, results, suggestedUsers } = useAudienceSelectorSearch({
    useSuggestedUsers: showSuggestedUsers,
    excludedUserIds: excludedUserIds,
    organization,
  });

  /****
   * State
   */
  const [inputValue, setInputValue] = useState('');
  const multipleSelection = useMultipleSelection<AudienceSelection>({
    initialSelectedItems: initialAudience || [],
    onSelectedItemsChange: ({ selectedItems }) => onChange(selectedItems || []),
  });
  const { selectedItems, addSelectedItem } = multipleSelection;
  const everyoneSelected = !!selectedItems.find(
    (item) => item.type === 'everyone'
  );

  const filteredCandidates = useMemo(() => {
    // If there's no input value show suggestions
    if (!inputValue && !selectedItems?.length) {
      const initialSuggestions = [
        ...(showSuggestedEveryoneGroup
          ? [createAudienceSelectionEveryone()]
          : []),

        ...suggestedUsers.map((user) => createAudienceSelectionUser(user)),
      ];

      return filterInitialSuggestions
        ? filterInitialSuggestions(initialSuggestions)
        : initialSuggestions;
    }

    // If the input is a valid email just return it, unless it looks like
    // we already have a user with that email address
    if (
      allowEmails &&
      inputValue &&
      !results.length &&
      string()
        .email()
        .required()
        .test((v) => isEmailValidator(v))
        .isValidSync(inputValue)
    ) {
      return [createAudienceSelectionEmail(inputValue.toLowerCase())];
    }

    if (!results) return [];

    return results.reduce<AudienceSelection[]>((items, result) => {
      const selection = createAudienceSelection(result);
      selection && items.push(selection);
      return items;
    }, []);
  }, [
    inputValue,
    selectedItems,
    suggestedUsers,
    results,
    showSuggestedEveryoneGroup,
    filterInitialSuggestions,
    allowEmails,
  ]);

  const dedupedFilteredCandidates = filteredCandidates.filter((candidate) => {
    // If 'Everyone' is selected, filter existing Frond users
    if (everyoneSelected && candidate.type === 'user') {
      return false;
    }

    // Filter any items already added to the list
    return !find(
      selectedItems,
      (selectedItem) =>
        getAudienceSelectionId(selectedItem) ===
        getAudienceSelectionId(candidate)
    );
  });

  /****
   * Combobox
   */
  const combobox = useCombobox<AudienceSelection>({
    inputValue,
    items: dedupedFilteredCandidates,
    defaultHighlightedIndex: 0,
    stateReducer: (_, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
          };
      }
      return changes;
    },
    initialIsOpen,
    onStateChange: ({ inputValue: newInputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue || '');
          setSearchTerm(newInputValue || '');
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          selectedItem && resolveSelection(selectedItem);
          break;
        case useCombobox.stateChangeTypes.InputBlur:
          selectedItem && resolveSelection(selectedItem);
          // Also add entered emails that are valid but haven't been selected
          if (
            inputValue &&
            string()
              .email()
              .required()
              .test((v) => isEmailValidator(v))
              .isValidSync(inputValue)
          ) {
            addSelectedItem(
              createAudienceSelectionEmail(inputValue.toLowerCase())
            );
            setInputValue(newInputValue || '');
            setSearchTerm(newInputValue || '');
          }
          break;
        default:
          break;
      }
    },
  });

  const handlePaste = (e: ClipboardEvent) => {
    e.stopPropagation();

    const text = e.clipboardData?.getData('text/plain');
    let oneEmailResolved = false;

    if (text && allowEmails) {
      const separatedValuesByCommaOrSpace = text.split(
        text.includes(',') ? ',' : ' '
      );

      if (separatedValuesByCommaOrSpace.length > 1) {
        for (const email of separatedValuesByCommaOrSpace) {
          const normalizedEmail = email.trim().toLowerCase();
          if (
            email &&
            string()
              .email()
              .required()
              .test((v) => isEmailValidator(v))
              .isValidSync(normalizedEmail)
          ) {
            const emailAlreadyExists = selectedItems.find(
              (item) =>
                item.type === 'email' && item.payload === normalizedEmail
            );
            if (!emailAlreadyExists) {
              addSelectedItem(createAudienceSelectionEmail(normalizedEmail));
            }
            oneEmailResolved = true;
          }
        }
      }
    }

    if (oneEmailResolved) {
      e.preventDefault();
    }
  };

  const resolveSelection = (selectedItem: AudienceSelection) => {
    if (selectedItem && isValidSelection(selectedItem)) {
      setInputValue('');
      setSearchTerm('');
      addSelectedItem(selectedItem);
    }
  };

  return (
    <AudienceSelectorInput
      audienceSelection={dedupedFilteredCandidates}
      multipleSelection={multipleSelection}
      combobox={combobox}
      placeholder={placeholder}
      showSelectedTokens={showSelectedTokens}
      renderMenuItemStatus={renderMenuItemStatus}
      renderTokenElement={renderTokenElement}
      autoFocus={autoFocus}
      onPaste={handlePaste}
      {...systemProps}
    >
      {children}
    </AudienceSelectorInput>
  );
};
