import {
  EventCoreFragment,
  refetchEventQuery,
  useToggleEventNotificationsMutation,
} from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Button } from '../../common/components/Button';
import { Icon } from '../../common/components/Icon';
import { useEventAttendance } from '../hooks/useEventAttendance';

export const EventNotificationsButton: React.FC<{
  event: EventCoreFragment;
}> = ({ event }) => {
  const { organization } = useOrganization();
  const { userIsMember } = useEventAttendance({ event, organization });

  const [mutationEventNotifications, { loading }] =
    useToggleEventNotificationsMutation({
      variables: {
        input: {
          eventId: event.id,
        },
      },
      refetchQueries: [
        refetchEventQuery({
          id: event.id,
        }),
      ],
    });

  if (!userIsMember) return <></>;

  return (
    <Button
      p={2}
      variant="secondary"
      loading={loading}
      leftElement={
        <Icon
          name="bell-filled"
          color={
            event.membership?.allowEventNotifications ? 'brand.300' : 'gray.300'
          }
        />
      }
      onClick={() => mutationEventNotifications()}
    />
  );
};
