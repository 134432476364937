const UPLOAD_URL = 'https://api.cloudinary.com/v1_1/frond/upload';
const UNSIGNED_PRESET = 'unsigned-upload-preset';

export interface CloudinaryResponse {
  public_id: string;
  version: number;
  signature: string;
  width: number;
  height: number;
  format: string;
  aspect_ratio: number;
  pixels: number;
  resource_type: string;
  created_at: string;
  tags: Array<string>;
  pages: number;
  bytes: number;
  type: string;
  etag: string;
  placeholder: boolean;
  url: string;
  secure_url: string;
  access_mode: string;
  original_filename: string;
}

export class UploadError extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'UploadError';
  }
}

export const uploadFile = (
  file: File,
  userId: string,
  organizationId?: string,
  tags?: Array<string>,
  onProgress?: (progress: number) => void
): Promise<CloudinaryResponse> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    xhr.open('POST', UPLOAD_URL, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    // Update progress (can be used to show progress indicator)
    // returns percentage number 0 -> 100
    if (onProgress) {
      xhr.upload.addEventListener('progress', (e) => {
        onProgress(Math.round((e.loaded * 100) / e.total));
      });
    }

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const uploadData = JSON.parse(xhr.responseText) as CloudinaryResponse;
          resolve(uploadData);
        } else {
          reject(new UploadError('Upload failed'));
        }
      }
    };

    formData.append('upload_preset', UNSIGNED_PRESET);
    formData.append('file', file);

    if (tags) {
      formData.append('tags', `${organizationId},${userId},${tags.join(',')}`);
    } else {
      formData.append('tags', `${organizationId},${userId}`);
    }
    // adding organization + userId as metadata as well as tags
    formData.append(
      'context',
      `organizationId=${organizationId}|userId=${userId}`
    );
    xhr.send(formData);
  });
};
