import { GroupCreationPolicy } from '../../../../generated/types-and-hooks';
import { useIsViewerAdmin, useViewer } from '../../auth/hooks/useViewer';
import { GroupsSidebarOrganization } from '../../groups/components/GroupsSidebar/GroupsSidebar';

export const useCanViewerCreateGroups = ({
  organization,
}: {
  organization: GroupsSidebarOrganization;
}) => {
  const { viewer } = useViewer();
  const isAdmin = useIsViewerAdmin(organization);

  if (!viewer) return false;

  const { groupCreationPolicy } = organization;
  return groupCreationPolicy === GroupCreationPolicy.Unrestricted || isAdmin;
};
