import * as React from 'react';
import { SVGProps } from 'react';
const SvgEye = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#eye_svg__a)">
      <path
        d="M9.994 3.75c-4.716 0-8.872 3.27-9.974 7.553a.625.625 0 0 0 1.21.31C2.18 7.924 5.86 5 9.994 5c4.134 0 7.826 2.924 8.776 6.614a.625.625 0 0 0 1.21-.311C18.878 7.018 14.71 3.75 9.994 3.75Zm.007 3.333a4.036 4.036 0 0 0-4.027 4.027 4.037 4.037 0 0 0 4.027 4.027 4.037 4.037 0 0 0 4.027-4.027 4.037 4.037 0 0 0-4.027-4.027Zm0 1.25a2.768 2.768 0 0 1 2.777 2.777 2.768 2.768 0 0 1-2.777 2.777 2.768 2.768 0 0 1-2.777-2.777 2.767 2.767 0 0 1 2.777-2.777Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="eye_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgEye;
