import * as React from "react";

function SvgCircleDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 100-18 9 9 0 000 18zm4.707-10.293l-1.414-1.414L12 12.586 8.707 9.293l-1.414 1.414L12 15.414l4.707-4.707z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCircleDown;
