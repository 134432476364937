import { useApolloClient } from '@apollo/client';
import { EMPTY, from, map } from 'rxjs';

import {
  OrganizationByShortIdDocument,
  OrganizationByShortIdQuery,
  OrganizationByShortIdQueryVariables,
  ViewerDocument,
  ViewerQuery,
  ViewerQueryVariables,
} from '../../../../generated/types-and-hooks';
import { AppContext } from './appMachine';

export const useAppMachineServices = () => {
  const client = useApolloClient();

  const initializeUser = () =>
    client.query<ViewerQuery, ViewerQueryVariables>({
      query: ViewerDocument,
    });

  const initializeOrganization = (context: AppContext) => {
    if (!context.shortId) return Promise.reject();
    return client.query<
      OrganizationByShortIdQuery,
      OrganizationByShortIdQueryVariables
    >({
      query: OrganizationByShortIdDocument,
      variables: {
        shortId: context.shortId,
      },
    });
  };

  const watchUserQuery = (context: AppContext) => {
    if (!context.isAuthenticated) return EMPTY;
    return from(
      client.watchQuery<ViewerQuery, ViewerQueryVariables>({
        query: ViewerDocument,
        fetchPolicy: 'cache-only',
      })
    ).pipe(
      map((data: any) => {
        return {
          type: 'UPDATE_USER',
          user: data.data.viewer,
        };
      })
    );
  };

  const watchOrganizationQuery = (context: AppContext) => {
    if (!context.shortId) return EMPTY;
    return from(
      client.watchQuery<
        OrganizationByShortIdQuery,
        OrganizationByShortIdQueryVariables
      >({
        query: OrganizationByShortIdDocument,
        fetchPolicy: 'cache-only',
        variables: {
          shortId: context.shortId,
        },
      })
    ).pipe(
      map((data: any) => {
        return {
          type: 'UPDATE_ORGANIZATION',
          organization: data.data.organizationByShortId,
        };
      })
    );
  };

  return {
    initializeUser,
    initializeOrganization,
    watchUserQuery,
    watchOrganizationQuery,
  };
};
