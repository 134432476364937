import * as React from "react";
import { SVGProps } from "react";
const SvgBookmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.25 2.5A3.262 3.262 0 0 0 5 5.75v15a.75.75 0 0 0 1.188.608L12 17.174l5.811 4.184A.75.75 0 0 0 19 20.75v-15a3.262 3.262 0 0 0-3.25-3.25h-7.5Zm0 1.5h7.5c.975 0 1.75.775 1.75 1.75v13.536l-5.062-3.644a.75.75 0 0 0-.877 0L6.5 19.286V5.75C6.5 4.775 7.275 4 8.25 4Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgBookmark;
