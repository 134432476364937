import {
  OnboardingProfileInputSchema,
  OnboardingProfileInputSchemaNoOrg,
} from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import { ViewerQuery } from '../../../../../generated/types-and-hooks';
import { Input } from '../../../common/components/Input';
import { ProfileAvatarInput } from '../../../common/components/ProfileAvatarInput';
import { MAX_LENGTH_FOR_INPUT } from '../../../common/config/constants';
import { AppMachineOrganization } from '../../../common/machine/appMachine';
import { extractError } from '../../../hello/utils/form.utils';
import { UsernameInput } from './UsernameInput';

export type ProfileFormContext =
  | 'hello_onboarding'
  | 'groups_onboarding'
  | 'user_account_settings';

export type ProfileFormProps = {
  user?: ViewerQuery['viewer'];
  organization?: AppMachineOrganization | null;
  showRole?: boolean;
  showBio?: boolean;
  showOrganization?: boolean;
  context: ProfileFormContext;
};

export type ProfileFormData =
  | yup.InferType<typeof OnboardingProfileInputSchema>
  | yup.InferType<typeof OnboardingProfileInputSchemaNoOrg>;

export const ProfileForm: React.FC<ProfileFormProps> = ({
  user,
  organization,
  showRole = false,
  showBio = false,
  showOrganization = false,
  context,
}) => {
  const firstTeamMemberLogin = !organization?.isSetup;
  const form = useFormContext<ProfileFormData>();
  const { formState } = form;
  const { t } = useTranslation();

  const profileImageId = useWatch({
    control: form.control,
    name: 'profileImageId',
    defaultValue: user?.profileImageId,
  });

  const formSetOptions = {
    shouldDirty: true,
    shouldValidate: true,
  };

  return (
    <>
      <x.div
        display="flex"
        flexDirection="column"
        justifyContent="center"
        spaceY={4}
        w="full"
        maxWidth="512px"
        mx="auto"
      >
        <x.div mb={7}>
          <ProfileAvatarInput
            name="profileImageId"
            profileImageId={profileImageId}
            onUploadSuccess={(image) => {
              if ('publicId' in image) {
                form.setValue('profileImageId', image.publicId, formSetOptions);
              }
            }}
            onRemove={() => {
              form.setValue('profileImageId', null, formSetOptions);
              form.setValue('avatar', user?.avatar, formSetOptions);
              form.setValue(
                'avatarBgColor',
                user?.avatarBgColor,
                formSetOptions
              );
            }}
          />
        </x.div>
        <x.div display="grid" gap={3} boxSizing="border-box">
          <input type="hidden" value={user?.id} {...form.register('userId')} />
          <UsernameInput variant="setup" />
          <Input
            {...form.register('firstName')}
            label={t('profile_form_first_name_label')}
            maxLength={MAX_LENGTH_FOR_INPUT}
            metadata={{ context }}
            error={extractError<ProfileFormData>(formState, 'firstName')}
            fullWidth
          />
          <Input
            {...form.register('lastName')}
            label={t('profile_form_last_name_label')}
            metadata={{ context }}
            error={extractError<ProfileFormData>(formState, 'lastName')}
            fullWidth
          />
          {showBio && (
            <Input
              {...form.register('bio')}
              label={t('profile_form_bio_label')}
              metadata={{ context }}
              error={extractError<ProfileFormData>(formState, 'bio')}
              fullWidth
            />
          )}
          {showRole && (
            <Input
              {...form.register('position')}
              label={t('profile_form_role_label')}
              metadata={{ context }}
              error={extractError<ProfileFormData>(formState, 'position')}
              fullWidth
            />
          )}
          {showOrganization && organization?.isGoogleWorkspace && (
            <Input
              {...form.register('organizationName')}
              name="organizationName"
              label={t('profile_form_organization_label')}
              disabled={!firstTeamMemberLogin}
              maxLength={MAX_LENGTH_FOR_INPUT}
              metadata={{ context }}
              error={extractError<ProfileFormData>(
                formState,
                'organizationName'
              )}
              fullWidth
            />
          )}

          <Input
            name="email"
            label={t('profile_form_email_label')}
            disabled
            value={user?.email}
            metadata={{ context }}
            required
            fullWidth
          />
        </x.div>
      </x.div>
    </>
  );
};
