import styled, { system, SystemProps } from '@xstyled/styled-components';
import { pickBy, startsWith } from 'lodash';
import { default as INextLink, LinkProps as INextLinkProps } from 'next/link';
import { NextRouter, useRouter } from 'next/router';
import { FC, PropsWithChildren } from 'react';

import { EXTERNAL_URL_REDIRECTS } from '../../../config/constants';
import { aliases } from '../../questions/routes';
import { AnalyticEventName } from '../utils/analytics';
import { linkMixin, LinkProps, NavLink, StyledAnchor } from './Link';

interface NextLinkProps extends INextLinkProps, Omit<SystemProps, 'color'> {
  /**
   * Whether to apply color of Link component styling
   */
  styleInheritColor?: boolean;
  /**
   * extra context to send with button click action
   */
  metadata?: { event?: AnalyticEventName; context?: string };
}

const StyledSpan = styled.span<LinkProps & SystemProps>`
  ${system};

  > a {
    ${linkMixin};
  }
`;

/**
 * Component used for any client side link transitions
 */
export const NextLink: FC<
  PropsWithChildren<NextLinkProps & Omit<SystemProps, 'color'>>
> = ({ children, metadata, ...props }) => {
  if (
    typeof props.href === 'string' &&
    EXTERNAL_URL_REDIRECTS.includes(props.href)
  ) {
    return (
      <StyledAnchor
        href={props.href as string}
        styleInheritColor={props.styleInheritColor}
        metadata={metadata}
      >
        {children}
      </StyledAnchor>
    );
  }

  const { styleInheritColor, minWidth } = props;

  return (
    <StyledSpan styleInheritColor={styleInheritColor} minWidth={minWidth}>
      <INextLink {...props} passHref>
        {children}
      </INextLink>
    </StyledSpan>
  );
};

interface NextNavLinkProps extends INextLinkProps {
  /**
   * Matching should be exact and not just first part of path
   */
  exact?: boolean;
  /**
   * extra context to send with button click action
   */
  metadata?: { event?: AnalyticEventName; context?: string };
}

/**
 * Aliases are used for fine grained control of active state in NextNavLink
 */
export type NextNavLinkAliasDefinition = {
  [path: string]: (router: NextRouter) => string | null;
};

/**
 * Component used to pass active prop if current route path matches href
 */
export const NextNavLink: FC<PropsWithChildren<NextNavLinkProps>> = ({
  exact,
  metadata,
  children,
  ...props
}) => {
  const router = useRouter();
  const active = exact
    ? router.asPath === props.href
    : router.asPath.startsWith(props.href as string);

  const [pathAlias] = Object.keys(
    pickBy(aliases, (_, path) => startsWith(router.asPath, path))
  );
  const pathAliasActive = !!(
    pathAlias && props.href === aliases[pathAlias](router)
  );

  return (
    <StyledSpan styleInheritColor>
      <INextLink {...props} passHref>
        <NavLink active={active || pathAliasActive} metadata={metadata}>
          {children}
        </NavLink>
      </INextLink>
    </StyledSpan>
  );
};
