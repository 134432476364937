import { SystemProps, x } from '@xstyled/styled-components';
import getBlobDuration from 'get-blob-duration';
import HLS from 'hls.js';
import React, { RefObject, useEffect, useRef } from 'react';

import analytics from '../../common/utils/analytics';
import { isHls } from './Video/Video.utils';

export type VideoCoreProps = {
  // Video URL
  src?: string;

  // Start the video as soon as it's ready
  autoPlay?: boolean;

  // Play the video without audio
  muted?: boolean;

  // Restart the video once it finishes
  loop?: boolean;

  // Called when ready to play
  onCanPlay?: (e: Event) => void;

  // Callback for playback errors
  onError?: (e: MediaError | null) => void;

  // Callback when playback starts and stops
  onPlaybackStatusChange?: (playing: boolean) => void;

  // Callback when buffering starts and stops
  onBufferingStatusChange?: (buffering: boolean) => void;

  // Callback when video is muted or unmuted
  onMutedStatusChange?: (muted: boolean) => void;

  // Callback fired when video duration (in seconds) is available
  onLoadedDuration?: (duration: number) => void;

  // Callback fired periodically during playback
  onTimeUpdate?: () => void;

  // Poster URL
  poster?: string;

  // Preload
  preload?: 'auto';

  style?: React.CSSProperties;
} & SystemProps;

/**
 * Low-level component that handles HLS streams, event listeners and playback state
 */
export const VideoCore = React.forwardRef<HTMLVideoElement, VideoCoreProps>(
  (
    {
      autoPlay,
      src,
      onCanPlay,
      onError,
      onMutedStatusChange,
      onTimeUpdate,
      onLoadedDuration,
      onPlaybackStatusChange,
      onBufferingStatusChange,
      muted,
      loop,
      ...system
    },
    forwardedVideoRef
  ) => {
    // Refs
    const localVideoRef = useRef<HTMLVideoElement>();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - this can't be null
    const videoRef: RefObject<HTMLVideoElement> =
      forwardedVideoRef || localVideoRef;
    const hlsRef = useRef<HLS>();

    // Playback event listeners
    useEffect(() => {
      const el = videoRef.current;
      if (!el) {
        return;
      }

      onTimeUpdate && (el.ontimeupdate = onTimeUpdate);

      el.onwaiting = () => {
        onBufferingStatusChange?.(true);

        el.addEventListener('canplay', () => onBufferingStatusChange?.(false), {
          once: true,
        });
      };

      el.onended = () => onBufferingStatusChange?.(false);

      el.onplaying = () => {
        onBufferingStatusChange?.(false);
        onPlaybackStatusChange?.(true);
      };

      el.onplay = () => {
        analytics.logEvent(analytics.events.VIDEO_PLAY, { src });
      };

      el.onseeking = () => onBufferingStatusChange?.(true);
      el.onseeked = () => onBufferingStatusChange?.(false);
      el.onpause = () => {
        analytics.logEvent(analytics.events.VIDEO_PAUSE, { src });
        onPlaybackStatusChange?.(false);
      };

      el.onloadedmetadata = (event) => {
        const target = event.target as MediaSource;
        onLoadedDuration?.(target.duration);
      };

      el.onerror = () => {
        onError?.(el?.error || null);
      };

      el.onvolumechange = () => {
        onMutedStatusChange?.(el?.muted);
      };
    }, [
      videoRef,
      src,
      onError,
      onMutedStatusChange,
      onBufferingStatusChange,
      onPlaybackStatusChange,
      onLoadedDuration,
      onTimeUpdate,
    ]);

    // HLS setup
    useEffect(() => {
      const el = videoRef.current;

      if (!src || !el) {
        return;
      }

      el.addEventListener(
        'canplay',
        (event: Event) => {
          onCanPlay?.(event);
          onBufferingStatusChange?.(false);

          if (autoPlay) {
            el
              .play()
              ?.then()
              .catch(() => onPlaybackStatusChange?.(false));
          }
        },
        { once: true }
      );

      // Resets
      onPlaybackStatusChange?.(false);
      onLoadedDuration?.(0);

      if (!isHls(src)) {
        el.src = src;
        /**
         * Work around a bug in Chrome by reading the duration
         * immediately from Object URLs
         * https://www.npmjs.com/package/get-blob-duration
         */
        getBlobDuration(src).then((duration) => {
          onLoadedDuration?.(duration);
        });

        return;
      }

      /**
       * For HLS video, use native support if available (Safari)
       * and fall back to hls.js
       */
      if (el.canPlayType('application/vnd.apple.mpegurl')) {
        el.src = src;
      } else if (HLS.isSupported()) {
        /**
         * Destroy the HLS context when src switches
         * https://github.com/video-dev/hls.js/issues/2473
         */
        if (hlsRef.current) {
          hlsRef.current.destroy();
        }

        hlsRef.current = new HLS();
        hlsRef.current.loadSource(src);
        hlsRef.current.attachMedia(el);
      }
    }, [
      src,
      hlsRef,
      videoRef,
      autoPlay,
      onCanPlay,
      onLoadedDuration,
      onPlaybackStatusChange,
      onBufferingStatusChange,
    ]);

    return <x.video ref={videoRef} loop={loop} muted={muted} {...system} />;
  }
);

VideoCore.displayName = 'VideoCore';
