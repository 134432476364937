/**
 * Make a naive judgement whether the provided source
 * should be handled as an HLS stream
 */
export const isHls = (src: string): boolean => {
  return src.endsWith('.m3u8');
};

/**
 * Formats seconds to mm:ss format
 */
export const formatSecondsToMMSS = (totalSeconds: number): string => {
  const flooredTotalSeconds = Math.floor(totalSeconds);
  const minutes = Math.floor(flooredTotalSeconds / 60);
  const seconds = ('0' + (flooredTotalSeconds - minutes * 60)).slice(-2);

  return `${minutes}:${seconds}`;
};

export type VideoPlayerVariant = 'default' | 'hello' | 'questions';
