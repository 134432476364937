/**
 * Groups sub-routes
 */
export const groupsRoutes = {
  organization: (shortId: string) => ({
    // Routes with shortId
    root: () => `/${shortId}`,
    events: () => `/${shortId}/events`,
    event: (id: string) => `/${shortId}/event/${id}`,
    feed: () => `/${shortId}/home`,
    groups: () => `/${shortId}/groups`,
    messages: () => `/${shortId}/messages`,
    tag: (tag: string) => `/${shortId}/tags/${tag}`,
    message: (id: string) => `/${shortId}/messages/${id}`,
    newMessage: (username?: string) =>
      username
        ? `/${shortId}/messages/new?username=${username}`
        : `/${shortId}/messages/new`,
    group: (id: string) => `/${shortId}/group/${id}`,
    course: (id: string) => `/${shortId}/courses/${id}`,
    groupJoin: (id: string) => `/${shortId}/group/${id}?join`,
    post: (id: string) => `/${shortId}/post/${id}`,
    newMember: () => `/${shortId}/post/new-member`,
    postComment: (id: string, commentId?: string) =>
      commentId
        ? `/${shortId}/post/${id}#comment-${commentId}`
        : `/${shortId}/post/${id}?comment`,
    team: () => `/${shortId}/members`,
    person: (username: string) => `/${shortId}/members/${username}`,
    inbox: () => `/${shortId}/inbox`,
    notification: (id: string) => `/${shortId}/inbox/${id}`,
    insights: () => `/${shortId}/insights`,
    bookmarks: () => `/${shortId}/bookmarks`,
    drafts: () => `/${shortId}/drafts`,
  }),
  // Routes without shortId
  qr: (token: string) => `/qr?token=${token}`,
  organizations: () => `/communities`,
  organizationsNew: () => `/communities/new`,
};
