import { routes } from '@frond/shared';
import styled, { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';

import {
  useDirectMessageNotificationCountsQuery,
  useNotificationCountsQuery,
} from '../../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../../auth/hooks/useOptionalOrganization';
import { useViewer } from '../../../auth/hooks/useViewer';
import { AddMembersButton } from '../../../common/components/AddMembersButton';
import { Button } from '../../../common/components/Button';
import { Icon } from '../../../common/components/Icon';
import { Modal } from '../../../common/components/Modal';
import { NextLink } from '../../../common/components/NextLink';
import { Text } from '../../../common/components/Text';
import { MarkAsReadButton } from '../../../inbox/components/MarkAsReadButton';
import { OrganizationAvatar } from '../../../organizations/components/OrganizationAvatar';
import { TagMobileBar } from '../../../posts/components/TagMobileBar';
import { usePageTile } from '../../hooks/usePageTitle';
import { GroupMobileBar } from '../GroupMobileBar';
import { GroupsSidebar } from '../GroupsSidebar';
import { GroupsSidebarUnreadBadge } from '../GroupsSidebar/GroupsSidebarUnreadBadge';
import { ProfileMobileBar } from '../ProfileMobileBar';

export const StyledModal = styled(Modal)`
  &[data-reach-dialog-overlay] {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;

    & > div {
      height: 100%;

      & > div {
        height: 100%;
      }
    }
  }

  [data-reach-dialog-content] {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0;
    border-radius: 0;
    overflow-y: scroll;
  }
`;

export const GroupsNavigationHeader: FC = () => {
  const router = useRouter();
  const { organization } = useOptionalOrganization();
  const title = usePageTile();

  const { viewer: user } = useViewer();
  const { data: notificationCountsQuery } = useNotificationCountsQuery({
    variables: { organizationId: organization?.id || '' },
    skip: !user || !organization,
    context: {
      skip: !user || !organization,
    },
  });
  const unreadCount = notificationCountsQuery?.notificationCounts.unread || 0;
  const { data: directMessageNotificationCountsQuery } =
    useDirectMessageNotificationCountsQuery({
      variables: {
        organizationId: organization?.id || '',
      },
      skip: !user || !organization,
      context: {
        skip: !user || !organization,
      },
    });
  const unreadMessagesCount =
    directMessageNotificationCountsQuery?.directMessageNotificationCounts
      .unread || 0;

  const totalUnreadCount = unreadCount + unreadMessagesCount;

  const showTitle =
    organization &&
    !router.asPath.includes(
      routes.groups.organization(organization.shortId).group('')
    ) &&
    !router.asPath.includes(
      routes.groups.organization(organization.shortId).person('')
    );

  const content =
    organization &&
    router.asPath.includes(
      routes.groups.organization(organization.shortId).group('')
    ) ? (
      <GroupMobileBar />
    ) : organization &&
      router.asPath.includes(
        routes.groups.organization(organization.shortId).person('')
      ) ? (
      <ProfileMobileBar />
    ) : organization &&
      router.asPath.includes(
        routes.groups.organization(organization.shortId).tag('')
      ) ? (
      <TagMobileBar />
    ) : null;

  return (
    <x.header
      position="static"
      borderBottom="default"
      borderBottomColor="gray.200"
    >
      <x.div
        px={5}
        h={19}
        display="flex"
        alignItems="center"
        boxSizing="border-box"
        w="full"
        bg="white"
      >
        <x.div
          display="flex"
          flexDirection="row"
          alignItems="center"
          spaceX={2}
          flex={title && showTitle ? 1 : undefined}
        >
          <LeftAccessory />
          {totalUnreadCount > 0 && (
            <GroupsSidebarUnreadBadge>
              {totalUnreadCount}
            </GroupsSidebarUnreadBadge>
          )}
        </x.div>
        {content && (
          <x.div ml={5} flex={1}>
            {content}
          </x.div>
        )}

        {title && showTitle ? (
          <x.div
            display={{ _: content ? 'none' : 'flex' }}
            justifyContent="center"
          >
            <Text variant="md-semibold">{title}</Text>
          </x.div>
        ) : null}

        <x.div
          flex={1}
          display={{ _: content ? 'none' : 'flex' }}
          justifyContent="flex-end"
          minWidth={0}
        >
          <x.div minWidth={0} ml={8}>
            <ActionButton />
          </x.div>
        </x.div>
      </x.div>
    </x.header>
  );
};

export const LeftAccessory = () => {
  const { asPath } = useRouter();
  const { organization } = useOptionalOrganization();
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (
    organization &&
    asPath.includes(routes.groups.organization(organization.shortId).post(''))
  ) {
    return (
      <Button
        href={routes.groups.organization(organization.shortId).feed()}
        variant="icon"
        size="md"
        leftElement={<Icon size="6" color="gray.500" name="chevron-left" />}
      />
    );
  } else {
    return (
      <>
        <Button
          onClick={() => setIsModalOpen(true)}
          variant="icon"
          leftElement={<Icon size="6" name="menu" color="gray.500" />}
        />
        <StyledModal
          isOpen={isModalOpen}
          onDismiss={() => setIsModalOpen(false)}
          ariaLabel="Mobile nav"
          showClose={false}
          disableFocus
        >
          <>
            <x.div
              px={5}
              pt={5}
              pb={2}
              display="flex"
              alignItems="center"
              boxSizing="border-box"
              bg="gray.50"
              maxW={{ 'sm-2': '17.5rem' }}
            >
              <Button
                onClick={() => setIsModalOpen(false)}
                variant="icon"
                color="gray.500"
                leftElement={<Icon size="6" name="close" />}
              />
            </x.div>
            <GroupsSidebar onClickNavItem={() => setIsModalOpen(false)} />
          </>
        </StyledModal>
      </>
    );
  }
};

const OrganizationButton = () => {
  const { organization } = useOptionalOrganization();

  if (organization) {
    return (
      <NextLink href={routes.groups.organizations()}>
        <x.div
          display="flex"
          flexDirection="row"
          borderRadius="sm-md"
          backgroundColor="gray.100"
          alignItems="center"
          py={1}
          px={1.5}
          spaceX={2}
        >
          <OrganizationAvatar organization={organization} size="xs" />
          <Text variant="md-medium" color="gray.500" truncate>
            {organization.name}
          </Text>
        </x.div>
      </NextLink>
    );
  }

  return null;
};

const ActionButton = () => {
  const { asPath } = useRouter();
  const { organization } = useOptionalOrganization();

  if (organization) {
    if (
      asPath.includes(routes.groups.organization(organization.shortId).team())
    ) {
      return <AddMembersButton />;
    } else if (
      asPath.includes(routes.groups.organization(organization.shortId).inbox())
    ) {
      return <MarkAsReadButton />;
    } else if (
      asPath.includes(routes.groups.organization(organization.shortId).feed())
    ) {
      return <OrganizationButton />;
    }
  }

  return null;
};
