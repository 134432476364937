import * as React from 'react';
import { SVGProps } from 'react';
const SvgPaperclip = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.511 1.682c-1.179 0-2.358.448-3.254 1.344L1.85 11.433a.626.626 0 1 0 .884.884L11.14 3.91a3.343 3.343 0 0 1 4.741 0 3.343 3.343 0 0 1 0 4.74l-7.934 7.935a1.723 1.723 0 0 1-2.45 0 1.723 1.723 0 0 1 0-2.45L11.9 7.735a.625.625 0 1 0-.884-.884l-6.402 6.402a2.991 2.991 0 0 0 0 4.217 2.991 2.991 0 0 0 4.217 0l7.935-7.935a4.612 4.612 0 0 0 0-6.508 4.588 4.588 0 0 0-3.254-1.344Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPaperclip;
