const VIDEO_STREAM_URL = 'https://stream.mux.com/';
const VIDEO_IMAGE_URL = 'https://image.mux.com/';

type ThumbnailUrlOptions = {
  height?: number;
  width?: number;
  time?: number;
  rotate?: number;
  fit_mode?: 'preserve' | 'stretch' | 'crop' | 'smartcrop' | 'pad';
  flip_v?: boolean;
  flip_h?: boolean;
};

type AnimatedThumbnailUrlOptions = {
  start?: number;
  end?: number;
  width?: number;
  height?: number;
  fps?: number;
};

/**
 * Takes an ID and returns a stream URL
 * for dropping into an HLS video player
 */
export const getPlaybackUrl = (playbackId: string): string => {
  return `${VIDEO_STREAM_URL}${playbackId}.m3u8`;
};

/**
 * Takes an ID and returns a URL suitable
 * for playing back static renditions
 *
 * Note: not available unless explicitly enabled in Mux
 */
export const getStaticRenditionPlaybackUrl = (
  playbackId: string,
  quality: 'high' | 'medium' | 'low' = 'medium'
): string => {
  return `${VIDEO_STREAM_URL}${playbackId}/${quality}.mp4`;
};

/**
 * Takes an ID and returns am image URL
 */
export const getThumbnailUrl = (
  playbackId: string,
  options?: ThumbnailUrlOptions
): string => {
  const url = `${VIDEO_IMAGE_URL}${playbackId}/thumbnail.jpg`;

  if (!options) {
    return url;
  }

  const query = Object.entries({ ...options })
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return `${url}?${query}`;
};

/**
 * Takes an ID and returns am image URL to the animated gif
 */
export const getAnimatedThumbnailUrl = (
  playbackId: string,
  options?: AnimatedThumbnailUrlOptions
): string => {
  const url = `${VIDEO_IMAGE_URL}${playbackId}/animated.gif`;

  if (!options) {
    return url;
  }

  const query = Object.entries({ ...options })
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return `${url}?${query}`;
};
