import { routes, theme } from '@frond/shared';
import { Menu, MenuButton } from '@reach/menu-button';
import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC, useState } from 'react';

import { AdminSubscriptionPlanId } from '../../../../../generated/types-and-hooks';
import { BASE_URL } from '../../../../config/constants';
import { SignInModal } from '../../../auth/components/SignIn/SignInModal';
import { useIsViewerAdmin, useViewer } from '../../../auth/hooks/useViewer';
import { Avatar } from '../../../common/components/Avatar';
import { Icon } from '../../../common/components/Icon';
import { MenuList } from '../../../common/components/MenuList';
import { Text } from '../../../common/components/Text';
import { useSignOut } from '../../../common/hooks/useApollo';
import { FeedbackWidget } from '../../../common/layout/FeedbackWidget';
import { bottomLeftAlign } from '../../../common/utils/position';
import { OrganizationAvatar } from '../../../organizations/components/OrganizationAvatar';
import { OrganizationDetailsAdminsCard } from '../../../organizations/components/OrganizationDetailsCard';
import { OrganizationShareModal } from '../../../organizations/components/OrganizationShareModal';
import { useCustomDomain } from '../../../organizations/hooks/useCustomDomain';
import { OrganizationAdminSubscriptionLabel } from '../../../pricing/components/AdminSubscriptionLabel';
import { UserSettingsMenuDivider } from '../../../settings/components/UserSettingsMenu/UserSettingsMenuDivider';
import { UserSettingsMenuOrganizationList } from '../../../settings/components/UserSettingsMenu/UserSettingsMenuOrganizationList';
import { UserSettingsMenuRow } from '../../../settings/components/UserSettingsMenu/UserSettingsMenuRow';
import { GroupsModal } from '../GroupsModal';
import { GroupsSidebarOrganization } from './GroupsSidebar';

type GroupsSidebarHeadingProps = {
  organization: GroupsSidebarOrganization;
  onSelectSwitchOrganization?: () => void;
};

const OrganizationButton: React.FC<
  GroupsSidebarHeadingProps & { isExpanded?: boolean }
> = ({ organization, isExpanded }) => {
  return (
    <x.div
      display="flex"
      bg={{
        sm: {
          _: isExpanded ? 'brand.50' : undefined,
          hover: 'brand.50',
        },
      }}
      p={1}
      alignItems="center"
      borderRadius="sm"
      transitionDuration="fast"
      transitionTimingFunction="ease-in-out"
    >
      <x.div display={{ _: 'block', 'lg-2': 'none' }} pr="4">
        <OrganizationAvatar organization={organization} size="m" />
      </x.div>
      <x.div>
        <Text variant="lg-semibold">{organization.name}</Text>
      </x.div>
      <x.div display={{ _: 'none', sm: 'block' }} pl="2" cursor="pointer">
        <Icon name="chevron-bottom" size="4" />
      </x.div>
    </x.div>
  );
};

export const GroupsSidebarHeading: FC<GroupsSidebarHeadingProps> = ({
  organization,
  onSelectSwitchOrganization,
}) => {
  const { viewingCustomDomain } = useCustomDomain();
  return (
    <x.div>
      <x.div
        mt={3}
        px={3}
        justifyContent="space-between"
        alignItems="center"
        display={{ _: 'flex', sm: 'none' }}
        w="full"
        boxSizing="border-box"
      >
        <OrganizationButton organization={organization} />
        <x.div>
          <OrganizationAdminSubscriptionLabel />
        </x.div>
      </x.div>
      <x.div
        display={{ _: 'none', sm: 'block' }}
        pl={5}
        pr={6}
        py={3}
        bg="gray.50"
      >
        {viewingCustomDomain && (
          <x.div
            display="inline-block"
            borderWidth="0.25rem"
            borderStyle="solid"
            borderColor="white"
            borderRadius="md-lg"
            backgroundColor="white"
            mb={2}
          >
            <OrganizationAvatar organization={organization} size="m" />
          </x.div>
        )}
        <Menu>
          {({ isExpanded }) => (
            <>
              <x.div display="flex" spaceX={3} justifyContent="space-between">
                <MenuButton
                  as="span"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={(event) => event.preventDefault()}
                >
                  <OrganizationButton
                    organization={organization}
                    isExpanded={isExpanded}
                  />
                </MenuButton>
                <x.div mt={{ _: 3, 'lg-2': 2 }}>
                  <OrganizationAdminSubscriptionLabel />
                </x.div>
              </x.div>
              <MenuList position={bottomLeftAlign(8)}>
                <HeadingMenu
                  organization={organization}
                  onSelectSwitchOrganization={onSelectSwitchOrganization}
                />
              </MenuList>
            </>
          )}
        </Menu>
      </x.div>
    </x.div>
  );
};

const HeadingMenu: React.FC<GroupsSidebarHeadingProps> = ({
  organization,
  onSelectSwitchOrganization,
}) => {
  const { t } = useTranslation();
  const [showSettings, setShowSettings] = useState(false);
  const router = useRouter();
  const { viewer } = useViewer();
  const isViewerAdmin = useIsViewerAdmin(organization);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const { hasCustomDomain, viewingCustomDomain } = useCustomDomain();
  const signOut = useSignOut();

  if (showSettings) {
    return (
      <x.div
        display="flex"
        flexDirection="column"
        spaceY="4"
        py="2"
        minWidth="3xs"
      >
        <UserSettingsMenuRow onClick={() => setShowSettings(false)}>
          <Icon name="chevron-left" size="4" />
          <Text variant="md-semibold">{t('settings')}</Text>
        </UserSettingsMenuRow>
        <UserSettingsMenuRow
          onClick={() => {
            router.push(routes.settings.account());
          }}
        >
          <Avatar
            size="xs"
            imageId={viewer?.profileImageId}
            avatar={viewer?.avatar}
            bgColor={viewer?.avatarBgColor}
          />
          <Text variant="md">{t('account')}</Text>
        </UserSettingsMenuRow>
        <UserSettingsMenuOrganizationList
          onSelectOrganization={(org) => {
            router.push(routes.settings.organization(org.shortId).details());
          }}
        />
      </x.div>
    );
  }

  return (
    <>
      <x.div display="flex" flexDirection="column" spaceY="4" py="2">
        <x.div display="flex" spaceX="3">
          <x.div mt={theme.sizes['0.5']}>
            <OrganizationAvatar size="xs" organization={organization} />
          </x.div>
          <x.div>
            <Text variant="md">{organization.name}</Text>
            <Text variant="sm" color="gray.300">
              {hasCustomDomain
                ? organization.customDomain?.name
                : `${new URL(BASE_URL).host}/${organization.shortId}`}
            </Text>
          </x.div>
        </x.div>
        <UserSettingsMenuDivider />
        <UserSettingsMenuRow onClick={() => setAboutOpen(true)}>
          <Icon name="news" size="5" />
          <Text variant="md">
            {t('about')} {organization?.name}
          </Text>
        </UserSettingsMenuRow>
        {viewer ? (
          <UserSettingsMenuRow onClick={() => setSharing(true)}>
            <Icon name="add" size="5" />
            <Text variant="md">{t('add_members')}</Text>
          </UserSettingsMenuRow>
        ) : null}

        {isViewerAdmin &&
          organization?.adminSubscription?.subscriptionPlan !==
            AdminSubscriptionPlanId.Pro && (
            <UserSettingsMenuRow
              onClick={() => {
                router.push(
                  routes.settings.organization(organization.shortId).plans()
                );
              }}
            >
              <Icon name="upgrade" size="5" />
              <Text variant="md">{t('upgrade_plan')}</Text>
            </UserSettingsMenuRow>
          )}
        {isViewerAdmin && (
          <UserSettingsMenuRow
            onClick={() =>
              router.push(
                routes.settings.organization(organization.shortId).details()
              )
            }
          >
            <Icon name="settings" size="5" />
            <Text variant="md">{t('community_settings')}</Text>
          </UserSettingsMenuRow>
        )}
        <UserSettingsMenuDivider />
        <UserSettingsMenuRow
          onClick={() => {
            if (viewer) {
              setFeedback(true);
            } else {
              setShowSignInModal(true);
            }
          }}
        >
          <Icon name="message" size="5" />
          <Text variant="md">{t('share_feedback')}</Text>
        </UserSettingsMenuRow>
        <UserSettingsMenuRow
          onClick={() => window.open(routes.marketing.faq(), '_blank')}
        >
          <Icon name="circle-question-mark" size="5" />
          <Text variant="md">{t('faq')}</Text>
        </UserSettingsMenuRow>
        {viewingCustomDomain && viewer ? (
          <>
            <x.div display={{ _: 'none', sm: 'block' }}>
              <UserSettingsMenuRow onClick={onSelectSwitchOrganization}>
                <Icon name="switch" size="5" />
                <Text variant="md">{t('switch_community')}</Text>
              </UserSettingsMenuRow>
            </x.div>
            <x.div display={{ _: 'none', sm: 'block' }}>
              <UserSettingsMenuRow onClick={() => signOut(router.asPath)}>
                <Icon name="sign-out" size="5" />
                <Text variant="md">{t('sign_out')}</Text>
              </UserSettingsMenuRow>
            </x.div>
          </>
        ) : null}
        {viewer ? (
          <x.div
            display={{ _: 'flex', lg: 'none' }}
            flexDirection="column"
            spaceY="4"
          >
            <UserSettingsMenuDivider />
            {viewer &&
            viewer.organizationMemberships &&
            viewer.organizationMemberships.length > 1 ? (
              <UserSettingsMenuRow
                onClick={() => router.push(routes.groups.organizations())}
              >
                <Icon name="switch" size="5" />
                <Text variant="md">Switch community</Text>
              </UserSettingsMenuRow>
            ) : null}
            <UserSettingsMenuRow onClick={() => signOut()}>
              <Icon name="sign-out" size="5" />
              <Text variant="md">Sign Out</Text>
            </UserSettingsMenuRow>
          </x.div>
        ) : null}
      </x.div>
      <GroupsModal
        isOpen={aboutOpen}
        onDismiss={() => setAboutOpen(false)}
        width="29.5rem"
        variant="about"
      >
        <OrganizationDetailsAdminsCard
          onClickUsers={() => setAboutOpen(false)}
        />
      </GroupsModal>
      <OrganizationShareModal
        isOpen={sharing}
        onDismiss={() => setSharing(false)}
        organization={organization}
      />
      <FeedbackWidget
        isOpen={feedback}
        variant="full"
        onDismiss={() => setFeedback(false)}
      />
      <SignInModal
        isOpen={showSignInModal}
        onDismiss={() => setShowSignInModal(false)}
        variant="public"
      />
    </>
  );
};
