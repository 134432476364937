import * as React from "react";
import { SVGProps } from "react";
const SvgCalendarFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 7v-.75A3.254 3.254 0 0 0 17.75 3H6.25A3.254 3.254 0 0 0 3 6.25V7h18ZM3 8.5v9.25A3.254 3.254 0 0 0 6.25 21h11.5A3.254 3.254 0 0 0 21 17.75V8.5H3ZM7.75 18a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm0-4.5a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5ZM12 18a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm0-4.5a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm4.25 0a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCalendarFilled;
