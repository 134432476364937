const ASSET_BASE_URL = 'https://res.cloudinary.com/frond/image/';
export const UNSIGNED_PRESET = 'unsigned-upload-preset';

const isUrl = (urlString: string): boolean => {
  try {
    new URL(urlString);
  } catch (_e) {
    return false;
  }

  return true;
};

export const getCloudinaryUrl = ({
  id,
  width,
  height,
  transform,
  coordinates,
  detectDPR = true,
}: {
  id: string;
  width?: number;
  height?: number;
  transform?: string;
  coordinates?: {
    x: number;
    y: number;
    width: number;
    height: number;
  } | null;
  detectDPR?: boolean;
}): string => {
  let URL = ASSET_BASE_URL;

  /**
   * Check if id is an external URL + return
   * cloudinary fetch method seems to be finicky with some URLS
   * https://cloudinary.com/documentation/fetch_remote_images
   */
  if (isUrl(id)) {
    return id;
  }

  URL += 'upload/';
  if (coordinates) {
    URL += `c_crop,x_${coordinates.x},y_${coordinates.y},w_${coordinates.width},h_${coordinates.height}/`;
  } else if (detectDPR) {
    // Only detect DPR if we're not cropping
    URL += `dpr_${getDPR()}.0,`;
  }

  if (width) URL += `w_${width},`;
  if (height) URL += `h_${height},`;
  if (transform) URL += `${transform},`;
  return `${URL}f_auto/${id}`;
};

/* Get the pixel ratio from the browser, if available
 * If not, optimize for desktop retina screens (2.0)
 */
export const getDPR = (): 1 | 2 | 3 => {
  if (
    typeof window === 'undefined' ||
    !window.devicePixelRatio ||
    window.devicePixelRatio === 2
  ) {
    return 2;
  }

  if (window.devicePixelRatio > 2) {
    return 3;
  }

  return 1;
};
