import { centsToDollars, MyValue, routes } from '@frond/shared';
import styled, { system, SystemProps, x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';

import {
  GroupWithMembersFragment,
  MemberSubscriptionPlan,
  useCreateMemberPlanCheckoutSessionMutation,
} from '../../../../generated/types-and-hooks';
import { useViewer } from '../../auth/hooks/useViewer';
import { Button } from '../../common/components/Button';
import { ComposerBody } from '../../common/components/Composer';
import { Emoji } from '../../common/components/Emoji';
import { Heading } from '../../common/components/Heading';
import { Icon } from '../../common/components/Icon';
import { Text } from '../../common/components/Text';

const Wrapper = styled.div<SystemProps>`
  ${system};

  * {
    font-size: sm;
  }
`;

const GroupPaywallCard: FC<{
  group: Pick<
    GroupWithMembersFragment,
    'emoji' | 'name' | 'description' | 'numPosts'
  >;
}> = ({ group }) => {
  const { t } = useTranslation();

  return (
    <x.div p="4" backgroundColor="gray.50" borderRadius="md-lg">
      <x.div display="flex" alignItems="center" spaceX={2}>
        {group.emoji && <Emoji size={16} emojiUnicode={group.emoji} />}
        <Text variant="md-semibold">{group.name}</Text>
      </x.div>
      {group.description && (
        <Wrapper>
          <x.div pt={2}>
            <ComposerBody content={group.description as MyValue} />
          </x.div>
        </Wrapper>
      )}

      <x.div pt={5} display="flex" flexDirection="row" flex="1" spaceX="3">
        <x.div
          backgroundColor="gray.100"
          borderRadius="sm-md"
          px="0.375rem"
          py="1"
          display="flex"
          spaceX="1"
        >
          <Icon name="post" size="5" />
          <Text variant="sm">{t('post_count', { count: group.numPosts })}</Text>
        </x.div>
      </x.div>
    </x.div>
  );
};

export const GroupPaywall: FC<{
  group?: GroupWithMembersFragment;
  memberSubscriptionPlan: Pick<
    MemberSubscriptionPlan,
    'name' | 'description' | 'priceMonthlyUsd' | 'id'
  > & {
    groupEntitlements: Pick<
      GroupWithMembersFragment,
      'emoji' | 'name' | 'description' | 'numPosts'
    >[];
  };
}> = ({ group, memberSubscriptionPlan }) => {
  const [redirecting, setRedirecting] = useState(false);
  const { t } = useTranslation();
  const router = useRouter();
  const { viewer: user } = useViewer();
  const [createCheckoutSession] = useCreateMemberPlanCheckoutSessionMutation({
    onCompleted: (r) => router.push(r.createMemberPlanCheckoutSession.url),
  });

  const buttonLabel = user
    ? t('paywall_subscription_subscribe', {
        price: centsToDollars(memberSubscriptionPlan.priceMonthlyUsd),
      })
    : t('paywall_subscription_subscribe_unauthed', {
        price: centsToDollars(memberSubscriptionPlan.priceMonthlyUsd),
      });

  const handleButtonClick = () => {
    setRedirecting(true);

    if (user) {
      createCheckoutSession({
        variables: {
          memberSubscriptionPlanId: memberSubscriptionPlan.id,
          returnToGroupId: group?.id,
        },
        onError: () => {
          setRedirecting(false);
        },
      });
      return;
    }

    router.push(routes.signin(router.asPath));
  };

  return (
    <x.div
      w={{ sm: '30rem' }}
      borderRadius={{ sm: 'md-lg' }}
      boxSizing="border-box"
      boxShadow={{ _: 'none', sm: 'sm' }}
    >
      <x.div p="8" display="flex" flexDirection="column" gap="8">
        <x.div display="flex" flexDirection="column" gap="3">
          <Icon name="badge" size="12" />
          <Heading variant="lg">
            {t('paywall_subscription_required', {
              name: memberSubscriptionPlan.name,
            })}
          </Heading>
          <Text variant="md">{memberSubscriptionPlan.description}</Text>
        </x.div>

        <x.div
          display="grid"
          gridTemplateColumns="1fr"
          {...(memberSubscriptionPlan.groupEntitlements.length > 1 && {
            gridTemplateColumns: '1fr 1fr',
            gap: '5',
          })}
        >
          {memberSubscriptionPlan.groupEntitlements.map((group, i) => (
            <GroupPaywallCard key={i} group={group} />
          ))}
        </x.div>

        <x.div>
          <Button
            w="full"
            p="3"
            label={buttonLabel}
            onClick={handleButtonClick}
            loading={redirecting}
          />
          <Text variant="sm" pt="4" color="gray.300">
            {t('paywall_subscription_benefits')}
          </Text>
        </x.div>
      </x.div>
    </x.div>
  );
};
