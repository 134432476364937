import * as React from "react";

function SvgZebra(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.75 35.519h-.063c-.25-.008-.499-.052-.737-.131l-2.219-.744a2.225 2.225 0 00-1.406 0l-2.225.744a2.5 2.5 0 01-2.563-.594 2.5 2.5 0 01-.787-1.8v-1.119a3.125 3.125 0 013.125-3.125h6.25a3.125 3.125 0 013.125 3.125v1.119a2.5 2.5 0 01-.756 1.8 2.556 2.556 0 01-1.744.725z"
        fill="#212121"
      />
      <path
        d="M20 33.281c.374-.001.745.058 1.1.175l2.225.744c.121.04.247.064.375.069a1.293 1.293 0 00.925-.369 1.25 1.25 0 00.375-.906v-1.119a1.875 1.875 0 00-.294-1A1.876 1.876 0 0023.125 30h-6.25a1.876 1.876 0 00-1.581.875c-.19.299-.293.646-.294 1v1.119a1.25 1.25 0 001.313 1.25c.123-.006.245-.029.362-.069l2.231-.744a3.468 3.468 0 011.094-.15z"
        fill="#757575"
      />
      <path
        d="M21.875 36.875h-3.75a2.5 2.5 0 01-2.4-1.812.625.625 0 01.582-.794c.125-.005.25-.029.369-.069l2.23-.744a3.48 3.48 0 012.195 0l2.225.744c.12.04.247.064.375.069a.625.625 0 01.58.794 2.5 2.5 0 01-2.405 1.812z"
        fill="#212121"
      />
      <path
        d="M17.294 35.313c.23.202.525.313.831.312h3.75c.304 0 .597-.111.825-.312l-2-.67a2.226 2.226 0 00-1.406 0l-2 .67z"
        fill="#BDBDBD"
      />
      <path
        d="M16.875 33.125a.624.624 0 01-.625-.625v-.625a.624.624 0 111.25 0v.625a.624.624 0 01-.625.625zm6.25 0a.624.624 0 01-.625-.625v-.625a.624.624 0 111.25 0v.625a.624.624 0 01-.625.625zm6.25-21.106a.625.625 0 01-.625-.594 4.832 4.832 0 00-1.25-3.006.626.626 0 01-.031-.807l1.118-1.437a7.862 7.862 0 016.226-3.05h1.437a.625.625 0 01.625.625 7.918 7.918 0 01-5.069 7.375l-2.212.85a.625.625 0 01-.219.044z"
        fill="#212121"
      />
      <path
        d="M28.75 7.988a6.094 6.094 0 011.144 2.537l1.469-.562a6.631 6.631 0 003.124-2.444 6.556 6.556 0 001.138-3.144h-.813a6.618 6.618 0 00-5.256 2.569l-.806 1.044z"
        fill="#E0E0E0"
      />
      <path
        d="M10.625 12.019a.624.624 0 01-.225-.044l-2.206-.85A7.918 7.918 0 013.125 3.75a.625.625 0 01.625-.625h1.438a7.863 7.863 0 016.25 3.056l1.118 1.438a.625.625 0 01-.056.806 4.832 4.832 0 00-1.25 3.006.625.625 0 01-.625.594v-.006z"
        fill="#212121"
      />
      <path
        d="M4.375 4.375A6.55 6.55 0 005.519 7.5a6.631 6.631 0 003.125 2.45l1.469.563c.18-.918.57-1.782 1.137-2.525l-.806-1.038a6.618 6.618 0 00-5.257-2.575h-.812z"
        fill="#E0E0E0"
      />
      <path
        d="M14.763 31.169h-.075a.626.626 0 01-.52-.425l-3.75-11.25A8.125 8.125 0 0110 16.875v-5.194-.318c.027-.463.109-.92.244-1.363.253-.9.716-1.726 1.35-2.412a6.087 6.087 0 014.462-1.963h1.15l-.05-.287A1.874 1.874 0 0119 3.125h2a1.875 1.875 0 011.875 2.213l-.081.287h1.15a6.087 6.087 0 014.456 1.95A5.95 5.95 0 0129.756 10 5.8 5.8 0 0130 11.356v5.519c0 .875-.141 1.745-.419 2.575l-3.75 11.25a.625.625 0 01-1.125.131 1.876 1.876 0 00-1.58-.831h-6.25a1.876 1.876 0 00-1.582.875.625.625 0 01-.531.294z"
        fill="#212121"
      />
      <path
        d="M16.875 28.75h6.25c.676 0 1.334.22 1.875.625l3.419-10.269c.232-.72.344-1.474.331-2.231v-5.194-.237a4.604 4.604 0 00-.188-1.082A4.719 4.719 0 0027.5 8.426a4.837 4.837 0 00-3.556-1.55h-1.875a.625.625 0 01-.625-.738l.187-1.025A.626.626 0 0021 4.375h-2a.625.625 0 00-.608.469.625.625 0 00-.01.275l.187 1.025a.624.624 0 01-.625.737h-1.875A4.838 4.838 0 0012.5 8.425a4.962 4.962 0 00-1.25 3.006v5.444c.001.74.121 1.474.356 2.175L15 29.375a3.125 3.125 0 011.875-.625z"
        fill="#EEE"
      />
      <path
        d="M20.218 11.25h-.437a1.876 1.876 0 01-1.831-1.525l-.794-4.375A1.876 1.876 0 0119 3.125h2a1.875 1.875 0 011.875 2.213l-.794 4.375a1.875 1.875 0 01-1.863 1.537z"
        fill="#212121"
      />
      <path
        d="M19 4.375a.625.625 0 00-.608.469.625.625 0 00-.01.275l.793 4.375A.625.625 0 0019.8 10h.438a.625.625 0 00.625-.513l.794-4.375A.624.624 0 0021 4.375h-2z"
        fill="#757575"
      />
      <path
        d="M13.75 19.375a.625.625 0 01-.625-.625V17.5a.625.625 0 111.25 0v1.25a.625.625 0 01-.625.625zm12.5 0a.625.625 0 01-.625-.625V17.5a.625.625 0 111.25 0v1.25a.625.625 0 01-.625.625zm-12.362 9.169h-.081a.624.624 0 01-.513-.425l-.9-2.713a.625.625 0 01.275-.731l4.513-2.706a.625.625 0 01.843.881l-3.612 5.419a.624.624 0 01-.525.275z"
        fill="#212121"
      />
      <path
        d="M13.737 25.481l.338 1.025 1.362-2.044-1.7 1.02z"
        fill="#757575"
      />
      <path
        d="M16.25 10.625a.624.624 0 01-.488-.237l-2.5-3.125a.625.625 0 01.975-.782l2.5 3.125a.624.624 0 01-.487 1.02zm6.875 5a.625.625 0 01-.388-1.112l6.013-4.825a.625.625 0 01.987.306c.141.442.23.9.263 1.362v2.2a.625.625 0 01-.482.625l-6.25 1.444h-.143z"
        fill="#212121"
      />
      <path
        d="M28.75 11.313l-3.094 2.437 3.094-.694v-1.375-.237-.132z"
        fill="#757575"
      />
      <path
        d="M16.732 15.625l-6.25-1.444a.625.625 0 01-.482-.625v-1.875-.325c.028-.46.11-.915.244-1.356a.625.625 0 011.006-.313l6.032 4.825a.625.625 0 01-.407 1.113h-.143z"
        fill="#212121"
      />
      <path
        d="M11.25 11.444v1.612l3.094.694-3.094-2.438v.132z"
        fill="#757575"
      />
      <path
        d="M26.112 28.544a.624.624 0 01-.519-.282l-3.612-5.418a.625.625 0 01.844-.882l4.512 2.707a.626.626 0 01.275.73l-.906 2.726a.625.625 0 01-.513.425l-.08-.006z"
        fill="#212121"
      />
      <path
        d="M24.563 24.462l1.362 2.044.337-1.025-1.7-1.019z"
        fill="#757575"
      />
      <path
        d="M23.75 10.625a.625.625 0 01-.487-1.013l2.5-3.125a.625.625 0 01.975.782l-2.5 3.125a.625.625 0 01-.488.231z"
        fill="#212121"
      />
    </svg>
  );
}

export default SvgZebra;
