import { theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';

import { Button } from '../../../common/components/Button';
import { Icon } from '../../../common/components/Icon';
import { VideoControlsProps } from '../../../video/components/Video/VideoControls';

export type QuestionsVideoRecorderReviewControlsProps = VideoControlsProps & {
  onClickRerecord: () => void;
  onClickSaveRecording: () => void;
  loading?: boolean;
  addMediaButton?: React.ReactElement;
};

export const QuestionsVideoRecorderReviewControls: FC<
  QuestionsVideoRecorderReviewControlsProps
> = ({
  skip,
  playing,
  togglePlayback,
  onClickRerecord,
  onClickSaveRecording,
  loading,
  addMediaButton,
  duration,
}) => {
  const { t } = useTranslation();
  return (
    <x.div
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pt={4}
      lineHeight="1"
    >
      <x.div w="25%" display="flex" spaceX={2}>
        {addMediaButton}
        <Button
          onClick={onClickRerecord}
          variant="secondary"
          label={t('re_record')}
        />
      </x.div>
      <QuestionsVideoReviewButtons
        skip={skip}
        playing={playing}
        togglePlayback={togglePlayback}
        duration={duration}
      />
      <x.div w="25%" display="flex" justifyContent="flex-end">
        <Button
          disabled={loading}
          onClick={onClickSaveRecording}
          variant="primary"
          label={loading ? t('loading') : t('use_video')}
        />
      </x.div>
    </x.div>
  );
};

const QuestionsVideoRecorderPlayPauseButton: FC<{
  playing: boolean;
  togglePlayback: () => void;
}> = ({ playing, togglePlayback }) => {
  const iconName = playing ? 'pause' : 'play';
  return (
    <Button
      borderRadius="full"
      p="0.7rem"
      backgroundColor="gray.200"
      color="gray.400"
      leftElement={<Icon name={iconName} size="6" />}
      variant="icon"
      onClick={() => togglePlayback()}
    />
  );
};

export const QuestionsVideoReviewButtons: React.FC<
  Pick<
    QuestionsVideoRecorderReviewControlsProps,
    'skip' | 'playing' | 'togglePlayback' | 'duration'
  > & {
    showSkipControls?: boolean;
  }
> = ({ skip, playing, togglePlayback, duration, showSkipControls = true }) => {
  const skipForward = () => skip(5);
  const skipBackward = () => skip(-5);

  const videoLongerThan10s = !!duration && duration > 10;

  return (
    <x.div
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      spaceX={5}
      h={9}
      overflow="visible"
    >
      {showSkipControls && videoLongerThan10s && (
        <Button
          onClick={skipBackward}
          border="none"
          variant="dark"
          backgroundColor="gray.400"
          px={theme.sizes[2.5]}
          label={
            <x.span display="flex" spaceX="1" alignItems="center">
              <Icon name="rotate-ccw" size="5" />
              <x.span display="block">5</x.span>
            </x.span>
          }
        />
      )}
      <QuestionsVideoRecorderPlayPauseButton
        playing={playing}
        togglePlayback={togglePlayback}
      />
      {showSkipControls && videoLongerThan10s && (
        <Button
          onClick={skipForward}
          variant="dark"
          border="none"
          backgroundColor="gray.400"
          px={theme.sizes[2.5]}
          label={
            <x.span display="flex" spaceX="1" alignItems="center">
              <x.span display="block">5</x.span>
              <Icon name="rotate-cw" size="5" />
            </x.span>
          }
        />
      )}
    </x.div>
  );
};
