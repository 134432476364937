import { theme } from '@frond/shared';
import { styled, x } from '@xstyled/styled-components';
import React from 'react';

import { Giphy } from '../../../../../generated/types-and-hooks';
import { GIPHY_API_KEY } from '../../../../config/constants';
import { GiphySearch } from '../GiphySearch';
import { SearchContextManager } from '../GiphySearchContext';
import { Modal } from '../Modal';

type CommentComposerGiphyModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onGiphyChosen: (giphy: Giphy) => void;
};

const StyledModal = styled(Modal)`
  [data-reach-dialog-content] {
    padding: 3;
  }
`;

export const CommentComposerGiphyModal = ({
  isOpen,
  onDismiss,
  onGiphyChosen,
}: CommentComposerGiphyModalProps) => {
  return (
    <StyledModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      ariaLabel="Comment Giphy Search Modal"
      center
      closeButtonVariant="v3"
    >
      <x.div w="34.5rem">
        <SearchContextManager
          shouldDefaultToTrending
          apiKey={GIPHY_API_KEY as string}
        >
          <GiphySearch colorVariant="white" onCompleted={onGiphyChosen} />
        </SearchContextManager>
      </x.div>

      <x.div
        display={{ _: 'none', 'sm-2': 'flex' }}
        position="absolute"
        bottom={`-${theme.sizes[12]}`}
        w="34.5rem"
        justifyContent="center"
      >
        <x.img
          w="60"
          src="https://res.cloudinary.com/frond/image/upload/v1676458511/static/Poweredby_640px-Black_HorizLogo_comthh.png"
          alt="Giphy attribution"
        />
      </x.div>
    </StyledModal>
  );
};
