import { ApolloCache, FetchResult, useApolloClient } from '@apollo/client';

import {
  PostFeedFragment,
  PostFeedFragmentDoc,
} from '../../../../generated/graphql-request-api-sdk';
import {
  CommentCoreFragment,
  CommentCoreFragmentDoc,
  CreateOrDeleteReactionMutation,
  ReactionTypes,
  refetchCommentQuery,
  refetchPostQuery,
  refetchViewerQuery,
  refetchVisitedPublicOrganizationsQuery,
  useCreateOrDeleteReactionMutation,
  ViewerQuery,
} from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { userInOrganization } from '../../auth/utils/permissions';

type UseReactionsReturnType = {
  onReactionClick(
    parentId: string,
    type: ReactionTypes,
    emojiUnicode: string,
    currentUser: ViewerQuery['viewer']
  ): void;
  mutationUpdate(
    cache: ApolloCache<CreateOrDeleteReactionMutation>,
    result: FetchResult<
      CreateOrDeleteReactionMutation,
      Record<string, any>,
      Record<string, any>
    >,
    update: PostFeedFragment | CommentCoreFragment | null
  ): void;
  optimisticResponse(
    user: ViewerQuery['viewer'],
    emojiUnicode: string,
    parentId: string,
    type: ReactionTypes,
    reactions: Reaction[]
  ): void;
};

type CreateOrDeleteReaction =
  CreateOrDeleteReactionMutation['createOrDeleteReaction'];
type CreatedByUser = CreateOrDeleteReaction['createdByUsers'][0];
type Reaction = CreateOrDeleteReaction;

export const useReactions = (): UseReactionsReturnType => {
  const { cache } = useApolloClient();
  const { viewer: user } = useViewer();
  const { organization } = useOrganization();
  const [mutateCreateOrDeleteReaction] = useCreateOrDeleteReactionMutation({
    ignoreResults: true,
  });

  const mutationUpdate = (
    cache: ApolloCache<CreateOrDeleteReactionMutation>,
    result: FetchResult<
      CreateOrDeleteReactionMutation,
      Record<string, any>,
      Record<string, any>
    >,
    parent: PostFeedFragment | CommentCoreFragment | null
  ) => {
    const { data } = result;

    if (data == null || parent == null) {
      return;
    }

    if (data.createOrDeleteReaction && parent) {
      // Update existing reactions
      let reactions: CreateOrDeleteReaction[] = (
        parent.reactions as CreateOrDeleteReaction[]
      ).reduce(
        (
          accumulator: CreateOrDeleteReaction[],
          reaction: CreateOrDeleteReaction
        ) => {
          if (reaction.id == data.createOrDeleteReaction.id) {
            if (data.createOrDeleteReaction.numReactions !== 0) {
              return [...accumulator, data.createOrDeleteReaction];
            } else {
              return accumulator;
            }
          } else {
            return [...accumulator, reaction];
          }
        },
        []
      );

      // Add new reaction if not included yet
      if (
        data.createOrDeleteReaction.numReactions !== 0 &&
        !reactions.includes(data.createOrDeleteReaction)
      ) {
        reactions = [...reactions, data.createOrDeleteReaction];
      }

      if (
        data.createOrDeleteReaction.type === ReactionTypes.Post &&
        parent.__typename === 'Post'
      ) {
        cache.writeFragment<PostFeedFragment>({
          id: `Post:${parent.id}`,
          fragment: PostFeedFragmentDoc,
          fragmentName: 'PostFeed',
          data: {
            ...parent,
            group: {
              ...parent.group,
            },
            comments: {
              ...parent.comments.map((c) => ({
                ...c,
              })),
            },
            reactions,
          },
        });
      } else if (
        data.createOrDeleteReaction.type === ReactionTypes.Comment &&
        parent.__typename === 'Comment'
      ) {
        cache.writeFragment<CommentCoreFragment>({
          id: `Comment:${parent.id}`,
          fragment: CommentCoreFragmentDoc,
          fragmentName: 'CommentCore',
          data: {
            ...parent,
            reactions,
          },
        });
      } else {
        throw new Error('Unknown type.');
      }
    }
  };

  const optimisticResponse = (
    user: ViewerQuery['viewer'],
    emojiUnicode: string,
    parentId: string,
    type: ReactionTypes,
    reactions: Reaction[]
  ) => {
    const reactionId = `${parentId}|${emojiUnicode}`;
    const existingReaction = reactions?.find(
      (reaction) => reaction.id === reactionId
    );

    const hasReacted = !existingReaction?.hasReacted;

    const numReactions = existingReaction
      ? existingReaction?.hasReacted
        ? existingReaction.numReactions - 1
        : existingReaction.numReactions + 1
      : 1;

    let createdByUsers = existingReaction?.createdByUsers || [];
    if (existingReaction?.hasReacted) {
      createdByUsers = existingReaction.createdByUsers?.filter(
        (createdBy: CreatedByUser) => createdBy.id !== user.id
      );
    } else {
      createdByUsers = createdByUsers?.concat([user]);
    }

    return {
      createOrDeleteReaction: {
        id: reactionId,
        type,
        emojiUnicode,
        hasReacted,
        numReactions,
        createdByUsers: createdByUsers || [],
      },
    };
  };

  const onReactionClick = async (
    parentId: string,
    type: ReactionTypes,
    emojiUnicode: string
    // currentUser: ViewerQuery['viewer']
  ) => {
    let fragment;
    // let reactions;

    if (type == ReactionTypes.Post) {
      fragment = cache.readFragment<PostFeedFragment>({
        id: `Post:${parentId}`,
        fragment: PostFeedFragmentDoc,
        fragmentName: 'PostFeed',
      });
      // reactions = fragment?.reactions || [];
    } else if (type == ReactionTypes.Comment) {
      fragment = cache.readFragment<CommentCoreFragment>({
        id: `Comment:${parentId}`,
        fragment: CommentCoreFragmentDoc,
        fragmentName: 'CommentCore',
      });
    } else {
      throw new Error('Unknown type.');
    }

    if (!fragment) {
      return;
    }

    // TODO: Write it to the GraphQL cache immediately
    // mutationUpdate(
    //   cache as ApolloCache<CreateOrDeleteReactionMutation>,
    //   {
    //     data: optimisticResponse(
    //       currentUser,
    //       emojiUnicode,
    //       parentId,
    //       type,
    //       reactions
    //     ),
    //   },
    //   fragment
    // );

    mutateCreateOrDeleteReaction({
      variables: {
        input: {
          parentId,
          type,
          emojiUnicode,
        },
      },
      refetchQueries: [
        ...(type === ReactionTypes.Post
          ? [refetchPostQuery({ id: parentId })]
          : []),
        ...(type === ReactionTypes.Comment
          ? [
              refetchCommentQuery({
                id: parentId,
              }),
            ]
          : []),
        ...(organization.isPublic &&
        user &&
        !userInOrganization(user, organization)
          ? [
              refetchVisitedPublicOrganizationsQuery({
                userId: user.id,
              }),
              refetchViewerQuery(),
            ]
          : []),
      ],
    });
  };

  return {
    onReactionClick,
    mutationUpdate,
    optimisticResponse,
  };
};
