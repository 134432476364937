import * as React from 'react';
import { SVGProps } from 'react';

const SvgMailbox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7 4.5h10a5 5 0 0 1 5 5V20a1 1 0 0 1-1 1H7V4.5Z" fill="#007AD9" />
    <path
      d="M7 4.5a5 5 0 0 0-5 5V20a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V9.5a5 5 0 0 0-5-5Z"
      fill="url(#type=mailbox_svg__a)"
    />
    <path d="M8.5 10.5H5a.5.5 0 0 1 0-1h3.5a.5.5 0 0 1 0 1Z" fill="#007AD9" />
    <path d="M22.5 8.5H15v1h7.5v-1Z" fill="#CF1928" />
    <path
      d="M21 11V8.5h1.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5Z"
      fill="#CF1928"
    />
    <path d="M15 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill="#D9414F" />
    <defs>
      <linearGradient
        id="type=mailbox_svg__a"
        x1={3.603}
        y1={5.485}
        x2={10.091}
        y2={22.473}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32BDEF" />
        <stop offset={1} stopColor="#1EA2E4" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgMailbox;
