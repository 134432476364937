import * as React from "react";

function SvgWolf(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.069 36.875h-6.137a3.9 3.9 0 01-2.77-1.163 3.55 3.55 0 01-1.037-2.587 3.75 3.75 0 00-.537-1.988l-1.013-1.694-4.55 1.182a.625.625 0 01-.743-.806l1.512-4.544-3.894.975a.625.625 0 01-.725-.875l1.007-2.331C4.722 21.799 5 20.457 5 19.1v-.35A10.625 10.625 0 0113.794 8.28a10.476 10.476 0 011.831-.156h8.75A10.625 10.625 0 0135 18.75v.35c0 1.357.278 2.7.819 3.944l1.006 2.33a.625.625 0 01-.725.876l-3.894-.975 1.513 4.544a.625.625 0 01-.744.806l-4.55-1.138-1.012 1.688a3.75 3.75 0 00-.538 1.95 3.551 3.551 0 01-1.037 2.562 3.902 3.902 0 01-2.769 1.188z"
        fill="#212121"
      />
      <path
        d="M11.875 28.125a.625.625 0 01.538.306l1.25 2.088a5 5 0 01.712 2.656 2.314 2.314 0 00.681 1.669 2.645 2.645 0 001.875.787h6.138a2.641 2.641 0 001.875-.787 2.306 2.306 0 00.681-1.669 5 5 0 01.713-2.656l1.25-2.088a.625.625 0 01.687-.287l3.894.975-1.513-4.544a.625.625 0 01.744-.825l3.75.944-.506-1.169a11.13 11.13 0 01-.894-4.425v-.35a9.375 9.375 0 00-9.375-9.375h-8.75A9.375 9.375 0 006.25 18.75v.35a11.131 11.131 0 01-.919 4.438l-.506 1.168 3.75-.944a.625.625 0 01.744.807l-1.513 4.544 3.92-.988c.049-.006.1-.006.15 0z"
        fill="#607D8B"
      />
      <path
        d="M23.069 36.875h-6.156a3.783 3.783 0 01-3.788-3.788 3.75 3.75 0 00-.537-1.925l-1.25-2.087a.625.625 0 01-.088-.325v-6.356a3.65 3.65 0 013.644-3.644 6.013 6.013 0 014.719 2.269L20 21.5l.387-.481a6.013 6.013 0 014.72-2.269 3.65 3.65 0 013.643 3.644v6.356c0 .112-.03.222-.087.319l-1.25 2.087a3.75 3.75 0 00-.538 1.969 3.556 3.556 0 01-1.038 2.569 3.906 3.906 0 01-2.768 1.181z"
        fill="#212121"
      />
      <path
        d="M12.5 28.575l1.162 1.938a5 5 0 01.713 2.574 2.53 2.53 0 002.538 2.538h6.156a2.644 2.644 0 001.875-.781 2.315 2.315 0 00.681-1.669 5 5 0 01.712-2.656l1.163-1.944v-6.181A2.4 2.4 0 0025.106 20a4.77 4.77 0 00-3.75 1.8l-.875 1.094a.625.625 0 01-.975 0l-.875-1.094a4.769 4.769 0 00-3.75-1.8 2.4 2.4 0 00-2.381 2.394v6.181z"
        fill="#F5F5F5"
      />
      <path
        d="M9.02 11.875h-.132a.625.625 0 01-.437-.35 10.4 10.4 0 01-.288-7.975.625.625 0 011.025-.219l5.15 5.15a.624.624 0 01-.337 1.056 9.319 9.319 0 00-4.575 2.2.625.625 0 01-.407.138z"
        fill="#212121"
      />
      <path
        d="M9.038 4.919a9.181 9.181 0 00.243 5.306 10.574 10.574 0 013.382-1.681L9.038 4.919z"
        fill="#EEE"
      />
      <path
        d="M20.782 35h-1.563a1.468 1.468 0 01-1.318-.831l-.4-.825a1.463 1.463 0 011.325-2.094h2.343a1.463 1.463 0 011.332 2.094l-.394.825a1.468 1.468 0 01-1.325.83z"
        fill="#212121"
      />
      <path
        d="M18.825 32.5a.213.213 0 00-.194.306l.394.825a.213.213 0 00.194.119h1.562a.213.213 0 00.194-.119l.394-.825a.214.214 0 00-.09-.28.213.213 0 00-.104-.026h-2.35z"
        fill="#424242"
      />
      <path
        d="M16.875 25.625a.625.625 0 01-.444-.181l-1.25-1.25a.625.625 0 01.882-.882l1.25 1.25a.625.625 0 01-.438 1.063zm6.25 0a.624.624 0 01-.444-1.069l1.25-1.25a.626.626 0 01.882.881l-1.25 1.25a.625.625 0 01-.438.188zm7.857-13.75a.625.625 0 01-.413-.156 9.319 9.319 0 00-4.575-2.2.624.624 0 01-.337-1.057l5.15-5.15a.626.626 0 011.024.22 10.399 10.399 0 01-.287 7.974.623.623 0 01-.438.35l-.125.019z"
        fill="#212121"
      />
      <path
        d="M27.338 8.544c1.219.353 2.364.922 3.381 1.681a9.18 9.18 0 00.244-5.306l-3.625 3.625z"
        fill="#EEE"
      />
    </svg>
  );
}

export default SvgWolf;
