import { getWeekOfMonth } from 'date-fns';
import { useTranslation } from 'next-i18next';

import { EventCoreFragment } from '../../../../generated/types-and-hooks';
import { days } from './EventModal/EventFormFields';

export const EventRecurringText = ({ event }: { event: EventCoreFragment }) => {
  const { t } = useTranslation('events');
  const startDateAsDate = new Date(event.startAt);
  const dayOfWeek = startDateAsDate.getDay();
  const currentDay = days[dayOfWeek];
  const weekOfMonth = getWeekOfMonth(startDateAsDate);
  // const dayOfMonth = startDateAsDate.getDate();

  if (!event.series) return null;

  return (
    <>
      {
        {
          [`* * ${dayOfWeek}#${weekOfMonth}`]: t('events.every_month', {
            weekDay: currentDay,
            nth:
              weekOfMonth === 1
                ? '1st'
                : weekOfMonth === 2
                  ? '2nd'
                  : weekOfMonth === 3
                    ? '3rd'
                    : '4th',
          }),
          [`* * *`]: t('events.every_day'),
          [`* * ${dayOfWeek}`]: t('events.every_week', {
            weekDay: currentDay,
          }),
          [`* * ${dayOfWeek}#2`]: t('events.every_other_week', {
            weekDay: currentDay,
          }),
        }[event.series.recurringCronExpression.split(' ').splice(2).join(' ')]
      }
    </>
  );
};
