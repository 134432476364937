import * as React from 'react';
import { SVGProps } from 'react';

const Invite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M21.5 14.726h-19V8.277a1 1 0 0 1 .466-.845l8.233-5.2a1.5 1.5 0 0 1 1.602 0l8.233 5.2a1 1 0 0 1 .466.845v6.449Z"
      fill="url(#type=org-invite-yellow_svg__a)"
    />
    <path
      d="M18.5 16h-13V7a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 .5.5v9Z"
      fill="url(#type=org-invite-yellow_svg__b)"
    />
    <path
      d="M21.5 8.5v10.628a.88.88 0 0 1-.887.872H4.78l2.401-3.163L21.5 8.5Z"
      fill="url(#type=org-invite-yellow_svg__c)"
    />
    <path
      d="M2.5 8.5v10.628a.88.88 0 0 0 .887.872h17.226c.28 0 .527-.13.69-.329L2.5 8.5Z"
      fill="url(#type=org-invite-yellow_svg__d)"
    />
    <path
      d="M14.25 10H12.5V8.25a.25.25 0 0 0-.25-.25h-.5a.25.25 0 0 0-.25.25V10H9.75a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h1.75v1.75c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25V11h1.75a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25Z"
      fill="url(#type=org-invite-yellow_svg__e)"
    />
    <defs>
      <linearGradient
        id="type=org-invite-yellow_svg__a"
        x1={9.46}
        y1={2.858}
        x2={12.572}
        y2={13.357}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFBB1D" />
        <stop offset={1} stopColor="#F9D482" />
      </linearGradient>
      <linearGradient
        id="type=org-invite-yellow_svg__b"
        x1={12}
        y1={6.708}
        x2={12}
        y2={13.617}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.24} stopColor="#F8F8F7" />
        <stop offset={1} stopColor="#E3E3E1" />
      </linearGradient>
      <linearGradient
        id="type=org-invite-yellow_svg__c"
        x1={12.943}
        y1={13.968}
        x2={18.998}
        y2={22.634}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9D482" />
        <stop offset={1} stopColor="#EFBB1D" />
      </linearGradient>
      <linearGradient
        id="type=org-invite-yellow_svg__d"
        x1={1.537}
        y1={13.618}
        x2={19.981}
        y2={22.563}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFBB1D" />
        <stop offset={1} stopColor="#F9D482" />
      </linearGradient>
      <linearGradient
        id="type=org-invite-yellow_svg__e"
        x1={12}
        y1={7.925}
        x2={12}
        y2={13.047}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#411991" />
        <stop offset={1} stopColor="#6338B8" />
      </linearGradient>
    </defs>
  </svg>
);

export default Invite;
