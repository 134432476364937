import * as React from "react";
import { SVGProps } from "react";
const SvgCommunity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 1.666c-4.595 0-8.333 3.738-8.333 8.333 0 1.365.36 2.64.942 3.778l-.903 3.234c-.21.751.532 1.494 1.284 1.284l3.236-.903c1.137.581 2.41.94 3.774.94 4.595 0 8.333-3.738 8.333-8.333 0-4.595-3.738-8.333-8.333-8.333Zm0 3.75c1.149 0 2.083.935 2.083 2.084A2.086 2.086 0 0 1 10 9.583 2.086 2.086 0 0 1 7.917 7.5c0-1.149.934-2.083 2.083-2.083ZM7.292 11.25h5.416c.575 0 1.042.467 1.042 1.042v.416c0 .186-.021.369-.063.543a.624.624 0 0 1-.168.298A4.975 4.975 0 0 1 10 15a4.976 4.976 0 0 1-3.519-1.451.626.626 0 0 1-.168-.298 2.337 2.337 0 0 1-.063-.543v-.416c0-.575.468-1.042 1.042-1.042Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCommunity;
