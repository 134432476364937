import * as React from "react";

function SvgReactions(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.636 2.351A10.011 10.011 0 0012 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10a9.982 9.982 0 00-.582-3.37 5.138 5.138 0 01-1.816.34 8.182 8.182 0 11-5.165-4.782 5.19 5.19 0 01.2-1.837zM7.278 13.818c0 2.938 1.698 4.546 4.75 4.546 3.063 0 4.731-1.624 4.647-4.572l-.025-.883H7.278v.91zm4.752 2.728c1.706 0 2.564-.55 2.777-1.819H9.18c.259 1.264 1.145 1.819 2.85 1.819zm4.516-8.182h-2.728v1.818h2.728V8.364zm-9.091 0h2.727v1.818H7.455V8.364z"
        fill="currentColor"
      />
      <path
        stroke="currentColor"
        strokeWidth={1.692}
        d="M15.666 4h7.333M19.333 7.667V.333"
      />
    </svg>
  );
}

export default SvgReactions;
