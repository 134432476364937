import * as React from "react";

function SvgRotateCcw(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.193 7H10v2H3V2h2v3.27C6.725 3.183 9.196 2 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2a8 8 0 108-8C9.606 4 7.554 5.079 6.193 7z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgRotateCcw;
