import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { SignInModal } from '../../../auth/components/SignIn/SignInModal';
import { useViewer } from '../../../auth/hooks/useViewer';
import { Button } from '../../../common/components/Button';
import { useJoinOrganization } from '../../../invites/hooks/useJoinOrganization';
import { GroupsSidebarOrganization } from './GroupsSidebar';

export const GroupsSidebarJoinButton: React.FC<{
  organization: GroupsSidebarOrganization;
}> = ({ organization }) => {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const [showSignInModal, setShowSignInModal] = useState(false);
  const { mutateJoinOrganization, loading: joiningOrganization } =
    useJoinOrganization({ organization });
  return (
    <>
      <SignInModal
        isOpen={showSignInModal}
        onDismiss={() => setShowSignInModal(false)}
        variant="public"
      />
      <Button
        label={`${t('join')} ${t('community')}`}
        loading={joiningOrganization}
        onClick={() => {
          if (viewer) {
            mutateJoinOrganization({
              variables: {
                input: {
                  userId: viewer.id,
                  organizationId: organization.id,
                },
              },
            });
          } else {
            setShowSignInModal(true);
          }
        }}
        w="full"
      />
    </>
  );
};
