import * as React from "react";
import { SVGProps } from "react";
const SvgMessage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 1.999c-5.514 0-10 4.486-10 10 0 1.638.431 3.168 1.13 4.533l-1.083 3.881c-.253.902.639 1.793 1.54 1.541l3.885-1.084C8.835 21.568 10.364 22 12 22c5.514 0 10-4.486 10-10s-4.486-10-10-10Zm0 1.5c4.703 0 8.5 3.797 8.5 8.5 0 4.703-3.797 8.5-8.5 8.5-1.5 0-2.905-.39-4.129-1.072a.75.75 0 0 0-.566-.068L3.61 20.39l1.032-3.69a.75.75 0 0 0-.068-.568A8.445 8.445 0 0 1 3.5 11.999c0-4.703 3.797-8.5 8.5-8.5Zm-4.25 6a.75.75 0 1 0 0 1.5h8.5a.75.75 0 1 0 0-1.5h-8.5Zm0 3.5a.751.751 0 1 0 0 1.5h6.5a.75.75 0 1 0 0-1.5h-6.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMessage;
