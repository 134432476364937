import * as React from "react";
import { SVGProps } from "react";
const SvgArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.783.833a.625.625 0 0 0-.433.183L5.183 5.183a.625.625 0 1 0 .884.884l3.1-3.1v15.575a.626.626 0 1 0 1.25 0V2.967l3.1 3.1a.623.623 0 0 0 1.029-.197.625.625 0 0 0-.146-.687l-4.166-4.167a.626.626 0 0 0-.451-.183Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowUp;
