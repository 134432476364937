import * as React from "react";
import { SVGProps } from "react";

const SvgAppNyt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.858 17.587V9.045l-2.805 1.122s-.686 1.434-.562 3.554c.063 1.684 1.06 3.616 1.808 4.49l1.56-.624ZM14.402.005c.623.062 1.683.374 2.432.997.81.749.997 1.933.748 2.993-.25.935-.561 1.434-1.559 2.12-.998.686-1.746.748-1.746.748v4.115l1.933 1.496-1.933 1.746v5.611s1.621-1.31 2.868-3.865c0 0 .063-.125.187-.437.125.686.063 2.12-.748 3.866-.623 1.371-1.683 2.618-3.117 3.366-2.432 1.31-4.302 1.434-6.235 1.06-2.307-.436-4.426-1.683-5.798-3.865C.436 18.397 0 16.65 0 14.719c.062-3.803 2.993-7.045 6.36-8.292.436-.125.56-.25 1.122-.312-.25.187-.562.374-.936.623-1.06.686-1.995 2.058-2.431 3.118l6.484-2.806v8.79l-5.175 2.557c.624.81 2.432 1.995 3.99 2.182 2.619.312 4.365-.686 4.365-.686v-5.735l-1.933-1.684 1.933-1.496V6.863c-1.123-.125-2.681-.623-3.43-.81-1.184-.25-5.05-1.31-5.673-1.434-.623-.063-1.372-.063-1.808.311-.436.374-.748 1.123-.561 1.746.125.374.374.561.56.748 0 0-.248 0-.623-.25C1.496 6.739.935 5.929.873 4.869a3.866 3.866 0 0 1 1.62-3.367c.998-.623 2.12-.997 3.43-.81C7.856.94 10.412 2 12.719 2.56c.873.187 1.558.25 2.182-.062.312-.187.81-.748.374-1.434-.499-.81-1.434-.81-2.245-.935.749-.125.873-.125 1.372-.125"
      fill="#251802"
    />
  </svg>
);

export default SvgAppNyt;
