import * as React from "react";
import { SVGProps } from "react";

const SvgFacetime = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.6 12H18v2.4h3.6c1.324 0 2.4-1.077 2.4-2.4V8.4h-2.4V12ZM21.6 0H18v2.4h3.6V6H24V2.4C24 1.076 22.924 0 21.6 0ZM2.4 2.4H6V0H2.4A2.402 2.402 0 0 0 0 2.4V6h2.4V2.4ZM6 14.4V12H2.4V8.4H0V12c0 1.323 1.076 2.4 2.4 2.4H6ZM12 14.4c2.647 0 4.8-2.152 4.8-4.8 0-2.647-2.153-4.8-4.8-4.8a4.805 4.805 0 0 0-4.8 4.8c0 2.648 2.153 4.8 4.8 4.8Zm0-7.2c1.323 0 2.4 1.077 2.4 2.4 0 1.324-1.077 2.4-2.4 2.4a2.402 2.402 0 0 1-2.4-2.4c0-1.323 1.076-2.4 2.4-2.4ZM12 15.6c-5.652 0-9.6 2.96-9.6 7.2V24h19.2v-1.2c0-4.24-3.948-7.2-9.6-7.2Zm-7.01 6C5.709 19.43 8.39 18 12 18s6.291 1.43 7.009 3.6H4.99Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFacetime;
