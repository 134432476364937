import { theme } from '@frond/shared';
import * as Radix from '@radix-ui/react-tabs';
import styled from '@xstyled/styled-components';

export const List: typeof Radix.List = styled(Radix.List)`
  background-color: gray.100;
  padding: 1;
  border-radius: md;
  border: 1px solid ${theme.colors.gray[200]};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  gap: 3;
  width: 100%;

  @media (min-width: sm) {
    width: fit-content;
  }
`;

export const Trigger: typeof Radix.Trigger = styled(Radix.Trigger)`
  cursor: pointer;
  border: none;
  padding: 2 3;
  border-radius: sm-md;
  background-color: transparent;
  transition: background-color ease-in-out ${theme.transitions.duration.faster};

  &[data-state='active'] {
    background-color: white;
    box-shadow: xs20;
  }

  width: 100%;

  @media (min-width: sm) {
    width: auto;
  }
`;

export const Content: typeof Radix.Content = styled(Radix.Content)`
  &[data-state='active'] {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  height: 100%;
`;

export const Root: typeof Radix.Root = styled(Radix.Root)`
  height: 100%;

  @media (min-width: sm) {
    height: 100%;
  }
`;
