import { getUsersText } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import {
  GroupWithMembersFragment,
  refetchGroupQuery,
  refetchGroupsQuery,
  refetchHomeFeedLatestContentQuery,
  refetchHomeFeedQuery,
  useJoinGroupMutation,
  UserPreviewFragment,
} from '../../../../generated/types-and-hooks';
import { GROUPS_PER_PAGE, POSTS_PER_PAGE } from '../../../config/constants';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { Button } from '../../common/components/Button';
import { Heading } from '../../common/components/Heading';
import { Text } from '../../common/components/Text';
import { Users } from '../../common/components/Users';
import { useEffectOnce } from '../../common/hooks/useEffectOnce';
import { GroupsModal } from './GroupsModal';

export const GroupJoinAlert: React.FC<{
  group: GroupWithMembersFragment;
}> = ({ group }) => {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const { organization } = useOrganization();
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);

  const [mutateJoinGroup, { loading }] = useJoinGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      refetchGroupQuery({ id: group.id }),
      refetchGroupsQuery({
        organizationId: organization.id,
        first: GROUPS_PER_PAGE - 1,
      }),
      refetchHomeFeedQuery({
        organizationId: organization.id,
        first: POSTS_PER_PAGE,
      }),
      refetchHomeFeedLatestContentQuery({
        organizationId: organization.id,
      }),
    ],
  });

  useEffectOnce(
    () => {
      setShowModal(true);
      const newQueries = router.query;
      delete newQueries.join;
      router.replace(
        { pathname: router.pathname, query: newQueries },
        undefined
      );
    },
    () => router.query.join === ''
  );

  const users = group.members.filter(
    (member): member is UserPreviewFragment => member.__typename === 'User'
  );

  const viewerIsMember = users.find((member) => member.id === viewer?.id);

  const handleDismiss = () => setShowModal(false);

  const handleClick = () => {
    if (!viewer) return;

    mutateJoinGroup({
      variables: {
        input: {
          groupId: group.id,
        },
      },
      onCompleted: handleDismiss,
    });
  };

  const { notificationText } = getUsersText(users);

  return (
    <GroupsModal
      isOpen={showModal && !viewerIsMember}
      onDismiss={handleDismiss}
      width="445px"
    >
      <x.div display="flex" flexDirection="column" alignItems="center">
        <Text color="gray.300" mb={1}>
          You&apos;re are invited to join
        </Text>
        <Heading mb={4}>{group.name}</Heading>
        <Users users={users} limit={6} />
        <Text mt={4} mb={8} variant="md">
          {notificationText} {users.length > 1 ? 'have' : 'has'} already joined.
        </Text>
        <x.div display="flex" w="100%" mt={4} spaceX={2}>
          <Button
            label={loading ? 'Joining group...' : 'Join group'}
            w="100%"
            py={2}
            onClick={handleClick}
          />
          <Button
            label={t('view_posts')}
            w="100%"
            py={2}
            onClick={handleDismiss}
          />
        </x.div>
      </x.div>
    </GroupsModal>
  );
};
