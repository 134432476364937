import { Menu, MenuButton, MenuItem } from '@reach/menu-button';
import { th, x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  GroupTypes,
  GroupWithMembersFragment,
} from '../../../../generated/graphql-request-api-sdk';
import { useUpdateMembershipMutation } from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Icon } from '../../common/components/Icon';
import { MenuList } from '../../common/components/MenuList';
import { Text } from '../../common/components/Text';
import { bottomLeftAlign } from '../../common/utils/position';
import { useGroupMembership } from '../hooks/useGroupMembership';
import { GroupEditModal } from './GroupEditModal';

const StyledMenuButton = styled(MenuButton)`
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  pointer-events: all;

  span {
    color: ${th.color('gray.300')};
  }

  &:hover span {
    color: ${th.color('brand.300')};
  }
`;

export const GroupUserSettingsDropdown: React.FC<
  PropsWithChildren<{
    group: GroupWithMembersFragment;
    onRemove?: () => void;
    open?: boolean;
  }>
> = ({ group, open, children }) => {
  const ref = useRef<HTMLButtonElement | null>(null);
  const { organization } = useOrganization();
  const { userIsMember, userCanEdit } = useGroupMembership({
    group,
    organization,
  });

  useEffect(() => {
    if (open && ref.current) {
      ref.current.dispatchEvent(
        new MouseEvent('mousedown', {
          bubbles: true,
        })
      );
    }
  }, [open]);

  if (!userIsMember && !userCanEdit) {
    return null;
  }

  /**
   * All members get a new post notification toggle
   * All members get a new question occurrence toggle if one is set on the group
   * Group creators and admins can edit
   * All members can leave
   */
  return (
    <>
      <x.div>
        <Menu>
          {() => (
            <>
              <StyledMenuButton ref={ref} onClick={(e) => e.preventDefault()}>
                {children}
              </StyledMenuButton>
              <MenuList variant="small" position={bottomLeftAlign()}>
                <>
                  {group.membership &&
                    group.groupType !== GroupTypes.Course && (
                      <>
                        <GroupUserSettingNewPostNotifications group={group} />
                        {group.questionEnabled && (
                          <GroupUserSettingNewOccurrenceNotifications
                            group={group}
                          />
                        )}
                        <GroupUserSettingDivider />
                      </>
                    )}
                  {userCanEdit && <GroupUserSettingEdit group={group} />}
                  {group.membership && <GroupUserSettingLeave group={group} />}
                </>
              </MenuList>
            </>
          )}
        </Menu>
      </x.div>
    </>
  );
};

const GroupUserSettingNewPostNotifications: FC<{
  group: GroupWithMembersFragment;
}> = ({ group }) => {
  const [mutate] = useUpdateMembershipMutation();
  const { t } = useTranslation('groups');
  const handleSelect = () =>
    mutate({
      variables: {
        input: {
          groupId: group.id,
          allowNewPostNotifications:
            !group.membership?.allowNewPostNotifications,
        },
      },
    });

  return (
    <MenuItem
      onClick={(event) => event.preventDefault()}
      onSelect={handleSelect}
    >
      <x.div
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        spaceX={4}
      >
        <Text variant="md">{t('groups.new_post_notifications_member')}</Text>
        {group.membership?.allowNewPostNotifications && (
          <Icon name="check-mark" color="gray.500" size="5" />
        )}
      </x.div>
    </MenuItem>
  );
};

const GroupUserSettingNewOccurrenceNotifications: FC<{
  group: GroupWithMembersFragment;
}> = ({ group }) => {
  const { t } = useTranslation('groups');
  const [mutate] = useUpdateMembershipMutation();

  const handleSelect = () =>
    mutate({
      variables: {
        input: {
          groupId: group.id,
          allowNewOccurrenceNotifications:
            !group.membership?.allowNewOccurrenceNotifications,
        },
      },
    });
  return (
    <MenuItem
      onClick={(event) => event.preventDefault()}
      onSelect={handleSelect}
    >
      <x.div
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        spaceX={4}
      >
        <Text variant="md">{t('groups.reminder_notifications_member')}</Text>
        {group.membership?.allowNewOccurrenceNotifications && (
          <Icon name="check-mark" color="gray.500" size="5" />
        )}
      </x.div>
    </MenuItem>
  );
};

const GroupUserSettingEdit: FC<{ group: GroupWithMembersFragment }> = ({
  group,
}) => {
  const [editing, setEditing] = useState(false);
  const { t } = useTranslation('groups');
  const label =
    group.groupType === GroupTypes.Course
      ? t('groups.edit_course')
      : t('groups.edit_group');
  return (
    <>
      <MenuItem
        onClick={(event) => event.preventDefault()}
        onSelect={() => setEditing(true)}
      >
        <x.div
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          spaceX={4}
        >
          <Text variant="md">{label}</Text>
        </x.div>
      </MenuItem>
      <GroupEditModal
        group={group}
        isOpen={editing}
        onDismiss={() => {
          setEditing(false);
        }}
        ariaLabel={label}
      />
    </>
  );
};

const GroupUserSettingLeave: FC<{
  group: GroupWithMembersFragment;
}> = ({ group }) => {
  const { organization } = useOrganization();
  const { leaveGroup } = useGroupMembership({ group, organization });
  const { t } = useTranslation('groups');

  const label =
    group.groupType === GroupTypes.Course
      ? t('groups.leave_course')
      : t('groups.leave_group');

  return (
    <MenuItem
      onClick={(event) => event.preventDefault()}
      onSelect={() => {
        leaveGroup();
      }}
    >
      <x.div
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        spaceX={4}
      >
        <Text variant="md">{label}</Text>
      </x.div>
    </MenuItem>
  );
};

const GroupUserSettingDivider: FC = () => {
  return (
    <x.hr
      ml="-1rem"
      w="calc(100% + 2rem)"
      backgroundColor="gray.100"
      h="px"
      border="none"
    />
  );
};
