import { useRouter } from 'next/router';

import {
  useUserActivityCountsQuery,
  useUserQuery,
} from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { DetailLayoutMobileBar } from '../../common/layout/DetailLayout';
import { ProfileViewControls } from '../../questions/views/ProfileView';

export const ProfileMobileBar = () => {
  const router = useRouter();
  const username = router.query.username as string | undefined;

  const { organization } = useOrganization();
  const { data: dataUser } = useUserQuery({
    variables: {
      username: username || '',
      organizationId: organization.id,
    },

    skip: !username,
    context: {
      skip: !username,
    },
  });

  const { data: dataUserActivity } = useUserActivityCountsQuery({
    variables: {
      username: username || '',
      organizationId: organization.id,
    },
    skip: !username,
    context: {
      skip: !username,
    },
  });

  const user = dataUser?.user;

  return (
    <DetailLayoutMobileBar
      numPosts={dataUserActivity?.userActivityCounts?.numPosts}
      numGroups={dataUserActivity?.userActivityCounts?.numGroups}
      item={user}
      controls={<ProfileViewControls user={user} organization={organization} />}
    />
  );
};
