import * as React from "react";
import { SVGProps } from "react";
const SvgSad = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 22c5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10C6.478 2 2 6.478 2 12c0 5.523 4.478 10 10 10Z"
      fill="url(#sad_svg__a)"
    />
    <path
      d="M14 12.25a.5.5 0 0 1-.5-.5c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5a.5.5 0 1 1-1 0c0-1.379 1.121-2.5 2.5-2.5s2.5 1.121 2.5 2.5a.5.5 0 0 1-.5.5Z"
      fill="#666E76"
    />
    <path d="M8 13.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" fill="#fff" />
    <path
      d="M8 14c-1.654 0-3-1.345-3-3s1.346-3 3-3c1.655 0 3 1.345 3 3s-1.345 3-3 3Zm0-5c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2Z"
      fill="#727D86"
    />
    <path d="M16 13.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" fill="#fff" />
    <path
      d="M16 14c-1.655 0-3-1.345-3-3s1.345-3 3-3c1.654 0 3 1.345 3 3s-1.346 3-3 3Zm0-5c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2Z"
      fill="#727D86"
    />
    <path
      d="M8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM16 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM12 19.5a3 3 0 0 1-3-3 .5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5 3 3 0 0 1-3 3Z"
      fill="#343434"
    />
    <path
      d="M13.977 18.753a2.973 2.973 0 0 0-3.954 0 2.986 2.986 0 0 0 3.954 0Z"
      fill="url(#sad_svg__b)"
    />
    <path
      d="M12.5 17h-1a.5.5 0 0 1-.5-.5V16h2v.5a.5.5 0 0 1-.5.5Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="sad_svg__a"
        x1={5.016}
        y1={5.016}
        x2={19.055}
        y2={19.055}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEDE00" />
        <stop offset={0.519} stopColor="#FECC00" />
        <stop offset={1} stopColor="#FFB700" />
      </linearGradient>
      <linearGradient
        id="sad_svg__b"
        x1={12}
        y1={20.326}
        x2={12}
        y2={18.378}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F05964" />
        <stop offset={1} stopColor="#D4414C" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgSad;
