import sizes from '@frond/shared/lib/theme/sizes';
import * as ContextMenu from '@radix-ui/react-context-menu';
import { Menu, MenuButton } from '@reach/menu-button';
import styled, { x } from '@xstyled/styled-components';
import React, { FC, PropsWithChildren, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd';

import {
  refetchVisitedPublicOrganizationsQuery,
  useDirectMessageNotificationCountsQuery,
  useHideOrganizationVisitMutation,
  useNotificationCountsQuery,
  useReorderOrganizationMembershipMutation,
  useVisitedPublicOrganizationsQuery,
  ViewerDocument,
  ViewerQuery,
} from '../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { Avatar } from '../../common/components/Avatar';
import { Icon } from '../../common/components/Icon';
import { MenuList } from '../../common/components/MenuList';
import { Skeleton } from '../../common/components/Skeleton';
import { Text } from '../../common/components/Text';
import { Tooltip } from '../../common/components/Tooltip';
import { AppMachineOrganization } from '../../common/machine/appMachine';
import { rightBottomAlign, rightCenter } from '../../common/utils/position';
import { reorder } from '../../groups/components/GroupsSidebar/GroupsSidebarGroupList';
import { useJoinOrganization } from '../../invites/hooks/useJoinOrganization';
import { GroupsOnboardingModal } from '../../onboarding/views/GroupsOnboardingModal';
import { UserSettingsMenu } from '../../settings/components/UserSettingsMenu';
import { useOrganizationEventSubscription } from '../hooks/useOrganizationEventSubscription';
import { OrganizationAvatar } from './OrganizationAvatar';

/**
 * Narrow sidebar on the left for switching communities
 */
export const OrganizationSidebar = () => {
  const { viewer: user } = useViewer();
  const { organization } = useOptionalOrganization();

  const { data } = useVisitedPublicOrganizationsQuery({
    variables: {
      userId: user?.id || '',
    },
    skip: !user,
    context: {
      skip: !user,
    },
  });

  const [reorderOrganizationMembership] =
    useReorderOrganizationMembershipMutation();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <x.div
      display="flex"
      flexDirection="column"
      alignContent="space-between"
      py={4}
      px={3}
      h="full"
      boxSizing="border-box"
      spaceY={6}
    >
      <x.div spaceY={3} flex="1">
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }

            reorderOrganizationMembership({
              variables: {
                input: {
                  organizationMembershipId: result.draggableId,
                  order: result.destination.index + 1,
                },
              },
              update: (cache) => {
                const cachedQuery = cache.readQuery<ViewerQuery>({
                  query: ViewerDocument,
                });

                if (!cachedQuery || !result.destination) {
                  return;
                }

                cache.writeQuery({
                  query: ViewerDocument,
                  data: {
                    viewer: {
                      ...cachedQuery.viewer,
                      organizationMemberships: reorder(
                        cachedQuery.viewer.organizationMemberships,
                        result.source.index,
                        result.destination.index
                      ),
                    },
                  },
                });
              },
              optimisticResponse: {
                __typename: 'Mutation',
                reorderOrganizationMembership: {
                  __typename: 'OrganizationMembership',
                  id: result.draggableId,
                },
              },
            });
          }}
        >
          <Droppable droppableId="groups">
            {(provided: DroppableProvided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {user?.organizationMemberships.map(
                  (organizationMembership, index) => (
                    <Draggable
                      disableInteractiveElementBlocking
                      key={organizationMembership.id}
                      draggableId={organizationMembership.id}
                      index={index}
                    >
                      {(
                        provided: DraggableProvided,
                        snapshot: DraggableStateSnapshot
                      ) => (
                        <x.div
                          display="block"
                          mb={3}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <OrganizationSidebarThumbnail
                            selected={
                              organizationMembership.organization.id ===
                              organization?.id
                            }
                            organization={organizationMembership.organization}
                            isDragging={snapshot.isDragging}
                          />
                        </x.div>
                      )}
                    </Draggable>
                  )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <OrganizationSidebarThumbnailContainer onClick={() => setIsOpen(true)}>
          <OrganizationSidebarEmptyThumbnail>
            <Icon name="plus" size="5" />
          </OrganizationSidebarEmptyThumbnail>
        </OrganizationSidebarThumbnailContainer>

        {data && data.visitedPublicOrganizations.length > 0 ? (
          <x.div h="2px" w={9} bg="gray.100" mx="auto" borderRadius="sm" />
        ) : null}

        {data?.visitedPublicOrganizations.map((o) => {
          return (
            <OrganizationSidebarThumbnail
              key={o.id}
              selected={o.id === organization?.id}
              organization={o}
              visited
            />
          );
        })}
      </x.div>

      <UserMenu />
      <GroupsOnboardingModal
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
      />
    </x.div>
  );
};

export const OrganizationSidebarSkeleton = () => {
  return (
    <x.div
      display="flex"
      flexDirection="column"
      alignContent="space-between"
      h="100%"
      py={4}
      px={3}
      boxSizing="border-box"
      spaceY={3}
    >
      {[...Array(2)].map((_, index) => {
        return (
          <x.div w="11" h="11" borderRadius={sizes[3]} key={index}>
            <Skeleton height="100%" />
          </x.div>
        );
      })}
    </x.div>
  );
};

const UserMenu: FC = () => {
  const { viewer: user } = useViewer();

  if (!user) return null;

  return (
    <>
      <Menu>
        {({ isExpanded }) => (
          <>
            <MenuButton as="span" onClick={(event) => event.preventDefault()}>
              <OrganizationSidebarThumbnailContainer selected={isExpanded}>
                <Avatar
                  size="m"
                  imageId={user.profileImageId}
                  avatar={user.avatar}
                  bgColor={user.avatarBgColor}
                />
              </OrganizationSidebarThumbnailContainer>
            </MenuButton>
            <MenuList portal position={rightBottomAlign}>
              <UserSettingsMenu minWidth="3xs" />
            </MenuList>
          </>
        )}
      </Menu>
    </>
  );
};

const StyledContextMenuItem = styled(ContextMenu.Item)`
  padding: 1;
  cursor: pointer;
  transition-duration: fast;
  transition-timing-function: ease-in;

  &:hover {
    color: brand.300;
  }
`;

/**
 * A single clickable organization
 */
const OrganizationSidebarThumbnail: FC<{
  organization: AppMachineOrganization;
  selected: boolean;
  isDragging?: boolean;
  visited?: boolean;
}> = ({ organization, selected, visited, isDragging = false }) => {
  const { setAndViewOrganization } = useOptionalOrganization();
  const { viewer: user } = useViewer();

  const [hideOrganizationVisit] = useHideOrganizationVisitMutation({
    refetchQueries: [
      refetchVisitedPublicOrganizationsQuery({
        userId: user?.id || '',
      }),
    ],
  });
  const { mutateJoinOrganization } = useJoinOrganization({ organization });

  const { data: notificationCountsQuery } = useNotificationCountsQuery({
    variables: { organizationId: organization.id },
    skip: !user,
    context: {
      skip: !user,
    },
  });

  const { data: directMessageNotificationCountsQuery } =
    useDirectMessageNotificationCountsQuery({
      variables: { organizationId: organization.id },
      skip: !user,
      context: {
        skip: !user,
      },
    });

  // Subscribe to organization events
  useOrganizationEventSubscription(organization.id);

  const unreadNotifications =
    (notificationCountsQuery?.notificationCounts.unread || 0) +
    (directMessageNotificationCountsQuery?.directMessageNotificationCounts
      .unread || 0);

  const handleClick = () => setAndViewOrganization(organization);

  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger asChild disabled={!visited}>
        <div>
          <Tooltip
            label={<Text variant="sm-semibold">{organization.name}</Text>}
            aria-label={`${organization.name} switcher`}
            size="small"
            position={rightCenter}
          >
            <x.div userSelect="none">
              <OrganizationSidebarThumbnailContainer
                onClick={handleClick}
                selected={!isDragging && selected}
              >
                <x.div borderRadius={sizes[2]} overflow="hidden">
                  <OrganizationAvatar organization={organization} size="m" />
                </x.div>
                {!!unreadNotifications && (
                  <x.div
                    borderRadius="full"
                    w="3"
                    h="3"
                    backgroundColor="red.300"
                    position="absolute"
                    top="-2"
                    right="-2"
                  />
                )}
              </OrganizationSidebarThumbnailContainer>
            </x.div>
          </Tooltip>
        </div>
      </ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content data-align="end">
          <x.div boxShadow="sm" p={3} spaceY={1} borderRadius="md" bg="white">
            <StyledContextMenuItem
              onClick={() => {
                mutateJoinOrganization({
                  variables: {
                    input: {
                      organizationId: organization.id,
                      userId: user?.id || '',
                    },
                  },
                });
              }}
            >
              Join {organization.name}
            </StyledContextMenuItem>
            <StyledContextMenuItem
              onClick={() => {
                hideOrganizationVisit({
                  variables: {
                    input: {
                      organizationId: organization.id,
                    },
                  },
                });
              }}
            >
              Hide {organization.name}
            </StyledContextMenuItem>
          </x.div>
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  );
};

/**
 * Wrapper that adds common layout and hover styles for all items in the org sidebar
 */
const OrganizationSidebarThumbnailContainer: FC<
  PropsWithChildren<{
    selected?: boolean;
    onClick?: () => void;
  }>
> = ({ children, selected, onClick }) => {
  return (
    <x.button
      w="11"
      h="11"
      display="flex"
      justifyContent="center"
      alignItems="center"
      boxSizing="border-box"
      borderWidth={sizes['0.5']}
      borderStyle="solid"
      borderColor={
        selected ? 'brand.300' : { _: 'transparent', hover: 'brand.100' }
      }
      color={selected ? 'brand.300' : { _: 'gray.300', hover: 'brand.300' }}
      transitionDuration="faster"
      transitionProperty="color, border-color"
      transitionTimingFunction="ease-in"
      borderRadius={sizes[3]}
      cursor="pointer"
      onClick={onClick}
      p={0}
      background="transparent"
      position="relative"
    >
      {children}
    </x.button>
  );
};

/**
 * Common layout for orgs without images and create new org icon
 */
const OrganizationSidebarEmptyThumbnail: FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <x.div
      w="9"
      h="9"
      backgroundColor="gray.50"
      borderRadius={sizes[2]}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </x.div>
  );
};
