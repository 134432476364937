import * as React from "react";
import { SVGProps } from "react";
const SvgClassroom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.084 17.5H2.916a1.669 1.669 0 0 1-1.666-1.667V4.166c0-.919.747-1.667 1.666-1.667h14.168c.918 0 1.666.748 1.666 1.667v11.667c0 .92-.748 1.667-1.666 1.667Z"
      fill="url(#classroom_svg__a)"
    />
    <path
      d="M17.083 15.832h-2.082l-1.667-.416-1.667.417h-8.75V4.165h14.166v11.666Z"
      fill="url(#classroom_svg__b)"
    />
    <path
      d="M15 15.833h-3.333V15H15v.833ZM11.25 8.333a1.25 1.25 0 1 0-2.501 0 1.25 1.25 0 0 0 2.5 0Z"
      fill="#fff"
    />
    <path
      d="M7.5 9.583a.834.834 0 1 0-1.668 0 .834.834 0 0 0 1.668 0Z"
      fill="#66D3A1"
    />
    <path d="M15 15.833h-3.333l1.667 1.667h3.333L15 15.833Z" fill="#FFBF00" />
    <path
      d="M14.167 9.583a.834.834 0 1 0-1.667 0 .834.834 0 0 0 1.667 0ZM15.417 11.953a.73.73 0 0 0-.195-.5c-.289-.308-.904-.62-1.888-.62-.985 0-1.6.312-1.889.62a.73.73 0 0 0-.195.5v.547h4.167v-.547ZM8.75 11.953a.73.73 0 0 0-.195-.5c-.288-.308-.903-.62-1.888-.62-.985 0-1.6.312-1.889.62a.73.73 0 0 0-.195.5v.547H8.75v-.547Z"
      fill="#66D3A1"
    />
    <path
      d="M12.5 11.559a.874.874 0 0 0-.234-.6A3.059 3.059 0 0 0 10 10c-1.18 0-1.92.591-2.266.96a.87.87 0 0 0-.234.6v.941h5v-.941Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="classroom_svg__a"
        x1={2.811}
        y1={2.81}
        x2={13.471}
        y2={13.47}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD647" />
        <stop offset={1} stopColor="#F5BF00" />
      </linearGradient>
      <linearGradient
        id="classroom_svg__b"
        x1={4.005}
        y1={4.004}
        x2={15.023}
        y2={15.023}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#21AD64" />
        <stop offset={1} stopColor="#088242" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgClassroom;
