import { theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';

import { Button } from '../../../../common/components/Button';
import { Icon } from '../../../../common/components/Icon';
import { Text } from '../../../../common/components/Text';
import { Tooltip } from '../../../../common/components/Tooltip';

export const MemberItemRequestButtons: React.FC<{
  onAccept: () => void;
  onReject: () => void;
}> = ({ onAccept, onReject }) => {
  const { t } = useTranslation(['common', 'settings']);

  return (
    <x.div display="flex" spaceX={2}>
      <Tooltip
        aria-label={t('settings.decline_access', { ns: 'settings' })}
        label={
          <Text variant="sm-semibold">
            {t('settings.decline_access', { ns: 'settings' })}
          </Text>
        }
      >
        <x.div>
          <Button
            leftElement={<Icon name="close" />}
            backgroundColor={{
              _: 'red.100',
              hover: 'red.300',
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            color={{
              _: 'white',
              hover: 'white',
            }}
            borderRadius="sm-md"
            variant="icon"
            onClick={onReject}
            p={theme.sizes['1.5']}
          />
        </x.div>
      </Tooltip>
      <Tooltip
        aria-label="Approve access"
        label={<Text variant="sm-semibold">Approve access</Text>}
      >
        <x.div>
          <Button
            leftElement={<Icon name="check-mark" />}
            backgroundColor={{
              _: 'green.100',
              hover: 'green.300',
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            color={{
              _: 'white',
              hover: 'white',
            }}
            borderRadius="sm-md"
            variant="icon"
            onClick={onAccept}
            p={theme.sizes['1.5']}
          />
        </x.div>
      </Tooltip>
    </x.div>
  );
};
