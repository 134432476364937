import {
  createPluginFactory,
  onKeyDownToggleElement,
  PlatePlugin,
} from '@udecode/plate-common';
import { HeadingPlugin, KEYS_HEADING } from '@udecode/plate-heading';

export const createHeadingPlugin = createPluginFactory<{ levels: number }>({
  key: 'heading',
  options: {
    levels: 6,
  },
  then: (editor, { options: { levels } = { levels: 3 }, component }) => {
    const plugins: PlatePlugin<HeadingPlugin>[] = [];

    for (let level = 1; level <= levels; level++) {
      const key = KEYS_HEADING[level - 1];

      const plugin: PlatePlugin<HeadingPlugin> = {
        key,
        isElement: true,
        component,
        deserializeHtml: {
          rules: [
            {
              validNodeName: `H${level}`,
            },
          ],
        },
        handlers: {
          onKeyDown: onKeyDownToggleElement,
        },
        options: {},
      };

      if (level < 4 && plugin.options) {
        plugin.options.hotkey = [`mod+opt+${level}`, `mod+shift+${level}`];
      }

      plugins.push(plugin);
    }

    return {
      plugins,
    };
  },
});
