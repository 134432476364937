import { x } from '@xstyled/styled-components';
import { SystemProps } from '@xstyled/system';
import React from 'react';

import { DISABLE_EMOJI_SPRITE_SHEET } from '../../../config/constants';
import { EmojiProps, EmojiSprite } from './EmojiSprite';
import { Text } from './Text';

/**
 * Emoji component either renders an emoji unicode or EmojiSprite
 */
export const Emoji: React.FC<EmojiProps & SystemProps> = (props) => {
  const { emojiUnicode, size } = props;

  const element = (
    <x.div w={`${size}px`} h={`${size}px`} display="flex" alignItems="center">
      <Text
        as="span"
        style={{
          fontSize: `calc(${size}px / var(--emoji-scale-factor))`,
        }}
      >
        {emojiUnicode}
      </Text>
    </x.div>
  );

  if (DISABLE_EMOJI_SPRITE_SHEET) return element;

  return <EmojiSprite {...props} />;
};
