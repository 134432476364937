import * as React from "react";
import { SVGProps } from "react";
const SvgCode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.868 3.325a.625.625 0 0 0-.59.448l-3.75 12.083a.625.625 0 1 0 1.193.371l3.75-12.083a.626.626 0 0 0-.603-.819ZM5.63 5.41a.625.625 0 0 0-.435.178L1.028 9.547a.625.625 0 0 0 0 .906l4.166 3.959a.625.625 0 1 0 .861-.907L2.365 10l3.69-3.505A.625.625 0 0 0 5.63 5.41Zm8.723.001a.625.625 0 0 0-.408 1.084L17.634 10l-3.69 3.505a.626.626 0 1 0 .861.907l4.167-3.959a.626.626 0 0 0 0-.906l-4.167-3.959a.625.625 0 0 0-.453-.177Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCode;
