import * as React from "react";
import { SVGProps } from "react";
const SvgTypeupgrade = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 1.667c-4.595 0-8.333 3.738-8.333 8.333 0 4.595 3.738 8.333 8.333 8.333 4.595 0 8.333-3.738 8.333-8.333 0-4.595-3.738-8.333-8.333-8.333Zm0 1.25A7.074 7.074 0 0 1 17.083 10 7.074 7.074 0 0 1 10 17.083 7.074 7.074 0 0 1 2.917 10 7.074 7.074 0 0 1 10 2.917Zm-.03 1.251a.631.631 0 0 0-.49.278c-.022.033-.043.066-.06.108a.94.94 0 0 0-.033.117.624.624 0 1 0 1.226 0 .956.956 0 0 0-.034-.117.624.624 0 0 0-.61-.386Zm2.322.514a.628.628 0 1 0-.03 1.252c.23 0 .454-.134.563-.355a.62.62 0 0 0-.292-.833.619.619 0 0 0-.24-.064Zm-4.589.002a.627.627 0 0 0-.532.895.625.625 0 1 0 .532-.895Zm6.318 1.446a.624.624 0 0 0 .05 1.245.629.629 0 0 0 .392-.133.628.628 0 0 0-.442-1.112Zm-8.044.002a.626.626 0 0 0-.44 1.114c.113.088.25.133.388.134a.627.627 0 0 0 .051-1.248Zm4.014.535a.625.625 0 0 0-.433.183L7.683 8.725a.625.625 0 1 0 .884.884l.808-.809v3.908a.625.625 0 1 0 1.25 0V8.8l.808.809a.626.626 0 1 0 .884-.884L10.442 6.85a.625.625 0 0 0-.451-.183Zm5.072 1.547a.626.626 0 1 0 .153 1.236.625.625 0 0 0-.153-1.236Zm-10.13.004a.625.625 0 0 0-.149 1.236.698.698 0 0 0 .137.012.623.623 0 0 0 .608-.483.622.622 0 0 0-.597-.765ZM15.09 10.53a.62.62 0 0 0-.62.486.623.623 0 1 0 1.217.279.626.626 0 0 0-.597-.765Zm-10.182.004a.627.627 0 0 0-.597.765.627.627 0 0 0 .61.483.77.77 0 0 0 .137-.012.625.625 0 0 0-.15-1.236Zm9.215 2.086a.628.628 0 0 0-.441 1.112.627.627 0 0 0 .78-.98.62.62 0 0 0-.339-.132Zm-8.247.002a.627.627 0 0 0-.44 1.014.63.63 0 0 0 .88.097.62.62 0 0 0 .1-.876.628.628 0 0 0-.54-.235Zm1.894 1.444a.626.626 0 0 0-.304 1.19.624.624 0 1 0 .304-1.19Zm4.462 0a.621.621 0 0 0-.529.895.616.616 0 0 0 .833.292.626.626 0 0 0 .292-.833.628.628 0 0 0-.596-.354ZM10 14.591a.619.619 0 0 0-.442.176c-.03.029-.054.062-.079.096a.526.526 0 0 0-.054.108.542.542 0 0 0-.05.237.624.624 0 0 0 1.067.441.626.626 0 0 0 .137-.204.645.645 0 0 0 0-.474.874.874 0 0 0-.058-.108.619.619 0 0 0-.52-.271Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgTypeupgrade;