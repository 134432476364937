import { routes } from '@frond/shared';

import { ViewerQuery } from '../../../../generated/types-and-hooks';
import {
  OrganizationForRoutingPermissions,
  UserForRoutingPermissions,
} from '../../auth/utils/permissions';

export const getSignInRedirectUrl = (
  user?: UserForRoutingPermissions,
  organization?: OrganizationForRoutingPermissions,
  callbackUrl?: string
) => {
  if (callbackUrl) {
    return callbackUrl;
  }

  if (organization) {
    return routes.groups.organization(organization.shortId).feed();
  }

  if (
    user?.organizationMemberships?.length &&
    user.organizationMemberships[0].organization
  ) {
    return routes.groups
      .organization(user.organizationMemberships[0].organization.shortId)
      .feed();
  }

  return routes.groups.organizations();
};

export const shouldDisplayOnboarding = (
  user: Pick<
    ViewerQuery['viewer'],
    'claimedAccount' | 'preferences' | 'organizationMemberships' | 'userInvites'
  >
) => {
  return (
    // User has no invites and user has not claimed account and seen groups onboarding
    user.userInvites.length === 0 &&
    !user.claimedAccount &&
    user.preferences.showQuestionsOnboarding
  );
};
