import { MyValue, routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import {
  GroupTypes,
  refetchGroupsQuery,
  refetchMenuSectionsQuery,
  useCreateAndJoinGroupMutation,
  useCreateCourseMutation,
} from '../../../../../generated/types-and-hooks';
import { GROUPS_PER_PAGE } from '../../../../config/constants';
import {
  TabbedModal,
  TabbedModalContentInset,
} from '../../../common/components/TabbedModal';
import { FormLayout } from '../GroupEditModal/GroupEditAbout/GroupEditAboutTab';
import { GroupEditSettingPrivate } from '../GroupEditModal/GroupEditSettings/GroupEditSettingPrivate';
import { GroupFormDescriptionField } from '../GroupEditModal/GroupForm/GroupFormDescriptionField';
import { GroupFormNameField } from '../GroupEditModal/GroupForm/GroupFormNameField';
import { GroupFormSubmitButton } from '../GroupEditModal/GroupForm/GroupFormSubmitButton';
import { GroupsSidebarOrganization } from '../GroupsSidebar/GroupsSidebar';
import { GroupCreateForm, GroupCreateFormData } from './GroupCreateForm';
import { GroupCreateHeading } from './GroupCreateHeading';

export const GroupCreateModal: FC<{
  organization: GroupsSidebarOrganization;
  type: GroupTypes.Regular | GroupTypes.Course | GroupTypes.Event;
  isOpen: boolean;
  onDismiss: () => void;
  onCompleted?: (id: string) => void;
  overrides?: {
    isPrivate: boolean;
  };
  menuSectionId?: string;
}> = ({
  organization,
  isOpen = false,
  onDismiss,
  onCompleted,
  type,
  overrides,
  menuSectionId,
}) => {
  const { t } = useTranslation(['groups', 'common']);
  const router = useRouter();

  const commonOptions = {
    awaitRefetchQueries: true,
    refetchQueries: [
      refetchMenuSectionsQuery({
        organizationId: organization.id,
      }),
      refetchGroupsQuery({
        organizationId: organization.id,
        first: GROUPS_PER_PAGE - 1,
      }),
    ],
  };

  const [mutateCreateAndJoinGroup, { loading }] = useCreateAndJoinGroupMutation(
    {
      ...commonOptions,
      onCompleted: ({ createAndJoinGroup }) => {
        onDismiss?.();

        const id =
          createAndJoinGroup.__typename === 'Group' && createAndJoinGroup.id;

        if (!id) {
          return;
        }

        onCompleted
          ? onCompleted(id)
          : router.push(
              routes.groups.organization(organization.shortId).group(id)
            );
      },
    }
  );

  const [createCourse, { loading: isLoadingCreatingCourse }] =
    useCreateCourseMutation({
      ...commonOptions,
      onCompleted: ({ createCourse }) => {
        onDismiss?.();

        const id = createCourse.__typename === 'Course' && createCourse.id;

        if (!id) {
          return;
        }

        onCompleted
          ? onCompleted(id)
          : router.push(
              routes.groups.organization(organization.shortId).group(id)
            );
      },
    });

  const handleCreateGroup = ({ ...data }: GroupCreateFormData) => {
    const { title, isPrivate, description, ...values } = data;

    if (type === GroupTypes.Course) {
      createCourse({
        variables: {
          input: {
            organizationId: organization.id,
            name: title,
            menuSectionId,
            description: description as MyValue,
            ...values,
          },
        },
      });
    } else {
      mutateCreateAndJoinGroup({
        variables: {
          input: {
            organizationId: organization.id,
            name: title,
            isPrivate,
            menuSectionId,
            description: description as MyValue,
            ...values,
          },
        },
      });
    }
  };

  const tabs = {
    create: (
      <GroupCreateForm
        isPrivate={overrides?.isPrivate}
        onSubmit={handleCreateGroup}
      >
        <GroupCreateHeading />
        <TabbedModalContentInset>
          <FormLayout>
            <GroupFormNameField type={type} />
            <GroupFormDescriptionField type={type} />
            {!overrides && type !== GroupTypes.Course ? (
              <GroupEditSettingPrivate first last />
            ) : (
              <x.div />
            )}
          </FormLayout>
          <GroupFormSubmitButton
            loading={loading || isLoadingCreatingCourse}
            label={t('create', { ns: 'common' })}
          />
        </TabbedModalContentInset>
      </GroupCreateForm>
    ),
  };

  const title =
    type === GroupTypes.Regular
      ? t('create_group', { ns: 'common' })
      : t('create_course', { ns: 'common' });
  return (
    <TabbedModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      title={title}
      tabs={tabs}
      ariaLabel={title}
    />
  );
};
