import * as React from "react";
import { SVGProps } from "react";
const SvgCrop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.199 1.658a.625.625 0 0 0-.616.634v2.291H2.292a.625.625 0 1 0 0 1.25h2.291v6.875a2.718 2.718 0 0 0 2.709 2.709h6.875v2.291a.625.625 0 1 0 1.25 0v-2.291h2.291a.624.624 0 1 0 0-1.25h-2.814a.627.627 0 0 0-.203 0h-7.4a1.45 1.45 0 0 1-1.458-1.459V5.311a.625.625 0 0 0 0-.203V2.292a.625.625 0 0 0-.634-.634Zm1.468 2.925v1.25h6.041a1.45 1.45 0 0 1 1.459 1.459v6.041h1.25V7.292a2.718 2.718 0 0 0-2.709-2.709H6.667Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCrop;
