import * as React from "react";
import { SVGProps } from "react";
const SvgDrafts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.208 1.667a1.877 1.877 0 0 0-1.875 1.875v12.916c0 1.034.842 1.875 1.875 1.875h3.977c.015-.103.04-.206.077-.306v.001c.023-.093.05-.19.08-.292l.187-.653h-4.32a.626.626 0 0 1-.626-.625V3.542c0-.345.28-.625.625-.625H10v3.541c0 1.034.841 1.875 1.875 1.875h3.542V9.93a2.76 2.76 0 0 1 1.25-.684V7.708a.623.623 0 0 0-.183-.442L11.067 1.85a.623.623 0 0 0-.442-.183H5.208ZM11.25 3.8l3.283 3.283h-2.658a.626.626 0 0 1-.625-.625V3.8Zm6.04 6.2c-.113 0-.229.01-.345.032-.368.066-.72.258-1.017.556l-4.916 4.916a1.254 1.254 0 0 0-.317.535l-.654 2.284a.618.618 0 0 0 .803.803l2.284-.654a1.25 1.25 0 0 0 .534-.317l4.917-4.916c.298-.297.49-.649.556-1.017a2 2 0 0 0 .03-.346 1.85 1.85 0 0 0-.548-1.326A1.855 1.855 0 0 0 17.29 10Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgDrafts;
