import { merge } from 'lodash';

import {
  JoinPolicy,
  Organization,
  OrganizationQuery,
  User,
  ViewerQuery,
} from '../../../../generated/types-and-hooks';

const MAIN = 'main';
const ADMIN = 'admin';

const COMMUNITY_SETTINGS_ROUTES = [
  '/[shortId]/settings',
  '/[shortId]/settings/plans',
  '/[shortId]/settings/details',
  '/[shortId]/settings/members',
  '/[shortId]/settings/integrations',
  '/[shortId]/settings/permissions',
  '/[shortId]/settings/paywall',
  '/[shortId]/settings/pricing',
  '/[shortId]/settings/onboarding',
  '/[shortId]/settings/roles',
  '/[shortId]/settings/features',
  '/[shortId]/settings/widget',
  '/[shortId]/settings/custom-domain',
];

// Protected routes requiring viewer and organization
const PROTECTED_ROUTES_WITH_VIEWER_AND_ORG = {
  [MAIN]: [
    ...[
      '/[shortId]',
      '/[shortId]/home',
      '/[shortId]/events',
      '/[shortId]/event/[id]',
      '/[shortId]/messages',
      '/[shortId]/messages/[id]',
      '/[shortId]/messages/new',
      '/[shortId]/groups',
      '/[shortId]/group',
      '/[shortId]/group/[id]',
      '/[shortId]/courses/[id]',
      '/[shortId]/post',
      '/[shortId]/post/[id]',
      '/[shortId]/post/new-member',
      '/[shortId]/members',
      '/[shortId]/members/[username]',
      '/[shortId]/inbox',
      '/[shortId]/inbox/[id]',
      '/[shortId]/insights',
      '/[shortId]/bookmarks',
      '/[shortId]/tags/[tag]',
      '/[shortId]/drafts',
    ],
    ...COMMUNITY_SETTINGS_ROUTES,
  ],
};

export const PUBLIC_COMMUNITIES_ALLOWED_ROUTES = [
  '/[shortId]',
  '/[shortId]/home',
  '/[shortId]/events',
  '/[shortId]/events/[id]',
  '/[shortId]/groups',
  '/[shortId]/group',
  '/[shortId]/group/[id]',
  '/[shortId]/post',
  '/[shortId]/post/[id]',
  '/[shortId]/members',
  '/[shortId]/members/[username]',
];

const ACCOUNT_ROUTES = [
  '/account/account',
  '/account/notifications',
  '/account/memberships',
  '/account/billing',
];

const COMMUNITY_PICKER_ROUTES = ['/communities', '/communities/new'];

// Protected routes requiring only viewer
const PROTECTED_ROUTES_WITH_VIEWER = {
  [ADMIN]: ['/admin', '/admin/organizations'],
  [MAIN]: [...COMMUNITY_PICKER_ROUTES, ...ACCOUNT_ROUTES],
};

// Storing routes clientside to avoid a API call for un authenticated users
export const PROTECTED_ROUTES: {
  [key: string]: string;
} = merge({
  ...PROTECTED_ROUTES_WITH_VIEWER_AND_ORG,
  [MAIN]: [
    ...PROTECTED_ROUTES_WITH_VIEWER_AND_ORG[MAIN],
    ...PROTECTED_ROUTES_WITH_VIEWER[MAIN],
  ],
});

const routeIsProtectedRoute = (
  route: string,
  protectedRoute: string | string[]
) => {
  if (typeof protectedRoute === 'string') {
    return route === protectedRoute;
  } else {
    let isProtectedRoute = false;
    protectedRoute.map((pr) => {
      if (route === pr) {
        isProtectedRoute = true;
      }
    });
    return isProtectedRoute;
  }
};

/**
 * check if route is community picker specific route
 */
export const isCommunityPickerRoute = (route: string): boolean => {
  return !!COMMUNITY_PICKER_ROUTES.find(
    (communityRoute) => route === communityRoute
  );
};

/**
 * check if route is community specific route
 */
export const isCommunityRoute = (route: string): boolean => {
  return isProtectedRoute(route) && !isProtectedRouteWithViewer(route);
};

/**
 * check if route is community settings specific route
 */
export const isCommunitySettingsRoute = (route: string): boolean => {
  return !!COMMUNITY_SETTINGS_ROUTES.find(
    (settingsRoute) => route === settingsRoute
  );
};

/**
 * check if route is account settings specific route
 */
export const isAccountSettingsRoute = (route: string): boolean => {
  return !!ACCOUNT_ROUTES.find((settingsRoute) => route === settingsRoute);
};

/**
 * check if route is auth callback specific route
 */
export const isAuthCallbackRoute = (route: string): boolean => {
  return route === '/auth-callback';
};

/**
 * check if route is protected by a feature
 */
export const isProtectedRoute = (route: string): boolean => {
  return !!Object.entries(PROTECTED_ROUTES).some(([, protectedRoute]) =>
    routeIsProtectedRoute(route, protectedRoute)
  );
};

/**
 * check if route is protected and requires viewer by a feature
 */
export const isProtectedRouteWithViewer = (route: string): boolean => {
  return !!Object.entries(PROTECTED_ROUTES_WITH_VIEWER).some(
    ([, protectedRoute]) => routeIsProtectedRoute(route, protectedRoute)
  );
};

export const organizationAllowsJoin = (
  organization?: Pick<
    NonNullable<OrganizationQuery['organization']>,
    'id' | 'joinPolicy'
  > | null
) => {
  return organization && organization.joinPolicy !== JoinPolicy.Restricted;
};

export const userInOrganizationByShortId = (
  user: Pick<ViewerQuery['viewer'], 'organizationMemberships'>,
  shortId: string
) => {
  return user.organizationMemberships?.find((organizationMembership) => {
    return (
      organizationMembership.organization.shortId === shortId ||
      organizationMembership.organization.legacyShortId === shortId
    );
  });
};

export const userInOrganization = (
  user?: Pick<ViewerQuery['viewer'], 'organizationMemberships'>,
  organization?: Pick<
    NonNullable<OrganizationQuery['organization']>,
    'id' | 'isPublic'
  > | null
) => {
  if (!user) {
    return false;
  }
  return (
    organization &&
    user.organizationMemberships?.find((organizationMembership) => {
      return organizationMembership.organization.id === organization.id;
    })
  );
};

export const userCanViewOrganization = (
  user: Pick<ViewerQuery['viewer'], 'organizationMemberships'>,
  organization?: Pick<
    NonNullable<OrganizationQuery['organization']>,
    'id' | 'isPublic'
  > | null
) => {
  if (organization && organization.isPublic) {
    return true;
  }

  return userInOrganization(user, organization);
};

export type OrganizationForRoutingPermissions = Pick<
  Organization,
  'id' | 'shortId'
> | null;

export type UserForRoutingPermissions =
  | (Pick<User, 'id'> & {
      organizationMemberships?:
        | {
            organization: OrganizationForRoutingPermissions;
          }[]
        | null;
    })
  | null;
