import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { format } from 'date-fns';
import { useTranslation } from 'next-i18next';

import {
  NotificationQuery,
  refetchNotificationQuery,
  refetchUserRequestsToJoinOrganizationQuery,
  RequestToJoinStatusTypes,
  useAcceptRequestToJoinMutation,
  useRejectRequestToJoinMutation,
} from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Button } from '../../common/components/Button';
import { Text } from '../../common/components/Text';
import { MEMBERS_LIMIT } from '../../settings/components/SettingsViews/CommunityMembers/CommunityMembersListView';
import { MemberItemRequestButtons } from '../../settings/components/SettingsViews/CommunityMembers/MemberItemRequestButtons';
import { InboxNotificationCommon } from './InboxNotificationCommon';

const InboxNotificationRequestToJoinCommon: React.FC<{
  title: string;
  description: string;
  notification: NotificationQuery['notification'];
}> = ({ title, description, notification }) => {
  const { t } = useTranslation(['common']);
  const { organization } = useOrganization();

  return (
    <InboxNotificationCommon
      icon="key"
      title={title}
      description={description}
      action={
        <Button
          href={routes.settings.organization(organization.shortId).members()}
          variant="secondary"
          label={t('view_and_manage_members')}
        />
      }
    >
      {notification.requestToJoins?.map((requestToJoin) => (
        <InboxNotificationRequestToJoinItem
          key={requestToJoin.id}
          notification={notification}
          requestToJoin={requestToJoin}
        />
      ))}
    </InboxNotificationCommon>
  );
};

export const InboxNotificationRequestToJoin: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation(['common', 'settings']);
  const { organization } = useOrganization();
  return (
    <InboxNotificationRequestToJoinCommon
      title={t('rtj_link.title')}
      description={t('rtj_link.description', {
        firstName:
          notification.otherUser?.firstName || notification.otherUser?.username,
        email: notification.requestToJoins[0].email,
        organizationName: organization?.name,
      })}
      notification={notification}
    />
  );
};

export const InboxNotificationRequestToJoinInvite: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation(['common', 'settings']);
  const { organization } = useOrganization();

  return (
    <InboxNotificationRequestToJoinCommon
      title={t('rtj_invite.title')}
      description={
        notification.requestToJoins.length > 1
          ? t('rtj_invite.multiple.description', {
              firstName:
                notification.otherUser?.firstName ||
                notification.otherUser?.username,
              organizationName: organization?.name,
              numMembers: notification.requestToJoins.length,
            })
          : t('rtj_invite.single.description', {
              firstName:
                notification.otherUser?.firstName ||
                notification.otherUser?.username,
              email: notification.requestToJoins[0].email,
              organizationName: organization?.name,
            })
      }
      notification={notification}
    />
  );
};

const InboxNotificationRequestToJoinItem: React.FC<{
  notification: NotificationQuery['notification'];
  requestToJoin: NotificationQuery['notification']['requestToJoins'][0];
}> = ({ notification, requestToJoin }) => {
  const { t } = useTranslation(['common']);
  const { organization } = useOrganization();

  const [acceptRequest] = useAcceptRequestToJoinMutation({
    refetchQueries: [
      refetchNotificationQuery({
        id: notification.id,
      }),
      refetchUserRequestsToJoinOrganizationQuery({
        organizationId: organization.id,
        limit: MEMBERS_LIMIT,
      }),
    ],
    variables: {
      input: {
        requestToJoinId: requestToJoin.id,
      },
    },
  });

  const [rejectRequest] = useRejectRequestToJoinMutation({
    refetchQueries: [
      refetchNotificationQuery({
        id: notification.id,
      }),
    ],
    variables: {
      input: {
        requestToJoinId: requestToJoin.id,
      },
    },
  });

  return (
    <x.div
      display={{ 'sm-2': 'flex' }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      borderRadius="md"
      backgroundColor="white"
      px={5}
      py={4.5}
      spaceY={{ _: 1, 'sm-2': 0 }}
    >
      <Text variant="md" color="gray.300">
        {requestToJoin.email}
      </Text>
      <x.div
        display={{ 'sm-2': 'flex' }}
        flexDirection="row"
        alignItems="center"
        minH={8}
        gap={12}
        spaceY={{ _: 3, 'sm-2': 0 }}
      >
        <Text variant="md" maxW={250}>
          {format(new Date(requestToJoin.createdAt), 'MMM d, yyyy')}
        </Text>
        <>
          {(() => {
            switch (requestToJoin.status) {
              case RequestToJoinStatusTypes.Approved: {
                return <Text variant="sm-semibold">{t('approved')}</Text>;
              }
              case RequestToJoinStatusTypes.Rejected: {
                return <Text variant="sm-semibold">{t('declined')}</Text>;
              }
              default: {
                return (
                  <MemberItemRequestButtons
                    onAccept={() => acceptRequest()}
                    onReject={() => rejectRequest()}
                  />
                );
              }
            }
          })()}
        </>
      </x.div>
    </x.div>
  );
};
