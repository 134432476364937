import {
  PostFeedFragment,
  PostFeedFragmentDoc,
} from '../../../../generated/graphql-request-api-sdk';
import { useCreateCommentMutation } from '../../../../generated/types-and-hooks';

export const useCreateComment = ({
  parent,
  onCompleted,
}: {
  parent?: PostFeedFragment | null;
  onCompleted?: () => void;
}): {
  mutateCreateComment: ReturnType<typeof useCreateCommentMutation>[0];
  isSubmitting: boolean;
} => {
  const [mutateCreateComment, { loading }] = useCreateCommentMutation({
    onCompleted,
    update(cache, res) {
      const comment = res.data?.createComment;

      if (comment && parent) {
        if (parent.__typename == 'Post') {
          cache.writeFragment<PostFeedFragment>({
            id: `Post:${parent.id}`,
            fragment: PostFeedFragmentDoc,
            fragmentName: 'PostFeed',
            data: {
              ...parent,
              group: {
                ...parent.group,
              },
              comments: [comment, ...parent.comments],
            },
          });
        } else {
          throw new Error('Unknown type.');
        }
      }
    },
  });

  return {
    mutateCreateComment,
    isSubmitting: loading,
  };
};
