import { NextRouter } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider as PostHogProviderExt } from 'posthog-js/react';
import React from 'react';
import { useEffect } from 'react';

import { useOptionalOrganization } from '../auth/hooks/useOptionalOrganization';
import { useViewer } from '../auth/hooks/useViewer';

const POSTHOG_API_HOST = process.env.NEXT_PUBLIC_POSTHOG_API_HOST;
const POSTHOG_API_KEY = process.env.NEXT_PUBLIC_POSTHOG_API_KEY;

// Check that PostHog is client-side (used to handle Next.js SSR)
if (
  typeof window !== 'undefined' &&
  POSTHOG_API_KEY !== undefined &&
  POSTHOG_API_HOST !== undefined
) {
  posthog.init(POSTHOG_API_KEY, {
    api_host: POSTHOG_API_HOST,
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  });
}

export const usePostHogAnalytics = (router: NextRouter) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Track initial page view
      posthog?.capture('$pageview');

      // Track subsequent page views
      const handleRouteChange = () => posthog?.capture('$pageview');
      router.events.on('routeChangeComplete', handleRouteChange);

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return posthog;
};

export const PostHogProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const { viewer: user } = useViewer();
  const { organization } = useOptionalOrganization();

  useEffect(() => {
    if (user?.id && user?.id) {
      posthog?.identify(user?.id, {
        email: user?.email,
        name: user?.name,
      });
    }
    if (organization?.id) {
      posthog?.group('organization', organization.id, {
        name: organization.name,
        shortId: organization.shortId,
      });
    }
  }, [
    user?.id,
    organization?.id,
    user?.email,
    user?.name,
    organization?.name,
    organization?.shortId,
  ]);

  return (
    <PostHogProviderExt client={posthog}>{props.children}</PostHogProviderExt>
  );
};

export const resetPostHog = () => {
  posthog?.reset();
};
