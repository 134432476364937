import * as React from 'react';
import { SVGProps } from 'react';

const SvgLoudspeaker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.0928 2.65523C18.9551 2.66747 18.8179 2.69579 18.6846 2.74117L10 5.7021V16.2978L18.6855 19.2587C19.2185 19.4402 19.8101 19.3528 20.2686 19.0253C20.7261 18.6978 21 18.166 21 17.6025V13.1015C21.7323 12.8045 22.25 12.0888 22.25 11.25C22.25 10.4112 21.7323 9.69542 21 9.39839V4.39742C21 3.83392 20.7266 3.30206 20.2686 2.97456C19.9247 2.72856 19.5057 2.6185 19.0928 2.65523ZM8.5 6.05562L4.53906 6.36031C3.11456 6.46981 2 7.67352 2 9.10152V12.8984C2 14.2884 3.05726 15.4664 4.42676 15.6289L4.90039 19.8935C5.00189 20.809 5.77381 21.5 6.69531 21.5C7.69081 21.5 8.5 20.6898 8.5 19.6943V6.05562Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgLoudspeaker;
