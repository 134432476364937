import {
  formatGroupName,
  getTimeZoneName,
  MyValue,
  routes,
  theme,
} from '@frond/shared';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  css,
  styled,
  system,
  SystemProps,
  x,
} from '@xstyled/styled-components';
import { format, isFuture, isPast } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import {
  CommentTypes,
  GroupTypes,
  PostColorTheme,
  PostFeedFragment,
  PostTypes,
  refetchCourseCompletionQuery,
  refetchDraftPostsQuery,
  refetchHomeFeedLatestContentQuery,
  refetchHomeFeedQuery,
  refetchMenuSectionsQuery,
  refetchPostBookmarksQuery,
  refetchPostQuery,
  refetchPostsByEventQuery,
  refetchPostsByGroupQuery,
  refetchViewerQuery,
  refetchVisitedPublicOrganizationsQuery,
  useCreateMenuItemWithPostMutation,
  useCreateOrDeletePostBookmarkMutation,
  useDeletePostMutation,
  useDismissWelcomePostMutation,
  useEventQuery,
  useIsFollowingPostLazyQuery,
  useIsFollowingPostQuery,
  usePublishDraftPostMutation,
  useReorderLessonMutation,
  useRescheduleDraftPostMutation,
  useToggleFollowPostMutation,
  useToggleHideFromProfilePostMutation,
  useToggleLessonCompletedMutation,
  useTogglePinnedPostMutation,
  useToggleShowAuthorOnPostMutation,
  useToggleShowCommentsAndReactionsOnPostMutation,
} from '../../../../generated/types-and-hooks';
import {
  BASE_URL,
  GROUPS_PER_PAGE,
  POST_PREVIEW_CARD_WIDTH_PX,
  POSTS_PER_PAGE,
} from '../../../config/constants';
import { SignInModal } from '../../auth/components/SignIn/SignInModal';
import { useOrganization } from '../../auth/hooks/useOrganization';
import {
  useIsViewerAdmin,
  useViewer,
  useViewerTimeZone,
} from '../../auth/hooks/useViewer';
import { userInOrganization } from '../../auth/utils/permissions';
import { Alert } from '../../common/components/Alert';
import { Avatar } from '../../common/components/Avatar';
import { Breakpoint } from '../../common/components/Breakpoint';
import { Button } from '../../common/components/Button';
import { CommentCell } from '../../common/components/Comments/CommentCell';
import { CommentComposer } from '../../common/components/Comments/CommentComposer';
import { Heading } from '../../common/components/Heading';
import { Icon } from '../../common/components/Icon';
import { Link } from '../../common/components/Link';
import { NextLink } from '../../common/components/NextLink';
import { Skeleton } from '../../common/components/Skeleton';
import { Text, TextProps, TextVariant } from '../../common/components/Text';
import { TimeAgo } from '../../common/components/TimeAgo';
import { UserCommunityRoles } from '../../common/components/UserCommunityRoles';
import { UsersTooltip } from '../../common/components/Users';
import { useClipboardCopy } from '../../common/hooks/useClipboardCopy';
import { CONTENT_SCROLL_ID } from '../../common/hooks/useContentScroll';
import { useCreateComment } from '../../common/hooks/useCreateComments';
import { getUserDisplayName } from '../../common/utils/user';
import { EventAttendButtonButton } from '../../events/components/EventAttendButton';
import { EventLocation } from '../../events/components/EventLocation';
import { EventRecurringText } from '../../events/components/EventRecurringText';
import { CoverImage } from '../../groups/components/CoverImage';
import { GroupDescriptionComposer } from '../../groups/components/GroupDescriptionComposer';
import { GroupsMentionDropdown } from '../../groups/components/GroupsMentionDropdown';
import { GroupsModal } from '../../groups/components/GroupsModal';
import {
  getAnswerNavigationLinkProps,
  PostNavContext,
} from '../../questions/hooks/useAnswerNavigation';
import { userCanEditPost } from '../../questions/utils/answers';
import { useUserCanEditGroup } from '../../questions/utils/questions';
import { EventDetailsButton } from './EventDetailsButton';
import { MovePostModal } from './MovePostModal';
import { PostCardContent } from './PostCardContent';
import { PostCardMedia } from './PostCardMedia';
import { PostCardReactions } from './PostCardReactions';
import { PostCardViewersModal } from './PostCardViewersModal';
import { PostComposer } from './PostComposer';
import { PostPoll } from './PostPoll';
import { PostResource } from './PostResource';

const COMMENTS_ANCHOR_ID = 'comments';

type PostCardProps = {
  post: PostFeedFragment;
  order?: number;
  totalPostsInGroup?: number;
  navContext?: PostNavContext;
  autoPlay?: boolean;
  maxComments?: number;
};

export const PostCardPlaceholder: FC = () => {
  return (
    <x.div
      maxW={POST_PREVIEW_CARD_WIDTH_PX}
      mx="auto"
      borderRadius={{ _: 0, sm: 'md-lg' }}
      overflow="hidden"
      px={{ _: 3, sm: 0 }}
    >
      <Breakpoint
        _={
          <Skeleton
            width="full"
            height={`${POST_PREVIEW_CARD_WIDTH_PX / 2}px`}
          />
        }
        sm={
          <Skeleton width="full" height={`${POST_PREVIEW_CARD_WIDTH_PX}px`} />
        }
      />
    </x.div>
  );
};

const PostCardTimeago = ({
  post,
  navContext,
  variant = 'md-s',
}: PostCardProps & TextProps) => {
  const { organization } = useOrganization();
  const linkProps = navContext
    ? getAnswerNavigationLinkProps(post.id, organization, navContext)
    : null;
  const { t } = useTranslation();

  const scheduledAtFormatter = new Intl.DateTimeFormat(undefined, {
    month: '2-digit',
    day: 'numeric',
  });

  return (
    <NextLink
      href={routes.groups.organization(organization.shortId).post(post.id)}
      styleInheritColor
    >
      <x.div minWidth={0}>
        <Text
          color={{
            _: 'gray.300',
            ...(linkProps && { hover: 'brand.300' }),
          }}
          transition
          transitionDuration="fast"
          transitionTimingFunction="ease-out"
          variant={variant}
          as="span"
          cursor="pointer"
          truncate
        >
          {post.isDraft ? (
            <>
              {post.scheduledAt ? (
                <>
                  {t('scheduled')}{' '}
                  <time
                    dateTime={post.scheduledAt}
                    title={new Date(post.scheduledAt).toLocaleString()}
                  >
                    {scheduledAtFormatter.format(new Date(post.scheduledAt))}
                  </time>
                </>
              ) : (
                t('draft')
              )}
            </>
          ) : (
            <>
              <x.span display={{ _: 'none', sm: 'inline' }}>
                <TimeAgo
                  date={post.createdAt}
                  title={new Date(post.createdAt).toLocaleString()}
                />
              </x.span>
              <x.span display={{ _: 'inline', sm: 'none' }}>
                <TimeAgo
                  date={post.createdAt}
                  title={new Date(post.createdAt).toLocaleString()}
                  abbreviate
                />
              </x.span>
            </>
          )}
        </Text>
      </x.div>
    </NextLink>
  );
};

const PostCardGroup = ({
  post,
  variant = 'regular',
}: Pick<PostCardProps, 'post'> & {
  variant?: 'regular' | 'connected';
}) => {
  const { organization } = useOrganization();

  const url =
    post.group.groupType === GroupTypes.Event
      ? routes.groups.organization(organization.shortId).event(post.group.id)
      : routes.groups.organization(organization.shortId).group(post.group.id);

  return (
    <NextLink href={url} styleInheritColor>
      <x.span
        px={2}
        py={1}
        bg={{ _: 'whiteAlpha.400', hover: 'whiteAlpha.450' }}
        borderRadius={variant === 'connected' ? 'sm-md 0 0 sm-md' : 'sm-md'}
        position="relative"
        border={1}
        borderColor={{
          _: 'transparent',
          hover: 'brand.300',
        }}
        transition
        transitionDuration="fast"
        transitionTimingFunction="ease-out"
        cursor="pointer"
        display="flex"
        alignItems="center"
        spaceX={1}
      >
        {post.group.groupType === GroupTypes.Event ? (
          <Icon color="gray.300" name="calendar" size="4" />
        ) : null}
        <Text as="span" variant="sm-semibold" truncate>
          {formatGroupName(post.group)}
        </Text>
        {post.group.isPrivate ? (
          <Icon color="gray.300" name="lock" size="4" />
        ) : null}
      </x.span>
    </NextLink>
  );
};

const PostCardAuthor = ({ post }: PostCardProps) => {
  const { organization } = useOrganization();
  return (
    <NextLink
      href={routes.groups
        .organization(organization.shortId)
        .person(post.createdBy.username)}
      styleInheritColor
    >
      <x.div
        display="flex"
        position="relative"
        alignItems="center"
        zIndex={4}
        spaceX={2}
        color={{
          hover: 'brand.300',
        }}
        transition
        transitionDuration="fast"
        transitionTimingFunction="ease-out"
        cursor="pointer"
      >
        <x.div>
          <x.div
            display={{
              _: 'block',
              sm: 'none',
            }}
          >
            <Avatar
              imageId={post.createdBy.profileImageId}
              avatar={post.createdBy.avatar}
              bgColor={post.createdBy.avatarBgColor}
              size="s-2"
            />
          </x.div>
          <x.div
            display={{
              _: 'none',
              sm: 'block',
            }}
          >
            <Avatar
              imageId={post.createdBy.profileImageId}
              avatar={post.createdBy.avatar}
              bgColor={post.createdBy.avatarBgColor}
              size="s"
            />
          </x.div>
        </x.div>
        <Text variant="md-s-semibold">
          {getUserDisplayName(post.createdBy, 'short')}
        </Text>
      </x.div>
    </NextLink>
  );
};

const PostCardPin = (props: PropsWithChildren & SystemProps & TextProps) => {
  const { children } = props;
  return (
    <x.div
      bg="whiteAlpha.400"
      borderRadius="sm-md"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      {children}
    </x.div>
  );
};

const PostCardDismiss: React.FC<{ color: string }> = ({ color }) => {
  const { organization } = useOrganization();
  const { t } = useTranslation();
  const [dismissWelcomePost] = useDismissWelcomePostMutation({
    variables: {
      input: {
        organizationId: organization.id,
      },
    },
    refetchQueries: [
      refetchHomeFeedQuery({
        organizationId: organization.id,
        first: POSTS_PER_PAGE,
      }),
      refetchHomeFeedLatestContentQuery({
        organizationId: organization.id,
      }),
    ],
  });

  const buttonColor = color.toLowerCase();

  return (
    <x.button
      bg={buttonColor === 'gray' ? `${buttonColor}.200` : `${buttonColor}.100`}
      color={`${buttonColor}.400`}
      borderRadius="sm"
      py={1}
      px={2}
      border="default"
      borderColor={{ _: 'transparent', hover: `${buttonColor}.400` }}
      transition
      transitionDuration="fast"
      transitionTimingFunction="ease-out"
      cursor="pointer"
      onClick={() => dismissWelcomePost()}
    >
      <Text variant="sm-semibold">{t('dismiss')}</Text>
    </x.button>
  );
};

const PostCardLessonHeader = ({
  post,
  order,
  totalPostsInGroup,
}: Pick<PostCardProps, 'post' | 'totalPostsInGroup' | 'order'>) => {
  const { t } = useTranslation();
  const { organization } = useOrganization();
  const isViewerAdmin = useIsViewerAdmin(organization);
  const [reorderLesson] = useReorderLessonMutation({
    refetchQueries: [
      refetchPostsByGroupQuery({
        groupId: post.group.id,
        first: POSTS_PER_PAGE,
      }),
    ],
  });
  if (!order) return null;

  const buttonArrows =
    totalPostsInGroup && totalPostsInGroup > 1
      ? [
          {
            disabled: order === 1,
            iconName: 'arrow-up',
            onClick: () => {
              reorderLesson({
                variables: {
                  input: {
                    lessonId: post.id,
                    order: order - 1,
                  },
                },
              });
            },
          },
          {
            disabled: order === totalPostsInGroup,
            iconName: 'arrow-down',
            onClick: () => {
              reorderLesson({
                variables: {
                  input: {
                    lessonId: post.id,
                    order: order + 1,
                  },
                },
              });
            },
          },
        ]
      : [];

  return (
    <x.div display="flex" alignItems="center" spaceX={5}>
      <x.div display="flex" alignItems="center">
        <PostCardGroup post={post} variant="connected" />
        <Text
          px={2}
          py={1}
          bg="brand.200"
          borderRadius="0 sm-md sm-md 0"
          position="relative"
          border={1}
          borderColor="brand.200"
          variant="sm-semibold"
          color="white"
        >
          {t('lesson')} {order} / {totalPostsInGroup}
        </Text>
      </x.div>
      {isViewerAdmin ? (
        <x.div display="flex" spaceX={2}>
          {buttonArrows.map((button) => {
            return (
              <x.button
                disabled={button.disabled}
                onClick={button.onClick}
                key={button.iconName}
                bg={{
                  _: 'whiteAlpha.400',
                  hover: 'whiteAlpha.450',
                  disabled: 'whiteAlpha.300',
                }}
                borderRadius="sm-md"
                w={7}
                h={7}
                display="flex"
                alignItems="center"
                justifyContent="center"
                border={1}
                borderColor={{
                  _: 'transparent',
                  hover: 'brand.300',
                  disabled: 'transparent',
                }}
                transition
                transitionDuration="fast"
                transitionTimingFunction="ease-out"
                cursor={{ _: 'pointer', disabled: 'default' }}
              >
                <Icon name={button.iconName} size="4" />
              </x.button>
            );
          })}
        </x.div>
      ) : null}
    </x.div>
  );
};

export const PostCardHeader = ({
  post,
  navContext,
  onEditPost,
  order,
  totalPostsInGroup,
}: PostCardProps & {
  onEditPost: () => void;
}) => {
  const { viewer: user } = useViewer();
  const { organization } = useOrganization();
  const { t } = useTranslation();
  return (
    <x.div display="flex" flexDirection="column" spaceY={1}>
      <x.div display="flex" justifyContent="space-between">
        <x.div display="flex" alignItems="center" spaceX={3}>
          {post.type === PostTypes.Lesson ? (
            <PostCardLessonHeader
              post={post}
              order={order}
              totalPostsInGroup={totalPostsInGroup}
            />
          ) : null}
          {post.pinned && !post.showAuthor ? (
            <PostCardPin
              px={2}
              py={1}
              border={1}
              borderColor="transparent"
              spaceX="2px"
              cursor="default"
            >
              <Icon name="pin" size="4" />{' '}
              <Text as="span" variant="sm-semibold">
                Pinned
              </Text>
            </PostCardPin>
          ) : post.showAuthor ? (
            <>
              <PostCardAuthor post={post} />
              <x.div display={{ _: 'none', sm: 'block' }}>
                <UserCommunityRoles user={post.createdBy} limit={1} />
              </x.div>
              <x.div display={{ _: 'block', sm: 'none' }}>
                <UserCommunityRoles
                  variant="small"
                  user={post.createdBy}
                  limit={1}
                />
              </x.div>
            </>
          ) : null}

          {post.type === PostTypes.Welcome && !post.group.hidden ? (
            <PostCardGroup post={post} />
          ) : null}

          {!post.pinned &&
          ![PostTypes.Welcome, PostTypes.Lesson].includes(post.type) ? (
            <x.div display={{ _: 'none', 'sm-2': 'block' }}>
              <PostCardTimeago post={post} navContext={navContext} />
            </x.div>
          ) : null}
        </x.div>
        <x.div
          spaceX={2}
          display="flex"
          alignItems="center"
          flex={1}
          minWidth={0}
          justifyContent="flex-end"
        >
          {post.pinned && post.showAuthor ? (
            <PostCardPin h={7} w={7}>
              <Icon name="pin" size="4" />
            </PostCardPin>
          ) : null}
          {![PostTypes.Welcome, PostTypes.Lesson].includes(post.type) &&
          !post.group.hidden ? (
            <x.div display={{ _: 'none', sm: 'block' }} maxWidth="75%">
              <PostCardGroup post={post} />
            </x.div>
          ) : null}

          {post.type === PostTypes.Welcome &&
          navContext !== PostNavContext.SETTINGS &&
          navContext !== PostNavContext.GROUP &&
          userInOrganization(user, organization) ? (
            <PostCardDismiss color={post.colorTheme} />
          ) : null}

          {navContext !== PostNavContext.SETTINGS ? (
            <x.div flexShrink={0}>
              <PostCardActionMenu
                onEditPost={onEditPost}
                navContext={navContext}
                post={post}
              />
            </x.div>
          ) : null}
        </x.div>
      </x.div>
      {post.showAuthor && (
        <x.div
          display={{ _: 'flex', sm: 'none' }}
          alignItems="center"
          ml={9}
          spaceX={2}
        >
          <PostCardTimeago post={post} navContext={navContext} variant="sm" />
          <Text variant="xs" color="gray.300">
            •
          </Text>
          <x.div minWidth={0}>
            <NextLink
              href={routes.groups
                .organization(organization.shortId)
                .group(post.group.id)}
              styleInheritColor
            >
              <Text variant="sm" color="gray.300" truncate>
                <Trans
                  i18nKey="post_in_group"
                  t={t}
                  values={{
                    group: formatGroupName(post.group),
                  }}
                  components={{
                    strong: <Text as="span" variant="sm-semibold" />,
                  }}
                />
              </Text>
            </NextLink>
          </x.div>
        </x.div>
      )}
    </x.div>
  );
};

const PostCardWelcome = ({ onEditPost }: { onEditPost: () => void }) => {
  const { organization } = useOrganization();
  const { t } = useTranslation();

  return (
    <x.div
      bg="yellow.50"
      border="default"
      borderColor="yellow.200"
      mx={6}
      mb={6}
      p={4}
      borderRadius="md"
      spaceY={3}
    >
      <x.div spaceY={1}>
        <Text variant="md-s-semibold">{t('welcome_your_members')}</Text>
        <Text>{t('welcome_your_members_explanation')}</Text>
      </x.div>

      <x.div display="flex" spaceX={2}>
        <Button
          label={t('edit_post')}
          variant="secondary"
          onClick={onEditPost}
        />
        <Button
          label={t('open_settings')}
          href={routes.settings.organization(organization.shortId).onboarding()}
          variant="secondary"
        />
      </x.div>
    </x.div>
  );
};

const PostCardEvent = (props: PostCardProps) => {
  const viewerTimezone = useViewerTimeZone();

  const { post, navContext } = props;

  const { data } = useEventQuery({
    variables: {
      id: post.group.id,
    },
  });

  return (
    <x.div>
      <x.div
        borderRadius="md md 0 0"
        backgroundColor="gray.200"
        position="relative"
        {...(!props.post.group.coverImageId && {
          padding: 6,
        })}
      >
        {props.post.group.coverImageId && (
          <CoverImage coverImageId={props.post.group.coverImageId} />
        )}
        <x.div
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          w="100px"
          h="100px"
          py={4}
          boxSizing="border-box"
          backgroundColor="white"
          borderRadius="md-lg"
          boxShadow="sm"
          {...(props.post.group.coverImageId && {
            position: 'absolute',
            top: theme.sizes[6],
            left: theme.sizes[6],
          })}
        >
          {data?.event && data?.event.__typename === 'Event' && (
            <x.div display="flex" alignItems="center" flexDirection="column">
              <Heading variant="3xl">
                {format(new Date(data.event.startAt), 'dd')}
              </Heading>
              <Heading color="gray.400" variant="xl">
                {format(new Date(data.event.startAt), 'LLL').toUpperCase()}
              </Heading>
            </x.div>
          )}
        </x.div>
      </x.div>
      <x.div display="flex" px={6} py={5} flexDirection="column" spaceY={2}>
        <x.div display="flex" alignItems="center" spaceX={3}>
          <PostCardAuthor post={post} />
          <UserCommunityRoles user={post.createdBy} limit={1} />
          <PostCardTimeago post={post} navContext={navContext} />
        </x.div>
        <Heading variant="xl">{post.group.name}</Heading>
        {data?.event?.__typename === 'Event' && (
          <x.div
            display={{ sm: 'flex' }}
            alignItems="center"
            spaceX={{ sm: 2 }}
            spaceY={{ _: 2, sm: 0 }}
            whiteSpace="nowrap"
          >
            <Text
              variant="sm"
              color="gray.300"
              {...(data?.event?.deleted && {
                textDecoration: 'line-through',
              })}
            >
              {format(new Date(data?.event.startAt), 'p')}
              {' - '}
              {format(new Date(data?.event.endAt), 'p')}{' '}
              {getTimeZoneName(viewerTimezone)}
            </Text>
            {data?.event?.series && (
              <>
                <Text
                  display={{ _: 'none', sm: 'block' }}
                  variant="sm"
                  color="gray.300"
                >
                  •
                </Text>
                <Text variant="sm" color="gray.300">
                  <EventRecurringText event={data.event} />
                </Text>
              </>
            )}
            {data?.event?.location && (
              <>
                <Text
                  display={{ _: 'none', sm: 'block' }}
                  variant="sm"
                  color="gray.300"
                >
                  •
                </Text>
                <EventLocation event={data.event} variant="postCard" />
              </>
            )}
            {data?.event?.url && (
              <>
                <Text
                  display={{ _: 'none', sm: 'block' }}
                  variant="sm"
                  color="gray.300"
                >
                  •
                </Text>
                <Link
                  href={data.event.url}
                  display="block"
                  target="_blank"
                  minWidth={0}
                  maxWidth={data.event.location ? 300 : undefined}
                >
                  <Text
                    variant="sm"
                    color="gray.300"
                    {...(data.event.deleted && {
                      textDecoration: 'line-through',
                    })}
                    truncate
                  >
                    {data.event.url}
                  </Text>
                </Link>
              </>
            )}
          </x.div>
        )}
        {post.group.description && (
          <GroupDescriptionComposer
            content={post.group.description as MyValue}
            initialTruncate={navContext !== PostNavContext.GROUP}
          />
        )}
      </x.div>
      <x.div px={6} pb={6}>
        {navContext === PostNavContext.FEED ? (
          <x.div display="flex" spaceX={2}>
            {data?.event && data?.event.__typename === 'Event' && (
              <>
                <EventAttendButtonButton event={data?.event} />
                <EventDetailsButton event={data?.event} />
              </>
            )}
          </x.div>
        ) : (
          <PostMeta {...props} showCommentsCount={false} />
        )}
      </x.div>
    </x.div>
  );
};

const PostCardDefault = ({
  post,
  navContext,
  maxComments,
  order,
  totalPostsInGroup,
}: PostCardProps) => {
  const { organization } = useOrganization();
  const isViewerAdmin = useIsViewerAdmin(organization);
  const [editing, setEditing] = useState(false);
  const linkProps = navContext
    ? getAnswerNavigationLinkProps(post.id, organization, navContext)
    : null;

  const [showCommentsOverride, setShowCommentsOverride] = useState(false);
  return (
    <>
      <x.div position="relative">
        <x.div
          px={{ _: 4, sm: 6 }}
          py={{ _: 4, sm: 5 }}
          spaceY={{ _: 3, sm: 5 }}
        >
          <PostCardHeader
            post={post}
            navContext={navContext}
            onEditPost={() => setEditing(true)}
            order={order}
            totalPostsInGroup={totalPostsInGroup}
          />

          {post.title ? <Heading variant="xl">{post.title}</Heading> : null}
          <PostCardMedia post={post} />
          <PostCardContent post={post} />
          {post.resources.map((resource, index) => {
            return (
              <PostResource resource={resource} key={resource.id + index} />
            );
          })}
          <PostPoll post={post} />
          {!post.isDraft ? (
            <>
              {post.showCommentsAndReactions ? <PostMeta post={post} /> : null}
              {post.type === PostTypes.Lesson ? (
                <PostCardLessonFooter
                  post={post}
                  onCommentsClick={() => setShowCommentsOverride((val) => !val)}
                />
              ) : null}
            </>
          ) : null}
        </x.div>
      </x.div>
      {(post.showCommentsAndReactions || showCommentsOverride) &&
      !post.isDraft ? (
        <x.div id={linkProps ? undefined : COMMENTS_ANCHOR_ID}>
          <PostComments post={post} maxComments={maxComments} />
        </x.div>
      ) : null}
      {isViewerAdmin &&
      post.type === PostTypes.Welcome &&
      navContext !== PostNavContext.SETTINGS ? (
        <PostCardWelcome onEditPost={() => setEditing(true)} />
      ) : null}
      {editing && (
        <PostComposer
          group={post.group}
          post={post}
          isOpen={editing}
          onDismiss={() => setEditing(false)}
          type={post.type}
          canChangeGroup={post.type !== PostTypes.Lesson}
        />
      )}
    </>
  );
};

export const PostCard = (props: PostCardProps) => {
  const { post } = props;
  const bgColorLevel = post.colorTheme === PostColorTheme.Gray ? '100' : '75';

  return (
    <x.div
      bg={`${post.colorTheme.toLowerCase()}.${bgColorLevel}`}
      borderRadius={{ _: 0, sm: 'md-lg' }}
      overflow="hidden"
    >
      {post.type === PostTypes.Event ? (
        <PostCardEvent {...props} />
      ) : (
        <PostCardDefault {...props} />
      )}
    </x.div>
  );
};

export const PostMeta: React.FC<{
  post: PostFeedFragment;
  showCommentsCount?: boolean;
}> = ({ post, showCommentsCount = true }) => {
  const { organization } = useOrganization();
  const { t } = useTranslation();
  const [viewersModalOpen, setViewersModalOpen] = useState(false);

  const filteredUsers = post.views
    .filter((view) => view.user.id !== post?.createdBy.id)
    .map((view) => view.user);
  const viewCount =
    filteredUsers.length +
    (organization.isPublic ? post.numOfAnonymousViews : 0);
  return (
    <x.div display="flex" justifyContent="space-between" spaceX={20}>
      <PostCardReactions post={post} />
      <x.div display="flex" spaceX={2} position="relative">
        <UsersTooltip
          users={filteredUsers}
          fallback={<Text variant="sm-semibold">{t('not_seen')}</Text>}
          label={t('seen_by_count', {
            count: viewCount,
          })}
          anonymousUsers={organization.isPublic ? post.numOfAnonymousViews : 0}
          showAvatars={!organization.isPublic}
        >
          <x.div>
            <PostCardCount
              iconName="eye"
              count={viewCount}
              onClick={() => setViewersModalOpen(true)}
            />
          </x.div>
        </UsersTooltip>
        {showCommentsCount && (
          <PostCardCount iconName="comment" count={post.comments.length} />
        )}
        <PostCardViewersModal
          ariaLabel="Post viewers modal"
          isOpen={viewersModalOpen}
          onDismiss={() => setViewersModalOpen(false)}
          viewers={filteredUsers}
          anonymousViewersCount={post.numOfAnonymousViews}
        />
      </x.div>
    </x.div>
  );
};

const PostCardLessonFooter = ({
  post,
  onCommentsClick,
}: Pick<PostCardProps, 'post'> & {
  onCommentsClick: () => void;
}) => {
  const [showSignInModal, setShowSignInModal] = useState(false);
  const { t } = useTranslation();
  const [toggleLessonCompleted] = useToggleLessonCompletedMutation({
    variables: {
      input: {
        lessonId: post.id,
      },
    },
    refetchQueries: [
      refetchCourseCompletionQuery({
        courseId: post.group.id,
      }),
    ],
  });
  const { viewer } = useViewer();
  const { organization } = useOrganization();
  const isViewerAdmin = useIsViewerAdmin(organization);
  const userHasCompletedTheLesson = post.completions.some((c) => {
    return c.user.id === viewer?.id;
  });

  const completedUsers = post.completions.map((c) => c.user);

  if (post.group.isDraft) return null;

  return (
    <x.div display="flex" alignItems="center" justifyContent="space-between">
      <div>
        {!isViewerAdmin ? (
          <>
            <Button
              variant={userHasCompletedTheLesson ? 'green' : 'secondary'}
              label={
                userHasCompletedTheLesson
                  ? t('completed')
                  : t('mark_as_completed')
              }
              onClick={() => {
                if (viewer) {
                  toggleLessonCompleted();
                } else {
                  setShowSignInModal(true);
                }
              }}
              leftElement={<Icon name="check-mark" size="4" />}
            />
            <SignInModal
              isOpen={showSignInModal}
              onDismiss={() => setShowSignInModal(false)}
              variant="public"
            />
          </>
        ) : null}
      </div>
      <x.div display="flex" spaceX={2} alignItems="center">
        {isViewerAdmin ? (
          <UsersTooltip
            users={completedUsers}
            fallback={<Text variant="sm-semibold">{t('no_completions')}</Text>}
            label={t('completed_by_count', {
              count: completedUsers.length,
            })}
            showAvatars={!organization.isPublic}
          >
            <x.div>
              <PostCardCount
                iconName="check-mark"
                count={completedUsers.length}
              />
            </x.div>
          </UsersTooltip>
        ) : null}
        <PostCardCount
          onClick={onCommentsClick}
          iconName="comment"
          count={post.comments.length}
        />
      </x.div>
    </x.div>
  );
};

export const PostCardCount: FC<
  {
    iconName?: string;
    count: number | string;
    textVariant?: TextVariant;
    onClick?: () => void;
  } & SystemProps
> = (props) => {
  const { iconName, count, textVariant = 'sm' } = props;
  return (
    <x.button
      cursor="default"
      minWidth={{
        _: '7',
        sm: '8',
      }}
      h={{
        _: '7',
        sm: '8',
      }}
      borderRadius="sm-md"
      backgroundColor="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      spaceX={1}
      boxSizing="border-box"
      px={`calc(${theme.sizes[2]} - 1px)`}
      transitionProperty="border-color, color"
      transitionDuration="fast"
      borderWidth="1px"
      borderStyle="solid"
      borderColor={{ _: 'transparent', hover: 'brand.300' }}
      color={{ _: 'gray.500', hover: 'brand.300' }}
      {...props}
    >
      {iconName && <Icon size="4.5" name={iconName} />}
      {count ? (
        <Text as="span" variant={textVariant}>
          {count}
        </Text>
      ) : null}
    </x.button>
  );
};

export const PostComments: React.FC<{
  post: PostFeedFragment;
  maxComments?: number;
}> = ({ post, maxComments }) => {
  const { t } = useTranslation();
  const { viewer: user } = useViewer();
  const { organization } = useOrganization();
  const [expanded, setExpanded] = useState(false);
  const router = useRouter();
  const { mutateCreateComment, isSubmitting } = useCreateComment({
    parent: post,
  });

  const handleSubmitComment = ({
    content,
    mediaIds,
    urls,
  }: {
    content: MyValue;
    mediaIds: string[];
    urls: string[];
  }) => {
    mutateCreateComment({
      variables: {
        input: {
          parentId: post.id,
          content,
          type: CommentTypes.Post,
          mediaIds,
          resources: urls.map((url) => {
            return {
              url,
              expanded: true,
            };
          }),
        },
      },
      refetchQueries: [
        refetchPostQuery({ id: post.id }),
        ...(organization.isPublic &&
        user &&
        !userInOrganization(user, organization)
          ? [
              refetchVisitedPublicOrganizationsQuery({
                userId: user.id,
              }),
              refetchViewerQuery(),
            ]
          : []),
      ],
    });
  };

  const commentAsBestAnswer = post.comments.find(
    (comment) => comment.isBestAnswer
  );

  const commentsWithoutBestAnswer = post.comments.filter(
    (comment) => !comment.isBestAnswer
  );

  const displayComments =
    typeof maxComments === 'number' && !expanded
      ? commentAsBestAnswer
        ? []
        : commentsWithoutBestAnswer.slice(0, maxComments)
      : commentsWithoutBestAnswer.slice();

  const commentOverflowCount = Math.max(
    0,
    commentsWithoutBestAnswer.length - displayComments.length
  );

  const commentId = Array.isArray(router.query.commentId)
    ? router.query.commentId[0]
    : router.query.commentId;

  useEffect(() => {
    if (commentId) {
      // Expand comments if commentId is in overflowing comments
      if (commentOverflowCount) {
        const overflowingComments =
          commentsWithoutBestAnswer.slice(maxComments);
        const isInOverflowingComments = !!overflowingComments.find(
          (comment) => comment.id === commentId
        );
        if (isInOverflowingComments && !expanded) {
          setExpanded(true);
        }
      }
    }
  }, [
    maxComments,
    commentsWithoutBestAnswer,
    router,
    commentId,
    commentOverflowCount,
    expanded,
  ]);

  return (
    <x.div pb={5} pt={3} backgroundColor="gray.50" spaceY={3}>
      {commentAsBestAnswer && (
        <CommentCell
          comment={commentAsBestAnswer}
          displayName={getUserDisplayName(
            commentAsBestAnswer.createdBy,
            'short'
          )}
          editable={true}
          post={post}
        />
      )}

      {commentOverflowCount && !expanded ? (
        <x.div px={6} pt={2}>
          <Text
            variant="sm-semibold"
            color={{ _: 'brand.300', hover: 'brand.200' }}
            as="span"
            cursor="pointer"
            onClick={() => setExpanded(true)}
          >
            {commentOverflowCount > 1
              ? t('view_n_more_comments', { count: commentOverflowCount })
              : t('view_one_more_comment')}
          </Text>
        </x.div>
      ) : null}

      {displayComments.reverse().map((comment) => (
        <x.div id={`comment-${comment.id}`} key={comment.id}>
          <CommentCell
            comment={comment}
            displayName={getUserDisplayName(comment.createdBy, 'short')}
            editable={true}
            post={post}
          />
        </x.div>
      ))}

      <x.div px={6} pt={{ _: displayComments.length ? 0 : 2, sm: 2 }}>
        <CommentComposer
          placeholder={t('leave_a_comment')}
          onSubmit={handleSubmitComment}
          isSubmitting={isSubmitting}
        >
          <GroupsMentionDropdown
            {...(post.group.isPrivate && {
              groupId: post.group.id,
            })}
          />
        </CommentComposer>
      </x.div>
    </x.div>
  );
};

const DropdownMenuCss = css`
  background-color: white;
  border-radius: md;

  @media (prefers-reduced-motion: no-preference) {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    &[data-state='open'] {
      &[data-side='top'] {
        animation-name: slideDownAndFade;
      }

      &[data-side='right'] {
        animation-name: slideLeftAndFade;
      }

      &[data-side='bottom'] {
        animation-name: slideUpAndFade;
      }

      &[data-side='left'] {
        animation-name: slideRightAndFade;
      }
    }
  }
`;

export const StyledDropdownMenuContent: typeof DropdownMenu.Content = styled(
  DropdownMenu.Content
)<SystemProps & DropdownMenu.DropdownMenuProps>`
  ${DropdownMenuCss};
  ${system};
`;

export const StyledDropdownSubMenuContent: typeof DropdownMenu.SubContent = styled(
  DropdownMenu.SubContent
)`
  ${DropdownMenuCss};
`;

export const StyledDropdownMenuButton = styled.buttonBox`
  &[data-state='open'] {
    background-color: whiteAlpha 0.45;
    border-color: brand 0.3;
  }

  &:disabled {
    border-color: gray 0.1;
    color: gray 0.2;
  }
`;

export const StyledDropdownMenuItem: FC<
  PropsWithChildren & SystemProps & DropdownMenu.DropdownMenuItemProps
> = styled(DropdownMenu.Item)<
  PropsWithChildren & SystemProps & DropdownMenu.DropdownMenuItemProps
>`
  padding: 4px;
  cursor: pointer;
  transition: ${theme.transitions.duration.faster} ease-in-out;

  &[data-highlighted] {
    color: brand 0.3;
  }

  &:hover {
    color: brand 0.3;
  }

  ${system};
`;

const StyledDateInput = styled.input<SystemProps>`
  ${theme.typographyStyles.text.md};
  ${system};

  ::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

function convertToUserTimezone(gmtDateString: string) {
  // Parse the GMT date string
  const gmtDate = new Date(gmtDateString);

  // Get the user's local time zone
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the date to the user's local time zone
  return utcToZonedTime(gmtDate, userTimezone);
}

type PostCardActionMenuProps = {
  post: PostFeedFragment;
  onEditPost: () => void;
  navContext?: PostNavContext;
};
const PostCardActionMenu = ({
  post,
  navContext,
  onEditPost,
}: PostCardActionMenuProps) => {
  const { viewer: user } = useViewer();
  const { organization } = useOrganization();

  const { t } = useTranslation();
  const [mutateDeletePost] = useDeletePostMutation();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [togglePinnedPost] = useTogglePinnedPostMutation({
    refetchQueries:
      post.group.groupType === GroupTypes.Event
        ? [
            refetchPostsByEventQuery({
              eventId: post.group.id,
              first: POSTS_PER_PAGE,
            }),
          ]
        : [
            refetchPostsByGroupQuery({
              groupId: post.group.id,
              first: POSTS_PER_PAGE,
            }),
          ],
    onCompleted: (data) => {
      if (data.togglePinnedPost.pinned && navContext === PostNavContext.GROUP) {
        const scrollableDiv = document.getElementById(CONTENT_SCROLL_ID);

        if (scrollableDiv) {
          scrollableDiv.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    },
  });

  const [toggleHideFromProfilePost] = useToggleHideFromProfilePostMutation({
    refetchQueries: user
      ? [
          refetchHomeFeedQuery({
            organizationId: organization.id,
            first: POSTS_PER_PAGE - 1,
            username: user.username,
          }),
        ]
      : [],
  });
  const [toggleShowAuthorOnPost] = useToggleShowAuthorOnPostMutation();
  const [toggleShowCommentsAndReactionsOnPost] =
    useToggleShowCommentsAndReactionsOnPostMutation();
  const isViewerAdmin = useIsViewerAdmin(organization);
  const canEditGroup = useUserCanEditGroup(post.group, organization, user);
  const canEditPost = userCanEditPost(post, user);
  const { handleCopy } = useClipboardCopy(2000);
  const [fetchIsFollowingPost] = useIsFollowingPostLazyQuery({
    fetchPolicy: 'cache-first',
  });
  const [rescheduleDraftPost, { loading: rescheduling }] =
    useRescheduleDraftPostMutation();
  const [scheduledAt, setScheduledAt] = useState<string | undefined>(() =>
    post.scheduledAt
      ? format(convertToUserTimezone(post.scheduledAt), "yyyy-MM-dd'T'HH:mm:ss")
      : undefined
  );
  const [publishDraftPost] = usePublishDraftPostMutation({
    variables: {
      input: {
        postId: post.id,
      },
    },
    refetchQueries: [
      refetchDraftPostsQuery({
        organizationId: organization.id,
        first: GROUPS_PER_PAGE - 1,
        isScheduled: false,
      }),
    ],
  });
  const [showMovePostModal, setShowMovePostModal] = useState(false);
  const [showHidePostOnProfileModal, setShowHidePostOnProfileModal] =
    useState(false);
  const [showReschedulePostModal, setShowReschedulePostModal] = useState(false);

  const [mutateCreateOrDeletePostBookmark] =
    useCreateOrDeletePostBookmarkMutation({
      variables: {
        input: {
          postId: post.id,
        },
      },
      refetchQueries: [
        refetchPostBookmarksQuery({
          organizationId: organization.id,
          first: POSTS_PER_PAGE,
        }),
      ],
    });

  const [mutateCreateMenuItemWithPost] = useCreateMenuItemWithPostMutation({
    refetchQueries: [
      refetchMenuSectionsQuery({
        organizationId: organization.id,
      }),
    ],
    variables: {
      input: {
        postId: post.id,
        organizationId: organization.id,
      },
    },
  });

  if (!user) {
    return null;
  }

  const scheduledAtAsDate = new Date(scheduledAt || '');

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <StyledDropdownMenuButton
            cursor="pointer"
            m={0}
            p={3}
            bg={{
              _: 'transparent',
              sm: 'whiteAlpha.400',
              hover: 'whiteAlpha.450',
            }}
            borderRadius="sm-md"
            position="relative"
            border={1}
            borderColor={{
              _: 'transparent',
              hover: 'brand.300',
            }}
            spaceX={1}
            display="flex"
            transition
            transitionDuration="fast"
            transitionTimingFunction="ease-out"
            onMouseEnter={() =>
              fetchIsFollowingPost({
                variables: {
                  userId: user.id,
                  postId: post.id,
                },
              })
            }
          >
            {[...new Array(3)].map((_, i) => {
              return (
                <x.div bg="gray.500" borderRadius={4} key={i} w={1} h={1} />
              );
            })}
          </StyledDropdownMenuButton>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <StyledDropdownMenuContent align="end" sideOffset={8}>
            {post.isDraft ? (
              <x.div p={3} boxShadow="sm" borderRadius="md" spaceY={1}>
                {canEditPost && !post.scheduledAt ? (
                  <StyledDropdownMenuItem onSelect={() => publishDraftPost()}>
                    <Text variant="md">{t('publish_post')}</Text>
                  </StyledDropdownMenuItem>
                ) : null}
                {canEditPost && !!post.scheduledAt ? (
                  <StyledDropdownMenuItem
                    onSelect={() => setShowReschedulePostModal(true)}
                  >
                    <Text variant="md">{t('reschedule_post')}</Text>
                  </StyledDropdownMenuItem>
                ) : null}
                {canEditPost ||
                (isViewerAdmin && post.type === PostTypes.Lesson) ? (
                  <StyledDropdownMenuItem onSelect={() => onEditPost()}>
                    <Text variant="md">
                      {t(
                        post.type === PostTypes.Lesson
                          ? 'edit_lesson'
                          : 'edit_post'
                      )}
                    </Text>
                  </StyledDropdownMenuItem>
                ) : null}
                {canEditPost ? (
                  <StyledDropdownMenuItem
                    onSelect={() => setShowDeleteAlert(true)}
                  >
                    <Text variant="md">
                      {post.type === PostTypes.Lesson
                        ? t('delete_lesson')
                        : t('delete_post')}
                    </Text>
                  </StyledDropdownMenuItem>
                ) : null}
              </x.div>
            ) : post.type === PostTypes.Welcome ? (
              <x.div p={3} boxShadow="sm" borderRadius="md" spaceY={1}>
                <StyledDropdownMenuItem>
                  <CopyToClipboard
                    text={`${BASE_URL}${routes.groups
                      .organization(organization.shortId)
                      .post(post.id)}`}
                    onCopy={handleCopy}
                  >
                    <Text variant="md">{t('copy_link')}</Text>
                  </CopyToClipboard>
                </StyledDropdownMenuItem>
                {isViewerAdmin ? (
                  <StyledDropdownMenuItem onSelect={() => onEditPost()}>
                    <Text variant="md">{t('edit_welcome_post')}</Text>
                  </StyledDropdownMenuItem>
                ) : null}
              </x.div>
            ) : (
              <x.div p={3} boxShadow="sm" borderRadius="md" spaceY={1}>
                <StyledDropdownMenuItem>
                  <CopyToClipboard
                    text={`${BASE_URL}${routes.groups
                      .organization(organization.shortId)
                      .post(post.id)}`}
                    onCopy={handleCopy}
                  >
                    <Text variant="md">{t('copy_link')}</Text>
                  </CopyToClipboard>
                </StyledDropdownMenuItem>
                {post.group.groupType === GroupTypes.Course &&
                post.group.isDraft ? null : (
                  <FollowPostDropdownMenuItem post={post} />
                )}
                <StyledDropdownMenuItem
                  onSelect={() => mutateCreateOrDeletePostBookmark()}
                >
                  <Text variant="md">
                    {post.isBookmarked
                      ? t('remove_bookmark')
                      : t('bookmark_post')}
                  </Text>
                </StyledDropdownMenuItem>
                {canEditPost ||
                (isViewerAdmin && post.type === PostTypes.Lesson) ? (
                  <StyledDropdownMenuItem onSelect={() => onEditPost()}>
                    <Text variant="md">
                      {t(
                        post.type === PostTypes.Lesson
                          ? 'edit_lesson'
                          : 'edit_post'
                      )}
                    </Text>
                  </StyledDropdownMenuItem>
                ) : null}
                {isViewerAdmin && post.type === PostTypes.Regular ? (
                  <StyledDropdownMenuItem
                    onSelect={() => setShowMovePostModal(true)}
                  >
                    <Text variant="md">{t('move_post')}</Text>
                  </StyledDropdownMenuItem>
                ) : null}
                {canEditGroup &&
                post.type !== PostTypes.Lesson &&
                !post.group.hidden ? (
                  <>
                    <StyledDropdownMenuItem
                      onSelect={() =>
                        togglePinnedPost({
                          variables: {
                            input: {
                              postId: post.id,
                            },
                          },
                        })
                      }
                    >
                      <Text variant="md">
                        {post.pinned
                          ? t('unpin_post')
                          : navContext === PostNavContext.FEED ||
                              navContext === PostNavContext.USER
                            ? t('pin_post_to_group', { group: post.group.name })
                            : t('pin_post')}
                      </Text>
                    </StyledDropdownMenuItem>
                    {post.pinned ? (
                      <>
                        <StyledDropdownMenuItem
                          onSelect={() =>
                            toggleShowCommentsAndReactionsOnPost({
                              variables: {
                                input: {
                                  postId: post.id,
                                },
                              },
                            })
                          }
                        >
                          <Text variant="md">
                            {post.showCommentsAndReactions
                              ? t('disable_comments')
                              : t('enable_comments')}
                          </Text>
                        </StyledDropdownMenuItem>
                        <StyledDropdownMenuItem
                          onSelect={() => {
                            toggleShowAuthorOnPost({
                              variables: {
                                input: {
                                  postId: post.id,
                                },
                              },
                              onCompleted(data) {
                                if (!data.toggleShowAuthorOnPost.showAuthor) {
                                  setShowHidePostOnProfileModal(true);
                                }
                              },
                            });
                          }}
                        >
                          <Text variant="md">
                            {post.showAuthor
                              ? t('hide_author')
                              : t('show_author')}
                          </Text>
                        </StyledDropdownMenuItem>

                        {!post.showAuthor && user.id === post.createdBy.id ? (
                          <StyledDropdownMenuItem
                            onSelect={() =>
                              toggleHideFromProfilePost({
                                variables: {
                                  input: {
                                    postId: post.id,
                                  },
                                },
                              })
                            }
                          >
                            <Text variant="md">
                              {post.hideFromProfile
                                ? t('show_post_on_profile')
                                : t('hide_post_on_profile')}
                            </Text>
                          </StyledDropdownMenuItem>
                        ) : null}
                      </>
                    ) : null}
                  </>
                ) : null}
                {isViewerAdmin || canEditPost ? (
                  <StyledDropdownMenuItem
                    onSelect={() => setShowDeleteAlert(true)}
                  >
                    <Text variant="md">
                      {post.type === PostTypes.Lesson
                        ? t('delete_lesson')
                        : t('delete_post')}
                    </Text>
                  </StyledDropdownMenuItem>
                ) : null}
                {isViewerAdmin ? (
                  <StyledDropdownMenuItem
                    onSelect={() => mutateCreateMenuItemWithPost()}
                  >
                    <Text variant="md">{t('add_post_to_sidebar')}</Text>
                  </StyledDropdownMenuItem>
                ) : null}
              </x.div>
            )}
          </StyledDropdownMenuContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      <GroupsModal
        width="425px"
        variant="default"
        isOpen={showReschedulePostModal}
        ariaLabel="Reschedule Post"
        onDismiss={() => setShowReschedulePostModal(false)}
      >
        <x.div spaceY={3}>
          <Heading variant="lg">{t('reschedule_post')}</Heading>
          <Text>{t('schedule_post_desc')}</Text>
          <StyledDateInput
            value={scheduledAt}
            onChange={(e) => setScheduledAt(e.target.value)}
            type="datetime-local"
            boxSizing="border-box"
            display="flex"
            flexDirection="row"
            alignItems="center"
            border="default"
            borderRadius="md"
            borderColor={{
              _: 'gray.200',
              hover: 'gray.300',
              focus: 'gray.300',
            }}
            transition
            transitionDuration="fast"
            transitionTimingFunction="ease-in-out"
            w="full"
            p={2}
            px={4}
          />
          <x.div
            spaceX={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              onClick={() => setShowReschedulePostModal(false)}
              label={t('cancel')}
              variant="secondary"
            />
            <Button
              disabled={
                !scheduledAt || (!!scheduledAt && isPast(scheduledAtAsDate))
              }
              loading={rescheduling}
              onClick={() => {
                if (scheduledAt && isFuture(scheduledAtAsDate)) {
                  rescheduleDraftPost({
                    variables: {
                      input: {
                        postId: post.id,
                        scheduledAt: scheduledAtAsDate.toISOString(),
                      },
                    },
                    onCompleted: () => {
                      setShowReschedulePostModal(false);
                    },
                  });
                }
              }}
              label={t('reschedule_post')}
            />
          </x.div>
        </x.div>
      </GroupsModal>
      <GroupsModal
        isOpen={showHidePostOnProfileModal}
        ariaLabel={t('hide_post_on_author_profile')}
        onDismiss={() => setShowHidePostOnProfileModal(false)}
        showClose={false}
      >
        <x.div
          display="flex"
          flexDirection="column"
          alignItems="center"
          p="4 2"
        >
          <Heading variant="lg" mb={3}>
            {t('hide_post_on_author_profile')}
          </Heading>
          <Text variant="md" mb={6}>
            {t('hide_post_on_author_profile_description')}
          </Text>
          <x.div display="flex" spaceX={2}>
            <Button
              variant="secondary"
              label={t('show_post')}
              onClick={() => setShowHidePostOnProfileModal(false)}
            />
            <Button
              label={t('hide_post')}
              onClick={() => {
                if (!post.hideFromProfile) {
                  toggleHideFromProfilePost({
                    variables: {
                      input: {
                        postId: post.id,
                      },
                    },
                  });
                }
                setShowHidePostOnProfileModal(false);
              }}
            />
          </x.div>
        </x.div>
      </GroupsModal>
      {showDeleteAlert && (
        <Alert
          headingText={
            post.type === PostTypes.Lesson
              ? t('delete_lesson')
              : t('delete_post')
          }
          descriptionText={
            post.type === PostTypes.Lesson
              ? t('delete_lesson_confirmation')
              : t('delete_post_confirmation')
          }
          submitButtonText={t('delete')}
          cancelButtonText={t('cancel')}
          onDismiss={() => {
            setShowDeleteAlert(false);
          }}
          onSubmitClick={() => {
            if (post) {
              mutateDeletePost({
                variables: {
                  input: {
                    id: post?.id,
                  },
                },
                refetchQueries: [
                  refetchHomeFeedQuery({
                    organizationId: organization.id,
                    first: POSTS_PER_PAGE,
                  }),
                  refetchPostsByGroupQuery({
                    groupId: post.group.id,
                    first: POSTS_PER_PAGE,
                  }),
                ],
                awaitRefetchQueries: true,
                onCompleted: () => {
                  setShowDeleteAlert(false);
                },
              });
            }
          }}
          onCancelClick={() => {
            setShowDeleteAlert(false);
          }}
          variant="warning"
        />
      )}
      {showMovePostModal && (
        <MovePostModal
          post={post}
          onDismiss={() => setShowMovePostModal(false)}
        />
      )}
    </>
  );
};

type FollowPostDropdownMenuItemProps = {
  post: PostFeedFragment;
};
const FollowPostDropdownMenuItem = ({
  post,
}: FollowPostDropdownMenuItemProps) => {
  const { t } = useTranslation();
  const { viewer: user } = useViewer();
  const { data, refetch } = useIsFollowingPostQuery({
    variables: {
      userId: user?.id || '',
      postId: post.id,
    },
    skip: !user,
    context: {
      skip: !user,
    },
    fetchPolicy: 'cache-first',
  });

  const [toggleFollowPost] = useToggleFollowPostMutation({
    onCompleted: () => {
      refetch();
    },
  });

  if (!user) {
    return null;
  }

  const followTranslation =
    post.type === PostTypes.Lesson ? t('follow_lesson') : t('follow_post');
  const unfollowTranslation =
    post.type === PostTypes.Lesson ? t('unfollow_lesson') : t('unfollow_post');

  let action = followTranslation;

  if (data) {
    action = data.isFollowingPost ? unfollowTranslation : followTranslation;
  } else {
    // Default to Unfollow if the post is created by the user
    if (post.createdBy.id === user.id) {
      action = unfollowTranslation;
    }
  }

  return (
    <StyledDropdownMenuItem
      disabled={!data}
      onSelect={() =>
        toggleFollowPost({
          variables: {
            input: {
              postId: post.id,
              userId: user.id,
            },
          },
        })
      }
    >
      <Text variant="md" color={!data ? 'gray.300' : undefined}>
        {action}
      </Text>
    </StyledDropdownMenuItem>
  );
};
