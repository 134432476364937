import * as React from "react";

function SvgAppGithub(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-github_svg__clip0)">
        <g clipPath="url(#app-github_svg__clip1)">
          <path
            d="M12 0a12 12 0 00-3.795 23.385c.6.105.825-.255.825-.57 0-.285-.015-1.23-.015-2.235-3.015.555-3.795-.735-4.035-1.41a4.363 4.363 0 00-1.23-1.695c-.42-.225-1.02-.78-.015-.795a2.402 2.402 0 011.845 1.23 2.566 2.566 0 003.495.99c.052-.61.324-1.18.765-1.605-2.67-.3-5.46-1.335-5.46-5.925a4.67 4.67 0 011.23-3.225 4.312 4.312 0 01.12-3.18s1.005-.315 3.3 1.23c1.963-.54 4.036-.54 6 0 2.295-1.56 3.3-1.23 3.3-1.23a4.312 4.312 0 01.12 3.18 4.643 4.643 0 011.23 3.225c0 4.605-2.805 5.625-5.475 5.925a2.841 2.841 0 01.81 2.22c0 1.605-.015 2.895-.015 3.3 0 .315.225.69.825.57A12.007 12.007 0 0012 0z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="app-github_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
        <clipPath id="app-github_svg__clip1">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppGithub;
