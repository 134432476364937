import * as React from "react";

function SvgRotateCw(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.807 7C16.447 5.079 14.394 4 12 4a8 8 0 108 8h2c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2c2.804 0 5.275 1.183 7 3.27V2h2v7h-7V7h3.807z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgRotateCw;
