import * as React from "react";
import { SVGProps } from "react";
const SvgAspect = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.542 2.917A2.301 2.301 0 0 0 1.25 5.208v9.584a2.301 2.301 0 0 0 2.292 2.291h12.916a2.301 2.301 0 0 0 2.292-2.291V5.208a2.301 2.301 0 0 0-2.292-2.291H3.542Zm0 1.25h12.916c.583 0 1.042.459 1.042 1.041v9.584c0 .582-.46 1.041-1.042 1.041H3.542c-.583 0-1.042-.459-1.042-1.041V5.208c0-.582.46-1.041 1.042-1.041Zm.833 1.25a.625.625 0 0 0-.625.625v3.333a.625.625 0 1 0 1.25 0V6.667h2.708a.625.625 0 1 0 0-1.25H4.375Zm11.24 4.574a.625.625 0 0 0-.615.634v2.708h-2.708a.624.624 0 0 0-.242 1.205c.077.031.159.046.242.045h3.333a.625.625 0 0 0 .625-.625v-3.333a.625.625 0 0 0-.635-.634Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgAspect;
