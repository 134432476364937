import { theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { FC } from 'react';

import { GroupFormCover } from '../GroupCover';
import { GroupFormEmoji } from '../GroupEditModal/GroupForm/GroupFormEmoji';

export const GroupCreateHeading: FC = () => {
  return (
    <x.div position="relative" mb={4}>
      <GroupFormCover buttonInset={6} />
      <GroupFormEmoji
        position="absolute"
        left={theme.sizes[6]}
        bottom="-20"
        w={20}
        h={20}
      />
    </x.div>
  );
};
