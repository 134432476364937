import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';

import { GroupWithMembersFragment } from '../../../../generated/types-and-hooks';
import { useViewer } from '../../auth/hooks/useViewer';
import { GroupsSidebarOrganization } from '../../groups/components/GroupsSidebar/GroupsSidebar';
import {
  AudienceSelection,
  AudienceSelector,
  AudienceSelectorMenuItemStatusTag,
} from './AudienceSelector';
import { useAudienceSelectorContext } from './AudienceSelector/AudienceSelectorContext';
import { AudienceSelectionEmail } from './AudienceSelector/AudienceSelectorTypes';
import { Avatar } from './Avatar';
import { Button } from './Button';
import { Icon } from './Icon';
import { Text } from './Text';

export const ShareAudienceMember: React.FC<{
  audience: AudienceSelection;
}> = ({ audience }) => {
  const { multipleSelection } = useAudienceSelectorContext();
  const { removeSelectedItem } = multipleSelection;

  const handleRemove = () => {
    removeSelectedItem(audience);
  };

  return (
    <x.div display="flex" alignItems="center" spaceX={3}>
      <x.div>
        <Avatar
          size="s-3"
          {...(audience.type === 'user'
            ? {
                avatar: audience.payload.avatar,
                imageId: audience.payload.profileImageId,
              }
            : {
                placeholderIcon: true,
                bgColor: 'gray.50',
                placeholderColor: 'gray.300',
              })}
        />
      </x.div>
      <x.div
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
      >
        <x.div>
          <Text
            variant="md-semibold"
            maxW={400}
            truncate
            color={audience.type === 'email' ? 'gray.300' : 'inherit'}
          >
            {audience.type === 'user'
              ? audience.payload.name
              : audience.payload}
          </Text>
        </x.div>
        <Button
          variant="icon"
          leftElement={<Icon name="Close" size="4" />}
          onClick={handleRemove}
        />
      </x.div>
    </x.div>
  );
};

const ShareMembersAudienceSelection: React.FC = () => {
  const { multipleSelection } = useAudienceSelectorContext();
  const { selectedItems } = multipleSelection;

  if (!selectedItems.length) {
    return null;
  }

  return (
    <x.div py={4} spaceY={2}>
      {selectedItems.map((audience, i) => (
        <ShareAudienceMember key={i} audience={audience} />
      ))}
    </x.div>
  );
};

/**
 * If no group is passed, only allow email invitations
 */
type ShareAudienceProps = {
  prompt: string | JSX.Element;
  placeholder?: string;
  organization: GroupsSidebarOrganization;
} & (
  | {
      group: GroupWithMembersFragment;
      onChangeAudience: (data: AudienceSelection[]) => void;
    }
  | {
      group: undefined;
      onChangeAudience: (data: AudienceSelectionEmail[]) => void;
    }
);

export const ShareAudience: React.FC<ShareAudienceProps> = ({
  group,
  onChangeAudience,
  prompt,
  placeholder,
  organization,
}) => {
  const { viewer: user } = useViewer();
  const { t } = useTranslation('groups');

  const renderMenuItemStatus = useCallback(
    (as: AudienceSelection) => {
      const alreadyJoined =
        group &&
        !!(
          as.type === 'user' &&
          group.members.find(
            (member) =>
              member.__typename === 'User' && member.id === as.payload.id
          )
        );
      if (alreadyJoined) {
        return (
          <AudienceSelectorMenuItemStatusTag
            backgroundColor="green.200"
            color="white"
            label={t('groups.already_joined')}
          />
        );
      }

      return null;
    },
    [group, t]
  );

  // Todo -> move typing inside AudienceSelector
  const handleChangeAudience = (data: AudienceSelection[]) => {
    if (!group) {
      return onChangeAudience(data as AudienceSelectionEmail[]);
    }

    onChangeAudience(data);
  };

  /**
   * Initial suggestions should only include users that didn't already join in group context
   */
  const filterInitialSuggestions = useCallback(
    (initialSuggestions: AudienceSelection[]) => {
      return initialSuggestions.filter((as) => {
        if (as.type !== 'user') {
          return false;
        }

        const alreadyJoinedGroup =
          group &&
          !!group.members.find(
            (member) =>
              member.__typename === 'User' && member.id === as.payload.id
          );

        return alreadyJoinedGroup ? false : true;
      });
    },
    [group]
  );

  const isValidSelection = useCallback(
    (audienceSelection: AudienceSelection) => {
      const hasMember = group?.members.find((member) => {
        if (audienceSelection.type === 'user') {
          return member.id === audienceSelection.payload.id;
        }

        if (audienceSelection.type === 'email' && 'email' in member) {
          return member.email === audienceSelection.payload;
        }
      });

      return !hasMember;
    },
    [group]
  );

  const excludedUserIds = useMemo(() => (user ? [user.id] : []), [user]);

  return (
    <x.div spaceY={2}>
      <x.div spaceY={2}>
        <AudienceSelector
          placeholder={placeholder}
          showSelectedTokens={false}
          showSuggestedEveryoneGroup={false}
          showSuggestedUsers={!!group}
          renderMenuItemStatus={renderMenuItemStatus}
          onChange={handleChangeAudience}
          isValidSelection={isValidSelection}
          filterInitialSuggestions={filterInitialSuggestions}
          autoFocus
          excludedUserIds={excludedUserIds}
          organization={organization}
          allowEmails={!group}
        >
          <ShareMembersAudienceSelection />
        </AudienceSelector>
      </x.div>
      <Text>{prompt}</Text>
    </x.div>
  );
};
