import * as React from "react";
import { SVGProps } from "react";
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.368 4.993a.625.625 0 0 0-.435 1.074l3.1 3.1H1.458a.625.625 0 1 0 0 1.25h15.575l-3.1 3.1a.627.627 0 0 0 .197 1.029.624.624 0 0 0 .687-.146l4.167-4.167a.625.625 0 0 0 0-.883l-4.167-4.167a.624.624 0 0 0-.449-.19Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowRight;
