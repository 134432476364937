import * as React from "react";
import { SVGProps } from "react";
const SvgWeb3Project = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#web3-project_svg__a)">
      <path
        d="M20.084 7.084v5.833c0 .23-.187.417-.417.417h-5.833V6.667h5.833c.23 0 .417.187.417.417Z"
        fill="url(#web3-project_svg__b)"
      />
      <path
        d="M20.083 7.917h-4.167v.833h4.167v-.833Z"
        fill="url(#web3-project_svg__c)"
      />
      <path
        d="M20.083 11.25h-4.167v.833h4.167v-.833Z"
        fill="url(#web3-project_svg__d)"
      />
      <path
        d="M15.916 10.416h3.125a.208.208 0 0 0 .208-.208v-.417a.208.208 0 0 0-.208-.208h-3.125v.833Z"
        fill="url(#web3-project_svg__e)"
      />
      <path
        d="M2.166 14.584h12.917c.69 0 1.25-.56 1.25-1.25V6.667c0-.69-.56-1.25-1.25-1.25H2.166c-.69 0-1.25.56-1.25 1.25v6.667c0 .69.56 1.25 1.25 1.25Z"
        fill="#7C8994"
      />
      <path
        d="M5.5 10a1.458 1.458 0 1 1-2.916 0A1.458 1.458 0 0 1 5.5 10Z"
        fill="url(#web3-project_svg__f)"
      />
      <path
        opacity={0.05}
        d="M10.917 12.916a2.917 2.917 0 1 0 0-5.833 2.917 2.917 0 0 0 0 5.833Z"
        fill="#333"
      />
      <path
        opacity={0.07}
        d="M10.917 12.709a2.708 2.708 0 1 0 0-5.417 2.708 2.708 0 0 0 0 5.417Z"
        fill="#333"
      />
      <path
        d="M13.416 10a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
        fill="url(#web3-project_svg__g)"
      />
    </g>
    <defs>
      <linearGradient
        id="web3-project_svg__b"
        x1={20.03}
        y1={9.983}
        x2={16.332}
        y2={10.002}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.004} stopColor="#6D7882" />
        <stop offset={0.337} stopColor="#6A757E" />
        <stop offset={0.668} stopColor="#616A72" />
        <stop offset={0.836} stopColor="#5A6269" />
        <stop offset={1} stopColor="#575F66" />
      </linearGradient>
      <linearGradient
        id="web3-project_svg__c"
        x1={16.386}
        y1={8.334}
        x2={19.037}
        y2={8.334}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5A505" />
        <stop offset={0.095} stopColor="#E9A804" />
        <stop offset={0.177} stopColor="#F4B102" />
        <stop offset={0.297} stopColor="#FBB600" />
        <stop offset={0.526} stopColor="#FDB700" />
      </linearGradient>
      <linearGradient
        id="web3-project_svg__d"
        x1={16.386}
        y1={11.667}
        x2={19.037}
        y2={11.667}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5A505" />
        <stop offset={0.095} stopColor="#E9A804" />
        <stop offset={0.177} stopColor="#F4B102" />
        <stop offset={0.297} stopColor="#FBB600" />
        <stop offset={0.526} stopColor="#FDB700" />
      </linearGradient>
      <linearGradient
        id="web3-project_svg__e"
        x1={16.375}
        y1={9.992}
        x2={19.209}
        y2={10.011}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5A505" />
        <stop offset={0.095} stopColor="#E9A804" />
        <stop offset={0.177} stopColor="#F4B102" />
        <stop offset={0.297} stopColor="#FBB600" />
        <stop offset={0.526} stopColor="#FDB700" />
      </linearGradient>
      <linearGradient
        id="web3-project_svg__f"
        x1={3.356}
        y1={8.709}
        x2={5.076}
        y2={11.944}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.428} stopColor="#F2F2F2" />
        <stop offset={1} stopColor="#CCC" />
      </linearGradient>
      <linearGradient
        id="web3-project_svg__g"
        x1={8.579}
        y1={5.77}
        x2={12.019}
        y2={11.997}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA1C" />
        <stop offset={1} stopColor="#FEB705" />
      </linearGradient>
      <clipPath id="web3-project_svg__a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWeb3Project;
