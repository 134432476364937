import React from 'react';

import { Alert } from '../../../common/components/Alert';

export type VideoPermissionsPromptProps = {
  onRequestPermissions: () => void;
  onDismiss: () => void;
};

/**
 * Prompts the user for access to their camera and microphone
 */
export const VideoPermissionsPrompt: React.FC<VideoPermissionsPromptProps> = ({
  onRequestPermissions,
  onDismiss,
}) => {
  const handleClick = () => {
    onRequestPermissions && onRequestPermissions();
  };

  return (
    <Alert
      headingText="Access request"
      descriptionText="To record video, your browser will need to request access to your camera
    &amp; microphone"
      onSubmitClick={handleClick}
      submitButtonText="Request access"
      onDismiss={onDismiss}
      icons={['mic-no', 'camera-no']}
    />
  );
};

export type VideoPermissonsDeniedProps = {
  onDismiss: () => void;
};

/**
 * Displayed when the user explicitly denies access to their camera
 */
export const VideoPermissonsDenied: React.FC<VideoPermissonsDeniedProps> = ({
  onDismiss,
}) => {
  return (
    <Alert
      icons={['mic-no', 'camera-no']}
      variant="warning"
      headingText="Access denied"
      descriptionText="Frond needs access to your microphone and camera to record videos. Please
    get in touch with us if you need help with this."
      onDismiss={onDismiss}
    />
  );
};

export type VideoDiscardWarningProps = {
  onClickDiscard: () => void;
  onDismiss: () => void;
};

/**
 * Displayed if the user attempts to leave before beginning upload
 */
export const VideoDiscardWarning: React.FC<VideoDiscardWarningProps> = ({
  onClickDiscard,
  onDismiss,
}) => {
  return (
    <Alert
      headingText="Discard recording?"
      descriptionText="Are you sure you want to discard your recorded video? All progress will be lost."
      onSubmitClick={onClickDiscard}
      submitButtonText="Discard"
      onCancelClick={onDismiss}
      cancelButtonText="Don't discard"
      variant="warning"
    />
  );
};

/**
 * Displayed if the user attempts to leave before the upload finalizes
 */
export const VideoUploadCancelWarning: React.FC<VideoDiscardWarningProps> = ({
  onClickDiscard,
  onDismiss,
}) => {
  return (
    <Alert
      headingText="Abort upload?"
      descriptionText="Are you sure you want to abort uploading? All progress will be lost"
      onSubmitClick={onClickDiscard}
      submitButtonText="Abort"
      onCancelClick={onDismiss}
      cancelButtonText="Cancel"
      variant="warning"
    />
  );
};

export type VideoUnsupportedBrowserErrorProps = {
  onDismiss: () => void;
};
/**
 * Displayed if we can't open the users media stream
 */
export const VideoUnsupportedBrowserError: React.FC<
  VideoUnsupportedBrowserErrorProps
> = ({ onDismiss }) => {
  return (
    <Alert
      icons={['mic-no', 'camera-no']}
      headingText="Unsupported browser"
      descriptionText="This feature is not yet supported by your browser"
      onDismiss={onDismiss}
    />
  );
};

export type VideoNoCameraErrorProps = {
  onDismiss: () => void;
};
/**
 * Displayed if we can't open the users media stream
 */
export const VideoNoCameraError: React.FC<VideoNoCameraErrorProps> = ({
  onDismiss,
}) => {
  return (
    <Alert
      icons={['mic-no', 'camera-no']}
      headingText="No camera"
      descriptionText="Please check your webcam's connection or restart your browser"
      onDismiss={onDismiss}
    />
  );
};
