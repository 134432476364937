import { useApolloClient } from '@apollo/client';
import { routes, theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd';

import { GroupsDocument } from '../../../../../generated/graphql-request-api-sdk';
import {
  GroupSidebarFragment,
  GroupsQuery,
  GroupsSidebarState,
  GroupsSidebarStateDocument,
  GroupTypes,
  refetchGroupsQuery,
  useGroupsSidebarStateQuery,
  useReorderGroupsMutation,
  useSidebarGroupsQuery,
} from '../../../../../generated/types-and-hooks';
import { Text } from '../../../common/components/Text';
import { Tooltip } from '../../../common/components/Tooltip';
import { topCenter } from '../../../common/utils/position';
import { GroupsSidebarOrganization } from './GroupsSidebar';
import {
  GroupsSidebarGroupLink,
  GroupsSidebarLinkList,
} from './GroupsSidebarLink';

export const NUM_GROUPS_FOR_SIDEBAR = 25;

const useGroupsSidebarState = ({
  organization,
}: {
  organization: GroupsSidebarOrganization;
}) => {
  const { cache } = useApolloClient();
  const { data: sidebarData, loading: sidebarLoading } =
    useGroupsSidebarStateQuery();

  const publicGroupsExpanded =
    sidebarData?.groupsSidebarState.publicGroupsExpanded ?? true;

  const privateGroupsExpanded =
    sidebarData?.groupsSidebarState.privateGroupsExpanded ?? true;

  const courseGroupsExpanded =
    sidebarData?.groupsSidebarState.courseGroupsExpanded ?? true;

  const { data: groupsData } = useSidebarGroupsQuery({
    variables: {
      organizationId: organization.id,
      first: NUM_GROUPS_FOR_SIDEBAR,
    },
  });

  const mutateGroupsSidebarState = (groupsSidebarState: GroupsSidebarState) => {
    cache.writeQuery({
      query: GroupsSidebarStateDocument,
      data: {
        groupsSidebarState,
      },
    });
  };

  const togglePublicGroups = () =>
    mutateGroupsSidebarState({
      publicGroupsExpanded: !publicGroupsExpanded,
      privateGroupsExpanded,
      courseGroupsExpanded,
    });

  const toggleCourseGroups = () =>
    mutateGroupsSidebarState({
      publicGroupsExpanded,
      privateGroupsExpanded,
      courseGroupsExpanded: !courseGroupsExpanded,
    });

  const togglePrivateGroups = () =>
    mutateGroupsSidebarState({
      publicGroupsExpanded,
      privateGroupsExpanded: !privateGroupsExpanded,
      courseGroupsExpanded,
    });

  const groups = groupsData?.groups.edges.map((e) => e.node) || [];
  const publicGroups = groups.filter(
    (n) =>
      !n.isPrivate &&
      n.groupType !== GroupTypes.Course &&
      n.groupType !== GroupTypes.Event
  );
  const privateGroups = groups.filter(
    (n) => n.isPrivate && n.groupType === GroupTypes.Regular
  );
  const courseGroups = groups.filter(
    (n) => !n.isPrivate && n.groupType === GroupTypes.Course
  );

  return {
    togglePublicGroups,
    togglePrivateGroups,
    toggleCourseGroups,
    publicGroups,
    privateGroups,
    courseGroups,
    sidebarLoading,
    publicGroupsExpanded,
    privateGroupsExpanded,
    courseGroupsExpanded,
  };
};

export const GroupsSidebarPrivateGroupsList: FC<{
  organization: GroupsSidebarOrganization;
  onClickGroup?: () => void;
}> = ({ organization, onClickGroup }) => {
  const { t } = useTranslation();

  const { privateGroups, togglePrivateGroups, privateGroupsExpanded } =
    useGroupsSidebarState({ organization });

  if (!privateGroups?.length) {
    return null;
  }

  return (
    <GroupsSidebarGroupList
      title={t('private')}
      groups={privateGroups}
      expanded={privateGroupsExpanded}
      onToggle={togglePrivateGroups}
      onClickGroup={onClickGroup}
      organization={organization}
    />
  );
};

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const GroupsSidebarGroupList: FC<{
  title: string;
  action?: JSX.Element;
  groups: GroupSidebarFragment[];
  expanded: boolean;
  onToggle: () => void;
  onClickGroup?: () => void;
  sortable?: boolean;
  organization: GroupsSidebarOrganization;
}> = ({
  title,
  action,
  groups,
  expanded,
  onToggle,
  onClickGroup,
  sortable,
  organization,
}) => {
  const { t } = useTranslation();
  const tooltipText = expanded ? t('hide_section') : t('show_section');

  return (
    <x.div
      maxHeight={expanded ? '100%' : theme.sizes[9]}
      overflowY="hidden"
      overflowX="visible"
      position="relative"
    >
      <GroupsSidebarLinkList>
        <li>
          <x.button
            px={3}
            py={1.5}
            backgroundColor={{ _: 'transparent', hover: 'gray.100' }}
            color={{ _: 'gray.300', hover: 'gray.400' }}
            transitionDuration="fast"
            transitionTimingFunction="ease-in-out"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            onClick={onToggle}
            border="none"
            w="full"
            borderRadius="sm"
            cursor="pointer"
          >
            <Tooltip
              aria-label={tooltipText}
              size="small"
              label={<Text variant="sm-semibold">{tooltipText}</Text>}
            >
              <Text variant="md-semibold" as="span">
                {title}
              </Text>
            </Tooltip>
            {action}
          </x.button>
        </li>
        {sortable ? (
          <GroupsSidebarSortableList
            organization={organization}
            groups={groups}
            onClickGroup={onClickGroup}
          />
        ) : (
          <GroupsSidebarStaticList
            organization={organization}
            groups={groups}
            onClickGroup={onClickGroup}
          />
        )}
      </GroupsSidebarLinkList>
    </x.div>
  );
};

type GroupsSidebarListProps = {
  groups: GroupSidebarFragment[];
  onClickGroup?: () => void;
  organization: GroupsSidebarOrganization;
};

const GroupsSidebarStaticList: React.FC<GroupsSidebarListProps> = ({
  groups,
  organization,
  onClickGroup,
}) => {
  return (
    <>
      {groups.map((g) => (
        <GroupsSidebarGroupLink
          key={g.id}
          route={
            g.groupType === GroupTypes.Course
              ? routes.groups.organization(organization.shortId).course(g.id)
              : routes.groups.organization(organization.shortId).group(g.id)
          }
          onClick={onClickGroup}
          group={g}
          name={g.name}
          organization={organization}
        />
      ))}
    </>
  );
};

const GroupsSidebarSortableList: React.FC<GroupsSidebarListProps> = ({
  groups,
  onClickGroup,
  organization,
}) => {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);
  const [reorderGroups] = useReorderGroupsMutation();
  const [sortedGroups, setSortedGroups] = useState(groups);

  useEffect(() => {
    setSortedGroups(groups);
  }, [groups]);

  return (
    <>
      <Tooltip
        visible={showTooltip}
        aria-label={t('sort_groups')}
        size="small"
        mt="-2"
        position={topCenter}
        label={
          <Text variant="sm-semibold" textAlign="center">
            {t('sort_groups')}
          </Text>
        }
      >
        <x.div position="absolute" w="full" top={0} />
      </Tooltip>
      <DragDropContext
        onDragStart={() => setShowTooltip(true)}
        onDragEnd={(result) => {
          setShowTooltip(false);
          if (!result.destination) {
            return;
          }

          const items = reorder(
            groups,
            result.source.index,
            result.destination.index
          );

          setSortedGroups(items);
          reorderGroups({
            variables: { input: { groupIds: items.map((i) => i.id) } },
            refetchQueries: [
              refetchGroupsQuery({
                organizationId: organization.id,
                first: NUM_GROUPS_FOR_SIDEBAR,
              }),
            ],
            update: (cache) => {
              const cachedQuery = cache.readQuery<GroupsQuery>({
                query: GroupsDocument,
                variables: {
                  organizationId: organization.id,
                  first: NUM_GROUPS_FOR_SIDEBAR,
                },
              });

              if (!cachedQuery) {
                return;
              }

              // Sort the edges separately until the refresh query resolves
              const { edges } = cachedQuery.groups;
              const fromIndex = edges.findIndex(
                (e) => e.node.id === groups[result.source.index].id
              );
              const toIndex = edges.findIndex(
                (e) => e.node.id === groups[result.destination?.index || 0]?.id
              );

              cache.writeQuery({
                query: GroupsDocument,
                variables: {
                  organizationId: organization.id,
                  first: NUM_GROUPS_FOR_SIDEBAR,
                },
                data: {
                  groups: {
                    ...cachedQuery?.groups,
                    edges: reorder(edges, fromIndex, toIndex),
                  },
                },
              });
            },
          });
        }}
      >
        <Droppable droppableId="groups">
          {(provided: DroppableProvided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {sortedGroups.map((g, index) => (
                <Draggable key={g.id} draggableId={g.id} index={index}>
                  {(
                    provided: DraggableProvided,
                    snapshot: DraggableStateSnapshot
                  ) => (
                    <x.div
                      display="block"
                      borderRadius="sm"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      {...(snapshot.draggingOver && {
                        bg: 'gray.100',
                        opacity: 0.65,
                      })}
                    >
                      <GroupsSidebarGroupLink
                        name={g.name}
                        group={g}
                        route={
                          g.groupType === GroupTypes.Course
                            ? routes.groups
                                .organization(organization.shortId)
                                .course(g.id)
                            : routes.groups
                                .organization(organization.shortId)
                                .group(g.id)
                        }
                        onClick={onClickGroup}
                        organization={organization}
                      />
                    </x.div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
