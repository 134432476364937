import { x } from '@xstyled/styled-components';
import { isAfter } from 'date-fns';
import { useTranslation } from 'next-i18next';
import React, { FC, Fragment, useState } from 'react';

import {
  GroupCoreFragment,
  GroupTypes,
  GroupWithMembersFragment,
} from '../../../../generated/graphql-request-api-sdk';
import {
  PostTypes,
  useAddGroupViewMutation,
  useNewMemberPostQuery,
  usePostsByGroupQuery,
  ViewLevels,
  ViewLocations,
} from '../../../../generated/types-and-hooks';
import { POSTS_PER_PAGE } from '../../../config/constants';
import { SignInModal } from '../../auth/components/SignIn/SignInModal';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { Button } from '../../common/components/Button';
import { Icon } from '../../common/components/Icon';
import { useEffectOnce } from '../../common/hooks/useEffectOnce';
import { DetailLayout } from '../../common/layout/DetailLayout';
import { InfiniteScroll } from '../../common/layout/InfiniteScroll';
import { GroupDraftModeCard } from '../../groups/components/GroupDraftModeCard';
import { GroupEmpty } from '../../groups/components/GroupEmpty';
import { GroupJoinAlert } from '../../groups/components/GroupJoinAlert';
import { GroupNewPostButton } from '../../groups/components/GroupNewPostButton';
import { GroupUserSettingsDropdown } from '../../groups/components/GroupUserSettingsDropdown';
import { useGroupMembership } from '../../groups/hooks/useGroupMembership';
import { IntroduceYourselfCard } from '../../posts/components/IntroduceYourselfCard';
import { PostCard } from '../../posts/components/PostCard';
import { PostViewMutator } from '../../posts/components/PostViewMutator';
import { PostsLoading } from '../components/PostsLoading';
import { PostNavContext } from '../hooks/useAnswerNavigation';

export type GroupViewProps = {
  group?: GroupWithMembersFragment | null;
  showLayout?: boolean;
};

export const GroupView: FC<GroupViewProps> = ({ group, showLayout = true }) => {
  const { viewer: user } = useViewer();
  const { organization } = useOrganization();
  const [mutateAddGroupView] = useAddGroupViewMutation();

  useEffectOnce(
    () => {
      group?.__typename == 'Group' &&
        user &&
        mutateAddGroupView({
          variables: {
            input: {
              viewedId: group.id,
              level: ViewLevels.Detail,
              location: ViewLocations.GroupDetail,
            },
          },
        });
    },
    () => !!group
  );

  const { data, loading, fetchMore } = usePostsByGroupQuery({
    variables: {
      groupId: group?.id || '',
      first: POSTS_PER_PAGE,
    },
    skip: !group,
    context: {
      skip: !group,
    },
  });

  const { data: newMemberPostData } = useNewMemberPostQuery({
    variables: {
      organizationId: organization.id,
      userId: user?.id || '',
    },
    skip: !user,
    context: {
      skip: !user,
    },
  });

  const userHasNoIntroduction = !newMemberPostData?.newMemberPost;

  const showLoading =
    !data || !group || group.organization.id !== organization.id;

  return (
    <DetailLayout
      controls={<GroupViewControls group={group} />}
      numPosts={group?.numPosts}
      numComments={group?.numComments}
      numReactions={group?.numReactions}
      item={group}
      showLayout={showLayout}
    >
      {showLoading ? (
        <PostsLoading />
      ) : data.postsByGroup.edges.length === 0 ? (
        <GroupEmpty group={group} />
      ) : (
        <x.div spaceY={6} h="full">
          {group.groupType === GroupTypes.Course && group.isDraft && (
            <GroupDraftModeCard group={group} />
          )}
          <InfiniteScroll
            threshold={100}
            loading={loading}
            hasMore={data.postsByGroup.pageInfo.hasNextPage || false}
            loadMore={() => {
              fetchMore({
                variables: {
                  after: data.postsByGroup.pageInfo.endCursor,
                },
              });
            }}
          >
            {data.postsByGroup.edges.map(({ node }, index, arr) => {
              const userCreatedAfterPost = user
                ? isAfter(new Date(user.createdAt), new Date(node.createdAt))
                : false;

              return (
                <Fragment key={node.id}>
                  {/* Add card above last post before they joined the org */}
                  {userCreatedAfterPost &&
                  node.type !== PostTypes.Welcome &&
                  userHasNoIntroduction &&
                  organization.enableNewMemberIntroductions &&
                  group.groupType === GroupTypes.Welcome ? (
                    <IntroduceYourselfCard variant="feed" />
                  ) : null}
                  <PostViewMutator
                    post={node}
                    viewLevel={ViewLevels.Detail}
                    viewLocation={ViewLocations.GroupDetail}
                    delay={2500}
                  >
                    <PostCard
                      post={node}
                      order={index + 1}
                      totalPostsInGroup={data.postsByGroup.totalCount}
                      navContext={PostNavContext.GROUP}
                      maxComments={3}
                    />
                  </PostViewMutator>
                  {/* Add card below if it's the last post */}
                  {!userCreatedAfterPost &&
                  index === arr.length - 1 &&
                  userHasNoIntroduction &&
                  organization.enableNewMemberIntroductions &&
                  group.groupType === GroupTypes.Welcome ? (
                    <IntroduceYourselfCard variant="feed" />
                  ) : null}
                </Fragment>
              );
            })}
          </InfiniteScroll>
        </x.div>
      )}
    </DetailLayout>
  );
};

export const GroupViewControls = ({
  group,
}: {
  group?: GroupWithMembersFragment | null;
}) => {
  if (!group) return null;

  return (
    <x.div spaceX={2} display="flex" alignItems="center">
      <GroupNewPostButton
        group={group}
        variant={
          group.groupType === GroupTypes.Course ? 'secondary' : 'primary'
        }
      />
      <JoinGroupButton group={group} />
      <GroupJoinAlert group={group} />
      <GroupUserSettingsDropdown group={group}>
        <Button
          p={2}
          variant="secondary"
          onClick={(e: React.MouseEvent) => e.preventDefault()}
          leftElement={<Icon name="more" />}
        />
      </GroupUserSettingsDropdown>
    </x.div>
  );
};

const JoinGroupButton: FC<{ group: GroupCoreFragment }> = ({ group }) => {
  const { t } = useTranslation();
  const [showSignInModal, setShowSignInModal] = useState(false);

  const { viewer } = useViewer();
  const { organization } = useOrganization();
  const { joinGroup, userIsMember } = useGroupMembership({
    group,
    organization,
  });

  if (userIsMember || (group.groupType === GroupTypes.Course && group.isDraft))
    return null;

  return (
    <>
      <Button
        label={
          group.groupType === GroupTypes.Course
            ? t('join_course')
            : t('join_group')
        }
        onClick={() => {
          if (viewer) {
            joinGroup();
          } else {
            setShowSignInModal(true);
          }
        }}
      />
      <SignInModal
        isOpen={showSignInModal}
        onDismiss={() => setShowSignInModal(false)}
        variant="public"
      />
    </>
  );
};
