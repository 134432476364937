import { createContext, PropsWithChildren, useContext } from 'react';

import { OrganizationRolesQuery } from '../../../../generated/graphql-request-api-sdk';
import { useOrganizationRolesQuery } from '../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { userInOrganization } from '../../auth/utils/permissions';

type OrganizationRolesType = {
  data?: OrganizationRolesQuery;
  loading: boolean;
};

const OrgaizationRolesContext = createContext<OrganizationRolesType>({
  loading: false,
});

export const OrganizationRolesProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { viewer } = useViewer();
  const { organization } = useOptionalOrganization();

  const { data, loading } = useOrganizationRolesQuery({
    variables: {
      organizationId: organization?.id || '',
    },
    skip: !organization || !userInOrganization(viewer, organization),
  });

  return (
    <OrgaizationRolesContext.Provider value={{ data, loading }}>
      {children}
    </OrgaizationRolesContext.Provider>
  );
};

export const useOrganizationRoles = () => useContext(OrgaizationRolesContext);
