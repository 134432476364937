import * as React from "react";

function SvgKeyboard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16 17H8v2h8v-2z" fill="currentColor" />
      <path
        d="M22 8h-9V7c0-.551.449-1 1-1h1c1.654 0 3-1.346 3-3V2h-2v1c0 .551-.449 1-1 1h-1c-1.654 0-3 1.346-3 3v1H2c-1.103 0-2 .896-2 2v10c0 1.103.897 2 2 2h20c1.103 0 2-.897 2-2V10c0-1.104-.897-2-2-2zM2 20V10h20l.001 10H2z"
        fill="currentColor"
      />
      <path
        d="M6 13a1 1 0 100-2 1 1 0 000 2zM9 13a1 1 0 100-2 1 1 0 000 2zM12 13a1 1 0 100-2 1 1 0 000 2zM15 13a1 1 0 100-2 1 1 0 000 2zM18 13a1 1 0 100-2 1 1 0 000 2zM6 16a1 1 0 100-2 1 1 0 000 2zM9 16a1 1 0 100-2 1 1 0 000 2zM12 16a1 1 0 100-2 1 1 0 000 2zM15 16a1 1 0 100-2 1 1 0 000 2zM18 16a1 1 0 100-2 1 1 0 000 2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgKeyboard;
