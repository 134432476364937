import { FC } from 'react';

import { Organization } from '../../../../../generated/types-and-hooks';
import { useViewer } from '../../../auth/hooks/useViewer';
import { Text } from '../../../common/components/Text';
import { OrganizationAvatar } from '../../../organizations/components/OrganizationAvatar';
import { UserSettingsMenuRow } from './UserSettingsMenuRow';

export const UserSettingsMenuOrganizationList: FC<{
  onSelectOrganization: (
    org: Pick<Organization, 'id' | 'logoId' | 'name' | 'shortId'>
  ) => void;
}> = ({ onSelectOrganization }) => {
  const { viewer: user } = useViewer();
  const organizations = user?.organizationMemberships?.map(
    (m) => m.organization
  );

  return (
    <>
      {organizations?.map((org) => (
        <UserSettingsMenuRow
          key={org.id}
          onClick={() => onSelectOrganization(org)}
        >
          <OrganizationAvatar organization={org} size="xs" />
          <Text variant="md">{org.name}</Text>
        </UserSettingsMenuRow>
      ))}
    </>
  );
};
