import { routes } from '@frond/shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  PostTypes,
  useUpdateNewMemberIntroductionMessageMutation,
  useWelcomeGroupQuery,
} from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useIsViewerAdmin, useViewer } from '../../auth/hooks/useViewer';
import { Button } from '../../common/components/Button';
import { Form } from '../../common/components/Form';
import { Heading } from '../../common/components/Heading';
import { Input } from '../../common/components/Input';
import { Text } from '../../common/components/Text';
import { GroupsModal } from '../../groups/components/GroupsModal';
import { PostComposer } from './PostComposer';

type IntroduceYourselfCardProps = {
  variant: 'feed' | 'settings';
};
export const IntroduceYourselfCard = ({
  variant,
}: IntroduceYourselfCardProps) => {
  const { t } = useTranslation();
  const { viewer: user } = useViewer();
  const { organization } = useOrganization();
  const isViewerAdmin = useIsViewerAdmin(organization);
  const [showEditPromptModal, setShowEditPromptModal] = useState(false);
  const [composerOpen, setComposerOpen] = useState(false);
  const { data: welcomeGroupData } = useWelcomeGroupQuery({
    variables: {
      organizationId: organization.id,
    },
  });
  if (variant !== 'settings' && !organization.enableNewMemberIntroductions) {
    return null;
  }

  if (!user || !welcomeGroupData?.welcomeGroup) return null;

  return (
    <x.div bg="gray.100" borderRadius={{ _: 0, sm: 'md-lg' }} overflow="hidden">
      <x.div spaceY={3} p={6}>
        <Text variant="md-semibold">{t('introduce_yourself')}</Text>
        <Text>{organization.newMemberIntroductionMessage}</Text>
        <Button
          variant="secondary"
          label={t('introduce_yourself')}
          onClick={() => setComposerOpen(true)}
          w="full"
          disabled={variant === 'settings'}
        />
      </x.div>

      {composerOpen && (
        <PostComposer
          isOpen
          onDismiss={() => {
            setComposerOpen(false);
          }}
          type={PostTypes.NewUser}
          group={welcomeGroupData.welcomeGroup}
        />
      )}

      {isViewerAdmin && variant === 'feed' ? (
        <x.div
          bg="yellow.50"
          border="default"
          borderColor="yellow.200"
          mx={6}
          mb={6}
          p={4}
          borderRadius="md"
          spaceY={3}
        >
          <x.div spaceY={1}>
            <Text variant="md-s-semibold">{t('member_introductions')}</Text>
            <Text>{t('member_introductions_explanation')}</Text>
          </x.div>

          <x.div display="flex" spaceX={2}>
            <Button
              label={t('edit_prompt')}
              variant="secondary"
              onClick={() => setShowEditPromptModal(true)}
            />
            <Button
              label={t('open_settings')}
              href={routes.settings
                .organization(organization.shortId)
                .onboarding()}
              variant="secondary"
            />
          </x.div>
        </x.div>
      ) : null}
      <EditPromptModal
        isOpen={showEditPromptModal}
        onDismiss={() => setShowEditPromptModal(false)}
      />
    </x.div>
  );
};

type EditPromptModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

const FormSchema = yup
  .object({
    message: yup.string().required(),
  })
  .defined();

type FormData = yup.InferType<typeof FormSchema>;

const EditPromptModal = ({ isOpen, onDismiss }: EditPromptModalProps) => {
  const { t } = useTranslation();
  const { organization } = useOrganization();

  const [updateNewMemberIntroductionMessage] =
    useUpdateNewMemberIntroductionMessageMutation();

  const form = useForm<FormData>({
    resolver: yupResolver(FormSchema),
    defaultValues: { message: organization.newMemberIntroductionMessage },
  });

  return (
    <GroupsModal isOpen={isOpen} onDismiss={onDismiss} width="445px">
      <Form<FormData>
        {...form}
        onSubmit={(data) => {
          updateNewMemberIntroductionMessage({
            variables: {
              input: {
                message: data.message,
                organizationId: organization.id,
              },
            },
            optimisticResponse: {
              __typename: 'Mutation',
              updateNewMemberIntroductionMessage: {
                __typename: 'Organization',
                id: organization.id,
                newMemberIntroductionMessage: data.message,
              },
            },
            onCompleted: () => {
              onDismiss();
            },
          });
        }}
      >
        <x.div spaceY={3}>
          <Heading>{t('edit_prompt')}</Heading>
          <Input
            fullWidth
            isMultiLine
            {...form.register('message')}
            label={t('prompt')}
            rows={3}
          />
          <Button label={t('save')} type="submit" />
        </x.div>
      </Form>
    </GroupsModal>
  );
};
