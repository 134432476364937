import { Grid } from '@giphy/react-components';
import styled, { x } from '@xstyled/styled-components';
import debounce from 'lodash/debounce';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Giphy,
  useCreateGiphyMutation,
} from '../../../../generated/types-and-hooks';
import { _SearchContext, SearchContext } from './GiphySearchContext';
import { Icon } from './Icon';
import { Input } from './Input';
import { LoadingPulse } from './LoadingPulse';

const Container = styled.divBox`
  aspect-ratio: auto;

  @media (min-width: sm) {
    aspect-ratio: 1;
  }
`;

type GiphySearchGridProps = {
  onCompleted: (giphy: Giphy) => void;
  colorVariant: 'brand' | 'white';
};
export const GiphySearch = ({
  onCompleted,
  colorVariant,
}: GiphySearchGridProps) => {
  const { fetchGifs, searchKey } = useContext(SearchContext);
  const { _setSearch } = useContext(_SearchContext);
  const [width, setWidth] = useState<number>();
  const [createGiphy] = useCreateGiphyMutation({
    onCompleted(data) {
      onCompleted(data.createGiphy);
    },
  });
  const containerRef = useRef<HTMLDivElement>(null);

  const debouncedSetSearch = useMemo(
    () => debounce(_setSearch, 500),
    [_setSearch]
  );

  const handleChangeSearchTerm = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearch(e.target.value);
  };

  const measure = useCallback(() => {
    if (containerRef.current) {
      const subtract = 20 * 2;
      setWidth(containerRef.current.clientWidth - subtract);
    }
  }, [containerRef]);

  useLayoutEffect(() => {
    measure();
    window.addEventListener('resize', measure);
    return () => window.removeEventListener('resize', measure);
  }, [containerRef, measure]);

  return (
    <Container
      px={5}
      pb={10}
      h={{ _: 'full', sm: 'auto' }}
      w={{ _: 'full', sm: 'auto' }}
      overflow="scroll"
      boxSizing="border-box"
      ref={containerRef}
    >
      {width && (
        <>
          <x.div
            pt={5}
            pb={2}
            position="sticky"
            top={0}
            zIndex={10}
            bg={colorVariant === 'brand' ? 'brand.50' : 'white'}
          >
            <Input
              icon={<Icon name="search" />}
              onChange={handleChangeSearchTerm}
              fullWidth
            />
          </x.div>
          <Grid
            width={width}
            columns={3}
            gutter={16}
            borderRadius={12}
            hideAttribution={true}
            fetchGifs={fetchGifs}
            key={searchKey}
            loader={() => (
              <x.div
                display="flex"
                justifyContent="center"
                alignItems="center"
                pt={12}
              >
                <LoadingPulse />
              </x.div>
            )}
            onGifClick={(gif, e) => {
              createGiphy({
                variables: {
                  input: {
                    giphyId: gif.id.toString(),
                    giphyMp4Url: gif.images.original_mp4.mp4,
                    giphyUrl: gif.images.original.url,
                    height: gif.images.original.height,
                    width: gif.images.original.width,
                  },
                },
              });

              e.preventDefault();
            }}
          />
        </>
      )}
    </Container>
  );
};
