import styled, { x } from '@xstyled/styled-components';
import { th } from '@xstyled/system';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from '../../../common/components/Button';
import { Text } from '../../../common/components/Text';
import { formatSecondsToMMSS } from '../../../video/components/Video/Video.utils';
import { VideoRecordViewCountdown } from '../../../video/components/Video/VideoRecordViewCountdown';
import { VideoRecordButton } from '../../../video/components/VideoRecorder/VideoRecordButton';
import {
  VideoRecorderStatusIndicator,
  VideoRecorderTimeContainer,
} from '../../../video/components/VideoRecorder/VideoRecorderTimeContainer';
import { VIDEO_MAX_LENGTH } from '../../../video/video.config';
import { QuestionsVideoRecorderMediaInputSelectors } from './QuestionsVideoRecorderMediaInputSelectors';

type QuestionsVideoRecorderControlsProps = {
  recording: boolean;
  paused: boolean;
  countdown: number | null;
  onClickRecord: () => void;
  onClickPause: () => void;
  onTimeElapsed: () => void;
  activeVideoInputDeviceId?: string;
  activeAudioInputDeviceId?: string;
  onSelectVideoInputDevice: (deviceId: string) => void;
  onSelectAudioInputDevice: (deviceId: string) => void;
};

type QuestionsVideoRecorderRecordViewProps =
  QuestionsVideoRecorderControlsProps & {
    stream: MediaStream | null;
    onCanPlay: () => void;
  };

const StyledButton = styled(Button)<{ paused: boolean; disabled: boolean }>`
  background-color: gray.400;
  opacity: ${(p) => (p.disabled ? 0.32 : 1)};
  color: ${(p) => (p.paused ? th('colors.yellow.300') : th('colors.white'))};
`;
/**
 * Recording control wrapper for a video element
 */
export const QuestionsVideoRecorderRecordView: React.FC<
  QuestionsVideoRecorderRecordViewProps
> = ({ stream, onCanPlay, ...controlsProps }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [videoRef, stream]);

  return (
    <x.div position="relative">
      <x.video
        w="100%"
        ref={videoRef}
        autoPlay
        muted
        transform
        scaleX={-1}
        borderRadius="md-lg"
        onCanPlay={onCanPlay}
        style={{ aspectRatio: '1' }}
        objectFit="cover"
        backgroundColor="gray.100"
      />
      {controlsProps.countdown && (
        <VideoRecordViewCountdown countdown={controlsProps.countdown} />
      )}
      <QuestionsVideoRecorderControls {...controlsProps} />
    </x.div>
  );
};

const QuestionsVideoRecorderControls: React.FC<
  QuestionsVideoRecorderControlsProps
> = ({
  recording,
  countdown,
  paused,
  onTimeElapsed,
  onClickRecord,
  onClickPause,
  activeVideoInputDeviceId,
  activeAudioInputDeviceId,
  onSelectVideoInputDevice,
  onSelectAudioInputDevice,
}) => {
  const { t } = useTranslation();
  const [timeRemaining, setTimeRemaining] = useState<number>(VIDEO_MAX_LENGTH);
  const timerRef = useRef<number>();

  /**
   * Track timer state here to prevent accidentally
   * causing expensive re-renders
   */
  useEffect(() => {
    if (recording) {
      const tick = () => {
        if (timeRemaining === 0) {
          return onTimeElapsed();
        }
        if (!paused) {
          setTimeRemaining(timeRemaining - 1);
        }
      };

      timerRef.current = window.setTimeout(tick, 1000);
    } else if (!paused) {
      // Reset the timer when recording stops
      setTimeRemaining(VIDEO_MAX_LENGTH);
    }
  }, [recording, timeRemaining, paused, onTimeElapsed]);

  const percentElapsed = 100 - (timeRemaining / VIDEO_MAX_LENGTH) * 100;
  const pauseLabel = paused ? t('resume') : t('pause');

  return (
    <>
      <x.div display="flex" justifyContent="space-between" pt={4}>
        <x.div w="25%" display="flex" alignItems="center">
          <QuestionsVideoRecorderMediaInputSelectors
            activeVideoInputDeviceId={activeVideoInputDeviceId}
            activeAudioInputDeviceId={activeAudioInputDeviceId}
            onSelectVideoInputDevice={onSelectVideoInputDevice}
            onSelectAudioInputDevice={onSelectAudioInputDevice}
          />
        </x.div>
        <x.div
          display="flex"
          alignItems="center"
          spaceX="5"
          h={9}
          overflow="visible"
        >
          <x.div flex={1} display="flex" justifyContent="flex-end" w="5.625rem">
            <VideoRecorderTimeContainer disabled={!recording && !paused}>
              <VideoRecorderStatusIndicator
                recording={recording}
                paused={paused}
              />
              <Text
                as="span"
                variant="md-semibold"
                data-test="video-recorder-time"
              >
                {formatSecondsToMMSS(VIDEO_MAX_LENGTH - timeRemaining)}
              </Text>
            </VideoRecorderTimeContainer>
          </x.div>
          <x.div>
            <VideoRecordButton
              recording={!!(recording || paused || countdown)}
              onClick={onClickRecord}
              percentElapsed={percentElapsed}
              disabled={false}
              diameter={48}
              strokeWidth={3}
            />
          </x.div>
          <x.div
            flex={1}
            display="flex"
            justifyContent="flex-start"
            w="5.625rem"
          >
            <StyledButton
              paused={paused}
              variant="dark-no-border"
              label={pauseLabel}
              textTransform="uppercase"
              disabled={!recording && !paused}
              onClick={onClickPause}
            />
          </x.div>
        </x.div>
        <x.div w="25%" />
      </x.div>
    </>
  );
};
