import * as React from "react";

function SvgCamel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.056 34.375h-2.112a7.715 7.715 0 01-2.25-.331 6.917 6.917 0 01-1.369-.575 7.657 7.657 0 01-4.075-6.788v-1.925c0-.589-.146-1.169-.425-1.687a3.519 3.519 0 00-1.169-1.294A4.838 4.838 0 017.5 17.744v-2.025a4.85 4.85 0 013.563-4.663l1.062-.293a2.886 2.886 0 001-.488 2.986 2.986 0 001.219-1.956c.023-.168.034-.337.031-.507a4.069 4.069 0 014.063-4.062h3.125a4.069 4.069 0 014.062 4.063 3.013 3.013 0 001.25 2.456c.299.225.638.39 1 .487l1.069.294a4.844 4.844 0 013.556 4.669v2.025a4.85 4.85 0 01-2.156 4.025 3.58 3.58 0 00-1.594 2.987v1.925a7.663 7.663 0 01-4.081 6.788 6.88 6.88 0 01-1.363.569 7.708 7.708 0 01-2.25.337z"
        fill="#212121"
      />
      <path
        d="M18.438 5a2.812 2.812 0 00-2.813 2.813 4.243 4.243 0 01-1.769 3.469c-.42.315-.898.546-1.406.68l-1.056.294A3.593 3.593 0 008.75 15.72v2.025a3.587 3.587 0 001.6 2.987 4.857 4.857 0 012.15 4.025v1.925a6.413 6.413 0 003.419 5.688c.36.2.742.362 1.137.481a6.46 6.46 0 001.875.281h2.113c.635 0 1.267-.096 1.875-.281a5.694 5.694 0 001.131-.475 6.42 6.42 0 003.45-5.694v-1.925a4.832 4.832 0 012.15-4.025 3.53 3.53 0 001.175-1.294 3.57 3.57 0 00.425-1.693v-2.025a3.594 3.594 0 00-2.637-3.463l-1.063-.293a4.105 4.105 0 01-1.419-.713 4.25 4.25 0 01-1.694-2.744 4.662 4.662 0 01-.062-.694A2.812 2.812 0 0021.562 5h-3.125z"
        fill="#FFB74D"
      />
      <path
        d="M20.875 36.875h-1.75A4.056 4.056 0 0115 32.919a.625.625 0 01.931-.55c.357.198.734.357 1.125.475a6.46 6.46 0 001.875.281h2.113a6.458 6.458 0 001.875-.281 5.7 5.7 0 001.131-.475.625.625 0 01.931.55 4.056 4.056 0 01-4.106 3.956z"
        fill="#212121"
      />
      <path
        d="M16.481 33.975a2.888 2.888 0 002.644 1.65h1.75a2.888 2.888 0 002.644-1.65l-.213.069a7.708 7.708 0 01-2.25.33h-2.112a7.716 7.716 0 01-2.25-.33l-.213-.07z"
        fill="#A1887F"
      />
      <path
        d="M21.056 34.375h-2.113a7.712 7.712 0 01-2.25-.331.625.625 0 01-.443-.625v-6.544a3.75 3.75 0 017.5 0v6.569a.626.626 0 01-.444.625 7.71 7.71 0 01-2.25.306z"
        fill="#212121"
      />
      <path
        d="M17.5 32.962a6.5 6.5 0 001.444.163h2.112a6.5 6.5 0 001.444-.163v-6.087a2.5 2.5 0 00-5 0v6.087z"
        fill="#A1887F"
      />
      <path
        d="M26.506 11.4a.625.625 0 01-.369-.119 4.256 4.256 0 01-1.706-2.75.626.626 0 01.106-.469l1.675-2.337a4.432 4.432 0 012.394-1.694l1.313-.375a1.831 1.831 0 012.306 2.019l-.094.662A4.482 4.482 0 0129.9 9.594l-3.125 1.725a.624.624 0 01-.269.08z"
        fill="#212121"
      />
      <path
        d="M25.719 8.563c.144.55.44 1.048.856 1.437L29.3 8.481a3.218 3.218 0 001.606-2.343L31 5.474a.581.581 0 00-.731-.625l-1.313.375a3.18 3.18 0 00-1.718 1.219l-1.52 2.119z"
        fill="#FF9800"
      />
      <path
        d="M13.494 11.4a.625.625 0 01-.306-.081l-3.125-1.725A4.481 4.481 0 017.83 6.337l-.093-.662a1.831 1.831 0 012.306-2.019l1.312.375a4.432 4.432 0 012.394 1.694l1.675 2.331a.627.627 0 01.106.469c-.183 1.11-.799 2.1-1.712 2.756a.625.625 0 01-.325.119z"
        fill="#212121"
      />
      <path
        d="M9.575 4.837a.581.581 0 00-.568.663l.093.662a3.225 3.225 0 001.607 2.344L13.425 10c.42-.393.718-.899.857-1.456L12.769 6.43a3.188 3.188 0 00-1.719-1.25l-1.312-.375a.588.588 0 00-.163.031z"
        fill="#FF9800"
      />
      <path
        d="M21.056 34.375h-2.112a.625.625 0 010-1.25h2.112a.625.625 0 110 1.25zm-.487-10H19.43a2.575 2.575 0 01-1.6-.562l-1.25-1.013a1.913 1.913 0 01.081-3.05l.775-.55a4.437 4.437 0 015.163 0l.775.55a1.912 1.912 0 01.081 3.05l-1.25 1.013c-.464.37-1.043.57-1.637.562z"
        fill="#212121"
      />
      <path
        d="M20 19.625a3.182 3.182 0 00-1.875.625l-.775.55a.663.663 0 000 1.056l1.25 1.013c.233.185.522.286.82.287h1.137c.297 0 .586-.102.818-.287l1.25-1.013a.663.663 0 000-1.056l-.75-.581A3.188 3.188 0 0020 19.625z"
        fill="#795548"
      />
      <path
        d="M20 34.375a.624.624 0 01-.625-.625V30a.624.624 0 111.25 0v3.75a.624.624 0 01-.625.625zM18.75 23.75a.624.624 0 01-.625-.625v-1.25a.624.624 0 111.25 0v1.25a.624.624 0 01-.625.625zm2.5 0a.624.624 0 01-.625-.625v-1.25a.624.624 0 111.25 0v1.25a.624.624 0 01-.625.625zm-10.625-5.625A.624.624 0 0110 17.5v-1.875a.624.624 0 111.25 0V17.5a.624.624 0 01-.625.625zm18.75 0a.624.624 0 01-.625-.625v-1.875a.624.624 0 111.25 0V17.5a.624.624 0 01-.625.625z"
        fill="#212121"
      />
    </svg>
  );
}

export default SvgCamel;
