import { MyValue } from '@frond/shared';
import { styled } from '@xstyled/styled-components';

import { Text } from '../Text';
import { Composer } from './Composer';

const Wrapper = styled.div`
  position: relative;
  border: default;
  border-color: gray.200;
  border-radius: md;

  &:hover {
    border-color: brand.200;
  }

  &:focus-within {
    border-color: brand.300;
  }
`;

export const ComposerField = ({
  label,
  onChange,
  initialValue,
  placeholder,
  id,
}: {
  label: string;
  onChange: (val: MyValue) => void;
  initialValue: MyValue | null | undefined;
  placeholder: string;
  id: string;
}) => {
  return (
    <Wrapper>
      <Text
        variant="sm"
        color="gray.300"
        pt={3}
        pl={4}
        position="absolute"
        top={0}
        pointerEvents="none"
      >
        {label}
      </Text>

      <Composer
        p={4}
        pt={9}
        position="relative"
        id={id}
        onChange={onChange}
        initialValue={initialValue ? (initialValue as MyValue) : undefined}
        placeholder={placeholder}
      />
    </Wrapper>
  );
};
