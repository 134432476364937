import * as React from "react";

function SvgLion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 36.875a.625.625 0 01-.425-.169l-9.012-8.412-3.338 2.225a.624.624 0 01-.975-.388l-1.356-6.287A21 21 0 017.569 8.256 10.625 10.625 0 0118.994 3.5L20 3.75l1.006-.231a10.625 10.625 0 0111.42 4.756A21 21 0 0135.1 23.863l-1.35 6.268a.625.625 0 01-.956.388l-3.356-2.225-9.013 8.412a.625.625 0 01-.425.169z"
        fill="#212121"
      />
      <path
        d="M10.625 26.875c.158 0 .31.06.425.169l8.95 8.35 8.95-8.35a.625.625 0 01.775-.063l2.988 1.994 1.175-5.394a19.743 19.743 0 00-2.5-14.662 9.375 9.375 0 00-10.1-4.2l-1.15.28a.625.625 0 01-.282 0l-1.15-.262a9.375 9.375 0 00-10.075 4.2 19.744 19.744 0 00-2.5 14.663l1.175 5.394L10.294 27a.626.626 0 01.331-.125z"
        fill="#FF8F00"
      />
      <path
        d="M22.338 28.125a2.662 2.662 0 01-2.213-1.181L20 26.756l-.125.188a2.664 2.664 0 01-2.212 1.181 1.356 1.356 0 01-.25 0 2.55 2.55 0 01-1.638-.775A2.587 2.587 0 0115 25.475a10.444 10.444 0 00-2.643-6.95 4.9 4.9 0 01-1.25-3.256c-.002-.23.015-.46.05-.688a4.725 4.725 0 01.774-2.018 4.763 4.763 0 015.882-1.707L20 11.82l2.188-.975a4.75 4.75 0 015.887 1.712 4.87 4.87 0 01-.406 5.938A10.481 10.481 0 0025 25.462a2.6 2.6 0 01-.787 1.875 2.537 2.537 0 01-1.625.788 1.356 1.356 0 01-.25 0z"
        fill="#212121"
      />
      <path
        d="M15.888 11.688a3.494 3.494 0 00-1.669.418 3.549 3.549 0 00-1.25 1.144 3.5 3.5 0 00-.575 1.5 3.214 3.214 0 00-.037.506c.002.888.329 1.743.918 2.407a11.7 11.7 0 012.975 7.8 1.357 1.357 0 00.413.987c.23.244.546.392.881.413h.113a1.437 1.437 0 001.187-.625l.625-.97a.625.625 0 011.044 0l.625.97a1.414 1.414 0 001.175.625h.125c.327-.021.635-.164.862-.4a1.374 1.374 0 00.45-1 11.731 11.731 0 012.956-7.776l.032-.03a3.619 3.619 0 00.294-4.376 3.531 3.531 0 00-4.344-1.25L20.25 13.12a.625.625 0 01-.506 0L17.3 12.03a3.507 3.507 0 00-1.412-.344z"
        fill="#FFECB3"
      />
      <path
        d="M20 30c-1.75 0-3.125-1.1-3.125-2.5a.625.625 0 01.492-.622c.094-.02.19-.02.283.003a1.437 1.437 0 001.188-.625l.625-.969a.625.625 0 011.043 0l.625.97a1.414 1.414 0 001.175.624.626.626 0 01.82.619c0 1.4-1.376 2.5-3.126 2.5z"
        fill="#212121"
      />
      <path
        d="M18.32 28.044a2.35 2.35 0 003.362 0 2.661 2.661 0 01-1.556-1.1L20 26.756l-.125.188c-.366.546-.92.937-1.557 1.1z"
        fill="#FFD54F"
      />
      <path
        d="M21.25 24.375a.625.625 0 01-.519-.281.9.9 0 00-1.462 0 .625.625 0 01-1.038-.694 2.125 2.125 0 013.538 0 .625.625 0 01-.519.975zM16.875 17.5a.626.626 0 01-.2-.031l-1.875-.625a.625.625 0 11.394-1.188l1.875.625a.625.625 0 01-.194 1.219zm6.875 0a.625.625 0 01-.2-1.219l1.875-.625a.626.626 0 01.394 1.188l-1.875.625a.625.625 0 01-.194.031zM11.77 15.294a.624.624 0 01-.475-.219l-1.575-1.831a1.875 1.875 0 011.931-3l2.444.712a.625.625 0 01.119 1.15 3.55 3.55 0 00-1.25 1.144 3.5 3.5 0 00-.575 1.5.625.625 0 01-.625.544h.006z"
        fill="#212121"
      />
      <path
        d="M11.113 11.413a.627.627 0 00-.5.287.588.588 0 00.05.725l.812.944A4.75 4.75 0 0112.55 11.8l-1.25-.362a.675.675 0 00-.187-.025z"
        fill="#FFCA28"
      />
      <path
        d="M28.231 15.294a.625.625 0 01-.625-.544 3.5 3.5 0 00-.575-1.5 3.55 3.55 0 00-1.25-1.144.625.625 0 01.12-1.15l2.443-.712a1.875 1.875 0 011.931 3L28.7 15.075a.625.625 0 01-.469.219z"
        fill="#212121"
      />
      <path
        d="M27.456 11.806c.233.227.442.476.625.744.175.26.323.536.444.825l.812-.944a.625.625 0 00-.625-.987l-1.256.362z"
        fill="#FFCA28"
      />
      <path
        d="M10.625 28.125A.624.624 0 0110 27.5v-4.375a.624.624 0 111.25 0V27.5a.624.624 0 01-.625.625zm18.75 0a.624.624 0 01-.625-.625v-4.375a.624.624 0 111.25 0V27.5a.624.624 0 01-.625.625zm-9.375-15a.624.624 0 01-.625-.625V5a.625.625 0 111.25 0v7.5a.624.624 0 01-.625.625z"
        fill="#212121"
      />
    </svg>
  );
}

export default SvgLion;
