import * as React from 'react';
import { SVGProps } from 'react';

const SvgMailbox2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7 4.5h10a5 5 0 0 1 5 5V20a1 1 0 0 1-1 1H7V4.5Z" fill="#007AD9" />
    <path
      d="M3 20.5a.5.5 0 0 1-.5-.5V9.5C2.5 7.019 4.519 5 7 5s4.5 2.019 4.5 4.5V20a.5.5 0 0 1-.5.5H3Z"
      fill="url(#type=mailbox-2_svg__a)"
    />
    <path d="M15 1.5h-1V9h1V1.5Z" fill="#CF1928" />
    <path
      d="M16.5 3H14V1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5Z"
      fill="#CF1928"
    />
    <path d="M15 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill="#D9414F" />
    <path
      d="m6.281 15.844 10.688-4.247v7.81a.594.594 0 0 1-.594.593H6.281v-4.156Z"
      fill="url(#type=mailbox-2_svg__b)"
    />
    <path
      d="M5 11.596v7.81c0 .329.266.594.593.594h11.282a.587.587 0 0 0 .381-.142L5 11.596Z"
      fill="url(#type=mailbox-2_svg__c)"
    />
    <path
      opacity={0.05}
      d="M5 11.39h12.468v.503l-5.142 3.993a1.782 1.782 0 0 1-2.184 0L5 11.893v-.503Z"
      fill="currentColor"
    />
    <path
      opacity={0.07}
      d="M5 11.242h12.468v.502l-5.353 3.893a1.48 1.48 0 0 1-1.761 0L5 11.744v-.502Z"
      fill="currentColor"
    />
    <path
      d="M5.593 10.5h11.282c.328 0 .593.265.593.594v.503l-5.566 3.792a1.187 1.187 0 0 1-1.336 0L5 11.596v-.502c0-.329.266-.594.593-.594Z"
      fill="#FABB62"
    />
    <path
      d="M7 5.5c2.206 0 4 1.795 4 4V20H3V9.5c0-2.205 1.795-4 4-4Zm0-1a5 5 0 0 0-5 5V20a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V9.5a5 5 0 0 0-5-5Z"
      fill="url(#type=mailbox-2_svg__d)"
    />
    <path
      d="M11 21H.5a.5.5 0 0 1 0-1H12a1 1 0 0 1-1 1Z"
      fill="url(#type=mailbox-2_svg__e)"
    />
    <path d="M19 10h-7v10.5h7V10Z" fill="#007AD9" />
    <path
      opacity={0.3}
      d="M11 10.5H9V20h2v-9.5Z"
      fill="url(#type=mailbox-2_svg__f)"
    />
    <defs>
      <linearGradient
        id="type=mailbox-2_svg__a"
        x1={-13.537}
        y1={33.197}
        x2={9.463}
        y2={11.572}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.266} stopColor="#0071D4" />
        <stop offset={0.446} stopColor="#006FD2" />
        <stop offset={0.595} stopColor="#0068CA" />
        <stop offset={0.712} stopColor="#005DBD" />
        <stop offset={0.793} stopColor="#004DAA" />
        <stop offset={0.884} stopColor="#003891" />
        <stop offset={1} stopColor="#002B82" />
      </linearGradient>
      <linearGradient
        id="type=mailbox-2_svg__b"
        x1={6.356}
        y1={15.798}
        x2={16.948}
        y2={15.798}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7AB40" />
        <stop offset={0.32} stopColor="#F4A73C" />
        <stop offset={0.671} stopColor="#ED992F" />
        <stop offset={1} stopColor="#E2861D" />
      </linearGradient>
      <linearGradient
        id="type=mailbox-2_svg__c"
        x1={5.887}
        y1={10.188}
        x2={12}
        y2={21.294}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBA84B" />
        <stop offset={1} stopColor="#D97218" />
      </linearGradient>
      <linearGradient
        id="type=mailbox-2_svg__d"
        x1={2}
        y1={12.75}
        x2={12}
        y2={12.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42A3F2" />
        <stop offset={1} stopColor="#42A4EB" />
      </linearGradient>
      <linearGradient
        id="type=mailbox-2_svg__e"
        x1={0}
        y1={20.5}
        x2={12}
        y2={20.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42A3F2" />
        <stop offset={1} stopColor="#42A4EB" />
      </linearGradient>
      <linearGradient
        id="type=mailbox-2_svg__f"
        x1={17.229}
        y1={15.25}
        x2={8.75}
        y2={15.25}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.702} />
        <stop offset={0.936} stopOpacity={0.098} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgMailbox2;
