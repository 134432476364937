import * as React from "react";

function SvgChevronLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.414 12l7.293 7.293-1.414 1.414L6.586 12l8.707-8.707 1.414 1.414L9.414 12z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgChevronLeft;
