import { x } from '@xstyled/styled-components';
import { getWeekOfMonth } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { ComposerField } from '../../../common/components/Composer/ComposerField';
import { Icon } from '../../../common/components/Icon';
import { Input } from '../../../common/components/Input';
import { Select } from '../../../common/components/Select';
import { extractError } from '../../../hello/utils/form.utils';
import { EventFormData } from './EventForm';
import { EventFormDateTime } from './EventFormDateTime';
import { EventLocation } from './EventLocation';

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const EventFormFields = ({
  variant,
}: {
  variant: 'create' | 'edit';
}) => {
  const form = useFormContext<EventFormData>();
  const { t } = useTranslation('events');
  const recurringOccurrencesLimit = form.watch('recurringOccurrencesLimit');
  const recurringCronExpression = form.watch('recurringCronExpression');
  const startDate = form.watch('startAt');
  const startDateAsDate = new Date(startDate);
  const dayOfWeek = startDateAsDate.getDay();
  const currentDay = days[dayOfWeek];
  const weekOfMonth = getWeekOfMonth(startDateAsDate);
  // const dayOfMonth = startDateAsDate.getDate();

  useEffect(() => {
    const startDateAsDate = new Date(startDate);

    if (recurringCronExpression && recurringCronExpression !== 'never') {
      const parsedCronExpression = recurringCronExpression.split(' ');
      const newMinutes = startDateAsDate.getMinutes().toString();
      const newHours = startDateAsDate.getHours().toString();
      const newDayOfWeek = startDateAsDate.getDay().toString();
      const newWeekOfMonth = getWeekOfMonth(startDateAsDate).toString();

      // Check if the recurringCronExpression really needs to be updated
      if (
        parsedCronExpression[0] !== newMinutes ||
        parsedCronExpression[1] !== newHours ||
        (parsedCronExpression[4] !== newDayOfWeek &&
          parsedCronExpression[4] !== `${newDayOfWeek}#${newWeekOfMonth}` &&
          parsedCronExpression[4] !== '*')
      ) {
        parsedCronExpression[0] = newMinutes;
        parsedCronExpression[1] = newHours;
        if (parsedCronExpression[4] !== '*') {
          if (parsedCronExpression[4].includes('#')) {
            parsedCronExpression[4] = `${newDayOfWeek}#${newWeekOfMonth}`;
          } else {
            parsedCronExpression[4] = newDayOfWeek;
          }
        }

        form.setValue(
          'recurringCronExpression',
          parsedCronExpression.join(' '),
          {
            shouldValidate: true,
            shouldDirty: true,
          }
        );
      }
    }
  }, [form, recurringCronExpression, startDate]);

  return (
    <x.div spaceY={2}>
      <EventFormDateTime />
      <Input
        {...form.register('title')}
        label={t('events.name_field_label')}
        placeholder={t('events.name_field_placeholder')}
        error={extractError<EventFormData>(form.formState, 'title')}
        fullWidth
      />
      <ComposerField
        label={t('events.description_field_label')}
        onChange={(val) => {
          form.setValue('description', val, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }}
        initialValue={form.formState.defaultValues?.description}
        placeholder={t('events.description_field_placeholder')}
        id={'event-form'}
      />
      {!(variant === 'edit' && recurringCronExpression === 'never') ? (
        <>
          <Select
            h={12}
            options={[
              {
                label: t('events.does_not_repeat'),
                value: 'never',
              },
              {
                label: t('events.every_day'),
                value: `${startDateAsDate.getMinutes()} ${startDateAsDate.getHours()} * * *`,
              },
              {
                label: t('events.every_week', {
                  weekDay: currentDay,
                }),
                value: `${startDateAsDate.getMinutes()} ${startDateAsDate.getHours()} * * ${dayOfWeek}`,
              },
              {
                label: t('events.every_other_week', {
                  weekDay: currentDay,
                }),
                value: `${startDateAsDate.getMinutes()} ${startDateAsDate.getHours()} * * ${dayOfWeek}#1`,
              },
              {
                label: t('events.every_month', {
                  weekDay: currentDay,
                  nth:
                    weekOfMonth === 1
                      ? '1st'
                      : weekOfMonth === 2
                        ? '2nd'
                        : weekOfMonth === 3
                          ? '3rd'
                          : '4th',
                }),
                value: `${startDateAsDate.getMinutes()} ${startDateAsDate.getHours()} * * ${dayOfWeek}#${weekOfMonth}`,
              },
            ]}
            value={recurringCronExpression}
            name="recurringCronExpression"
            onChange={(value) => {
              form.setValue('recurringCronExpression', value, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
          />
          {recurringCronExpression !== 'never' && (
            <Select
              h={12}
              options={[
                {
                  label: t('events.does_not_end'),
                  value: 'never',
                },
                ...[...new Array(8)].map((_, i) => ({
                  label: t('events.ends_after', {
                    count: i + 1,
                  }),
                  value: (i + 1).toString(),
                })),
              ]}
              value={recurringOccurrencesLimit}
              name="recurringOccurrencesLimit"
              onChange={(value) => {
                form.setValue('recurringOccurrencesLimit', value, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
            />
          )}
        </>
      ) : null}

      <EventLocation />
      <Input
        {...form.register('url')}
        icon={<Icon name="link" color="gray.300" />}
        placeholder={t('events.link_field_placeholder')}
        error={extractError<EventFormData>(form.formState, 'url')}
        fullWidth
      />
    </x.div>
  );
};
