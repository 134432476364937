import * as React from 'react';
import { SVGProps } from 'react';

const SvgGroups = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M6.25 2.5A2.261 2.261 0 0 0 4 4.75v8.75h-.25a.75.75 0 0 0-.75.75v4a3.262 3.262 0 0 0 3.25 3.25h11.5A3.262 3.262 0 0 0 21 18.25v-4a.75.75 0 0 0-.75-.75H20V4.75a2.261 2.261 0 0 0-2.25-2.25H6.25Zm0 1.5h11.5c.423 0 .75.327.75.75v8.75h-3.54a.75.75 0 0 0-.75.75c0 .804-.29 1.32-.706 1.688a2.343 2.343 0 0 1-1.504.563 2.343 2.343 0 0 1-1.504-.563c-.417-.368-.706-.884-.706-1.687a.75.75 0 0 0-.75-.75H5.5V4.75c0-.424.327-.75.75-.75Zm2 2.5a.75.75 0 1 0 0 1.5h4.5a.75.75 0 1 0 0-1.5h-4.5Zm7.5 0a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM8.25 10a.749.749 0 1 0 0 1.5h4.5a.751.751 0 1 0 0-1.5h-4.5Zm7.5 0a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM4.5 15h.127c.08.014.163.014.243 0h3.577c.166.838.507 1.577 1.057 2.063a3.782 3.782 0 0 0 2.496.938c.874 0 1.78-.306 2.496-.938.55-.486.891-1.225 1.057-2.062h3.574c.08.013.163.013.243 0h.13v3.25A1.74 1.74 0 0 1 17.75 20H6.25a1.74 1.74 0 0 1-1.75-1.75V15Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgGroups;
