import { useTranslation } from 'next-i18next';
import { FC, useMemo } from 'react';

import {
  GroupTypes,
  GroupWithMembersFragment,
} from '../../../../../generated/types-and-hooks';
import { useOrganization } from '../../../auth/hooks/useOrganization';
import { useIsViewerAdmin } from '../../../auth/hooks/useViewer';
import { ModalProps } from '../../../common/components/Modal';
import {
  TabbedModal,
  TabDefinition,
} from '../../../common/components/TabbedModal';
import { useGroupMembership } from '../../hooks/useGroupMembership';
import { GroupEditAboutTab } from './GroupEditAbout/GroupEditAboutTab';
import { GroupEditMembersTab } from './GroupEditMembers/GroupEditMembersTab';
import { GroupEditSettingsTab } from './GroupEditSettings/GroupEditSettingsTab';

export type GroupEditModalProps = Pick<
  ModalProps,
  'ariaLabel' | 'isOpen' | 'onDismiss'
> & {
  group: GroupWithMembersFragment;
  defaultValue?: 'About' | 'Members' | 'Settings';
};

export const GroupEditModal: FC<GroupEditModalProps> = ({
  ariaLabel,
  isOpen,
  onDismiss,
  group,
  defaultValue,
}) => {
  const { t } = useTranslation(['groups']);
  const localizedDefaultValue =
    defaultValue &&
    t(
      (
        {
          About: 'groups.edit_tab_about',
          Members:
            group.groupType === GroupTypes.Course
              ? 'groups.edit_tab_participants'
              : 'groups.edit_tab_members',
          Settings: 'groups.edit_tab_settings',
        } as const
      )[defaultValue]
    );

  const { organization } = useOrganization();
  const admin = useIsViewerAdmin(organization);
  const { userCanEdit } = useGroupMembership({ group, organization });

  const tabs = useMemo(() => {
    if (userCanEdit || admin) {
      return {
        [t('groups.edit_tab_about')]: <GroupEditAboutTab group={group} />,
        [group.groupType === GroupTypes.Course
          ? t('groups.edit_tab_participants')
          : t('groups.edit_tab_members')]: (
          <GroupEditMembersTab group={group} />
        ),
        ...([
          GroupTypes.Regular,
          GroupTypes.General,
          GroupTypes.Welcome,
        ].includes(group.groupType) && {
          [t('groups.edit_tab_settings')]: (
            <GroupEditSettingsTab group={group} />
          ),
        }),
      };
    }

    return {
      [t('groups.edit_tab_members')]: <GroupEditMembersTab group={group} />,
    };
  }, [group, userCanEdit, admin, t]) as TabDefinition;

  return (
    <TabbedModal
      h={{
        _: '100%',
        sm: '770px',
      }}
      ariaLabel={ariaLabel}
      isOpen={isOpen}
      onDismiss={onDismiss}
      title={group.name}
      tabs={tabs}
      defaultValue={localizedDefaultValue || t('groups.edit_tab_about')}
    />
  );
};
