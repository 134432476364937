import * as React from "react";

function SvgColorPicker(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="color-picker_svg__abc"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <rect width={24} height={24} rx={5.333} fill="#C4C4C4" />
      </mask>
      <g mask="url(#color-picker_svg__abc)">
        <path d="M11.702-3.495H12l-.162 15.494-.136-15.495z" fill="red" />
        <path
          d="M11.973-3.496c.1 0 .198.003.298.005L11.838 12l.135-15.495z"
          fill="#FF0400"
        />
        <path d="M12.243-3.489l.298.01-.703 15.48.405-15.49z" fill="#FF0900" />
        <path d="M12.514-3.48l.297.016-.973 15.465.676-15.48z" fill="#FF0D00" />
        <path d="M12.784-3.467l.297.021-1.243 15.445.946-15.466z" fill="#F10" />
        <path
          d="M13.054-3.446l.296.027-1.512 15.421 1.216-15.448z"
          fill="#FF1500"
        />
        <path
          d="M13.323-3.424c.099.01.197.02.296.032L11.838 12l1.485-15.424z"
          fill="#FF1A00"
        />
        <path
          d="M13.592-3.398c.099.01.197.023.295.036l-2.05 15.36 1.755-15.396z"
          fill="#FF1E00"
        />
        <path d="M13.86-3.366l.295.041-2.317 15.322L13.86-3.366z" fill="#F20" />
        <path
          d="M14.128-3.328c.098.015.196.03.294.047l-2.584 15.278 2.29-15.325z"
          fill="#FF2600"
        />
        <path
          d="M14.395-3.285c.098.016.196.034.293.052l-2.85 15.231 2.557-15.283z"
          fill="#FF2B00"
        />
        <path
          d="M14.662-3.236c.097.018.195.037.292.057L11.838 12l2.824-15.236z"
          fill="#FF2F00"
        />
        <path d="M14.927-3.19l.291.063-3.38 15.122 3.09-15.184z" fill="#F30" />
        <path
          d="M15.192-3.128l.29.067L11.838 12l3.354-15.128z"
          fill="#FF3700"
        />
        <path
          d="M15.455-3.07c.097.023.193.047.289.072l-3.906 14.995L15.455-3.07z"
          fill="#FF3C00"
        />
        <path
          d="M15.718-3.005c.096.025.191.05.287.077l-4.167 14.925 3.88-15.002z"
          fill="#FF4000"
        />
        <path
          d="M15.979-2.936c.095.026.19.054.286.082l-4.427 14.85 4.14-14.932z"
          fill="#F40"
        />
        <path
          d="M16.239-2.86c.095.028.19.057.284.087l-4.685 14.77 4.4-14.857z"
          fill="#FF4800"
        />
        <path
          d="M16.497-2.781c.095.03.19.06.283.092l-4.942 14.686 4.66-14.778z"
          fill="#FF4D00"
        />
        <path
          d="M16.755-2.694c.094.031.187.063.28.097L11.839 12l4.917-14.694z"
          fill="#FF5100"
        />
        <path
          d="M17.01-2.611c.094.033.187.067.28.102l-5.452 14.505L17.01-2.611z"
          fill="#F50"
        />
        <path
          d="M17.265-2.514c.092.035.185.07.277.107l-5.704 14.408 5.427-14.515z"
          fill="#FF5900"
        />
        <path
          d="M17.517-2.42l.276.112-5.955 14.306L17.517-2.42z"
          fill="#FF5E00"
        />
        <path
          d="M17.768-2.318c.091.038.183.076.273.116l-6.203 14.2 5.93-14.316z"
          fill="#FF6200"
        />
        <path d="M18.017-2.214l.271.121-6.45 14.09 6.179-14.21z" fill="#F60" />
        <path
          d="M18.264-2.105c.09.04.18.083.27.125l-6.696 13.975 6.426-14.1z"
          fill="#FF6A00"
        />
        <path
          d="M18.509-1.99l.267.13-6.938 13.856 6.67-13.986z"
          fill="#FF6F00"
        />
        <path
          d="M18.752-1.867c.089.044.177.09.265.135L11.837 12l6.915-13.867z"
          fill="#FF7300"
        />
        <path
          d="M18.993-1.746c.088.046.175.092.262.14l-7.417 13.604 7.155-13.744z"
          fill="#F70"
        />
        <path
          d="M19.232-1.618c.087.047.174.096.26.145L11.838 12l7.394-13.618z"
          fill="#FF7B00"
        />
        <path
          d="M19.468-1.491c.087.049.172.098.258.149l-7.888 13.337 7.63-13.486z"
          fill="#FF8000"
        />
        <path
          d="M19.702-1.347c.086.05.17.102.255.154l-8.12 13.198 7.865-13.352z"
          fill="#FF8400"
        />
        <path
          d="M19.934-1.21c.085.052.169.105.252.158l-8.348 13.054L19.934-1.21z"
          fill="#F80"
        />
        <path
          d="M20.164-1.069l.249.163L11.838 12l8.326-13.069z"
          fill="#FF8C00"
        />
        <path
          d="M20.39-.92c.083.054.165.11.247.166L11.837 12 20.39-.921z"
          fill="#FF9100"
        />
        <path
          d="M20.615-.769c.081.056.163.113.243.171l-9.02 12.6 8.777-12.77z"
          fill="#FF9500"
        />
        <path
          d="M20.836-.617c.08.057.161.116.24.175l-9.238 12.44L20.836-.617z"
          fill="#F90"
        />
        <path
          d="M21.055-.455c.08.06.159.119.237.18L11.838 12 21.055-.455z"
          fill="#FF9D00"
        />
        <path
          d="M21.27-.296c.08.06.158.122.235.184l-9.667 12.11L21.27-.296z"
          fill="#FFA200"
        />
        <path
          d="M21.484-.126c.078.062.155.124.231.187l-9.877 11.94L21.484-.126z"
          fill="#FFA600"
        />
        <path
          d="M21.694.044c.077.063.153.127.228.191L11.838 12.001 21.694.044z"
          fill="#FA0"
        />
        <path
          d="M21.901.214c.076.064.15.13.225.195L11.838 11.996 21.9.214z"
          fill="#FFAE00"
        />
        <path
          d="M22.105.39c.075.066.148.133.221.2L11.838 11.996 22.106.391z"
          fill="#FFB300"
        />
        <path
          d="M22.306.573c.074.067.146.135.218.203L11.838 11.997 22.307.573z"
          fill="#FFB700"
        />
        <path
          d="M22.504.759c.072.068.143.137.214.207l-10.88 11.033L22.504.759z"
          fill="#FB0"
        />
        <path
          d="M22.504.759c.072.068.143.137.214.207l-10.88 11.033L22.504.759z"
          fill="#FB0"
        />
        <path d="M22.699.947l.21.21L11.838 12 22.698.947z" fill="#FFBF00" />
        <path
          d="M22.89 1.14c.07.071.138.143.206.215L11.838 12 22.89 1.141z"
          fill="#FFC400"
        />
        <path
          d="M23.078 1.33c.068.072.136.144.203.218L11.838 11.996 23.078 1.33z"
          fill="#FFC800"
        />
        <path
          d="M23.262 1.532l.2.221-11.624 10.248L23.262 1.532z"
          fill="#FC0"
        />
        <path
          d="M23.443 1.73l.195.225-11.8 10.043L23.443 1.731z"
          fill="#FFD000"
        />
        <path
          d="M23.62 1.938c.065.076.129.152.192.229l-11.974 9.835L23.62 1.938z"
          fill="#FFD500"
        />
        <path
          d="M23.794 2.143c.064.076.126.153.188.23L11.838 12l11.957-9.856z"
          fill="#FFD900"
        />
        <path
          d="M23.965 2.352l.183.235-12.31 9.411 12.127-9.646z"
          fill="#FD0"
        />
        <path
          d="M24.131 2.565l.18.238-12.473 9.195L24.13 2.565z"
          fill="#FFE100"
        />
        <path
          d="M24.294 2.782l.175.24L11.838 12l12.456-9.217z"
          fill="#FFE600"
        />
        <path
          d="M24.453 2.999c.058.08.114.162.17.244l-12.785 8.754 12.615-8.998z"
          fill="#FFEA00"
        />
        <path
          d="M24.608 3.223c.056.081.112.164.166.246L11.838 12l12.77-8.776z"
          fill="#FE0"
        />
        <path
          d="M24.76 3.447c.054.082.108.165.161.25l-13.083 8.302 12.921-8.552z"
          fill="#FFF200"
        />
        <path
          d="M24.907 3.673c.053.083.105.167.157.252l-13.226 8.073 13.069-8.325z"
          fill="#FFF700"
        />
        <path
          d="M25.05 3.902c.052.084.103.17.153.255l-13.365 7.841L25.05 3.902z"
          fill="#FFFB00"
        />
        <path
          d="M25.19 4.136c.05.086.1.172.148.258l-13.5 7.607 13.351-7.865z"
          fill="#FF0"
        />
        <path
          d="M25.325 4.368l.143.26-13.63 7.37 13.487-7.63z"
          fill="#FBFF00"
        />
        <path
          d="M25.456 4.608l.139.263-13.757 7.131 13.618-7.394z"
          fill="#F7FF00"
        />
        <path
          d="M25.582 4.844c.046.088.091.177.135.266L11.837 12l13.745-7.156z"
          fill="#F2FF00"
        />
        <path
          d="M25.705 5.083c.045.089.088.178.13.268l-13.997 6.646 13.867-6.914z"
          fill="#EF0"
        />
        <path
          d="M25.824 5.33l.125.27-14.111 6.401 13.986-6.67z"
          fill="#EAFF00"
        />
        <path
          d="M25.938 5.574c.041.09.082.181.12.272L11.839 12l14.1-6.426z"
          fill="#E6FF00"
        />
        <path
          d="M26.048 5.82c.04.091.078.182.116.274l-14.326 5.905 14.21-6.179z"
          fill="#E1FF00"
        />
        <path
          d="M26.154 6.07c.038.09.075.183.111.275l-14.427 5.654 14.316-5.93z"
          fill="#DF0"
        />
        <path
          d="M26.255 6.318c.037.093.072.185.107.278l-14.524 5.402 14.417-5.68z"
          fill="#D9FF00"
        />
        <path
          d="M26.352 6.571c.035.093.069.186.101.28l-14.615 5.147L26.352 6.57z"
          fill="#D5FF00"
        />
        <path
          d="M26.445 6.824c.033.093.065.187.096.281l-14.703 4.891 14.607-5.172z"
          fill="#D0FF00"
        />
        <path
          d="M26.533 7.08c.031.094.062.189.091.283l-14.786 4.634L26.533 7.08z"
          fill="#CF0"
        />
        <path d="M26.616 7.34l.087.285L11.838 12l14.778-4.66z" fill="#C8FF00" />
        <path
          d="M26.695 7.6c.028.096.056.191.082.287l-14.94 4.115L26.696 7.6z"
          fill="#C4FF00"
        />
        <path
          d="M26.77 7.86c.026.096.052.192.076.288l-15.008 3.854L26.77 7.86z"
          fill="#BFFF00"
        />
        <path
          d="M26.84 8.117c.025.096.049.192.072.289l-15.074 3.591 15.002-3.88z"
          fill="#BF0"
        />
        <path
          d="M26.905 8.38c.023.097.046.194.067.29l-15.134 3.328 15.067-3.617z"
          fill="#B7FF00"
        />
        <path
          d="M26.966 8.646l.062.292L11.838 12l15.128-3.354z"
          fill="#B3FF00"
        />
        <path
          d="M27.022 8.912c.02.097.039.194.057.292L11.838 12l15.184-3.09z"
          fill="#AEFF00"
        />
        <path
          d="M27.074 9.176c.018.097.035.195.051.293l-15.287 2.53 15.236-2.823z"
          fill="#AF0"
        />
        <path
          d="M27.12 9.443c.017.098.033.196.047.294l-15.33 2.264 15.284-2.558z"
          fill="#A6FF00"
        />
        <path
          d="M27.163 9.707c.015.098.029.196.041.294l-15.366 1.996 15.325-2.29z"
          fill="#A2FF00"
        />
        <path
          d="M27.2 9.978c.014.098.026.196.037.295L11.837 12l15.364-2.022z"
          fill="#9EFF00"
        />
        <path
          d="M27.234 10.247c.01.098.021.197.03.296L11.839 12l15.396-1.754z"
          fill="#9F0"
        />
        <path
          d="M27.262 10.512c.01.099.018.197.026.296l-15.45 1.19 15.424-1.486z"
          fill="#95FF00"
        />
        <path
          d="M27.286 10.787l.02.297-15.468.919 15.448-1.216z"
          fill="#91FF00"
        />
        <path
          d="M27.305 11.05l.015.298-15.482.648 15.467-.946z"
          fill="#8CFF00"
        />
        <path d="M27.319 11.325l.01.297-15.491.379 15.48-.676z" fill="#8F0" />
        <path
          d="M27.328 11.592c.003.1.004.198.005.297l-15.495.109 15.49-.406z"
          fill="#84FF00"
        />
        <path
          d="M27.333 11.867v.297l-15.495-.162 15.495-.135z"
          fill="#80FF00"
        />
        <path
          d="M27.333 12.136l-.006.297L11.837 12l15.496.136z"
          fill="#7BFF00"
        />
        <path
          d="M27.328 12.406c-.002.099-.006.198-.01.297L11.837 12l15.49.406z"
          fill="#7F0"
        />
        <path
          d="M27.319 12.676l-.016.297L11.838 12l15.48.676z"
          fill="#73FF00"
        />
        <path
          d="M27.304 12.946a14.74 14.74 0 01-.02.297L11.837 12l15.466.946z"
          fill="#6FFF00"
        />
        <path
          d="M27.286 13.216a15.45 15.45 0 01-.027.296L11.838 12l15.448 1.216z"
          fill="#6AFF00"
        />
        <path
          d="M27.262 13.485l-.031.296-15.393-1.78 15.424 1.484z"
          fill="#6F0"
        />
        <path
          d="M27.234 13.754l-.037.296L11.837 12l15.397 1.754z"
          fill="#62FF00"
        />
        <path
          d="M27.2 14.023c-.012.098-.026.196-.04.294L11.837 12 27.2 14.023z"
          fill="#5EFF00"
        />
        <path
          d="M27.163 14.29c-.015.099-.03.197-.047.294L11.838 12l15.325 2.29z"
          fill="#59FF00"
        />
        <path
          d="M27.12 14.558c-.015.098-.033.195-.051.293L11.838 12l15.283 2.557z"
          fill="#5F0"
        />
        <path
          d="M27.074 14.824c-.018.098-.037.195-.057.292L11.837 12l15.237 2.824z"
          fill="#51FF00"
        />
        <path
          d="M27.022 15.09c-.02.097-.04.194-.062.29L11.838 12l15.184 3.09z"
          fill="#4DFF00"
        />
        <path
          d="M26.966 15.354c-.021.097-.044.194-.067.29L11.838 12l15.128 3.354z"
          fill="#48FF00"
        />
        <path
          d="M26.905 15.618l-.072.288L11.838 12l15.067 3.618z"
          fill="#4F0"
        />
        <path
          d="M26.84 15.88c-.025.096-.05.192-.077.287L11.838 12l15.002 3.88z"
          fill="#40FF00"
        />
        <path
          d="M26.77 16.141l-.082.286L11.838 12l14.932 4.141z"
          fill="#3CFF00"
        />
        <path
          d="M26.695 16.401l-.087.285L11.838 12l14.857 4.401z"
          fill="#37FF00"
        />
        <path
          d="M26.616 16.66a15.8 15.8 0 01-.092.283L11.838 12l14.778 4.66z"
          fill="#3F0"
        />
        <path
          d="M26.533 16.917c-.032.094-.064.188-.098.281L11.839 12l14.695 4.917z"
          fill="#2FFF00"
        />
        <path
          d="M26.445 17.173l-.102.28L11.838 12l14.607 5.173z"
          fill="#2BFF00"
        />
        <path
          d="M26.352 17.427c-.035.093-.07.185-.107.277L11.838 12l14.514 5.427z"
          fill="#26FF00"
        />
        <path
          d="M26.255 17.68c-.036.092-.073.184-.111.275L11.838 12l14.417 5.68z"
          fill="#2F0"
        />
        <path
          d="M26.154 17.93a15.29 15.29 0 01-.117.274L11.837 12l14.317 5.93z"
          fill="#1EFF00"
        />
        <path
          d="M26.048 18.18l-.121.27L11.837 12l14.211 6.18z"
          fill="#1AFF00"
        />
        <path
          d="M25.938 18.426l-.126.27L11.838 12l14.1 6.426z"
          fill="#15FF00"
        />
        <path d="M25.824 18.671l-.13.268L11.837 12l13.986 6.671z" fill="#1F0" />
        <path
          d="M25.705 18.914c-.044.089-.09.177-.135.265L11.838 12l13.867 6.914z"
          fill="#0DFF00"
        />
        <path
          d="M25.582 19.155c-.045.088-.092.176-.14.263L11.839 12l13.745 7.155z"
          fill="#09FF00"
        />
        <path
          d="M25.456 19.394l-.145.26L11.838 12l13.618 7.394z"
          fill="#04FF00"
        />
        <path
          d="M25.325 19.63c-.05.087-.099.173-.15.258L11.838 12l13.487 7.63z"
          fill="#0F0"
        />
        <path
          d="M25.19 19.865l-.154.255L11.838 12l13.351 7.865z"
          fill="#00FF04"
        />
        <path
          d="M25.05 20.097l-.158.252L11.838 12l13.212 8.097z"
          fill="#00FF09"
        />
        <path
          d="M24.907 20.326l-.163.25L11.838 12l13.069 8.326z"
          fill="#00FF0D"
        />
        <path
          d="M24.76 20.553c-.055.082-.11.165-.167.246L11.838 12l12.921 8.553z"
          fill="#0F1"
        />
        <path
          d="M24.608 20.777c-.056.082-.113.163-.17.243L11.837 12l12.77 8.777z"
          fill="#00FF15"
        />
        <path
          d="M24.453 20.999a15.2 15.2 0 01-.175.24L11.838 12 24.453 21z"
          fill="#00FF1A"
        />
        <path
          d="M24.294 21.217c-.059.08-.119.16-.18.238L11.839 12l12.456 9.217z"
          fill="#00FF1E"
        />
        <path
          d="M24.131 21.433c-.06.079-.121.157-.183.235L11.838 12l12.293 9.433z"
          fill="#0F2"
        />
        <path
          d="M23.965 21.646a15.36 15.36 0 01-.188.232L11.837 12l12.128 9.646z"
          fill="#00FF26"
        />
        <path
          d="M23.795 21.857c-.064.076-.127.152-.192.227L11.838 12l11.957 9.857z"
          fill="#00FF2B"
        />
        <path
          d="M23.62 22.064c-.064.075-.129.15-.195.224L11.838 12 23.62 22.064z"
          fill="#00FF2F"
        />
        <path
          d="M23.443 22.268a15.44 15.44 0 01-.199.22L11.838 12l11.605 10.268z"
          fill="#0F3"
        />
        <path
          d="M23.262 22.469a16.67 16.67 0 01-.203.217L11.838 12 23.262 22.47z"
          fill="#00FF37"
        />
        <path
          d="M23.078 22.667c-.068.072-.137.143-.207.213L11.838 12l11.24 10.667z"
          fill="#00FF3C"
        />
        <path
          d="M22.89 22.861l-.21.21-10.842-11.07L22.89 22.86z"
          fill="#00FF40"
        />
        <path
          d="M22.699 23.052c-.07.07-.142.139-.214.207L11.838 12l10.86 11.052z"
          fill="#0F4"
        />
        <path
          d="M22.504 23.24a16.06 16.06 0 01-.218.203L11.838 12l10.666 11.24z"
          fill="#00FF48"
        />
        <path
          d="M22.306 23.425l-.22.199L11.837 12l10.468 11.425z"
          fill="#00FF4D"
        />
        <path
          d="M22.105 23.606l-.224.195L11.838 12l10.268 11.605z"
          fill="#00FF51"
        />
        <path
          d="M21.901 23.783a15.47 15.47 0 01-.228.191L11.838 12 21.9 23.783z"
          fill="#0F5"
        />
        <path
          d="M21.694 23.957c-.076.063-.153.125-.231.187L11.838 12l9.856 11.957z"
          fill="#00FF59"
        />
        <path
          d="M21.484 24.127c-.078.062-.156.123-.235.183L11.838 12l9.646 12.127z"
          fill="#00FF5E"
        />
        <path
          d="M21.27 24.294c-.078.06-.157.12-.237.178L11.838 12l9.433 12.294z"
          fill="#00FF62"
        />
        <path
          d="M21.055 24.456c-.08.06-.16.118-.24.175l-8.977-12.63 9.217 12.455z"
          fill="#0F6"
        />
        <path
          d="M20.836 24.615c-.08.058-.162.115-.244.17L11.838 12l8.998 12.615z"
          fill="#00FF6A"
        />
        <path
          d="M20.615 24.77c-.082.057-.164.112-.247.167L11.838 12l8.777 12.77z"
          fill="#00FF6F"
        />
        <path
          d="M20.39 24.922c-.082.055-.166.108-.25.162L11.839 12l8.552 12.922z"
          fill="#00FF73"
        />
        <path
          d="M20.164 25.069c-.084.053-.168.106-.253.157L11.838 12l8.326 13.069z"
          fill="#0F7"
        />
        <path
          d="M19.934 25.212c-.084.052-.17.103-.255.153L11.838 12l8.096 13.212z"
          fill="#00FF7B"
        />
        <path
          d="M19.702 25.352c-.085.05-.171.1-.257.148L11.838 12l7.864 13.352z"
          fill="#00FF80"
        />
        <path
          d="M19.468 25.487c-.086.049-.173.097-.26.144L11.838 12l7.63 13.486z"
          fill="#00FF84"
        />
        <path d="M19.232 25.618l-.263.14L11.838 12l7.394 13.618z" fill="#0F8" />
        <path
          d="M18.993 25.745c-.088.046-.176.09-.265.135L11.838 12l7.155 13.745z"
          fill="#00FF8C"
        />
        <path
          d="M18.752 25.868a16.1 16.1 0 01-.268.13L11.838 12l6.914 13.868z"
          fill="#00FF91"
        />
        <path
          d="M18.509 25.986l-.27.126L11.838 12l6.67 13.986z"
          fill="#00FF95"
        />
        <path
          d="M18.264 26.1c-.09.042-.181.082-.272.121l-6.154-14.22 6.426 14.1z"
          fill="#0F9"
        />
        <path
          d="M18.017 26.21c-.091.04-.183.079-.274.116L11.838 12l6.179 14.21z"
          fill="#00FF9D"
        />
        <path
          d="M17.768 26.316l-.276.111L11.838 12l5.93 14.316z"
          fill="#00FFA2"
        />
        <path
          d="M17.517 26.418l-.278.106L11.838 12l5.679 14.418z"
          fill="#00FFA6"
        />
        <path
          d="M17.265 26.514c-.093.035-.187.069-.28.102L11.838 12l5.427 14.514z"
          fill="#0FA"
        />
        <path
          d="M17.01 26.607l-.281.097L11.838 12l5.172 14.607z"
          fill="#00FFAE"
        />
        <path
          d="M16.755 26.695l-.283.092L11.838 12l4.917 14.695z"
          fill="#00FFB3"
        />
        <path
          d="M16.497 26.779c-.094.03-.189.058-.284.086L11.838 12l4.66 14.779z"
          fill="#00FFB7"
        />
        <path
          d="M16.239 26.858c-.095.028-.19.055-.286.081L11.838 12l4.4 14.858z"
          fill="#0FB"
        />
        <path
          d="M15.979 26.932a15.8 15.8 0 01-.288.077L11.838 12l4.14 14.932z"
          fill="#00FFBF"
        />
        <path
          d="M15.718 27.002c-.096.025-.193.049-.29.072L11.839 12l3.88 15.002z"
          fill="#00FFC4"
        />
        <path
          d="M15.455 27.068c-.096.023-.193.045-.29.066L11.838 12l3.617 15.068z"
          fill="#00FFC8"
        />
        <path
          d="M15.192 27.128a15.19 15.19 0 01-.291.062L11.838 12l3.354 15.128z"
          fill="#0FC"
        />
        <path
          d="M14.927 27.185c-.097.02-.194.038-.292.056l-2.797-15.24 3.09 15.184z"
          fill="#00FFD0"
        />
        <path
          d="M14.662 27.236a14.59 14.59 0 01-.293.052L11.838 12l2.824 15.236z"
          fill="#00FFD5"
        />
        <path
          d="M14.395 27.283a15.34 15.34 0 01-.293.046L11.838 12l2.557 15.283z"
          fill="#00FFD9"
        />
        <path
          d="M14.128 27.326c-.098.014-.196.028-.294.04L11.838 12l2.29 15.326z"
          fill="#0FD"
        />
        <path
          d="M13.86 27.363a16.4 16.4 0 01-.295.036L11.838 12l2.023 15.363z"
          fill="#00FFE1"
        />
        <path
          d="M13.592 27.396a15.36 15.36 0 01-.296.03L11.838 12l1.754 15.396z"
          fill="#00FFE6"
        />
        <path
          d="M13.323 27.424c-.099.01-.197.018-.296.026L11.837 12l1.486 15.424z"
          fill="#00FFEA"
        />
        <path
          d="M13.054 27.448c-.1.008-.198.015-.297.02L11.837 12l1.217 15.448z"
          fill="#0FE"
        />
        <path
          d="M12.784 27.467l-.297.015L11.837 12l.947 15.467z"
          fill="#00FFF2"
        />
        <path
          d="M12.514 27.481c-.1.004-.198.008-.297.01l-.38-15.49.677 15.48z"
          fill="#00FFF7"
        />
        <path
          d="M12.243 27.49c-.099.003-.198.005-.297.005L11.838 12l.405 15.49z"
          fill="#00FFFB"
        />
        <path d="M11.973 27.495h-.298L11.838 12l.135 15.495z" fill="#0FF" />
        <path
          d="M11.703 27.495c-.1 0-.199-.003-.298-.005L11.838 12l-.135 15.495z"
          fill="#00FBFF"
        />
        <path
          d="M11.432 27.49c-.1-.002-.199-.006-.298-.01L11.837 12l-.405 15.49z"
          fill="#00F7FF"
        />
        <path
          d="M11.16 27.481c-.098-.004-.197-.01-.296-.016L11.835 12l-.675 15.481z"
          fill="#00F2FF"
        />
        <path
          d="M10.891 27.467a14.679 14.679 0 01-.296-.021L11.837 12l-.946 15.467z"
          fill="#0EF"
        />
        <path
          d="M10.623 27.448a16.29 16.29 0 01-.297-.026L11.838 12l-1.215 15.448z"
          fill="#00EAFF"
        />
        <path
          d="M10.352 27.424l-.296-.031L11.837 12l-1.485 15.424z"
          fill="#00E6FF"
        />
        <path
          d="M10.083 27.396a15.396 15.396 0 01-.295-.036L11.838 12l-1.755 15.396z"
          fill="#00E1FF"
        />
        <path
          d="M9.816 27.363a16.234 16.234 0 01-.295-.041L11.84 12 9.816 27.363z"
          fill="#0DF"
        />
        <path
          d="M9.547 27.326c-.098-.015-.196-.03-.294-.047L11.837 12l-2.29 15.326z"
          fill="#00D9FF"
        />
        <path
          d="M9.28 27.283a14.541 14.541 0 01-.293-.052l2.85-15.23L9.28 27.282z"
          fill="#00D5FF"
        />
        <path
          d="M9.013 27.236a16.054 16.054 0 01-.292-.057L11.837 12 9.013 27.236z"
          fill="#00D0FF"
        />
        <path
          d="M8.749 27.185c-.097-.02-.194-.04-.291-.062L11.838 12l-3.09 15.185z"
          fill="#0CF"
        />
        <path
          d="M8.485 27.128a15.143 15.143 0 01-.29-.067l3.643-15.06-3.353 15.127z"
          fill="#00C8FF"
        />
        <path
          d="M8.22 27.068a15.072 15.072 0 01-.288-.073L11.838 12 8.221 27.068z"
          fill="#00C4FF"
        />
        <path
          d="M7.958 27.002c-.096-.025-.191-.05-.287-.077L11.838 12l-3.88 15.002z"
          fill="#00BFFF"
        />
        <path
          d="M7.697 26.932c-.096-.026-.191-.054-.286-.082L11.838 12 7.697 26.932z"
          fill="#0BF"
        />
        <path
          d="M7.437 26.858l-.284-.088L11.838 12l-4.4 14.858z"
          fill="#00B7FF"
        />
        <path
          d="M7.177 26.779l-.282-.093L11.837 12 7.177 26.78z"
          fill="#00B3FF"
        />
        <path
          d="M6.92 26.695a15.495 15.495 0 01-.281-.097L11.837 12 6.92 26.695z"
          fill="#00AEFF"
        />
        <path
          d="M6.665 26.607a16.177 16.177 0 01-.279-.102L11.838 12 6.665 26.607z"
          fill="#0AF"
        />
        <path
          d="M6.41 26.514c-.092-.034-.184-.07-.277-.106L11.838 12 6.41 26.514z"
          fill="#00A6FF"
        />
        <path
          d="M6.159 26.418a15.982 15.982 0 01-.276-.112L11.838 12l-5.68 14.418z"
          fill="#00A2FF"
        />
        <path
          d="M5.908 26.316a15.13 15.13 0 01-.274-.116L11.837 12l-5.93 14.316z"
          fill="#009DFF"
        />
        <path
          d="M5.66 26.21c-.092-.039-.182-.08-.273-.12L11.837 12 5.66 26.21z"
          fill="#09F"
        />
        <path
          d="M5.412 26.1c-.09-.04-.18-.083-.27-.125L11.839 12 5.412 26.1z"
          fill="#0095FF"
        />
        <path
          d="M5.166 25.986a15.56 15.56 0 01-.267-.13L11.837 12 5.166 25.986z"
          fill="#0091FF"
        />
        <path
          d="M4.923 25.868c-.088-.044-.177-.09-.265-.136L11.838 12 4.922 25.868z"
          fill="#008CFF"
        />
        <path
          d="M4.683 25.745a15.337 15.337 0 01-.263-.14L11.838 12 4.683 25.745z"
          fill="#08F"
        />
        <path
          d="M4.443 25.618a15.94 15.94 0 01-.26-.144L11.836 12 4.443 25.618z"
          fill="#0084FF"
        />
        <path
          d="M4.206 25.487a15.146 15.146 0 01-.257-.15L11.837 12l-7.63 13.487z"
          fill="#0080FF"
        />
        <path
          d="M3.972 25.352a15.72 15.72 0 01-.254-.154L11.837 12 3.972 25.352z"
          fill="#007BFF"
        />
        <path
          d="M3.742 25.212a15.544 15.544 0 01-.252-.157L11.838 12 3.742 25.212z"
          fill="#07F"
        />
        <path
          d="M3.512 25.069a14.807 14.807 0 01-.25-.162L11.838 12 3.511 25.07z"
          fill="#0072FF"
        />
        <path
          d="M3.285 24.922c-.083-.055-.165-.11-.247-.167L11.838 12 3.284 24.922z"
          fill="#006FFF"
        />
        <path
          d="M3.062 24.77a15.909 15.909 0 01-.244-.17l9.02-12.6-8.776 12.77z"
          fill="#006AFF"
        />
        <path
          d="M2.84 24.615a15.11 15.11 0 01-.24-.175L11.838 12 2.84 24.615z"
          fill="#06F"
        />
        <path
          d="M2.62 24.456c-.08-.059-.159-.118-.237-.179L11.837 12 2.62 24.456z"
          fill="#0062FF"
        />
        <path
          d="M2.405 24.294a15.46 15.46 0 01-.234-.184L11.838 12 2.405 24.294z"
          fill="#005EFF"
        />
        <path
          d="M2.192 24.127a15.357 15.357 0 01-.23-.187L11.837 12 2.192 24.127z"
          fill="#0059FF"
        />
        <path
          d="M1.981 23.957a15.832 15.832 0 01-.228-.191L11.838 12 1.98 23.957z"
          fill="#05F"
        />
        <path
          d="M1.774 23.783c-.076-.064-.15-.13-.225-.195L11.837 12 1.774 23.783z"
          fill="#0051FF"
        />
        <path
          d="M1.57 23.606a15.604 15.604 0 01-.22-.2L11.838 12 1.571 23.606z"
          fill="#004DFF"
        />
        <path
          d="M1.369 23.425a16.11 16.11 0 01-.218-.203L11.837 12 1.37 23.425z"
          fill="#0048FF"
        />
        <path
          d="M1.17 23.24a15.347 15.347 0 01-.213-.207L11.837 12 1.171 23.24z"
          fill="#04F"
        />
        <path d="M.977 23.052l-.21-.21L11.836 12 .978 23.052z" fill="#0040FF" />
        <path
          d="M.786 22.861c-.07-.07-.139-.142-.207-.214L11.838 12 .786 22.861z"
          fill="#003CFF"
        />
        <path
          d="M.597 22.667a15.473 15.473 0 01-.203-.218L11.837 12 .597 22.667z"
          fill="#0037FF"
        />
        <path
          d="M.412 22.469a15.344 15.344 0 01-.198-.221L11.837 12 .412 22.47z"
          fill="#03F"
        />
        <path
          d="M.233 22.268a14.72 14.72 0 01-.195-.225L11.838 12 .233 22.268z"
          fill="#002FFF"
        />
        <path
          d="M.054 22.064a15.106 15.106 0 01-.19-.228L11.836 12 .054 22.064z"
          fill="#002BFF"
        />
        <path
          d="M-.12 21.857a15.48 15.48 0 01-.186-.232L11.837 12-.119 21.857z"
          fill="#0026FF"
        />
        <path
          d="M-.29 21.646a15.347 15.347 0 01-.183-.234L11.837 12-.29 21.646z"
          fill="#02F"
        />
        <path
          d="M-.455 21.433a15.23 15.23 0 01-.18-.238L11.839 12-.455 21.433z"
          fill="#001EFF"
        />
        <path
          d="M-.619 21.217l-.174-.24L11.837 12-.618 21.217z"
          fill="#001AFF"
        />
        <path
          d="M-.778 20.998c-.058-.08-.114-.162-.17-.243L11.837 12-.778 20.998z"
          fill="#0015FF"
        />
        <path
          d="M-.932 20.777a15.347 15.347 0 01-.166-.247L11.838 12l-12.77 8.777z"
          fill="#01F"
        />
        <path
          d="M-1.084 20.553a15.704 15.704 0 01-.162-.25L11.838 12l-12.922 8.553z"
          fill="#000DFF"
        />
        <path
          d="M-1.232 20.326a15.572 15.572 0 01-.157-.253L11.837 12l-13.069 8.326z"
          fill="#0009FF"
        />
        <path
          d="M-1.374 20.097c-.052-.085-.103-.17-.153-.256l13.365-7.84-13.212 8.096z"
          fill="#0004FF"
        />
        <path
          d="M-1.514 19.865l-.149-.258L11.837 12l-13.351 7.865z"
          fill="#00F"
        />
        <path
          d="M-1.649 19.63a15.622 15.622 0 01-.144-.26L11.837 12l-13.486 7.63z"
          fill="#0400FF"
        />
        <path
          d="M-1.78 19.394a15.946 15.946 0 01-.14-.263l13.758-7.13-13.618 7.393z"
          fill="#0900FF"
        />
        <path
          d="M-1.907 19.155c-.046-.088-.091-.176-.135-.265L11.838 12l-13.745 7.155z"
          fill="#0D00FF"
        />
        <path
          d="M-2.03 18.914a15.204 15.204 0 01-.13-.267L11.837 12-2.03 18.914z"
          fill="#10F"
        />
        <path
          d="M-2.148 18.671l-.125-.27 14.111-6.4-13.986 6.67z"
          fill="#1500FF"
        />
        <path
          d="M-2.263 18.426c-.04-.09-.081-.18-.12-.272L11.836 12l-14.1 6.426z"
          fill="#1A00FF"
        />
        <path
          d="M-2.372 18.179c-.04-.09-.078-.182-.116-.274L11.838 12l-14.21 6.179z"
          fill="#1E00FF"
        />
        <path
          d="M-2.48 17.93a15.497 15.497 0 01-.11-.276L11.837 12-2.48 17.93z"
          fill="#20F"
        />
        <path
          d="M-2.58 17.68a15.507 15.507 0 01-.107-.279l14.524-5.4-14.417 5.678z"
          fill="#2600FF"
        />
        <path
          d="M-2.677 17.427a15.628 15.628 0 01-.101-.28L11.838 12l-14.515 5.427z"
          fill="#2B00FF"
        />
        <path
          d="M-2.77 17.173a15.552 15.552 0 01-.096-.282l14.704-4.89-14.607 5.172z"
          fill="#2F00FF"
        />
        <path
          d="M-2.857 16.917a15.403 15.403 0 01-.092-.283L11.837 12l-14.694 4.917z"
          fill="#30F"
        />
        <path
          d="M-2.94 16.66c-.03-.095-.06-.19-.087-.285L11.838 12l-14.779 4.66z"
          fill="#3700FF"
        />
        <path
          d="M-3.02 16.401c-.028-.095-.055-.19-.081-.286L11.838 12-3.02 16.401z"
          fill="#3C00FF"
        />
        <path
          d="M-3.094 16.141a15.48 15.48 0 01-.077-.287L11.838 12l-14.932 4.141z"
          fill="#4000FF"
        />
        <path
          d="M-3.165 15.88a15.396 15.396 0 01-.072-.289l15.074-3.59-15.002 3.879z"
          fill="#40F"
        />
        <path
          d="M-3.23 15.618a15.505 15.505 0 01-.067-.29L11.837 12-3.23 15.618z"
          fill="#4800FF"
        />
        <path
          d="M-3.29 15.354a15.423 15.423 0 01-.063-.29L11.837 12-3.29 15.354z"
          fill="#4D00FF"
        />
        <path
          d="M-3.347 15.09c-.02-.098-.038-.195-.056-.293L11.837 12l-15.184 3.09z"
          fill="#5100FF"
        />
        <path
          d="M-3.399 14.824a15.496 15.496 0 01-.05-.293l15.287-2.53L-3.4 14.823z"
          fill="#50F"
        />
        <path
          d="M-3.446 14.558a15.546 15.546 0 01-.046-.294L11.837 12l-15.283 2.558z"
          fill="#5900FF"
        />
        <path
          d="M-3.488 14.29a15.506 15.506 0 01-.042-.294L11.837 12l-15.325 2.29z"
          fill="#5E00FF"
        />
        <path
          d="M-3.526 14.023a15.346 15.346 0 01-.036-.296L11.837 12l-15.363 2.023z"
          fill="#6200FF"
        />
        <path
          d="M-3.56 13.754a15.36 15.36 0 01-.03-.295L11.837 12-3.56 13.754z"
          fill="#60F"
        />
        <path
          d="M-3.586 13.485c-.01-.098-.018-.197-.026-.296L11.838 12l-15.424 1.485z"
          fill="#6A00FF"
        />
        <path
          d="M-3.61 13.216a15.59 15.59 0 01-.02-.297L11.837 12-3.61 13.216z"
          fill="#6F00FF"
        />
        <path
          d="M-3.629 12.946a15.63 15.63 0 01-.015-.297L11.838 12l-15.467.946z"
          fill="#7300FF"
        />
        <path
          d="M-3.643 12.676c-.004-.099-.008-.198-.01-.297L11.837 12l-15.48.676z"
          fill="#70F"
        />
        <path
          d="M-3.653 12.406a15.44 15.44 0 01-.005-.298L11.837 12l-15.49.406z"
          fill="#7B00FF"
        />
        <path d="M-3.657 12.133v-.297l15.495.162-15.495.135z" fill="#8000FF" />
        <path
          d="M-3.658 11.864c.001-.1.003-.198.006-.297l15.49.432-15.496-.135z"
          fill="#8400FF"
        />
        <path d="M-3.653 11.593l.01-.297 15.48.703-15.49-.406z" fill="#80F" />
        <path
          d="M-3.643 11.324c.004-.099.01-.198.016-.297L11.837 12l-15.48-.676z"
          fill="#8C00FF"
        />
        <path
          d="M-3.629 11.049l.021-.297 15.446 1.243-15.467-.946z"
          fill="#9100FF"
        />
        <path
          d="M-3.61 10.783l.026-.296L11.838 12-3.61 10.783z"
          fill="#9500FF"
        />
        <path
          d="M-3.586 10.512l.03-.296 15.394 1.78-15.424-1.484z"
          fill="#90F"
        />
        <path
          d="M-3.558 10.247c.01-.098.023-.197.036-.295l15.36 2.05-15.396-1.755z"
          fill="#9D00FF"
        />
        <path
          d="M-3.526 9.976c.013-.098.027-.197.042-.295L11.837 12-3.526 9.976z"
          fill="#A200FF"
        />
        <path
          d="M-3.488 9.712c.015-.099.03-.197.047-.294l15.278 2.584-15.325-2.29z"
          fill="#A600FF"
        />
        <path d="M-3.446 9.443l.052-.293L11.837 12-3.446 9.444z" fill="#A0F" />
        <path
          d="M-3.398 9.173c.018-.097.037-.195.057-.292l15.18 3.116-15.237-2.824z"
          fill="#AE00FF"
        />
        <path
          d="M-3.346 8.914c.02-.097.04-.194.062-.291l15.122 3.38-15.184-3.089z"
          fill="#B300FF"
        />
        <path
          d="M-3.291 8.645c.021-.097.044-.193.067-.29L11.837 12-3.29 8.645z"
          fill="#B700FF"
        />
        <path d="M-3.23 8.38l.072-.288 14.996 3.906L-3.23 8.38z" fill="#B0F" />
        <path
          d="M-3.165 8.115c.025-.096.05-.191.077-.287l14.925 4.167-15.002-3.88z"
          fill="#BF00FF"
        />
        <path d="M-3.095 7.86l.082-.287L11.837 12-3.095 7.86z" fill="#C400FF" />
        <path d="M-3.02 7.6l.087-.285 14.77 4.686L-3.02 7.6z" fill="#C800FF" />
        <path
          d="M-2.94 7.342c.03-.095.06-.19.091-.283L11.838 12l-14.779-4.66z"
          fill="#C0F"
        />
        <path
          d="M-2.858 7.084l.097-.282 14.598 5.199-14.695-4.917z"
          fill="#D000FF"
        />
        <path d="M-2.77 6.827l.102-.28L11.837 12-2.77 6.827z" fill="#D500FF" />
        <path
          d="M-2.677 6.573c.035-.093.07-.186.107-.278l14.407 5.704-14.514-5.426z"
          fill="#D900FF"
        />
        <path d="M-2.58 6.321l.112-.275L11.838 12-2.58 6.32z" fill="#D0F" />
        <path
          d="M-2.478 6.072l.116-.274 14.2 6.204-14.316-5.93z"
          fill="#E100FF"
        />
        <path
          d="M-2.374 5.822c.04-.09.08-.181.122-.271L11.837 12l-14.21-6.179z"
          fill="#E600FF"
        />
        <path
          d="M-2.262 5.57c.04-.09.083-.18.126-.27l13.974 6.695-14.1-6.426z"
          fill="#EA00FF"
        />
        <path d="M-2.149 5.329l.131-.268L11.837 12-2.149 5.33z" fill="#E0F" />
        <path
          d="M-2.03 5.088c.044-.089.089-.177.135-.265l13.732 7.179-13.868-6.914z"
          fill="#F200FF"
        />
        <path
          d="M-1.907 4.842c.045-.088.092-.176.14-.263l13.604 7.418-13.744-7.155z"
          fill="#F700FF"
        />
        <path
          d="M-1.78 4.606c.047-.087.095-.174.144-.26L11.837 12-1.78 4.606z"
          fill="#FB00FF"
        />
        <path
          d="M-1.648 4.365c.048-.086.098-.172.149-.257l13.337 7.888-13.486-7.63z"
          fill="#F0F"
        />
        <path
          d="M-1.514 4.135c.05-.085.102-.17.154-.255L11.838 12-1.514 4.134z"
          fill="#FF00FB"
        />
        <path
          d="M-1.375 3.9l.158-.251 13.054 8.348-13.212-8.096z"
          fill="#FF00F7"
        />
        <path
          d="M-1.23 3.67l.162-.249 12.907 8.575-13.07-8.326z"
          fill="#FF00F2"
        />
        <path
          d="M-1.083 3.45c.054-.082.11-.164.166-.246l12.755 8.8L-1.083 3.45z"
          fill="#F0E"
        />
        <path d="M-.933 3.224l.17-.244 12.6 9.02-12.77-8.776z" fill="#FF00EA" />
        <path
          d="M-.779 3.004l.175-.24 12.44 9.239L-.779 3.004z"
          fill="#FF00E6"
        />
        <path
          d="M-.619 2.785c.06-.08.119-.16.18-.238l12.276 9.455L-.619 2.785z"
          fill="#FF00E1"
        />
        <path
          d="M-.455 2.568c.06-.078.121-.157.183-.234l12.11 9.667L-.455 2.568z"
          fill="#F0D"
        />
        <path
          d="M-.29 2.352c.061-.078.124-.155.187-.231l11.94 9.877L-.29 2.352z"
          fill="#FF00D9"
        />
        <path
          d="M-.119 2.143c.063-.077.127-.153.192-.228l11.765 10.084L-.119 2.143z"
          fill="#FF00D5"
        />
        <path d="M.055 1.937l.195-.224L11.838 12 .055 1.937z" fill="#FF00D0" />
        <path
          d="M.233 1.728c.065-.075.132-.148.199-.221l11.406 10.488L.233 1.728z"
          fill="#F0C"
        />
        <path
          d="M.413 1.533l.203-.218 11.222 10.686L.413 1.533z"
          fill="#FF00C8"
        />
        <path
          d="M.597 1.33l.207-.213 11.033 10.88L.597 1.331z"
          fill="#FF00C4"
        />
        <path
          d="M.785 1.137l.21-.21 10.843 11.071L.785 1.138z"
          fill="#FF00BF"
        />
        <path
          d="M.977.95c.07-.07.142-.139.214-.207L11.838 12 .977.95z"
          fill="#F0B"
        />
        <path
          d="M1.17.76c.072-.069.145-.136.218-.203L11.837 12 1.17.76z"
          fill="#FF00B7"
        />
        <path d="M1.369.575l.22-.199L11.838 12 1.37.575z" fill="#FF00B3" />
        <path
          d="M1.57.396c.073-.066.148-.13.224-.195l10.043 11.8L1.569.396z"
          fill="#FF00AE"
        />
        <path
          d="M1.774.217c.076-.065.152-.128.228-.191l9.836 11.973L1.774.217z"
          fill="#F0A"
        />
        <path
          d="M1.982.043c.076-.063.154-.125.231-.187L11.838 12 1.982.043z"
          fill="#FF00A6"
        />
        <path
          d="M2.191-.127c.078-.062.156-.123.235-.183L11.838 12 2.19-.128z"
          fill="#FF00A2"
        />
        <path
          d="M2.406-.296c.078-.06.157-.12.237-.179l9.196 12.473L2.405-.296z"
          fill="#FF009D"
        />
        <path
          d="M2.62-.453c.08-.06.16-.117.241-.175l8.976 12.63L2.62-.452z"
          fill="#F09"
        />
        <path
          d="M2.84-.615c.08-.057.162-.114.244-.17L11.838 12 2.84-.615z"
          fill="#FF0095"
        />
        <path
          d="M3.061-.772c.082-.057.164-.112.247-.166l8.53 12.936L3.06-.772z"
          fill="#FF0091"
        />
        <path
          d="M3.285-.925c.083-.055.166-.109.25-.162l8.302 13.084L3.285-.925z"
          fill="#FF008C"
        />
        <path d="M3.512-1.07l.253-.158L11.838 12 3.512-1.071z" fill="#F08" />
        <path
          d="M3.742-1.212c.084-.052.17-.103.255-.153L11.838 12 3.742-1.212z"
          fill="#FF0084"
        />
        <path
          d="M3.972-1.35c.086-.05.172-.1.258-.148l7.607 13.5L3.972-1.35z"
          fill="#FF0080"
        />
        <path
          d="M4.206-1.49c.086-.049.173-.097.26-.144l7.37 13.63L4.206-1.49z"
          fill="#FF007B"
        />
        <path
          d="M4.445-1.617c.087-.048.174-.094.262-.14L11.838 12 4.445-1.617z"
          fill="#F07"
        />
        <path
          d="M4.682-1.747c.088-.046.176-.09.265-.135l6.89 13.88L4.682-1.747z"
          fill="#FF0073"
        />
        <path
          d="M4.923-1.867c.089-.044.178-.088.268-.13L11.837 12 4.923-1.868z"
          fill="#FF006F"
        />
        <path
          d="M5.167-1.984l.27-.125 6.401 14.111L5.167-1.984z"
          fill="#FF006A"
        />
        <path
          d="M5.41-2.098c.091-.041.182-.082.273-.121l6.154 14.221-6.426-14.1z"
          fill="#F06"
        />
        <path
          d="M5.66-2.208c.09-.04.181-.078.273-.116l5.905 14.326-6.179-14.21z"
          fill="#FF0062"
        />
        <path
          d="M5.907-2.32l.276-.11 5.654 14.427L5.907-2.32z"
          fill="#FF005E"
        />
        <path
          d="M6.158-2.418c.093-.036.185-.072.278-.106l5.402 14.523-5.68-14.417z"
          fill="#FF0059"
        />
        <path
          d="M6.41-2.513c.092-.035.186-.069.28-.102l5.146 14.616L6.41-2.513z"
          fill="#F05"
        />
        <path
          d="M6.665-2.608c.094-.034.188-.066.282-.097l4.89 14.703L6.666-2.608z"
          fill="#FF0051"
        />
        <path
          d="M6.92-2.698a15.6 15.6 0 01.284-.092l4.634 14.786L6.92-2.698z"
          fill="#FF004D"
        />
        <path
          d="M7.178-2.777l.285-.087 4.375 14.865-4.66-14.778z"
          fill="#FF0048"
        />
        <path
          d="M7.436-2.855c.095-.028.19-.055.286-.081l4.115 14.939-4.4-14.858z"
          fill="#F04"
        />
        <path
          d="M7.697-2.932l.287-.077 3.854 15.01L7.697-2.933z"
          fill="#FF0040"
        />
        <path
          d="M7.957-3.005c.096-.025.193-.05.289-.072l3.591 15.073-3.88-15.001z"
          fill="#FF003C"
        />
        <path d="M8.22-3.068l.29-.067L11.839 12 8.221-3.068z" fill="#FF0037" />
        <path d="M8.484-3.13l.29-.06L11.839 12 8.484-3.13z" fill="#F03" />
        <path
          d="M8.749-3.185c.097-.02.194-.039.292-.056l2.797 15.24-3.09-15.184z"
          fill="#FF002F"
        />
        <path
          d="M9.014-3.239c.097-.018.195-.035.293-.051l2.53 15.287L9.015-3.239z"
          fill="#FF002B"
        />
        <path
          d="M9.28-3.287c.097-.017.195-.032.293-.047l2.264 15.33L9.28-3.287z"
          fill="#FF0026"
        />
        <path
          d="M9.547-3.329c.098-.014.196-.028.294-.04l1.996 15.366L9.547-3.33z"
          fill="#F02"
        />
        <path
          d="M9.815-3.366c.098-.013.197-.025.295-.036l1.728 15.399L9.815-3.366z"
          fill="#FF001E"
        />
        <path
          d="M10.083-3.397c.099-.01.197-.021.296-.03l1.458 15.426-1.754-15.396z"
          fill="#FF001A"
        />
        <path
          d="M10.352-3.424c.099-.01.198-.018.297-.025L11.838 12 10.351-3.424z"
          fill="#FF0015"
        />
        <path
          d="M10.622-3.45c.1-.008.198-.015.297-.02l.92 15.467L10.621-3.45z"
          fill="#F01"
        />
        <path
          d="M10.891-3.464c.1-.006.198-.012.297-.016l.65 15.482-.947-15.466z"
          fill="#FF000D"
        />
        <path
          d="M11.162-3.479c.1-.004.198-.008.298-.01l.378 15.49-.676-15.48z"
          fill="#FF0009"
        />
        <path
          d="M11.431-3.491c.1-.003.199-.004.298-.005l.108 15.495-.406-15.49z"
          fill="#FF0004"
        />
        <path
          d="M11.838 27.495c8.558 0 15.495-6.938 15.495-15.496S20.396-3.496 11.838-3.496-3.658 3.44-3.658 11.999 3.28 27.495 11.838 27.495z"
          fill="url(#color-picker_svg__paint0_radial_2508_263691)"
        />
      </g>
      <defs>
        <radialGradient
          id="color-picker_svg__paint0_radial_2508_263691"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.838 12) scale(15.4955)"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default SvgColorPicker;
