import { useTranslation } from 'next-i18next';

import {
  GroupWithMembersFragment,
  refetchGroupQuery,
  useToggleGroupEnableNewPostNotificationsForNewMembersMutation,
} from '../../../../../../generated/types-and-hooks';
import { Switch } from '../../../../common/components/Switch';
import { Text } from '../../../../common/components/Text';
import {
  GroupEditSetting,
  GroupEditSettingPositioning,
} from './GroupEditSetting';

export const GroupEditSettingEnableNewPost: React.FC<
  {
    group: GroupWithMembersFragment;
  } & GroupEditSettingPositioning
> = ({ group, ...position }) => {
  const { t } = useTranslation('groups');
  const [toggleEnableNewPostNotificationsForNewMembers] =
    useToggleGroupEnableNewPostNotificationsForNewMembersMutation({
      optimisticResponse: {
        __typename: 'Mutation',
        toggleGroupEnableNewPostNotificationsForNewMembers: {
          __typename: 'Group',
          id: group.id,
          enableNewPostNotificationsForNewMembers:
            !group.enableNewPostNotificationsForNewMembers,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        refetchGroupQuery({
          id: group.id,
        }),
      ],
    });

  const handleChange = () => {
    toggleEnableNewPostNotificationsForNewMembers({
      variables: {
        input: {
          groupId: group.id,
        },
      },
    });
  };

  return (
    <GroupEditSetting
      {...position}
      title={t('groups.new_post_notifications')}
      toggle={
        <Switch
          checked={group.enableNewPostNotificationsForNewMembers}
          onCheckedChange={handleChange}
        />
      }
    >
      <Text variant="md" color="gray.300" pr={16}>
        {t('groups.new_post_notifications_explanation')}
      </Text>
    </GroupEditSetting>
  );
};
