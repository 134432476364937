import * as React from 'react';
import { SVGProps } from 'react';

const SvgMembers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M12 3c-.938 0-1.736.38-2.248.956C9.239 4.532 9 5.27 9 6c0 .729.24 1.468.752 2.045.512.576 1.31.955 2.248.955.938 0 1.736-.379 2.248-.955C14.761 7.469 15 6.73 15 6c0-.73-.24-1.469-.752-2.045C13.736 3.379 12.938 3 12 3ZM5.5 4c-.77 0-1.444.317-1.873.8A2.547 2.547 0 0 0 3 6.5c0 .605.198 1.22.627 1.702C4.057 8.685 4.729 9 5.5 9c.77 0 1.444-.316 1.873-.8.43-.482.627-1.096.627-1.7 0-.605-.198-1.219-.627-1.702-.43-.482-1.102-.798-1.873-.798Zm13 0c-.77 0-1.444.317-1.873.8A2.547 2.547 0 0 0 16 6.5c0 .605.198 1.22.627 1.702.43.483 1.102.799 1.873.799.77 0 1.444-.316 1.873-.8.43-.482.627-1.096.627-1.7 0-.605-.198-1.219-.627-1.702-.43-.482-1.102-.798-1.873-.798Zm-6.5.5c.563 0 .89.185 1.127.452S13.5 5.605 13.5 6c0 .395-.136.781-.373 1.048-.238.268-.565.452-1.127.452-.563 0-.89-.184-1.127-.452-.237-.267-.373-.653-.373-1.048 0-.396.136-.782.373-1.05.238-.266.565-.45 1.127-.45Zm-6.5 1c.396 0 .598.122.752.296.154.173.248.434.248.705 0 .27-.094.531-.248.705-.154.173-.356.295-.752.295s-.598-.122-.752-.295A1.08 1.08 0 0 1 4.5 6.5c0-.271.094-.532.248-.705.154-.174.356-.295.752-.295Zm13 0c.396 0 .598.122.752.296.154.173.248.434.248.705 0 .27-.094.531-.248.705-.154.173-.356.295-.752.295s-.598-.122-.752-.295A1.08 1.08 0 0 1 17.5 6.5c0-.271.094-.532.248-.705.154-.174.356-.295.752-.295ZM3.75 10C2.785 10 2 10.787 2 11.75V15a4.005 4.005 0 0 0 5.03 3.866c-.21-.45-.365-.93-.45-1.436A2.5 2.5 0 0 1 3.5 15v-3.25c0-.14.11-.25.25-.25h2.765c.05-.57.27-1.084.615-1.5H3.75Zm5.5 0c-.957 0-1.75.793-1.75 1.75v4.75c0 2.477 2.024 4.5 4.5 4.5s4.5-2.023 4.5-4.5v-4.75c0-.957-.793-1.75-1.75-1.75h-5.5Zm7.62 0c.345.416.565.93.615 1.5h2.765c.14 0 .25.11.25.25V15a2.5 2.5 0 0 1-3.08 2.43c-.085.505-.24.986-.45 1.436A4.005 4.005 0 0 0 22 15v-3.25c0-.965-.785-1.75-1.75-1.75h-3.38Zm-7.62 1.5h5.5c.148 0 .25.103.25.25v4.75c0 1.666-1.334 3-3 3s-3-1.334-3-3v-4.75c0-.147.102-.25.25-.25Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMembers;
