import * as React from "react";
import { SVGProps } from "react";
const SvgSendLetter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#send-letter_svg__a)">
      <path
        d="M19 22H5c-.55 0-1-.45-1-1V3.5c0-.55.45-1 1-1h14c.55 0 1 .45 1 1V21c0 .55-.45 1-1 1Z"
        fill="url(#send-letter_svg__b)"
      />
      <path
        opacity={0.05}
        d="M19 22H5c-.45 0-.8-.3-.95-.7l1.25-2.55c.25-.45.75-.75 1.3-.75h10.85c.55 0 1.05.3 1.3.75L20 21.3c-.2.4-.55.7-1 .7Z"
        fill="currentColor"
      />
      <path
        opacity={0.07}
        d="M19 22H5c-.35 0-.7-.2-.85-.5l1.35-2.6c.2-.4.65-.65 1.1-.65h10.85c.45 0 .85.25 1.1.65l1.35 2.6c-.2.3-.55.5-.9.5Z"
        fill="currentColor"
      />
      <path
        d="M4.2 21.6 5.7 19c.2-.3.5-.5.85-.5H17.4c.35 0 .7.2.85.5l1.5 2.6c-.15.25-.45.4-.75.4H5c-.3 0-.6-.15-.8-.4Z"
        fill="#FABB62"
      />
      <path d="M12.5 6h-1v12.5h1V6Z" fill="#B14716" />
      <path
        d="m23.9 14.55-.95-.95a.392.392 0 0 0-.55 0l-.45.45 1.5 1.5.45-.45c.15-.15.15-.4 0-.55Z"
        fill="#C94F60"
      />
      <path
        opacity={0.05}
        d="M20 19.7v-4.4L13.3 22h4.4l2.3-2.3Z"
        fill="currentColor"
      />
      <path
        opacity={0.07}
        d="M20 19.35v-3.7L13.65 22h3.7L20 19.35Z"
        fill="currentColor"
      />
      <path d="m14.5 21.5-.5 2 2-.5.2-1.75-1.7.25Z" fill="#F0F0F0" />
      <path d="M22.15 16.8 16 23l-1.5-1.5 6.15-6.15 1.5 1.45Z" fill="#EDBE00" />
      <path
        d="m20.65 15.35 1.25-1.25 1.5 1.5-1.25 1.25-1.5-1.5Z"
        fill="url(#send-letter_svg__c)"
      />
      <path
        opacity={0.05}
        d="M19 2.5H5c-.45 0-.8.3-.95.7L5.3 5.75c.25.45.75.75 1.3.75h10.85c.55 0 1.05-.3 1.3-.75L20 3.2c-.2-.4-.55-.7-1-.7Z"
        fill="currentColor"
      />
      <path
        opacity={0.07}
        d="M19 2.5H5c-.35 0-.7.2-.85.5L5.5 5.6c.2.4.65.65 1.1.65h10.85c.45 0 .85-.25 1.1-.65l1.3-2.6c-.15-.3-.5-.5-.85-.5Z"
        fill="currentColor"
      />
      <path d="m14.25 22.5-.25 1 1-.25-.75-.75Z" fill="#787878" />
      <path
        d="m4.2 2.9 1.5 2.6c.2.3.5.5.85.5H17.4c.35 0 .7-.2.85-.5l1.5-2.6c-.15-.25-.45-.4-.75-.4H5c-.3 0-.6.15-.8.4Z"
        fill="#FABB62"
      />
      <path
        d="M12 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM12 19.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="#D97218"
      />
    </g>
    <defs>
      <radialGradient
        id="send-letter_svg__b"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.766 11.527) scale(15.539)"
      >
        <stop stopColor="#EBA84B" />
        <stop offset={1} stopColor="#D97218" />
      </radialGradient>
      <linearGradient
        id="send-letter_svg__c"
        x1={22.056}
        y1={14.156}
        x2={22.056}
        y2={16.901}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEDEDE" />
        <stop offset={1} stopColor="#D6D6D6" />
      </linearGradient>
      <clipPath id="send-letter_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSendLetter;
