import * as React from "react";
import { SVGProps } from "react";
const SvgKey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 20.75a1.25 1.25 0 0 1 1.477-1.227l.593-.593A1.492 1.492 0 0 1 8 18.5 1.5 1.5 0 0 1 9.5 17c.15 0 .293.029.43.07L12 15l-3-3-6.707 6.707a1 1 0 0 0-.293.707v.671a1 1 0 0 0 .293.707l.914.914a.999.999 0 0 0 .707.294h.671a1 1 0 0 0 .708-.293l.73-.73A1.299 1.299 0 0 1 6 20.75Z"
      fill="url(#key_svg__a)"
    />
    <path
      d="M14.75 2.5a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5Zm1.75 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
      fill="url(#key_svg__b)"
    />
    <defs>
      <linearGradient
        id="key_svg__a"
        x1={10.479}
        y1={13.379}
        x2={2.87}
        y2={21.311}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5A505" />
        <stop offset={0.01} stopColor="#E9A804" />
        <stop offset={0.06} stopColor="#F4B102" />
        <stop offset={0.129} stopColor="#FBB600" />
        <stop offset={0.323} stopColor="#FDB700" />
      </linearGradient>
      <linearGradient
        id="key_svg__b"
        x1={10.82}
        y1={3.537}
        x2={18.485}
        y2={14.681}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEDE00" />
        <stop offset={1} stopColor="#FFD000" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgKey;
