import { styled, system, SystemProps, x } from '@xstyled/styled-components';
import { debounce } from 'lodash';
import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, useMemo } from 'react';

import { Centered } from '../../../../common/components/Centered';
import { Icon } from '../../../../common/components/Icon';
import { Input } from '../../../../common/components/Input';
import { NavigationButton } from '../../../../common/components/NavigationButton';
import { Option, Select } from '../../../../common/components/Select';
import { Text } from '../../../../common/components/Text';
import { MemberItemSkeleton } from './MemberItemSkeleton';

export const MEMBERS_LIMIT = 10;

type CommunityMembersListViewProps = {
  searchPlaceholder?: string;
  emptyPlaceholder?: string;
  sortFields: Option[];
  sortValue: string;
  headers: string[];
  button?: JSX.Element;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSortChange: (value: string) => void;
  onPreviousPage: () => void;
  onNextPage: () => void;
  offset: number;
  totalCount?: number;
};

const StyledInput = styled(Input)<SystemProps>`
  input {
    padding: 5px;
    padding-left: 45px;
    flex-grow: 0;

    ${system};
  }
`;

export const CommunityMembersListView: React.FC<
  PropsWithChildren<CommunityMembersListViewProps>
> = ({
  sortFields,
  sortValue,
  headers,
  button,
  onSearchChange,
  onSortChange,
  onPreviousPage,
  onNextPage,
  offset,
  totalCount,
  children,
  emptyPlaceholder,
  ...props
}) => {
  const { t } = useTranslation();
  const searchPlaceholder =
    props.searchPlaceholder || t('settings.search_email', { ns: 'settings' });

  const start = offset + 1;
  const end =
    totalCount && start + MEMBERS_LIMIT > totalCount
      ? totalCount
      : offset + MEMBERS_LIMIT;

  const debouncedOnSearchChange = useMemo(
    () =>
      debounce((event) => {
        onSearchChange(event);
      }, 250),
    [onSearchChange]
  );

  return (
    <x.div
      w={{
        _: '100%',
        sm: '724px',
      }}
      my={5}
      border="default"
      borderColor="gray.100"
      borderRadius="md"
    >
      <x.div borderBottom="default" borderColor="gray.100">
        <x.div
          display="flex"
          flexDirection={{
            _: 'column',
            sm: 'row',
          }}
          alignItems="center"
          justifyContent={{
            sm: 'space-between',
          }}
          borderBottom="default"
          borderColor="gray.100"
          spaceX={{
            sm: 8,
          }}
          spaceY={{
            _: 4,
            sm: 0,
          }}
          padding={4}
        >
          <x.div
            w={{
              _: '100%',
              sm: '255px',
            }}
          >
            <StyledInput
              placeholder={searchPlaceholder}
              icon={<Icon name="search" />}
              onChange={debouncedOnSearchChange}
              fullWidth
              w={{
                _: '100%',
                auto: 'auto',
              }}
            />
          </x.div>

          <x.div
            display="flex"
            flexDirection="row"
            alignItems="center"
            w={{
              _: '100%',
              sm: 'auto',
            }}
            spaceX={2}
          >
            <x.div
              w={{
                _: '100%',
                sm: '240px',
              }}
            >
              <Select
                name="sortBy"
                options={sortFields}
                onChange={onSortChange}
                value={sortValue}
                prefix={t('settings.sort_by', { ns: 'settings' })}
                py="5px"
                fullWidth
                disabled={totalCount === 0}
              />
            </x.div>
            {button}
          </x.div>
        </x.div>
        <x.div
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <x.div
            boxSizing="border-box"
            p="3 5"
            w={{
              _: '100%',
              sm: '45%',
            }}
          >
            <Text variant="sm-medium" color="gray.300">
              {headers[0]}
            </Text>
          </x.div>
          <x.div
            display={{
              _: 'none',
              sm: 'block',
            }}
            w="55%"
          >
            <Text variant="sm-medium" color="gray.300">
              {headers[1]}
            </Text>
          </x.div>
        </x.div>
      </x.div>
      <x.div minH="680px" position="relative">
        {totalCount === 0 ? (
          <Centered>
            <Text variant="md" color="gray.300">
              {emptyPlaceholder}
            </Text>
          </Centered>
        ) : children ? (
          children
        ) : (
          [...new Array(10)].map((_, index) => {
            return <MemberItemSkeleton key={index} />;
          })
        )}
      </x.div>
      <x.div
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderTop="default"
        borderColor="gray.100"
        borderRadius="0 0 md md"
      >
        <x.div p="3 5">
          {totalCount && totalCount > 0 ? (
            <Text variant="sm-medium" color="gray.300">
              {start} - {end} {t('of')} {totalCount}
            </Text>
          ) : null}
        </x.div>
        <x.div p="3 3">
          <x.div display="flex" justifyContent="flex-end" spaceX={2}>
            <NavigationButton
              variant="left"
              onClick={onPreviousPage}
              disabled={offset === 0}
            />
            <NavigationButton
              variant="right"
              onClick={onNextPage}
              disabled={totalCount === 0 || end === totalCount}
            />
          </x.div>
        </x.div>
      </x.div>
    </x.div>
  );
};
