import * as React from "react";
import { SVGProps } from "react";

const SvgAppSoundcloud = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#app-soundcloud_svg__a)">
      <path
        d="M23.351.005H2.018C1.282.005.685.602.685 1.338v21.334c0 .736.597 1.333 1.333 1.333H23.35c.737 0 1.334-.597 1.334-1.333V1.338c0-.736-.597-1.333-1.334-1.333Z"
        fill="url(#app-soundcloud_svg__b)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m19.024 16.005-6.084-.004a.293.293 0 0 1-.255-.29V7.92c.001-.143.049-.217.227-.289a4.08 4.08 0 0 1 1.51-.293c2.09 0 3.817 1.598 4.119 3.682a2.07 2.07 0 0 1 1.144-.348c1.288 0 2.333 1.218 2.333 2.722 0 1.503-1.045 2.721-2.333 2.721-.225 0-.449-.037-.661-.11ZM3.666 12.602c.056 0 .102.032.11.079l.242 1.126-.241 1.108c-.01.047-.055.079-.111.079-.058 0-.104-.032-.111-.079l-.204-1.107.204-1.127c.007-.047.053-.08.11-.08Zm1.431-.616.254 1.822-.254 1.781a.095.095 0 0 1-.094.082.094.094 0 0 1-.095-.082l-.223-1.781.223-1.822a.095.095 0 0 1 .095-.082c.048 0 .086.033.094.082Zm2.594-1.68a.16.16 0 0 1 .158.151l.186 3.352-.186 2.167v-.001a.16.16 0 0 1-.159.152.159.159 0 0 1-.158-.152l-.164-2.166.164-3.352a.16.16 0 0 1 .158-.152ZM6.338 11.47c.067 0 .122.05.128.117l.219 2.221-.22 2.149a.128.128 0 0 1-.127.116.127.127 0 0 1-.128-.116l-.192-2.149.192-2.22a.127.127 0 0 1 .128-.118Zm2.671 4.656a.189.189 0 0 1-.187-.186l-.137-2.13.137-4.462a.19.19 0 0 1 .187-.187.19.19 0 0 1 .188.187l.154 4.462-.155 2.13a.19.19 0 0 1-.187.186Zm2.67.01c-.13 0-.236-.115-.238-.255l-.09-2.071.09-5.152c.002-.142.109-.257.238-.257.13 0 .236.116.238.257l.1 5.153-.1 2.069v.002-.002c-.002.141-.109.257-.238.257Zm-1.334-.008a.218.218 0 0 1-.214-.22l-.113-2.097.113-4.495c.002-.124.096-.222.213-.222.117 0 .21.098.214.222l.127 4.495-.127 2.097v-.002c-.003.125-.097.222-.213.222Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="app-soundcloud_svg__b"
        x1={12.685}
        y1={0.005}
        x2={12.685}
        y2={24.005}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE9B00" />
        <stop offset={0.497} stopColor="#FE5D00" />
        <stop offset={1} stopColor="#FF1E00" />
      </linearGradient>
      <clipPath id="app-soundcloud_svg__a">
        <path fill="#fff" transform="translate(.685 .005)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAppSoundcloud;
