import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useForm } from 'react-hook-form';

import { Form } from '../../../../common/components/Form';
import {
  FormData,
  OrganizationForm,
} from '../../common/OrganizationForm/OrganizationForm';
import { StepView } from '../../common/StepView';
import { StepHeading } from '../layout/StepHeading';

export interface OrganizationStepProps {
  onNext: (organization: FormData) => void;
  loading: boolean;
}

export const OrganizationStep: React.FC<OrganizationStepProps> = ({
  onNext,
  loading,
}) => {
  const { t } = useTranslation();
  const form = useForm<FormData>({
    mode: 'all',
  });

  const handleSubmit = () => onNext(form.getValues());

  return (
    // manual height to set overflow hidden
    <x.div
      h={{ _: 'fit-content' }}
      display="flex"
      flexDirection="column"
      overflow="hidden"
      borderRadius={{ _: 'initial', lg: 'lg' }}
    >
      <StepHeading
        title={t('create_community_title')}
        text={t('create_community_description')}
      />
      <Form<FormData> {...form} onSubmit={handleSubmit} w="100%">
        <StepView loading={loading} dataTest="groups-onboarding-organization">
          <OrganizationForm variant="create" />
        </StepView>
      </Form>
    </x.div>
  );
};
