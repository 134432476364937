import borders from './borders';
import colors from './colors';
import radii from './radius';
import screens from './screens';
import shadows from './shadows';
import sizes, { baseSizes } from './sizes';
import states from './states';
import transitions from './transitions';
import typography from './typography';
import zIndices from './z-index';
export * from './colors';
export * from './typography';
export * from './sizes';
export * from './shadows';
export * from './screens';
export * from './states';
export * from './radius';
export * from './transitions';
export * from './borders';
export * from './z-index';
import { defaultTheme } from '@xstyled/styled-components';

const space = baseSizes;

export const theme = {
  screens,
  zIndices,
  radii,
  colors,
  ...typography,
  sizes,
  shadows,
  space,
  borders,
  transitions,
  durations: transitions.duration,
  states: {
    ...defaultTheme.states,
    ...states,
  },
};
