import * as React from "react";

function SvgGiraffe(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 36.875a3.75 3.75 0 01-3.75-3.75 3.295 3.295 0 010-.4.625.625 0 01.931-.469c.288.163.614.247.944.244h3.75c.325 0 .643-.084.925-.244a.626.626 0 01.931.469A3.75 3.75 0 0120 36.875z"
        fill="#212121"
      />
      <path
        d="M17.568 33.7a2.5 2.5 0 004.87 0 3.13 3.13 0 01-.563.05h-3.75c-.187 0-.373-.017-.557-.05z"
        fill="#E65100"
      />
      <path
        d="M21.875 33.75h-3.75A3.125 3.125 0 0115 30.625s-.031-1.419-.169-3.194c-.256-3.4-.7-5.768-1.25-6.681a7.5 7.5 0 01-1.037-4.656 7.35 7.35 0 011.687-3.969c.235-.29.492-.562.769-.813V5a1.875 1.875 0 013.75 0v4.488c.31-.058.623-.096.938-.113a7.406 7.406 0 011.562.1V5A1.875 1.875 0 0125 5v6.294a7.5 7.5 0 011.494 9.331c-.569.988-1.038 3.413-1.313 6.837A64.636 64.636 0 0025 30.625a3.125 3.125 0 01-3.125 3.125z"
        fill="#212121"
      />
      <path
        d="M16.875 4.375a.625.625 0 00-.431.181.625.625 0 00-.194.444v6.6a.626.626 0 01-.225.481 6.356 6.356 0 00-2.243 4.169 6.25 6.25 0 00.862 3.881c.669 1.1 1.15 3.532 1.431 7.232.144 1.787.175 3.262.175 3.262a1.875 1.875 0 00.956 1.644c.282.154.598.234.92.231h3.75a1.875 1.875 0 001.874-1.875s.038-1.469.188-3.275c.3-3.706.794-6.181 1.475-7.363a6.25 6.25 0 00-.588-7.074 6.679 6.679 0 00-.85-.85.626.626 0 01-.225-.482V5a.625.625 0 00-.625-.625.625.625 0 00-.431.181A.625.625 0 0022.5 5v5.262a.627.627 0 01-.794.625 6.251 6.251 0 00-1.962-.262c-.49.024-.974.105-1.444.244a.624.624 0 01-.8-.625V5a.625.625 0 00-.625-.625z"
        fill="#FB8C00"
      />
      <path
        d="M28.35 12.813h-.544l-2.868-.313a.624.624 0 01-.482-.925l1.088-1.962a9.45 9.45 0 016.594-4.725l2.75-.5a.625.625 0 01.669.893l-2.232 4.457a5.625 5.625 0 01-4.975 3.075z"
        fill="#212121"
      />
      <path
        d="M26.006 11.35l1.919.194a4.338 4.338 0 004.287-2.363l1.669-3.337-1.519.275a8.206 8.206 0 00-5.725 4.1l-.631 1.131z"
        fill="#E65100"
      />
      <path
        d="M11.65 12.812a5.624 5.624 0 01-5-3.075L4.444 5.281a.625.625 0 01.668-.906l2.75.5A9.45 9.45 0 0114.456 9.6l1.088 1.962a.625.625 0 01-.482.938l-2.862.287a5.459 5.459 0 01-.55.025z"
        fill="#212121"
      />
      <path
        d="M6.118 5.837l1.669 3.338a4.332 4.332 0 004.287 2.362l1.92-.193-.626-1.132a8.207 8.207 0 00-5.725-4.1l-1.525-.275z"
        fill="#E65100"
      />
      <path
        d="M24.562 28.025a.626.626 0 01-.456-.2 11.038 11.038 0 01-2.856-7.512 10.893 10.893 0 013.65-8.275.623.623 0 01.887.075 7.5 7.5 0 01.707 8.512c-.57.988-1.038 3.413-1.313 6.837a.625.625 0 01-.625.576l.006-.013z"
        fill="#212121"
      />
      <path
        d="M25.218 13.444a9.77 9.77 0 00-2.718 6.869 10.056 10.056 0 001.587 5.468c.306-2.843.75-4.793 1.331-5.793a6.25 6.25 0 00-.193-6.544h-.007z"
        fill="#FFE0B2"
      />
      <path
        d="M15.456 28.006a.625.625 0 01-.625-.575c-.256-3.4-.7-5.768-1.25-6.681a7.5 7.5 0 01-1.037-4.656 7.35 7.35 0 011.687-3.969.626.626 0 01.882-.075 10.75 10.75 0 013.637 8.262 11.056 11.056 0 01-2.838 7.5.626.626 0 01-.456.194z"
        fill="#212121"
      />
      <path
        d="M14.788 13.45a6.087 6.087 0 00-1.007 2.8 6.25 6.25 0 00.863 3.881c.569.931 1 2.831 1.294 5.625a10.075 10.075 0 001.562-5.444 9.768 9.768 0 00-2.712-6.862z"
        fill="#FFE0B2"
      />
      <path
        d="M18.75 31.25a.624.624 0 01-.625-.625V30a.624.624 0 111.25 0v.625a.624.624 0 01-.625.625zm2.5 0a.624.624 0 01-.625-.625V30a.624.624 0 111.25 0v.625a.624.624 0 01-.625.625zM15 18.125a.624.624 0 110-1.249.624.624 0 010 1.249zm10 0a.624.624 0 110-1.249.624.624 0 010 1.249zm-6.875-11.25h-2.5a.625.625 0 110-1.25h2.5a.625.625 0 110 1.25zm6.25 0h-2.5a.625.625 0 110-1.25h2.5a.625.625 0 110 1.25z"
        fill="#212121"
      />
      <path
        d="M16.25 5.625h1.25V5a.625.625 0 10-1.25 0v.625zm6.25 0h1.25V5a.625.625 0 10-1.25 0v.625z"
        fill="#E65100"
      />
    </svg>
  );
}

export default SvgGiraffe;
