import * as React from "react";

function SvgImageAdd(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.006 4.364H5.455c-.904 0-1.637.732-1.637 1.636v13.091c0 .904.733 1.636 1.637 1.636h13.09c.904 0 1.637-.732 1.637-1.636v-8.87a4.603 4.603 0 01-1.636-.389v1.556l-1.637-1.636-5.318 5.318-2.864-2.863-3.272 3.272V6H15.83a4.583 4.583 0 01.175-1.636zM5.455 17.794v1.297h7.842l-4.57-4.57-3.272 3.273zm10.157 1.297h2.934v-5.388l-1.637-1.637-4.16 4.161 2.863 2.864zm-4.43-12.273a2.455 2.455 0 110 4.91 2.455 2.455 0 010-4.91zm0 1.637a.818.818 0 100 1.636.818.818 0 000-1.636z"
        fill="currentColor"
      />
      <path
        stroke="currentColor"
        strokeWidth={1.506}
        d="M17.455 5.457H24M20.729 8.727V2.182"
      />
    </svg>
  );
}

export default SvgImageAdd;
