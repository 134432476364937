import { useSelector } from '@xstate/react';
import { useContext } from 'react';

import { ErrorPage } from '../../auth/views/ErrorPage';
import { AppContext } from '../../common/machine/appContext';

export const AuthErrorView: React.FC = () => {
  const { appService } = useContext(AppContext);
  const error = useSelector(appService, (state) => state.context.error);
  return (
    <ErrorPage
      emojiUnicode="⌛"
      errorCode="Link expired"
      errorMessage={error || 'The link has expired. Please try again.'}
    />
  );
};
