import _isChromatic from 'chromatic/isChromatic';

/**
 * Temporary wrapper for isChromatic until
 * https://github.com/chromaui/chromatic-cli/issues/379
 * lands in a release
 */
export const isChromatic = (): boolean => {
  return typeof window !== 'undefined' && _isChromatic();
};
