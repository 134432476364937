import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { GroupWithMembersFragment } from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Icon } from '../../common/components/Icon';
import { Input } from '../../common/components/Input';
import { useClientSearch } from '../../hello/hooks/useClientSearch';
import { useGroupMembership } from '../hooks/useGroupMembership';
import {
  GroupMemberListItemInvite,
  GroupMemberListItemUser,
} from './GroupMemberListItem';

/**
 * Main export - renders a list of users
 * Can optionally be searchable and/or invitable
 */
export const GroupMemberList: FC<{
  group: Pick<
    GroupWithMembersFragment,
    'members' | 'isPrivate' | 'id' | 'createdBy' | 'membership'
  >;
  searchable?: boolean;
  invitable?: boolean;
}> = ({ group, searchable, invitable }) => {
  if (searchable) {
    return <MemberListWithSearch group={group} invitable={invitable} />;
  }

  return (
    <MemberList members={group.members} group={group} invitable={invitable} />
  );
};

/**
 * Base user list UI
 */
const MemberList: FC<{
  members: GroupWithMembersFragment['members'];
  group: Pick<
    GroupWithMembersFragment,
    'members' | 'isPrivate' | 'id' | 'createdBy' | 'membership'
  >;
  invitable?: boolean;
}> = ({ members, group, invitable }) => {
  const { organization } = useOrganization();
  const { userCanEdit } = useGroupMembership({ group, organization });

  const sortedMembers = [...members].sort((a, b) => {
    if (a.__typename !== b.__typename) {
      return a.__typename === 'User' ? -1 : 1;
    }

    return 0;
  });

  return (
    <>
      {invitable && <GroupMemberListItemInvite group={group} />}
      {sortedMembers.map((m) => {
        return (
          <GroupMemberListItemUser
            key={m.id}
            user={m}
            group={group}
            editable={userCanEdit && m.id !== group.createdBy.id}
          />
        );
      })}
    </>
  );
};

/**
 * Composition of the user list adding client search
 */
const MemberListWithSearch: FC<{
  group: Pick<
    GroupWithMembersFragment,
    'members' | 'isPrivate' | 'id' | 'createdBy' | 'membership'
  >;
  invitable?: boolean;
}> = ({ group, invitable }) => {
  const { t } = useTranslation();
  const { handleSearchInput, results, searchTerm } = useClientSearch(
    group.members,
    ['name', 'email']
  );

  const members = searchTerm ? results : group.members;

  return (
    <>
      <Input
        autoFocus
        placeholder={t('search_members')}
        icon={<Icon name="search" />}
        onChange={handleSearchInput}
        fullWidth
      />
      <x.div pt={4}>
        <MemberList
          members={members || []}
          group={group}
          invitable={invitable}
        />
      </x.div>
    </>
  );
};
