import { UseMultipleSelectionReturnValue } from 'downshift';
import { createContext, useContext } from 'react';

import { AudienceSelection } from './AudienceSelectorTypes';

type AudienceSelectorContextType = {
  multipleSelection: UseMultipleSelectionReturnValue<AudienceSelection>;
};

export const AudienceSelectorContext = createContext<
  AudienceSelectorContextType | undefined
>(undefined);

export const useAudienceSelectorContext = (): AudienceSelectorContextType => {
  const context = useContext(AudienceSelectorContext);

  if (context === undefined) {
    throw new Error(
      'useAudienceSelectorContext must be used within AudienceSelectorContext'
    );
  }
  return context;
};
