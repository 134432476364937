import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { FC, PropsWithChildren } from 'react';

import { useOrganization } from '../../auth/hooks/useOrganization';
import { useIsViewerAdmin } from '../../auth/hooks/useViewer';
import { AddMembersButton } from '../../common/components/AddMembersButton';
import { Button } from '../../common/components/Button';
import { Text } from '../../common/components/Text';
import { useUsers } from '../../common/hooks/useUsers';

export const ProfilesLayout: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['members', 'common']);
  const { organization } = useOrganization();
  const isViewerAdmin = useIsViewerAdmin(organization);
  const { data } = useUsers();

  return (
    <x.div>
      <x.div
        py={5}
        px={6}
        display={{ _: 'none', 'lg-2': 'flex' }}
        justifyContent="space-between"
        borderBottom="default"
        borderBottomColor="gray.100"
        spaceX={2}
        alignItems="center"
      >
        <x.div>
          <Text variant="lg-semibold">Members</Text>
          <Text variant="sm-medium" color="gray.300">
            {!data
              ? ''
              : data.users.totalCount === 0
                ? t('member_count_zero', {
                    ns: 'common',
                  })
                : data.users.totalCount === 1
                  ? t('member_count_one', {
                      ns: 'common',
                    })
                  : t('member_count_other', {
                      count: data.users.totalCount,
                      ns: 'common',
                    })}
          </Text>
        </x.div>

        <x.div display="flex" spaceX={2}>
          <x.div display={{ _: 'none', sm: 'block' }}>
            <AddMembersButton />
          </x.div>
          {isViewerAdmin && (
            <x.div display={{ _: 'none', 'sm-2': 'block' }}>
              <Button
                label={t('members.manage')}
                variant="secondary"
                href={routes.settings
                  .organization(organization.shortId)
                  .members()}
              />
            </x.div>
          )}
        </x.div>
      </x.div>
      <x.div p={6} maxW="4xl" mx="auto" w="full" boxSizing="border-box">
        {children}
      </x.div>
    </x.div>
  );
};
