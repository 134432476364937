import { theme } from '@frond/shared';
import styled, { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, useState } from 'react';

import { Alert } from '../../common/components/Alert';
import { Button } from '../../common/components/Button';
import { CircleCloseButton } from '../../common/components/CloseButton';
import { Heading } from '../../common/components/Heading';
import { Icon } from '../../common/components/Icon';
import { Modal } from '../../common/components/Modal';

export const StyledModal = styled(Modal)<{
  width: string;
  height: string;
  minHeight?: string;
  variant?: 'tabbed' | 'composer' | 'about' | 'default';
  overlayBackground?: string;
  fullscreenBreakpoint: keyof typeof theme.screens;
}>`
  width: 100%;

  &[data-reach-dialog-overlay] {
    padding: 0;
    margin: 0;

    /* Enable scrolling on mobile for fixed positioned overlay */
    transform: translate3d(0, 0, 0);

    @media (min-width: ${(p) => `${theme.screens[p.fullscreenBreakpoint]}px`}) {
      padding: 8 0;
    }
    background: ${(p) => p.overlayBackground};
  }

  [data-reach-dialog-content] {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: ${(p) =>
      p.variant === 'default' ? 0 : p.variant === 'composer' ? 0 : 0};
    border-radius: 0;
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: scroll;
    box-shadow: glossyModal;

    @media (min-width: ${(p) => `${theme.screens[p.fullscreenBreakpoint]}px`}) {
      padding: ${(p) => (p.variant === 'default' ? theme.sizes['6'] : 0)};
      border-radius: 0.75rem;
      width: ${(p) => p.width};
      height: ${(p) => p.height};
      background-color: ${(p) =>
        p.variant === 'composer' ? 'transparent' : 'white'};
      min-height: ${(p) => p.minHeight};
      position: relative;
      top: auto;
      left: auto;
      overflow: visible;
    }
  }

  @media (min-width: ${(p) => `${theme.screens[p.fullscreenBreakpoint]}px`}) {
    min-width: 23.25rem;
  }
`;

export const GroupsModal: React.FC<
  PropsWithChildren<{
    ariaLabel?: string;
    isOpen: boolean;
    onDismiss?: () => void;
    width?: string;
    height?: string;
    minHeight?: string;
    confirmBeforeDismiss?: boolean;
    showClose?: boolean;
    overlayBackground?: string;
    title?: string;
    fullscreenBreakpoint?: keyof typeof theme.screens;
    variant?: 'tabbed' | 'about' | 'composer' | 'default';
    submitButton?: JSX.Element;
  }>
> = ({
  ariaLabel = 'Group modal',
  isOpen,
  onDismiss,
  children,
  width = '528px',
  height = 'fit-content',
  minHeight,
  confirmBeforeDismiss,
  overlayBackground,
  title,
  fullscreenBreakpoint = 'sm',
  variant = 'default',
  submitButton,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showDismissAlert, setShowDismissAlert] = useState(false);

  const handleDismiss = () => {
    if (!confirmBeforeDismiss) {
      setShowModal(false);
      onDismiss?.();
      return;
    }
    setShowDismissAlert(true);
  };

  return (
    <>
      <StyledModal
        ariaLabel={ariaLabel}
        isOpen={showModal || isOpen}
        onDismiss={handleDismiss}
        disableAnimation
        disableFocus
        closeButtonVariant="v3"
        width={width}
        height={height}
        minHeight={minHeight}
        variant={variant}
        confirmBeforeDismiss={confirmBeforeDismiss}
        showClose={false}
        overlayBackground={overlayBackground}
        fullscreenBreakpoint={fullscreenBreakpoint}
        center
      >
        <>
          <x.div
            display={{
              _: 'flex',
              sm: 'none',
            }}
            alignItems="center"
            justifyContent="space-between"
            w="full"
            boxSizing="border-box"
            px={4}
            py={3}
            borderBottom="default"
            borderColor="gray.200"
          >
            <Button
              variant="icon"
              leftElement={<Icon size="6" name="close" color="gray.500" />}
              onClick={handleDismiss}
            />
            {submitButton}
          </x.div>
          <x.div
            display={{
              _: 'none',
              sm: 'flex',
            }}
            position="absolute"
            left="-3.375rem"
            top="0"
          >
            <CircleCloseButton onClick={handleDismiss} />
          </x.div>
        </>
        {title && (
          <Heading variant="xl" mb={4}>
            {title}
          </Heading>
        )}
        <x.div
          px={{ _: variant === 'composer' ? 4 : 0, sm: 0 }}
          py={{ _: variant === 'composer' ? 5 : 0, sm: 0 }}
        >
          {children}
        </x.div>
      </StyledModal>
      {showDismissAlert && (
        <Alert
          headingText={t('dismiss_alert_title')}
          descriptionText={t('dismiss_alert_body')}
          cancelButtonText={t('cancel')}
          submitButtonText={t('close')}
          onCancelClick={() => setShowDismissAlert(false)}
          variant={'warning'}
          onSubmitClick={() => {
            setShowDismissAlert(false);
            setShowModal(false);
            onDismiss?.();
          }}
        />
      )}
    </>
  );
};
