import * as React from 'react';
import { SVGProps } from 'react';

const SvgProfileFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M11.5 2C8.575 2 6.187 4.247 6.187 7s2.388 5 5.313 5 5.313-2.247 5.313-5-2.388-5-5.313-5ZM5.39 14C4.08 14 3 15.016 3 16.25v.6c0 1.47.99 2.789 2.502 3.696C7.012 21.453 9.08 22 11.5 22c2.42 0 4.487-.547 5.998-1.454C19.01 19.639 20 18.32 20 16.85v-.6c0-1.234-1.08-2.25-2.39-2.25H5.39Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgProfileFilled;
