import * as React from "react";
import { SVGProps } from "react";
const SvgSignIn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.708 2.5a2.301 2.301 0 0 0-2.291 2.292v2.5a.625.625 0 1 0 1.25 0v-2.5c0-.583.459-1.042 1.041-1.042h7.5c.583 0 1.042.46 1.042 1.042v10.416c0 .583-.46 1.042-1.042 1.042h-7.5c-.582 0-1.041-.46-1.041-1.042v-2.492a.625.625 0 1 0-1.25 0v2.492A2.301 2.301 0 0 0 7.708 17.5h7.5a2.301 2.301 0 0 0 2.292-2.292V4.792A2.301 2.301 0 0 0 15.208 2.5h-7.5Zm2.494 4.377a.625.625 0 0 0-.436 1.073L11.2 9.382l-8.073-.007a.625.625 0 1 0-.002 1.25l8.076.007-1.434 1.434a.626.626 0 1 0 .884.884l2.5-2.5a.625.625 0 0 0 0-.884l-2.5-2.5a.624.624 0 0 0-.448-.19Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSignIn;
