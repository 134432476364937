import * as React from 'react';
import { SVGProps } from 'react';

const SvgHomeFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="m19.571 7.76-7.107-5.599a.752.752 0 0 0-.928 0l-7.106 5.6A3.728 3.728 0 0 0 3 10.706v9.545c0 .69.56 1.25 1.25 1.25h15.5c.69 0 1.25-.56 1.25-1.25v-9.545a3.73 3.73 0 0 0-1.429-2.945ZM16.25 17.5h-8.5a.75.75 0 0 1 0-1.5h8.5a.75.75 0 0 1 0 1.5Zm0-3h-8.5a.75.75 0 0 1 0-1.5h8.5a.75.75 0 0 1 0 1.5Zm0-3h-8.5a.75.75 0 0 1 0-1.5h8.5a.75.75 0 0 1 0 1.5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgHomeFilled;
