import * as RadioGroup from '@radix-ui/react-radio-group';
import styled, { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import { SeriesSelection } from '../../../../generated/types-and-hooks';
import { Button } from '../../common/components/Button';
import { Heading } from '../../common/components/Heading';
import { Icon } from '../../common/components/Icon';
import { Text } from '../../common/components/Text';
import { GroupsModal } from '../../groups/components/GroupsModal';

const RadioGroupItem = styled(RadioGroup.Item)`
  all: unset;
  border-radius: sm;
  background-color: transparent;
  border: 1px solid;
  border-color: gray.200;
  width: 100%;
  padding: 2;
  transition: all 100ms ease-in-out;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    opacity: 0;
    transition: all 100ms ease-in-out;
  }

  &:hover {
    border-color: brand.200;
  }

  &[data-state='checked'] {
    border-color: brand.300;

    svg {
      opacity: 1;
    }
  }
`;

type EventSeriesSelectionModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onAction: (value: SeriesSelection) => void;
  variant: 'delete' | 'update';
};
export const EventSeriesSelectionModal = ({
  onAction,
  onDismiss,
  isOpen,
  variant,
}: EventSeriesSelectionModalProps) => {
  const { t } = useTranslation(['common', 'events']);
  const [seriesSelection, setSeriesSelection] = useState<SeriesSelection>(
    SeriesSelection.ThisEvent
  );
  const options = [
    {
      value: SeriesSelection.ThisEvent,
      label: 'This event',
    },
    {
      value: SeriesSelection.ThisAndFollowingEvents,
      label: 'This and following events',
    },
    {
      value: SeriesSelection.AllEvents,
      label: 'All events',
    },
  ];
  const heading =
    variant === 'update'
      ? t('events.update_repeating_event', {
          ns: 'events',
        })
      : t('events.delete_repeating_event', {
          ns: 'events',
        });
  return (
    <GroupsModal
      width="400px"
      isOpen={isOpen}
      ariaLabel={heading}
      onDismiss={onDismiss}
    >
      <Heading mb={4} variant="lg" textAlign="center">
        {heading}
      </Heading>

      <RadioGroup.Root
        value={seriesSelection}
        onValueChange={(value) => setSeriesSelection(value as SeriesSelection)}
      >
        <x.div spaceY={2}>
          {options.map((option) => {
            return (
              <RadioGroupItem value={option.value} key={option.value}>
                <Text>{option.label}</Text>
                <Icon color="brand.300" size="5" name="circle-filled-checked" />
              </RadioGroupItem>
            );
          })}
        </x.div>
      </RadioGroup.Root>
      <x.div spaceX={3} display="flex" justifyContent="center" mt={6}>
        <Button
          label={t('cancel')}
          variant="secondary"
          onClick={onDismiss}
          type="button"
        />
        <Button
          label={variant === 'update' ? t('update') : t('delete')}
          variant={variant === 'update' ? 'primary' : 'warning'}
          onClick={() => onAction(seriesSelection)}
          type="button"
        />
      </x.div>
    </GroupsModal>
  );
};
