import {
  ListboxButton,
  ListboxInput,
  ListboxList,
  ListboxOption,
  ListboxPopover,
} from '@reach/listbox';
import { useSelector } from '@xstate/react';
import { styled, th, x } from '@xstyled/styled-components';
import { useContext } from 'react';

import { PostColorTheme } from '../../../../generated/types-and-hooks';
import { topCenter } from '../../common/utils/position';
import { PostComposerStateContext } from './PostComposer';

const POST_COLOR_THEMES: PostColorTheme[] = [
  PostColorTheme.Yellow,
  PostColorTheme.Blue,
  PostColorTheme.Red,
  PostColorTheme.Green,
  PostColorTheme.Purple,
  PostColorTheme.Gray,
];

const StyledListboxPopover = styled(ListboxPopover)`
  background-color: white;
  z-index: popover;
  margin-top: 1;
  border-radius: md;
  max-height: ${th.size('50')};
  overflow-y: scroll;
  padding: 0;
  box-sizing: border-box;

  box-shadow: sm;
  border: none;

  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  animation: slide-down 0.2s ease;

  &:focus-within {
    outline: none;
  }
`;

const StyledListboxButton = styled(ListboxButton)`
  width: 9;
  height: 9;
  border: 1px solid transparent;
  border-color: gray.200;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: md;
  padding: 0;

  &:hover {
    border-color: brand.200;
  }

  &[aria-expanded='true'] {
    border-color: brand.200;
  }
`;

const StyledListboxOption = styled(ListboxOption)`
  padding: 1;
  color: gray.500;
  cursor: default;
  border-radius: 100%;
  border: 2px solid transparent;

  &:hover {
    border-color: brand.200;
  }

  &[data-reach-listbox-option][data-current-selected] {
    border-color: brand.300;
  }

  &[data-reach-listbox-option][data-current-nav] {
    border-color: brand.300;
    background-color: transparent;
  }
`;

const StyledListboxList = styled(ListboxList)`
  padding: 6;
`;
export const PostComposerColorThemePicker = () => {
  const { postComposerService } = useContext(PostComposerStateContext);

  const colorTheme = useSelector(
    postComposerService,
    (state) => state.context.post.colorTheme
  );

  return (
    <ListboxInput
      onChange={(value) =>
        postComposerService.send({
          type: 'SET_COLOR_THEME',
          colorTheme: value as PostColorTheme,
        })
      }
      value={colorTheme}
    >
      <StyledListboxButton>
        <x.div
          borderRadius="full"
          w={5}
          h={5}
          bg={`${colorTheme.toLowerCase()}.${
            colorTheme === PostColorTheme.Gray ? '500' : '400'
          }`}
        />
      </StyledListboxButton>
      <StyledListboxPopover position={topCenter}>
        <StyledListboxList>
          <x.div display="grid" gap={3} gridTemplateColumns="repeat(3, 1fr)">
            {POST_COLOR_THEMES.map((option) => {
              return (
                <StyledListboxOption key={option} value={option}>
                  <x.div
                    w={8}
                    h={8}
                    borderRadius="full"
                    bg={`${option.toLowerCase()}.${
                      option === PostColorTheme.Gray ? '500' : '400'
                    }`}
                  />
                </StyledListboxOption>
              );
            })}
          </x.div>
        </StyledListboxList>
      </StyledListboxPopover>
    </ListboxInput>
  );
};
