import * as React from "react";
import { SVGProps } from "react";
const SvgMessagesFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.25 2.5A3.254 3.254 0 0 0 2 5.75v10.976c0 .48.266.915.695 1.133a1.268 1.268 0 0 0 1.325-.104L8.493 14.5h6.257A3.254 3.254 0 0 0 18 11.25v-5.5a3.254 3.254 0 0 0-3.25-3.25h-9.5ZM6.75 6h6.5a.75.75 0 1 1 0 1.5h-6.5a.75.75 0 1 1 0-1.5ZM19 7.013v4.237a4.255 4.255 0 0 1-4.25 4.25H8.82l-2.44 1.773A3.252 3.252 0 0 0 9.25 19h6.257l4.473 3.254a1.263 1.263 0 0 0 1.325.104c.428-.218.695-.652.695-1.133V10.25a3.252 3.252 0 0 0-3-3.237ZM6.75 9.5h5a.75.75 0 1 1 0 1.5h-5a.75.75 0 1 1 0-1.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMessagesFilled;
