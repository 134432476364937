import * as React from "react";
import { SVGProps } from "react";
const SvgGif = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={3.1}
      y={3.1}
      width={13.8}
      height={13.8}
      rx={1.7}
      stroke="currentColor"
      strokeWidth={1.2}
    />
    <path
      d="M8.194 9.562a.766.766 0 0 0-.107-.237.681.681 0 0 0-.407-.285A1.036 1.036 0 0 0 7.39 9a.974.974 0 0 0-.55.154.985.985 0 0 0-.355.447 1.791 1.791 0 0 0-.126.71c0 .28.04.518.122.716a.972.972 0 0 0 .351.451c.154.104.34.156.559.156.193 0 .356-.031.49-.094a.69.69 0 0 0 .306-.27.787.787 0 0 0 .105-.412l.196.024H7.427v-.77h1.881v.578c0 .39-.083.725-.25 1.004a1.658 1.658 0 0 1-.68.64c-.29.15-.62.225-.996.225-.416 0-.782-.09-1.097-.27a1.875 1.875 0 0 1-.737-.772c-.177-.336-.265-.733-.265-1.193 0-.358.054-.676.16-.953a1.925 1.925 0 0 1 1.11-1.146c.253-.1.525-.15.816-.15.253 0 .488.037.706.11.218.072.412.174.58.306.168.132.305.29.41.471.105.182.17.381.196.599H8.194Zm2.79-1.426V12.5H9.93V8.136h1.055Zm.663 4.364V8.136h2.978v.857h-1.924v.895h1.735v.858H12.7V12.5h-1.054Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgGif;
