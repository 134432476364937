import { getAnimatedThumbnailUrl, getCloudinaryUrl } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { VideoType } from '../../../../generated/graphql-request-api-sdk';
import {
  Image,
  useVideoStatusQuery,
  Video,
  VideoStatus,
} from '../../../../generated/types-and-hooks';
import { VIDEO_POLL_INTERVAL } from '../../../config/constants';
import { ProgressBar } from '../../common/components/ProgressBar';
import { Text } from '../../common/components/Text';
import { useFileUpload } from '../../common/hooks/useFileUpload';
import { usePresignedUpload } from '../../common/hooks/usePresignedUpload';
import { PostComposerStateContext } from './PostComposer';
import { UPLOAD_ERROR_MESSAGE } from './PostComposerErrors';
import { ProcessingMedia } from './postComposerMachine';

export const PostCardProcessing = ({ media }: { media: ProcessingMedia }) => {
  const { postComposerService } = useContext(PostComposerStateContext);
  const [videoId, setVideoId] = useState<string>(!media.file ? media.id : '');
  const { uploadVideo } = usePresignedUpload();
  const uploadingIds = useRef<string[]>([]);
  const [imageSrc, setImageSrc] = useState<string | null>();
  const [loadedMedia, setLoadedMedia] = useState<Video | Image | null>(null);
  const { t } = useTranslation();
  const { uploadSelectedFile } = useFileUpload({
    name: 'user-uploaded-file',
    onUploadSuccess: (file) => {
      if ('publicId' in file) {
        setImageSrc(
          getCloudinaryUrl({ id: file.publicId, transform: 'q_auto' })
        );
        setLoadedMedia(file);
      }
    },
    onUploadProgress: (percentUploaded) =>
      postComposerService.send({
        type: 'SET_UPLOAD_PROGRESS',
        id: media.id,
        percentUploaded,
      }),
    onUploadFailure: () => {
      postComposerService.send({
        type: 'SET_ERROR',
        errorMessage: UPLOAD_ERROR_MESSAGE,
      });
    },
  });

  useVideoStatusQuery({
    variables: {
      videoId,
    },
    pollInterval: VIDEO_POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.video?.status === VideoStatus.Ready && data.video.playbackId) {
        setLoadedMedia(data.video);
        setImageSrc(getAnimatedThumbnailUrl(data.video.playbackId));
      }
    },
    onError: () => {
      postComposerService.send({
        type: 'REMOVE_MEDIA',
        mediaId: media.id,
      });
      postComposerService.send({
        type: 'SET_ERROR',
        errorMessage: UPLOAD_ERROR_MESSAGE,
      });
    },
    skip: !videoId,
  });

  useEffect(() => {
    if (media.file && !uploadingIds.current.includes(media.id)) {
      uploadingIds.current.push(media.id);

      if (media.file.type.startsWith('image')) {
        uploadSelectedFile(media.file);
      } else {
        uploadVideo(VideoType.Profile, false, media.file, (percentUploaded) =>
          postComposerService.send({
            type: 'SET_UPLOAD_PROGRESS',
            percentUploaded,
            id: media.id,
          })
        ).then(({ id }) => {
          setVideoId(id);
        });
      }
    }
  }, [media, postComposerService, uploadSelectedFile, uploadVideo]);

  return (
    <x.div
      h="full"
      w="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="gray.50"
    >
      {imageSrc && loadedMedia ? (
        <x.img
          display="none"
          src={imageSrc}
          crossOrigin="anonymous"
          onLoad={(e) => {
            let base64String: string | undefined = undefined;
            const canvasElement = document.createElement('canvas');
            canvasElement.height = e.currentTarget.naturalHeight;
            canvasElement.width = e.currentTarget.naturalWidth;
            const ctx = canvasElement.getContext('2d');
            if (ctx) {
              ctx.drawImage(
                e.currentTarget,
                0,
                0,
                canvasElement.width,
                canvasElement.height
              );
              base64String = canvasElement.toDataURL('image/png');
            }

            // Passing the base64 string so the cropper uses that instead of
            // the remote image url to avoid loading the image twice
            postComposerService.send({
              type: 'MEDIA_UPLOADED',
              media: {
                ...loadedMedia,
                ...('publicId' in loadedMedia && { base64: base64String }),
              },
              prevId: media.id,
            });
          }}
        />
      ) : null}

      <x.div textAlign="center" minWidth="40%" spaceY={2}>
        <Text as="span" variant="md">
          {videoId ? t('processing') : t('uploading')}
        </Text>
        <ProgressBar
          h={1}
          trackColor="brand.100"
          progressColor="brand.300"
          progress={videoId ? undefined : media.uploadProgress}
        />
      </x.div>
    </x.div>
  );
};
