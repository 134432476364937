import * as React from 'react';
import { SVGProps } from 'react';

const SvgAppGiphy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#app-giphy_svg__a)">
      <path
        d="M23.351.005H2.018C1.282.005.685.602.685 1.338v21.334c0 .736.597 1.333 1.333 1.333H23.35c.737 0 1.334-.597 1.334-1.333V1.338c0-.736-.597-1.333-1.334-1.333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.351 5.338h1.667v13.334H6.35V5.338Z"
        fill="#2AFC9C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.351 8.338h1.667v10.334H17.35V8.338Z"
        fill="#9740FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.351 9.005h1.667v1.667H17.35V9.005Z"
        fill="#4A1F7C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.351 18.338h12.667v1.667H6.35v-1.667Z"
        fill="#22CDFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.351 4.005h8.334v1.667H6.35V4.005Z"
        fill="#FFF39F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.018 4.005h1.667v1.667h1.666v1.666h1.667v1.667h-5v-5Z"
        fill="#FB6769"
      />
    </g>
    <defs>
      <clipPath id="app-giphy_svg__a">
        <path fill="#fff" transform="translate(.685 .005)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAppGiphy;
