import { routes } from '@frond/shared';
import { useSelector } from '@xstate/react';
import styled, { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { useContext, useEffect, useState } from 'react';

import {
  useCreateTemporaryPostMutation,
  useTemporaryPostPollQuery,
} from '../../../../generated/types-and-hooks';
import { BASE_URL } from '../../../config/constants';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { Image } from '../../common/components/Image';
import { Text } from '../../common/components/Text';
import { PostComposerStateContext } from './PostComposer';
import { PostComposerSurfaceBg } from './PostComposerSurfaceBg';

const QR_IMAGE_SIZE_PX = 140;

const QrImage = styled(Image)`
  width: 100%;
  aspect-ratio: 1;

  @media (min-width: sm) {
    width: ${QR_IMAGE_SIZE_PX}px;
    height: ${QR_IMAGE_SIZE_PX}px;
  }
`;
export const PostComposerQrUploadSurface = () => {
  const { t } = useTranslation();
  const [qrCode, setQrCode] = useState<string | null>(null);
  const { viewer: user } = useViewer();
  const { organization } = useOrganization();
  const { postComposerService } = useContext(PostComposerStateContext);
  const group = useSelector(
    postComposerService,
    (state) => state.context.group,
    (prevGroup, nextGroup) => prevGroup?.id === nextGroup?.id
  );

  const postMedia = useSelector(
    postComposerService,
    (state) => state.context.post.media
  );

  const [createTempPost, { data }] = useCreateTemporaryPostMutation({
    onCompleted: ({ createTemporaryPost: { token } }) => {
      setQrCode(`${BASE_URL}${routes.groups.qr(token)}`);
    },
  });

  useTemporaryPostPollQuery({
    variables: {
      token: data?.createTemporaryPost.token || '',
    },
    skip: !data?.createTemporaryPost.token,
    pollInterval: 2000,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ temporaryPost }) => {
      if (temporaryPost) {
        temporaryPost.media.forEach((media) => {
          const existingMedia = postMedia.find((m) => m.id === media.id);
          if (!existingMedia) {
            postComposerService.send({
              type: 'ADD_MEDIA',
              media: 'publicId' in media ? media : { id: media.id },
            });
          }
        });
      }
    },
  });

  useEffect(() => {
    if (!qrCode && user) {
      createTempPost({
        variables: {
          input: {
            groupId: group?.id,
            userId: user.id,
          },
        },
      });
    }
  }, [createTempPost, qrCode, group, user, organization.id]);

  return (
    <x.div mx={-2}>
      <x.div position="relative">
        <PostComposerSurfaceBg>
          <x.div
            h="full"
            w="full"
            display="flex"
            justifyContent="center"
            p={{ _: 8, sm: 24 }}
            boxSizing="border-box"
          >
            {qrCode ? (
              <x.div>
                <Text
                  mb={{ _: 4, sm: 9 }}
                  maxW={310}
                  color="gray.500"
                  textAlign="center"
                  mx="auto"
                >
                  {t('scan_this_code')}
                </Text>
                <x.div display="flex" justifyContent="center">
                  <x.div p={5} bg="white" borderRadius="md">
                    <QrImage
                      src={`https://api.qrserver.com/v1/create-qr-code/?size=${
                        QR_IMAGE_SIZE_PX * 2
                      }x${QR_IMAGE_SIZE_PX * 2}&data=${qrCode}`}
                    />
                  </x.div>
                </x.div>
              </x.div>
            ) : (
              <Text maxW={310} color="gray.500" textAlign="center" mx="auto">
                {t('generating_qr_code')}
              </Text>
            )}
          </x.div>
        </PostComposerSurfaceBg>
      </x.div>
    </x.div>
  );
};
