import styled from '@xstyled/styled-components';

export const PostComposerSurfaceBg = styled.div`
  border-radius: md;
  height: 100%;
  background-color: brand.50;

  @media (min-width: sm) {
    aspect-ratio: 1;
  }
`;
