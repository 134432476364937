import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { GroupWithMembersFragment } from '../../../../generated/graphql-request-api-sdk';
import { GroupTypes } from '../../../../generated/types-and-hooks';
import { Icon } from '../../common/components/Icon';
import { Text } from '../../common/components/Text';
import { CourseEmptyCard } from './CourseEmptyCard';

export const GroupEmpty = ({ group }: { group: GroupWithMembersFragment }) => {
  const { t } = useTranslation();

  if (group.groupType === GroupTypes.Course && group.isDraft) {
    return <CourseEmptyCard group={group} />;
  }

  return (
    <x.div
      w="full"
      h="full"
      flex={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      boxSizing="border-box"
    >
      <x.div
        spaceY={3}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Icon
          name={
            group.groupType === GroupTypes.Course ? 'classroom' : 'send-letter'
          }
          size="12"
        />
        <Text variant="md-semibold" color="gray.300">
          {group.groupType === GroupTypes.Course
            ? t('course_has_no_lessons')
            : t('no_posts_yet')}
        </Text>
      </x.div>
    </x.div>
  );
};
