import * as React from "react";
import { SVGProps } from "react";
const SvgCheckMark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.113 3.744a.625.625 0 0 0-.43.19L6.875 14.74l-4.558-4.558a.625.625 0 1 0-.884.884l5 5a.625.625 0 0 0 .884 0l11.25-11.25a.625.625 0 0 0-.454-1.073Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCheckMark;
