import { x } from '@xstyled/styled-components';
import React, { PropsWithChildren } from 'react';

import { POST_PREVIEW_CARD_WIDTH_PX } from '../../../config/constants';
import { PostComposerMini } from './PostComposerMini';

export const HomeLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <x.div
      data-test="groups-feed"
      display="flex"
      flexDirection="column"
      minH="full"
      maxW={POST_PREVIEW_CARD_WIDTH_PX}
      minWidth={{ md: POST_PREVIEW_CARD_WIDTH_PX }}
      mx="auto"
      spaceY={6}
    >
      <x.div mt={{ 'sm-2': 8 }}>
        <PostComposerMini />
      </x.div>
      {children}
    </x.div>
  );
};
