import { TabsProps } from '@radix-ui/react-tabs';
import { SystemProps, x } from '@xstyled/styled-components';
import { FC, PropsWithChildren } from 'react';

import * as Tabs from '../../common/components/Tabs';
import { GroupsModal } from '../../groups/components/GroupsModal';
import { Heading } from './Heading';
import { ModalProps } from './Modal';
import { Text } from './Text';

export type TabDefinition = {
  [title: string]: React.ReactElement;
};

export type TabbedModalProps = TabbedModalHeaderProps &
  TabbedModalScrollViewProps &
  Pick<TabsProps, 'defaultValue'> &
  Pick<ModalProps, 'ariaLabel' | 'isOpen' | 'onDismiss'> & {
    h?: SystemProps['h'];
  } & {
    actions?: JSX.Element;
  };

export const TabbedModal: FC<TabbedModalProps> = ({
  isOpen,
  onDismiss,
  ariaLabel,
  title,
  actions,
  tabs,
  defaultValue,
  h,
}) => {
  const defaultValueWithFallback = defaultValue || Object.keys(tabs)[0];

  return (
    <GroupsModal
      width="560px"
      isOpen={isOpen}
      ariaLabel={ariaLabel}
      onDismiss={onDismiss}
      variant="tabbed"
    >
      <Tabs.Root defaultValue={defaultValueWithFallback}>
        <TabbedModalLayout h={h}>
          <TabbedModalHeader title={title} actions={actions} tabs={tabs} />
          <TabbedModalScrollView tabs={tabs} />
        </TabbedModalLayout>
      </Tabs.Root>
    </GroupsModal>
  );
};

type TabbedModalHeaderProps = TabbedModalTabsProps & {
  title: string;
  actions?: JSX.Element;
};

const TabbedModalHeader: FC<TabbedModalHeaderProps> = ({
  title,
  actions,
  tabs,
}) => {
  const hasTabs = Object.keys(tabs).length > 1;

  return (
    <x.div
      px={6}
      pt={4}
      pb={hasTabs ? 6 : 4}
      backgroundColor="gray.50"
      display="flex"
      flexDirection="column"
      spaceY={5}
    >
      <x.div display="flex" justifyContent="space-between" alignItems="center">
        <Heading variant="lg">{title}</Heading>
        {actions}
      </x.div>

      {hasTabs && <TabbedModalTabs tabs={tabs} />}
    </x.div>
  );
};

const TabbedModalLayout: FC<PropsWithChildren<{ h?: SystemProps['h'] }>> = ({
  h,
  children,
}) => {
  return (
    <x.div
      h={h}
      borderRadius={{ _: 0, sm: 'lg' }}
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      {children}
    </x.div>
  );
};

type TabbedModalScrollViewProps = { tabs: TabDefinition };

const TabbedModalScrollView: FC<TabbedModalScrollViewProps> = ({ tabs }) => {
  return (
    <x.div
      flex={1}
      overflowY="auto"
      bg="white"
      display="flex"
      flexDirection="column"
    >
      {Object.entries(tabs).map(([t, C]) => (
        <Tabs.Content value={t} key={t}>
          {C}
        </Tabs.Content>
      ))}
    </x.div>
  );
};

type TabbedModalTabsProps = { tabs: TabDefinition };

const TabbedModalTabs: FC<TabbedModalTabsProps> = ({ tabs }) => {
  const titles = Object.keys(tabs);

  return (
    <Tabs.List>
      {titles.map((t) => (
        <Tabs.Trigger value={t} key={t}>
          <Text variant="sm-semibold" color="gray.500">
            {t}
          </Text>
        </Tabs.Trigger>
      ))}
    </Tabs.List>
  );
};

export const TabbedModalContentInset: FC<PropsWithChildren<SystemProps>> = (
  props
) => {
  return (
    <x.div p={6} display="flex" flexDirection="column" flex={1} {...props} />
  );
};
