import * as React from "react";
import { SVGProps } from "react";
const SvgReaction = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 1.667c-4.595 0-8.333 3.738-8.333 8.333 0 4.595 3.738 8.333 8.333 8.333 4.595 0 8.333-3.738 8.333-8.333 0-4.595-3.738-8.333-8.333-8.333Zm0 1.25A7.074 7.074 0 0 1 17.083 10 7.074 7.074 0 0 1 10 17.083 7.074 7.074 0 0 1 2.917 10 7.074 7.074 0 0 1 10 2.917ZM7.5 6.875a1.042 1.042 0 1 0 0 2.083 1.042 1.042 0 0 0 0-2.083Zm5 0a1.042 1.042 0 1 0 0 2.084 1.042 1.042 0 0 0 0-2.084ZM6.572 11.66a.626.626 0 0 0-.496 1.018A4.988 4.988 0 0 0 10 14.583c1.586 0 3.01-.743 3.924-1.905a.624.624 0 0 0-.797-.935.624.624 0 0 0-.185.162A3.727 3.727 0 0 1 10 13.333a3.727 3.727 0 0 1-2.942-1.428.625.625 0 0 0-.486-.245Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgReaction;
