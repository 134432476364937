import * as React from "react";

function SvgAppVimeo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-vimeo_svg__clip0)">
        <path
          d="M23.735 6.59c-.103 2.233-1.666 5.29-4.689 9.173-3.126 4.054-5.772 6.081-7.936 6.081-1.34 0-2.474-1.236-3.401-3.71L5.854 11.33c-.688-2.474-1.427-3.71-2.216-3.71-.172 0-.773.36-1.804 1.081L.752 7.311c1.124-.988 2.24-1.984 3.35-2.99 1.511-1.305 2.645-1.992 3.4-2.06 1.788-.172 2.887 1.048 3.3 3.659.445 2.817.755 4.57.927 5.256.515 2.336 1.081 3.504 1.7 3.504.48 0 1.202-.756 2.164-2.268.963-1.511 1.478-2.662 1.546-3.452C17.276 7.654 16.761 7 15.593 7c-.549 0-1.117.12-1.7.36 1.134-3.676 3.298-5.461 6.494-5.358 2.37.069 3.485 1.598 3.348 4.587z"
          fill="#1AB7EA"
        />
      </g>
      <defs>
        <clipPath id="app-vimeo_svg__clip0">
          <path
            fill="#fff"
            transform="translate(.749 1.999)"
            d="M0 0h23v19.844H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppVimeo;
