import { theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '../../../common/components/Button';

export type StepDataTest =
  | 'groups-onboarding-organization'
  | 'groups-onboarding-profile'
  | 'groups-onboarding-templates';

/**
 * View component wrapping form components with next button for onboarding
 */
export const StepView: React.FC<
  PropsWithChildren<{
    loading?: boolean;
    dataTest?: StepDataTest;
    responsiveButton?: boolean;
  }>
> = ({ children, loading, dataTest, responsiveButton = true }) => {
  const form = useFormContext();
  const { t } = useTranslation();
  return (
    <x.div
      display="flex"
      flexDirection="column"
      justifyContent="center"
      boxSizing="border-box"
      w="full"
      mt={{ _: 13, 'lg-3': 0 }}
      px={{ _: 6, 'lg-3': 28 }}
    >
      {children}
      <Button
        type="submit"
        data-test={dataTest}
        label={t('next')}
        loading={loading}
        disabled={!form.formState.isValid}
        top={`${theme.sizes['10']}`}
        right={`${theme.sizes['10']}`}
        position="absolute"
        {...(responsiveButton && {
          h: { _: '12', sm: 'initial' },
          my: { _: '6', sm: '0' },
          position: { _: 'initial', sm: 'absolute' },
        })}
      />
    </x.div>
  );
};
