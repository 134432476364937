import { Position } from '@reach/popover';
import { TooltipPopup, TooltipPopupProps, useTooltip } from '@reach/tooltip';
import styled, {
  css,
  system,
  SystemProps,
  th,
} from '@xstyled/styled-components';
import React, { MutableRefObject, useState } from 'react';

import { topCenter } from '../utils/position';

const StyledTooltipPopup = styled(TooltipPopup)<
  TooltipPopupProps & {
    variant?: 'light' | 'dark';
    size?: 'small' | 'regular';
  }
>`
  &[data-reach-tooltip] {
    box-sizing: border-box;
    z-index: tooltip;
    background-color: ${(p) =>
      p.variant === 'light' ? 'white' : th.color('gray.500')};
    color: ${(p) => (p.variant === 'light' ? th.color('gray.500') : 'white')};
    border: 0;
    border-radius: md;
    ${({ size }) =>
      size === 'regular'
        ? css`
            padding: 3;
          `
        : css`
            padding: 2 4;
          `}

    box-shadow: base;
    white-space: pre-wrap;
    ${system}
  }
`;

export type TooltipVariant = 'light' | 'dark';

export type TooltipProps = {
  children: JSX.Element;
  label: JSX.Element;
  'aria-label': string;
  maxWidth?: number | string;
  ref?: MutableRefObject<HTMLDivElement | null>;
  visible?: boolean;
  onVisible?: () => void;
  variant?: TooltipVariant;
  size?: 'small' | 'regular';
  position?: Position;
  disabled?: boolean;
  persistTooltipOnMouseOver?: boolean;
};

export const Tooltip: React.FC<
  Omit<SystemProps, 'position' | 'color'> & TooltipProps
> = ({
  children,
  disabled,
  label,
  'aria-label': ariaLabel,
  maxWidth = 213,
  visible,
  onVisible,
  variant,
  position = topCenter,
  size = 'regular',
  persistTooltipOnMouseOver,
  ...props
}) => {
  const [mouseOverPopover, setMouseOverPopover] = useState(false);
  const [trigger, tooltip] = useTooltip({
    DEBUG_STYLE: visible,
  });
  const { isVisible } = tooltip;

  if (isVisible) onVisible?.();

  return (
    <>
      {React.cloneElement(children, trigger)}
      {!disabled && (
        <StyledTooltipPopup
          {...tooltip}
          label={label}
          position={position}
          aria-label={ariaLabel}
          variant={variant}
          size={size}
          style={{ maxWidth }}
          onMouseEnter={() => setMouseOverPopover(true)}
          onMouseLeave={() => setMouseOverPopover(false)}
          isVisible={
            isVisible ||
            visible ||
            (persistTooltipOnMouseOver && mouseOverPopover)
          }
          {...props}
        />
      )}
    </>
  );
};
