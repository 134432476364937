import { createContext, useContext } from 'react';

export type NextEnv = 'production' | 'development' | 'test';

export const NextContext = createContext<
  | {
      NextEnv: NextEnv;
    }
  | undefined
>(undefined);

export const useNextEnv = (): NextEnv | undefined => {
  const context = useContext(NextContext);
  return context?.NextEnv;
};
