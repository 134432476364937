import {
  ListboxButton,
  ListboxInput,
  ListboxList,
  ListboxOption,
  ListboxPopover,
} from '@reach/listbox';
import { styled, th, x } from '@xstyled/styled-components';
import React from 'react';

import {
  Maybe,
  MemberSubscriptionPlan,
  OrganizationRoleColor,
  refetchOrganizationRolesQuery,
  useUpdateOrganizationRoleMutation,
} from '../../../../../generated/types-and-hooks';
import {
  OrganizationRole,
  UserPreviewFragment,
} from '../../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../../auth/hooks/useOptionalOrganization';
import { topCenter } from '../../../common/utils/position';
import { COMMUNITY_ROLE_COLOR } from './CommunityRoleItem';
import { getBackgroundColor } from './CommunityRoleToken';

const StyledListboxPopover = styled(ListboxPopover)`
  background-color: white;
  z-index: popover;
  margin-top: 1;
  border-radius: md;
  max-height: ${th.size('50')};
  overflow-y: scroll;
  padding: 0;
  box-sizing: border-box;

  box-shadow: sm;
  border: none;

  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  animation: slide-down 0.2s ease;

  &:focus-within {
    outline: none;
  }
`;

const StyledListboxButton = styled(ListboxButton)`
  width: 5;
  height: 5;
  border: 1px solid transparent;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: md;
  padding: 0;
`;

const StyledListboxOption = styled(ListboxOption)`
  padding: 1;
  color: gray.500;
  cursor: default;
  border-radius: 100%;
  border: 2px solid transparent;

  &[data-reach-listbox-option][data-current-nav] {
    background-color: transparent;
  }
`;

const StyledListboxList = styled(ListboxList)`
  padding: 6;
`;
export const CommunityRoleItemColorPicker: React.FC<{
  role: Omit<OrganizationRole, 'users' | 'memberSubscriptionPlan'> & {
    users: UserPreviewFragment[];
    memberSubscriptionPlan?: Maybe<
      Pick<MemberSubscriptionPlan, '__typename' | 'id' | 'name'>
    >;
  };
}> = ({ role }) => {
  const { organization } = useOptionalOrganization();
  const [mutateUpdateOrganizationRole] = useUpdateOrganizationRoleMutation({
    refetchQueries: [
      refetchOrganizationRolesQuery({
        organizationId: organization?.id || '',
      }),
    ],
  });

  const handleChange = (value: string) => {
    mutateUpdateOrganizationRole({
      variables: {
        input: {
          roleId: role.id,
          label: role.label,
          color: value as OrganizationRoleColor,
        },
      },
    });
  };

  return (
    <ListboxInput onChange={handleChange} value={role.color}>
      <StyledListboxButton>
        <x.div>
          <x.div
            borderRadius="full"
            w={5}
            h={5}
            bg={getBackgroundColor(role.color)}
          />
        </x.div>
      </StyledListboxButton>
      <StyledListboxPopover position={topCenter}>
        <StyledListboxList>
          <x.div display="grid" gap={3} gridTemplateColumns="repeat(3, 1fr)">
            {Object.keys(COMMUNITY_ROLE_COLOR).map((option) => {
              return (
                <StyledListboxOption key={option} value={option}>
                  <x.div
                    w={8}
                    h={8}
                    borderRadius="full"
                    bg={getBackgroundColor(option as OrganizationRoleColor)}
                  />
                </StyledListboxOption>
              );
            })}
          </x.div>
        </StyledListboxList>
      </StyledListboxPopover>
    </ListboxInput>
  );
};
