import { isChromatic } from '../utils/isChromatic';
import { useNextEnv } from '../utils/providers';

type UseModalInput = {
  disableAnimation?: boolean;
  metadata?: { context?: string };
};

export const useModal = ({ disableAnimation = false }: UseModalInput) => {
  const NextEnv = useNextEnv();
  const skipTransition = disableAnimation || isChromatic() || NextEnv == 'test';

  const animationProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: skipTransition ? 0 : 0.15 },
  };

  return {
    animationProps,
  };
};
