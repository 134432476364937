import * as React from "react";

function SvgAppDropboxPaper(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.74 16.235L12.044 22.8l10.303-6.565-10.303-6.563L1.74 16.235z"
        fill="#B4D0E7"
      />
      <path
        d="M1.74 7.763l10.304 6.565 10.303-6.565L12.044 1.2 1.74 7.763z"
        fill="#0061FF"
      />
    </svg>
  );
}

export default SvgAppDropboxPaper;
