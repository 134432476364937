import * as React from "react";
import { SVGProps } from "react";
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.25 3A3.262 3.262 0 0 0 3 6.25v11.5A3.262 3.262 0 0 0 6.25 21h11.5A3.261 3.261 0 0 0 21 17.75V6.25A3.262 3.262 0 0 0 17.75 3H6.25Zm0 1.5h11.5c.975 0 1.75.775 1.75 1.75V7h-15v-.75c0-.975.775-1.75 1.75-1.75Zm-1.75 4h15v9.25a1.74 1.74 0 0 1-1.75 1.75H6.25a1.74 1.74 0 0 1-1.75-1.75V8.5Zm3.25 2a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm4.25 0a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm4.25 0a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM7.75 15a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM12 15a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCalendar;
