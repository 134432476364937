import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';

import { GroupTypes } from '../../../../../generated/types-and-hooks';
import { useIsViewerAdmin } from '../../../auth/hooks/useViewer';
import { Icon, IconNames } from '../../../common/components/Icon';
import { Text } from '../../../common/components/Text';
import { EventModal } from '../../../events/components/EventModal/EventModal';
import { OrganizationShareModal } from '../../../organizations/components/OrganizationShareModal';
import { useCanViewerCreateGroups } from '../../../organizations/hooks/useCanViewerCreateGroups';
import { useOrganizationEntitlements } from '../../../organizations/hooks/useOrganizationEntitlements';
import { PostComposer } from '../../../posts/components/PostComposer';
import { GroupCreateModal } from '../GroupCreateModal';
import { GroupsSidebarOrganization } from './GroupsSidebar';

const SHARED_TRANSITION_PROPS = {
  transition: true,
  transitionDuration: 'fast',
  transitionTimingFunction: 'ease-in-out',
};

const GroupsGlobalCreateMenuTrigger: React.FC<{
  isOpen: boolean;
  onClick?: () => void;
}> = ({ isOpen, onClick }) => {
  const { t } = useTranslation();
  return (
    <x.div
      display="flex"
      alignItems="center"
      py={top ? 2 : 1}
      px={3}
      minH={top ? 9 : 6}
      boxSizing="border-box"
      onClick={onClick}
      cursor="pointer"
      userSelect="none"
      color={{ _: 'gray.500', hover: 'brand.300' }}
    >
      <x.div
        display="flex"
        alignItems="center"
        justifyContent="center"
        h="1.125rem"
        w="1.125rem"
        mr={2}
      >
        <Icon
          transform
          rotate={isOpen ? 45 : 0}
          name="plus"
          size="4"
          transitionProperty="transform, color"
          {...SHARED_TRANSITION_PROPS}
        />
      </x.div>
      <Text
        variant="sm-semibold"
        color={isOpen ? 'white' : undefined}
        transitionProperty="color"
        {...SHARED_TRANSITION_PROPS}
      >
        {t('add')}
      </Text>
    </x.div>
  );
};

const GroupsGlobalCreateMenuItem: React.FC<{
  icon: IconNames;
  label?: string;
  onClick?: () => void;
}> = ({ icon, label, onClick }) => {
  return (
    <x.div
      display="flex"
      alignItems="center"
      py={2}
      px={3}
      minH={6}
      boxSizing="border-box"
      onClick={onClick}
      cursor="pointer"
      userSelect="none"
      color={{ _: 'gray.500', hover: 'brand.300' }}
    >
      <Icon name={icon} size="4.5" mr={2} />
      <Text variant="md">{label}</Text>
    </x.div>
  );
};

export const GroupsGlobalCreateButton: React.FC<{
  organization: GroupsSidebarOrganization;
}> = ({ organization }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const isViewerAdmin = useIsViewerAdmin(organization);
  const canCreateGroups = useCanViewerCreateGroups({ organization });

  const [showModalVariant, setShowModalVariant] = useState<
    'post' | 'event' | 'group' | 'course' | 'member' | null
  >(null);
  const { features } = useOrganizationEntitlements();

  const [menuHeight, setMenuHeight] = useState(0);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (menuRef.current) setMenuHeight(menuRef.current.clientHeight);
  }, [menuRef, features]);

  const toggleModal = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
      // Using setTimeout, because capturing has weird behavior with closing the menu
      setTimeout(() => {
        window.addEventListener(
          'click',
          () => {
            setIsOpen(false);
          },
          { once: true }
        );
      }, 0);
    }
  };

  const items = [
    {
      label: t('post'),
      icon: 'post',
      onClick: () => setShowModalVariant('post'),
      hide: false,
    },
    {
      label: t('event'),
      icon: 'calendar',
      onClick: () => setShowModalVariant('event'),
      hide:
        !features.Events || !isViewerAdmin || !organization.hasEventsEnabled,
    },
    {
      label: t('group'),
      icon: 'groups',
      onClick: () => setShowModalVariant('group'),
      hide: !canCreateGroups,
    },
    {
      label: t('course'),
      icon: 'courses',
      onClick: () => setShowModalVariant('course'),
      hide:
        !isViewerAdmin || !features.Courses || !organization.hasCoursesEnabled,
    },
    {
      label: t('member'),
      icon: 'members',
      onClick: () => setShowModalVariant('member'),
      hide: false,
    },
  ];

  // Return null if we're not on a page that's wrapped with AuthContext because of the
  // create modals below
  if (!router.pathname.includes('[shortId]')) {
    return null;
  }

  return (
    <>
      <x.div position="relative" mb={6} pb={4}>
        <x.div position="absolute" w="full">
          <x.div
            bg="white"
            borderRadius="md"
            border="default"
            borderColor={
              isOpen ? 'white' : { _: 'gray.200', hover: 'brand.300' }
            }
            boxShadow={isOpen ? 'sm' : undefined}
            overflow="hidden"
            h={isOpen ? menuHeight : 9}
            transitionProperty="height, box-shadow, border-color"
            {...SHARED_TRANSITION_PROPS}
          >
            <x.div ref={menuRef}>
              <GroupsGlobalCreateMenuTrigger
                isOpen={isOpen}
                onClick={toggleModal}
              />
              <x.div pb={2}>
                {items.map((item) => {
                  if (item.hide) {
                    return null;
                  }
                  return (
                    <GroupsGlobalCreateMenuItem key={item.label} {...item} />
                  );
                })}
              </x.div>
            </x.div>
          </x.div>
        </x.div>
      </x.div>

      {showModalVariant === 'post' && (
        <PostComposer
          isOpen={showModalVariant === 'post'}
          onDismiss={() => setShowModalVariant(null)}
        />
      )}

      <EventModal
        isOpen={showModalVariant === 'event'}
        onDismiss={() => setShowModalVariant(null)}
      />
      <GroupCreateModal
        organization={organization}
        type={GroupTypes.Regular}
        isOpen={showModalVariant === 'group'}
        onDismiss={() => setShowModalVariant(null)}
      />
      <GroupCreateModal
        organization={organization}
        type={GroupTypes.Course}
        isOpen={showModalVariant === 'course'}
        onDismiss={() => setShowModalVariant(null)}
      />
      <OrganizationShareModal
        isOpen={showModalVariant === 'member'}
        onDismiss={() => setShowModalVariant(null)}
        organization={organization}
      />
    </>
  );
};
