import * as React from "react";
import { SVGProps } from "react";
const SvgDraftsFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.208 1.667a1.877 1.877 0 0 0-1.875 1.875v12.916c0 1.034.842 1.875 1.875 1.875h3.977c.015-.103.04-.206.077-.306v.001c.023-.093.05-.19.08-.292l.224-.785.327-1.144c.098-.336.28-.644.529-.892l4.916-4.917c.383-.384.84-.637 1.328-.752v-.913h-4.791A1.877 1.877 0 0 1 10 6.458V1.667H5.208Zm6.042.366v4.425c0 .345.28.625.625.625H16.3l-5.05-5.05ZM17.29 10c-.113 0-.229.01-.345.032-.368.066-.72.258-1.017.556l-4.916 4.916a1.253 1.253 0 0 0-.317.535l-.654 2.284a.618.618 0 0 0 .803.803l2.284-.654c.2-.059.385-.168.534-.317l4.917-4.916c.298-.297.49-.649.556-1.017a2 2 0 0 0 .03-.346 1.85 1.85 0 0 0-.548-1.326A1.855 1.855 0 0 0 17.29 10Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgDraftsFilled;
