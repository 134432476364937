import {
  convertCronExpressionTimezone,
  getDaysFromCronExpression,
  getHourFromCronExpression,
  getTimeZoneName,
  theme,
} from '@frond/shared';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import styled, { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { GroupCoreFragment } from '../../../../../../generated/graphql-request-api-sdk';
import { useViewerTimeZone } from '../../../../auth/hooks/useViewer';
import { Button } from '../../../../common/components/Button';
import { Input } from '../../../../common/components/Input';
import { Select } from '../../../../common/components/Select';
import { Switch } from '../../../../common/components/Switch';
import { Text } from '../../../../common/components/Text';
import { MAX_LENGTH_FOR_QUESTION_INPUT } from '../../../../common/config/constants';
import {
  GroupEditSetting,
  GroupEditSettingPositioning,
} from './GroupEditSetting';
import { GroupEditSettingsReminderFormData } from './GroupEditSettingsReminderForm';
import { GroupEditSettingsToken } from './GroupEditSettingsToken';

const StyledCheckbox = styled(CheckboxPrimitive.Root)`
  all: unset;
  width: calc(${theme.sizes[9]} - 2px);
  height: calc(${theme.sizes[9]} - 2px);
  border-radius: full;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: brand.200;
  background-color: brand.50;
  cursor: pointer;
  transition-property: ${theme.transitions.property.colors};
  transition-duration: faster;

  &[data-state='checked'] {
    background-color: brand.300;
    color: white;
  }

  &:hover {
    border-color: brand.300;
  }

  &:focus {
    box-shadow: 0 0 0 2px black;
  }
`;

const DEFAULT_TIME_OF_DAY = '9';

const daysOptions = [
  { en: 'M', de: 'M', es: 'L', fr: 'L', value: '1' },
  { en: 'T', de: 'D', es: 'M', fr: 'M', value: '2' },
  { en: 'W', de: 'M', es: 'X', fr: 'M', value: '3' },
  { en: 'T', de: 'D', es: 'J', fr: 'J', value: '4' },
  { en: 'F', de: 'F', es: 'V', fr: 'V', value: '5' },
  { en: 'S', de: 'S', es: 'S', fr: 'S', value: '6' },
  { en: 'S', de: 'S', es: 'D', fr: 'D', value: '0' },
] as const;

const timeOfDayOptions = {
  twelve: [
    { label: '12:00 am', value: '0' },
    { label: '1:00 am', value: '1' },
    { label: '2:00 am', value: '2' },
    { label: '3:00 am', value: '3' },
    { label: '4:00 am', value: '4' },
    { label: '5:00 am', value: '5' },
    { label: '6:00 am', value: '6' },
    { label: '7:00 am', value: '7' },
    { label: '8:00 am', value: '8' },
    { label: '9:00 am', value: '9' },
    { label: '10:00 am', value: '10' },
    { label: '11:00 am', value: '11' },
    { label: '12:00 pm', value: '12' },
    { label: '1:00 pm', value: '13' },
    { label: '2:00 pm', value: '14' },
    { label: '3:00 pm', value: '15' },
    { label: '4:00 pm', value: '16' },
    { label: '5:00 pm', value: '17' },
    { label: '6:00 pm', value: '18' },
    { label: '7:00 pm', value: '19' },
    { label: '8:00 pm', value: '20' },
    { label: '9:00 pm', value: '21' },
    { label: '10:00 pm', value: '22' },
    { label: '11:00 pm', value: '23' },
  ],
  twentyFour: [
    { label: '0:00', value: '0' },
    { label: '1:00', value: '1' },
    { label: '2:00', value: '2' },
    { label: '3:00', value: '3' },
    { label: '4:00', value: '4' },
    { label: '5:00', value: '5' },
    { label: '6:00', value: '6' },
    { label: '7:00', value: '7' },
    { label: '8:00', value: '8' },
    { label: '9:00', value: '9' },
    { label: '10:00', value: '10' },
    { label: '11:00', value: '11' },
    { label: '12:00', value: '12' },
    { label: '13:00', value: '13' },
    { label: '14:00', value: '14' },
    { label: '15:00', value: '15' },
    { label: '16:00', value: '16' },
    { label: '17:00', value: '17' },
    { label: '18:00', value: '18' },
    { label: '19:00', value: '19' },
    { label: '20:00', value: '20' },
    { label: '21:00', value: '21' },
    { label: '22:00', value: '22' },
    { label: '23:00', value: '23' },
  ],
} as const;

export const GroupEditSettingReminder: FC<
  {
    group?: GroupCoreFragment;
    showSwitch?: boolean;
    loading: boolean;
  } & GroupEditSettingPositioning
> = ({ group, showSwitch = true, loading, first, last }) => {
  const { t, i18n } = useTranslation(['groups', 'common']);
  const viewerTimeZone = useViewerTimeZone();
  const form = useFormContext<GroupEditSettingsReminderFormData>();
  const { isValid, isDirty } = form.formState;

  const questionEnabled = form.watch('questionEnabled');
  const questionText = form.watch('questionText');
  const questionCronExpression = form.watch('questionCronExpression');

  const rawDays = questionCronExpression
    ? getDaysFromCronExpression(
        questionCronExpression,
        group?.timeZone,
        viewerTimeZone
      )
    : [1, 2, 3, 4, 5];

  const days = daysOptions.map((dayOption) =>
    rawDays.includes(Number(dayOption.value))
  );

  const timeOfDay =
    (questionCronExpression &&
      getHourFromCronExpression(
        questionCronExpression,
        group?.timeZone,
        viewerTimeZone
      )) ||
    DEFAULT_TIME_OF_DAY;

  const handleToggleEnabled = (enabled: boolean) => {
    form.setValue('questionEnabled', enabled, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handlePrefill = (text: string) => {
    form.setValue('questionText', text, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const convertCronToGroupTimezone = (cronInViewerTZ: string) => {
    // If the group doesn't exist yet, group.timezone === viewerTimezone
    if (!group?.timeZone || !viewerTimeZone) {
      return cronInViewerTZ;
    }

    const cronInGroupTZ = convertCronExpressionTimezone(
      cronInViewerTZ,
      viewerTimeZone,
      group.timeZone
    );

    return formatCronExpression(
      cronInGroupTZ.timeOfDay24?.toString() || DEFAULT_TIME_OF_DAY,
      daysOptions.map((dayOption) =>
        cronInGroupTZ.days.includes(Number(dayOption.value))
      )
    );
  };

  const handleChangeTimeOfDay = (timeOfDay: string) => {
    const cronInViewerTZ = formatCronExpression(timeOfDay, days);
    const cronInGroupTZ = convertCronToGroupTimezone(cronInViewerTZ);

    form.setValue('questionCronExpression', cronInGroupTZ, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleChangeCheckedDays = (dayValue: string, checked: boolean) => {
    const updatedDays = [...days];
    const dayIndex = daysOptions.findIndex((o) => o.value == dayValue);
    updatedDays[dayIndex] = checked;

    const cronInViewerTZ = formatCronExpression(timeOfDay, updatedDays);
    const cronInGroupTZ = convertCronToGroupTimezone(cronInViewerTZ);

    form.setValue('questionCronExpression', cronInGroupTZ, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const localizedTimeOfDayOptions =
    i18n.language === 'en'
      ? timeOfDayOptions.twelve
      : timeOfDayOptions.twentyFour;

  const options = localizedTimeOfDayOptions.map((o) => ({
    value: o.value,
    label: o.label,
  }));

  return (
    <GroupEditSetting
      title={showSwitch ? t('groups.reminder') : undefined}
      first={first}
      last={last}
      toggle={
        showSwitch ? (
          <Switch
            checked={!!questionEnabled}
            onCheckedChange={handleToggleEnabled}
          />
        ) : undefined
      }
    >
      <Text variant="md" color="gray.300">
        {t('groups.reminder_explanation')}
      </Text>
      {questionEnabled && (
        <>
          <x.div pt={3}>
            <Input
              {...form.register('questionText')}
              placeholder={t('groups.reminder_placeholder')}
              isMultiLine
              maxLength={MAX_LENGTH_FOR_QUESTION_INPUT}
              onKeyDown={(e) => e.code === 'Enter' && e.preventDefault()}
              rows={3}
              fullWidth
            />
            <x.div
              pt={2}
              pb={1}
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              alignItems="start"
            >
              {!questionText && (
                <>
                  <GroupEditSettingsToken
                    name={t('groups.reminder_suggestion_1')}
                    onClick={handlePrefill}
                    variant="sm"
                  />
                  <GroupEditSettingsToken
                    name={t('groups.reminder_suggestion_2')}
                    onClick={handlePrefill}
                    variant="sm"
                  />
                  <GroupEditSettingsToken
                    name={t('groups.reminder_suggestion_3')}
                    onClick={handlePrefill}
                    variant="sm"
                  />
                  <GroupEditSettingsToken
                    name={t('groups.reminder_suggestion_4')}
                    onClick={handlePrefill}
                    variant="sm"
                  />
                </>
              )}
            </x.div>

            <x.div spaceY={4}>
              <x.div spaceY={2}>
                <Select
                  name="timeOfDay"
                  options={options}
                  onChange={handleChangeTimeOfDay}
                  value={timeOfDay}
                  suffix={getTimeZoneName(viewerTimeZone)}
                />
                <x.div spaceY={2}>
                  <x.div display="flex" spaceX={2}>
                    {daysOptions.map((day) => {
                      return (
                        <StyledCheckbox
                          key={day.value}
                          checked={rawDays.includes(Number(day.value))}
                          onCheckedChange={(checked) =>
                            handleChangeCheckedDays(day.value, !!checked)
                          }
                        >
                          <Text variant="md-semibold">
                            {day[i18n.language as keyof typeof day] || day.en}
                          </Text>
                        </StyledCheckbox>
                      );
                    })}
                  </x.div>
                </x.div>
              </x.div>
            </x.div>
          </x.div>

          <x.div display="flex" pt={4}>
            <x.div marginLeft="auto">
              <Button
                label={t('save', { ns: 'common' })}
                loading={loading}
                disabled={!isValid || !isDirty}
                type="submit"
              />
            </x.div>
          </x.div>
        </>
      )}
    </GroupEditSetting>
  );
};

const formatCronExpression = (timeOfDay: string, days: boolean[]) => {
  const daysString = daysOptions
    .map((option, index) => {
      return days[index] ? option.value : null;
    })
    .filter((val) => val)
    .join(',');

  return `0 ${timeOfDay} * * ${daysString}`;
};
