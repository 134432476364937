import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Icon } from '../../../common/components/Icon';
import { Text } from '../../../common/components/Text';

export const GroupsSwitchCommunityButton: FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  return (
    <>
      <x.button
        w={{ _: 'full', md: 'auto' }}
        cursor="pointer"
        border="none"
        p={{ _: '2', sm: '1 2' }}
        backgroundColor={{ _: 'gray.50', hover: 'yellow.100' }}
        color="gray.300"
        display="flex"
        alignItems="center"
        justifyContent={{ _: 'center', sm: 'flex-start' }}
        spaceX="2"
        borderRadius="sm"
        transitionDuration="fast"
        transitionTimingFunction="ease-in-out"
        transitionProperty="color"
        onClick={() => router.push(routes.groups.organizations())}
      >
        <Icon name="switch" size="5" />
        <Text variant="md" as="span">
          {t('switch_or_create_community')}
        </Text>
      </x.button>
    </>
  );
};
