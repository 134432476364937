import { yupResolver } from '@hookform/resolvers/yup';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  GroupTypes,
  PostFeedFragment,
  PostTypes,
  refetchHomeFeedQuery,
  refetchPostsByGroupQuery,
  useGroupsQuery,
  useMovePostMutation,
} from '../../../../generated/types-and-hooks';
import {
  GROUPS_PER_PAGE_SELECTOR,
  POSTS_PER_PAGE,
} from '../../../config/constants';
import { Button } from '../../common/components/Button';
import { Form } from '../../common/components/Form';
import { Heading } from '../../common/components/Heading';
import { GroupsModal } from '../../groups/components/GroupsModal';
import { PostComposerGroupSelector } from './PostComposerGroupSelector';

type MovePostModalProps = {
  post: PostFeedFragment;
  onDismiss: () => void;
};

const FormSchema = yup
  .object({
    groupId: yup.string().required().uuid(),
  })
  .defined();

type FormData = yup.InferType<typeof FormSchema>;
export const MovePostModal = ({ post, onDismiss }: MovePostModalProps) => {
  const { t } = useTranslation();
  const [group, setGroup] = useState<{
    id: string;
    name: string;
    emoji: string;
    groupType: GroupTypes;
  }>(() => post.group);
  const form = useForm<FormData>({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      groupId: post.group.id,
    },
  });

  const { data } = useGroupsQuery({
    variables: {
      organizationId: post.organization.id,
      first: GROUPS_PER_PAGE_SELECTOR,
    },
    onCompleted(data) {
      const groupThatHasPost = data.groups.edges.find(
        ({ node: group }) =>
          group.groupType === GroupTypes.Regular && group.id === post.group.id
      )?.node;
      if (groupThatHasPost) {
        form.setValue('groupId', groupThatHasPost.id);
        setGroup(groupThatHasPost);
      }
    },
  });
  const [movePost, { loading }] = useMovePostMutation({
    refetchQueries: [
      refetchPostsByGroupQuery({
        groupId: post.group.id,
        first: POSTS_PER_PAGE,
      }),
      refetchHomeFeedQuery({
        organizationId: post.organization.id,
        first: POSTS_PER_PAGE,
      }),
      refetchHomeFeedQuery({
        organizationId: post.organization.id,
        first: POSTS_PER_PAGE - 1,
        username: post.createdBy.id,
      }),
    ],
    onCompleted: onDismiss,
    awaitRefetchQueries: true,
  });

  return (
    <GroupsModal isOpen ariaLabel={t('move_post')} onDismiss={onDismiss}>
      <Form<FormData>
        {...form}
        onSubmit={(data) => {
          movePost({
            variables: {
              input: {
                postId: post.id,
                groupId: data.groupId,
              },
            },
          });
        }}
      >
        <x.div spaceY={6}>
          <x.div
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Heading variant="lg">{t('move_post')}</Heading>
            <Button type="submit" loading={loading} label={t('move')} />
          </x.div>
          <PostComposerGroupSelector
            type={PostTypes.Regular}
            showEvents={false}
            value={group}
            disabled={loading}
            groups={data ? data.groups.edges.map((e) => e.node) : []}
            name="groupId"
            onChange={(g) => {
              setGroup(g);
              form.setValue('groupId', g.id);
            }}
          />
        </x.div>
      </Form>
    </GroupsModal>
  );
};
