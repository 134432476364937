import { MyPlatePlugin, MyValue } from '@frond/shared';
import isHotkey from 'is-hotkey';

import { getPlatformShortcuts } from '../../../utils/keyboard';

export const createSubmitPlugin = ({
  onSubmit,
  shortcut = getPlatformShortcuts().submit,
}: {
  onSubmit: (value: MyValue) => void;
  shortcut?: string;
}): MyPlatePlugin => ({
  key: 'submit',
  handlers: {
    onKeyDown: (editor) => {
      return (event) => {
        // Suppress newline on submit keyboard shortcut
        if (isHotkey(shortcut)(event.nativeEvent)) {
          event.stopPropagation();
          onSubmit(editor.children);
          return false;
        }
      };
    },
  },
});
