import { useSelector } from '@xstate/react';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';

import { Toast } from '../../common/components/Toast';
import { PostComposerStateContext } from './PostComposer';

export const PUBLISH_ERROR_MESSAGE = 'publish_error_message';

export const UPLOAD_ERROR_MESSAGE = 'upload_error_message';

export const PostComposerErrors = () => {
  const { t } = useTranslation();
  const { postComposerService } = useContext(PostComposerStateContext);

  const errorMessage = useSelector(
    postComposerService,
    (state) => state.context.errorMessage
  ) as typeof PUBLISH_ERROR_MESSAGE | typeof UPLOAD_ERROR_MESSAGE | undefined;

  if (errorMessage) {
    return (
      <Toast
        variant="error"
        message={t(errorMessage)}
        onDisappear={() => postComposerService.send({ type: 'CLEAR_ERROR' })}
      />
    );
  }

  return null;
};
