import { MyPlatePlugin } from '@frond/shared';

type MaxLinesOptions = {
  maxLinesConfig: {
    fontHeight: number;
    maxLinesCount: number;
  };
  ref: React.MutableRefObject<HTMLDivElement | null>;
};

const isOverLimit = ({ maxLinesConfig, ref }: MaxLinesOptions) => {
  const { fontHeight, maxLinesCount } = maxLinesConfig;
  const composerHeight = ref?.current?.getBoundingClientRect().height || 0;
  const maxHeight = fontHeight * maxLinesCount;
  return composerHeight > maxHeight;
};

export const createMaxLinesPlugin = ({
  maxLinesConfig,
  ref,
}: MaxLinesOptions): MyPlatePlugin => ({
  key: 'max-lines',
  withOverrides: (editor) => {
    const { maxLinesCount } = maxLinesConfig;
    const { insertBreak } = editor;

    editor.insertBreak = () => {
      if (editor.children.length >= maxLinesCount) {
        return;
      }
      return insertBreak();
    };

    return editor;
  },
  handlers: {
    onChange: (editor) => {
      return () => {
        if (isOverLimit({ maxLinesConfig, ref })) {
          editor.deleteBackward('character');
          return;
        }
      };
    },
    onPaste: (editor) => {
      return () => {
        const adjustContentToLimit = () => {
          // Wait animation frame as Composer updates in next frame
          requestAnimationFrame(() => {
            if (isOverLimit({ maxLinesConfig, ref })) {
              editor.deleteBackward('line');
              adjustContentToLimit();
            }
          });
        };
        adjustContentToLimit();
      };
    },
  },
});
