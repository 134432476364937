import styled, { css, x } from '@xstyled/styled-components';
import { system, SystemProps, th } from '@xstyled/system';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { IStyledComponent } from 'styled-components';

import analytics, { AnalyticEventName } from '../utils/analytics';
import { useIsStorybook } from '../utils/useIsStorybook';
import { variant } from '../utils/variant';
import { Avatar } from './Avatar';
import { Icon } from './Icon';
import { LoadingSpinner } from './LoadingSpinner';
import { Text, TextVariant } from './Text';

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type ButtonBaseVariants =
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'dark'
  | 'gray'
  | 'icon'
  | 'ghost'
  | 'outline'
  | 'secondary-warning'
  | 'green'
  | 'text';
type ButtonState = 'base' | 'hover' | 'active' | 'focus' | 'disabled';
type ButtonStateMap = {
  [s in ButtonState]: any;
};
type ButtonVariantMixins = {
  [v in ButtonBaseVariants]: ButtonStateMap;
};

export interface ButtonProps extends Omit<SystemProps, 'color'> {
  /**
   * Text label for the button
   */
  label?: string | React.ReactNode;
  /**
   * Button style
   */
  variant?:
    | ButtonBaseVariants
    | 'secondary-selected'
    | 'dark-no-border'
    | 'dark-selected'
    | 'ghost'
    | 'secondary-warning';
  /**
   * Button size
   */
  size?: ButtonSize;
  /**
   * Disable interation with button
   */
  disabled?: boolean;
  /**
   * Loading boolean to show spinner
   */
  loading?: boolean;
  /**
   * onClick or href to determine button type
   * wanted to split this out into two interfaces and use never type
   * to ensure only one is passed but it broke many other component's types
   * checking and throwing an error instead
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  href?: string;
  target?: string;
  /**
   * Element to use as left icon glyph when not loading
   */
  leftElement?:
    | React.ReactElement<typeof Avatar>
    | React.ReactElement<typeof Icon>
    | null;
  /**
   * Ref used for alerts
   */
  ref?: React.RefObject<HTMLButtonElement> | React.RefObject<HTMLAnchorElement>;

  blurAfterClick?: boolean;

  /**
   * extra context to send with button click action
   */
  metadata?: { event?: AnalyticEventName; context?: string };

  leftAlign?: boolean;
}

const mixins: ButtonVariantMixins = {
  primary: {
    base: css`
      color: white;
      background-color: brand.300;
      border-color: brand.300;
    `,
    hover: css`
      background-color: brand.400;
      border-color: brand.400;
    `,
    active: css`
      background-color: brand.500;
      border-color: brand.500;
    `,
    focus: css`
      background-color: brand.300;
      border-color: brand.200;
    `,
    disabled: css`
      color: white;
      background-color: brand.100;
      border-color: brand.100;
    `,
  },

  secondary: {
    base: css`
      color: gray.500;
      background-color: white;
      border-color: gray.200;
    `,
    hover: css`
      color: brand.300;
      border-color: brand.300;
    `,
    active: css`
      color: brand.300;
      border-color: brand.300;
      background-color: brand.50;
    `,
    focus: css`
      border-color: gray.200;
      background-color: gray.50;
    `,
    disabled: css`
      color: gray.300;
      background-color: gray.50;
      border-color: gray.200;
    `,
  },

  warning: {
    base: css`
      color: white;
      background-color: red.300;
      border-color: red.300;
    `,
    hover: css`
      border-color: red.400;
      background-color: red.400;
    `,
    active: css`
      border-color: red.500;
      background-color: red.500;
    `,
    focus: css`
      border-color: red.200;
      background-color: red.300;
    `,
    disabled: css`
      background-color: red.100;
      border-color: red.100;
    `,
  },

  'secondary-warning': {
    base: css`
      color: red.300;
      background-color: white;
      border-color: gray.200;
    `,
    hover: css`
      border-color: red.400;
      background-color: white;
    `,
    active: css`
      border-color: red.500;
      background-color: white;
    `,
    focus: css`
      border-color: red.200;
      background-color: white;
    `,
    disabled: css`
      background-color: gray.50;
      border-color: gray.50;
    `,
  },

  green: {
    base: css`
      color: white;
      background-color: green.200;
      border-color: green.200;
    `,
    hover: css`
      border-color: green.300;
      background-color: green.300;
    `,
    active: css`
      border-color: green.400;
      background-color: green.400;
    `,
    focus: css`
      border-color: green.300;
      background-color: green.400;
    `,
    disabled: css`
      background-color: green.100;
      border-color: green.100;
    `,
  },

  dark: {
    base: css`
      color: white;
      background-color: gray.500;
      border-color: gray.300;
    `,
    active: css`
      background-color: gray.400;
      border-color: gray.300;
    `,
    focus: css``,
    hover: css``,
    disabled: css``,
  },

  gray: {
    base: css`
      color: gray.400;
      background-color: gray.200;
      border-color: gray.200;
    `,
    active: css``,
    focus: css``,
    hover: css``,
    disabled: css``,
  },

  ghost: {
    base: css`
      color: white;
      filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.24));
      border-color: transparent;
      background-color: gray-a75;
    `,
    hover: css`
      background-color: gray.500;
    `,
    active: css`
      background-color: gray.500;
      border-color: gray.500;
    `,
    focus: css``,
    disabled: css`
      opacity: 0.32;
    `,
  },

  icon: {
    base: css`
      padding: 0;
      color: gray.300;
      border-radius: none;
      background-color: transparent;
      border-color: transparent;
    `,
    hover: css`
      color: gray.500;
    `,
    active: css``,
    focus: css``,
    disabled: css`
      color: gray.100;
    `,
  },

  outline: {
    base: css`
      color: white;
      background-color: transparent;
    `,
    hover: css`
      color: gray.300;
    `,
    active: css``,
    focus: css``,
    disabled: css`
      color: gray.100;
    `,
  },

  text: {
    base: css`
      padding: 0;
      color: gray.500;
      border-radius: none;
      background-color: transparent;
      border-color: transparent;
    `,
    hover: css``,
    active: css``,
    focus: css``,
    disabled: css`
      opacity: 0.4;
    `,
  },
};

const createVariant = (name: ButtonBaseVariants) => {
  const { base, hover, active, focus, disabled } = mixins[name];
  return css`
    /* stylelint-disable */
    ${base}

    &:hover {
      ${hover}
    }
    &:active {
      ${active}
    }
    &:focus {
      ${focus}
    }
    &:disabled {
      ${disabled}
    }
    /* stylelint-enable */
  `;
};

const variants: any = variant({
  variants: {
    primary: createVariant('primary'),
    secondary: createVariant('secondary'),
    warning: createVariant('warning'),
    gray: createVariant('gray'),
    'secondary-warning': createVariant('secondary-warning'),
    green: createVariant('green'),
    text: createVariant('text'),
    dark: createVariant('dark'),
    ghost: createVariant('ghost'),
    icon: createVariant('icon'),
    outline: createVariant('outline'),
    'secondary-selected': css`
      ${mixins.secondary.base}
      ${mixins.secondary.active}
    `,
    'dark-selected': css`
      ${mixins.dark.base}
      ${mixins.dark.active}
    `,
    'dark-no-border': css`
      ${mixins.dark.base}
      border-color: gray.500;
    `,
  },
});

// todo - this is supporting the inputform component
export const primaryMixin = createVariant('primary');

const buttonSizeToTextVariant: { [key in ButtonSize]: TextVariant } = {
  xs: 'sm-semibold',
  sm: 'sm-semibold',
  md: 'md-semibold',
  lg: 'lg-semibold',
  xl: 'xl-semibold',
};

const iconOnlyMixin = css`
  padding: calc(${th.size('2')} - 1px);
  border-radius: full;
`;

const paddingXBySize: { [key in ButtonSize]: string } = {
  xs: '2',
  sm: '4',
  md: '4',
  lg: '4',
  xl: '6',
};

const paddingYBySize: { [key in ButtonSize]: string } = {
  xs: '1',
  sm: '2',
  md: '2',
  lg: '2',
  xl: '4',
};

const radiusBySize: { [key in ButtonSize]: string } = {
  xs: 'md',
  sm: 'md',
  md: 'md',
  lg: 'md',
  xl: 'md-lg',
};

export const baseStyleMixin = css<ButtonProps>`
  appearance: none;
  margin: 0;

  ${({ size = 'sm' }) => css`
    /* Remove 1px from padding on all sides to account for inset border */
    padding-top: calc(${th.size(paddingYBySize[size])} - 1px);
    padding-bottom: calc(${th.size(paddingYBySize[size])} - 1px);
    padding-left: calc(${th.size(paddingXBySize[size])} - 1px);
    padding-right: calc(${th.size(paddingXBySize[size])} - 1px);

    border-radius: ${th.radius(radiusBySize[size])};
  `}

  outline: none;
  border: default;
  cursor: pointer;
  white-space: nowrap;

  /* Have to use theme getter because only "transition:" property is supported automagically */
  transition-property: ${th('transitions.property.colors')};
  transition-duration: ${th('transitions.duration.faster')};
  transition-timing-function: linear;

  &:focus {
    box-shadow: 0 0 0 2px ${th('colors.brand.100')};
  }
`;

const StyledButton = styled.button<ButtonProps>`
  ${baseStyleMixin}
  ${variants}
  ${system}
  ${(p) => p.label === '' && iconOnlyMixin}
  ${(p) => p.disabled && 'cursor: default;'}
`;

const StyledLink = styled.span<ButtonProps>`
  text-decoration: none;
  display: inline-block;
  ${baseStyleMixin}
  ${system}
  ${(p) => p.label === '' && iconOnlyMixin}
  ${(p) => p.disabled && 'cursor: default;'}
  ${(p) => {
    const variant = p.variant || 'primary';
    if (p.disabled && variant in mixins) {
      return mixins[variant as ButtonBaseVariants].disabled;
    } else {
      return variants;
    }
  }}
`;

const LeftElementContainer = styled.div`
  padding: 0;
  margin-right: 2;
  font-size: 1.35em;

  /* Icon only case */
  &:only-child {
    margin-right: 0;
  }
`;

/**
 * Primary component for a button
 */
export const Button: React.FC<
  ButtonProps &
    (
      | React.ButtonHTMLAttributes<HTMLButtonElement>
      | React.AnchorHTMLAttributes<HTMLSpanElement>
    )
> = ({
  label,
  disabled,
  loading,
  leftElement,
  size = 'sm',
  variant = 'primary',
  href,
  type,
  onClick,
  blurAfterClick = false,
  metadata,
  leftAlign = false,
  ...domProps
}) => {
  const router = useRouter();
  const isStorybook = useIsStorybook();
  if (href && onClick && !isStorybook) {
    throw new Error('href and onClick cannot be used in the same component');
  }

  const domLabel = typeof label === 'string' && !href ? label : undefined;

  // Show button as disabled if loading
  const domDisabled = disabled || loading;

  let ButtonComponent: IStyledComponent<'web', ButtonProps> = StyledButton;
  if (href) ButtonComponent = StyledLink;

  const button = (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-ignore */
    <ButtonComponent
      {...domProps}
      variant={variant}
      label={domLabel}
      disabled={domDisabled}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /** @ts-ignore */
      type={type || 'button'}
      size={size}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        if (metadata?.event) {
          analytics.logEvent(metadata.event, {
            type: href ? 'link_click' : 'button_click',
            href,
            page: router?.pathname,
            url: router?.asPath,
            ...metadata,
          });
        }
        onClick?.(e);
        if (blurAfterClick) {
          e.currentTarget.blur();
        }
      }}
    >
      <x.div position="relative">
        <Text
          as="span"
          display="flex"
          alignItems="center"
          justifyContent={leftAlign ? 'start' : 'center'}
          position="relative"
          variant={buttonSizeToTextVariant[size]}
          visibility={loading ? 'hidden' : 'visible'}
        >
          {leftElement && (
            <LeftElementContainer>{leftElement}</LeftElementContainer>
          )}
          {label && <span>{label}</span>}
        </Text>
        {loading && !disabled && (
          <x.div
            position="absolute"
            top={0}
            left={0}
            bottom={0}
            right={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <LoadingSpinner
              size="4"
              variant={variant === 'secondary' ? 'dark' : 'light'}
            />
          </x.div>
        )}
      </x.div>
    </ButtonComponent>
  );

  if (href && !disabled)
    return (
      <Link href={href} passHref target={domProps.target}>
        {button}
      </Link>
    );

  return button;
};
Button.displayName = 'Button';
