import Script from 'next/script';

const UMAMI_URL = process.env.NEXT_PUBLIC_UMAMI_URL;
const UMAMI_SITE_ID = process.env.NEXT_PUBLIC_UMAMI_SITE_ID;

export default function UmamiAnalytics() {
  return (
    <>
      <Script src={UMAMI_URL} data-website-id={UMAMI_SITE_ID} />
    </>
  );
}

export function sendUmamiEvent(eventName: string, data: object) {
  const key = 'umami';
  const umami = window[key as keyof typeof window];
  umami?.track(eventName, data);
}
