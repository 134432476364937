import { getAnimatedThumbnailUrl, getPlaybackUrl } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import React, { useEffect, useRef, useState } from 'react';

import { Video } from '../../../../generated/types-and-hooks';
import { QuestionsVideoPlayerControls } from '../../questions/components/QuestionsVideoPlayer/QuestionsVideoPlayerControls';
import {
  VideoPlayer,
  VideoPlayerRef,
} from '../../video/components/VideoPlayer';
import { PostCardButton } from './PostCardMedia';

export const PostCardVideo = ({
  video,
  autoPlay,
  onClick,
}: {
  video: Video;
  autoPlay?: boolean;
  onClick?: React.MouseEventHandler;
}) => {
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [showCasting, setShowCasting] = useState(false);

  const videoPlayerRef = useRef<VideoPlayerRef>(null);
  const poster = video?.playbackId
    ? getAnimatedThumbnailUrl(video.playbackId)
    : undefined;

  useEffect(() => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.watchAvailability(setShowCasting)?.catch(() => {
        /* If the device cannot continuously watch available,
        show the button to allow the user to try to prompt for a connection.*/
        setShowCasting(true);
      });
    }
  }, [videoPlayerRef]);

  return (
    <x.div h="100%" w="100%">
      <VideoPlayer
        overflow="hidden"
        preload="auto"
        poster={poster}
        src={getPlaybackUrl(video?.playbackId || '')}
        transform
        aspect={video?.aspect}
        playsInline
        scaleX={video?.mirror ? -1 : 1}
        controls={QuestionsVideoPlayerControls}
        onPlaybackStatusChange={setPlaying}
        onMutedStatusChange={setMuted}
        ref={videoPlayerRef}
        autoPlay={autoPlay}
        w="100%"
        h="100%"
        objectFit="cover"
        onClick={onClick}
      />

      <x.div
        spaceX={2}
        display="flex"
        position="absolute"
        right={24}
        bottom={24}
        transform="translateZ(0)"
      >
        {showCasting && (
          <PostCardButton
            iconName={
              navigator.userAgent.indexOf('Mac OS X') ? 'airplay' : 'casting'
            }
            onClick={() => videoPlayerRef.current?.remotePrompt()}
          />
        )}
        <PostCardButton
          iconName={muted ? 'audio-off' : 'audio-on'}
          onClick={() => videoPlayerRef.current?.toggleMuted()}
        />
        <PostCardButton
          iconName={playing ? 'pause' : 'play'}
          onClick={() => {
            videoPlayerRef.current?.togglePlayback();
          }}
        />
      </x.div>
    </x.div>
  );
};
