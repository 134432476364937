import * as React from "react";

function SvgAppLinear(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-linear_svg__clip0)" fill="#5E6AD2">
        <path d="M19.835 21.107c.221-.19.436-.39.646-.6 4.691-4.692 4.691-12.297 0-16.988-4.69-4.691-12.297-4.691-16.988 0-.21.21-.41.425-.6.646l16.942 16.942zM18.406 22.17L1.83 5.596a11.92 11.92 0 00-.863 1.63l15.807 15.807c.56-.243 1.106-.53 1.63-.863zM14.913 23.665L.335 9.088C.158 9.798.046 10.52 0 11.246L12.754 24a12.048 12.048 0 002.159-.335zM10.143 23.883L.117 13.857a11.958 11.958 0 003.377 6.65c1.875 1.874 4.215 3 6.65 3.376z" />
      </g>
      <defs>
        <clipPath id="app-linear_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppLinear;
