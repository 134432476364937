import _ from 'lodash';
import slugifyFn from 'slugify';

// Allow only lower-case characters and numbers with dashes
export const SHORT_ID_VALUE_REGEX = '[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?';
export const SHORT_ID_REGEX = `^${SHORT_ID_VALUE_REGEX}$`;

export const getShortId = (s?: string): string | undefined => {
  return s?.match(new RegExp(SHORT_ID_VALUE_REGEX))?.[0];
};

export const slugify = (s: string): string =>
  slugifyFn(s, {
    replacement: '-',
    lower: true,
    remove: /[^a-zA-Z\d- ]/,
  });

export const getUsersText = (
  users: {
    id: string;
    username: string;
    firstName: string;
  }[],
  maxNumUniqueUsers = 5,
  replacements?: {
    and: string;
    other: string;
    others: string;
  }
) => {
  const uniqueUsers = _.uniqBy(users, 'id');
  const slicedUsers = uniqueUsers.slice(0, maxNumUniqueUsers);
  const and = replacements?.and || ' and ';
  const other = replacements?.other || 'other';
  const others = replacements?.others || 'others';

  const usersText = slicedUsers
    .map((user) => `*${user.firstName || user.username}*`)
    .join(users.length == 2 ? and : ', ');
  const notificationUsersText = slicedUsers
    .map((user) => user.firstName || user.username)
    .join(users.length == 2 ? and : ', ');
  const commonText = `${
    uniqueUsers.length > maxNumUniqueUsers
      ? `${and}${uniqueUsers.length - maxNumUniqueUsers} ${
          uniqueUsers.length - maxNumUniqueUsers > 1 ? others : other
        }`
      : ''
  }`;
  const text = `${usersText}${commonText}`;
  const notificationText = `${notificationUsersText}${commonText}`;
  return {
    text,
    notificationText,
  };
};
