import {
  EventCoreFragment,
  refetchEventQuery,
  refetchEventsQuery,
  useJoinGroupMutation,
  useRemoveGroupMemberMutation,
} from '../../../../generated/types-and-hooks';
import { GROUPS_PER_PAGE } from '../../../config/constants';
import { useViewer } from '../../auth/hooks/useViewer';
import { GroupsSidebarOrganization } from '../../groups/components/GroupsSidebar/GroupsSidebar';
import { useUserCanEditGroup } from '../../questions/utils/questions';

export const useEventAttendance = ({
  organization,
  event,
}: {
  organization: GroupsSidebarOrganization;
  event: EventCoreFragment;
}) => {
  const { viewer: user } = useViewer();
  const userCanEdit = useUserCanEditGroup(event, organization, user);
  const [joinGroup, { loading: joiningGroup }] = useJoinGroupMutation({
    variables: {
      input: {
        groupId: event.id,
      },
    },
    awaitRefetchQueries: true,
    refetchQueries: [refetchEventQuery({ id: event.id })],
  });

  const [leaveGroup, { loading: leavingGroup }] = useRemoveGroupMemberMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      refetchEventsQuery({
        organizationId: organization.id,
        first: GROUPS_PER_PAGE - 1,
      }),
      refetchEventQuery({ id: event.id }),
    ],
    fetchPolicy: 'network-only',
    variables: {
      input: {
        groupId: event.id,
        userId: user?.id || '',
      },
    },
  });

  return {
    userIsMember: !!event.membership,
    userCanEdit,
    joinGroup,
    joiningGroup,
    leaveGroup,
    leavingGroup,
  };
};
