import { x } from '@xstyled/styled-components';
import React from 'react';

import { FrondLogoMinimal } from '../../../../common/components/FrondLogoMinimal';
import { Heading } from '../../../../common/components/Heading';
import { Text } from '../../../../common/components/Text';

export interface StepHeadingProps {
  title: string;
  text: string;
}

export const StepHeading: React.FC<StepHeadingProps> = ({ title, text }) => {
  return (
    <x.div
      display="flex"
      flexDirection="column"
      alignItems="center"
      mb={{ _: 6, 'lg-3': 10 }}
      px={{ _: 8, 'lg-3': 0 }}
    >
      <x.div display={{ _: 'initial', 'lg-3': 'none' }} w="10" mt="12" mb="6">
        <FrondLogoMinimal />
      </x.div>
      <Heading variant={{ _: 'lg', 'md-2': '3xl' }}>{title}</Heading>
      <Text
        maxW="container.sm"
        mt={{ _: '4', 'lg-3': '6 ' }}
        variant={{ _: 'md', 'lg-3': 'lg' }}
        textAlign="center"
      >
        {text}
      </Text>
    </x.div>
  );
};
