import * as React from 'react';

function SvgCircleLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 12c0-6.075 4.925-11 11-11s11 4.925 11 11-4.925 11-11 11-11-4.925-11-11zm2 0a9 9 0 1018 0 9 9 0 00-18 0zm10.293 4.707l1.414-1.414L11.914 12l3.293-3.293-1.414-1.414L9.086 12l4.707 4.707z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCircleLeft;
