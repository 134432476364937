import * as Popover from '@radix-ui/react-popover';
import { SystemProps, th, x } from '@xstyled/styled-components';
import { BaseEmoji, Picker } from 'emoji-mart';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Emoji } from '../../../../common/components/Emoji';
import { Icon } from '../../../../common/components/Icon';

export const GroupFormEmoji: React.FC<SystemProps> = (props) => {
  const form = useFormContext();

  const [openPicker, setOpenPicker] = useState(false);

  const handleCloseEmojiPicker = () => setOpenPicker(false);

  const emoji = form.watch('emoji');
  const handleEmojiPickerClick = (
    emoji: BaseEmoji,
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.preventDefault();
    handleCloseEmojiPicker();
    form.setValue('emoji', emoji.native, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <Popover.Root open={openPicker}>
      <Popover.Trigger asChild>
        <x.button
          boxShadow="sm"
          type="button"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="lg"
          bg="white"
          w={20}
          h={20}
          cursor="pointer"
          border="none"
          onClick={() => setOpenPicker(true)}
          {...props}
        >
          {emoji ? (
            <Emoji size={40} emojiUnicode={emoji} />
          ) : (
            <Icon
              name="reaction-add"
              size="10"
              color="gray.300"
              pointerEvents="none"
            />
          )}
        </x.button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          onEscapeKeyDown={handleCloseEmojiPicker}
          onInteractOutside={handleCloseEmojiPicker}
        >
          <x.div p={1} onClick={(e) => e.preventDefault()}>
            <Picker
              title={''} // Hide title in preview
              emoji={''} // Hide default emoji in preview
              color={`${th.color('brand.300')}`}
              onClick={handleEmojiPickerClick}
              native
            />
          </x.div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
