import * as React from "react";

function SvgHamster(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30 13.75a4.375 4.375 0 01-1.781-.375.624.624 0 01-.357-.412 8.125 8.125 0 00-2.03-3.588.626.626 0 01-.176-.506A4.376 4.376 0 1130 13.75z"
        fill="#212121"
      />
      <path
        d="M28.988 12.331a3.124 3.124 0 10-2.044-3.58 9.375 9.375 0 012.044 3.58z"
        fill="#A1887F"
      />
      <path
        d="M27.806 35.625a9.038 9.038 0 01-5.58-1.925 3.55 3.55 0 00-4.45 0 9.05 9.05 0 01-6.25 1.875 8.931 8.931 0 01-4.545-1.637 9.13 9.13 0 01-3.856-6.907A9.112 9.112 0 017.75 18.67l.425-.225.481-.219a3.387 3.387 0 001.97-3.194 9.21 9.21 0 01.3-2.393 9.313 9.313 0 012.343-4.163 9.338 9.338 0 0112.419-.925c.368.287.717.598 1.043.931a9.374 9.374 0 012.344 4.15 9.586 9.586 0 01.3 2.519 3.275 3.275 0 001.969 3.075 9.026 9.026 0 015.175 5.85c.285.958.405 1.958.356 2.956a9.137 9.137 0 01-3.844 6.932 8.931 8.931 0 01-4.556 1.662h-.669z"
        fill="#212121"
      />
      <path
        d="M20 31.675c1.089 0 2.146.369 3 1.044a7.806 7.806 0 005.388 1.656 7.686 7.686 0 003.906-1.413 7.876 7.876 0 003.331-5.993 7.596 7.596 0 00-.294-2.55 7.775 7.775 0 00-4.468-5.044 4.537 4.537 0 01-2.738-4.244 8.3 8.3 0 00-.119-1.544 6.524 6.524 0 00-.143-.625 8.125 8.125 0 00-2.032-3.587 9.28 9.28 0 00-.912-.819 8.081 8.081 0 00-9.856 0c-.319.25-.618.523-.894.819a8.062 8.062 0 00-2.031 3.612A7.89 7.89 0 0011.875 15a4.644 4.644 0 01-2.731 4.375l-.419.187-.362.194a7.887 7.887 0 00-3.682 4.656 7.607 7.607 0 00-.306 2.557 7.875 7.875 0 003.319 5.975 7.687 7.687 0 003.919 1.43A7.8 7.8 0 0017 32.738a4.844 4.844 0 013-1.062z"
        fill="#A1887F"
      />
      <path
        d="M10 13.75a4.375 4.375 0 114.338-4.912.626.626 0 01-.169.512 8.056 8.056 0 00-2.031 3.606.625.625 0 01-.356.419A4.375 4.375 0 0110 13.75z"
        fill="#212121"
      />
      <path
        d="M10 6.25a3.125 3.125 0 101.013 6.081 9.32 9.32 0 012.037-3.625A3.125 3.125 0 0010 6.25z"
        fill="#A1887F"
      />
      <path
        d="M32.657 34.075a.625.625 0 01-.325-.087c-.77-.463-1.476-1.513-2.1-3.125A14.431 14.431 0 0025 24.375a10.311 10.311 0 01-.062-16.825.625.625 0 01.75 0c.366.287.712.598 1.037.931a9.375 9.375 0 012.344 4.15c.063.244.113.482.156.725.106.592.157 1.193.15 1.794a3.275 3.275 0 001.969 3.075 9.026 9.026 0 015.175 5.85c.285.958.406 1.958.356 2.956a9.132 9.132 0 01-3.843 6.925.625.625 0 01-.375.12z"
        fill="#212121"
      />
      <path
        d="M25.288 8.85a9.063 9.063 0 00.437 14.494 15.676 15.676 0 015.675 7.069c.267.822.691 1.584 1.25 2.243a7.863 7.863 0 002.975-5.687 7.594 7.594 0 00-.293-2.55 7.776 7.776 0 00-4.47-5.044 4.537 4.537 0 01-2.737-4.244 8.3 8.3 0 00-.118-1.543 6.524 6.524 0 00-.144-.625 8.125 8.125 0 00-2.031-3.588c-.207-.187-.357-.362-.544-.525z"
        fill="#EEE"
      />
      <path
        d="M7.344 34.075a.625.625 0 01-.363-.113 9.137 9.137 0 01-3.856-6.93 9.112 9.112 0 014.625-8.363l.425-.225.481-.22a3.387 3.387 0 001.97-3.193 9.21 9.21 0 01.3-2.394 9.313 9.313 0 012.343-4.162c.32-.334.664-.644 1.031-.925a.625.625 0 01.744 0A10.306 10.306 0 0115 24.375a14.437 14.437 0 00-5.231 6.5c-.625 1.6-1.331 2.65-2.1 3.125a.625.625 0 01-.325.075z"
        fill="#212121"
      />
      <path
        d="M14.7 8.844a7.872 7.872 0 00-.525.5 8.062 8.062 0 00-2.031 3.612A7.892 7.892 0 0011.875 15a4.644 4.644 0 01-2.731 4.375l-.419.188-.362.193a7.888 7.888 0 00-3.682 4.657 7.607 7.607 0 00-.306 2.556 7.863 7.863 0 002.944 5.694c.559-.66.984-1.422 1.25-2.244a15.682 15.682 0 015.675-7.069 9.055 9.055 0 00.419-14.506h.037z"
        fill="#EEE"
      />
      <path
        d="M20 27.731a1.875 1.875 0 01-1.25-.456l-.994-.856a1.876 1.876 0 011.25-3.3H21a1.874 1.874 0 011.25 3.3l-1 .856c-.347.3-.791.462-1.25.456z"
        fill="#212121"
      />
      <path
        d="M19.007 24.375a.624.624 0 00-.406 1.1l.993.856a.625.625 0 00.813 0l.994-.856a.625.625 0 00-.407-1.1h-1.987z"
        fill="#9E9E9E"
      />
      <path
        d="M23.125 31.25a.624.624 0 01-.318-.087l-1.025-.625a3.456 3.456 0 00-3.563 0l-1.025.625a.625.625 0 01-.625-1.07l1.025-.624a4.706 4.706 0 014.85 0l1.025.625a.625.625 0 01-.344 1.156z"
        fill="#212121"
      />
      <path
        d="M20 30a.624.624 0 01-.625-.625V27.5a.624.624 0 111.25 0v1.875A.624.624 0 0120 30zm-6.25-11.25a.624.624 0 01-.625-.625v-1.25a.624.624 0 111.25 0v1.25a.624.624 0 01-.625.625zm12.5 0a.624.624 0 01-.625-.625v-1.25a.624.624 0 111.25 0v1.25a.624.624 0 01-.625.625z"
        fill="#212121"
      />
    </svg>
  );
}

export default SvgHamster;
