import { generateHexAlphaVariants } from '@xstyled/styled-components';

export interface ColorHues {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
}

export type Colors = typeof colors;

const baseColors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000000',
  white: '#FFFFFF',

  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.1)',
    200: 'rgba(255, 255, 255, 0.2)',
    300: 'rgba(255, 255, 255, 0.4)',
    350: 'rgba(255, 255, 255, 0.6)',
    400: 'rgba(255, 255, 255, 0.8)',
    450: 'rgba(255, 255, 255, 0.9)',
    500: 'rgba(255, 255, 255, 0.95)',
  },

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.05)',
    100: 'rgba(0, 0, 0, 0.1)',
    200: 'rgba(0, 0, 0, 0.2)',
    300: 'rgba(0, 0, 0, 0.4)',
    400: 'rgba(0, 0, 0, 0.7)',
    500: 'rgba(0, 0, 0, 0.9)',
  },

  ...generateHexAlphaVariants({
    gray: '#333333',
  }),

  gray: {
    50: '#F9F9F9',
    100: '#F2F2F2',
    200: '#E0E0E0',
    250: '#D9D9D9',
    300: '#8D8D8D',
    400: '#4F4F4F',
    500: '#333333',
  },

  purple: {
    50: '#F7F2FF',
    75: '#F1EAFF',
    100: '#D2C4ED',
    200: '#AD95DC',
    300: '#8867CA',
    400: '#6338B8',
    500: '#411991',
  },

  red: {
    50: '#FFF6F8',
    75: '#FFE8EB',
    100: '#FBC2CA',
    200: '#F48C93',
    300: '#EB5757',
    400: '#B9394F',
    500: '#802042',
  },

  yellow: {
    50: '#FFFBF6',
    75: '#FFF6DB',
    100: '#FDE4BB',
    200: '#F9D482',
    300: '#EFBB1D',
    400: '#BF832F',
    500: '#804519',
  },

  green: {
    50: '#F4FAF5',
    75: '#E4F6E9',
    100: '#ACDBB9',
    200: '#65B984',
    300: '#219653',
    400: '#12772C',
    500: '#07540D',
  },

  blue: {
    50: '#F4FBFF',
    75: '#E6F4FF',
    100: '#B0DAFC',
    200: '#6EB1F6',
    300: '#2F80ED',
    400: '#1855BD',
    500: '#092F80',
  },
};

const derivedColors = {
  background: baseColors.white,
  brand: baseColors.purple,
};

const colors = {
  ...baseColors,
  ...derivedColors,
};

export default colors;
