export enum NodeEnv {
  DEVELOPMENT = 'development',
  TEST = 'test',
  PRODUCTION = 'production',
}

export enum VercelEnv {
  DEVELOPMENT = 'development',
  PREVIEW = 'preview',
  PRODUCTION = 'production',
}

export const IS_PRODUCTION =
  process.env.NEXT_PUBLIC_VERCEL_ENV === VercelEnv.PRODUCTION;

export const IS_DEV =
  process.env.NEXT_PUBLIC_BASE_URL === 'http://localhost:3000' ||
  process.env.NEXT_PUBLIC_NODE_ENV === NodeEnv.DEVELOPMENT ||
  !process.env.NODE_ENV;

export const IS_PREVIEW_DEPLOY =
  process.env.NEXT_PUBLIC_VERCEL_ENV === VercelEnv.PREVIEW &&
  process.env.NEXT_PUBLIC_BASE_URL !== 'https://frond.dev';

export const IS_STAGING =
  process.env.NEXT_PUBLIC_VERCEL_ENV === VercelEnv.PREVIEW &&
  !IS_PREVIEW_DEPLOY;

export const BASE_URL = IS_PREVIEW_DEPLOY
  ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
  : (process.env.NEXT_PUBLIC_BASE_URL as string) || 'https://frond.com';

export const IS_SSR = typeof window === 'undefined';

export const PEOPLE_PER_PAGE = 24;
export const GROUPS_PER_PAGE = 24;
export const POSTS_PER_PAGE = 8;
export const USERS_PER_PAGE = 1000;
export const GROUPS_PER_PAGE_SELECTOR = 1000;
export const POST_PREVIEW_CARD_WIDTH_PX = 600;

export const AUTH0_SCOPE = 'openid profile email offline_access';
export const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;
export const AUTH0_COOKIE_KEY = `auth0.${AUTH0_CLIENT_ID}.is.authenticated`;
export const ORGANIZATION_SHORT_ID = 'organizationShortId';

export const DEMO_URL =
  IS_STAGING || IS_DEV || IS_PREVIEW_DEPLOY
    ? 'https://demo.frond.dev'
    : 'https://demo.frond.com';

export const IS_DEMO =
  !IS_SSR &&
  (window.location.origin === 'https://demo.frond.com' ||
    window.location.origin === 'https://demo.frond.dev');

export const GIPHY_API_KEY = process.env.NEXT_PUBLIC_GIPHY_API_KEY;
export const VIDEO_POLL_INTERVAL = 1000;

export const DEFAULT_OG_IMAGE_URL =
  'https://res.cloudinary.com/frond/image/upload/landing-assets/u2ydohtvzo8hp0dapmtf.png';

export const FROND_X_URL = 'https://x.com/frondcom';

export const COMMENT_MEDIA_HEIGHT_PX = 156;

export const EXTERNAL_URL_REDIRECTS = [
  '/jobs',
  '/privacy',
  '/terms',
  '/changelog',
  '/faq',
  '/press',
  '/mediakit',
  '/roadmap',
  '/schedule',
  '/frond',
  '/communities/new',
];

export const LANDING_LAUNCH_BANNER_EMOJI =
  process.env.NEXT_PUBLIC_LANDING_LAUNCH_BANNER_EMOJI;
export const LANDING_LAUNCH_BANNER_MESSAGE =
  process.env.NEXT_PUBLIC_LANDING_LAUNCH_BANNER_MESSAGE;
export const LANDING_LAUNCH_BANNER_URL =
  process.env.NEXT_PUBLIC_LANDING_LAUNCH_BANNER_URL;
export const CUSTOM_DOMAINS_LAUNCHED =
  process.env.NEXT_PUBLIC_CUSTOM_DOMAINS_LAUNCHED;
export const DISABLE_EMOJI_SPRITE_SHEET = true;
