import { useApolloClient } from '@apollo/client';
import { routes, theme } from '@frond/shared';
import { useUp, x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { NextSeo } from 'next-seo';
import { FC, useState } from 'react';

import { NotificationFragmentFragment } from '../../../../generated/graphql-request-api-sdk';
import {
  InboxState,
  InboxStateDocument,
  Organization,
  refetchNotificationCountsQuery,
  useInboxStateQuery,
  useMarkNotificationReadMutation,
  useNotificationCountsQuery,
  useNotificationsQuery,
} from '../../../../generated/types-and-hooks';
import { BASE_URL } from '../../../config/constants';
import ProfilePage from '../../../pages/[shortId]/members/[username]';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Icon } from '../../common/components/Icon';
import { LoadingPulse } from '../../common/components/LoadingPulse';
import { Switch } from '../../common/components/Switch';
import { Text } from '../../common/components/Text';
import { InfiniteScroll } from '../../common/layout/InfiniteScroll';
import { EventPage } from '../../events/views/EventPage';
import { NewMemberPostView } from '../../posts/views/NewMemberPostView';
import { PostDetailView } from '../../posts/views/PostDetailView';
import { PostNavContext } from '../../questions/hooks/useAnswerNavigation';
import { GroupPage } from '../../questions/views/GroupPage';
import { InboxNotification } from '../components/InboxNotification';
import { InboxNotificationView } from '../components/InboxNotificationView';
import { MarkAsReadButton } from '../components/MarkAsReadButton';

const UPPER_BREAKPOINT = `${theme.screens['1348px']}px`;
export const NUM_NOTIFICATIONS = 20;

const getPostId = (
  organization: Pick<Organization, 'shortId'>,
  notification: Pick<NotificationFragmentFragment, 'link'>
) => {
  const url = new URL(notification.link);
  if (
    !url.pathname.startsWith(
      routes.groups.organization(organization.shortId).post('')
    ) ||
    url.pathname.endsWith('new-member')
  ) {
    return null;
  }
  return url.pathname.replace(
    routes.groups.organization(organization.shortId).post(''),
    ''
  );
};

const getGroupId = (
  organization: Pick<Organization, 'shortId'>,
  notification: Pick<NotificationFragmentFragment, 'link'>
) => {
  const url = new URL(notification.link);
  if (
    !url.pathname.startsWith(
      routes.groups.organization(organization.shortId).group('')
    )
  ) {
    return null;
  }

  return url.pathname.replace(
    routes.groups.organization(organization.shortId).group(''),
    ''
  );
};

const getEventId = (
  organization: Pick<Organization, 'shortId'>,
  notification: Pick<NotificationFragmentFragment, 'link'>
) => {
  const url = new URL(notification.link);
  if (
    !url.pathname.startsWith(
      routes.groups.organization(organization.shortId).event('')
    )
  ) {
    return null;
  }

  return url.pathname.replace(
    routes.groups.organization(organization.shortId).event(''),
    ''
  );
};

const getIntroduceYourself = (
  organization: Pick<Organization, 'shortId'>,
  notification: Pick<NotificationFragmentFragment, 'link'>
) => {
  const url = new URL(notification.link);
  if (
    !url.pathname.startsWith(
      routes.groups.organization(organization.shortId).newMember()
    )
  ) {
    return null;
  }

  return url.pathname;
};

const getUserJoined = (
  organization: Pick<Organization, 'shortId'>,
  notification: Pick<NotificationFragmentFragment, 'link' | 'user'>
) => {
  const url = new URL(notification.link);
  if (
    notification.user &&
    !url.pathname.startsWith(
      routes.groups
        .organization(organization.shortId)
        .person(notification.user?.username)
    )
  ) {
    return null;
  }

  return url.pathname;
};

const getNotificationPathname = (
  organization: Pick<Organization, 'shortId'>,
  notification: Pick<NotificationFragmentFragment, 'id' | 'link'>
) => {
  const url = new URL(notification.link);
  if (
    !url.pathname.startsWith(
      routes.groups
        .organization(organization.shortId)
        .notification(notification.id)
    )
  ) {
    return null;
  }

  return url.pathname;
};

/**
 * The inbox functions in one of two modes, depending on screen size
 */
export const InboxView: FC = () => {
  const { t } = useTranslation('inbox');
  const { cache } = useApolloClient();
  const { organization } = useOrganization();
  const [selectedNotification, setSelectedNotification] =
    useState<NotificationFragmentFragment | null>(null);

  const { data: inboxStateQuery } = useInboxStateQuery();
  const { data: notificationCountsQuery } = useNotificationCountsQuery({
    variables: { organizationId: organization.id },
  });

  const mutateInboxState = (inboxState: InboxState) => {
    cache.writeQuery({
      query: InboxStateDocument,
      data: {
        inboxState,
      },
    });
  };

  const showRead = inboxStateQuery?.inboxState.showRead !== false;
  const setShowRead = (showRead: boolean) => mutateInboxState({ showRead });

  const postId =
    selectedNotification && getPostId(organization, selectedNotification);

  const groupId =
    selectedNotification && getGroupId(organization, selectedNotification);

  const eventId =
    selectedNotification && getEventId(organization, selectedNotification);

  const introduceYourselfPath =
    selectedNotification &&
    getIntroduceYourself(organization, selectedNotification);
  const userJoinedPath =
    selectedNotification && getUserJoined(organization, selectedNotification);
  const notificationPath =
    selectedNotification &&
    getNotificationPathname(organization, selectedNotification);

  const openGraph = {
    title: `Frond - Inbox`,
    url: `${BASE_URL}${routes.groups
      .organization(organization.shortId)
      .inbox()}`,
  };

  const shouldHaveZeroPadding =
    !!groupId || (userJoinedPath && selectedNotification.user) || !!eventId;

  return (
    <x.div display="flex">
      <NextSeo
        title={openGraph.title}
        canonical={openGraph.url}
        openGraph={openGraph}
      />
      <x.div
        flex={1}
        maxWidth={{ _: 'none', [UPPER_BREAKPOINT]: '22.5rem' }}
        display={{
          _: selectedNotification ? 'none' : 'block',
          [UPPER_BREAKPOINT]: 'block',
        }}
        h="100vh"
        boxSizing="border-box"
        overflowY="auto"
      >
        <x.div
          display="flex"
          justifyContent={{ _: 'flex-end', sm: 'space-between' }}
          alignItems="center"
          p={{ _: '3 4', 'lg-2': 5 }}
          borderWidth={0}
          borderBottomWidth="1"
          borderStyle="solid"
          borderColor="gray.100"
        >
          <x.div display={{ _: 'none', sm: 'block' }}>
            <MarkAsReadButton />
          </x.div>

          <x.div display="flex" alignItems="center" spaceX="2">
            <Text variant="sm-semibold" color="gray.300" as="span">
              <label htmlFor="show-read">{t('inbox.show_read')}</label>
            </Text>
            <Switch
              id="show-read"
              checked={showRead}
              onCheckedChange={setShowRead}
              size="xs"
            />
          </x.div>
        </x.div>
        <InboxNotifications
          onSelectNotification={setSelectedNotification}
          selectedNotification={selectedNotification}
          showRead={showRead}
        />
      </x.div>
      <x.div
        p={!shouldHaveZeroPadding && 5}
        flex={1}
        borderLeftWidth="1px"
        borderColor="gray.100"
        borderStyle="solid"
        display={{
          _: selectedNotification ? 'block' : 'none',
          [UPPER_BREAKPOINT]: 'block',
        }}
        h="100vh"
        boxSizing="border-box"
        overflowY="auto"
      >
        {!selectedNotification && (
          <InboxUnselectedState
            unreadCount={notificationCountsQuery?.notificationCounts.unread}
          />
        )}
        {postId && <PostDetailView id={postId} showLayout={false} />}
        {groupId && <GroupPage id={groupId} showLayout={false} />}
        {introduceYourselfPath && <NewMemberPostView showLayout={false} />}
        {userJoinedPath && selectedNotification.user && (
          <ProfilePage
            username={selectedNotification.user.username}
            showLayout={false}
          />
        )}
        {notificationPath && (
          <InboxNotificationView id={selectedNotification.id} />
        )}
        {eventId && <EventPage id={eventId} showLayout={false} />}
      </x.div>
    </x.div>
  );
};

const InboxUnselectedState: FC<{ unreadCount?: number }> = ({
  unreadCount,
}) => {
  const { t } = useTranslation('inbox');
  const loading = unreadCount === undefined;
  const iconName = unreadCount ? 'mailbox-2' : 'mailbox';

  return (
    <x.div
      display="flex"
      flex="1"
      alignItems="center"
      justifyContent="center"
      h="100%"
    >
      <x.div
        maxWidth="40"
        textAlign="center"
        display="flex"
        alignItems="center"
        flexDirection="column"
        spaceY="5"
      >
        {loading && <LoadingPulse />}
        {!loading && (
          <>
            <Icon name={iconName} size="16" opacity={0.4} />
            <Text variant="md" color="gray.300">
              {t('inbox.notification_count', { count: unreadCount })}
            </Text>
          </>
        )}
      </x.div>
    </x.div>
  );
};

const InboxNotifications: FC<{
  selectedNotification: NotificationFragmentFragment | null;
  onSelectNotification: (notification: NotificationFragmentFragment) => void;
  showRead: boolean;
}> = ({ onSelectNotification, selectedNotification, showRead }) => {
  const showPreview = useUp(UPPER_BREAKPOINT);
  const router = useRouter();
  const { organization } = useOrganization();

  // Posts are retained after being marked as read until the view is reloaded
  const [retainedIds, setRetainedIds] = useState<string[]>([]);

  const [markNotificationRead] = useMarkNotificationReadMutation({
    refetchQueries: [
      refetchNotificationCountsQuery({ organizationId: organization.id }),
    ],
  });

  const { data, loading, fetchMore } = useNotificationsQuery({
    variables: {
      organizationId: organization.id,
      first: NUM_NOTIFICATIONS,
    },
  });

  const nodes = data?.notifications.edges.map((e) => e.node);
  const notifications =
    nodes &&
    (showRead
      ? nodes
      : nodes.filter((n) => !n.read || retainedIds.includes(n.id)));

  const handleSelectNotification = (
    notification: NotificationFragmentFragment
  ) => {
    if (!notification.read) {
      markNotificationRead({
        variables: {
          input: {
            organizationId: organization.id,
            notificationId: notification.id,
          },
        },
      });

      if (showRead === false) {
        setRetainedIds([...retainedIds, notification.id]);
      }
    }

    const url = new URL(notification.link);
    const pathname = url.pathname + url.hash;

    if (showPreview) {
      window.history.replaceState(null, '', pathname);
      onSelectNotification(notification);
      return;
    }

    router.push({ pathname, query: { ctx: PostNavContext.INBOX } }, pathname);
  };

  return (
    <InfiniteScroll
      threshold={512}
      loading={loading}
      hasMore={showRead && Boolean(data?.notifications.pageInfo.hasNextPage)}
      loadMore={() => {
        fetchMore({
          variables: {
            after: data?.notifications.pageInfo.endCursor,
            first: NUM_NOTIFICATIONS,
          },
        });
      }}
    >
      <x.div display="flex" flexDirection="column">
        {notifications?.map((n) => (
          <x.div
            key={n.id}
            onClick={() => handleSelectNotification(n)}
            borderBottomWidth="1"
            borderStyle="solid"
            borderColor="gray.100"
          >
            <InboxNotification
              notification={n}
              selected={selectedNotification?.id === n.id}
            />
          </x.div>
        ))}
      </x.div>
    </InfiniteScroll>
  );
};
