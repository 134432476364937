import { theme } from '@frond/shared';
import styled, { css, x } from '@xstyled/styled-components';
import { system, SystemProps, th } from '@xstyled/system';
import { useRouter } from 'next/router';
import React, {
  ChangeEvent,
  FocusEvent,
  PropsWithChildren,
  useRef,
} from 'react';
import { ForwardRefRenderFunction } from 'react';
import TextareaAutosize from 'react-autosize-textarea';

import analytics from '../utils/analytics';
import { leftAlign } from '../utils/position';
import { Icon, IconWrapper } from './Icon';
import { Text } from './Text';
import { Tooltip } from './Tooltip';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Element to use as left icon glyph
   */
  icon?: React.ReactElement<typeof Icon>;
  /**
   * Alternative input component to use
   */
  inputComponent?: React.FC<React.HTMLProps<HTMLInputElement>>;
  /**
   * Optional label
   */
  label?: string;
  /**
   * Optional prefix
   */
  prefix?: string;
  /**
   * Optional suffix
   */
  suffix?: string;
  /**
   * Optional element suffix
   */
  suffixElement?: JSX.Element;
  /**
   * input width fills container
   */
  fullWidth?: boolean;
  /**
   * Vertical icon offset
   */
  iconOffset?: string;
  /**
   * Transforms the input into a textarea
   */
  isMultiLine?: boolean;
  /**
   * extra context to send with input focus/blur action
   */
  metadata?: { context?: string };
  /**
   * Validation error
   */
  error?: string;

  rows?: number;

  /**
   * Whether to show the error tooltip
   */
  showErrorTooltip?: boolean;
  maxWidth?: string;
}

const IconContainer = styled.div<{
  iconOffset: string | undefined;
}>`
  position: absolute;
  padding-left: 4;
  pointer-events: none;
  height: 100%;
  display: flex;
  align-items: center;
  color: gray.200;
  top: ${(p) => p.iconOffset || 0};
  left: 0;

  ${IconWrapper} {
    font-size: 1.25em;
  }
`;

export const InputContainer = styled.div<{
  hasExtraPadding?: boolean;
  fullWidth?: boolean;
}>`
  position: relative;
  width: xs;

  ${(p) =>
    p.hasExtraPadding &&
    css`
      width: calc(${th.size('xs')} + ${th.size('8')});
    `}

  @media (max-width: sm) {
    width: 100%;
    max-width: sm;
  }

  ${(p) =>
    p.fullWidth &&
    css`
      width: full;
      box-sizing: border-box;

      input {
        width: 100%;
      }

      @media (max-width: sm) {
        width: full;
        max-width: full;
      }
    `}

  display: inline-flex;
  vertical-align: top;
`;

// Multi line inputs have slightly different padding
export const inputWithLabelAndPlaceholderStyles = css`
  padding-top: calc(${th.size('9')} - 1px);
  padding-bottom: calc(${th.size('3')} - 1px);

  ~ label {
    transform: translateY(-${th.size('3')}) scale(0.875);
  }
`;

// Multi line inputs have slightly different padding
export const inputWithLabelStyles = css`
  padding-top: calc(${th.size('9')} - 1px);
  padding-bottom: calc(${th.size('3')} - 1px);

  &::placeholder {
    color: transparent;
  }

  &:focus ~ label,
  &:not(:placeholder-shown) ~ label {
    transform: translateY(-${th.size('3')}) scale(0.875);
  }

  ~ span {
    opacity: 0;
    transition: 0.2s ease all;
  }

  &:focus ~ span,
  &:not(:placeholder-shown) ~ span {
    opacity: 1;
  }
`;

export const inputWithPrefixStyles = css`
  &:focus ~ label,
  &:placeholder-shown ~ label {
    transform: translateY(-${th.size('3')}) scale(0.875);
  }
`;

// Icon inputs have slightly different padding
export const inputWithIconStyles = css`
  padding-left: 10;
  padding-right: 10;
`;

export const inputWithErrorStyles = css`
  border-color: red.300;

  &:hover {
    border-color: red.300;
  }

  &:focus {
    border-color: red.300;
  }
`;

export const inputStyles = css`
  resize: none;
  appearance: none;
  outline: none;
  margin: 0;
  color: inherit;
  background: white;
  border: default;
  border-radius: md;
  border-color: gray.200;
  flex-grow: 1;
  min-width: 0;
  /* Remove 1px from padding on all sides to account for inset border */
  /* Using 0.625rem because adding a non-integer value to sizes.ts would resolve in the theme getter */
  padding-top: calc(0.625rem - 1px);
  padding-bottom: calc(0.625rem - 1px);
  padding-left: calc(${th.size('4')} - 1px);
  padding-right: calc(${th.size('4')} - 1px);

  ${th('typographyStyles.text.md')}

  &:hover {
    border-color: brand.200;
  }

  &:focus {
    border-color: brand.300;

    ~ div {
      color: brand.300;
    }
  }

  &::placeholder {
    font-weight: 400;
    color: gray.200;
  }

  &:disabled {
    opacity: 1;
    -webkit-text-fill-color: ${th('colors.gray.200')};
    background: transparent;
    color: gray.200;
    cursor: not-allowed;
    border-color: gray.200;
  }
`;

const StyledInput = styled.input<
  {
    hasLabel?: boolean;
    hasIcon?: boolean;
    hasError?: boolean;
    hasPlaceholder?: boolean;
    hasPrefix?: boolean;
    prefixPadding?: number;
    suffixPadding?: number;
  } & SystemProps
>`
  ${inputStyles}
  ${(p) => p.hasLabel && p.hasPlaceholder && inputWithLabelAndPlaceholderStyles}
  ${(p) => p.hasLabel && !p.hasPlaceholder && inputWithLabelStyles}
  ${(p) => p.hasIcon && inputWithIconStyles}
  ${(p) => p.hasError && inputWithErrorStyles}
  ${(p) => p.hasPrefix && inputWithPrefixStyles}
  ${(p) =>
    p.prefixPadding &&
    css`
      padding-left: calc(${th.size('4')} + ${p.prefixPadding}px);
    `}
  ${(p) =>
    p.suffixPadding &&
    css`
      padding-right: calc(${th.size('4')} + ${p.suffixPadding}px);
    `}
  ${system};
`;

const StyledTextarea = styled(TextareaAutosize)<
  {
    hasLabel?: boolean;
    hasIcon?: boolean;
    hasError?: boolean;
    hasPlaceholder?: boolean;
    hasPrefix?: boolean;
    prefixPadding?: number;
    suffixPadding?: number;
  } & SystemProps
>`
  ${inputStyles}
  ${(p) => p.hasLabel && p.hasPlaceholder && inputWithLabelAndPlaceholderStyles}
  ${(p) => p.hasLabel && !p.hasPlaceholder && inputWithLabelStyles}
  ${(p) => p.hasIcon && inputWithIconStyles}
  ${(p) => p.hasError && inputWithErrorStyles}
  ${(p) => p.hasPrefix && inputWithPrefixStyles}
  ${(p) =>
    p.prefixPadding &&
    css`
      padding-left: calc(${th.size('4')} + ${p.prefixPadding}px);
    `}
  ${(p) =>
    p.suffixPadding &&
    css`
      padding-right: calc(${th.size('4')} + ${p.suffixPadding}px);
    `}
  ${system};
`;

const StyledLabel = styled(Text).attrs({ forwardedAs: 'label' })`
  color: gray.300;
  position: absolute;
  pointer-events: none;
  top: ${th.size('6')};
  left: ${th.size('4')};
  transform-origin: 0 0;
  transition: 0.2s ease all;
`;

const StyledSuffixText = styled(Text).attrs({
  forwardedAs: 'span',
})`
  position: absolute;
  bottom: ${th.size('3')};
  right: ${th.size('4')};
  pointer-events: none;
  padding-left: 1;
  background: white;
`;

const StyledPrefixText = styled(Text).attrs({
  forwardedAs: 'span',
})`
  position: absolute;
  bottom: ${th.size('4')};
  left: ${th.size('4')};
  pointer-events: none;
  background: white;
  color: gray.200;
`;

const RelativeDiv = styled.div`
  position: relative;
  width: 100%;
`;

/**
 * Wrapper that adds a tooltip to inputs with errors
 */
const InputErrorWrapper: React.FC<
  PropsWithChildren<Pick<InputProps, 'error' | 'showErrorTooltip'>>
> = ({ error, showErrorTooltip, children }) => {
  if (!showErrorTooltip) return <>{children}</>;
  return (
    <Tooltip
      disabled={!error}
      maxWidth="auto"
      size="small"
      position={leftAlign}
      label={<Text variant="sm-semibold">{error}</Text>}
      aria-label={error || ''}
    >
      <RelativeDiv>{children}</RelativeDiv>
    </Tooltip>
  );
};

/**
 * Primary component for text input
 */
const CustomInput: ForwardRefRenderFunction<
  HTMLInputElement,
  InputProps & React.InputHTMLAttributes<HTMLInputElement>
> = (
  {
    icon,
    className,
    inputComponent,
    label,
    prefix,
    suffix,
    suffixElement,
    fullWidth = false,
    iconOffset,
    isMultiLine = false,
    onChange,
    onBlur,
    onFocus,
    metadata,
    error,
    showErrorTooltip = true,
    ...props
  },
  ref
) => {
  const prefixRef = useRef<HTMLSpanElement>(null);
  const suffixRef = useRef<HTMLSpanElement>(null);
  const router = useRouter();
  const InputComponent = inputComponent
    ? inputComponent
    : isMultiLine
      ? StyledTextarea
      : StyledInput;

  const hasLabel = !!label;
  const hasPlaceholder = !!props.placeholder;
  const placeholder = props.placeholder || ' ';

  return (
    <InputErrorWrapper showErrorTooltip={showErrorTooltip} error={error}>
      <InputContainer
        className={className}
        hasExtraPadding={!!label || !!suffix || !!suffixElement}
        fullWidth={fullWidth}
      >
        {prefix && (
          <StyledPrefixText ref={prefixRef} as="span">
            {prefix}
          </StyledPrefixText>
        )}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/* @ts-ignore */}
        <InputComponent
          {...(InputComponent !== inputComponent && {
            hasLabel,
            hasPlaceholder,
            hasIcon: !!icon,
            hasError: !!error,
            hasPrefix: !!prefix,
            hasSuffix: !!suffix,
            prefixPadding: prefixRef?.current?.offsetWidth,
            suffixPadding: suffixRef?.current?.offsetWidth,
          })}
          ref={ref}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            analytics.logEvent(analytics.events.INPUT_CHANGE, {
              name: props.name || label,
              page: router?.pathname,
              url: router?.asPath,
              ...metadata,
            });
            onChange?.(e);
          }}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            analytics.logEvent(analytics.events.INPUT_BLUR, {
              name: props.name || label,
              page: router?.pathname,
              url: router?.asPath,
              ...metadata,
            });
            onBlur?.(e);
          }}
          onFocus={(e: FocusEvent<HTMLInputElement>) => {
            analytics.logEvent(analytics.events.INPUT_FOCUS, {
              name: props.name || label,
              page: router?.pathname,
              url: router?.asPath,
              ...metadata,
            });
            onFocus?.(e);
          }}
          {...props}
          name={props.name || label}
          placeholder={placeholder}
        />
        {label && <StyledLabel>{label}</StyledLabel>}
        {icon && <IconContainer iconOffset={iconOffset}>{icon}</IconContainer>}
        {suffix && <StyledSuffixText>{suffix}</StyledSuffixText>}
        {suffixElement && (
          <x.span
            position="absolute"
            bottom={theme.sizes['3']}
            right={theme.sizes['4']}
            pl={1}
            backgroundColor="white"
            ref={suffixRef}
          >
            {suffixElement}
          </x.span>
        )}
      </InputContainer>
    </InputErrorWrapper>
  );
};

export const Input = React.forwardRef(CustomInput);
