import {
  ObservableQuery,
  OperationVariables,
  useApolloClient,
} from '@apollo/client';
import { useContext } from 'react';

import {
  refetchViewerQuery,
  useUpdateUserOnboardingMutation,
  ViewerDocument,
  ViewerQuery,
  ViewerQueryVariables,
} from '../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { AppContext } from '../../common/machine/appContext';
import { AppMachineOrganization } from '../../common/machine/appMachine';

export const useUpdateUserOnboarding = ({
  onComplete,
}: {
  onComplete?: () => void;
}) => {
  const { appService } = useContext(AppContext);
  const client = useApolloClient();

  const [mutateUpdateUserOnboarding, { loading }] =
    useUpdateUserOnboardingMutation();

  const { setOrganization, setAndViewOrganization } = useOptionalOrganization();

  const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));
  const delayRefetchedQuery = async (
    observableQuery: ObservableQuery<any, OperationVariables>
  ) => {
    await wait(3000);
    observableQuery.refetch();
  };

  const updateUserOnboarding = (
    organization: AppMachineOrganization,
    creatingNewOrganization?: boolean
  ) => {
    mutateUpdateUserOnboarding({
      variables: {
        input: {
          organizationId: organization.id,
        },
      },
      refetchQueries: [refetchViewerQuery()],
      fetchPolicy: 'network-only',
      awaitRefetchQueries: true,
      ...(creatingNewOrganization && { onQueryUpdated: delayRefetchedQuery }),
      onCompleted: () => {
        client
          .query<ViewerQuery, ViewerQueryVariables>({
            query: ViewerDocument,
          })
          .then(({ data }) => {
            appService.send({
              type: 'UPDATE_USER',
              user: data.viewer,
            });

            // Only set and redirect to organization when it was created as part of onboarding
            if (creatingNewOrganization) {
              setAndViewOrganization(organization);
            } else {
              // Set organization when user sets up profile first time when joining organization
              setOrganization(organization);
            }

            onComplete?.();
          });
      },
    });
  };

  return {
    updateUserOnboarding,
    loading,
  };
};
