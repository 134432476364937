import { routes, theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC, useState } from 'react';

import { IS_DEMO } from '../../../../config/constants';
import { useOrganization } from '../../../auth/hooks/useOrganization';
import { useViewer } from '../../../auth/hooks/useViewer';
import { userInOrganization } from '../../../auth/utils/permissions';
import { Avatar } from '../../../common/components/Avatar';
import { Icon } from '../../../common/components/Icon';
import { Text } from '../../../common/components/Text';
import { useSignOut } from '../../../common/hooks/useApollo';
import { FeedbackWidget } from '../../../common/layout/FeedbackWidget';
import { getUserDisplayName } from '../../../common/utils/user';
import { RestrictedDemoActionModal } from '../../../demo/RestrictedDemoActionModal';
import { UserSettingsMenuDivider } from './UserSettingsMenuDivider';
import { UserSettingsMenuRow } from './UserSettingsMenuRow';

export const UserSettingsMenu: FC<{
  minWidth?: string;
}> = ({ minWidth }) => {
  const { t } = useTranslation(['common']);
  const { viewer: user } = useViewer();
  const { organization } = useOrganization();
  const router = useRouter();
  const signOut = useSignOut();
  const [showRestrictedDemoActionModal, setShowRestrictedDemoActionModal] =
    useState(false);
  const [feedback, setFeedback] = useState(false);

  if (!user) {
    return null;
  }

  return (
    <>
      <x.div
        display="flex"
        flexDirection="column"
        spaceY="4"
        minWidth={minWidth}
      >
        <x.div display="flex" spaceX="3">
          <x.div mt={theme.sizes['0.5']}>
            <Avatar
              size="xs"
              imageId={user.profileImageId}
              avatar={user.avatar}
              bgColor={user.avatarBgColor}
            />
          </x.div>
          <x.div>
            <Text variant="md">{getUserDisplayName(user)}</Text>
            <Text variant="sm" color="gray.300">
              {user.email}
            </Text>
          </x.div>
        </x.div>
        <UserSettingsMenuDivider />
        <UserSettingsMenuRow onClick={() => setFeedback(true)}>
          <Icon name="message" size="5" />
          <Text variant="md" as="span">
            {t('share_feedback')}
          </Text>
        </UserSettingsMenuRow>
        <UserSettingsMenuRow
          onClick={() => window.open(routes.marketing.faq(), '_blank')}
        >
          <Icon name="circle-question-mark" size="5" />
          <Text variant="md" as="span">
            {t('faq')}
          </Text>
        </UserSettingsMenuRow>
        {userInOrganization(user, organization) ? (
          <UserSettingsMenuRow
            onClick={() =>
              router.push(
                routes.groups
                  .organization(organization.shortId)
                  .person(user.username)
              )
            }
          >
            <Icon name="profile" size="5" />
            <Text variant="md" as="span">
              {t('view_profile')}
            </Text>
          </UserSettingsMenuRow>
        ) : null}

        <UserSettingsMenuDivider />
        <UserSettingsMenuRow
          onClick={() => router.push(routes.settings.account())}
        >
          <Icon name="profile" size="5" />
          <Text variant="md" as="span">
            {t('account_settings')}
          </Text>
        </UserSettingsMenuRow>
        <UserSettingsMenuRow
          onClick={() => {
            if (IS_DEMO) {
              setShowRestrictedDemoActionModal(true);
            }

            signOut('/');
          }}
        >
          <Icon name="sign-out" size="5" />
          <Text variant="md" as="span">
            {t('sign_out')}
          </Text>
        </UserSettingsMenuRow>
        {IS_DEMO && (
          <RestrictedDemoActionModal
            onDismiss={() => setShowRestrictedDemoActionModal(false)}
            isOpen={showRestrictedDemoActionModal}
          />
        )}
      </x.div>
      <FeedbackWidget
        isOpen={feedback}
        variant="full"
        onDismiss={() => setFeedback(false)}
      />
    </>
  );
};
