import * as React from "react";
import { SVGProps } from "react";
const SvgLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.417 5.417A4.593 4.593 0 0 0 .833 10a4.593 4.593 0 0 0 4.584 4.583h1.875a.626.626 0 1 0 0-1.25H5.417A3.324 3.324 0 0 1 2.083 10a3.324 3.324 0 0 1 3.334-3.333h1.875a.625.625 0 1 0 0-1.25H5.417Zm7.291 0a.625.625 0 1 0 0 1.25h1.875A3.324 3.324 0 0 1 17.917 10a3.324 3.324 0 0 1-3.334 3.333h-1.875a.624.624 0 1 0 0 1.25h1.875A4.593 4.593 0 0 0 19.167 10a4.593 4.593 0 0 0-4.584-4.583h-1.875ZM4.792 9.375a.625.625 0 1 0 0 1.25h10.416a.624.624 0 1 0 0-1.25H4.792Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgLink;
