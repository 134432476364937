import { theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC, PropsWithChildren, useState } from 'react';

import {
  GroupSidebarFragment,
  useGroupUnseenPostsQuery,
} from '../../../../../generated/types-and-hooks';
import { useViewer } from '../../../auth/hooks/useViewer';
import { userInOrganization } from '../../../auth/utils/permissions';
import { Emoji } from '../../../common/components/Emoji';
import { Icon } from '../../../common/components/Icon';
import { Link } from '../../../common/components/Link';
import { NextNavLink } from '../../../common/components/NextLink';
import { Text } from '../../../common/components/Text';
import { Tooltip } from '../../../common/components/Tooltip';
import { isInternalURL } from '../../../common/utils';
import { topCenter } from '../../../common/utils/position';
import { useGroupMembership } from '../../hooks/useGroupMembership';
import { GroupsSidebarOrganization } from './GroupsSidebar';

export type GroupsSidebarLinkProps = {
  name: string;
  badge?: JSX.Element;
  dot?: boolean;
  symbol?: React.ReactElement; // icon or emoji
  onClick?: () => void;
  isPrivateGroup?: boolean | null;
  action?: JSX.Element;
};

/**
 * Common sidebar link layout
 */
export const GroupsSidebarLink: FC<GroupsSidebarLinkProps> = ({
  name,
  symbol,
  onClick,
  badge,
  dot,
  isPrivateGroup,
  action,
}) => {
  const EMOJI_SIZE_PX = 20;
  const GAP_PX = 8;
  const SIDEBAR_INNER_SIZE_PX = 234;
  const SIDEBAR_LINK_TEXT_MAX_SIZE_PX =
    SIDEBAR_INNER_SIZE_PX -
    (EMOJI_SIZE_PX + GAP_PX) * (isPrivateGroup ? 2 : 1) -
    (action ? 20 : 0);

  return (
    <x.span
      display="flex"
      w="full"
      justifyContent="space-between"
      spaceX={2}
      alignItems="center"
      cursor="pointer"
      onClick={onClick}
    >
      <x.span display="flex" alignItems="center" spaceX={2}>
        {symbol}
        <Text
          variant="md"
          maxW={SIDEBAR_LINK_TEXT_MAX_SIZE_PX - 20}
          as="span"
          truncate
        >
          {name}
        </Text>
        {dot && (
          <x.div
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor="brand.300"
            borderRadius="full"
            h={theme.sizes['1.5']}
            w={theme.sizes['1.5']}
            boxSizing="border-box"
          />
        )}
      </x.span>
      <x.span>
        {badge ? badge : null}
        {action ? action : null}
      </x.span>
    </x.span>
  );
};

/**
 * Top level navigation links
 */
export const GroupsSidebarNavigationLink: React.FC<
  GroupsSidebarLinkProps & {
    route?: string;
    icon: string;
    selectedIcon: string;
    exact?: boolean;
    action?: JSX.Element;
  }
> = ({
  name,
  icon,
  selectedIcon,
  route,
  badge,
  onClick,
  exact = true,
  action,
}) => {
  const router = useRouter();
  const [hover, setHover] = useState(false);
  const isSelected = route
    ? exact
      ? router.asPath === route
      : router.asPath.startsWith(route)
    : false;
  const navIcon = isSelected ? selectedIcon : icon;
  const symbol = <Icon name={navIcon} size="5" />;

  const sidebarLink = (
    <x.li
      display="flex"
      boxSizing="border-box"
      w="full"
      backgroundColor={{
        _: 'gray.50',
        sm: {
          _: isSelected ? 'brand.50' : 'transparent',
          hover: !isSelected && 'gray.50',
        },
      }}
      transitionDuration="fast"
      transitionTimingFunction="ease-in-out"
      borderRadius={{
        _: 'sm-md',
        sm: 'sm',
      }}
      py={{ _: 2, sm: 1.5 }}
      px={{ _: 4, sm: 3 }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <GroupsSidebarLink
        name={name}
        symbol={symbol}
        onClick={onClick}
        badge={badge}
        action={hover ? action : undefined}
      />
    </x.li>
  );

  return route && isInternalURL(route) ? (
    <NextNavLink href={route} exact={exact} passHref>
      {sidebarLink}
    </NextNavLink>
  ) : route ? (
    <Link target={'_blank'} rel={'noopener'} href={route} styleInheritColor>
      {sidebarLink}
    </Link>
  ) : (
    sidebarLink
  );
};

/**
 * Link to an individual group
 */
export const GroupsSidebarGroupLink: React.FC<
  GroupsSidebarLinkProps & {
    group: GroupSidebarFragment;
    route: string;
    organization: GroupsSidebarOrganization;
  }
> = ({ name, route, badge, onClick, group, organization }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { viewer: user } = useViewer();
  const { userIsMember } = useGroupMembership({ organization, group });
  const { data } = useGroupUnseenPostsQuery({
    variables: { id: group.id },
    fetchPolicy: 'cache-first',
    skip: !user,
    context: {
      skip: !user,
    },
  });
  const isSelected = route ? router.asPath.startsWith(route) : false;
  const hasUnseenPosts =
    (data?.group?.__typename === 'Group' && data.group.hasUnseenPosts) || false;

  const symbol = group.emoji ? (
    <Emoji size={20} emojiUnicode={group.emoji} />
  ) : undefined;

  return (
    <x.li>
      <NextNavLink href={route} exact>
        <x.div
          display="flex"
          py={1.5}
          px={3}
          bg={{
            _: isSelected ? 'brand.50' : 'transparent',
            hover: !isSelected && 'gray.50',
          }}
          borderRadius="sm"
          alignItems="center"
          spaceX={2}
        >
          <x.div
            display="flex"
            alignItems="center"
            spaceX={2}
            opacity={
              group.isDraft
                ? 0.5
                : userIsMember ||
                    !user ||
                    !userInOrganization(user, organization)
                  ? 1
                  : 0.5
            }
          >
            <GroupsSidebarLink
              name={group.isDraft ? `${name} (${t('draft')})` : name}
              symbol={symbol}
              onClick={onClick}
              badge={badge}
              isPrivateGroup={group.isPrivate}
              dot={userIsMember && hasUnseenPosts}
            />
            {group.isPrivate && <Icon name="lock" color="gray.300" size="5" />}
          </x.div>
          {group.unlockedBy?.active && (
            <Tooltip
              label={<Text variant="sm-semibold">{t('subscriber_group')}</Text>}
              aria-label={t('subscriber_group')}
              position={topCenter}
            >
              <x.div>
                <Icon name="badge" color="gray.300" size="5" />
              </x.div>
            </Tooltip>
          )}
        </x.div>
      </NextNavLink>
    </x.li>
  );
};

export const GroupsSidebarLinkList: React.FC<
  PropsWithChildren & {
    variant?: 'default' | 'grid';
  }
> = ({ children, variant = 'default' }) => {
  return (
    <x.ul
      listStyleType="none"
      display="flex"
      flexDirection="column"
      flex-grow={1}
      color="gray.300"
      maxWidth={{ _: 'none', 'sm-2': 64 }}
      {...(variant === 'grid' && {
        display: { _: 'grid', sm: 'flex' },
        gridTemplateColumns: { _: '1fr 1fr', sm: 'none' },
        gap: { _: 3, sm: 0 },
        flexDirection: { _: 'initial', sm: 'column' },
        flexGrow: { _: 'initial', sm: '1' },
      })}
    >
      {children}
    </x.ul>
  );
};
