import * as React from "react";

function SvgSocialGithub(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.017 20.004c.005.122-.008.242-.034.364a2.17 2.17 0 01-.257.647c-.353.593-.953.988-1.726.988-1.82 0-2.534-.893-3.428-3.129C3.966 17.36 3.68 17.003 3 17.003v-2c1.82 0 2.534.893 3.428 3.128.606 1.515.892 1.872 1.572 1.872 0-.29-.004-.547-.012-.895-.02-.816-.022-.983.013-1.2.015-.477.138-.815.384-1.15-2.232-.486-3.737-1.49-4.601-3.117l-.324-.76C3.145 11.973 3 10.946 3 9.76c0-1.365.416-2.586 1.194-3.606-.243-.97-.21-2.156.326-3.494l.175-.438.45-.143c.06-.02.132-.037.218-.05.874-.138 2.117.19 3.743 1.23a13.265 13.265 0 012.983-.333c.908 0 1.81.091 2.673.272 1.581-.99 2.79-1.301 3.64-1.17.085.014.158.031.217.05l.452.143.175.44c.469 1.177.549 2.261.379 3.21C20.518 6.945 21 8.264 21 9.762c0 1.263-.09 2.212-.35 3.136l-.275.754c-.719 1.625-2.303 2.64-4.748 3.123.255.352.373.709.373 1.228v1l-.001 1a.312.312 0 00.008.064L16 22.003c-.852 0-1.485-.416-1.797-1.07a2.069 2.069 0 01-.203-.944v-1.986c0-.084-.003-.089-.207-.293-.546-.546-.793-.958-.793-1.707v-.905l.9-.09c2.68-.27 4.136-1.013 4.62-2.1l.226-.617c.18-.646.254-1.432.254-2.53 0-1.165-.407-2.129-1.177-2.898L17.4 6.44l.173-.573c.153-.509.19-1.111.027-1.798a3.672 3.672 0 00-.084.022c-.535.15-1.204.47-2.01 1.008l-.367.245-.429-.106a10.964 10.964 0 00-2.621-.311c-1.001 0-1.99.127-2.905.376l-.446.122-.381-.261c-.833-.57-1.526-.91-2.08-1.068a3.71 3.71 0 00-.114-.03c-.193.795-.117 1.448.08 1.938l.234.588-.43.464C5.36 7.796 5 8.695 5 9.762c0 .977.114 1.786.324 2.396l.262.62c.654 1.227 2.043 1.965 4.52 2.23l.894.096v.899c0 .75-.247 1.161-.793 1.707-.204.204-.207.21-.207.293l-.017.185c-.012.06-.012.217.004.872.007.3.011.539.013.788a.792.792 0 01.017.156z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSocialGithub;
