import * as React from "react";

function SvgAppDropbox(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 5.5l-6 4-6-4 6-4 6 4zM12 5.5l6 4 6-4-6-4-6 4zM12 13.5l6 4 6-4-6-4-6 4zM6 18.5l6 4 6-4-6-4-6 4zM12 13.5l-6 4-6-4 6-4 6 4z"
        fill="#007EE5"
      />
      <path
        d="M6 1.5l-6 4 6 4 6-4-6-4zm18 4l-6-4-6 4 6 4 6-4zm-12 8l6 4 6-4-6-4-6 4zm-6 5l6 4 6-4-6-4-6 4zm-6-5l6 4 6-4-6-4-6 4z"
        fill="url(#app-dropbox_svg__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="app-dropbox_svg__paint0_linear"
          x1={0.189}
          y1={5.094}
          x2={22.967}
          y2={15.715}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0.2} />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgAppDropbox;
