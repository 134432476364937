import * as React from "react";
import { SVGProps } from "react";
const SvgEventTime = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 20V6h18v14c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1Z"
      fill="url(#EventTime_svg__a)"
    />
    <path
      d="M21 4v3H3V4c0-.55.45-1 1-1h16c.55 0 1 .45 1 1Z"
      fill="url(#EventTime_svg__b)"
    />
    <path
      d="M19 9.75v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25ZM19 13.25v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25ZM15 16.75v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Z"
      fill="#fff"
    />
    <path
      opacity={0.2}
      d="M3 4v8.318c.91.433 1.925.682 3 .682a7 7 0 0 0 7-7c0-1.075-.25-2.09-.682-3H4c-.55 0-1 .45-1 1Z"
      fill="url(#EventTime_svg__c)"
    />
    <path
      d="M6 12A6 6 0 1 0 6 0a6 6 0 0 0 0 12Z"
      fill="url(#EventTime_svg__d)"
    />
    <path
      d="M6 11.5a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Z"
      fill="url(#EventTime_svg__e)"
    />
    <path
      d="M6 11A5 5 0 1 0 6 1a5 5 0 0 0 0 10Z"
      fill="url(#EventTime_svg__f)"
    />
    <path
      d="M6 6.25a.25.25 0 0 1-.177-.426l2.545-2.546a.25.25 0 0 1 .354.353L6.176 6.177A.25.25 0 0 1 6 6.25Z"
      fill="#45494D"
    />
    <path
      d="M6 6.375a.37.37 0 0 1-.167-.04l-3-1.5a.375.375 0 0 1 .335-.67l3 1.5a.375.375 0 0 1-.168.71Z"
      fill="#45494D"
    />
    <path d="M6 6.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" fill="#1E2021" />
    <path
      opacity={0.05}
      d="M17.75 6.25a1.5 1.5 0 0 1-1.5-1.5V3h3v1.75a1.5 1.5 0 0 1-1.5 1.5Z"
      fill="currentColor"
    />
    <path
      opacity={0.07}
      d="M17.75 5.875a1.125 1.125 0 0 1-1.125-1.125V3h2.25v1.75c0 .622-.503 1.125-1.125 1.125Z"
      fill="currentColor"
    />
    <path
      d="M17.75 5.5a.75.75 0 0 1-.75-.75v-2a.75.75 0 0 1 1.5 0v2a.75.75 0 0 1-.75.75Z"
      fill="#3CCBF4"
    />
    <defs>
      <radialGradient
        id="EventTime_svg__c"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.99 5.934) scale(7.0215)"
      >
        <stop offset={0.847} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="EventTime_svg__e"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(5.5 0 0 5.5 6 6)"
      >
        <stop />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="EventTime_svg__f"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.968 1.116) scale(11.142)"
      >
        <stop stopColor="#FAFAFB" />
        <stop offset={1} stopColor="#C8CDD1" />
      </radialGradient>
      <linearGradient
        id="EventTime_svg__a"
        x1={3.207}
        y1={6.831}
        x2={20.887}
        y2={19.744}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28AFEA" />
        <stop offset={1} stopColor="#047ED6" />
      </linearGradient>
      <linearGradient
        id="EventTime_svg__b"
        x1={3.21}
        y1={3.304}
        x2={20.599}
        y2={6.84}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0077D2" />
        <stop offset={1} stopColor="#0B59A2" />
      </linearGradient>
      <linearGradient
        id="EventTime_svg__d"
        x1={1.758}
        y1={1.758}
        x2={10.242}
        y2={10.242}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#889097" />
        <stop offset={1} stopColor="#64717C" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgEventTime;
