import {
  OrganizationRolesQuery,
  UsersQuery,
  useUsersQuery,
} from '../../../../../../generated/types-and-hooks';
import { USERS_PER_PAGE } from '../../../../../config/constants';
import { useOrganization } from '../../../../auth/hooks/useOrganization';
import { useOrganizationRoles } from '../../../hooks/useOrganizationRoles';
import { useUsers } from '../../../hooks/useUsers';

export type SearchMention =
  | { isEveryone: boolean }
  | UsersQuery['users']['edges'][0]['node']
  | OrganizationRolesQuery['organizationRoles'][0];

type UseSearchMentionsReturnType = {
  mentions?: SearchMention[];
  users?: UsersQuery['users']['edges'][0]['node'][];
  loading?: boolean;
};

export const useGroupSearchMentions = ({
  groupId,
}: {
  groupId?: string;
} = {}) => {
  const { organization } = useOrganization();

  const { data, loading, refetch } = useUsersQuery({
    variables: {
      organizationId: organization.id,
      groupId,
      first: USERS_PER_PAGE,
    },
  });

  const users = data?.users.edges.map(({ node }) => node) || [];

  const { data: dataOrganizationRoles, loading: loadingOrganizationRoles } =
    useOrganizationRoles();

  const roles = dataOrganizationRoles?.organizationRoles || [];

  const mentions = [...[{ isEveryone: true }], ...roles, ...users];

  return {
    mentions,
    users,
    loading: loading || loadingOrganizationRoles,
    refetch,
  };
};

export const useSearchMentions = (): UseSearchMentionsReturnType => {
  const { data, loading } = useUsers();
  const { data: dataOrganizationRoles, loading: loadingOrganizationRoles } =
    useOrganizationRoles();

  const users = data?.users.edges.map(({ node }) => node) || [];

  const roles = dataOrganizationRoles?.organizationRoles || [];

  const mentions = [...[{ isEveryone: true }], ...roles, ...users];

  return {
    mentions,
    users,
    loading: loading || loadingOrganizationRoles,
  };
};
