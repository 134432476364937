import { routes } from '@frond/shared';
import { useRouter } from 'next/router';

import { ErrorPage } from '../../auth/views/ErrorPage';
import { useSignOut } from '../../common/hooks/useApollo';

export const Error401View: React.FC<{
  actionLabel?: string;
  action?: () => void;
  errorMessage?: string;
}> = ({
  actionLabel = 'Sign in with a different user',
  action,
  errorMessage = "You don't have access to this community.",
}) => {
  const router = useRouter();
  const signout = useSignOut();
  return (
    <ErrorPage
      errorMessage={errorMessage}
      errorCode="401"
      emojiUnicode="👮‍♀️"
      actionLabel={actionLabel}
      action={
        action
          ? action
          : () => {
              signout();
              router.push(routes.signin());
            }
      }
    />
  );
};
