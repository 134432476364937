import mapValues from 'lodash/mapValues';

const KEYBOARD_SHORTCUTS = {
  submit: 'mod+enter',
};

/**
 * Gets a list of standard keyboard shortcuts for this platform
 * On MacOS, the mod key is 'cmd', on every other platform 'ctrl'
 */
export const getPlatformShortcuts = (): typeof KEYBOARD_SHORTCUTS => {
  const isMacos = navigator.appVersion.includes('Mac');
  const mod = isMacos ? 'cmd' : 'ctrl';

  return mapValues(KEYBOARD_SHORTCUTS, (value) => value.replace(/^mod/, mod));
};
