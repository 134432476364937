import { Node } from 'slate';
import * as yup from 'yup';

import { MyValue } from './plateTypes';

export const validateContent = (val?: MyValue): void | yup.ValidationError => {
  if (!val) {
    throw new yup.ValidationError('content is a required field');
  }
  /**
   * isNode requires a plain object
   * values from the graphql playground will fail without the parse
   * values from the web seems to come through correctly
   * const parsedVal = JSON.parse(JSON.stringify(val));
   */
  if (!Node.isNodeList(val)) {
    throw new yup.ValidationError('Content is not a Node[]');
  }
};

export const validateContentLength = (maxLength: number, val?: Node[]) => {
  if (!val) {
    throw new yup.ValidationError('content is a required field');
  }
  const valLength = val.map((n: Node) => Node.string(n)).join('\n').length;
  if (valLength > maxLength) {
    throw new yup.ValidationError(
      `Content is longer than ${maxLength} characters`
    );
  }
};

export const isContentNotEmpty = (content: MyValue) => {
  return content.map((n) => Node.string(n)).join().length > 0;
};

export const validateContentIsNotEmpty = (content?: MyValue | null) => {
  return !!content && isContentNotEmpty(content);
};
