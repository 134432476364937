import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';

import { GroupTypes } from '../../../../../../generated/types-and-hooks';
import { ComposerField } from '../../../../common/components/Composer/ComposerField';

export const GroupFormDescriptionField = ({ type }: { type: GroupTypes }) => {
  const form = useFormContext();
  const { t } = useTranslation('common');
  return (
    <ComposerField
      label={t('groups.description_field_label')}
      onChange={(val) => {
        form.setValue('description', val, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }}
      initialValue={form.formState.defaultValues?.description}
      placeholder={
        type === GroupTypes.Course
          ? t('courses.description_field_placeholder')
          : t('groups.description_field_placeholder')
      }
      id={'event-form'}
    />
  );
};
