import * as React from "react";

function SvgHorse(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.125 31.25h-6.25a.625.625 0 01-.581-.394L12.5 21.344v-.094a17.8 17.8 0 01-.625-4.612v-.888c0-3.65 2.544-6.712 5.794-6.981a.625.625 0 01.625.425l.043.144L20 14.275l1.65-4.95.038-.125a.625.625 0 01.625-.425c3.25.263 5.793 3.331 5.793 6.981v.913a17.802 17.802 0 01-.606 4.581v.069l-3.794 9.537a.625.625 0 01-.581.394z"
        fill="#212121"
      />
      <path
        d="M17.3 30h5.4l3.619-9.081c.37-1.389.556-2.82.556-4.256v-.913c0-2.813-1.794-5.206-4.163-5.669l-2.118 6.369a.625.625 0 01-1.188 0l-2.118-6.369c-2.37.469-4.163 2.857-4.163 5.669v.913c0 1.436.187 2.867.556 4.256L17.3 30z"
        fill="#E0E0E0"
      />
      <path
        d="M20.413 36.875h-.826A4.594 4.594 0 0115 32.288 2.287 2.287 0 0117.288 30h5.43A2.288 2.288 0 0125 32.288a4.594 4.594 0 01-4.587 4.587z"
        fill="#212121"
      />
      <path
        d="M17.288 31.25a1.037 1.037 0 00-1.038 1.038 3.343 3.343 0 003.337 3.337h.82a3.343 3.343 0 003.343-3.337 1.037 1.037 0 00-1.038-1.038h-5.424z"
        fill="#8D6E63"
      />
      <path
        d="M13.125 13.125a.625.625 0 01-.506-.262L10.313 9.63A3.125 3.125 0 0110 6.588l1.319-3.082a.625.625 0 011.056-.156l2.194 2.631a3.124 3.124 0 01.525 3.125l-1.362 3.638a.625.625 0 01-.507.4l-.1-.019z"
        fill="#212121"
      />
      <path
        d="M12.05 4.931l-.918 2.144a1.875 1.875 0 00.2 1.831l1.625 2.275.95-2.537a1.874 1.874 0 00-.313-1.875L12.051 4.93z"
        fill="#9E9E9E"
      />
      <path
        d="M26.875 13.125h-.081a.625.625 0 01-.507-.4l-1.362-3.637a3.125 3.125 0 01.525-3.125l2.194-2.632a.624.624 0 011.056.157l1.3 3.1a3.124 3.124 0 01-.331 3.05l-2.307 3.23a.625.625 0 01-.487.257z"
        fill="#212121"
      />
      <path
        d="M27.95 4.931l-1.543 1.875a1.875 1.875 0 00-.313 1.875l.95 2.538 1.625-2.275a1.875 1.875 0 00.2-1.831l-.918-2.182z"
        fill="#9E9E9E"
      />
      <path
        d="M20 16.875a.625.625 0 01-.593-.425l-2.282-6.875a2.632 2.632 0 012.538-3.325h.675a2.63 2.63 0 012.55 3.3v.131l-2.25 6.763a.625.625 0 01-.638.431z"
        fill="#212121"
      />
      <path
        d="M19.663 7.5a1.381 1.381 0 00-1.338 1.738l.031.106L20 14.275l1.65-4.95v-.1A1.382 1.382 0 0020.338 7.5h-.675z"
        fill="#A1887F"
      />
      <path
        d="M18.75 34.375a.625.625 0 01-.625-.625v-.625a.625.625 0 111.25 0v.625a.625.625 0 01-.625.625zm2.5 0a.625.625 0 01-.625-.625v-.625a.625.625 0 111.25 0v.625a.625.625 0 01-.625.625zm-5-13.75a.623.623 0 01-.444-.181l-1.25-1.25a.625.625 0 01.882-.881l1.25 1.25a.626.626 0 01-.438 1.062zm7.5 0a.624.624 0 01-.444-1.069l1.25-1.25a.625.625 0 01.882.881l-1.25 1.25a.625.625 0 01-.438.188z"
        fill="#212121"
      />
    </svg>
  );
}

export default SvgHorse;
