import styled, { x } from '@xstyled/styled-components';
import React from 'react';

import {
  Giphy,
  Image as ImageType,
  Video,
} from '../../../../../generated/types-and-hooks';
import { COMMENT_MEDIA_HEIGHT_PX } from '../../../../config/constants';
import { VideoPlayer } from '../../../video/components/VideoPlayer';
import { Image } from '../Image';
import { ProcessingImage } from './CommentComposer';
import { CommentComposerDeleteButton } from './CommentComposerDeleteButton';
import { CommentComposerMediaLoader } from './CommentComposerMediaLoader';

const StyledImage = styled(Image)`
  width: ${COMMENT_MEDIA_HEIGHT_PX}px;
  height: ${COMMENT_MEDIA_HEIGHT_PX}px;
  object-fit: cover;
  border-radius: md;
  background-color: gray.50;
`;

export const CommentComposerMedia = ({
  media,
  onDelete,
  onProcessed,
}: {
  media: ImageType | Video | Giphy | ProcessingImage;
  onDelete: () => void;
  onProcessed: (image: ImageType) => void;
}) => {
  return (
    <x.div
      pointerEvents="auto"
      cursor="default"
      position="relative"
      key={media.id}
      borderRadius="md"
    >
      {'publicId' in media ? (
        <StyledImage src={media.publicId} key={media.id} />
      ) : 'giphyId' in media ? (
        <x.div
          w={COMMENT_MEDIA_HEIGHT_PX}
          h={COMMENT_MEDIA_HEIGHT_PX}
          bg="gray.50"
          borderRadius="md"
        >
          <VideoPlayer
            poster={media.giphyUrl}
            preload="auto"
            src={media.giphyMp4Url}
            playsInline
            loop
            autoPlay
            w="100%"
            h="100%"
            overflow="hidden"
            borderRadius="md"
            transform
            bindEvents={false}
          />
        </x.div>
      ) : (
        'file' in media && (
          <CommentComposerMediaLoader
            onProcessed={onProcessed}
            file={media.file}
          />
        )
      )}
      <CommentComposerDeleteButton onClick={onDelete} />
    </x.div>
  );
};
