import styled, { css, keyframes } from '@xstyled/styled-components';
import { th } from '@xstyled/system';

export interface LoadingSpinnerProps {
  /**
   * spinner size
   */
  size: 'inherit' | '4' | '8';

  /**
   * spinner color
   */
  variant?: 'dark' | 'light';
  /**
   * boolean to animate spinner
   * used in stories so chromatic doesn't show changes
   */
  animate?: boolean;
}

const loading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const lightStyle = css`
  border: 0.125em solid ${th.color('white')};
  border-left-color: ${th.color('brand.100')};
`;

const darkStyle = css`
  border: 0.125em solid ${th.color('gray.300')};
  border-left-color: ${th.color('gray.200')};
`;

export const LoadingSpinner = styled.div<LoadingSpinnerProps>`
  box-sizing: border-box;
  animation: ${(p) => css`
    ${loading} ${p.animate ? 1.2 : 0}s infinite linear;
  `};
  ${(p) => (p.variant === 'light' ? lightStyle : darkStyle)}
  border-radius: 50%;
  font-size: ${(p) => th.size(p.size)};
  height: 1em;
  width: 1em;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);

  &:after {
    border-radius: 50%;
    height: 1em;
    width: 1em;
  }
`;

LoadingSpinner.displayName = 'LoadingSpinner';

LoadingSpinner.defaultProps = {
  size: 'inherit',
  variant: 'dark',
  animate: true,
};
