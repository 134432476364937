import { yupResolver } from '@hookform/resolvers/yup';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  GroupWithMembersFragment,
  refetchGroupQuery,
  refetchGroupsQuery,
  useAddGroupMembersMutation,
} from '../../../../../generated/types-and-hooks';
import { GROUPS_PER_PAGE, IS_DEMO } from '../../../../config/constants';
import { useOrganization } from '../../../auth/hooks/useOrganization';
import { AudienceSelection } from '../../../common/components/AudienceSelector';
import { Button } from '../../../common/components/Button';
import { Form } from '../../../common/components/Form';
import { ShareAudience } from '../../../common/components/ShareAudience';
import {
  TabbedModal,
  TabbedModalContentInset,
} from '../../../common/components/TabbedModal';
import { Text } from '../../../common/components/Text';
import { RestrictedDemoActionModal } from '../../../demo/RestrictedDemoActionModal';
import { FormLayout } from '../GroupEditModal/GroupEditAbout/GroupEditAboutTab';

const YupMembersValidation = yup
  .array()
  .of(yup.string().uuid().required())
  .required()
  .min(1);

const FormSchema = yup
  .object({
    groupId: yup.string().required(),
    userIds: YupMembersValidation,
  })
  .defined();

export type GroupShareFormData = yup.InferType<typeof FormSchema>;

export const GroupShareModal: React.FC<{
  isOpen: boolean;
  onDismiss: () => void;
  group: GroupWithMembersFragment;
}> = ({ isOpen, onDismiss, group }) => {
  const [showModal, setShowModal] = useState(false);
  const { organization } = useOrganization();
  const { t } = useTranslation(['common', 'groups']);

  const [mutateAddGroupMembers, { loading }] = useAddGroupMembersMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      refetchGroupQuery({ id: group.id }),
      refetchGroupsQuery({
        organizationId: organization.id,
        first: GROUPS_PER_PAGE - 1,
      }),
    ],
  });

  const form = useForm<GroupShareFormData>({
    resolver: yupResolver(FormSchema),
    mode: 'all',
    defaultValues: {
      groupId: group.id,
    },
  });

  const handleDismiss = () => {
    setShowModal(false);
    form.reset();
    onDismiss();
  };

  const handleSubmit = (data: GroupShareFormData) => {
    mutateAddGroupMembers({
      awaitRefetchQueries: true,
      variables: {
        input: data,
      },
      onCompleted: handleDismiss,
    });
  };

  const handleChangeAudience = (data: AudienceSelection[]) => {
    form.setValue(
      'userIds',
      data.map((item) => {
        if (item.type === 'user') {
          return item.payload.id;
        } else {
          throw new Error('Invalid audience selection');
        }
      }),
      {
        shouldValidate: true,
      }
    );
  };

  if (IS_DEMO) {
    return (
      <RestrictedDemoActionModal isOpen={isOpen} onDismiss={handleDismiss} />
    );
  }

  return (
    <TabbedModal
      isOpen={isOpen || showModal}
      onDismiss={handleDismiss}
      title={t('add_members')}
      ariaLabel={t('add_members')}
      tabs={{
        members: (
          <Form<GroupShareFormData> {...form} onSubmit={handleSubmit}>
            <TabbedModalContentInset>
              <FormLayout>
                {group.unlockedBy?.active && (
                  <x.div
                    display="flex"
                    flexDirection="column"
                    gap="2"
                    backgroundColor="gray.50"
                    p="4"
                    borderRadius="md-lg"
                  >
                    <Text variant="md-semibold" color="brand.300">
                      {t('groups.paywalled', { ns: 'groups' })}
                    </Text>
                    <Text variant="sm">
                      {t('groups.paywalled_add_members_behavior', {
                        ns: 'groups',
                      })}
                    </Text>
                  </x.div>
                )}
                <ShareAudience
                  group={group}
                  organization={organization}
                  onChangeAudience={handleChangeAudience}
                  placeholder={'E.g. Julie'}
                  prompt={
                    <x.div spaceY={2}>
                      <Text>
                        {t('group.add_members_description', {
                          ns: 'groups',
                        })}
                      </Text>
                      {group.isPrivate && (
                        <Text variant="md-semibold">
                          {t('groups.this_is_a_private_group', {
                            ns: 'groups',
                          })}
                        </Text>
                      )}
                    </x.div>
                  }
                />
                <div>
                  <Button
                    disabled={!form.formState.isValid}
                    label={t('add_members')}
                    loading={loading}
                    type="submit"
                  />
                </div>
              </FormLayout>
            </TabbedModalContentInset>
          </Form>
        ),
      }}
    />
  );
};
