import { x } from '@xstyled/styled-components';
import { PropsWithChildren } from 'react';

import { FullscreenPageLayout } from '../components/FullscreenPageLayout';

export const ModalLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <FullscreenPageLayout>
      <x.div
        display="flex"
        h="100vh"
        boxSizing="border-box"
        alignItems="center"
        p={4}
      >
        <x.div
          maxWidth="lg"
          mx="auto"
          textAlign="center"
          display="flex"
          flexDirection="column"
          boxSizing="border-box"
          w="full"
          p={{
            sm: 6,
          }}
          pt={{
            sm: 11,
          }}
          borderRadius={{
            sm: 'lg',
          }}
          boxShadow={{
            sm: '2px 2px 30px rgba(0, 0, 0, 0.18);',
          }}
        >
          {children}
        </x.div>
      </x.div>
    </FullscreenPageLayout>
  );
};
