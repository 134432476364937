import { routes } from '@frond/shared';
import { format } from 'date-fns';
import { Trans, useTranslation } from 'next-i18next';

import {
  NotificationQuery,
  useEventQuery,
} from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Button } from '../../common/components/Button';
import { Text } from '../../common/components/Text';
import { InboxNotificationCommon } from './InboxNotificationCommon';

export const InboxNotificationEventCancelled: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation('inbox');
  const { group } = notification;
  const { organization } = useOrganization();

  if (!group) return <></>;

  return (
    <InboxNotificationCommon
      icon="event-cancelled"
      title={t('inbox.event_cancelled')}
      description={
        <Trans
          i18nKey="inbox.event_cancelled_description"
          t={t}
          values={{
            name: group.name,
          }}
          components={{
            strong: <Text as="span" variant="md-semibold" />,
          }}
        />
      }
      action={
        <Button
          variant="secondary"
          label={t('inbox.view_event')}
          href={routes.groups
            .organization(organization.shortId)
            .event(group.id)}
        />
      }
    />
  );
};

export const InboxNotificationEventDateChanged: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation('inbox');
  const { group } = notification;
  const { organization } = useOrganization();

  const { data } = useEventQuery({
    variables: {
      id: group?.id || '',
    },
  });

  if (!group || !data || data.event.__typename === 'EventError') return <></>;

  return (
    <InboxNotificationCommon
      icon="event-time"
      title={t('inbox.event_date_changed')}
      description={
        <Trans
          i18nKey="inbox.event_date_changed_description"
          t={t}
          values={{
            name: group.name,
            startAtDate: format(new Date(data.event.startAt), 'eeee, LLL dd'),
            startAtTime: format(new Date(data.event.startAt), 'p'),
            endAtTime: format(new Date(data.event.endAt), 'p'),
          }}
          components={{
            strong: <Text as="span" variant="md-semibold" />,
          }}
        />
      }
      action={
        <Button
          variant="secondary"
          label={t('inbox.view_event')}
          href={routes.groups
            .organization(organization.shortId)
            .event(group.id)}
        />
      }
    />
  );
};

export const InboxNotificationEventTimeChanged: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation('inbox');
  const { group } = notification;
  const { organization } = useOrganization();

  const { data } = useEventQuery({
    variables: {
      id: group?.id || '',
    },
  });

  if (!group || !data || data.event.__typename === 'EventError') return <></>;

  return (
    <InboxNotificationCommon
      icon="event-time"
      title={t('inbox.event_time_changed')}
      description={
        <Trans
          i18nKey="inbox.event_time_changed_description"
          t={t}
          values={{
            name: group.name,
            startAtDate: format(new Date(data.event.startAt), 'eeee, LLL dd'),
            startAtTime: format(new Date(data.event.startAt), 'p'),
            endAtTime: format(new Date(data.event.endAt), 'p'),
          }}
          components={{
            strong: <Text as="span" variant="md-semibold" />,
          }}
        />
      }
      action={
        <Button
          variant="secondary"
          label={t('inbox.view_event')}
          href={routes.groups
            .organization(organization.shortId)
            .event(group.id)}
        />
      }
    />
  );
};

export const InboxNotificationEventLocationChanged: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation('inbox');
  const { group } = notification;
  const { organization } = useOrganization();

  const { data } = useEventQuery({
    variables: {
      id: group?.id || '',
    },
  });

  if (!group || !data || data.event.__typename === 'EventError') return <></>;

  return (
    <InboxNotificationCommon
      icon="event-time"
      title={t('inbox.event_location_changed')}
      description={
        <Trans
          i18nKey="inbox.event_location_changed_description"
          t={t}
          values={{
            name: group.name,
            locationDisplayName: data.event.location?.displayName,
          }}
          components={{
            strong: <Text as="span" variant="md-semibold" />,
          }}
        />
      }
      action={
        <Button
          variant="secondary"
          label={t('inbox.view_event')}
          href={routes.groups
            .organization(organization.shortId)
            .event(group.id)}
        />
      }
    />
  );
};

export const InboxNotificationEventReminder: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation('inbox');
  const { group } = notification;
  const { organization } = useOrganization();

  const { data } = useEventQuery({
    variables: {
      id: group?.id || '',
    },
  });

  if (!group || !data || data.event.__typename === 'EventError') return <></>;

  return (
    <InboxNotificationCommon
      icon="event-time"
      title={t('inbox.event_reminder')}
      description={
        <Trans
          i18nKey="inbox.event_reminder_description"
          t={t}
          values={{
            name: group.name,
            startAtTime: format(new Date(data.event.startAt), 'p'),
          }}
          components={{
            strong: <Text as="span" variant="md-semibold" />,
          }}
        />
      }
      action={
        <Button
          variant="secondary"
          label={t('inbox.view_event')}
          href={routes.groups
            .organization(organization.shortId)
            .event(group.id)}
        />
      }
    />
  );
};
