import { theme } from '@frond/shared';
import * as Dialog from '@radix-ui/react-dialog';
import { css, styled, x } from '@xstyled/styled-components';
import debounce from 'lodash/debounce';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { PostCoreFragment } from '../../../../generated/graphql-request-api-sdk';
import { usePrevious } from '../../common/hooks/usePreviousBoolean';
import { useVideoEvents } from '../../video/hooks/useVideoEvents';
import { PostCardGiphy } from './PostCardGiphy';
import { PostCardImage } from './PostCardImage';
import { PostCardMediaCarouselControls } from './PostCardMediaCarouselControls';
import { PostLightBoxVideoPlayer } from './PostLightboxVideoPlayer';

const PostLightboxBackdrop = styled(Dialog.Overlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 0 !important;
  background-color: black;
  opacity: 0.75;
  z-index: ${theme.zIndices.modal};
`;

const PostLightboxContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${theme.zIndices.modal};
`;

export const PostLightboxTrack = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  transform: translateZ(0);

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PostLightbox: FC<{
  isOpen: boolean;
  onDismiss: (currentIndex: number) => void;
  initialIndex: number;
  media: PostCoreFragment['media'];
  aspectRatio?: number | null;
}> = ({ isOpen, onDismiss, initialIndex, media, aspectRatio }) => {
  const { pauseAll } = useVideoEvents();
  const [index, setIndex] = useState(initialIndex);

  const trackRef = useRef<HTMLDivElement | null>(null);
  const justOpened = !usePrevious<boolean>(isOpen) && isOpen;

  // Reset on close
  useEffect(() => {
    !isOpen && setIndex(0);
  }, [isOpen]);

  useHotkeys(
    'left',
    () => {
      index > 0 && scrollToIndex(index - 1, true);
    },
    { preventDefault: true }
  );

  useHotkeys(
    'right',
    () => {
      index < media.length && scrollToIndex(index + 1, true);
    },
    { preventDefault: true }
  );

  const handleScroll = useCallback(() => {
    if (!trackRef.current) return;

    const newIndex = Math.round(
      trackRef.current.scrollLeft / trackRef.current.clientWidth
    );

    if (index !== newIndex) {
      pauseAll();
    }

    setIndex(newIndex);
  }, [index, pauseAll]);

  const debouncedHandleScroll = useMemo(
    () => debounce(handleScroll, 30),
    [handleScroll]
  );

  const scrollToIndex = (i: number, smooth: boolean) => {
    if (!trackRef.current) {
      return;
    }

    pauseAll();
    trackRef.current.scrollTo({
      left: window.innerWidth * i,
      behavior: smooth ? 'smooth' : 'auto',
    });
  };

  const initialMedia = media[initialIndex];
  const aspectRatioFromMedia =
    initialMedia?.__typename === 'Image' || initialMedia?.__typename === 'Giphy'
      ? initialMedia?.aspectRatio
      : initialMedia?.__typename === 'Video'
        ? initialMedia?.aspect
        : undefined;

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <PostLightboxBackdrop />
        <Dialog.Content onEscapeKeyDown={() => onDismiss(index)}>
          <PostLightboxContainer>
            <PostLightboxTrack
              ref={(node) => {
                trackRef.current = node;
                if (node && justOpened) {
                  node.scrollTo({ left: window.innerWidth * initialIndex });
                }
              }}
              onScroll={debouncedHandleScroll}
            >
              {media.map((item, i) => {
                // prevent upscaling images
                const maxH =
                  item.__typename === 'Image' && item.height
                    ? `min(${item.height}px, 100%)`
                    : '100%';

                const maxW =
                  item.__typename === 'Image' && item.width
                    ? `min(${item.width}px, 100%)`
                    : '100%';

                return (
                  <x.div
                    key={item.id}
                    flex={1}
                    minWidth="100%"
                    px={24}
                    py={8}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    lineHeight={0}
                    boxSizing="border-box"
                    style={{
                      scrollSnapAlign: 'start',
                    }}
                    onClick={() => onDismiss(index)}
                  >
                    <x.div flex={1} display="flex" h="full" maxW="full">
                      <PostLightboxItemContainer
                        aspectRatio={
                          aspectRatio ? aspectRatio : aspectRatioFromMedia
                        }
                        maxH={maxH}
                        maxW={maxW}
                        fullHeight={item.__typename === 'Video'}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        {item.__typename === 'Image' && (
                          <PostCardImage
                            image={item}
                            maxH="100%"
                            objectFit="cover"
                          />
                        )}

                        {item.__typename === 'Video' && (
                          <PostLightBoxVideoPlayer
                            video={item}
                            autoPlay={justOpened && initialIndex === i}
                          />
                        )}

                        {item.__typename === 'Giphy' && (
                          <PostCardGiphy giphy={item} />
                        )}
                      </PostLightboxItemContainer>
                    </x.div>
                  </x.div>
                );
              })}
            </PostLightboxTrack>
            <PostCardMediaCarouselControls
              dots={media.length}
              carouselIndex={index}
              variant="fullscreen"
              onCloseClick={() => onDismiss(index)}
              onPrevClick={() => scrollToIndex(index - 1, true)}
              onNextClick={() => scrollToIndex(index + 1, true)}
            />
          </PostLightboxContainer>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const PostLightboxItemContainer = styled.div<{
  aspectRatio?: number | null;
  maxH: string;
  maxW: string;
  fullHeight: boolean;
}>`
  border-radius: ${theme.radii['md-lg']};
  overflow: hidden;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  box-shadow: ${theme.shadows.glossyModal};
  aspect-ratio: ${(p) => p.aspectRatio};
  max-height: ${(p) => p.maxH};
  max-width: ${(p) => p.maxW};
  ${(p) =>
    p.fullHeight
      ? css`
          height: 100%;
        `
      : null};
  transform: translate3d(0, 0, 0);
`; // transform to fix rounded corners on safari
