import { theme } from '@frond/shared';
import { styled, system, SystemProps, th, x } from '@xstyled/styled-components';
import { format } from 'date-fns';
import { useTranslation } from 'next-i18next';
import pluralize from 'pluralize';

import {
  SearchResultFragment,
  SearchResultTypes,
} from '../../../../../generated/types-and-hooks';
import { Avatar } from '../../../common/components/Avatar';
import { Icon } from '../../../common/components/Icon';
import { NextLink } from '../../../common/components/NextLink';
import { Text } from '../../../common/components/Text';
import { getUserDisplayName } from '../../../common/utils/user';

const StyledText = styled(Text)<SystemProps>`
  b {
    color: ${th('colors.gray.500')};
    ${th('typographyStyles.text.md-semibold')}
  }

  ${system};
`;

export const GroupSearchResultElement: React.FC<{
  heading: string;
  excerpt?: string;
  icon?: string;
  showTimestamp?: boolean;
  result: SearchResultFragment;
  selected?: boolean;
}> = ({ heading, excerpt, icon, showTimestamp = true, result, selected }) => {
  return (
    <NextLink href={result.link}>
      <x.div
        display="flex"
        flexDirection="row"
        spaceX={3}
        p={1}
        pr="25px"
        borderRadius="sm"
        backgroundColor={{
          _: selected ? 'gray.50' : 'transparent',
        }}
      >
        <x.div>
          {icon ? (
            <x.div
              borderRadius="sm"
              backgroundColor="gray.100"
              p={theme.sizes['1.5']}
            >
              <Icon name={icon} color="gray.500" />
            </x.div>
          ) : (
            <Avatar
              size="s-2"
              bgColor={result?.createdBy.avatarBgColor}
              avatar={result?.createdBy.avatar}
              imageId={result?.createdBy.profileImageId}
            />
          )}
        </x.div>
        <x.div w="100%" spaceY={2}>
          <x.div
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <StyledText
              variant="md-semibold"
              color="gray.300"
              dangerouslySetInnerHTML={{
                __html: heading,
              }}
            />
            {showTimestamp && (
              <Text variant="sm" color="gray.300">
                {format(new Date(result.createdAt), 'MMM d, y')}
              </Text>
            )}
          </x.div>
          <StyledText
            variant="md"
            color="gray.500"
            dangerouslySetInnerHTML={{
              __html: excerpt || result.excerpt,
            }}
          />
        </x.div>
      </x.div>
    </NextLink>
  );
};

export const GroupSearchResult: React.FC<{
  result: SearchResultFragment;
  selected?: boolean;
}> = ({ result, selected }) => {
  const { t } = useTranslation();
  switch (result.type) {
    case SearchResultTypes.Post: {
      return (
        <GroupSearchResultElement
          heading={t('post_by_user', {
            user: getUserDisplayName(result.createdBy),
          })}
          icon="news"
          result={result}
          selected={selected}
        />
      );
    }
    case SearchResultTypes.Comment: {
      return (
        <GroupSearchResultElement
          heading={t('comment_by_user', {
            user: getUserDisplayName(result.createdBy),
          })}
          icon="comment"
          result={result}
          selected={selected}
        />
      );
    }
    case SearchResultTypes.Resource: {
      return (
        <GroupSearchResultElement
          heading={t('shared_by_user', {
            user: getUserDisplayName(result.createdBy),
          })}
          icon="link"
          result={result}
          selected={selected}
        />
      );
    }
    case SearchResultTypes.Group: {
      return (
        <GroupSearchResultElement
          heading={t('group_result', { excerpt: result.excerpt })}
          icon="groups"
          result={result}
          selected={selected}
          excerpt={
            result.node?.__typename === 'Group'
              ? `${result.node.numMembers} ${pluralize(
                  'member',
                  result.node.numMembers
                )}, 
              ${result.node.numPosts} ${pluralize(
                'post',
                result.node.numPosts
              )}`
              : ''
          }
        />
      );
    }
    case SearchResultTypes.User: {
      return (
        <GroupSearchResultElement
          heading={result.excerpt}
          result={result}
          selected={selected}
          excerpt={`Member since ${format(
            new Date(result.createdAt),
            'MMM d, y'
          )}`}
          showTimestamp={false}
        />
      );
    }
    default: {
      return null;
    }
  }
};
