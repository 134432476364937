import { styled, x } from '@xstyled/styled-components';
import { system, SystemProps } from '@xstyled/system';
import { format } from 'date-fns';
import { PropsWithChildren } from 'react';

import { Text } from '../../../../common/components/Text';

const StyledWrapper = styled.div<SystemProps>`
  & {
    border-bottom: default;
    border-bottom-color: gray.100;
  }

  a {
    width: 100%;
  }

  &:nth-child(10) {
    border-bottom: none;
  }

  ${system};
`;

export const MemberItemLayout: React.FC<
  PropsWithChildren<{
    createdAt?: string;
    column?: JSX.Element | null;
    menu?: JSX.Element | null;
  }>
> = ({ createdAt, column, menu, children }) => {
  return (
    <StyledWrapper
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px="5"
      minH="68px"
      boxSizing="border-box"
    >
      <x.div
        w={{
          _: '55%',
          sm: '40%',
        }}
        display="flex"
        alignItems="center"
      >
        {children}
      </x.div>
      <x.div
        display={{
          _: 'none',
          sm: 'block',
        }}
        w={{
          sm: '15%',
        }}
      >
        {createdAt && (
          <Text variant="md" maxW={250}>
            {format(new Date(createdAt), 'MMM d, yyyy')}
          </Text>
        )}
      </x.div>
      <x.div
        w={{
          _: '45%',
          sm: '25%',
        }}
        display="flex"
        justifyContent={{
          _: 'flex-end',
          sm: 'flex-start',
        }}
      >
        {column}
      </x.div>
      <x.div
        display={{
          _: 'none',
          sm: 'flex',
        }}
        justifyContent="flex-end"
        w={{
          sm: '5%',
        }}
      >
        <x.div>{menu}</x.div>
      </x.div>
    </StyledWrapper>
  );
};

export const MemberItemDetails: React.FC<
  PropsWithChildren<{
    active?: boolean;
    name?: string;
    email: string;
  }>
> = ({ active = true, name, email, children }) => {
  return (
    <x.div display="flex" flexDirection="row" alignItems="center" spaceX={3}>
      {children}
      {name && email ? (
        <x.div minWidth={0}>
          <Text variant="md" color={!active ? 'gray.200' : 'gray.500'} truncate>
            {name}
          </Text>
          <Text truncate variant="sm" color={!active ? 'gray.200' : 'gray.300'}>
            {email}
          </Text>
        </x.div>
      ) : (
        <x.div py={2}>
          <Text variant="md" color={!active ? 'gray.200' : 'gray.300'} truncate>
            {email}
          </Text>
        </x.div>
      )}
    </x.div>
  );
};
