import * as React from "react";

function SvgArrowTopAngleRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.218 7.99L5.49 19.718l-1.415-1.415L15.803 6.575h-8.9v-2h12.315V16.89h-2v-8.9z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgArrowTopAngleRight;
