import { yupResolver } from '@hookform/resolvers/yup';
import styled, { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import isEmailValidator from 'validator/lib/isEmail';
import * as yup from 'yup';

import { Button } from '../../../common/components/Button';
import { Form } from '../../../common/components/Form';
import { Input } from '../../../common/components/Input';
import analytics from '../../../common/utils/analytics';
import { FieldValidationIcon } from '../../../hello/components/FieldValidationIcon';
import { useAuth } from '../../hooks/useAuth';

const StyledInput = styled(Input)`
  width: 100%;
  height: 60px;

  input {
    padding-right: 11;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover,
    &:focus {
      border-color: brand.200;
    }
  }
`;

const FormSchema = yup
  .object({
    email: yup
      .string()
      .required()
      .email()
      .max(255)
      .test((v) => isEmailValidator(v)),
  })
  .defined();

type FormData = yup.InferType<typeof FormSchema>;
type EmailSigninFormType = {
  onSubmit?: () => void;
  callbackUrlPath?: string;
  inviteCode?: string;
  shortId?: string;
};

export const SignInEmailSigninForm: React.FC<EmailSigninFormType> = ({
  onSubmit,
  callbackUrlPath,
  inviteCode,
  shortId,
}) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const routerInviteCode = Array.isArray(router.query.invite)
    ? router.query.invite[0]
    : router.query.invite;

  const form = useForm<FormData>({
    resolver: yupResolver(FormSchema),
    mode: 'all',
  });
  const { sendMagicLinkEmail } = useAuth();

  const onFormSubmit = (data: FormData) => {
    onSubmit?.();
    sendMagicLinkEmail(
      data.email,
      callbackUrlPath,
      inviteCode || routerInviteCode,
      shortId
    );
    analytics.logEvent(analytics.events.SIGN_IN);
  };

  return (
    <Form<FormData> {...form} onSubmit={onFormSubmit} w="100%">
      <x.div w="full">
        <x.div w="full" position="relative">
          <StyledInput
            {...form.register('email')}
            type="email"
            placeholder="Email"
            fullWidth
            autoComplete="email"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            autoFocus
          />
          <FieldValidationIcon name="email" />
        </x.div>
      </x.div>
      <Button mt={3} p={4} type="submit" label={t('auth.send_link')} w="full" />
    </Form>
  );
};
