import { MyValue } from '@frond/shared';
import React from 'react';

import {
  CommentCoreFragment,
  PostFeedFragment,
  ReactionTypes,
  refetchHomeFeedQuery,
  refetchPostsByEventQuery,
  refetchPostsByGroupQuery,
  useDeleteCommentMutation,
  useToggleCommentAsBestAnswerMutation,
  useUpdateCommentMutation,
} from '../../../../../generated/types-and-hooks';
import { POSTS_PER_PAGE } from '../../../../config/constants';
import { useOrganization } from '../../../auth/hooks/useOrganization';
import { useViewer } from '../../../auth/hooks/useViewer';
import { useReactions } from '../../hooks/useReactions';
import { CommentLayout } from './CommentLayout';

export interface CommentCellProps {
  /**
   * Main comment object
   */
  comment: CommentCoreFragment;
  /**
   * Whether to show when comments were posted
   */
  displayTimestamp?: boolean;
  /**
   * Override default user attribution (first name)
   */
  displayName?: string;
  editable?: boolean;
  post: PostFeedFragment;
}

/**
 * Primary component for an update
 */
export const CommentCell: React.FC<CommentCellProps> = ({
  comment,
  displayTimestamp = true,
  displayName,
  editable,
  post,
}) => {
  const { viewer: user } = useViewer();
  const { onReactionClick } = useReactions();
  const { organization } = useOrganization();

  const [deleteComment] = useDeleteCommentMutation({
    variables: { id: comment.id },
    update(cache) {
      const normalizedId = cache.identify({
        id: comment.id,
        __typename: 'Comment',
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const [mutateComment, { loading }] = useUpdateCommentMutation();

  const [toggleCommentAsBestAnswer] = useToggleCommentAsBestAnswerMutation({
    refetchQueries: [
      refetchHomeFeedQuery({
        organizationId: organization.id,
        first: POSTS_PER_PAGE,
      }),
      refetchPostsByGroupQuery({
        first: POSTS_PER_PAGE,
        groupId: post.group.id,
      }),
      refetchPostsByEventQuery({
        first: POSTS_PER_PAGE,
        eventId: post.group.id,
      }),
    ],
  });

  const handleSubmitEdits = ({
    content,
    mediaIds,
    urls,
  }: {
    content: MyValue;
    mediaIds: string[];
    urls: string[];
  }) => {
    mutateComment({
      variables: {
        input: {
          id: comment.id,
          content,
          mediaIds,
          resources: urls.map((url) => {
            return {
              url,
              expanded: true,
            };
          }),
        },
      },
    });
  };

  const handleReactionClick = (emojiUnicode: string) => {
    if (user) {
      onReactionClick(comment.id, ReactionTypes.Comment, emojiUnicode, user);
    }
  };

  const handleClickDelete = () => deleteComment();

  const handelClickBestAnswer = () => {
    toggleCommentAsBestAnswer({
      variables: {
        input: {
          id: comment.id,
        },
      },
    });
  };

  return (
    <CommentLayout
      user={comment.createdBy}
      createdAt={new Date(comment.createdAt)}
      reactions={comment.reactions}
      content={comment.content as MyValue}
      media={comment.media}
      resources={comment.resources}
      onReactionClick={handleReactionClick}
      onClickDelete={handleClickDelete}
      onClickBestAnswer={handelClickBestAnswer}
      onSubmit={handleSubmitEdits}
      isSubmitting={loading}
      displayTimestamp={displayTimestamp}
      displayName={displayName}
      editable={editable}
      highlight={comment.isBestAnswer}
    />
  );
};
