import * as React from "react";
import { SVGProps } from "react";
const SvgStartFromScratch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.167 2.5H3.833A.833.833 0 0 0 3 3.333v13.334c0 .46.373.833.833.833h13.334c.46 0 .833-.373.833-.833V3.333a.833.833 0 0 0-.833-.833Z"
      fill="url(#start-from-scratch_svg__a)"
    />
    <path
      opacity={0.05}
      d="M14.667 8.75H11.75V5.833A.833.833 0 0 0 10.917 5h-.834a.833.833 0 0 0-.833.833V8.75H6.333a.833.833 0 0 0-.833.833v.834c0 .46.373.833.833.833H9.25v2.917c0 .46.373.833.833.833h.834c.46 0 .833-.373.833-.833V11.25h2.917c.46 0 .833-.373.833-.833v-.834a.833.833 0 0 0-.833-.833Z"
      fill="currentColor"
    />
    <path
      opacity={0.07}
      d="M14.667 8.958h-3.125V5.833a.625.625 0 0 0-.625-.625h-.833a.625.625 0 0 0-.625.625v3.125H6.334a.625.625 0 0 0-.625.625v.833c0 .345.28.625.625.625h3.125v3.125c0 .345.28.625.625.625h.833c.345 0 .625-.28.625-.625v-3.125h3.125c.345 0 .625-.28.625-.625v-.833a.625.625 0 0 0-.625-.625Z"
      fill="currentColor"
    />
    <path
      d="M10.083 5.417h.833c.23 0 .417.187.417.417v8.333c0 .23-.187.417-.417.417h-.833a.417.417 0 0 1-.417-.417V5.834c0-.23.187-.417.417-.417Z"
      fill="#fff"
    />
    <path
      d="M15.083 9.584v.833c0 .23-.187.417-.417.417H6.333a.417.417 0 0 1-.417-.417v-.833c0-.23.187-.417.417-.417h8.333c.23 0 .417.187.417.417Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="start-from-scratch_svg__a"
        x1={8.078}
        y1={2.5}
        x2={12.769}
        y2={17.027}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BD4FF4" />
        <stop offset={0.587} stopColor="#A235EC" />
        <stop offset={1} stopColor="#8C20E5" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgStartFromScratch;
