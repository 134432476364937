import { Tooltip } from '@reach/tooltip';
import styled, { x } from '@xstyled/styled-components';
import { th } from '@xstyled/system';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import {
  SendFeedbackEmailInput,
  useSendFeedbackEmailMutation,
} from '../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { Button } from '../components/Button';
import { Emoji } from '../components/Emoji';
import { Heading } from '../components/Heading';
import { Icon } from '../components/Icon';
import { Input } from '../components/Input';
import { Modal } from '../components/Modal';
import { Text } from '../components/Text';
import analytics from '../utils/analytics';

enum FeedbackFormTypes {
  FEEDBACK,
  HELP,
}

export interface FeedbackWidgetProps {
  isOpen: boolean;

  variant: 'full' | 'hello';

  onDismiss: () => void;
}

type IconNames = 'sad-face' | 'neutral-face' | 'happy-face';

const StyledTooltip = styled(Tooltip)`
  background-color: gray.500;
  color: white;
  padding: 2 4;
  ${th('typographyStyles.text.sm-semibold')}
  box-shadow: base;
  border: none;
  border-radius: md;
  z-index: tooltip;
`;

/**
 * Using a div here because Tooltip has some broken
 * behavior when wrapping a button (autofocus)
 */
const IconWrapper = styled.div`
  font-size: 2xl;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
`;

const FeedbackIcon: React.FC<{
  name: IconNames;
  tooltipText: string;
  active?: boolean;
  onClick: (name: IconNames) => void;
}> = ({ name, tooltipText, active, onClick }) => (
  <StyledTooltip label={tooltipText} aria-label={tooltipText}>
    <IconWrapper onClick={() => onClick(name)}>
      {/* #DADADA isn't a theme color - added directly */}
      <Icon name={name} color={active ? 'brand.300' : '#DADADA'} />
    </IconWrapper>
  </StyledTooltip>
);

const IconContainer = styled.div`
  width: 32;
  display: flex;
  justify-content: space-between;
  margin: 6 6 10 6;
`;

const FeedbackIconList: React.FC<{
  currentIcon?: IconNames;
  onChange: (selectedIcon: IconNames) => void;
}> = ({ currentIcon, onChange }) => (
  <IconContainer>
    <FeedbackIcon
      name="sad-face"
      tooltipText="Oh, so sorry to hear this"
      active={currentIcon === 'sad-face'}
      onClick={onChange}
    />
    <FeedbackIcon
      name="neutral-face"
      tooltipText="Let us know if you have any requests"
      active={currentIcon === 'neutral-face'}
      onClick={onChange}
    />
    <FeedbackIcon
      name="happy-face"
      tooltipText="Aww, we like you too"
      active={currentIcon === 'happy-face'}
      onClick={onChange}
    />
  </IconContainer>
);

const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledModal = styled(Modal)`
  background-color: blackAlpha.400;

  [data-reach-dialog-content] {
    max-width: lg;
    box-sizing: border-box;
    padding: 6;
  }
`;

/**
 * Primary component for combobox input
 */
export const FeedbackWidget: React.FC<FeedbackWidgetProps> = ({
  isOpen,
  onDismiss,
}) => {
  const { t } = useTranslation();
  const { organization } = useOptionalOrganization();
  const [feedbackType, setFeedbackType] = useState(FeedbackFormTypes.FEEDBACK);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [feedbackContent, setFeedbackContent] = useState('');
  const [currentIcon, setCurrentIcon] = useState<IconNames>();

  // Feedback button
  const [sendFeedbackEmailMutation] = useSendFeedbackEmailMutation();

  const sendFeedback = (feedbackData: SendFeedbackEmailInput) => {
    sendFeedbackEmailMutation({
      variables: {
        input: feedbackData,
      },
    });
  };

  const submitFeedback = () => {
    // Not submitting without icon selected (button disabled)
    if (!currentIcon && feedbackType === FeedbackFormTypes.FEEDBACK)
      throw new Error('Feedback must be submitted with an emoji');

    sendFeedback({
      feeling: currentIcon ? currentIcon.replace('-face', '') : null,
      feedback: feedbackContent || undefined,
      url: window.location.href,
      organizationId: organization?.id || '',
    });
    setFeedbackSent(true);
    setFeedbackContent('');
    setCurrentIcon(undefined);
  };

  return (
    <StyledModal
      ariaLabel="Feedback"
      isOpen={isOpen}
      responsive
      onDismiss={() => {
        setFeedbackType(FeedbackFormTypes.FEEDBACK);
        setFeedbackSent(false);
        onDismiss();
      }}
      closeButtonVariant="v2"
    >
      {feedbackSent ? (
        <x.div
          h="388px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <x.div spaceY={4} textAlign="center">
            <Emoji size={40} emojiUnicode={'📨'} />
            <Heading>{t('thank_you')}</Heading>
            <Text>{t('feedback_response_promise')}</Text>
          </x.div>
        </x.div>
      ) : (
        <x.div display="flex" flexDirection="column" alignItems="center">
          <Heading mb={4} variant={{ _: 'xl', md: 'xl' }}>
            {feedbackType === FeedbackFormTypes.FEEDBACK
              ? t('share_feedback')
              : t('get_help')}
          </Heading>
          <Text textAlign="center" mb={10}>
            {feedbackType === FeedbackFormTypes.FEEDBACK
              ? t('feedback_prompt_thoughts')
              : t('feedback_prompt_help')}
          </Text>
          {feedbackType === FeedbackFormTypes.FEEDBACK && (
            <>
              <Text variant="sm-semibold">{t('feedback_prompt_frond')}</Text>
              <FeedbackIconList
                currentIcon={currentIcon}
                onChange={(selectedIcon) => setCurrentIcon(selectedIcon)}
              />
            </>
          )}
          <StyledInput
            label={t('message', { ns: 'common' })}
            isMultiLine
            onChange={(e) => setFeedbackContent(e.target.value)}
          />
          <Button
            mt={6}
            w="full"
            py="13px"
            type="button"
            label={t('send_message')}
            metadata={{ event: analytics.events.SUBMIT_FEEDBACK }}
            disabled={
              feedbackType === FeedbackFormTypes.FEEDBACK
                ? !currentIcon
                : !feedbackContent
            }
            onClick={submitFeedback}
          />
        </x.div>
      )}
    </StyledModal>
  );
};
