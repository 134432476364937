import { getEmojiDataFromNative } from 'emoji-mart';
import data from 'emoji-mart/data/all.json';

import { Reaction } from './types';
import { getUserDisplayName } from './user';

export const reactionsCreatedByUsernames = (reactions: Reaction[]) => {
  const colons: string[] = [];
  const names = reactions.reduce(
    (accumulator: string[], reaction: Reaction) => {
      const emojiData = getEmojiDataFromNative(
        reaction.emojiUnicode,
        'apple',
        // Emoji Mart doesn't provide correct typing
        data as any
      );
      colons.push(emojiData.colons);

      const names: string[] = [];
      reaction.createdByUsers?.map((user) => {
        const name = getUserDisplayName(user, 'short');
        if (!accumulator.includes(name)) {
          names.push(name);
        }
      });

      return [...accumulator, ...names];
    },
    []
  );

  return [names, colons];
};

export const reactionCreatedByUsernamesWithEmojiColons = (
  reactions: Reaction[],
  reactedWith: string
) => {
  const [names, colons] = reactionsCreatedByUsernames(reactions);

  return `${names?.join(', ')} ${reactedWith} ${colons.join(', ')}`;
};
