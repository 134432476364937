import { getShortId } from '@frond/shared';
import { useRouter } from 'next/router';

import { BASE_URL } from '../../../config/constants';
import { getSignInRedirectUrl } from '../../common/utils/routing.utils';
import { useOptionalOrganization } from './useOptionalOrganization';
import { useViewer } from './useViewer';

export const useAuthCallbackParams = () => {
  const router = useRouter();
  const { viewer } = useViewer();
  const { organization } = useOptionalOrganization();
  let inviteCode = Array.isArray(router.query.invite)
    ? router.query.invite[0]
    : router.query.invite;

  const callbackUrlPath = Array.isArray(router.query.callbackUrlPath)
    ? router.query.callbackUrlPath[0]
    : router.query.callbackUrlPath;

  // Parse invite from callbackUrlPath if url doesn't contain invite query param
  if (callbackUrlPath && !inviteCode) {
    const url = new URL(`${BASE_URL}${callbackUrlPath}`);
    const inviteCodeFromCallbackUrl = url.searchParams.get('invite');
    if (inviteCodeFromCallbackUrl) inviteCode = inviteCodeFromCallbackUrl;
  }

  const redirectUrl = getSignInRedirectUrl(
    viewer,
    organization,
    callbackUrlPath
  );

  // Parse shortId from callbackUrlPath
  const shortId = getShortId(callbackUrlPath);

  // Parse error
  const error = router.query.error as string;
  const errorDescription = router.query.error_description as string;

  return {
    shortId,
    inviteCode,
    redirectUrl,
    error,
    errorDescription,
  };
};
