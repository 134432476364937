import { createGlobalStyle, css } from '@xstyled/styled-components';
import reset from 'styled-reset';

const additionalResets = css`
  /* Reset font-family for text input elements since styled-reset / Eric Meyer's reset doesn't cover this */
  input,
  textarea,
  button {
    font-family: default;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${reset}
  ${additionalResets}

  :root {
    font-family: default;
    color: gray.500;
    font-size: base;
    line-height: base;
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body {
    background-color: background;
    height: 100%;
  }

  code {
    font-family: code;
    line-height: base;
  }
  
  [data-radix-popper-content-wrapper] {
    z-index: popover !important;
  }

  /* Main container */
  .emoji-mart {
    top: 5px;
    font-family: default;
    color: gray.500;
    border-radius: md;
    border-color: gray.200;
  }

  .emoji-mart,
  .emoji-mart * {
    font-family: default;
  }

  /* Icons */
  .emoji-mart-anchor {
    color: gray.300;
  }

  /* Top bar */
  .emoji-mart-bar {
    border-color: gray.200;
  }

  /* Search input */
  .emoji-mart-search input {
    border-color: gray.200;
    color: gray.500;
  }

  /* Placeholder text */
  .emoji-mart-sr-only {
    color: gray.300;
  }
  
  img {
    display: block;
  }

  /* Combobox doesn't provide a className prop so we need to overwrite it globally */
  ul.slate-Combobox {
    padding: 3;
    background: white;
    border-radius: md;
    box-shadow: sm;
    width: auto;
    overflow: auto;
    z-index: popover;

    div[role=option] {
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
      font-size: inherit;
      min-height: auto;
    }

    > * ~ * {
      margin-top: 3;
    }
  }

  /* suppress focus ring on form controls for mouse users */
  [data-whatintent='mouse'] *:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }

    to {
      opacity: 100%;
      transform: translateY(0);
    }
  }
  
  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 100%;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 100%;
      transform: translateX(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 100%;
      transform: translateX(0);
    }
  }
`;
