import { theme } from '@frond/shared';
import styled, { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';

import {
  JoinPolicy,
  useOrganizationInviteQuery,
} from '../../../../generated/types-and-hooks';
import { GroupsSidebarOrganization } from '../../groups/components/GroupsSidebar/GroupsSidebar';
import { getInviteLink, getInviteText } from '../../invites/utils';
import { useClipboardCopy } from '../hooks/useClipboardCopy';
import { Button } from './Button';
import { Icon } from './Icon';
import { Text } from './Text';

const StyledButton = styled(Button)`
  color: gray.300;

  &:disabled {
    color: gray.300;
  }
`;

export const ShareLink: React.FC<{
  label?: string;
  route: string;
  variant?: 'default' | 'dark';
  organization: GroupsSidebarOrganization;
}> = ({ route, variant = 'default', organization, ...props }) => {
  const { t } = useTranslation();
  const { copied, handleCopy } = useClipboardCopy(2000);
  const label = props.label || t('invite_via_link');
  const { data, loading } = useOrganizationInviteQuery({
    variables: {
      organizationId: organization.id,
    },
    skip: [JoinPolicy.Unrestricted, JoinPolicy.RequestToJoin].includes(
      organization.joinPolicy
    ),
    context: {
      skip: [JoinPolicy.Unrestricted, JoinPolicy.RequestToJoin].includes(
        organization.joinPolicy
      ),
    },
  });

  const inviteLink = getInviteLink(
    organization,
    route,
    data?.organizationInvite.inviteCode
  );

  return (
    <x.div spaceY={2}>
      <Text
        variant="md-semibold"
        color={variant === 'dark' ? 'gray.300' : 'gray.800'}
      >
        {label}
      </Text>
      <CopyToClipboard text={inviteLink} onCopy={handleCopy}>
        <x.div
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="gray.300"
          border="default"
          borderColor="gray.200"
          borderRadius="md"
          p="2 4"
          cursor={copied ? 'default' : 'pointer'}
          bg="white"
          {...(variant === 'dark' && {
            color: 'gray.400',
            borderColor: 'gray.200',
            bg: 'gray.100',
          })}
        >
          {loading ? (
            <Text as="span" variant="md" color="gray.400">
              Loading...
            </Text>
          ) : copied ? (
            <>
              <Icon
                name="CircleChecked"
                size="5"
                mr={theme.sizes[1.5]}
                color="green.300"
              />
              <Text as="span" variant="md" color="green.300">
                Link copied
              </Text>
            </>
          ) : (
            <>
              <Text
                w="100%"
                textAlign="left"
                truncate
                textOverflow="clip"
                mr="2"
                color="gray.400"
                variant="md"
              >
                {inviteLink}
              </Text>
              <StyledButton
                variant="icon"
                leftElement={
                  <>
                    {copied ? (
                      <Icon name="CircleChecked" />
                    ) : (
                      <Icon name="Copy" />
                    )}
                  </>
                }
                blurAfterClick
                disabled={copied}
              />
            </>
          )}
        </x.div>
      </CopyToClipboard>
      <Text variant="md">{getInviteText(organization, t)}</Text>
    </x.div>
  );
};
