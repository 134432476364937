import {
  User,
  UserGroupNotificationPreferencesFragment,
  UserProfileFragment,
} from '../../../../../generated/graphql-request-api-sdk';
import { getUserDisplayName } from '../../utils/user';
import {
  AudienceSelection,
  AudienceSelectionEmail,
  AudienceSelectionEveryone,
  AudienceSelectionUser,
} from './AudienceSelectorTypes';

export const createAudienceSelectionEmail = (
  email: string,
  id?: string,
  notificationPreferences?: UserGroupNotificationPreferencesFragment['membership']
): AudienceSelectionEmail => ({
  id,
  notificationPreferences,
  type: 'email',
  payload: email,
});

export const createAudienceSelectionUser = (
  user: Pick<
    UserProfileFragment,
    | 'id'
    | 'avatar'
    | 'avatarBgColor'
    | 'firstName'
    | 'username'
    | 'name'
    | 'profileImageId'
  >,
  id?: string,
  notificationPreferences?: UserGroupNotificationPreferencesFragment['membership']
): AudienceSelectionUser => ({
  id,
  notificationPreferences,
  type: 'user',
  payload: user,
});

export const createAudienceSelectionEveryone = (
  id?: string,
  notificationPreferences?: UserGroupNotificationPreferencesFragment['membership']
): AudienceSelectionEveryone => ({
  id,
  notificationPreferences,
  type: 'everyone',
  payload: undefined,
});

export const createAudienceSelection = (
  source: User,
  id?: string,
  notificationPreferences?: UserGroupNotificationPreferencesFragment['membership']
) => {
  if (source.__typename === 'User') {
    return createAudienceSelectionUser(source, id, notificationPreferences);
  }
};

/**
 * Returns a unique value to use as React keys
 */
export const getAudienceSelectionId = (aud: AudienceSelection) => {
  if (aud.type === 'everyone') {
    return 'every_frond_user_in_org';
  }

  return aud.type === 'email' ? aud.payload : aud.payload.id;
};

/**
 * Returns a string representation of a single selection
 */
export const formatAudienceSelection = (aud: AudienceSelection): string => {
  if (aud.type === 'user') return getUserDisplayName(aud.payload);
  if (aud.type === 'email') return aud.payload;
  if (aud.type === 'everyone') return 'everyone on Frond';

  return '';
};

/**
 * Renders an audience selection list to a string
 */
export const formatAudience = (
  audList: AudienceSelection[],
  numItems = 3
): string => {
  // Sort 'everyone' and slack channels to the front
  const sortedAudience = [...audList].sort((a, b) => {
    if (a.type === 'everyone') return -1;
    if (b.type === 'everyone') return 1;

    return 0;
  });

  const hasOverflow = audList.length > numItems;

  const displayAudience = hasOverflow
    ? sortedAudience.slice(0, numItems - 1)
    : sortedAudience;

  const formattedDisplayAudience = displayAudience.map(formatAudienceSelection);

  if (hasOverflow) {
    const overflowSize = audList.length - (numItems - 1);
    formattedDisplayAudience.push(`${overflowSize} more`);
  }

  const audienceString = formattedDisplayAudience.reduce(
    (list, item, index) => {
      if (formattedDisplayAudience.length === 1 || index === 0) {
        return item;
      }

      if (index === formattedDisplayAudience.length - 1) {
        return `${list} and ${item}`;
      }

      return `${list}, ${item}`;
    },
    ''
  );

  // Don't capitalize the first char if it belongs to an email
  if (displayAudience[0]?.type === 'email') {
    return audienceString;
  }

  return audienceString.charAt(0).toUpperCase() + audienceString.substring(1);
};
