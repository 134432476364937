import * as React from "react";

function SvgCheetah(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.488 35h-.207a3.962 3.962 0 01-2.319-.744 3.596 3.596 0 01-.524-.437L20 33.38l-.438.438c-.162.164-.34.313-.53.444a3.956 3.956 0 01-2.313.737h-.207a3.969 3.969 0 01-2.656-1.013A12.575 12.575 0 014.375 21.8v-4.713a11.444 11.444 0 011.194-5c.106-.206.206-.406.331-.624a11.25 11.25 0 012.619-3.125 11.368 11.368 0 013.575-2.044c.61-.217 1.237-.382 1.875-.494.65-.117 1.308-.175 1.969-.175h8.124c2.717.001 5.345.96 7.425 2.706A11.287 11.287 0 0134.12 11.5c.106.175.212.369.312.575a11.436 11.436 0 011.2 5v4.713a12.587 12.587 0 01-9.481 12.187c-.21.19-.44.356-.688.494-.6.347-1.281.53-1.974.531z"
        fill="#212121"
      />
      <path
        d="M20 31.875a.624.624 0 01.444.181l.881.882c.11.109.229.207.356.293.467.338 1.03.52 1.607.52h.206c.476 0 .944-.126 1.356-.363.204-.114.39-.255.556-.42.08-.076.18-.13.288-.155A11.324 11.324 0 0034.375 21.8v-4.706a10.2 10.2 0 00-1.069-4.463c-.087-.168-.168-.33-.268-.5a10.3 10.3 0 00-8.975-5.256h-8.125a9.869 9.869 0 00-1.726.144c-.578.1-1.146.248-1.7.444a10.288 10.288 0 00-5.53 4.637c-.12.188-.2.356-.288.525a10.194 10.194 0 00-1.069 4.469V21.8a11.337 11.337 0 008.681 11.013c.108.025.208.08.288.156.163.162.348.3.55.412.415.242.888.37 1.368.37h.207a2.725 2.725 0 001.593-.507c.13-.088.252-.189.363-.3l.881-.881a.626.626 0 01.444-.188z"
        fill="#FFB74D"
      />
      <path
        d="M6.131 12.975a.626.626 0 01-.506-.262l-1.688-2.37a4.131 4.131 0 015.5-5.93l3.188 1.925a.625.625 0 01-.113 1.125 10.394 10.394 0 00-5.818 5.162.624.624 0 01-.513.344l-.05.006z"
        fill="#212121"
      />
      <path
        d="M7.3 5.069a2.881 2.881 0 00-2.344 4.55l1.107 1.562a11.7 11.7 0 014.856-4.375L8.788 5.488a2.881 2.881 0 00-1.488-.42z"
        fill="#FFB74D"
      />
      <path
        d="M33.869 12.975h-.05a.624.624 0 01-.513-.344A10.394 10.394 0 0027.5 7.463a.625.625 0 01-.113-1.125l3.188-1.925a4.131 4.131 0 015.5 5.925l-1.7 2.375a.625.625 0 01-.506.262z"
        fill="#212121"
      />
      <path
        d="M29.075 6.775a11.7 11.7 0 014.856 4.375l1.113-1.556a2.881 2.881 0 00-3.838-4.138l-2.13 1.32z"
        fill="#FFB74D"
      />
      <path
        d="M14.143 34.038a.625.625 0 01-.412-.17l-.056-.05a4.014 4.014 0 011.25-6.53l.624-.275a2.137 2.137 0 001.326-1.963V20.1a1.424 1.424 0 00-.863-1.306l-1.875-.706a.625.625 0 11.444-1.17l1.875.72a2.682 2.682 0 011.669 2.462v4.95a3.387 3.387 0 01-2.057 3.125l-.625.275a2.762 2.762 0 00-.881 4.488.625.625 0 01.038.9.623.623 0 01-.457.2z"
        fill="#212121"
      />
      <path
        d="M14.375 18.125a.625.625 0 01-.232-.044l-3.125-1.25a.626.626 0 01.463-1.162l3.125 1.25a.626.626 0 01-.231 1.206z"
        fill="#212121"
      />
      <path
        d="M11.25 19.375a.625.625 0 01-.232-1.206l3.125-1.25a.626.626 0 11.463 1.162l-3.125 1.25a.625.625 0 01-.231.044zm14.593 14.656a.625.625 0 01-.437-1.062l.062-.05c.506-.515.787-1.21.782-1.931a2.77 2.77 0 00-1.682-2.544l-.625-.275a3.388 3.388 0 01-2.056-3.125V20.1a2.668 2.668 0 011.625-2.456l1.875-.731a.625.625 0 01.444 1.168l-1.875.719a1.413 1.413 0 00-.831 1.3v4.95a2.143 2.143 0 001.293 1.969l.625.275a4.011 4.011 0 011.25 6.531l-.075.063a.625.625 0 01-.375.143z"
        fill="#212121"
      />
      <path
        d="M25.625 18.125a.625.625 0 01-.232-1.206l3.125-1.25a.626.626 0 01.463 1.162l-3.125 1.25a.625.625 0 01-.231.044z"
        fill="#212121"
      />
      <path
        d="M28.75 19.375a.625.625 0 01-.231-.044l-3.125-1.25a.625.625 0 11.462-1.162l3.125 1.25a.625.625 0 01-.231 1.206zm-7.394 17.5h-2.712a4.894 4.894 0 01-4.375-2.656.625.625 0 01.868-.831c.413.237.88.362 1.357.362h.206a2.726 2.726 0 001.594-.506c.13-.089.251-.189.362-.3l.882-.881a.625.625 0 01.88 0l.882.88c.11.11.229.208.356.295.468.338 1.03.52 1.607.518h.206c.476 0 .944-.124 1.356-.362a.626.626 0 01.869.831 4.894 4.894 0 01-4.338 2.65z"
        fill="#212121"
      />
      <path
        d="M16.6 35a3.631 3.631 0 002.043.625h2.713A3.631 3.631 0 0023.4 35h-.12a3.962 3.962 0 01-2.318-.744 3.601 3.601 0 01-.525-.437L20 33.38l-.438.438c-.163.164-.34.313-.531.444a3.957 3.957 0 01-2.313.737H16.6z"
        fill="#8D6E63"
      />
      <path
        d="M20 30.988c-.33 0-.647-.13-.881-.363l-.994-.994a1.25 1.25 0 01.881-2.131h1.982a1.25 1.25 0 01.887 2.131l-.994.994a1.25 1.25 0 01-.88.363z"
        fill="#212121"
      />
      <path d="M20.994 28.75H19l1 .994.994-.994z" fill="#4E342E" />
      <path
        d="M20 33.125a.625.625 0 01-.625-.625v-1.875a.625.625 0 111.25 0V32.5a.625.625 0 01-.625.625zM20 12.5a.625.625 0 01-.625-.625V6.25a.625.625 0 111.25 0v5.625A.625.625 0 0120 12.5zm-3.125 3.644a.625.625 0 01-.625-.625V11.98a.625.625 0 111.25 0v3.538a.625.625 0 01-.625.625zm6.25 0a.625.625 0 01-.625-.625V11.98a.625.625 0 111.25 0v3.538a.625.625 0 01-.625.625zM5.938 21.875a2.662 2.662 0 01-1.25-.288.625.625 0 01-.313-.556v-3.944a.625.625 0 01.35-.562 2.812 2.812 0 111.213 5.35z"
        fill="#212121"
      />
      <path
        d="M5.625 20.625c.104.01.209.01.313 0a1.563 1.563 0 100-3.125 1.6 1.6 0 00-.313 0v3.125z"
        fill="#4E342E"
      />
      <path
        d="M8.462 30.831a.626.626 0 01-.444-.181 12.362 12.362 0 01-2.587-3.775.625.625 0 01.23-.775c.452-.304.982-.47 1.526-.475a2.806 2.806 0 011.637 5.087.625.625 0 01-.362.12z"
        fill="#212121"
      />
      <path
        d="M6.844 26.913c.438.868.989 1.675 1.637 2.4a1.562 1.562 0 00-1.637-2.4z"
        fill="#4E342E"
      />
      <path
        d="M8.437 13.75a2.8 2.8 0 01-2.581-1.706.625.625 0 010-.55 11.294 11.294 0 012.631-3.169.625.625 0 01.537-.131A2.813 2.813 0 018.4 13.75h.038z"
        fill="#212121"
      />
      <path
        d="M7.144 11.813A1.563 1.563 0 0010 10.938a1.575 1.575 0 00-.938-1.432 9.999 9.999 0 00-1.918 2.307z"
        fill="#4E342E"
      />
      <path
        d="M31.537 30.831a.625.625 0 01-.362-.119 2.806 2.806 0 011.637-5.087 2.776 2.776 0 011.525.45.625.625 0 01.232.8c-.623 1.4-1.5 2.671-2.588 3.75a.626.626 0 01-.444.206z"
        fill="#212121"
      />
      <path
        d="M32.812 26.875a1.557 1.557 0 00-1.287 2.438 11.115 11.115 0 001.637-2.4 1.548 1.548 0 00-.35-.038z"
        fill="#4E342E"
      />
      <path
        d="M15.625 10a2.5 2.5 0 01-2.031-3.956.625.625 0 01.4-.25 11.13 11.13 0 011.944-.169h1.08a.625.625 0 01.463.206A2.5 2.5 0 0115.625 10z"
        fill="#212121"
      />
      <path
        d="M14.488 6.975a1.25 1.25 0 00-.113.525 1.25 1.25 0 102.331-.625h-.768a10.1 10.1 0 00-1.45.1z"
        fill="#4E342E"
      />
      <path
        d="M34.063 21.875a2.813 2.813 0 111.212-5.35.625.625 0 01.35.563v3.943a.626.626 0 01-.344.556 2.66 2.66 0 01-1.218.288z"
        fill="#212121"
      />
      <path
        d="M34.063 17.5a1.562 1.562 0 100 3.125c.103.01.208.01.312 0V17.5a1.6 1.6 0 00-.313 0z"
        fill="#4E342E"
      />
      <path
        d="M31.563 13.75a2.812 2.812 0 01-.625-5.556.625.625 0 01.537.131 11.293 11.293 0 012.631 3.169.624.624 0 010 .55 2.8 2.8 0 01-2.543 1.706z"
        fill="#212121"
      />
      <path
        d="M30.938 9.506a1.563 1.563 0 101.918 2.307 10 10 0 00-1.918-2.307z"
        fill="#4E342E"
      />
      <path
        d="M24.375 10A2.5 2.5 0 0122.5 5.831a.626.626 0 01.481-.206h1.081a11.13 11.13 0 011.95.169c.161.028.305.117.4.25A2.5 2.5 0 0124.375 10z"
        fill="#212121"
      />
      <path
        d="M23.294 6.875a1.25 1.25 0 102.331.625 1.25 1.25 0 00-.113-.525 10.1 10.1 0 00-1.45-.1h-.768z"
        fill="#4E342E"
      />
    </svg>
  );
}

export default SvgCheetah;
