import * as React from "react";
import { SVGProps } from "react";
const SvgPause = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.375 2.083A1.885 1.885 0 0 0 2.5 3.958v12.084c0 1.028.847 1.875 1.875 1.875h2.083a1.885 1.885 0 0 0 1.875-1.875V3.958a1.885 1.885 0 0 0-1.875-1.875H4.375Zm9.167 0a1.885 1.885 0 0 0-1.875 1.875v12.084c0 1.028.847 1.875 1.875 1.875h2.083a1.885 1.885 0 0 0 1.875-1.875V3.958a1.885 1.885 0 0 0-1.875-1.875h-2.083Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPause;
