import { createContext, PropsWithChildren, useContext } from 'react';

import { CommentConfigQuery } from '../../../../generated/graphql-request-api-sdk';
import { useCommentConfigQuery } from '../../../../generated/types-and-hooks';

type CommentConfigType = {
  data?: CommentConfigQuery;
  loading: boolean;
};

const CommentConfigContext = createContext<CommentConfigType>({
  loading: false,
});

export const CommentConfigProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { data, loading } = useCommentConfigQuery();

  return (
    <CommentConfigContext.Provider value={{ data, loading }}>
      {children}
    </CommentConfigContext.Provider>
  );
};

export const useCommentConfig = () => useContext(CommentConfigContext);
