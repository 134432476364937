import * as React from "react";
import { SVGProps } from "react";
const SvgStrikethrough = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.792 2.917C7.484 2.917 5 4.025 5 6.458c0 .804.308 1.405.794 1.875h2.564c-1.336-.46-2.108-.955-2.108-1.875 0-1.813 2.317-2.291 3.542-2.291 2.1 0 3.279 1.042 3.326 1.084a.625.625 0 0 0 .848-.918c-.062-.058-1.562-1.416-4.174-1.416ZM3.958 9.583a.625.625 0 1 0 0 1.25h8.028c1.111.514 1.764 1.174 1.764 2.292 0 .945-1.161 2.708-3.958 2.708-2.572 0-4.034-1.773-4.096-1.849a.624.624 0 0 0-.977.78c.076.095 1.882 2.32 5.073 2.32 3.553 0 5.208-2.363 5.208-3.959 0-.98-.335-1.717-.865-2.292h1.907a.626.626 0 0 0 .242-1.205.625.625 0 0 0-.242-.045H3.958Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgStrikethrough;
