import { x } from '@xstyled/styled-components';
import debounce from 'lodash/debounce';
import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useVideoEvents } from '../hooks/useVideoEvents';
import { VideoControlsProps } from './Video/VideoControls';
import { VideoSeekbarProps } from './Video/VideoSeekbar';
import { VideoCore, VideoCoreProps } from './VideoCore';

const SEEKBAR_DEBOUNCE_MS = 100;

export type VideoPlayerProps = VideoCoreProps & {
  playsInline?: boolean;
  onClick?: React.MouseEventHandler;
} & {
  seekbar?: FC<VideoSeekbarProps>;
  controls?: FC<VideoControlsProps>;
  aspect?: number | null;
  displayAspectRatio?: string;
  // By default videos don't play simultaneously
  // Setting bindEvents false will disable this behavior
  bindEvents?: boolean;
};

export type VideoPlayerRef = {
  togglePlayback: () => void;
  toggleMuted: () => void;
  skip: (seconds: number) => void;
  watchAvailability: (
    callback: RemotePlaybackAvailabilityCallback
  ) => Promise<number> | null;
  remotePrompt: () => Promise<void> | null;
};

/**
 * Interface for UI components extending VideoCore
 */
export const VideoPlayer = forwardRef<VideoPlayerRef, VideoPlayerProps>(
  (
    {
      seekbar,
      controls,
      aspect,
      w,
      h,
      displayAspectRatio = 'auto',
      backgroundColor = 'transparent',
      onMutedStatusChange,
      onPlaybackStatusChange,
      onClick,
      bindEvents = true,
      ...coreProps
    },
    forwardedRef
  ) => {
    const ref = useRef<HTMLVideoElement>(null);
    const { pauseAll } = useVideoEvents(ref, { disabled: !bindEvents });

    useImperativeHandle(forwardedRef, () => ({
      togglePlayback,
      toggleMuted,
      skip,
      watchAvailability,
      remotePrompt,
    }));

    const [duration, setDuration] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [active, setActive] = useState(false);
    const [played, setPlayed] = useState(coreProps.autoPlay || false);
    const [buffering, setBuffering] = useState(false);
    const [muted, setMuted] = useState(coreProps.muted || false);
    const [percentElapsed, setPercentElapsed] = useState(0);

    const { onLoadedDuration } = coreProps;

    useEffect(() => {
      coreProps.autoPlay && bindEvents && pauseAll();
    }, [coreProps.autoPlay, pauseAll, bindEvents]);

    /**
     * Called when the metadata has loaded
     */
    const handleLoadedDuration = useCallback(
      (duration: number) => {
        onLoadedDuration?.(duration);
        setDuration(duration);
      },
      [onLoadedDuration, setDuration]
    );

    /**
     * Seekbar changes update the playback time
     */
    const handleSeekbarChange = useCallback((seekPercent: number) => {
      if (!ref.current) {
        return;
      }

      const time = ref.current.duration * (seekPercent / 100);
      ref.current.currentTime = time;
    }, []);

    /**
     * Called when the video finishes
     */
    const handleCompletion = useCallback(() => {
      setPercentElapsed(0);
      setActive(false);
    }, [setActive, setPercentElapsed]);

    /**
     * Called periodically during playback
     */
    const handleTimeUpdate = useCallback(() => {
      if (!ref.current) {
        return;
      }

      if (ref.current.currentTime >= duration) {
        return handleCompletion();
      }

      if (!active) {
        setActive(true);
      }

      if (!played) {
        setPlayed(true);
      }

      setPercentElapsed((100 / duration) * ref.current.currentTime);
    }, [
      ref,
      duration,
      setPercentElapsed,
      setActive,
      active,
      handleCompletion,
      played,
    ]);

    /**
     * Update UI state as video is muted and unmuted
     */
    const handleMutedStatusChange = useCallback(
      (muted: boolean) => {
        setMuted(muted);
        onMutedStatusChange?.(muted);
      },
      [onMutedStatusChange]
    );

    /**
     * Toggle the video muted state
     */
    const toggleMuted = useCallback(() => {
      ref.current && (ref.current.muted = !ref.current.muted);
    }, []);

    /**
     * Skip backwards or forwards by a fixed amount
     */
    const skip = useCallback((seconds: number) => {
      ref.current && (ref.current.currentTime += seconds);
    }, []);

    /**
     * Watch remote availability
     */
    const watchAvailability = useCallback(
      (callback: RemotePlaybackAvailabilityCallback) => {
        return ref.current && ref.current.remote.watchAvailability(callback);
      },
      []
    );

    /**
     * Remote prompt
     */
    const remotePrompt = useCallback(() => {
      return ref.current && ref.current.remote.prompt();
    }, []);

    /**
     * Skip to a specific point
     */
    const skipTo = useCallback((seconds: number) => {
      ref.current && (ref.current.currentTime = seconds);
    }, []);

    /**
     * Play from currentTime
     */
    const play = useCallback(() => {
      bindEvents && pauseAll();
      ref.current?.play();
    }, [pauseAll, bindEvents]);

    /**
     * Pause
     */
    const pause = useCallback(() => {
      ref.current?.pause();
    }, []);

    /**
     * Toggles play and pause states
     */
    const togglePlayback = useCallback(() => {
      if (playing) {
        return pause();
      }

      if (percentElapsed !== 100) {
        return play();
      }

      // Video has ended, restart it
      skipTo(0);
      ref.current?.addEventListener('canplay', play, { once: true });
    }, [playing, percentElapsed, play, skipTo, pause]);

    /**
     * Set local playback state and inform subscribers
     */
    const handlePlaybackStatusChange = useCallback(
      (playing: boolean) => {
        setPlaying(playing);
        onPlaybackStatusChange?.(playing);
      },
      [onPlaybackStatusChange]
    );

    const portrait = aspect && aspect < 1;

    return (
      <x.div position="relative" w={w} h={h}>
        <VideoCore
          ref={ref}
          w={w}
          h={h}
          style={{ aspectRatio: displayAspectRatio }}
          backgroundColor={portrait ? 'black' : backgroundColor}
          objectFit={portrait ? 'fit' : 'cover'}
          {...coreProps}
          onMutedStatusChange={handleMutedStatusChange}
          onLoadedDuration={handleLoadedDuration}
          onTimeUpdate={handleTimeUpdate}
          onPlaybackStatusChange={handlePlaybackStatusChange}
          onBufferingStatusChange={setBuffering}
        />
        {controls &&
          controls({
            active,
            buffering,
            play,
            pause,
            playing,
            played,
            muted,
            toggleMuted,
            skip,
            togglePlayback,
            duration,
            onClick,
          })}
        {seekbar &&
          seekbar({
            videoRef: ref,
            active: active,
            progress: percentElapsed,
            onChange: debounce(handleSeekbarChange, SEEKBAR_DEBOUNCE_MS),
          })}
      </x.div>
    );
  }
);

VideoPlayer.displayName = 'VideoPlayer';
