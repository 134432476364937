import * as React from 'react';
import { SVGProps } from 'react';

const SvgWelcomeMember = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M7.593 21.5a.497.497 0 0 1-.324-.12c-2.029-1.735-4.722-3.127-4.75-3.141a.5.5 0 0 1 .458-.89c.114.058 2.823 1.459 4.942 3.271a.5.5 0 0 1-.325.88Zm8.584-14a.499.499 0 0 1-.33-.124 52.406 52.406 0 0 1-4.05-4.066.5.5 0 1 1 .747-.664c1.971 2.222 3.944 3.962 3.963 3.979a.5.5 0 0 1-.33.875Zm1.233-2.484a.497.497 0 0 1-.312-.11c-.039-.031-.966-.78-1.818-1.993a.5.5 0 0 1 .819-.575 9.63 9.63 0 0 0 1.625 1.787.5.5 0 0 1-.314.89Z"
      fill="#199BE2"
    />
    <path
      d="M21.011 6.572c-.302-.598-1.098-.624-1.741-.204-1.235.804-.93 2.074-.93 2.074-1.133.759-.343 3.247-2.779 1.357C14.864 9.257 8.2 3.714 8.2 3.714a.94.94 0 0 0-1.235 1.413l3.892 3.56a.648.648 0 0 1-.87.961l-4.81-4.296a.939.939 0 0 0-1.317.06l-.01.01a.938.938 0 0 0 .063 1.336l4.69 4.169a.54.54 0 0 1-.707.817L3.57 8.094a.938.938 0 0 0-1.297.08l-.025.027a.937.937 0 0 0 .068 1.338l4.211 3.697a.418.418 0 0 1-.551.63l-2.298-1.998a.938.938 0 0 0-1.311.066l-.029.031a.94.94 0 0 0 .05 1.324c1.547 1.437 5.319 4.633 6.927 5.88 2.297 1.805 5.212 1.776 6.925.493 1.02-1.106 1.737-2.067 3.139-3.985.76-1.04.846-3.938 1.03-6.005.15-1.658.601-3.1.601-3.1Z"
      fill="url(#welcome-member_svg__a)"
    />
    <defs>
      <linearGradient
        id="welcome-member_svg__a"
        x1={9.04}
        y1={3.43}
        x2={15.753}
        y2={20.135}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC430" />
        <stop offset={0.41} stopColor="#FEC130" />
        <stop offset={0.72} stopColor="#FCB730" />
        <stop offset={0.998} stopColor="#F7A72F" />
        <stop offset={1} stopColor="#F7A72F" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgWelcomeMember;
