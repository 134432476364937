import { x } from '@xstyled/styled-components';

import {
  OrganizationRoleCoreFragment,
  UserPreviewFragment,
} from '../../../../generated/types-and-hooks';
import { useOrganizationEntitlements } from '../../organizations/hooks/useOrganizationEntitlements';
import { CommunityItemStatusProps } from '../../settings/components/SettingsViews/CommunityItemStatus';
import { CommunityRoleToken } from '../../settings/components/SettingsViews/CommunityRoleToken';
import { useUsers } from '../hooks/useUsers';
import { Text } from './Text';
import { Tooltip } from './Tooltip';

export const UserCommunityRoles: React.FC<{
  user?: UserPreviewFragment;
  roles?: OrganizationRoleCoreFragment[];
  limit?: number;
  variant?: CommunityItemStatusProps['variant'];
}> = ({ user, roles, limit, variant }) => {
  const { features } = useOrganizationEntitlements();

  const { rolesByUserId } = useUsers();

  const rolesOrRolesByUserId =
    roles || (rolesByUserId && user && rolesByUserId[user.id]) || [];

  const children = rolesOrRolesByUserId
    ?.filter((r) =>
      features.CustomRoles ? true : r.isAdmin || r.memberSubscriptionPlan
    )
    ?.slice(0, limit)
    ?.map((role) => (
      <CommunityRoleToken key={role.id} role={role} variant={variant} />
    ));

  if (rolesOrRolesByUserId && limit && rolesOrRolesByUserId.length > limit) {
    return (
      <Tooltip
        variant="light"
        aria-label={rolesOrRolesByUserId.map((role) => role.label).join(', ')}
        label={
          <x.div
            display="flex"
            gap={1}
            alignItems="flex-start"
            justifyContent="flex-start"
            flexWrap="wrap"
          >
            {rolesOrRolesByUserId.map((role) => (
              <CommunityRoleToken key={role.id} role={role} variant={variant} />
            ))}
          </x.div>
        }
      >
        <x.div display="flex" flexDirection="row" alignItems="center">
          <CommunityRoleToken
            role={rolesOrRolesByUserId[0]}
            borderRadius="sm 0 0 sm"
            variant={variant}
          />
          <x.div borderRadius="0 sm sm 0" backgroundColor="white">
            <Text variant="sm-medium" p="1 2">
              {`+${rolesOrRolesByUserId.length - limit}`}
            </Text>
          </x.div>
        </x.div>
      </Tooltip>
    );
  }

  return (
    <x.div
      display="flex"
      flexDirection="row"
      alignItems="center"
      spaceX={2}
      color="gray.500"
    >
      {children}
    </x.div>
  );
};
