import * as React from "react";

function SvgTempTabTeam(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.427 14.294a3.867 3.867 0 01-4.854 0 1.03 1.03 0 10-1.317 1.584 5.842 5.842 0 007.488 0 1.03 1.03 0 00-1.317-1.584zM8.914 10.97a1.029 1.029 0 100-2.057 1.029 1.029 0 000 2.057zm6.172-2.057a1.028 1.028 0 100 2.057 1.028 1.028 0 000-2.057zM12 1.714a10.286 10.286 0 100 20.572 10.286 10.286 0 000-20.572zm0 18.515a8.228 8.228 0 110-16.457 8.228 8.228 0 010 16.457z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTempTabTeam;
