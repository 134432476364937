import { FieldPolicy, Reference } from '@apollo/client';

export const directMessageFieldPolicy: FieldPolicy = {
  merge: (existing, incoming, { readField }) => {
    if (existing && !incoming) {
      return existing;
    } else if (!existing && incoming) {
      return incoming;
    }

    const existingVersion = readField<number>('version', existing);
    const incomingVersion = readField<number>('version', incoming);

    // Compare version to determine which fragment to use
    if (
      existingVersion &&
      incomingVersion &&
      existingVersion > incomingVersion
    ) {
      return existing;
    } else {
      return incoming;
    }
  },
};

export const directMessageThreadsFieldPolicy: FieldPolicy = {
  keyArgs: ['organizationId'],
  read(existing, { readField }) {
    if (!existing) return existing;

    return [...existing].sort((aRef: Reference, bRef: Reference) => {
      if (!aRef || !bRef) return 0;

      const aLastMessageReceivedOn = readField<string>(
        'lastMessageReceivedOn',
        aRef
      );
      const bLastMessageReceivedOn = readField<string>(
        'lastMessageReceivedOn',
        bRef
      );

      if (!aLastMessageReceivedOn || !bLastMessageReceivedOn) return 0;

      return (
        new Date(bLastMessageReceivedOn).getTime() -
        new Date(aLastMessageReceivedOn).getTime()
      );
    });
  },
  merge(_, incoming) {
    return [...incoming];
  },
};

export const directMessageNotificationCountsFieldPolicy: FieldPolicy = {
  merge(_, incoming) {
    return { ...incoming };
  },
};
