import * as React from "react";
import { SVGProps } from "react";
const SvgSquareTime = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.25 3A3.262 3.262 0 0 0 3 6.25v11.5A3.262 3.262 0 0 0 6.25 21h11.5A3.261 3.261 0 0 0 21 17.75V6.25A3.262 3.262 0 0 0 17.75 3H6.25Zm0 1.5h11.5c.975 0 1.75.775 1.75 1.75v11.5a1.74 1.74 0 0 1-1.75 1.75H6.25a1.74 1.74 0 0 1-1.75-1.75V6.25c0-.975.775-1.75 1.75-1.75Zm4.988 1.49a.75.75 0 0 0-.738.76v6a.75.75 0 0 0 .75.75h4a.751.751 0 1 0 0-1.5H12V6.75a.75.75 0 0 0-.762-.76Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSquareTime;
