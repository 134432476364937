import { x } from '@xstyled/styled-components';
import React from 'react';

import { Resource } from '../../../../../generated/types-and-hooks';
import { PostResource } from '../../../posts/components/PostResource';
import { CommentComposerDeleteButton } from './CommentComposerDeleteButton';

export const CommentComposerResource = ({
  resource,
  onDelete,
}: {
  resource: Resource;
  onDelete: () => void;
}) => {
  return (
    <x.div pointerEvents="auto" position="relative" key={resource.id}>
      <PostResource colorVariant="dark" resource={resource} />
      <CommentComposerDeleteButton onClick={onDelete} />
    </x.div>
  );
};
