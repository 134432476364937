import { theme } from '@frond/shared';
import React from 'react';

type FrondLogoProps = {
  accentColor?: string;
  horizontal?: boolean;
};

export const FrondLogo: React.FC<FrondLogoProps> = ({
  accentColor = theme.colors.brand['400'],
  horizontal = false,
}) => {
  if (horizontal) {
    return (
      <svg viewBox="0 0 129 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.064178 6.24127C0.064178 6.24127 -1.65325 19.9423 12.6805 19.9423C12.6805 19.9423 16.2528 6.24127 0.064178 6.24127Z"
          fill={accentColor}
        />
        <path
          d="M36.4851 0.5C16.9867 0.5 15.183 12.7634 15.5408 19.0598C18.8349 9.55847 27.815 7.46132 27.815 7.46132C19.2423 12.6441 17.484 22.0002 17.3697 22.6606C39.1431 21.8202 36.4851 0.5 36.4851 0.5Z"
          fill={accentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M125.064 3.34758V8.116C123.9 6.64444 122.061 5.66341 119.855 5.66341C115.382 5.66341 111.767 9.37295 111.767 14.0635C111.767 18.6315 115.382 22.433 119.855 22.433C122.092 22.433 123.9 21.4826 125.064 20.0417V22.433H128.587V0.72757H128.571C127.553 1.77616 126.37 2.6635 125.064 3.34758ZM52.2027 22.433V13.0825H62.8342V9.80216H52.2027V4.09988H64.0598V0.72757H48.5874V22.433H52.2027ZM69.3568 22.433V13.9409C69.3568 10.4153 72.39 8.66783 75.607 9.64887V5.87801C72.3594 5.35683 70.3066 6.70576 69.3568 8.60652V5.93932H65.8334V22.433H69.3568ZM92.7667 14.0329C92.7667 18.6621 89.0288 22.433 84.433 22.433C79.8067 22.433 76.0688 18.6315 76.0688 14.0329C76.0688 9.43427 79.8067 5.66341 84.433 5.66341C89.0594 5.66341 92.7667 9.46492 92.7667 14.0329ZM79.3777 14.0329C79.3777 16.8534 81.6143 19.214 84.433 19.214C87.2518 19.214 89.4577 16.8227 89.4577 14.0329C89.4577 11.2124 87.2518 8.88243 84.433 8.88243C81.5837 8.88243 79.3777 11.2124 79.3777 14.0329ZM98.0815 22.433V13.8183C98.0815 11.0591 99.8278 8.94375 102.432 8.94375C104.577 8.94375 106.415 10.5073 106.415 13.1745V22.433H109.938V12.2241C109.938 8.116 106.691 5.66341 103.137 5.66341C100.778 5.66341 99.0925 6.76707 98.0815 8.08534V5.93932H94.5581V22.433H98.0815ZM115.045 14.0635C115.045 16.884 117.251 19.2446 120.162 19.2446C122.981 19.2446 125.309 16.8534 125.309 14.0635C125.309 11.2737 123.011 8.85178 120.162 8.85178C117.282 8.85178 115.045 11.2431 115.045 14.0635Z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg viewBox="0 0 81 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.4766 23.62V28.3884C75.3123 26.9169 73.474 25.9358 71.2681 25.9358C66.7949 25.9358 63.1796 29.6454 63.1796 34.336C63.1796 38.9039 66.7949 42.7054 71.2681 42.7054C73.5047 42.7054 75.3123 41.755 76.4766 40.3142V42.7054H80V21H79.9834C78.9657 22.0486 77.7828 22.9359 76.4766 23.62ZM3.61532 42.7054V33.3549H14.2468V30.0746H3.61532V24.3723H15.4724V21H0V42.7054H3.61532ZM20.7694 42.7054V34.2133C20.7694 30.6877 23.8026 28.9403 27.0196 29.9213V26.1504C23.772 25.6293 21.7192 26.9782 20.7694 28.8789V26.2118H17.246V42.7054H20.7694ZM44.1793 34.3053C44.1793 38.9346 40.4414 42.7054 35.8456 42.7054C31.2193 42.7054 27.4814 38.9039 27.4814 34.3053C27.4814 29.7067 31.2193 25.9358 35.8456 25.9358C40.472 25.9358 44.1793 29.7374 44.1793 34.3053ZM30.7903 34.3053C30.7903 37.1258 33.0269 39.4864 35.8456 39.4864C38.6644 39.4864 40.8703 37.0951 40.8703 34.3053C40.8703 31.4848 38.6644 29.1549 35.8456 29.1549C32.9963 29.1549 30.7903 31.4848 30.7903 34.3053ZM49.4941 42.7054V34.0907C49.4941 31.3315 51.2404 29.2162 53.8447 29.2162C55.9894 29.2162 57.8277 30.7797 57.8277 33.4469V42.7054H61.3511V32.4965C61.3511 28.3884 58.1034 25.9358 54.5494 25.9358C52.1902 25.9358 50.5051 27.0395 49.4941 28.3578V26.2118H45.9706V42.7054H49.4941ZM66.4579 34.336C66.4579 37.1564 68.6638 39.5171 71.5745 39.5171C74.3932 39.5171 76.7217 37.1258 76.7217 34.336C76.7217 31.5461 74.4238 29.1242 71.5745 29.1242C68.6945 29.1242 66.4579 31.5155 66.4579 34.336Z"
          fill="currentColor"
        />
        <path
          d="M52.0492 4.4043C52.0492 4.4043 50.7317 14.9147 61.7276 14.9147C61.7276 14.9147 64.468 4.4043 52.0492 4.4043Z"
          fill={accentColor}
        />
        <path
          d="M79.9887 0C65.031 0 63.6473 9.40761 63.9218 14.2378C66.4488 6.94901 73.3377 5.34022 73.3377 5.34022C66.7613 9.3161 65.4125 16.4934 65.3248 17C82.0278 16.3553 79.9887 0 79.9887 0Z"
          fill={accentColor}
        />
      </svg>
    );
  }
};
