import * as React from "react";

function SvgExternalLink(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.704 13.707l7.293-7.293V11h2V3h-8v2h4.586l-7.293 7.293 1.414 1.414zM19 19v-5h-2v5H5V7h5V5H5a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgExternalLink;
