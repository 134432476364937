import { useApolloClient } from '@apollo/client';
import * as UpChunk from '@mux/upchunk';
import * as Sentry from '@sentry/nextjs';

const CHUNK_SIZE = 5120;

import {
  CreateTemporaryVideoPresignedUploadDocument,
  CreateVideoPresignedUploadDocument,
  VideoType,
} from '../../../../generated/types-and-hooks';
import analytics from '../utils/analytics';

type UploadVideo = (
  videoType: VideoType,
  mirror: boolean,
  file: File,
  handleProgress: (percentageUploaded: number) => void,
  temporaryPostToken?: string
) => Promise<{ id: string }>;

type UsePresignedUploadReturnType = {
  uploadVideo: UploadVideo;
};

/**
 * Hook that returns methods for generating presigned URLs server side.
 * Currently implements Mux but can also be extended to support Cloudinary.
 */
export const usePresignedUpload = (): UsePresignedUploadReturnType => {
  const apollo = useApolloClient();

  /**
   * Gets a pre-signed MUX upload URL from our API
   * and uploads the passed video data
   */
  const uploadVideo: UploadVideo = (
    videoType,
    mirror,
    file,
    handleProgress,
    temporaryPostToken
  ) => {
    const logData = {
      videoType,
      mirror,
      name: file.name,
      size: file.size,
    };

    analytics.logEvent(analytics.events.VIDEO_UPLOAD_STARTED, logData);

    return new Promise<{ id: string }>((resolve, reject) => {
      apollo
        .mutate({
          mutation: temporaryPostToken
            ? CreateTemporaryVideoPresignedUploadDocument
            : CreateVideoPresignedUploadDocument,
          variables: {
            input: {
              videoType,
              mirror,
              ...(temporaryPostToken && { token: temporaryPostToken }),
            },
          },
        })
        .then((result) => {
          const { url, id } = temporaryPostToken
            ? result.data.createTemporaryVideoPresignedUpload
            : result.data.createVideoPresignedUpload;

          const upload = UpChunk.createUpload({
            endpoint: url,
            file,
            chunkSize: CHUNK_SIZE,
          });

          upload.on('attempt', (data) => {
            analytics.logEvent(analytics.events.VIDEO_UPLOAD_ATTEMPT, {
              ...logData,
              detail: data.detail,
            });
          });

          upload.on('attemptFailure', (data) => {
            analytics.logEvent(analytics.events.VIDEO_UPLOAD_ATTEMPT_FAILURE, {
              ...logData,
              detail: data.detail,
            });
          });

          upload.on('chunkSuccess', (data) => {
            analytics.logEvent(analytics.events.VIDEO_UPLOAD_CHUNK_SUCCESS, {
              ...logData,
              detail: data.detail,
            });
          });

          upload.on('success', () => {
            analytics.logEvent(analytics.events.VIDEO_UPLOAD_SUCCESS, logData);
            resolve({ id });
          });

          upload.on('error', (err) => {
            analytics.logEvent(analytics.events.VIDEO_UPLOAD_FAILED, {
              ...logData,
              detail: err.detail,
            });
            Sentry.captureException(err);
            reject(err);
          });

          upload.on('progress', (progress) => {
            handleProgress(progress.detail);
          });
        })
        .catch(reject);
    });
  };

  return {
    uploadVideo,
  };
};
