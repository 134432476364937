import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import router from 'next/router';
import { useTranslation } from 'next-i18next';
import { NextSeo } from 'next-seo';
import React, { FC, useState } from 'react';

import {
  OrganizationQuery,
  useHomeFeedQuery,
  UserQuery,
  useUserActivityCountsQuery,
  ViewLevels,
  ViewLocations,
} from '../../../../generated/types-and-hooks';
import { BASE_URL, POSTS_PER_PAGE } from '../../../config/constants';
import { SignInModal } from '../../auth/components/SignIn/SignInModal';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { Button } from '../../common/components/Button';
import { Icon } from '../../common/components/Icon';
import { Text } from '../../common/components/Text';
import { DetailLayout } from '../../common/layout/DetailLayout';
import { InfiniteScroll } from '../../common/layout/InfiniteScroll';
import { getUserDisplayName } from '../../common/utils/user';
import { Error404View } from '../../errors/views/Error404View';
import { DirectMessageSendMessageButton } from '../../messages/components/common/DirectMessageSendMessageButton';
import { PostCard, PostCardPlaceholder } from '../../posts/components/PostCard';
import { PostViewMutator } from '../../posts/components/PostViewMutator';
import { ProfileEditButton } from '../../settings/components/ProfileEditButton';
import { PostNavContext } from '../hooks/useAnswerNavigation';

export const ProfileView: FC<{
  user: UserQuery['user'];
  username?: string;
  showLayout?: boolean;
}> = ({ user, username, showLayout = true }) => {
  const { t } = useTranslation();
  const { organization } = useOrganization();
  const { data: dataUserActivity } = useUserActivityCountsQuery({
    variables: {
      username: user?.username || '',
      organizationId: organization.id,
    },
    skip: !user,
    context: {
      skip: !user,
    },
  });

  const { data, loading, fetchMore } = useHomeFeedQuery({
    variables: {
      organizationId: organization.id,
      first: POSTS_PER_PAGE - 1,
      username: username,
    },
    skip: !username,
    context: {
      skip: !username,
    },
  });

  const openGraph = {
    title: user ? `${getUserDisplayName(user)} - Frond` : 'Frond',
    url: `${BASE_URL}${routes.groups
      .organization(organization.shortId)
      .person(user?.username || '')}`,
  };

  if (user === null) {
    return <Error404View />;
  }

  return (
    <>
      <NextSeo
        title={openGraph.title}
        canonical={openGraph.url}
        openGraph={openGraph}
      />
      <DetailLayout
        controls={
          <ProfileViewControls user={user} organization={organization} />
        }
        item={user}
        numPosts={dataUserActivity?.userActivityCounts?.numPosts}
        numGroups={dataUserActivity?.userActivityCounts?.numGroups}
        showLayout={showLayout}
      >
        {!data?.homeFeed || !user ? (
          <x.div spaceY={6}>
            {[...Array(POSTS_PER_PAGE)].map((_, i) => (
              <PostCardPlaceholder key={i} />
            ))}
          </x.div>
        ) : data.homeFeed.edges.length === 0 ? (
          <x.div
            h="full"
            w="full"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex={1}
          >
            <x.div
              spaceY={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Icon name="sad" size="12" />
              <Text variant="sm-medium" color="gray.300">
                {t('no_posts_yet')}
              </Text>
            </x.div>
          </x.div>
        ) : (
          <x.div spaceY={6} h="full">
            <InfiniteScroll
              threshold={100}
              loading={loading}
              hasMore={!!data?.homeFeed?.pageInfo?.hasNextPage}
              loadMore={() => {
                fetchMore({
                  variables: {
                    username,
                    after: data?.homeFeed?.pageInfo?.endCursor,
                    first: POSTS_PER_PAGE,
                  },
                });
              }}
            >
              {data.homeFeed.edges.map(({ node: item }) => {
                if (item.__typename === 'Post') {
                  return (
                    <PostViewMutator
                      post={item}
                      viewLevel={ViewLevels.Detail}
                      viewLocation={ViewLocations.Home}
                      key={item.id}
                      delay={2500}
                    >
                      <PostCard post={item} navContext={PostNavContext.USER} />
                    </PostViewMutator>
                  );
                }
              })}
            </InfiniteScroll>
          </x.div>
        )}
      </DetailLayout>
    </>
  );
};

export const ProfileViewControls: FC<{
  user: UserQuery['user'];
  organization: OrganizationQuery['organization'];
}> = ({ user, organization }) => {
  const { viewer } = useViewer();
  const { t } = useTranslation();
  const [showSignInModal, setShowSignInModal] = useState(false);

  return (
    <div>
      {viewer?.id === user?.id ? (
        <ProfileEditButton
          onUpdateUser={(updatedUserData) => {
            if (updatedUserData && organization) {
              router.push(
                routes.groups
                  .organization(organization.shortId)
                  .person(updatedUserData.username)
              );
            }
          }}
        />
      ) : viewer && user ? (
        <DirectMessageSendMessageButton user={user} />
      ) : !viewer && user ? (
        <Button
          label={t('send_message_to', {
            userName: user.firstName || user.username,
          })}
          onClick={() => setShowSignInModal(true)}
        />
      ) : null}
      <SignInModal
        isOpen={showSignInModal}
        onDismiss={() => setShowSignInModal(false)}
        variant="public"
      />
    </div>
  );
};
