export const getMessageDescription = ({
  name,
  messageBody,
  metadata,
}: {
  name: string;
  messageBody: string;
  metadata?: Record<string, string>;
}): {
  [key: string]: string;
} => {
  if (!messageBody?.trim()) {
    const mediaIds = metadata?.mediaIds ? JSON.parse(metadata?.mediaIds) : [];

    if (mediaIds.length) {
      return {
        en: `${name} sent an image`,
        de: `${name} hat ein Bild geschickt`,
        es: `${name} envió una imagen`,
        fr: `${name} a envoyé une imag`,
      };
    }

    const resources = metadata?.resources
      ? JSON.parse(metadata?.resources)
      : [];

    if (resources.length) {
      return {
        en: `${name} shared a link`,
        de: `${name} hat einen Link geteilt`,
        es: `${name} compartió un enlace`,
        fr: `${name} a partagé un lien`,
      };
    }
  }

  return {
    en: messageBody,
    de: messageBody,
    es: messageBody,
    fr: messageBody,
  };
};
