import * as React from "react";
import { SVGProps } from "react";
const SvgZoom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.542 2.5A6.051 6.051 0 0 0 2.5 8.542a6.051 6.051 0 0 0 6.042 6.041c1.44 0 2.764-.508 3.803-1.354l4.088 4.088a.625.625 0 1 0 .884-.884l-4.088-4.088a6.011 6.011 0 0 0 1.354-3.803A6.051 6.051 0 0 0 8.542 2.5Zm0 1.25a4.782 4.782 0 0 1 4.791 4.792c0 1.292-.51 2.46-1.337 3.32a.626.626 0 0 0-.133.134 4.773 4.773 0 0 1-3.321 1.337A4.782 4.782 0 0 1 3.75 8.542 4.782 4.782 0 0 1 8.542 3.75Zm-.01 2.074a.625.625 0 0 0-.615.634v1.459H6.458a.625.625 0 1 0 0 1.25h1.459v1.458a.625.625 0 1 0 1.25 0V9.167h1.458a.625.625 0 1 0 0-1.25H9.167V6.458a.625.625 0 0 0-.635-.634Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgZoom;
