import * as React from "react";
import { SVGProps } from "react";
const SvgClear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.285 1.66a.625.625 0 0 0-.435 1.074l7.525 7.525v5.991h-.833a.624.624 0 1 0 0 1.25h2.916a.624.624 0 1 0 0-1.25h-.833v-4.741l6.642 6.641a.624.624 0 1 0 .883-.884L2.734 1.85a.625.625 0 0 0-.449-.19Zm13.33.831a.625.625 0 0 0-.582.426H5.531l1.25 1.25h2.594V6.76l1.25 1.25V4.167h4.408a.625.625 0 0 0 1.217-.209v-.833a.625.625 0 0 0-.635-.634Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgClear;
