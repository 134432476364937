import * as React from "react";
import { SVGProps } from "react";
const SvgPost = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.125 2.5A1.885 1.885 0 0 0 1.25 4.375v10.417A2.718 2.718 0 0 0 3.958 17.5h12.084a2.718 2.718 0 0 0 2.708-2.708v-6.25a1.885 1.885 0 0 0-1.875-1.875h-.625V4.375A1.885 1.885 0 0 0 14.375 2.5H3.125Zm0 1.25h11.25c.353 0 .625.272.625.625v2.814a.626.626 0 0 0 0 .203v6.983a.624.624 0 1 0 1.25 0V7.917h.625c.353 0 .625.272.625.625v6.25a1.45 1.45 0 0 1-1.458 1.458H3.958A1.45 1.45 0 0 1 2.5 14.792V4.375c0-.353.272-.625.625-.625Zm1.25 2.5a.625.625 0 1 0 0 1.25h8.75a.625.625 0 1 0 0-1.25h-8.75Zm0 3.333a.625.625 0 0 0-.625.625v2.917a.625.625 0 0 0 .625.625h2.917a.625.625 0 0 0 .625-.625v-2.917a.625.625 0 0 0-.625-.625H4.375Zm5.417 0a.625.625 0 1 0 0 1.25h3.333a.624.624 0 1 0 0-1.25H9.792ZM5 10.833h1.667V12.5H5v-1.667ZM9.792 12.5a.624.624 0 1 0 0 1.25h3.333a.624.624 0 1 0 0-1.25H9.792Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPost;
