import styled from '@xstyled/styled-components';
import React, { useLayoutEffect } from 'react';

import { Button } from '../../../common/components/Button';
import { Icon } from '../../../common/components/Icon';
import { LoadingSpinner } from '../../../common/components/LoadingSpinner';
import { Modal } from '../../../common/components/Modal';
import { usePreviousBoolean } from '../../../common/hooks/usePreviousBoolean';

export interface VideoLayoutProps {
  title: string;
  prompt: React.ReactNode;
  customLayoutFooter?: React.ReactNode;
  hidden?: boolean;
  isOpen: boolean;
  children?: React.ReactNode;
  onDismiss: () => void;
  onVideoModalOpen?: () => void;
  progressText?: React.ReactNode;
  spinner?: boolean;
}

const Container = styled.div`
  position: relative;
`;

export const Visibility = styled.div<{ hidden: boolean }>`
  opacity: ${(p) => (p.hidden ? 0 : 1)};
`;

const SpinnerPositioner = styled.div`
  height: 14;
`;

const StyledButton = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  margin-left: 8;
  margin-top: 8;
  width: 9;
  height: 9;
`;

export const VideoLayout: React.FC<VideoLayoutProps> = ({
  children,
  customLayoutFooter,
  isOpen,
  onDismiss,
  onVideoModalOpen,
  spinner,
}) => {
  const previousIsOpen = usePreviousBoolean(isOpen);

  useLayoutEffect(() => {
    if (!previousIsOpen && isOpen && onVideoModalOpen) {
      onVideoModalOpen();
    }
  }, [isOpen, previousIsOpen, onVideoModalOpen]);

  const actionsEl = spinner ? (
    <SpinnerPositioner>
      <LoadingSpinner size="4" />
    </SpinnerPositioner>
  ) : undefined;

  return (
    <Modal
      ariaLabel="Video recorder"
      onDismiss={onDismiss}
      isOpen={isOpen}
      showClose={false}
      responsiveVideo={true}
      actionsEl={actionsEl}
      center={true}
    >
      <Container>
        <StyledButton
          label={undefined}
          variant="ghost"
          leftElement={<Icon name="close" size="5" />}
          onClick={onDismiss}
        />
        {children}
      </Container>
      {customLayoutFooter}
    </Modal>
  );
};
