import * as React from "react";

function HelloTemplateCelebrate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.4 1.8S4.8 4.116 4.8 5A1.6 1.6 0 108 5c0-.884-1.6-3.2-1.6-3.2zm5.6 0S10.4 4.116 10.4 5a1.6 1.6 0 103.2 0c0-.884-1.6-3.2-1.6-3.2zm5.6 0S16 4.116 16 5a1.6 1.6 0 103.2 0c0-.884-1.6-3.2-1.6-3.2zM4.8 8.2v3.316c-1.372.36-2.4 1.604-2.4 3.084 0 .8.309 1.526.8 2.09v3.51a1.6 1.6 0 001.6 1.6h14.4a1.6 1.6 0 001.6-1.6v-3.51a3.17 3.17 0 00.8-2.09c0-1.48-1.027-2.724-2.4-3.084V8.2H16v3.2h-2.4V8.2h-3.2v3.2H8V8.2H4.8zm.8 4.8h12.8c.894 0 1.6.707 1.6 1.6 0 .893-.706 1.6-1.6 1.6-.41 0-.772-.155-1.064-.417L16.8 15.3l-.535.483a1.568 1.568 0 01-2.129 0L13.6 15.3l-.535.483a1.568 1.568 0 01-2.129 0L10.4 15.3l-.536.483a1.568 1.568 0 01-2.128 0L7.2 15.3l-.536.483A1.568 1.568 0 015.6 16.2c-.893 0-1.6-.707-1.6-1.6 0-.893.707-1.6 1.6-1.6z"
        fill="currentColor"
      />
    </svg>
  );
}

export default HelloTemplateCelebrate;
