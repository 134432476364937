import * as React from "react";
import { SVGProps } from "react";
const SvgCasting = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.958 3.333a2.301 2.301 0 0 0-2.291 2.292v.417a.625.625 0 1 0 1.25 0v-.417c0-.583.459-1.042 1.041-1.042h12.084c.582 0 1.041.46 1.041 1.042v8.75c0 .583-.459 1.042-1.041 1.042h-3.75a.624.624 0 0 0-.242 1.205c.077.03.159.046.242.045h3.75a2.301 2.301 0 0 0 2.291-2.292v-8.75a2.301 2.301 0 0 0-2.291-2.292H3.958Zm-.241 4.584a6.736 6.736 0 0 0-1.565.17.625.625 0 1 0 .28 1.218 5.446 5.446 0 0 1 5.118 1.496 5.6 5.6 0 0 1 1.615 3.831c.01.43-.03.86-.117 1.282a.625.625 0 1 0 1.224.255c.107-.515.155-1.04.143-1.564a6.86 6.86 0 0 0-1.976-4.682 6.711 6.711 0 0 0-4.722-2.006Zm-.12 2.916a3.954 3.954 0 0 0-1.537.29.624.624 0 1 0 .47 1.158 2.703 2.703 0 0 1 1.052-.198c.682.009 1.357.27 1.88.792.56.56.82 1.298.789 2.03a2.702 2.702 0 0 1-.196.9.625.625 0 1 0 1.159.472 3.954 3.954 0 0 0-.869-4.286 3.95 3.95 0 0 0-2.747-1.158Zm-.264 2.917a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCasting;
