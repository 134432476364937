export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts?.pop()?.split(';').shift();
};

export const setCookie = (
  name: string,
  value: string | boolean,
  expires = 30 * 24 * 60 * 60
) => {
  document.cookie = `${name}=${value}; max-age=${expires}; path=/;`;
};

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
