import { useApolloClient } from '@apollo/client';

import {
  refetchOrganizationByShortIdQuery,
  refetchViewerQuery,
  refetchVisitedPublicOrganizationsQuery,
  useJoinOrganizationMutation,
  ViewerDocument,
  ViewerQuery,
  ViewerQueryVariables,
} from '../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { AppMachineOrganization } from '../../common/machine/appMachine';

export const useJoinOrganization = ({
  organization,
}: {
  organization: AppMachineOrganization;
}) => {
  const { viewer } = useViewer();
  const { setAndViewOrganization } = useOptionalOrganization();
  const client = useApolloClient();

  const [mutateJoinOrganization, { error, loading }] =
    useJoinOrganizationMutation({
      refetchQueries: [
        refetchViewerQuery(),
        refetchOrganizationByShortIdQuery({
          shortId: organization.shortId,
        }),
        ...(viewer
          ? [
              refetchVisitedPublicOrganizationsQuery({
                userId: viewer.id,
              }),
            ]
          : []),
      ],
      fetchPolicy: 'network-only',
      awaitRefetchQueries: true,
      onCompleted: () => {
        client
          .query<ViewerQuery, ViewerQueryVariables>({
            query: ViewerDocument,
          })
          .then(() => {
            setAndViewOrganization(organization);
          });
      },
    });

  return {
    mutateJoinOrganization,
    loading,
    error,
  };
};
