import { yupResolver } from '@hookform/resolvers/yup';
import { getEmojiDataFromNative } from 'emoji-mart';
import data from 'emoji-mart/data/all.json';
import { PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import * as yup from 'yup';

import { Form } from '../../../common/components/Form';

function getRandomEmoji() {
  const communityEmojis = [
    '😃',
    '😊',
    '😎',
    '🤔',
    '🙌',
    '👏',
    '🎉',
    '🔥',
    '💯',
    '🌟',
    '👍',
    '🤗',
    '👋',
    '🤝',
    '👥',
    '🌈',
    '💬',
    '📣',
    '📚',
    '🔔',
    '🛑',
    '🚀',
    '💡',
    '🎯',
    '🚨',
    '✨',
    '💻',
    '📅',
    '🎈',
    '🎊',
    '📌',
    '📎',
    '📰',
    '📸',
    '🎥',
    '📡',
    '🕵️‍♂️',
    '🔍',
    '🚧',
    '🛠️',
    '🚀',
    '🌐',
    '🌎',
    '🌍',
    '🌙',
    '⭐',
    '🍀',
    '🏆',
    '🎮',
  ];

  const randomIndex = Math.floor(Math.random() * communityEmojis.length);
  return getEmojiDataFromNative(communityEmojis[randomIndex], 'apple', data)
    .native;
}

// Example usage

// We need to use `title` rather than `name` here because it'll be written to the DOM
// Inputs with name="name" or placeholder="... name ..." autofill in Safari
// https://linear.app/frond/issue/FR-3891/group-title-field-triggers-contact-dropdown-in-safari
const GroupCreateFormSchema = yup
  .object({
    title: yup.string().trim().required(),
    emoji: yup.string().required(),
    description: yup.array().nullable(),
    coverImageId: yup.string().nullable(),
    isPrivate: yup.boolean(),
  })
  .defined();

export type GroupCreateFormData = yup.InferType<typeof GroupCreateFormSchema>;

export const GroupCreateForm: React.FC<
  PropsWithChildren<{
    isPrivate?: boolean;
    onSubmit: (data: GroupCreateFormData) => void;
  }>
> = ({ onSubmit, children, isPrivate = false }) => {
  const form = useForm<GroupCreateFormData>({
    resolver: yupResolver(GroupCreateFormSchema),
    mode: 'all',
    defaultValues: {
      isPrivate,
      emoji: getRandomEmoji(),
    },
  });

  useHotkeys(
    'cmd+enter',
    () => {
      form.handleSubmit(onSubmit)();
    },
    {
      enableOnFormTags: ['INPUT', 'TEXTAREA'],
    }
  );

  return (
    <Form<GroupCreateFormData>
      {...form}
      onSubmit={onSubmit}
      flex={1}
      display="flex"
      flexDirection="column"
    >
      {children}
    </Form>
  );
};
