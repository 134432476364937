import { useFormContext } from 'react-hook-form';

import { Button } from '../../../../common/components/Button';

export const GroupFormSubmitButton: React.FC<{
  loading: boolean;
  label: string;
}> = ({ loading, label }) => {
  const form = useFormContext();

  return (
    <Button
      disabled={!form.formState.isValid || !form.formState.isDirty}
      alignSelf="flex-end"
      mt="auto"
      type="submit"
      label={label}
      loading={loading}
    />
  );
};
