import { avatars, getRandomAvatarBgColor } from '@frond/shared';
import {
  ListboxButton,
  ListboxInput,
  ListboxList,
  ListboxOption,
  ListboxPopover,
} from '@reach/listbox';
import styled, { x } from '@xstyled/styled-components';
import { th } from '@xstyled/system';
import { FC, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import analytics from '../../common/utils/analytics';
import { bottomCenter } from '../utils/position';
import { Avatar } from './Avatar';
import { Icon } from './Icon';

const StyledListboxList = styled(ListboxList)`
  padding: 2;
  display: grid;
  background-color: gray.100;
  border-radius: lg;
  gap: 2;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;

  @media (max-width: lg) {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: gray.500;
    box-sizing: border-box;
    border-radius: lg lg 0 0;
  }
`;

const StyledListboxInput = styled(ListboxInput)`
  [data-reach-listbox-button] {
    display: flex;
  }
`;

const StyledListboxButton = styled(ListboxButton)`
  border: none;
  background: transparent;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  transition-property: ${th('transitions.property.colors')};
  transition-duration: ${th('transitions.duration.faster')};
  transition-timing-function: linear;
  color: gray.300;

  &:hover {
    box-shadow: 0 0 0 1px ${th('colors.brand.200')};
    color: brand.200;
    cursor: pointer;
  }

  &[aria-expanded='true'] {
    color: brand.200;
    box-shadow: 0 0 0 1px ${th('colors.brand.200')};
  }
`;

const StyledListboxOption = styled(ListboxOption)`
  border-radius: full;
  border: 2px solid transparent;
  cursor: pointer;
  padding: 10px;
  transition-property: ${th('transitions.property.colors')};
  transition-duration: ${th('transitions.duration.faster')};
  transition-timing-function: linear;

  &[data-current-selected] {
    background-color: brand.50;
    border-color: brand.300;
  }

  &[data-current-nav] {
    background-color: transparent;
    border-color: transparent;
  }

  &:hover {
    background-color: brand.50;
    border-color: brand.300;
  }
`;

const StyledListboxPopover = styled(ListboxPopover)`
  box-sizing: border-box;
  padding: 0;
  border: none;
  border-radius: lg;
  box-shadow: sm;

  margin-top: 3;
  left: -9.25rem;

  z-index: popover;

  &:focus-within {
    outline: 0;
    box-shadow: sm;
  }

  @media (max-width: lg) {
    height: 100vh;
    width: 100vh;
    margin-top: 0;
    border-radius: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const AVATAR_NAME = 'avatar';
const AVATAR_BG_COLOR_NAME = 'avatarBgColor';

export const AvatarPicker: FC<{ size?: 'lg-2' | 'lg-5' }> = ({
  size = 'lg-2',
}) => {
  const popoverOpen = useRef(false);
  const { register, unregister, watch, setValue } = useFormContext();
  const selectValue = watch(AVATAR_NAME);
  const bgColor = watch(AVATAR_BG_COLOR_NAME);

  useEffect(() => {
    register(AVATAR_NAME);
    register(AVATAR_BG_COLOR_NAME);
    return () => {
      unregister(AVATAR_NAME);
      unregister(AVATAR_BG_COLOR_NAME);
    };
  }, [register, unregister]);

  const sendIsOpenEvent = (isExpanded: boolean): void => {
    if (isExpanded && !popoverOpen.current) {
      analytics.logEvent(analytics.events.AVATAR_PICKER_OPEN);
    }
    popoverOpen.current = isExpanded;
  };

  return (
    <x.div position="relative">
      <StyledListboxInput
        name={AVATAR_NAME}
        value={selectValue || 'panda'}
        onChange={(avatar) => {
          setValue(AVATAR_NAME, avatar, {
            shouldDirty: true,
            shouldValidate: true,
          });
          setValue(AVATAR_BG_COLOR_NAME, getRandomAvatarBgColor(), {
            shouldDirty: true,
            shouldValidate: true,
          });
          analytics.logEvent(analytics.events.AVATAR_PICKER_SELECT);
        }}
      >
        {({ isExpanded }) => {
          sendIsOpenEvent(isExpanded);
          return (
            <>
              <StyledListboxButton
                style={{
                  borderRadius: size === 'lg-2' ? 20 : 8,
                }}
              >
                <Avatar avatar={selectValue} bgColor={bgColor} size={size} />
              </StyledListboxButton>
              <StyledListboxPopover position={bottomCenter()} portal={false}>
                <StyledListboxList>
                  {avatars.map((avatar) => (
                    <StyledListboxOption key={avatar} value={avatar}>
                      <Icon name={avatar} size="10" />
                    </StyledListboxOption>
                  ))}
                </StyledListboxList>
              </StyledListboxPopover>
            </>
          );
        }}
      </StyledListboxInput>
    </x.div>
  );
};
