import * as React from "react";

function SvgSheep(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 36.875a4.375 4.375 0 01-4.376-3.938 4.375 4.375 0 01-5.575-4.85 4.375 4.375 0 01-2.13-7.775 4.375 4.375 0 01-1.288-1.656.625.625 0 01.687-.869c.268.052.545.037.806-.043l1.95-.557-1.325-1.33a1.906 1.906 0 00-1.25-.538.626.626 0 01-.462-.988 4.375 4.375 0 012.769-1.756 4.375 4.375 0 015.862-5.863 4.375 4.375 0 018.606 0 4.375 4.375 0 015.863 5.863 4.375 4.375 0 012.769 1.756.626.626 0 01-.407.988 1.906 1.906 0 00-1.193.537l-1.363 1.331 1.938.55c.259.079.533.094.8.044a.624.624 0 01.687.869 4.375 4.375 0 01-1.287 1.656 4.375 4.375 0 01-2.132 7.775 4.374 4.374 0 01-5.575 4.856A4.375 4.375 0 0120 36.875z"
        fill="#212121"
      />
      <path
        d="M16.294 31.344a.627.627 0 01.625.712 3.127 3.127 0 003.087 3.608 3.125 3.125 0 003.088-3.608.625.625 0 01.937-.625 3.125 3.125 0 004.719-2.681c0-.358-.064-.714-.188-1.05a.625.625 0 01.625-.837l.188.012a3.125 3.125 0 001.25-5.988.625.625 0 010-1.143 3.124 3.124 0 001.063-.756l-.15-.038-2.957-.825a.626.626 0 01-.268-1.044L30.392 15a3.07 3.07 0 01.857-.625 3.125 3.125 0 00-2.15-.625.625.625 0 01-.531-1.044 3.125 3.125 0 00-4.413-4.412.625.625 0 01-1.031-.513 2.639 2.639 0 000-.281 3.125 3.125 0 10-6.25 0 2.443 2.443 0 000 .281.625.625 0 01-1.044.532 3.125 3.125 0 00-4.412 4.412.625.625 0 01-.513 1.025h-.281c-.676 0-1.334.22-1.875.625.317.158.605.37.85.625l2.088 2.087a.626.626 0 01-.27 1.038l-2.962.844-.137.037c.297.314.657.564 1.056.732a.625.625 0 010 1.143 3.125 3.125 0 001.25 5.994h.169a.625.625 0 01.625.837 3.042 3.042 0 00-.169 1.038 3.124 3.124 0 004.725 2.681.626.626 0 01.319-.087z"
        fill="#EEE"
      />
      <path
        d="M27.5 15.938a.625.625 0 10-1.25 0 1.67 1.67 0 01-3.332 0 .625.625 0 00-1.25 0 1.67 1.67 0 01-3.33 0 .625.625 0 10-1.25 0 1.67 1.67 0 01-3.332 0 .625.625 0 00-1.25 0c.002.697.27 1.368.75 1.874l1.694 7.438a4.481 4.481 0 004.375 3.5h1.33a4.487 4.487 0 004.376-3.475l1.844-7.587a2.725 2.725 0 00.625-1.75z"
        fill="#212121"
      />
      <path
        d="M23.843 25a3.244 3.244 0 01-3.169 2.5h-1.331a3.238 3.238 0 01-3.175-2.5l-1.438-6.337c.226.055.456.085.688.087a2.938 2.938 0 002.294-1.094 2.95 2.95 0 004.581 0 2.937 2.937 0 002.288 1.094c.255-.003.51-.038.756-.106L23.843 25z"
        fill="#9E9E9E"
      />
      <path
        d="M21.25 25.625h-2.5a.625.625 0 110-1.25h2.5a.625.625 0 010 1.25zm-3.75-3.75a.625.625 0 110-1.25.625.625 0 010 1.25zm5 0a.625.625 0 110-1.25.625.625 0 010 1.25zm9.881-2.806c-.285-.001-.57-.043-.843-.125l-2.957-.82a.624.624 0 01-.268-1.043L30.392 15a3.125 3.125 0 011.982-.9c.364-.03.73.009 1.081.112L36.42 15a.626.626 0 01.269 1.044l-2.082 2.08a3.075 3.075 0 01-1.712.863c-.168.044-.34.071-.513.082z"
        fill="#212121"
      />
      <path
        d="M29.943 17.188l1.938.55c.26.078.533.093.8.043.402-.05.777-.23 1.069-.512l1.337-1.338-1.962-.55a1.75 1.75 0 00-.625-.069c-.451.032-.877.223-1.2.538l-1.357 1.338z"
        fill="#9E9E9E"
      />
      <path
        d="M7.625 19.063a2.9 2.9 0 01-.544-.05 3.056 3.056 0 01-1.688-.888l-2.087-2.056A.625.625 0 013.58 15l2.962-.844a2.968 2.968 0 011.088-.106 3.125 3.125 0 011.975.95l2.087 2.087a.626.626 0 01-.275 1.038l-2.962.844a2.92 2.92 0 01-.831.093z"
        fill="#212121"
      />
      <path
        d="M4.943 15.937L6.25 17.27a1.82 1.82 0 001.012.512c.286.062.583.049.863-.037l1.95-.557-1.325-1.33a1.906 1.906 0 00-1.25-.538 1.78 1.78 0 00-.625.062l-1.932.556z"
        fill="#9E9E9E"
      />
    </svg>
  );
}

export default SvgSheep;
