import { IS_SSR } from '../../config/constants';

export const VIDEO_DEFAULT_ASPECT_RATIO = 1.33;
export const VIDEO_SQUARE_ASPECT_RATIO = 1;
export const VIDEO_LANDSCAPE_ASPECT_RATIO = 1.77;
export const VIDEO_PORTRAIT_ASPECT_RATIO = 0.75;

export const VIDEO_CAPTURE_STREAM_CONSTRAINTS = {
  audio: {},
  video: {
    aspectRatio: VIDEO_SQUARE_ASPECT_RATIO,
    resizeMode: 'none',
  },
};

export const VIDEO_CAPTURE_TIMESLICE = 500;
export const VIDEO_STATUS_POLL_INTERVAL = 1000;
export const VIDEO_MAX_LENGTH = 180;

export const VIDEO_NATIVE_CAPTURE_SUPPORTED =
  !IS_SSR && document.createElement('input').capture != undefined;
