import { useTranslation } from 'next-i18next';

import {
  GroupWithMembersFragment,
  refetchGroupQuery,
  refetchHomeFeedQuery,
  useToggleGroupIncludePostsOnHomeMutation,
} from '../../../../../../generated/types-and-hooks';
import { POSTS_PER_PAGE } from '../../../../../config/constants';
import { Switch } from '../../../../common/components/Switch';
import { Text } from '../../../../common/components/Text';
import {
  GroupEditSetting,
  GroupEditSettingPositioning,
} from './GroupEditSetting';

export const GroupEditSettingIncludePostsOnHome: React.FC<
  {
    group: GroupWithMembersFragment;
  } & GroupEditSettingPositioning
> = ({ group, ...position }) => {
  const { t } = useTranslation('groups');
  const [toggleGroupIncludePostsOnHome] =
    useToggleGroupIncludePostsOnHomeMutation({
      optimisticResponse: {
        __typename: 'Mutation',
        toggleGroupIncludePostsOnHome: {
          __typename: 'Group',
          id: group.id,
          includePostsOnHome: !group.includePostsOnHome,
        },
      },
      refetchQueries: [
        refetchHomeFeedQuery({
          organizationId: group.organization.id,
          first: POSTS_PER_PAGE,
        }),
        refetchGroupQuery({
          id: group.id,
        }),
      ],
    });

  const handleChange = () => {
    toggleGroupIncludePostsOnHome({
      variables: {
        input: {
          groupId: group.id,
        },
      },
    });
  };

  return (
    <GroupEditSetting
      {...position}
      title={t('groups.include_posts_on_home')}
      toggle={
        <Switch
          checked={group.includePostsOnHome}
          onCheckedChange={handleChange}
        />
      }
    >
      <Text variant="md" color="gray.300" pr={16}>
        {t('groups.include_posts_on_home_explanation')}
      </Text>
    </GroupEditSetting>
  );
};
