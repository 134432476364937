import * as React from "react";

function SvgMicNo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9.414L1.293 2.707l1.414-1.414 20 20-1.414 1.414-4.387-4.387A7.96 7.96 0 0113 19.938V21h3v2H8v-2h3v-1.062A8.001 8.001 0 014 12v-2h2v2a6 6 0 009.477 4.89l-1.445-1.444A4 4 0 018 12V9.414zm4.518 4.518A2 2 0 0110 12v-.586l2.518 2.518zM14 5v4.786l2 2V5a4 4 0 00-7.855-1.07L10 5.786V5a2 2 0 114 0zm5.358 10.144l-1.567-1.568c.136-.502.21-1.03.21-1.576v-2h2v2c0 1.116-.23 2.18-.643 3.144z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMicNo;
