import * as React from "react";
import { SVGProps } from "react";
const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.613 5.411a.625.625 0 0 0-.43.189L1.016 9.766a.625.625 0 0 0 0 .884l4.167 4.167a.623.623 0 0 0 1.03-.197.625.625 0 0 0-.146-.687l-3.1-3.1h15.575a.624.624 0 1 0 0-1.25H2.967l3.1-3.1a.625.625 0 0 0-.454-1.072Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowLeft;
