import styled, { x } from '@xstyled/styled-components';
import { system, SystemProps } from '@xstyled/system';
import {
  BaseEmoji,
  Emoji as EmojiMart,
  getEmojiDataFromNative,
} from 'emoji-mart';
import React from 'react';

import { useEmojiData } from '../hooks/useEmojiData';
import { Text } from './Text';

export type EmojiSizes =
  | 15
  | 16
  | 18
  | 20
  | 22
  | 24
  | 28
  | 32
  | 36
  | 38
  | 40
  | 44
  | 48
  | 64
  | 80
  | 100
  | 120;

export interface EmojiProps extends SystemProps {
  emojiUnicode: string;
  size: EmojiSizes;
}

const formatCdnUrl = (emojiData: BaseEmoji) => {
  return `https://cdn.jsdelivr.net/gh/iamcal/emoji-data@master/img-apple-160/${emojiData.unified}.png`;
};

const StyledEmoji = styled(EmojiMart)`
  ${system}
`;

/**
 * Emoji component that wraps emoji-mart
 *
 * - At 32px display size or less, uses emoji-mart's Apple sprite sheet
 * - Above 32px uses emoji-mart's data to provide individual CDN URLs of larger assets
 */
export const EmojiSprite: React.FC<EmojiProps & SystemProps> = ({
  emojiUnicode,
  size,
  ...props
}) => {
  const { data } = useEmojiData();
  const emojiData = getEmojiDataFromNative(emojiUnicode, 'apple', data as any);

  if (!emojiData)
    return (
      <x.div w={`${size}px`} h={`${size}px`} mb={0.5}>
        <Text fontSize={size} lineHeight="normal">
          {emojiUnicode}
        </Text>
      </x.div>
    );

  if (size >= 32) {
    return (
      <x.img
        width={size}
        height={size}
        alt={emojiData.name}
        src={formatCdnUrl(emojiData)}
        draggable={false}
        {...props}
      />
    );
  }

  /**
   * Below 36px display size use the sprite sheet
   */
  return (
    <StyledEmoji
      size={size}
      emoji={emojiData}
      set="apple"
      skin={emojiData.skin || 1}
      w={`${size}px`}
      h={`${size}px`}
      {...props}
    />
  );
};
