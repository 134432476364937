import { sendGAEvent } from '../../../analytics/GoogleAnalytics';
import { sendMatomoEvent } from '../../../analytics/MatomoAnalytics';
import { sendUmamiEvent } from '../../../analytics/UmamiAnalytics';
import * as events from './events';

export type AnalyticEventName = (typeof events)[keyof typeof events];

export const logEvent = (
  name: AnalyticEventName,
  data?: Record<string, unknown>
): void => {
  sendMatomoEvent(name);
  sendUmamiEvent(name, data || {});
  sendGAEvent(name, data || {});
};

export default {
  logEvent,
  events,
};
