import {
  FieldError,
  FieldErrors,
  FieldValues,
  FormState,
} from 'react-hook-form';
import { upperCaseFirst } from 'upper-case-first';

/**
 * Returns a formatted error for a field from it's form state
 */
export const extractError = <FormData extends FieldValues>(
  formState: FormState<FormData>,
  field: keyof FieldErrors<FormData>
): string | undefined => {
  const error = formState.errors[field] as FieldError;

  if (error && 'message' in error && error.message) {
    return upperCaseFirst(error.message);
  }
};
