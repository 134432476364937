import * as React from "react";
import { SVGProps } from "react";
const SvgAirplay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="Airplay_svg__a"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={3}
      width={17}
      height={15}
    >
      <path
        d="m9.943 11.448-4.917 5.548c-.126.252 0 .504.252.504h9.835c.252 0 .378-.378.252-.504l-4.917-5.548c0-.126-.126-.126-.252-.126s-.253 0-.253.126ZM4.27 3c-.757 0-1.01.126-1.387.252a1.52 1.52 0 0 0-.63.63C2.125 4.262 2 4.514 2 5.27v6.304c0 .883.126 1.135.252 1.387s.378.504.63.63c.253.126.631.253 1.388.253h1.89l.757-.883h-2.9c-.378 0-.63 0-.756-.126 0 0-.126-.126-.252-.379-.126-.126-.126-.252-.126-.63V5.017c0-.378 0-.63.126-.756s.126-.252.378-.378c.126-.126.252-.126.756-.126h12.23c.38 0 .631 0 .757.126.127.126.253.126.379.378.126.126.126.252.126.756v6.81c0 .377 0 .63-.126.756s-.126.252-.379.378c-.126.126-.252.126-.756.126h-2.9l.756.883h1.892c.882 0 1.134-.127 1.387-.253a1.52 1.52 0 0 0 .63-.63c.126-.378.252-.757.252-1.513V5.27c0-.883-.126-1.135-.252-1.387a1.52 1.52 0 0 0-.63-.63c-.253-.127-.63-.253-1.387-.253H4.27Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#Airplay_svg__a)">
      <path d="M20.66.73H-.27v19.166h20.93V.73Z" fill="currentColor" />
    </g>
  </svg>
);
export default SvgAirplay;
