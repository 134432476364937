import { routes, theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { PropsWithChildren } from 'react';

import { useOrganizationAdministratorsQuery } from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useIsViewerAdmin } from '../../auth/hooks/useViewer';
import { Button } from '../../common/components/Button';
import { Heading } from '../../common/components/Heading';
import { Icon } from '../../common/components/Icon';
import { ShareLink } from '../../common/components/ShareLink';
import { Text } from '../../common/components/Text';
import { Users } from '../../common/components/Users';
import { useDateFns } from '../../common/hooks/useDateFns';
import { AppMachineOrganization } from '../../common/machine/appMachine';
import { OrganizationAdminSubscriptionLabel } from '../../pricing/components/AdminSubscriptionLabel';
import { OrganizationAvatar } from './OrganizationAvatar';

export type OrganizationDetailsCardProps = {
  organization: NonNullable<AppMachineOrganization>;
  isAdmin?: boolean;
};

export const OrganizationDetailsCard: React.FC<
  PropsWithChildren<OrganizationDetailsCardProps>
> = ({ organization, isAdmin, children }) => {
  const { t } = useTranslation();
  const { format } = useDateFns();
  return (
    <>
      <x.div
        border="default"
        borderColor="transparent"
        borderRadius={{ _: 0, sm: 'md-lg md-lg none none' }}
        boxSizing="border-box"
        backgroundColor="gray.100"
        h={30}
        px={{ _: 4, 'sm-2': 6 }}
        position="relative"
      >
        {organization.adminSubscription && (
          <x.div
            position="absolute"
            right={theme.sizes[6]}
            top={theme.sizes[6]}
          >
            <OrganizationAdminSubscriptionLabel />
          </x.div>
        )}
        <x.div
          display="inline-block"
          border="7px solid"
          borderColor="white"
          borderRadius="md-lg"
          backgroundColor="white"
          marginTop="80px"
        >
          <OrganizationAvatar organization={organization} size="m-5" />
        </x.div>
      </x.div>
      <x.div
        backgroundColor="gray.50"
        display="flex"
        flexDirection="column"
        spaceY={3}
        px={{ _: 4, 'sm-2': 6 }}
        pb={6}
        pt={isAdmin ? 6 : 18}
      >
        {isAdmin && (
          <x.div display="flex" w="full" justifyContent="flex-end">
            <Button
              label={t('edit_community_details')}
              variant="secondary"
              justifySelf="flex-end"
              href={routes.settings
                .organization(organization.shortId)
                .details()}
            />
          </x.div>
        )}
        <x.div display="flex" justifyContent="flex-start" mb={4}>
          <Heading variant="xl">{organization.name}</Heading>
        </x.div>
        {organization.description && (
          <Text variant="md" color="gray.400">
            {organization.description}
          </Text>
        )}
        {children}
      </x.div>
      <x.div
        border="default"
        borderColor="transparent"
        borderRadius={{ _: 0, 'sm-2': 'none none lg lg' }}
        backgroundColor="gray.100"
        boxSizing="border-box"
        p={{ _: '6 4', 'sm-2': 6 }}
      >
        <x.div
          display="flex"
          justifyContent="space-between"
          flexDirection={{
            _: 'column',
            sm: 'row',
          }}
          spaceY={{
            _: 1,
            sm: 0,
          }}
        >
          <x.div
            display="flex"
            flexDirection={{
              _: 'column',
              sm: 'row',
            }}
            spaceX={{
              _: 0,
              sm: 4,
            }}
            spaceY={{
              _: 1,
              sm: 0,
            }}
          >
            <x.div display="flex" alignItems="center" spaceX={2}>
              <Icon name="members" size="5" />
              <Text variant="sm-medium">
                {t('member_count', { count: organization.numMembers })}
              </Text>
            </x.div>
            <x.div
              display="flex"
              flexDirection="row"
              alignItems="center"
              spaceX={2}
            >
              <Icon name="groups" size="5" />
              <Text variant="sm-medium">
                {t('group_count', { count: organization.numGroups })}
              </Text>
            </x.div>
          </x.div>
          {organization.createdAt && (
            <x.div
              display="flex"
              flexDirection="row"
              alignItems="center"
              spaceX={2}
            >
              <Icon name="calendar" size="5" />
              <Text variant="sm-medium">
                {t('created')}{' '}
                {format(new Date(organization.createdAt), 'MMM d, y')}
              </Text>
            </x.div>
          )}
        </x.div>
      </x.div>
    </>
  );
};

export const OrganizationDetailsAdminsCard: React.FC<{
  onClickUsers?: () => void;
}> = ({ onClickUsers }) => {
  const { t } = useTranslation();
  const { organization } = useOrganization();
  const isAdmin = useIsViewerAdmin(organization);
  const { data: adminsQuery } = useOrganizationAdministratorsQuery({
    variables: { organizationId: organization.id },
  });

  const admins = adminsQuery?.organization?.administrators;

  return (
    <>
      <OrganizationDetailsCard organization={organization} isAdmin={isAdmin}>
        {admins && (
          <x.div display="flex" flexDirection="column" pt={2} spaceY="2">
            <Text variant="sm-semibold" color="gray.300">
              {t('admins')}
            </Text>
            <x.div onClick={onClickUsers}>
              <Users
                users={admins}
                limit={15}
                variant="medium"
                showNameTooltips
              />
            </x.div>
            <x.div pt={5}>
              <ShareLink
                label={t('community_link')}
                route={routes.groups.organization(organization.shortId).root()}
                variant="dark"
                organization={organization}
              />
            </x.div>
          </x.div>
        )}
      </OrganizationDetailsCard>
    </>
  );
};
