import { routes, theme } from '@frond/shared';
import { Trans } from 'next-i18next';
import { FC } from 'react';

import { AdminSubscription } from '../../../../generated/graphql-request-api-sdk';
import { Organization } from '../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { useIsViewerAdmin } from '../../auth/hooks/useViewer';
import { NextLink } from '../../common/components/NextLink';
import { Text } from '../../common/components/Text';
import { Tooltip } from '../../common/components/Tooltip';
import { AdminPlanName } from './AdminPlanName';

const AdminSubscriptionLabel: FC<{
  organization: Pick<Organization, 'name' | 'shortId'> & {
    adminSubscription?: Pick<AdminSubscription, 'subscriptionPlan'> | null;
  };
  variant?: 'admin' | 'default';
}> = ({ organization, variant }) => {
  const id = organization.adminSubscription?.subscriptionPlan;

  const color = id ? 'white' : 'gray.300';
  const backgroundColor = id ? 'gray.500' : 'gray.200';

  const tooltip = (
    <Tooltip
      aria-label={`Subscription plan`}
      label={
        <Text variant="sm-semibold" textAlign="center">
          <Trans i18nKey="admin_plan_current" ns="common">
            {organization.name} is on the <AdminPlanName adminPlanId={id} />{' '}
            plan
          </Trans>
        </Text>
      }
    >
      <Text
        variant="xs-semibold"
        color={color}
        backgroundColor={backgroundColor}
        px={theme.sizes['1.5']}
        py={theme.sizes['0.5']}
        borderRadius="sm"
        as="span"
      >
        <AdminPlanName adminPlanId={id} />
      </Text>
    </Tooltip>
  );

  return variant === 'admin' ? (
    <NextLink
      href={routes.settings.organization(organization.shortId).plans()}
      passHref
    >
      {tooltip}
    </NextLink>
  ) : (
    tooltip
  );
};

export const OrganizationAdminSubscriptionLabel: FC = () => {
  const { organization } = useOptionalOrganization();
  const isAdmin = useIsViewerAdmin(organization);

  if (!organization) {
    return null;
  }

  return (
    <AdminSubscriptionLabel
      organization={organization}
      variant={isAdmin ? 'admin' : 'default'}
    />
  );
};
