import { routes } from '@frond/shared';
import { useSelector } from '@xstate/react';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import { AppContext } from '../../common/machine/appContext';
import { AppMachineOrganization } from '../../common/machine/appMachine';

/**
 * Returns nullable organization and utils to set organization.
 */
export const useOptionalOrganization = () => {
  const { appService } = useContext(AppContext);
  const router = useRouter();

  const organization = useSelector(
    appService,
    (state) => state.context.organization
  );

  /**
   * Set organization
   */
  const setOrganization = (organization: AppMachineOrganization) => {
    appService.send({
      type: 'SET_ORGANIZATION',
      organization,
    });
  };

  /**
   * Set organization and push route of organization
   */
  const setAndViewOrganization = (organization: AppMachineOrganization) => {
    setOrganization(organization);
    router.push(routes.groups.organization(organization.shortId).feed());
  };

  return {
    organization,
    setOrganization,
    setAndViewOrganization,
  };
};
