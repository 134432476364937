import * as React from 'react';
import { SVGProps } from 'react';

const SvgGroupsFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M6.25 2.5C5.01 2.5 4 3.51 4 4.75v8.75h3.781a1.75 1.75 0 0 1 1.739 1.547 2.498 2.498 0 0 0 4.96 0A1.75 1.75 0 0 1 16.22 13.5H20V4.75c0-1.24-1.01-2.25-2.25-2.25H6.25Zm2 4h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1 0-1.5Zm7.5 0a.75.75 0 1 1 0 1.501.75.75 0 0 1 0-1.5ZM8.25 10h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1 0-1.5Zm7.5 0a.75.75 0 1 1 0 1.501.75.75 0 0 1 0-1.5Zm-12 4.5a.75.75 0 0 0-.75.75v3a3.254 3.254 0 0 0 3.25 3.25h11.5A3.254 3.254 0 0 0 21 18.25v-3a.75.75 0 0 0-.75-.75h-4.031a.75.75 0 0 0-.745.663 3.497 3.497 0 0 1-6.947 0 .75.75 0 0 0-.746-.662H3.75Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgGroupsFilled;
