import * as React from 'react';
import { SVGProps } from 'react';

const SvgSettingsFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M19.57 13.142a1.399 1.399 0 0 1-.57-1.124c0-.443.213-.863.569-1.124l1.647-1.207a.751.751 0 0 0 .268-.842 9.937 9.937 0 0 0-1.993-3.455.752.752 0 0 0-.864-.19l-1.854.816a1.398 1.398 0 0 1-1.258-.07 1.398 1.398 0 0 1-.689-1.054l-.223-2.036a.751.751 0 0 0-.595-.653 10.074 10.074 0 0 0-3.985-.005.75.75 0 0 0-.598.654l-.221 2.023a1.397 1.397 0 0 1-1.947 1.124l-1.864-.82a.75.75 0 0 0-.863.188 9.932 9.932 0 0 0-2.004 3.448.75.75 0 0 0 .268.844l1.636 1.2A1.4 1.4 0 0 1 5 11.984c0 .442-.213.862-.569 1.123l-1.647 1.208a.751.751 0 0 0-.268.842 9.937 9.937 0 0 0 1.993 3.454.75.75 0 0 0 .864.19l1.854-.815a1.398 1.398 0 0 1 1.258.069c.383.22.64.615.689 1.054l.223 2.036a.751.751 0 0 0 .595.654 10.064 10.064 0 0 0 3.984.004.75.75 0 0 0 .598-.654l.222-2.023a1.397 1.397 0 0 1 1.947-1.124l1.865.82a.749.749 0 0 0 .862-.188 9.932 9.932 0 0 0 2.004-3.448.75.75 0 0 0-.268-.844l-1.636-1.2ZM12 15.5a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSettingsFilled;
