import { theme } from '@frond/shared';
import { ThemeProvider } from '@xstyled/styled-components';
import React, { PropsWithChildren } from 'react';

import { GlobalStyle } from './global';

export const BaseThemeProvider: React.FC<PropsWithChildren> = ({
  children,
  ...props
}) => {
  return (
    <ThemeProvider theme={theme} {...props}>
      <>
        <GlobalStyle />
        {children}
      </>
    </ThemeProvider>
  );
};
