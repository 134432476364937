import { getCloudinaryUrl } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import React, { useEffect, useState } from 'react';

import { Image as ImageType } from '../../../../../generated/types-and-hooks';
import { COMMENT_MEDIA_HEIGHT_PX } from '../../../../config/constants';
import { useFileUpload } from '../../hooks/useFileUpload';
import { ProgressBar } from '../ProgressBar';

export const CommentComposerMediaLoader = ({
  file,
  onProcessed,
}: {
  onProcessed: (image: ImageType) => void;
  file: File;
}) => {
  const [uploading, setUploading] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>();
  const [loadedMedia, setLoadedMedia] = useState<ImageType | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { uploadSelectedFile } = useFileUpload({
    name: `user-uploaded-file-${Math.random()}}`,
    onUploadSuccess: (image) => {
      if ('publicId' in image) {
        setImageSrc(
          getCloudinaryUrl({ id: image.publicId, transform: 'q_auto' })
        );
        setLoadedMedia(image);
      }
    },
    onUploadProgress: (percentUploaded) => setUploadProgress(percentUploaded),
    onUploadFailure: () => {
      // TODO: handle error
    },
  });

  useEffect(() => {
    if (file && !uploading) {
      setUploading(true);
      if (file.type.startsWith('image')) {
        uploadSelectedFile(file);
      }
    }
  }, [file, uploadSelectedFile, uploading]);

  return (
    <x.div
      h={COMMENT_MEDIA_HEIGHT_PX}
      w={COMMENT_MEDIA_HEIGHT_PX}
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="gray.50"
      borderRadius="md"
    >
      {imageSrc && loadedMedia ? (
        <x.img
          display="none"
          src={imageSrc}
          crossOrigin="anonymous"
          onLoad={() => {
            onProcessed(loadedMedia);
          }}
        />
      ) : null}

      <x.div textAlign="center" px={6} w="full">
        <ProgressBar
          h={1}
          trackColor="brand.100"
          progressColor="brand.300"
          progress={uploadProgress}
        />
      </x.div>
    </x.div>
  );
};
