import { groupsRoutes } from './groups/routes';
import { helloRoutes } from './hello/routes';
import { featureFaqRoutes, marketingRoutes } from './marketing/routes';
import { settingsRoutes } from './settings/routes';

export const routes = {
  settings: settingsRoutes,
  blog: { index: () => 'https://blog.frond.com' },
  hello: helloRoutes,
  groups: groupsRoutes,
  marketing: marketingRoutes,
  featureFaqRoutes: featureFaqRoutes,
  signin: (callbackUrlPath?: string) =>
    callbackUrlPath
      ? `/auth/signin?callbackUrlPath=${callbackUrlPath}`
      : `/auth/signin`,
  addInvite: (route: string, inviteCode?: string) => {
    if (!inviteCode) return route;
    return `${route}${route.includes('?') ? '&' : '?'}invite=${inviteCode}`;
  },
};
