import * as React from "react";
import { SVGProps } from "react";

const SvgAppGoogleMaps = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#app-google-maps_svg__a)">
      <path
        d="m26.08 8.84-.003.003c.083.232.161.467.228.706-.068-.24-.14-.477-.224-.71Zm-9.395-6.605-7.071 2.93 4.062 4.062a4.249 4.249 0 1 1 6.03 5.988l5.979-5.98.392-.392a9.988 9.988 0 0 0-9.392-6.608Z"
        fill="#4285F4"
      />
      <path
        d="M16.685 16.478a4.25 4.25 0 0 1-3.009-7.251L9.614 5.164a9.968 9.968 0 0 0-2.93 7.07c0 4.407 2.266 7.168 4.93 10 .18.184.328.386.496.575l7.561-7.56a4.234 4.234 0 0 1-2.986 1.23Z"
        fill="#FFBA00"
      />
      <path
        d="M16.685 2.235a10 10 0 0 0-10 10c-.002 1.173.182 2.34.545 3.455L20.08 2.84a9.957 9.957 0 0 0-3.395-.605Z"
        fill="#0066DA"
      />
      <path
        d="M17.586 29.125a21.83 21.83 0 0 1 4.032-6.897c2.864-2.91 5.067-5.587 5.067-9.993a9.96 9.96 0 0 0-.604-3.396L12.11 22.81a18.044 18.044 0 0 1 3.59 6.276.998.998 0 0 0 1.885.04Z"
        fill="#00AC47"
      />
      <path
        opacity={0.5}
        d="M11.615 22.235c.178.183.327.385.495.575-.168-.19-.317-.392-.495-.575ZM7.892 7.469Zm-.757 1.793Z"
        fill="#0066DA"
      />
      <path
        d="M15.88 29.404c.02.025.045.042.067.064-.022-.022-.047-.04-.067-.064Zm.592.344.04.006-.04-.006Zm-2.166-3.816c-.037-.067-.068-.138-.106-.205.038.067.07.138.106.205Zm1.396 3.153Zm.51.576c-.023-.011-.047-.02-.069-.034.023.014.045.023.069.034Zm4.184-5.903c-.249.347-.468.712-.692 1.074.224-.362.444-.727.692-1.074ZM17.731 28.779Zm1.634-3.416Z"
        fill="#fff"
      />
      <path
        d="M9.614 5.164a9.968 9.968 0 0 0-2.93 7.07c-.001 1.174.183 2.34.546 3.456l6.455-6.455-4.071-4.07Z"
        fill="#EA4435"
      />
    </g>
    <defs>
      <clipPath id="app-google-maps_svg__a">
        <path fill="#fff" transform="translate(.685 .005)" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAppGoogleMaps;
