export const baseSizes = {
  px: '1px',
  '0': '0',
  '0.5': '0.125rem',
  '1': '0.25rem',
  '1.5': '0.375rem',
  '2': '0.5rem',
  '2.5': '0.625rem',
  '3': '0.75rem',
  '3.5': '0.875rem',
  '4': '1rem',
  '4.5': '1.125rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '7': '1.75rem',
  '7.5': '1.875rem',
  '8': '2rem',
  '9': '2.25rem',
  '10': '2.5rem',
  '11': '2.75rem',
  '12': '3rem',
  '13': '3.25rem',
  '14': '3.5rem',
  '15': '3.75rem',
  '16': '4rem',
  '17': '4.25rem',
  '17.5': '4.313rem',
  '18': '4.5rem',
  '18.5': '4.563rem',
  '19': '4.875rem',
  '20': '5rem',
  '21': '5.625rem',
  '24': '6rem',
  '24.5': '6.063rem',
  '25': '6.25rem',
  '26': '6.5rem',
  '27': '6.75rem',
  '28': '7rem',
  '29': '7.25rem',
  '30': '7.5rem',
  '31': '7.75rem',
  '32': '8rem',
  '36': '9rem',
  '40': '10rem',
  '48': '12rem',
  '50': '12.5rem',
  '56': '14rem',
  '60': '15rem',
  '64': '16rem',
};

export type BaseSizes = typeof baseSizes;

const largeSizes = {
  full: '100%',
  '4xs': '11rem',
  '3xs': '14rem',
  '2xs': '16rem',
  xs: '20rem',
  sm: '24rem',
  md: '28rem',
  lg: '32rem',
  xl: '36rem',
  '2xl': '42rem',
  '3xl': '48rem',
  '4xl': '56rem',
  '5xl': '64rem',
  '6xl': '72rem',
  '7xl': '80rem',
};

type LargeSizes = typeof largeSizes;

const container = {
  xs: '572px',
  sm: '640px',
  md: '776px',
  lg: '1024px',
  xl: '1280px',
};

const sizes = {
  ...baseSizes,
  ...largeSizes,
  container,
} as const;

export type Sizes = BaseSizes & LargeSizes & { container: typeof container };

export default sizes;
