import * as React from "react";

function SvgAppLoom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-loom_svg__clip0)">
        <g clipPath="url(#app-loom_svg__clip1)">
          <path
            d="M23.95 10.644h-7.003l6.066-3.502-1.332-2.308-6.066 3.502 3.501-6.065L16.81.938l-3.502 6.065V0h-2.664v7.004L7.141.938 4.834 2.27l3.502 6.065-6.066-3.5L.938 7.14l6.066 3.502H0v2.664h7.003L.938 16.81l1.332 2.308 6.065-3.501-3.502 6.065 2.308 1.332 3.502-6.066v7.004h2.664v-7.003l3.501 6.065 2.308-1.332-3.502-6.066 6.065 3.502 1.333-2.308-6.065-3.501h7.003v-2.664zm-11.975 4.955a3.637 3.637 0 110-7.274 3.637 3.637 0 010 7.274z"
            fill="#625DF5"
          />
        </g>
      </g>
      <defs>
        <clipPath id="app-loom_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
        <clipPath id="app-loom_svg__clip1">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppLoom;
