import * as Sentry from '@sentry/nextjs';
import { addHours, isPast } from 'date-fns';
import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import { IS_DEMO } from '../../config/constants';
import { Loading } from '../common/components/Loading';
import { sdk } from '../common/hooks/useApollo';
import { AppMachineOrganization } from '../common/machine/appMachine';

type DemoContext = {
  organization: AppMachineOrganization | null;
};

const DEMO_ORGANIZATION_KEY = 'demo-org';

const DEMO_EXPIRATION_HOURS = 2;

export const DemoContext = createContext<DemoContext | undefined>(undefined);

export const DemoProvider: FC<PropsWithChildren> = ({ children }) => {
  const [demoOrg, setDemoOrg] = useState<AppMachineOrganization | null>(null);

  useEffect(() => {
    if (!demoOrg && IS_DEMO) {
      try {
        const item = window.localStorage.getItem(DEMO_ORGANIZATION_KEY);
        const parsedItem = item ? JSON.parse(item) : null;

        const shouldCreateNewDemoOrg =
          IS_DEMO &&
          (!parsedItem ||
            (!!parsedItem &&
              isPast(
                addHours(new Date(parsedItem.createdAt), DEMO_EXPIRATION_HOURS)
              )));

        if (shouldCreateNewDemoOrg) {
          sdk.GetDemoOrganization().then(({ getDemoOrganization }) => {
            const value = {
              ...getDemoOrganization,
              createdAt: new Date().toISOString(),
            };
            setDemoOrg(value);
            window.localStorage.setItem(
              DEMO_ORGANIZATION_KEY,
              JSON.stringify(value)
            );
          });
        } else {
          setDemoOrg(parsedItem);
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }, [demoOrg, setDemoOrg]);

  if (IS_DEMO && !demoOrg) {
    return <Loading />;
  }

  return (
    <DemoContext.Provider value={{ organization: demoOrg }}>
      {children}
    </DemoContext.Provider>
  );
};
