export const radii = {
  none: '0',
  sm: '0.25rem',
  'sm-md': '0.375rem',
  md: '0.5rem',
  'md-lg': '0.75rem',
  'md-xl': '1rem',
  'md-xxl': '1.125rem',
  lg: '1.25rem',
  'lg-md': '1.75rem',
  'lg-lg': '2rem',
  'lg-xl': '2.25rem',
  'lg-xxl': '2.5rem',
  xl: '3rem',
  full: '9999px',
};

export type Radii = typeof radii;

export default radii;
