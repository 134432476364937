import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';

import { IS_DEMO } from '../../../config/constants';
import { Button } from './Button';
import { Text } from './Text';

export const FloatingDemoPrompt = () => {
  const { t } = useTranslation();
  if (!IS_DEMO) return null;

  return (
    <x.div
      position="fixed"
      bg="white"
      bottom={20}
      right={30}
      boxShadow="glossyModal"
      borderRadius="md-lg"
      spaceY={2}
      p={4}
    >
      <x.div display="flex" spaceX={1} alignItems="center">
        <Text variant="xs" color="gray.300">
          You&apos;re viewing a demo of
        </Text>
        <Text variant="sm-semibold" color="brand.300">
          Frond
        </Text>
      </x.div>

      <Button
        href="https://frond.com/communities/new"
        target="_blank"
        label={t('rtj.create_your_own_button')}
      />
    </x.div>
  );
};
