import Colors from '../theme/colors';

export const avatars = [
  'alpaca',
  'bear',
  'camel',
  'cat',
  'cheetah',
  'cow',
  'dog',
  'elephant',
  'giraffe',
  'gorilla',
  'hamster',
  'horse',
  'kangaroo',
  'koala',
  'lion',
  'mouse',
  'panda',
  'pig',
  'rabbit',
  'rhino',
  'sheep',
  'sloth',
  'squirrel',
  'wolf',
  'zebra',
] as const;

export const getRandomAvatar = () => {
  return avatars[Math.floor(Math.random() * avatars.length)];
};

export type Avatar = (typeof avatars)[number];

const breakColorIntoShades = (color: keyof typeof Colors) => {
  return [`${color}.100`, `${color}.200`, `${color}.300`];
};

export const avatarBgColors = [
  ...breakColorIntoShades('purple'),
  ...breakColorIntoShades('yellow'),
  ...breakColorIntoShades('gray'),
  ...breakColorIntoShades('red'),
  ...breakColorIntoShades('green'),
  ...breakColorIntoShades('blue'),
];

export const getRandomAvatarBgColor = (): string => {
  return avatarBgColors[Math.floor(Math.random() * avatarBgColors.length)];
};
