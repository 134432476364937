import useSWR, { Fetcher } from 'swr';

export const useBuildId = () => {
  const fetcher: Fetcher<{ buildId?: string }, string> = (...args) =>
    fetch(...args).then((res) => res.json());
  const { data } = useSWR('/api/build-id', fetcher);
  const buildId = data?.buildId;

  return buildId;
};
