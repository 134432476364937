import { routes } from '@frond/shared';
import { NextSeo } from 'next-seo';
import React, { FC } from 'react';

import { useEventQuery } from '../../../../generated/types-and-hooks';
import { BASE_URL } from '../../../config/constants';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Error404View } from '../../errors/views/Error404View';
import { EventView } from './EventView';

export const EventPage: FC<{
  id?: string | string[];
  showLayout?: boolean;
}> = ({ id, showLayout = true }) => {
  const { organization } = useOrganization();

  const { data } = useEventQuery({
    variables: {
      id: id as string,
    },
    skip: !id,
    context: {
      skip: !id,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (data?.event.__typename === 'EventError') {
    return <Error404View />;
  }

  const openGraph = {
    title: `${data?.event.name} - Frond`,
    url: `${BASE_URL}${routes.groups
      .organization(organization.shortId)
      .event(data?.event?.id || '')}`,
  };

  return (
    <>
      <NextSeo
        title={openGraph.title}
        canonical={openGraph.url}
        openGraph={openGraph}
      />
      <EventView event={data?.event} showLayout={showLayout} />
    </>
  );
};
