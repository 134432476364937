import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_LINE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_IMAGE,
  ELEMENT_LI,
  ELEMENT_LIC,
  ELEMENT_LINK,
  ELEMENT_MENTION,
  ELEMENT_MENTION_INPUT,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_TAG,
  ELEMENT_UL,
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  MyAllChildren,
  MyValue,
  RichText,
  routes,
} from '@frond/shared';
import {
  isText,
  TRenderElementProps,
  TRenderLeafProps,
} from '@udecode/plate-common';
import styled, { css } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { useOptionalOrganization } from '../../../auth/hooks/useOptionalOrganization';
import analytics from '../../utils/analytics';
import { Heading } from '../Heading';
import { Image } from '../Image';
import { MentionToken } from '../MentionToken';
import { NextLink } from '../NextLink';
import { Text } from '../Text';
import { UserMentionToken } from '../UserToken';
import { ComposerVariant } from './Composer';
import { ComposerLinkElement } from './ComposerLinkElement';
import { useSearchMentions } from './hooks/useSearchMentions';
import { MENTION_EVERYONE_KEY } from './MentionDropdown';

export const ALL_MARKS = [
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MARK_STRIKETHROUGH,
  MARK_CODE,
];

const listMixin = css`
  list-style-position: outside;
  padding-left: 6;
`;

const helloListMixin = css`
  list-style-position: inside;
  padding-left: 0;
`;

export const UnorderedList = styled.ul<{
  variant?: ComposerVariant;
}>`
  ${listMixin};
  ${(p) => p.variant === 'hello' && helloListMixin}
  list-style-type: disc;
`;

export const OrderedList = styled.ol<{
  variant?: ComposerVariant;
}>`
  ${listMixin};
  ${(p) => p.variant === 'hello' && helloListMixin}
  list-style-type: decimal;
`;

export const Blockquote = styled.blockquote`
  border-left: thick;
  border-color: gray.500;
  padding-left: 6;
  line-height: base;
`;

export const CodeBlock = styled.pre`
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 2 3;
  color: gray.400;
  background-color: gray.50;
  border: default;
  border-color: gray.200;
  border-radius: md;
  font-family: code;
  font-size: sm;
  line-height: base;

  > code {
    display: block;
  }

  span {
    white-space: pre-wrap;
  }
`;

const CodeLeafInner = styled.span`
  * {
    font-family: code;
    font-size: sm;
    line-height: base;
  }
`;

export const ComposerLeaf: React.FC<
  Omit<TRenderLeafProps<MyValue>, 'attributes' | 'text'> & {
    attributes?: TRenderLeafProps<MyValue>['attributes'];
  }
> = ({ attributes, children, leaf }) => {
  if ('placeholder' in leaf) {
    children = (
      <Text color="gray.300" as="span">
        {children}
      </Text>
    );
  }

  if ('bold' in leaf) {
    children = (
      <Text as="strong" variant="md-semibold">
        {children}
      </Text>
    );
  }

  if ('italic' in leaf) {
    children = (
      <Text as="i" fontStyle="italic">
        {children}
      </Text>
    );
  }

  if ('underline' in leaf) {
    children = (
      <Text as="u" textDecoration="underline">
        {children}
      </Text>
    );
  }

  if ('strikethrough' in leaf) {
    children = (
      <Text as="strike" textDecoration="strikethrough">
        {children}
      </Text>
    );
  }

  if ('code' in leaf) {
    children = (
      <Text
        as="code"
        fontFamily="code"
        bg="gray.100"
        border="default"
        borderColor="gray.200"
        p="2px"
      >
        <CodeLeafInner>{children}</CodeLeafInner>
      </Text>
    );
  }

  if ('color' in leaf) {
    children = (
      <Text color={leaf.color}>{children || String.fromCharCode(160)}</Text>
    );
  }

  return <span {...attributes}>{children || String.fromCharCode(160)}</span>;
};

export type ComposerElementProps = Partial<TRenderElementProps<MyValue>> & {
  stripEmptySpaces?: boolean;
  variant?: ComposerVariant;
  readonly?: boolean;
};

export const ComposerMentionElement: React.FC<ComposerElementProps> = ({
  stripEmptySpaces,
  ...props
}) => {
  const { attributes, children, element } = props;
  const { mentions } = useSearchMentions();
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const user = mentions?.find((mention) => mention.id === element?.user?.id);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const role = mentions?.find((mention) => mention.id === element?.role?.id);

  return (
    <span {...attributes} contentEditable={false}>
      {element?.isEveryone ? (
        <MentionToken
          dataSlateValue={MENTION_EVERYONE_KEY}
          attributes={attributes}
        >
          {t('everyone')}
        </MentionToken>
      ) : user && '__typename' in user && user.__typename === 'User' ? (
        <UserMentionToken
          data-slate-value={user?.firstName || user?.username}
          user={user}
          ref={attributes?.ref}
        />
      ) : (
        role &&
        '__typename' in role &&
        role.__typename === 'OrganizationRole' && (
          <MentionToken dataSlateValue={role?.label} attributes={attributes}>
            {role.label}
          </MentionToken>
        )
      )}
      {!stripEmptySpaces ? children : null}
    </span>
  );
};

export const ComposerElement: React.FC<ComposerElementProps> = (props) => {
  const { attributes, children, element, variant } = props;
  const { organization } = useOptionalOrganization();

  switch (element?.type) {
    case ELEMENT_H1:
      return (
        <Heading
          {...{
            ...attributes,
            variant: 'xl',
          }}
        >
          {children}
        </Heading>
      );
    case ELEMENT_H2:
      return (
        <Heading
          {...{
            ...attributes,
            variant: 'lg',
          }}
        >
          {children}
        </Heading>
      );
    case ELEMENT_H3:
      return (
        <Heading
          {...{
            ...attributes,
            variant: 'md',
          }}
        >
          {children}
        </Heading>
      );
    case ELEMENT_CODE_BLOCK:
      return <CodeBlock {...attributes}>{children}</CodeBlock>;
    case ELEMENT_CODE_LINE:
      return <code>{children}</code>;
    case ELEMENT_BLOCKQUOTE:
      return <Blockquote {...attributes}>{children}</Blockquote>;
    case ELEMENT_OL:
      return (
        <OrderedList {...attributes} variant={variant}>
          {children}
        </OrderedList>
      );
    case ELEMENT_UL:
      return (
        <UnorderedList {...attributes} variant={variant}>
          {children}
        </UnorderedList>
      );

    case ELEMENT_LI:
      // Backwards compatible to nodes without block element
      if (isText((element.children as MyAllChildren)[0] as RichText)) {
        return (
          <li {...attributes}>
            <Text as="span">{children}</Text>
          </li>
        );
      } else {
        return <li {...attributes}>{children}</li>;
      }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    case ELEMENT_LIC:
      return (
        <Text as="span" mb={1} {...attributes}>
          {children}
        </Text>
      );

    case ELEMENT_IMAGE:
      if (!element.url) {
        return null;
      }

      return (
        <>
          <Image src={element.url as string} style={{ width: '100%' }} />
          {children}
        </>
      );
    case ELEMENT_LINK:
      return <ComposerLinkElement {...props} />;
    case ELEMENT_MENTION:
      return <ComposerMentionElement {...props} />;
    case ELEMENT_MENTION_INPUT:
      return (
        <Text mb={{ _: 1, last: 0 }} {...attributes} as="span">
          @{children}
        </Text>
      );
    case ELEMENT_TAG:
      if (!element.children[0]) return null;
      if (!organization) return <span {...attributes}>#{children}</span>;
      return (
        <span
          {...attributes}
          autoCorrect="off"
          spellCheck="false"
          autoCapitalize="off"
        >
          <NextLink
            href={routes.groups
              .organization(organization.shortId)
              .tag(element.children[0].text)}
            metadata={{ event: analytics.events.COMPOSER_TAG }}
          >
            <span>#{children}</span>
          </NextLink>
        </span>
      );
    case ELEMENT_PARAGRAPH:
      return (
        <Text position="relative" {...attributes}>
          {children}
        </Text>
      );

    default:
      return (
        <Text as="span" {...attributes}>
          {children}
        </Text>
      );
  }
};
