import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import {
  GroupCoreFragment,
  PostCreationPolicy,
  useOrganizationAdministratorsQuery,
  useUpdateGroupMutation,
} from '../../../../../../generated/types-and-hooks';
import { useOrganization } from '../../../../auth/hooks/useOrganization';
import { Switch } from '../../../../common/components/Switch';
import { Text } from '../../../../common/components/Text';
import { Users } from '../../../../common/components/Users';
import {
  GroupEditSetting,
  GroupEditSettingPositioning,
} from './GroupEditSetting';

export const GroupEditSettingPermissions: FC<
  { group: GroupCoreFragment } & GroupEditSettingPositioning
> = ({ group, first, last }) => {
  const { t } = useTranslation('groups');

  const { organization } = useOrganization();
  const { postCreationPolicy } = group;
  const checked = postCreationPolicy === PostCreationPolicy.Unrestricted;

  const { data } = useOrganizationAdministratorsQuery({
    variables: { organizationId: organization.id },
  });
  const [mutate, { loading }] = useUpdateGroupMutation({
    optimisticResponse: {
      __typename: 'Mutation',
      updateGroup: {
        ...group,
        postCreationPolicy:
          group.postCreationPolicy === PostCreationPolicy.Unrestricted
            ? PostCreationPolicy.Administrators
            : PostCreationPolicy.Unrestricted,
      },
    },
  });

  const admins = data?.organization?.administrators;

  const handleCheckedChange = (enabled: boolean) => {
    const policy = enabled
      ? PostCreationPolicy.Unrestricted
      : PostCreationPolicy.Administrators;

    mutate({
      variables: { input: { id: group.id, postCreationPolicy: policy } },
    });
  };

  return (
    <GroupEditSetting
      title={t('groups.allow_members_to_post')}
      toggle={
        <Switch
          disabled={loading}
          checked={checked}
          onCheckedChange={handleCheckedChange}
        />
      }
      first={first}
      last={last}
    >
      <Text variant="md" color="gray.300">
        {checked
          ? t('groups.allow_members_to_post_enabled')
          : t('groups.allow_members_to_post_disabled')}
        {!checked && admins && (
          <x.div pt={2}>
            <Users users={admins} limit={15} variant="small" showNameTooltips />
          </x.div>
        )}
      </Text>
    </GroupEditSetting>
  );
};
