import * as yup from 'yup';

const emptyStringToNull = (value: string) => (value === '' ? null : value);

export const UpdateUserInputSchema = yup.object().shape({
  userId: yup.string().required(),
  firstName: yup.string().max(255),
  lastName: yup.string().max(255),
  username: yup
    .string()
    .max(255)
    .matches(/^[a-zA-Z0-9._]+$/),
  linkedinUrl: yup
    .string()
    .transform(emptyStringToNull)
    .max(255)
    .optional()
    .nullable()
    .url('Please enter a full LinkedIn URL')
    .matches(/linkedin.com/, 'Please enter a full LinkedIn URL'),
  instagramHandle: yup
    .string()
    .transform(emptyStringToNull)
    .max(255)
    .optional()
    .nullable()
    .matches(/^[a-z0-9\-_.]+$/, "Your Instagram username doesn't look right"),
  hometown: yup.string().max(255).transform(emptyStringToNull).nullable(),
  currentCity: yup.string().max(255).transform(emptyStringToNull).nullable(),
  timeZone: yup.string().max(255).nullable(),
  bio: yup.string().max(1024).transform(emptyStringToNull).nullable(),
  profileImageId: yup.string().max(1024).nullable(),
  coverImageId: yup.string().max(1024).nullable(),
});

export const OnboardingProfileInputSchema = yup
  .object({
    userId: yup.string().required(),
    firstName: yup.string().max(255),
    lastName: yup.string().max(255),
    username: yup
      .string()
      .max(255)
      .required()
      .matches(/^[a-zA-Z0-9._]+$/),
    position: yup.string().max(255).transform(emptyStringToNull).nullable(),
    profileImageId: yup.string().max(1024).nullable(),
    timeZone: yup.string().max(255).nullable(),
    avatar: yup.string().max(255).nullable(),
    avatarBgColor: yup.string().max(255).nullable(),
    bio: yup.string().max(255).nullable(),
    organizationName: yup
      .string()
      .max(255)
      .required('Please enter an organization name'),
  })
  .defined();

export const OnboardingProfileInputSchemaNoOrg =
  OnboardingProfileInputSchema.shape({
    organizationName: yup.string().max(255),
  });
