import * as React from 'react';

function SvgCircleRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5 12c0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11 11 4.925 11 11zm-2 0a9 9 0 10-18 0 9 9 0 0018 0zM11.207 7.293L9.793 8.707 13.086 12l-3.293 3.293 1.414 1.414L15.914 12l-4.707-4.707z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCircleRight;
