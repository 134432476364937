import * as React from "react";
import { SVGProps } from "react";

const SvgAppLightroom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.997 1.13H5.372a3.938 3.938 0 0 0-3.937 3.937v13.875a3.937 3.937 0 0 0 3.937 3.938h14.625a3.937 3.937 0 0 0 3.938-3.938V5.067a3.938 3.938 0 0 0-3.938-3.937Z"
      fill="#001E36"
    />
    <path
      d="M14.09 9.755h1.595a.173.173 0 0 1 .171.125c.12.255.189.531.204.812.602-.778 1.342-1.125 2.328-1.125a.094.094 0 0 1 .11.11v1.655c0 .083-.042.119-.126.109-.902-.052-1.619.066-2.137.585a.491.491 0 0 0-.175.354v4.766c0 .072-.042.109-.125.109h-1.61c-.095 0-.14-.041-.14-.125v-5.313c0-1.258-.094-1.437-.188-1.953-.01-.072.02-.11.094-.11Zm-1.263 7.5H7.015c-.094 0-.144-.053-.144-.16V7.258A.113.113 0 0 1 7 7.13h1.653c.064 0 .094.037.094.112v8.513h4.383c.094 0 .132.043.111.127l-.255 1.245a.151.151 0 0 1-.159.128Z"
      fill="#31A8FF"
    />
  </svg>
);

export default SvgAppLightroom;
