import { theme } from '@frond/shared';
import styled, { x } from '@xstyled/styled-components';
import React, { FC, PropsWithChildren } from 'react';

import { LoadingSpinner } from '../../../common/components/LoadingSpinner';
import { VideoControlsProps } from '../../../video/components/Video/VideoControls';

const ControlSurface = styled.div<{ buffering: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > div.controls,
  & > div.hidden {
    transition: opacity ${theme.transitions.duration.faster};
    opacity: 0;
  }

  ${(p) =>
    !p.buffering &&
    `
    &:hover > div.controls,
    & > div.visible {
      opacity: 1;
    }
  `}
`;

export const QuestionsVideoPlayerControls: FC<VideoControlsProps> = ({
  buffering,
  togglePlayback,
  onClick,
}) => {
  const handleClick = (e: React.MouseEvent) => {
    if (onClick && onClick(e) === false) {
      return;
    }

    togglePlayback();
  };

  return (
    <>
      <ControlSurface buffering={buffering} onClick={handleClick} />
      <StatusContainer>
        <BufferingSpinner buffering={buffering} />
      </StatusContainer>
    </>
  );
};

const StatusContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <x.div
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      pointerEvents="none"
    >
      {children}
    </x.div>
  );
};

const BufferingSpinner: FC<{ buffering: boolean }> = ({ buffering }) => {
  return (
    <x.div
      opacity={buffering ? 1 : 0}
      transitionProperty="opacity"
      transitionDuration="fast"
      borderRadius="full"
      w={18}
      h={18}
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor={theme.colors.blackAlpha[300]}
    >
      {buffering && <LoadingSpinner size="8" />}
    </x.div>
  );
};
