import * as React from "react";
import { SVGProps } from "react";
const SvgMedia = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.792 2.5A2.301 2.301 0 0 0 2.5 4.792v10.416A2.301 2.301 0 0 0 4.792 17.5h10.416a2.301 2.301 0 0 0 2.292-2.292V4.792A2.301 2.301 0 0 0 15.208 2.5H4.792Zm0 1.25h10.416c.583 0 1.042.46 1.042 1.042v8.523l-2.505-2.424a1.687 1.687 0 0 0-2.344 0l-.854.826-2.575-2.49a1.687 1.687 0 0 0-1.173-.477 1.69 1.69 0 0 0-1.172.475v.001L3.75 11.042v-6.25c0-.583.46-1.042 1.042-1.042Zm7.916 1.667c-.573 0-1.081.237-1.404.6-.323.363-.47.823-.47 1.275 0 .451.147.91.47 1.274.323.363.831.6 1.404.6s1.082-.237 1.405-.6c.323-.363.47-.823.47-1.274 0-.452-.147-.912-.47-1.275-.323-.363-.832-.6-1.405-.6Zm0 1.25c.26 0 .377.075.47.18.095.106.155.271.155.445 0 .173-.06.338-.154.444-.094.106-.21.18-.47.18-.261 0-.377-.074-.471-.18a.684.684 0 0 1-.155-.444c0-.174.061-.34.155-.445.094-.105.21-.18.47-.18ZM6.8 10c.109 0 .216.039.303.124l2.546 2.462L5.86 16.25H4.792c-.583 0-1.042-.46-1.042-1.042v-2.427l2.746-2.657A.43.43 0 0 1 6.799 10Zm5.773 1.667c.11 0 .217.038.304.123l3.374 3.264v.154c0 .583-.46 1.042-1.042 1.042h-7.55l4.612-4.46a.428.428 0 0 1 .302-.123Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMedia;
