import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC, useState } from 'react';

import { FrondLogo } from '../../../common/components/FrondLogo';
import { LoadingPulse } from '../../../common/components/LoadingPulse';
import { NextLink } from '../../../common/components/NextLink';
import { Text } from '../../../common/components/Text';
import { useAuth } from '../../hooks/useAuth';
import { useOptionalOrganization } from '../../hooks/useOptionalOrganization';
import { useViewer } from '../../hooks/useViewer';
import { SignInRedirectGuard } from '../guards/SignInRedirectGuard';
import { SignInEmailSuccess } from './SignInEmailSuccess';
import { SignInForm } from './SignInForm';

export const SignInContent: FC<{
  onLoginAttempt?: () => void;
  callbackUrlPath?: string;
  shouldRedirect?: boolean;
  variant?: 'default' | 'public';
}> = ({
  onLoginAttempt,
  callbackUrlPath,
  shouldRedirect,
  variant = 'default',
}) => {
  const { t } = useTranslation('common');
  const { organization } = useOptionalOrganization();
  const { isAuthenticated } = useAuth();
  const { isViewerLoading } = useViewer();
  const router = useRouter();

  const [showSignInLinkForm, setShowSignInLinkForm] = useState(false);
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);

  const inviteCode = Array.isArray(router.query.invite)
    ? router.query.invite[0]
    : router.query.invite;

  return (
    <SignInRedirectGuard
      shouldRedirect={!inviteCode && shouldRedirect !== false}
    >
      <x.div
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        minH="352px"
      >
        {isViewerLoading || isAuthenticated ? (
          <LoadingPulse />
        ) : showEmailSuccess ? (
          <SignInEmailSuccess />
        ) : (
          <>
            <x.div
              display="flex"
              flexDirection="column"
              alignItems="center"
              spaceY={10}
            >
              <x.div w={20}>
                <FrondLogo />
              </x.div>
              <Text
                textAlign="center"
                px={{
                  sm: variant === 'public' && organization?.isPublic ? 7 : 14,
                }}
              >
                {showSignInLinkForm ? (
                  t('auth.email_behavior')
                ) : variant === 'public' && organization?.isPublic ? (
                  <>
                    {t('auth.continue_behavior_public_part_one')}{' '}
                    <Text as="span" variant="md-medium">
                      {organization?.name}
                    </Text>
                    . <NextLink href="/">Frond</NextLink>{' '}
                    {t('auth.continue_behavior_public_part_two')}
                  </>
                ) : (
                  t('auth.continue_behavior')
                )}
              </Text>
              <SignInForm
                onLoginAttempt={onLoginAttempt}
                onEmailClick={() => setShowSignInLinkForm(true)}
                onEmailSuccess={() => setShowEmailSuccess(true)}
                callbackUrlPath={callbackUrlPath || router.asPath}
                variant={variant}
              />
            </x.div>
          </>
        )}
      </x.div>
    </SignInRedirectGuard>
  );
};

SignInContent.displayName = 'SignInContent';
