import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { FC, useMemo } from 'react';

import { User } from '../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Avatar } from '../../common/components/Avatar';
import { Icon } from '../../common/components/Icon';
import { ModalProps } from '../../common/components/Modal';
import {
  TabbedModal,
  TabDefinition,
} from '../../common/components/TabbedModal';
import { Text } from '../../common/components/Text';
import { Tooltip } from '../../common/components/Tooltip';
import { getUserDisplayName } from '../../common/utils/user';

export type PostCardViewersModalProps = Pick<
  ModalProps,
  'ariaLabel' | 'isOpen' | 'onDismiss'
> & {
  viewers: Pick<
    User,
    | 'profileImageId'
    | 'avatarBgColor'
    | 'avatar'
    | 'firstName'
    | 'lastName'
    | 'id'
    | 'name'
    | 'username'
  >[];
  defaultValue?: 'Members';
  anonymousViewersCount?: number;
};

export const PostCardViewerListItem: FC<{
  viewer: Pick<
    User,
    | 'profileImageId'
    | 'avatarBgColor'
    | 'avatar'
    | 'firstName'
    | 'lastName'
    | 'id'
    | 'name'
    | 'username'
  >;
}> = ({ viewer }) => {
  return (
    <>
      <x.span display="flex" spaceX={2} alignItems="center" w="full" py={2}>
        <Avatar
          imageId={viewer.profileImageId}
          bgColor={viewer.avatarBgColor}
          avatar={viewer.avatar}
          size="s-3"
        />
        <Text as="span" variant="md" truncate color="gray.500">
          {getUserDisplayName(viewer)}
        </Text>
      </x.span>
    </>
  );
};

export const PostCardViewersList: FC<{
  viewers: Pick<
    User,
    | 'profileImageId'
    | 'avatarBgColor'
    | 'avatar'
    | 'firstName'
    | 'lastName'
    | 'id'
    | 'name'
    | 'username'
  >[];
  anonymousViewersCount?: number;
}> = ({ viewers, anonymousViewersCount }) => {
  const { organization } = useOptionalOrganization();

  const anonymousViewsTooltipLabel = `${organization?.name} is a public community. Anonymous viewers are people who viewed this post without signing in.`;
  return (
    <x.div px={6} py={4}>
      {viewers.map((v) => {
        return <PostCardViewerListItem key={v.id} viewer={v} />;
      })}
      {organization?.isPublic &&
      anonymousViewersCount &&
      anonymousViewersCount > 0 ? (
        <Tooltip
          label={
            <Text variant="sm-semibold">{anonymousViewsTooltipLabel}</Text>
          }
          aria-label={anonymousViewsTooltipLabel}
        >
          <x.span display="flex" spaceX={2} alignItems="center" w="full" py={2}>
            <x.div
              w={8}
              h={8}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Icon name="eye" size="5" />
            </x.div>

            <Text as="span" variant="md" truncate color="gray.500">
              {anonymousViewersCount} anonymous
            </Text>
          </x.span>
        </Tooltip>
      ) : null}
    </x.div>
  );
};

export const PostCardViewersModal: FC<PostCardViewersModalProps> = ({
  ariaLabel,
  isOpen,
  onDismiss,
  viewers,
  defaultValue,
  anonymousViewersCount,
}) => {
  const { t } = useTranslation();
  const { organization } = useOrganization();
  const tabs = useMemo(() => {
    return {
      Members: (
        <PostCardViewersList
          viewers={viewers}
          anonymousViewersCount={anonymousViewersCount}
        />
      ),
    };
  }, [viewers, anonymousViewersCount]) as TabDefinition;

  return (
    <TabbedModal
      h="722px"
      ariaLabel={ariaLabel}
      isOpen={isOpen}
      onDismiss={onDismiss}
      title={t('seen_by_count', {
        count:
          viewers.length +
          (organization.isPublic ? anonymousViewersCount || 0 : 0),
      })}
      tabs={tabs}
      defaultValue={defaultValue || Object.keys(tabs)[0]}
    />
  );
};
