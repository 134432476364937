import * as React from "react";
import { SVGProps } from "react";
const SvgAppFrond = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.79 5.936s-.769 6.128 5.642 6.128c0 0 1.598-6.128-5.643-6.128ZM17.193 4.044c-8.72 0-9.527 5.485-9.367 8.301 1.473-4.25 5.49-5.187 5.49-5.187-3.834 2.318-4.62 6.502-4.672 6.798 9.738-.376 8.55-9.912 8.55-9.912Z"
      fill="#6338B8"
    />
  </svg>
);
export default SvgAppFrond;
