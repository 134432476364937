import * as React from "react";

function SvgTable(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4h12a2 2 0 012 2v13a2 2 0 01-2 2H3a2 2 0 01-2-2V6a2 2 0 012-2h6zM7 6H3v3h4V6zm2 3V6h12v3H9zm-2 2H3v3h4v-3zm2 3v-3h12v3H9zm-2 2H3v3h4v-3zm2 3v-3h12v3H9z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTable;
