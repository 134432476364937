import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { GroupWithMembersFragment } from '../../../../generated/types-and-hooks';
import { Text } from '../../common/components/Text';
import { GroupNewPostButton } from './GroupNewPostButton';

export const CourseEmptyCard = ({
  group,
}: {
  group: GroupWithMembersFragment;
}) => {
  const { t } = useTranslation(['groups', 'common']);

  return (
    <x.div p={4} borderRadius={{ 'sm-2': 'md-lg' }} bg="gray.50" spaceY={3}>
      <x.div spaceY={2}>
        <Text variant="md-semibold" color="brand.300">
          {t('groups.course_empty_heading', {
            ns: 'groups',
          })}
        </Text>
        <Text>
          {t('groups.course_empty_description', {
            ns: 'groups',
          })}
        </Text>
      </x.div>

      <GroupNewPostButton
        group={group}
        label={t('add_lesson', {
          ns: 'common',
        })}
      />
    </x.div>
  );
};
