import { routes } from '@frond/shared';
import { TFunction } from 'next-i18next';

import {
  AddMemberPolicy,
  JoinPolicy,
  Organization,
} from '../../../generated/types-and-hooks';
import { BASE_URL } from '../../config/constants';

export const getInviteLink = (
  organization: Pick<Organization, 'id' | 'addMemberPolicy' | 'joinPolicy'>,
  route: string,
  inviteCode?: string
) => {
  const link = `${BASE_URL}${route}`;
  const inviteLink = `${BASE_URL}${routes.addInvite(route, inviteCode)}`;

  if (
    [JoinPolicy.Unrestricted, JoinPolicy.RequestToJoin].includes(
      organization.joinPolicy
    )
  ) {
    return link;
  } else {
    return inviteLink;
  }
};

export const getInviteText = (
  organization: Pick<
    Organization,
    'id' | 'name' | 'addMemberPolicy' | 'joinPolicy'
  >,
  t: TFunction
) => {
  if (
    organization.addMemberPolicy === AddMemberPolicy.RequestToJoin ||
    organization.joinPolicy == JoinPolicy.RequestToJoin
  ) {
    if (organization.joinPolicy === JoinPolicy.Unrestricted) {
      throw new Error(`Invalid community permissions for ${organization.id}`);
    }
    return t('invite_with_approval', { organizationName: organization.name });
  } else {
    return t('invite_without_approval', {
      organizationName: organization.name,
    });
  }
};

export const getAddMemberText = (
  organization: Pick<Organization, 'id' | 'name' | 'addMemberPolicy'>,
  isViewerAdmin: boolean,
  t: TFunction
) => {
  if (
    organization.addMemberPolicy === AddMemberPolicy.RequestToJoin &&
    !isViewerAdmin
  ) {
    return t('add_member_with_approval');
  } else {
    return t('add_member_without_approval');
  }
};
