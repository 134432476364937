import * as React from "react";

function SvgChevronTop(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.414l-7.293 7.293-1.414-1.414L12 6.586l8.707 8.707-1.414 1.414L12 9.414z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgChevronTop;
