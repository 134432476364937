import { x } from '@xstyled/styled-components';

import { POSTS_PER_PAGE } from '../../../config/constants';
import { PostCardPlaceholder } from '../../posts/components/PostCard';

export const PostsLoading = ({ numPosts = POSTS_PER_PAGE }) => {
  return (
    <x.div spaceY={4}>
      {[...Array(numPosts)].map((_, i) => (
        <PostCardPlaceholder key={i} />
      ))}
    </x.div>
  );
};
