import * as React from "react";

function SvgAppAbstract(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-abstract_svg__clip0)" fill="currentColor">
        <path d="M10.032 11.628A2.34 2.34 0 007.734 14.4a2.346 2.346 0 004.266.87 2.34 2.34 0 00.396-1.302 2.4 2.4 0 00-.69-1.656 2.345 2.345 0 00-1.674-.684z" />
        <path d="M12 0C2.4 0 0 2.4 0 12s2.4 12 12 12 12-2.4 12-12S21.6 0 12 0zm1.278 17.214a4.59 4.59 0 01-5.802.6 4.662 4.662 0 01-1.692-2.064 4.614 4.614 0 012.691-6.088 4.602 4.602 0 015.379 1.738 4.584 4.584 0 01-.6 5.796l.024.018zm5.1 1.164h-2.25v-10.5h-10.5v-2.25h12.75v12.75z" />
      </g>
      <defs>
        <clipPath id="app-abstract_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppAbstract;
