import * as React from "react";

function SvgMouse(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.962 16.213a.625.625 0 01-.625-.457 11.35 11.35 0 00-.962-2.456 10 10 0 00-5-4.456.625.625 0 01-.375-.731 6.563 6.563 0 117.087 8.125l-.125-.025z"
        fill="#212121"
      />
      <path
        d="M25.306 7.906a11.25 11.25 0 015.13 4.788c.39.699.713 1.433.963 2.193a5.313 5.313 0 10-6.093-6.98z"
        fill="#A1887F"
      />
      <path
        d="M20 37.044a3.958 3.958 0 01-3.225-1.631l-6.3-8.594a13.125 13.125 0 01-2.038-11.406c.267-.95.645-1.864 1.125-2.725a11.25 11.25 0 015.657-5 11.057 11.057 0 014.156-.813h1.25c1.433-.005 2.853.27 4.181.806a11.25 11.25 0 015.625 5c.48.862.858 1.776 1.125 2.725a13.225 13.225 0 01-2.044 11.4l-6.287 8.607A3.951 3.951 0 0120 37.043z"
        fill="#212121"
      />
      <path
        d="M19.375 8.125a9.812 9.812 0 00-3.706.719 10 10 0 00-5 4.456 11.377 11.377 0 00-1.013 2.462 11.875 11.875 0 001.875 10.325l6.256 8.588a2.745 2.745 0 004.432 0l6.293-8.594a11.967 11.967 0 001.875-10.319c-.24-.857-.58-1.684-1.012-2.462a10 10 0 00-5-4.456 9.819 9.819 0 00-3.75-.719h-1.25z"
        fill="#BCAAA4"
      />
      <path
        d="M9.037 16.212H8.97a6.563 6.563 0 117.087-8.087.624.624 0 01-.375.731 10 10 0 00-5 4.45 11.39 11.39 0 00-1.012 2.463.625.625 0 01-.632.443z"
        fill="#212121"
      />
      <path
        d="M9.688 4.375A5.313 5.313 0 008.6 14.887c.25-.762.573-1.498.963-2.2a11.25 11.25 0 015.13-4.78 5.294 5.294 0 00-5.005-3.532z"
        fill="#A1887F"
      />
      <path d="M20 34.375a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" fill="#212121" />
      <path
        d="M20 33.125a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
        fill="#757575"
      />
      <path
        d="M15.625 26.875A.624.624 0 0115 26.25V25a.624.624 0 111.25 0v1.25a.624.624 0 01-.625.625zm8.75 0a.624.624 0 01-.625-.625V25A.624.624 0 1125 25v1.25a.624.624 0 01-.625.625zm-2.5 5a.627.627 0 01-.094-1.25l8.125-1.25a.632.632 0 11.188 1.25l-8.125 1.25h-.094z"
        fill="#212121"
      />
      <path
        d="M30 35h-.144l-8.125-1.875a.64.64 0 01-.202-1.166.64.64 0 01.484-.084l8.125 1.875a.631.631 0 01.556.694A.627.627 0 0130 35zm-11.875-3.125h-.094l-8.125-1.25a.631.631 0 11.188-1.25l8.125 1.25a.63.63 0 01.578.672.626.626 0 01-.672.578z"
        fill="#212121"
      />
      <path
        d="M10 35a.629.629 0 01-.137-1.25l8.125-1.875a.64.64 0 01.281 1.25L10.144 35H10z"
        fill="#212121"
      />
    </svg>
  );
}

export default SvgMouse;
