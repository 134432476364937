import { css } from '@xstyled/styled-components';

const typography = {
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },

  lineHeights: {
    base: '150%',
    shorterer: '120%',
    shorter: '125%',
    short: '130%',
    tall: '140%',
    taller: '145%',
    tallest: '150%',
  },

  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },

  fonts: {
    default: `"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif`,
    lato: `"Lato", sans-serif`,
    code: `"Source Code Pro", monospace`,
  },

  fontSizes: {
    base: '16px',
    xs: '0.75rem',
    sm: '0.875rem',
    'md-s': '0.9375rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '2.25rem',
    '4xl': '3rem',
    '5xl': '4rem',
    '6xl': '6.25rem',
  },

  typographyStyles: {
    heading: {
      '4xl': css`
        font-size: 4xl;
        font-weight: bold;
        line-height: shorter;
      `,
      '3xl': css`
        font-size: 3xl;
        font-weight: bold;
        line-height: shorter;
      `,
      '2xl': css`
        font-size: 2xl;
        font-weight: bold;
        line-height: shorter;
      `,
      xl: css`
        font-size: xl;
        font-weight: bold;
        line-height: short;
      `,
      lg: css`
        font-size: lg;
        font-weight: bold;
        line-height: tall;
      `,
      md: css`
        font-size: md;
        font-weight: semibold;
        line-height: tall;
      `,
    },
    text: {
      '2xl': css`
        font-size: 2xl;
        font-weight: semibold;
        line-height: shorter;
      `,
      lg: css`
        font-size: lg;
        font-weight: normal;
        line-height: tallest;
      `,
      md: css`
        font-size: md;
        font-weight: normal;
        line-height: tallest;
      `,
      sm: css`
        font-size: sm;
        font-weight: normal;
        line-height: taller;
      `,
      xs: css`
        font-size: xs;
        line-height: taller;
      `,
      'lg-semibold': css`
        font-size: lg;
        font-weight: semibold;
        line-height: tallest;
      `,
      'lg-bold': css`
        font-size: lg;
        font-weight: bold;
        line-height: tallest;
      `,
      'md-medium': css`
        font-size: md;
        font-weight: medium;
        line-height: tallest;
      `,
      'md-semibold': css`
        font-size: md;
        font-weight: semibold;
        line-height: tallest;
      `,
      'sm-semibold': css`
        font-size: sm;
        font-weight: semibold;
        line-height: taller;
      `,
      'sm-medium': css`
        font-size: sm;
        font-weight: medium;
        line-height: taller;
      `,
      'xs-semibold': css`
        font-size: xs;
        font-weight: bold;
        line-height: taller;
      `,
      'xs-bold': css`
        font-size: xs;
        font-weight: bold;
        line-height: taller;
      `,
      'md-s': css`
        font-size: md-s;
        line-height: tallest;
      `,
      'md-s-medium': css`
        font-size: md-s;
        font-weight: medium;
        line-height: tallest;
      `,
      'md-s-semibold': css`
        font-size: md-s;
        font-weight: semibold;
        line-height: tallest;
      `,
    },
    /* Custom typograph for card designs to fit text */
    card: {
      xs: css`
        font-size: 0.75rem;
        font-weight: bold;
        line-height: 120%;
      `,
      sm: css`
        font-size: 1rem;
        font-weight: bold;
        line-height: 120%;
      `,
      md: css`
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 120%;
      `,
      lg: css`
        font-size: 1.375rem;
        font-weight: bold;
        line-height: 120%;
      `,
      xl: css`
        font-size: 3xl;
        font-weight: bold;
        line-height: 120%;
      `,
    },
    utils: {
      truncate: css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `,
    },
  },
};

export type Typography = typeof typography;

export default typography;
