import { VideoStatusQuery } from '../../../../../generated/types-and-hooks';
import {
  VideoCreationMetadata,
  VideoRecorderStates,
} from './videoRecorderTypes';

/**
 * Function to prompt the user if they are attempting to navigate
 * away while the video is still uploading
 */
export const navigationPreventionHandler = (e: BeforeUnloadEvent): void => {
  e.preventDefault();
  e.returnValue = '';
};

/**
 * Determine whether a video is ready for playback from its query result
 */
export const videoIsReady = (data: VideoStatusQuery | undefined): boolean => {
  return data?.video?.status === 'READY' || false;
};

/**
 * Checks whether the current device supports the input capture attribute
 */
export const supportsNativeVideoCapture = (): boolean => {
  const input = document.createElement('input') as { capture?: any };
  return input.capture !== undefined;
};

/**
 * Extract a playback ID from a video query result
 */
export const getCreationMetadata = (
  data: VideoStatusQuery | undefined
): VideoCreationMetadata | null => {
  if (!data?.video?.playbackId) {
    return null;
  }

  return {
    id: data.video.id,
    playbackId: data.video.playbackId,
    aspect: data.video.aspect || undefined,
    mirror: true,
  };
};

/**
 * Several states make up the recorder's review context
 * Returns true if the recorder is currently in one of them
 */
export const recorderIsInReviewContext = (
  state: VideoRecorderStates
): boolean => {
  return [
    VideoRecorderStates.REVIEW,
    VideoRecorderStates.UPLOADING,
    VideoRecorderStates.UPLOAD_FAILED,
    VideoRecorderStates.PROCESSING,
  ].includes(state);
};

/**
 * The recorder is either in record or review context
 */
export const recorderIsInRecordContext = (
  state: VideoRecorderStates
): boolean => {
  return !recorderIsInReviewContext(state);
};

/**
 * A subset of the review context states form an upload context
 * Returns true if the recorder is currently in one of them
 */
export const recorderIsInUploadContext = (
  state: VideoRecorderStates
): boolean => {
  return [
    VideoRecorderStates.UPLOADING,
    VideoRecorderStates.UPLOAD_FAILED,
    VideoRecorderStates.PROCESSING,
  ].includes(state);
};
