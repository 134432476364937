import { format, setDefaultOptions } from 'date-fns';
import { de, enUS, es, fr, it, ja, pt } from 'date-fns/locale';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

export const useDateFns = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const locale =
      {
        es: es,
        de: de,
        fr: fr,
        pt: pt,
        ja: ja,
        it: it,
      }[i18n.language] || enUS;

    setDefaultOptions({ locale });
  }, [i18n.language]);

  return {
    format,
  };
};
