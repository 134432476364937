import * as React from "react";
import { SVGProps } from "react";
const SvgEventEdited = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 20V6h18v14c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1Z"
      fill="url(#EventEdited_svg__a)"
    />
    <path
      opacity={0.05}
      d="M18.698 21 21 18.697v-4.396L14.302 21h4.396Z"
      fill="currentColor"
    />
    <path
      opacity={0.07}
      d="M18.344 21 21 18.344v-3.69L14.655 21h3.689Z"
      fill="currentColor"
    />
    <path
      d="M21 4v3H3V4c0-.55.45-1 1-1h16c.55 0 1 .45 1 1Z"
      fill="url(#EventEdited_svg__b)"
    />
    <path
      opacity={0.05}
      d="M17.75 6.25a1.5 1.5 0 0 1-1.5-1.5V3h3v1.75a1.5 1.5 0 0 1-1.5 1.5Z"
      fill="currentColor"
    />
    <path
      opacity={0.07}
      d="M17.75 5.875a1.125 1.125 0 0 1-1.125-1.125V3h2.25v1.75c0 .622-.503 1.125-1.125 1.125Z"
      fill="currentColor"
    />
    <path
      opacity={0.05}
      d="M6.25 6.25a1.5 1.5 0 0 1-1.5-1.5V3h3v1.75a1.5 1.5 0 0 1-1.5 1.5Z"
      fill="currentColor"
    />
    <path
      opacity={0.07}
      d="M6.25 5.875A1.125 1.125 0 0 1 5.125 4.75V3h2.25v1.75c0 .622-.503 1.125-1.125 1.125Z"
      fill="currentColor"
    />
    <path
      d="M6.25 5.5a.75.75 0 0 1-.75-.75v-2a.75.75 0 0 1 1.5 0v2a.75.75 0 0 1-.75.75Z"
      fill="#3CCBF4"
    />
    <path
      d="M19 9.75v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25ZM19 13.25v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25ZM15 16.75v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Zm-4 0v1.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25Z"
      fill="#fff"
    />
    <path
      d="M17.75 5.5a.75.75 0 0 1-.75-.75v-2a.75.75 0 0 1 1.5 0v2a.75.75 0 0 1-.75.75Z"
      fill="#3CCBF4"
    />
    <path
      d="m23.89 14.57-.96-.96a.375.375 0 0 0-.531 0l-.452.453 1.49 1.49.453-.452a.376.376 0 0 0 0-.53Z"
      fill="#C94F60"
    />
    <path
      d="M14.502 21.508 14 23.5l1.992-.502.21-1.728-1.7.238Z"
      fill="#F0F0F0"
    />
    <path
      d="m22.166 16.824-6.174 6.174-1.49-1.49 6.174-6.174 1.49 1.49Z"
      fill="#EDBE00"
    />
    <path
      d="m20.675 15.334 1.271-1.272 1.492 1.49-1.272 1.272-1.491-1.49Z"
      fill="url(#EventEdited_svg__c)"
    />
    <path d="M14.254 22.492 14 23.5l1.007-.254-.753-.754Z" fill="#787878" />
    <defs>
      <linearGradient
        id="EventEdited_svg__a"
        x1={3.207}
        y1={6.831}
        x2={16.689}
        y2={16.677}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28AFEA" />
        <stop offset={1} stopColor="#047ED6" />
      </linearGradient>
      <linearGradient
        id="EventEdited_svg__b"
        x1={3.21}
        y1={3.304}
        x2={20.599}
        y2={6.84}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0077D2" />
        <stop offset={1} stopColor="#0B59A2" />
      </linearGradient>
      <linearGradient
        id="EventEdited_svg__c"
        x1={22.056}
        y1={14.156}
        x2={22.056}
        y2={16.901}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEDEDE" />
        <stop offset={1} stopColor="#D6D6D6" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgEventEdited;
