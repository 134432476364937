import * as React from "react";
import { SVGProps } from "react";

const SvgAppAppleTv = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#app-apple-tv_svg__a)">
      <path
        d="M19.485.005h-13.6a5.2 5.2 0 0 0-5.2 5.2v13.6a5.2 5.2 0 0 0 5.2 5.2h13.6a5.2 5.2 0 0 0 5.2-5.2v-13.6a5.2 5.2 0 0 0-5.2-5.2Z"
        fill="url(#app-apple-tv_svg__b)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.995 11.773a1.961 1.961 0 0 1 .938-1.65 2.029 2.029 0 0 0-1.586-.86c-.674-.068-1.318.4-1.66.4-.342 0-.87-.388-1.43-.376a2.116 2.116 0 0 0-1.8 1.086c-.764 1.326-.2 3.292.55 4.368.364.524.8 1.118 1.368 1.096.568-.022.756-.354 1.42-.354.664 0 .85.354 1.432.342.582-.012.964-.536 1.326-1.064.259-.38.46-.796.6-1.234a1.905 1.905 0 0 1-1.158-1.754Zm-1.09-3.22c.327-.384.489-.883.45-1.386-.492.05-.946.285-1.27.658a1.8 1.8 0 0 0-.46 1.34 1.6 1.6 0 0 0 1.28-.612Z"
        fill="#F9F9F9"
      />
      <path
        d="M15.085 10.575v-1h-1.218v-1.4h-1.082v1.4h-.944v1h.944v3.63a1.626 1.626 0 0 0 1.624 1.624h.542v-1.076h-.542a.545.545 0 0 1-.542-.548v-3.63h1.218Zm5.06-1.28-1.624 4.876-1.626-4.876H15.81l2.168 6.51h1.084l2.166-6.502-1.084-.008Z"
        fill="#F9F9F9"
      />
    </g>
    <defs>
      <linearGradient
        id="app-apple-tv_svg__b"
        x1={12.685}
        y1={0.307}
        x2={12.685}
        y2={24.277}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#333" />
        <stop offset={1} stopColor="#111" />
      </linearGradient>
      <clipPath id="app-apple-tv_svg__a">
        <path fill="#fff" transform="translate(.685 .005)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAppAppleTv;
