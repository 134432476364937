import * as React from "react";

function SvgClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#close_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 10.586l6.363-6.364 1.415 1.414L13.414 12l6.364 6.364-1.415 1.414L12 13.414l-6.364 6.364-1.415-1.414L10.585 12 4.221 5.636l1.415-1.414L12 10.586z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="close_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgClose;
