import * as React from 'react';
import { SVGProps } from 'react';

const SvgAddMembers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M19.75 3.5H4.25A2.753 2.753 0 0 0 1.5 6.25v11.5a2.753 2.753 0 0 0 2.75 2.75h15.5a2.753 2.753 0 0 0 2.75-2.75V6.25a2.753 2.753 0 0 0-2.75-2.75Zm-3.153 7.918-1.933 1.864.457 2.633a.5.5 0 0 1-.723.529L12 15.197l-2.398 1.247a.5.5 0 0 1-.723-.53l.457-2.633-1.933-1.864a.5.5 0 0 1 .276-.855l2.676-.385 1.198-2.401c.17-.34.725-.34.895 0l1.197 2.4 2.677.386a.499.499 0 0 1 .275.856Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgAddMembers;
