import * as React from "react";

function SvgTempTabHome(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2815 1.70737L21.1385 7.56151C21.5001 7.87642 21.7889 8.26247 21.9859 8.69414C22.1829 9.12581 22.2836 9.59327 22.2814 10.0657V19.804C22.2814 20.6895 21.9202 21.5388 21.2772 22.1649C20.6342 22.7911 19.7622 23.1429 18.8528 23.1429H5.13866C4.22935 23.1429 3.35729 22.7911 2.71431 22.1649C2.07134 21.5388 1.71012 20.6895 1.71012 19.804V10.0768C1.70624 9.60253 1.80616 9.13292 2.00322 8.69922C2.20028 8.26551 2.48996 7.87767 2.85296 7.56151L9.71006 1.70737C10.3386 1.15985 11.1524 0.857147 11.9958 0.857147C12.8391 0.857147 13.6529 1.15985 14.2815 1.70737ZM19.661 20.591C19.8753 20.3823 19.9957 20.0992 19.9957 19.804V10.0657C19.9955 9.90763 19.9607 9.75146 19.8937 9.60754C19.8267 9.46361 19.729 9.33523 19.6071 9.23094L12.75 3.38793C12.5415 3.2095 12.2734 3.1111 11.9958 3.1111C11.7181 3.1111 11.45 3.2095 11.2415 3.38793L4.38438 9.23094C4.26248 9.33523 4.16477 9.46361 4.09777 9.60754C4.03078 9.75146 3.99602 9.90763 3.99581 10.0657V19.804C3.99581 20.0992 4.11622 20.3823 4.33055 20.591C4.54487 20.7997 4.83556 20.917 5.13866 20.917H6.71001V20.9171H17.71V20.917H18.8528C19.156 20.917 19.4466 20.7997 19.661 20.591Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTempTabHome;
