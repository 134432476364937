import * as React from "react";

function SvgAppMsOffice(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-ms-office_svg__clip0)">
        <path
          d="M2 19.36V4.769l12.907-4.77 7.015 2.244v19.642L14.907 24 2 19.36l12.907 1.553V3.928L6.49 5.892v11.505L2 19.36z"
          fill="#EB3C00"
        />
      </g>
      <defs>
        <clipPath id="app-ms-office_svg__clip0">
          <path
            fill="#fff"
            transform="translate(2 -.001)"
            d="M0 0h19.922v24H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppMsOffice;
