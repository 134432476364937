import * as React from "react";

function SvgDog(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.019 33.75H25.2a6.12 6.12 0 01-5.2-2.906 6.094 6.094 0 01-5.2 2.906h-1.819a6.113 6.113 0 01-6.106-6.106v-2.556a14.144 14.144 0 012.106-7.438l.775-1.25a14.436 14.436 0 001.581-3.6c.36-1.267.541-2.577.538-3.894a5.788 5.788 0 015.781-5.781h4.688a5.787 5.787 0 015.781 5.781 14.243 14.243 0 002.119 7.5l.775 1.25a14.15 14.15 0 012.106 7.431v2.557a6.112 6.112 0 01-6.106 6.106z"
        fill="#212121"
      />
      <path
        d="M20 28.75a.625.625 0 01.594.425A4.862 4.862 0 0025.2 32.5h1.819a4.863 4.863 0 004.856-4.856v-2.556a12.9 12.9 0 00-1.919-6.782l-.775-1.25a15.494 15.494 0 01-2.306-8.125 4.536 4.536 0 00-4.531-4.556h-4.688a4.538 4.538 0 00-4.53 4.531c0 1.427-.198 2.847-.588 4.219-.278.98-.651 1.93-1.113 2.838-.187.362-.394.725-.625 1.075l-.775 1.25a12.9 12.9 0 00-1.9 6.8v2.556a4.863 4.863 0 004.856 4.856h1.82a4.85 4.85 0 004.605-3.325.625.625 0 01.594-.425z"
        fill="#CFD8DC"
      />
      <path
        d="M20 36.875a6.875 6.875 0 01-5.956-3.438.625.625 0 01.543-.937h.213a4.85 4.85 0 004.606-3.325.624.624 0 01.96-.308.624.624 0 01.228.308A4.862 4.862 0 0025.2 32.5h.212a.625.625 0 01.544.938A6.875 6.875 0 0120 36.874z"
        fill="#212121"
      />
      <path
        d="M15.744 33.675a5.626 5.626 0 008.506 0A6.131 6.131 0 0120 30.844a6.095 6.095 0 01-4.256 2.831z"
        fill="#90A4AE"
      />
      <path
        d="M20 30a.624.624 0 01-.625-.625v-2.5a.624.624 0 111.25 0v2.5A.624.624 0 0120 30z"
        fill="#212121"
      />
      <path
        d="M20.469 27.344h-.938a2.032 2.032 0 010-4.063h.938a2.032 2.032 0 010 4.063z"
        fill="#212121"
      />
      <path
        d="M19.531 24.531a.782.782 0 000 1.563h.938a.782.782 0 000-1.563h-.938z"
        fill="#8D6E63"
      />
      <path
        d="M5.044 22.319a1.82 1.82 0 01-.67-.131 1.782 1.782 0 01-1.13-1.732l.18-5.212A11.019 11.019 0 0113.988 4.619a.625.625 0 01.47 1.069 4.48 4.48 0 00-1.332 3.218c0 1.427-.197 2.847-.588 4.22-.278.979-.65 1.929-1.112 2.837a.625.625 0 01-.088.13l-4.95 5.626a1.789 1.789 0 01-1.343.6z"
        fill="#212121"
      />
      <path
        d="M12.631 6.038A9.775 9.775 0 004.67 15.28l-.181 5.213a.55.55 0 00.962.381l4.894-5.544c.408-.815.74-1.666.987-2.543.36-1.263.544-2.569.544-3.882a5.738 5.738 0 01.756-2.868z"
        fill="#90A4AE"
      />
      <path
        d="M34.956 22.319a1.787 1.787 0 01-1.337-.625l-4.95-5.625a.626.626 0 01-.088-.131c-.462-.9-.837-1.842-1.118-2.813a15.394 15.394 0 01-.588-4.219 4.483 4.483 0 00-1.325-3.2.625.625 0 01.438-1.069 11.025 11.025 0 0110.587 10.625l.181 5.213a1.781 1.781 0 01-1.13 1.712c-.213.086-.44.13-.67.132z"
        fill="#212121"
      />
      <path
        d="M29.65 15.331l4.9 5.544a.55.55 0 00.963-.381l-.181-5.213a9.78 9.78 0 00-7.963-9.237c.498.871.76 1.858.756 2.862a14.14 14.14 0 00.538 3.888c.249.875.58 1.724.987 2.537z"
        fill="#90A4AE"
      />
      <path
        d="M16.875 18.125a.624.624 0 01-.625-.625v-1.25a.624.624 0 111.25 0v1.25a.624.624 0 01-.625.625zm6.25 0a.624.624 0 01-.625-.625v-1.25a.624.624 0 111.25 0v1.25a.624.624 0 01-.625.625z"
        fill="#212121"
      />
    </svg>
  );
}

export default SvgDog;
