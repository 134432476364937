import { getCloudinaryUrl } from '@frond/shared';
import { SystemProps, x } from '@xstyled/styled-components';
import React from 'react';

import { Image } from '../../../../generated/types-and-hooks';
import { NextLink } from '../../common/components/NextLink';
import { getAnswerNavigationLinkProps } from '../../questions/hooks/useAnswerNavigation';

export const PostCardImage = ({
  image,
  linkProps,
  onClick,
  ...system
}: {
  image: Image;
  linkProps?: ReturnType<typeof getAnswerNavigationLinkProps> | null;
  onClick?: () => void;
} & SystemProps<HTMLImageElement>) => {
  const img = (
    <x.img
      alt="Post for group"
      w="full"
      h="full"
      src={getCloudinaryUrl({
        id: image.publicId,
        transform: 'q_auto',
        coordinates: image.coordinates,
      })}
      onClick={onClick}
      {...system}
    />
  );
  if (!linkProps) return img;

  return (
    <NextLink href={linkProps.href} as={linkProps.as}>
      {img}
    </NextLink>
  );
};
