import { Portal } from '@reach/portal';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { IS_DEMO } from '../../../config/constants';
import { Alert } from '../../common/components/Alert';
import { RestrictedDemoActionModal } from '../../demo/RestrictedDemoActionModal';
import { GroupsOnboarding } from './GroupsOnboarding';

export const GroupsOnboardingModal: React.FC<{
  isOpen: boolean;
  onDismiss: () => void;
}> = ({ isOpen = false, onDismiss }) => {
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);

  if (IS_DEMO) {
    return <RestrictedDemoActionModal isOpen={isOpen} onDismiss={onDismiss} />;
  }

  return (
    <Portal>
      <x.div
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex="modal"
        display={isOpen ? 'block' : 'none'}
        backgroundColor="gray.300"
        overflowY="scroll"
      >
        <GroupsOnboarding onDismiss={onDismiss} showCreateOrganization />
        {showAlert && (
          <Alert
            variant="warning"
            headingText={t('warning_discard_community_title')}
            descriptionText={t('warning_discard_community_body')}
            cancelButtonText={t('cancel')}
            onCancelClick={() => setShowAlert(false)}
            submitButtonText={t('discard')}
            onSubmitClick={() => {
              setShowAlert(false);
              onDismiss();
            }}
          />
        )}
      </x.div>
    </Portal>
  );
};
