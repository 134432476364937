import { formatGroupName } from '@frond/shared';
import {
  ListboxButton,
  ListboxInput,
  ListboxList,
  ListboxOption,
  ListboxPopover,
} from '@reach/listbox';
import styled, { system, SystemProps, th, x } from '@xstyled/styled-components';
import { format } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import {
  GroupCoreFragment,
  GroupTypes,
  PostCreationPolicy,
  PostTypes,
  useEventsQuery,
} from '../../../../generated/types-and-hooks';
import { GROUPS_PER_PAGE_SELECTOR } from '../../../config/constants';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useIsViewerAdmin } from '../../auth/hooks/useViewer';
import { Icon } from '../../common/components/Icon';
import { Text } from '../../common/components/Text';
import { useCanViewerPostToGroup } from '../../groups/hooks/useCanViewerPostToGroup';
import { PostComposerGroup } from './PostComposer';

const StyledListboxPopover = styled(ListboxPopover)<{ maxHeight?: string }>`
  background-color: white;
  z-index: popover;
  margin-top: 1;
  border-radius: md;
  overflow-y: scroll;
  padding: 0;
  box-sizing: border-box;
  box-shadow: sm;
  border: none;
  max-width: 664px;
  min-width: auto;
  ${(p) => p.maxHeight && `max-height: ${p.maxHeight};`}

  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  animation: slide-down 0.2s ease;

  &:focus-within {
    outline: none;
  }
`;

const StyledListboxButton = styled(ListboxButton)<SystemProps>`
  border: 1px solid ${th.color('gray.200')};
  border-radius: md;
  box-sizing: border-box;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition-property: ${th('transitions.property.colors')};
  transition-duration: ${th('transitions.duration.faster')};
  transition-timing-function: linear;

  &:hover {
    border-color: gray.300;
  }

  &[aria-expanded='true'] {
    border-color: brand.200;

    svg {
      transform: rotate(180deg);
    }
  }

  ${system};
`;

const StyledListboxOption = styled(ListboxOption)`
  color: gray.500;
  cursor: default;
  padding: 6px 0;

  &:hover {
    color: brand.300;
  }

  &[data-reach-listbox-option][data-current-selected] {
    color: brand.300;
  }

  &[data-reach-listbox-option][data-current-nav] {
    color: brand.300;
    background-color: transparent;
  }
`;

const StyledListboxList = styled(ListboxList)`
  padding: 10px 4;
  width: 100%;
  box-sizing: border-box;
`;

export interface PostComposerGroupSelectorProps {
  name: string;
  groups: GroupCoreFragment[];
  onChange: (group: PostComposerGroup) => void;
  value?: PostComposerGroup;
  disabled?: boolean;
  type: PostTypes;
  showEvents?: boolean;
}

export const PostComposerGroupSelector = ({
  groups,
  onChange,
  value,
  disabled,
  type,
  showEvents = true,
}: PostComposerGroupSelectorProps) => {
  const { t } = useTranslation();
  const { organization } = useOrganization();
  const isViewerAdmin = useIsViewerAdmin(organization);

  const groupsOrCourses = groups.filter((g) => {
    if (type === PostTypes.Lesson) {
      return g.groupType === GroupTypes.Course;
    } else {
      return g.groupType !== GroupTypes.Course;
    }
  });

  const { data: upcomingEventsData } = useEventsQuery({
    variables: {
      organizationId: organization.id,
      first: GROUPS_PER_PAGE_SELECTOR,
    },
  });

  const { data: pastEventsData } = useEventsQuery({
    variables: {
      organizationId: organization.id,
      first: GROUPS_PER_PAGE_SELECTOR,
      past: true,
    },
  });

  const eventsEdges = [
    ...(upcomingEventsData ? upcomingEventsData.events.edges : []),
    ...(pastEventsData ? pastEventsData.events.edges : []),
  ];

  const events = eventsEdges.filter((event) => {
    return (
      (event.node.membership && isViewerAdmin) ||
      event.node.postCreationPolicy === PostCreationPolicy.Unrestricted
    );
  });

  const groupIsNotHidden =
    groups.find((g) => g.id === value?.id) ||
    events.find((g) => g.node.id === value?.id);

  const getText = () =>
    value && groupIsNotHidden
      ? formatGroupName(value)
      : type === PostTypes.NewUser
        ? t('your_profile')
        : t('select_group');

  return (
    <x.div boxSizing="border-box">
      <ListboxInput
        onChange={(value: string) => {
          const group = groups.find((g) => g.id === value);
          const event = events.find((e) => e.node.id === value);
          if (group) {
            onChange(group);
          } else if (event) {
            onChange(event.node);
          }
        }}
        value={value?.id}
        defaultValue=""
        disabled={type === PostTypes.NewUser || disabled}
      >
        <StyledListboxButton
          px={3}
          py={{
            _: 3,
            sm: 1.5,
          }}
        >
          <Text
            display={{
              _: 'block',
              sm: 'none',
            }}
            variant="md"
            color="gray.300"
          >
            Group
          </Text>
          <x.div
            display="flex"
            w={{
              _: 'auto',
              sm: 'full',
            }}
            spaceX={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Text truncate>{getText()}</Text>
            <Icon name="chevron-bottom" size="4" color="gray.300" />
          </x.div>
        </StyledListboxButton>
        <StyledListboxPopover maxHeight="45vh">
          {groupsOrCourses.length > 0 && (
            <StyledListboxList>
              <Text variant="sm-semibold">
                {type === PostTypes.Lesson ? t('courses') : t('groups')}
              </Text>
              {groupsOrCourses.map((groupOrCourse) => (
                <GroupOption key={groupOrCourse.id} group={groupOrCourse} />
              ))}
            </StyledListboxList>
          )}
          {events && events.length > 0 && showEvents && (
            <StyledListboxList>
              <Text variant="sm-semibold">{t('events')}</Text>
              {events?.map(({ node }) => (
                <GroupOption key={node.id} group={node} />
              ))}
            </StyledListboxList>
          )}
        </StyledListboxPopover>
      </ListboxInput>
    </x.div>
  );
};

const GroupOption: FC<{
  group: {
    id: string;
    name: string;
    emoji: string;
    groupType: GroupTypes;
    postCreationPolicy: PostCreationPolicy;
    startAt?: string;
    series?: {
      id: string;
    } | null;
    isPrivate?: boolean | null;
  };
}> = ({ group }) => {
  const canPost = useCanViewerPostToGroup(group);

  if (!canPost) {
    return null;
  }

  const label = formatGroupName(group);
  const value = group.id;
  const prefix =
    group.series && group.startAt
      ? `${format(new Date(group.startAt), 'MM/dd')} - `
      : '';

  return (
    <StyledListboxOption key={value} value={value}>
      <x.div display="flex" alignItems="center" spaceX={2}>
        <Text truncate>
          {prefix}
          {label}
        </Text>
        {group.isPrivate && <Icon name="lock" size="4" color="gray.300" />}
      </x.div>
    </StyledListboxOption>
  );
};
