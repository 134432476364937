import {
  getRandomAvatar,
  getRandomAvatarBgColor,
  routes,
  theme,
  URL_TRANSFORMATION,
} from '@frond/shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { MouseEventHandler, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { GroupCoreFragment } from '../../../../generated/types-and-hooks';
import { SignInModal } from '../../auth/components/SignIn/SignInModal';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { Avatar } from '../../common/components/Avatar';
import { Form } from '../../common/components/Form';
import { Icon } from '../../common/components/Icon';
import { NextLink } from '../../common/components/NextLink';
import { Text } from '../../common/components/Text';
import { CONTENT_WIDTH } from '../../common/layout/Layout';
import { dashedBorderSVGString, svgStringToImageUrl } from '../../common/utils';
import { useOrganizationEntitlements } from '../../organizations/hooks/useOrganizationEntitlements';
import { PostComposer } from '../../posts/components/PostComposer';
import { PostComposerState } from '../../posts/components/postComposerMachine';
import { PostComposerMiniMenu } from './PostComposerMiniMenu';

type MiniComposerButtonProps = {
  iconName: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  isActive?: boolean;
};

export const MiniComposerButton = ({
  iconName,
  onClick,
  isActive,
}: MiniComposerButtonProps) => {
  return (
    <x.button
      h={11}
      w={11}
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="md"
      border
      cursor="pointer"
      type="button"
      borderColor={{
        _: isActive ? 'brand.200' : 'gray.200',
        hover: 'brand.200',
      }}
      bg="white"
      onClick={onClick}
    >
      <Icon name={iconName} size="4.5" />
    </x.button>
  );
};

type AnswerComposerMiniProps = {
  group?: GroupCoreFragment;
  variant?: 'regular' | 'thin';
};

const FormSchema = yup
  .object({
    url: yup.string().required().transform(URL_TRANSFORMATION).url(),
  })
  .defined();

type FormData = yup.InferType<typeof FormSchema>;

const publicAvatar = getRandomAvatar();
const publicAvatarBgColor = getRandomAvatarBgColor();

export const PostComposerMini = ({
  group,
  variant = 'regular',
}: AnswerComposerMiniProps) => {
  const { t } = useTranslation();
  const { features } = useOrganizationEntitlements();
  const [url, setUrl] = useState('');
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [files, setFiles] = useState<File[] | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const { viewer: user } = useViewer();
  const { organization } = useOrganization();
  const [initialState, setInitialState] = useState<PostComposerState>(
    PostComposerState.REVIEW
  );
  const [pollOptions, setPollOptions] = useState<string[]>();
  const form = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    multiple: true,
    onDrop: (files) => {
      setFiles(files);
      setIsOpen(true);
    },
  });

  const resetState = () => {
    setUrl('');
    setFiles(undefined);
    form.reset();
    setPollOptions(undefined);
    setInitialState(PostComposerState.REVIEW);
  };

  return (
    <>
      <SignInModal
        isOpen={showSignInModal}
        onDismiss={() => setShowSignInModal(false)}
        variant="public"
      />
      <x.div
        {...(variant === 'regular' && {
          boxShadow: { _: 'none', 'sm-2': 'base' },
          borderRadius: { _: 'none', 'sm-2': 'md-xl' },
        })}
        maxW={{ _: 'none', 'sm-2': CONTENT_WIDTH }}
        minWidth={0}
        display="flex"
        boxSizing="border-box"
        w="full"
        backgroundColor={{ _: 'gray.50', 'sm-2': 'white' }}
      >
        <input {...getInputProps()} />
        <x.div
          {...getRootProps()}
          w="full"
          {...(variant === 'regular' && {
            p: { _: 4, 'sm-2': 3 },
          })}
          display="flex"
          spaceX={2}
        >
          {isDragActive ? (
            <x.div
              h={11}
              bg="brand.50"
              w="full"
              borderRadius="md"
              backgroundImage={`url("${svgStringToImageUrl(
                dashedBorderSVGString({
                  color: `${theme.colors.brand[300]}`,
                  dasharray: [`${theme.sizes['3']}`, `${theme.sizes['3']}`],
                  radius: 'md',
                })
              )}")`}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <x.div
                display="flex"
                alignItems="center"
                justifyContent="center"
                spaceX={3}
                color="brand.300"
              >
                <Icon size="6" name="image-add" />
                <Text variant="md-semibold">{t('drop_to_add_media')}</Text>
              </x.div>
            </x.div>
          ) : (
            <>
              {user ? (
                <NextLink
                  href={routes.groups
                    .organization(organization.shortId)
                    .person(user.username)}
                >
                  <Avatar
                    avatar={user.avatar}
                    bgColor={user.avatarBgColor}
                    imageId={user.profileImageId}
                    size="m-2"
                  />
                </NextLink>
              ) : (
                <Avatar
                  avatar={publicAvatar}
                  bgColor={publicAvatarBgColor}
                  size="m-2"
                />
              )}

              <x.div flex="1" w="full" minWidth={0} position="relative">
                <Form<FormData>
                  {...form}
                  onSubmit={(data) => {
                    setUrl(data.url);
                    setIsOpen(true);
                  }}
                >
                  <x.button
                    bg={
                      variant === 'regular'
                        ? { _: 'white', 'sm-2': 'gray.100' }
                        : 'white'
                    }
                    border
                    borderColor={{
                      _: 'gray.200',
                      hover: 'brand.200',
                      focus: 'brand.300',
                    }}
                    borderRadius="md"
                    fontSize="medium"
                    w="full"
                    h="11"
                    textAlign="left"
                    pl={4}
                    boxSizing="border-box"
                    cursor="pointer"
                    color="gray.300"
                    maxW={variant === 'regular' ? 420 : 444}
                    type="button"
                    onClick={() => {
                      if (user) {
                        setIsOpen(true);
                      } else {
                        setShowSignInModal(true);
                      }
                    }}
                    minWidth={0}
                  >
                    <Text truncate>
                      {group
                        ? t('share_something_to_group', { group: group.name })
                        : t('share_something')}
                      ...
                    </Text>
                  </x.button>
                </Form>
              </x.div>
              <x.div display={{ _: 'none ', sm: 'block' }}>
                <MiniComposerButton
                  iconName="video"
                  onClick={() => {
                    if (user) {
                      setInitialState(PostComposerState.VIDEO_CAPTURE);
                      setIsOpen(true);
                    } else {
                      setShowSignInModal(true);
                    }
                  }}
                />
              </x.div>
              <x.div display={{ _: 'none ', sm: 'block' }}>
                <PostComposerMiniMenu
                  variant="mini"
                  onFilesSelect={(files) => {
                    if (user) {
                      setFiles(files);
                      setIsOpen(true);
                    } else {
                      setShowSignInModal(true);
                    }
                  }}
                  onUploadMedia={() => {
                    if (user) {
                      setInitialState(PostComposerState.QR_UPLOAD);
                      setIsOpen(true);
                    } else {
                      setShowSignInModal(true);
                    }
                  }}
                  onSearchGiphy={() => {
                    if (user) {
                      setInitialState(PostComposerState.GIPHY_SEARCH);
                      setIsOpen(true);
                    } else {
                      setShowSignInModal(true);
                    }
                  }}
                />
              </x.div>
              {features.Polls && (
                <>
                  {' '}
                  <x.div display={{ _: 'none ', sm: 'block' }}>
                    <MiniComposerButton
                      iconName="poll"
                      onClick={() => {
                        if (user) {
                          setPollOptions(['', '']);
                          setIsOpen(true);
                        } else {
                          setShowSignInModal(true);
                        }
                      }}
                    />
                  </x.div>
                </>
              )}
            </>
          )}
        </x.div>
      </x.div>
      {isOpen && (
        <PostComposer
          isOpen={isOpen}
          onDismiss={() => {
            resetState();
            setIsOpen(false);
          }}
          url={url}
          files={files}
          initialState={initialState}
          group={group}
          pollOptions={pollOptions}
        />
      )}
    </>
  );
};
