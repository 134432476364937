import { useFormContext } from 'react-hook-form';

import {
  GroupCoreFragment,
  refetchGroupQuery,
  useUpdateGroupMutation,
} from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { useUserCanEditGroup } from '../../questions/utils/questions';
import { CoverImage } from './CoverImage';
import { GroupCreateFormData } from './GroupCreateModal/GroupCreateForm';

export const GroupCover: React.FC<{
  group: GroupCoreFragment;
  showAddCoverButton?: boolean;
  buttonInset?: number;
  variant?: 'placeholder';
}> = ({ group, showAddCoverButton, buttonInset, variant }) => {
  const { viewer: user } = useViewer();
  const { organization } = useOrganization();
  const canEditGroup = useUserCanEditGroup(group, organization, user);

  const [mutateUpdateGroup, { loading }] = useUpdateGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      refetchGroupQuery({
        id: group.id,
      }),
    ],
  });

  const handleRemoveCover = () => {
    mutateUpdateGroup({
      variables: {
        input: {
          id: group.id,
          coverImageId: null,
        },
      },
    });
  };

  const handleUploadSuccess = (imageId: string) => {
    group &&
      mutateUpdateGroup({
        variables: {
          input: {
            id: group.id,
            coverImageId: imageId,
          },
        },
      });
  };

  return (
    <CoverImage
      canEditCover={canEditGroup}
      coverImageId={group?.coverImageId}
      onRemoveCover={handleRemoveCover}
      onUploadSuccess={handleUploadSuccess}
      loading={loading}
      showAddCoverButton={showAddCoverButton}
      buttonInset={buttonInset}
      variant={variant}
    />
  );
};

export const GroupFormCover: React.FC<{ buttonInset?: number }> = ({
  buttonInset,
}) => {
  const form = useFormContext<GroupCreateFormData>();
  const coverImageId = form.watch('coverImageId');

  return (
    <CoverImage
      canEditCover
      coverImageId={coverImageId}
      showAddCoverButton
      variant="placeholder"
      buttonInset={buttonInset}
      onUploadSuccess={(id) => form.setValue('coverImageId', id)}
      onRemoveCover={() => form.setValue('coverImageId', null)}
    />
  );
};
