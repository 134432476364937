import {
  createParagraphElementArray,
  ELEMENT_IMAGE,
  ELEMENT_OL,
  ELEMENT_TAG,
  MyEditor,
  MyPlatePlugin,
  MyValue,
} from '@frond/shared';
import {
  AutoformatPlugin,
  createAutoformatPlugin,
} from '@udecode/plate-autoformat';
import {
  createBoldPlugin,
  createCodePlugin,
  createItalicPlugin,
  createStrikethroughPlugin,
  createUnderlinePlugin,
} from '@udecode/plate-basic-marks';
import { createBlockquotePlugin } from '@udecode/plate-block-quote';
import {
  createExitBreakPlugin,
  createSoftBreakPlugin,
} from '@udecode/plate-break';
import { createCodeBlockPlugin } from '@udecode/plate-code-block';
import {
  createComboboxPlugin,
  TComboboxItemWithData,
} from '@udecode/plate-combobox';
import {
  createHistoryPlugin,
  createReactPlugin,
  findNode,
  focusEditor,
  getStartPoint,
  Plate,
  PlatePlugin,
  TRenderElementProps,
  TRenderLeafProps,
} from '@udecode/plate-common';
import { createHighlightPlugin } from '@udecode/plate-highlight';
import { createKbdPlugin } from '@udecode/plate-kbd';
import { createLinkPlugin } from '@udecode/plate-link';
import {
  ELEMENT_LI,
  ELEMENT_LIC,
  ELEMENT_UL,
  ListPlugin,
  onKeyDownList,
  withList,
} from '@udecode/plate-list';
import {
  ELEMENT_MENTION,
  ELEMENT_MENTION_INPUT,
  isSelectionInMentionInput,
  mentionOnKeyDownHandler,
  MentionPlugin,
  withMention,
} from '@udecode/plate-mention';
import { createParagraphPlugin } from '@udecode/plate-paragraph';
import { createSelectOnBackspacePlugin } from '@udecode/plate-select';
import { createTabbablePlugin } from '@udecode/plate-tabbable';
import styled, { system, SystemProps } from '@xstyled/styled-components';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Editor, Range, Transforms } from 'slate';

import { autoformatRules } from './autoformat/autoformatRules';
import {
  ComposerElement as ComposerElementComponent,
  ComposerLeaf,
} from './ComposerElement';
import { ComposerToolbar } from './ComposerToolbar';
import { MentionDropdownItemProps } from './MentionDropdown';
import { createHeadingPlugin } from './plugins/createHeadingPlugin';
import { createInputEventPlugin } from './plugins/createInputEventPlugin';
import { createMaxCharactersPlugin } from './plugins/maxCharactersPlugin';
import { createMaxLinesPlugin } from './plugins/maxLinesPlugin';
import {
  editableProps,
  optionsExitBreakPlugin,
  optionsSoftBreakPlugin,
} from './plugins/pluginOptions';
import { createSubmitPlugin } from './plugins/supressKeyPlugin';
import { createMyPlugins, useMyPlateSelectors } from './utils/editor.utils';
import { mentionOnChangeHandler } from './utils/mention.utils';

export type ComposerVariant = 'hello' | 'helloText' | 'mentionText';

interface ComposerProps {
  id: string;
  onChange?: (value: MyValue) => void;
  onFocus?: (event: React.FocusEvent<HTMLDivElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLDivElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onSubmit?: (value: MyValue) => void;
  autoFocus?: boolean;
  initialValue?: MyValue;
  placeholder?: string;
  disabled?: boolean;
  maxCharacters?: number;
  maxLinesConfig?: {
    fontHeight: number;
    maxLinesCount: number;
  };
  variant?: ComposerVariant;
  style?: React.CSSProperties | undefined;
  submitShortcut?: string;
}

const AutoFocus = ({ autoFocus = false }: { autoFocus?: boolean }) => {
  const editor = useMyPlateSelectors().editor();
  const isRendered = useMyPlateSelectors().isRendered();

  useEffect(() => {
    if (isRendered && editor && autoFocus) {
      setTimeout(() => {
        focusEditor(editor, getStartPoint(editor, [0]));
      }, 0);
    }
  }, [editor, isRendered, autoFocus]);
  return null;
};

const ComposerWrapper = styled.div<SystemProps>`
  cursor: text;

  [data-slate-editor='true'] > * ~ * {
    margin-top: 2;
  }

  > [disabled] span {
    color: gray.300;
  }

  [data-slate-placeholder='true'] {
    display: none;
    color: gray.200;
    opacity: 1 !important;
    top: 0;
    left: 0;
    position: relative;
  }

  &:has(ul) {
    [data-slate-placeholder='true'] {
      display: none !important;
    }
  }

  ${system}
`;

export const Composer: React.FC<
  PropsWithChildren<
    ComposerProps & Omit<SystemProps, 'value' | 'event' | 'color' | 'onChange'>
  >
> = ({ variant, children, ...props }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const onChange = (newVal: MyValue) => {
    props.onChange?.(newVal);
  };

  const renderElement = useCallback(
    (props: TRenderElementProps<MyValue>) => (
      <ComposerElementComponent {...props} variant={variant} />
    ),
    [variant]
  );
  const renderLeaf = useCallback(
    (
      props: Omit<TRenderLeafProps<MyValue>, 'attributes' | 'text'> & {
        attributes?: TRenderLeafProps<MyValue>['attributes'];
      }
    ) => <ComposerLeaf {...props} />,
    []
  );

  const plugins = useMemo(() => {
    const mentionPlugin = {
      key: ELEMENT_MENTION,
      isElement: true,
      isInline: true,
      isVoid: true,
      handlers: {
        onChange: mentionOnChangeHandler,
        onKeyDown: mentionOnKeyDownHandler({
          query: isSelectionInMentionInput,
        }),
      },
      withOverrides: withMention,
      component: renderElement,
      options: {
        id: ELEMENT_MENTION,
        trigger: '@',
        insertSpaceAfterMention: true,
        createMentionNode: (item) => {
          let data = {};
          if (
            (item as TComboboxItemWithData<MentionDropdownItemProps>).data
              .type === 'everyone'
          ) {
            data = {
              isEveryone: true,
            };
          } else if (
            (item as TComboboxItemWithData<MentionDropdownItemProps>).data
              .type === 'role'
          ) {
            data = {
              role: { id: item.key },
            };
          } else if (
            (item as TComboboxItemWithData<MentionDropdownItemProps>).data
              .type === 'user'
          ) {
            data = {
              user: { id: item.key },
            };
          }

          return {
            ...data,
            value: item.text,
            key: item.key,
          };
        },
      },
      plugins: [
        {
          key: ELEMENT_MENTION_INPUT,
          component: renderElement,
          isElement: true,
          isInline: true,
        },
      ],
    } as MyPlatePlugin<MentionPlugin>;
    const withTags = (editor: Editor) => {
      const { insertText, deleteBackward } = editor;

      editor.insertText = (text) => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
          const block = Editor.above(editor, {
            // @ts-expect-error plate vs slate
            match: (n) => Editor.isBlock(editor, n),
          });

          if (block) {
            // Check if we are currently in a tag element or starting a new one
            const [tagNodeEntry] = Editor.nodes(editor, {
              // @ts-expect-error plate vs slate
              match: (n) => n.type === ELEMENT_TAG,
              at: selection,
            });

            // Start a new tag if `#` is typed, and we are not in a tag
            if (text === '#' && !tagNodeEntry) {
              const tagElement = {
                type: ELEMENT_TAG,
                children: [{ text: '' }],
              };

              // Insert the empty tag element at the cursor position
              Transforms.insertNodes(editor, tagElement, {
                at: selection,
              });

              // Move the cursor inside the tag element
              Transforms.move(editor, { distance: 1, unit: 'offset' });

              return; // Prevent further processing
            } else if (tagNodeEntry && text === ' ') {
              // Move cursor to a new node or out of the tag element
              Transforms.move(editor, { distance: 1, unit: 'offset' });

              // Add space
              insertText(text);
              return; // Prevent further processing
            }
          }
        }

        // Default behavior for typing text
        insertText(text);
      };

      editor.deleteBackward = (unit) => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
          const [tagNodeEntry] = Editor.nodes(editor, {
            // @ts-expect-error plate vs slate
            match: (n) => n.type === ELEMENT_TAG,
            at: selection,
          });

          if (tagNodeEntry) {
            const [, path] = tagNodeEntry;
            if (
              Editor.string(editor, path) === '' &&
              selection.anchor.offset === 0
            ) {
              Transforms.removeNodes(editor, { at: path });
              return; // Prevent further default deletion behavior
            }
          }
        }

        deleteBackward(unit);
      };

      return editor;
    };

    return createMyPlugins([
      createReactPlugin(),
      createHistoryPlugin(),
      createParagraphPlugin({
        component: renderElement,
      }),
      createInputEventPlugin(),
      createTabbablePlugin({
        options: {
          query: (editor) => {
            const isMention = findNode(editor, {
              match: { type: ELEMENT_MENTION },
            });
            return !isMention;
          },
        },
      }) as MyPlatePlugin<MentionPlugin>,
      ...(props.onSubmit
        ? [
            createSubmitPlugin({
              onSubmit: props.onSubmit,
              shortcut: props.submitShortcut,
            }),
          ]
        : []),
      ...(props.maxLinesConfig
        ? [
            createMaxLinesPlugin({
              maxLinesConfig: props.maxLinesConfig,
              ref,
            }),
          ]
        : []),
      ...(props.maxCharacters
        ? [createMaxCharactersPlugin({ maxCharacters: props.maxCharacters })]
        : []),
      ...(variant !== 'helloText'
        ? ([
            createLinkPlugin({
              component: renderElement,
            }),
            {
              key: ELEMENT_UL,
              isElement: true,
              component: renderElement,
              handlers: {
                onKeyDown: onKeyDownList,
              },
              withOverrides: withList,
              deserializeHtml: {
                rules: [
                  {
                    validNodeName: 'UL',
                  },
                ],
              },
            } as PlatePlugin<ListPlugin>,
            {
              key: ELEMENT_OL,
              isElement: true,
              component: renderElement,
              handlers: {
                onKeyDown: onKeyDownList,
              },
              deserializeHtml: { rules: [{ validNodeName: 'OL' }] },
            } as PlatePlugin<ListPlugin>,
            {
              key: ELEMENT_LI,
              isElement: true,
              component: renderElement,
              deserializeHtml: { rules: [{ validNodeName: 'LI' }] },
            } as PlatePlugin<ListPlugin>,
            {
              key: ELEMENT_LIC,
              component: renderElement,
              isElement: true,
            },
            createBoldPlugin(),
            createCodePlugin({
              component: renderElement,
            }),
            createHighlightPlugin(),
            createItalicPlugin(),
            createUnderlinePlugin(),
            createStrikethroughPlugin(),
            createKbdPlugin(),
            createAutoformatPlugin<
              AutoformatPlugin<MyValue, MyEditor>,
              MyValue
            >({
              options: {
                rules: autoformatRules as any,
                enableUndoOnDelete: true,
              },
            }),
            createSoftBreakPlugin(optionsSoftBreakPlugin),
            createExitBreakPlugin(optionsExitBreakPlugin),
            createSelectOnBackspacePlugin({
              options: {
                query: {
                  allow: [ELEMENT_IMAGE],
                },
              },
            }),
          ] as MyPlatePlugin[])
        : []),
      ...(variant === 'mentionText'
        ? ([mentionPlugin] as MyPlatePlugin[])
        : []),
      ...(variant !== 'hello' && variant !== 'helloText'
        ? ([
            createBlockquotePlugin({
              component: renderElement,
            }),
            createHeadingPlugin({
              options: {
                levels: 3,
              },
              component: renderElement,
            }),
            createCodeBlockPlugin({
              component: renderElement,
            }),
            createComboboxPlugin(),
            mentionPlugin,
            {
              key: ELEMENT_TAG,
              type: ELEMENT_TAG,
              isInline: true,
              isElement: true,
              withOverrides: withTags,
              component: renderElement,
            },
          ] as MyPlatePlugin[])
        : []),
    ]);
  }, [
    renderElement,
    props.onSubmit,
    props.submitShortcut,
    props.maxLinesConfig,
    props.maxCharacters,
    variant,
  ]);

  return (
    <ComposerWrapper
      ref={ref}
      p={props.p}
      pb={props.pb}
      pl={props.pl}
      pt={props.pt}
    >
      <Plate<MyValue, MyEditor>
        id={props.id}
        plugins={plugins}
        editableProps={{
          ...editableProps,
          readOnly: props.disabled,
          disabled: props.disabled,
          placeholder: props.placeholder,
          autoFocus: props.autoFocus,
          spellCheck: true,
          onFocus: props.onFocus,
          onClick: props.onClick,
          onBlur: props.onBlur,
          onKeyDown: props.onKeyDown,
          renderLeaf,
          onPaste: props.onPaste,
          style: props.style,
        }}
        initialValue={props.initialValue || createParagraphElementArray()}
        onChange={onChange}
      >
        {variant !== 'helloText' && (
          <>
            <ComposerToolbar
              zIndex={variant === 'hello' ? 'dropdown' : 'tooltip'}
            />
            <AutoFocus autoFocus={props.autoFocus} />
          </>
        )}
        {children}
      </Plate>
    </ComposerWrapper>
  );
};
