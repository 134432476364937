import { FC } from 'react';

import {
  GroupTypes,
  GroupWithMembersFragment,
} from '../../../../../../generated/graphql-request-api-sdk';
import { TabbedModalContentInset } from '../../../../common/components/TabbedModal';
import { GroupEditSettingAutoAdd } from './GroupEditSettingAutoAdd';
import { GroupEditSettingEnableNewPost } from './GroupEditSettingEnableNewPost';
import { GroupEditSettingIncludePostsOnHome } from './GroupEditSettingIncludePostsOnHome';
import { GroupEditSettingPermissions } from './GroupEditSettingPermissions';
import { GroupEditSettingPrivate } from './GroupEditSettingPrivate';
import { GroupEditSettingsReminderForm } from './GroupEditSettingsReminderForm';

export const GroupEditSettingsTab: FC<{
  group: GroupWithMembersFragment;
}> = ({ group }) => {
  return (
    <TabbedModalContentInset>
      {group.groupType !== GroupTypes.Welcome ? (
        <GroupEditSettingPrivate group={group} first />
      ) : null}
      <GroupEditSettingAutoAdd group={group} />
      <GroupEditSettingPermissions group={group} />
      <GroupEditSettingsReminderForm group={group} />
      <GroupEditSettingEnableNewPost group={group} />
      <GroupEditSettingIncludePostsOnHome group={group} last />
    </TabbedModalContentInset>
  );
};
