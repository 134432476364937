import {
  OnboardingProfileInputSchema,
  OnboardingProfileInputSchemaNoOrg,
} from '@frond/shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  refetchViewerQuery,
  useProfileSetupMutation,
  useUsernameAvailableLazyQuery,
  ViewerQuery,
} from '../../../../../generated/types-and-hooks';
import { Form } from '../../../common/components/Form';
import { AppMachineOrganization } from '../../../common/machine/appMachine';
import { StepHeading } from '../groups/layout/StepHeading';
import {
  ProfileForm,
  ProfileFormContext,
  ProfileFormData,
} from './ProfileForm';
import { StepDataTest, StepView } from './StepView';

export interface ProfileCommonStepProps {
  user?: ViewerQuery['viewer'];
  organization?: AppMachineOrganization | null;
  onCompleted: () => void;
  loading?: boolean;
  dataTest?: StepDataTest;
  context: ProfileFormContext;
  showOrganization?: boolean;
}

/**
 * Common profile form component for onboarding in Groups and Hello
 */
export const ProfileCommonStep: React.FC<ProfileCommonStepProps> = ({
  user,
  organization,
  onCompleted,
  loading,
  dataTest,
  context,
  showOrganization,
}) => {
  const [checkIfUsernameAvailable] = useUsernameAvailableLazyQuery({
    fetchPolicy: 'network-only',
  });

  const { t, i18n } = useTranslation();
  const additions = {
    username: yup
      .string()
      .max(255)
      .required()
      .matches(/^[a-zA-Z0-9._]+$/)
      .test('availability', async (username) => {
        if (!username) return false;

        if (username === user?.username) return true;

        const result = await checkIfUsernameAvailable({
          variables: {
            username,
          },
        });

        return result.data?.usernameAvailable.__typename === 'Username';
      }),
  };

  const form = useForm<ProfileFormData>({
    resolver: yupResolver(
      !organization?.isSetup && organization?.isGoogleWorkspace
        ? OnboardingProfileInputSchema.shape(additions)
        : OnboardingProfileInputSchemaNoOrg.shape(additions)
    ),
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      avatar: user?.avatar,
      organizationName: organization?.name,
      avatarBgColor: user?.avatarBgColor,
      bio: user?.bio,
      username: user?.username,
    },
    mode: 'all',
  });

  const [mutateProfileSetup, { loading: profileSetupLoading }] =
    useProfileSetupMutation({
      refetchQueries: [refetchViewerQuery()],
      onCompleted,
    });

  const handleSubmit = useCallback(
    (data: ProfileFormData) => {
      mutateProfileSetup({
        variables: {
          input: {
            ...data,
            organizationId: organization?.id,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            language: i18n.language,
            username: data.username.toLowerCase(),
          },
        },
      });
    },
    [mutateProfileSetup, organization, i18n]
  );

  // Reset form when organization is fetched
  useEffect(() => {
    form.reset({
      ...form.formState.defaultValues,
      organizationName: organization?.name,
    });
  }, [organization, form]);

  return (
    <>
      <StepHeading
        title={t('profile_setup_title')}
        text={t('profile_setup_description')}
      />
      <Form<ProfileFormData> {...form} onSubmit={handleSubmit} w="100%">
        <StepView loading={profileSetupLoading || loading} dataTest={dataTest}>
          <ProfileForm
            user={user}
            organization={organization}
            context={context}
            showOrganization={showOrganization}
          />
        </StepView>
      </Form>
    </>
  );
};
