import styled, { css, th } from '@xstyled/styled-components';

/**
 * Component for restricting summary cell height
 * Uses line-clamp where supported to add ellipsis, falls
 * back to max-height
 */
export const LineClamp = styled.article<{
  maxLines: number;
}>`
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  visibility: visible;

  ${(p) =>
    p.maxLines &&
    css`
      -webkit-line-clamp: ${p.maxLines};
      max-height: calc(${p.maxLines} * ${th('sizes.6')});
    `}/* stylelint-enable */
`;
