import * as React from "react";

function SvgSloth(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 36.875a.624.624 0 01-.262-.056l-8.307-3.832A14.312 14.312 0 015.075 27.2a14.269 14.269 0 01-1.95-7.2c0-.462 0-.931.056-1.381A16.95 16.95 0 0120 3.125 16.875 16.875 0 0136.875 20a14.376 14.376 0 01-8.306 12.987l-8.306 3.832a.624.624 0 01-.263.056z"
        fill="#212121"
      />
      <path
        d="M20 4.375A15.694 15.694 0 004.431 18.75c-.056.394-.056.825-.056 1.25 0 2.313.614 4.584 1.781 6.581.088.157.182.306.275.457a13.069 13.069 0 005.525 4.837L20 35.563l8.044-3.688A13.125 13.125 0 0035.625 20 15.625 15.625 0 0020 4.375z"
        fill="#E0E0E0"
      />
      <path
        d="M20 27.2c-.43.001-.852-.13-1.206-.375-2.244-1.563-2.544-2.275-2.544-2.762a2.188 2.188 0 012.188-2.188h3.125a2.188 2.188 0 012.187 2.188c0 .487-.3 1.2-2.544 2.762A2.113 2.113 0 0120 27.2z"
        fill="#212121"
      />
      <path
        d="M18.438 23.125a.938.938 0 00-.938.938c.044.118.531.706 2.006 1.73a.857.857 0 00.988 0c1.475-1.024 1.956-1.612 2.012-1.78a.937.937 0 00-.944-.888h-3.125z"
        fill="#424242"
      />
      <path
        d="M20 29.288a13.424 13.424 0 01-10.487-5.15.625.625 0 11.975-.782 12.006 12.006 0 0019.025 0 .625.625 0 11.975.781A13.426 13.426 0 0120 29.288z"
        fill="#212121"
      />
      <path
        d="M5.625 27.5a.625.625 0 01-.537-.313A14.281 14.281 0 013.125 20c0-.462 0-.931.056-1.381a.625.625 0 01.107-.3l.956-1.4a8.557 8.557 0 016.675-4.107l1.487-.118a3.319 3.319 0 013.531 2.881c.008.135.008.271 0 .406a3.319 3.319 0 01-2.65 3.244 7.344 7.344 0 00-3.012 1.356 7.262 7.262 0 00-2.081 2.544l-2.019 4.05a.625.625 0 01-.55.325z"
        fill="#212121"
      />
      <path
        d="M4.419 18.881c-.044.37-.044.744-.044 1.12a13.037 13.037 0 001.25 5.524l1.475-2.95a8.493 8.493 0 012.463-2.981A8.606 8.606 0 0113.087 18a2.038 2.038 0 001.644-2.019c.005-.085.005-.17 0-.256a2.056 2.056 0 00-2.231-1.781l-1.481.119a7.324 7.324 0 00-5.719 3.53l-.881 1.288z"
        fill="#424242"
      />
      <path
        d="M34.375 27.5a.624.624 0 01-.537-.344l-2.031-4.031a7.255 7.255 0 00-2.1-2.544 7.338 7.338 0 00-3.013-1.356 3.319 3.319 0 01-2.656-3.244 3.404 3.404 0 010-.394 3.306 3.306 0 013.531-2.887l1.494.119a8.575 8.575 0 016.694 4.13l.937 1.37c.06.089.097.192.106.3.038.456.057.918.057 1.38-.001 2.536-.676 5.025-1.957 7.213a.624.624 0 01-.525.288z"
        fill="#212121"
      />
      <path
        d="M27.338 13.938a2.062 2.062 0 00-2.038 1.8 2.155 2.155 0 000 .243A2.038 2.038 0 0026.938 18a8.595 8.595 0 013.525 1.587 8.487 8.487 0 012.462 2.982l1.45 2.956A13.037 13.037 0 0035.625 20c0-.375 0-.75-.043-1.119l-.863-1.25a7.345 7.345 0 00-5.737-3.562l-1.482-.125-.162-.007z"
        fill="#424242"
      />
      <path
        d="M10.625 16.875a.625.625 0 01-.2-1.219l1.875-.625a.624.624 0 01.82.64.627.627 0 01-.426.548l-1.875.625a.626.626 0 01-.194.031zm18.75 0a.626.626 0 01-.2-.031l-1.875-.625a.626.626 0 01.394-1.188l1.875.625a.626.626 0 01-.194 1.22z"
        fill="#9E9E9E"
      />
    </svg>
  );
}

export default SvgSloth;
