import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { x } from '@xstyled/styled-components';
import { PropsWithChildren } from 'react';

import { Icon } from '../../../../common/components/Icon';
import { Text } from '../../../../common/components/Text';
import {
  StyledDropdownMenuButton,
  StyledDropdownMenuContent,
  StyledDropdownMenuItem,
  StyledDropdownSubMenuContent,
} from '../../../../posts/components/PostCard';

export type MenuAction = {
  label: JSX.Element | string;
  onClick?: () => void;
  menuActions?: MenuAction[];
};

export const MemberItemMenuCheckbox: React.FC<
  PropsWithChildren<{
    checked: boolean;
  }>
> = ({ checked, children }) => {
  return (
    <x.div display="flex" flexDirection="row" alignItems="center" spaceX={2}>
      <x.div
        h={5}
        w={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor={checked ? 'brand.300' : 'white'}
        border="default"
        borderColor="gray.300"
        borderRadius="sm"
      >
        {checked && (
          <Icon name="check-mark" position="absolute" color="white" />
        )}
        <x.input
          w="100%"
          h="100%"
          zIndex="overlay"
          cursor="pointer"
          m={0}
          opacity={0}
          type="checkbox"
          checked={checked}
        />
      </x.div>
      {children}
    </x.div>
  );
};

export const MemberItemMenu: React.FC<{
  menuActions: MenuAction[];
  disabled?: boolean;
}> = ({ menuActions, disabled }) => {
  if (!menuActions.length) return null;
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild disabled={disabled}>
        <StyledDropdownMenuButton
          cursor="pointer"
          p="3"
          bg="white"
          borderRadius="sm-md"
          position="relative"
          border={1}
          borderColor="gray.100"
          spaceX={1}
          display="flex"
          alignItems="center"
          color="gray.500"
          disabled={disabled}
        >
          {[...new Array(3)].map((_, i) => {
            return (
              <x.div bg="currentColor" borderRadius={4} key={i} w={1} h={1} />
            );
          })}
        </StyledDropdownMenuButton>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <StyledDropdownMenuContent align="end">
          <x.div p={3} boxShadow="sm" borderRadius="md" spaceY={1}>
            {menuActions.map((action, i) => {
              return !action.menuActions ? (
                <StyledDropdownMenuItem key={i} onSelect={() => {}}>
                  <Text variant="md" onClick={action.onClick}>
                    {action.label}
                  </Text>
                </StyledDropdownMenuItem>
              ) : (
                <x.div p={1}>
                  <DropdownMenu.Sub>
                    <DropdownMenu.SubTrigger>
                      <x.div
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spaceX={6}
                      >
                        <Text variant="md">{action.label}</Text>
                        <Icon name="chevron-right" />
                      </x.div>
                    </DropdownMenu.SubTrigger>
                    <DropdownMenu.Portal>
                      <StyledDropdownSubMenuContent
                        sideOffset={25}
                        alignOffset={-15}
                      >
                        <x.div
                          p={3}
                          boxShadow="sm"
                          borderRadius="md"
                          spaceY={1}
                        >
                          {action.menuActions.map((subAction, i) => (
                            <StyledDropdownMenuItem key={i} onSelect={() => {}}>
                              <Text
                                variant="md"
                                onClick={(event: React.MouseEvent) => {
                                  event.preventDefault();
                                  subAction?.onClick?.();
                                }}
                              >
                                {subAction.label}
                              </Text>
                            </StyledDropdownMenuItem>
                          ))}
                        </x.div>
                      </StyledDropdownSubMenuContent>
                    </DropdownMenu.Portal>
                  </DropdownMenu.Sub>
                </x.div>
              );
            })}
          </x.div>
        </StyledDropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
