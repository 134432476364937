import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';

import { Heading } from '../../../common/components/Heading';
import { Icon } from '../../../common/components/Icon';
import { Text } from '../../../common/components/Text';

export const SignInEmailSuccess: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <x.div
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      spaceY={4}
    >
      <Icon name="EmailInbox" size="11" />
      <Heading variant="xl">{t('auth.check_inbox')}</Heading>
      <Text variant="md">{t('auth.link_sent')}</Text>
    </x.div>
  );
};
