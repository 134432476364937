import { theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import React, { PropsWithChildren, ReactElement } from 'react';

import { FrondLogoMinimal } from '../../../common/components/FrondLogoMinimal';

export interface OnboardingLayoutProps {
  /**
   * product logo (defaults to frond minimal logo)
   */
  logo?: ReactElement;
  backgroundColor: string;
  breadcrumbs?: boolean;
}

export const OnboardingLayout: React.FC<
  PropsWithChildren<OnboardingLayoutProps>
> = ({ backgroundColor, logo, breadcrumbs = false, children }) => {
  return (
    <x.div
      w="full"
      minH="100vh"
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      boxSizing="border-box"
      padding={{
        _: 0,
        largerScreen: 20,
      }}
      backgroundColor={backgroundColor}
    >
      <x.div
        w="10"
        position="absolute"
        top={`${theme.sizes['8']}`}
        left={`${theme.sizes['10']}`}
      >
        {logo ? logo : <FrondLogoMinimal />}
      </x.div>
      <x.div
        backgroundColor="white"
        textAlign="center"
        position="relative"
        w={{
          _: '100%',
          largerScreen: '1001px', // not defined in theme
        }}
        minH={{
          _: '100vh',
          largerScreen: '801px', // not defined in theme
        }}
        borderRadius={{
          _: 'none',
          largerScreen: theme.radii['lg'],
        }}
        pb={5}
        pt={!breadcrumbs ? '29' : undefined}
      >
        {children}
      </x.div>
    </x.div>
  );
};
