import * as React from "react";
import { SVGProps } from "react";
const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.25 2.5c-1.066 0-1.99.611-2.446 1.5H18.75c.69 0 1.25.56 1.25 1.25v12.946a2.75 2.75 0 0 0 1.5-2.446V5.25a2.754 2.754 0 0 0-2.75-2.75H8.25Zm-3 3C3.74 5.5 2.5 6.74 2.5 8.25v10.5c0 1.51 1.24 2.75 2.75 2.75h10.5c1.51 0 2.75-1.24 2.75-2.75V8.25c0-1.51-1.24-2.75-2.75-2.75H5.25Zm0 1.5h10.5c.7 0 1.25.55 1.25 1.25v10.5c0 .7-.55 1.25-1.25 1.25H5.25C4.55 20 4 19.45 4 18.75V8.25C4 7.55 4.55 7 5.25 7Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCopy;
