import { useCallback } from 'react';

import { useCreateUserWebPushSubscriptionMutation } from '../../../../generated/types-and-hooks';
import { useAuth } from '../../auth/hooks/useAuth';

const SW = '/push-sw.js';

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

/**
 * Register the service worker and subscribe to push notifications
 */
export const usePushServiceWorker = () => {
  const [mutate] = useCreateUserWebPushSubscriptionMutation();
  const { isAuthenticated } = useAuth();

  /**
   * Subscribe to notifications
   */
  const registerSubscription = useCallback(async () => {
    const permissionGranted =
      'Notification' in window && Notification.permission === 'granted';

    if (!permissionGranted || !isAuthenticated) {
      return;
    }

    const publicKey = process.env.NEXT_PUBLIC_WEB_PUSH_PUBLIC_KEY;

    if (!publicKey) {
      throw new Error('No web push public key available');
    }

    if ('serviceWorker' in navigator && 'PushManager' in window) {
      const reg = await navigator.serviceWorker.register(SW);

      const sub =
        (await reg.pushManager.getSubscription()) ||
        (await reg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(publicKey),
        }));

      const { endpoint, keys } = sub.toJSON();

      // Persist the subscription in the database
      endpoint &&
        keys &&
        mutate({
          variables: {
            input: { endpoint, keyP256dh: keys.p256dh, keyAuth: keys.auth },
          },
        });
    }
  }, [isAuthenticated, mutate]);

  /**
   * Unsubscribe from notifications without revoking permissions
   */
  const revokeSubscription = useCallback(async () => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      const reg = await navigator.serviceWorker.getRegistration();

      if (!reg) {
        return;
      }

      const sub = await reg.pushManager.getSubscription();
      sub?.unsubscribe();
    }
  }, []);

  return { registerSubscription, revokeSubscription };
};
