import { keyframes, styled, system, x } from '@xstyled/styled-components';
import React from 'react';

const indeterminateProgressAnimation = keyframes`
  0% { transform: translateX(-50%) scaleX(0.1);}
  50% { transform:  translateX(-5%) scaleX(0.65); }
  100% { transform:  translateX(50%) scaleX(0.5); }
`;

const ProgressMeter = styled.divBox`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: md;
  ${system}
`;

const IndeterminateProgressMeter = styled(ProgressMeter)`
  animation: ${indeterminateProgressAnimation} 1s infinite;
`;

type ProgressBarProps = {
  progress?: number;
  h?: number;
  trackColor?: string;
  progressColor?: string;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  h = 2,
  trackColor = 'gray.100',
  progressColor = 'brand.300',
}) => {
  const determinate = typeof progress !== 'undefined';

  return (
    <x.div
      position="relative"
      w="100%"
      h={h}
      backgroundColor={trackColor}
      borderRadius="md"
      overflow="hidden"
    >
      {determinate ? (
        <ProgressMeter
          h={h}
          backgroundColor={progressColor}
          w={`${progress}%`}
        />
      ) : (
        <IndeterminateProgressMeter
          h={h}
          w="100%"
          backgroundColor={progressColor}
        />
      )}
    </x.div>
  );
};
