import { x } from '@xstyled/styled-components';

import {
  OrganizationRoleColor,
  OrganizationRoleCoreFragment,
} from '../../../../../generated/types-and-hooks';
import { Icon } from '../../../common/components/Icon';
import {
  CommunityItemStatus,
  CommunityItemStatusProps,
} from './CommunityItemStatus';
import { COMMUNITY_ROLE_COLOR } from './CommunityRoleItem';

export const getBackgroundColor = (color: OrganizationRoleColor) => {
  if (color === OrganizationRoleColor.Gray) {
    return COMMUNITY_ROLE_COLOR[color][200];
  }
  return COMMUNITY_ROLE_COLOR[color][100];
};

export const CommunityRoleToken: React.FC<
  {
    role: OrganizationRoleCoreFragment;
  } & CommunityItemStatusProps
> = (props) => {
  const { role } = props;
  return (
    <CommunityItemStatus
      backgroundColor={getBackgroundColor(role.color)}
      {...props}
    >
      <x.div display="flex" gap="1" alignItems="center">
        {role.memberSubscriptionPlan && <Icon size="5" name="badge" />}
        {role.label}
      </x.div>
    </CommunityItemStatus>
  );
};
