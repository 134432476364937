import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';

import { useOrganization } from '../../auth/hooks/useOrganization';
import { ComposerElementProps } from './Composer/ComposerElement';
import { NextLink } from './NextLink';
import { Text } from './Text';

export const MentionToken: React.FC<
  ComposerElementProps & {
    dataSlateValue: string;
  }
> = ({ dataSlateValue, attributes, children }) => {
  const { organization } = useOrganization();
  return (
    <x.span
      data-slate-value={dataSlateValue}
      color={{ _: 'blue.300', hover: 'blue.400' }}
      ref={attributes?.ref}
    >
      <NextLink
        href={routes.groups.organization(organization.shortId).team()}
        styleInheritColor
      >
        <Text as="span">@{children}</Text>
      </NextLink>
    </x.span>
  );
};
