import * as React from "react";
import { SVGProps } from "react";
const SvgSwitch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.792 2.5A2.301 2.301 0 0 0 2.5 4.792v10.416A2.301 2.301 0 0 0 4.792 17.5h10.416a2.301 2.301 0 0 0 2.292-2.292V4.792A2.301 2.301 0 0 0 15.208 2.5H4.792Zm0 1.25h10.416c.583 0 1.042.46 1.042 1.042v10.416c0 .583-.46 1.042-1.042 1.042H4.792c-.583 0-1.042-.46-1.042-1.042V4.792c0-.583.46-1.042 1.042-1.042ZM5.417 5A.417.417 0 0 0 5 5.417v1.666c0 .23.187.417.417.417h1.666c.23 0 .417-.187.417-.417V5.417A.417.417 0 0 0 7.083 5H5.417Zm3.75 0a.417.417 0 0 0-.417.417v1.666c0 .23.187.417.417.417h1.666c.23 0 .417-.187.417-.417V5.417A.417.417 0 0 0 10.833 5H9.167Zm3.75 0a.417.417 0 0 0-.417.417v1.666c0 .23.187.417.417.417h1.666c.23 0 .417-.187.417-.417V5.417A.417.417 0 0 0 14.583 5h-1.666Zm-7.5 3.75A.417.417 0 0 0 5 9.167v1.666c0 .23.187.417.417.417h1.666c.23 0 .417-.187.417-.417V9.167a.417.417 0 0 0-.417-.417H5.417Zm3.75 0a.417.417 0 0 0-.417.417v1.666c0 .23.187.417.417.417h1.666c.23 0 .417-.187.417-.417V9.167a.417.417 0 0 0-.417-.417H9.167Zm3.75 0a.417.417 0 0 0-.417.417v1.666c0 .23.187.417.417.417h1.666c.23 0 .417-.187.417-.417V9.167a.417.417 0 0 0-.417-.417h-1.666Zm-7.5 3.75a.417.417 0 0 0-.417.417v1.666c0 .23.187.417.417.417h1.666c.23 0 .417-.187.417-.417v-1.666a.417.417 0 0 0-.417-.417H5.417Zm3.75 0a.417.417 0 0 0-.417.417v1.666c0 .23.187.417.417.417h1.666c.23 0 .417-.187.417-.417v-1.666a.417.417 0 0 0-.417-.417H9.167Zm3.75 0a.417.417 0 0 0-.417.417v1.666c0 .23.187.417.417.417h1.666c.23 0 .417-.187.417-.417v-1.666a.417.417 0 0 0-.417-.417h-1.666Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSwitch;
