import * as React from "react";

function SvgKangaroo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.569 18.056a.625.625 0 01-.42-.162 6.737 6.737 0 00-1.455-1.019.625.625 0 01-.344-.519l-.163-2.587a9.113 9.113 0 014.207-8.238l2.643-1.681a.625.625 0 01.963.419l.494 2.956a9.1 9.1 0 01-2.832 8.219L25 17.894a.625.625 0 01-.431.162z"
        fill="#212121"
      />
      <path
        d="M23.575 15.95c.34.184.665.393.975.625l2.256-2.075a7.851 7.851 0 002.444-7.069l-.338-2.025-1.875 1.175a7.862 7.862 0 00-3.631 7.125l.169 2.244z"
        fill="#FF9800"
      />
      <path
        d="M21.387 36.875h-2.775a2.363 2.363 0 01-2.363-2.362v-.282a3.206 3.206 0 00-1.15-2.356 9.849 9.849 0 01-3.225-7.5A9.905 9.905 0 0115 16.969a8.022 8.022 0 011.731-1.188 7.219 7.219 0 016.513 0 8.012 8.012 0 011.756 1.188 9.907 9.907 0 013.125 7.406 9.852 9.852 0 01-3.225 7.5 3.208 3.208 0 00-1.15 2.356v.282a2.363 2.363 0 01-2.362 2.362z"
        fill="#212121"
      />
      <path
        d="M20 16.25a5.98 5.98 0 00-2.695.625 6.748 6.748 0 00-1.456 1 8.657 8.657 0 00-2.725 6.5 8.6 8.6 0 002.8 6.531 4.45 4.45 0 011.575 3.325v.282a1.112 1.112 0 001.113 1.112h2.775a1.112 1.112 0 001.112-1.112v-.282a4.451 4.451 0 011.575-3.325 8.598 8.598 0 002.8-6.53 8.656 8.656 0 00-2.725-6.482 6.719 6.719 0 00-1.456-1.019 5.975 5.975 0 00-2.694-.625z"
        fill="#FFB74D"
      />
      <path
        d="M15.43 18.056a.624.624 0 01-.424-.162l-2.663-2.444a9.1 9.1 0 01-2.83-8.219L10 4.27a.626.626 0 01.95-.425l2.643 1.681a9.113 9.113 0 014.22 8.263l-.163 2.587a.626.626 0 01-.344.519 6.748 6.748 0 00-1.456 1 .625.625 0 01-.42.162z"
        fill="#212121"
      />
      <path
        d="M11.087 5.406l-.338 2.032a7.85 7.85 0 002.444 7.093l2.256 2.075c.31-.232.635-.44.975-.625l.144-2.237a7.862 7.862 0 00-3.631-7.125l-1.85-1.213z"
        fill="#FF9800"
      />
      <path
        d="M20 33.375a1.25 1.25 0 01-.975-.469l-1.2-1.5a1.25 1.25 0 01.975-2.031h2.4a1.25 1.25 0 01.975 2.031l-1.2 1.5a1.251 1.251 0 01-.975.469z"
        fill="#212121"
      />
      <path d="M18.8 30.625h-.007l1.206 1.5 1.2-1.5h-2.4z" fill="#6D4C41" />
      <path
        d="M20 36.25a.624.624 0 01-.625-.625v-2.5a.624.624 0 111.25 0v2.5a.624.624 0 01-.625.625zm-2.5-10a.624.624 0 01-.625-.625V25a.624.624 0 111.25 0v.625a.624.624 0 01-.625.625zm5 0a.624.624 0 01-.625-.625V25a.624.624 0 111.25 0v.625a.624.624 0 01-.625.625z"
        fill="#212121"
      />
    </svg>
  );
}

export default SvgKangaroo;
