import { Button } from './Button';
import { Icon } from './Icon';

export const NavigationButton: React.FC<{
  onClick: () => void;
  variant: 'left' | 'right';
  disabled?: boolean;
}> = ({ onClick, variant, disabled }) => {
  return (
    <Button
      leftElement={
        <Icon name={variant === 'left' ? 'arrow-left' : 'arrow-right'} />
      }
      variant="icon"
      onClick={onClick}
      border="default"
      borderColor={{
        ...(!disabled && {
          _: 'gray.200',
          hover: 'brand.200',
        }),
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      color={{
        ...(!disabled && {
          _: 'gray.300',
          hover: 'brand.300',
        }),
      }}
      borderRadius="md"
      p="2"
      disabled={disabled}
    />
  );
};
