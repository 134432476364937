import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';

import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { Emoji } from './Emoji';
import { Heading } from './Heading';
import { NextLink } from './NextLink';
import { Text } from './Text';

const cardSize = {
  _: '21rem',
  md: '41.5rem',
};

type NotFoundCardProps = {
  title: string;
};
export const NotFoundCard = ({ title }: NotFoundCardProps) => {
  const { organization } = useOptionalOrganization();
  const { t } = useTranslation();
  return (
    <x.div
      mt="4.5rem" // Header height
      display="flex"
      boxSizing="border-box"
      alignItems="center"
      justifyContent="center"
    >
      <x.div w={cardSize} h={cardSize} bg="gray.50" borderRadius="lg">
        <x.div
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          h="full"
          boxSizing="border-box"
          spaceY={2.5}
          p={8}
        >
          <Emoji
            emojiUnicode="👨‍🚀"
            size={120}
            display={{ _: 'none', md: 'block' }}
          />
          <Emoji
            emojiUnicode="👨‍🚀"
            size={48}
            display={{ _: 'block', md: 'none' }}
          />
          <Heading variant={{ _: '2xl', md: '4xl' }}>404</Heading>
          <Text variant="sm">
            {title}
            {organization && (
              <>
                <br />
                <Text as="span" variant="sm-semibold">
                  <NextLink
                    href={routes.groups
                      .organization(organization.shortId)
                      .feed()}
                  >
                    {t('take_me_home')}
                  </NextLink>
                </Text>
              </>
            )}
          </Text>
        </x.div>
      </x.div>
    </x.div>
  );
};
