import { useSelector } from '@xstate/react';
import { x } from '@xstyled/styled-components';
import { useContext } from 'react';

import { PostComposerStateContext } from './PostComposer';
import { PostResource } from './PostResource';

export const PostComposerResources = () => {
  const { postComposerService } = useContext(PostComposerStateContext);
  const resources = useSelector(
    postComposerService,
    (state) => state.context.post.resources
  );

  const isPublishing = useSelector(
    postComposerService,
    (state) => state.context.isPublishing
  );

  if (resources.length === 0) return null;

  return (
    <x.div spaceY={4}>
      {resources.map((resource) => {
        return (
          <PostResource
            key={resource.id}
            colorVariant="dark"
            resource={resource}
            variant="composer"
            onUpdateResource={(url, title) =>
              postComposerService.send({
                type: 'UPDATE_RESOURCE',
                url,
                title,
              })
            }
            onRemoveResource={(resourceId) =>
              postComposerService.send({
                type: 'REMOVE_RESOURCE',
                resourceId,
              })
            }
            disabled={isPublishing}
            onToggleExpandedResource={(resourceId) => {
              postComposerService.send({
                type: 'TOGGLE_EXPANDED_RESOURCE',
                resourceId,
              });
            }}
            editable
          />
        );
      })}
    </x.div>
  );
};
