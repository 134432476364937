/**
 * The MJML counterpart for this component lives in /emails/src/ui,
 * so bear in mind if you're updating this file and want the changes to
 * appear in email notifications as well, you should update that too
 */

import { MyValue, theme } from '@frond/shared';
import styled, { x } from '@xstyled/styled-components';
import React, { FC, PropsWithChildren, ReactElement } from 'react';

import {
  NotificationEventNames,
  NotificationFragmentFragment,
} from '../../../../generated/types-and-hooks';
import { Avatar } from '../../common/components/Avatar';
import { ComposerBody } from '../../common/components/Composer';
import { Emoji } from '../../common/components/Emoji';
import { Image } from '../../common/components/Image';
import { Text } from '../../common/components/Text';
import { TimeAgo } from '../../common/components/TimeAgo';

type InboxNotificationProps = {
  notification: NotificationFragmentFragment;
  selected: boolean;
};

/**
 * Maps a notification to one of the components based on it's type
 */
export const InboxNotification: FC<InboxNotificationProps> = (props) => {
  const { eventName } = props.notification;

  const rendererMap: Partial<{
    [event in NotificationEventNames]: FC<InboxNotificationProps>;
  }> = {
    [NotificationEventNames.GroupQuestionOccurrence]: InboxNotificationReminder,
    [NotificationEventNames.ReactionCreated]: InboxNotificationReaction,
    [NotificationEventNames.NewMemberIntroduction]: InboxNotificationNewMember,
    [NotificationEventNames.RequestToJoin]: RequestToJoinNotificationReminder,
    [NotificationEventNames.RequestToJoinInvite]:
      RequestToJoinNotificationReminder,
    [NotificationEventNames.MemberSubscriptionCreated]:
      SubscriptionNotification,
    [NotificationEventNames.MemberSubscriptionGifted]: SubscriptionNotification,
    [NotificationEventNames.MemberSubscriptionCanceled]:
      SubscriptionNotification,
    [NotificationEventNames.MemberSubscriptionExpiry]: SubscriptionNotification,
    [NotificationEventNames.MemberSubscriptionPlanPriceChanged]:
      SubscriptionNotification,
    [NotificationEventNames.EventCreated]: EventCreatedNotification,
    [NotificationEventNames.EventDeleted]: EventDeletedNotification,
    [NotificationEventNames.EventDateChanged]: EventTimeNotification,
    [NotificationEventNames.EventTimeChanged]: EventTimeNotification,
    [NotificationEventNames.EventLocationChanged]: EventEditedNotification,
    [NotificationEventNames.EventReminder]: EventTimeNotification,
  };

  const Notification = rendererMap[eventName] || InboxNotificationDefault;
  return <Notification {...props} />;
};

/**
 * Reaction notifs use an emoji in place of the user avatar
 */
const InboxNotificationReaction: FC<InboxNotificationProps> = (props) => {
  const emojiUnicode = props.notification.emojiUnicode;

  if (!emojiUnicode) {
    return null;
  }

  return (
    <InboxNotificationWrapper {...props}>
      <InboxNotificationContent
        {...props}
        left={
          <x.div
            w={10}
            h={10}
            backgroundColor="gray.50"
            borderRadius="md"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Emoji emojiUnicode={emojiUnicode} size={20} />
          </x.div>
        }
      />
    </InboxNotificationWrapper>
  );
};

/**
 * New member notifications have a specific icon to use in place of the avatar
 */
const InboxNotificationNewMember: FC<InboxNotificationProps> = (props) => {
  return (
    <InboxNotificationWrapper {...props}>
      <InboxNotificationContent
        {...props}
        left={<Avatar size="m-1" avatar="welcome-member" bgColor="gray.50" />}
      />
    </InboxNotificationWrapper>
  );
};

/**
 * Reminders have a specific icon to use in place of the avatar
 */
const InboxNotificationReminder: FC<InboxNotificationProps> = (props) => {
  return (
    <InboxNotificationWrapper {...props}>
      <InboxNotificationContent
        {...props}
        left={<Avatar size="m-1" avatar="reminder" bgColor="gray.50" />}
      />
    </InboxNotificationWrapper>
  );
};

/**
 * Request to join have a specific icon to use in place of the avatar
 */
const RequestToJoinNotificationReminder: FC<InboxNotificationProps> = (
  props
) => {
  return (
    <InboxNotificationWrapper {...props}>
      <InboxNotificationContent
        {...props}
        left={<Avatar size="m-1" avatar="key" bgColor="gray.50" />}
      />
    </InboxNotificationWrapper>
  );
};

/**
 * Subscriptions have a specific icon to use in place of the avatar
 */
const SubscriptionNotification: FC<InboxNotificationProps> = (props) => {
  return (
    <InboxNotificationWrapper {...props}>
      <InboxNotificationContent
        {...props}
        left={<Avatar size="m-1" avatar="badge" bgColor="gray.50" />}
      />
    </InboxNotificationWrapper>
  );
};

/**
 * Events have a specific icon to use in place of the avatar
 */
const EventCreatedNotification: FC<InboxNotificationProps> = (props) => {
  return (
    <InboxNotificationWrapper {...props}>
      <InboxNotificationContent
        {...props}
        left={<Avatar size="m-1" avatar="event-created" bgColor="gray.50" />}
      />
    </InboxNotificationWrapper>
  );
};

/**
 * Events have a specific icon to use in place of the avatar
 */
const EventDeletedNotification: FC<InboxNotificationProps> = (props) => {
  return (
    <InboxNotificationWrapper {...props}>
      <InboxNotificationContent
        {...props}
        left={<Avatar size="m-1" avatar="event-deleted" bgColor="gray.50" />}
      />
    </InboxNotificationWrapper>
  );
};

/**
 * Events have a specific icon to use in place of the avatar
 */
const EventTimeNotification: FC<InboxNotificationProps> = (props) => {
  return (
    <InboxNotificationWrapper {...props}>
      <InboxNotificationContent
        {...props}
        left={<Avatar size="m-1" avatar="event-time" bgColor="gray.50" />}
      />
    </InboxNotificationWrapper>
  );
};

/**
 * Events have a specific icon to use in place of the avatar
 */
const EventEditedNotification: FC<InboxNotificationProps> = (props) => {
  return (
    <InboxNotificationWrapper {...props}>
      <InboxNotificationContent
        {...props}
        left={<Avatar size="m-1" avatar="event-edited" bgColor="gray.50" />}
      />
    </InboxNotificationWrapper>
  );
};

/**
 * All of the defaults below used for notifications that aren't explicitly overridden
 */
const InboxNotificationDefault: FC<InboxNotificationProps> = (props) => {
  return (
    <InboxNotificationWrapper {...props}>
      <InboxNotificationContent {...props} />
    </InboxNotificationWrapper>
  );
};

const InboxNotificationWrapper: FC<
  PropsWithChildren<InboxNotificationProps>
> = ({ selected, notification, children }) => {
  const getBackgroundColor = () => {
    if (selected) {
      return 'brand.50';
    }

    if (!notification.read) {
      return `${theme.colors.brand[50]}80`;
    }

    return 'white';
  };

  return (
    <x.div
      backgroundColor={{
        _: getBackgroundColor(),
        hover: 'brand.50',
      }}
      p="3"
      pl="2"
      borderWidth="0"
      borderColor={selected ? 'brand.300' : 'transparent'}
      borderLeftWidth={theme.sizes[1]}
      borderStyle="solid"
      display="flex"
      spaceX="3"
      cursor="pointer"
      tabIndex={0}
    >
      {children}
    </x.div>
  );
};

export const CommentComposerWrapper = styled.div`
  word-break: break-word;

  span,
  p,
  code,
  i,
  strong,
  u,
  strike {
    font-size: sm;
  }
`;

const InboxNotificationContent: FC<{
  notification: NotificationFragmentFragment;
  left?: ReactElement;
  right?: ReactElement;
}> = ({ notification, left, right }) => {
  return (
    <>
      <x.div w="10">
        {left || (
          <Avatar
            size="m-1"
            imageId={notification.user?.profileImageId}
            avatar={notification.user?.avatar}
            bgColor={notification.user?.avatarBgColor}
          />
        )}
      </x.div>
      <x.div display="flex" flexDirection="column" spaceY="1" flex={1}>
        <CommentComposerWrapper>
          <ComposerBody truncate content={notification.content as MyValue} />
        </CommentComposerWrapper>
        {notification.quoteContent && (
          <x.div display="flex" spaceX={3} py={2}>
            <x.div
              w="3px"
              h="full"
              backgroundColor="yellow.100"
              flexShrink={0}
            />
            <CommentComposerWrapper>
              <ComposerBody
                truncate
                content={notification.quoteContent as MyValue}
              />
            </CommentComposerWrapper>
          </x.div>
        )}
        <Text
          variant="sm"
          color={notification.read ? 'gray.300' : 'brand.300'}
          display="flex"
          alignItems="center"
          spaceX={2}
        >
          {!notification.read && (
            <x.span fontSize={theme.sizes['2.5']}>&#x25cf;</x.span>
          )}
          <TimeAgo date={notification.createdAt} />
        </Text>
      </x.div>
      {right ||
        (notification.imageUrl && (
          <x.div borderRadius="md" overflow="hidden" w={18} h={18}>
            <Image src={notification.imageUrl} style={{ width: '100%' }} />
          </x.div>
        ))}
    </>
  );
};
