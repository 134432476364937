import * as React from "react";

function SvgCaret(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.72 18.251a1 1 0 01-1.44 0L3.236 9.893c-.611-.635-.16-1.693.72-1.693h16.085c.882 0 1.332 1.058.72 1.693l-8.041 8.358z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCaret;
