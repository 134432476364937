import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';

import { GroupTypes } from '../../../../../../generated/types-and-hooks';
import { Input } from '../../../../common/components/Input';
import { GroupEditAboutFormData } from '../GroupEditAbout/GroupEditAboutForm';

export const GroupFormNameField = ({ type }: { type: GroupTypes }) => {
  const form = useFormContext<GroupEditAboutFormData>();
  const { t } = useTranslation('common');
  return (
    <Input
      {...form.register('title')}
      // The zwnj here is to prevent this being recognized
      // as a person's name input in Safari
      label={t(
        type === GroupTypes.Course
          ? 'courses.name_field_label'
          : 'groups.name_field_label'
      )}
      fullWidth
      placeholder={t('groups.name_field_placeholder')}
    />
  );
};
