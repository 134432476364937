import * as React from "react";
import { SVGProps } from "react";
const SvgBold = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.625 2.083a1.459 1.459 0 0 0-1.458 1.693V16.22a1.459 1.459 0 0 0 1.458 1.697h6.25c2.63 0 4.792-2.163 4.792-4.792 0-1.635-.888-3.018-2.153-3.884.525-.746.903-1.602.903-2.574 0-2.514-2.07-4.584-4.584-4.584H5.625ZM7.083 5h3.75c.938 0 1.667.729 1.667 1.667 0 .937-.729 1.666-1.667 1.666H7.292c-.07 0-.14.005-.209.014V5Zm0 6.236a1.466 1.466 0 0 0 .209.014h4.583c1.053 0 1.875.822 1.875 1.875A1.854 1.854 0 0 1 11.875 15H7.083v-3.764Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgBold;
