import { useApolloClient } from '@apollo/client';
import {
  ChatMessageReceivedEvent,
  ReadReceiptReceivedEvent,
} from '@azure/communication-chat';
import { getMessageDescription } from '@frond/shared';
import { debounce } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';

import {
  DirectMessageNotificationCountsDocument,
  DirectMessageNotificationCountsQuery,
  DirectMessageNotificationCountsQueryVariables,
  DirectMessageThreadsDocument,
  DirectMessageThreadsQuery,
} from '../../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../../auth/hooks/useOptionalOrganization';
import { DirectMessageThreadType } from '../common/directMessageMachine';

export const useChatCacheHandlers = ({
  communicationUserId,
}: {
  communicationUserId?: string;
}) => {
  const { cache, query } = useApolloClient();
  const { organization } = useOptionalOrganization();
  const { i18n } = useTranslation();

  const handleUpdateUnreadCount = useCallback(() => {
    query<
      DirectMessageNotificationCountsQuery,
      DirectMessageNotificationCountsQueryVariables
    >({
      query: DirectMessageNotificationCountsDocument,
      variables: { organizationId: organization?.id || '' },
    });
  }, [query, organization]);

  const debounceHandleUpdateUnreadCount = useMemo(
    () => debounce(handleUpdateUnreadCount),
    [handleUpdateUnreadCount]
  );

  const handleReadReceiptReceived = (e: ReadReceiptReceivedEvent) => {
    const cachedQuery = cache.readQuery<DirectMessageThreadsQuery>({
      query: DirectMessageThreadsDocument,
      variables: {
        organizationId: organization?.id || '',
      },
    });

    const updatedDirectMessageThreads = cachedQuery?.directMessageThreads.map(
      (thread) => {
        if (
          thread.threadId === e.threadId &&
          e.sender.kind === 'communicationUser'
        ) {
          return {
            ...thread,
            unreadCount:
              e.sender.communicationUserId === communicationUserId
                ? thread.unreadCount
                  ? thread.unreadCount - 1
                  : 0
                : 0,
          };
        }
        return thread;
      }
    );

    if (updatedDirectMessageThreads) {
      cache.writeQuery<DirectMessageThreadsQuery>({
        query: DirectMessageThreadsDocument,
        data: {
          directMessageThreads: updatedDirectMessageThreads,
        },
      });

      // Update unread counter
      debounceHandleUpdateUnreadCount();
    }
  };

  const handelChatMessageReceived = (e: ChatMessageReceivedEvent) => {
    const cachedQuery = cache.readQuery<DirectMessageThreadsQuery>({
      query: DirectMessageThreadsDocument,
      variables: {
        organizationId: organization?.id,
      },
    });
    const updatedDirectMessageThreads = cachedQuery?.directMessageThreads.map(
      (thread) => {
        if (
          thread.threadId === e.threadId &&
          e.sender.kind === 'communicationUser'
        ) {
          const messageDescription = getMessageDescription({
            name:
              e.sender.kind === 'communicationUser' ? e.senderDisplayName : '',
            messageBody: e.message,
            metadata: e.metadata,
          });
          return {
            ...thread,
            unreadCount:
              e.sender.communicationUserId !== communicationUserId
                ? thread.unreadCount
                  ? thread.unreadCount + 1
                  : 1
                : 0,
            lastMessage: messageDescription[i18n.language],
            lastMessageReceivedOn: e.createdOn.toISOString(),
          };
        }
        return thread;
      }
    );

    if (updatedDirectMessageThreads) {
      cache.writeQuery<DirectMessageThreadsQuery>({
        query: DirectMessageThreadsDocument,
        data: {
          directMessageThreads: updatedDirectMessageThreads,
        },
      });

      // Update unread counter
      debounceHandleUpdateUnreadCount();
    }
  };

  const handleMessageSeen = (thread: DirectMessageThreadType) => {
    const cachedQuery = cache.readQuery<DirectMessageThreadsQuery>({
      query: DirectMessageThreadsDocument,
      variables: {
        organizationId: organization?.id,
      },
    });

    const updatedDirectMessageThreads = cachedQuery?.directMessageThreads.map(
      (cachedThread) => {
        if (cachedThread.threadId === thread.threadId) {
          return {
            ...cachedThread,
            unreadCount: cachedThread.unreadCount
              ? cachedThread.unreadCount - 1
              : 0,
          };
        }
        return thread;
      }
    );

    if (updatedDirectMessageThreads) {
      cache.writeQuery<DirectMessageThreadsQuery>({
        query: DirectMessageThreadsDocument,
        data: {
          directMessageThreads: updatedDirectMessageThreads,
        },
      });

      // Update unread counter
      debounceHandleUpdateUnreadCount();
    }
  };

  return {
    handelChatMessageReceived,
    handleReadReceiptReceived,
    handleMessageSeen,
  };
};
