import * as React from "react";

function SvgAppPitch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-pitch_svg__clip0)" fill="currentColor">
        <path d="M16.317 14.132a1.57 1.57 0 11.015-1.774l1.16-.685a2.916 2.916 0 10-.006 3.132l-1.17-.673zM19.514 7.851h-1.346v8.075h1.346v-2.692a1.57 1.57 0 113.14 0v2.692H24v-2.953a2.654 2.654 0 00-4.486-1.921v-3.2zM10.094 7.851H8.748v6.28a2.019 2.019 0 003.346 1.521l-.65-1.103a.672.672 0 01-.528.255h-.15a.673.673 0 01-.673-.673V11.89h1.347v-1.346h-1.346V7.85zM7.177 7.851a.748.748 0 100 1.495.748.748 0 000-1.495zM7.85 15.926v-5.383H6.506v5.383H7.85z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 15.926h1.346v-1.57h1.233a3.252 3.252 0 100-6.505H0v8.075zm1.346-6.73h1.233a1.907 1.907 0 010 3.814H1.346V9.197z"
        />
      </g>
      <defs>
        <clipPath id="app-pitch_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppPitch;
