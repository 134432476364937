import { theme } from '@frond/shared';
import { yupResolver } from '@hookform/resolvers/yup';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  refetchViewerQuery,
  UpdateUserMutation,
  useUpdateUserMutation,
  useUsernameAvailableLazyQuery,
} from '../../../../generated/types-and-hooks';
import { useViewer } from '../../auth/hooks/useViewer';
import { Avatar } from '../../common/components/Avatar';
import { AvatarPicker } from '../../common/components/AvatarPicker';
import { Button } from '../../common/components/Button';
import { Form } from '../../common/components/Form';
import { Heading } from '../../common/components/Heading';
import { Input } from '../../common/components/Input';
import { ModalProps } from '../../common/components/Modal';
import { ImageCropper } from '../../common/components/ProfileAvatarInput';
import { Text } from '../../common/components/Text';
import { Toast } from '../../common/components/Toast';
import { useFileUpload } from '../../common/hooks/useFileUpload';
import { CoverImage } from '../../groups/components/CoverImage';
import { GroupsModal } from '../../groups/components/GroupsModal';
import { UsernameInput } from '../../onboarding/components/common/UsernameInput';
import {
  StyledDropdownMenuContent,
  StyledDropdownMenuItem,
} from '../../posts/components/PostCard';

export type ProfileEditModalProps = Pick<ModalProps, 'isOpen' | 'onDismiss'> & {
  onUpdateUser?: (updatedUserData?: UpdateUserMutation['updateUser']) => void;
};

export const ProfileEditModal: FC<ProfileEditModalProps> = ({
  isOpen,
  onDismiss,
  onUpdateUser,
}) => {
  const [checkIfUsernameAvailable] = useUsernameAvailableLazyQuery({
    fetchPolicy: 'network-only',
  });

  const { t } = useTranslation(['common', 'settings']);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const { viewer } = useViewer();
  const user = viewer;

  const ProfileFormSchema = yup
    .object({
      firstName: yup.string().max(255),
      lastName: yup.string().max(255),
      username: yup
        .string()
        .max(255)
        .required()
        .matches(/^[a-zA-Z0-9._]+$/)
        .test('availability', async (username) => {
          if (!username) return false;

          if (username === user?.username) return true;

          const result = await checkIfUsernameAvailable({
            variables: {
              username,
            },
          });

          return result.data?.usernameAvailable.__typename === 'Username';
        }),
      profileImageId: yup.string().max(1024).nullable(),
      avatar: yup.string().max(255).nullable(),
      avatarBgColor: yup.string().max(255).nullable(),
      bio: yup.string().max(255).nullable(),
      coverImageId: yup.string().max(255).nullable(),
    })
    .defined();

  type ProfileFormData = yup.InferType<typeof ProfileFormSchema>;

  const form = useForm<ProfileFormData>({
    resolver: yupResolver(ProfileFormSchema),
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      coverImageId: user?.coverImageId,
      bio: user?.bio,
      avatar: user?.avatar,
      avatarBgColor: user?.avatarBgColor,
      profileImageId: user?.profileImageId,
      username: user?.username,
    },
    mode: 'all',
  });
  const [fileToCrop, setFileToCrop] = useState<File | null>(null);

  const [updateUser, { loading }] = useUpdateUserMutation();

  const { uploadSelectedFile, loading: fileUploadLoading } = useFileUpload({
    onUploadSuccess: (image) => {
      if ('publicId' in image) {
        form.setValue('profileImageId', image.publicId, {
          shouldDirty: true,
        });
      }
    },
    name: 'profileImageId',
    accept: 'image/*',
    onUploadFailure: (e) => {
      setUploadError(e.message);
    },
  });

  const { getInputProps, open: openFileDialog } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: { 'image/*': [] },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFileToCrop(acceptedFiles[0]);
      }
    },
  });

  const handleSubmit = (data: ProfileFormData) => {
    if (!user) return;

    updateUser({
      variables: {
        input: {
          ...data,
          username: data.username.toLowerCase(),
          userId: user.id,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [refetchViewerQuery()],
    }).then((res) => {
      if (onUpdateUser) onUpdateUser(res.data?.updateUser);
    });
  };

  const handleRemoveProfileImage = () => {
    if (!user) return;

    form.setValue('profileImageId', null, {
      shouldDirty: true,
    });
    form.setValue('avatar', user.avatar, {
      shouldDirty: true,
    });
    form.setValue('avatarBgColor', user.avatarBgColor, {
      shouldDirty: true,
    });
  };

  const coverImageId = form.watch('coverImageId');
  const profileImageId = form.watch('profileImageId');

  if (!user) return null;

  return (
    <GroupsModal
      width="560px"
      isOpen={isOpen}
      ariaLabel={t('settings.edit_profile', { ns: 'settings' })}
      onDismiss={() => {
        form.reset({
          firstName: user.firstName,
          lastName: user.lastName,
          coverImageId: user.coverImageId,
          bio: user.bio,
          avatar: user.avatar,
          avatarBgColor: user.avatarBgColor,
          profileImageId: user.profileImageId,
          username: user.username,
        });
        onDismiss();
      }}
      variant="tabbed"
    >
      <div>
        {!!uploadError && (
          <Toast
            variant="error"
            message={uploadError}
            onDisappear={() => setUploadError(null)}
          />
        )}
        {fileToCrop ? (
          <ImageCropper
            onDismiss={() => setFileToCrop(null)}
            isOpen={!!fileToCrop}
            file={fileToCrop}
            onUseImage={(image) => {
              uploadSelectedFile(image);
            }}
          />
        ) : null}

        <x.div
          px={6}
          py={4}
          backgroundColor="gray.50"
          borderRadius="md-lg md-lg 0 0"
        >
          <Heading variant="xl">
            {t('settings.edit_profile', { ns: 'settings' })}
          </Heading>
        </x.div>
        <Form<ProfileFormData> {...form} onSubmit={handleSubmit}>
          <x.div position="relative">
            <CoverImage
              coverImageId={coverImageId}
              onRemoveCover={() => {
                form.setValue('coverImageId', null, { shouldDirty: true });
              }}
              onUploadSuccess={(id) => {
                form.setValue('coverImageId', id, { shouldDirty: true });
              }}
              canEditCover
              showAddCoverButton
              buttonInset={5}
              variant="placeholder"
            />
            <x.div
              position="absolute"
              left={theme.sizes[6]}
              bottom="-20"
              bg="white"
              borderRadius="md-lg"
              p="5px"
              // one-off shadow
              boxShadow="1px 1px 3px rgba(0, 0, 0, 0.12)"
            >
              {profileImageId ? (
                <x.button
                  display="contents"
                  onClick={(e) => {
                    e.preventDefault();
                    openFileDialog();
                  }}
                  border={1}
                >
                  <Avatar imageId={profileImageId} size="lg-5" />
                </x.button>
              ) : (
                <AvatarPicker size="lg-5" />
              )}
            </x.div>
          </x.div>
          <x.div px={6} pt={10} pb={6}>
            <input
              {...getInputProps()}
              onChange={(e) => {
                if (e.target?.files) {
                  // Check size
                  if (e.target?.files[0].size > 15_000_000) {
                    setUploadError('File size must be under 15mb');
                    return;
                  }

                  setFileToCrop(e.target?.files[0]);
                }
              }}
            />

            <x.div mb={8}>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                  <x.div display="inline-block">
                    <Button
                      label={
                        fileUploadLoading
                          ? t('uploading')
                          : t('settings.edit_profile_image', {
                              ns: 'settings',
                            })
                      }
                      variant="secondary"
                      disabled={fileUploadLoading}
                    />
                  </x.div>
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                  <StyledDropdownMenuContent align="start">
                    <x.div p={3} boxShadow="sm" borderRadius="md" spaceY={1}>
                      <StyledDropdownMenuItem onSelect={openFileDialog}>
                        <Text variant="md">{t('upload_photo')}</Text>
                      </StyledDropdownMenuItem>
                      {profileImageId ? (
                        <StyledDropdownMenuItem
                          onSelect={handleRemoveProfileImage}
                        >
                          <Text variant="md">{t('remove_photo')}</Text>
                        </StyledDropdownMenuItem>
                      ) : null}
                    </x.div>
                  </StyledDropdownMenuContent>
                </DropdownMenu.Portal>
              </DropdownMenu.Root>
            </x.div>

            <x.div spaceY={5} mb={4}>
              <x.div spaceY={2}>
                <x.label>
                  <Text as="span" variant="md-semibold">
                    Username
                  </Text>
                </x.label>
                <UsernameInput />
              </x.div>
              <x.div display="flex" alignItems="center" spaceX={3}>
                <x.div spaceY={2} flex={1}>
                  <x.label>
                    <Text as="span" variant="md-semibold">
                      {t('first_name')}
                    </Text>
                  </x.label>
                  <Input
                    {...form.register('firstName')}
                    placeholder={t('first_name')}
                    fullWidth
                  />
                </x.div>
                <x.div spaceY={2} flex={1}>
                  <x.label>
                    <Text as="span" variant="md-semibold">
                      {t('last_name')}
                    </Text>
                  </x.label>
                  <Input
                    {...form.register('lastName')}
                    placeholder={t('last_name')}
                    fullWidth
                  />
                </x.div>
              </x.div>

              <x.div spaceY={2}>
                <x.label>
                  <Text as="span" variant="md-semibold">
                    {t('bio')}
                  </Text>
                </x.label>
                <Input
                  isMultiLine
                  rows={3}
                  {...form.register('bio')}
                  placeholder={t('bio')}
                  fullWidth
                />
              </x.div>
            </x.div>
            <x.div display="flex" justifyContent="flex-end">
              <Button
                label={t('save')}
                loading={loading}
                disabled={!form.formState.isValid || !form.formState.isDirty}
                type="submit"
              />
            </x.div>
          </x.div>
        </Form>
      </div>
    </GroupsModal>
  );
};
