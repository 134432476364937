import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
} from '@reach/menu-button';
import styled, { x } from '@xstyled/styled-components';
import { th } from '@xstyled/system';
import find from 'lodash/find';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import { Button } from '../../../common/components/Button';
import { Icon } from '../../../common/components/Icon';
import { Text } from '../../../common/components/Text';
import analytics from '../../../common/utils/analytics';

type QuestionsVideoRecorderMediaInputSelectorsProps = {
  activeVideoInputDeviceId?: string;
  activeAudioInputDeviceId?: string;
  onSelectVideoInputDevice: (deviceId: string) => void;
  onSelectAudioInputDevice: (deviceId: string) => void;
};

const Popover = styled(MenuPopover)`
  z-index: modal;
  background-color: ${th.color('gray.500')};
  border-radius: md;
  padding: 3 3 3 4;
  position: absolute;
  bottom: ${th.size('11')};
`;

const Items = styled(MenuItems)`
  border: none;
  padding: 0;
  background-color: ${th.color('gray.500')};
`;

const Item = styled(MenuItem)`
  display: flex;
  align-items: center;
  padding: 1 0 2 0;
  color: white;
  &:hover {
    background-color: ${th.color('gray.500')};
  }
`;

const formatDeviceName = (
  device: MediaDeviceInfo,
  devices: MediaDeviceInfo[],
  sameAsSystem: string
): string => {
  if (device.deviceId === 'default') {
    const actualDevice = find(
      devices,
      (candidate) =>
        candidate.groupId === device.groupId &&
        candidate.deviceId !== device.deviceId
    );

    const label = actualDevice?.label.replace(/ *\([^)]*\) */g, '');
    return label ? `${sameAsSystem} (${label})` : sameAsSystem;
  }

  return device.label;
};

export const QuestionsVideoRecorderMediaInputSelectors: React.FC<
  QuestionsVideoRecorderMediaInputSelectorsProps
> = ({
  activeVideoInputDeviceId,
  activeAudioInputDeviceId,
  onSelectAudioInputDevice,
  onSelectVideoInputDevice,
}) => {
  const { t } = useTranslation();

  const [devices, setDevices] = useState<{
    audioInputs: MediaDeviceInfo[];
    videoInputs: MediaDeviceInfo[];
  }>();

  useEffect(() => {
    const handleDevices = (devices: MediaDeviceInfo[]) => {
      setDevices({
        audioInputs: devices.filter((device) => device.kind === 'audioinput'),
        videoInputs: devices.filter((device) => device.kind === 'videoinput'),
      });
    };

    navigator.mediaDevices
      .enumerateDevices()
      .then(handleDevices)
      .catch(() => {});
  }, [activeAudioInputDeviceId, activeVideoInputDeviceId]);

  return (
    <x.div position="relative">
      <Menu>
        {() => (
          <>
            <MenuButton
              as="div"
              onClick={() => {
                analytics.logEvent(analytics.events.VIDEO_INPUT_MENU_OPEN);
              }}
            >
              <Button
                pl="3"
                pr="2"
                variant="secondary"
                leftElement={
                  <x.div display="flex" flexDirection="row">
                    <Icon name="settings" mr="2" color="inherit" />
                    <Icon name="chevron-top" />
                  </x.div>
                }
              />
            </MenuButton>
            <Popover portal={false}>
              <Text color="gray.300" pt="1" pb="2" variant="sm-medium">
                {t('select_microphone')}
              </Text>
              <Items>
                {devices?.audioInputs.map((device) => (
                  <Item
                    key={device.deviceId}
                    onSelect={() => {
                      analytics.logEvent(
                        analytics.events.AUDIO_INPUT_MENU_SELECT
                      );
                      onSelectAudioInputDevice(device.deviceId);
                    }}
                  >
                    <Text w="100%" variant="sm-medium">
                      {formatDeviceName(
                        device,
                        devices.videoInputs,
                        t('same_as_system')
                      )}
                    </Text>
                    {activeAudioInputDeviceId === device.deviceId && (
                      <Icon size="5" ml="16" name="circle-filled-checked" />
                    )}
                  </Item>
                ))}
              </Items>
              <Text color="gray.300" pt="1" pb="2" variant="sm-medium">
                {t('select_camera')}
              </Text>
              <Items>
                {devices?.videoInputs.map((device) => (
                  <Item
                    key={device.deviceId}
                    onSelect={() => {
                      analytics.logEvent(
                        analytics.events.VIDEO_INPUT_MENU_SELECT
                      );
                      onSelectVideoInputDevice(device.deviceId);
                    }}
                  >
                    <Text w="100%" variant="sm-medium">
                      {formatDeviceName(
                        device,
                        devices.videoInputs,
                        t('same_as_system')
                      )}
                    </Text>
                    {activeVideoInputDeviceId === device.deviceId && (
                      <Icon size="5" ml="16" name="circle-filled-checked" />
                    )}
                  </Item>
                ))}
              </Items>
            </Popover>
          </>
        )}
      </Menu>
    </x.div>
  );
};
