import { MyValue } from '@frond/shared';
import emojiRegex from 'emoji-regex';
import { Node } from 'slate';

import { PostCoreFragment } from '../../../../generated/graphql-request-api-sdk';
import { User } from '../../../../generated/types-and-hooks';

export const isEmojiOnly = (content?: MyValue | null) => {
  if (!content || !content.length || content.length > 1) {
    return false;
  }

  const string = Node.string(content[0]).trim();
  const chars = Array.from(string);

  if (chars.length === 0 || chars.length > 5) {
    return false;
  }

  const regex = emojiRegex();
  const matches = string.matchAll(regex);

  let matched = 0;
  for (const match of matches) {
    matched += match[0].length;
  }
  return matched === string.length;
};

export const userCanEditPost = (
  post?: PostCoreFragment,
  user?: Pick<User, 'id'>
) => {
  return post?.createdBy.id === user?.id;
};
