import { theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { FC } from 'react';

import { GroupWithMembersFragment } from '../../../../../../generated/types-and-hooks';
import { GroupCover } from '../../GroupCover';
import { GroupFormEmoji } from '../GroupForm/GroupFormEmoji';

export const GroupEditAboutHeader: FC<{
  group: GroupWithMembersFragment;
}> = ({ group }) => {
  return (
    <x.div position="relative" mb={4}>
      <GroupCover
        group={group}
        showAddCoverButton
        buttonInset={5}
        variant="placeholder"
      />
      <GroupFormEmoji
        boxShadow="sm"
        position="absolute"
        left={theme.sizes[6]}
        bottom="-20"
        backgroundColor="white"
        w={20}
        h={20}
      />
    </x.div>
  );
};
