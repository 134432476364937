import { routes } from '@frond/shared';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import {
  GroupCoreFragment,
  GroupTypes,
  refetchGroupQuery,
  refetchGroupsQuery,
  refetchHomeFeedQuery,
  refetchMenuSectionsQuery,
  useDeleteGroupMutation,
} from '../../../../../../generated/types-and-hooks';
import {
  GROUPS_PER_PAGE,
  POSTS_PER_PAGE,
} from '../../../../../config/constants';
import { useOrganization } from '../../../../auth/hooks/useOrganization';
import { Alert } from '../../../../common/components/Alert';
import { Button } from '../../../../common/components/Button';

export const GroupFormDeleteButton: React.FC<{
  group: GroupCoreFragment;
  onDismiss?: () => void;
}> = ({ group, onDismiss }) => {
  const { t } = useTranslation(['groups', 'common']);
  const router = useRouter();
  const { organization } = useOrganization();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [mutateDeleteGroup, { loading }] = useDeleteGroupMutation();

  const handleDeleteGroup = () => {
    mutateDeleteGroup({
      refetchQueries: [
        refetchHomeFeedQuery({
          organizationId: organization.id,
          first: POSTS_PER_PAGE,
        }),
        refetchGroupsQuery({
          organizationId: organization.id,
          first: GROUPS_PER_PAGE - 1,
        }),
        refetchGroupQuery({ id: group.id }),
        refetchMenuSectionsQuery({
          organizationId: organization.id,
        }),
      ],
      variables: {
        input: {
          id: group.id,
        },
      },
      onCompleted: () => {
        onDismiss?.();
        router.push(routes.groups.organization(organization.shortId).feed());
      },
    });
  };

  const title =
    group.groupType === GroupTypes.Course
      ? t('groups.delete_course_modal_heading', { ns: 'groups' })
      : t('groups.delete_modal_heading', { ns: 'groups' });

  return (
    <>
      <Button
        label={title}
        variant="secondary-warning"
        loading={loading}
        onClick={() => setShowDeleteAlert(true)}
      />
      {showDeleteAlert && (
        <Alert
          headingText={title}
          descriptionText={t('groups.delete_modal_body', { ns: 'groups' })}
          submitButtonText={t('delete', { ns: 'common' })}
          cancelButtonText={t('cancel', { ns: 'common' })}
          onDismiss={() => {
            setShowDeleteAlert(false);
          }}
          onSubmitClick={() => {
            setShowDeleteAlert(false);
            handleDeleteGroup();
          }}
          onCancelClick={() => {
            setShowDeleteAlert(false);
          }}
          variant="warning"
        />
      )}
    </>
  );
};
