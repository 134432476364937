import * as React from "react";
import { SVGProps } from "react";
const SvgFriendsFamily = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.417 13.333H6.75V17.5a.833.833 0 1 0 1.667 0v-4.167Z"
      fill="url(#friends-family_svg__a)"
    />
    <path
      d="M5.917 13.333H4.25V17.5a.833.833 0 1 0 1.667 0v-4.167Z"
      fill="url(#friends-family_svg__b)"
    />
    <path
      d="M14.667 5.834a2.09 2.09 0 0 1-2.083-2.084 2.09 2.09 0 0 1 2.083-2.083 2.09 2.09 0 0 1 2.084 2.083 2.09 2.09 0 0 1-2.084 2.084Z"
      fill="url(#friends-family_svg__c)"
    />
    <path
      d="M16.75 13.333h-1.666V17.5a.833.833 0 1 0 1.667 0v-4.167Z"
      fill="url(#friends-family_svg__d)"
    />
    <path
      d="M6.333 5.834A2.09 2.09 0 0 1 4.25 3.75a2.09 2.09 0 0 1 2.083-2.083A2.09 2.09 0 0 1 8.417 3.75a2.09 2.09 0 0 1-2.084 2.084Z"
      fill="url(#friends-family_svg__e)"
    />
    <path
      d="M3 10.417v-.972a2.781 2.781 0 0 1 2.778-2.778h1.111a2.781 2.781 0 0 1 2.778 2.778v.972H3Z"
      fill="url(#friends-family_svg__f)"
    />
    <path
      d="M6.75 6.667h-.833c-.92 0-1.667.746-1.667 1.667v3.75h4.167v-3.75c0-.92-.747-1.667-1.667-1.667Z"
      fill="url(#friends-family_svg__g)"
    />
    <path
      d="M9.666 9.583h-1.25v3.542a.625.625 0 0 0 1.25 0V9.583Z"
      fill="url(#friends-family_svg__h)"
    />
    <path
      d="M4.25 9.583H3v3.542a.625.625 0 0 0 1.25 0V9.583Z"
      fill="url(#friends-family_svg__i)"
    />
    <path
      d="M14.25 13.333h-1.666V17.5a.833.833 0 1 0 1.667 0v-4.167Z"
      fill="url(#friends-family_svg__j)"
    />
    <path
      d="m18 9.584-1.25-.417v3.958a.625.625 0 0 0 1.25 0V9.584Z"
      fill="url(#friends-family_svg__k)"
    />
    <path
      d="m12.584 9.167-1.25.417v3.541a.625.625 0 0 0 1.25 0V9.167Z"
      fill="url(#friends-family_svg__l)"
    />
    <path
      d="M18 9.444a2.777 2.777 0 0 0-2.777-2.777h-1.112a2.778 2.778 0 0 0-2.777 2.778v.139h1.25l-.76 4.936a.417.417 0 0 0 .412.48H17.1c.255 0 .45-.227.411-.48l-.76-4.936H18v-.14Z"
      fill="url(#friends-family_svg__m)"
    />
    <path
      d="M16.707 3.334a2.09 2.09 0 0 0-2.041-1.667 2.09 2.09 0 0 0-2.041 1.667c.968.048 1.637-.243 2.04-.834.48.537.827.825 1.668.834h.374Z"
      fill="url(#friends-family_svg__n)"
    />
    <path
      d="M16.334 2.917c.758 0 2.083.298 2.083 2.5h-1.25s0-2.084-.833-2.084v-.416ZM13 3.333c-.834 0-.834 2.084-.834 2.084h-1.25c0-2.202 1.325-2.5 2.083-2.5v.416Z"
      fill="#B55017"
    />
    <path
      d="M4.25 12.083V15h1.667v-.834a.417.417 0 0 1 .833 0V15h1.667v-2.917H4.25Z"
      fill="#0D61A9"
    />
    <defs>
      <linearGradient
        id="friends-family_svg__a"
        x1={7.383}
        y1={14.991}
        x2={8.666}
        y2={18.219}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF54" />
        <stop offset={0.261} stopColor="#FDCB4D" />
        <stop offset={0.639} stopColor="#F7C13A" />
        <stop offset={1} stopColor="#F0B421" />
      </linearGradient>
      <linearGradient
        id="friends-family_svg__b"
        x1={4.883}
        y1={14.991}
        x2={6.166}
        y2={18.219}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF54" />
        <stop offset={0.261} stopColor="#FDCB4D" />
        <stop offset={0.639} stopColor="#F7C13A" />
        <stop offset={1} stopColor="#F0B421" />
      </linearGradient>
      <linearGradient
        id="friends-family_svg__c"
        x1={13.965}
        y1={1.815}
        x2={15.369}
        y2={5.685}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF54" />
        <stop offset={0.261} stopColor="#FDCB4D" />
        <stop offset={0.639} stopColor="#F7C13A" />
        <stop offset={1} stopColor="#F0B421" />
      </linearGradient>
      <linearGradient
        id="friends-family_svg__d"
        x1={15.716}
        y1={14.991}
        x2={16.999}
        y2={18.219}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF54" />
        <stop offset={0.261} stopColor="#FDCB4D" />
        <stop offset={0.639} stopColor="#F7C13A" />
        <stop offset={1} stopColor="#F0B421" />
      </linearGradient>
      <linearGradient
        id="friends-family_svg__e"
        x1={5.631}
        y1={1.815}
        x2={7.035}
        y2={5.685}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF54" />
        <stop offset={0.261} stopColor="#FDCB4D" />
        <stop offset={0.639} stopColor="#F7C13A" />
        <stop offset={1} stopColor="#F0B421" />
      </linearGradient>
      <linearGradient
        id="friends-family_svg__f"
        x1={5.746}
        y1={6.698}
        x2={6.53}
        y2={10.838}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32BDEF" />
        <stop offset={1} stopColor="#1EA2E4" />
      </linearGradient>
      <linearGradient
        id="friends-family_svg__g"
        x1={5.697}
        y1={6.771}
        x2={6.898}
        y2={13.113}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32BDEF" />
        <stop offset={1} stopColor="#1EA2E4" />
      </linearGradient>
      <linearGradient
        id="friends-family_svg__h"
        x1={8.512}
        y1={10.245}
        x2={10.043}
        y2={13.647}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF54" />
        <stop offset={0.261} stopColor="#FDCB4D" />
        <stop offset={0.639} stopColor="#F7C13A" />
        <stop offset={1} stopColor="#F0B421" />
      </linearGradient>
      <linearGradient
        id="friends-family_svg__i"
        x1={3.096}
        y1={10.245}
        x2={4.627}
        y2={13.647}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF54" />
        <stop offset={0.261} stopColor="#FDCB4D" />
        <stop offset={0.639} stopColor="#F7C13A" />
        <stop offset={1} stopColor="#F0B421" />
      </linearGradient>
      <linearGradient
        id="friends-family_svg__j"
        x1={13.216}
        y1={14.991}
        x2={14.499}
        y2={18.219}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF54" />
        <stop offset={0.261} stopColor="#FDCB4D" />
        <stop offset={0.639} stopColor="#F7C13A" />
        <stop offset={1} stopColor="#F0B421" />
      </linearGradient>
      <linearGradient
        id="friends-family_svg__k"
        x1={16.714}
        y1={9.954}
        x2={18.387}
        y2={13.669}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF54" />
        <stop offset={0.261} stopColor="#FDCB4D" />
        <stop offset={0.639} stopColor="#F7C13A" />
        <stop offset={1} stopColor="#F0B421" />
      </linearGradient>
      <linearGradient
        id="friends-family_svg__l"
        x1={11.508}
        y1={10.211}
        x2={13.039}
        y2={13.612}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF54" />
        <stop offset={0.261} stopColor="#FDCB4D" />
        <stop offset={0.639} stopColor="#F7C13A" />
        <stop offset={1} stopColor="#F0B421" />
      </linearGradient>
      <linearGradient
        id="friends-family_svg__m"
        x1={14.348}
        y1={6.129}
        x2={15.148}
        y2={14.444}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F44F5A" />
        <stop offset={0.443} stopColor="#EE3D4A" />
        <stop offset={1} stopColor="#E52030" />
      </linearGradient>
      <radialGradient
        id="friends-family_svg__n"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.586 1.745) scale(3.18208)"
      >
        <stop stopColor="#C26715" />
        <stop offset={0.508} stopColor="#B85515" />
        <stop offset={1} stopColor="#AD3F16" />
      </radialGradient>
    </defs>
  </svg>
);
export default SvgFriendsFamily;
