import { FC } from 'react';

import { Organization } from '../../../../generated/types-and-hooks';
import { Avatar, AvatarSizes } from '../../common/components/Avatar';

export const OrganizationAvatar: FC<{
  organization: Pick<Organization, 'id' | 'logoId' | 'name'>;
  size: AvatarSizes;
}> = ({ organization, size }) => {
  const initial = organization.name.charAt(0).toUpperCase();
  return <Avatar size={size} imageId={organization.logoId} initial={initial} />;
};
