import * as React from "react";

function SvgSquirrel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.875 16.175v.075l-.294 9.375a11.587 11.587 0 01-23.162 0l-.294-9.45a8.75 8.75 0 014.95-7.837c.219-.1.438-.194.625-.275a8.582 8.582 0 013.069-.563H23.2c1.048 0 2.088.188 3.069.556.219.082.437.175.625.275a8.75 8.75 0 014.981 7.844z"
        fill="#212121"
      />
      <path
        d="M16.8 8.75a7.413 7.413 0 00-7.425 7.425v.075l.294 9.375a10.338 10.338 0 0020.662 0l.294-9.375v-.056A7.414 7.414 0 0023.2 8.75h-6.4z"
        fill="#FFA726"
      />
      <path
        d="M8.75 16.8a.625.625 0 01-.625-.625V4.762a1.6 1.6 0 012.969-.818L13.88 8.58a.625.625 0 01-.268.888 7.463 7.463 0 00-4.238 6.706.625.625 0 01-.625.625z"
        fill="#212121"
      />
      <path
        d="M9.731 4.412h-.106a.331.331 0 00-.257.338v6.937a8.7 8.7 0 013.094-3.043L10 4.587a.319.319 0 00-.269-.175z"
        fill="#FFCC80"
      />
      <path
        d="M31.25 16.8a.625.625 0 01-.625-.625 7.462 7.462 0 00-4.238-6.713.625.625 0 01-.268-.887l2.787-4.638a1.6 1.6 0 012.969.825v11.413a.625.625 0 01-.625.625z"
        fill="#212121"
      />
      <path
        d="M27.531 8.656a8.7 8.7 0 013.094 3.044V4.763A.35.35 0 0030 4.58l-2.469 4.075z"
        fill="#FFCC80"
      />
      <path
        d="M10.313 21.25c-1.25 0-2.188-1.25-2.188-2.813 0-1.562.963-2.812 2.188-2.812 1.224 0 2.187 1.25 2.187 2.813 0 1.562-.963 2.812-2.188 2.812z"
        fill="#212121"
      />
      <path
        d="M10.313 16.875c-.444 0-.938.625-.938 1.563 0 .937.494 1.562.938 1.562.443 0 .937-.625.937-1.563 0-.937-.494-1.562-.938-1.562z"
        fill="#D7CCC8"
      />
      <path
        d="M29.688 21.25c-1.25 0-2.188-1.25-2.188-2.813 0-1.562.962-2.812 2.188-2.812 1.225 0 2.187 1.25 2.187 2.813 0 1.562-.962 2.812-2.188 2.812z"
        fill="#212121"
      />
      <path
        d="M29.688 16.875c-.444 0-.938.625-.938 1.563 0 .937.494 1.562.938 1.562.443 0 .937-.625.937-1.563 0-.937-.494-1.562-.938-1.562z"
        fill="#D7CCC8"
      />
      <path
        d="M21.563 34.375h-3.125a5.937 5.937 0 010-11.875h3.125a5.937 5.937 0 010 11.875z"
        fill="#212121"
      />
      <path
        d="M18.438 23.75a4.688 4.688 0 000 9.375h3.125a4.688 4.688 0 000-9.375h-3.125z"
        fill="#FFCC80"
      />
      <path
        d="M19.375 32.5a.624.624 0 01-.625-.625v-.625a.624.624 0 111.25 0v.625a.624.624 0 01-.625.625zm1.25 0a.624.624 0 01-.625-.625v-.625a.624.624 0 111.25 0v.625a.624.624 0 01-.625.625z"
        fill="#212121"
      />
      <path
        d="M20 31.25a.624.624 0 01-.625-.625V28.75a.624.624 0 111.25 0v1.875a.624.624 0 01-.625.625z"
        fill="#212121"
      />
      <path
        d="M20 29.113c-.33 0-.647-.13-.881-.363L17.5 27.131A1.25 1.25 0 0118.381 25h3.232a1.25 1.25 0 01.887 2.131l-1.619 1.619a1.25 1.25 0 01-.88.363z"
        fill="#212121"
      />
      <path d="M21.619 26.25h-3.244L20 27.869l1.619-1.619z" fill="#4E342E" />
      <path
        d="M21.25 31.25h-2.5a.624.624 0 110-1.25h2.5a.624.624 0 110 1.25zM23.2 7.5h-6.4a8.582 8.582 0 00-3.069.563l1.544 6.168c.181.719.288 1.45.419 2.182a4.376 4.376 0 008.612 0c.132-.732.238-1.463.42-2.182l1.524-6.175A8.712 8.712 0 0023.2 7.5z"
        fill="#212121"
      />
      <path
        d="M23.513 13.925c-.15.587-.25 1.175-.344 1.737l-.087.52a3.125 3.125 0 01-6.15 0l-.094-.526a20.803 20.803 0 00-.344-1.737l-1.25-5A7.388 7.388 0 0116.8 8.75h6.4a7.5 7.5 0 011.563.163l-1.25 5.012z"
        fill="#FFCC80"
      />
    </svg>
  );
}

export default SvgSquirrel;
