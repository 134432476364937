import * as React from "react";
import { SVGProps } from "react";
const SvgVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.375 3.75a2.718 2.718 0 0 0-2.708 2.708v7.084a2.718 2.718 0 0 0 2.708 2.708h7.083a2.718 2.718 0 0 0 2.709-2.708v-.563l3.22 1.932a.625.625 0 0 0 .946-.536v-8.75a.625.625 0 0 0-.946-.536l-3.22 1.932v-.563a2.718 2.718 0 0 0-2.709-2.708H4.375Zm0 1.25h7.083a1.45 1.45 0 0 1 1.459 1.458v7.084A1.45 1.45 0 0 1 11.458 15H4.375a1.45 1.45 0 0 1-1.458-1.458V6.458A1.45 1.45 0 0 1 4.375 5Zm12.708 1.73v6.54l-2.916-1.749V8.479l2.916-1.75Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgVideo;
