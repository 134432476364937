import * as React from "react";

function SvgAppSlack(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.202 14.64c0 1.155-.945 2.1-2.101 2.1A2.106 2.106 0 012 14.64c0-1.157.945-2.102 2.101-2.102h2.101v2.101zM7.262 14.639c0-1.156.944-2.101 2.1-2.101 1.157 0 2.102.944 2.102 2.1V19.9A2.106 2.106 0 019.363 22a2.106 2.106 0 01-2.101-2.1v-5.261z"
        fill="#E01E5A"
      />
      <path
        d="M9.362 6.202a2.106 2.106 0 01-2.101-2.1C7.26 2.944 8.205 2 9.36 2c1.157 0 2.102.945 2.102 2.101v2.101H9.362zM9.362 7.261c1.156 0 2.1.945 2.1 2.101a2.106 2.106 0 01-2.1 2.101H4.1A2.106 2.106 0 012 9.363c0-1.157.945-2.102 2.101-2.102h5.26z"
        fill="#36C5F0"
      />
      <path
        d="M17.798 9.363c0-1.156.945-2.101 2.1-2.101 1.157 0 2.102.945 2.102 2.1 0 1.157-.945 2.102-2.101 2.102h-2.101V9.363zM16.74 9.362a2.106 2.106 0 01-2.102 2.1 2.106 2.106 0 01-2.1-2.1v-5.26c0-1.157.944-2.102 2.1-2.102 1.156 0 2.101.945 2.101 2.101v5.26z"
        fill="#2EB67D"
      />
      <path
        d="M14.638 17.8c1.156 0 2.101.944 2.101 2.1 0 1.157-.945 2.102-2.1 2.102a2.106 2.106 0 01-2.102-2.101V17.8h2.101zM14.638 16.74a2.106 2.106 0 01-2.1-2.1c0-1.157.944-2.101 2.1-2.101h5.26c1.157 0 2.102.944 2.102 2.1a2.106 2.106 0 01-2.101 2.102h-5.26z"
        fill="#ECB22E"
      />
    </svg>
  );
}

export default SvgAppSlack;
