import { x } from '@xstyled/styled-components';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import {
  useGroupsLazyQuery,
  useUsersLazyQuery,
} from '../../../../generated/types-and-hooks';
import {
  GROUPS_PER_PAGE_SELECTOR,
  USERS_PER_PAGE,
} from '../../../config/constants';
import { useAuth } from '../../auth/hooks/useAuth';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { GroupsNavigationHeader } from '../../groups/components/GroupsNavigationHeader';
import { GroupsSidebar } from '../../groups/components/GroupsSidebar';
import {
  OrganizationSidebar,
  OrganizationSidebarSkeleton,
} from '../../organizations/components/OrganizationSidebar';
import { useCustomDomain } from '../../organizations/hooks/useCustomDomain';
import { FloatingDemoPrompt } from '../components/FloatingDemoPrompt';
import { FullscreenPageLayout } from '../components/FullscreenPageLayout';
import { useContentScroll } from '../hooks/useContentScroll';

export const CONTENT_WIDTH = 600;
export const GROUPS_SIDEBAR_WIDTH = 280;
export const ORGANIZATION_SIDEBAR_WIDTH = 68;

const PrimeCache = () => {
  const { organization } = useOrganization();

  const [callUsersQuery] = useUsersLazyQuery({
    variables: {
      organizationId: organization.id,
      first: USERS_PER_PAGE,
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  });

  const [callGroupsQuery] = useGroupsLazyQuery({
    variables: {
      organizationId: organization.id,
      first: GROUPS_PER_PAGE_SELECTOR,
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  });

  // Don't prime the cache right away since there's a lot of network calls at the beginning
  // and this data isn't needed until the group selector or comment mentions
  useEffect(() => {
    window.setTimeout(() => {
      callGroupsQuery();
      callUsersQuery();
    }, 4000);
  }, [callGroupsQuery, callUsersQuery]);

  return null;
};

/**
 * Layout component for all signed in pages
 */
export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const { viewer: user } = useViewer();
  const { hasAuth0Cookie } = useAuth();
  const { viewingCustomDomain } = useCustomDomain();

  const [showOrganizationSidebar, setShowOrganizationSidebar] =
    useState(!viewingCustomDomain);

  return (
    <BasePageLayout
      communitySidebar={
        showOrganizationSidebar && user ? (
          <OrganizationSidebar />
        ) : showOrganizationSidebar && hasAuth0Cookie ? (
          <OrganizationSidebarSkeleton />
        ) : null
      }
      sidebar={
        <GroupsSidebar
          onSelectSwitchOrganization={
            !showOrganizationSidebar
              ? () => setShowOrganizationSidebar(true)
              : undefined
          }
        />
      }
      mobileNavigation={<GroupsNavigationHeader />}
    >
      <PrimeCache />
      {children}
    </BasePageLayout>
  );
};

export const BasePageLayout: React.FC<
  PropsWithChildren<{
    communitySidebar?: React.ReactNode;
    sidebar: React.ReactNode;
    mobileNavigation?: React.ReactNode;
  }>
> = ({ communitySidebar, sidebar, mobileNavigation, children }) => {
  const { contentScrollId } = useContentScroll();

  return (
    <FullscreenPageLayout>
      <x.div
        display={{ 'lg-2': 'grid' }}
        gridTemplateColumns={{
          'lg-2': communitySidebar
            ? `${ORGANIZATION_SIDEBAR_WIDTH}px ${GROUPS_SIDEBAR_WIDTH}px 1fr`
            : `auto 1fr`,
        }}
      >
        {communitySidebar && (
          <x.div
            h="100vh" // fallback
            style={{
              height: '100dvh',
            }}
            position="sticky"
            top={0}
            display={{ _: 'none', 'lg-2': 'block' }}
            overscrollBehavior="contain"
            overflowY="auto"
          >
            {communitySidebar}
          </x.div>
        )}
        <x.nav
          overflowX="hidden"
          h="100vh" // fallback
          style={{
            height: '100dvh',
          }}
          position="sticky"
          top={0}
          overscrollBehavior="contain"
          display={{ _: 'none', 'lg-2': 'block' }}
        >
          {sidebar}
        </x.nav>
        {mobileNavigation && (
          <x.div
            display={{ _: 'block', 'lg-2': 'none' }}
            position="sticky"
            zIndex="docked"
            top={0}
          >
            {mobileNavigation}
          </x.div>
        )}
        <x.main id={contentScrollId} minWidth={0}>
          {children}
        </x.main>
        <FloatingDemoPrompt />
      </x.div>
    </FullscreenPageLayout>
  );
};
