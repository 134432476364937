import * as React from "react";
import { SVGProps } from "react";
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 1.667c-4.595 0-8.333 3.738-8.333 8.333 0 4.595 3.738 8.333 8.333 8.333 4.595 0 8.333-3.738 8.333-8.333 0-4.595-3.738-8.333-8.333-8.333Zm0 1.25A7.074 7.074 0 0 1 17.083 10 7.074 7.074 0 0 1 10 17.083 7.074 7.074 0 0 1 2.917 10 7.074 7.074 0 0 1 10 2.917Zm0 2.916A.833.833 0 1 0 10 7.5a.833.833 0 0 0 0-1.667Zm-.01 2.908a.625.625 0 0 0-.615.634v4.583a.625.625 0 1 0 1.25 0V9.375a.625.625 0 0 0-.635-.634Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgInfo;
