import { x } from '@xstyled/styled-components';

import {
  EventsQuery,
  LocationTypes,
  useLocationByPlaceIdQuery,
} from '../../../../generated/types-and-hooks';
import { Icon } from '../../common/components/Icon';
import { Text } from '../../common/components/Text';

export const EventLocation: React.FC<{
  event: EventsQuery['events']['edges'][0]['node'];
  variant?: 'postCard' | 'eventCard' | 'sidebar';
}> = ({ event, variant = 'eventCard' }) => {
  const skip =
    !event.location?.placeId ||
    event.location.type !== LocationTypes.GooglePlaces;
  const { data } = useLocationByPlaceIdQuery({
    variables: {
      placeId: event.location?.placeId || '',
    },
    skip:
      !event.location?.placeId ||
      event.location.type !== LocationTypes.GooglePlaces,
  });

  const displayName = skip
    ? event.location?.displayName
    : data?.locationByPlaceId.displayName;

  if (variant === 'postCard') {
    return (
      <Text
        variant="sm"
        color="gray.300"
        {...(event?.deleted && {
          textDecoration: 'line-through',
        })}
        truncate
      >
        {displayName}
      </Text>
    );
  }

  return (
    <x.div display="flex" alignItems="center" spaceX={2}>
      <Icon
        name="location"
        color="gray.300"
        {...(variant === 'sidebar' && {
          size: '5',
          color: 'gray.500',
        })}
      />
      <Text
        variant="md"
        {...(event?.deleted && {
          textDecoration: 'line-through',
        })}
        color="gray.300"
        {...(variant === 'sidebar' && {
          color: 'gray.500',
        })}
        truncate
      >
        {skip
          ? event.location?.displayName
          : data?.locationByPlaceId.displayName}
      </Text>
    </x.div>
  );
};
