import * as React from "react";
import { SVGProps } from "react";
const SvgCourses = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.542 2.917A2.301 2.301 0 0 0 1.25 5.208v9.584a2.301 2.301 0 0 0 2.292 2.291h12.916a2.301 2.301 0 0 0 2.292-2.291V5.208a2.301 2.301 0 0 0-2.292-2.291H3.542Zm0 1.25h12.916c.583 0 1.042.459 1.042 1.041v9.584c0 .582-.46 1.041-1.042 1.041H3.542c-.583 0-1.042-.459-1.042-1.041V5.208c0-.582.46-1.041 1.042-1.041Zm6.464 1.666a.625.625 0 0 0-.566.347L8.428 8.219l-2.268.327a.625.625 0 0 0-.345 1.067l1.638 1.585-.387 2.237a.625.625 0 0 0 .905.66L10 13.037l2.029 1.06a.625.625 0 0 0 .905-.66l-.387-2.238 1.638-1.585a.626.626 0 0 0-.346-1.067l-2.267-.327L10.56 6.18a.625.625 0 0 0-.554-.347ZM10 7.863l.599 1.205a.624.624 0 0 0 .47.34l1.332.192-.96.93a.625.625 0 0 0-.181.555l.227 1.317-1.198-.625a.625.625 0 0 0-.578 0l-1.198.625.227-1.317a.625.625 0 0 0-.18-.555L7.6 9.6l1.33-.192a.625.625 0 0 0 .471-.34L10 7.863Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCourses;
