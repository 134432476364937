import * as React from "react";

function SvgPig(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 36.875A16.875 16.875 0 015.03 12.212a.626.626 0 01.557-.337h6.287V5.587a.625.625 0 01.338-.556 16.875 16.875 0 0115.58 0 .626.626 0 01.338.556v6.288h6.282a.625.625 0 01.556.338 16.874 16.874 0 01-14.969 24.662z"
        fill="#212121"
      />
      <path
        d="M5.968 13.125A15.375 15.375 0 004.374 20a15.625 15.625 0 0031.25 0 15.376 15.376 0 00-1.594-6.875H27.5a.625.625 0 01-.626-.625V5.969a15.625 15.625 0 00-13.75 0V12.5a.625.625 0 01-.625.625H5.968z"
        fill="#FFCDD2"
      />
      <path
        d="M35.106 13.125H27.5a.625.625 0 01-.625-.625V4.894a1.875 1.875 0 013.2-1.331l6.363 6.362a1.874 1.874 0 01-1.332 3.2z"
        fill="#212121"
      />
      <path
        d="M28.125 11.875h6.981a.626.626 0 00.444-1.063L29.188 4.45a.625.625 0 00-.675-.137.625.625 0 00-.388.58v6.982z"
        fill="#EF9A9A"
      />
      <path
        d="M12.5 13.125H4.892a1.875 1.875 0 01-1.331-3.2l6.363-6.362a1.875 1.875 0 013.2 1.33V12.5a.625.625 0 01-.626.625z"
        fill="#212121"
      />
      <path
        d="M11.25 4.262a.588.588 0 00-.426.182L4.45 10.813a.625.625 0 00.444 1.062h6.981V4.894a.625.625 0 00-.387-.582.67.67 0 00-.238-.05z"
        fill="#EF9A9A"
      />
      <path
        d="M23.438 31.25h-6.875a3.438 3.438 0 010-6.875h6.875a3.438 3.438 0 010 6.875z"
        fill="#212121"
      />
      <path
        d="M16.563 25.625a2.188 2.188 0 000 4.375h6.875a2.188 2.188 0 000-4.375h-6.875z"
        fill="#EF9A9A"
      />
      <path
        d="M16.875 22.5a.625.625 0 01-.625-.625v-.95a.625.625 0 111.25 0v.95a.625.625 0 01-.625.625zm6.25 0a.625.625 0 01-.625-.625v-.956a.624.624 0 111.25 0v.956a.625.625 0 01-.625.625zM17.5 28.75a.625.625 0 01-.625-.625V27.5a.625.625 0 111.25 0v.625a.625.625 0 01-.625.625zm5 0a.625.625 0 01-.625-.625V27.5a.625.625 0 111.25 0v.625a.625.625 0 01-.625.625z"
        fill="#212121"
      />
    </svg>
  );
}

export default SvgPig;
