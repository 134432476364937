import * as React from 'react';
import { SVGProps } from 'react';

const SvgAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M5.75 3C4.24 3 3 4.24 3 5.75v12.5C3 19.76 4.24 21 5.75 21h12.5c1.51 0 2.75-1.24 2.75-2.75V5.75C21 4.24 19.76 3 18.25 3H5.75Zm0 1.5h12.5c.7 0 1.25.552 1.25 1.25v12.5c0 .7-.55 1.25-1.25 1.25H5.75c-.7 0-1.25-.55-1.25-1.25V5.75c0-.698.55-1.25 1.25-1.25Zm6.238 2.49a.75.75 0 0 0-.738.76v3.5h-3.5a.75.75 0 1 0 0 1.5h3.5v3.5a.752.752 0 0 0 1.284.538.75.75 0 0 0 .216-.537v-3.5h3.5a.751.751 0 1 0 0-1.5h-3.5v-3.5a.75.75 0 0 0-.762-.761Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgAdd;
