import { useApolloClient, useSubscription } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import {
  GroupUnseenPostsDocument,
  GroupUnseenPostsQuery,
  GroupUnseenPostsQueryVariables,
  HomeFeedLatestContentDocument,
  HomeFeedLatestContentQuery,
  HomeFeedLatestContentQueryVariables,
  MenuSectionsDocument,
  MenuSectionsQuery,
  MenuSectionsQueryVariables,
  OrganizationPushEventsDocument,
  PostDocument,
  PostQuery,
  PostQueryVariables,
} from '../../../../generated/types-and-hooks';
import { useViewer } from '../../auth/hooks/useViewer';

/**
 * Listen for new org events and refetch in response
 */
export const useOrganizationEventSubscription = (organizationId: string) => {
  const viewer = useViewer();
  const { data } = useSubscription(OrganizationPushEventsDocument, {
    variables: { organizationId },
    fetchPolicy: 'no-cache',
    skip: !viewer,
  });

  const client = useApolloClient();
  const router = useRouter();
  const postId = data?.organizationPushEvent?.postId;
  const groupId = data?.organizationPushEvent?.groupId;

  /**
   * Refetch data depending on the notification payload
   */
  useEffect(() => {
    const event = data?.organizationPushEvent;

    if (!event) {
      return;
    }

    /**
     * Check for new feed content
     */
    client.query<
      HomeFeedLatestContentQuery,
      HomeFeedLatestContentQueryVariables
    >({
      query: HomeFeedLatestContentDocument,
      variables: { organizationId },
    });

    /**
     * Check for new menu sidebar
     */
    client.query<MenuSectionsQuery, MenuSectionsQueryVariables>({
      query: MenuSectionsDocument,
      variables: { organizationId },
    });

    /**
     * Pull the latest post content
     */
    postId &&
      client.query<PostQuery, PostQueryVariables>({
        query: PostDocument,
        variables: { id: postId },
      });

    groupId &&
      client.query<GroupUnseenPostsQuery, GroupUnseenPostsQueryVariables>({
        query: GroupUnseenPostsDocument,
        variables: { id: groupId },
      });
  }, [data, postId, groupId, client, router, organizationId]);
};
