import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { FC, PropsWithChildren, ReactElement, useState } from 'react';

import {
  GroupWithMembersFragment,
  refetchGroupQuery,
  User,
  useRemoveGroupMemberMutation,
} from '../../../../generated/types-and-hooks';
import { Avatar } from '../../common/components/Avatar';
import { Icon } from '../../common/components/Icon';
import { Text } from '../../common/components/Text';
import { getUserDisplayName } from '../../common/utils/user';
import {
  StyledDropdownMenuButton,
  StyledDropdownMenuContent,
  StyledDropdownMenuItem,
} from '../../posts/components/PostCard';
import { GroupShareModal } from './GroupShareModal/GroupShareModal';

export const GroupMemberListItemUser: FC<{
  user: Pick<
    User,
    | 'profileImageId'
    | 'avatarBgColor'
    | 'avatar'
    | 'firstName'
    | 'lastName'
    | 'username'
    | 'id'
  >;
  group?: {
    id: string;
    createdBy: {
      id: string;
    };
  };
  editable?: boolean;
}> = ({ user, group, editable }) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const createdByCurrentUser = group && user.id === group.createdBy.id;

  const [removeMember, { loading }] = useRemoveGroupMemberMutation({
    variables: { input: { groupId: group?.id || '', userId: user?.id } },
    refetchQueries: [refetchGroupQuery({ id: group?.id || '' })],
    awaitRefetchQueries: true,
  });

  const getLabel = () => {
    if (createdByCurrentUser) {
      return t('group_manager');
    }

    return undefined;
  };

  const menu = editable ? (
    <DropdownMenu.Root onOpenChange={setMenuOpen}>
      <DropdownMenu.Trigger asChild>
        <StyledDropdownMenuButton
          cursor="pointer"
          m={0}
          p={3}
          bg={{ _: 'whiteAlpha.400', hover: 'whiteAlpha.450' }}
          borderRadius="sm-md"
          position="relative"
          border={1}
          borderColor={{
            _: 'transparent',
            hover: 'brand.300',
          }}
          spaceX={1}
          display="flex"
          transition
          transitionDuration="fast"
          transitionTimingFunction="ease-out"
          ml="auto !important"
        >
          {[...new Array(3)].map((_, i) => {
            return <x.div bg="gray.500" borderRadius={4} key={i} w={1} h={1} />;
          })}
        </StyledDropdownMenuButton>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <StyledDropdownMenuContent>
          <x.div mt={1} p={3} boxShadow="sm" borderRadius="md" spaceY={1}>
            <StyledDropdownMenuItem onSelect={() => removeMember()}>
              {t('remove_from_group')}
            </StyledDropdownMenuItem>
          </x.div>
        </StyledDropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  ) : undefined;

  return (
    <x.div
      backgroundColor={menuOpen ? 'brand.50' : 'white'}
      opacity={loading ? 0.5 : 1}
      ml="-2"
      px="2"
      borderRadius="md"
      transition
      transitionDuration="fast"
      transitionTimingFunction="ease-out"
    >
      <GroupMemberListItemLayout
        thumbnail={
          <Avatar
            imageId={user.profileImageId}
            bgColor={user.avatarBgColor}
            avatar={user.avatar}
            size="s-3"
          />
        }
        identifier={getUserDisplayName(user)}
        label={getLabel()}
        menu={menu}
      />
    </x.div>
  );
};

export const GroupMemberListItemInvite: FC<{
  group:
    | Pick<
        GroupWithMembersFragment,
        'members' | 'isPrivate' | 'id' | 'createdBy' | 'membership'
      >
    | GroupWithMembersFragment;
}> = ({ group }) => {
  const { t } = useTranslation();
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  return (
    <x.button
      outline="none"
      border="none"
      p={0}
      background="transparent"
      cursor="pointer"
      onClick={() => setInviteModalOpen(true)}
    >
      <GroupMemberListItemLayout
        thumbnail={
          <x.span
            backgroundColor="brand.300"
            w="8"
            h="8"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="sm-md"
          >
            <Icon name="plus" size="4" color="white" />
          </x.span>
        }
        identifier={t('add_members')}
      >
        {inviteModalOpen && 'isDraft' in group && (
          <GroupShareModal
            group={group}
            isOpen
            onDismiss={() => setInviteModalOpen(false)}
          />
        )}
      </GroupMemberListItemLayout>
    </x.button>
  );
};

export const GroupMemberListItemLayout: FC<
  PropsWithChildren<{
    thumbnail: ReactElement;
    identifier: string;
    label?: string;
    disabled?: boolean;
    menu?: ReactElement;
  }>
> = ({ thumbnail, identifier, label, disabled, children, menu }) => {
  return (
    <>
      <x.span display="flex" spaceX={2} alignItems="center" w="full" py={2}>
        {thumbnail}
        <Text
          as="span"
          variant="md"
          truncate
          color={disabled ? 'gray.300' : 'gray.500'}
        >
          {identifier}
        </Text>
        {label && (
          <x.span
            justifyContent="flex-end"
            backgroundColor="gray.100"
            p="1 2"
            borderRadius="sm"
          >
            <Text as="span" variant="sm-medium">
              {label}
            </Text>
          </x.span>
        )}
        {menu}
      </x.span>
      {children}
    </>
  );
};
