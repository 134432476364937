import * as React from "react";

function SvgUploadSuccess(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 14a5 5 0 01-5 5H7a6 6 0 01-.78-11.95 7.004 7.004 0 0112.506 2.002A5.001 5.001 0 0123 14zm-5 3a3 3 0 100-6h-.063l-.863.018-.143-.852a5.002 5.002 0 00-9.265-1.662l-.275.478-.551.021A4 4 0 007 17h11zm-4.707-7.707L10 12.586l-1.293-1.293-1.414 1.414L10 15.414l4.707-4.707-1.414-1.414z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgUploadSuccess;
