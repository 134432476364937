import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';

import { Input } from '../../../common/components/Input';
import { SetupAvailabilityLabel } from './SetupAvailabilityLabel';

type UsernameInputProps = {
  variant?: 'default' | 'setup';
};
export const UsernameInput = ({ variant = 'default' }: UsernameInputProps) => {
  const { t } = useTranslation();
  const form = useFormContext();
  const username = form.watch('username');

  const renderAvailabilityLabel = () => {
    if (!username || username === form.formState.defaultValues?.username)
      return;

    if (form.formState.errors.username) {
      return (
        <SetupAvailabilityLabel success={false}>
          {form.formState.errors.username?.type === 'availability'
            ? t('unavailable')
            : t('invalid')}
        </SetupAvailabilityLabel>
      );
    }
    return (
      <SetupAvailabilityLabel success>{t('available')}</SetupAvailabilityLabel>
    );
  };

  return (
    <x.div display="flex" flexDirection="column" position="relative">
      <Input
        placeholder={username}
        label={variant === 'setup' ? 'Username' : undefined}
        {...form.register('username')}
        fullWidth
      />
      <x.div
        h="full"
        display="flex"
        alignItems="center"
        right={16}
        position="absolute"
      >
        {renderAvailabilityLabel()}
      </x.div>
    </x.div>
  );
};
