import {
  ListboxButton,
  ListboxInput,
  ListboxList,
  ListboxOption,
  ListboxPopover,
} from '@reach/listbox';
import styled, { css, th, x } from '@xstyled/styled-components';
import { system, SystemProps } from '@xstyled/system';
import { useTranslation } from 'next-i18next';

import { Icon } from './Icon';
import { Text } from './Text';

const StyledListboxInput = styled(ListboxInput)<{
  fullWidth?: boolean;
}>`
  ${(p) =>
    p.fullWidth &&
    css`
      width: full;
      box-sizing: border-box;

      @media (max-width: sm) {
        width: full;
        max-width: full;
      }
    `}
`;

const StyledListboxPopover = styled(ListboxPopover)`
  background-color: white;
  z-index: popover;
  margin-top: 1;
  border-radius: md;
  max-height: ${th.size('50')};
  overflow-y: scroll;
  padding: 0;
  box-sizing: border-box;

  box-shadow: sm;
  border: none;

  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  animation: slide-down 0.2s ease;

  &:focus-within {
    outline: none;
  }
`;

const StyledListboxButton = styled(ListboxButton)`
  width: full;
  border: default;
  border-color: gray.200;
  border-radius: md;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1 4;

  &:hover {
    border-color: brand.200;
  }

  &[data-reach-listbox-button][aria-disabled='true'] {
    :hover {
      border-color: gray.200;
    }
  }

  &[aria-expanded='true'] {
    border-color: brand.200;

    svg {
      transform: rotate(180deg);
    }
  }
  ${system}
`;

const StyledListboxOption = styled(ListboxOption)`
  padding: ${th.size('1.5')} 0;
  color: gray.500;
  cursor: default;

  &:hover {
    color: brand.300;
  }

  &[data-reach-listbox-option] {
    padding: 0;
  }

  &[data-reach-listbox-option][data-current-selected] {
    color: brand.300;
  }

  &[data-reach-listbox-option][data-current-nav] {
    color: brand.300;
    background-color: transparent;
  }
`;

const StyledListboxList = styled(ListboxList)<SystemProps>`
  padding: 4;

  ${system};
`;

export type Option = {
  label: string;
  value: string;
};

export type SelectProps = {
  name: string;
  placeholder?: string;
  options: Option[];
  onChange: (value: string) => void;
  value?: string;
  prefix?: string;
  suffix?: string;
  noDropdownIcon?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
};

export const Select = ({
  options,
  placeholder,
  prefix,
  suffix,
  onChange,
  value,
  noDropdownIcon,
  disabled,
  fullWidth,
  ...props
}: SelectProps & SystemProps) => {
  const { t } = useTranslation();
  const selectedOption = options.find((option) => option.value === value);

  const getText = () => {
    if (selectedOption) {
      return `${selectedOption?.label}${suffix ? ` ${suffix}` : ''}`;
    } else if (placeholder) {
      return placeholder;
    }

    return '';
  };

  return (
    <StyledListboxInput
      onChange={onChange}
      value={value}
      disabled={disabled}
      defaultValue={t('everyone')}
      fullWidth={fullWidth}
    >
      {/* @ts-expect-error not sure how to handle this */}
      <StyledListboxButton {...props} backgroundColor="white">
        <x.div display="flex" spaceX={1}>
          {prefix && <Text color="gray.300">{prefix}</Text>}
          <Text>{getText()}</Text>
        </x.div>
        {!noDropdownIcon && (
          <Icon name="chevron-bottom" size="4" color="gray.300" />
        )}
      </StyledListboxButton>
      <StyledListboxPopover>
        <StyledListboxList spaceY={3}>
          {options.map((option, i) => {
            return (
              <StyledListboxOption key={i} value={option.value}>
                <Text>{option.label}</Text>
              </StyledListboxOption>
            );
          })}
        </StyledListboxList>
      </StyledListboxPopover>
    </StyledListboxInput>
  );
};
