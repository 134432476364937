import { theme } from '@frond/shared';
import styled, { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Cropper, CropperRef } from 'react-advanced-cropper';
import { useDropzone } from 'react-dropzone';

import { GroupsModal } from '../../groups/components/GroupsModal';
import { useFileUpload, UseFileUploadProps } from '../hooks/useFileUpload';
import { dashedBorderSVGString, svgStringToImageUrl } from '../utils';
import { UploadError } from '../utils/cloudinary';
import { Avatar } from './Avatar';
import { AvatarPicker } from './AvatarPicker';
import { Button } from './Button';
import { Icon } from './Icon';
import { Toast } from './Toast';

const PROFILE_AVATAR_MAX_SIZE = 15_000_000; // 15mb

export type ProfileAvatarInputProps = UseFileUploadProps & {
  profileImageId?: string | null;
  onRemove: () => void;
  showAvatarPicker?: boolean;
  editable?: boolean;
  label?: string;
};

/**
 * Component for making a file input that looks like one of our buttons
 */
export const ProfileAvatarInput: React.FC<ProfileAvatarInputProps> = ({
  profileImageId,
  onUploadSuccess,
  onUploadFailure,
  onRemove,
  name,
  showAvatarPicker = true,
  editable = true,
  ...props
}) => {
  const { t } = useTranslation();
  const label = props.label || t('photo');
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [fileToCrop, setFileToCrop] = useState<File | null>(null);

  const { loading, uploadSelectedFile } = useFileUpload({
    onUploadSuccess,
    name,
    accept: 'image/*',
    onUploadFailure: (e) => {
      onUploadFailure?.(e);
      setUploadError(e.message);
    },
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: { 'image/*': [] },
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFileToCrop(acceptedFiles[0]);
      }
    },
  });

  return (
    <x.div
      display="flex"
      flexDirection="column"
      alignItems="center"
      {...getRootProps()}
    >
      {fileToCrop ? (
        <ImageCropper
          onDismiss={() => setFileToCrop(null)}
          isOpen={!!fileToCrop}
          file={fileToCrop}
          onUseImage={(image) => {
            uploadSelectedFile(image);
          }}
        />
      ) : null}

      {!!uploadError && (
        <Toast
          variant="error"
          message={uploadError}
          onDisappear={() => setUploadError(null)}
        />
      )}
      <input
        {...getInputProps()}
        onChange={(e) => {
          if (e.target?.files) {
            // Check size
            if (e.target?.files[0].size > PROFILE_AVATAR_MAX_SIZE) {
              onUploadFailure?.(
                new UploadError(t('error_file_size_exceeded', { max: '15mb' }))
              );
              return;
            }

            setFileToCrop(e.target?.files[0]);
          }
        }}
      />
      <x.div position="relative" mb="4">
        {isDragActive && (
          <x.div
            backgroundImage={`url("${svgStringToImageUrl(
              dashedBorderSVGString({
                color: `${theme.colors.brand[300]}`,
                dasharray: [`${theme.sizes['3']}`, `${theme.sizes['4']}`],
                radius: 'lg',
              })
            )}")`}
            borderRadius="lg"
            boxSizing="border-box"
            position="absolute"
            w="100%"
            h="100%"
          />
        )}
        {profileImageId ? (
          <x.button
            display="contents"
            onClick={(e) => {
              e.preventDefault();
              openFileDialog();
            }}
            border={1}
            disabled={!editable}
          >
            <Avatar imageId={profileImageId} size="lg-2" />
          </x.button>
        ) : showAvatarPicker ? (
          <AvatarPicker />
        ) : (
          <x.button
            type="button"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor="gray.50"
            borderRadius="md-xl"
            cursor="pointer"
            w={18}
            h={18}
            onClick={(e) => {
              e.preventDefault();
              openFileDialog();
            }}
            border="none"
            disabled={!editable}
          >
            <Icon name="image-add" size="8" color="gray.300" />
          </x.button>
        )}
      </x.div>
      <x.div
        display="flex"
        flexDirection="row"
        alignContent="flex-start"
        spaceX="10"
      >
        <Button
          type="button"
          variant="text"
          color="brand.300"
          size="md"
          label={loading ? t('uploading') : t('upload_media', { media: label })}
          boxShadow={{ focus: 'none' }}
          disabled={loading || !editable}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            openFileDialog();
          }}
        />
        {!loading && (
          <Button
            type="button"
            variant="text"
            color="red.300"
            size="md"
            onClick={onRemove}
            label={t('remove_media', { media: label })}
            boxShadow={{ focus: 'none' }}
            disabled={!editable || profileImageId === null}
          />
        )}
      </x.div>
    </x.div>
  );
};

type ImageCropperProps = {
  file: File;
  onDismiss: () => void;
  isOpen: boolean;
  onUseImage: (image: File) => void;
  variant?: 'profileImage' | 'coverImage';
};

const StyledCropper = styled(Cropper)`
  background-color: gray.50;

  .advanced-cropper-simple-line {
    border-color: brand.300;
  }

  .advanced-cropper-simple-handler {
    border: 1px solid ${theme.colors.brand[300]};
  }

  .advanced-cropper-simple-line--north {
    border-top-width: 2px;
  }

  .advanced-cropper-simple-line--west {
    border-left-width: 2px;
  }

  .advanced-cropper-simple-line--east {
    border-right-width: 2px;
  }

  .advanced-cropper-simple-line--south {
    border-bottom-width: 2px;
  }
`;

export const ImageCropper = ({
  file,
  onDismiss,
  isOpen,
  onUseImage,
  variant = 'profileImage',
}: ImageCropperProps) => {
  const [originalImageBase64, setOriginalImageBase64] = useState<string | null>(
    null
  );

  const cropperRef = useRef<CropperRef>(null);

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = () => {
      const binaryStr = reader.result;

      if (binaryStr) {
        setOriginalImageBase64(binaryStr.toString());
      }
    };
    reader.readAsDataURL(file);
  }, [file]);

  const crop = () => {
    const croppedImageBase64 = cropperRef.current?.getCanvas()?.toDataURL();

    if (croppedImageBase64) {
      const fileToUpload = new File([croppedImageBase64], file.name, {
        type: file.type,
      });
      onUseImage(fileToUpload);
    } else if (originalImageBase64) {
      const fileToUpload = new File([originalImageBase64], file.name, {
        type: file.type,
      });
      onUseImage(fileToUpload);
    }
    onDismiss();
  };

  return (
    <GroupsModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      ariaLabel="Image cropper modal"
      submitButton={
        <Button
          label="Crop"
          onClick={crop}
          variant="text"
          color="brand.300"
          size="lg"
        />
      }
    >
      <StyledCropper
        src={originalImageBase64}
        ref={cropperRef}
        aspectRatio={
          variant === 'profileImage'
            ? { minimum: 1, maximum: 1 }
            : {
                minimum: 16 / 9,
                maximum: 16 / 9,
              }
        }
        defaultPosition={{
          left: 0,
          top: 0,
        }}
        defaultSize={({ imageSize, visibleArea }) => {
          return {
            width: (visibleArea || imageSize).width,
            height: (visibleArea || imageSize).height,
          };
        }}
      />
      <x.div mt={4} display={{ _: 'none', sm: 'flex' }} justifyContent="end">
        <Button label="Crop" onClick={crop} />
      </x.div>
    </GroupsModal>
  );
};
