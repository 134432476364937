import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useState } from 'react';

import { SignInEmailSuccess } from '../../auth/components/SignIn/SignInEmailSuccess';
import { SignInForm } from '../../auth/components/SignIn/SignInForm';
import { useViewer } from '../../auth/hooks/useViewer';
import { Button } from '../../common/components/Button';
import { Heading } from '../../common/components/Heading';
import { Text } from '../../common/components/Text';
import { Toast } from '../../common/components/Toast';
import { useSignOut } from '../../common/hooks/useApollo';
import { AppMachineOrganization } from '../../common/machine/appMachine';
import { useJoinOrganization } from '../hooks/useJoinOrganization';
import { InviteCommunityLayout } from './InviteCommunityLayout';

export const JoinCommunity: React.FC<{
  organization: NonNullable<AppMachineOrganization>;
  inviteCode?: string;
}> = ({ organization, inviteCode }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const signout = useSignOut();
  const { viewer } = useViewer();
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);

  const {
    mutateJoinOrganization,
    error,
    loading: joiningOrganization,
  } = useJoinOrganization({ organization });

  const hasJoined = viewer?.organizationMemberships?.find(
    (organizationMembership) =>
      organization?.id === organizationMembership.organization.id
  );

  const handleClick = async () => {
    if (viewer) {
      await mutateJoinOrganization({
        variables: {
          input: {
            userId: viewer.id,
            organizationId: organization.id,
            inviteCode,
          },
        },
      });
    }
  };

  return (
    <>
      <InviteCommunityLayout
        title={
          <Heading variant="xl" mb={2}>
            <Trans
              i18nKey="auth.invited_to_community"
              ns="common"
              organizationName={organization.name}
            >
              You&apos;ve been invited to the{' '}
              <Heading variant="xl" color="brand.300" as="span">
                {/* @ts-expect-error related to xstyled v4 */}
                {{ organizationName: organization.name }}
              </Heading>{' '}
              community
            </Trans>
          </Heading>
        }
        organization={organization}
      >
        {viewer ? (
          <x.div textAlign="center" w="full">
            <Button
              label={
                hasJoined
                  ? t('joined', { ns: 'common' })
                  : t('join', { ns: 'common' })
              }
              loading={joiningOrganization}
              onClick={handleClick}
              disabled={!!hasJoined}
              p={4}
              w="full"
              mb={4}
            />
            <Text color="gray.300" variant="sm">
              {t('auth.signed_in_as_email', { email: viewer.email })}
            </Text>
            <x.button
              onClick={() => signout(router.asPath)}
              backgroundColor="transparent"
              outline="none"
              border="none"
              cursor="pointer"
            >
              <Text color="gray.300" variant="sm">
                {t('auth.another_account_link')}
              </Text>
            </x.button>
          </x.div>
        ) : showEmailSuccess ? (
          <SignInEmailSuccess />
        ) : (
          <SignInForm
            inviteCode={inviteCode}
            callbackUrlPath={router.asPath as string}
            onEmailSuccess={() => setShowEmailSuccess(true)}
            labelGoogle={t('auth.join_google')}
            labelEmail={t('auth.join_email')}
            maxW="480px"
            w="full"
          />
        )}
      </InviteCommunityLayout>
      {error && (
        <Toast
          variant="error"
          message={
            error.message === 'SuspendedUserError'
              ? 'Your account has been suspended. We sent a request to join.'
              : error.message
          }
        />
      )}
    </>
  );
};
