import * as React from "react";
import { SVGProps } from "react";
const SvgSend = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 18.333c4.595 0 8.333-3.738 8.333-8.333 0-4.595-3.738-8.333-8.333-8.333-4.595 0-8.333 3.738-8.333 8.333 0 4.595 3.738 8.333 8.333 8.333Zm-2.942-9.4 2.5-2.5a.625.625 0 0 1 .884 0l2.5 2.5a.625.625 0 1 1-.884.884l-1.433-1.433v4.741a.625.625 0 0 1-1.25 0V8.384L7.942 9.817a.625.625 0 1 1-.884-.884Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSend;
