import * as React from "react";

function SvgAppMsWord(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-ms-word_svg__clip0)">
        <g clipPath="url(#app-ms-word_svg__clip1)">
          <path
            d="M22.977.838H6.604c-.565 0-1.023.458-1.023 1.023v4.558l9.488 2.79L24 6.42V1.86c0-.565-.457-1.023-1.022-1.023z"
            fill="#41A5EE"
          />
          <path d="M24 6.419H5.58V12l9.488 1.675L24 12V6.42z" fill="#2B7CD3" />
          <path
            d="M5.581 12v5.582l8.93 1.116L24 17.582V12H5.58z"
            fill="#185ABD"
          />
          <path
            d="M6.604 23.163h16.373c.565 0 1.023-.458 1.023-1.023v-4.558H5.58v4.558c0 .565.458 1.023 1.023 1.023z"
            fill="#103F91"
          />
          <path
            opacity={0.1}
            d="M12.372 5.303h-6.79v13.953h6.79a1.026 1.026 0 001.023-1.023V6.326a1.026 1.026 0 00-1.023-1.023z"
            fill="currentColor"
          />
          <path
            opacity={0.2}
            d="M11.814 5.86H5.58v13.954h6.233a1.026 1.026 0 001.023-1.023V6.884a1.026 1.026 0 00-1.023-1.023z"
            fill="currentColor"
          />
          <path
            opacity={0.2}
            d="M11.814 5.86H5.58v12.838h6.233a1.026 1.026 0 001.023-1.023V6.884a1.026 1.026 0 00-1.023-1.023z"
            fill="currentColor"
          />
          <path
            opacity={0.2}
            d="M11.256 5.86H5.58v12.838h5.675a1.026 1.026 0 001.023-1.023V6.884a1.026 1.026 0 00-1.023-1.023z"
            fill="currentColor"
          />
          <path
            d="M1.023 5.86h10.233c.565 0 1.023.459 1.023 1.024v10.233c0 .565-.458 1.023-1.023 1.023H1.023A1.023 1.023 0 010 17.117V6.884C0 6.319.458 5.86 1.023 5.86z"
            fill="url(#app-ms-word_svg__paint0_linear)"
          />
          <path
            d="M4.198 13.707c.02.158.034.295.04.413h.023c.01-.111.028-.246.056-.403.028-.158.054-.29.077-.4L5.47 8.675H6.86l1.116 4.573c.065.284.112.572.14.863h.018c.02-.282.06-.561.116-.838l.89-4.6h1.267l-1.564 6.653h-1.48l-1.06-4.406a10.283 10.283 0 01-.177-.943H6.11c-.013.108-.037.27-.072.482-.036.213-.065.371-.086.474l-.997 4.392H3.449l-1.572-6.65h1.29l.969 4.652c.022.095.042.223.062.38z"
            fill="#fff"
          />
        </g>
      </g>
      <defs>
        <clipPath id="app-ms-word_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
        <clipPath id="app-ms-word_svg__clip1">
          <path fill="#fff" transform="translate(0 .6)" d="M0 0h24v22.8H0z" />
        </clipPath>
        <linearGradient
          id="app-ms-word_svg__paint0_linear"
          x1={2.133}
          y1={5.061}
          x2={10.146}
          y2={18.939}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2368C4" />
          <stop offset={0.5} stopColor="#1A5DBE" />
          <stop offset={1} stopColor="#1146AC" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgAppMsWord;
