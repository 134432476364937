import { DisplayFeatureI18nKey } from '@frond/web/src/modules/pricing/config/plans';

/**
 * Marketing sub-routes
 */

export const marketingRoutes = {
  pricing: () => `/pricing`,
  features: () => `/features`,
  about: () => `/about`,
  faq: () => `/faq`,
  changelog: () => `/changelog`,
  roadmap: () => `/roadmap`,
  press: () => `/press`,
  mediakit: () => `/mediakit`,
  privacy: () => `/privacy`,
  schedule: () => `/schedule`,
  community: () => `/frond`,
};

// Keys should map to DisplayFeatureI18nKey
export const featureFaqRoutes: Partial<Record<DisplayFeatureI18nKey, string>> =
  {
    community_templates:
      'https://frondcom.notion.site/What-are-community-templates-7977fa6af32a4bfaa6c28d10fb77d36b',
    direct_messaging:
      'https://frondcom.notion.site/Can-I-send-direct-messages-3741257cc3404ef5af657eb23c76c9ab',
    privacy_controls:
      'https://frondcom.notion.site/How-can-I-set-up-joining-permissions-da3c8cba7273423cb6712d81e9ffec70',
    private_groups:
      'https://frondcom.notion.site/How-do-I-make-a-group-private-6a96c738ab2946ecbc46f237956a1891',
    unlimited_groups:
      'https://frondcom.notion.site/Frond-FAQ-81a633cb29d1484da3d8935522890e89#763169dc7e8342a4aa99cbcc219529d1',
    advanced_search:
      'https://frondcom.notion.site/How-can-I-search-01595e03d49a4951ab5c4d782ec8279f',
    community_digests:
      'https://frondcom.notion.site/What-are-digests-e38a54541ad34f9783a0c5fb14a0f34f',
    polls:
      'https://frondcom.notion.site/How-can-I-start-a-poll-e804ef52904b4e489948ee4ac84ec7a5',
    custom_member_roles:
      'https://frondcom.notion.site/What-are-custom-member-roles-and-how-can-I-assign-them-931987207afa4f60b8a18a09a659915f',
    community_insights:
      'https://frondcom.notion.site/About-community-insights-be2fc089c7f74f81bb3d148b903e4b6e',
    courses:
      'https://frondcom.notion.site/frondcom/Adding-courses-435dc7705a0842c7bcb4ec7e1ebc1127',
    '5pct_paywall_fee':
      'https://frondcom.notion.site/How-to-charge-members-for-access-to-groups-behind-a-paywall-1a8550e48a60416cb507d65e92abb630',
    '2pct_paywall_fee':
      'https://frondcom.notion.site/How-to-charge-members-for-access-to-groups-behind-a-paywall-1a8550e48a60416cb507d65e92abb630',
    '1pct_paywall_fee':
      'https://frondcom.notion.site/How-to-charge-members-for-access-to-groups-behind-a-paywall-1a8550e48a60416cb507d65e92abb630',
  };
