import { theme } from '@frond/shared';
import styled, { css, system } from '@xstyled/styled-components';
import { SystemProps } from '@xstyled/system';
import isUndefined from 'lodash/isUndefined';
import kebabCase from 'lodash/kebabCase';
import keys from 'lodash/keys';
import React from 'react';

import * as icons from '../icons';

type IconKeys = keyof typeof icons;

const iconsKeyedInKebabCase = {};
keys(icons).forEach((key: string | IconKeys) => {
  // Difficult to type right now
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  iconsKeyedInKebabCase[kebabCase(key)] = icons[key];
});
export const iconKeysInKebabCase = keys(iconsKeyedInKebabCase);
export type IconNames = (typeof iconKeysInKebabCase)[number];
export type IconSizes =
  | 'inherit'
  | '2'
  | '3'
  | '4'
  | '4.5'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '16'
  | '30'
  | '48';

export interface IconProps extends SystemProps {
  /**
   * Icon name from SVG file - can be original SVG filename kebab case or component name
   */
  name: IconNames;
  /**
   * Size of largest dimension
   */
  size?: IconSizes;
  /**
   * Color of icon
   */
  color?: string | SystemProps['color'];
}

export const IconWrapper = styled.div<{
  size: IconSizes;
  color: string | SystemProps['color'];
}>`
  height: 1em;
  width: 1em;
  display: flex;
  font-size: ${(p) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return theme.sizes[p.size];
  }};

  ${(p) => {
    // @ts-expect-error related to xstyled v4
    return css({ color: p.color });
  }}
  ${system}
`;

/**
 * Component for rendering SVG icons
 */
export const Icon: React.FC<IconProps> = ({
  name,
  size = 'inherit',
  color = 'inherit',
  ...props
}) => {
  // Difficult to type right now
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const IconComponent = iconsKeyedInKebabCase[name] || icons[name as IconKeys];

  if (isUndefined(IconComponent)) {
    return null;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <IconWrapper size={size} color={color} {...props}>
      <IconComponent />
    </IconWrapper>
  );
};
