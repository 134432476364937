import { routes, theme } from '@frond/shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled, x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Maybe,
  MemberSubscriptionPlan,
  OrganizationRole,
  OrganizationRoleColor,
  refetchOrganizationRolesQuery,
  useCreateOrganizationRoleMutation,
  useDeleteOrganizationRoleMutation,
  UserPreviewFragment,
  useUpdateOrganizationRoleMutation,
} from '../../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../../auth/hooks/useOptionalOrganization';
import { Button } from '../../../common/components/Button';
import { Form } from '../../../common/components/Form';
import { Icon } from '../../../common/components/Icon';
import { Input } from '../../../common/components/Input';
import { Text } from '../../../common/components/Text';
import { Tooltip } from '../../../common/components/Tooltip';
import { Users } from '../../../common/components/Users';
import { extractError } from '../../../hello/utils/form.utils';
import { MemberItemMenu } from './CommunityMembers/MemberItemMenu';
import { CommunityRoleItemColorPicker } from './CommunityRoleItemColorPicker';
import { CommunityRoleToken } from './CommunityRoleToken';

export const COMMUNITY_ROLE_COLOR = {
  [OrganizationRoleColor.Blue]: theme.colors.blue,
  [OrganizationRoleColor.Gray]: theme.colors.gray,
  [OrganizationRoleColor.Green]: theme.colors.green,
  [OrganizationRoleColor.Purple]: theme.colors.purple,
  [OrganizationRoleColor.Red]: theme.colors.red,
  [OrganizationRoleColor.Yellow]: theme.colors.yellow,
};

type CommunityRoleItemProps = {
  role?: Omit<OrganizationRole, 'users' | 'memberSubscriptionPlan'> & {
    users: UserPreviewFragment[];
    memberSubscriptionPlan?: Maybe<
      Pick<MemberSubscriptionPlan, '__typename' | 'id' | 'name'>
    >;
  };
  onCreateCompleted?: () => void;
  autoFocus?: boolean;
};

const StyledInput = styled(Input)`
  width: 288px;
`;

const OrganizationRoleFormSchema = yup
  .object({
    label: yup
      .string()
      .max(18, 'Label must be less than 18 characters')
      .required('Label is required'),
  })
  .defined();

export type OrganizationRoleFormData = yup.InferType<
  typeof OrganizationRoleFormSchema
>;

const CommunityRoleItemForm: React.FC<CommunityRoleItemProps> = ({
  role,
  onCreateCompleted,
  autoFocus,
}) => {
  const { t } = useTranslation(['common', 'settings']);
  const { organization } = useOptionalOrganization();

  const form = useForm<OrganizationRoleFormData>({
    resolver: yupResolver(OrganizationRoleFormSchema),
    mode: 'all',
    defaultValues: {
      label: role?.label,
    },
  });

  const label = form.watch('label');

  const [mutateCreateOrganizationRole, { loading }] =
    useCreateOrganizationRoleMutation({
      onCompleted: () => {
        form.reset();
        onCreateCompleted?.();
      },
      refetchQueries: [
        refetchOrganizationRolesQuery({
          organizationId: organization?.id || '',
        }),
      ],
    });
  const [mutateUpdateOrganizationRole] = useUpdateOrganizationRoleMutation({
    onCompleted: ({ updateOrganizationRole }) => {
      form.reset({
        label: updateOrganizationRole.label,
      });
      onCreateCompleted?.();
    },
    refetchQueries: [
      refetchOrganizationRolesQuery({
        organizationId: organization?.id || '',
      }),
    ],
  });

  const createOrUpdateOrganizationRole = () => {
    if (!label) return;
    if (!role) {
      mutateCreateOrganizationRole({
        variables: {
          input: {
            organizationId: organization?.id || '',
            label,
          },
        },
      });
    } else {
      mutateUpdateOrganizationRole({
        variables: {
          input: {
            roleId: role?.id || '',
            label,
          },
        },
      });
    }
  };

  const commonProps = {
    ...form.register('label'),
    placeholder: 'Label',
    disabled: loading,
    autoFocus,
  };

  return (
    <Form<OrganizationRoleFormData>
      onSubmit={createOrUpdateOrganizationRole}
      {...form}
    >
      {role && !form.formState.isDirty ? (
        <StyledInput
          {...commonProps}
          showErrorTooltip={false}
          suffixElement={
            <x.div display="flex" flexDirection="row" spaceX={2}>
              {role.isAdmin && (
                <Tooltip
                  aria-label={t('settings.community_admin_change_tooltip', {
                    ns: 'settings',
                  })}
                  label={
                    <Text variant="sm-semibold">
                      {t('settings.community_admin_change_tooltip', {
                        ns: 'settings',
                      })}
                    </Text>
                  }
                >
                  <x.div display="flex" alignItems="center" flexDirection="row">
                    <Icon name="info" color="gray.300" />
                  </x.div>
                </Tooltip>
              )}
              {role.memberSubscriptionPlan && (
                <Tooltip
                  aria-label={t('settings.community_subscriber_role_tooltip', {
                    ns: 'settings',
                  })}
                  label={
                    <Text variant="sm-semibold">
                      {t('settings.community_subscriber_role_tooltip', {
                        ns: 'settings',
                      })}
                    </Text>
                  }
                >
                  <x.div display="flex" alignItems="center" flexDirection="row">
                    <Icon name="info" color="gray.300" />
                  </x.div>
                </Tooltip>
              )}
              <x.div>
                <CommunityRoleItemColorPicker role={role} />
              </x.div>
            </x.div>
          }
        />
      ) : (
        <StyledInput
          {...commonProps}
          error={extractError<OrganizationRoleFormData>(
            form.formState,
            'label'
          )}
          suffixElement={
            <x.div display="flex" flexDirection="row" spaceX={2}>
              <Button
                variant="text"
                label={t('cancel')}
                color="gray.300"
                onClick={() => {
                  form.reset();
                  onCreateCompleted?.();
                }}
              />
              <Button
                variant="text"
                label={t('save')}
                color="brand.300"
                onClick={createOrUpdateOrganizationRole}
                disabled={!form.formState.isValid}
              />
            </x.div>
          }
          autoFocus={true}
        />
      )}
    </Form>
  );
};

export const CommunityRoleItem: React.FC<CommunityRoleItemProps> = (props) => {
  const { t } = useTranslation(['common', 'settings']);
  const { role } = props;
  const { organization } = useOptionalOrganization();
  const router = useRouter();

  const [mutateDeleteOrganizationRole] = useDeleteOrganizationRoleMutation({
    refetchQueries: [
      refetchOrganizationRolesQuery({
        organizationId: organization?.id || '',
      }),
    ],
  });

  return (
    <x.div display="flex" flexDirection="row" alignItems="center" p="3 4">
      <x.div w="50%">
        <CommunityRoleItemForm {...props} />
      </x.div>
      <x.div w="25%" display="flex" justifyContent="flex-start" px={3}>
        {role && <CommunityRoleToken role={role} />}
      </x.div>
      <x.div w="25%">
        <x.div
          display="flex"
          flexDirection="row"
          justifyContent={role ? 'space-between' : 'flex-end'}
        >
          {role && <Users limit={4} variant="small" users={role.users} />}
          <MemberItemMenu
            disabled={!role}
            menuActions={[
              ...[
                {
                  label: t('settings.community_role_item_assign', {
                    ns: 'settings',
                  }),
                  onClick: () =>
                    organization &&
                    router.push(
                      routes.settings
                        .organization(organization.shortId)
                        .members()
                    ),
                },
              ],
              ...(!role?.isAdmin && !role?.memberSubscriptionPlan
                ? [
                    {
                      label: t('settings.community_role_item_delete', {
                        ns: 'settings',
                      }),
                      onClick: () =>
                        mutateDeleteOrganizationRole({
                          variables: {
                            input: {
                              roleId: role?.id || '',
                            },
                          },
                        }),
                    },
                  ]
                : []),
            ]}
          />
        </x.div>
      </x.div>
    </x.div>
  );
};
