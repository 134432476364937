import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import React, { FC } from 'react';

import {
  GroupErrorReason,
  GroupQuery,
  useGroupQuery,
  useGroupsQuery,
} from '../../../../generated/types-and-hooks';
import { BASE_URL } from '../../../config/constants';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useIsViewerAdmin, useViewer } from '../../auth/hooks/useViewer';
import { Error401View } from '../../errors/views/Error401View';
import { Error404View } from '../../errors/views/Error404View';
import { GroupPaywall } from './GroupPaywall';
import { GroupView } from './GroupView';

('use client');

export const GroupPage: FC<{
  id?: string | string[];
  showLayout?: boolean;
}> = ({ id, showLayout = true }) => {
  const router = useRouter();
  const { organization } = useOrganization();
  const { setOrganization } = useOptionalOrganization();
  const { viewer } = useViewer();
  const isViewerAdmin = useIsViewerAdmin(organization);
  const { data: groupsData } = useGroupsQuery({
    variables: { organizationId: organization.id, first: 100 },
    fetchPolicy: 'cache-first',
  });

  const publicGroupEdge = groupsData?.groups.edges.find(
    (e) => e.node.id === id
  );

  const handleComplete = (data: GroupQuery) => {
    if (
      data.group?.__typename === 'Group' &&
      organization.id !== data.group.organization.id
    ) {
      setOrganization(data.group.organization);
    }
  };

  const { data } = useGroupQuery({
    variables: {
      id: id as string,
    },
    skip: !id,
    context: {
      skip: !id,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: handleComplete,
  });

  if (data?.group?.__typename === 'GroupError') {
    const error = data?.group;

    if (
      error.reason === GroupErrorReason.Paywall &&
      organization.memberSubscriptionPlan
    ) {
      const plan = organization.memberSubscriptionPlan;
      return (
        <x.div
          display="flex"
          minH={{ sm: 'full' }}
          alignItems="center"
          justifyContent="center"
        >
          <x.div p={{ sm: 6 }}>
            <GroupPaywall
              memberSubscriptionPlan={plan}
              group={publicGroupEdge?.node}
            />
          </x.div>
        </x.div>
      );
    }

    switch (error.reason) {
      case GroupErrorReason.Private: {
        return (
          <Error401View
            actionLabel="Visit Home"
            errorMessage="You don't have access to this content"
            action={() => {
              router.push(
                routes.groups.organization(organization.shortId).feed()
              );
            }}
          />
        );
      }
      default: {
        return <Error401View />;
      }
    }
  }

  if (data?.group === null || data?.group?.hidden) {
    return <Error404View />;
  }

  if (viewer && data?.group && data.group.isDraft && !isViewerAdmin) {
    return <Error404View />;
  }

  const openGraph = {
    title: `${data?.group?.name} - Frond`,
    url: `${BASE_URL}${routes.groups
      .organization(organization.shortId)
      .group(data?.group?.id || '')}`,
  };

  return (
    <>
      <NextSeo
        title={openGraph.title}
        canonical={openGraph.url}
        openGraph={openGraph}
      />
      <GroupView group={data?.group} showLayout={showLayout} />
    </>
  );
};
