import { IS_SSR } from '../../../config/constants';

/**
 * Workaround for scroll issues in Safari
 * See https://linear.app/frond/issue/FR-2440/sometimes-our-web-app-loses-the-ability-to-scroll
 */
export const forceSafariReflow = () => {
  !IS_SSR && navigator.vendor?.startsWith('Apple') && forceReflow();
};

/**
 * Toggles the overflow-y attribute of the body element
 */
export const forceReflow = () => {
  if (!IS_SSR) {
    document.body.style.overflowY =
      document.body.style.overflowY === 'scroll' ? 'auto' : 'scroll';
  }
};
