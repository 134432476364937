import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import {
  JoinPolicy,
  refetchOrganizationInviteQuery,
  useResetOrganizationInviteMutation,
} from '../../../../generated/types-and-hooks';
import { useIsViewerAdmin } from '../../auth/hooks/useViewer';
import { Alert } from '../../common/components/Alert';
import { Button } from '../../common/components/Button';
import { GroupsSidebarOrganization } from '../../groups/components/GroupsSidebar/GroupsSidebar';

type ResetInviteLinkButtonProps = {
  isPrivateGroup?: boolean | null;
  organization: GroupsSidebarOrganization;
};
export const ResetInviteLinkButton = ({
  isPrivateGroup,
  organization,
}: ResetInviteLinkButtonProps) => {
  const { t } = useTranslation(['common', 'settings']);
  const isViewerAdmin = useIsViewerAdmin(organization);

  const [
    showResetOrganizationInviteAlert,
    setShowResetOrganizationInviteAlert,
  ] = useState(false);

  const [resetOrganizationInvite, { loading: resetLoading }] =
    useResetOrganizationInviteMutation({
      variables: {
        input: {
          organizationId: organization.id,
        },
      },
      refetchQueries: [
        refetchOrganizationInviteQuery({
          organizationId: organization.id,
        }),
      ],
      onCompleted: () => {
        setShowResetOrganizationInviteAlert(false);
      },
    });

  // Don't show the button if the organization is unrestricted or request to join
  if (
    organization.joinPolicy === JoinPolicy.Unrestricted ||
    organization.joinPolicy === JoinPolicy.RequestToJoin
  ) {
    return null;
  }

  // Don't show the button if the viewer is not an admin
  if (!isViewerAdmin) return null;

  if (isPrivateGroup) return null;

  return (
    <>
      {showResetOrganizationInviteAlert && (
        <Alert
          headingText={t('reset_invite_link', { ns: 'common' })}
          descriptionText={t('reset_invite_link_description', {
            organizationName: organization.name,
            ns: 'common',
          })}
          cancelButtonText={t('cancel')}
          submitButtonText={t('reset_link')}
          variant="warning"
          onCancelClick={() => setShowResetOrganizationInviteAlert(false)}
          onDismiss={() => setShowResetOrganizationInviteAlert(false)}
          submitting={resetLoading}
          onSubmitClick={() => {
            resetOrganizationInvite();
          }}
        />
      )}
      <Button
        variant="secondary"
        loading={resetLoading}
        onClick={() => setShowResetOrganizationInviteAlert(true)}
        label={t('reset_link')}
      />
    </>
  );
};
