import { theme } from '@frond/shared';
import { SystemProps, x } from '@xstyled/styled-components';
import { PropsWithChildren } from 'react';

import { Text } from '../../../common/components/Text';

type CommunityItemStatusVariant = 'small';

export type CommunityItemStatusProps = SystemProps & {
  variant?: CommunityItemStatusVariant;
};

export const CommunityItemStatus: React.FC<
  PropsWithChildren<CommunityItemStatusProps>
> = (props) => {
  const { children, variant } = props;
  return (
    <x.span
      p={
        variant === 'small'
          ? `${theme.sizes['0.5']} ${theme.sizes['1.5']}`
          : '1 2'
      }
      display="flex"
      backgroundColor="gray.100"
      borderRadius="sm"
      {...props}
    >
      <Text as="span" variant="sm-medium">
        {children}
      </Text>
    </x.span>
  );
};
