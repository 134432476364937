import * as React from "react";

function SvgAlpaca(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.813 21.25A4.075 4.075 0 0120 20.113a4.075 4.075 0 01-5.336.269 4.025 4.025 0 01-1.426-2.257 3.438 3.438 0 11-.006-6.819c.192-.842.65-1.6 1.306-2.162a3.994 3.994 0 012.65-1.019A4.075 4.075 0 0120 9.262a4.075 4.075 0 012.813-1.137 4.081 4.081 0 013.956 3.125 3.356 3.356 0 011.444.125 3.452 3.452 0 01-1.45 6.75 4.025 4.025 0 01-3.95 3.125z"
        fill="currentColor"
      />
      <path
        d="M20 18.538a.625.625 0 01.513.262 2.83 2.83 0 002.3 1.2 2.787 2.787 0 002.812-2.556.58.58 0 010-.081.657.657 0 01.545-.65.626.626 0 01.3.024c.23.087.472.134.718.138a2.187 2.187 0 10-.763-4.238.625.625 0 01-.837-.562 2.831 2.831 0 00-2.775-2.7 2.831 2.831 0 00-2.3 1.2.625.625 0 01-1.019 0 2.83 2.83 0 00-2.306-1.2 2.756 2.756 0 00-1.831.688 2.794 2.794 0 00-.982 2.012.625.625 0 01-.837.563 2.144 2.144 0 00-1.394-.038 2.188 2.188 0 00.669 4.275c.25-.003.497-.05.731-.137a.624.624 0 01.838.587v.119A2.788 2.788 0 0017.188 20a2.83 2.83 0 002.3-1.2.626.626 0 01.512-.262z"
        fill="#EEE"
      />
      <path
        d="M21.25 36.875h-2.5a2.5 2.5 0 01-2.5-2.5 2.5 2.5 0 00-2.5-2.5A4.375 4.375 0 019.375 27.5v-.494c0-.72.177-1.428.519-2.062a4.43 4.43 0 011.425-1.575A4.046 4.046 0 0013.125 20v-2.5a.625.625 0 011.048-.462c.116.105.188.25.202.406A2.788 2.788 0 0017.188 20a2.83 2.83 0 002.3-1.2.625.625 0 011.018 0 2.83 2.83 0 002.306 1.2 2.787 2.787 0 002.813-2.556.625.625 0 011.25.056V20a4.026 4.026 0 001.806 3.369 4.374 4.374 0 011.944 3.637v.494a4.375 4.375 0 01-4.375 4.375 2.5 2.5 0 00-2.5 2.5 2.5 2.5 0 01-2.5 2.5z"
        fill="currentColor"
      />
      <path
        d="M14.375 20.125a5.289 5.289 0 01-2.356 4.281A3.176 3.176 0 0011 25.531c-.245.454-.373.96-.375 1.475v.494a3.125 3.125 0 003.125 3.125 3.75 3.75 0 013.75 3.75 1.25 1.25 0 001.25 1.25h2.5a1.25 1.25 0 001.25-1.25 3.75 3.75 0 013.75-3.75 3.125 3.125 0 003.125-3.125v-.494a3.126 3.126 0 00-1.387-2.6 5.269 5.269 0 01-2.363-4.281 4.062 4.062 0 01-5.625 0 4.062 4.062 0 01-5.625 0z"
        fill="#FFECB3"
      />
      <path
        d="M20 34.375a.624.624 0 01-.625-.625v-1.875a.624.624 0 111.25 0v1.875a.624.624 0 01-.625.625z"
        fill="currentColor"
      />
      <path
        d="M20 32.025a3.255 3.255 0 01-2.319-.962.625.625 0 01.881-.882 2.075 2.075 0 002.87 0 .625.625 0 01.88.881 3.256 3.256 0 01-2.312.963zm-4.375-7.65A.625.625 0 0115 23.75V22.5a.625.625 0 111.25 0v1.25a.625.625 0 01-.625.625zm8.75 0a.625.625 0 01-.625-.625V22.5a.625.625 0 111.25 0v1.25a.625.625 0 01-.625.625zm-10.625-11.7a.623.623 0 01-.213-.038 2.145 2.145 0 00-1.393-.037.625.625 0 01-.682-.219l-.35-.462a5.23 5.23 0 01-.593-5.263l1.4-3.125a.625.625 0 01.525-.406.625.625 0 01.575.275l.73 1.113a11.17 11.17 0 011.8 5 .626.626 0 01-.212.537 2.787 2.787 0 00-.962 2.025.625.625 0 01-.625.625v-.025z"
        fill="currentColor"
      />
      <path
        d="M12.613 5.038l-.938 2.118a3.982 3.982 0 00.45 4.007l.107.137a3.321 3.321 0 011.006 0 4.037 4.037 0 011.05-1.925 9.912 9.912 0 00-1.563-4.144l-.112-.193z"
        fill="#FFECB3"
      />
      <path
        d="M26.25 12.675a.625.625 0 01-.625-.625 2.788 2.788 0 00-.969-2.006.625.625 0 01-.212-.538c.189-1.787.806-3.502 1.8-5l.744-1.112a.625.625 0 011.087.1l1.4 3.125a5.231 5.231 0 01-.587 5.256l-.35.463a.625.625 0 01-.682.218 2.136 2.136 0 00-1.393.038.625.625 0 01-.213.081z"
        fill="currentColor"
      />
      <path
        d="M27.188 11.25c.197 0 .393.017.587.05l.107-.138a3.981 3.981 0 00.45-4l-.938-2.118-.112.168a9.918 9.918 0 00-1.57 4.163c.516.518.88 1.166 1.057 1.875.14-.01.28-.01.419 0z"
        fill="#FFECB3"
      />
    </svg>
  );
}

export default SvgAlpaca;
