import * as React from "react";

function SvgAppServicenow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-servicenow_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.796 11.166c-.253 0-.47.09-.652.236v-.2h-.616v2.337h.634v-1.485a.747.747 0 01.58-.29c.09 0 .181.018.253.036v-.598c-.072-.018-.144-.036-.199-.036zM.344 12.814c.164.145.417.236.634.236.163 0 .308-.09.308-.2 0-.362-1.14-.235-1.14-.977 0-.453.434-.725.887-.725.308 0 .634.109.797.236l-.29.452c-.127-.072-.272-.163-.453-.163s-.308.073-.308.2c0 .308 1.141.18 1.141.996 0 .453-.434.724-.923.724-.326 0-.67-.108-.942-.308l.29-.47zM4.256 12.344c0-.653-.453-1.196-1.105-1.196-.688 0-1.141.58-1.141 1.214 0 .742.525 1.213 1.213 1.213.363 0 .725-.145.96-.416l-.362-.363a.802.802 0 01-.58.272.63.63 0 01-.634-.561H4.22c.036-.037.036-.091.036-.163zm-1.594-.272a.526.526 0 01.507-.417c.254 0 .435.2.47.417h-.977zM7.463 12.633l.634-1.43h.652L7.68 13.538h-.435l-1.068-2.337h.634l.652 1.431zM9.273 10.097c.236 0 .417.181.417.399 0 .235-.181.398-.417.398a.39.39 0 01-.398-.398.38.38 0 01.398-.399z"
          fill="#293E40"
        />
        <path d="M9.6 11.202h-.634v2.337H9.6v-2.337z" fill="#293E40" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.172 13.05a1.233 1.233 0 01-1.05.525c-.725 0-1.25-.543-1.25-1.213 0-.689.543-1.214 1.25-1.214.417 0 .76.2.978.471l-.417.38a.66.66 0 00-.525-.271.63.63 0 00-.634.634c0 .362.254.634.634.634a.68.68 0 00.562-.308l.452.362zM14.436 13.159a1.274 1.274 0 01-.96.416c-.688 0-1.213-.47-1.213-1.213 0-.652.434-1.214 1.14-1.214.635 0 1.106.543 1.106 1.196 0 .072 0 .126-.018.18h-1.63a.63.63 0 00.633.562c.254 0 .471-.145.58-.272l.362.345zm-.58-1.087a.473.473 0 00-.47-.417c-.254 0-.471.2-.507.417h.978zM14.744 13.539v-2.337h.598v.2c.181-.145.398-.236.652-.236.326 0 .616.145.815.38.145.182.254.417.254.816v1.195h-.634v-1.25c0-.235-.055-.362-.145-.434a.476.476 0 00-.362-.145c-.254 0-.471.163-.58.29v1.521h-.598z"
          fill="#293E40"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.675 11.148c-.76 0-1.359.598-1.359 1.359 0 .398.163.76.417 1.014.09.09.254.09.362.018a.899.899 0 01.58-.2c.235 0 .416.073.58.2.108.09.253.072.362-.036.253-.254.416-.598.416-.996-.018-.743-.616-1.359-1.358-1.359zm-.018 2.065a.666.666 0 01-.689-.688c0-.38.272-.689.689-.689.416 0 .688.308.688.689 0 .38-.272.688-.688.688z"
          fill="#81B5A1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.265 13.539h-.471l-.924-2.337h.616l.507 1.34.507-1.34h.526l.489 1.34.507-1.34h.634l-.924 2.337h-.47l-.508-1.322-.49 1.322z"
          fill="#293E40"
        />
        <path
          d="M23.656 13.267h-.054v.09h-.037v-.27h.11c.053 0 .09.035.09.09 0 .036-.037.072-.055.09l.073.109h-.055l-.072-.109zm-.054-.036h.072c.036 0 .036-.018.036-.036 0-.037-.018-.037-.036-.037h-.072v.073z"
          fill="#293E40"
        />
        <path
          d="M23.655 12.995c.145 0 .254.11.254.254a.248.248 0 01-.254.254.248.248 0 01-.254-.254.26.26 0 01.254-.254zm0-.054a.298.298 0 00-.29.29c0 .163.145.29.29.29.163 0 .29-.145.29-.29a.312.312 0 00-.29-.29z"
          fill="#293E40"
        />
      </g>
      <defs>
        <clipPath id="app-servicenow_svg__clip0">
          <path fill="#fff" transform="translate(0 10)" d="M0 0h24v3.636H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppServicenow;
