import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import { PostFeedFragment } from '../../../../generated/types-and-hooks';
import { Button } from '../../common/components/Button';
import { Icon } from '../../common/components/Icon';
import { Image } from '../../common/components/Image';
import { Input } from '../../common/components/Input';
import { LineClamp } from '../../common/components/LineClamp';
import { Link } from '../../common/components/Link';
import { Text } from '../../common/components/Text';
import {
  StyledDropdownMenuButton,
  StyledDropdownMenuContent,
  StyledDropdownMenuItem,
} from './PostCard';

const PostResourceTitle: React.FC<{
  resource: PostFeedFragment['resources'][0];
  editable?: boolean;
  onSave?: (url: string, title: string) => void;
  isHovering?: boolean;
}> = ({ resource, editable, onSave, isHovering }) => {
  const [showInput, setShowInput] = useState(false);
  const [title, setTitle] = useState(
    resource.title || resource.logoTitle || ''
  );

  let normalizedUrl = resource.url
    .replace('https://www.', '')
    .replace('https://', '');

  if (normalizedUrl.startsWith('res.cloudinary.com/frond')) {
    const url = new URL(resource.url);
    const slugs = url.pathname.split('/');
    normalizedUrl = slugs[slugs.length - 1];
  }

  const handleInputClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleEditClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowInput(true);
  };

  const handleCancelClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowInput(false);
  };

  const handleSaveClick = (event: Pick<React.MouseEvent, 'preventDefault'>) => {
    event.preventDefault();
    setShowInput(false);
    onSave?.(resource.url, title);
  };

  return (
    <x.div minWidth="0" flex={1}>
      {resource.title || resource.logoTitle || showInput ? (
        <>
          <x.div
            display="flex"
            flexDirection="row"
            alignItems="center"
            spaceX={1.5}
          >
            {showInput ? (
              <x.div w="full" mb={2.5}>
                <Input
                  onClick={handleInputClick}
                  onChange={handleInputChange}
                  placeholder="Add a title..."
                  value={title}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSaveClick(e);
                    }
                  }}
                  suffixElement={
                    <x.div display="flex" flexDirection="row" spaceX={2}>
                      <Button
                        variant="text"
                        color="red.300"
                        label="Cancel"
                        onClick={handleCancelClick}
                      />
                      <Button
                        variant="text"
                        color="brand.300"
                        label="Save"
                        disabled={!title}
                        onClick={handleSaveClick}
                      />
                    </x.div>
                  }
                  fullWidth
                />
              </x.div>
            ) : (
              <>
                <Text
                  variant="md-semibold"
                  truncate
                  minWidth="0"
                  color="gray.400"
                >
                  {resource.title || resource.logoTitle}
                </Text>
                {editable && isHovering && (
                  <Button
                    variant="icon"
                    leftElement={<Icon name="pencil" color="gray.300" />}
                    onClick={handleEditClick}
                  />
                )}
              </>
            )}
          </x.div>
          <Text variant="sm" truncate color="gray.400">
            {normalizedUrl}
          </Text>
        </>
      ) : (
        <x.div
          display="flex"
          flexDirection="row"
          alignItems="center"
          spaceX={1.5}
        >
          <Text variant="md-semibold" truncate color="gray.400">
            {normalizedUrl}
          </Text>
          {editable && (
            <Button
              variant="icon"
              leftElement={<Icon name="pencil" color="gray.300" />}
              onClick={handleEditClick}
            />
          )}
        </x.div>
      )}
    </x.div>
  );
};

export const PostResource = ({
  resource,
  colorVariant = 'light',
  variant = 'default',
  onUpdateResource,
  onRemoveResource,
  onToggleExpandedResource,
  disabled,
  editable,
}: {
  resource: PostFeedFragment['resources'][0];
  colorVariant?: 'light' | 'dark';
  variant?: 'default' | 'composer';
  onUpdateResource?: (url: string, title: string) => void;
  onRemoveResource?: (id: string) => void;
  onToggleExpandedResource?: (id: string) => void;
  disabled?: boolean;
  editable?: boolean;
}) => {
  const { t } = useTranslation();
  const [isHovering, setIsHovering] = useState(false);

  const defaultRemoveButton = (
    <x.button
      position="absolute"
      opacity={{ sm: isHovering ? 1 : 0 }}
      transition
      transitionDuration="fast"
      transitionTimingFunction="ease-out"
      type="button"
      display="flex"
      w={5}
      h={5}
      top={-10}
      right={-10}
      bg={{
        _: 'rgba(0,0,0,0.6)',
        hover: 'blackAlpha.400',
      }}
      border="none"
      borderRadius="full"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      onClick={() => onRemoveResource?.(resource.id)}
      disabled={disabled}
    >
      <Icon name="close" color="white" />
    </x.button>
  );

  const title = (
    <PostResourceTitle
      resource={resource}
      editable={editable}
      onSave={onUpdateResource}
      isHovering={isHovering}
    />
  );

  const isEmbeddableUrl =
    resource.url.includes('youtube.com/watch?v=') ||
    resource.url.includes('youtu.be/') ||
    resource.url.includes('vimeo.com/');

  const extractYoutubeId = (url: string) => {
    const youtubeId = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/
    );
    return youtubeId ? youtubeId[1].replace('&', '?') : '';
  };

  const extractVimeoId = (url: string) => {
    const vimeoId = url.match(/vimeo.*\/(\d+)/i);
    return vimeoId ? vimeoId[1] : '';
  };

  const youtubeId = extractYoutubeId(resource.url);
  const vimeoId = extractVimeoId(resource.url);

  const handleMouseEnter = () => {
    if (variant === 'composer') {
      setIsHovering(true);
    }
  };
  const handleMouseLeave = () => {
    if (variant === 'composer') {
      setIsHovering(false);
    }
  };

  if ((youtubeId || vimeoId) && isEmbeddableUrl) {
    const iframe = (
      <x.iframe
        borderRadius="md"
        width="100%"
        overflow="hidden"
        src={
          youtubeId
            ? `https://www.youtube.com/embed/${youtubeId}`
            : vimeoId
              ? `https://player.vimeo.com/video/${vimeoId}`
              : ''
        }
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          aspectRatio: '16 / 9',
        }}
      />
    );

    if (variant === 'composer') {
      return (
        <x.div
          position="relative"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <x.div
            bg={colorVariant === 'light' ? 'white' : 'gray.50'}
            borderRadius="md"
            p={4}
            border={1}
            transition
            transitionDuration="fast"
            transitionTimingFunction="ease-out"
            borderColor="transparent"
            spaceY={3}
          >
            <x.div display="flex" spaceX={3} alignItems="center">
              {!resource.expanded ? (
                <Icon
                  name={resource.logo || 'earth'}
                  size="5"
                  color="gray.400"
                />
              ) : null}

              <x.div minWidth="0" flex={1}>
                {title}
              </x.div>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                  <StyledDropdownMenuButton
                    cursor="pointer"
                    m={0}
                    p={3}
                    bg={{ _: 'whiteAlpha.400', hover: 'whiteAlpha.450' }}
                    borderRadius="sm-md"
                    position="relative"
                    border={1}
                    borderColor={{
                      _: 'transparent',
                      hover: 'brand.300',
                    }}
                    spaceX={1}
                    display="flex"
                    transition
                    transitionDuration="fast"
                    transitionTimingFunction="ease-out"
                  >
                    {[...new Array(3)].map((_, i) => {
                      return (
                        <x.div
                          bg="gray.500"
                          borderRadius={4}
                          key={i}
                          w={1}
                          h={1}
                        />
                      );
                    })}
                  </StyledDropdownMenuButton>
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                  <StyledDropdownMenuContent align="end">
                    <x.div p={4}>
                      <StyledDropdownMenuItem
                        onSelect={() => onRemoveResource?.(resource.id)}
                      >
                        <Text variant="md">Remove</Text>
                      </StyledDropdownMenuItem>
                      <StyledDropdownMenuItem
                        onSelect={() => onToggleExpandedResource?.(resource.id)}
                      >
                        <Text variant="md">
                          {resource.expanded ? t('collapse') : t('expand')}
                        </Text>
                      </StyledDropdownMenuItem>
                    </x.div>
                  </StyledDropdownMenuContent>
                </DropdownMenu.Portal>
              </DropdownMenu.Root>
            </x.div>

            {resource.expanded ? iframe : null}
          </x.div>
          {variant === 'composer' && defaultRemoveButton}
        </x.div>
      );
    } else {
      if (resource.expanded) {
        return <div>{iframe}</div>;
      }
    }
  }

  if (!resource.imageUrl) {
    return (
      <x.div
        position="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Link
          href={resource.url}
          target="_blank"
          rel="noreferrer noopener"
          styleInheritColor
          position="relative"
        >
          <x.div
            display="flex"
            bg={colorVariant === 'light' ? 'white' : 'gray.50'}
            spaceX={3}
            alignItems="center"
            borderRadius="md"
            p={4}
            border={1}
            borderColor={{
              _: 'transparent',
              hover: 'brand.300',
            }}
            transition
            transitionDuration="fast"
            transitionTimingFunction="ease-out"
          >
            <Icon name={resource.logo || 'earth'} size="5" color="gray.400" />
            {title}
          </x.div>
        </Link>
        {variant === 'composer' && defaultRemoveButton}
      </x.div>
    );
  }

  return (
    <x.div
      position="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link
        href={resource.url}
        target="_blank"
        rel="noreferrer noopener"
        styleInheritColor
        position="relative"
      >
        <x.div
          bg={colorVariant === 'light' ? 'white' : 'gray.50'}
          borderRadius="md"
          p={4}
          border={1}
          borderColor={{
            _: 'transparent',
            hover: 'brand.300',
          }}
          transition
          transitionDuration="fast"
          transitionTimingFunction="ease-out"
        >
          <x.div spaceY={2}>
            <x.div display="flex" spaceX={3} alignItems="center">
              <x.div
                overflow="hidden"
                borderRadius="md"
                w={12}
                h={12}
                flexShrink={0}
              >
                <Image
                  src={resource.imageUrl}
                  transform="ar_1,c_fill"
                  style={{ width: '100%' }}
                />
              </x.div>
              {title}
            </x.div>
            {resource.description ? (
              <LineClamp maxLines={4}>
                <Text variant="sm-medium" color="gray.300">
                  {resource.description}
                </Text>
              </LineClamp>
            ) : null}
          </x.div>
        </x.div>
      </Link>
      {variant === 'composer' && defaultRemoveButton}
    </x.div>
  );
};
