import { x } from '@xstyled/styled-components';
import { PropsWithChildren, ReactElement } from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';

type CustomFormProps<T extends FieldValues> = UseFormReturn<T> & {
  onSubmit: SubmitHandler<T>;
  onBlur?: SubmitHandler<T>;
  id?: string;
  w?: string;
  flex?: number;
  flexDirection?: string;
  display?: string;
};

export function Form<T extends FieldValues>({
  onSubmit,
  onBlur,
  children,
  id,
  w,
  flex,
  flexDirection,
  display,
  ...methods
}: PropsWithChildren<CustomFormProps<T>>): ReactElement {
  const handleSubmit = methods.handleSubmit(onSubmit);
  const handleBlur = onBlur
    ? methods.handleSubmit((data) => onBlur(data))
    : undefined;

  return (
    <FormProvider {...methods}>
      <x.form
        id={id}
        onBlur={handleBlur}
        onSubmit={handleSubmit}
        w={w}
        flex={flex}
        flexDirection={flexDirection}
        display={display}
      >
        {children}
      </x.form>
    </FormProvider>
  );
}
