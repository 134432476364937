import styled, { css, system, SystemProps } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import React, { AnchorHTMLAttributes } from 'react';

import { isInternalURL } from '../utils';
import analytics, { AnalyticEventName } from '../utils/analytics';

export interface LinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement | HTMLSpanElement> {
  /**
   * Link destination
   */
  href?: string;
  /**
   * Whether to apply color of styling
   */
  styleInheritColor?: boolean;
  /**
   * extra context to send with button click action
   */
  metadata?: { event?: AnalyticEventName; context?: string };
}

const linkColorMixin = css`
  color: blue.300;

  &:hover {
    color: blue.400;
  }
`;

export const linkMixin = css<{
  styleInheritColor?: boolean;
}>`
  text-decoration: none;
  color: inherit;
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }

  ${(a) => !a.styleInheritColor && linkColorMixin}
  ${system}
`;

/**
 * Primary component for link navigation
 */
export const StyledAnchor = styled.a<LinkProps>`
  ${linkMixin}
`;

export const StyledSpan = styled.span<LinkProps>`
  ${linkMixin}
`;

export const Link: React.FC<LinkProps & SystemProps> = ({
  metadata,
  onClick,
  ...props
}) => {
  const router = useRouter();
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (metadata?.event) {
      analytics.logEvent(metadata.event, {
        type: 'link_click',
        href: props.href,
        page: router.pathname,
        url: router.asPath,
        ...metadata,
      });
    }
    // NextJS's link component onClick passthrough
    onClick?.(e);
  };

  if (typeof props.href === 'string' && !isInternalURL(props.href)) {
    return <StyledAnchor {...props} onClick={handleClick} />;
  }

  return <StyledSpan {...props} onClick={handleClick} />;
};

export interface NavLinkProps extends LinkProps {
  /**
   * Whether link gets active color
   */
  active?: boolean;
}

const navLinkActiveMixin = css`
  color: brand.300;
`;

const StyledNavLink = styled.span<NavLinkProps>`
  color: inherit;
  ${(p) => p.active && navLinkActiveMixin}
  transition: color 100ms ease-in-out;
`;

/**
 * Link component that has active color used in navigation bars
 */
export const NavLink: React.FC<NavLinkProps> = ({
  metadata,
  onClick,
  ...props
}) => {
  const router = useRouter();
  return (
    <StyledNavLink
      {...props}
      onClick={(e) => {
        analytics.logEvent(analytics.events.NAV_LINK, {
          type: 'link_click',
          href: props.href,
          page: router.pathname,
          url: router.asPath,
          ...metadata,
        });
        // NextJS's link component onClick passthrough
        onClick?.(e);
      }}
      styleInheritColor
    />
  );
};
