import { useEffect, useRef, useState } from 'react';

export const useClipboardCopy = (
  resetTimeout: number | undefined
): {
  copied: boolean;
  handleCopy: () => void;
} => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleCopy = () => {
    setCopied(true);
    if (resetTimeout) {
      timeoutRef.current = setTimeout(() => setCopied(false), resetTimeout);
    }
  };

  return {
    copied,
    handleCopy,
  };
};
