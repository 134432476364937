import { css, styled, x } from '@xstyled/styled-components';
import { useState } from 'react';

import {
  CommentCoreFragment,
  PostFeedFragment,
} from '../../../../generated/types-and-hooks';
import { COMMENT_MEDIA_HEIGHT_PX } from '../../../config/constants';
import { Image } from '../../common/components/Image';
import { VideoPlayer } from '../../video/components/VideoPlayer';
import { PostLightbox } from './PostLightbox';
import { PostResource } from './PostResource';

const imageCss = css`
  width: ${COMMENT_MEDIA_HEIGHT_PX}px;
  height: ${COMMENT_MEDIA_HEIGHT_PX}px;
  object-fit: cover;
  border-radius: md;
  background-color: gray.100;
`;

const StyledImage = styled(Image)`
  ${imageCss};
  cursor: ${(p) => (p.onClick ? 'pointer' : 'auto')};
`;

export type PostMediaResourcesProps = {
  media?: PostFeedFragment['media'][0][] | CommentCoreFragment['media'][0][];
  numMediaLoading?: number;
  resources?:
    | PostFeedFragment['resources'][0][]
    | CommentCoreFragment['resources'][0][];
  numResourcesLoading?: number;
  colorVariant?: 'dark' | 'light';
};

export const PostMediaResources: React.FC<PostMediaResourcesProps> = ({
  media,
  numMediaLoading,
  resources,
  numResourcesLoading,
  colorVariant,
}) => {
  const [lightboxIndex, setLightboxIndex] = useState<number | null>(null);

  return (
    <>
      {media?.length ||
      numMediaLoading ||
      resources?.length ||
      numResourcesLoading ? (
        <x.div spaceY={2}>
          {media?.length ? (
            <>
              <x.div display="flex" gap={3} flexWrap="wrap">
                {media.map((m, i) => {
                  if ('publicId' in m) {
                    return (
                      <StyledImage
                        src={m.publicId}
                        key={m.id}
                        onClick={() => setLightboxIndex(i)}
                      />
                    );
                  } else if ('giphyUrl' in m) {
                    return (
                      <x.div
                        key={m.id}
                        w={COMMENT_MEDIA_HEIGHT_PX}
                        h={COMMENT_MEDIA_HEIGHT_PX}
                        bg="gray.100"
                        onClick={() => setLightboxIndex(i)}
                        cursor="pointer"
                      >
                        <VideoPlayer
                          poster={m.giphyUrl}
                          preload="auto"
                          src={m.giphyMp4Url}
                          playsInline
                          loop
                          autoPlay
                          w="100%"
                          h="100%"
                          overflow="hidden"
                          borderRadius="md"
                          transform
                          bindEvents={false}
                        />
                      </x.div>
                    );
                  }
                })}
              </x.div>
              <PostLightbox
                media={media}
                isOpen={lightboxIndex !== null}
                onDismiss={() => setLightboxIndex(null)}
                initialIndex={lightboxIndex || 0}
              />
            </>
          ) : numMediaLoading ? (
            <x.div display="flex" gap={3} mb={2} flexWrap="wrap">
              {[...new Array(numMediaLoading)].map((_, i) => {
                return (
                  <x.div
                    key={`media-${i}`}
                    w={COMMENT_MEDIA_HEIGHT_PX}
                    h={COMMENT_MEDIA_HEIGHT_PX}
                    bg="gray.100"
                    borderRadius="md"
                  />
                );
              })}
            </x.div>
          ) : null}
          {resources?.length ? (
            <x.div spaceY={2}>
              {resources.map((resource) => {
                return (
                  <PostResource
                    colorVariant={colorVariant}
                    resource={resource}
                    key={resource.id}
                  />
                );
              })}
            </x.div>
          ) : numResourcesLoading ? (
            <x.div spaceY={2}>
              {[...new Array(numResourcesLoading)].map((_, i) => {
                return (
                  <x.div
                    key={`resource-${i}`}
                    w="100%"
                    h="78px"
                    bg="gray.100"
                    borderRadius="md"
                  />
                );
              })}
            </x.div>
          ) : null}
        </x.div>
      ) : null}
    </>
  );
};
