import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import {
  GroupWithMembersFragment,
  refetchGroupQuery,
  useToggleGroupAutoAddMembersMutation,
} from '../../../../../../generated/types-and-hooks';
import { Alert } from '../../../../common/components/Alert';
import { Icon } from '../../../../common/components/Icon';
import { Switch } from '../../../../common/components/Switch';
import { Text } from '../../../../common/components/Text';
import { GroupEditSetting } from './GroupEditSetting';

export const GroupEditSettingAutoAdd: React.FC<{
  group: GroupWithMembersFragment;
}> = ({ group }) => {
  const { t } = useTranslation(['groups', 'common']);
  const [toggleGroupAutoAddMembers] = useToggleGroupAutoAddMembersMutation({
    optimisticResponse: {
      __typename: 'Mutation',
      toggleGroupAutoAddMembers: {
        __typename: 'Group',
        id: group.id,
        autoAddMembers: !group.autoAddMembers,
      },
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      refetchGroupQuery({
        id: group.id,
      }),
    ],
  });

  const [showAlertSwitchToAutoAddMembers, setShowAlertSwitchToAutoAddMembers] =
    useState<boolean>(false);

  const handleChange = (enabled: boolean) => {
    if (enabled) {
      setShowAlertSwitchToAutoAddMembers(true);
    } else {
      toggleGroupAutoAddMembers({
        variables: {
          input: {
            groupId: group.id,
          },
        },
      });
    }
  };

  const disabled = !!group.isPrivate;
  return (
    <GroupEditSetting
      title={t('groups.auto_add')}
      disabled={disabled}
      toggle={
        <Switch
          disabled={disabled}
          checked={group.autoAddMembers}
          onCheckedChange={handleChange}
        />
      }
    >
      <Text variant="md" color={disabled ? 'gray.200' : 'gray.300'} pr={16}>
        {t('groups.auto_add_explanation')}
      </Text>
      {disabled ? (
        <x.div
          display="flex"
          mt={4}
          alignItems="center"
          color="gray.300"
          spaceX={2}
        >
          <Icon name="info" size="5" />
          <Text>{t('groups.auto_add_private')}</Text>
        </x.div>
      ) : null}

      {showAlertSwitchToAutoAddMembers && (
        <Alert
          headingText={t('groups.add_existing')}
          descriptionText={t('groups.add_existing_explanation')}
          cancelButtonText={t('groups.dont_add')}
          submitButtonText={t('add_members', { ns: 'common' })}
          onSubmitClick={() => {
            toggleGroupAutoAddMembers({
              variables: {
                input: {
                  groupId: group.id,
                  addExistingMembers: true,
                },
              },
            });
            setShowAlertSwitchToAutoAddMembers(false);
          }}
          onCancelClick={() => {
            toggleGroupAutoAddMembers({
              variables: {
                input: {
                  groupId: group.id,
                },
              },
            });
            setShowAlertSwitchToAutoAddMembers(false);
          }}
          onDismiss={() => setShowAlertSwitchToAutoAddMembers(false)}
        />
      )}
    </GroupEditSetting>
  );
};
