import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { useOrganization } from '../../auth/hooks/useOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { OrganizationShareModal } from '../../organizations/components/OrganizationShareModal';
import { Button } from './Button';
import { Icon } from './Icon';

export const AddMembersButton = () => {
  const { t } = useTranslation();
  const [sharing, setSharing] = useState<boolean>(false);
  const { viewer: user } = useViewer();
  const { organization } = useOrganization();
  return (
    <>
      {user ? (
        <>
          <Button
            display={{ _: 'none', sm: 'block' }}
            variant="secondary"
            label={t('add_members')}
            leftElement={<Icon name="plus" size="4" />}
            flex="1"
            onClick={() => setSharing(true)}
          />
          <Button
            display={{ _: 'block', sm: 'none' }}
            variant="secondary"
            label={t('add')}
            onClick={() => setSharing(true)}
          />
        </>
      ) : null}

      <OrganizationShareModal
        isOpen={sharing}
        onDismiss={() => setSharing(false)}
        organization={organization}
      />
    </>
  );
};
