import * as React from "react";

function SvgTempTabQuestions(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.36 12.966h1.567l.789 1.014.775.903 1.462 1.833H14.23l-1.005-1.236-.516-.733-1.35-1.781zm4.725-1.33c0 .952-.18 1.762-.541 2.43a3.753 3.753 0 01-1.466 1.53c-.617.349-1.31.523-2.08.523-.775 0-1.471-.176-2.088-.528-.616-.352-1.103-.862-1.461-1.53-.358-.668-.537-1.476-.537-2.425 0-.951.179-1.761.537-2.429.358-.667.845-1.176 1.461-1.525.617-.352 1.313-.529 2.088-.529.77 0 1.463.177 2.08.529.62.35 1.108.858 1.466 1.525.36.668.541 1.478.541 2.43zm-1.87 0c0-.616-.093-1.136-.278-1.56-.181-.423-.439-.744-.771-.962a2.075 2.075 0 00-1.168-.328c-.446 0-.835.109-1.167.328-.333.218-.591.54-.776.963-.182.423-.273.943-.273 1.56 0 .616.091 1.136.273 1.559.185.423.443.744.776.963.332.219.721.328 1.167.328.446 0 .836-.11 1.168-.328.332-.219.59-.54.771-.963.185-.423.277-.943.277-1.56z"
        fill="currentColor"
      />
      <rect
        x={1.25}
        y={1.25}
        width={21.5}
        height={21.5}
        rx={4.75}
        stroke="currentColor"
        strokeWidth={2.5}
      />
    </svg>
  );
}

export default SvgTempTabQuestions;
