import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { UsersQuery } from '../../../../generated/types-and-hooks';
import { Avatar } from '../../common/components/Avatar';
import { Skeleton } from '../../common/components/Skeleton';
import { Text } from '../../common/components/Text';
import { TimeAgo } from '../../common/components/TimeAgo';
import { getUserDisplayName } from '../../common/utils/user';
import { CommunityRoleToken } from '../../settings/components/SettingsViews/CommunityRoleToken';

export type GroupProfileCardProps = {
  user?: UsersQuery['users']['edges'][0]['node'];
};

export const GroupProfileCard: FC<GroupProfileCardProps> = ({ user }) => {
  const { t } = useTranslation();
  const joinedAt = user?.organizationMembership?.createdAt;

  if (!user) {
    return (
      <x.div
        borderRadius="lg"
        backgroundColor="gray.50"
        border
        borderColor="gray.50"
        h="57px"
      >
        <Skeleton width="100%" height="100%" />
      </x.div>
    );
  }

  return (
    <x.div
      backgroundColor="gray.50"
      borderRadius="md-lg"
      display="flex"
      alignItems="center"
      spaceX={3}
      px={3}
      py={2}
      border
      borderColor={{
        _: 'gray.50',
        hover: 'brand.300',
      }}
      transitionTimingFunction="ease-in-out"
      transitionDuration="fast"
    >
      <Avatar
        size="s-3"
        bgColor={user.avatarBgColor}
        avatar={user.avatar}
        imageId={user.profileImageId}
      />
      <x.div flex={1}>
        <Text variant="md-semibold">{getUserDisplayName(user)}</Text>
        {joinedAt && (
          <Text variant="xs" color="gray.300">
            {t('joined', { ns: 'common' })}{' '}
            <TimeAgo date={new Date(joinedAt)} />
          </Text>
        )}
      </x.div>
      <x.div display="flex" flexWrap="wrap" spaceX={2}>
        {user.organizationMembership?.roles?.map((role) => (
          <CommunityRoleToken key={role.id} role={role} />
        ))}
      </x.div>
    </x.div>
  );
};
