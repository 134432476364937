import * as React from "react";

function SvgTextAlignJustify(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 7V5H2v2h20zm0 2v2H2V9h20zm0 6v-2H2v2h20zm0 4v-2H2v2h20z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTextAlignJustify;
