import * as React from "react";

function SvgAppYoutube(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-youtube_svg__clip0)">
        <path
          d="M12.985 19.58l-4.924-.09c-1.594-.031-3.192.032-4.755-.294C.928 18.711.759 16.33.583 14.331a34.129 34.129 0 01.31-8.453C1.15 4.315 2.17 3.383 3.744 3.281c5.316-.368 10.667-.324 15.97-.153.56.016 1.125.102 1.677.2 2.727.478 2.793 3.177 2.97 5.45a30.89 30.89 0 01-.235 6.882c-.27 1.887-.788 3.47-2.97 3.623-2.734.2-5.406.36-8.148.31 0-.013-.015-.013-.023-.013zm-2.895-4.779c2.06-1.183 4.082-2.346 6.13-3.521-2.064-1.183-4.081-2.347-6.13-3.522v7.043z"
          fill="red"
        />
      </g>
      <defs>
        <clipPath id="app-youtube_svg__clip0">
          <path fill="#fff" transform="translate(.453 3)" d="M0 0h24v17H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppYoutube;
