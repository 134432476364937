import * as React from 'react';
import { SVGProps } from 'react';
const SvgReactionAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.264 1.98A8.312 8.312 0 0 0 10 1.666c-4.595 0-8.333 3.738-8.333 8.333 0 4.595 3.738 8.333 8.333 8.333 4.595 0 8.333-3.738 8.333-8.333 0-.784-.109-1.544-.312-2.264a4.48 4.48 0 0 1-1.225.254c.187.637.287 1.312.287 2.01A7.074 7.074 0 0 1 10 17.083 7.074 7.074 0 0 1 2.917 10 7.074 7.074 0 0 1 10 2.917c.698 0 1.373.1 2.01.287a4.48 4.48 0 0 1 .254-1.225Zm-5.5 5.2a1.042 1.042 0 1 1 1.473 1.473A1.042 1.042 0 0 1 6.763 7.18Zm5 0a1.042 1.042 0 1 1 1.472 1.473 1.042 1.042 0 0 1-1.473-1.473Zm-5.529 4.572a.626.626 0 0 1 .823.153A3.727 3.727 0 0 0 10 13.333a3.727 3.727 0 0 0 2.942-1.428.624.624 0 1 1 .982.773A4.988 4.988 0 0 1 10 14.583a4.988 4.988 0 0 1-3.924-1.905.625.625 0 0 1 .159-.926Z"
      fill="currentColor"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.41}
      d="M13.5 3.556h6.111M16.555 6.611V.5"
    />
  </svg>
);
export default SvgReactionAdd;
