import { useApolloClient } from '@apollo/client';
import { ChatClient, ChatMessageEditedEvent } from '@azure/communication-chat';
import { useCallback, useEffect } from 'react';

import {
  DirectMessageDocument,
  DirectMessageNotificationCountsDocument,
  DirectMessageNotificationCountsQuery,
  DirectMessageNotificationCountsQueryVariables,
  DirectMessageQuery,
  DirectMessageQueryVariables,
  DirectMessageThreadsDocument,
  DirectMessageThreadsQuery,
  DirectMessageThreadsQueryVariables,
  useDirectMessageThreadsQuery,
} from '../../../../../generated/types-and-hooks';
import { useDirectMessageToken } from '../../../auth/hooks/useDirectMessageToken';
import { useOptionalOrganization } from '../../../auth/hooks/useOptionalOrganization';
import { useViewer } from '../../../auth/hooks/useViewer';
import { userInOrganization } from '../../../auth/utils/permissions';
import { useChatCacheHandlers } from './useChatCacheHandlers';

export const useChatSubscription = ({
  chatClient,
}: {
  chatClient?: ChatClient;
}) => {
  const { organization } = useOptionalOrganization();
  const { viewer } = useViewer();

  const { directMessageToken } = useDirectMessageToken();

  const communicationUserId = directMessageToken?.communicationUserId;
  const { handleReadReceiptReceived, handelChatMessageReceived } =
    useChatCacheHandlers({ communicationUserId });

  const client = useApolloClient();

  const skip =
    !organization ||
    !viewer ||
    (organization && viewer && !userInOrganization(viewer, organization));

  useDirectMessageThreadsQuery({
    variables: {
      organizationId: organization?.id || '',
    },
    skip,
    context: { skip },
  });

  // Refetch when message was edited, i.e. when user reacts to a message
  const handleMessageEdited = useCallback(
    (event: ChatMessageEditedEvent) => {
      client.query<DirectMessageQuery, DirectMessageQueryVariables>({
        query: DirectMessageDocument,
        variables: { id: event.id, threadId: event.threadId },
      });
    },
    [client]
  );

  // Refetch threads and unread count when thread was created or deleted
  const handleThreadCreatedOrDeleted = useCallback(() => {
    if (!organization) return;
    client.query<DirectMessageThreadsQuery, DirectMessageThreadsQueryVariables>(
      {
        query: DirectMessageThreadsDocument,
        variables: {
          organizationId: organization.id,
        },
      }
    );
    client.query<
      DirectMessageNotificationCountsQuery,
      DirectMessageNotificationCountsQueryVariables
    >({
      query: DirectMessageNotificationCountsDocument,
      variables: {
        organizationId: organization.id,
      },
    });
  }, [client, organization]);

  useEffect(() => {
    if (!chatClient) return;

    // Subscribe to notifications
    chatClient.on('chatMessageReceived', handelChatMessageReceived);
    chatClient.on('chatThreadCreated', handleThreadCreatedOrDeleted);
    chatClient.on('chatThreadDeleted', handleThreadCreatedOrDeleted);
    chatClient.on('readReceiptReceived', handleReadReceiptReceived);
    chatClient.on('chatMessageEdited', handleMessageEdited);

    return () => {
      // Unsubscribe from notifications
      chatClient.off('chatMessageReceived', handelChatMessageReceived);
      chatClient.off('chatThreadCreated', handleThreadCreatedOrDeleted);
      chatClient.off('chatThreadDeleted', handleThreadCreatedOrDeleted);
      chatClient.off('readReceiptReceived', handleReadReceiptReceived);
      chatClient.off('chatMessageEdited', handleMessageEdited);
    };
  }, [
    chatClient,
    handelChatMessageReceived,
    handleReadReceiptReceived,
    handleThreadCreatedOrDeleted,
    handleMessageEdited,
  ]);
};
