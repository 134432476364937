import * as React from "react";

function SvgAppVercel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-vercel_svg__clip0)">
        <path
          d="M11.973 10.64c-.937 0-1.613.612-1.613 1.529 0 .917.76 1.528 1.698 1.528.566 0 1.065-.224 1.374-.602l-.65-.375a.972.972 0 01-.724.297c-.407 0-.752-.212-.88-.552h2.378a1.53 1.53 0 00.03-.297c0-.916-.676-1.527-1.613-1.527zm-.803 1.232c.106-.34.396-.552.802-.552.407 0 .697.213.802.552H11.17zm9.944-1.231c-.937 0-1.613.61-1.613 1.528 0 .917.76 1.528 1.698 1.528.566 0 1.065-.224 1.374-.602l-.65-.375a.972.972 0 01-.724.297c-.407 0-.753-.212-.88-.552h2.378a1.53 1.53 0 00.03-.297c0-.916-.676-1.527-1.613-1.527zm-.803 1.23c.107-.338.397-.551.803-.551s.697.213.802.552h-1.605zm-3.313.298c0 .51.333.849.85.849.349 0 .611-.16.746-.418l.652.376c-.27.45-.776.72-1.399.72-.938 0-1.613-.61-1.613-1.527 0-.917.676-1.528 1.613-1.528.623 0 1.128.27 1.4.72l-.653.377c-.135-.26-.397-.418-.747-.418-.515 0-.849.34-.849.849zM24 9.707v3.905h-.764V9.707H24zM3.137 9.282l3.136 5.433H0l3.137-5.433zm7.842.425l-2.353 4.075-2.352-4.075h.882l1.47 2.547 1.47-2.547h.883zm5 1.019v.822a.968.968 0 00-.271-.041c-.493 0-.849.34-.849.848v1.257h-.764v-2.886h.764v.78c0-.43.502-.78 1.12-.78z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="app-vercel_svg__clip0">
          <path fill="#fff" transform="translate(0 -.001)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppVercel;
