import { x } from '@xstyled/styled-components';
import React from 'react';

export const Breakpoint: React.FC<{
  _: JSX.Element;
  sm?: JSX.Element;
  'sm-2'?: JSX.Element;
}> = (props) => {
  const { _, sm } = props;
  return (
    <x.div>
      <x.div
        display={{
          _: 'block',
          ...(props['sm-2'] ? { 'sm-2': 'none' } : { sm: 'none' }),
        }}
      >
        {_}
      </x.div>
      {sm && <x.div display={{ _: 'none', sm: 'block' }}>{sm}</x.div>}
      {props['sm-2'] && (
        <x.div display={{ _: 'none', 'sm-2': 'block' }}>{props['sm-2']}</x.div>
      )}
    </x.div>
  );
};
