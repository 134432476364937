import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { UserPreviewFragment } from '../../../../../generated/types-and-hooks';
import { useOrganization } from '../../../auth/hooks/useOrganization';
import { useIsViewerAdmin, useViewer } from '../../../auth/hooks/useViewer';
import {
  StyledDropdownMenuButton,
  StyledDropdownMenuContent,
  StyledDropdownMenuItem,
} from '../../../posts/components/PostCard';
import { Text } from '../Text';

export const CommentCellActionButton: FC<{
  onOpenChange?: (open: boolean) => void;
  onClickDelete: () => void;
  onClickEdit: () => void;
  onClickBestAnswer?: () => void;
  highlight?: boolean;
  user: UserPreviewFragment;
}> = ({
  onOpenChange,
  onClickEdit,
  onClickDelete,
  onClickBestAnswer,
  highlight,
  user,
}) => {
  const { t } = useTranslation();
  const { organization } = useOrganization();
  const isViewerAdmin = useIsViewerAdmin(organization);
  const { viewer } = useViewer();

  if (user.id !== viewer?.id && !isViewerAdmin) {
    return null;
  }

  return (
    <>
      <DropdownMenu.Root onOpenChange={onOpenChange}>
        <DropdownMenu.Trigger asChild>
          <StyledDropdownMenuButton
            cursor="pointer"
            m={0}
            p={3}
            bg="white"
            borderRadius="sm-md"
            position="relative"
            border={1}
            borderColor={{
              _: 'transparent',
              hover: 'brand.300',
            }}
            spaceX={1}
            display="flex"
            alignItems="center"
            transition
            transitionDuration="fast"
            transitionTimingFunction="ease-out"
            color={{ _: 'gray.500', sm: 'gray.300', hover: 'brand.300' }}
            boxShadow="sm"
          >
            {[...new Array(3)].map((_, i) => {
              return (
                <x.div bg="currentColor" borderRadius={4} key={i} w={1} h={1} />
              );
            })}
          </StyledDropdownMenuButton>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <StyledDropdownMenuContent align="end">
            <x.div p={3} boxShadow="sm" borderRadius="md" spaceY={1}>
              {viewer?.id === user.id && (
                <>
                  <StyledDropdownMenuItem onSelect={onClickEdit}>
                    <Text variant="md">{t('edit_comment')}</Text>
                  </StyledDropdownMenuItem>
                  <StyledDropdownMenuItem onSelect={onClickDelete}>
                    <Text variant="md">{t('delete_comment')}</Text>
                  </StyledDropdownMenuItem>
                </>
              )}
              {isViewerAdmin && (
                <StyledDropdownMenuItem onSelect={onClickBestAnswer}>
                  <Text variant="md">
                    {highlight
                      ? t('unhighlight_comment')
                      : t('highlight_comment')}
                  </Text>
                </StyledDropdownMenuItem>
              )}
            </x.div>
          </StyledDropdownMenuContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
};
