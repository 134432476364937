import * as React from 'react';
import { SVGProps } from 'react';

const SvgSignOut = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M5.75 3C4.24 3 3 4.24 3 5.75v12.5C3 19.76 4.24 21 5.75 21h9c1.51 0 2.75-1.24 2.75-2.75a.751.751 0 1 0-1.5 0c0 .7-.55 1.25-1.25 1.25h-9c-.7 0-1.25-.55-1.25-1.25V5.75c0-.698.55-1.25 1.25-1.25h9c.7 0 1.25.552 1.25 1.25a.751.751 0 1 0 1.5 0C17.5 4.24 16.26 3 14.75 3h-9Zm10.992 4.743a.75.75 0 0 0-.522 1.288l2.22 2.22H7.75a.75.75 0 1 0 0 1.5h10.69l-2.22 2.22a.75.75 0 1 0 1.06 1.06l3.5-3.5a.75.75 0 0 0 0-1.06l-3.5-3.5a.75.75 0 0 0-.538-.228Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSignOut;
