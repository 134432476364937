import * as React from "react";
import { SVGProps } from "react";
const SvgSportsClub = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.833 10a8.333 8.333 0 0 1-8.334 8.334A8.333 8.333 0 0 1 2.166 10a8.333 8.333 0 0 1 8.333-8.333A8.333 8.333 0 0 1 18.833 10Z"
      fill="url(#sports-club_svg__a)"
    />
    <path
      d="M10.916 10.417h2.525c.155 2.09 1.468 3.91 3.39 4.993.182-.213.354-.433.513-.664-1.724-.929-2.91-2.51-3.07-4.329h4.538c.006-.138.02-.276.02-.417 0-.14-.014-.278-.02-.416h-4.538c.16-1.82 1.346-3.4 3.07-4.33-.16-.23-.331-.45-.513-.664-1.922 1.083-3.235 2.904-3.39 4.993h-2.525V1.688c-.139-.006-.276-.021-.417-.021-.14 0-.278.015-.416.021v7.896H7.558c-.156-2.09-1.468-3.91-3.39-4.994a8.414 8.414 0 0 0-.514.664c1.725.93 2.91 2.51 3.07 4.33H2.187c-.007.138-.021.275-.021.416 0 .141.014.279.021.417h4.537c-.159 1.819-1.345 3.4-3.07 4.33.16.23.332.45.514.664 1.922-1.084 3.234-2.905 3.39-4.994h2.525v7.896c.138.006.275.02.416.02.141 0 .278-.014.417-.02v-7.896Z"
      fill="url(#sports-club_svg__b)"
    />
    <defs>
      <linearGradient
        id="sports-club_svg__a"
        x1={10.499}
        y1={0.444}
        x2={10.499}
        y2={32.359}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FED100" />
        <stop offset={0.033} stopColor="#FCCA00" />
        <stop offset={0.221} stopColor="#F3A400" />
        <stop offset={0.408} stopColor="#EC8601" />
        <stop offset={0.592} stopColor="#E77101" />
        <stop offset={0.771} stopColor="#E46401" />
        <stop offset={0.941} stopColor="#E36001" />
      </linearGradient>
      <linearGradient
        id="sports-club_svg__b"
        x1={2.166}
        y1={10}
        x2={18.833}
        y2={10}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C26715" />
        <stop offset={0.508} stopColor="#B85515" />
        <stop offset={1} stopColor="#AD3F16" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgSportsClub;
