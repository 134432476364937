import * as React from "react";
import { SVGProps } from "react";
const SvgGraphUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 5h19a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H6a3.5 3.5 0 0 1-3.5-3.5V5Z"
      fill="url(#graph-up_svg__a)"
    />
    <path
      d="M6 13h1a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6a3.5 3.5 0 0 0-3.5 3.5v11A3.5 3.5 0 0 1 6 13Z"
      fill="url(#graph-up_svg__b)"
    />
    <path
      d="M9.75 15.5h9.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-9.5a.25.25 0 0 1-.25-.25v-.5a.25.25 0 0 1 .25-.25ZM10.177 13.677l-.354-.354a.25.25 0 0 1 0-.354l3.25-3.25a.25.25 0 0 1 .354 0l1.822 1.824 2.647-2.647.707.707-3.177 3.177a.25.25 0 0 1-.353 0l-1.823-1.823-2.72 2.72a.25.25 0 0 1-.353 0Z"
      fill="#35C1F1"
    />
    <path
      d="m16.927 8.427 2.146 2.146a.25.25 0 0 0 .427-.177V8.25a.25.25 0 0 0-.25-.25h-2.146a.25.25 0 0 0-.177.427Z"
      fill="#35C1F1"
    />
    <defs>
      <linearGradient
        id="graph-up_svg__a"
        x1={22.431}
        y1={12.5}
        x2={2.204}
        y2={12.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0068B8" />
        <stop offset={1} stopColor="#004D94" />
      </linearGradient>
      <linearGradient
        id="graph-up_svg__b"
        x1={2.579}
        y1={9.25}
        x2={8.134}
        y2={9.25}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28AFEA" />
        <stop offset={1} stopColor="#0B88DA" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgGraphUp;
