import * as React from "react";

function SvgUpload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 17v2h1a5 5 0 00.725-9.948A7.004 7.004 0 006.22 7.05 6.001 6.001 0 007 19v-2a4 4 0 01-.16-7.997l.55-.021.276-.478a5.002 5.002 0 019.264 1.662l.144.852.863-.017h.032L18 11a3 3 0 110 6h-1zm-4 4v-6.586l2.293 2.293 1.414-1.414L12 10.586l-4.707 4.707 1.414 1.414L11 14.414V21h2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgUpload;
