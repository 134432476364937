import * as React from "react";

function SvgAppJira(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-jira_svg__clip0)">
        <path
          d="M23.388 11.335L13.183 1.13l-.989-.988-7.682 7.681L1 11.335a.941.941 0 000 1.328l7.018 7.018 4.176 4.175 7.68-7.682.12-.118 3.394-3.388a.939.939 0 000-1.333zm-11.194 4.17L8.688 12l3.506-3.506 3.505 3.506-3.505 3.506z"
          fill="#2684FF"
        />
        <path
          d="M12.195 8.493A5.903 5.903 0 0112.169.17L4.497 7.84l4.176 4.175 3.522-3.522z"
          fill="url(#app-jira_svg__paint0_linear)"
        />
        <path
          d="M15.71 11.99l-3.516 3.515a5.904 5.904 0 010 8.35l7.694-7.69-4.179-4.176z"
          fill="url(#app-jira_svg__paint1_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="app-jira_svg__paint0_linear"
          x1={11.567}
          y1={4.945}
          x2={6.671}
          y2={9.84}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.18} stopColor="#0052CC" />
          <stop offset={1} stopColor="#2684FF" />
        </linearGradient>
        <linearGradient
          id="app-jira_svg__paint1_linear"
          x1={894.25}
          y1={1029.63}
          x2={1394.23}
          y2={1353.76}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.18} stopColor="#0052CC" />
          <stop offset={1} stopColor="#2684FF" />
        </linearGradient>
        <clipPath id="app-jira_svg__clip0">
          <path
            fill="#fff"
            transform="translate(1 -.001)"
            d="M0 0h22.666v24H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppJira;
