import * as React from "react";
import { SVGProps } from "react";
const SvgAudioOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.281 1.66a.625.625 0 0 0-.435 1.074L5.362 6.25H3.958a1.878 1.878 0 0 0-1.875 1.875v3.75c0 1.033.842 1.875 1.875 1.875h2.758l3.842 3.416a1.153 1.153 0 0 0 1.254.192c.425-.191.688-.6.688-1.062v-2.908l4.762 4.762a.624.624 0 1 0 .884-.884L2.73 1.85a.625.625 0 0 0-.448-.19Zm9.048.877c-.28 0-.554.1-.77.297L7.87 5.224l.883.884 2.496-2.216v4.712l1.25 1.25V3.71c0-.467-.263-.876-.688-1.067a1.175 1.175 0 0 0-.483-.105ZM16.1 5.002a.623.623 0 0 0-.505.91c1.305 2.492 1.426 4.922.368 7.405l.941.942c1.413-2.963 1.346-5.963-.2-8.921a.622.622 0 0 0-.604-.336Zm-1.849 2.086a.628.628 0 0 0-.55.887c.516 1.13.603 2.23.266 3.346l.974.975c.667-1.609.634-3.233-.104-4.842a.624.624 0 0 0-.586-.366ZM3.958 7.5h2.654l4.638 4.638v3.97l-3.883-3.45a.61.61 0 0 0-.413-.158H3.958a.624.624 0 0 1-.625-.625v-3.75c0-.346.28-.625.625-.625Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgAudioOff;
