import { mapValues } from 'lodash';
import { createContext, PropsWithChildren, useContext } from 'react';

import {
  FeatureEntitlement,
  FeatureQuota,
  useOrganizationEntitlementsQuery,
} from '../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';

type OrganizationEntitlementsType = {
  loading: boolean;
  refetch: () => void;
  features: {
    readonly [f in keyof typeof FeatureEntitlement]?: boolean;
  };
  quotas: {
    readonly [q in keyof typeof FeatureQuota]: number;
  };
};

const OrganizationEntitlementsContext =
  createContext<OrganizationEntitlementsType>({
    loading: false,
    refetch: () => {},
    features: {},
    quotas: {
      ImageUploadSize: 0,
      VideoUploadSize: 0,
      Members: 0,
      Admins: 0,
      PaywallPlatformFee: 0,
    },
  });

export const OrganizationEntitlementsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { organization } = useOptionalOrganization();
  const { data, loading, refetch } = useOrganizationEntitlementsQuery({
    variables: { organizationId: organization?.id as string },
    skip: !organization,
    context: {
      skip: !organization,
    },
  });

  const features = mapValues(FeatureEntitlement, (value) =>
    data?.organization?.entitlements?.features.includes(value)
  );

  const quotas = mapValues(
    FeatureQuota,
    (quota) =>
      data?.organization?.entitlements?.quotas.find((q) => q.name === quota)
        ?.limit || 0
  );

  return (
    <OrganizationEntitlementsContext.Provider
      value={{ loading, refetch, features, quotas }}
    >
      {children}
    </OrganizationEntitlementsContext.Provider>
  );
};

export const useOrganizationEntitlements = () =>
  useContext(OrganizationEntitlementsContext);
