import * as React from "react";

function Seminar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M23.306 12C23.306 18.2062 18.2623 23.25 12.056 23.25C5.84978 23.25 0.80603 18.2062 0.80603 12C0.80603 5.79375 5.83103 0.75 12.056 0.75C18.2623 0.76875 23.306 5.79375 23.306 12Z" fill="#FFAB00"/>
      <path d="M12.056 23.5312C5.69978 23.5312 0.52478 18.3563 0.52478 12C0.52478 5.64375 5.69978 0.46875 12.056 0.46875C18.4123 0.46875 23.5873 5.64375 23.5873 12C23.5873 18.3563 18.4123 23.5312 12.056 23.5312ZM12.056 1.05C6.01853 1.05 1.08728 5.9625 1.08728 12C1.08728 18.0375 5.99978 22.9688 12.056 22.9688C18.1123 22.9688 23.0248 18.0562 23.0248 12C23.0248 5.9625 18.0935 1.05 12.056 1.05Z" fill="#37474F"/>
      <path d="M3.61843 11.6812C3.61843 11.85 3.48718 11.9812 3.31843 11.9812C3.14968 11.9812 3.01843 11.85 3.01843 11.6812C3.01843 11.5125 3.14968 11.3812 3.31843 11.3812C3.48718 11.3812 3.61843 11.5125 3.61843 11.6812Z" fill="#37474F"/>
      <path d="M3.33723 12.075C3.11223 12.075 2.94348 11.9062 2.94348 11.6812C2.94348 11.4562 3.11223 11.2875 3.33723 11.2875C3.56223 11.2875 3.73098 11.4562 3.73098 11.6812C3.71223 11.8875 3.54348 12.075 3.33723 12.075ZM3.33723 11.475C3.22473 11.475 3.13098 11.5687 3.13098 11.6812C3.13098 11.7937 3.22473 11.8875 3.33723 11.8875C3.44973 11.8875 3.54348 11.7937 3.54348 11.6812C3.52473 11.5687 3.44973 11.475 3.33723 11.475Z" fill="#37474F"/>
      <path d="M5.73745 11.6812C5.73745 11.85 5.6062 11.9812 5.43745 11.9812C5.2687 11.9812 5.13745 11.85 5.13745 11.6812C5.13745 11.5125 5.2687 11.3812 5.43745 11.3812C5.6062 11.3812 5.73745 11.5125 5.73745 11.6812Z" fill="#37474F"/>
      <path d="M5.43745 12.075C5.2312 12.075 5.0437 11.8875 5.0437 11.6812C5.0437 11.4562 5.21245 11.2875 5.43745 11.2875C5.66245 11.2875 5.8312 11.4562 5.8312 11.6812C5.8312 11.8875 5.6437 12.075 5.43745 12.075ZM5.43745 11.475C5.32495 11.475 5.2312 11.5687 5.2312 11.6812C5.2312 11.7937 5.32495 11.8875 5.43745 11.8875C5.54995 11.8875 5.6437 11.7937 5.6437 11.6812C5.6437 11.5687 5.54995 11.475 5.43745 11.475Z" fill="#37474F"/>
      <path d="M7.83718 11.6812C7.83718 11.85 7.70593 11.9812 7.53718 11.9812C7.36843 11.9812 7.23718 11.85 7.23718 11.6812C7.23718 11.5125 7.36843 11.3812 7.53718 11.3812C7.70593 11.3812 7.83718 11.5125 7.83718 11.6812Z" fill="#37474F"/>
      <path d="M7.53718 12.075C7.31218 12.075 7.14343 11.9062 7.14343 11.6812C7.14343 11.4562 7.33093 11.2875 7.53718 11.2875C7.76218 11.2875 7.93093 11.4562 7.93093 11.6812C7.93093 11.8875 7.76218 12.075 7.53718 12.075ZM7.53718 11.475C7.42468 11.475 7.33093 11.5687 7.33093 11.6812C7.33093 11.7937 7.42468 11.8875 7.53718 11.8875C7.64968 11.8875 7.74343 11.7937 7.74343 11.6812C7.74343 11.5687 7.64968 11.475 7.53718 11.475Z" fill="#37474F"/>
      <path d="M14.7188 15.4688H9.28125V20.5312H14.7188V15.4688Z" fill="white"/>
      <path d="M14.7188 20.8125H9.28125C9.13125 20.8125 9 20.6813 9 20.5312V15.4688C9 15.3187 9.13125 15.1875 9.28125 15.1875H14.7188C14.8688 15.1875 15 15.3187 15 15.4688V20.5312C15 20.6813 14.8688 20.8125 14.7188 20.8125ZM9.5625 20.25H14.4375V15.75H9.5625V20.25Z" fill="#37474F"/>
      <path d="M3.5438 20.1377L4.31255 16.9877L9.3938 15.1127L10.0313 20.5314H3.48755L3.5438 20.1377Z" fill="#37474F"/>
      <path d="M10.0313 20.8127H3.46875C3.39375 20.8127 3.31875 20.7752 3.2625 20.7189C3.20625 20.6627 3.1875 20.5689 3.1875 20.4939L3.24375 20.1002C3.24375 20.0814 3.24375 20.0814 3.24375 20.0627L4.03125 16.9127C4.05 16.8189 4.125 16.7439 4.2 16.7252L9.28125 14.8502C9.35625 14.8127 9.45 14.8314 9.525 14.8689C9.6 14.9064 9.65625 14.9814 9.65625 15.0752L10.275 20.4377C10.275 20.4564 10.2938 20.4939 10.2938 20.5127C10.3125 20.6814 10.2 20.8127 10.0313 20.8127ZM3.80625 20.2502H9.7125L9.15 15.5064L4.5375 17.1939L3.80625 20.2502Z" fill="#37474F"/>
      <path d="M20.5688 20.1377L19.7813 16.9877L14.7 15.1127L14.0813 20.5314H20.625L20.5688 20.1377Z" fill="#37474F"/>
      <path d="M20.625 20.8127H14.0813C14.0063 20.8127 13.9313 20.7752 13.875 20.7189C13.8188 20.6627 13.8 20.5877 13.8 20.4939L14.4188 15.0752C14.4375 14.9814 14.475 14.9064 14.55 14.8689C14.625 14.8314 14.7188 14.8127 14.7938 14.8502L19.875 16.7252C19.9688 16.7627 20.025 16.8377 20.0438 16.9127L20.8313 20.0627C20.8313 20.0814 20.8313 20.0814 20.8313 20.1002L20.8875 20.4939C20.9063 20.5689 20.8688 20.6627 20.8125 20.7189C20.775 20.7752 20.7 20.8127 20.625 20.8127ZM14.4 20.2502H20.3063V20.1939L19.5563 17.1939L14.9438 15.5064L14.4 20.2502Z" fill="#37474F"/>
      <path d="M13.9688 14.1375C13.9688 15.1875 13.1437 16.0312 12.0938 16.0312C11.0438 16.0312 10.2188 15.1875 10.2188 14.1375V12.4687C10.2188 11.4187 11.0438 10.575 12.0938 10.575C13.1437 10.575 13.9688 11.4187 13.9688 12.4687V14.1375Z" fill="#FFECB3"/>
      <path d="M12.0938 16.3125C10.9125 16.3125 9.9375 15.3375 9.9375 14.1375V12.4687C9.9375 11.2687 10.9125 10.2937 12.0938 10.2937C13.275 10.2937 14.25 11.2687 14.25 12.4687V14.1375C14.25 15.3375 13.275 16.3125 12.0938 16.3125ZM12.0938 10.8562C11.2125 10.8562 10.5 11.5687 10.5 12.4687V14.1375C10.5 15.0187 11.2125 15.75 12.0938 15.75C12.975 15.75 13.6875 15.0375 13.6875 14.1375V12.4687C13.6875 11.5875 12.975 10.8562 12.0938 10.8562Z" fill="#37474F"/>
      <path d="M15.6562 8.73747C15.6562 10.7062 14.0625 12.3187 12.0938 12.3187C10.1062 12.3187 8.53125 10.725 8.53125 8.73747V6.33747C8.53125 4.36873 10.125 2.75623 12.0938 2.75623C14.0625 2.75623 15.6562 4.34997 15.6562 6.33747V8.73747Z" fill="#37474F"/>
      <path d="M12.0938 12.5812C9.975 12.5812 8.25 10.8562 8.25 8.73748V6.33748C8.25 4.21873 9.975 2.47498 12.0938 2.47498C14.2125 2.47498 15.9375 4.19998 15.9375 6.33748V8.71873C15.9375 10.8562 14.2125 12.5812 12.0938 12.5812ZM12.0938 3.05623C10.275 3.05623 8.8125 4.53748 8.8125 6.35623V8.73748C8.8125 10.5562 10.2937 12.0375 12.0938 12.0375C13.8938 12.0375 15.375 10.5562 15.375 8.73748V6.33748C15.375 4.51873 13.9125 3.05623 12.0938 3.05623Z" fill="#37474F"/>
      <path d="M15.6562 8.7374C15.6562 10.7062 14.0625 12.3187 12.0938 12.3187C10.1062 12.3187 8.53125 10.7249 8.53125 8.7374V6.3374C8.53125 4.36865 9.225 5.75615 11.1938 5.75615L12.525 3.99365C14.4938 3.99365 15.6375 4.36865 15.6375 6.3374V8.7374H15.6562Z" fill="#FFECB3"/>
      <path d="M12.0937 12.5812C9.975 12.5812 8.25 10.8562 8.25 8.73745V6.33745C8.25 5.8312 8.25 5.0062 8.925 5.0062C9.1125 5.0062 9.31875 5.0812 9.58125 5.17495C9.9375 5.3062 10.425 5.47495 11.0438 5.4937L12.3 3.8437C12.3562 3.7687 12.4313 3.7312 12.525 3.7312C14.25 3.7312 15.9187 3.93745 15.9187 6.3562V8.73745C15.9375 10.8562 14.2125 12.5812 12.0937 12.5812ZM8.925 5.5687C8.90625 5.5687 8.90625 5.5687 8.90625 5.5687C8.8875 5.58745 8.8125 5.69995 8.8125 6.33745V8.7187C8.8125 10.5375 10.2937 12.0187 12.0937 12.0187C13.8937 12.0187 15.375 10.5375 15.375 8.7187V6.33745C15.375 4.79995 14.7187 4.2937 12.675 4.27495L11.4188 5.92495C11.3625 5.99995 11.2875 6.03745 11.1937 6.03745C10.4063 6.03745 9.825 5.8312 9.39375 5.6812C9.20625 5.62495 9.01875 5.5687 8.925 5.5687Z" fill="#37474F"/>
      <path d="M8.75635 7.06872C8.75635 7.06872 12.7501 6.82497 13.7063 5.09998C13.7063 5.09998 14.7751 6.56247 15.5438 6.50622L15.3188 4.96872L14.0626 3.97498L11.4751 4.12498L9.7126 5.19373L8.8876 5.34372L8.75635 7.06872Z" fill="#37474F"/>
      <path d="M8.75622 7.34995C8.68122 7.34995 8.60622 7.31245 8.54997 7.27495C8.49372 7.2187 8.45622 7.1437 8.47497 7.04995L8.58747 5.36245C8.58747 5.2312 8.68122 5.1187 8.81247 5.09995L9.58122 4.94995L11.3062 3.9187C11.3437 3.89995 11.3812 3.8812 11.4375 3.8812L14.025 3.7312C14.1 3.7312 14.1562 3.74995 14.2125 3.78745L15.4687 4.7812C15.525 4.8187 15.5625 4.8937 15.5812 4.9687L15.8062 6.5062C15.825 6.5812 15.7875 6.6562 15.75 6.7312C15.6937 6.78745 15.6187 6.82495 15.5437 6.82495C14.8687 6.86245 14.0812 6.07495 13.7062 5.6062C12.4312 7.1062 8.92497 7.3312 8.75622 7.34995ZM9.11247 5.6062L9.05622 6.74995C9.99372 6.6562 12.7312 6.26245 13.4437 4.9687C13.4812 4.87495 13.575 4.83745 13.6687 4.8187C13.7625 4.8187 13.8562 4.8562 13.9125 4.9312C14.2125 5.32495 14.7562 5.92495 15.1875 6.14995L15.0375 5.13745L13.95 4.2937L11.5312 4.4437L9.82497 5.47495C9.78747 5.4937 9.76872 5.51245 9.73122 5.51245L9.11247 5.6062Z" fill="#37474F"/>
      <path d="M10.8748 20.3624L11.6248 16.7812L11.2686 16.0687L12.0936 15.5249V20.6249L10.7623 20.5499L10.8748 20.3624Z" fill="#37474F"/>
      <path d="M12.0936 20.9061C12.0936 20.9061 12.0748 20.9061 12.0936 20.9061L10.7436 20.8311C10.6498 20.8311 10.5561 20.7749 10.5186 20.6811C10.4811 20.5874 10.4811 20.4936 10.5373 20.3999L10.6311 20.2499L11.3436 16.8186L11.0436 16.1999C10.9873 16.0686 11.0248 15.9186 11.1373 15.8436L11.9623 15.2999C12.0561 15.2436 12.1686 15.2436 12.2436 15.2811C12.3373 15.3374 12.3936 15.4311 12.3936 15.5249V20.6249C12.3936 20.6999 12.3561 20.7749 12.2998 20.8311C12.2436 20.8874 12.1686 20.9061 12.0936 20.9061ZM11.1748 20.2874L11.8123 20.3249V17.2499L11.1748 20.2874ZM11.6248 16.1624L11.8123 16.5186V16.0499L11.6248 16.1624Z" fill="#37474F"/>
      <path d="M13.0875 20.3625L12.3375 16.7813L12.7125 16.0688L11.9062 15.525V20.625L13.2188 20.55L13.0875 20.3625Z" fill="#37474F"/>
      <path d="M11.9063 20.9063C11.8313 20.9063 11.7563 20.8875 11.7188 20.8313C11.6625 20.775 11.625 20.7 11.625 20.625V15.525C11.625 15.4125 11.6812 15.3188 11.775 15.2813C11.8687 15.225 11.9813 15.2438 12.0563 15.3L12.8625 15.8438C12.975 15.9188 13.0125 16.0875 12.9562 16.2L12.6188 16.8188L13.3312 20.2313L13.4437 20.3813C13.5 20.4563 13.5188 20.5688 13.4625 20.6625C13.425 20.7563 13.3313 20.8125 13.2188 20.8313L11.9063 20.9063C11.9063 20.9063 11.925 20.9063 11.9063 20.9063ZM12.1875 17.4563V20.325L12.7875 20.2875L12.1875 17.4563ZM12.1875 16.05V16.4438L12.3375 16.1625L12.1875 16.05Z" fill="#37474F"/>
      <path d="M11.9997 15.7687L9.71223 13.4812L8.75598 14.5125L10.481 16.9687L11.9997 15.7687Z" fill="white"/>
      <path d="M10.4809 17.25C10.4622 17.25 10.4622 17.25 10.4434 17.25C10.3684 17.2312 10.2934 17.1937 10.2559 17.1375L8.53091 14.6812C8.45591 14.5687 8.45591 14.4187 8.54966 14.325L9.52466 13.2937C9.58091 13.2375 9.65591 13.2 9.73091 13.2C9.80591 13.2 9.88091 13.2375 9.93716 13.275L12.2059 15.5625C12.2622 15.6187 12.2997 15.6937 12.2809 15.7687C12.2809 15.8437 12.2434 15.9187 12.1684 15.975L10.6497 17.175C10.6122 17.2312 10.5372 17.25 10.4809 17.25ZM9.11216 14.5312L10.5372 16.5562L11.5872 15.7312L9.73091 13.875L9.11216 14.5312Z" fill="#37474F"/>
      <path d="M12.1122 15.7687L14.3809 13.4812L15.3559 14.5125L13.6309 16.9687L12.1122 15.7687Z" fill="white"/>
      <path d="M13.6309 17.25C13.5747 17.25 13.4997 17.2312 13.4622 17.1937L11.9434 15.9938C11.8872 15.9375 11.8497 15.8625 11.8309 15.7875C11.8309 15.7125 11.8497 15.6375 11.9059 15.5812L14.1747 13.2938C14.2309 13.2375 14.2872 13.2188 14.3809 13.2188C14.4559 13.2188 14.5309 13.2562 14.5872 13.3125L15.5622 14.3437C15.6559 14.4375 15.6747 14.5875 15.5809 14.7L13.8559 17.1562C13.8184 17.2125 13.7434 17.2687 13.6684 17.2687C13.6497 17.25 13.6309 17.25 13.6309 17.25ZM12.5247 15.75L13.5559 16.575L14.9997 14.5312L14.3809 13.875L12.5247 15.75Z" fill="#37474F"/>
      <path d="M22.9688 20.3438H1.03125V23.3438H22.9688V20.3438Z" fill="#B0BEC5"/>
      <path d="M22.9688 23.625H1.03125C0.88125 23.625 0.75 23.4938 0.75 23.3438V20.3438C0.75 20.1937 0.88125 20.0625 1.03125 20.0625H22.9688C23.1188 20.0625 23.25 20.1937 23.25 20.3438V23.3438C23.25 23.4938 23.1188 23.625 22.9688 23.625ZM1.3125 23.0625H22.6875V20.625H1.3125V23.0625Z" fill="#37474F"/>
      <path d="M17.625 18.5625H15.375V18.9375H17.625V18.5625Z" fill="#B0BEC5"/>
      <path d="M19.7063 12.6937L21.4688 14.4375V20.25" fill="white"/>
      <path d="M21.4686 20.5313C21.3186 20.5313 21.1873 20.4 21.1873 20.25V14.55L19.5186 12.9C19.4061 12.7875 19.4061 12.6187 19.5186 12.5062C19.6311 12.3938 19.7998 12.3938 19.9123 12.5062L21.6748 14.25C21.7311 14.3063 21.7498 14.3813 21.7498 14.4563V20.2687C21.7498 20.4 21.6186 20.5313 21.4686 20.5313Z" fill="#37474F"/>
      <path d="M20.7188 12.0563C20.7188 12.8813 19.95 13.5563 19.0312 13.5563C18.1125 13.5563 17.3438 12.8813 17.3438 12.0563V9.30005C17.3438 8.47505 18.1125 7.80005 19.0312 7.80005C19.95 7.80005 20.7188 8.4563 20.7188 9.30005V12.0563Z" fill="#B0BEC5"/>
      <path d="M19.0312 13.8188C17.9437 13.8188 17.0625 13.0313 17.0625 12.0375V9.30005C17.0625 8.32505 17.9437 7.5188 19.0312 7.5188C20.1188 7.5188 21 8.3063 21 9.30005V12.0563C21 13.0313 20.1188 13.8188 19.0312 13.8188ZM19.0312 8.0813C18.2625 8.0813 17.625 8.6438 17.625 9.30005V12.0563C17.625 12.7125 18.2625 13.275 19.0312 13.275C19.8 13.275 20.4375 12.7125 20.4375 12.0563V9.30005C20.4375 8.6438 19.8 8.0813 19.0312 8.0813Z" fill="#37474F"/>
      <path d="M19.2375 9.46875C19.2375 9.65625 19.0875 9.75 18.9 9.75H17.9625C17.775 9.75 17.625 9.65625 17.625 9.46875C17.625 9.28125 17.775 9.1875 17.9625 9.1875H18.9C19.0875 9.1875 19.2375 9.28125 19.2375 9.46875Z" fill="#37474F"/>
      <path d="M19.2375 10.6875C19.2375 10.875 19.0875 11.0625 18.9 11.0625H17.9625C17.775 11.0625 17.625 10.875 17.625 10.6875C17.625 10.5 17.775 10.3125 17.9625 10.3125H18.9C19.0875 10.3125 19.2375 10.5 19.2375 10.6875Z" fill="#37474F"/>
      <path d="M19.2375 11.8125C19.2375 12 19.0875 12.1875 18.9 12.1875H17.9625C17.775 12.1875 17.625 12 17.625 11.8125C17.625 11.625 17.775 11.4375 17.9625 11.4375H18.9C19.0875 11.4375 19.2375 11.625 19.2375 11.8125Z" fill="#37474F"/>
    </svg>
  );
}

export default Seminar;
