import { x } from '@xstyled/styled-components';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { CoverImage } from '../../../groups/components/CoverImage';
import { EventFormData } from './EventForm';

export const NewEventCover: FC = () => {
  const form = useFormContext<EventFormData>();
  const coverImageId = form.watch('coverImageId');

  return (
    <x.div position="relative" mb={4}>
      <CoverImage
        canEditCover
        coverImageId={coverImageId}
        showAddCoverButton
        variant="placeholder"
        buttonInset={6}
        onUploadSuccess={(id) =>
          form.setValue('coverImageId', id, {
            shouldDirty: true,
            shouldValidate: true,
          })
        }
        onRemoveCover={() =>
          form.setValue('coverImageId', null, {
            shouldDirty: true,
          })
        }
      />
    </x.div>
  );
};
