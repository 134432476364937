export const SIGN_IN = 'sign_in';
export const MODAL_VIEW = 'modal_view';
// FORM
export const INPUT_CHANGE = 'input_change';
export const INPUT_FOCUS = 'input_focus';
export const INPUT_BLUR = 'input_blur';
export const FORM_SAVE = 'form_save';
// MENUS
export const FEEDBACK_MENU_OPEN = 'feedback_menu_open';
export const FEEDBACK_MENU_SELECT = 'feedback_menu_select';
export const FEEDBACK_MENU_SHARE = 'feedback_menu_share';
export const USER_MENU_OPEN = 'user_menu_open';
export const USER_MENU_SELECT = 'user_menu_select';
export const EMOJI_PICKER_OPEN = 'emoji_picker_open';
export const EMOJI_PICKER_SELECT = 'emoji_picker_select';
export const AVATAR_PICKER_OPEN = 'avatar_picker_open';
export const AVATAR_PICKER_SELECT = 'avatar_picker_select';
export const APP_SWITCHER_OPEN = 'app_switcher_open';
export const APP_SWITCHER_SELECT = 'app_switcher_select';
// VIDEO
export const AUDIO_INPUT_MENU_OPEN = 'audio_input_menu_open';
export const AUDIO_INPUT_MENU_SELECT = 'audio_input_menu_select';
export const VIDEO_INPUT_MENU_OPEN = 'video_input_menu_open';
export const VIDEO_INPUT_MENU_SELECT = 'video_input_menu_select';
export const VIDEO_PERMISSION_GRANTED = 'video_permission_granted';
export const VIDEO_PERMISSION_DENIED = 'video_permission_denied';
export const VIDEO_PLAY = 'video_play';
export const VIDEO_PAUSE = 'video_pause';
export const VIDEO_RECORD_START = 'video_record_started';
export const VIDEO_RECORD_STOP = 'video_record_stopped';
export const VIDEO_RECORD_PAUSE = 'video_record_paused';
export const VIDEO_RECORD_RERECORD = 'video_record_rerecord';
export const VIDEO_UPLOAD_STARTED = 'video_upload_started';
export const VIDEO_UPLOAD_SUCCESS = 'video_upload_success';
export const VIDEO_UPLOAD_ATTEMPT = 'video_upload_attempt';
export const VIDEO_UPLOAD_ATTEMPT_FAILURE = 'video_upload_attempt_failure';
export const VIDEO_UPLOAD_CHUNK_SUCCESS = 'video_upload_chunk_success';
export const VIDEO_UPLOAD_FAILED = 'video_upload_failed';
export const VIDEO_MODAL_OPEN = 'video_modal_open';
export const VIDEO_MODAL_DISMISS = 'video_modal_dismiss';
export const VIDEO_MOBILE_OPEN = 'video_mobile_open';
export const VIDEO_MOBILE_UPLOAD_STARTED = 'video_mobile_upload_started';
export const VIDEO_MOBILE_UPLOAD_COMPLETE = 'video_mobile_upload_complete';
export const VIDEO_MOBILE_UPLOAD_FAILURE = 'video_mobile_upload_failure';
// LINKS
export const ERROR_REDIRECT = 'error_redirect';
export const NAV_LINK = 'nav_link';
export const TERMS_LINK = 'terms_link';
export const PRIVACY_LINK = 'privacy_link';
export const PROFILE_PAGE_LINK = 'profile_page_link';
export const COMPOSER_LINK = 'composer_link';
export const COMPOSER_TAG = 'composer_tag';
export const HELLO_RESOURCE_LINK = 'hello_resource_link';
export const HELLO_PREVIEW = 'hello_preview';
export const RECORD_LINK = 'record_link';
export const HELLO_SAMPLE_LINK = 'hello_sample_link';
export const UPDATE_LINK = 'update_link';
export const HELLO_LANDING_SEE_EXAMPLE_LINK = 'hello_landing_see_example_link';
export const HELLO_LANDING_FAQ_LINK = 'hello_landing_faq_link';
// BUTTONS
export const SUBMIT_FEEDBACK = 'submit_feedback';
export const COPY_TO_CLIPBOARD = 'copy_to_clipboard';
export const SCROLL_TO = 'scroll_to';
export const CANCEL_NEW_HELLO_PAGE = 'cancel_new_hello_page';
export const HELLO_EDIT_CLOSE = 'hello_edit_close';
export const SETUP_NEXT_STEP = 'setup_next_step';
export const NOTIFY_USER = 'notify_user';
export const NOTIFY_USER_CANCEL = 'notify_user_cancel';
export const COMMENT_NEW = 'comment_new';
export const UPDATE_NEW = 'update_new';
export const UPDATE_EDIT = 'update_edit';
export const UPDATE_DELETE = 'update_delete';
export const UPDATE_SHARE = 'update_share';
export const HELLO_EDIT_DETAILS_OPEN = 'hello_edit_details_open';
export const HELLO_EDIT_DETAILS_CLOSE = 'hello_edit_details_close';
export const HELLO_EDIT_DETAILS_DELETE_PAGE = 'hello_edit_details_delete_page';
export const HELLO_SHARE_OPEN = 'hello_share_open';
export const HELLO_SHARE_DISMISS = 'hello_share_dismiss';
export const HELLO_LANDING_CREATE_A_PAGE = 'hello_landing_create_a_page';
export const HELLO_LANDING_EXAMPLE_MODAL_OPEN =
  'hello_landing_example_modal_open';
export const HELLO_BLOCK_LIBRARY_ADD = 'hello_block_library_add';
