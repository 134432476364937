import * as React from "react";

function SvgFolderAdd(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 7a2 2 0 00-2-2h-8.998c.04 0 .027-.016-.06-.123a12.737 12.737 0 01-.271-.342l-.122-.156C10.833 3.475 10.12 3 9 3H3a2 2 0 00-2 2v14a2 2 0 002 2h11v-2H3v-8h18v4h2V7zm-2 2V7h-9.005c-.718-.004-1.186-.34-1.69-.963l-.236-.303v-.001l-.087-.112C9.607 5.15 9.384 5 9 5H3v4h18zm-4 12v-2h2v-2h2v2h2v2h-2v2h-2v-2h-2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgFolderAdd;
