import { x } from '@xstyled/styled-components';
import React, { FC } from 'react';

import { useNewMemberPostQuery } from '../../../../generated/types-and-hooks';
import { POST_PREVIEW_CARD_WIDTH_PX } from '../../../config/constants';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { Loading } from '../../common/components/Loading';
import { IntroduceYourselfCard } from '../components/IntroduceYourselfCard';
import { PostCard } from '../components/PostCard';

export const NewMemberPostView: FC<{
  showLayout?: boolean;
}> = ({ showLayout = true }) => {
  const { viewer: user } = useViewer();
  const { organization } = useOrganization();
  const { data, loading } = useNewMemberPostQuery({
    variables: {
      organizationId: organization.id,
      userId: user?.id || '',
    },
    skip: !user,
    context: {
      skip: !user,
    },
  });

  if ((loading && !data) || !data) {
    return <Loading />;
  }

  const children = (
    <x.div
      position="relative"
      maxW={POST_PREVIEW_CARD_WIDTH_PX}
      mx="auto"
      boxSizing="border-box"
      w="full"
    >
      {data.newMemberPost ? (
        <PostCard post={data.newMemberPost} />
      ) : (
        <IntroduceYourselfCard variant="feed" />
      )}
    </x.div>
  );

  return showLayout ? <x.div py={8}>{children}</x.div> : children;
};
