import styled from '@xstyled/styled-components';
import { th } from '@xstyled/system';
import React, { useEffect, useRef, useState } from 'react';

// One off pixel values not included in our theme
const TRACK_HEIGHT = 6;
const THUMB_DIAMETER = 10;
const TRANSITION = 'transform 250ms linear';

const Track = styled.div<{ disabled?: boolean }>`
  background-color: ${(p) =>
    p.disabled ? 'white' : th.color('whiteAlpha.200')};
  height: ${TRACK_HEIGHT}px;
  bottom: ${th.size('6')};
  position: absolute;
  cursor: pointer;
  left: ${th.size('8')};
  right: ${th.size('8')};
  border-radius: sm;
  overflow: hidden;
`;

type ProgressAttrs = {
  disabled: boolean;
  scaleFactor: number;
  seeking: boolean;
};

const Progress = styled.div.attrs<ProgressAttrs>(
  ({ scaleFactor, seeking }) => ({
    style: {
      transform: `scaleX(${scaleFactor})`,
      transition: !seeking ? TRANSITION : 'none',
    },
  })
)<ProgressAttrs>`
  height: ${TRACK_HEIGHT}px;
  background-color: ${(p) => (p.disabled ? th.color('brand.300') : 'white')};
  pointer-events: none;
  border-radius: sm;
  position: absolute;
  z-index: overlay;
  width: 100%;
  transform-origin: center left;
`;

type ThumbAttrs = {
  pixelOffset: number;
  seeking: boolean;
};

const Thumb = styled.div.attrs<ThumbAttrs>(({ pixelOffset, seeking }) => ({
  style: {
    transform: `translateX(${pixelOffset}px)`,
    transition: !seeking ? TRANSITION : 'none',
  },
}))<ThumbAttrs>`
  height: ${THUMB_DIAMETER}px;
  width: ${THUMB_DIAMETER}px;
  background: white;
  pointer-events: none;
  border-radius: full;
  position: absolute;
  z-index: overlay;
  top: -${(THUMB_DIAMETER - TRACK_HEIGHT) / 2}px;
  margin-left: -${THUMB_DIAMETER / 2}px;
  transform-origin: center left;
  transform-box: content-box;
`;

export type VideoSeekbarProps = {
  videoRef?: React.RefObject<HTMLVideoElement>;
  active?: boolean;
  progress: number;
  onChange?: (percent: number) => void;
};

type DragAnchor = {
  x: number;
  width: number;
};

export const VideoSeekbar: React.FC<VideoSeekbarProps> = ({
  progress,
  onChange,
}) => {
  const disabled = !onChange;
  const dragAnchor = useRef<DragAnchor | null>(null);
  const trackRef = useRef<HTMLDivElement | null>(null);
  const [seekPercent, setSeekPercent] = useState<number | null>(null);

  /**
   * If we're seeking, clear the next time the progress updates
   */
  useEffect(() => {
    setSeekPercent(null);
  }, [progress]);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!disabled) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);

      const { x, width } = e.currentTarget.getBoundingClientRect();
      dragAnchor.current = { x, width };
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!disabled) {
      const anchor = dragAnchor.current;

      if (!anchor) {
        return;
      }

      const seekDelta = e.pageX - anchor.x;
      const seekPercent = (100 / anchor.width) * seekDelta;
      const boundedSeekPercent = Math.max(0, Math.min(100, seekPercent));

      setSeekPercent(boundedSeekPercent);
      onChange?.(boundedSeekPercent);
    }
  };

  const handleMouseUp = () => {
    if (!disabled) {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
  };

  const progressScaleFactor = (seekPercent || progress) / 100;
  const progressPixelOffset = trackRef.current
    ? progressScaleFactor * trackRef.current.clientWidth
    : 0;

  return (
    <Track
      disabled={disabled}
      ref={trackRef}
      tabIndex={0}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
    >
      <Progress
        disabled={disabled}
        scaleFactor={progressScaleFactor}
        seeking={!!seekPercent}
      />
      {!disabled && (
        <Thumb pixelOffset={progressPixelOffset} seeking={!!seekPercent} />
      )}
    </Track>
  );
};
