import * as React from "react";
import { SVGProps } from "react";
const SvgPremiumColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.533 21.5h-2.667l-1.46 1.82a.466.466 0 0 1-.792-.107L10.721 16.5h6.235l2 4.338a.466.466 0 0 1-.423.662Z"
      fill="#B31523"
    />
    <path
      d="m13.829 16.5-2.942 6.719a.466.466 0 0 1-.79.105L8.633 21.5H5.967a.466.466 0 0 1-.424-.662l2-4.338h6.285Z"
      fill="#CF1928"
    />
    <path
      d="m12.991 2.26 1.059.593 1.213.015a2.024 2.024 0 0 1 1.716.99l.62 1.044 1.043.62c.606.36.982 1.01.99 1.715l.015 1.214.594 1.058a2.026 2.026 0 0 1 0 1.982l-.593 1.058-.015 1.214a2.024 2.024 0 0 1-.991 1.716l-1.043.62-.62 1.042c-.36.607-1.01.982-1.716.991l-1.213.015-1.059.594a2.026 2.026 0 0 1-1.982 0l-1.058-.594-1.214-.015a2.024 2.024 0 0 1-1.716-.99l-.619-1.044-1.043-.62a2.026 2.026 0 0 1-.991-1.715l-.015-1.214-.594-1.058a2.026 2.026 0 0 1 0-1.982l.594-1.058.015-1.214a2.024 2.024 0 0 1 .991-1.716l1.043-.62.62-1.042a2.026 2.026 0 0 1 1.715-.991l1.214-.015 1.058-.594a2.024 2.024 0 0 1 1.982 0Z"
      fill="#FDB700"
    />
    <path
      d="M12 17a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Z"
      fill="url(#premium-color_svg__a)"
    />
    <path
      opacity={0.05}
      d="m16.257 8.506-.466-.466a.83.83 0 0 0-1.173 0l-3.229 3.23-1.582-1.582a.849.849 0 0 0-1.174 0l-.466.465a.825.825 0 0 0-.242.586c0 .222.086.43.243.587l2.634 2.634a.823.823 0 0 0 .587.244c.222 0 .43-.087.587-.243l4.281-4.282a.827.827 0 0 0 0-1.173Z"
      fill="currentColor"
    />
    <path
      opacity={0.07}
      d="m16.08 8.682-.465-.466a.58.58 0 0 0-.82 0l-3.405 3.407-1.76-1.76a.593.593 0 0 0-.82 0l-.466.466a.577.577 0 0 0 0 .819l2.635 2.635a.576.576 0 0 0 .82 0L16.08 9.5a.577.577 0 0 0 0-.819Z"
      fill="currentColor"
    />
    <path
      d="m11.156 13.606-2.635-2.635a.33.33 0 0 1 0-.466l.465-.465a.33.33 0 0 1 .466 0l1.937 1.936 3.583-3.583a.33.33 0 0 1 .466 0l.465.465a.33.33 0 0 1 0 .466l-4.282 4.282a.329.329 0 0 1-.465 0Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="premium-color_svg__a"
        x1={5.5}
        y1={10.5}
        x2={18.5}
        y2={10.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEDE00" />
        <stop offset={1} stopColor="#FFD000" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgPremiumColor;
