import * as React from "react";

function SvgAppInstagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-instagram_svg__clip0)">
        <path
          d="M6.539 23.915c-1.427-.064-2.203-.302-2.718-.503a4.55 4.55 0 01-1.684-1.095c-.513-.512-.83-1-1.095-1.683-.2-.515-.438-1.29-.503-2.718C.467 16.373.453 15.91.453 12c0-3.909.016-4.37.085-5.915.065-1.427.304-2.201.504-2.718a4.565 4.565 0 011.094-1.684c.513-.513 1-.83 1.684-1.095C4.336.388 5.11.15 6.538.085 8.082.015 8.545 0 12.453 0c3.909 0 4.37.016 5.915.085C19.795.15 20.57.39 21.086.59c.684.264 1.17.582 1.684 1.095.513.512.829 1 1.095 1.683.2.516.438 1.291.503 2.718.07 1.545.085 2.007.085 5.916 0 3.907-.014 4.37-.085 5.915-.065 1.427-.304 2.203-.503 2.718a4.54 4.54 0 01-1.095 1.683c-.512.512-1 .829-1.684 1.095-.515.2-1.29.439-2.718.503-1.543.071-2.006.085-5.915.085-3.908 0-4.371-.013-5.914-.085z"
          fill="url(#app-instagram_svg__paint0_radial)"
        />
        <path
          d="M6.539 23.915c-1.427-.064-2.203-.302-2.718-.503a4.55 4.55 0 01-1.684-1.095c-.513-.512-.83-1-1.095-1.683-.2-.515-.438-1.29-.503-2.718C.467 16.373.453 15.91.453 12c0-3.909.016-4.37.085-5.915.065-1.427.304-2.201.504-2.718a4.565 4.565 0 011.094-1.684c.513-.513 1-.83 1.684-1.095C4.336.388 5.11.15 6.538.085 8.082.015 8.545 0 12.453 0c3.909 0 4.37.016 5.915.085C19.795.15 20.57.39 21.086.59c.684.264 1.17.582 1.684 1.095.513.512.829 1 1.095 1.683.2.516.438 1.291.503 2.718.07 1.545.085 2.007.085 5.916 0 3.907-.014 4.37-.085 5.915-.065 1.427-.304 2.203-.503 2.718a4.54 4.54 0 01-1.095 1.683c-.512.512-1 .829-1.684 1.095-.515.2-1.29.439-2.718.503-1.543.071-2.006.085-5.915.085-3.908 0-4.371-.013-5.914-.085z"
          fill="url(#app-instagram_svg__paint1_radial)"
        />
        <path
          d="M9.506 12.051a2.972 2.972 0 115.944 0 2.972 2.972 0 01-5.944 0zm-1.607 0a4.578 4.578 0 109.157 0 4.578 4.578 0 00-9.157 0zm8.268-4.76a1.07 1.07 0 101.07-1.069 1.07 1.07 0 00-1.07 1.07zM8.876 19.31c-.87-.04-1.342-.185-1.656-.307a2.77 2.77 0 01-1.026-.667 2.75 2.75 0 01-.667-1.025c-.122-.313-.267-.786-.306-1.655-.044-.94-.052-1.223-.052-3.603 0-2.382.01-2.663.052-3.604.04-.869.185-1.34.306-1.655.162-.417.355-.714.667-1.026A2.748 2.748 0 017.22 5.1c.314-.122.786-.267 1.656-.307.94-.043 1.222-.051 3.602-.051s2.662.009 3.603.051c.87.04 1.34.186 1.655.307.417.162.714.355 1.026.667.312.312.505.61.667 1.026.122.314.267.786.307 1.655.043.941.052 1.223.052 3.604 0 2.38-.009 2.662-.052 3.603-.04.869-.185 1.342-.307 1.656a2.762 2.762 0 01-.667 1.024 2.77 2.77 0 01-1.026.667c-.313.123-.786.268-1.655.307-.94.043-1.222.052-3.603.052-2.381 0-2.663-.009-3.602-.052zm-.074-16.12c-.95.043-1.598.194-2.164.414a4.376 4.376 0 00-1.58 1.029c-.496.495-.8.992-1.028 1.58-.22.566-.371 1.214-.414 2.163-.044.951-.054 1.255-.054 3.676 0 2.422.01 2.726.054 3.676.043.95.193 1.598.414 2.164a4.359 4.359 0 001.028 1.58c.496.495.993.8 1.58 1.028.567.22 1.215.371 2.164.414.95.044 1.254.054 3.676.054 2.42 0 2.725-.01 3.676-.054.949-.043 1.597-.193 2.164-.414a4.384 4.384 0 001.58-1.028c.495-.496.8-.993 1.028-1.58.22-.566.371-1.215.414-2.164.043-.951.053-1.254.053-3.676 0-2.421-.01-2.725-.053-3.676-.043-.949-.194-1.597-.414-2.164a4.386 4.386 0 00-1.029-1.58 4.363 4.363 0 00-1.579-1.028c-.567-.22-1.215-.371-2.164-.414-.95-.043-1.254-.054-3.675-.054s-2.726.01-3.677.054"
          fill="#fff"
        />
      </g>
      <defs>
        <radialGradient
          id="app-instagram_svg__paint0_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(2.006 23.474) scale(30.4711)"
        >
          <stop offset={0.09} stopColor="#FA8F21" />
          <stop offset={0.78} stopColor="#D82D7E" />
        </radialGradient>
        <radialGradient
          id="app-instagram_svg__paint1_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.077 22.704) scale(26.8148)"
        >
          <stop offset={0.64} stopColor="#8C3AAA" stopOpacity={0} />
          <stop offset={1} stopColor="#8C3AAA" />
        </radialGradient>
        <clipPath id="app-instagram_svg__clip0">
          <path fill="#fff" transform="translate(.453)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppInstagram;
