import * as React from "react";

function SvgStar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.17 14.894l-1.376 8.024L12 19.13l7.206 3.788-1.376-8.024 5.83-5.682-8.057-1.171L12 .74l-3.603 7.3L.341 9.21l5.83 5.683zm9.511-.698l.87 5.066L12 16.87l-4.55 2.392.869-5.066-3.681-3.588 5.086-.74L12 5.26l2.275 4.609 5.087.74-3.68 3.587z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgStar;
