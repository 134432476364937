import { Language } from '../i18n';
import de from '../locales/de/templates.json';
import en from '../locales/en/templates.json';
import es from '../locales/es/templates.json';
import fr from '../locales/fr/templates.json';
import it from '../locales/it/templates.json';
import ja from '../locales/ja/templates.json';
import pt from '../locales/pt/templates.json';

export type CommunityTemplate = {
  id: string;
  icon: string;
  name: {
    [key in Language]: string;
  };
  description: {
    [key in Language]: string;
  };
  referenceIds: string[];
};

const extractAllLocales = (
  key: keyof typeof en
): { [key in Language]: string } => ({
  en: en[key],
  de: de[key],
  es: es[key],
  it: it[key],
  ja: ja[key],
  pt: pt[key],
  fr: fr[key],
});

const groupTemplates = {
  Announcements: '0a3d9939',
  Notes: 'e4ee66e6',
  'New videos': '26e079e0',
  'Off-topic': '4f5388e7',
  Photos: '650c135d',
  Questions: '471914ee',
  Plans: '384fb132',
  Movies: '2eb4391d',
  Funny: '145f5685',
  Ideas: '166bb440',
  'Bug reports': '7154b584',
  Standups: '56e029e0',
};

export const WORK_TEMPLATE: CommunityTemplate = {
  id: 'f5b9eaa5',
  icon: 'team-bonding',
  name: extractAllLocales('template.work.name'),
  description: extractAllLocales('template.work.description'),
  referenceIds: [
    groupTemplates.Standups,
    groupTemplates['Off-topic'],
    groupTemplates.Notes,
  ],
};

const BUILD_TOGETHER_TEMPLATE: CommunityTemplate = {
  id: 'aa7e7f34',
  icon: 'graph-up',
  name: extractAllLocales('template.build_together.name'),
  description: extractAllLocales('template.build_together.description'),
  referenceIds: [
    groupTemplates.Announcements,
    groupTemplates.Ideas,
    groupTemplates['Bug reports'],
    groupTemplates.Questions,
  ],
};

const CONTENT_CREATOR_TEMPLATE: CommunityTemplate = {
  id: 'c20c2013',
  icon: 'creator-community',
  name: extractAllLocales('template.content_creator.name'),
  description: extractAllLocales('template.content_creator.description'),
  referenceIds: [
    groupTemplates.Announcements,
    groupTemplates.Notes,
    groupTemplates['New videos'],
    groupTemplates.Ideas,
  ],
};

const CLUB_OR_ALUMNI_GROUP_TEMPLATE: CommunityTemplate = {
  id: '3ae67494',
  icon: 'sports-club',
  name: extractAllLocales('template.club_or_alumni_group.name'),
  description: extractAllLocales('template.club_or_alumni_group.description'),
  referenceIds: [
    groupTemplates.Announcements,
    groupTemplates.Photos,
    groupTemplates.Plans,
    groupTemplates.Questions,
  ],
};

const PERSONAL_TEMPLATE: CommunityTemplate = {
  id: '63f02d52',
  icon: 'friends-family',
  name: extractAllLocales('template.personal.name'),
  description: extractAllLocales('template.personal.description'),
  referenceIds: [
    groupTemplates.Photos,
    groupTemplates.Plans,
    groupTemplates.Movies,
  ],
};

const START_FROM_SCRATCH_TEMPLATE: CommunityTemplate = {
  id: '2c3a68ff',
  icon: 'start-from-scratch',
  name: extractAllLocales('template.from_scratch.name'),
  description: extractAllLocales('template.from_scratch.description'),
  referenceIds: [],
};

export const COMMUNITY_TEMPLATES: CommunityTemplate[] = [
  CONTENT_CREATOR_TEMPLATE,
  BUILD_TOGETHER_TEMPLATE,
  CLUB_OR_ALUMNI_GROUP_TEMPLATE,
  WORK_TEMPLATE,
  PERSONAL_TEMPLATE,
  START_FROM_SCRATCH_TEMPLATE,
];

export const PERSONAL_COMMUNITY_TEMPLATES = [
  CLUB_OR_ALUMNI_GROUP_TEMPLATE,
  PERSONAL_TEMPLATE,
];
