import { FetchMoreQueryOptions } from '@apollo/client';
import { createContext, PropsWithChildren, useContext } from 'react';

import { UsersQuery } from '../../../../generated/graphql-request-api-sdk';
import {
  MemberSubscriptionPlan,
  OrganizationRoleColor,
  UsersQueryVariables,
  useUsersQuery,
} from '../../../../generated/types-and-hooks';
import { USERS_PER_PAGE } from '../../../config/constants';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { userInOrganization } from '../../auth/utils/permissions';

type OrganizationRole = {
  id: string;
  label: string;
  color: OrganizationRoleColor;
  memberSubscriptionPlan?: Pick<MemberSubscriptionPlan, 'id' | 'name'> | null;
  isAdmin: boolean;
};

type UsersType = {
  data?: UsersQuery;
  rolesByUserId?: { [key: string]: OrganizationRole[] };
  fetchMore?: (
    fetchMoreOptons: FetchMoreQueryOptions<UsersQueryVariables>
  ) => void;
  loading: boolean;
};

const UsersContext = createContext<UsersType>({
  loading: false,
});

export const UsersProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { viewer } = useViewer();
  const { organization } = useOptionalOrganization();

  const { data, loading, fetchMore } = useUsersQuery({
    variables: {
      organizationId: organization?.id || '',
      first: USERS_PER_PAGE,
    },
    skip: !organization || !userInOrganization(viewer, organization),
  });

  const rolesByUserId: {
    [key: string]: OrganizationRole[];
  } = {};

  data?.users.edges.forEach(({ node }) => {
    if (node?.id && node.organizationMembership?.roles) {
      rolesByUserId[node?.id] = node.organizationMembership?.roles;
    }
  });

  return (
    <UsersContext.Provider value={{ data, rolesByUserId, fetchMore, loading }}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = () => useContext(UsersContext);
