import * as React from "react";
import { SVGProps } from "react";
const SvgTeamBonding = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.5 8.334a2.083 2.083 0 1 0 0-4.167 2.083 2.083 0 0 0 0 4.167Z"
      fill="url(#team-bonding_svg__a)"
    />
    <path
      d="M5.5 9.167a3.75 3.75 0 0 0-3.75 3.75v3.75c0 .46.373.833.833.833h5.834c.46 0 .833-.373.833-.833v-3.75a3.75 3.75 0 0 0-3.75-3.75Z"
      fill="url(#team-bonding_svg__b)"
    />
    <path
      d="M15.5 8.334a2.083 2.083 0 1 0 0-4.167 2.083 2.083 0 0 0 0 4.167Z"
      fill="url(#team-bonding_svg__c)"
    />
    <path
      d="M15.5 9.167a3.75 3.75 0 0 0-3.75 3.75v3.75c0 .46.373.833.833.833h5.834c.46 0 .833-.373.833-.833v-3.75a3.75 3.75 0 0 0-3.75-3.75Z"
      fill="url(#team-bonding_svg__d)"
    />
    <path
      d="M10.5 6.667a2.083 2.083 0 1 0 0-4.167 2.083 2.083 0 0 0 0 4.167Z"
      fill="url(#team-bonding_svg__e)"
    />
    <path
      opacity={0.05}
      d="M15.083 16.667V11.25c0-.709-.166-1.378-.455-1.978a3.749 3.749 0 0 0-2.878 3.645v3.75c0 .46.373.833.833.833h2.269a1.65 1.65 0 0 0 .231-.833Z"
      fill="#333"
    />
    <path
      opacity={0.067}
      d="M14.805 16.667V11.25c0-.675-.16-1.312-.438-1.882a3.714 3.714 0 0 0-2.617 3.549v3.75c0 .46.373.833.833.833h1.79c.25-.164.432-.476.432-.833Z"
      fill="#333"
    />
    <path
      opacity={0.09}
      d="M14.528 16.666V11.25c0-.643-.155-1.248-.423-1.787a3.708 3.708 0 0 0-2.355 3.453v3.75c0 .46.373.834.833.834h1.312c.355-.082.633-.425.633-.834Z"
      fill="#333"
    />
    <path
      opacity={0.05}
      d="M5.916 16.667V11.25c0-.709.166-1.378.455-1.978a3.749 3.749 0 0 1 2.878 3.645v3.75c0 .46-.373.833-.833.833H6.148a1.65 1.65 0 0 1-.232-.833Z"
      fill="#333"
    />
    <path
      opacity={0.067}
      d="M6.195 16.667V11.25c0-.675.16-1.312.44-1.882a3.714 3.714 0 0 1 2.616 3.549v3.75c0 .46-.373.833-.834.833h-1.79a1.004 1.004 0 0 1-.432-.833Z"
      fill="#333"
    />
    <path
      opacity={0.09}
      d="M6.473 16.666V11.25c0-.643.154-1.248.422-1.787a3.708 3.708 0 0 1 2.356 3.453v3.75c0 .46-.373.834-.834.834H7.106c-.355-.082-.633-.425-.633-.834Z"
      fill="#333"
    />
    <path
      d="M10.5 7.5a3.75 3.75 0 0 0-3.75 3.75v5.417c0 .46.373.833.833.833h5.834c.46 0 .833-.373.833-.833V11.25A3.75 3.75 0 0 0 10.5 7.5Z"
      fill="url(#team-bonding_svg__f)"
    />
    <defs>
      <linearGradient
        id="team-bonding_svg__a"
        x1={4.086}
        y1={4.837}
        x2={6.993}
        y2={7.744}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#199AE0" />
        <stop offset={1} stopColor="#0782D8" />
      </linearGradient>
      <linearGradient
        id="team-bonding_svg__b"
        x1={2.346}
        y1={11.034}
        x2={8.832}
        y2={17.52}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#199AE0" />
        <stop offset={1} stopColor="#0782D8" />
      </linearGradient>
      <linearGradient
        id="team-bonding_svg__c"
        x1={14.086}
        y1={4.837}
        x2={16.993}
        y2={7.744}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0D61A9" />
        <stop offset={1} stopColor="#16528C" />
      </linearGradient>
      <linearGradient
        id="team-bonding_svg__d"
        x1={12.346}
        y1={11.034}
        x2={18.832}
        y2={17.52}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0D61A9" />
        <stop offset={1} stopColor="#16528C" />
      </linearGradient>
      <linearGradient
        id="team-bonding_svg__e"
        x1={9.086}
        y1={3.17}
        x2={11.993}
        y2={6.077}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32BDEF" />
        <stop offset={1} stopColor="#1EA2E4" />
      </linearGradient>
      <linearGradient
        id="team-bonding_svg__f"
        x1={9.325}
        y1={7.94}
        x2={11.418}
        y2={18.4}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32BDEF" />
        <stop offset={1} stopColor="#1EA2E4" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgTeamBonding;
