import { x } from '@xstyled/styled-components';
import React, { useEffect } from 'react';

import {
  Resource,
  useUnfurlUrlMutation,
} from '../../../../../generated/types-and-hooks';
import { Link } from '../Link';
import { LoadingSpinner } from '../LoadingSpinner';
import { Text } from '../Text';

export const CommentComposerResourceLoader = ({
  url,
  onProcessed,
}: {
  url: string;
  onProcessed: (resource: Resource) => void;
}) => {
  const [unfurlUrl] = useUnfurlUrlMutation({
    onCompleted(data) {
      if ('id' in data.unfurlUrl) {
        onProcessed(data.unfurlUrl);
      }
    },
  });

  useEffect(() => {
    unfurlUrl({
      variables: {
        input: {
          url,
        },
      },
    });
  }, [unfurlUrl, url]);

  return (
    <div>
      <Link
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        styleInheritColor
        position="relative"
      >
        <x.div
          display="flex"
          bg="gray.50"
          spaceX={2}
          alignItems="center"
          borderRadius="md-lg"
          p={4}
          border={1}
          borderColor={{
            _: 'transparent',
            hover: 'brand.300',
          }}
          transition
          transitionDuration="fast"
          transitionTimingFunction="ease-out"
        >
          <x.div>
            <LoadingSpinner size="4" color="gray.400" />
          </x.div>

          <x.div minWidth="0">
            <Text variant="md-semibold" truncate color="gray.400">
              {url}
            </Text>
          </x.div>
        </x.div>
      </Link>
    </div>
  );
};
