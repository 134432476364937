import * as React from "react";
import { SVGProps } from "react";
const SvgPostCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.208 1.667a1.885 1.885 0 0 0-1.875 1.875v12.916c0 1.028.847 1.875 1.875 1.875h9.584a1.885 1.885 0 0 0 1.875-1.875V3.542a1.885 1.885 0 0 0-1.875-1.875H5.208Zm0 1.25h9.584c.352 0 .625.272.625.625v12.916a.616.616 0 0 1-.625.625H5.208a.616.616 0 0 1-.625-.625V3.542c0-.353.273-.625.625-.625ZM6.458 5a.625.625 0 1 0 0 1.25h7.084a.625.625 0 1 0 0-1.25H6.458Zm0 2.917a.625.625 0 1 0 0 1.25h7.084a.625.625 0 1 0 0-1.25H6.458Zm0 2.916a.624.624 0 1 0 0 1.25h7.084a.628.628 0 0 0 .585-.384.626.626 0 0 0-.585-.866H6.458Zm0 2.917a.626.626 0 1 0 0 1.25h7.084a.624.624 0 1 0 0-1.25H6.458Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPostCard;
