import { useSelector } from '@xstate/react';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

import {
  AddMemberPolicy,
  useOrganizationByInviteCodeQuery,
} from '../../../../generated/types-and-hooks';
import { useAuth } from '../../auth/hooks/useAuth';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { ErrorPage } from '../../auth/views/ErrorPage';
import { Loading } from '../../common/components/Loading';
import { AppContext } from '../../common/machine/appContext';
import { RequestToJoinView } from '../../request-to-join/RequestToJoinView';
import { JoinCommunity } from '../components/JoinCommunity';

/**
 * Full screen component used for invites
 */
export const InvitesView = () => {
  const router = useRouter();
  const { isAuthenticated, isLoading } = useAuth();
  const { viewer, isViewerLoading } = useViewer();
  const { organization, setOrganization } = useOptionalOrganization();

  const { appService } = useContext(AppContext);
  const inviteCode = useSelector(
    appService,
    (state) => state.context.inviteCode
  );

  const { data, loading } = useOrganizationByInviteCodeQuery({
    variables: {
      inviteCode: inviteCode || '',
    },
    skip: !inviteCode,
  });

  const hasJoined = !!viewer?.organizationMemberships?.find(
    (organizationMembership) =>
      organization?.id === organizationMembership.organization.id
  );

  // Handle community joined states
  useEffect(() => {
    if (organization && hasJoined && router.isReady) {
      if (router.query.invite) {
        const newQueries = router.query;
        delete newQueries.invite;
        router.replace({ pathname: router.pathname, query: newQueries });
      }
    }
  }, [hasJoined, router, organization, setOrganization]);

  if (
    hasJoined ||
    isViewerLoading ||
    (isAuthenticated && isLoading) ||
    loading ||
    !organization
  ) {
    return <Loading />;
  }

  // Show request to join when add member policy is set to request to join and query to
  // find organization by invite code does not return an organization, e.g. with an organization
  // invite code
  if (
    !data?.organizationByInviteCode &&
    organization?.addMemberPolicy === AddMemberPolicy.RequestToJoin
  ) {
    return <RequestToJoinView />;
  }

  if (!data?.organizationByInviteCode && inviteCode) {
    return (
      <ErrorPage
        emojiUnicode={'⌛'}
        errorCode="Link expired"
        errorMessage="This link has expired. Ask the person who sent the link for a new one!"
      />
    );
  }

  return <JoinCommunity organization={organization} inviteCode={inviteCode} />;
};
InvitesView.displayName = 'InvitesView';
