import * as React from 'react';
import { SVGProps } from 'react';

const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M11.976 2a.75.75 0 0 0-.44.162L4.43 7.76A3.752 3.752 0 0 0 3 10.706v9.545c0 .681.568 1.25 1.25 1.25h15.5c.682 0 1.25-.569 1.25-1.25v-9.545a3.752 3.752 0 0 0-1.43-2.946l-7.106-5.598A.75.75 0 0 0 11.976 2ZM12 3.707l6.643 5.233c.542.427.857 1.077.857 1.767V20h-15v-9.295c0-.69.315-1.34.857-1.767L12 3.706ZM7.75 10a.749.749 0 0 0-.537 1.284.75.75 0 0 0 .537.216h8.5a.751.751 0 1 0 0-1.5h-8.5Zm0 3a.75.75 0 1 0 0 1.5h8.5a.751.751 0 1 0 0-1.5h-8.5Zm0 3a.75.75 0 1 0 0 1.5h8.5a.751.751 0 1 0 0-1.5h-8.5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgHome;
