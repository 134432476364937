import * as React from "react";
import type { SVGProps } from "react";
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="url(#check_svg__a)"
      d="M22 12c0 5.523-4.477 10-10 10-5.522 0-10-4.477-10-10C2 6.478 6.478 2 12 2c5.523 0 10 4.478 10 10"
    />
    <path
      fill="#000"
      d="M16.086 8.086 11 13.172l-2.586-2.586a1 1 0 0 0-1.414 0l-.707.707a1 1 0 0 0 0 1.414l4 4a1 1 0 0 0 1.414 0l6.5-6.5a1 1 0 0 0 0-1.414l-.707-.707a1 1 0 0 0-1.414 0"
      opacity={0.05}
    />
    <path
      fill="#000"
      d="m10.47 16.53-4-4a.75.75 0 0 1 0-1.06l.707-.707a.75.75 0 0 1 1.06 0L11 13.525l5.262-5.262a.75.75 0 0 1 1.061 0l.707.707a.75.75 0 0 1 0 1.06l-6.5 6.5a.75.75 0 0 1-1.06 0"
      opacity={0.07}
    />
    <path
      fill="#fff"
      d="m10.647 16.354-4-4a.5.5 0 0 1 0-.707l.707-.707a.5.5 0 0 1 .706 0L11 13.879l5.44-5.44a.5.5 0 0 1 .706 0l.707.707a.5.5 0 0 1 0 .707l-6.5 6.5a.5.5 0 0 1-.707 0"
    />
    <defs>
      <linearGradient
        id="check_svg__a"
        x1={4.929}
        x2={19.071}
        y1={4.929}
        y2={19.071}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#21AD64" />
        <stop offset={1} stopColor="#088242" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgCheck;
