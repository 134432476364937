import * as React from "react";
import { SVGProps } from "react";
const SvgCreatorCommunity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#creator-community_svg__a)">
      <path
        d="M17.158 10.833h-.013a.417.417 0 0 1-.404-.428 6.25 6.25 0 0 0-6.24-6.447 6.25 6.25 0 0 0-6.24 6.447.417.417 0 0 1-.406.428c-.22-.01-.421-.174-.428-.405A7.085 7.085 0 0 1 10.5 3.125a7.085 7.085 0 0 1 7.074 7.303.417.417 0 0 1-.416.405Z"
        fill="#ABB4BE"
      />
      <path
        d="M18.209 4.166H2.376a.417.417 0 1 1 0-.833h15.833a.417.417 0 1 1 0 .833Z"
        fill="#ABB4BE"
      />
      <path
        d="M11.75 5.834h-2.5A.836.836 0 0 1 8.415 5V2.5c0-.458.375-.833.833-.833h2.5c.459 0 .834.375.834.833V5a.836.836 0 0 1-.834.834Z"
        fill="url(#creator-community_svg__b)"
      />
      <path
        d="M18.417 14.584h-2.5a.836.836 0 0 1-.833-.834v-2.5c0-.458.375-.833.833-.833h2.5c.459 0 .834.375.834.833v2.5a.836.836 0 0 1-.834.834Z"
        fill="url(#creator-community_svg__c)"
      />
      <path
        d="M5.083 14.584h-2.5a.836.836 0 0 1-.833-.834v-2.5c0-.458.375-.833.833-.833h2.5c.459 0 .834.375.834.833v2.5a.836.836 0 0 1-.834.834Z"
        fill="url(#creator-community_svg__d)"
      />
      <path
        d="M18.834 5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
        fill="url(#creator-community_svg__e)"
      />
      <path
        d="M2.166 5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
        fill="url(#creator-community_svg__f)"
      />
      <path
        d="M11.75 16.042h-2.5c0-1.683-1.49-2.87-1.504-2.88a.418.418 0 0 1-.121-.523l2.502-4.911a.418.418 0 0 1 .743 0l2.5 4.912c.093.18.041.4-.121.522-.013.01-1.5 1.197-1.5 2.88Z"
        fill="url(#creator-community_svg__g)"
      />
      <path
        d="M10.916 12.199V7.917a.417.417 0 0 0-.833 0v4.282a.83.83 0 0 0 .416 1.551.83.83 0 0 0 .417-1.551Z"
        fill="url(#creator-community_svg__h)"
      />
      <path
        opacity={0.05}
        d="M9.182 15.417c.043.2.068.408.068.625h2.5c0-.217.026-.426.07-.625H9.181Z"
        fill="#333"
      />
      <path
        opacity={0.05}
        d="M9.217 15.625c.02.135.034.273.034.417h2.5c0-.144.014-.282.034-.417H9.217Z"
        fill="#333"
      />
      <path
        d="M11.813 15.833H9.186a.416.416 0 0 0-.411.348l-.359 2.152h4.167l-.36-2.152a.416.416 0 0 0-.41-.348Z"
        fill="url(#creator-community_svg__i)"
      />
    </g>
    <defs>
      <linearGradient
        id="creator-community_svg__b"
        x1={9.91}
        y1={1.551}
        x2={11.019}
        y2={5.692}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2AA4F4" />
        <stop offset={1} stopColor="#007AD9" />
      </linearGradient>
      <linearGradient
        id="creator-community_svg__c"
        x1={16.578}
        y1={10.301}
        x2={17.687}
        y2={14.442}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2AA4F4" />
        <stop offset={1} stopColor="#007AD9" />
      </linearGradient>
      <linearGradient
        id="creator-community_svg__d"
        x1={3.244}
        y1={10.301}
        x2={4.353}
        y2={14.442}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2AA4F4" />
        <stop offset={1} stopColor="#007AD9" />
      </linearGradient>
      <linearGradient
        id="creator-community_svg__e"
        x1={18.005}
        y1={2.921}
        x2={19.756}
        y2={4.672}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#33BEF0" />
        <stop offset={1} stopColor="#0A85D9" />
      </linearGradient>
      <linearGradient
        id="creator-community_svg__f"
        x1={1.337}
        y1={2.921}
        x2={3.088}
        y2={4.672}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#33BEF0" />
        <stop offset={1} stopColor="#0A85D9" />
      </linearGradient>
      <linearGradient
        id="creator-community_svg__g"
        x1={-1.37}
        y1={11.771}
        x2={13.695}
        y2={11.771}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E36001" />
        <stop offset={1} stopColor="#FED100" />
      </linearGradient>
      <linearGradient
        id="creator-community_svg__h"
        x1={10.499}
        y1={13.75}
        x2={10.499}
        y2={0.647}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C18310" />
        <stop offset={1} stopColor="#EBA601" />
      </linearGradient>
      <linearGradient
        id="creator-community_svg__i"
        x1={8.982}
        y1={15.864}
        x2={12.017}
        y2={18.899}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F44F5A" />
        <stop offset={0.443} stopColor="#EE3D4A" />
        <stop offset={1} stopColor="#E52030" />
      </linearGradient>
      <clipPath id="creator-community_svg__a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCreatorCommunity;
