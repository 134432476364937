import { createContext, PropsWithChildren, useContext, useEffect } from 'react';

import {
  DirectMessageTokenQuery,
  useDirectMessageTokenQuery,
} from '../../../../generated/types-and-hooks';
import { AppContext } from '../../common/machine/appContext';
import { useChatClient } from '../../messages/components/hooks/useChatClient';
import { userInOrganization } from '../utils/permissions';
import { useOptionalOrganization } from './useOptionalOrganization';
import { useViewer } from './useViewer';

type DirectMessageTokenContextType = {
  directMessageToken?: DirectMessageTokenQuery['directMessageToken'];
  refetch: () => Promise<void>;
};

const DirectMessageTokenContext = createContext<DirectMessageTokenContextType>({
  refetch: async () => {},
});

const DirectMessageClientProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  // Update chat client in machine when it is initialized
  const { appService } = useContext(AppContext);
  const chatClient = useChatClient();
  useEffect(() => {
    if (chatClient) {
      appService.send({
        type: 'SET_CHAT_CLIENT',
        chatClient,
      });
    }
  }, [chatClient, appService]);

  return <>{children}</>;
};

export const DirectMessageTokenProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { organization } = useOptionalOrganization();
  const { viewer } = useViewer();

  const skip =
    !organization ||
    !viewer ||
    (organization && viewer && !userInOrganization(viewer, organization));

  const { data, refetch } = useDirectMessageTokenQuery({
    variables: {
      organizationId: organization?.id || '',
    },
    skip,
    context: { skip },
  });

  const refetchWhenInOrganization = async () => {
    if (organization && userInOrganization(viewer, organization)) {
      refetch({
        organizationId: organization.id,
      });
    }
  };

  return (
    <DirectMessageTokenContext.Provider
      value={{
        directMessageToken: data?.directMessageToken,
        refetch: refetchWhenInOrganization,
      }}
    >
      <DirectMessageClientProvider>{children}</DirectMessageClientProvider>
    </DirectMessageTokenContext.Provider>
  );
};

export const useDirectMessageToken = () =>
  useContext(DirectMessageTokenContext);
