import { useCallback } from 'react';

export const CONTENT_SCROLL_ID = 'scrollable-content';

/**
 * Used to get the main content scroll position
 * For this to work the scroll id must be bound to layout scrollview
 */
export const useContentScroll = () => {
  const getScrollTop = useCallback(
    () => document.getElementById(CONTENT_SCROLL_ID)?.scrollTop,
    []
  );

  const scrollToTop = useCallback(
    () =>
      document
        .getElementById(CONTENT_SCROLL_ID)
        ?.scrollTo({ top: 0, behavior: 'smooth' }),
    []
  );

  return {
    contentScrollId: CONTENT_SCROLL_ID,
    getScrollTop,
    scrollToTop,
  };
};
