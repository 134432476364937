import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { Trans, useTranslation } from 'next-i18next';

import { FeatureEntitlement } from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useIsViewerAdmin } from '../../auth/hooks/useViewer';
import { GroupsModal } from '../../groups/components/GroupsModal';
import { Button } from './Button';
import { Heading } from './Heading';
import { Text } from './Text';

export const UpsellModal: React.FC<{
  feature: FeatureEntitlement;
  onDismiss: () => void;
  isOpen: boolean;
}> = ({ feature, onDismiss, isOpen }) => {
  const { organization } = useOrganization();
  const isViewerAdmin = useIsViewerAdmin(organization);
  const { t } = useTranslation('common');

  const featureToTitle = {
    [FeatureEntitlement.Polls]: t('polls'),
    [FeatureEntitlement.CustomRoles]: t('roles'),
    [FeatureEntitlement.Insights]: t('insights'),
    [FeatureEntitlement.Courses]: t('courses'),
    [FeatureEntitlement.Events]: t('events'),
    [FeatureEntitlement.CustomDomains]: t('custom_domain'),
  }[feature];

  const { title, description, buttonLabel, buttonUrl } = {
    admin: {
      title: t('upgrade_community'),
      description: (
        <Trans
          i18nKey="upgrade_feature_as_admin"
          t={t}
          values={{
            feature: featureToTitle,
          }}
          components={{
            strong: <Text as="span" variant="md-medium" />,
          }}
        />
      ),
      buttonLabel: t('view_plans'),
      buttonUrl: routes.settings.organization(organization.shortId).plans(),
    },
    member: {
      title: t('require_an_upgrade', {
        feature: featureToTitle,
      }),
      description: (
        <Trans
          i18nKey="upgrade_feature_as_member"
          t={t}
          values={{
            feature: featureToTitle,
          }}
          components={{
            strong: <Text as="span" variant="md-medium" />,
          }}
        />
      ),
      buttonLabel: t('send_message'),
      buttonUrl: routes.groups.organization(organization.shortId).messages(),
    },
  }[isViewerAdmin ? 'admin' : 'member'];

  return (
    <GroupsModal
      isOpen={isOpen}
      ariaLabel={title}
      onDismiss={onDismiss}
      showClose={false}
    >
      <x.div display="flex" flexDirection="column" alignItems="center" p="4 2">
        <Heading variant="lg" mb={3}>
          {title}
        </Heading>
        <Text variant="md" mb={6}>
          {description}
        </Text>
        <x.div display="flex" spaceX={2}>
          <Button variant="secondary" label={t('close')} onClick={onDismiss} />
          <Button label={buttonLabel} href={buttonUrl} />
        </x.div>
      </x.div>
    </GroupsModal>
  );
};
