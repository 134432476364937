import * as React from "react";
import { SVGProps } from "react";
const SvgEarth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 1.667c-4.595 0-8.333 3.738-8.333 8.333 0 4.595 3.738 8.333 8.333 8.333 4.595 0 8.333-3.738 8.333-8.333 0-4.595-3.738-8.333-8.333-8.333Zm0 1.25c.305 0 .615.127.955.44.34.313.686.81.981 1.453.238.517.413 1.18.573 1.857H7.49c.16-.677.335-1.34.573-1.857.295-.642.64-1.14.98-1.453.34-.313.65-.44.956-.44Zm-2.593.494c-.172.271-.334.561-.48.878-.316.69-.554 1.507-.739 2.378h-2.44a7.08 7.08 0 0 1 3.66-3.256Zm5.186 0a7.08 7.08 0 0 1 3.659 3.256h-2.44c-.185-.871-.423-1.688-.74-2.378a7.32 7.32 0 0 0-.48-.878ZM3.228 7.917h2.797c-.085.68-.192 1.346-.192 2.083 0 .737.107 1.403.192 2.083H3.228a7.107 7.107 0 0 1 0-4.166Zm4.05 0h5.444c.093.672.195 1.342.195 2.083 0 .741-.102 1.411-.195 2.083H7.278c-.093-.672-.195-1.342-.195-2.083 0-.741.102-1.411.195-2.083Zm6.697 0h2.797a7.105 7.105 0 0 1 0 4.166h-2.797c.085-.68.192-1.346.192-2.083 0-.737-.107-1.403-.192-2.083ZM3.748 13.333h2.44c.185.871.423 1.688.74 2.378.145.317.307.607.48.878a7.08 7.08 0 0 1-3.66-3.256Zm3.743 0h5.018c-.16.677-.335 1.34-.573 1.857-.295.642-.64 1.14-.98 1.453-.34.313-.65.44-.956.44-.305 0-.615-.127-.955-.44-.34-.313-.686-.81-.981-1.453-.238-.517-.413-1.18-.573-1.857Zm6.32 0h2.44a7.08 7.08 0 0 1-3.658 3.256c.172-.271.334-.561.48-.878.316-.69.554-1.507.739-2.378Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEarth;
