import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import { IS_DEMO } from '../../../../config/constants';
import { useViewer } from '../../../auth/hooks/useViewer';
import { useSignOut } from '../../../common/hooks/useApollo';
import { FeedbackWidget } from '../../../common/layout/FeedbackWidget';
import { RestrictedDemoActionModal } from '../../../demo/RestrictedDemoActionModal';
import {
  GroupsSidebarLinkList,
  GroupsSidebarNavigationLink,
} from './GroupsSidebarLink';
import { GroupsSwitchCommunityButton } from './GroupsSwitchCommunityButton';

export const GroupsSidebarMobileMenu: React.FC = () => {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const signOut = useSignOut();
  const [showRestrictedDemoActionModal, setShowRestrictedDemoActionModal] =
    useState(false);
  const [feedback, setFeedback] = useState(false);

  return (
    <x.div
      display={{ _: 'block', sm: 'none' }}
      backgroundColor="gray.100"
      p={6}
      mt={11}
    >
      <GroupsSidebarLinkList variant="grid">
        <GroupsSidebarNavigationLink
          name={t('feedback')}
          icon="message"
          selectedIcon="message-filled"
          onClick={() => setFeedback(true)}
        />
        <GroupsSidebarNavigationLink
          name={t('faq')}
          icon="circle-question-mark"
          selectedIcon="circle-question-mark-filled"
          onClick={() => window.open(routes.marketing.faq(), '_blank')}
        />
        {viewer ? (
          <GroupsSidebarNavigationLink
            name={t('account')}
            icon="profile"
            selectedIcon="profile-filled"
            route={routes.settings.account()}
          />
        ) : null}
        <GroupsSidebarNavigationLink
          name={t('sign_out')}
          icon="sign-in"
          selectedIcon="sign-in-filled"
          onClick={() => {
            if (IS_DEMO) {
              setShowRestrictedDemoActionModal(true);
            }

            signOut('/');
          }}
        />
        {IS_DEMO && (
          <RestrictedDemoActionModal
            onDismiss={() => setShowRestrictedDemoActionModal(false)}
            isOpen={showRestrictedDemoActionModal}
          />
        )}
        <FeedbackWidget
          isOpen={feedback}
          variant="full"
          onDismiss={() => setFeedback(false)}
        />
      </GroupsSidebarLinkList>
      <x.div mt={{ _: 3, sm: 6 }}>
        <GroupsSwitchCommunityButton />
      </x.div>
    </x.div>
  );
};
