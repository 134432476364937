import { theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';

import { PostCardButton } from './PostCardMedia';

type PostCardMediaCarouselControlsProps = {
  dots: number;
  carouselIndex: number;
  onClick?: (index: number) => void;
  onPrevClick: () => void;
  onNextClick: () => void;
  onCloseClick?: () => void;
  variant?: 'fullscreen' | 'composer' | 'default';
};
export const PostCardMediaCarouselControls = ({
  dots,
  carouselIndex,
  onClick,
  onPrevClick,
  onNextClick,
  onCloseClick,
  variant = 'default',
}: PostCardMediaCarouselControlsProps) => {
  const closeButton = (
    <x.div position="absolute" top={0} right={0} padding={5}>
      <PostCardButton
        iconName="close"
        iconSize="5"
        variant={variant}
        onClick={onCloseClick}
      />
    </x.div>
  );

  if (dots <= 1) {
    return variant === 'fullscreen' ? closeButton : null;
  }

  return (
    <>
      {/* Arrow controls */}
      <x.div
        display="flex"
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        justifyContent="space-between"
        alignItems="center"
        pointerEvents="none"
        boxSizing="border-box"
        px={variant === 'fullscreen' ? 5 : 0}
        mx={variant === 'default' ? -3 : 0}
      >
        <x.div pointerEvents="auto">
          {variant === 'fullscreen' && closeButton}
          {carouselIndex > 0 && (
            <PostCardButton
              iconName="chevron-left"
              iconSize="5"
              onClick={onPrevClick}
              variant={variant}
            />
          )}
        </x.div>
        <x.div pointerEvents="auto">
          {carouselIndex < dots - 1 && (
            <PostCardButton
              iconName="chevron-right"
              iconSize="5"
              onClick={onNextClick}
              variant={variant}
            />
          )}
        </x.div>
      </x.div>
      {/* Carousel dots */}
      {variant !== 'fullscreen' && (
        <x.div
          position="absolute"
          w="full"
          display="flex"
          justifyContent="center"
          bottom={variant === 'composer' ? 28 : variant === 'default' ? 16 : 20}
          pointerEvents="none"
          alignItems="center"
        >
          <x.div
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            pointerEvents="auto"
            spaceX={1}
            py={1}
            px={2}
            // One-off bg color
            bg="rgba(51, 51, 51, 0.6)"
            borderRadius="sm"
          >
            {[...new Array(dots)].map((_, i) => {
              return (
                <x.button
                  border="none"
                  p={0}
                  onClick={() => {
                    onClick?.(i);
                  }}
                  type="button"
                  cursor="pointer"
                  boxSizing="border-box"
                  w={theme.sizes['1.5']}
                  h={theme.sizes['1.5']}
                  bg={{
                    _: i === carouselIndex ? 'white' : 'whiteAlpha.300',
                    hover: i === carouselIndex ? undefined : 'whiteAlpha.500',
                  }}
                  borderRadius="full"
                  key={i}
                />
              );
            })}
          </x.div>
        </x.div>
      )}
    </>
  );
};
