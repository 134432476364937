import * as React from "react";

function HelloTemplateScratch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.4 1.6H5.6A1.6 1.6 0 004 3.2v17.6a1.6 1.6 0 001.6 1.6h12.8a1.6 1.6 0 001.6-1.6V3.2a1.6 1.6 0 00-1.6-1.6zm-3.2 7.2H8.8a.8.8 0 010-1.6h6.4a.8.8 0 010 1.6zm0 4H8.8a.8.8 0 010-1.6h6.4a.8.8 0 010 1.6zm0 4H8.8a.8.8 0 010-1.6h6.4a.8.8 0 010 1.6z"
        fill="currentColor"
      />
    </svg>
  );
}

export default HelloTemplateScratch;
