import styled, { system, SystemProps } from '@xstyled/styled-components';

/**
 * Utility component to center children in nearest relative positioned parent
 */
export const Centered = styled.div<SystemProps>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${system};
`;
