import { getUsersText } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { PropsWithChildren } from 'react';

import { useOrganizationActiveMembersQuery } from '../../../../generated/types-and-hooks';
import { FrondLogo } from '../../common/components/FrondLogo';
import { Skeleton } from '../../common/components/Skeleton';
import { Text } from '../../common/components/Text';
import { Users } from '../../common/components/Users';
import { AppMachineOrganization } from '../../common/machine/appMachine';
import { OrganizationDetailsCard } from '../../organizations/components/OrganizationDetailsCard';

export const InviteCommunityLayout: React.FC<
  PropsWithChildren<{
    title: JSX.Element;
    organization: NonNullable<AppMachineOrganization>;
  }>
> = ({ title, organization, children }) => {
  const { data, loading } = useOrganizationActiveMembersQuery({
    variables: {
      organizationId: organization.id,
    },
  });
  const { t } = useTranslation('common');

  if ((loading && !data?.organization) || !data?.organization) {
    return <Skeleton width="100%" height="100%" />;
  }

  // Text shown below avatars
  const { notificationText: names } = getUsersText(
    data.organization.activeMembers,
    5,
    {
      and: t('auth.and'),
      other: t('auth.other'),
      others: t('auth.others'),
    }
  );

  const multipleMembers = data.organization.activeMembers.length > 1;
  const activeMembersText = multipleMembers
    ? t('auth.users_are_already_members', { names })
    : t('auth.user_is_already_a_member', { name: names });

  return (
    <x.div
      maxWidth="480px"
      mx="auto"
      py={10}
      px={4}
      display={{ _: 'flex', sm: 'block' }}
      flexDirection="column"
      h={{ _: 'full', sm: 'auto' }}
    >
      <x.div flex={1} display="flex" flexDirection="column" h="full">
        <x.div
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyItems="center"
          textAlign="center"
          mb={12}
        >
          <x.div w="81px" mb={8}>
            <FrondLogo />
          </x.div>
          {title}
          <Text variant="md">{t('auth.frond_description')}</Text>
        </x.div>
        <x.div boxSizing="border-box" maxW="480px" w="full" mb={12}>
          <OrganizationDetailsCard organization={organization}>
            {data.organization.activeMembers && (
              <x.div>
                <x.div mb={3}>
                  <Users
                    users={data.organization.activeMembers || []}
                    variant="medium"
                    showLink={false}
                  />
                </x.div>
                <Text color="gray.300" variant="sm">
                  {activeMembersText}
                </Text>
              </x.div>
            )}
          </OrganizationDetailsCard>
        </x.div>
        <x.div textAlign="center" w="full">
          {children}
        </x.div>
      </x.div>
    </x.div>
  );
};
