import * as React from "react";

function SvgCameraFilled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 5H3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2v-1.382l6 3V5.382l-6 3V7a2 2 0 00-2-2zm2 5.618v2.764l4 2V8.618l-4 2z"
        fill="currentColor"
      />
      <path d="M17 9l4-.422V15.5l-4-.5V9z" fill="currentColor" />
    </svg>
  );
}

export default SvgCameraFilled;
