import {
  comboboxStore,
  TComboboxItemBase,
  TComboboxItemWithData,
} from '@udecode/plate-combobox';
import { ComboboxItemProps } from '@udecode/plate-ui-combobox';
import { MentionCombobox } from '@udecode/plate-ui-mention';
import styled, { css, x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';

import { getUserDisplayName } from '../../utils/user';
import { Avatar } from '../Avatar';
import { Text } from '../Text';
import { useGroupSearchMentions } from './hooks/useSearchMentions';

type StyledButtonProps = {
  selected: boolean;
};

const hoverMixin = css`
  background-color: brand.50;
`;

const StyledButton = styled.button<StyledButtonProps>`
  outline: none;
  border-radius: sm;
  background: white;
  display: flex;
  width: 100%;
  padding: '1 1.5';
  align-items: center;
  border: 1px solid transparent;

  > * + * {
    margin-left: 2;
  }

  ${({ selected }) => selected && hoverMixin}

  &:hover {
    ${hoverMixin}
  }
`;

export type MentionDropdownItemProps = {
  avatar?: {
    profileImageId?: string | null;
    avatar: string;
    avatarBgColor: string;
  };
  type: string;
  notifyText?: string;
};

export const MentionDropdownItem: React.FC<
  ComboboxItemProps<MentionDropdownItemProps>
> = ({ item }) => {
  const items = comboboxStore.use.filteredItems();
  const highlightedIndex = comboboxStore.use.highlightedIndex();
  const index = items.indexOf(item);

  return (
    <StyledButton selected={index === highlightedIndex}>
      {'data' in item && 'avatar' in item.data && item.data.avatar && (
        <Avatar
          imageId={item.data.avatar.profileImageId}
          size="xs"
          avatar={item.data.avatar.avatar}
          bgColor={item.data.avatar.avatarBgColor}
        />
      )}
      <x.div
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        spaceX={2}
      >
        <Text variant="md">{item.text}</Text>
        {'data' in item &&
          'notifyText' in item.data &&
          item.data.notifyText && (
            <>
              <Text variant="md" color="gray.300">
                •
              </Text>
              <Text variant="md" color="gray.300">
                {item.data.notifyText}
              </Text>
            </>
          )}
      </x.div>
    </StyledButton>
  );
};

export const MENTION_EVERYONE_KEY = 'everyone';

export const MentionDropdown: React.FC<{
  groupId?: string;
  filter?: (
    search: string
  ) => (
    item:
      | TComboboxItemBase
      | TComboboxItemWithData<false>
      | TComboboxItemWithData<MentionDropdownItemProps>
  ) => boolean;
}> = ({ filter, groupId }) => {
  const { mentions, users, loading } = useGroupSearchMentions({ groupId });
  const { t } = useTranslation();

  if (!mentions && loading) return null;

  const items = mentions?.map((mention) => {
    const key = 'isEveryone' in mention ? MENTION_EVERYONE_KEY : mention.id;

    const text =
      'isEveryone' in mention
        ? t('everyone')
        : mention.__typename === 'OrganizationRole'
          ? mention.label
          : getUserDisplayName(mention);

    const notifyText =
      'isEveryone' in mention
        ? users && users.length > 1
          ? t('notify_everyone', { numMembers: users.length })
          : t('notify_everyone_one')
        : mention.__typename === 'OrganizationRole' &&
            mention?.users &&
            mention.users.length > 1
          ? t('notify_role', { numMembers: mention.users.length })
          : t('notify_role_one') && undefined;

    const avatar = '__typename' in mention &&
      mention.__typename === 'User' && {
        avatar: {
          profileImageId: mention.profileImageId,
          avatar: mention.avatar,
          avatarBgColor: mention.avatarBgColor,
        },
      };

    const type =
      'isEveryone' in mention
        ? 'everyone'
        : mention.__typename === 'OrganizationRole'
          ? 'role'
          : 'user';

    return {
      key,
      text,
      data: {
        type,
        notifyText,
        ...avatar,
      },
    };
  });

  return (
    <MentionCombobox
      pluginKey="mention"
      onRenderItem={(props) => <MentionDropdownItem {...props} />}
      items={items}
      filter={filter}
    />
  );
};
