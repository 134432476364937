import * as React from "react";
import { SVGProps } from "react";
const SvgPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.749 1.713c-.956.038-1.832.817-1.832 1.867v12.84c0 1.4 1.56 2.318 2.784 1.64l11.587-6.42c1.26-.698 1.26-2.582 0-3.28L5.7 1.94a1.807 1.807 0 0 0-.952-.227Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPlay;
