import styled, { x } from '@xstyled/styled-components';
import React from 'react';

import { Button } from './Button';
import { Icon } from './Icon';

export interface CloseButtonProps {
  /**
   * Disable interation with button
   */
  disabled?: boolean;
  /**
   * Handler for click event
   */
  onClick?: () => void;
}

const StyledCloseButton = styled(Button)`
  width: 9;
  height: 9;
  color: gray.300;
  border-color: gray.200;
  border-radius: md;
  /* Remove 1px from padding on all sides to account for inset border */
  /* Using 0.375rem because adding a non-integer value to sizes.ts would resolve in the theme getter */
  padding-top: calc(0.375rem - 1px);
  padding-bottom: calc(0.375rem - 1px);
  padding-left: calc(0.375rem - 1px);
  padding-right: calc(0.375rem - 1px);
  &:hover {
    color: brand.300;
    border-color: brand.300;
  }
  :disabled {
    color: gray.200;
    background-color: white;
    border-color: gray.100;
  }
`;

/**
 * Utility component to display a CloseButton message for things not implemented
 */
export const CloseButton: React.FC<CloseButtonProps> = (props) => (
  <StyledCloseButton
    label={undefined}
    variant="secondary"
    leftElement={<Icon name="close" size="5" />}
    {...props}
  />
);

export const CircleCloseButton: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <x.button
      w={9}
      h={9}
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="full"
      borderColor="transparent"
      backgroundColor="rgba(0,0,0, 0.6)"
      onClick={onClick}
      cursor="pointer"
      color={{
        _: 'white',
        hover: 'gray.300',
      }}
      boxShadow="glossyModal"
    >
      <Icon name="close" size="5" />
    </x.button>
  );
};
