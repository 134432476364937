import { x } from '@xstyled/styled-components';
import { FC } from 'react';

/**
 * Display component for simple file uploads
 */
export const PostComposerFileSelector: FC<
  {
    id: string;
  } & React.InputHTMLAttributes<HTMLInputElement>
> = ({ id, children, ...inputProps }) => {
  return (
    <>
      <x.input type="file" display="none" id={id} {...inputProps} />
      <x.label htmlFor={id} cursor="pointer">
        {children}
      </x.label>
    </>
  );
};
