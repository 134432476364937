import * as React from "react";
import { SVGProps } from "react";
const SvgQr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.375 2.5A1.885 1.885 0 0 0 2.5 4.375v2.917c0 1.028.847 1.875 1.875 1.875h2.917a1.885 1.885 0 0 0 1.875-1.875V4.375A1.885 1.885 0 0 0 7.292 2.5H4.375Zm8.333 0a1.885 1.885 0 0 0-1.875 1.875v2.917c0 1.028.847 1.875 1.875 1.875h2.917A1.885 1.885 0 0 0 17.5 7.292V4.375A1.885 1.885 0 0 0 15.625 2.5h-2.917ZM4.375 3.75h2.917c.352 0 .625.272.625.625v2.917a.616.616 0 0 1-.625.625H4.375a.616.616 0 0 1-.625-.625V4.375c0-.353.272-.625.625-.625Zm8.333 0h2.917c.353 0 .625.272.625.625v2.917a.616.616 0 0 1-.625.625h-2.917a.616.616 0 0 1-.625-.625V4.375c0-.353.273-.625.625-.625ZM5 5v1.667h1.667V5H5Zm8.333 0v1.667H15V5h-1.667Zm-8.958 5.833A1.885 1.885 0 0 0 2.5 12.708v2.917c0 1.028.847 1.875 1.875 1.875h2.917a1.885 1.885 0 0 0 1.875-1.875v-2.917a1.885 1.885 0 0 0-1.875-1.875H4.375Zm6.458 0v2.292h2.292v-2.292h-2.292Zm2.292 2.292v2.083h2.083v-2.083h-2.083Zm2.083 0H17.5v-2.292h-2.292v2.292Zm0 2.083V17.5H17.5v-2.292h-2.292Zm-2.083 0h-2.292V17.5h2.292v-2.292Zm-8.75-3.125h2.917c.352 0 .625.273.625.625v2.917a.616.616 0 0 1-.625.625H4.375a.616.616 0 0 1-.625-.625v-2.917c0-.352.272-.625.625-.625ZM5 13.333V15h1.667v-1.667H5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgQr;
