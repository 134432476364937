import { theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { SystemProps } from '@xstyled/system';
import React from 'react';

type FrondLogoMinimalProps = {
  accentColor?: string;
} & Pick<SystemProps, 'w' | 'h'>;

export const FrondLogoMinimal: React.FC<FrondLogoMinimalProps> = ({
  accentColor = theme.colors.brand['400'],
  w,
  h,
}) => {
  return (
    <x.svg
      viewBox="0 0 40 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      w={w}
      h={h}
    >
      <path
        d="M0.0701641 7.16309C0.0701641 7.16309 -1.80745 22.142 13.8632 22.142C13.8632 22.142 17.7687 7.16309 0.0701641 7.16309Z"
        fill={accentColor}
      />
      <path
        d="M39.8877 0.88623C18.5706 0.88623 16.5987 14.2935 16.9899 21.1772C20.5913 10.7896 30.4089 8.49685 30.4089 8.49685C21.0367 14.1631 19.1144 24.3918 18.9894 25.1138C42.7937 24.1951 39.8877 0.88623 39.8877 0.88623Z"
        fill={accentColor}
      />
    </x.svg>
  );
};
