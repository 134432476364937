import * as React from "react";

function SvgFile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1h10.414L21 6.586V21a2 2 0 01-2 2H5a2 2 0 01-2-2V3a2 2 0 012-2zm8 2H5v18h14V9h-4a2 2 0 01-2-2V3zm2 .414V7h3.586L15 3.414z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgFile;
