import { useRouter } from 'next/router';
import { PropsWithChildren, useEffect } from 'react';

import { getSignInRedirectUrl } from '../../../common/utils/routing.utils';
import { useAuth } from '../../hooks/useAuth';
import { useOptionalOrganization } from '../../hooks/useOptionalOrganization';
import { useViewer } from '../../hooks/useViewer';

/**
 * Redirects the user to the current product when they are signed in
 */
export const SignInRedirectGuard: React.FC<
  PropsWithChildren<{
    shouldRedirect?: boolean;
  }>
> = ({ children, shouldRedirect = true }) => {
  const { isAuthenticated } = useAuth();
  const { viewer, isViewerLoading } = useViewer();
  const router = useRouter();
  const { organization } = useOptionalOrganization();

  useEffect(() => {
    if (router.isReady) {
      const callbackUrlPath = router.query.callbackUrlPath as
        | string
        | undefined;

      if (isAuthenticated && viewer && !isViewerLoading && shouldRedirect) {
        const redirectUrl = getSignInRedirectUrl(
          viewer,
          organization,
          callbackUrlPath
        );
        router.replace(redirectUrl);
      }
    }
  }, [
    isAuthenticated,
    router,
    organization,
    viewer,
    isViewerLoading,
    shouldRedirect,
  ]);

  return <>{children}</>;
};
