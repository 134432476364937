import * as React from "react";

function SvgAppFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-facebook_svg__clip0)">
        <path
          d="M14.541 23.997V13.052h3.674l.55-4.265h-4.224V6.064c0-1.235.343-2.077 2.114-2.077l2.259-.001V.17c-.39-.052-1.732-.168-3.291-.168-3.257 0-5.486 1.988-5.486 5.638v3.146H6.452v4.265h3.683v10.946h4.405z"
          fill="#3C5A9A"
        />
      </g>
      <defs>
        <clipPath id="app-facebook_svg__clip0">
          <path
            fill="#fff"
            transform="translate(6.453)"
            d="M0 0h12.461v24H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppFacebook;
