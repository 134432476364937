import { ErrorPage } from '../../auth/views/ErrorPage';

export const Error500View: React.FC = () => {
  return (
    <ErrorPage
      errorMessage="Whoops, something went wrong, please try again"
      errorCode="Error code 500"
      emojiUnicode="🚧"
    />
  );
};
