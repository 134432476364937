import { useSelector } from '@xstate/react';
import { useContext } from 'react';

import { Maybe, Organization } from '../../../../generated/types-and-hooks';
import { AppContext } from '../../common/machine/appContext';

export const useViewer = () => {
  const { appService } = useContext(AppContext);

  const state = useSelector(appService, (state) => state);
  const viewer = useSelector(appService, (state) => state.context.user);

  return {
    viewer,
    isViewerLoading: state.matches('INITIALIZE_USER.loading-user'),
  };
};

export const useIsViewerAdmin = (
  organization?: Maybe<Pick<Organization, 'id'>>
) => {
  const { viewer: user } = useViewer();

  if (!organization) {
    return false;
  }

  const organizationMembership = user?.organizationMemberships?.find(
    (organizationMembership) => {
      return (
        organizationMembership.organization.id === organization.id &&
        !!organizationMembership.roles.find((role) => role.isAdmin)
      );
    }
  );

  return !!organizationMembership;
};

export const useIsViewerInFrondTeam = () => {
  const { viewer } = useViewer();

  return (
    viewer?.email.split('@')[1] === 'frond.com' ||
    viewer?.organizationMemberships?.some((membership) => {
      return membership.organization.domain === 'frond.com';
    })
  );
};

export const useHasAdminRole = () => {
  const { viewer } = useViewer();
  return viewer?.organizationMemberships?.some((membership) => {
    return membership.roles.some((role) => role.isAdmin);
  });
};

export const useViewerTimeZone = () => {
  const { viewer: user } = useViewer();
  return (
    Intl.DateTimeFormat().resolvedOptions().timeZone ||
    user?.timeZone ||
    undefined
  );
};
