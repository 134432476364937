import { x } from '@xstyled/styled-components';
import { SystemProps } from '@xstyled/system';
import React, { FC } from 'react';

import { Text } from '../Text';

type AudienceSelectorMenuItemStatusTagProps = {
  label: string;
} & SystemProps;

export const AudienceSelectorMenuItemStatusTag: FC<
  AudienceSelectorMenuItemStatusTagProps
> = ({ label, ...system }) => {
  return (
    <x.span px={1} py={0.5} borderRadius="sm" {...system}>
      <Text variant="sm-semibold" as="span">
        {label}
      </Text>
    </x.span>
  );
};
