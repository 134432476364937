import styled, { css } from '@xstyled/styled-components';
import { th } from '@xstyled/system';

export const VideoRecorderTimeContainer = styled.div<{ disabled: boolean }>`
  color: white;
  user-select: none;
  padding: 6px 10px;
  border-radius: md;
  background-color: gray.400;
  display: flex;
  flex-grow: 0;
  width: 4.75rem;
  box-sizing: border-box;
  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.32;
    `}
  > * ~ * {
    margin-left: 6px;
  }
`;

export const VideoRecorderStatusIndicator = styled.div<{
  recording: boolean;
  paused: boolean;
}>`
  border-radius: full;
  width: 3;
  height: 3;
  margin-right: auto;
  align-self: center;
  background-color: ${({ recording, paused }) => {
    if (!recording && !paused) {
      return 'white';
    }
    return recording ? th('colors.red.300') : th('colors.yellow.300');
  }};
`;
