import { URL_TRANSFORMATION } from '@frond/shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { addDays, addHours, isSameDay } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { PropsWithChildren, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import * as yup from 'yup';

import {
  EventCoreFragment,
  LocationTypes,
  SeriesSelection,
} from '../../../../../generated/types-and-hooks';
import { Alert } from '../../../common/components/Alert';
import { Form } from '../../../common/components/Form';
import { EventSeriesSelectionModal } from '../EventSeriesSelectionModal';

const EventFormSchema = (event?: EventCoreFragment) =>
  yup
    .object({
      title: yup.string().required(),
      description: yup.array().nullable(),
      timeZone: yup.string().required(),
      url: yup.string().transform(URL_TRANSFORMATION).url().nullable(),
      meetingUrl: yup.string().transform(URL_TRANSFORMATION).url().nullable(),
      startAt: yup
        .string()
        .test({
          message: 'Start date must be before end date',
          test: (value, context) => {
            if (!value) return false;
            return (
              new Date(value).getTime() <
              new Date(context.parent.endAt).getTime()
            );
          },
        })
        .test({
          message: 'Start date must be after now',
          test: (value) => {
            if (event) return true;

            if (!value) return false;
            return new Date(value).getTime() > new Date().getTime();
          },
        })
        .required(),
      endAt: yup
        .string()
        .test({
          message: 'End date must be after start date',
          test: (value, context) => {
            if (!value) return false;
            return (
              new Date(value).getTime() >
              new Date(context.parent.startAt).getTime()
            );
          },
        })
        .required(),
      coverImageId: yup.string().nullable(),
      location: yup
        .object()
        .shape({
          id: yup.string().nullable(),
          placeId: yup.string().required(),
          type: yup.string().oneOf(Object.values(LocationTypes)).required(),
          displayName: yup.string().required(),
          lat: yup.number().required(),
          lon: yup.number().required(),
        })
        .default(null)
        .nullable(),
      recurringOccurrencesLimit: yup.string(),
      recurringCronExpression: yup.string(),
    })
    .defined();

export type EventFormData = yup.InferType<ReturnType<typeof EventFormSchema>>;

export const EventForm: React.FC<
  PropsWithChildren<{
    event?: EventCoreFragment;
    onSubmit: (
      data: EventFormData & {
        seriesSelection?: SeriesSelection;
      }
    ) => void;
  }>
> = ({ event, onSubmit, children }) => {
  const { t } = useTranslation();
  const tomorrowNoon = new Date(addDays(new Date(), 1).setHours(12, 0, 0, 0));

  const form = useForm<EventFormData>({
    resolver: yupResolver(EventFormSchema(event)),
    mode: 'all',
    defaultValues: {
      title: event?.name || '',
      description: event?.description as EventFormData['description'],
      url: event?.url,
      coverImageId: event?.coverImageId,
      location: event?.location && {
        placeId: event?.location.placeId,
        type: event?.location.type,
        displayName: event?.location.displayName,
        lat: event?.location.lat,
        lon: event?.location.lon,
      },
      startAt: event?.startAt || tomorrowNoon.toISOString(),
      endAt: event?.endAt || addHours(tomorrowNoon, 1).toISOString(),
      timeZone:
        event?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      recurringOccurrencesLimit:
        event?.series?.recurringOccurrencesLimit?.toString() || 'never',
      recurringCronExpression:
        event?.series?.recurringCronExpression || 'never',
    },
  });
  const [showSeriesSelectionModal, setShowSeriesSelectionModal] =
    useState(false);

  const [showSeriesSplitModal, setShowSeriesSplitModal] = useState(false);

  useHotkeys(
    'cmd+enter',
    () => {
      if (event?.series) {
        if (
          event.series.recurringCronExpression
            .split(' ')
            .splice(2)
            .join(' ') !==
            form
              .getValues()
              .recurringCronExpression?.split(' ')
              .splice(2)
              .join(' ') ||
          event.series.recurringOccurrencesLimit?.toString() !==
            form.getValues().recurringOccurrencesLimit ||
          !isSameDay(
            new Date(event.startAt),
            new Date(form.getValues().startAt)
          )
        ) {
          setShowSeriesSplitModal(true);
        } else {
          setShowSeriesSelectionModal(true);
        }
      } else {
        form.handleSubmit(onSubmit)();
      }
    },
    {
      enableOnFormTags: ['INPUT', 'TEXTAREA'],
    }
  );

  return (
    <>
      <Form<EventFormData>
        {...form}
        onSubmit={(data) => {
          if (event?.series) {
            if (
              event.series.recurringCronExpression
                .split(' ')
                .splice(2)
                .join(' ') !==
                form
                  .getValues()
                  .recurringCronExpression?.split(' ')
                  .splice(2)
                  .join(' ') ||
              (event.series.recurringOccurrencesLimit &&
                event.series.recurringOccurrencesLimit.toString() !==
                  form.getValues().recurringOccurrencesLimit) ||
              !isSameDay(
                new Date(event.startAt),
                new Date(form.getValues().startAt)
              )
            ) {
              setShowSeriesSplitModal(true);
            } else {
              setShowSeriesSelectionModal(true);
            }
          } else {
            onSubmit(data);
          }
        }}
        flex={1}
        display="flex"
        flexDirection="column"
      >
        {children}
      </Form>
      <EventSeriesSelectionModal
        isOpen={showSeriesSelectionModal}
        onDismiss={() => setShowSeriesSelectionModal(false)}
        onAction={(seriesSelection) => {
          form.handleSubmit((data) => onSubmit({ ...data, seriesSelection }))();
        }}
        variant="update"
      />
      {showSeriesSplitModal && (
        <Alert
          headingText="Edit recurrence"
          descriptionText="Editing the date or cadence will split the event into a new series using the new recurrence details."
          submitButtonText={t('confirm')}
          cancelButtonText={t('cancel')}
          onDismiss={() => {
            setShowSeriesSplitModal(false);
          }}
          onSubmitClick={() =>
            form.handleSubmit((data) =>
              onSubmit({
                ...data,
                seriesSelection: SeriesSelection.FollowingEvents,
              })
            )()
          }
          onCancelClick={() => {
            setShowSeriesSplitModal(false);
          }}
          variant="warning"
        />
      )}
    </>
  );
};
