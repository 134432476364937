import { theme } from '@frond/shared';
import styled, { css } from '@xstyled/styled-components';
import { CSSProperties, FC, ReactNode } from 'react';

// A way smaller rewrite of https://www.npmjs.com/package/react-loading-skeleton which uses Emotion

const defaultBaseColor = theme.colors.gray['100'];

const defaultHighlightColor = theme.colors.gray['50'];

const StyledSkeleton = styled.span<{ animate: boolean }>`
  background-color: ${defaultBaseColor};
  ${(props) =>
    props.animate &&
    css`
      background-image: linear-gradient(
        90deg,
        ${defaultBaseColor},
        ${defaultHighlightColor},
        ${defaultBaseColor}
      );
    `}

  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  animation: skeleton-animation 1.2s ease-in-out infinite;

  @keyframes skeleton-animation {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
  }
`;

type SkeletonProps = {
  count?: number;
  width?: string | number;
  wrapper?: ReactNode;
  height?: string | number;
  circle?: boolean;
  style?: CSSProperties;
  className?: string;
  animate?: boolean;
};

export const Skeleton: FC<SkeletonProps> = ({
  count = 1,
  width,
  wrapper: Wrapper,
  height,
  circle = false,
  style: customStyle,
  className: customClassName,
  animate = true,
}) => {
  const elements = [];

  if (count)
    for (let i = 0; i < count; i++) {
      const style = {
        ...(width && { width }),
        ...(height && { height }),
        ...(width && height && circle && { borderRadius: '50%' }),
      };

      let className = 'react-loading-skeleton';
      if (customClassName) {
        className += ' ' + customClassName;
      }

      elements.push(
        <StyledSkeleton
          key={i}
          className={className}
          style={{
            ...customStyle,
            ...style,
          }}
          animate={animate}
        >
          &zwnj;
        </StyledSkeleton>
      );
    }

  return (
    <span>
      {Wrapper
        ? elements.map((element, i) => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Wrapper key={i}>
              {element}
              &zwnj;
            </Wrapper>
          ))
        : elements}
    </span>
  );
};
