import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * Returns the previous value passed to the hook
 */
export const usePrevious = <T,>(value?: T): T | undefined => {
  const ref: MutableRefObject<T | undefined> = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

/**
 * Returns the previous boolean passed to the hook
 */
export const usePreviousBoolean = (value?: boolean): boolean | undefined =>
  usePrevious(value);

/**
 * Returns the previous number passed to the hook
 */
export const usePreviousNumber = (value?: number): number | undefined =>
  usePrevious(value);
