import * as React from "react";
import { SVGProps } from "react";
const SvgMessagesBlue = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.75 9.5h13a.75.75 0 0 1 .75.75v9a.75.75 0 0 1-.75.75H19.5v3.199a.4.4 0 0 1-.683.283L15.335 20H8.75a.75.75 0 0 1-.75-.75v-9a.75.75 0 0 1 .75-.75Z"
      fill="url(#MessagesBlue_svg__a)"
    />
    <path
      opacity={0.05}
      d="M16.5 9.5v3.25c0 .69-.56 1.25-1.25 1.25H8.87l-.87.87v-4.62c0-.415.335-.75.75-.75h7.75Z"
      fill="currentColor"
    />
    <path
      opacity={0.07}
      d="M16.25 9.5v3.25c0 .55-.45 1-1 1H8.77l-.77.77v-4.27c0-.415.335-.75.75-.75h7.5Z"
      fill="currentColor"
    />
    <path
      d="M15.25 3h-13a.75.75 0 0 0-.75.75v9c0 .414.336.75.75.75H4.5v3.199a.4.4 0 0 0 .683.283L8.665 13.5h6.585a.75.75 0 0 0 .75-.75v-9a.75.75 0 0 0-.75-.75Z"
      fill="url(#MessagesBlue_svg__b)"
    />
    <defs>
      <linearGradient
        id="MessagesBlue_svg__a"
        x1={12.204}
        y1={9.613}
        x2={20.099}
        y2={22.929}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0176D0" />
        <stop offset={1} stopColor="#16538C" />
      </linearGradient>
      <linearGradient
        id="MessagesBlue_svg__b"
        x1={7.782}
        y1={6.676}
        x2={13.362}
        y2={15.754}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#33BEF0" />
        <stop offset={1} stopColor="#22A5E2" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgMessagesBlue;
