import { x } from '@xstyled/styled-components';
import { PropsWithChildren } from 'react';

import { Icon } from '../../common/components/Icon';
import { Text } from '../../common/components/Text';

export const InboxNotificationCommon: React.FC<
  {
    icon?: string;
    avatar?: JSX.Element;
    title: string;
    description: JSX.Element | string;
    action: JSX.Element;
  } & PropsWithChildren
> = ({ icon, avatar, title, description, children, action }) => {
  return (
    <x.div p={6} spaceY={4} borderRadius="md" backgroundColor="gray.100">
      {icon && <Icon name={icon} size="10" />}
      {avatar && avatar}
      <x.div spaceY={3}>
        <Text variant="md-semibold">{title}</Text>
        <Text variant="md" color="gray.400">
          {description}
        </Text>
        {children}
        <div>{action}</div>
      </x.div>
    </x.div>
  );
};
