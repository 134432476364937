import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_LI,
  ELEMENT_LINK,
  ELEMENT_MENTION,
  ELEMENT_OL,
  ELEMENT_UL,
} from '@frond/shared';
import { TRenderElementProps } from '@udecode/plate-common';
import styled from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren } from 'react';

import { getUserDisplayName } from '../../utils/user';
import { Text } from '../Text';
import {
  Blockquote,
  CodeBlock,
  OrderedList,
  UnorderedList,
} from './ComposerElement';
import { useSearchMentions } from './hooks/useSearchMentions';

/**
 * This file contains a series of tiny components that render DOM elements
 * that visually represent update content, but without padding or margins applied.
 * This is needed to render a truncated update summary inside a single `article` tag.
 */

const ComposerSummaryHeader: React.FC<PropsWithChildren> = ({ children }) => {
  return <Text variant="md-semibold">{children}</Text>;
};

const ComposerSummaryListItem: React.FC<PropsWithChildren> = ({ children }) => (
  <li>
    <Text as="span">{children}</Text>
  </li>
);

const UpdateSummaryLink: React.FC<PropsWithChildren> = ({ children }) => (
  <Text as="span">{children}</Text>
);

const UpdateSummaryText: React.FC<PropsWithChildren> = ({ children }) => (
  <Text>{children}</Text>
);

// Adjust codeblock padding to fit in full lines
const SummaryCodeBlock = styled(CodeBlock)`
  padding: 4;
`;

/**
 * Mapping of element types to component resolvers
 */
const elementMap = {
  [ELEMENT_H1]: ComposerSummaryHeader,
  [ELEMENT_H2]: ComposerSummaryHeader,
  [ELEMENT_H3]: ComposerSummaryHeader,
  [ELEMENT_BLOCKQUOTE]: Blockquote,
  [ELEMENT_LINK]: UpdateSummaryLink,
  [ELEMENT_UL]: UnorderedList,
  [ELEMENT_LI]: ComposerSummaryListItem,
  [ELEMENT_OL]: OrderedList,
  [ELEMENT_CODE_BLOCK]: SummaryCodeBlock,
  default: UpdateSummaryText,
};

/**
 * Summary node renderer for Slate content
 */
export const ComposerSummary: React.FC<
  Omit<TRenderElementProps, 'attributes'>
> = ({ children, element }) => {
  const { mentions } = useSearchMentions();
  const { t } = useTranslation();

  if (element.type === ELEMENT_MENTION) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const user = mentions?.find((user) => user.id === element?.user?.id);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const role = mentions?.find((role) => role.id === element?.role?.id);

    if ('isEveryone' in element) {
      return <span>{t('everyone')}</span>;
    } else if ('role' in element && role && 'label' in role) {
      return <span>{role.label}</span>;
    } else if (user && 'username' in user) {
      return <span>{getUserDisplayName(user)}</span>;
    }
  }

  const Component =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (element.type && elementMap[element.type]) || elementMap.default;

  return <Component>{children}</Component>;
};
