import { MyValue } from '@frond/shared';
import { CropperRef } from 'react-advanced-cropper';
import { Node } from 'slate';
import { assign, createMachine } from 'xstate';

import {
  Coordinates,
  Giphy,
  Image,
  PostColorTheme,
  PostCoreFragment,
  PostTypes,
  Video,
} from '../../../../generated/types-and-hooks';
import { PostComposerGroup } from './PostComposer';

export type ProcessingMedia = {
  id: string;
  uploadProgress?: number;
  file?: File;
};

export const postHasContent = (content?: MyValue | null) => {
  return !!content && content.map((n) => Node.string(n)).join().length > 0;
};

export const postHasPollWithOptions = (pollOptions?: string[]) => {
  return pollOptions
    ? pollOptions.filter((option) => option !== '').length > 1
    : true;
};

export type PostComposerImage = Image & {
  base64?: string;
  cropperRef?: CropperRef;
};

export type Media = Video | PostComposerImage | Giphy | ProcessingMedia;

type PostEditableFields = Pick<
  PostCoreFragment,
  'content' | 'resources' | 'colorTheme' | 'aspectRatio' | 'poll' | 'title'
> & {
  media: Media[];
};

type ResourceToEmbed = {
  title?: string;
  file?: File;
  url: string;
  expanded: boolean;
};

type PostComposerContext = {
  post: PostEditableFields;
  postId?: string;
  errorMessage?: string;
  group?: PostComposerGroup;
  resourcesToEmbed: ResourceToEmbed[];
  pollOptions: string[];
  currentMediaIndex: number;
  type: PostTypes;
  isPublishing: boolean;
  upsertSuccess: boolean;
  canChangeGroup?: boolean;
};

export enum PostComposerState {
  GIPHY_SEARCH = 'GIPHY_SEARCH',
  VIDEO_CAPTURE = 'VIDEO_CAPTURE',
  QR_UPLOAD = 'QR_UPLOAD',
  REVIEW = 'REVIEW',
  CROP = 'CROP',
  POLL_UPSELL = 'POLL_UPSELL',
}

type PostComposerTypestate = {
  value: PostComposerState;
  context: PostComposerContext;
};

type PostComposerEvent =
  | {
      type: 'SET_POLL_OPTIONS';
      pollOptions: string[];
    }
  | {
      type: 'REMOVE_POLL_OPTIONS';
    }
  | {
      type:
        | 'START_QR_UPLOAD'
        | 'START_VIDEO_CAPTURE'
        | 'START_GIPHY_SEARCH'
        | 'REVIEW_POST'
        | 'CLEAR_ERROR'
        | 'START_IMAGE_CROP'
        | 'SET_IS_PUBLISHING'
        | 'SET_PUBLISHING_COMPLETE'
        | 'SET_UPSERT_SUCCESS'
        | 'SHOW_POLL_UPSELL'
        | 'CLOSE_POLL_UPSELL';
    }
  | {
      type: 'SET_COLOR_THEME';
      colorTheme: PostColorTheme;
    }
  | {
      type: 'ADD_URL_TO_EMBED';
      url: string;
      file?: File;
    }
  | {
      type: 'REMOVE_URL_TO_EMBED';
      url: string;
    }
  | {
      type: 'SET_ERROR';
      errorMessage: string;
    }
  | {
      type: 'SET_POST_CONTENT';
      content: MyValue;
    }
  | {
      type: 'SET_GROUP';
      group: PostComposerGroup;
    }
  | {
      type: 'REMOVE_RESOURCE';
      resourceId: string;
    }
  | {
      type: 'TOGGLE_EXPANDED_RESOURCE';
      resourceId: string;
    }
  | {
      type: 'ADD_RESOURCE';
      resource: PostCoreFragment['resources'][0];
    }
  | {
      type: 'UPDATE_RESOURCE';
      url: string;
      title: string;
    }
  | {
      type: 'UPDATE_IMAGE_COORDINATES';
      mediaId: string;
      coordinates: Coordinates;
    }
  | {
      type: 'UPDATE_POST_ASPECT_RATIO';
      aspectRatio: number;
    }
  | {
      type: 'REMOVE_MEDIA';
      mediaId: string;
    }
  | {
      type: 'MEDIA_UPLOADED';
      media: Video | PostComposerImage;
      prevId: string;
    }
  | {
      type: 'ADD_MEDIA';
      media: Media;
    }
  | {
      type: 'SET_UPLOAD_PROGRESS';
      percentUploaded: number;
      id: string;
    }
  | {
      type: 'SET_CURRENT_MEDIA_INDEX';
      mediaIndex: number;
    }
  | {
      type: 'SET_CROPPER_REF';
      mediaId: string;
      cropperRef: CropperRef;
    }
  | {
      type: 'CROP_IMAGE';
      coordinates: Coordinates;
      mediaId: string;
    }
  | {
      type: 'SET_POST_TITLE';
      title: string;
    };

export const postComposerHasNoValuesSelector = (
  state: PostComposerTypestate
) => {
  return (
    (!postHasContent(state.context.post.content as MyValue) &&
      !state.context.post.media.length &&
      !state.context.post.resources.length) ||
    (state.context.type === PostTypes.Lesson && !state.context.post.title) ||
    !postHasPollWithOptions(state.context.pollOptions)
  );
};

export const initialPostComposerContext: PostComposerContext = {
  post: {
    content: undefined,
    colorTheme: PostColorTheme.Gray,
    resources: [],
    media: [],
    aspectRatio: 1,
    title: '',
  },
  resourcesToEmbed: [],
  pollOptions: [],
  currentMediaIndex: 0,
  type: PostTypes.Regular,
  isPublishing: false,
  upsertSuccess: false,
  canChangeGroup: true,
};
export const postComposerMachine = createMachine<
  PostComposerContext,
  PostComposerEvent,
  PostComposerTypestate
>({
  id: 'postComposer',
  initial: PostComposerState.REVIEW,
  context: initialPostComposerContext,
  on: {
    REVIEW_POST: PostComposerState.REVIEW,
    CLEAR_ERROR: {
      actions: assign({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        errorMessage: (_) => undefined,
      }),
    },
    ADD_URL_TO_EMBED: {
      actions: assign({
        resourcesToEmbed: (context, event) => {
          if (context.pollOptions?.length > 0) return [];
          return [
            ...context.resourcesToEmbed,
            { url: event.url, file: event.file, expanded: true },
          ];
        },
      }),
    },
    REMOVE_URL_TO_EMBED: {
      actions: assign({
        resourcesToEmbed: (context, event) => {
          return context.resourcesToEmbed.filter(
            (resource) => resource.url !== event.url
          );
        },
      }),
    },
    ADD_MEDIA: {
      actions: assign({
        post: (context, event) => {
          return {
            ...context.post,
            media: [...context.post.media, event.media],
          };
        },
        currentMediaIndex: (context) => {
          return context.post.media.length === 0
            ? 0
            : context.post.media.length + 1;
        },
      }),
      target: PostComposerState.REVIEW,
    },
    MEDIA_UPLOADED: {
      actions: assign({
        post: (context, event) => {
          return {
            ...context.post,
            media: context.post.media.map((m) => {
              if (m.id === event.prevId) {
                return event.media;
              } else {
                return m;
              }
            }),
          };
        },
      }),
    },
    SET_UPLOAD_PROGRESS: {
      actions: assign({
        post: (context, event) => {
          return {
            ...context.post,
            media: context.post.media.map((m) => {
              if (m.id === event.id) {
                return {
                  ...m,
                  uploadProgress: event.percentUploaded,
                };
              } else {
                return m;
              }
            }),
          };
        },
      }),
    },
    UPDATE_POST_ASPECT_RATIO: {
      actions: assign({
        post: (context, event) => {
          return {
            ...context.post,
            aspectRatio: event.aspectRatio,
          };
        },
      }),
    },

    SET_ERROR: {
      actions: assign({
        errorMessage: (_, event) => event.errorMessage,
        isPublishing: false,
      }),
    },

    SET_POLL_OPTIONS: {
      actions: assign({
        pollOptions: (_, event) => {
          return event.pollOptions.filter((option) => option !== '');
        },
      }),
    },

    REMOVE_POLL_OPTIONS: {
      actions: assign({
        pollOptions: undefined,
      }),
    },

    SET_IS_PUBLISHING: {
      actions: assign({
        isPublishing: true,
      }),
    },

    SET_PUBLISHING_COMPLETE: {
      actions: assign({
        isPublishing: false,
      }),
    },
    SET_UPSERT_SUCCESS: {
      actions: assign({
        upsertSuccess: true,
      }),
    },
  },
  states: {
    [PostComposerState.REVIEW]: {
      on: {
        START_QR_UPLOAD: PostComposerState.QR_UPLOAD,
        START_VIDEO_CAPTURE: PostComposerState.VIDEO_CAPTURE,
        START_GIPHY_SEARCH: PostComposerState.GIPHY_SEARCH,
        START_IMAGE_CROP: PostComposerState.CROP,
        SHOW_POLL_UPSELL: PostComposerState.POLL_UPSELL,
        SET_CURRENT_MEDIA_INDEX: {
          actions: assign({
            currentMediaIndex: (_, event) => event.mediaIndex,
          }),
        },
        SET_POST_TITLE: {
          actions: assign({
            post: (context, event) => ({
              ...context.post,
              title: event.title,
            }),
          }),
        },
        ADD_RESOURCE: {
          actions: assign({
            post: (context, event) => {
              const alreadyHasResource = context.post.resources.find(
                (r) => r.url === event.resource.url
              );
              if (alreadyHasResource) {
                return context.post;
              }

              return {
                ...context.post,
                resources: [...context.post.resources, event.resource],
              };
            },
          }),
        },
        UPDATE_RESOURCE: {
          actions: assign({
            post: (context, event) => {
              return {
                ...context.post,
                resources: context.post.resources.map((resource) => {
                  if (resource.url === event.url) {
                    return {
                      ...resource,
                      title: event.title,
                    };
                  }
                  return resource;
                }),
              };
            },
          }),
        },
        REMOVE_RESOURCE: {
          actions: assign({
            post: (context, event) => ({
              ...context.post,
              resources: context.post.resources.filter(
                (resource) => resource.id !== event.resourceId
              ),
            }),
          }),
        },
        TOGGLE_EXPANDED_RESOURCE: {
          actions: assign({
            post: (context, event) => ({
              ...context.post,
              resources: context.post.resources.map((resource) => {
                if (resource.id === event.resourceId) {
                  return {
                    ...resource,
                    expanded: !resource.expanded,
                  };
                }
                return resource;
              }),
            }),
          }),
        },
        REMOVE_MEDIA: {
          actions: assign({
            post: (context, event) => ({
              ...context.post,
              media: context.post.media.filter(
                (resource) => resource.id !== event.mediaId
              ),
            }),
          }),
        },
        SET_POST_CONTENT: {
          actions: assign({
            post: (context, event) => ({
              ...context.post,
              content: event.content,
            }),
          }),
        },
        SET_GROUP: {
          actions: assign({
            group: (_, event) => event.group,
          }),
        },
        SET_COLOR_THEME: {
          actions: assign({
            post: (context, event) => ({
              ...context.post,
              colorTheme: event.colorTheme,
            }),
          }),
        },
        UPDATE_IMAGE_COORDINATES: {
          actions: assign({
            post: (context, event) => ({
              ...context.post,
              media: context.post.media.map((media) => {
                if (media.id === event.mediaId) {
                  return {
                    ...media,
                    coordinates: event.coordinates,
                  };
                } else {
                  return media;
                }
              }),
            }),
          }),
        },
        SET_CROPPER_REF: {
          actions: assign({
            post: (context, event) => ({
              ...context.post,
              media: context.post.media.map((media) => {
                if (media.id === event.mediaId) {
                  return {
                    ...media,
                    cropperRef: event.cropperRef,
                  };
                } else {
                  return media;
                }
              }),
            }),
          }),
        },
        SET_UPSERT_SUCCESS: {
          actions: 'dismiss',
        },
      },
    },
    [PostComposerState.QR_UPLOAD]: {},
    [PostComposerState.VIDEO_CAPTURE]: {},
    [PostComposerState.GIPHY_SEARCH]: {},
    [PostComposerState.POLL_UPSELL]: {
      on: {
        CLOSE_POLL_UPSELL: PostComposerState.REVIEW,
      },
    },
    [PostComposerState.CROP]: {
      on: {
        CROP_IMAGE: {
          target: PostComposerState.REVIEW,
          actions: assign({
            post: (context, event) => ({
              ...context.post,
              aspectRatio: event.coordinates.width / event.coordinates.height,
              media: context.post.media.map((media) => {
                if (media.id === event.mediaId) {
                  return {
                    ...media,
                    coordinates: event.coordinates,
                  };
                } else {
                  return media;
                }
              }),
            }),
          }),
        },
      },
    },
  },
});
