import { useTranslation } from 'next-i18next';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Toast } from './Toast';

/**
 * Displays an error state if the user doesn't have connection
 */
export const NetworkGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const [online, setOnline] = useState(false);

  const handleNetworkStatusChange = useCallback(() => {
    setOnline(window.navigator.onLine);
  }, [setOnline]);

  useEffect(() => {
    handleNetworkStatusChange();

    window.addEventListener('online', handleNetworkStatusChange);
    window.addEventListener('offline', handleNetworkStatusChange);

    return () => {
      window.removeEventListener('online', handleNetworkStatusChange);
      window.removeEventListener('offline', handleNetworkStatusChange);
    };
  }, [handleNetworkStatusChange]);

  return (
    <>
      {!online && (
        <Toast
          message={t('offline_alert')}
          variant="error"
          disappearTime={null}
        />
      )}
      {children}
    </>
  );
};
