import * as React from "react";

function SvgCircleChecked(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 100-18 9 9 0 000 18zm3.293-12.707L10 13.586l-2.293-2.293-1.414 1.414L10 16.414l6.707-6.707-1.414-1.414z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCircleChecked;
