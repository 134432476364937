import { RefObject, useCallback, useEffect } from 'react';

import { IS_SSR } from '../../../config/constants';

const EVENT_NAME = 'video-pause-all';

export const useVideoEvents = (
  ref?: RefObject<HTMLVideoElement>,
  opts?: { disabled?: boolean }
) => {
  useEffect(() => {
    if (IS_SSR || opts?.disabled) {
      return;
    }

    const handleEvent = () => ref?.current?.pause();

    window.addEventListener(EVENT_NAME, handleEvent);
    return () => window.removeEventListener(EVENT_NAME, handleEvent);
  }, [ref, opts?.disabled]);

  const pauseAll = useCallback(
    () => window.dispatchEvent(new Event(EVENT_NAME)),
    []
  );

  return {
    pauseAll,
  };
};
