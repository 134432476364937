import { merge } from 'lodash';

import { VIDEO_CAPTURE_STREAM_CONSTRAINTS } from '../../video.config';

const VIDEO_INPUT_PREFERENCE_KEY = 'frond-preference-video-in';
const AUDIO_INPUT_PREFERENCE_KEY = 'frond-preference-audio-in';

/**
 * Write a user device preference to browser storage
 */
export const setDevicePreference = (
  input: 'video' | 'audio',
  deviceId: string
): void => {
  const key =
    input === 'video' ? VIDEO_INPUT_PREFERENCE_KEY : AUDIO_INPUT_PREFERENCE_KEY;
  localStorage.setItem(key, deviceId);
};

/**
 * Get any stored preferences from browser storage
 */
const getDevicePreferences = (): MediaStreamConstraints => {
  try {
    const audioDeviceId =
      localStorage.getItem(AUDIO_INPUT_PREFERENCE_KEY) || undefined;
    const videoDeviceId =
      localStorage.getItem(VIDEO_INPUT_PREFERENCE_KEY) || undefined;

    return {
      audio: {
        deviceId: audioDeviceId,
      },
      video: {
        deviceId: videoDeviceId,
      },
    };
  } catch (e) {
    return {};
  }
};

/**
 * Get the constraints object that reflects our defaults
 * combined with any stored user preferences
 */
export const getPreferredConstraints = (): MediaStreamConstraints => {
  const preferences = getDevicePreferences();
  return merge(VIDEO_CAPTURE_STREAM_CONSTRAINTS, preferences);
};
