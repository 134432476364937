import { useSelector } from '@xstate/react';
import styled, { th, x } from '@xstyled/styled-components';
import React, { useContext, useEffect, useState } from 'react';

import { useUnfurlUrlMutation } from '../../../../generated/types-and-hooks';
import { Icon } from '../../common/components/Icon';
import { Input } from '../../common/components/Input';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { Toast } from '../../common/components/Toast';
import { useEffectOnce } from '../../common/hooks/useEffectOnce';
import { useFileUpload } from '../../common/hooks/useFileUpload';
import { PostComposerStateContext } from './PostComposer';
import { UPLOAD_ERROR_MESSAGE } from './PostComposerErrors';

const StyledInput = styled(Input)`
  width: 100%;
  pointer-events: all;
  background-color: transparent;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  color: gray.400;
  ${th('typographyStyles.text.sm-semibold')};
`;

type ResourceUnfurlCardProps = {
  url: string;
  file?: File;
};
export const ResourceUnfurlCard = ({ url, file }: ResourceUnfurlCardProps) => {
  const { postComposerService } = useContext(PostComposerStateContext);
  const [ignoreResult, setIgnoreResult] = useState(false);

  const [unfurlUrl] = useUnfurlUrlMutation({
    onCompleted(data) {
      if (ignoreResult) {
        postComposerService.send({
          type: 'REMOVE_URL_TO_EMBED',
          url,
        });
        return;
      }

      if ('id' in data.unfurlUrl) {
        postComposerService.send({
          type: 'ADD_RESOURCE',
          resource: data.unfurlUrl,
        });
        postComposerService.send({
          type: 'REMOVE_URL_TO_EMBED',
          url,
        });
      } else {
        postComposerService.send({
          type: 'REMOVE_URL_TO_EMBED',
          url,
        });
        setUnfurlError(data.unfurlUrl.message);
      }
    },
  });
  const { uploadSelectedFile, loading } = useFileUpload({
    name: 'user-uploaded-file',
    onUploadSuccess: (file) => {
      if (!('publicId' in file)) {
        unfurlUrl({
          variables: {
            input: {
              url: file.url,
            },
          },
        });
      }
    },
    onUploadFailure: () => {
      postComposerService.send({
        type: 'REMOVE_URL_TO_EMBED',
        url,
      });
      postComposerService.send({
        type: 'SET_ERROR',
        errorMessage: UPLOAD_ERROR_MESSAGE,
      });
    },
  });
  const [unfurlError, setUnfurlError] = useState<string | null>(null);
  const [isHovering, setIsHovering] = useState(false);

  useEffectOnce(
    () => {
      if (file) {
        if (loading) return;
        uploadSelectedFile(file);
      }
    },
    () => !!file
  );

  useEffect(() => {
    if (!file) {
      unfurlUrl({
        variables: {
          input: {
            url,
          },
        },
      });
    }
  }, [unfurlUrl, url, file]);

  if (ignoreResult) {
    return null;
  }

  return (
    <x.div
      position="relative"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {!!unfurlError && <Toast variant="error" message={unfurlError} />}
      <StyledInput
        icon={<LoadingSpinner size="4" />}
        autoFocus
        disabled
        value={url}
      />
      <x.button
        position="absolute"
        opacity={{ sm: isHovering ? 1 : 0 }}
        transition
        transitionDuration="fast"
        transitionTimingFunction="ease-out"
        type="button"
        display="flex"
        w={5}
        h={5}
        top={-10}
        right={-10}
        bg={{
          _: 'rgba(0,0,0,0.6)',
          hover: 'blackAlpha.400',
        }}
        border="none"
        borderRadius="full"
        justifyContent="center"
        alignItems="center"
        cursor="pointer"
        onClick={() => {
          setIgnoreResult(true);
        }}
      >
        <Icon name="close" color="white" />
      </x.button>
    </x.div>
  );
};

export const PostComposerResourcesExternalUnfurl = () => {
  const { postComposerService } = useContext(PostComposerStateContext);
  const resourcesToEmbed = useSelector(
    postComposerService,
    (state) => state.context.resourcesToEmbed
  );

  return (
    <x.div spaceY={3}>
      {resourcesToEmbed.map((resource) => (
        <ResourceUnfurlCard
          key={resource.url}
          file={resource.file}
          url={resource.url}
        />
      ))}
    </x.div>
  );
};
