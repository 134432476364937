import * as React from "react";
import { SVGProps } from "react";

const SvgAppApplePodcasts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#app-apple-podcasts_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.485 24.005h-13.6a5.188 5.188 0 0 1-5.2-5.2v-13.6a5.19 5.19 0 0 1 5.2-5.2h13.6a5.19 5.19 0 0 1 5.2 5.2v13.6a5.19 5.19 0 0 1-5.2 5.2Z"
        fill="url(#app-apple-podcasts_svg__b)"
      />
      <path
        d="m15.665 15.945.1-.88a1.77 1.77 0 0 0 0-.46 4.6 4.6 0 1 0-6.16 0c-.01.153-.01.307 0 .46l.1.88a5.6 5.6 0 1 1 5.96 0Zm-.34 3.04.12-1.1a7.426 7.426 0 1 0-5.52-.02l.12 1.1a8.37 8.37 0 1 1 5.28.02Z"
        fill="#fff"
      />
      <path
        d="M12.685 12.405a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM11.865 13.305l-.28.1a1.524 1.524 0 0 0-.98 1.56l.54 4.86a1.571 1.571 0 0 0 1.54 1.38 1.542 1.542 0 0 0 1.54-1.38l.52-4.86a1.475 1.475 0 0 0-.98-1.56l-.26-.1c-.529-.2-1.112-.2-1.64 0Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="app-apple-podcasts_svg__b"
        x1={12.684}
        y1={-0.021}
        x2={12.684}
        y2={23.979}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C873F5" />
        <stop offset={1} stopColor="#7B33BB" />
      </linearGradient>
      <clipPath id="app-apple-podcasts_svg__a">
        <path fill="#fff" transform="translate(.685 .005)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAppApplePodcasts;
