import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

import { Loading } from '../../common/components/Loading';
import { useEffectOnce } from '../../common/hooks/useEffectOnce';
import { AppContext } from '../../common/machine/appContext';
import { useAuth } from '../hooks/useAuth';
import { useAuthCallbackParams } from '../hooks/useAuthCallbackParams';
import { useViewer } from '../hooks/useViewer';

export const isCustomDomain = () => {
  return (
    !window.location.hostname.endsWith('frond.com') &&
    !window.location.hostname.endsWith('frond.dev') &&
    !window.location.hostname.endsWith('-frond.vercel.app')
  );
};

export const AuthCallbackView: React.FC = () => {
  const { viewer, isViewerLoading } = useViewer();
  const router = useRouter();
  const { appService } = useContext(AppContext);
  const { shortId, inviteCode, redirectUrl, errorDescription } =
    useAuthCallbackParams();

  const {
    getAccessTokenSilently,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth();

  // Handle error when email or verification code is invalid
  useEffect(() => {
    if (errorDescription) {
      appService.send({
        type: 'SET_AUTH_ERROR',
        error: errorDescription,
      });
    }
  }, [appService, errorDescription]);

  const hasAuthQueryParams =
    router.isReady && !!router.query.code && !!router.query.state;

  const shouldAuthenticate =
    !isLoading && !isAuthenticated && hasAuthQueryParams;

  useEffect(() => {
    if (shouldAuthenticate) {
      // this is not documented in auth0 docs, but getAccessTokenSilently() will fetch the "code" and "state"
      // params from the url hash, exchange them for valid access_tokens and create the auth0 cookie
      // this is the easiest way to make auth0-react recognize and use auth0-js generated tokens
      getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
          inviteCode,
          shortId,
        },
      }).catch((e) => {
        Sentry.captureException(e);
        isCustomDomain()
          ? loginWithRedirect({
              authorizationParams: {
                redirect_uri: `${window.location.origin}/auth-callback?callbackUrlPath=${redirectUrl}`,
                audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
                inviteCode,
                shortId,
              },
            })
          : appService.send({
              type: 'SET_ERROR',
              error: e.message,
            });
      });
    }
  }, [
    appService,
    shouldAuthenticate,
    getAccessTokenSilently,
    router,
    inviteCode,
    shortId,
    redirectUrl,
    loginWithRedirect,
  ]);

  // Redirect to URL when authenticated and user is loaded
  useEffectOnce(
    () => {
      router.replace(redirectUrl);
    },
    () =>
      !isLoading &&
      isAuthenticated &&
      viewer &&
      !isViewerLoading &&
      !!redirectUrl
  );

  return <Loading />;
};
