import * as React from "react";
import { SVGProps } from "react";
const SvgH = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 2.5h-1.25c-.69 0-1.25.56-1.25 1.25v4.583h-5V3.75c0-.69-.56-1.25-1.25-1.25H5c-.69 0-1.25.56-1.25 1.25v12.5c0 .69.56 1.25 1.25 1.25h1.25c.69 0 1.25-.56 1.25-1.25v-4.167h5v4.167c0 .69.56 1.25 1.25 1.25H15c.69 0 1.25-.56 1.25-1.25V3.75c0-.69-.56-1.25-1.25-1.25Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgH;
