import * as React from "react";

function SvgAppConfluence(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.206 6.484c.227-.368.48-.796.697-1.137a.695.695 0 00-.234-.946L18.141 1.62a.697.697 0 00-.993.23c-.18.302-.414.695-.669 1.116-1.794 2.956-3.598 2.595-6.851 1.044L5.152 1.887a.698.698 0 00-.929.33l-.008.018-2.156 4.869a.695.695 0 00.348.91c.948.446 2.832 1.333 4.529 2.15 6.116 2.956 11.303 2.758 15.27-3.68zM1.794 18.016c-.227.369-.48.797-.697 1.137a.695.695 0 00.234.946l4.528 2.782a.697.697 0 00.993-.23c.18-.302.414-.695.669-1.116 1.794-2.956 3.598-2.594 6.851-1.043l4.476 2.121a.698.698 0 00.937-.347l2.156-4.87a.695.695 0 00-.348-.91 537.503 537.503 0 01-4.529-2.15c-6.116-2.955-11.303-2.757-15.27 3.68z"
        fill="#2684FF"
      />
    </svg>
  );
}

export default SvgAppConfluence;
