import styled from '@xstyled/styled-components';
import React, { PropsWithChildren, useEffect } from 'react';
import { InView, useInView } from 'react-intersection-observer';

import { LoadingPulse } from '../components/LoadingPulse';

export interface InfiniteScrollProps {
  /**
   * Element or Document whose bounds are treated as the bounding box of the viewport
   */
  root?: Element | null;
  /**
   * loading more results
   */
  loading: boolean;
  /**
   * more items to load
   */
  hasMore: boolean;
  /**
   * inverse load
   */
  inverse?: boolean;
  /**
   * Distance in pixels before the end of the items that will trigger loadMore
   */
  threshold: number;
  /**
   * function called when threshold is triggered
   */
  loadMore: () => void;
}

const IntersectionTrigger = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8;
  padding-bottom: 10;
  font-size: 4xl;
`;

export const InfiniteScroll: React.FC<
  PropsWithChildren<InfiniteScrollProps>
> = ({
  root,
  loading = false,
  hasMore = false,
  inverse = false,
  threshold = 0,
  loadMore,
  children,
}) => {
  const { ref, inView } = useInView({
    root,
    rootMargin: `${threshold}px`,
    onChange: (inView) => inView && !loading && loadMore(),
  });

  useEffect(() => {
    if (inView && !loading) loadMore();
  }, [inView, loading, loadMore]);

  return (
    <>
      {inverse && hasMore && <div ref={ref} />}
      {children}
      {!inverse && hasMore && (
        <IntersectionTrigger ref={ref}>
          <>
            {/*// eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/*// @ts-expect-error app typings wrong */}
            <InView>
              {({ inView, ref }) => (
                <div ref={ref}>{inView ? <LoadingPulse /> : null}</div>
              )}
            </InView>
          </>
        </IntersectionTrigger>
      )}
    </>
  );
};
