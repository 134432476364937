import { COMMUNITY_TEMPLATES, CommunityTemplate, theme } from '@frond/shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled, system, SystemProps, x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Form } from '../../../../common/components/Form';
import { Icon } from '../../../../common/components/Icon';
import { Text } from '../../../../common/components/Text';
import { StepView } from '../../common/StepView';
import { StepHeading } from '../layout/StepHeading';

export type GroupsOnboardingFormData = yup.InferType<
  typeof GroupsOnboardingFormSchema
>;

export interface TemplatesStepProps {
  onNext: (templateReferenceId: string) => void;
  loading: boolean;
}

const GroupsOnboardingFormSchema = yup
  .object({
    templateReferenceId: yup.string().required(),
  })
  .defined();

const TemplateContainer = styled.div<SystemProps & { selected?: boolean }>`
  ${system}

  @media (hover: none) {
    &:hover {
      border-color: ${(p) =>
        p.selected ? theme.colors.brand[200] : theme.colors.gray[200]};
    }
  }
`;

const Template: React.FC<{
  template: CommunityTemplate;
  selected?: boolean;
  onClick: () => void;
}> = ({ template, selected, onClick }) => {
  const { i18n } = useTranslation();
  const { icon } = template;

  const name = template.name[i18n.language as 'en'] || template.name.en;
  const description =
    template.description[i18n.language as 'en'] || template.name.en;

  return (
    <TemplateContainer
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      cursor="pointer"
      p={5}
      border="default"
      borderColor={{
        _: selected ? 'brand.200' : 'gray.200',
        hover: 'brand.200',
      }}
      borderRadius="md-lg"
      backgroundColor={{
        _: selected ? 'brand.50' : 'gray.50',
      }}
      onClick={onClick}
      textAlign="left"
      spaceY={2}
    >
      <x.div
        display="flex"
        flexDirection="row"
        w="full"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text variant="md-semibold">{name}</Text>
        <Icon name={icon} size="6" />
      </x.div>
      <Text variant="md">{description}</Text>
    </TemplateContainer>
  );
};

export const TemplatesStep: React.FC<TemplatesStepProps> = ({
  onNext,
  loading,
}) => {
  const { t } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] =
    useState<CommunityTemplate | null>();

  const form = useForm<GroupsOnboardingFormData>({
    resolver: yupResolver(GroupsOnboardingFormSchema),
    mode: 'all',
  });

  const templateReferenceId = form.watch('templateReferenceId');

  const handleTemplateClick = (template: CommunityTemplate) => {
    setSelectedTemplate(template);
    form.setValue('templateReferenceId', template.id, {
      shouldValidate: true,
    });
  };

  const handleSubmit = () => onNext(templateReferenceId);

  return (
    <Form<GroupsOnboardingFormData> {...form} onSubmit={handleSubmit}>
      <StepHeading
        title={t('select_template_title')}
        text={t('select_template_description')}
      />
      <StepView loading={loading} dataTest="groups-onboarding-templates">
        <x.div
          display="grid"
          gridTemplateColumns={{
            _: `repeat(1, auto)`,
            md: `repeat(2, 310px)`,
          }}
          justifyContent="center"
          gap={5}
          w={{ _: 'full', md: '2xl' }}
          m="0 auto"
          pb={10}
        >
          {COMMUNITY_TEMPLATES.map((template) => (
            <Template
              key={template.id}
              template={template}
              selected={template.id === selectedTemplate?.id}
              onClick={() => handleTemplateClick(template)}
            />
          ))}
        </x.div>
      </StepView>
    </Form>
  );
};
