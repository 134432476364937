/**
 * Breakpoints for responsive design
 */
const viewports = {
  '480px': 480,
  '704px': 704,
  '768px': 768,
  '960px': 960,
  '992px': 992,
  '1004px': 1004,
  '1024px': 1024,
  '1060px': 1060,
  '1172px': 1172,
  '1280px': 1280,
  '1348px': 1348,
  '1440px': 1440,
  '1512px': 1512,
};

const screens = {
  _: 0,
  sm: viewports['480px'],
  'sm-2': viewports['704px'],
  md: viewports['768px'],
  'md-2': viewports['960px'],
  lg: viewports['992px'],
  'lg-2': viewports['1004px'],
  'lg-3': viewports['1060px'],
  xl: viewports['1280px'],
  '2xl': viewports['1440px'],

  'grid-sm': viewports['1024px'],
  'grid-md': viewports['1280px'],
  'grid-lg': viewports['1512px'],

  ...viewports,
};

export type Screens = typeof screens;

export default screens;
