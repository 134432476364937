import * as React from "react";

function SvgAppLinkedin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-linkedin_svg__clip0)">
        <path
          d="M.661 1.76c0-.946.791-1.713 1.767-1.713h20.37c.976 0 1.767.767 1.767 1.712v20.48c0 .945-.79 1.712-1.767 1.712H2.428c-.976 0-1.767-.767-1.767-1.712V1.76z"
          fill="#069"
        />
        <path
          d="M7.926 20.051V9.29H4.349V20.05h3.577zM6.138 7.821c1.247 0 2.023-.827 2.023-1.86-.023-1.056-.776-1.859-2-1.859s-2.023.803-2.023 1.86c0 1.032.776 1.859 1.976 1.859h.024zm3.767 12.23h3.577v-6.009c0-.32.024-.643.118-.872.258-.643.847-1.309 1.835-1.309 1.294 0 1.812.987 1.812 2.434v5.756h3.577v-6.17c0-3.305-1.764-4.844-4.118-4.844-1.93 0-2.776 1.079-3.247 1.813h.024V9.29H9.905c.046 1.01 0 10.762 0 10.762z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="app-linkedin_svg__clip0">
          <path
            fill="#fff"
            transform="translate(.661 -.001)"
            d="M0 0h23.904v24H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppLinkedin;
