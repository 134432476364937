import * as React from "react";
import { SVGProps } from "react";
const SvgPoll = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.75 3C4.24 3 3 4.24 3 5.75v12.5C3 19.76 4.24 21 5.75 21h12.5c1.51 0 2.75-1.24 2.75-2.75V5.75C21 4.24 19.76 3 18.25 3H5.75Zm0 1.5h12.5c.7 0 1.25.55 1.25 1.25v12.5c0 .7-.55 1.25-1.25 1.25H5.75c-.7 0-1.25-.55-1.25-1.25V5.75c0-.7.55-1.25 1.25-1.25ZM7.25 7a.75.75 0 1 0 0 1.5h6.5a.75.75 0 1 0 0-1.5h-6.5Zm0 4.25a.751.751 0 1 0 0 1.5h9.5a.751.751 0 1 0 0-1.5h-9.5Zm0 4.25a.751.751 0 1 0 0 1.5h3.5a.751.751 0 1 0 0-1.5h-3.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPoll;
