import * as React from 'react';
import { SVGProps } from 'react';

const SvgBellFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M19.249 18H4.751a1.74 1.74 0 0 1-1.478-.812 1.742 1.742 0 0 1-.106-1.682L4.5 12.825V9.69c0-4.12 3.136-7.493 7.139-7.682a7.454 7.454 0 0 1 5.537 2.064A7.433 7.433 0 0 1 19.5 9.501v3.324l1.325 2.666a1.75 1.75 0 0 1-1.576 2.51ZM11.674 2.76h.005-.005ZM9.045 19A3.002 3.002 0 0 0 12 21.5a3.002 3.002 0 0 0 2.955-2.5h-5.91Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBellFilled;
