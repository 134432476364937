/**
 * Settings sub-routes
 */

export const settingsRoutes = {
  account: () => `/account/account`,
  memberships: () => `/account/memberships`,
  notifications: () => `/account/notifications`,
  billing: () => `/account/billing`,
  organization: (shortId: string) => ({
    plans: () => `/${shortId}/settings/plans`,
    details: () => `/${shortId}/settings/details`,
    permissions: () => `/${shortId}/settings/permissions`,
    roles: () => `/${shortId}/settings/roles`,
    features: () => `/${shortId}/settings/features`,
    members: (showRequestsTab?: boolean) =>
      showRequestsTab
        ? `/${shortId}/settings/members?requests`
        : `/${shortId}/settings/members`,
    pricing: () => `/${shortId}/settings/pricing`,
    onboarding: () => `/${shortId}/settings/onboarding`,
    paywall: () => `/${shortId}/settings/paywall`,
    widget: () => `/${shortId}/settings/widget`,
    customDomain: () => `/${shortId}/settings/custom-domain`,
  }),
};
