import * as React from "react";
import { SVGProps } from "react";

const SvgAppAppleMusic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#app-apple-music_svg__a)"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path
        d="M19.485 24.005h-13.6a5.188 5.188 0 0 1-5.2-5.2v-13.6a5.19 5.19 0 0 1 5.2-5.2h13.6a5.19 5.19 0 0 1 5.2 5.2v13.6a5.19 5.19 0 0 1-5.2 5.2Z"
        fill="url(#app-apple-music_svg__b)"
      />
      <path
        d="M18.285 14.505v.7c0 .45-.04.898-.12 1.34a1.708 1.708 0 0 1-.72 1.12h-.02a2.47 2.47 0 0 1-1.38.5l-.38.04a1.908 1.908 0 0 1-1.58-.68 1.755 1.755 0 0 1-.28-1.76v-.02a1.85 1.85 0 0 1 1.3-1.16l1.5-.4a.859.859 0 0 0 .66-.84v-5.32a.324.324 0 0 0-.12-.26.347.347 0 0 0-.28-.06l-6.4 1.3a.475.475 0 0 0-.4.48v7.34c0 .45-.04.898-.12 1.34a1.707 1.707 0 0 1-.72 1.12h-.02a2.47 2.47 0 0 1-1.38.5l-.36.02a1.908 1.908 0 0 1-1.58-.68 1.755 1.755 0 0 1-.28-1.76v-.02a1.85 1.85 0 0 1 1.3-1.16l1.5-.4a.859.859 0 0 0 .66-.84v-8.96a.744.744 0 0 1 .6-.72l8.02-1.46a.629.629 0 0 1 .42.1.493.493 0 0 1 .18.38v10.22Z"
        fill="#BB58BB"
      />
      <path
        d="M18.285 14.505v.7c0 .45-.04.898-.12 1.34a1.708 1.708 0 0 1-.72 1.12h-.02a2.47 2.47 0 0 1-1.38.5l-.38.04a1.908 1.908 0 0 1-1.58-.68 1.755 1.755 0 0 1-.28-1.76v-.02a1.85 1.85 0 0 1 1.3-1.16l1.5-.4a.859.859 0 0 0 .66-.84v-5.32a.324.324 0 0 0-.12-.26.347.347 0 0 0-.28-.06l-6.4 1.3a.475.475 0 0 0-.4.48v7.34c0 .45-.04.898-.12 1.34a1.707 1.707 0 0 1-.72 1.12h-.02a2.47 2.47 0 0 1-1.38.5l-.36.02a1.908 1.908 0 0 1-1.58-.68 1.755 1.755 0 0 1-.28-1.76v-.02a1.85 1.85 0 0 1 1.3-1.16l1.5-.4a.859.859 0 0 0 .66-.84v-8.96a.744.744 0 0 1 .6-.72l8.02-1.46a.629.629 0 0 1 .42.1.493.493 0 0 1 .18.38v10.22Z"
        fill="url(#app-apple-music_svg__c)"
      />
      <path
        d="M18.285 14.505v.7c0 .45-.04.898-.12 1.34a1.708 1.708 0 0 1-.72 1.12h-.02a2.47 2.47 0 0 1-1.38.5l-.38.04a1.908 1.908 0 0 1-1.58-.68 1.755 1.755 0 0 1-.28-1.76v-.02a1.85 1.85 0 0 1 1.3-1.16l1.5-.4a.859.859 0 0 0 .66-.84v-5.32a.324.324 0 0 0-.12-.26.347.347 0 0 0-.28-.06l-6.4 1.3a.475.475 0 0 0-.4.48v7.34c0 .45-.04.898-.12 1.34a1.707 1.707 0 0 1-.72 1.12h-.02a2.47 2.47 0 0 1-1.38.5l-.36.02a1.908 1.908 0 0 1-1.58-.68 1.755 1.755 0 0 1-.28-1.76v-.02a1.85 1.85 0 0 1 1.3-1.16l1.5-.4a.859.859 0 0 0 .66-.84v-8.96a.744.744 0 0 1 .6-.72l8.02-1.46a.629.629 0 0 1 .42.1.493.493 0 0 1 .18.38v10.22Z"
        fill="url(#app-apple-music_svg__d)"
      />
      <path
        d="M18.285 14.505v.7c0 .45-.04.898-.12 1.34a1.708 1.708 0 0 1-.72 1.12h-.02a2.47 2.47 0 0 1-1.38.5l-.38.04a1.908 1.908 0 0 1-1.58-.68 1.755 1.755 0 0 1-.28-1.76v-.02a1.85 1.85 0 0 1 1.3-1.16l1.5-.4a.859.859 0 0 0 .66-.84v-5.32a.324.324 0 0 0-.12-.26.347.347 0 0 0-.28-.06l-6.4 1.3a.475.475 0 0 0-.4.48v7.34c0 .45-.04.898-.12 1.34a1.707 1.707 0 0 1-.72 1.12h-.02a2.47 2.47 0 0 1-1.38.5l-.36.02a1.908 1.908 0 0 1-1.58-.68 1.755 1.755 0 0 1-.28-1.76v-.02a1.85 1.85 0 0 1 1.3-1.16l1.5-.4a.859.859 0 0 0 .66-.84v-8.96a.744.744 0 0 1 .6-.72l8.02-1.46a.629.629 0 0 1 .42.1.493.493 0 0 1 .18.38v10.22Z"
        fill="url(#app-apple-music_svg__e)"
      />
      <path
        d="M18.285 14.505v.7c0 .45-.04.898-.12 1.34a1.708 1.708 0 0 1-.72 1.12h-.02a2.47 2.47 0 0 1-1.38.5l-.38.04a1.908 1.908 0 0 1-1.58-.68 1.755 1.755 0 0 1-.28-1.76v-.02a1.85 1.85 0 0 1 1.3-1.16l1.5-.4a.859.859 0 0 0 .66-.84v-5.32a.324.324 0 0 0-.12-.26.347.347 0 0 0-.28-.06l-6.4 1.3a.475.475 0 0 0-.4.48v7.34c0 .45-.04.898-.12 1.34a1.707 1.707 0 0 1-.72 1.12h-.02a2.47 2.47 0 0 1-1.38.5l-.36.02a1.908 1.908 0 0 1-1.58-.68 1.755 1.755 0 0 1-.28-1.76v-.02a1.85 1.85 0 0 1 1.3-1.16l1.5-.4a.859.859 0 0 0 .66-.84v-8.96a.744.744 0 0 1 .6-.72l8.02-1.46a.629.629 0 0 1 .42.1.493.493 0 0 1 .18.38v10.22Z"
        fill="#fff"
      />
    </g>
    <defs>
      <radialGradient
        id="app-apple-music_svg__c"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(90 -4.937 13.146) scale(10.1226)"
      >
        <stop stopColor="#7470F9" />
        <stop offset={1} stopColor="#7A70FE" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="app-apple-music_svg__d"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-105.538 14.09 1.733) scale(8.17081)"
      >
        <stop stopColor="#4CA3F8" />
        <stop offset={0.362} stopColor="#4CA4F7" />
        <stop offset={1} stopColor="#4AA2F9" stopOpacity={0} />
      </radialGradient>
      <linearGradient
        id="app-apple-music_svg__b"
        x1={12.684}
        y1={24.005}
        x2={12.684}
        y2={0.005}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD355A" />
        <stop offset={1} stopColor="#FD5163" />
      </linearGradient>
      <linearGradient
        id="app-apple-music_svg__e"
        x1={10.345}
        y1={6.882}
        x2={13.87}
        y2={13.281}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF6380" />
        <stop offset={1} stopColor="#F65E79" stopOpacity={0} />
      </linearGradient>
      <clipPath id="app-apple-music_svg__a">
        <path fill="#fff" transform="translate(.685 .005)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAppAppleMusic;
