import * as React from "react";

function SvgRepeat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.41421 19.0001L9.70711 21.293L8.29289 22.7072L3.58579 18.0001L8.29289 13.293L9.70711 14.7072L7.41421 17.0001H16C17.6569 17.0001 19 15.6569 19 14.0001V11.0001H21V14.0001C21 16.7615 18.7614 19.0001 16 19.0001H7.41421ZM16.5868 5.00108L14.2929 2.70718L15.7071 1.29297L20.4142 6.00008L15.7071 10.7072L14.2929 9.29297L16.5848 7.00108H8C6.34315 7.00108 5 8.34422 5 10.0011V13.0011H3V10.0011C3 7.23965 5.23858 5.00108 8 5.00108H16.5868Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgRepeat;
