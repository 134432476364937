import { routes, theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { differenceInDays } from 'date-fns';
import { useTranslation } from 'next-i18next';

import {
  refetchUpsellsQuery,
  UpsellTypes,
  useHideUpsellMutation,
  useUpsellsQuery,
} from '../../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../../auth/hooks/useOptionalOrganization';
import { useViewer } from '../../../auth/hooks/useViewer';
import { Button } from '../../../common/components/Button';
import { Icon } from '../../../common/components/Icon';
import { Text } from '../../../common/components/Text';

export const GroupsSidebarUpsell = () => {
  const { organization } = useOptionalOrganization();
  const { viewer } = useViewer();
  const { t } = useTranslation('common');

  const { data } = useUpsellsQuery({
    variables: {
      organizationId: organization?.id || '',
    },
    skip: !organization,
  });

  const [mutateHideUpsell] = useHideUpsellMutation({
    refetchQueries: [
      refetchUpsellsQuery({
        organizationId: organization?.id || '',
      }),
    ],
  });

  const trailUpsell = data?.upsells?.find(
    (upsell) => upsell.type === UpsellTypes.Trial
  );

  const membership = viewer?.organizationMemberships?.find(
    (organizationMembership) =>
      organization?.id === organizationMembership.organization.id
  );

  if (
    !organization ||
    !trailUpsell ||
    trailUpsell?.hidden ||
    (membership &&
      differenceInDays(new Date(membership.createdAt), new Date()) < 3)
  )
    return null;

  const handleClick = () => {
    mutateHideUpsell({
      variables: {
        input: {
          upsellId: trailUpsell.id,
        },
      },
    });
  };

  return (
    <x.div
      border="default"
      borderColor="white"
      borderRadius="md"
      boxShadow="sm"
      backgroundColor="gray.50"
      position="fixed"
      bottom={theme.sizes[4]}
      left="84px"
      right={theme.sizes[4]}
      p={4}
      w="250px"
      boxSizing="border-box"
    >
      <x.div
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Text color="brand.300" variant="md-semibold" mb={2}>
          Frond Pro
        </Text>
        <Button
          variant="icon"
          leftElement={<Icon name="close" size="3" />}
          onClick={handleClick}
        />
      </x.div>
      <Text variant="sm" mb={4}>
        {t('access_features_trial')}
      </Text>
      <Button
        label="Try for free"
        href={routes.settings.organization(organization.shortId).plans()}
      />
    </x.div>
  );
};
