import {
  MyEditor,
  MyOverrideByKey,
  MyPlatePlugin,
  MyValue,
} from '@frond/shared';
import {
  createPlateEditor,
  CreatePlateEditorOptions,
  createPlugins,
  createTEditor,
  getTEditor,
  PlatePluginComponent,
  useEditorRef,
  useEditorState,
  usePlateActions,
  usePlateEditorRef,
  usePlateEditorState,
  usePlateSelectors,
} from '@udecode/plate-common';
import { Text } from 'slate';

export const getTrimmedContent = (
  content: MyValue,
  length: number
): MyValue => {
  return content.map((n) => {
    if (Text.isText(n)) {
      const text = n.text;
      n.text = text.substring(0, text.length - length);
    } else if ('children' in n) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      n.children = getTrimmedContent(n.children, length);
    }
    return n;
  });
};

/**
 * Plate store, Slate context
 */

export const getMyEditor = (editor: MyEditor) =>
  getTEditor<MyValue, MyEditor>(editor);
export const useMyEditorRef = () => useEditorRef<MyValue, MyEditor>();
export const useMyEditorState = () => useEditorState<MyValue, MyEditor>();
export const useMyPlateEditorRef = (id?: string) =>
  usePlateEditorRef<MyValue, MyEditor>(id);
export const useMyPlateEditorState = (id?: string) =>
  usePlateEditorState<MyValue, MyEditor>(id);
export const useMyPlateSelectors = (id?: string) =>
  usePlateSelectors<MyValue, MyEditor>(id);

export const useMyPlateActions = (id?: string) =>
  usePlateActions<MyValue, MyEditor>(id);

/**
 * Utils
 */
export const createMyEditor = () => createTEditor() as MyEditor;
export const createMyPlateEditor = (
  options: CreatePlateEditorOptions<MyValue, MyEditor> = {}
) => createPlateEditor<MyValue, MyEditor>(options);
export const createMyPlugins = (
  plugins: MyPlatePlugin[],
  options?: {
    components?: Record<string, PlatePluginComponent>;
    overrideByKey?: MyOverrideByKey;
  }
) => createPlugins<MyValue, MyEditor>(plugins, options);
