import { getTimeZoneName, theme } from '@frond/shared';
import styled, { system, SystemProps, x } from '@xstyled/styled-components';
import { formatInTimeZone } from 'date-fns-tz';
import { useFormContext } from 'react-hook-form';

import { Text } from '../../../common/components/Text';
import { Tooltip } from '../../../common/components/Tooltip';
import { leftAlign } from '../../../common/utils/position';
import { extractError } from '../../../hello/utils/form.utils';
import { EventFormData } from './EventForm';

const StyledDateInput = styled.input<SystemProps>`
  ${theme.typographyStyles.text.md};
  ${system};

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

const StyledTimeInput = styled.input<SystemProps>`
  ${theme.typographyStyles.text.md};
  ${system};

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

const EventFormTime: React.FC<{
  formTimeField: 'startAt' | 'endAt';
}> = ({ formTimeField }) => {
  const form = useFormContext<EventFormData>();
  const startAt = form.watch('startAt');
  const endAt = form.watch('endAt');
  const fromOrTo = form.watch(formTimeField);
  const timeZone = form.watch('timeZone');

  const handleTimeChange = (fromOrTo: 'startAt' | 'endAt') => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const date = new Date(startAt);
      const inputDate = event.target.valueAsDate;

      if (!inputDate) return;

      const updatedDate = date.setHours(
        inputDate.getUTCHours(),
        inputDate.getUTCMinutes(),
        0
      );

      if (fromOrTo === 'startAt' && new Date(updatedDate) >= new Date(endAt)) {
        const updatedEndAt = new Date(updatedDate);
        updatedEndAt.setMinutes(updatedEndAt.getMinutes() + 30);
        form.setValue('endAt', updatedEndAt.toISOString(), {
          shouldValidate: true,
          shouldDirty: true,
        });
      }

      form.setValue(fromOrTo, new Date(updatedDate).toISOString(), {
        shouldValidate: true,
        shouldDirty: true,
      });
    };
  };

  const error = extractError<EventFormData>(form.formState, formTimeField);

  return (
    <Tooltip
      disabled={!error}
      maxWidth="auto"
      size="small"
      position={leftAlign}
      label={<Text variant="sm-semibold">{error}</Text>}
      aria-label={error || ''}
    >
      <StyledTimeInput
        type="time"
        boxSizing="border-box"
        display="flex"
        flexDirection="row"
        alignItems="center"
        border="default"
        borderRadius="sm"
        borderColor={error ? 'red.300' : 'gray.200'}
        p={2}
        px={4}
        spaceX={2}
        value={formatInTimeZone(fromOrTo, timeZone, 'HH:mm')}
        onChange={handleTimeChange(formTimeField)}
      />
    </Tooltip>
  );
};

const EventFormDate: React.FC = () => {
  const form = useFormContext<EventFormData>();

  const startAt = form.watch('startAt');
  const endAt = form.watch('endAt');
  const timeZone = form.watch('timeZone');

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const startAtDate = new Date(startAt);
    const inputDate = event.currentTarget.valueAsDate;

    if (!inputDate) return;

    const updatedStartAtDate = startAtDate.setFullYear(
      inputDate.getUTCFullYear(),
      inputDate.getUTCMonth(),
      inputDate.getUTCDate()
    );

    form.setValue('startAt', new Date(updatedStartAtDate).toISOString(), {
      shouldDirty: true,
    });

    const endAtDate = new Date(endAt);
    const updatedEndAtDate = endAtDate.setFullYear(
      inputDate.getUTCFullYear(),
      inputDate.getUTCMonth(),
      inputDate.getUTCDate()
    );

    form.setValue('endAt', new Date(updatedEndAtDate).toISOString(), {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const error = extractError<EventFormData>(form.formState, 'startAt');

  return (
    <Tooltip
      disabled={!error}
      maxWidth="auto"
      size="small"
      position={leftAlign}
      label={<Text variant="sm-semibold">{error}</Text>}
      aria-label={error || ''}
    >
      <StyledDateInput
        type="date"
        border="default"
        borderRadius="sm"
        borderColor={error ? 'red.300' : 'gray.200'}
        w="100%"
        boxSizing="border-box"
        p={2}
        px={4}
        value={formatInTimeZone(startAt, timeZone, 'yyyy-MM-dd')}
        onChange={handleDateChange}
      />
    </Tooltip>
  );
};

export const EventFormDateTime = () => {
  const form = useFormContext<EventFormData>();

  const timeZone = form.watch('timeZone');

  return (
    <x.div display="flex" alignItems="center" spaceX={3}>
      <x.div flex={1}>
        <EventFormDate />
      </x.div>

      <x.div display="flex" alignItems="center" spaceX={2}>
        <EventFormTime formTimeField="startAt" />
        <Text color="gray.300">to</Text>
        <EventFormTime formTimeField="endAt" />
        <Text color="gray.500" variant="sm-medium">
          {getTimeZoneName(timeZone)}
        </Text>
      </x.div>
    </x.div>
  );
};
