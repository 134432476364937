import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import {
  EventCoreFragment,
  refetchEventQuery,
  refetchEventsQuery,
  refetchPostsByEventQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
} from '../../../../../generated/types-and-hooks';
import { GROUPS_PER_PAGE, POSTS_PER_PAGE } from '../../../../config/constants';
import { useOrganization } from '../../../auth/hooks/useOrganization';
import { Heading } from '../../../common/components/Heading';
import { GroupFormSubmitButton } from '../../../groups/components/GroupEditModal/GroupForm/GroupFormSubmitButton';
import { GroupsModal } from '../../../groups/components/GroupsModal';
import { NewEventCover } from './EventCover';
import { EventForm, EventFormData } from './EventForm';
import { EventFormFields } from './EventFormFields';

export const EventModal: FC<{
  event?: EventCoreFragment;
  isOpen: boolean;
  onDismiss: () => void;
}> = ({ event, isOpen = false, onDismiss }) => {
  const { organization } = useOrganization();
  const { t } = useTranslation(['groups', 'common']);
  const router = useRouter();

  const [mutateCreateEvent, { loading: loadingCreateEvent }] =
    useCreateEventMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        refetchEventsQuery({
          organizationId: organization.id,
          first: GROUPS_PER_PAGE - 1,
        }),
      ],
      onCompleted: ({ createEvent }) => {
        onDismiss?.();
        if (createEvent.__typename === 'Event') {
          router.push(
            routes.groups
              .organization(organization.shortId)
              .event(createEvent.id)
          );
        }
      },
    });

  const [mutateUpdateEvent, { loading: loadingUpdateEvent }] =
    useUpdateEventMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        refetchEventsQuery({
          organizationId: organization.id,
          first: GROUPS_PER_PAGE - 1,
        }),
        refetchEventQuery({
          id: event?.id || '',
        }),
        refetchPostsByEventQuery({
          eventId: event?.id || '',
          first: POSTS_PER_PAGE,
        }),
      ],
      onCompleted: onDismiss,
    });

  const loading = loadingCreateEvent || loadingUpdateEvent;

  const handleCreateEvent = ({ ...data }: EventFormData) => {
    const {
      title,
      recurringOccurrencesLimit,
      recurringCronExpression,
      ...values
    } = data;

    mutateCreateEvent({
      variables: {
        input: {
          organizationId: organization.id,
          name: title,
          recurringOccurrencesLimit:
            recurringOccurrencesLimit === 'never'
              ? null
              : Number(recurringOccurrencesLimit),
          recurringCronExpression:
            recurringCronExpression === 'never'
              ? null
              : recurringCronExpression,
          ...values,
        },
      },
    });
  };

  const handleEditEvent = ({ ...data }: EventFormData) => {
    const {
      title,
      recurringOccurrencesLimit,
      recurringCronExpression,
      ...values
    } = data;

    mutateUpdateEvent({
      variables: {
        input: {
          eventId: event?.id || '',
          name: title,
          recurringOccurrencesLimit:
            recurringOccurrencesLimit === 'never'
              ? null
              : Number(recurringOccurrencesLimit),
          recurringCronExpression:
            recurringCronExpression === 'never'
              ? null
              : recurringCronExpression,
          ...values,
        },
      },
    });
  };

  return (
    <GroupsModal
      width="560px"
      isOpen={isOpen}
      ariaLabel={
        event
          ? t('edit_event', { ns: 'common' })
          : t('new_event', { ns: 'common' })
      }
      onDismiss={onDismiss}
      variant="tabbed"
    >
      <EventForm
        event={event}
        onSubmit={event ? handleEditEvent : handleCreateEvent}
      >
        <x.div
          borderRadius={{ _: 0, sm: 'lg' }}
          overflow="hidden"
          display="flex"
          flexDirection="column"
        >
          <x.div
            px={6}
            pt={4}
            pb={4}
            backgroundColor="gray.50"
            display="flex"
            flexDirection="column"
            spaceY={5}
          >
            <x.div
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading variant="lg">
                {event
                  ? t('edit_event', { ns: 'common' })
                  : t('new_event', { ns: 'common' })}
              </Heading>
              <GroupFormSubmitButton
                loading={loading}
                label={
                  event
                    ? t('save', { ns: 'common' })
                    : t('create', { ns: 'common' })
                }
              />
            </x.div>
          </x.div>
          <x.div
            flex={1}
            overflowY="auto"
            bg="white"
            display="flex"
            flexDirection="column"
          >
            <NewEventCover />
            <x.div display="flex" flexDirection="column" p={4} pt={0} flex={1}>
              <EventFormFields variant={event ? 'edit' : 'create'} />
            </x.div>
          </x.div>
        </x.div>
      </EventForm>
    </GroupsModal>
  );
};
