import { routes } from '@frond/shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import isEmailValidator from 'validator/lib/isEmail';
import * as yup from 'yup';
import { string } from 'yup';

import {
  refetchMembersQuery,
  useInviteUsersMutation,
} from '../../../../generated/types-and-hooks';
import { IS_DEMO } from '../../../config/constants';
import { useIsViewerAdmin } from '../../auth/hooks/useViewer';
import { Button } from '../../common/components/Button';
import { Form } from '../../common/components/Form';
import { Input } from '../../common/components/Input';
import { ShareLink } from '../../common/components/ShareLink';
import {
  TabbedModal,
  TabbedModalContentInset,
} from '../../common/components/TabbedModal';
import { Text } from '../../common/components/Text';
import { Toast } from '../../common/components/Toast';
import { useEffectOnce } from '../../common/hooks/useEffectOnce';
import { RestrictedDemoActionModal } from '../../demo/RestrictedDemoActionModal';
import { GroupsSidebarOrganization } from '../../groups/components/GroupsSidebar/GroupsSidebar';
import { getAddMemberText } from '../../invites/utils';
import { ResetInviteLinkButton } from '../../settings/components/ResetInviteLinkButton';

const FormSchema = yup
  .object({
    emails: yup
      .string()
      .required('An email address is required')
      .test({
        test: (value, ctx) => {
          const separatedValuesByComma = value.split(',');

          for (const email of separatedValuesByComma) {
            if (!email) {
              continue;
            }
            const normalizedEmail = email.trim().toLowerCase();
            const emailIsValid = string()
              .email()
              .required()
              .test((v) => isEmailValidator(v))
              .isValidSync(normalizedEmail);
            if (!emailIsValid) {
              return ctx.createError({
                message: `Invalid email address (${normalizedEmail})`,
                path: 'emails',
              });
            }
          }

          return true;
        },
      }),
  })
  .defined();

type OrganizationShareFormData = yup.InferType<typeof FormSchema>;

export const OrganizationShareModal: React.FC<{
  isOpen: boolean;
  onDismiss: () => void;
  organization: GroupsSidebarOrganization;
}> = ({ isOpen, onDismiss, organization }) => {
  const { t } = useTranslation('common');
  const [invitesSent, setInvitesSent] = useState(false);
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const isViewerAdmin = useIsViewerAdmin(organization);
  const [mutateInviteUsers, { loading }] = useInviteUsersMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      refetchMembersQuery({
        organizationId: organization.id,
      }),
    ],
  });

  const form = useForm<OrganizationShareFormData>({
    resolver: yupResolver(FormSchema),
  });

  const handleDismiss = () => {
    setShowModal(false);
    form.reset();
    onDismiss();
  };

  const emails = form.watch('emails');

  const handleSubmit = (data: OrganizationShareFormData) => {
    const emails = data.emails
      .split(',')
      .filter((email) => !!email)
      .map((email) => email.trim().toLowerCase());

    mutateInviteUsers({
      variables: {
        input: {
          organizationId: organization.id,
          emails,
        },
      },
      onCompleted: () => {
        setInvitesSent(true);
        form.reset();
      },
      onError: () =>
        setError(
          emails.length > 1
            ? 'Something went wrong. Not all users could be invited.'
            : "Something went wrong. The user couldn't be invited."
        ),
    });
  };

  useEffectOnce(
    () => {
      setShowModal(true);
      const newQueries = router.query;
      delete newQueries.showShare;
      router.replace(
        { pathname: router.pathname, query: newQueries },
        undefined
      );
    },
    () => router.query.showShare === ''
  );

  if (IS_DEMO) {
    return (
      <RestrictedDemoActionModal isOpen={isOpen} onDismiss={handleDismiss} />
    );
  }

  return (
    <>
      <TabbedModal
        isOpen={isOpen || showModal}
        onDismiss={handleDismiss}
        title={t('add_members')}
        ariaLabel={t('add_members')}
        tabs={{
          members: (
            <Form<OrganizationShareFormData> {...form} onSubmit={handleSubmit}>
              <TabbedModalContentInset>
                <x.div spaceY={8}>
                  <x.div spaceY={2}>
                    <Text>
                      {getAddMemberText(organization, isViewerAdmin, t)}
                    </Text>
                    <x.div spaceY={3}>
                      <Input
                        {...form.register('emails')}
                        onChange={(e) => {
                          form.register('emails').onChange(e);

                          if (invitesSent) {
                            setInvitesSent(false);
                          }
                        }}
                        fullWidth
                        isMultiLine
                        rows={4}
                        placeholder="email1@example.com, email2@example.com"
                        error={form.formState.errors.emails?.message}
                      />
                      {form.formState.errors.emails?.message ? (
                        <Text variant="sm" color="red.300">
                          {form.formState.errors.emails?.message}
                        </Text>
                      ) : null}
                      {invitesSent ? (
                        <Text variant="sm" color="brand.300">
                          Invites sent!
                        </Text>
                      ) : null}
                      <Button
                        label={t('invite')}
                        loading={loading}
                        disabled={!form.formState.isDirty || !emails}
                        type="submit"
                      />
                    </x.div>
                  </x.div>

                  <x.div spaceY={4}>
                    <ShareLink
                      organization={organization}
                      route={routes.groups
                        .organization(organization.shortId)
                        .root()}
                    />

                    <ResetInviteLinkButton organization={organization} />
                  </x.div>
                </x.div>
              </TabbedModalContentInset>
            </Form>
          ),
        }}
      />

      {error && (
        <Toast
          variant="error"
          message={error}
          onDisappear={() => setError(null)}
        />
      )}
    </>
  );
};
