import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { AdminSubscriptionPlanId } from '../../../../generated/graphql-request-api-sdk';
import { PLANS } from '../config/plans';

export const getAdminPlanNameTranslationKey = (
  adminPlanId?: AdminSubscriptionPlanId
) => PLANS.find((plan) => plan.planId === adminPlanId)?.i18nKey || 'free';

export const AdminPlanName: FC<{ adminPlanId?: AdminSubscriptionPlanId }> = ({
  adminPlanId,
}) => {
  const { t } = useTranslation();
  const translationKey = getAdminPlanNameTranslationKey(adminPlanId);

  return <>{t(translationKey)}</>;
};
