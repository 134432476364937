import { x } from '@xstyled/styled-components';
import React, { FC } from 'react';

type VideoRecordViewCountdownProps = {
  countdown: number;
};

export const VideoRecordViewCountdown: FC<VideoRecordViewCountdownProps> = ({
  countdown,
}) => {
  return (
    <x.div
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
    >
      <x.div
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="full"
        h="180px"
        w="180px"
        bg="rgba(0, 0, 0, 0.5);"
      >
        <x.p color="white" fontSize="80px" lineHeight="100px">
          {countdown}
        </x.p>
      </x.div>
    </x.div>
  );
};
