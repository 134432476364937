import { useSelector } from '@xstate/react';
import { useContext } from 'react';

import { AppContext } from '../../common/machine/appContext';
import { AppMachineOrganization } from '../../common/machine/appMachine';

/**
 * Returns organization either current or last viewed organization.
 * Throws error if organization is not set.
 */
export const useOrganization = (): {
  organization: NonNullable<AppMachineOrganization>;
} => {
  const { appService } = useContext(AppContext);
  const organization = useSelector(
    appService,
    (state) => state.context.organization
  );

  if (!organization) {
    throw new Error('organization required for useOrganization');
  }

  return {
    organization,
  };
};
