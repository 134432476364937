import * as React from "react";

function SvgAppWikipedia(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-wikipedia_svg__clip0)">
        <path
          d="M22.166 5.167c-.806 0-.772-.167-2.32-.167v.595h.199c.404.04 1.7.295 1.236 1.43l-4.373 10.128-2.834-5.702 2.4-4.286c.227-.347 1.102-1.532 2.48-1.57V5c-1.479 0-1.672.167-2.482.167-.875 0-.838-.167-2.519-.167v.595c.362.032 2.045.302 1.431 1.465-.046.088-.076.16-.098.225l-1.702 3.18-1.702-3.425c-.145-.386-.264-1.463 1.11-1.442l-.024-.003h.183V5c-1.791 0-1.855.167-2.837.167C9.254 5.167 9.746 5 7.71 5v.595h.105c.267.013 1.226.167 2 1.591l2.59 5.485-2.454 4.587L5.363 7.072c-.083-.304-.225-1.495 1.236-1.474l-.025-.003h.38V5c-1.922 0-2.174.167-3.227.167C2.59 5.167 2.638 5 .453 5v.595h.435l-.225.01s1.688-.103 2.561 1.835c.021.047.044.08.066.123L8.754 20l.526.008 3.575-6.382L15.866 20l.05-.003-.001.003.49.008L22.313 7.09c.22-.418.883-1.46 2.084-1.495h.057V5c-1.362 0-1.54.167-2.287.167z"
          fill="#1B1B1B"
        />
      </g>
      <defs>
        <clipPath id="app-wikipedia_svg__clip0">
          <path fill="#fff" transform="translate(.453)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppWikipedia;
