import * as React from "react";

function SvgAppIntercom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-intercom_svg__clip0)">
        <path
          d="M20.8 13.195a.8.8 0 01-1.6 0V6a.8.8 0 011.6 0v7.195zm-.28 5.008c-.123.106-3.086 2.593-8.52 2.593-5.434 0-8.397-2.487-8.52-2.593a.8.8 0 011.039-1.217c.047.04 2.693 2.21 7.481 2.21 4.848 0 7.453-2.186 7.48-2.208a.8.8 0 011.04 1.215zM3.2 6a.8.8 0 011.6 0v7.195a.8.8 0 01-1.6 0V6zm4-1.6a.8.8 0 011.6 0v10.688a.8.8 0 01-1.6 0V4.4zm4-.405a.8.8 0 011.6 0v11.6a.8.8 0 01-1.6 0v-11.6zm4 .405a.8.8 0 011.6 0v10.688a.8.8 0 01-1.6 0V4.4zM21 0H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3V3a3 3 0 00-3-3z"
          fill="#1F8DED"
        />
      </g>
      <defs>
        <clipPath id="app-intercom_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppIntercom;
