export const URL_TRANSFORMATION = (
  url?: string | null
): string | undefined | null => {
  if (!url) return undefined;

  try {
    const trimUrl = new URL(url).toString().trim();

    if (trimUrl.startsWith('https://') || trimUrl.startsWith('http://')) {
      return trimUrl;
    } else {
      return `https://${trimUrl}`;
    }
  } catch {
    return url;
  }
};

export const normalizeUrl = (url: string) => {
  const trimUrl = url.trim();

  if (trimUrl.startsWith('https://')) {
    return trimUrl;
  } else if (trimUrl.startsWith('http://')) {
    return trimUrl.replace('http://', 'https://');
  } else {
    return `https://${trimUrl}`;
  }
};
