import { useDown, x } from '@xstyled/styled-components';
import React from 'react';

import { Giphy, Image, Video } from '../../../../generated/types-and-hooks';
import { getAnswerNavigationLinkProps } from '../../questions/hooks/useAnswerNavigation';
import { PostCardGiphy } from './PostCardGiphy';
import { PostCardImage } from './PostCardImage';
import { PostCardVideo } from './PostCardVideo';
import { ProcessingMedia } from './postComposerMachine';

type PostCardMediaItemProps = {
  media: Video | Image | Giphy | ProcessingMedia;
  linkProps?: ReturnType<typeof getAnswerNavigationLinkProps>;
  objectFit?: 'cover' | 'contain';
  maxW?: string;
  maxH?: string;
  i?: number;
  onClick?: () => void;
};

export const PostCardMediaItem = ({
  media,
  linkProps,
  onClick,
  i,
  objectFit,
  maxW,
  maxH,
}: PostCardMediaItemProps) => {
  const smallScreen = useDown('lg');

  // Videos click behavior differs - plays inline on mobile
  const handleClickVideo: React.MouseEventHandler = () => {
    if (!smallScreen) {
      onClick?.();
      return false; // preventDefault
    }
  };

  let children = null;
  if ('publicId' in media) {
    children = (
      <PostCardImage
        image={media}
        linkProps={linkProps}
        onClick={onClick}
        objectFit={objectFit}
        maxW={maxW}
        maxH={maxH}
      />
    );
  } else if ('giphyUrl' in media) {
    children = <PostCardGiphy giphy={media} linkProps={linkProps} />;
  } else if ('status' in media) {
    children = <PostCardVideo video={media} onClick={handleClickVideo} />;
  }

  return i === 0 ? (
    children
  ) : (
    <x.div position="absolute" w="full" maxHeight="100%" h="full">
      {children}
    </x.div>
  );
};
