import * as React from "react";
import { SVGProps } from "react";
const SvgBookmarkFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.25 21.5a.746.746 0 0 1-.438-.142L12 17.174l-5.812 4.184A.75.75 0 0 1 5 20.75v-15A3.254 3.254 0 0 1 8.25 2.5h7.5A3.254 3.254 0 0 1 19 5.75v15a.75.75 0 0 1-.75.75Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgBookmarkFilled;
