import {
  MenuItem,
  MenuItems,
  MenuPopover,
  MenuPopoverProps,
} from '@reach/menu-button';
import { Position } from '@reach/popover';
import styled, { css } from '@xstyled/styled-components';
import React from 'react';

import { topCenter } from '../utils/position';

export interface MenuListProps extends MenuPopoverProps {
  style?: React.CSSProperties;

  /**
   * Element rendered top of the menu items
   */
  heading?: JSX.Element;

  /**
   * Variant for dropdown size
   */
  variant?: 'small';

  /**
   * menu position function
   */
  position?: Position;

  /**
   * Callback on click on menu items
   */
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

  /**
   * List of menu items
   */
  children:
    | React.ReactElement<typeof MenuItem>
    | React.ReactElement<typeof MenuItem>[];
}

const smallMixin = css`
  [data-reach-menu-items],
  [data-reach-menu-item] {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  [data-reach-menu-items] {
    padding-left: 4;
    padding-right: 4;
  }
`;

const StyledMenuPopover = styled(MenuPopover)<{ variant?: string }>`
  z-index: modal;

  border: 0;
  border-radius: md;

  padding: 0;

  background-color: white;
  box-shadow: sm;

  [data-reach-menu-items] {
    border: 0;
    background: none;
    padding: 4 6;
  }

  [data-reach-menu-item] {
    padding: 2 0;
  }

  [data-reach-menu-item][data-selected] {
    color: inherit;
    background: none;
  }

  ${(p) => p.variant == 'small' && smallMixin}
`;

export const MenuList: React.FC<MenuListProps> = ({
  position = topCenter,
  ...rest
}) => {
  return (
    <StyledMenuPopover position={position} {...rest}>
      {rest.heading}
      <MenuItems>{rest.children}</MenuItems>
    </StyledMenuPopover>
  );
};
