import { SHORT_ID_REGEX } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { Input } from '../../../../common/components/Input';
import { Link } from '../../../../common/components/Link';
import { ProfileAvatarInput } from '../../../../common/components/ProfileAvatarInput';
import { Switch } from '../../../../common/components/Switch';
import { Text } from '../../../../common/components/Text';
import { extractError } from '../../../../hello/utils/form.utils';
import { OrganizationFormShortId } from './OrganizationFormShortId';

export type OrganizationFormProps = {
  editable?: boolean;
  variant: 'create' | 'edit';
};

export const OrganizationFormSchema = yup
  .object({
    organizationId: yup.string().nullable(),
    name: yup.string().max(255).required('Please enter a community name'),
    description: yup.string().nullable(),
    logoId: yup.string().max(1024).nullable(),
    shortId: yup
      .string()
      .matches(new RegExp(SHORT_ID_REGEX))
      .required('Please enter a community url'),
    isPublic: yup.boolean().nullable(),
  })
  .defined();

export type FormData = yup.InferType<typeof OrganizationFormSchema>;

export const OrganizationForm: React.FC<OrganizationFormProps> = ({
  editable = true,
  variant,
}) => {
  const form = useFormContext<FormData>();
  const logoId = form.watch('logoId');
  const { t } = useTranslation();

  const formSetOptions = {
    shouldDirty: true,
    shouldValidate: true,
  };

  return (
    <x.div w="full" maxWidth="512px" mx="auto">
      <x.div mb={8}>
        <ProfileAvatarInput
          name="logoId"
          profileImageId={logoId}
          onUploadSuccess={(image) => {
            if ('publicId' in image) {
              form.setValue('logoId', image.publicId, formSetOptions);
            }
          }}
          onRemove={() => form.setValue('logoId', null, formSetOptions)}
          showAvatarPicker={false}
          editable={editable}
          label="logo"
        />
      </x.div>
      <x.div spaceY={4} mb={10}>
        <Input
          {...form.register('name', {
            required: t('error_missing_community_name'),
          })}
          label={t('community_name_label')}
          placeholder={t('community_name_placeholder')}
          disabled={!editable}
          error={extractError<FormData>(form.formState, 'name')}
          fullWidth
        />
        <Input
          {...form.register('description')}
          label={t('community_description_label')}
          placeholder={t('community_description_placeholder')}
          rows={3}
          disabled={!editable}
          fullWidth
          isMultiLine
        />
        <OrganizationFormShortId />
      </x.div>

      {variant === 'create' ? (
        <x.div spaceY={3} mb={16} textAlign="left">
          <x.div display="flex" justifyContent="space-between">
            <Text variant="md-semibold">
              {t('make_community_public', { ns: 'common' })}
            </Text>
            <Switch
              onCheckedChange={(checked) => {
                form.setValue('isPublic', checked, formSetOptions);
              }}
              {...form.register('isPublic')}
            />
          </x.div>
          <Text>
            {t('make_community_public_description', { ns: 'common' })} –{' '}
            <Link href="/faq" target="_blank">
              {t('make_community_public_description_faq', { ns: 'common' })}
            </Link>
          </Text>
        </x.div>
      ) : null}
    </x.div>
  );
};
