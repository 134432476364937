import styled from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { Modal } from '../../../common/components/Modal';
import { SignInContent } from './SignInContent';

const StyledModal = styled(Modal)`
  &[data-reach-dialog-overlay] {
    padding: 0;
  }

  [data-reach-dialog-content] {
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    height: 100vh;
    padding: 6;
    border-radius: 0;
  }

  @media (min-width: 776px) {
    &[data-reach-dialog-overlay] {
      padding-top: 10;
      padding-bottom: 10;
    }

    [data-reach-dialog-content] {
      width: lg;
      height: auto;
      border-radius: lg;
      margin-top: 10vh;
      margin-bottom: 10vh;
    }
  }
`;

type SignInModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onLoginAttempt?: () => void;
  shouldRedirect?: boolean;
  variant?: 'default' | 'public';
};

export const SignInModal: FC<SignInModalProps> = ({
  isOpen,
  onDismiss,
  onLoginAttempt,
  shouldRedirect = true,
  variant = 'default',
}) => {
  const router = useRouter();

  return (
    <StyledModal
      closeButtonVariant="v2"
      ariaLabel="Sign in"
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <SignInContent
        shouldRedirect={shouldRedirect}
        onLoginAttempt={onLoginAttempt}
        variant={variant}
        callbackUrlPath={
          variant === 'public' ? (router.asPath as string) : undefined
        }
      />
    </StyledModal>
  );
};
