import * as React from "react";
import { SVGProps } from "react";
const SvgReload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 2.083A5.426 5.426 0 0 0 4.583 7.5v4.95L3.15 11.016a.624.624 0 1 0-.884.884l2.447 2.447a.624.624 0 0 0 .994-.004L8.15 11.9a.626.626 0 1 0-.884-.884L5.833 12.45V7.5A4.157 4.157 0 0 1 10 3.333c1.082 0 2.057.412 2.8 1.088a.624.624 0 1 0 .84-.925A5.401 5.401 0 0 0 10 2.083Zm4.782 3.325a.625.625 0 0 0-.49.25L11.85 8.1a.625.625 0 1 0 .884.884l1.433-1.434v4.95A4.157 4.157 0 0 1 10 16.667a4.137 4.137 0 0 1-2.8-1.088.624.624 0 1 0-.84.925A5.402 5.402 0 0 0 10 17.917a5.426 5.426 0 0 0 5.417-5.417V7.55l1.433 1.434a.624.624 0 1 0 .884-.884l-2.447-2.447a.626.626 0 0 0-.505-.245Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgReload;
