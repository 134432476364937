import * as React from "react";

function SvgAppAmazon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-amazon_svg__clip0)">
        <path
          d="M20.59 19.757c-9.906 4.714-16.054.77-19.989-1.626-.244-.15-.657.035-.298.448C1.613 20.169 5.91 24 11.519 24c5.612 0 8.95-3.062 9.368-3.596.415-.53.122-.822-.297-.647zm2.782-1.536c-.266-.347-1.618-.411-2.468-.307-.852.101-2.13.622-2.02.935.057.117.174.064.759.012.587-.059 2.23-.266 2.573.181.344.451-.524 2.6-.683 2.946-.153.346.059.435.347.205.284-.23.798-.828 1.143-1.673.342-.85.55-2.036.349-2.3z"
          fill="#F90"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.929 10.137c0 1.237.031 2.269-.594 3.367-.505.894-1.304 1.443-2.197 1.443-1.22 0-1.93-.929-1.93-2.3 0-2.706 2.425-3.197 4.72-3.197v.687zm3.202 7.74a.663.663 0 01-.75.075c-1.054-.875-1.242-1.281-1.822-2.117-1.742 1.778-2.975 2.31-5.234 2.31-2.67 0-4.752-1.649-4.752-4.949 0-2.576 1.398-4.331 3.385-5.189 1.724-.759 4.131-.893 5.97-1.103v-.41c0-.755.059-1.648-.383-2.3-.389-.585-1.13-.827-1.782-.827-1.21 0-2.29.621-2.554 1.907-.054.286-.264.567-.55.58l-3.081-.33c-.259-.058-.545-.268-.473-.665C5.815 1.126 9.186 0 12.205 0c1.545 0 3.564.411 4.783 1.581 1.545 1.443 1.398 3.367 1.398 5.462v4.948c0 1.487.616 2.139 1.196 2.943.206.285.25.63-.009.844-.647.54-1.8 1.545-2.433 2.107l-.01-.009"
          fill="currentColor"
        />
        <path
          d="M20.59 19.757c-9.906 4.714-16.054.77-19.989-1.626-.244-.15-.657.035-.298.448C1.613 20.169 5.91 24 11.519 24c5.612 0 8.95-3.062 9.368-3.596.415-.53.122-.822-.297-.647zm2.782-1.536c-.266-.347-1.618-.411-2.468-.307-.852.101-2.13.622-2.02.935.057.117.174.064.759.012.587-.059 2.23-.266 2.573.181.344.451-.524 2.6-.683 2.946-.153.346.059.435.347.205.284-.23.798-.828 1.143-1.673.342-.85.55-2.036.349-2.3z"
          fill="#F90"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.929 10.137c0 1.237.031 2.269-.594 3.367-.505.894-1.304 1.443-2.197 1.443-1.22 0-1.93-.929-1.93-2.3 0-2.706 2.425-3.197 4.72-3.197v.687zm3.202 7.74a.663.663 0 01-.75.075c-1.054-.875-1.242-1.281-1.822-2.117-1.742 1.778-2.975 2.31-5.234 2.31-2.67 0-4.752-1.649-4.752-4.949 0-2.576 1.398-4.331 3.385-5.189 1.724-.759 4.131-.893 5.97-1.103v-.41c0-.755.059-1.648-.383-2.3-.389-.585-1.13-.827-1.782-.827-1.21 0-2.29.621-2.554 1.907-.054.286-.264.567-.55.58l-3.081-.33c-.259-.058-.545-.268-.473-.665C5.815 1.126 9.186 0 12.205 0c1.545 0 3.564.411 4.783 1.581 1.545 1.443 1.398 3.367 1.398 5.462v4.948c0 1.487.616 2.139 1.196 2.943.206.285.25.63-.009.844-.647.54-1.8 1.545-2.433 2.107l-.01-.009"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="app-amazon_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppAmazon;
