import { routes } from '@frond/shared';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';

export const usePageTile = () => {
  const { t } = useTranslation();
  const { organization } = useOptionalOrganization();
  const router = useRouter();
  if (organization) {
    if (
      router.asPath.includes(
        routes.settings.organization(organization.shortId).details()
      )
    ) {
      return t('settings');
    } else if (
      router.asPath.includes(
        routes.groups.organization(organization.shortId).inbox()
      )
    ) {
      return t('inbox');
    } else if (
      router.asPath.includes(
        routes.groups.organization(organization.shortId).messages()
      )
    ) {
      return t('messages');
    } else if (
      router.asPath.includes(
        routes.groups.organization(organization.shortId).team()
      )
    ) {
      return t('members');
    } else if (
      router.asPath.includes(
        routes.groups.organization(organization.shortId).insights()
      )
    ) {
      return t('insights');
    }
  } else if (router.asPath.includes(routes.settings.account())) {
    return t('account');
  }
};
