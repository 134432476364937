import { createParagraphElementArray, MyValue, theme } from '@frond/shared';
import { useOnClickOutside } from '@udecode/plate-common';
import { x } from '@xstyled/styled-components';
import { nanoid } from 'nanoid';
import { useTranslation } from 'next-i18next';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useHotkeys } from 'react-hotkeys-hook';
import { Node } from 'slate';

import {
  Giphy,
  Image as ImageType,
  Resource,
  Video,
} from '../../../../../generated/types-and-hooks';
import { SignInModal } from '../../../auth/components/SignIn/SignInModal';
import { useViewer } from '../../../auth/hooks/useViewer';
import { PostMediaResourcesProps } from '../../../posts/components/PostMediaResources';
import { PostComposerFileSelector } from '../../../questions/components/PostComposerFileSelector';
import { useCommentConfig } from '../../hooks/useCommentConfig';
import { usePreviousBoolean } from '../../hooks/usePreviousBoolean';
import { dashedBorderSVGString, svgStringToImageUrl } from '../../utils';
import { Button } from '../Button';
import { Composer } from '../Composer';
import { getTrimmedContent } from '../Composer/utils/editor.utils';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { CommentComposerGiphyModal } from './CommentComposerGiphyModal';
import { CommentComposerMedia } from './CommentComposerMedia';
import { CommentComposerResource } from './CommentComposerResource';
import { CommentComposerResourceLoader } from './CommentComposerResourceLoader';
import { CommentComposerWrapper } from './CommentLayout';

export type CommentInputSubmitValue = {
  content: MyValue;
  mediaIds: string[];
  urls: string[];
};

export type CommentInputProps = {
  /**
   * Callback for click on send and hitting cmd + enter
   */
  onSubmit: (value: CommentInputSubmitValue) => void;
  /**
   * Callback for change in input
   */
  onChange?: (value: CommentInputSubmitValue) => void;
  /**
   * Whether to show the loading state
   */
  isSubmitting?: boolean;
  /**
   * Metadata for logging
   */
  metadata?: {
    context: string;
  };
  /**
   * Whether to focus on input field
   */
  autoFocus?: boolean;
  placeholder?: string;
  variant?: 'edit';
  onCancel?: () => void;
  id?: string;
  content?: MyValue;
  initialMedia?: PostMediaResourcesProps['media'];
  initialResources?: PostMediaResourcesProps['resources'];
  showGiphy?: boolean;
  showMedia?: boolean;
  submitShortcut?: string;
  expanded?: boolean;
};

const COMMENT_COMPOSER_ID = 'commentComposer';

export type ProcessingImage = {
  id: string;
  file: File;
};

/**
 * Primary component for a comment input field
 */
export const CommentComposer: React.FC<
  PropsWithChildren<CommentInputProps>
> = ({
  onSubmit,
  onChange,
  isSubmitting,
  placeholder,
  autoFocus,
  variant,
  onCancel,
  id,
  content,
  initialMedia,
  initialResources,
  children,
  showGiphy = true,
  showMedia = true,
  submitShortcut,
  expanded = false,
}) => {
  const { viewer: user } = useViewer();
  const { t } = useTranslation();
  const { data, loading: commentConfigLoading } = useCommentConfig();
  const [uniqueId, setUniqueId] = useState(nanoid());
  const [hasFocus, setFocus] = useState(autoFocus);
  const [submitAfterProcessing, setSubmitAfterProcess] = useState(false);
  const [composerValue, setComposerValue] = useState(
    content || createParagraphElementArray()
  );
  const [showSignInModal, setShowSignInModal] = useState(false);

  const parent = useRef<HTMLDivElement | null>(null);

  const [media, setMedia] = useState<
    Array<Giphy | ImageType | Video | ProcessingImage>
  >(initialMedia || []);
  const urlsRef = useRef<string[]>([]);
  const [urls, setUrls] = useState<string[]>(
    initialResources?.map((r) => r.url) || []
  );
  const [resources, setResources] = useState<Resource[]>(
    initialResources || []
  );

  const hasValue =
    (!!composerValue &&
      composerValue.map((n) => Node.string(n)).join().length > 0) ||
    media.length > 0 ||
    urls.length > 0 ||
    resources.length > 0;

  const previousIsSubmitting = usePreviousBoolean(isSubmitting);

  const { getRootProps, isDragActive } = useDropzone({
    noClick: true,
    multiple: true,
    disabled: !user,
    onDrop: (files) => {
      const processedFiles = files
        .filter((file) => file.type.startsWith('image'))
        .map((file) => {
          return {
            id: nanoid(),
            file,
          };
        });

      setMedia((media) => {
        return [...media, ...processedFiles];
      });
    },
  });

  useEffect(() => {
    if (previousIsSubmitting && !isSubmitting && !content) {
      // Hack!! Can no longer reset the editor when it's not wrapped in a Provider
      // which makes this component tricky to reset
      setUniqueId(nanoid());
      setComposerValue(createParagraphElementArray());
      setMedia([]);
      setUrls([]);
      urlsRef.current = [];
      setResources([]);
      setFocus(autoFocus);
    }
  }, [previousIsSubmitting, isSubmitting, content, autoFocus]);

  useEffect(() => {
    onChange?.({
      content: composerValue,
      urls,
      mediaIds: media.map((m) => m.id),
    });
  }, [onChange, composerValue, urls, media, resources]);

  const hasMediaProcessing = useCallback(() => {
    return media.filter((m) => 'file' in m).length > 0;
  }, [media]);

  const isSubmittingOrProcessing = isSubmitting || submitAfterProcessing;

  const handleSubmit = useCallback(
    (value: MyValue) => {
      if (parent && !isSubmitting && !hasMediaProcessing()) {
        onSubmit({ content: value, urls, mediaIds: media.map((m) => m.id) });
      } else {
        setSubmitAfterProcess(true);
      }
    },
    [parent, isSubmitting, onSubmit, media, urls, hasMediaProcessing]
  );

  const [showGiphyModal, setShowGiphyModal] = useState(false);

  useHotkeys('Esc', () => {
    setFocus(false);
  });

  useOnClickOutside(
    () => {
      setFocus(false);
    },
    {
      refs: [parent],
      disabled: !hasFocus,
    }
  );

  useEffect(() => {
    if (submitAfterProcessing && !hasMediaProcessing()) {
      handleSubmit(composerValue);
      setSubmitAfterProcess(false);
    }
  }, [
    media,
    submitAfterProcessing,
    media,
    composerValue,
    handleSubmit,
    hasMediaProcessing,
  ]);

  const urlsToUnfurl = urls.filter((url) => {
    return !resources.find((r) => r.url === url);
  });

  const handleResourceProcessed = useCallback((resource: Resource) => {
    // In case urls were reset in the meantime
    if (urlsRef.current && !urlsRef.current.length) return;

    setResources((resources) => {
      const resourceAlreadyExists = resources.find(
        (r) => r.url === resource.url
      );
      if (resourceAlreadyExists) return resources;

      return [...resources, resource];
    });
  }, []);

  if ((commentConfigLoading && !data) || !data) return null;

  const onComposerChange = (value: MyValue) => {
    // Limit comment to max length
    const comment = value.map((n) => Node.string(n)).join('');

    if (comment.length >= data.commentConfig.maxCommentLength) {
      const lengthToTrim = comment.length - data.commentConfig.maxCommentLength;
      value = getTrimmedContent(value, lengthToTrim);
    }

    setComposerValue(value);
  };

  const hasFocusOrHasValue = hasFocus || hasValue || expanded;

  const actions = (
    <x.div display="flex" alignItems="center" spaceX={2}>
      {showGiphy && (
        <x.button
          border="none"
          bg="transparent"
          p={0}
          color={{
            _: 'gray.300',
            hover: 'brand.200',
            disabled: 'gray.200',
          }}
          pointerEvents="auto"
          cursor="pointer"
          onClick={() => {
            if (user) {
              setShowGiphyModal(true);
            } else {
              setShowSignInModal(true);
            }
          }}
          transitionTimingFunction="linear"
          transitionDuration="faster"
          tabIndex={-1}
          disabled={isSubmittingOrProcessing}
        >
          <Icon name="gif" size="5" />
        </x.button>
      )}
      {showMedia && (
        <div>
          <PostComposerFileSelector
            id={`comment-composer-file-selector-${variant}-${id || nanoid(5)}`}
            accept="image/jpeg,image/png,image/svg+xml,image/webp,image/bmp,image/tiff,image/heif"
            multiple
            disabled={isSubmittingOrProcessing}
            onChange={(e) => {
              if (!user) {
                setShowSignInModal(true);
                return;
              }

              if (!e.target.files) {
                return;
              }

              const processedFiles = [...e.target.files].map((file) => {
                return {
                  id: nanoid(),
                  file,
                };
              });

              setMedia((media) => {
                return [...media, ...processedFiles];
              });
              e.currentTarget.value = '';
            }}
          >
            <x.div
              border="none"
              bg="transparent"
              p={0}
              color={{
                _: isSubmittingOrProcessing ? 'gray.200' : 'gray.300',
                hover: 'brand.200',
              }}
              pointerEvents="auto"
              cursor="pointer"
              transitionTimingFunction="linear"
              transitionDuration="faster"
            >
              <Icon name="media" size="5" />
            </x.div>
          </PostComposerFileSelector>
        </div>
      )}
    </x.div>
  );

  return (
    <div {...getRootProps()}>
      <SignInModal
        isOpen={showSignInModal}
        onDismiss={() => setShowSignInModal(false)}
        variant="public"
      />
      <CommentComposerGiphyModal
        isOpen={showGiphyModal}
        onDismiss={() => setShowGiphyModal(false)}
        onGiphyChosen={(giphy) => {
          setMedia((media) => {
            return [...media, giphy];
          });
          setShowGiphyModal(false);
        }}
      />
      <x.div
        ref={parent}
        border="default"
        borderColor={{
          _: hasFocus ? 'brand.200' : 'gray.200',
          hover: !hasFocus && 'brand.100',
        }}
        key={uniqueId}
        borderRadius="md"
        transition
        transitionDuration="fast"
        transitionProperty="border-color"
        w="full"
        boxSizing="border-box"
        overflow="hidden"
        bg="white"
        position="relative"
        onClick={() => setFocus(true)}
      >
        <x.div
          data-test="comment-input-wrapper"
          boxSizing="border-box"
          w="full"
          position="relative"
        >
          <CommentComposerWrapper
            flexGrow="1"
            alignSelf="flex-start"
            overflow="hidden"
            onClick={(e) => {
              if (!user) {
                e.preventDefault();
                e.stopPropagation();
                setShowSignInModal(true);
              }
            }}
          >
            <Composer
              id={COMMENT_COMPOSER_ID}
              p={{
                _: 2.5,
                sm: 4,
              }}
              pb={{
                _: hasFocusOrHasValue ? 0 : 2.5,
                sm: hasFocusOrHasValue ? 0 : 4,
              }}
              pl={4}
              disabled={isSubmittingOrProcessing}
              placeholder={placeholder || t('comment_placeholder')}
              onChange={onComposerChange}
              onSubmit={handleSubmit}
              onFocus={(e) => {
                if (user) {
                  setFocus(true);
                } else {
                  e.preventDefault();
                  e.currentTarget.blur();
                }
              }}
              submitShortcut={submitShortcut}
              maxCharacters={data.commentConfig.maxCommentLength}
              autoFocus={hasFocus}
              initialValue={content || createParagraphElementArray('')}
              onPaste={(e) => {
                const paste = e.clipboardData.getData('text');
                const file = e.clipboardData.files[0];

                if (file && file.type.startsWith('image')) {
                  setMedia((media) => [...media, { id: nanoid(), file }]);
                }

                if (paste) {
                  try {
                    new URL(paste);
                    if (!urls.includes(paste)) {
                      setUrls((urls) => [...urls, paste]);
                      urlsRef.current = [...urls, paste];
                    }
                  } catch {
                    // do nothing
                  }
                }
              }}
            >
              {hasFocusOrHasValue && children}
              {hasFocusOrHasValue ? (
                <x.div
                  pb={{ _: 2.5, sm: 4 }}
                  mt={{ _: 0, sm: 4 }}
                  spaceY={{ _: 2.5, sm: 4 }}
                  pointerEvents="none"
                >
                  {media.length > 0 ? (
                    <x.div display="flex" gap={3} flexWrap="wrap">
                      {media.map((item) => {
                        return (
                          <CommentComposerMedia
                            key={item.id}
                            media={item}
                            onDelete={() => {
                              setMedia((media) =>
                                media.filter((m) => m.id !== item.id)
                              );
                            }}
                            onProcessed={(image) => {
                              setMedia((media) => {
                                return media.map((m) => {
                                  if (m.id === item.id) {
                                    return image;
                                  }
                                  return m;
                                });
                              });
                            }}
                          />
                        );
                      })}
                    </x.div>
                  ) : null}
                  {resources.length > 0 ? (
                    <x.div spaceY={2}>
                      {resources.map((resource) => {
                        return (
                          <CommentComposerResource
                            resource={resource}
                            onDelete={() => {
                              setResources((resources) =>
                                resources.filter((r) => r.id !== resource.id)
                              );
                              setUrls((urls) =>
                                urls.filter((u) => u !== resource.url)
                              );
                              urlsRef.current = urls.filter(
                                (u) => u !== resource.url
                              );
                            }}
                            key={resource.id}
                          />
                        );
                      })}
                    </x.div>
                  ) : null}
                  {urlsToUnfurl.length > 0 ? (
                    <x.div spaceY={2}>
                      {urlsToUnfurl.map((url) => {
                        return (
                          <CommentComposerResourceLoader
                            key={url}
                            onProcessed={handleResourceProcessed}
                            url={url}
                          />
                        );
                      })}
                    </x.div>
                  ) : null}
                  <x.div
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {actions}
                    <x.div display="flex" spaceX={4}>
                      {variant === 'edit' && (
                        <Button
                          variant="text"
                          label="Cancel"
                          color="gray.300"
                          pointerEvents="auto"
                          onClick={() => onCancel?.()}
                        />
                      )}

                      <x.button
                        bg="transparent"
                        p={0}
                        m={0}
                        disabled={!hasValue || isSubmittingOrProcessing}
                        border="none"
                        display="block"
                        alignItems="center"
                        justifyContent="center"
                        cursor={{
                          _: 'pointer',
                          disabled: 'default',
                        }}
                        color={{
                          _: 'brand.300',
                          disabled: isSubmittingOrProcessing
                            ? 'brand.200'
                            : 'gray.200',
                          hover: 'brand.400',
                        }}
                        pointerEvents="auto"
                        onClick={() => handleSubmit(composerValue)}
                      >
                        <Icon
                          name="send"
                          size="9"
                          transitionTimingFunction="linear"
                          transitionDuration="faster"
                        />
                      </x.button>
                    </x.div>
                  </x.div>
                </x.div>
              ) : null}
            </Composer>
          </CommentComposerWrapper>

          {!hasFocusOrHasValue ? (
            <x.div
              display="flex"
              position="absolute"
              top={0}
              right={16}
              bottom={0}
              alignItems="center"
              pointerEvents="none"
              bg="transparent"
            >
              {actions}
            </x.div>
          ) : null}
        </x.div>
        {isDragActive ? (
          <x.div
            position="absolute"
            left={0}
            right={0}
            top={0}
            bottom={0}
            bg="white"
            borderRadius="md"
            p={2}
          >
            <x.div
              bg="brand.50"
              borderRadius="md"
              h="full"
              backgroundImage={`url("${svgStringToImageUrl(
                dashedBorderSVGString({
                  color: `${theme.colors.brand[300]}`,
                  dasharray: [`${theme.sizes['3']}`, `${theme.sizes['3']}`],
                  radius: 'md',
                })
              )}")`}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text
                color="brand.300"
                variant={hasFocusOrHasValue ? 'md-semibold' : 'sm-semibold'}
              >
                {t('drop_to_add_media')}
              </Text>
            </x.div>
          </x.div>
        ) : null}
      </x.div>
    </div>
  );
};
