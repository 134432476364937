import { theme } from '@frond/shared';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import styled, { css, x } from '@xstyled/styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

import { useModal } from '../hooks/useModal';
import analytics from '../utils/analytics';
import { Alert } from './Alert';
import { Button } from './Button';
import { CircleCloseButton, CloseButton } from './CloseButton';
import { Icon } from './Icon';

export type ModalProps = {
  isOpen: boolean;
  onDismiss(): void;
  ariaLabel: string;
  disableAnimation?: boolean;
  disableFocus?: boolean;
  titleEl?: JSX.Element;
  actionsEl?: JSX.Element;
  showClose?: boolean;
  responsive?: boolean;
  metadata?: { context?: string };
  center?: boolean;
  centerTop?: boolean;
  // Will be replacing all v1s with v2s in the near future
  closeButtonVariant?: 'v1' | 'v2' | 'v3';
  responsiveVideo?: boolean;
  confirmBeforeDismiss?: boolean;
};

const StyledDialogContent = motion(styled(DialogContent)<{
  responsive?: boolean;
  center?: boolean;
  responsiveVideo?: boolean;
  showOverflow?: boolean;
}>`
  border-radius: lg;
  position: relative;
  ${(p) => !p.showOverflow && `overflow: hidden`};

  /* undefined size in theme */
  width: 856px;

  ${(p) =>
    p.responsive &&
    css`
      @media (max-width: ${theme.sizes.container.lg}) {
        max-width: 856px;
        width: auto;
        box-sizing: border-box;
      }

      @media (max-width: ${theme.sizes.container.md}) {
        margin-top: 0;
        padding: ${theme.sizes[6]};
        max-width: 856px;
        width: auto;
        box-sizing: border-box;
      }
    `}

  ${(p) =>
    p.center &&
    css`
      width: auto;
      height: auto;
      margin: auto;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    `};

  ${(p) =>
    p.responsiveVideo &&
    css`
      width: 100%;
      display: flex;
      min-width: ${theme.sizes['3xl']};
      max-width: ${theme.sizes['4xl']};
      padding: 0;
    `}
`);

const FlexHeightWrapper = styled.div<{
  center?: boolean;
  centerTop?: boolean;
}>`
  ${(p) =>
    (p.center || p.centerTop) &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;

      flex-grow: 1;
      flex-basis: 100%;

      min-height: auto;
      max-height: auto;

      box-sizing: border-box;
      width: 100%;
      margin: auto;
    `};
  ${(p) =>
    p.centerTop &&
    css`
      align-items: flex-start;
      margin-top: 0;
    `}
`;

const FlexWidthWrapper = styled.div<{
  center?: boolean;
}>`
  ${(p) =>
    p.center &&
    css`
      display: flex;
      flex-direction: row;
      flex-basis: 100%;
      align-items: center;

      min-width: auto;
      max-width: auto;

      height: 100%;
    `};
`;

const StyledDialogOverlay = motion(styled(DialogOverlay)<{
  responsive?: boolean;
  center?: boolean;
  visualViewportOffset: number;
}>`
  z-index: modal;
  background: hsla(0, 0%, 0%, 0.5);
  overflow-y: scroll;
  margin: auto;
  padding-top: 10;
  padding-bottom: 10;
  transform: translateY(${(p) => p.visualViewportOffset}px);

  ${(p) =>
    p.responsive &&
    css`
      @media (max-width: ${theme.sizes.container.md}) {
        padding-top: ${theme.sizes[4]};
      }
    `}

  ${(p) =>
    p.center &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding: ${theme.sizes[10]};
    `}
`);

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  flex-basis: 25%;
  align-self: flex-start;
`;

const TitleWrapper = styled.div<{
  fullWidth?: boolean;
}>`
  display: flex;
  flex-grow: 1;
  flex-basis: ${(p) => (p.fullWidth ? '100%' : '50%')};
  text-align: center;
  justify-content: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 25%;
  justify-content: flex-end;
`;

export const Modal: React.FC<
  ModalProps & React.HTMLAttributes<HTMLDivElement>
> = ({
  children,
  isOpen,
  onDismiss,
  disableAnimation,
  disableFocus = false,
  ariaLabel,
  className,
  titleEl,
  actionsEl,
  showClose = true,
  responsive,
  metadata,
  center,
  centerTop,
  closeButtonVariant = 'v1',
  responsiveVideo,
  confirmBeforeDismiss = false,
}) => {
  const router = useRouter();
  const [showDismissAlert, setShowDismissAlert] = useState(false);
  const { animationProps } = useModal({
    disableAnimation,
    metadata,
  });

  const overlayRef = useRef<HTMLDivElement>(null);
  const [visualViewportOffset, setVisualViewportOffset] = useState(0);

  const onDismissPassthrough = () => {
    if (!confirmBeforeDismiss) {
      onDismiss();
      return;
    }

    setShowDismissAlert(true);
  };

  /*
    This is a workaround using visualViewport API to adjust the modal position
    when the keyboard is open on mobile web
  */
  useEffect(() => {
    const viewportHandler = () => {
      if (overlayRef.current && window.visualViewport) {
        setVisualViewportOffset(window.visualViewport.offsetTop);
      }
    };

    if (typeof visualViewport !== 'undefined') {
      window.visualViewport?.addEventListener('resize', viewportHandler);
      return () => {
        window.visualViewport?.removeEventListener('resize', viewportHandler);
      };
    }
  }, []);

  // Track when modal is opened
  useEffect(() => {
    if (isOpen) {
      analytics.logEvent(analytics.events.MODAL_VIEW, {
        page: router?.pathname,
        url: router?.asPath,
        ...metadata,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <StyledDialogOverlay
            className={className}
            // This is not recommended for accessibility reasons but the only way to have no focus on open
            dangerouslyBypassFocusLock={disableFocus}
            onDismiss={onDismissPassthrough}
            responsive={responsive}
            center={center}
            ref={overlayRef}
            visualViewportOffset={visualViewportOffset}
            {...animationProps}
          >
            <FlexHeightWrapper center={center} centerTop={centerTop}>
              <FlexWidthWrapper center={center}>
                <StyledDialogContent
                  aria-label={ariaLabel}
                  data-test="modal"
                  responsive={responsive}
                  center={center}
                  responsiveVideo={responsiveVideo}
                  showOverflow={closeButtonVariant === 'v3'}
                  {...animationProps}
                >
                  {showClose && closeButtonVariant === 'v2' && (
                    <x.div w="full" display="flex" justifyContent="flex-end">
                      <Button
                        variant="icon"
                        leftElement={<Icon name="close" size="5" />}
                        onClick={onDismissPassthrough}
                      />
                    </x.div>
                  )}
                  {showClose && closeButtonVariant === 'v3' && (
                    <x.div position="absolute" left="-3.375rem" top="0">
                      <CircleCloseButton onClick={onDismissPassthrough} />
                    </x.div>
                  )}
                  <HeaderWrapper>
                    {closeButtonVariant === 'v1' && (
                      <CloseButtonWrapper>
                        {showClose && (
                          <CloseButton onClick={onDismissPassthrough} />
                        )}
                      </CloseButtonWrapper>
                    )}
                    <TitleWrapper fullWidth={!actionsEl || !showClose}>
                      {titleEl}
                    </TitleWrapper>
                    <ActionsWrapper>{actionsEl}</ActionsWrapper>
                  </HeaderWrapper>
                  {children}
                </StyledDialogContent>
              </FlexWidthWrapper>
            </FlexHeightWrapper>
          </StyledDialogOverlay>
        )}
      </AnimatePresence>
      {showDismissAlert && (
        <Alert
          headingText={'Close?'}
          descriptionText={
            'Are you sure you want to close? Any unsaved changes will be lost.'
          }
          cancelButtonText={'Cancel'}
          submitButtonText={'Close'}
          onCancelClick={() => setShowDismissAlert(false)}
          variant={'warning'}
          onSubmitClick={() => {
            setShowDismissAlert(false);
            onDismiss();
          }}
        />
      )}
    </>
  );
};
