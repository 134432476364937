/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * Helper utility since the variant utility was removed from xstyled v2
 * https://xstyled.dev/docs/upgrade-guide/#no-more-variant-utility
 */

import { assign, getThemeValue, is, merge, warn } from '@xstyled/util';

interface VariantProps {
  key?: string | null;
  default?: any;
  variants?: any;
  prop?: string;
}

export const variant =
  ({
    key = null,
    default: defaultValue,
    variants = {},
    prop = 'variant',
  }: VariantProps) =>
  (props: any) => {
    const themeVariants = is(key) ? getThemeValue(props, key) : null;
    const computedVariants = merge(assign({}, variants), themeVariants);
    const value = props[prop] !== undefined ? props[prop] : defaultValue;
    const result = getThemeValue(props, value, computedVariants);
    warn(is(result), `variant "${value}" not found`);
    return result;
  };
