import * as React from "react";

function SvgAppGoogleDocs(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="app-google-docs_svg__a"
        maskUnits="userSpaceOnUse"
        x={3}
        y={0}
        width={18}
        height={25}
      >
        <path
          d="M14.17 0H4.95c-.895 0-1.627.736-1.627 1.636v20.728c0 .9.732 1.636 1.627 1.636h14.1c.895 0 1.627-.736 1.627-1.636V6.546L14.17 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#app-google-docs_svg__a)">
        <path
          d="M14.17 0H4.95c-.895 0-1.627.736-1.627 1.636v20.728c0 .9.732 1.636 1.627 1.636h14.1c.895 0 1.627-.736 1.627-1.636V6.546l-3.796-2.728L14.169 0z"
          fill="#4285F4"
        />
      </g>
      <mask
        id="app-google-docs_svg__b"
        maskUnits="userSpaceOnUse"
        x={3}
        y={0}
        width={18}
        height={25}
      >
        <path
          d="M14.17 0H4.95c-.895 0-1.627.736-1.627 1.636v20.728c0 .9.732 1.636 1.627 1.636h14.1c.895 0 1.627-.736 1.627-1.636V6.546L14.17 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#app-google-docs_svg__b)">
        <path
          d="M14.646 6.067l6.031 6.065V6.546l-6.031-.479z"
          fill="url(#app-google-docs_svg__paint0_linear)"
        />
      </g>
      <mask
        id="app-google-docs_svg__c"
        maskUnits="userSpaceOnUse"
        x={3}
        y={0}
        width={18}
        height={25}
      >
        <path
          d="M14.17 0H4.95c-.895 0-1.627.736-1.627 1.636v20.728c0 .9.732 1.636 1.627 1.636h14.1c.895 0 1.627-.736 1.627-1.636V6.546L14.17 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#app-google-docs_svg__c)">
        <path
          d="M7.662 17.455h8.677v-1.091H7.662v1.09zm0 2.182h6.508v-1.091H7.662v1.09zm0-7.637v1.091h8.677v-1.09H7.662zm0 3.273h8.677v-1.091H7.662v1.09z"
          fill="#F1F1F1"
        />
      </g>
      <mask
        id="app-google-docs_svg__d"
        maskUnits="userSpaceOnUse"
        x={3}
        y={0}
        width={18}
        height={25}
      >
        <path
          d="M14.17 0H4.95c-.895 0-1.627.736-1.627 1.636v20.728c0 .9.732 1.636 1.627 1.636h14.1c.895 0 1.627-.736 1.627-1.636V6.546L14.17 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#app-google-docs_svg__d)">
        <path
          d="M14.169 0v4.91c0 .903.728 1.636 1.627 1.636h4.88L14.17 0z"
          fill="#A1C2FA"
        />
      </g>
      <mask
        id="app-google-docs_svg__e"
        maskUnits="userSpaceOnUse"
        x={3}
        y={0}
        width={18}
        height={25}
      >
        <path
          d="M14.17 0H4.95c-.895 0-1.627.736-1.627 1.636v20.728c0 .9.732 1.636 1.627 1.636h14.1c.895 0 1.627-.736 1.627-1.636V6.546L14.17 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#app-google-docs_svg__e)">
        <path
          d="M4.95 0c-.895 0-1.627.736-1.627 1.636v.137c0-.9.732-1.637 1.627-1.637h9.22V0H4.95z"
          fill="#fff"
          fillOpacity={0.2}
        />
      </g>
      <mask
        id="app-google-docs_svg__f"
        maskUnits="userSpaceOnUse"
        x={3}
        y={0}
        width={18}
        height={25}
      >
        <path
          d="M14.17 0H4.95c-.895 0-1.627.736-1.627 1.636v20.728c0 .9.732 1.636 1.627 1.636h14.1c.895 0 1.627-.736 1.627-1.636V6.546L14.17 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#app-google-docs_svg__f)">
        <path
          d="M19.05 23.864H4.95a1.636 1.636 0 01-1.627-1.637v.137c0 .9.732 1.636 1.627 1.636h14.1c.895 0 1.627-.736 1.627-1.636v-.137c0 .9-.732 1.637-1.627 1.637z"
          fill="#1A237E"
          fillOpacity={0.2}
        />
      </g>
      <mask
        id="app-google-docs_svg__g"
        maskUnits="userSpaceOnUse"
        x={3}
        y={0}
        width={18}
        height={25}
      >
        <path
          d="M14.17 0H4.95c-.895 0-1.627.736-1.627 1.636v20.728c0 .9.732 1.636 1.627 1.636h14.1c.895 0 1.627-.736 1.627-1.636V6.546L14.17 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#app-google-docs_svg__g)">
        <path
          d="M15.796 6.546a1.631 1.631 0 01-1.627-1.637v.137c0 .904.728 1.636 1.627 1.636h4.88v-.136h-4.88z"
          fill="#1A237E"
          fillOpacity={0.1}
        />
      </g>
      <path
        d="M14.17 0H4.95c-.895 0-1.627.736-1.627 1.636v20.728c0 .9.732 1.636 1.627 1.636h14.1c.895 0 1.627-.736 1.627-1.636V6.546L14.17 0z"
        fill="url(#app-google-docs_svg__paint1_radial)"
      />
      <defs>
        <radialGradient
          id="app-google-docs_svg__paint1_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.873 .474) scale(27.9828)"
        >
          <stop stopColor="#fff" stopOpacity={0.1} />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </radialGradient>
        <linearGradient
          id="app-google-docs_svg__paint0_linear"
          x1={17.662}
          y1={6.588}
          x2={17.662}
          y2={12.133}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A237E" stopOpacity={0.2} />
          <stop offset={1} stopColor="#1A237E" stopOpacity={0.02} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgAppGoogleDocs;
