import { MentionDropdown } from '../../common/components/Composer/MentionDropdown';

export const GroupsMentionDropdown: React.FC<{
  groupId?: string;
}> = ({ groupId }) => {
  return (
    <MentionDropdown
      groupId={groupId}
      filter={(search: string) => (item) => {
        return item.text.toLowerCase().includes(search.toLowerCase());
      }}
    />
  );
};
