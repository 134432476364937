import { MyValue, theme } from '@frond/shared';
import { useSelector } from '@xstate/react';
import { styled, th } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';

import {
  PostColorTheme,
  PostTypes,
} from '../../../../generated/types-and-hooks';
import { Composer } from '../../common/components/Composer';
import { GroupsMentionDropdown } from '../../groups/components/GroupsMentionDropdown';
import { isEmojiOnly } from '../../questions/utils/answers';
import { PostComposerStateContext } from './PostComposer';

const PostComposerWrapper = styled.divBox<{
  colorTheme: PostColorTheme;
  isEmojiOnly: boolean;
}>`
  position: relative;
  display: inline-block;

  /*
    Set fixed height of composer to prevent shifting of modal on mobile web
  */
  [data-slate-editor='true'] {
    height: 187px !important;
    overflow-y: scroll;
  }

  @media (min-width: ${th('screens.sm')}) {
    [data-slate-editor='true'] {
      min-height: 187px !important;
    }
  }

  /* stylelint-disable */
  span,
  p,
  code,
  i,
  strong,
  u,
  strike {
    color: ${(p) =>
      th(
        `colors.${p.colorTheme.toLowerCase()}.${
          p.colorTheme === PostColorTheme.Gray ? '500' : '400'
        }`
      )};

    &[data-slate-placeholder='true'] {
      span {
        color: ${(p) => th(`colors.${p.colorTheme.toLowerCase()}.200`)};
      }
    }

    ${(p) => p.isEmojiOnly && th('typographyStyles.heading.3xl')}
  }

  a,
  a span {
    color: blue.300 !important;

    :hover {
      color: blue.400 !important;
    }
  }
  /* stylelint-enable */
`;

export const PostComposerTextComposer = () => {
  const { t } = useTranslation();
  const { postComposerService } = useContext(PostComposerStateContext);
  const content = useSelector(
    postComposerService,
    (state) => state.context.post.content
  ) as MyValue;

  const media = useSelector(
    postComposerService,
    (state) => state.context.post.media
  );

  const postId = useSelector(
    postComposerService,
    (state) => state.context.postId
  );

  const colorTheme = useSelector(
    postComposerService,
    (state) => state.context.post.colorTheme
  );

  const type = useSelector(postComposerService, (state) => state.context.type);

  const group = useSelector(
    postComposerService,
    (state) => state.context.group,
    (prevGroup, nextGroup) => prevGroup?.id === nextGroup?.id
  );

  const pollOptions = useSelector(
    postComposerService,
    (state) => state.context.pollOptions
  );

  const isPublishing = useSelector(
    postComposerService,
    (state) => state.context.isPublishing
  );

  const handleComposerChange = (content: MyValue) => {
    postComposerService.send({ type: 'SET_POST_CONTENT', content });
  };

  return (
    <PostComposerWrapper
      w="full"
      border="default"
      borderColor="gray.200"
      isEmojiOnly={isEmojiOnly(content as MyValue)}
      colorTheme={colorTheme}
      borderRadius="md"
      position="relative"
    >
      <Composer
        id="postComposer"
        onChange={handleComposerChange}
        placeholder={
          type === PostTypes.Lesson
            ? t('whats_your_lesson_about')
            : pollOptions?.length
              ? t('ask_a_question')
              : t('share_something')
        }
        onSubmit={() => {}}
        autoFocus={
          !content && media.length === 0 && !postId && type !== PostTypes.Lesson
        }
        style={{
          padding: `${theme.sizes['4']}`,
          boxSizing: 'border-box',
        }}
        variant="mentionText"
        onPaste={(e) => {
          const paste = e.clipboardData.getData('text');
          const file = e.clipboardData.files[0];

          if (file && file.type.startsWith('image')) {
            postComposerService.send({
              type: 'ADD_MEDIA',
              media: {
                id: `file-to-upload-${new Date().getTime()}`,
                file,
                uploadProgress: 0,
              },
            });
          }

          try {
            new URL(paste);
            postComposerService.send({
              type: 'ADD_URL_TO_EMBED',
              url: paste,
            });
          } catch {
            // do nothing
          }
        }}
        initialValue={content || undefined}
        disabled={isPublishing}
      >
        <GroupsMentionDropdown
          {...(group &&
            (group?.isPrivate || group?.unlockedBy) && {
              groupId: group.id,
            })}
        />
      </Composer>
    </PostComposerWrapper>
  );
};
