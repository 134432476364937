import { assign, createMachine } from '@xstate/fsm';

import {
  VideoRecorderActions,
  VideoRecorderContext,
  VideoRecorderEvents,
  VideoRecorderStates,
  VideoRecorderStateSchema,
} from './videoRecorderTypes';

/**
 *  FSM
 */
export const videoRecorderFSM = createMachine<
  VideoRecorderContext,
  VideoRecorderEvents,
  VideoRecorderStateSchema
>({
  initial: VideoRecorderStates.LOADING,
  context: {
    cameraStream: null,
    percentUploaded: 0,
    constraints: null,
  },
  states: {
    LOADING: {
      on: {
        PERMISSIONS_PROMPT: VideoRecorderStates.PERMISSIONS_PROMPT,
        PERMISSIONS_DENIED: VideoRecorderStates.PERMISSIONS_DENIED,
        INITIALIZE_REQUEST: VideoRecorderStates.INITIALIZING,
      },
    },
    PERMISSIONS_PROMPT: {
      on: {
        LIVE_VIDEO_READY: VideoRecorderStates.INITIALIZED,
        PERMISSIONS_REQUESTED: VideoRecorderStates.PERMISSIONS_REQUESTED,
      },
    },
    INITIALIZING: {
      entry: VideoRecorderActions.INITIALIZE_STREAM,
      on: {
        LIVE_VIDEO_READY: VideoRecorderStates.INITIALIZED,
        COUNTDOWN_STARTED: VideoRecorderStates.COUNTDOWN,
        INITIALIZE_FAILURE: VideoRecorderStates.PERMISSIONS_DENIED,
        PERMISSIONS_DENIED: VideoRecorderStates.PERMISSIONS_DENIED,
        UNSUPPORTED_BROWSER_DETECTED: VideoRecorderStates.UNSUPPORTED,
        CAMERA_NOT_FOUND: VideoRecorderStates.NO_CAMERA,
        INITIALIZE_SUCCESS: {
          actions: assign({
            cameraStream: (_, event) => event.payload,
          }),
        },
      },
    },
    INITIALIZED: {
      on: {
        COUNTDOWN_STARTED: VideoRecorderStates.COUNTDOWN,
        INITIALIZE_REQUEST: VideoRecorderStates.INITIALIZING,
        SET_CONSTRAINTS: {
          actions: [
            assign({
              constraints: (_, event) => event.payload,
            }),
          ],
        },
      },
    },
    RECORDING: {
      on: {
        RECORDING_STOPPED: VideoRecorderStates.REVIEW,
        RECORDING_PAUSED: VideoRecorderStates.PAUSED,
      },
    },
    COUNTDOWN: {
      on: {
        RECORDING_STARTED: VideoRecorderStates.RECORDING,
      },
    },
    PAUSED: {
      on: {
        RECORDING_STOPPED: VideoRecorderStates.REVIEW,
        RECORDING_RESUMED: VideoRecorderStates.RECORDING,
      },
    },
    REVIEW: {
      on: {
        RECORDING_RESTARTED: VideoRecorderStates.TRANSITION_TO_RECORD,
        UPLOAD_STARTED: VideoRecorderStates.UPLOADING,
      },
    },
    PERMISSIONS_DENIED: {},
    NO_CAMERA: {},
    UPLOADING: {
      entry: assign({
        percentUploaded: 0,
      }),
      on: {
        UPLOAD_PROGRESSED: {
          actions: assign({
            percentUploaded: (_, event) => event.payload,
          }),
        },
        UPLOAD_FAILURE: VideoRecorderStates.UPLOAD_FAILED,
        UPLOAD_SUCCESS: VideoRecorderStates.PROCESSING,
      },
    },
    PROCESSING: {},
    UNSUPPORTED: {},
    TRANSITION_TO_RECORD: {
      entry: VideoRecorderActions.INITIALIZE_STREAM,
      on: {
        INITIALIZE_SUCCESS: {
          actions: assign({
            cameraStream: (_, event) => event.payload,
          }),
        },
        LIVE_VIDEO_READY: VideoRecorderStates.INITIALIZED,
      },
    },
    PERMISSIONS_REQUESTED: {
      entry: VideoRecorderActions.INITIALIZE_STREAM,
      on: {
        INITIALIZE_SUCCESS: {
          actions: assign({
            cameraStream: (_, event) => event.payload,
          }),
        },
        LIVE_VIDEO_READY: VideoRecorderStates.INITIALIZED,
        PERMISSIONS_DENIED: VideoRecorderStates.PERMISSIONS_DENIED,
      },
    },
    UPLOAD_FAILED: {
      on: {
        UPLOAD_STARTED: VideoRecorderStates.UPLOADING,
      },
    },
  },
});
