import { getCloudinaryUrl } from '@frond/shared';
import styled, { css, th } from '@xstyled/styled-components';
import React from 'react';

import { Icon, IconSizes } from './Icon';
import { Text } from './Text';
import { Tooltip } from './Tooltip';

export type AvatarSizes =
  | 'xxs'
  | 'xs'
  | 's'
  | 's-2'
  | 's-3'
  | 'm'
  | 'm-1'
  | 'm-2'
  | 'm-3'
  | 'm-3.5'
  | 'm-4'
  | 'm-5'
  | 'lg'
  | 'lg-2'
  | 'lg-3'
  | 'lg-4'
  | 'lg-5'
  | 'xl'
  | 'circle';

type SizeDetails = {
  base: number;
  placeholder: number;
  radius: number | string;
  textVariant: 'xs-semibold' | 'sm-semibold' | 'lg-semibold';
};

/* Size numbers correspond to what we're using in sizes.ts on 4px scale
   We should always express in terms of rem, so we get automagic scaling
   for responsive design just by changing font-size */
const sizeMap: Record<AvatarSizes, SizeDetails> = {
  xxs: { base: 4, placeholder: 2, radius: 1, textVariant: 'xs-semibold' },
  xs: { base: 5, placeholder: 2, radius: 1, textVariant: 'xs-semibold' },
  s: {
    base: 6,
    placeholder: 3,
    radius: '0.375rem',
    textVariant: 'sm-semibold',
  },
  's-2': {
    base: 7,
    placeholder: 3,
    radius: '0.3125rem',
    textVariant: 'sm-semibold',
  },
  's-3': {
    base: 8,
    placeholder: 5,
    radius: '0.375rem',
    textVariant: 'sm-semibold',
  },
  m: { base: 9, placeholder: 5, radius: 2, textVariant: 'lg-semibold' },
  'm-1': { base: 10, placeholder: 6, radius: 2, textVariant: 'lg-semibold' },
  'm-2': { base: 11, placeholder: 6, radius: 2, textVariant: 'lg-semibold' },
  'm-3': { base: 12, placeholder: 6, radius: 2, textVariant: 'lg-semibold' },
  'm-3.5': {
    base: 13.5,
    placeholder: 7,
    radius: 2,
    textVariant: 'lg-semibold',
  },
  'm-4': { base: 14, placeholder: 8, radius: 2, textVariant: 'lg-semibold' },
  'm-5': { base: 16, placeholder: 9, radius: 2, textVariant: 'lg-semibold' },
  lg: { base: 16, placeholder: 9, radius: 3, textVariant: 'lg-semibold' },
  'lg-5': { base: 18, placeholder: 10, radius: 2, textVariant: 'lg-semibold' },
  'lg-2': { base: 18, placeholder: 10, radius: 4, textVariant: 'lg-semibold' },
  'lg-3': { base: 25, placeholder: 16, radius: 5, textVariant: 'lg-semibold' },
  'lg-4': { base: 20, placeholder: 12, radius: 3, textVariant: 'lg-semibold' },
  xl: { base: 32, placeholder: 18, radius: 5, textVariant: 'lg-semibold' },
  circle: {
    base: 25,
    placeholder: 14,
    radius: 'full',
    textVariant: 'lg-semibold',
  },
} as const;

export interface AvatarProps {
  /**
   * URL of avatar image
   */
  imageId?: string | null;
  /**
   * Size of largest dimension
   */
  size?: AvatarSizes;
  /**
   * Background avatar color
   */
  bgColor?: string;
  /**
   * Placeholder icon for if there is no image
   */
  placeholderIcon?: boolean;
  /**
   * Placeholder icon color
   */
  placeholderColor?: string;
  /**
   * Animal avatar
   */
  avatar?: string;
  /**
   * Initial
   */
  initial?: string;
  /**
   * Text to display on hover
   */
  tooltipText?: string;
}

const StyledAvatar = styled.div<{
  sizeDetails: SizeDetails;
  imageUrl?: string;
  bgColor?: string;
  initial?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1em;
  width: 1em;
  font-size: ${(p) => p.sizeDetails.base * 4}px;

  ${(p) =>
    p.bgColor &&
    css`
      background-color: ${th.color(p.bgColor)};
    `}

  background-image: url('${(p) => p.imageUrl}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: ${(p) => th.size(p.sizeDetails.radius.toString())};
`;

const PlaceholderIcon = styled(Icon)<{
  iconSize: number;
  color: string;
}>`
  display: flex;
  font-size: ${(p) => th.size(p.iconSize.toString())};
`;

/**
 * Component used to render an avatar for a person
 */
export const Avatar: React.FC<AvatarProps> = ({
  imageId,
  size = 'xxs',
  bgColor = 'brand.50',
  avatar,
  placeholderIcon = false,
  placeholderColor = 'brand.300',
  tooltipText,
  initial,
}) => {
  const sizeDetails = sizeMap[size];
  const imageSizePx = sizeDetails.base * 16;

  const element = (
    <StyledAvatar
      sizeDetails={sizeDetails}
      imageUrl={
        imageId
          ? getCloudinaryUrl({ id: imageId, width: imageSizePx })
          : undefined
      }
      bgColor={bgColor}
    >
      {placeholderIcon && !imageId && (
        <PlaceholderIcon
          name="profile"
          iconSize={sizeDetails.placeholder}
          color={placeholderColor}
        />
      )}
      {!!avatar && !placeholderIcon && !imageId && (
        <Icon
          name={avatar}
          size={sizeDetails.placeholder.toString() as IconSizes}
        />
      )}
      {!!initial && !placeholderIcon && !imageId && (
        <Text variant={sizeDetails.textVariant}>{initial}</Text>
      )}
    </StyledAvatar>
  );

  if (!tooltipText) {
    return <>{element}</>;
  }

  return (
    <Tooltip
      label={<Text variant="sm-semibold">{tooltipText}</Text>}
      aria-label={tooltipText}
    >
      {element}
    </Tooltip>
  );
};
