import * as React from "react";
import { SVGProps } from "react";
const SvgRevenue = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 .833c-.685 0-1.25.355-1.667.834H6.458a.625.625 0 0 0-.585.844l1.09 2.904c-1.202.959-5.026 4.384-4.854 9.116.077 2.12 1.838 3.802 3.956 3.802h7.87c2.117 0 3.88-1.682 3.957-3.802.172-4.732-3.653-8.157-4.854-9.116l1.089-2.904a.624.624 0 0 0-.585-.844h-1.874c-.419-.479-.983-.834-1.668-.834Zm0 1.25c.387 0 .712.208.895.523a.625.625 0 0 0 .54.31h1.205L11.859 5H8.14L7.36 2.917h1.204a.625.625 0 0 0 .54-.311c.184-.315.509-.523.896-.523ZM7.923 6.25h4.154c.722.567 4.723 3.91 4.565 8.236a2.698 2.698 0 0 1-2.707 2.597h-7.87a2.698 2.698 0 0 1-2.707-2.597C3.2 10.159 7.202 6.817 7.923 6.25ZM9.99 7.491a.625.625 0 0 0-.615.634v.303c-.832.237-1.458.976-1.458 1.877v.001c0 1.09.896 1.986 1.985 1.986h.612c.414 0 .736.322.736.735a.726.726 0 0 1-.73.735l-.767.007a.583.583 0 0 1-.56-.41.626.626 0 1 0-1.192.375c.195.617.74 1.014 1.374 1.16v.314a.625.625 0 1 0 1.25 0v-.215c1.037-.061 1.875-.916 1.875-1.966 0-1.089-.897-1.985-1.986-1.985h-.612a.727.727 0 0 1-.735-.736c0-.41.315-.73.725-.737l.343-.004c.27 0 .489.176.562.414a.625.625 0 1 0 1.195-.366c-.19-.62-.735-1.018-1.367-1.168v-.32a.625.625 0 0 0-.635-.634Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgRevenue;
