import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import {
  PostFeedFragment,
  usePostQuery,
  ViewLevels,
  ViewLocations,
} from '../../../../generated/types-and-hooks';
import { POST_PREVIEW_CARD_WIDTH_PX } from '../../../config/constants';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Button } from '../../common/components/Button';
import { Icon } from '../../common/components/Icon';
import { NotFoundCard } from '../../common/components/NotFoundCard';
import { Error401View } from '../../errors/views/Error401View';
import { PostNavContext } from '../../questions/hooks/useAnswerNavigation';
import { PostCard, PostCardPlaceholder } from '../components/PostCard';
import { PostViewMutator } from '../components/PostViewMutator';

export const PostDetailView: FC<{
  id?: string | string[];
  showLayout?: boolean;
}> = ({ id, showLayout = true }) => {
  const { organization } = useOrganization();
  const { setOrganization } = useOptionalOrganization();

  const { data, loading } = usePostQuery({
    variables: {
      id: id as string,
    },
    skip: !id,
    onCompleted: (data) => {
      if (
        data.post?.__typename === 'Post' &&
        organization.id !== data.post.organization.id
      ) {
        setOrganization(data.post.organization);
      }
    },
  });

  const showLoading =
    !id ||
    (loading && !data?.post) ||
    (data?.post?.__typename === 'Post' &&
      data.post.organization?.id !== organization.id);

  if (showLoading) {
    return showLayout ? (
      <x.div py={8}>
        <PostCardPlaceholder />
      </x.div>
    ) : (
      <PostCardPlaceholder />
    );
  }

  if (data?.post?.__typename === 'PostError') {
    return <Error401View />;
  }

  return showLayout ? (
    <x.div py={8}>
      <PostDetailViewChildren post={data?.post} />
    </x.div>
  ) : (
    <PostDetailViewChildren post={data?.post} />
  );
};

const PostDetailViewChildren: FC<{
  post?: PostFeedFragment | null;
}> = ({ post }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const autoPlay = router.query.autoplay === 'true';
  const { ctx }: { ctx?: PostNavContext } = router.query;

  if (!post) {
    return <NotFoundCard title={t('post_not_found')} />;
  }

  if (post.deleted) {
    return <NotFoundCard title={t('post_deleted')} />;
  }

  if (post.group.deleted) {
    return <NotFoundCard title={t('group_deleted')} />;
  }

  return (
    <PostViewMutator
      post={post}
      viewLevel={ViewLevels.Detail}
      viewLocation={ViewLocations.PostDetail}
    >
      <x.div
        position="relative"
        maxW={POST_PREVIEW_CARD_WIDTH_PX}
        mx="auto"
        boxSizing="border-box"
        w="full"
      >
        {ctx && (
          <x.div
            display={{ _: 'none', 'lg-2': 'block' }}
            alignSelf="flex-start"
            pb={6}
          >
            <Button
              variant="secondary"
              leftElement={<Icon name="arrow-left" color="gray.300" />}
              px={2}
              onClick={() => router.back()}
            />
          </x.div>
        )}
        <PostCard post={post} autoPlay={autoPlay} />
      </x.div>
    </PostViewMutator>
  );
};
