import * as React from "react";

function SvgAtom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.628 12c2.092 3.422 2.618 6.724.857 8.485-1.76 1.761-5.063 1.236-8.485-.857-3.422 2.093-6.725 2.618-8.485.857-1.761-1.76-1.236-5.063.857-8.485-2.093-3.422-2.618-6.724-.857-8.485 1.76-1.761 5.063-1.236 8.485.857 3.422-2.093 6.724-2.618 8.485-.857 1.76 1.76 1.235 5.063-.857 8.485zM9.17 9.172A22.048 22.048 0 0112 6.774c.943.668 1.902 1.471 2.828 2.398A22.04 22.04 0 0117.225 12a22.04 22.04 0 01-2.397 2.828A22.033 22.033 0 0112 17.226a22.04 22.04 0 01-2.829-2.398A22.047 22.047 0 016.774 12a22.048 22.048 0 012.397-2.828zM4.93 4.929c.861-.862 2.891-.561 5.242.704-.82.63-1.63 1.34-2.414 2.124a24.747 24.747 0 00-2.124 2.414C4.368 7.82 4.067 5.79 4.93 4.929zm11.313 2.828a24.75 24.75 0 012.124 2.414c1.266-2.35 1.566-4.38.705-5.242-.862-.862-2.892-.561-5.242.704a24.75 24.75 0 012.413 2.124zm0 8.486a24.753 24.753 0 002.124-2.414c1.266 2.35 1.566 4.38.705 5.242-.862.862-2.892.561-5.242-.704.819-.63 1.63-1.34 2.413-2.124zM5.633 13.829a24.75 24.75 0 002.124 2.414 24.75 24.75 0 002.414 2.124c-2.35 1.265-4.38 1.566-5.242.704-.862-.861-.561-2.892.704-5.242zM13 12a1 1 0 10-2 0 1 1 0 002 0z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgAtom;
