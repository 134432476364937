import { Data } from 'emoji-mart';
import data from 'emoji-mart/data/apple.json';
import { createContext, PropsWithChildren, useContext } from 'react';

type EmojiDataType = {
  data?: Data;
};

const EmojiDataContext = createContext<EmojiDataType>({
  data,
});

export const EmojiDataProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <EmojiDataContext.Provider value={{ data }}>
      {children}
    </EmojiDataContext.Provider>
  );
};

export const useEmojiData = () => useContext(EmojiDataContext);
