import { Screens } from '@frond/shared';
import {
  FontSizeProps,
  FontWeightProps,
  LineHeightProps,
  x,
} from '@xstyled/styled-components';
import { ComponentProps, FC, forwardRef } from 'react';

export const textStyles = {
  '4xl': {
    lineHeight: 'shorter',
    fontSize: '4xl',
    fontWeight: 'bold',
  },
  '3xl': {
    lineHeight: 'shorter',
    fontSize: '3xl',
    fontWeight: 'bold',
  },
  '2xl': {
    lineHeight: 'shorter',
    fontSize: '2xl',
    fontWeight: 'bold',
  },
  xl: {
    lineHeight: 'short',
    fontSize: 'xl',
    fontWeight: 'bold',
  },
  lg: {
    lineHeight: 'tall',
    fontSize: 'lg',
    fontWeight: 'bold',
  },
  md: {
    lineHeight: 'tall',
    fontSize: 'md',
    fontWeight: 'semibold',
  },
};

export type HeadingVariant = keyof typeof textStyles;

export type HeadingProps = ComponentProps<typeof x.div> & {
  /**
   * Text style from our design system
   */
  variant?: HeadingVariant | { [key in keyof Screens]?: HeadingVariant };
  /**
   * Truncate the end of the text with ellipsis
   */
  truncate?: boolean;
  /**
   * Specify the HTML tag used to render the element
   */
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
};

type HtmlHeadingTag =
  | typeof x.h1
  | typeof x.h2
  | typeof x.h3
  | typeof x.h4
  | typeof x.h5
  | typeof x.h6;

export const headingVariantToHtmlTag: {
  [key in HeadingVariant]: HtmlHeadingTag;
} = {
  '4xl': x.h1,
  '3xl': x.h2,
  '2xl': x.h3,
  xl: x.h4,
  lg: x.h5,
  md: x.h6,
};

export const Heading: FC<HeadingProps> = forwardRef(
  ({ variant = '2xl', children, truncate = false, as, ...props }, ref) => {
    let fontSize: FontSizeProps['fontSize'];
    let fontWeight: FontWeightProps['fontWeight'];
    let lineHeight: LineHeightProps['lineHeight'];

    if (typeof variant === 'object') {
      fontSize = {
        _: variant._ && textStyles[variant._].fontSize,
        sm: variant.sm && textStyles[variant.sm].fontSize,
        md: variant.md && textStyles[variant.md].fontSize,
        'md-2': variant['md-2'] && textStyles[variant['md-2']].fontSize,
        lg: variant.lg && textStyles[variant.lg].fontSize,
        xl: variant.xl && textStyles[variant.xl].fontSize,
        'grid-sm':
          variant['grid-sm'] && textStyles[variant['grid-sm']].fontSize,
        'grid-md':
          variant['grid-md'] && textStyles[variant['grid-md']].fontSize,
        'grid-lg':
          variant['grid-lg'] && textStyles[variant['grid-lg']].fontSize,
      };

      fontWeight = {
        _: variant._ && textStyles[variant._].fontWeight,
        sm: variant.sm && textStyles[variant.sm].fontWeight,
        md: variant.md && textStyles[variant.md].fontWeight,
        'md-2': variant['md-2'] && textStyles[variant['md-2']].fontWeight,
        lg: variant.lg && textStyles[variant.lg].fontWeight,
        xl: variant.xl && textStyles[variant.xl].fontWeight,
        'grid-sm':
          variant['grid-sm'] && textStyles[variant['grid-sm']].fontWeight,
        'grid-md':
          variant['grid-md'] && textStyles[variant['grid-md']].fontWeight,
        'grid-lg':
          variant['grid-lg'] && textStyles[variant['grid-lg']].fontWeight,
      };

      lineHeight = {
        _: variant._ && textStyles[variant._].lineHeight,
        sm: variant.sm && textStyles[variant.sm].lineHeight,
        md: variant.md && textStyles[variant.md].lineHeight,
        'md-2': variant['md-2'] && textStyles[variant['md-2']].lineHeight,
        lg: variant.lg && textStyles[variant.lg].lineHeight,
        xl: variant.xl && textStyles[variant.xl].lineHeight,
        'grid-sm':
          variant['grid-sm'] && textStyles[variant['grid-sm']].lineHeight,
        'grid-md':
          variant['grid-md'] && textStyles[variant['grid-md']].lineHeight,
        'grid-lg':
          variant['grid-lg'] && textStyles[variant['grid-lg']].lineHeight,
      };
    } else {
      const styles = textStyles[variant];
      fontSize = styles.fontSize;
      fontWeight = styles.fontWeight;
      lineHeight = styles.lineHeight;
    }

    const autoAs =
      typeof variant === 'object'
        ? headingVariantToHtmlTag[
            variant.xl ||
              variant.lg ||
              variant.md ||
              variant['md-2'] ||
              variant.sm ||
              variant._ ||
              '2xl'
          ]
        : headingVariantToHtmlTag[variant];

    return (
      // @ts-expect-error related to xstyled v4 and the as prop
      <x.p
        fontSize={fontSize}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
        {...(truncate && {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        })}
        as={as || autoAs}
        ref={ref}
        {...props}
      >
        {children}
      </x.p>
    );
  }
);

Heading.displayName = 'Heading';
