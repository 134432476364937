import * as React from 'react';
import { SVGProps } from 'react';

const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M12 2c-.789 0-1.549.1-2.274.27a.75.75 0 0 0-.576.649l-.159 1.45a1.499 1.499 0 0 1-2.095 1.21l-1.333-.587a.75.75 0 0 0-.85.174A9.982 9.982 0 0 0 2.43 9.1a.75.75 0 0 0 .274.823l1.183.867a1.5 1.5 0 0 1 0 2.42l-1.183.866a.75.75 0 0 0-.274.822 9.979 9.979 0 0 0 2.283 3.937.75.75 0 0 0 .85.173l1.333-.587a1.5 1.5 0 0 1 2.095 1.21l.16 1.45a.75.75 0 0 0 .574.65C10.45 21.9 11.21 22 12 22c.788 0 1.549-.1 2.274-.269a.75.75 0 0 0 .576-.65l.159-1.45a1.498 1.498 0 0 1 2.095-1.21l1.334.587a.75.75 0 0 0 .85-.173 9.983 9.983 0 0 0 2.282-3.937.75.75 0 0 0-.274-.822l-1.183-.866a1.498 1.498 0 0 1 0-2.42l1.183-.867a.75.75 0 0 0 .274-.822 9.984 9.984 0 0 0-2.283-3.936.75.75 0 0 0-.85-.173l-1.334.587a1.5 1.5 0 0 1-2.094-1.21l-.16-1.451a.75.75 0 0 0-.574-.649A9.997 9.997 0 0 0 12 2Zm0 1.5c.487 0 .955.088 1.424.17l.094.863a3.003 3.003 0 0 0 4.19 2.42l.793-.349a8.43 8.43 0 0 1 1.428 2.462l-.703.516a3.001 3.001 0 0 0 0 4.838l.703.515a8.43 8.43 0 0 1-1.428 2.462l-.793-.348a3.001 3.001 0 0 0-4.19 2.42l-.094.862c-.469.082-.937.17-1.424.17-.487 0-.955-.088-1.424-.17l-.094-.863a3.003 3.003 0 0 0-4.19-2.42l-.793.35a8.424 8.424 0 0 1-1.428-2.463l.703-.515a3.001 3.001 0 0 0 0-4.84l-.703-.515c.337-.905.82-1.73 1.429-2.462l.792.349a3.001 3.001 0 0 0 4.19-2.42l.094-.861c.469-.083.937-.17 1.424-.17ZM12 8c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4Zm0 1.5c1.39 0 2.5 1.111 2.5 2.5 0 1.39-1.11 2.5-2.5 2.5S9.5 13.39 9.5 12c0-1.389 1.11-2.5 2.5-2.5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSettings;
