import { Language } from '@frond/shared/lib/i18n';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

import { useUserQuery } from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../../modules/auth/hooks/useOrganization';
import { ProfileView } from '../../../modules/questions/views/ProfileView';

export async function getStaticProps({ locale }: { locale: Language }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'settings'])),
    },
    revalidate: 60,
  };
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  };
}

const ProfilePage = ({
  username,
  showLayout = true,
}: {
  username?: string;
  showLayout?: boolean;
}) => {
  const router = useRouter();
  const chosenUsername =
    username || (router.query.username as string | undefined);

  const { organization } = useOrganization();

  const { data: dataUser } = useUserQuery({
    variables: {
      username: chosenUsername || '',
      organizationId: organization.id,
    },

    skip: !chosenUsername,
    context: {
      skip: !chosenUsername,
    },
  });

  return (
    <ProfileView
      user={dataUser?.user}
      showLayout={showLayout}
      username={chosenUsername}
    />
  );
};

export default ProfilePage;
