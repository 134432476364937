import { styled, SystemProps, x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { Button } from '../../../common/components/Button';
import { Icon } from '../../../common/components/Icon';
import { Link } from '../../../common/components/Link';
import { Text } from '../../../common/components/Text';
import analytics from '../../../common/utils/analytics';
import { useAuth } from '../../hooks/useAuth';
import { SignInEmailSigninForm } from './SignInEmailForm';
const StyledIcon = styled(Icon)`
  path {
    fill: white;
  }
`;

export const SignInForm: React.FC<
  SystemProps & {
    onEmailClick?: () => void;
    onEmailSuccess?: () => void;
    onLoginAttempt?: () => void;
    inviteCode?: string;
    callbackUrlPath?: string;
    labelGoogle?: string;
    labelEmail?: string;
    variant?: 'default' | 'public';
  }
> = ({
  onEmailClick,
  onEmailSuccess,
  onLoginAttempt,
  inviteCode,
  callbackUrlPath,
  variant = 'default',
  ...props
}) => {
  const { loginWithPopup } = useAuth();
  const [showSignInLinkForm, setShowSignInLinkForm] = useState(false);
  const router = useRouter();
  const { t } = useTranslation('common');

  const labelGoogle = props.labelGoogle || t('auth.continue_google');
  const labelEmail = props.labelEmail || t('auth.continue_email');

  return (
    <>
      <x.div w="full" flex={1} spaceY={3} mb={6} {...props}>
        {showSignInLinkForm ? (
          <SignInEmailSigninForm
            callbackUrlPath={
              callbackUrlPath || (router.query.callbackUrlPath as string)
            }
            onSubmit={onEmailSuccess}
            inviteCode={inviteCode}
            shortId={router.query.shortId as string}
          />
        ) : (
          <>
            <Button
              label={labelGoogle}
              p={4}
              w="full"
              onClick={() => {
                // adding invite code here will pass it as a query param to auth0
                // We can append it to the user (via rule) and check it during user creation
                loginWithPopup({
                  authorizationParams: {
                    connection: 'google-oauth2',
                    prompt: 'select_account',
                    inviteCode,
                    shortId: router.query.shortId,
                  },
                });
                onLoginAttempt?.();
                analytics.logEvent(analytics.events.SIGN_IN);
              }}
              leftElement={<StyledIcon size="4.5" name="app-google" />}
            />
            <Button
              p={4}
              variant="secondary"
              label={labelEmail}
              w="full"
              onClick={() => {
                onEmailClick?.();
                setShowSignInLinkForm(true);
              }}
            />
          </>
        )}
      </x.div>
      <Text
        mb={{ _: 6, md: 0 }}
        color="gray.300"
        variant="sm"
        textAlign="center"
      >
        {variant === 'public' ? `${t('auth.continue_behavior')} ` : ''}
        {t('auth.agree')}
        <Text as="span" variant="sm-medium">
          <Link
            href="/terms"
            target={'_blank'}
            rel={'noopener'}
            metadata={{ event: analytics.events.TERMS_LINK }}
            styleInheritColor
          >
            {t('auth.terms')}
          </Link>
        </Text>
        {t('auth.and')}
        <Text as="span" variant="sm-medium">
          <Link
            href="/privacy"
            target={'_blank'}
            rel={'noopener'}
            metadata={{ event: analytics.events.PRIVACY_LINK }}
            styleInheritColor
          >
            {t('auth.privacy_policy')}
          </Link>
        </Text>
        .
      </Text>
    </>
  );
};
