import * as React from "react";

function SvgAppGusto(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-gusto_svg__clip0)">
        <path
          d="M21.311 8.334a2.699 2.699 0 00-2.69 2.702 2.699 2.699 0 002.69 2.701A2.698 2.698 0 0024 11.036a2.698 2.698 0 00-2.689-2.702zm0 4.123a1.42 1.42 0 01-1.415-1.421 1.42 1.42 0 011.415-1.421 1.42 1.42 0 010 2.842zm-7.919-1.968l-.47-.236c-.204-.102-.332-.18-.384-.238a.284.284 0 01-.078-.191c0-.091.04-.168.122-.228.08-.062.193-.091.338-.091.264 0 .556.16.878.48l.794-.797a2.09 2.09 0 00-.744-.63 2.061 2.061 0 00-.937-.22c-.484 0-.884.143-1.196.431a1.367 1.367 0 00-.468 1.04c0 .63.412 1.155 1.24 1.57l.433.218c.373.19.559.381.559.58 0 .108-.052.201-.155.284a.63.63 0 01-.409.123c-.156 0-.335-.054-.538-.164a1.81 1.81 0 01-.528-.427l-.79.864c.446.584 1.04.879 1.786.879.561 0 1.009-.154 1.34-.458.335-.305.502-.686.502-1.142 0-.342-.093-.642-.277-.897-.185-.255-.525-.503-1.018-.75zm-7.985 2.66V8.442H4.131v.315a2.662 2.662 0 00-1.442-.423A2.698 2.698 0 000 11.036a2.698 2.698 0 002.689 2.701c.505.001 1-.142 1.427-.412l-.002.104A1.42 1.42 0 012.7 14.85c-.265 0-.524-.076-.748-.218l-.631 1.111c.416.254.894.389 1.382.39a2.713 2.713 0 002.705-2.702c0-.058.004-.228 0-.282zm-2.718-.692a1.42 1.42 0 01-1.415-1.421A1.42 1.42 0 012.69 9.615a1.42 1.42 0 010 2.842zm7.803-4.017H9.21v2.568c.002.365.002.995-.265 1.264-.128.129-.269.245-.563.245a.707.707 0 01-.565-.245c-.269-.27-.266-.9-.265-1.264V8.44H6.27V11c-.004.529-.01 1.509.633 2.16.377.38.827.575 1.476.575.65 0 1.1-.192 1.477-.576.643-.65.637-1.632.633-2.158l.004-2.561zm7.27 3.787c-.104.079-.353.253-.636.214-.23-.032-.418-.201-.447-.769v-2.13h1.507V8.436H16.68V7H15.4v.282h-.002v4.222c0 .672.17 2.231 1.726 2.231.788-.012 1.266-.44 1.467-.608l.026-.021-.767-.95c-.002.003-.056.046-.089.071z"
          fill="#F45D48"
        />
      </g>
      <defs>
        <clipPath id="app-gusto_svg__clip0">
          <path fill="#fff" transform="translate(0 7)" d="M0 0h24v9.391H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppGusto;
