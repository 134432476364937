import * as React from "react";

function SvgKoala(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.168 14.856a.625.625 0 01-.531-.293 13.676 13.676 0 00-6.25-5.338.625.625 0 01-.375-.625 5.937 5.937 0 117.294 6.25l-.138.006z"
        fill="#212121"
      />
      <path
        d="M26.318 8.269a14.913 14.913 0 016.12 5.237 4.688 4.688 0 10-6.12-5.237z"
        fill="#455A64"
      />
      <path
        d="M20 36.875a15 15 0 110-29.998 15 15 0 010 29.998z"
        fill="#212121"
      />
      <path
        d="M20 8.125a13.706 13.706 0 10.04 27.412A13.706 13.706 0 0020 8.125z"
        fill="#B0BEC5"
      />
      <path
        d="M7.83 14.856h-.143A5.937 5.937 0 1115 8.6a.625.625 0 01-.375.625 13.675 13.675 0 00-6.25 5.338.626.626 0 01-.544.293z"
        fill="#212121"
      />
      <path
        d="M9.062 4.375a4.687 4.687 0 00-1.506 9.131 14.906 14.906 0 016.119-5.237 4.706 4.706 0 00-4.613-3.894z"
        fill="#455A64"
      />
      <path
        d="M20 34.375c-2.756 0-5-1.681-5-3.75a3.393 3.393 0 011.65-2.781.625.625 0 01.95.35A2.5 2.5 0 0019.831 30h.344a2.5 2.5 0 002.231-1.8.625.625 0 01.95-.35A3.393 3.393 0 0125 30.625c0 2.069-2.244 3.75-5 3.75z"
        fill="#212121"
      />
      <path
        d="M16.756 29.375c-.318.34-.498.785-.506 1.25 0 1.356 1.719 2.5 3.75 2.5 2.031 0 3.75-1.144 3.75-2.5a1.875 1.875 0 00-.506-1.25 3.75 3.75 0 01-2.963 1.875 2.06 2.06 0 01-.569 0 3.749 3.749 0 01-2.956-1.875z"
        fill="#BDBDBD"
      />
      <path
        d="M13.125 20a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm13.75 0a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zM20 31.25a2 2 0 01-.281 0 3.75 3.75 0 01-3.325-2.7 3.914 3.914 0 01-.144-1.05V20a3.75 3.75 0 017.5 0v7.5c-.002.346-.05.691-.144 1.025a3.75 3.75 0 01-3.325 2.725 2 2 0 01-.281 0z"
        fill="#212121"
      />
      <path
        d="M20 17.5a2.5 2.5 0 00-2.5 2.5v7.5c0 .236.032.472.094.7A2.5 2.5 0 0019.83 30h.344a2.5 2.5 0 002.231-1.8c.063-.228.094-.463.094-.7V20a2.5 2.5 0 00-2.5-2.5z"
        fill="#455A64"
      />
    </svg>
  );
}

export default SvgKoala;
