import * as React from "react";

function AppX(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <g clipPath="url(#x-icon_svg__a)">
        <path
          fill="#000"
          d="M18.45 1.8h3.45l-7.575 8.625 8.85 11.7H16.23l-5.438-7.11-6.224 7.11h-3.45L9.143 12.9.668 1.8h7.117l4.912 6.495L18.45 1.8Zm-1.207 18.3h1.912L6.78 3.75H4.725L17.242 20.1Z"
        />
      </g>
      <defs>
        <clipPath id="x-icon_svg__a">
          <path fill="#fff" d="M.75 1.8h22.5v20.325H.75z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AppX;

