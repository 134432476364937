import * as React from "react";
import { SVGProps } from "react";
const SvgActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.057 2.5a.625.625 0 0 0-.628.502L8.829 11 7.89 7.94a.625.625 0 0 0-1.172-.063L5.63 10.417H3.125a1.042 1.042 0 1 0 0 1.25h2.917a.625.625 0 0 0 .574-.379l.58-1.35 1.165 3.788a.625.625 0 0 0 1.21-.062l1.376-6.882 1.56 10.6a.625.625 0 0 0 1.224.061l1.549-6.193h1.596a1.041 1.041 0 1 0 0-1.25h-2.084a.625.625 0 0 0-.607.474l-.9 3.604L11.66 3.034a.625.625 0 0 0-.603-.533Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgActive;
