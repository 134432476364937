import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { useState } from 'react';

import { EventCoreFragment } from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Button } from '../../common/components/Button';
import { useCanViewerPostToGroup } from '../../groups/hooks/useCanViewerPostToGroup';
import { PostComposer } from '../../posts/components/PostComposer';
import { useEventAttendance } from '../hooks/useEventAttendance';

export const EventPostButton: FC<{ event: EventCoreFragment }> = ({
  event,
}) => {
  const { t } = useTranslation();
  const { organization } = useOrganization();
  const canPost = useCanViewerPostToGroup(event);
  const { userIsMember } = useEventAttendance({
    event,
    organization,
  });

  const [composerOpen, setComposerOpen] = useState(false);

  if (!canPost || !userIsMember) return null;

  return (
    <>
      <Button
        variant="secondary"
        label={t('new_post')}
        onClick={() => setComposerOpen(true)}
      />
      {composerOpen && (
        <PostComposer
          isOpen
          onDismiss={() => {
            setComposerOpen(false);
          }}
          group={event}
        />
      )}
    </>
  );
};
