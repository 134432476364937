import { theme } from '@frond/shared';
import * as Sentry from '@sentry/nextjs';
import { keyframes, styled, x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { FrondLogoMinimal } from './FrondLogoMinimal';

const pulse = keyframes`
0%,
100% {
  opacity: 1;
}
50% {
  opacity: 0.7;
}
`;

const Pulse = styled.div`
  animation: ${pulse} 2s ease-in-out infinite;
`;

/**
 * Utility component to display while data for a page is loading
 */
export const Loading: React.FC = () => {
  const router = useRouter();

  useEffect(() => {
    const timer = setTimeout(() => {
      Sentry.captureMessage('Loading shown longer than 3 seconds', {
        level: 'info',
        extra: {
          path: router.asPath,
        },
      });
    }, 3000);
    return () => clearTimeout(timer);
  });

  return (
    <x.div
      top={0}
      left={0}
      right={0}
      bottom={0}
      display="flex"
      position="absolute"
      alignItems="center"
      justifyContent="center"
    >
      <Pulse>
        <FrondLogoMinimal
          w="5.375rem"
          h="3.25rem"
          accentColor={theme.colors.purple[300]}
          data-test="loading"
        />
      </Pulse>
    </x.div>
  );
};
Loading.displayName = 'Loading';
