import * as React from "react";

function SvgCow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.625 31.875h-11.25a.625.625 0 01-.625-.519l-2.875-16.3a4.84 4.84 0 014.75-5.681h8.75a4.837 4.837 0 014.769 5.681l-2.894 16.3a.625.625 0 01-.625.519z"
        fill="#212121"
      />
      <path
        d="M14.9 30.625h10.2l2.787-15.787a3.587 3.587 0 00-3.512-4.213h-8.75a3.588 3.588 0 00-3.538 4.213L14.9 30.625z"
        fill="#8D6E63"
      />
      <path
        d="M26.25 36.875h-12.5a3.125 3.125 0 110-6.25h12.5a3.125 3.125 0 110 6.25z"
        fill="#212121"
      />
      <path
        d="M13.75 31.875a1.875 1.875 0 100 3.75h12.5a1.875 1.875 0 100-3.75h-12.5z"
        fill="#616161"
      />
      <path
        d="M3.75 20.625a.624.624 0 01-.594-.825l.306-.913a9.662 9.662 0 016.825-6.337l1.25-.313a.625.625 0 01.756.763l-1.043 4.063A4.275 4.275 0 017.5 20.25l-3.688.375H3.75z"
        fill="#212121"
      />
      <path
        d="M10.8 13.713l-.175.037a8.419 8.419 0 00-5.95 5.519l2.756-.275A3.026 3.026 0 0010 16.756l.8-3.043z"
        fill="#4E342E"
      />
      <path
        d="M36.25 20.625h-.063L32.5 20.25a4.274 4.274 0 01-3.75-3.188L27.712 13a.624.624 0 01.756-.762l1.25.312a9.663 9.663 0 016.82 6.344l.305.912a.625.625 0 01-.593.819z"
        fill="#212121"
      />
      <path
        d="M29.2 13.713l.8 3.043a3.025 3.025 0 002.618 2.25l2.756.275a8.418 8.418 0 00-6-5.531l-.175-.037z"
        fill="#4E342E"
      />
      <path
        d="M18.75 20.288a9.644 9.644 0 01-4.257 8.043l-.237-1.369-2.144-12.124a3.537 3.537 0 01.388-2.288 3.28 3.28 0 01.425-.625c.111-.132.232-.255.362-.369a9.681 9.681 0 015.463 8.732z"
        fill="#D7CCC8"
      />
      <path
        d="M25 10.625H15A6.875 6.875 0 018.125 3.75a.625.625 0 011.25 0A5.625 5.625 0 0015 9.375h10a5.625 5.625 0 005.625-5.625.625.625 0 111.25 0A6.875 6.875 0 0125 10.625zm-8.75 10a.624.624 0 01-.444-.181l-1.25-1.25a.625.625 0 01.882-.881l1.25 1.25a.625.625 0 01-.438 1.062zm7.5 0a.624.624 0 01-.444-1.069l1.25-1.25a.625.625 0 01.881.881l-1.25 1.25a.625.625 0 01-.437.188zM15 35a.624.624 0 01-.625-.625v-1.25a.624.624 0 111.25 0v1.25A.624.624 0 0115 35zm10 0a.624.624 0 01-.625-.625v-1.25a.624.624 0 111.25 0v1.25A.624.624 0 0125 35z"
        fill="#212121"
      />
      <path
        d="M18.75 20.287a9.644 9.644 0 01-4.257 8.044c-.137.1-.275.188-.418.275a.625.625 0 01-.325.088.625.625 0 01-.507-.257l-.118-.537a.625.625 0 01.275-.363c.289-.173.566-.365.83-.575A8.481 8.481 0 0012.5 12.55l-.213-.1a.625.625 0 01.475-1.15 9.681 9.681 0 015.988 8.988z"
        fill="#212121"
      />
    </svg>
  );
}

export default SvgCow;
