import * as React from "react";

function SvgAppAirtable(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-airtable_svg__clip0)">
        <path
          d="M10.712 2.175L1.77 5.876a.6.6 0 00.008 1.11l8.98 3.562c.79.313 1.669.313 2.458 0l8.98-3.561a.6.6 0 00.009-1.111l-8.943-3.7a3.333 3.333 0 00-2.549 0z"
          fill="#FFBF00"
        />
        <path
          d="M12.783 12.493v8.897a.6.6 0 00.82.557l10.008-3.884a.599.599 0 00.378-.557V8.608a.6.6 0 00-.82-.557l-10.008 3.885a.6.6 0 00-.378.557z"
          fill="#26B5F8"
        />
        <path
          d="M10.446 12.952l-2.97 1.434-.302.146-6.27 3.004c-.397.192-.904-.098-.904-.54v-8.35c0-.16.082-.298.192-.402a.681.681 0 01.151-.113.64.64 0 01.546-.042l9.507 3.766c.483.192.521.87.05 1.097z"
          fill="#ED3049"
        />
        <path
          d="M10.445 12.952l-2.97 1.434L.191 8.244a.682.682 0 01.152-.113.64.64 0 01.545-.042l9.508 3.766c.483.192.52.87.05 1.097z"
          fill="currentColor"
          fillOpacity={0.25}
        />
      </g>
      <defs>
        <clipPath id="app-airtable_svg__clip0">
          <path
            fill="#fff"
            transform="translate(0 1.922)"
            d="M0 0h24v20.156H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppAirtable;
