import * as React from "react";

function SvgAppFigma(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.615 24a4.009 4.009 0 004.008-4.008v-4.087H8.615a4.009 4.009 0 100 8.017V24z"
        fill="#0ACF83"
      />
      <path
        d="M4.5 12a4.009 4.009 0 014.113-3.904h3.904v7.809H8.613A4.01 4.01 0 014.5 12z"
        fill="#A259FF"
      />
      <path
        d="M4.501 3.984A4.008 4.008 0 018.614 0h3.904v8.095H8.614a4.008 4.008 0 01-4.113-4.112z"
        fill="#F24E1E"
      />
      <path
        d="M12.519.001h3.904a4.047 4.047 0 010 8.095h-3.904V.001z"
        fill="#FF7262"
      />
      <path
        d="M20.534 12a4.008 4.008 0 11-8.012.207A4.008 4.008 0 0120.534 12z"
        fill="#1ABCFE"
      />
    </svg>
  );
}

export default SvgAppFigma;
