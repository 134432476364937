import { MyValue } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { FC, PropsWithChildren } from 'react';

import {
  GroupWithMembersFragment,
  refetchGroupQuery,
  refetchGroupsQuery,
  useUpdateGroupMutation,
} from '../../../../../../generated/types-and-hooks';
import { GROUPS_PER_PAGE } from '../../../../../config/constants';
import { useOrganization } from '../../../../auth/hooks/useOrganization';
import { TabbedModalContentInset } from '../../../../common/components/TabbedModal';
import { GroupFormDeleteButton } from '../GroupForm/GroupFormDeleteButton';
import { GroupFormDescriptionField } from '../GroupForm/GroupFormDescriptionField';
import { GroupFormNameField } from '../GroupForm/GroupFormNameField';
import { GroupFormSubmitButton } from '../GroupForm/GroupFormSubmitButton';
import {
  GroupEditAboutForm,
  GroupEditAboutFormData,
} from './GroupEditAboutForm';
import { GroupEditAboutHeader } from './GroupEditAboutHeader';

export const GroupEditAboutTab: FC<{
  group: GroupWithMembersFragment;
}> = ({ group }) => {
  const { t } = useTranslation(['common', 'groups']);
  const { organization } = useOrganization();

  const [mutateUpdateGroup, { loading }] = useUpdateGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      refetchGroupQuery({
        id: group.id,
      }),
      refetchGroupsQuery({
        organizationId: organization.id,
        first: GROUPS_PER_PAGE - 1,
      }),
    ],
  });

  const handleUpdateGroup = ({
    description,
    ...data
  }: GroupEditAboutFormData) => {
    const { title, ...values } = data;

    mutateUpdateGroup({
      variables: {
        input: {
          id: group.id,
          name: title,
          description: description as MyValue,
          ...values,
        },
      },
    });
  };

  return (
    <GroupEditAboutForm group={group} onSubmit={handleUpdateGroup}>
      <GroupEditAboutHeader group={group} />
      <TabbedModalContentInset>
        <FormLayout>
          <GroupFormNameField type={group.groupType} />
          <GroupFormDescriptionField type={group.groupType} />
        </FormLayout>
        <x.div display="flex" justifyContent="space-between" mt={4}>
          <GroupFormDeleteButton group={group} />
          <GroupFormSubmitButton loading={loading} label={t('save')} />
        </x.div>
      </TabbedModalContentInset>
    </GroupEditAboutForm>
  );
};

export const FormLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <x.div display="flex" flexDirection="column" spaceY={4} flex={1}>
      {children}
    </x.div>
  );
};
