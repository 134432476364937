import { theme } from '@frond/shared';
import { styled, system, SystemProps, x } from '@xstyled/styled-components';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useSearchQuery } from '../../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../../auth/hooks/useOptionalOrganization';
import { Button } from '../../../common/components/Button';
import { Icon } from '../../../common/components/Icon';
import { Input } from '../../../common/components/Input';
import { LoadingPulse } from '../../../common/components/LoadingPulse';
import { Modal } from '../../../common/components/Modal';
import { Text } from '../../../common/components/Text';
import { GroupSearchResult } from './GroupSearchResult';

const SEARCH_LOCAL_STORAGE_KEY = 'recent-search';

const StyledInput = styled(Input)<SystemProps>`
  div {
    padding-left: 0px;
  }

  input {
    padding-left: 31px;
    flex-grow: 0;
    border: 0;

    ${system};
  }
`;

const StyledModal = styled(Modal)`
  [data-reach-dialog-overlay] {
    align-items: flex-start;
  }

  [data-reach-dialog-content] {
    border-radius: ${theme.radii['md-lg']};
    padding: ${theme.sizes['4']} ${theme.sizes['6']};
  }
`;

export const GroupsSearchModal: React.FC<{
  showModal: boolean;
  onDismiss: () => void;
}> = ({ showModal, onDismiss }) => {
  const [search, setSearch] = useState<string>('');
  const [selected, setSelected] = useState<number>(0);
  const [hasRecentSearch, setHasRecentSearch] = useState<boolean>(false);
  const router = useRouter();
  const { organization } = useOptionalOrganization();
  const { t } = useTranslation();

  const handleRemoveStoredSearch = useCallback(() => {
    localStorage.removeItem(SEARCH_LOCAL_STORAGE_KEY);
    setSearch('');
    setHasRecentSearch(false);
  }, []);

  useEffect(() => {
    // Reset search when modal re-opens
    if (showModal) {
      setSelected(0);

      // Restore recent search from local storage
      const recentSearch = localStorage.getItem(SEARCH_LOCAL_STORAGE_KEY);
      if (recentSearch) {
        const item = JSON.parse(recentSearch);
        const now = new Date();
        if (now.getTime() > item.expiry) {
          handleRemoveStoredSearch();
        } else {
          setSearch(item.value);
          setHasRecentSearch(true);
        }
      }
    }
  }, [showModal, handleRemoveStoredSearch]);

  const { data, refetch, loading } = useSearchQuery({
    variables: {
      organizationId: organization?.id || '',
      query: search,
    },
    skip: search === '' || !organization,
    context: {
      skip: search === '' || !organization,
    },
  });

  useEffect(() => {
    // Update local storage with recent search
    if (search !== '' && data?.search?.length) {
      const now = new Date();
      const ttl = 1000 * 60 * 5; // 5 minutes
      const item = {
        value: search,
        expiry: now.getTime() + ttl,
      };
      localStorage.setItem(SEARCH_LOCAL_STORAGE_KEY, JSON.stringify(item));
    }
  }, [search, data]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!showModal) return;
      const numItems = data?.search?.length || 0;
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelected((prev) => (prev + 1 === numItems ? 0 : prev + 1));
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelected((prev) => (prev - 1 < 0 ? numItems - 1 : prev - 1));
      } else if (e.key === 'Enter') {
        e.preventDefault();
        if (data?.search?.[selected]) {
          onDismiss();
          router.push(data.search[selected].link);
        }
      }
    },
    [data, onDismiss, router, selected, showModal]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [data, handleKeyDown]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debouncedSearch(event);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(
        (event) =>
          refetch({
            organizationId: organization?.id || '',
            query: event.target.value,
          }),
        250
      ),
    [organization, refetch]
  );

  return (
    <StyledModal
      ariaLabel="Search modal"
      isOpen={showModal}
      onDismiss={onDismiss}
      showClose={false}
      center
      centerTop
    >
      <x.div w={{ sm: '552px' }} spaceY={4}>
        <x.div
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <StyledInput
            placeholder="Search"
            icon={<Icon name="search" />}
            onChange={handleChange}
            fullWidth
            w="100%"
            value={search}
            autoFocus
          />
          <x.div
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            spaceX={2}
          >
            {loading && (
              <x.div minWidth={9} textAlign="right">
                <LoadingPulse />
              </x.div>
            )}
            {hasRecentSearch && (
              <Button
                variant="icon"
                onClick={handleRemoveStoredSearch}
                leftElement={
                  <Icon
                    name="circle-filled-x"
                    size="5"
                    color={{
                      _: 'gray.200',
                      hover: 'brand.300',
                    }}
                  />
                }
              />
            )}
          </x.div>
        </x.div>
        {data?.search && data?.search.length > 0 ? (
          <x.div spaceY={5}>
            {data?.search?.map((item, i) => (
              <x.div
                key={item.id}
                onClick={onDismiss}
                onMouseEnter={() => setSelected(i)}
              >
                <GroupSearchResult result={item} selected={i === selected} />
              </x.div>
            ))}
          </x.div>
        ) : (
          data?.search?.length === 0 && (
            <x.div py={7} textAlign="center">
              <Text variant="md" color="gray.300">
                {t('no_results')}
              </Text>
            </x.div>
          )
        )}
      </x.div>
    </StyledModal>
  );
};
