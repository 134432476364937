import { useApolloClient } from '@apollo/client';
import { routes } from '@frond/shared';
import { useRouter } from 'next/router';

import {
  AddOrganizationVisitDocument,
  AddOrganizationVisitMutation,
  AddOrganizationVisitMutationVariables,
  refetchVisitedPublicOrganizationsQuery,
} from '../../../../generated/types-and-hooks';
import { ORGANIZATION_SHORT_ID } from '../../../config/constants';
import { deleteCookie, setCookie } from '../../auth/utils/cookies';
import { AppContext, AppEvent } from './appMachine';

export const useAppMachineActions = () => {
  const client = useApolloClient();
  const router = useRouter();

  const addOrganizationVisit = (context: AppContext) => {
    if (!context.organization || !context.user) return;
    return client.mutate<
      AddOrganizationVisitMutation,
      AddOrganizationVisitMutationVariables
    >({
      mutation: AddOrganizationVisitDocument,
      variables: {
        input: {
          organizationId: context.organization.id,
        },
      },
      refetchQueries: [
        refetchVisitedPublicOrganizationsQuery({
          userId: context.user.id,
        }),
      ],
    });
  };

  const setShortIdCookie = (_: AppContext, event: AppEvent) => {
    if (event.type === 'SET_ORGANIZATION' && event.organization) {
      setCookie(ORGANIZATION_SHORT_ID, event.organization.shortId);
    }
  };

  const deleteShortIdCookie = () => deleteCookie(ORGANIZATION_SHORT_ID);

  const updateOrganizationRoute = (context: AppContext) => {
    if (context.organization) {
      router.replace(
        routes.groups.organization(context.organization.shortId).feed()
      );
    }
  };

  return {
    addOrganizationVisit,
    setShortIdCookie,
    deleteShortIdCookie,
    updateOrganizationRoute,
  };
};
