import { useRouter } from 'next/router';

import { useGroupQuery } from '../../../../generated/types-and-hooks';
import { DetailLayoutMobileBar } from '../../common/layout/DetailLayout';
import { GroupViewControls } from '../../questions/views/GroupView';

export const GroupMobileBar = () => {
  const router = useRouter();
  const id = router.query.id as string | undefined;

  const { data } = useGroupQuery({
    variables: {
      id: id as string,
    },
    skip: !id,
    context: {
      skip: !id,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (data?.group?.__typename === 'GroupError') return null;

  return (
    <DetailLayoutMobileBar
      numPosts={data?.group?.numPosts}
      item={data?.group}
      controls={<GroupViewControls group={data?.group} />}
    />
  );
};
