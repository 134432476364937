import { routes } from '@frond/shared';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

import {
  Organization,
  PostFeedFragment,
  useHomeFeedQuery,
  usePostQuery,
  usePostsByGroupQuery,
} from '../../../../generated/types-and-hooks';
import { POSTS_PER_PAGE } from '../../../config/constants';
import { useOrganization } from '../../auth/hooks/useOrganization';

/**
 * The answer view can exist in four navigation contexts
 * - feed
 * - user
 * - question
 * - share
 * - unseen
 *
 * These determine the feed for the prev / next links in the view
 * You can use getAnswerNavigationLinkProps to configure NextLink correctly
 */

export enum PostNavContext {
  FEED = 'feed',
  USER = 'user',
  GROUP = 'group',
  SHARE = 'share',
  UNSEEN = 'unseen',
  INBOX = 'inbox',
  SETTINGS = 'SETTINGS',
  BOOKMARKS = 'BOOKMARKS',
}

/**
 * Generates NextLink props to work with answer navigation
 */
export const getAnswerNavigationLinkProps = (
  answerId: string,
  organization: Pick<Organization, 'shortId'>,
  ctx: PostNavContext
) => {
  const pathname = routes.groups
    .organization(organization.shortId)
    .post(answerId);

  return {
    href: {
      pathname,
      query: { ctx, autoplay: true },
    },
    as: pathname,
  };
};

/**
 * Resolves prev / next posts based on where the user came from
 * Will be used when a NextLink is passed the result of `useAnswerNavigationLink` above
 */
export const useAnswerNavigation = (
  postId: string
): {
  prev: PostFeedFragment | null;
  next: PostFeedFragment | null;
  ctx: PostNavContext;
} => {
  const { ctx }: { ctx?: PostNavContext } = useRouter().query;

  const { organization } = useOrganization();

  const { data } = usePostQuery({
    variables: { id: postId },
    fetchPolicy: 'cache-first',
    skip: !ctx || ctx === PostNavContext.SHARE,
    context: {
      skip: !ctx || ctx === PostNavContext.SHARE,
    },
  });

  const post = data?.post as PostFeedFragment;

  // Question context answer feed
  const { data: groupData } = usePostsByGroupQuery({
    variables: {
      groupId: post?.group.id as string,
      first: POSTS_PER_PAGE,
    },
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    skip: ctx !== PostNavContext.GROUP || !post?.group.id,
  });

  // User context answer feed
  const {
    data: userFeedData,
    loading: userFeedLoading,
    fetchMore: userFeedFetchMore,
  } = useHomeFeedQuery({
    variables: {
      organizationId: organization.id,
      username: post?.createdBy.username as string,
      first: POSTS_PER_PAGE,
    },
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    skip: ctx !== PostNavContext.USER || !post?.createdBy.username,
  });

  // Activity feed
  const {
    data: organizationFeedData,
    fetchMore: organizationFeedFetchMore,
    loading: organizationFeedLoading,
  } = useHomeFeedQuery({
    variables: {
      organizationId: organization.id,
      first: POSTS_PER_PAGE,
    },
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    skip: ctx !== PostNavContext.FEED,
    context: {
      skip: ctx !== PostNavContext.FEED,
    },
  });

  // Mapping of nav contexts to answer lists
  const postSources: {
    [c in PostNavContext]?: PostFeedFragment[];
  } = useMemo(
    () => ({
      [PostNavContext.GROUP]: groupData
        ? groupData.postsByGroup.edges
            .map((e) => e.node)
            .filter((n) => n.__typename === 'Post')
        : [],
      [PostNavContext.USER]: userFeedData?.homeFeed?.edges
        .map((e) => e.node)
        .filter((n) => n.__typename === 'Post') as PostFeedFragment[],
      [PostNavContext.FEED]: organizationFeedData?.homeFeed?.edges
        .map((e) => e.node)
        .filter((node) => node.__typename === 'Post') as PostFeedFragment[],
    }),
    [groupData, userFeedData, organizationFeedData]
  );

  // Mapping of nav contexts to fetch more queries
  const fetchMoreQueries: {
    [c in PostNavContext]?: () => void;
  } = useMemo(
    () => ({
      [PostNavContext.USER]: () => {
        const pageInfo = userFeedData?.homeFeed?.pageInfo;

        if (!pageInfo?.hasNextPage || userFeedLoading) {
          return;
        }

        userFeedFetchMore({
          variables: {
            username: post?.createdBy.username,
            first: POSTS_PER_PAGE,
            after: userFeedData?.homeFeed?.pageInfo.endCursor,
          },
        });
      },
      [PostNavContext.FEED]: () => {
        const pageInfo = organizationFeedData?.homeFeed?.pageInfo;

        if (!pageInfo?.hasNextPage || organizationFeedLoading) {
          return;
        }

        organizationFeedFetchMore({
          variables: {
            first: POSTS_PER_PAGE,
            after: organizationFeedData?.homeFeed?.pageInfo.endCursor,
          },
        });
      },
    }),
    [
      post,
      userFeedData,
      userFeedLoading,
      userFeedFetchMore,
      organizationFeedData,
      organizationFeedLoading,
      organizationFeedFetchMore,
    ]
  );

  // Pick post list to use for navigation
  const posts = (ctx && postSources[ctx]) || [];

  // Load next page if we're on the last item and there are more pages
  const isLastPost = !posts.length || posts[posts.length - 1]?.id === postId;

  useEffect(() => {
    ctx && isLastPost && fetchMoreQueries[ctx]?.();
  }, [ctx, isLastPost, fetchMoreQueries]);

  return {
    prev: findPrevPost(postId, posts),
    next: findNextPost(postId, posts),
    ctx: ctx || PostNavContext.SHARE,
  };
};

const findNextPost = (currentId: string, posts: any[]) => {
  const idx = posts.findIndex((post) => post.id === currentId);

  if (posts.length < 2 || idx === -1) {
    return null;
  }

  return posts[idx + 1] || null;
};

const findPrevPost = (currentId: string, posts: any[]) => {
  const idx = posts.findIndex((post) => post.id === currentId);

  if (posts.length < 2 || idx === -1) {
    return null;
  }

  return posts[idx - 1] || null;
};
