import { Menu, MenuButton, MenuItem } from '@reach/menu-button';
import { th, x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { FC, PropsWithChildren, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  EventCoreFragment,
  PostCreationPolicy,
  refetchEventQuery,
  refetchEventsQuery,
  useDeleteEventMutation,
  useToggleEventPostCreationPolicyMutation,
} from '../../../../generated/types-and-hooks';
import { GROUPS_PER_PAGE } from '../../../config/constants';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Alert } from '../../common/components/Alert';
import { MenuList } from '../../common/components/MenuList';
import { Text } from '../../common/components/Text';
import { bottomLeftAlign } from '../../common/utils/position';
import { useEventAttendance } from '../hooks/useEventAttendance';
import { EventModal } from './EventModal/EventModal';
import { EventSeriesSelectionModal } from './EventSeriesSelectionModal';

const StyledMenuButton = styled(MenuButton)`
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  pointer-events: all;

  span {
    color: ${th.color('gray.300')};
  }

  &:hover span {
    color: ${th.color('brand.300')};
  }
`;

export const EventSettingsDropdown: React.FC<
  PropsWithChildren<{
    event: EventCoreFragment;
  }>
> = ({ event, children }) => {
  const ref = useRef<HTMLButtonElement | null>(null);
  const { organization } = useOrganization();
  const { userCanEdit } = useEventAttendance({
    event,
    organization,
  });

  if (!userCanEdit || event.deleted) {
    return null;
  }

  return (
    <>
      <x.div>
        <Menu>
          {() => (
            <>
              <StyledMenuButton ref={ref} onClick={(e) => e.preventDefault()}>
                {children}
              </StyledMenuButton>
              <MenuList variant="small" position={bottomLeftAlign()}>
                <EventSettingEdit event={event} />
                <EventSettingPostPolicy event={event} />
                <EventSettingCancel event={event} />
              </MenuList>
            </>
          )}
        </Menu>
      </x.div>
    </>
  );
};

const EventSettingEdit: FC<{ event: EventCoreFragment }> = ({ event }) => {
  const [editing, setEditing] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <MenuItem
        onClick={(event) => event.preventDefault()}
        onSelect={() => setEditing(true)}
      >
        <Text variant="md">{t('edit_event')}</Text>
      </MenuItem>
      <EventModal
        isOpen={editing}
        event={event}
        onDismiss={() => setEditing(false)}
      />
    </>
  );
};

const EventSettingPostPolicy: FC<{ event: EventCoreFragment }> = ({
  event,
}) => {
  const { t } = useTranslation();
  const [mutateEventPostCreationPolicy] =
    useToggleEventPostCreationPolicyMutation({
      variables: {
        input: {
          eventId: event.id,
        },
      },
      refetchQueries: [
        refetchEventQuery({
          id: event.id,
        }),
      ],
    });
  return (
    <>
      <MenuItem
        onClick={(event) => event.preventDefault()}
        onSelect={() => mutateEventPostCreationPolicy()}
      >
        <Text variant="md">
          {event.postCreationPolicy === PostCreationPolicy.Administrators
            ? t('enable_posts_for_members')
            : t('disable_posts_for_members')}
        </Text>
      </MenuItem>
    </>
  );
};

const EventSettingCancel: FC<{ event: EventCoreFragment }> = ({ event }) => {
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const [showSeriesSelectionModal, setShowSeriesSelectionModal] =
    useState(false);
  const { t } = useTranslation();
  const { organization } = useOrganization();

  const [mutateDeleteEvent] = useDeleteEventMutation({
    refetchQueries: [
      refetchEventsQuery({
        organizationId: organization.id,
        first: GROUPS_PER_PAGE - 1,
      }),
      refetchEventQuery({
        id: event?.id || '',
      }),
    ],
    awaitRefetchQueries: true,
    onCompleted: () => setShowCancelAlert(false),
    variables: {
      input: {
        eventId: event.id,
      },
    },
  });

  return (
    <>
      <MenuItem
        onClick={(event) => event.preventDefault()}
        onSelect={() => {
          if (event.series) {
            setShowSeriesSelectionModal(true);
          } else {
            setShowCancelAlert(true);
          }
        }}
      >
        <x.div
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          spaceX={4}
        >
          <Text variant="md">{t('cancel_event')}</Text>
        </x.div>
      </MenuItem>
      <EventSeriesSelectionModal
        isOpen={showSeriesSelectionModal}
        onDismiss={() => setShowSeriesSelectionModal(false)}
        onAction={(seriesSelection) => {
          mutateDeleteEvent({
            variables: {
              input: {
                eventId: event.id,
                seriesSelection,
              },
            },
          });
        }}
        variant="delete"
      />
      {showCancelAlert && (
        <Alert
          headingText={t('cancel_event')}
          descriptionText={t('cancel_event_confirmation')}
          submitButtonText={t('confirm')}
          cancelButtonText={t('cancel')}
          onDismiss={() => {
            setShowCancelAlert(false);
          }}
          onSubmitClick={() => mutateDeleteEvent()}
          onCancelClick={() => {
            setShowCancelAlert(false);
          }}
          variant="warning"
        />
      )}
    </>
  );
};
