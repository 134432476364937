import { routes } from '@frond/shared';
import { Menu, MenuButton, MenuItem } from '@reach/menu-button';
import { styled, th } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { BASE_URL } from '../../../config/constants';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Button } from '../../common/components/Button';
import { Icon } from '../../common/components/Icon';
import { MenuList } from '../../common/components/MenuList';
import { Text } from '../../common/components/Text';
import { useClipboardCopy } from '../../common/hooks/useClipboardCopy';
import { bottomRightAlign } from '../../common/utils/position';

const StyledMenuButton = styled(MenuButton)`
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  pointer-events: all;

  span {
    color: ${th.color('gray.300')};
  }

  &:hover span {
    color: ${th.color('brand.300')};
  }
`;
export const TagDetailLayoutControls = ({ tag }: { tag: string }) => {
  const { organization } = useOrganization();
  const { t } = useTranslation();
  const { handleCopy } = useClipboardCopy(2000);

  return (
    <div>
      <Menu>
        {() => (
          <>
            <StyledMenuButton onClick={(e) => e.preventDefault()}>
              <Button
                p={2}
                variant="secondary"
                onClick={(e: React.MouseEvent) => e.preventDefault()}
                leftElement={<Icon name="more" />}
              />
            </StyledMenuButton>
            <MenuList variant="small" position={bottomRightAlign}>
              <MenuItem
                onClick={(event) => event.preventDefault()}
                onSelect={() => {}}
              >
                <CopyToClipboard
                  text={`${BASE_URL}${routes.groups
                    .organization(organization.shortId)
                    .tag(tag)}`}
                  onCopy={handleCopy}
                >
                  <Text variant="md">{t('copy_link')}</Text>
                </CopyToClipboard>
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </div>
  );
};
