import { EffectCallback, useEffect, useRef } from 'react';

/**
 * Executes a side effect once per component mount regardless of the deps
 * If predicate returns true, the function will be executed once
 */
export const useEffectOnce = (
  effect: EffectCallback,
  predicate: () => boolean | undefined
) => {
  const executed = useRef<boolean>(false);

  const singleExecutionEffect = () => {
    if (predicate() && !executed.current) {
      executed.current = true;
      effect();
    }
  };

  useEffect(singleExecutionEffect, [effect, predicate]);
};
