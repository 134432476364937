import { useMachine } from '@xstate/react/lib/fsm';
import { useCallback, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { VideoType } from '../../../../../generated/types-and-hooks';
import { usePresignedUpload } from '../../../common/hooks/usePresignedUpload';
import analytics from '../../../common/utils/analytics';
import { useVideoRecorderService } from '../../components/VideoRecorder/useVideoRecorderService';
import { VIDEO_CAPTURE_TIMESLICE } from '../../video.config';
import { videoRecorderFSM } from './videoRecorderFSM';
import {
  getPreferredConstraints,
  setDevicePreference,
} from './videoRecorderStorage';
import {
  VideoRecorderActions as Actions,
  VideoRecorderEventNames as Events,
  VideoRecorderFSMSend,
  VideoRecorderFSMState,
  VideoRecorderStates as States,
} from './videoRecorderTypes';
import {
  navigationPreventionHandler,
  recorderIsInReviewContext,
} from './videoRecorderUtils';

export type UseVideoRecorderStateArgs = {
  /**
   * The type of video to save as
   */
  videoType: VideoType;

  /**
   * Show a countdown before recording starts
   */
  showCountdown?: boolean;

  /**
   * Optional callback fired when the user clicks the record button
   */
  onRecordingStart?: () => void;

  /**
   * Optional callback fired when uploading starts
   */
  onUploadStart?: () => void;

  /**
   * Optional callback fired when uploading progresses
   */
  onUploadProgress?: (percent: number) => void;

  /**
   * Optional callback fired when uploading errors
   */
  onUploadError?: (e: Error) => void;

  /**
   * Optional callback fired when uploading completes
   */
  onUploadComplete?: (id: string) => void;

  /**
   * Handler to close the modal
   */
  onDismiss?: () => void;

  /**
   * Extra context for logging
   */
  metadata?: { context?: string };
};

type UseVideoRecorderStateReturn = {
  state: VideoRecorderFSMState;
  send: VideoRecorderFSMSend;
  countdownValue: number | null;
  getVideoReviewObjectURL: () => string | undefined;
  getActiveVideoDeviceId: () => string | undefined;
  getActiveAudioDeviceId: () => string | undefined;
  dismountProtection: boolean;
  disableDismountProtection: () => void;
  handleRequestPermissions: () => void;
  handleStartRecordingRequest: () => void;
  handleStartCountdownRequest: () => void;
  handleStopRecordingRequest: () => void;
  handlePauseRecordingRequest: () => void;
  handleResumeRecordingRequest: () => void;
  handleRestartRecordingRequest: () => void;
  handleSelectVideoDevice: (deviceId: string) => void;
  handleSelectAudioDevice: (deviceId: string) => void;
  handleDismiss: () => void;
  handleUploadProgress: (percentageUploaded: number) => void;
  handleSaveRecordingRequest: () => void;
  handleReviewVideoCanPlay: () => void;
  handleLiveVideoCanPlay: () => void;
};

type UseVideoRecorderState = (
  args: UseVideoRecorderStateArgs
) => UseVideoRecorderStateReturn;

/**
 * Tracks state and transitions for video record -> review -> submit flow
 */
export const useVideoRecorderState: UseVideoRecorderState = ({
  videoType,
  showCountdown,
  onRecordingStart,
  onUploadStart,
  onUploadProgress,
  onUploadError,
  onUploadComplete,
  onDismiss,
  metadata,
}) => {
  const recorderService = useVideoRecorderService();
  const [dismountProtection, setDismountProtection] = useState(false);
  const { uploadVideo } = usePresignedUpload();
  const [countdownValue, setCountdownValue] = useState<number | null>(null);

  // Cleanup on unmount
  useEffect(() => recorderService.destroy, [recorderService.destroy]);

  /**
   * Open the user's camera add the stream to the preview video
   * This will trigger the native browser permissions prompt if
   * the permissions were not already granted or denied
   */
  const initializeStream = async (): Promise<void> => {
    const streamConstraints =
      getPreferredConstraints() || state.context.constraints;

    try {
      const stream = await recorderService.initializeStream(streamConstraints);
      send({
        type: Events.INITIALIZE_SUCCESS,
        payload: stream,
      });
    } catch (e) {
      if ((e as Error).name === 'NotAllowedError') {
        return send(Events.PERMISSIONS_DENIED);
      }

      if ((e as Error).name === 'NotFoundError') {
        return send(Events.CAMERA_NOT_FOUND);
      }

      send(Events.UNSUPPORTED_BROWSER_DETECTED);
    }
  };

  /**
   * Set up our FSM to track the recorder state
   */
  const [state, send] = useMachine(videoRecorderFSM, {
    actions: {
      [Actions.INITIALIZE_STREAM]: () => initializeStream(),
    },
  });

  /**
   * Bind keyboard controls
   */
  useHotkeys(
    'space',
    () => {
      if (state.value === States.INITIALIZED) {
        handleStartRecordingRequest();
        return;
      }

      if ([States.RECORDING, States.PAUSED].includes(state.value)) {
        handleStopRecordingRequest();
        return;
      }
    },
    [state.value]
  );

  /**
   * On component mount, check whether we can find out if
   * the user already explicitly granted or denied permissions
   */
  useEffect(() => {
    if (!(navigator && navigator.permissions)) {
      return send(Events.INITIALIZE_REQUEST);
    }

    navigator.permissions
      .query({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore // wrong types
        name: 'camera',
      })
      .then((status: PermissionStatus) => {
        /**
         * Promise handler for permission status
         * If the user already granted or denied permissions, reflect this in the UI
         * Fall back to initialization as not all browsers support the permissions API
         */
        const event = {
          granted: Events.INITIALIZE_REQUEST,
          denied: Events.PERMISSIONS_DENIED,
          prompt: Events.PERMISSIONS_PROMPT,
        }[status.state];

        if (event === Events.PERMISSIONS_DENIED) {
          analytics.logEvent(
            analytics.events.VIDEO_PERMISSION_DENIED,
            metadata
          );
        }

        if (event === Events.INITIALIZE_REQUEST) {
          analytics.logEvent(
            analytics.events.VIDEO_PERMISSION_GRANTED,
            metadata
          );
        }

        send(event);
      })
      .catch(() => {
        // On failure, attempt to initialize anyway
        // This will trigger the native permissions dialog
        send(Events.INITIALIZE_REQUEST);
      });
  }, [send, recorderService, metadata]);

  const handleStartCountdownRequest = () => send(Events.COUNTDOWN_STARTED);

  const handleStartRecordingRequest = useCallback(() => {
    recorderService.startRecording(VIDEO_CAPTURE_TIMESLICE);
    analytics.logEvent(analytics.events.VIDEO_RECORD_START, metadata);
    send(Events.RECORDING_STARTED);

    onRecordingStart?.();
  }, [onRecordingStart, recorderService, send, metadata]);

  /**
   * Countdown
   */
  useEffect(() => {
    if (state.value !== States.COUNTDOWN) {
      return setCountdownValue(null);
    }

    if (countdownValue === null) {
      return setCountdownValue(showCountdown ? 3 : 0);
    }

    if (countdownValue === 0) {
      return handleStartRecordingRequest();
    }

    window.setTimeout(() => {
      setCountdownValue((prev) => prev && prev - 1);
    }, 1000);
  }, [
    handleStartRecordingRequest,
    send,
    state,
    countdownValue,
    setCountdownValue,
    showCountdown,
  ]);

  /**
   * Track modal opens
   */
  //useEffect(() => {
  //  if (props.showModal) {
  //   analytics.logEvent(analytics.events.VIDEO_MODAL_OPEN, metadata);
  // }
  //}, [props.showModal, props.metadata]);

  /**
   * Returns the URL representing the recorded video blob, if available, or null
   */
  const getVideoReviewObjectURL = () => {
    if (recorderIsInReviewContext(state.value)) {
      return recorderService.getObjectURL();
    }
  };

  /**
   * Event handlers
   */
  const handleStopRecordingRequest = async () => {
    await recorderService.stopRecording();
    analytics.logEvent(analytics.events.VIDEO_RECORD_STOP, metadata);
    send(Events.RECORDING_STOPPED);
  };

  const handlePauseRecordingRequest = () => {
    recorderService.pauseRecording();
    analytics.logEvent(analytics.events.VIDEO_RECORD_PAUSE, metadata);
    send(Events.RECORDING_PAUSED);
  };

  const handleResumeRecordingRequest = () => {
    recorderService.resumeRecording();
    send(Events.RECORDING_RESUMED);
  };

  const handleRestartRecordingRequest = () => {
    recorderService.reset();
    analytics.logEvent(analytics.events.VIDEO_RECORD_RERECORD, metadata);
    send(Events.RECORDING_RESTARTED);
  };

  const handleRequestPermissions = () => {
    send(Events.RECORDING_STOPPED);
    send(Events.PERMISSIONS_REQUESTED);
  };

  /**
   * Callback for user selecting an alternative video input
   */
  const handleSelectVideoDevice = (deviceId: string) => {
    if (deviceId === getActiveVideoDeviceId()) {
      return;
    }

    const constraints = {
      audio: getActiveAudioConstraints(),
      video: { ...getActiveVideoConstraints(), deviceId },
    };

    send({
      type: Events.SET_CONSTRAINTS,
      payload: constraints,
    });

    setDevicePreference('video', deviceId);

    send(Events.INITIALIZE_REQUEST);
  };

  /**
   * Callback for user selecting an alternative audio input
   */
  const handleSelectAudioDevice = (deviceId: string) => {
    if (deviceId === getActiveAudioDeviceId()) {
      return;
    }

    const constraints = {
      video: getActiveVideoConstraints(),
      audio: { ...getActiveAudioConstraints(), deviceId },
    };

    send({
      type: Events.SET_CONSTRAINTS,
      payload: constraints,
    });

    setDevicePreference('audio', deviceId);

    send(Events.INITIALIZE_REQUEST);
  };

  /**
   * If the user attempts to dismiss the recorder,
   * warn them if they might lose a recording
   */
  const handleDismiss = () => {
    if (
      [States.LOADING, States.INITIALIZING, States.INITIALIZED].includes(
        state.value
      )
    ) {
      analytics.logEvent(analytics.events.VIDEO_MODAL_DISMISS, metadata);
      return onDismiss?.();
    }

    setDismountProtection(true);
  };

  /**
   * Once the upload starts, this handler is called periodically
   */
  const handleUploadProgress = (percentageUploaded: number) => {
    send({
      type: Events.UPLOAD_PROGRESSED,
      payload: percentageUploaded,
    });
    onUploadProgress?.(percentageUploaded);
  };

  /**
   * Start the upload process
   */
  const handleSaveRecordingRequest = async () => {
    try {
      window.addEventListener('beforeunload', navigationPreventionHandler);
      send(Events.UPLOAD_STARTED);
      onUploadStart?.();

      const video = recorderService.getData();
      const { id } = await uploadVideo(
        videoType,
        true,
        new File([video], 'browser_recording'),
        handleUploadProgress
      );

      window.removeEventListener('beforeunload', navigationPreventionHandler);
      onUploadComplete?.(id);
      send(Events.UPLOAD_SUCCESS);
      onDismiss?.();
    } catch (e) {
      onUploadError?.(e as Error);
      send(Events.UPLOAD_FAILURE);
    }
  };

  const handleReviewVideoCanPlay = useCallback(() => {
    send(Events.REVIEW_VIDEO_READY);
  }, [send]);

  const handleLiveVideoCanPlay = useCallback(() => {
    send(Events.LIVE_VIDEO_READY);
  }, [send]);

  const getActiveMediaTrack = useCallback(
    (media: 'video' | 'audio') => {
      const { cameraStream } = state.context;
      return media === 'audio'
        ? cameraStream?.getAudioTracks()[0]
        : cameraStream?.getVideoTracks()[0];
    },
    [state.context]
  );

  const getActiveVideoDeviceId = useCallback(
    () => getActiveMediaTrack('video')?.getSettings().deviceId,
    [getActiveMediaTrack]
  );

  const getActiveVideoConstraints = useCallback(
    () => getActiveMediaTrack('video')?.getConstraints(),
    [getActiveMediaTrack]
  );

  const getActiveAudioDeviceId = useCallback(
    () => getActiveMediaTrack('audio')?.getSettings().deviceId,
    [getActiveMediaTrack]
  );

  const getActiveAudioConstraints = useCallback(
    () => getActiveMediaTrack('audio')?.getConstraints(),
    [getActiveMediaTrack]
  );

  const disableDismountProtection = useCallback(
    () => setDismountProtection(false),
    [setDismountProtection]
  );

  return {
    state,
    send,
    countdownValue,
    dismountProtection,
    disableDismountProtection,
    getVideoReviewObjectURL,
    getActiveAudioDeviceId,
    getActiveVideoDeviceId,
    handleRequestPermissions,
    handlePauseRecordingRequest,
    handleResumeRecordingRequest,
    handleRestartRecordingRequest,
    handleStopRecordingRequest,
    handleStartRecordingRequest,
    handleStartCountdownRequest,
    handleSelectVideoDevice,
    handleSelectAudioDevice,
    handleDismiss,
    handleUploadProgress,
    handleSaveRecordingRequest,
    handleReviewVideoCanPlay,
    handleLiveVideoCanPlay,
  };
};
