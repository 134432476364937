import { Menu, MenuButton, MenuItem } from '@reach/menu-button';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import { Icon } from '../../common/components/Icon';
import { MenuList } from '../../common/components/MenuList';
import { Text } from '../../common/components/Text';
import { Toast } from '../../common/components/Toast';
import { useOrganizationFileUploadValidation } from '../../common/hooks/useFileUpload';
import { UploadError } from '../../common/utils/cloudinary';
import { leftAlign } from '../../common/utils/position';
import { PostComposerFileSelector } from './PostComposerFileSelector';
import { MiniComposerButton } from './PostComposerMini';

type AnswerComposerMiniMenuProps = {
  onFilesSelect: (files: File[]) => void;
  onUploadMedia: () => void;
  onSearchGiphy: () => void;
  variant?: 'default' | 'mini';
};
export const PostComposerMiniMenu = ({
  onFilesSelect,
  onUploadMedia,
  onSearchGiphy,
  variant = 'default',
}: AnswerComposerMiniMenuProps) => {
  const { t } = useTranslation();
  const { validateFiles } = useOrganizationFileUploadValidation();
  const [error, setError] = useState<UploadError | null>(null);

  const menuActions = [
    {
      label: t('upload_from_this_device'),
      hasFileInput: true,
      iconName: 'media',
    },
    {
      label: t('upload_from_phone'),
      onClick: () => {
        onUploadMedia();
      },
      iconName: 'qr',
    },
    {
      label: t('search_giphy'),
      onClick: () => {
        onSearchGiphy();
      },
      iconName: 'search',
    },
  ];

  return (
    <Menu>
      {({ isExpanded }) => (
        <>
          {error && (
            <Toast
              variant="error"
              message={error.message}
              onDisappear={() => setError(null)}
            />
          )}
          <MenuButton as="span" onClick={(event) => event.preventDefault()}>
            <MiniComposerButton
              iconName="media"
              isActive={isExpanded}
              onClick={(e) => e.preventDefault()}
            />
          </MenuButton>
          <MenuList portal variant="small" position={leftAlign}>
            {menuActions.map((action) => {
              if (action.hasFileInput) {
                return (
                  <MenuItem onSelect={() => {}} key={action.label}>
                    <PostComposerFileSelector
                      id={`mini-composer-file-selector-${variant}`}
                      accept="video/*,image/jpeg,image/png,image/svg+xml,image/webp,image/bmp,image/tiff,image/heif"
                      multiple
                      onChange={(e) => {
                        if (!e.target.files) {
                          return;
                        }

                        const err = validateFiles(e.target.files);

                        if (err) {
                          setError(err);
                          return;
                        }

                        onFilesSelect([...e.target.files]);
                        e.currentTarget.value = '';
                      }}
                    >
                      <x.div display="flex" alignItems="center" spaceX={2}>
                        <Icon name={action.iconName} size="4.5" />
                        <Text variant="md" onClick={action.onClick}>
                          {action.label}
                        </Text>
                      </x.div>
                    </PostComposerFileSelector>
                  </MenuItem>
                );
              }
              return (
                <MenuItem onSelect={() => {}} key={action.label}>
                  <x.div display="flex" alignItems="center" spaceX={2}>
                    <Icon name={action.iconName} size="4.5" />
                    <Text variant="md" onClick={action.onClick}>
                      {action.label}
                    </Text>
                  </x.div>
                </MenuItem>
              );
            })}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
