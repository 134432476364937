import { theme } from '@frond/shared';
import { useSelector } from '@xstate/react';
import { styled, th, x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { useContext, useState } from 'react';

import { Button } from '../../common/components/Button';
import { Icon } from '../../common/components/Icon';
import { Input } from '../../common/components/Input';
import { Text } from '../../common/components/Text';
import { PostComposerStateContext } from './PostComposer';

const StyledInput = styled(Input)`
  input:disabled {
    -webkit-text-fill-color: ${th('colors.gray.400')};
    background-color: ${th('colors.gray.100')};
    border-color: ${th('colors.gray.200')};
  }
`;

type PostComposerPollProps = {
  onRemove: () => void;
};
export const PostComposerPoll = ({ onRemove }: PostComposerPollProps) => {
  const { t } = useTranslation();
  const { postComposerService } = useContext(PostComposerStateContext);
  const poll = useSelector(
    postComposerService,
    (state) => state.context.post.poll
  );

  const initialPollOptions = useSelector(
    postComposerService,
    (state) => state.context.pollOptions
  );

  const isPublishing = useSelector(
    postComposerService,
    (state) => state.context.isPublishing
  );

  const [pollOptions, setPollOptions] = useState<string[]>(
    initialPollOptions || ['', '']
  );

  const handleBlur = () => {
    postComposerService.send({
      type: 'SET_POLL_OPTIONS',
      pollOptions,
    });
  };

  const handleKeydown = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter') {
      event.preventDefault();
      handleBlur();
    }
  };

  const handleRemovePoll = () => {
    postComposerService.send({
      type: 'REMOVE_POLL_OPTIONS',
    });
    onRemove();
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    const copyPollOptions = [...pollOptions];
    copyPollOptions.splice(i, 1, event.target.value);
    setPollOptions(copyPollOptions);
    postComposerService.send({
      type: 'SET_POLL_OPTIONS',
      pollOptions,
    });
  };

  const hasVotes = !!poll?.options.filter((option) => option.votes.length)
    .length;

  return (
    <x.div
      p={4}
      spaceY={theme.sizes['2.5']}
      backgroundColor="gray.50"
      borderRadius="md"
    >
      {poll && hasVotes
        ? poll?.options.map((option, i) => (
            <StyledInput
              key={option.id}
              label={`${t('option')} ${i + 1}${
                i > 1 ? ` (${t('optional')})` : ''
              }`}
              value={option.name}
              disabled
              fullWidth
            />
          ))
        : pollOptions?.map((option, i) => (
            <StyledInput
              key={i}
              label={`${t('option')} ${i + 1}${
                i > 1 ? ` (${t('optional')})` : ''
              }`}
              onChange={(event) => handleChange(event, i)}
              onBlur={handleBlur}
              onKeyDown={handleKeydown}
              value={option}
              fullWidth
              disabled={isPublishing}
            />
          ))}
      <x.div display="flex" justifyContent="space-between">
        <Button
          variant="secondary-warning"
          label={t('remove_poll')}
          onClick={handleRemovePoll}
          disabled={isPublishing}
        />
        <Button
          variant="secondary"
          label={t('add_option')}
          leftElement={<Icon name="plus" size="4" />}
          onClick={() => setPollOptions([...pollOptions, ''])}
          backgroundColor={{
            disabled: 'gray.100',
          }}
          disabled={hasVotes || isPublishing}
        />
      </x.div>
      {hasVotes && (
        <x.div
          display="flex"
          flexDirection="row"
          alignItems="center"
          spaceX={2}
          mt={4}
        >
          <Icon size="5" name="circle-exclamation" color="gray.300" />
          <Text variant="md" color="gray.300">
            {t('polls_with_votes')}
          </Text>
        </x.div>
      )}
    </x.div>
  );
};
