import { theme } from '@frond/shared';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Menu, MenuButton, MenuItem } from '@reach/menu-button';
import { useSelector } from '@xstate/react';
import styled, {
  DisplayProps,
  system,
  SystemProp,
  SystemProps,
  Theme,
  x,
} from '@xstyled/styled-components';
import { isFuture, isPast } from 'date-fns';
import { useTranslation } from 'next-i18next';
import React, { FC, MouseEventHandler, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  GroupTypes,
  PostCreationPolicy,
  PostTypes,
  refetchDraftPostsQuery,
  refetchEventQuery,
  refetchEventsQuery,
  refetchGroupQuery,
  refetchGroupsQuery,
  refetchHomeFeedLatestContentQuery,
  refetchHomeFeedQuery,
  refetchNewMemberPostQuery,
  refetchPostsByEventQuery,
  refetchPostsByGroupQuery,
  refetchViewerQuery,
  refetchVisitedPublicOrganizationsQuery,
  refetchWelcomePostQuery,
  useGroupsQuery,
  useHomeFeedLazyQuery,
  useUpdateImageCoordinatesMutation,
  useUpsertPostMutation,
} from '../../../../generated/types-and-hooks';
import {
  GROUPS_PER_PAGE,
  GROUPS_PER_PAGE_SELECTOR,
  POSTS_PER_PAGE,
} from '../../../config/constants';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { userInOrganization } from '../../auth/utils/permissions';
import { Breakpoint } from '../../common/components/Breakpoint';
import { Button } from '../../common/components/Button';
import { Heading } from '../../common/components/Heading';
import { Icon } from '../../common/components/Icon';
import { Input } from '../../common/components/Input';
import { MenuList } from '../../common/components/MenuList';
import { Text } from '../../common/components/Text';
import { Toast } from '../../common/components/Toast';
import { Tooltip } from '../../common/components/Tooltip';
import { useOrganizationFileUploadValidation } from '../../common/hooks/useFileUpload';
import { dashedBorderSVGString, svgStringToImageUrl } from '../../common/utils';
import { UploadError } from '../../common/utils/cloudinary';
import { bottomLeftAlign, leftAlign } from '../../common/utils/position';
import { GroupsModal } from '../../groups/components/GroupsModal';
import { useOrganizationEntitlements } from '../../organizations/hooks/useOrganizationEntitlements';
import { PostComposerFileSelector } from '../../questions/components/PostComposerFileSelector';
import {
  StyledDropdownMenuButton,
  StyledDropdownMenuContent,
  StyledDropdownMenuItem,
} from './PostCard';
import { PostComposerStateContext } from './PostComposer';
import { PostComposerColorThemePicker } from './PostComposerColorThemePicker';
import { PUBLISH_ERROR_MESSAGE } from './PostComposerErrors';
import { PostComposerGroupSelector } from './PostComposerGroupSelector';
import { postComposerHasNoValuesSelector } from './postComposerMachine';
import { PostComposerMedia } from './PostComposerMedia';
import { PostComposerPoll } from './PostComposerPoll';
import { PostComposerResourceInput } from './PostComposerResourceInput';
import { PostComposerResources } from './PostComposerResources';
import { PostComposerResourcesExternalUnfurl } from './PostComposerResourcesExternalUnfurl';
import { PostComposerTextComposer } from './PostComposerTextComposer';

export const PostComposerReviewSurface = () => {
  const { t } = useTranslation();
  const { features } = useOrganizationEntitlements();
  const { organization } = useOrganization();
  const { postComposerService } = useContext(PostComposerStateContext);
  const group = useSelector(
    postComposerService,
    (state) => state.context.group
  );
  const isPublishing = useSelector(
    postComposerService,
    (state) => state.context.isPublishing
  );
  const postId = useSelector(
    postComposerService,
    (state) => state.context.postId
  );
  const title = useSelector(
    postComposerService,
    (state) => state.context.post.title
  );
  const type = useSelector(postComposerService, (state) => state.context.type);
  const pollOptions = useSelector(
    postComposerService,
    (state) => state.context.pollOptions
  );

  const resourcesToEmbed = useSelector(
    postComposerService,
    (state) => state.context.resourcesToEmbed
  );

  const media = useSelector(
    postComposerService,
    (state) => state.context.post.media
  );

  const canChangeGroup = useSelector(
    postComposerService,
    (state) => state.context.canChangeGroup
  );

  const { data } = useGroupsQuery({
    variables: {
      organizationId: organization.id,
      first: GROUPS_PER_PAGE_SELECTOR,
    },
    onCompleted(data) {
      if (!group) {
        if (type === PostTypes.NewUser) {
          const welcomeGroup = data.groups.edges.find(
            ({ node: group }) => group.groupType === GroupTypes.Welcome
          )?.node;

          if (welcomeGroup) {
            postComposerService.send({
              type: 'SET_GROUP',
              group: welcomeGroup,
            });
          }
        } else {
          const generalGroup = data.groups.edges.find(
            ({ node: group }) =>
              group.groupType === GroupTypes.General &&
              group.postCreationPolicy === PostCreationPolicy.Unrestricted
          )?.node;

          if (generalGroup) {
            postComposerService.send({
              type: 'SET_GROUP',
              group: generalGroup,
            });
          }
        }
      }
    },
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    multiple: true,
    disabled: isPublishing,
    onDrop: (files) => {
      files.forEach((file, index) => {
        if (file.type.startsWith('image') || file.type.startsWith('video')) {
          postComposerService.send({
            type: 'ADD_MEDIA',
            media: {
              file,
              id: `file-to-upload-${new Date().getTime()}-${index}`,
              uploadProgress: 0,
            },
          });
        } else {
          postComposerService.send({
            type: 'ADD_URL_TO_EMBED',
            url: file.name,
            file,
          });
        }
      });
    },
  });

  const [showResourceInput, setShowResourceInput] = useState(false);

  const [showPoll, setShowPoll] = useState(!!pollOptions?.length);

  if (!data) return null;

  const disabledActionButtonByPoll = !!pollOptions?.length || showPoll;

  const handlePollClick = () => {
    if (features.Polls) {
      setShowPoll(true);
    } else {
      postComposerService.send('SHOW_POLL_UPSELL');
    }
  };

  let heading = '';

  if (type === PostTypes.Welcome) {
    heading = t('welcome_post');
  } else if (type === PostTypes.Lesson) {
    heading = t('new_lesson');
    if (postId) {
      heading = t('edit_lesson');
    }
  } else {
    heading = t('new_post');
    if (postId) {
      heading = t('edit_post');
    }
  }

  return (
    <x.div {...getRootProps()}>
      <input {...getInputProps()} />
      <x.form
        position="relative"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <x.div spaceY={4}>
          <x.div
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <x.div display={{ _: 'none', sm: 'block' }}>
              <Heading variant="lg">{heading}</Heading>
            </x.div>
            <x.div
              display="flex"
              w={{ _: 'full', sm: 'auto' }}
              alignItems="center"
              spaceX={2}
            >
              {/*Hide group selector for welcome post*/}
              {type !== PostTypes.Welcome ? (
                <x.div
                  w={{
                    _: '100%',
                    sm: '2xs',
                  }}
                >
                  <PostComposerGroupSelector
                    type={type}
                    name="groupId"
                    value={group}
                    disabled={isPublishing || !canChangeGroup}
                    groups={data.groups.edges.map((e) => e.node)}
                    onChange={(value) => {
                      postComposerService.send({
                        type: 'SET_GROUP',
                        group: value,
                      });
                    }}
                  />
                </x.div>
              ) : null}

              <x.div
                display={{
                  _: 'none',
                  sm: 'block',
                }}
              >
                <PostButton isPublishing={isPublishing} />
              </x.div>
            </x.div>
          </x.div>
          <PostComposerMedia />
          {type === PostTypes.Lesson ? (
            <Input
              fullWidth
              required
              label={t('lesson_title')}
              placeholder={t('title')}
              value={title === null ? '' : title}
              onChange={(e) => {
                postComposerService.send({
                  type: 'SET_POST_TITLE',
                  title: e.currentTarget.value,
                });
              }}
              autoFocus
            />
          ) : null}
          <PostComposerTextComposer />
          <PostComposerResources />
          <PostComposerResourcesExternalUnfurl />
          {showResourceInput && (
            <PostComposerResourceInput
              onHide={() => setShowResourceInput(false)}
            />
          )}
          {showPoll && <PostComposerPoll onRemove={() => setShowPoll(false)} />}
          <x.div display="flex" alignItems="center" gap={2}>
            <PostComposerActionButton
              iconName="video"
              display={{ _: 'none', sm: 'flex' }}
              onClick={() =>
                postComposerService.send({ type: 'START_VIDEO_CAPTURE' })
              }
              disabled={disabledActionButtonByPoll || isPublishing}
              tooltipLabel={t('record_a_video')}
            />
            <Breakpoint
              _={
                <PostMediaActions
                  includeQRUpload={false}
                  disabled={disabledActionButtonByPoll || isPublishing}
                />
              }
              sm={
                <PostMediaActions
                  disabled={disabledActionButtonByPoll || isPublishing}
                />
              }
            />
            {type !== PostTypes.Welcome && type !== PostTypes.Lesson ? (
              <PostComposerActionButton
                iconName="poll"
                onClick={handlePollClick}
                disabled={
                  !!media.length || !!resourcesToEmbed.length || isPublishing
                }
                tooltipLabel={t('add_poll')}
              />
            ) : null}
            <PostComposerFileSelector
              onChange={(e) => {
                if (!e.target.files) {
                  return;
                }
                const file = e.target.files[0];
                if (
                  file.type.startsWith('image') ||
                  file.type.startsWith('video')
                ) {
                  postComposerService.send({
                    type: 'ADD_MEDIA',
                    media: {
                      id: `file-to-upload-${new Date().getTime()}`,
                      file,
                      uploadProgress: 0,
                    },
                  });
                } else {
                  postComposerService.send({
                    type: 'ADD_URL_TO_EMBED',
                    url: file.name,
                    file,
                  });
                }
                e.currentTarget.value = '';
              }}
              id="file-input"
              disabled={disabledActionButtonByPoll || isPublishing}
            >
              <PostComposerActionButton
                iconName="paperclip"
                as="span"
                disabled={disabledActionButtonByPoll || isPublishing}
                tooltipLabel={t('add_file')}
              />
            </PostComposerFileSelector>

            <PostComposerActionButton
              iconName="link"
              onClick={() => setShowResourceInput(true)}
              disabled={disabledActionButtonByPoll || isPublishing}
              tooltipLabel={t('add_link')}
            />
            <x.div flexGrow="1" />
            <PostComposerColorThemePicker />
          </x.div>
        </x.div>

        {isDragActive ? (
          <x.div
            position="absolute"
            left={0}
            right={0}
            top={0}
            bottom={0}
            bg="white"
            borderRadius="lg"
            mx={-6}
            my={-4}
            p={4}
          >
            <x.div
              bg="brand.50"
              borderRadius="md"
              h="full"
              backgroundImage={`url("${svgStringToImageUrl(
                dashedBorderSVGString({
                  color: `${theme.colors.brand[300]}`,
                  dasharray: [`${theme.sizes['3']}`, `${theme.sizes['3']}`],
                  radius: 'md',
                })
              )}")`}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <x.div
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                spaceY={1}
                color="brand.300"
              >
                <Icon size="6" name="image-add" />
                <Text variant="md-semibold">{t('drop_to_add_media')}</Text>
              </x.div>
            </x.div>
          </x.div>
        ) : null}
      </x.form>
    </x.div>
  );
};

type MenuAction = {
  label: string;
  hasFileInput?: boolean;
  iconName: string;
  onClick?: () => void;
};

export const PostMediaActions: FC<{
  includeQRUpload?: boolean;
  disabled?: boolean;
}> = ({ includeQRUpload = true, disabled }) => {
  const { t } = useTranslation();
  const { postComposerService } = useContext(PostComposerStateContext);
  const { validateFile } = useOrganizationFileUploadValidation();
  const [error, setError] = useState<UploadError | null>(null);

  const menuActions: MenuAction[] = [
    {
      label: t('upload_from_this_device'),
      hasFileInput: true,
      iconName: 'media',
    },
  ];

  includeQRUpload &&
    menuActions.push({
      label: t('upload_from_phone'),
      onClick: () => {
        postComposerService.send({ type: 'START_QR_UPLOAD' });
      },
      iconName: 'qr',
    });

  menuActions.push({
    label: t('search_giphy'),
    onClick: () => {
      postComposerService.send({ type: 'START_GIPHY_SEARCH' });
    },
    iconName: 'search',
  });

  return disabled ? (
    <PostComposerActionButton
      iconName="media"
      tooltipLabel={t('add_media')}
      disabled
    />
  ) : (
    <Menu>
      {({ isExpanded }) => (
        <>
          {error && (
            <Toast
              variant="error"
              message={error.message}
              onDisappear={() => setError(null)}
            />
          )}
          <MenuButton as="span" onClick={(event) => event.preventDefault()}>
            <PostComposerActionButton
              iconName="media"
              isActive={isExpanded}
              onClick={(e) => e.preventDefault()}
              tooltipLabel={t('add_media')}
            />
          </MenuButton>
          <MenuList
            portal
            variant="small"
            position={(pos, pos2) => {
              if (pos && pos2 && pos.top + pos2.height > window.innerHeight) {
                return leftAlign(pos, pos2);
              } else {
                return bottomLeftAlign(8)(pos, pos2);
              }
            }}
          >
            {menuActions.map((action) => {
              if (action.hasFileInput) {
                return (
                  <MenuItem onSelect={() => {}} key={action.label}>
                    <PostComposerFileSelector
                      id={`mini-composer-file-selector`}
                      accept="video/*,image/jpeg,image/png,image/svg+xml,image/webp,image/bmp,image/tiff,image/heif"
                      multiple
                      onChange={(e) => {
                        if (!e.target.files) {
                          return;
                        }
                        [...e.target.files].forEach((file) => {
                          const err = validateFile(file);

                          if (err) {
                            setError(err);
                            return;
                          }

                          postComposerService.send({
                            type: 'ADD_MEDIA',
                            media: {
                              file,
                              uploadProgress: 0,
                              id: `temp-${Math.random()}`,
                            },
                          });
                        });

                        e.currentTarget.value = '';
                      }}
                    >
                      <x.div display="flex" alignItems="center" spaceX={2}>
                        <Icon name={action.iconName} size="4.5" />
                        <Text variant="md" onClick={action.onClick}>
                          {action.label}
                        </Text>
                      </x.div>
                    </PostComposerFileSelector>
                  </MenuItem>
                );
              }
              return (
                <MenuItem onSelect={() => {}} key={action.label}>
                  <x.div display="flex" alignItems="center" spaceX={2}>
                    <Icon name={action.iconName} size="4.5" />
                    <Text variant="md" onClick={action.onClick}>
                      {action.label}
                    </Text>
                  </x.div>
                </MenuItem>
              );
            })}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

const StyledDateInput = styled.input<SystemProps>`
  ${theme.typographyStyles.text.md};
  ${system};

  ::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

export const PostButton = ({
  isPublishing = false,
}: {
  isPublishing: boolean;
}) => {
  const { t } = useTranslation();
  const { viewer: user } = useViewer();
  const { organization } = useOrganization();
  const { postComposerService } = useContext(PostComposerStateContext);
  const group = useSelector(
    postComposerService,
    (state) => state.context.group,
    (prevGroup, nextGroup) => prevGroup?.id === nextGroup?.id
  );
  const postId = useSelector(
    postComposerService,
    (state) => state.context.postId
  );
  const type = useSelector(postComposerService, (state) => state.context.type);

  const [upsertPost] = useUpsertPostMutation();
  const [refetchHomeFeed] = useHomeFeedLazyQuery({
    variables: {
      organizationId: organization.id,
      first: POSTS_PER_PAGE,
    },
  });
  const [isSchedulePostModalOpen, setIsSchedulePostModalOpen] = useState(false);

  const [updateImageCoordinates] = useUpdateImageCoordinatesMutation();

  const postComposerHasNoValues = useSelector(
    postComposerService,
    postComposerHasNoValuesSelector
  );
  const label = postId ? t('save') : t('post_verb');
  const disabled = postComposerHasNoValues || !group?.id;

  const [scheduledAt, setScheduledAt] = useState<string | undefined>();

  const savePost = async ({
    isDraft,
    scheduledAt,
  }: {
    isDraft?: boolean;
    scheduledAt?: Date;
  }) => {
    const { context } = postComposerService.getSnapshot();

    if (!context.group) {
      return;
    }

    postComposerService.send({
      type: 'SET_IS_PUBLISHING',
    });

    await Promise.all(
      context.post.media.map((item) => {
        if ('publicId' in item) {
          if (item.coordinates) {
            return updateImageCoordinates({
              variables: {
                input: {
                  imageId: item.id,
                  x: item.coordinates.x,
                  y: item.coordinates.y,
                  width: item.coordinates.width,
                  height: item.coordinates.height,
                },
              },
            });
          } else {
            return item;
          }
        }
      })
    );

    upsertPost({
      variables: {
        input: {
          groupId: context.group.id,
          content: context.post.content,
          postId: context.postId,
          resources: [
            ...context.post.resources.map((r) => {
              return {
                title: r.title,
                url: r.url,
                expanded: r.expanded,
              };
            }),
            ...context.resourcesToEmbed,
          ],
          title: context.post.title,
          mediaIds: context.post.media.map((m) => m.id),
          colorTheme: context.post.colorTheme,
          aspectRatio: context.post.aspectRatio,
          pollOptions: context.pollOptions,
          type: context.type,
          ...(scheduledAt ? { scheduledAt: scheduledAt.toISOString() } : {}),
          ...(isDraft ? { isDraft: true } : {}),
        },
      },
      refetchQueries: [
        refetchHomeFeedLatestContentQuery({
          organizationId: organization.id,
        }),
        refetchHomeFeedQuery({
          organizationId: organization.id,
          first: POSTS_PER_PAGE - 1,
          username: user?.username,
        }),
        ...(type === PostTypes.NewUser && user
          ? [
              refetchNewMemberPostQuery({
                userId: user.id,
                organizationId: organization.id,
              }),
            ]
          : []),
        ...(organization.isPublic &&
        user &&
        !userInOrganization(user, organization)
          ? [
              refetchVisitedPublicOrganizationsQuery({
                userId: user.id,
              }),
              refetchViewerQuery(),
            ]
          : []),
        ...(context.group.groupType === GroupTypes.Event
          ? [
              refetchEventsQuery({
                organizationId: organization.id,
                first: GROUPS_PER_PAGE - 1,
              }),
              refetchPostsByEventQuery({
                eventId: context.group.id,
                first: POSTS_PER_PAGE,
              }),
              refetchEventQuery({ id: context.group.id }),
            ]
          : [
              refetchGroupsQuery({
                organizationId: organization.id,
                first: GROUPS_PER_PAGE - 1,
              }),
              refetchGroupQuery({ id: context.group.id }),
              refetchPostsByGroupQuery({
                groupId: context.group.id,
                first: POSTS_PER_PAGE,
              }),
            ]),
        ...(context.type === PostTypes.Welcome
          ? [
              refetchWelcomePostQuery({
                organizationId: organization.id,
              }),
            ]
          : []),
        ...(isDraft
          ? [
              refetchDraftPostsQuery({
                organizationId: organization.id,
                first: 1,
              }),
            ]
          : []),
      ],
      awaitRefetchQueries: true,
      onCompleted: ({ upsertPost }) => {
        // This refetch has to be triggered manually as a lazy query
        // to override the cache-only fetch policy used in the feed
        refetchHomeFeed({
          variables: {
            organizationId: organization.id,
            first: POSTS_PER_PAGE,
          },
        });

        postComposerService.send({
          type: 'SET_PUBLISHING_COMPLETE',
        });

        if (upsertPost.__typename === 'PostError') {
          return postComposerService.send({
            type: 'SET_ERROR',
            errorMessage: PUBLISH_ERROR_MESSAGE,
          });
        }

        if (upsertPost.__typename === 'Post') {
          return postComposerService.send({
            type: 'SET_UPSERT_SUCCESS',
          });
        }
      },
      onError: () => {
        postComposerService.send({
          type: 'SET_ERROR',
          errorMessage: PUBLISH_ERROR_MESSAGE,
        });
      },
    });
  };

  const scheduledAtAsDate = new Date(scheduledAt || '');

  return (
    <x.div>
      <GroupsModal
        width="425px"
        variant="default"
        isOpen={isSchedulePostModalOpen}
        ariaLabel="Schedule Post"
        onDismiss={() => setIsSchedulePostModalOpen(false)}
      >
        <x.div spaceY={3}>
          <Heading variant="lg">{t('schedule_post')}</Heading>
          <Text>{t('schedule_post_desc')}</Text>
          <StyledDateInput
            value={scheduledAt}
            onChange={(e) => setScheduledAt(e.target.value)}
            type="datetime-local"
            boxSizing="border-box"
            display="flex"
            flexDirection="row"
            alignItems="center"
            border="default"
            borderRadius="md"
            borderColor={{
              _: 'gray.200',
              hover: 'gray.300',
              focus: 'gray.300',
            }}
            transition
            transitionDuration="fast"
            transitionTimingFunction="ease-in-out"
            w="full"
            p={2}
            px={4}
          />
          <x.div
            spaceX={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              onClick={() => setIsSchedulePostModalOpen(false)}
              label={t('cancel')}
              variant="secondary"
            />
            <Button
              disabled={
                !scheduledAt || (!!scheduledAt && isPast(scheduledAtAsDate))
              }
              onClick={() => {
                if (scheduledAt && isFuture(scheduledAtAsDate)) {
                  savePost({
                    isDraft: true,
                    scheduledAt: scheduledAtAsDate,
                  });
                  setIsSchedulePostModalOpen(false);
                }
              }}
              label={t('schedule_post')}
            />
          </x.div>
        </x.div>
      </GroupsModal>
      <x.div display={{ _: 'none', sm: 'flex' }}>
        <Button
          onClick={() => savePost({})}
          label={label}
          loading={isPublishing}
          type="submit"
          disabled={disabled}
          {...(!type
            ? {
                borderRadius: 'md 0 0 md',
              }
            : {})}
        />
        {!type ? (
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
              <StyledDropdownMenuButton
                disabled={disabled || isPublishing}
                cursor={disabled || isPublishing ? 'default' : 'pointer'}
                m={0}
                p={3}
                bg={
                  disabled || isPublishing
                    ? 'brand.100'
                    : { _: 'brand.300', hover: 'brand.400' }
                }
                borderRadius="0 md md 0"
                position="relative"
                border="none"
                borderLeft={1}
                borderColor={
                  disabled || isPublishing ? 'brand.50' : 'brand.200'
                }
                display="flex"
                alignItems="center"
                transition
                transitionDuration="fast"
                transitionTimingFunction="ease-out"
                color="white"
              >
                <Icon name="chevron-bottom" />
              </StyledDropdownMenuButton>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
              <StyledDropdownMenuContent sideOffset={8} align="end">
                <x.div p={3} boxShadow="sm" borderRadius="md" spaceY={1}>
                  <StyledDropdownMenuItem
                    onSelect={() => savePost({ isDraft: true })}
                  >
                    <Text variant="md">{t('save_as_draft')}</Text>
                  </StyledDropdownMenuItem>
                  <StyledDropdownMenuItem
                    onSelect={() => setIsSchedulePostModalOpen(true)}
                  >
                    <Text variant="md">{t('schedule_post')}</Text>
                  </StyledDropdownMenuItem>
                </x.div>
              </StyledDropdownMenuContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        ) : null}
      </x.div>

      <Button
        onClick={() => savePost({})}
        label={label}
        loading={isPublishing}
        type="submit"
        disabled={disabled}
        display={{ _: 'block', sm: 'none' }}
        variant="text"
        size="lg"
        color="brand.300"
      />
    </x.div>
  );
};

type PostComposerActionButtonProps = {
  iconName: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isActive?: boolean;
  display?: SystemProp<DisplayProps, Theme>;
  as?: 'button' | 'span';
  disabled?: boolean;
  tooltipLabel: string;
};
export const PostComposerActionButton = ({
  iconName,
  onClick,
  isActive,
  display,
  as = 'button',
  disabled,
  tooltipLabel,
}: PostComposerActionButtonProps) => {
  return (
    <Tooltip aria-label={iconName} label={<>{tooltipLabel}</>}>
      <x.div
        as={as}
        h={9}
        w={9}
        display={display || 'flex'}
        alignItems="center"
        justifyContent="center"
        borderRadius="md"
        border
        cursor="pointer"
        type="button"
        boxSizing="border-box"
        borderColor={{
          _: isActive ? 'brand.200' : 'gray.200',
          hover: 'brand.200',
        }}
        bg="white"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick={onClick}
        {...(disabled && {
          onClick: undefined,
          color: 'gray.200',
          borderColor: 'gray.200',
        })}
      >
        <Icon name={iconName} size="4.5" />
      </x.div>
    </Tooltip>
  );
};
