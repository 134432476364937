import * as React from 'react';
import { SVGProps } from 'react';

const SvgComment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.375 2.917a2.718 2.718 0 0 0-2.708 2.708v7.083a2.718 2.718 0 0 0 2.708 2.709H5v2.291c0 .82 1.011 1.325 1.667.834l4.166-3.125h4.792a2.718 2.718 0 0 0 2.708-2.709V5.625a2.718 2.718 0 0 0-2.708-2.708H4.375Zm0 1.25h11.25a1.45 1.45 0 0 1 1.458 1.458v7.083a1.45 1.45 0 0 1-1.458 1.459h-5a.624.624 0 0 0-.375.125l-4 3v-2.5a.625.625 0 0 0-.625-.625h-1.25a1.45 1.45 0 0 1-1.458-1.459V5.625a1.45 1.45 0 0 1 1.458-1.458Zm2.083 2.916a.625.625 0 1 0 0 1.25h7.084a.625.625 0 1 0 0-1.25H6.458Zm0 2.916a.625.625 0 1 0 0 1.25h5.417a.624.624 0 1 0 0-1.25H6.458Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgComment;
