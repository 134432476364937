import * as React from "react";

function SvgGift(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.497 7C5.169 6.463 5 5.803 5 5c0-1.583 1.19-3 3-3 1.812 0 3.273 1.214 4 3.114C12.727 3.214 14.188 2 16 2c1.81 0 3 1.417 3 3 0 .803-.169 1.463-.497 2H20a2 2 0 012 2v2a2 2 0 01-2 2v7a2 2 0 01-2 2H6a2 2 0 01-2-2v-7a2 2 0 01-2-2V9a2 2 0 012-2h1.497zM11 9H4v2h7V9zm9 2h-7V9h7v2zm-9 9v-7H6v7h5zm7 0h-5v-7h5v7zM8 4c-.62 0-1 .452-1 1 0 1.253 1.017 1.85 3.43 1.974C10.16 5.112 9.161 4 8 4zm5.5 2.974C13.768 5.112 14.767 4 15.93 4c.62 0 1 .452 1 1 0 1.253-1.018 1.85-3.43 1.974z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgGift;
