import { useSelector } from '@xstate/react';
import { PropsWithChildren, useContext } from 'react';

import { IS_DEMO } from '../../../config/constants';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { AuthCallbackView } from '../../auth/views/AuthCallbackView';
import { AuthErrorView } from '../../auth/views/AuthErrorView';
import { SignInPage } from '../../auth/views/SignInPage';
import { useDemoContext } from '../../demo/useDemoContext';
import { Error401View } from '../../errors/views/Error401View';
import { Error404View } from '../../errors/views/Error404View';
import { Error500View } from '../../errors/views/Error500View';
import { InvitesView } from '../../invites/views/InvitesView';
import { OnboardingFlow } from '../../onboarding/views/OnboardingFlow';
import { HomeLayout } from '../../questions/components/HomeLayout';
import { PostsLoading } from '../../questions/components/PostsLoading';
import { MembersLoading } from '../../questions/views/MembersLoading';
import { ProfilesLayout } from '../../questions/views/ProfilesLayout';
import { RequestToJoinView } from '../../request-to-join/RequestToJoinView';
import { SettingsLayout } from '../../settings/views/SettingsView';
import { useEffectOnce } from '../hooks/useEffectOnce';
import { DetailLayout } from '../layout/DetailLayout';
import { Layout } from '../layout/Layout';
import { AppContext } from '../machine/appContext';
import { AppState } from '../machine/appMachine';
import { Loading } from './Loading';

export const AppStates: React.FC<PropsWithChildren> = ({ children }) => {
  const { appService } = useContext(AppContext);
  const state = useSelector(appService, (state) => state);

  const { organization: demoOrganization } = useDemoContext();
  const { setAndViewOrganization } = useOptionalOrganization();
  const organization = useSelector(
    appService,
    (state) => state.context.organization
  );

  // Show demo organization if demo mode is enabled
  useEffectOnce(
    () => {
      if (IS_DEMO && demoOrganization) setAndViewOrganization(demoOrganization);
    },
    () => !organization && !!demoOrganization
  );

  if (state.matches(AppState.INITIALIZE)) {
    return null;
  } else if (
    state.matches('LOGIN.loading-auth') ||
    state.matches('LOGIN.loading-user')
  ) {
    return <SignInPage />;
  } else if (state.matches(AppState.ERROR_401)) {
    return <Error401View />;
  } else if (state.matches(AppState.ERROR_404)) {
    return <Error404View />;
  } else if (state.matches(AppState.ERROR_500)) {
    return <Error500View />;
  } else if (state.matches(AppState.AUTH_ERROR)) {
    return <AuthErrorView />;
  } else if (
    state.matches('AUTH_CALLBACK.loading-auth') ||
    state.matches('AUTH_CALLBACK.loading-user') ||
    state.matches('AUTH_CALLBACK.loading-user-successful.auth-redirect')
  ) {
    return <AuthCallbackView />;
  } else if (
    state.matches('INITIALIZE_USER.loading-auth') ||
    state.matches('INITIALIZE_USER.loading-user') ||
    state.matches('COMMUNITY.loading-community') ||
    state.matches('COMMUNITY.loading-auth') ||
    state.matches('COMMUNITY.loading-router') ||
    state.matches('REDIRECTING')
  ) {
    return <Loading />;
  } else if (
    state.matches('INITIALIZE_USER.loading-user-successful.onboarding') ||
    state.matches('AUTH_CALLBACK.loading-user-successful.onboarding') ||
    state.matches('UNPROTECTED.loading-user-successful.onboarding')
  ) {
    return <OnboardingFlow />;
  } else if (state.matches('COMMUNITY.loading-community-successful.invite')) {
    return <InvitesView />;
  } else if (
    state.matches('COMMUNITY.loading-community-successful.request-to-join')
  ) {
    return <RequestToJoinView />;
  } else if (
    state.matches(
      'COMMUNITY.loading-community-successful.view-community.routing.home'
    )
  ) {
    return (
      <Layout>
        <HomeLayout>
          <PostsLoading />
        </HomeLayout>
      </Layout>
    );
  } else if (
    state.matches(
      'COMMUNITY.loading-community-successful.view-community.routing.group'
    )
  ) {
    return (
      <Layout>
        <DetailLayout>
          <PostsLoading />
        </DetailLayout>
      </Layout>
    );
  } else if (
    state.matches(
      'COMMUNITY.loading-community-successful.view-community.routing.post'
    )
  ) {
    return (
      <Layout>
        <DetailLayout>
          <PostsLoading numPosts={1} />
        </DetailLayout>
      </Layout>
    );
  } else if (
    state.matches(
      'COMMUNITY.loading-community-successful.view-community.routing.members'
    )
  ) {
    return (
      <Layout>
        <ProfilesLayout>
          <MembersLoading />
        </ProfilesLayout>
      </Layout>
    );
  } else if (
    state.matches('COMMUNITY.loading-community-successful.view-community')
  ) {
    return <Layout>{children}</Layout>;
  } else if (
    state.matches('COMMUNITY.loading-community-successful.settings') ||
    state.matches('INITIALIZE_USER.loading-user-successful.settings')
  ) {
    return <SettingsLayout>{children}</SettingsLayout>;
  } else {
    return <>{children}</>;
  }
};
