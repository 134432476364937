import * as React from "react";

function SvgBriefcase(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8H4v5h16V8H8zm0-2V5a2 2 0 012-2h4a2 2 0 012 2v1h4a2 2 0 012 2v11a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h4zm3 9H4v4h16v-4h-7v1h-2v-1zm3-9V5h-4v1h4z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBriefcase;
