import * as React from "react";
import { SVGProps } from "react";
const SvgPayout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.625 2.5a1.05 1.05 0 0 0-1.042 1.042V5.29L5.924 6.417a3.126 3.126 0 0 0-1.742 1.35l-2.84 4.614a.625.625 0 1 0 1.065.655l2.84-4.615c.239-.388.61-.675 1.045-.809L9.583 6.6v2.71l-2.62 1.617a.625.625 0 0 0 .657 1.065L11.33 9.7l.008-.005a.658.658 0 0 1 .825.09c.27.27.263.687-.013.95l-5.155 4.776-.003.002a2.708 2.708 0 0 1-1.855.736H1.875a.624.624 0 1 0 0 1.25h3.263a3.959 3.959 0 0 0 2.708-1.072h.002l1.735-1.609v1.639a1.05 1.05 0 0 0 1.042 1.042h6.667a1.05 1.05 0 0 0 1.041-1.042V3.542A1.05 1.05 0 0 0 17.292 2.5h-6.667Zm1.458 1.25h3.75c0 .69.56 1.25 1.25 1.25v10c-.69 0-1.25.56-1.25 1.25h-3.75c0-.69-.56-1.25-1.25-1.25v-1.339l2-1.853c.335.177.72.275 1.125.275 1.207 0 2.266-.898 2.266-2.083s-1.06-2.083-2.266-2.083c-.593 0-1.143.21-1.555.569a1.93 1.93 0 0 0-.5-.13 1.91 1.91 0 0 0-1.07.2V5c.69 0 1.25-.56 1.25-1.25ZM13.958 5a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667Zm0 4.167c.606 0 1.016.408 1.016.833s-.41.833-1.016.833c-.155 0-.299-.029-.427-.079.127-.472.07-.981-.17-1.419.165-.1.367-.168.597-.168Zm0 4.166a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPayout;
