import { getCloudinaryUrl } from '@frond/shared';
import NextImage from 'next/image';
import React, { ImgHTMLAttributes } from 'react';

// https://nextjs.org/docs/api-reference/next/image
export interface NextImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  /**
   * cloudinary ID of image
   */
  src: string;
  /**
   * NextJS Image props
   * without width + height falls back to <img />
   */
  width?: number;
  height?: number;
  layout?: 'fixed' | 'intrinsic' | 'responsive';
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  objectPosition?: string;
  quality?: number | string;
  loading?: 'eager' | 'lazy';
  priority?: boolean;
  unoptimized?: boolean;
}

export interface ImageProps extends NextImageProps {
  /**
   * cloudinary transform string
   */
  transform?: string;
}

/**
 * Image component that uses Image when we have it and img when we don't
 */
export const Image: React.FC<ImageProps> = ({
  src,
  transform,
  width,
  height,
  layout,
  objectFit,
  objectPosition,
  alt,
  ...rest
}) => {
  if (!width || !height) {
    return (
      <img
        {...rest}
        src={getCloudinaryUrl({ id: src, width, height, transform })}
        alt={alt}
      />
    );
  }

  return (
    // Image component of NextJS v11 requires src to be string or StaticImport
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <NextImage
      {...rest}
      src={src}
      layout={layout}
      width={width}
      height={height}
      objectFit={objectFit}
      objectPosition={objectPosition}
    />
  );
};
