import React, { PropsWithChildren } from 'react';

/**
 * Shim for allowing full screen flexbox layouts on mobile
 * Child components with h="100%" will occupy the full screen
 */
export const FullscreenPageLayout: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style global jsx>{`
        html,
        body,
        div#__next,
        div#root {
          height: 100%;
          overscroll-behavior: none;
        }
        // Required to allow drag and drop in fixed containers with scrollbars
        div#__next.dragging {
          overflow-y: scroll;
          height: 100vh;
        }
      `}</style>
      {children}
    </>
  );
};
