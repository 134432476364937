import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { Trans, useTranslation } from 'next-i18next';

import { NotificationQuery } from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Avatar } from '../../common/components/Avatar';
import { Button } from '../../common/components/Button';
import { Text } from '../../common/components/Text';
import { InboxNotificationCommon } from './InboxNotificationCommon';

export const InboxNotificationNewMember: React.FC<{
  notification: NotificationQuery['notification'];
}> = ({ notification }) => {
  const { t } = useTranslation('inbox');
  const { otherUser } = notification;
  const { organization } = useOrganization();

  if (!otherUser) return <></>;

  return (
    <InboxNotificationCommon
      avatar={
        <Avatar
          size="m-1"
          imageId={otherUser.profileImageId}
          avatar={otherUser.avatar}
          bgColor={otherUser.avatarBgColor}
        />
      }
      title={t('inbox.new_member')}
      description={
        <Trans
          i18nKey="inbox.new_member_description"
          t={t}
          values={{
            firstName: otherUser.firstName || otherUser.username,
            organizationName: organization.name,
          }}
          components={{
            strong: <Text as="span" variant="md-semibold" />,
          }}
        />
      }
      action={
        <x.div display="flex" spaceX={2}>
          <Button
            variant="secondary"
            label={t('inbox.view_profile')}
            href={routes.groups
              .organization(organization.shortId)
              .person(otherUser.username)}
          />
          <Button
            variant="secondary"
            label={t('inbox.message_name', {
              name: otherUser.firstName || otherUser.username,
            })}
            href={routes.groups
              .organization(organization.shortId)
              .newMessage(otherUser.username)}
          />
        </x.div>
      }
    />
  );
};
