import * as React from "react";

function SvgCircleFilledExclamation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-5.002a1 1 0 100-2 1 1 0 000 2zm1.004-12h-2.001v8h2v-8z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCircleFilledExclamation;
