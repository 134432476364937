import * as React from "react";

function SvgAppZoom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-zoom_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.535 10.617c.091.158.121.338.131.54l.013.27v1.886l.014.27c.026.44.351.767.795.794l.269.013v-2.963l.013-.27c.011-.2.041-.383.134-.542a1.076 1.076 0 011.865.003c.091.158.12.342.131.54l.013.268v1.887l.014.27c.027.443.35.769.796.794l.268.013v-3.232a2.156 2.156 0 00-3.772-1.426 2.154 2.154 0 00-2.824-.36c-.21-.235-.68-.37-.95-.37v5.388l.27-.013c.451-.03.777-.347.795-.794l.014-.27v-1.886l.013-.27c.012-.203.04-.382.132-.541a1.08 1.08 0 011.471-.393c.164.095.3.23.395.394zm-18.45 3.76l.27.013h4.042l-.013-.268c-.037-.443-.35-.767-.795-.796l-.27-.013H1.895l3.232-3.234-.013-.269a.815.815 0 00-.795-.796l-.27-.012H.009l.013.269c.035.439.354.77.795.795l.27.013H3.51L.277 13.313l.014.27a.822.822 0 00.795.794zM15.388 9.79a2.695 2.695 0 11-3.81 3.811 2.695 2.695 0 013.81-3.81zm-.762.763a1.618 1.618 0 11-2.288 2.287 1.618 1.618 0 012.288-2.287zm-6.8-1.552a2.693 2.693 0 011.904.79 2.694 2.694 0 11-3.812 3.81 2.695 2.695 0 011.904-4.6h.003zm1.141 1.552a1.618 1.618 0 11-2.288 2.286 1.618 1.618 0 012.288-2.286z"
          fill="#2D8CFF"
        />
      </g>
      <defs>
        <clipPath id="app-zoom_svg__clip0">
          <path fill="#fff" transform="translate(0 9)" d="M0 0h24v5.391H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppZoom;
