import * as React from "react";
import { SVGProps } from "react";
const SvgAnalyticsFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 1.667c-4.595 0-8.333 3.738-8.333 8.333 0 4.595 3.738 8.333 8.333 8.333 4.595 0 8.333-3.738 8.333-8.333 0-4.595-3.738-8.333-8.333-8.333Zm0 1.875a.625.625 0 1 1 0 1.25.625.625 0 0 1 0-1.25Zm-5.833 7.083a.625.625 0 1 1 0-1.25.625.625 0 0 1 0 1.25Zm2.15 3.942a.625.625 0 1 1-.884-.883.625.625 0 0 1 .884.883Zm0-8.25a.625.625 0 1 1-.884-.883.625.625 0 0 1 .884.883ZM9.23 10.32a.833.833 0 0 1 .45-1.089c.426-.176 4.763-1.569 4.94-1.143.175.425-3.876 2.507-4.301 2.683a.834.834 0 0 1-1.089-.451Zm5.337 4.248a.625.625 0 1 1-.884-.883.625.625 0 0 1 .884.883Zm0-8.25a.625.625 0 1 1-.884-.883.625.625 0 0 1 .884.883Zm1.266 4.308a.625.625 0 1 1 0-1.25.625.625 0 0 1 0 1.25Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgAnalyticsFilled;
