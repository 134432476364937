import React from 'react';

import { Button } from '../../common/components/Button';
import { Loading } from '../../common/components/Loading';
import { ModalLayout } from '../../common/layout/ModalLayout';
import { SignInContent } from '../components/SignIn/SignInContent';

export interface SignInPageProps {
  /**
   * show dev sign in
   */
  preview?: boolean;
  /**
   * whether to sign in page has an invite
   */
  hasInvite?: boolean;
  /**
   * Handler reset db click
   */
  onResetDatabaseClick?: () => void;
  /**
   * Callback url path for sign in form
   */
  callbackUrlPath?: string;
}

/**
 * Full screen component used for sign in
 */
export const SignInPage: React.FC<SignInPageProps> = ({
  preview,
  hasInvite,
  onResetDatabaseClick,
  callbackUrlPath,
}) => {
  if (hasInvite) {
    return <Loading />;
  }

  return (
    <ModalLayout>
      <SignInContent callbackUrlPath={callbackUrlPath} />
      {preview && (
        <Button
          label="Reset database"
          variant="warning"
          onClick={onResetDatabaseClick}
        />
      )}
    </ModalLayout>
  );
};
