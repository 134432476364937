import * as React from "react";
import { SVGProps } from "react";

const SvgReminder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.5 17v-3.98c0-.239-.085-.47-.24-.652l-1.88-2.193a.5.5 0 0 0-.88.326V17h3ZM17.5 7v3.98c0 .239.085.47.24.652l1.88 2.193a.5.5 0 0 0 .88-.326V7h-3Z"
      fill="#0368BA"
    />
    <path
      d="M16 14.905V17H3.5l2.414 2.414A2 2 0 0 0 7.328 20H16v2.095c0 .36.435.54.69.286l3.35-3.351a.75.75 0 0 0 0-1.06l-3.35-3.351a.404.404 0 0 0-.69.286ZM8 9.095V7h12.5l-2.414-2.414A2 2 0 0 0 16.672 4H8V1.905c0-.36-.435-.54-.69-.286L3.96 4.97a.75.75 0 0 0 0 1.061l3.35 3.35c.255.255.69.075.69-.285Z"
      fill="#0884DB"
    />
  </svg>
);
export default SvgReminder;
