import { routes } from '@frond/shared';
import styled, { x } from '@xstyled/styled-components';

import { Button } from '../common/components/Button';
import { Heading } from '../common/components/Heading';
import { Modal } from '../common/components/Modal';
import { Text } from '../common/components/Text';

const StyledModal = styled(Modal)`
  [data-reach-dialog-content] {
    width: 440px;
    box-sizing: border-box;
    padding: 6;
  }
`;

type RestrictedDemoActionModalProps = {
  onDismiss: () => void;
  isOpen: boolean;
};
export const RestrictedDemoActionModal = ({
  onDismiss,
  isOpen,
}: RestrictedDemoActionModalProps) => {
  return (
    <StyledModal
      ariaLabel="Ask us anything"
      onDismiss={onDismiss}
      isOpen={isOpen}
      closeButtonVariant="v3"
      responsive
      center
    >
      <x.div textAlign="center" spaceY={4}>
        <Heading variant="lg">Thanks for your interest in Frond</Heading>
        <Text>
          Hi there! This is a demo and some actions are disabled. Create your
          own community to get the full experience.
        </Text>
        <Button
          href={`https://frond.com${routes.groups.organizationsNew()}`}
          label="Create your community"
          target="_blank"
        />
      </x.div>
    </StyledModal>
  );
};
