import * as Popover from '@radix-ui/react-popover';
import styled, { SystemProps, x } from '@xstyled/styled-components';
import {
  UseComboboxReturnValue,
  UseMultipleSelectionReturnValue,
} from 'downshift';
import { forwardRef, KeyboardEvent, PropsWithChildren, useRef } from 'react';

import { inputStyles } from '../Input';
import { AudienceSelectorContext } from './AudienceSelectorContext';
import { AudienceSelectorMenuItem } from './AudienceSelectorMenuItem';
import { AudienceSelectorToken } from './AudienceSelectorToken';
import { AudienceSelection } from './AudienceSelectorTypes';
import { getAudienceSelectionId } from './AudienceSelectorUtils';

export const AudienceSelectorInput: React.FC<
  PropsWithChildren<
    {
      audienceSelection: AudienceSelection[];
      placeholder?: string;
      showSelectedTokens?: boolean;
      multipleSelection: UseMultipleSelectionReturnValue<AudienceSelection>;
      combobox: UseComboboxReturnValue<AudienceSelection>;
      renderTokenElement?: (
        audience: AudienceSelection,
        onRemove: () => void,
        onUpdate: (as: AudienceSelection) => void
      ) => React.ReactElement | null;
      renderMenuItemStatus?: (
        audience: AudienceSelection
      ) => React.ReactElement | null;
      onPaste?: (event: ClipboardEvent) => void;
      autoFocus?: boolean;
    } & SystemProps
  >
> = (props) => {
  const {
    audienceSelection,
    placeholder,
    showSelectedTokens,
    children,
    multipleSelection,
    combobox,
    renderTokenElement,
    renderMenuItemStatus,
    autoFocus,
    onPaste,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    getDropdownProps,
    selectedItems,
    setSelectedItems,
    removeSelectedItem,
  } = multipleSelection;

  const {
    getInputProps,
    getComboboxProps,
    getMenuProps,
    getItemProps,
    openMenu,
    isOpen,
  } = combobox;

  /****
   * Rendering
   */
  const defaultPlaceholder = 'E.g. Julie, richard@piedpiper.com';

  const currentPlaceholder =
    selectedItems.length && showSelectedTokens
      ? ''
      : placeholder || defaultPlaceholder;

  return (
    <Popover.Root open={true}>
      <x.div position="relative">
        <Popover.Anchor>
          <InputWrapper
            {...getComboboxProps()}
            onClick={(event: Event) => {
              if (inputRef.current?.contains(event.target as Node)) {
                inputRef.current?.focus();
                openMenu();
              }
            }}
            bg="white"
            {...props}
          >
            {showSelectedTokens &&
              selectedItems.map((audienceSelection) => {
                const handleRemove = () =>
                  removeSelectedItem(audienceSelection);
                const handleUpdate = (as: AudienceSelection) => {
                  const copySelectedItems = [...selectedItems];
                  const audienceIndex = copySelectedItems.findIndex(
                    (audience) =>
                      getAudienceSelectionId(audience) ===
                      getAudienceSelectionId(as)
                  );
                  copySelectedItems[audienceIndex] = as;
                  setSelectedItems(copySelectedItems);
                };
                return renderTokenElement ? (
                  renderTokenElement?.(
                    audienceSelection,
                    handleRemove,
                    handleUpdate
                  )
                ) : (
                  <AudienceSelectorToken
                    key={getAudienceSelectionId(audienceSelection)}
                    audienceSelection={audienceSelection}
                    onRemove={handleRemove}
                  />
                );
              })}
            <InnerInput
              autoComplete="off"
              autoFocus={autoFocus}
              placeholder={currentPlaceholder}
              {...getInputProps(
                getDropdownProps({
                  ref: inputRef,
                  // Prevent selecting items with tab key
                  onKeyDown(event: KeyboardEvent) {
                    if (event.key === 'Tab') {
                      event.preventDefault();
                      event.stopPropagation();
                    }
                  },
                })
              )}
              onPaste={onPaste}
            />
          </InputWrapper>
        </Popover.Anchor>

        <Popover.Portal>
          <Popover.Content
            align="start"
            sideOffset={8}
            style={{
              width: 'var(--radix-popover-trigger-width)',
            }}
          >
            <x.div {...getMenuProps()}>
              {isOpen && audienceSelection?.length > 0 && (
                <x.ul
                  p={3}
                  boxSizing="border-box"
                  backgroundColor="white"
                  boxShadow="sm"
                  borderRadius="md"
                  display="flex"
                  flexDirection="column"
                  spaceY={1}
                  maxH="198px"
                  overflowY="scroll"
                  zIndex="popover"
                >
                  {audienceSelection.map((item, index) => (
                    <x.li
                      {...getItemProps({ item, index })}
                      key={getAudienceSelectionId(item)}
                    >
                      <AudienceSelectorMenuItem
                        audienceSelection={item}
                        renderMenuItemStatus={renderMenuItemStatus}
                      />
                    </x.li>
                  ))}
                </x.ul>
              )}
            </x.div>
          </Popover.Content>
        </Popover.Portal>

        <AudienceSelectorContext.Provider value={{ multipleSelection }}>
          {children}
        </AudienceSelectorContext.Provider>
      </x.div>
    </Popover.Root>
  );
};

/**
 * Use the existing styles for the text input, with the border hidden
 * The border is added in the wrapper so we can add the selected items first
 */
const InnerInput = styled.input`
  ${inputStyles}
  border: none;
  padding-left: 0;
  padding-right: 0;
  display: inline-block;
  min-width: 20;
  max-width: 100%;
  flex: 1;
  margin-top: 0.4rem;
  margin-bottom: 0.38rem;
`;

const InputWrapper = forwardRef<any>((props, ref) => {
  return (
    <x.div
      display="flex"
      flexWrap="wrap"
      border="default"
      borderRadius="md"
      borderColor="gray.200"
      backgroundColor="white"
      ref={ref}
      px={3}
      minHeight={14}
      boxSizing="border-box"
      {...props}
    />
  );
});

InputWrapper.displayName = 'InputWrapper';
