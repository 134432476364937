import * as Sentry from '@sentry/browser';
import { useInterpret, useSelector } from '@xstate/react';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { PropsWithChildren, useEffect } from 'react';

import { IS_DEMO } from '../../../../config/constants';
import { PostHogProvider } from '../../../analytics/PostHogAnalytics';
import { AppStates } from '../../../common/components/AppStates';
import { CommentConfigProvider } from '../../../common/hooks/useCommentConfig';
import { EmojiDataProvider } from '../../../common/hooks/useEmojiData';
import { OrganizationRolesProvider } from '../../../common/hooks/useOrganizationRoles';
import { UsersProvider } from '../../../common/hooks/useUsers';
import { useAppMachineActions } from '../../../common/machine/appActions';
import { AppContext } from '../../../common/machine/appContext';
import {
  appMachine,
  initialAppContext,
} from '../../../common/machine/appMachine';
import { useAppMachineServices } from '../../../common/machine/appServices';
import { NotificationsProvider } from '../../../notifications/components/NotificationsProvider';
import { OrganizationEntitlementsProvider } from '../../../organizations/hooks/useOrganizationEntitlements';
import { useAuth } from '../../hooks/useAuth';
import { DirectMessageTokenProvider } from '../../hooks/useDirectMessageToken';

export const MachineGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const { error, isAuthenticated, isLoading } = useAuth();
  const services = useAppMachineServices();
  const actions = useAppMachineActions();

  const appService = useInterpret(
    appMachine,
    {
      devTools: true,
      context: {
        ...initialAppContext,
        inviteCode: router.query.invite as string,
        shortId: router.query.shortId as string,
        isAuthenticated,
        isAuthenticatedLoading: isLoading,
        pathname: router.pathname,
        isDemo: IS_DEMO,
      },
      services,
      actions,
    },
    (state) => {
      if (state && typeof window !== 'undefined') {
        // Log state with context for analytics
        posthog?.capture(state.event.type, {
          ...state.context,
          states: state.toStrings(),
        });
      }
    }
  );

  const user = useSelector(appService, (state) => state.context.user);

  // Set up Sentry user context
  useEffect(() => {
    if (user) Sentry.setContext('user', user);
  }, [user]);

  // Update auth state in machine when auth is changing
  useEffect(() => {
    appService.send({
      type: 'SET_AUTH',
      isAuthenticated,
      isAuthenticatedLoading: isLoading,
      error,
    });
  }, [isAuthenticated, isLoading, error, appService]);

  // Update routing state in machine when route is changing
  useEffect(() => {
    const handleRouteStart = (pathname: string) => {
      appService.send('ROUTE_CHANGE_START', { pathname });
    };
    const handleRouteComplete = (pathname: string) => {
      appService.send('ROUTE_CHANGE_COMPLETE', { pathname });
    };

    router.events.on('routeChangeStart', handleRouteStart);
    router.events.on('routeChangeComplete', handleRouteComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteStart);
      router.events.off('routeChangeComplete', handleRouteComplete);
    };
  }, [router, appService]);

  // Update inviteCode in machine when invite in URL changes
  useEffect(() => {
    appService.send({
      type: 'SET_INVITE_CODE',
      inviteCode: router.query.invite as string,
    });
  }, [appService, router.query.invite]);

  // Update isRouterReady in machine when router is initialized
  useEffect(() => {
    appService.send({
      type: 'SET_ROUTER_READY',
      isReady: router.isReady,
    });
  }, [appService, router.isReady]);

  // Update organization in machine when shortId in URL changes
  useEffect(() => {
    appService.send({
      type: 'SET_SHORT_ID',
      shortId: router.query.shortId as string,
    });
  }, [appService, router.query.shortId]);

  // Update organization in machine when shortId in URL changes
  useEffect(() => {
    appService.send({
      type: 'SET_PATHNAME',
      pathname: router.pathname,
    });
  }, [appService, router.pathname]);

  useEffect(() => {
    try {
      let emojiScaleFactor = 1;
      const userAgent = navigator.userAgent;

      if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
        // Safari on macOS
        emojiScaleFactor = 1.3125;
      } else if (userAgent.includes('Chrome')) {
        // Chrome on both macOS and Windows
        if (navigator.platform === 'Win32') {
          emojiScaleFactor = 1.38;
        } else {
          emojiScaleFactor = 1.0;
        }
      } else if (userAgent.includes('Firefox')) {
        // Firefox on macOS
        emojiScaleFactor = 1.0;
      } else {
        // Default to 1.0 for browsers not explicitly handled
        emojiScaleFactor = 1.0;
      }

      // Apply the scaling factor to CSS variables
      document.documentElement.style.setProperty(
        '--emoji-scale-factor',
        emojiScaleFactor.toString()
      );
    } catch {
      // Do nothing
    }
  }, []);

  return (
    <AppContext.Provider value={{ appService }}>
      <DirectMessageTokenProvider>
        <UsersProvider>
          <OrganizationRolesProvider>
            <CommentConfigProvider>
              <OrganizationEntitlementsProvider>
                <NotificationsProvider>
                  <PostHogProvider>
                    <EmojiDataProvider>
                      <AppStates>{children}</AppStates>
                    </EmojiDataProvider>
                  </PostHogProvider>
                </NotificationsProvider>
              </OrganizationEntitlementsProvider>
            </CommentConfigProvider>
          </OrganizationRolesProvider>
        </UsersProvider>
      </DirectMessageTokenProvider>
    </AppContext.Provider>
  );
};
