import { FC } from 'react';

import {
  PostFeedFragment,
  ReactionTypes,
} from '../../../../generated/types-and-hooks';
import { useViewer } from '../../auth/hooks/useViewer';
import { Reactions } from '../../common/components/Reactions';
import { useReactions } from '../../common/hooks/useReactions';

export const PostCardReactions: FC<{ post: PostFeedFragment }> = ({ post }) => {
  const { viewer: user } = useViewer();
  const { onReactionClick } = useReactions();
  const handleReactionClick = (emojiUnicode: string) => {
    if (!user) return;
    onReactionClick(post?.id as string, ReactionTypes.Post, emojiUnicode, user);
  };
  return (
    <Reactions
      onReactionClick={handleReactionClick}
      reactions={post.reactions}
      variant="secondary"
    />
  );
};
