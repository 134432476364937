import * as React from "react";

function SvgAppGoogleSheets(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="app-google-sheets_svg__a"
        maskUnits="userSpaceOnUse"
        x={3}
        y={0}
        width={18}
        height={25}
      >
        <path
          d="M14.182 0H4.909c-.9 0-1.637.736-1.637 1.636v20.728c0 .9.737 1.636 1.637 1.636H19.09c.9 0 1.636-.736 1.636-1.636V6.546L14.182 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#app-google-sheets_svg__a)">
        <path
          d="M14.182 0H4.909c-.9 0-1.637.736-1.637 1.636v20.728c0 .9.737 1.636 1.637 1.636H19.09c.9 0 1.636-.736 1.636-1.636V6.546l-3.818-2.728L14.182 0z"
          fill="#0F9D58"
        />
      </g>
      <mask
        id="app-google-sheets_svg__b"
        maskUnits="userSpaceOnUse"
        x={3}
        y={0}
        width={18}
        height={25}
      >
        <path
          d="M14.182 0H4.909c-.9 0-1.637.736-1.637 1.636v20.728c0 .9.737 1.636 1.637 1.636H19.09c.9 0 1.636-.736 1.636-1.636V6.546L14.182 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#app-google-sheets_svg__b)">
        <path
          d="M7.636 11.727v7.91h8.727v-7.91H7.636zm3.818 6.819H8.727v-1.364h2.727v1.364zm0-2.182H8.727V15h2.727v1.364zm0-2.182H8.727v-1.364h2.727v1.364zm3.818 4.364h-2.727v-1.364h2.727v1.364zm0-2.182h-2.727V15h2.727v1.364zm0-2.182h-2.727v-1.364h2.727v1.364z"
          fill="#F1F1F1"
        />
      </g>
      <mask
        id="app-google-sheets_svg__c"
        maskUnits="userSpaceOnUse"
        x={3}
        y={0}
        width={18}
        height={25}
      >
        <path
          d="M14.182 0H4.909c-.9 0-1.637.736-1.637 1.636v20.728c0 .9.737 1.636 1.637 1.636H19.09c.9 0 1.636-.736 1.636-1.636V6.546L14.182 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#app-google-sheets_svg__c)">
        <path
          d="M14.66 6.067l6.067 6.065V6.546l-6.067-.479z"
          fill="url(#app-google-sheets_svg__paint0_linear)"
        />
      </g>
      <mask
        id="app-google-sheets_svg__d"
        maskUnits="userSpaceOnUse"
        x={3}
        y={0}
        width={18}
        height={25}
      >
        <path
          d="M14.182 0H4.909c-.9 0-1.637.736-1.637 1.636v20.728c0 .9.737 1.636 1.637 1.636H19.09c.9 0 1.636-.736 1.636-1.636V6.546L14.182 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#app-google-sheets_svg__d)">
        <path
          d="M14.182 0v4.91c0 .903.732 1.636 1.636 1.636h4.91L14.181 0z"
          fill="#87CEAC"
        />
      </g>
      <mask
        id="app-google-sheets_svg__e"
        maskUnits="userSpaceOnUse"
        x={3}
        y={0}
        width={18}
        height={25}
      >
        <path
          d="M14.182 0H4.909c-.9 0-1.637.736-1.637 1.636v20.728c0 .9.737 1.636 1.637 1.636H19.09c.9 0 1.636-.736 1.636-1.636V6.546L14.182 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#app-google-sheets_svg__e)">
        <path
          d="M4.909 0c-.9 0-1.637.736-1.637 1.636v.137c0-.9.737-1.637 1.637-1.637h9.273V0H4.909z"
          fill="#fff"
          fillOpacity={0.2}
        />
      </g>
      <mask
        id="app-google-sheets_svg__f"
        maskUnits="userSpaceOnUse"
        x={3}
        y={0}
        width={18}
        height={25}
      >
        <path
          d="M14.182 0H4.909c-.9 0-1.637.736-1.637 1.636v20.728c0 .9.737 1.636 1.637 1.636H19.09c.9 0 1.636-.736 1.636-1.636V6.546L14.182 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#app-google-sheets_svg__f)">
        <path
          d="M19.09 23.864H4.91c-.9 0-1.637-.737-1.637-1.637v.137c0 .9.737 1.636 1.637 1.636H19.09c.9 0 1.636-.736 1.636-1.636v-.137c0 .9-.736 1.637-1.636 1.637z"
          fill="#263238"
          fillOpacity={0.2}
        />
      </g>
      <mask
        id="app-google-sheets_svg__g"
        maskUnits="userSpaceOnUse"
        x={3}
        y={0}
        width={18}
        height={25}
      >
        <path
          d="M14.182 0H4.909c-.9 0-1.637.736-1.637 1.636v20.728c0 .9.737 1.636 1.637 1.636H19.09c.9 0 1.636-.736 1.636-1.636V6.546L14.182 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#app-google-sheets_svg__g)">
        <path
          d="M15.818 6.546a1.636 1.636 0 01-1.636-1.637v.137c0 .904.732 1.636 1.636 1.636h4.91v-.136h-4.91z"
          fill="#263238"
          fillOpacity={0.1}
        />
      </g>
      <path
        d="M14.182 0H4.909c-.9 0-1.637.736-1.637 1.636v20.728c0 .9.737 1.636 1.637 1.636H19.09c.9 0 1.636-.736 1.636-1.636V6.546L14.182 0z"
        fill="url(#app-google-sheets_svg__paint1_radial)"
      />
      <defs>
        <radialGradient
          id="app-google-sheets_svg__paint1_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.825 .477) scale(28.1452)"
        >
          <stop stopColor="#fff" stopOpacity={0.1} />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </radialGradient>
        <linearGradient
          id="app-google-sheets_svg__paint0_linear"
          x1={17.694}
          y1={6.588}
          x2={17.694}
          y2={12.133}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#263238" stopOpacity={0.2} />
          <stop offset={1} stopColor="#263238" stopOpacity={0.02} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgAppGoogleSheets;
