import { theme } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import pluralize from 'pluralize';
import { useState } from 'react';

import {
  PollCoreFragment,
  PostCoreFragment,
  useCreateVoteMutation,
  useDeleteVoteMutation,
} from '../../../../generated/types-and-hooks';
import { useViewer } from '../../auth/hooks/useViewer';
import { Icon } from '../../common/components/Icon';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { Text } from '../../common/components/Text';
import { Tooltip } from '../../common/components/Tooltip';
import { UsersTooltip } from '../../common/components/Users';
import { PostCardCount } from './PostCard';

const PostPollOption: React.FC<{
  option: PollCoreFragment['options'][0];
}> = ({ option }) => {
  const [mutate] = useCreateVoteMutation();

  const handleClick = () => {
    mutate({
      variables: {
        input: {
          pollOptionId: option.id,
        },
      },
    });
  };

  return (
    <x.div
      display="flex"
      justifyContent="center"
      backgroundColor={{
        _: 'white',
        hover: 'yellow.100',
      }}
      borderRadius="md"
      boxShadow={{
        _: 'sm',
      }}
      border="none"
      cursor="pointer"
      p={3}
      onClick={handleClick}
    >
      <Text variant="sm-semibold">{option.name}</Text>
    </x.div>
  );
};

const PostPollOptionVoted: React.FC<{
  poll: PollCoreFragment;
  option: PollCoreFragment['options'][0];
}> = ({ poll, option }) => {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const [mutate, { loading }] = useDeleteVoteMutation();
  const [hover, setHover] = useState<boolean>();

  const handleClick = () => {
    mutate({
      variables: {
        input: {
          pollOptionId: option.id,
        },
      },
    });
  };

  const hasVotedOption = !!option.votes.find(
    (vote) => vote.createdBy.id === viewer?.id
  );

  let totalVotes = 0;
  poll.options.map((option) => {
    totalVotes += option.votes.length;
  });

  const perc =
    totalVotes > 0 ? Math.round((option.votes.length / totalVotes) * 100) : 0;

  const isWinningOption = (
    poll: PollCoreFragment,
    option: PollCoreFragment['options'][0]
  ) => {
    for (let i = 0; i < poll.options.length; i++) {
      if (poll.options[i].votes.length >= option.votes.length) {
        return false;
      }
    }

    return true;
  };

  const bgColor = isWinningOption(poll, option) ? 'yellow.200' : 'yellow.100';

  const commonProps = {
    w: `${perc}%`,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: bgColor,
  };

  const buttonWithTooltip = (
    <Tooltip
      aria-label={t('remove_your_vote')}
      label={<Text variant="sm-semibold">{t('remove_your_vote')}</Text>}
      size="small"
      visible={hover}
    >
      <x.div
        {...commonProps}
        backgroundColor={hover ? 'yellow.200' : bgColor}
      />
    </Tooltip>
  );

  const div = <x.div {...commonProps} />;

  return (
    <x.div
      backgroundColor="white"
      borderRadius="md"
      position="relative"
      w="100%"
    >
      <x.div
        borderRadius="md"
        overflow="hidden"
        position="absolute"
        right={0}
        left={0}
        top={0}
        bottom={0}
      >
        {hasVotedOption ? buttonWithTooltip : div}
      </x.div>
      <x.div
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        spaceX={5}
        p="2"
        pl="3"
        position="relative"
        w="100%"
        boxSizing="border-box"
        {...(hasVotedOption && {
          cursor: 'pointer',
          onClick: handleClick,
          onMouseEnter: () => setHover(true),
          onMouseLeave: () => setHover(false),
        })}
      >
        <x.div
          w="100%"
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          spaceX={2}
        >
          <Text variant="sm-semibold">{option.name}</Text>
          {loading ? (
            <x.div>
              <LoadingSpinner size="4" />
            </x.div>
          ) : (
            hasVotedOption && (
              <Icon name="circle-filled-checked" size="4" color="yellow.400" />
            )
          )}
        </x.div>
        <UsersTooltip
          users={option.votes.map((v) => v.createdBy)}
          fallback={<Text variant="sm-semibold">No votes</Text>}
          variant="small"
          tooltipVariant="light"
          label={`${option.votes.length} ${pluralize(
            'vote',
            option.votes.length
          )}`}
        >
          <x.div>
            <PostCardCount
              count={`${perc}%`}
              textVariant="sm-semibold"
              backgroundColor="gray.50"
              minWidth="auto"
              h="auto"
              py={`calc(${theme.sizes[1]} - 1px)`}
            />
          </x.div>
        </UsersTooltip>
      </x.div>
    </x.div>
  );
};

export const PostPoll: React.FC<{
  post: PostCoreFragment;
}> = ({ post }) => {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  if (!post.poll) return null;

  const poll = post.poll;

  const hasVotedPoll = !!poll.options.find((option) => {
    return option.votes.find((vote) => vote.createdBy.id === viewer?.id);
  });

  let numVotes = 0;
  for (const option of poll.options) {
    numVotes += option.votes.length;
  }

  return (
    <x.div
      display="flex"
      flexDirection="column"
      spaceY={2}
      p={4}
      borderRadius="md"
      backgroundColor="gray.50"
    >
      {hasVotedPoll ? (
        <>
          {poll.options.map((option) => (
            <PostPollOptionVoted key={option.id} poll={poll} option={option} />
          ))}
          <Text variant="sm-medium" color="gray.300">
            {numVotes === 1 ? t('one_vote') : t('n_votes', { count: numVotes })}
          </Text>
        </>
      ) : (
        poll.options.map((option) => (
          <PostPollOption key={option.id} option={option} />
        ))
      )}
    </x.div>
  );
};
