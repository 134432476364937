import * as SwitchPrimitive from '@radix-ui/react-switch';
import styled, { css, th } from '@xstyled/styled-components';

const SIZE_MAP = {
  xs: {
    width: '37px',
    height: th.size('5'),
    thumb: '16px',
    thumbTransformUnchecked: 'translateX(2px)',
    thumbTransformChecked: 'translateX(19px)',
  },
  sm: {
    width: '49px',
    height: th.size('7'),
    thumb: '22px',
    thumbTransformUnchecked: 'translateX(3px)',
    thumbTransformChecked: 'translateX(23px)',
  },
  md: {
    width: '69px',
    height: th.size('9'),
    thumb: '28px',
    thumbTransformUnchecked: 'translateX(4px)',
    thumbTransformChecked: 'translateX(36px)',
  },
} as const;

const StyledThumb = styled(SwitchPrimitive.Thumb)<SwitchProps>`
  display: block;
  width: ${({ size }) => SIZE_MAP[size || 'sm'].thumb};
  height: ${({ size }) => SIZE_MAP[size || 'sm'].thumb};
  background-color: white;
  border-radius: full;
  transition: transform 100ms;
  transform: ${({ size }) => SIZE_MAP[size || 'sm'].thumbTransformUnchecked};
  will-change: transform;
  box-sizing: border-box;

  &[data-state='checked'] {
    transform: ${({ size }) => SIZE_MAP[size || 'sm'].thumbTransformChecked};
  }
`;

const StyledSwitch = styled(SwitchPrimitive.Root)<SwitchProps>`
  all: unset;
  width: ${({ size }) => SIZE_MAP[size || 'sm'].width};
  height: ${({ size }) => SIZE_MAP[size || 'sm'].height};
  background-color: gray.200;
  border-radius: full;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;

  &:focus {
    box-shadow: 0 0 0 2px black;
  }

  :disabled {
    cursor: not-allowed;
  }

  :disabled ${StyledThumb} {
    background-color: gray.50;
  }

  &[data-state='checked'] {
    ${({ colorScheme }) =>
      colorScheme === 'primary'
        ? css`
            background-color: brand.300;
            &:disabled {
              background-color: brand.100;
            }
          `
        : css`
            background-color: gray.500;
          `}
  }
`;

type SwitchProps = SwitchPrimitive.SwitchProps & {
  size?: keyof typeof SIZE_MAP;
  colorScheme?: 'primary' | 'gray';
};

export const Switch = ({
  size = 'sm',
  colorScheme = 'primary',
  ...props
}: SwitchProps) => {
  return (
    <StyledSwitch {...props} size={size} colorScheme={colorScheme}>
      <StyledThumb size={size} />
    </StyledSwitch>
  );
};
