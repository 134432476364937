import { theme } from '@frond/shared';

import { ViewerQuery } from '../../../../generated/types-and-hooks';

export enum GroupOnboardingSteps {
  ORGANIZATION,
  TEMPLATES,
  PROFILE,
}

export type ActiveStepsType = {
  [GroupOnboardingSteps.ORGANIZATION]: boolean;
  [GroupOnboardingSteps.TEMPLATES]: boolean;
  [GroupOnboardingSteps.PROFILE]: boolean;
};

export const ONBOARDING_STATE_KEY = 'onboarding';

export type OnboardingState = {
  steps: ActiveStepsType;
  currentStep: GroupOnboardingSteps;
  firstRun?: boolean;
};

export const STEP_COLOR = {
  [GroupOnboardingSteps.ORGANIZATION]: theme.colors.brand[200],
  [GroupOnboardingSteps.TEMPLATES]: theme.colors.blue[100],
  [GroupOnboardingSteps.PROFILE]: theme.colors.green[100],
};

export const getNextStep = (
  currentStep: GroupOnboardingSteps,
  activeSteps: ActiveStepsType
) => {
  let nextStep = currentStep;
  nextStep++;
  while (!activeSteps[nextStep]) {
    // no more steps
    if (nextStep === Object.keys(activeSteps).length) return null;
    nextStep++;
  }
  return nextStep;
};

export const getActiveSteps = (
  user?: Pick<ViewerQuery['viewer'], 'preferences' | 'organizationMemberships'>,
  showCreateOrganization?: boolean
) => {
  const enableOrganizationStep =
    showCreateOrganization || !user?.organizationMemberships?.length;
  return {
    steps: {
      [GroupOnboardingSteps.ORGANIZATION]: enableOrganizationStep,
      [GroupOnboardingSteps.TEMPLATES]: enableOrganizationStep,
      [GroupOnboardingSteps.PROFILE]: !user?.preferences.isProfileSetup,
    },
    currentStep: enableOrganizationStep
      ? GroupOnboardingSteps.ORGANIZATION
      : !user?.preferences.isProfileSetup
        ? GroupOnboardingSteps.PROFILE
        : GroupOnboardingSteps.TEMPLATES,
    firstRun: true,
  };
};

export const getStepNum = (
  step: GroupOnboardingSteps,
  activeSteps: ActiveStepsType
) => {
  switch (step) {
    case GroupOnboardingSteps.ORGANIZATION: {
      return 1;
    }
    case GroupOnboardingSteps.TEMPLATES: {
      return activeSteps[GroupOnboardingSteps.ORGANIZATION] ? 2 : 1;
    }
    case GroupOnboardingSteps.PROFILE: {
      return activeSteps[GroupOnboardingSteps.ORGANIZATION] &&
        activeSteps[GroupOnboardingSteps.TEMPLATES]
        ? 3
        : activeSteps[GroupOnboardingSteps.ORGANIZATION] ||
            activeSteps[GroupOnboardingSteps.TEMPLATES]
          ? 2
          : 1;
    }
    default: {
      return 1;
    }
  }
};

export const getNumActiveSteps = (activeSteps: ActiveStepsType) => {
  let num = 0;
  Object.entries(activeSteps).map(([, active]) => active && num++);
  return num;
};
