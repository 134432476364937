import * as React from "react";

function SvgAppChromatic(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#app-chromatic_svg__clip0)">
        <path
          d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
          fill="#FC521F"
        />
        <path
          d="M14.82 17.519l-1.908-1.1 5.737-3.31c.22-.127.427-.272.62-.434a3.347 3.347 0 01.154 3.61A3.383 3.383 0 0116.5 17.97a3.368 3.368 0 01-1.68-.451zm-.46.795c.22.127.45.234.687.32a3.352 3.352 0 01-3.054 1.937 3.373 3.373 0 01-3.37-3.367v-6.618l2.911 1.68v4.153a.46.46 0 00.23.397l2.596 1.498zm-6.865-.345a3.382 3.382 0 01-2.921-1.683 3.342 3.342 0 01-.336-2.555 3.344 3.344 0 011.57-2.045l1.906-1.1v6.617c0 .254.022.506.067.755-.095.008-.19.011-.286.011zm7.418-8.178l-2.91 1.678-3.6-2.076a.46.46 0 00-.458 0L5.349 10.89c-.22.127-.428.272-.621.435a3.35 3.35 0 01-.154-3.611 3.384 3.384 0 012.923-1.685c.587 0 1.168.157 1.68.452l5.736 3.31zm-2.92-6.362c1.859 0 3.37 1.51 3.37 3.368v2.2L9.63 5.688a4.276 4.276 0 00-.688-.32 3.351 3.351 0 013.052-1.94zm7.43 4.286a3.37 3.37 0 01-1.234 4.6l-5.736 3.309v-3.358l3.6-2.077a.459.459 0 00.23-.397V6.797c0-.253-.023-.505-.066-.754a3.383 3.383 0 013.206 1.672z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="app-chromatic_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgAppChromatic;
