import { useTranslation } from 'next-i18next';
import React, { FC, PropsWithChildren } from 'react';

import { Alert } from '../../common/components/Alert';
import { VideoRecorderStates } from '../hooks/useVideoRecorderState';
import { VideoPermissionsPrompt } from './VideoRecorder/VideoAlert';

type VideoRecorderErrorWrapperProps = {
  state: VideoRecorderStates;
  onRequestPermissions: () => void;
  onDismiss: () => void;
};

/**
 * Wrapper component that displays standard error UI for video recorders
 */
export const VideoRecorderErrorWrapper: FC<
  PropsWithChildren<VideoRecorderErrorWrapperProps>
> = ({ state, onDismiss, children, onRequestPermissions }) => {
  if (state === VideoRecorderStates.PERMISSIONS_PROMPT) {
    return (
      <VideoPermissionsPrompt
        onDismiss={onDismiss}
        onRequestPermissions={onRequestPermissions}
      />
    );
  }

  if (state === VideoRecorderStates.PERMISSIONS_DENIED) {
    return <VideoPermissionsDenied onDismiss={onDismiss} />;
  }

  if (state === VideoRecorderStates.UNSUPPORTED) {
    return <VideoUnsupportedBrowserError onDismiss={onDismiss} />;
  }

  if (state === VideoRecorderStates.NO_CAMERA) {
    return <VideoNoCameraError onDismiss={onDismiss} />;
  }

  return <>{children}</>;
};

export type VideoPermissionsDeniedProps = {
  onDismiss: () => void;
};

/**
 * Displayed when the user explicitly denies access to their camera
 */
const VideoPermissionsDenied: React.FC<VideoPermissionsDeniedProps> = ({
  onDismiss,
}) => {
  const { t } = useTranslation('video');

  return (
    <Alert
      icons={['mic-no', 'camera-no']}
      variant="warning"
      headingText={t('video.error_access_denied')}
      descriptionText={t('video.error_access_denied_explanation')}
      onDismiss={onDismiss}
    />
  );
};

export type VideoUnsupportedBrowserErrorProps = {
  onDismiss: () => void;
};

/**
 * Displayed if we can't open the users media stream
 */
const VideoUnsupportedBrowserError: React.FC<
  VideoUnsupportedBrowserErrorProps
> = ({ onDismiss }) => {
  const { t } = useTranslation('video');

  return (
    <Alert
      icons={['mic-no', 'camera-no']}
      headingText={t('video.error_unsupported_browser')}
      descriptionText={t('video.error_unsupported_browser_explanation')}
      onDismiss={onDismiss}
    />
  );
};

export type VideoNoCameraErrorProps = {
  onDismiss: () => void;
};

/**
 * Displayed if we can't open the users media stream
 */
const VideoNoCameraError: React.FC<VideoNoCameraErrorProps> = ({
  onDismiss,
}) => {
  const { t } = useTranslation('video');

  return (
    <Alert
      icons={['mic-no', 'camera-no']}
      headingText={t('video.error_no_camera')}
      descriptionText={t('video.error_no_camera_explanation')}
      onDismiss={onDismiss}
    />
  );
};
