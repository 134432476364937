import * as React from "react";
import { SVGProps } from "react";
const SvgCustomerForum = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5 3.603c-6.678 0-8.334 10.782-8.334 10.782 0 1.92.891 3.6 2.758 4.047 1.6.381 3.611.735 5.575.735s3.977-.354 5.575-.736c1.867-.446 2.759-2.127 2.759-4.047 0 0-1.656-10.781-8.334-10.781Z"
      fill="url(#customer-forum_svg__a)"
    />
    <path
      d="M10.5 3.222c2.878 0 5.833 1.166 5.833 7.388v2.418c0 2.222-3.573 5.305-5.834 5.305-2.26 0-5.833-3.083-5.833-5.305v-2.417c0-6.223 2.955-7.39 5.833-7.39Z"
      fill="url(#customer-forum_svg__b)"
    />
    <path
      d="M10.5 1.25c-3.326 0-4.167 2.083-4.167 2.083-1.352.2-4.167 2.074-4.167 11.044 0 2.437 1.22 3.331 1.667 3.592C.599 8.464 8.008 9.564 6.906 4.272c3.175 7.056 13.177 1.978 10.562 13.474.773-.588 1.365-1.767 1.365-3.369 0-8.85-3.59-13.127-8.334-13.127Z"
      fill="url(#customer-forum_svg__c)"
    />
    <path
      d="M16.333 16.667H10.5v-.833h5.833c.69 0 1.25-.561 1.25-1.25v-2.917h.834v2.917a2.086 2.086 0 0 1-2.084 2.083Z"
      fill="#727D86"
    />
    <path
      d="M11.75 16.25a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Z"
      fill="url(#customer-forum_svg__d)"
    />
    <path
      d="M10.083 1.666h.833a6.666 6.666 0 0 1 6.667 6.667v2.917h.833V8.333a7.5 7.5 0 0 0-7.5-7.5h-.833a.417.417 0 0 0 0 .833Z"
      fill="#727D86"
    />
    <path
      d="m18.416 9.583.59.59a.834.834 0 0 1 .243.588v2.227c0 .22-.088.433-.244.589l-.589.59V9.582Z"
      fill="#5C6369"
    />
    <path
      d="M18.417 14.166h-1.25a.833.833 0 0 1-.833-.833v-2.917c0-.46.373-.833.833-.833h1.25v4.583Z"
      fill="#727D86"
    />
    <path
      d="M18.417 9.583h-.833v4.583h.833V9.583Z"
      fill="url(#customer-forum_svg__e)"
    />
    <defs>
      <radialGradient
        id="customer-forum_svg__a"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.34 11.648) scale(8.89083)"
      >
        <stop offset={0.376} stopColor="#261103" />
        <stop offset={0.68} stopColor="#431D06" />
        <stop offset={1} stopColor="#662C09" />
      </radialGradient>
      <radialGradient
        id="customer-forum_svg__b"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(180 5.253 5.389) scale(6.74998)"
      >
        <stop stopColor="#FFCF54" />
        <stop offset={0.261} stopColor="#FDCB4D" />
        <stop offset={0.639} stopColor="#F7C13A" />
        <stop offset={1} stopColor="#F0B421" />
      </radialGradient>
      <radialGradient
        id="customer-forum_svg__c"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.938 1.554) scale(20.7321)"
      >
        <stop stopColor="#C26715" />
        <stop offset={0.508} stopColor="#B85515" />
        <stop offset={1} stopColor="#AD3F16" />
      </radialGradient>
      <linearGradient
        id="customer-forum_svg__d"
        x1={9.616}
        y1={15.366}
        x2={11.384}
        y2={17.134}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#33BEF0" />
        <stop offset={1} stopColor="#0A85D9" />
      </linearGradient>
      <linearGradient
        id="customer-forum_svg__e"
        x1={16.646}
        y1={10.521}
        x2={19.355}
        y2={13.229}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#33BEF0" />
        <stop offset={1} stopColor="#0A85D9" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgCustomerForum;
