import { FC } from 'react';

import { GroupWithMembersFragment } from '../../../../../../generated/graphql-request-api-sdk';
import { TabbedModalContentInset } from '../../../../common/components/TabbedModal';
import { GroupMemberList } from '../../GroupMemberList';

export const GroupEditMembersTab: FC<{
  group: Pick<
    GroupWithMembersFragment,
    'members' | 'isPrivate' | 'id' | 'createdBy' | 'membership'
  >;
  invitable?: boolean;
}> = ({ group, invitable = true }) => {
  return (
    <TabbedModalContentInset>
      <GroupMemberList group={group} searchable invitable={invitable} />
    </TabbedModalContentInset>
  );
};
