import * as React from "react";

function SvgRhino(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.319 36.875H18.75a3.125 3.125 0 01-2.5-1.25l-5.918-7.719a8.125 8.125 0 01-1.67-4.644L8.4 15.92a8.156 8.156 0 012.832-6.463 8 8 0 013.306-1.712 7.957 7.957 0 011.987-.244h7.225c.57-.001 1.137.058 1.694.175.25.056.481.119.719.187a8.006 8.006 0 012.744 1.475l.375.332a8.162 8.162 0 012.593 5.956v7.306A8.125 8.125 0 0130 28.125l-6.25 7.612a3.125 3.125 0 01-2.431 1.138z"
        fill="#212121"
      />
      <path
        d="M16.525 8.75a6.705 6.705 0 00-1.668.206c-.194.05-.394.113-.588.175a6.736 6.736 0 00-2.213 1.25c-.124.113-.25.219-.368.338a6.914 6.914 0 00-2.038 5.13l.263 7.345a6.874 6.874 0 001.412 3.93l5.925 7.745a1.875 1.875 0 001.5.756h2.581a1.873 1.873 0 001.45-.681l6.276-7.644a6.875 6.875 0 001.568-4.375v-7.3a6.807 6.807 0 00-2.187-5l-.313-.325a6.75 6.75 0 00-4.375-1.55h-7.225z"
        fill="#BDBDBD"
      />
      <path
        d="M11.644 10.556a.626.626 0 01-.35-.106A4.963 4.963 0 019.42 4.575l.263-.706a1.138 1.138 0 012.025-.213L14.6 8.2a.625.625 0 01-.325.925 6.744 6.744 0 00-2.22 1.25.625.625 0 01-.412.181z"
        fill="#212121"
      />
      <path
        d="M10.77 4.519l-.182.48a3.75 3.75 0 001.044 4.126c.464-.35.965-.647 1.493-.888L10.77 4.52z"
        fill="#757575"
      />
      <path
        d="M20 32.387a3.274 3.274 0 01-3.081-4.375l2.5-9.425a.625.625 0 011.206 0l2.5 9.475A3.275 3.275 0 0120 32.387z"
        fill="#212121"
      />
      <path
        d="M20 21.206l-1.875 7.175a2.044 2.044 0 00.625 2.306 2.02 2.02 0 003.125-2.25L20 21.207z"
        fill="#616161"
      />
      <path
        d="M14.375 21.25a.625.625 0 01-.625-.625v-1.25a.625.625 0 111.25 0v1.25a.625.625 0 01-.625.625zm11.25 0a.625.625 0 01-.625-.625v-1.25a.625.625 0 111.25 0v1.25a.625.625 0 01-.625.625zm2.881-10.8a.625.625 0 01-.393-.144 6.757 6.757 0 00-2.32-1.25.625.625 0 01-.343-.931l2.85-4.469a1.138 1.138 0 012.025.206l.262.713a5 5 0 01-1.712 5.75.625.625 0 01-.369.125z"
        fill="#212121"
      />
      <path
        d="M26.919 8.125c.554.234 1.08.53 1.569.881A3.75 3.75 0 0029.413 5l-.182-.494-2.312 3.619z"
        fill="#757575"
      />
    </svg>
  );
}

export default SvgRhino;
