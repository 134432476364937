import { routes } from '@frond/shared';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { EventCoreFragment } from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { Button } from '../../common/components/Button';

export const EventDetailsButton: React.FC<{
  event: EventCoreFragment;
}> = ({ event }) => {
  const { t } = useTranslation(['events']);
  const { organization } = useOrganization();
  return (
    <Button
      variant="secondary"
      href={routes.groups.organization(organization.shortId).event(event.id)}
      label={t('events.view_event_details')}
    />
  );
};
