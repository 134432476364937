import { SystemProps, x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { FC, PropsWithChildren, ReactElement } from 'react';

import { Avatar } from '../Avatar';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Text } from '../Text';
import {
  AudienceSelection,
  AudienceSelectionEmail,
  AudienceSelectionEveryone,
  AudienceSelectionUser,
} from './AudienceSelectorTypes';

type AudienceSelectorTokenProps = {
  audienceSelection: AudienceSelection;
  onRemove?: () => void;
  variant?: 'transparent';
};

/**
 * Token shown inside the Audience Selector input
 */
export const AudienceSelectorToken: FC<AudienceSelectorTokenProps> = ({
  audienceSelection,
  onRemove,
  variant,
}) => {
  const { type } = audienceSelection;

  let children;

  if (type === 'email') {
    children = <ASTEmail as={audienceSelection} onRemove={onRemove} />;
  }
  if (type === 'user') {
    children = <ASTUser as={audienceSelection} onRemove={onRemove} />;
  }

  if (type === 'everyone') {
    children = <ASTEveryone as={audienceSelection} onRemove={onRemove} />;
  }

  if (variant === 'transparent' && children) return children;

  return <ASTStyle audienceSelection={audienceSelection}>{children}</ASTStyle>;
};

/*****
 * Sub-components
 */

const ASTEveryone: FC<{
  as: AudienceSelectionEveryone;
  onRemove?: () => void;
}> = ({ onRemove }) => {
  const { t } = useTranslation();
  return <ASTLayout label={t('everyone')} onRemove={onRemove} />;
};

const ASTUser: FC<{
  as: AudienceSelectionUser;
  onRemove?: () => void;
}> = ({ as, onRemove }) => {
  const { avatar, avatarBgColor, profileImageId, name } = as.payload;

  const image = (
    <Avatar
      avatar={avatar}
      bgColor={avatarBgColor}
      imageId={profileImageId}
      size="s"
    />
  );
  return <ASTLayout label={name} image={image} onRemove={onRemove} />;
};

const ASTEmail: FC<{
  as: AudienceSelectionEmail;
  onRemove?: () => void;
}> = ({ as, onRemove }) => {
  const email = as.payload;

  return <ASTLayout label={email} onRemove={onRemove} />;
};

export const ASTStyle: FC<
  PropsWithChildren<
    {
      audienceSelection: AudienceSelection;
    } & SystemProps
  >
> = (props) => {
  const { audienceSelection, children } = props;

  let image;

  const { type } = audienceSelection;

  if (type === 'user') {
    const { profileImageId } = audienceSelection.payload;
    image = profileImageId;
  }

  return (
    <x.span
      display="inline-block"
      mt="0.625rem"
      mr={2}
      p={1.5}
      pr={3}
      pl={image ? 1.5 : 3}
      backgroundColor={'gray.100'}
      borderRadius={'sm-md'}
      h={9}
      boxSizing="border-box"
      {...props}
    >
      {children}
    </x.span>
  );
};

const ASTLayout: FC<{
  label: string | ReactElement;
  image?: ReactElement;
  onRemove?: () => void;
}> = ({ label, image, onRemove }) => {
  return (
    <>
      <x.span display="flex" alignItems="center" spaceX={2}>
        {image}
        <Text as="span" variant="md">
          {label}
        </Text>
        {onRemove && (
          <Button
            variant="icon"
            onClick={onRemove}
            leftElement={
              <Icon
                name="close"
                size="4"
                color={{ _: 'gray.300', hover: 'red.300' }}
                cursor="pointer"
              />
            }
          />
        )}
      </x.span>
    </>
  );
};
