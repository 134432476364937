import { theme } from '@frond/shared';
import * as Popover from '@radix-ui/react-popover';
import styled, {
  css,
  system,
  SystemProps,
  x,
} from '@xstyled/styled-components';
import { th } from '@xstyled/system';
import { BaseEmoji, Picker } from 'emoji-mart';
import { useTranslation } from 'next-i18next';
import React, {
  MutableRefObject,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { DISABLE_EMOJI_SPRITE_SHEET } from '../../../config/constants';
import { SignInModal } from '../../auth/components/SignIn/SignInModal';
import { useViewer } from '../../auth/hooks/useViewer';
import analytics from '../utils/analytics';
import { reactionCreatedByUsernamesWithEmojiColons } from '../utils/reactions';
import { Reaction } from '../utils/types';
import { getUserDisplayName } from '../utils/user';
import { ButtonProps } from './Button';
import { Emoji } from './Emoji';
import { Icon } from './Icon';
import { NextLink } from './NextLink';
import { Text } from './Text';
import { Tooltip } from './Tooltip';

export const createdByUserNames = (reaction: Reaction) => {
  return reaction.createdByUsers?.map((user) => {
    return getUserDisplayName(user, 'short');
  });
};

export interface ReactionButtonProps {
  /**
   * Unicode for emoji
   */
  reaction: Reaction;
  /**
   * Handler for click event
   */
  onClick?: (emojiUnicode: string) => void;
  /**
   * Whether to show the tooltip. Used for Chromatic.
   */
  showTooltip?: boolean;
  location?: 'primary' | 'secondary' | 'tertiary'; // Calling it location to avoid button variant conflict
}

const StyledText = styled(Text).attrs({
  variant: 'sm-medium',
  forwardedAs: 'span',
})`
  color: gray.300;
`;

const reactionButtonMixin = css`
  display: flex;
  align-items: center;
  justify-content: center;

  border: default;
  border-radius: sm-md;
  outline: none;
  cursor: pointer;

  padding: calc(${theme.sizes['1.5']} - 1px) ${theme.sizes['2']};
  background-color: rgba(255, 255, 255, 0.8);
  border-color: transparent;
  transition-property: color, border-color;
  transition-duration: fast;

  &:hover {
    border-color: brand.300;
    color: brand.300;
  }

  height: 7;
  min-width: 7;

  @media (min-width: sm) {
    height: 8;
    min-width: 8;
  }
`;

const reactionButtonReactedMixin = css`
  ${StyledText} {
    color: gray.500;
  }

  border-color: yellow.300;
`;

const reactionButtonCardMixin = css`
  background-color: whiteAlpha.400;
  border-radius: md;

  &:hover {
    background-color: white;
  }
`;

const reactionButtonTertiaryCardMixin = css`
  background-color: gray.50;
  border-radius: md;

  &:hover {
    background-color: gray.50;
  }
`;

const reactionButtonTertiaryReactedCardMixin = css`
  background-color: yellow.50;

  &:hover {
    border-color: yellow.300;
  }
`;

/**
 * Primary component for a reaction
 */
const StyledReactionButton = styled.button<
  Omit<ReactionButtonProps, 'onClick'> & ButtonProps
>`
  ${reactionButtonMixin}
  ${(p) => p.reaction.hasReacted && reactionButtonReactedMixin}
  ${(p) => p.location === 'secondary' && reactionButtonCardMixin}
  ${(p) => p.location === 'tertiary' && reactionButtonTertiaryCardMixin}
  ${(p) =>
    p.reaction.hasReacted &&
    p.location === 'tertiary' &&
    reactionButtonTertiaryReactedCardMixin}
`;

const ReactionWrapper = styled.div`
  margin-right: 1;
  margin-bottom: ${theme.sizes[0.5]};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${theme.sizes[4.5]};
  height: ${theme.sizes[4]};
`;

type ReactionTooltipProps = {
  /**
   * Unicode for emoji
   */
  reaction: Reaction;
  /**
   * Children element
   */
  children: JSX.Element;
  /**
   * Whether to show the tooltip. Used for Chromatic.
   */
  showTooltip?: boolean;
};

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextWrapper = styled.div`
  margin-top: 2;
  white-space: normal;
  text-align: center;
`;

const ReactionTooltip: React.FC<ReactionTooltipProps> = ({
  reaction,
  children,
  showTooltip,
}) => {
  const { t } = useTranslation();

  const joinedNames = createdByUserNames(reaction)?.join(', ') || '';

  return (
    <Tooltip
      visible={showTooltip}
      variant={'light'}
      label={
        <TooltipWrapper>
          <Emoji emojiUnicode={reaction.emojiUnicode} size={28} mb={2} />
          <TextWrapper>
            <Text as="span" variant="sm">
              <Text as="span" variant="sm-semibold">
                {joinedNames}
              </Text>{' '}
              {t('reacted')}
            </Text>
          </TextWrapper>
        </TooltipWrapper>
      }
      {...(!DISABLE_EMOJI_SPRITE_SHEET
        ? {
            'aria-label': reactionCreatedByUsernamesWithEmojiColons(
              [reaction],
              t('reacted_with')
            ),
          }
        : {
            'aria-label': joinedNames,
          })}
    >
      {children}
    </Tooltip>
  );
};

export const ReactionButton: React.FC<ReactionButtonProps> = ({
  location = 'primary',
  ...props
}) => {
  const [showSignInModal, setShowSignInModal] = useState(false);
  const { viewer: user } = useViewer();
  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (user) {
      props.onClick && props.onClick(props.reaction.emojiUnicode);
    } else {
      setShowSignInModal(true);
    }
  };

  if (props.reaction.numReactions === 0) return null;

  return (
    <>
      <SignInModal
        isOpen={showSignInModal}
        onDismiss={() => setShowSignInModal(false)}
        variant="public"
      />
      <ReactionTooltip
        reaction={props.reaction}
        showTooltip={props.showTooltip}
      >
        <StyledReactionButton {...props} location={location} onClick={onClick}>
          <ReactionWrapper>
            <Emoji emojiUnicode={props.reaction.emojiUnicode} size={18} />
          </ReactionWrapper>
          <Text variant="sm" color="gray.500">
            {props.reaction.numReactions}
          </Text>
        </StyledReactionButton>
      </ReactionTooltip>
    </>
  );
};

export interface AddReactionButtonProps {
  /**
   * Handler for click event on emoji in picker
   */
  onReactionSelected: (emojiUncode: string) => void;
  /**
   * Reference to parent container for positioning of picker
   */
  parentRef?: MutableRefObject<HTMLElement | null>;

  variant?: 'primary' | 'secondary' | 'tertiary';
  onTogglePicker?: (isOpen: boolean) => void;
}

const StyledAddReactionButton = styled.buttonBox<
  SystemProps & {
    variant?: 'primary' | 'secondary' | 'tertiary';
  }
>`
  ${reactionButtonMixin};
  background-color: rgba(255, 255, 255, 0.8);
  border: default;

  border-color: transparent;
  font-size: 1.25rem;
  color: gray.300;
  border-radius: sm-md;

  &[data-state='open'] {
    border-color: brand.300;
    color: brand.300;
  }

  &:hover {
    color: brand.300;
    background-color: white;
  }

  ${(props) =>
    props.variant === 'secondary' &&
    css`
      border-color: transparent;
      background-color: white;
      color: gray.300;

      &:hover {
        background-color: white;
      }

      &[data-state='open'] {
        background-color: white;
      }
    `}

  ${(props) =>
    props.variant === 'tertiary' &&
    css`
      background-color: gray.50;

      &:hover {
        background-color: gray.50;
      }
    `}

  ${system};
`;

/**
 * Primary component for adding a reaction
 */
export const AddReactionButton: React.FC<
  AddReactionButtonProps & SystemProps
> = ({ onReactionSelected, variant, onTogglePicker, ...props }) => {
  const [openPicker, setOpenPicker] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const { viewer: user } = useViewer();
  const closePicker = () => {
    onTogglePicker?.(false);
    setOpenPicker(false);
  };

  const onPickerClick = (
    emoji: BaseEmoji,
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.preventDefault();
    closePicker();
    onReactionSelected && onReactionSelected(emoji.native);
  };

  return (
    <>
      <SignInModal
        isOpen={showSignInModal}
        onDismiss={() => setShowSignInModal(false)}
        variant="public"
      />
      <Popover.Root open={openPicker}>
        <Popover.Trigger asChild>
          <StyledAddReactionButton
            onClick={() => {
              if (user) {
                setOpenPicker(true);
                onTogglePicker?.(true);
              } else {
                setShowSignInModal(true);
              }
            }}
            data-test="add-reaction-button"
            variant={variant}
            {...props}
          >
            <Icon name="reaction-add" size="4.5" />
          </StyledAddReactionButton>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            onEscapeKeyDown={closePicker}
            onInteractOutside={closePicker}
            align="start"
            alignOffset={-4}
          >
            <x.div p={1} onClick={(e) => e.preventDefault()}>
              <Picker
                title={''} // Hide title in preview
                emoji={''} // Hide default emoji in preview
                color={`${th.color('brand.300')}`}
                onClick={onPickerClick}
                native
                autoFocus
              />
            </x.div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </>
  );
};
AddReactionButton.displayName = 'AddReactionButton';

export interface MoreReactionsButtonProps {
  /**
   * Reactions to show in tooltip
   */
  reactions: Reaction[];
  /**
   * Whether to show the tooltip. Used for Chromatic.
   */
  showTooltip?: boolean;
}

const StyledMoreReactionButton = styled.button`
  ${reactionButtonMixin};
  color: gray.300;
`;

const MoreReactionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2;

  > ${StyledReactionButton} {
    margin-right: 1;
    margin-bottom: 1;
  }
`;

const MoreReactionButton = styled(ReactionButton)`
  ${StyledText} {
    color: gray.300;
  }

  background-color: gray.400;
  border-color: gray.400;
`;

export const MoreReactionsButton: React.FC<MoreReactionsButtonProps> = ({
  reactions,
  showTooltip,
}) => {
  const { t } = useTranslation();

  const names = useMemo(
    () =>
      reactions.reduce((accumulator: string[], reaction: Reaction) => {
        const names: string[] = [];
        reaction.createdByUsers?.map((user) => {
          const name = getUserDisplayName(user, 'short');
          if (!accumulator.includes(name)) {
            names.push(name);
          }
        });

        return [...accumulator, ...names];
      }, []),
    [reactions]
  );

  const joinedNames = names?.join(', ');

  return (
    <Tooltip
      visible={showTooltip}
      variant={'light'}
      label={
        <>
          <MoreReactionsWrapper>
            {reactions.map((reaction) => {
              return (
                <MoreReactionButton
                  key={reaction.emojiUnicode}
                  reaction={reaction}
                />
              );
            })}
          </MoreReactionsWrapper>
          <Text as="span" variant="sm-semibold">
            {joinedNames}
          </Text>
        </>
      }
      {...(!DISABLE_EMOJI_SPRITE_SHEET
        ? {
            'aria-label': reactionCreatedByUsernamesWithEmojiColons(
              reactions,
              t('reacted_with')
            ),
          }
        : {
            'aria-label': joinedNames,
          })}
    >
      <StyledMoreReactionButton>
        <StyledText>{`+${reactions.length}`}</StyledText>
      </StyledMoreReactionButton>
    </Tooltip>
  );
};

const ReactionsWrapper = styled.div`
  display: flex;
  position: relative; /* For absolute positioning of picker */
  flex-wrap: wrap;

  ${StyledReactionButton}, ${StyledMoreReactionButton} {
    margin-right: 1;
  }
`;

export interface ReactionsProps {
  /**
   * Reactions
   */
  reactions?: Reaction[];
  /**
   * Callback for click on a reaction
   */
  onReactionClick: (emojiUnicode: string) => void;
  showAddReactionButton?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary';
}

/**
 * Primary component for a list of reactions
 */
export const Reactions: React.FC<ReactionsProps> = ({
  reactions,
  onReactionClick,
  variant = 'primary',
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  return (
    <x.div
      position="relative"
      display="flex"
      flexWrap="wrap"
      gap={variant === 'primary' ? 1 : 2}
      ref={parentRef}
    >
      {reactions?.map((reaction: Reaction) => {
        return (
          <ReactionButton
            key={reaction.emojiUnicode}
            location={variant}
            {...{
              reaction,
              onClick: onReactionClick,
            }}
          />
        );
      })}
      <AddReactionButton
        parentRef={parentRef}
        onReactionSelected={onReactionClick}
        variant={variant}
      />
    </x.div>
  );
};

export type ReactionsCollapsableProps = ReactionsProps & {
  href: string;
  maxWidth?: number | null;
  showTooltip?: boolean;
};

/**
 * Reactions component collapsing when reaching max width
 */
export const ReactionsCollapsable: React.FC<ReactionsCollapsableProps> = ({
  href,
  maxWidth,
  reactions,
  onReactionClick,
  showTooltip,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const addReactionButtonRef = useRef<HTMLDivElement>(null);
  const moreReactionButtonRef = useRef<HTMLDivElement>(null);
  const [maxIndex, setMaxIndex] = useState<number | null>(null);

  // Calculate max index of element to show if wrapper does not fit all reactions
  useLayoutEffect(() => {
    setMaxIndex(null); // Reset maxIndex to re-render reactions

    if (parentRef.current && maxWidth) {
      let width = 0;
      let lastElementIndex = 0;
      const children = parentRef.current.children;

      // Children excluding last element since it is the add reaction button
      for (let i = 0; i < children.length; i++) {
        const child = children[i] as HTMLElement;

        // Increase width by child width and margin
        const margin = parseInt(window.getComputedStyle(child).marginRight);
        width += child.offsetWidth + margin;

        // Determine index of last element to show
        const addReactionWidth = addReactionButtonRef.current?.offsetWidth;
        if (addReactionWidth && width <= maxWidth - addReactionWidth) {
          lastElementIndex = i;

          // Check if more button fits
          const moreReactionsWidth = moreReactionButtonRef.current?.offsetWidth;
          if (moreReactionsWidth && width - moreReactionsWidth > maxWidth) {
            lastElementIndex -= 1;
          }
        }
      }

      setMaxIndex(lastElementIndex);
    }
  }, [
    parentRef,
    addReactionButtonRef,
    moreReactionButtonRef,
    maxWidth,
    reactions,
  ]);

  return (
    <ReactionsWrapper ref={parentRef}>
      {reactions?.map((reaction: Reaction, i: number) => {
        if (!maxIndex || (maxIndex && i < maxIndex)) {
          return (
            <ReactionButton
              key={reaction.emojiUnicode}
              {...{
                reaction,
                onClick: onReactionClick,
              }}
            />
          );
        }
      })}
      {maxIndex !== null &&
        maxIndex > 0 &&
        reactions &&
        maxIndex < reactions.length && (
          <div ref={moreReactionButtonRef}>
            <NextLink
              href={href}
              metadata={{
                event: analytics.events.UPDATE_LINK,
                context: 'reaction',
              }}
            >
              <MoreReactionsButton
                reactions={reactions.slice(maxIndex, reactions.length)}
                showTooltip={showTooltip}
              />
            </NextLink>
          </div>
        )}
      <div ref={addReactionButtonRef}>
        <AddReactionButton
          onReactionSelected={onReactionClick}
          parentRef={parentRef}
        />
      </div>
    </ReactionsWrapper>
  );
};
