import styled from '@xstyled/styled-components';
import { th } from '@xstyled/system';
import React from 'react';

import { Icon } from '../../../common/components/Icon';
import { Text } from '../../../common/components/Text';

export interface SetupAvailabilityLabelProps {
  success: boolean;
  children: string;
}

const Container = styled.div<{ success: boolean }>`
  display: flex;
  align-self: flex-end;
  margin-bottom: 3;
  margin-left: 4;
  color: ${(p) => (p.success ? th('colors.green.300') : th('colors.red.300'))};
`;

const PositionedIcon = styled(Icon)`
  margin-right: 2;
  margin-top: 2px;
`;

/**
 * Form for creating an Organization entity on first user login
 */
export const SetupAvailabilityLabel: React.FC<SetupAvailabilityLabelProps> = ({
  success,
  children,
}) => {
  const icon = success ? 'circle-checked' : 'circle-x';

  return (
    <Container success={success} data-test="org-sub-availability">
      <PositionedIcon name={icon} />
      <Text as="span" variant="sm-medium">
        {children}
      </Text>
    </Container>
  );
};
