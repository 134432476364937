import { routes } from '@frond/shared';
import { useTranslation } from 'next-i18next';

import {
  useDirectMessageUserThreadQuery,
  UserQuery,
} from '../../../../../generated/types-and-hooks';
import { useOrganization } from '../../../auth/hooks/useOrganization';
import { Button } from '../../../common/components/Button';

export const DirectMessageSendMessageButton: React.FC<{
  user: NonNullable<UserQuery['user']>;
}> = ({ user }) => {
  const { t } = useTranslation();
  const { organization } = useOrganization();

  const { data, loading } = useDirectMessageUserThreadQuery({
    variables: {
      username: user.username,
      organizationId: organization.id,
    },
  });

  const commonProps = {
    loading,
    label: t('send_message_to', { userName: user.firstName || user.username }),
  };

  const messageId = data?.directMessageUserThread?.id;

  if (!messageId) {
    return (
      <Button
        {...commonProps}
        href={routes.groups
          .organization(organization.shortId)
          .newMessage(user.username)}
      />
    );
  }

  return (
    <Button
      {...commonProps}
      href={routes.groups.organization(organization.shortId).message(messageId)}
    />
  );
};
