import { theme } from '@frond/shared';
import styled, { css, x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  DEFAULT_MAX_FILESIZE,
  useFileUpload,
  UseFileUploadProps,
} from '../hooks/useFileUpload';
import { dashedBorderSVGString, svgStringToImageUrl } from '../utils';
import { UploadError } from '../utils/cloudinary';
import { Button, ButtonProps } from './Button';
import { ImageCropper } from './ProfileAvatarInput';
import { Toast } from './Toast';

export type FileUploaderProps = UseFileUploadProps & {
  /**
   * Button label
   */
  label?: string;
  /**
   * Button label to use while the component is in an uploading state
   */
  activeLabel?: string;
  /**
   * Optional custom button component
   */
  as?: React.FC<ButtonProps>;
  /**
   * Optionally center the input
   */
  centered?: boolean;
  /**
   * Optional secondary button for video upload etc
   */
  secondaryButton?: React.ReactElement<ButtonProps>;
  /**
   * Which file type to accept
   */
  accept?: string;
};

const Label = styled.label<{ centered?: boolean }>`
  cursor: pointer;
  display: block;

  ${(p) =>
    p.centered &&
    css`
      display: flex;
      justify-content: center;
    `}
`;

/**
 * Component for making a file input that looks like one of our buttons
 */
export const FileUploader: React.FC<PropsWithChildren<FileUploaderProps>> = ({
  label,
  onUploadSuccess,
  onUploadProgress,
  onUploadFailure,
  activeLabel = 'Uploading',
  children,
  as,
  centered,
  secondaryButton,
  name,
  accept = 'image/*',
}) => {
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [fileToCrop, setFileToCrop] = useState<File | null>(null);
  const { t } = useTranslation();
  const { loading, id, uploadSelectedFile } = useFileUpload({
    onUploadSuccess,
    name,
    accept,
    onUploadProgress,
    onUploadFailure: (e) => {
      onUploadFailure?.(e);
      setUploadError(e.message);
    },
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    accept: { ['image/*']: [] },
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFileToCrop(acceptedFiles[0]);
      }
    },
  });

  const displayLabel = (
    <Label centered={centered} htmlFor={id}>
      {loading ? activeLabel : label}
    </Label>
  );

  const dropTarget = (
    <x.div
      backgroundImage={`url("${svgStringToImageUrl(
        dashedBorderSVGString({
          color: `${theme.colors.brand[300]}`,
          dasharray: [`${theme.sizes['3']}`, `${theme.sizes['4']}`],
          radius: 'lg',
        })
      )}")`}
      borderRadius="lg"
      boxSizing="border-box"
      position="absolute"
      w="100%"
      h="100%"
    />
  );

  const wrappedChildren = children && (
    <Label centered={centered} htmlFor={id}>
      <x.div position="relative">
        {isDragActive && dropTarget}
        {children}
      </x.div>
    </Label>
  );
  const ButtonComponent = as || Button;

  return (
    <div {...getRootProps()}>
      {fileToCrop ? (
        <ImageCropper
          onDismiss={() => setFileToCrop(null)}
          isOpen={!!fileToCrop}
          file={fileToCrop}
          onUseImage={(image) => {
            uploadSelectedFile(image);
          }}
          variant="coverImage"
        />
      ) : null}
      {!!uploadError && (
        <Toast
          variant="error"
          message={uploadError}
          onDisappear={() => setUploadError(null)}
        />
      )}
      <input
        {...getInputProps()}
        id={id}
        onChange={(e) => {
          if (e.target?.files) {
            // Check size
            if (e.target?.files[0].size > DEFAULT_MAX_FILESIZE) {
              onUploadFailure?.(
                new UploadError(t('error_file_size_exceeded', { max: '15mb' }))
              );
              return;
            }

            setFileToCrop(e.target?.files[0]);
          }
        }}
      />
      {wrappedChildren}
      {label && (
        <x.div
          display="flex"
          flexDirection="row"
          alignContent="flex-start"
          spaceX="2"
        >
          <Label centered={centered} htmlFor={id}>
            <ButtonComponent
              type="button"
              variant="secondary"
              label={displayLabel}
              loading={loading}
            />
          </Label>
          {secondaryButton}
        </x.div>
      )}
    </div>
  );
};
