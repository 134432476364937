import emojiRegex from 'emoji-regex';
import * as yup from 'yup';

import { Organization } from '../../../../generated/types-and-hooks';

export const getUserOrganizationName = (
  organization: Pick<Organization, 'name' | 'domain'>
): string | undefined | null => {
  let organizationName;

  if (organization.name !== '') {
    organizationName = organization.name;
  } else if (organization.domain !== '') {
    const isIndividualOrganization = yup
      .string()
      .uuid()
      .isValidSync(organization.domain);
    if (!isIndividualOrganization) {
      organizationName = organization.domain;
    }
  }

  return organizationName;
};

export const getUserDisplayName = (
  user: {
    name?: string;
    firstName?: string;
    lastName?: string;
    username: string;
  },
  variant: 'default' | 'short' = 'default'
): string => {
  // John D. or John or john_doe
  if (variant === 'short') {
    const regex = emojiRegex();
    const emojiMatch = user.lastName ? user.lastName.search(regex) : null;
    const lastNameStartsWithAnEmoji = emojiMatch === 0;

    return user.firstName
      ? `${user.firstName}${
          lastNameStartsWithAnEmoji
            ? ` ${user.lastName}`
            : user.lastName
              ? ` ${user.lastName[0]}.`
              : ''
        }`
      : user.username;
  }

  // John Doe or john_doe
  return user.name || user.username;
};
