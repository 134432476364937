import { Menu, MenuButton } from '@reach/menu-button';
import { x } from '@xstyled/styled-components';
import { FC } from 'react';

import { useViewer } from '../../../auth/hooks/useViewer';
import { Avatar } from '../../../common/components/Avatar';
import { Icon } from '../../../common/components/Icon';
import { MenuList } from '../../../common/components/MenuList';
import { Text } from '../../../common/components/Text';
import { rightBottomAlign } from '../../../common/utils/position';
import { UserSettingsMenu } from '../../../settings/components/UserSettingsMenu';

export const GroupsSidebarUserSettingsMenu: FC = () => {
  const { viewer: user } = useViewer();
  if (!user) return null;

  return (
    <Menu>
      <MenuButton as="span" onClick={(event) => event.preventDefault()}>
        <x.div
          cursor="pointer"
          position="fixed"
          borderTop="default"
          borderColor="gray.100"
          bottom={0}
          px="4"
          py="5"
          backgroundColor="white"
          boxSizing="border-box"
          w="calc(17.5rem - 2px)"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <x.div display="flex" gap="2">
            <Avatar
              size="xs"
              bgColor={user.avatarBgColor}
              avatar={user.avatar}
              imageId={user.profileImageId}
            />
            <Text variant="sm" color="gray.300">
              {user.email}
            </Text>
          </x.div>
          <Icon
            name="chevron-left"
            size="3"
            color="gray.500"
            transform
            rotate={270}
          />
        </x.div>
      </MenuButton>
      <MenuList portal position={rightBottomAlign}>
        <UserSettingsMenu minWidth="3xs" />
      </MenuList>
    </Menu>
  );
};
