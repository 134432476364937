import * as React from "react";
import { SVGProps } from "react";
const SvgAnalytics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 1.667c-4.595 0-8.333 3.738-8.333 8.333 0 4.595 3.738 8.333 8.333 8.333 4.595 0 8.333-3.738 8.333-8.333 0-4.595-3.738-8.333-8.333-8.333Zm0 1.25A7.074 7.074 0 0 1 17.083 10 7.074 7.074 0 0 1 10 17.083 7.074 7.074 0 0 1 2.917 10 7.074 7.074 0 0 1 10 2.917Zm0 .625a.625.625 0 1 0 0 1.25.625.625 0 0 0 0-1.25ZM5.876 5.25a.625.625 0 1 0 0 1.25.625.625 0 0 0 0-1.25Zm8.248 0a.625.625 0 1 0 0 1.25.625.625 0 0 0 0-1.25Zm.25 2.756c-.954-.016-4.321 1.069-4.693 1.223a.833.833 0 0 0 .638 1.54c.425-.176 4.476-2.259 4.3-2.683-.022-.053-.108-.078-.245-.08ZM4.167 9.375a.625.625 0 1 0 0 1.25.625.625 0 0 0 0-1.25Zm11.666 0a.625.625 0 1 0 0 1.25.625.625 0 0 0 0-1.25Zm-9.957 4.124a.625.625 0 1 0 0 1.25.625.625 0 0 0 0-1.25Zm8.248 0a.625.625 0 1 0 0 1.25.625.625 0 0 0 0-1.25Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgAnalytics;
