import { routes } from '@frond/shared';
import styled, { x } from '@xstyled/styled-components';
import { SystemProps, th } from '@xstyled/system';
import { ReactElement } from 'react';

import { User } from '../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { getUserDisplayName } from '../utils/user';
import { Avatar, AvatarSizes } from './Avatar';
import { NextLink } from './NextLink';
import { Text } from './Text';
import { Tooltip } from './Tooltip';
import { TooltipVariant } from './Tooltip';

type UsersVariant = 'default' | 'small' | 'medium';

export type UsersProps = {
  users: Pick<
    User,
    | 'id'
    | 'profileImageId'
    | 'username'
    | 'firstName'
    | 'lastName'
    | 'avatar'
    | 'avatarBgColor'
  >[];
  variant?: UsersVariant;
  limit?: number;
  showTooltip?: boolean;
  showNameTooltips?: boolean;
  fallback?: ReactElement;
  showLink?: boolean;
  onMoreUsersClick?: () => void;
};

const AvatarWrapper = styled.div<{
  size: AvatarSizes;
}>`
  display: flex;
  flex-direction: row;
  border: none;
  flex-wrap: wrap;
  gap: 1;
`;

const MoreUsers = styled.div<{
  size: AvatarSizes;
}>`
  border: none;
  color: gray.500;
  background-color: brand.50;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${(p) => (p.size === 's' ? th.size('6') : th.size('7'))};
  height: ${(p) => (p.size === 's' ? th.size('6') : th.size('7'))};
  border-radius: ${(p) => (p.size === 's' ? '4px' : `${28 / 6}px`)};
`;

const avatarSizeByVariant: { [key: string]: AvatarSizes } = {
  default: 's-2',
  small: 's',
  medium: 'm',
};

export const Users: React.FC<UsersProps & SystemProps> = ({
  users,
  variant = 'default',
  limit = 5,
  showTooltip,
  showNameTooltips,
  fallback,
  showLink = true,
  onMoreUsersClick,
  ...props
}) => {
  const { organization } = useOptionalOrganization();
  const avatarSize = avatarSizeByVariant[variant];

  return (
    <AvatarWrapper size={avatarSize}>
      {users.length
        ? users.map((user, i) => {
            const child = (
              <Avatar
                size={avatarSize}
                imageId={user.profileImageId}
                avatar={user.avatar}
                bgColor={user.avatarBgColor}
                tooltipText={
                  showNameTooltips ? getUserDisplayName(user) : undefined
                }
              />
            );
            if (i < limit) {
              return showLink && organization ? (
                <NextLink
                  key={i}
                  href={routes.groups
                    .organization(organization.shortId)
                    .person(user.username)}
                >
                  {child}
                </NextLink>
              ) : (
                child
              );
            }
          })
        : fallback}
      <>
        {users.length > limit && variant === 'default' && (
          <UsersTooltip
            users={users}
            variant={variant}
            limit={limit}
            showTooltip={showTooltip}
          >
            <MoreUsers onClick={onMoreUsersClick} size={avatarSize}>
              <Text color="brand.300" variant="xs-semibold" {...props}>{`+${
                users.length - limit
              }`}</Text>
            </MoreUsers>
          </UsersTooltip>
        )}
      </>
    </AvatarWrapper>
  );
};

const AvatarTooltipWrapper = styled.div<{
  variant?: UsersVariant;
}>`
  display: flex;
  gap: 1;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const UsersTooltip: React.FC<
  UsersProps & {
    children: JSX.Element;
    tooltipVariant?: TooltipVariant;
    label?: string;
    showAvatars?: boolean;
    anonymousUsers?: number;
  }
> = ({
  users,
  limit,
  variant = 'default',
  children,
  showTooltip,
  fallback,
  label,
  showAvatars = true,
  anonymousUsers = 0,
}) => {
  const avatarSize = avatarSizeByVariant[variant];
  const maxWidth = variant === 'small' ? 160 : 260;
  const userNames = users?.map((user, i) => {
    if ((limit && i >= limit) || !limit) {
      return getUserDisplayName(user, 'short');
    }
  });

  return (
    <Tooltip
      variant="light"
      visible={showTooltip}
      disabled={users.length <= 0 && !fallback}
      maxWidth={maxWidth}
      label={
        <x.div display="flex" flexDirection="column" alignItems="center">
          {showAvatars && users.length > 0 ? (
            <AvatarTooltipWrapper variant={variant}>
              {users.map((user, i) => {
                if ((limit && i >= limit) || !limit) {
                  return (
                    <div key={i}>
                      <Avatar
                        size={avatarSize}
                        imageId={user.profileImageId}
                        avatar={user.avatar}
                        bgColor={user.avatarBgColor}
                      />
                    </div>
                  );
                }
              })}
            </AvatarTooltipWrapper>
          ) : users.length === 0 && anonymousUsers === 0 ? (
            fallback
          ) : null}

          {(users.length > 0 || anonymousUsers > 0) && label && (
            <Text as="span" variant="sm-semibold" mt={showAvatars ? 2 : 0}>
              {label}
            </Text>
          )}
        </x.div>
      }
      aria-label={`${userNames?.join(', ')}`}
    >
      {children}
    </Tooltip>
  );
};
