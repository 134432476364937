import * as React from "react";

function SvgDoc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 20a2 2 0 002 2h13.414L22 17.414V4a2 2 0 00-2-2H4a2 2 0 00-2 2v16zM4 4h16v11h-3a2 2 0 00-2 2v3H4V4zm13 13h2.586L17 19.586V17zM7 15v2h6v-2H7zm0-2v-2h10v2H7zm0-6v2h10V7H7z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDoc;
