import * as React from "react";
import { SVGProps } from "react";
const SvgAlumniNetwork = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.833 17.917H2.166v-7.5l8.333-1.667 8.334 1.667v7.5Z"
      fill="url(#alumni-network_svg__a)"
    />
    <path
      d="M17.435 8.333H3.564a.833.833 0 0 0-.774.524l-.624 1.56h16.667l-.624-1.56a.833.833 0 0 0-.774-.524Z"
      fill="url(#alumni-network_svg__b)"
    />
    <path
      d="m13.417 7.916-2.916-2.708-2.917 2.708v10h5.833v-10Z"
      fill="url(#alumni-network_svg__c)"
    />
    <path
      d="M13.397 8.495 10.501 5.78 7.604 8.495a.208.208 0 0 1-.294-.01l-.285-.303a.208.208 0 0 1 .01-.295l3.18-2.982a.417.417 0 0 1 .57 0l3.182 2.982a.208.208 0 0 1 .01.295l-.285.303a.207.207 0 0 1-.295.01Z"
      fill="#35C1F1"
    />
    <path
      d="M13.204 4.578c-1.356-.032-1.137-.38-2.497-.411a.21.21 0 0 1-.207-.208v-1.25a.209.209 0 0 1 .212-.209c1.356.033 1.138.38 2.497.412a.21.21 0 0 1 .208.208v1.25a.21.21 0 0 1-.132.194.209.209 0 0 1-.08.014Z"
      fill="url(#alumni-network_svg__d)"
    />
    <path
      d="M18 11.667h-1.25v2.083H18v-2.083ZM15.5 11.667h-1.25v2.083h1.25v-2.083ZM18 14.583h-1.25v2.083H18v-2.083ZM15.5 14.583h-1.25v2.083h1.25v-2.083Z"
      fill="#C28200"
    />
    <path
      d="M10.5 14.167a1.25 1.25 0 0 1 1.25 1.25v2.5h-2.5v-2.5a1.25 1.25 0 0 1 1.25-1.25Z"
      fill="url(#alumni-network_svg__e)"
    />
    <path
      d="M6.75 11.667H5.5v2.083h1.25v-2.083ZM4.25 11.667H3v2.083h1.25v-2.083ZM6.75 14.583H5.5v2.083h1.25v-2.083ZM4.25 14.583H3v2.083h1.25v-2.083Z"
      fill="#C28200"
    />
    <path
      opacity={0.05}
      d="M10.5 8.75a1.667 1.667 0 1 1 0 3.333 1.667 1.667 0 0 1 0-3.333m0-.417a2.083 2.083 0 1 0 0 4.167 2.083 2.083 0 0 0 0-4.167Z"
      fill="#333"
    />
    <path
      opacity={0.07}
      d="M10.5 8.75a1.667 1.667 0 1 1 0 3.334 1.667 1.667 0 0 1 0-3.334m0-.208a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75Z"
      fill="#333"
    />
    <path
      d="M10.5 12.083a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333Z"
      fill="url(#alumni-network_svg__f)"
    />
    <path
      d="M11.124 10.625H10.5a.208.208 0 0 1-.208-.208V9.375a.209.209 0 0 1 .417 0v.834h.416a.208.208 0 1 1 0 .416Z"
      fill="#5C2415"
    />
    <path
      opacity={0.05}
      d="m13.396 8.496-2.895-2.717-2.896 2.717c-.009.004-.013.012-.021.012v.446a.624.624 0 0 0 .304-.154l2.613-2.45 2.612 2.45c.085.079.19.132.304.154v-.446c-.008 0-.012-.008-.02-.012Z"
      fill="#333"
    />
    <path
      opacity={0.07}
      d="m13.396 8.496-2.895-2.717-2.896 2.717c-.009.004-.013.012-.021.012v.23a.374.374 0 0 0 .162-.092l2.755-2.58 2.754 2.58a.374.374 0 0 0 .162.092v-.23c-.008 0-.012-.008-.02-.012Z"
      fill="#333"
    />
    <defs>
      <linearGradient
        id="alumni-network_svg__a"
        x1={10.499}
        y1={10.363}
        x2={10.499}
        y2={26.586}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBA600" />
        <stop offset={1} stopColor="#C28200" />
      </linearGradient>
      <linearGradient
        id="alumni-network_svg__c"
        x1={6.449}
        y1={1.785}
        x2={12.789}
        y2={19.203}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCF54" />
        <stop offset={0.261} stopColor="#FDCB4D" />
        <stop offset={0.639} stopColor="#F7C13A" />
        <stop offset={1} stopColor="#F0B421" />
      </linearGradient>
      <linearGradient
        id="alumni-network_svg__d"
        x1={11.958}
        y1={1.841}
        x2={11.958}
        y2={5.084}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F44F5A" />
        <stop offset={0.443} stopColor="#EE3D4A" />
        <stop offset={1} stopColor="#E52030" />
      </linearGradient>
      <linearGradient
        id="alumni-network_svg__e"
        x1={10.5}
        y1={14.113}
        x2={10.5}
        y2={19.416}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D43A02" />
        <stop offset={1} stopColor="#B9360C" />
      </linearGradient>
      <radialGradient
        id="alumni-network_svg__b"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(7.60583 0 0 7.60584 10.5 9.375)"
      >
        <stop stopColor="#007AD9" />
        <stop offset={1} stopColor="#2AA4F4" />
      </radialGradient>
      <radialGradient
        id="alumni-network_svg__f"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.5 10.417) scale(2.49667)"
      >
        <stop stopColor="#F9F9F9" />
        <stop offset={0.26} stopColor="#F0F1F2" />
        <stop offset={0.678} stopColor="#D9DCDF" />
        <stop offset={1} stopColor="#C2C8CC" />
      </radialGradient>
    </defs>
  </svg>
);
export default SvgAlumniNetwork;
