import { normalizeUrl, URL_TRANSFORMATION } from '@frond/shared';
import { isUrl } from '@udecode/plate-common';
import styled, { th } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React, { useContext, useState } from 'react';

import { Icon } from '../../common/components/Icon';
import { Input } from '../../common/components/Input';
import { PostComposerStateContext } from './PostComposer';

const StyledInput = styled(Input)`
  width: 100%;
  pointer-events: all;
  background-color: transparent;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  color: gray.400;
  ${th('typographyStyles.text.sm-semibold')};
`;

type PostComposerResourceInputProps = {
  onHide: () => void;
};
export const PostComposerResourceInput = ({
  onHide,
}: PostComposerResourceInputProps) => {
  const { t } = useTranslation();
  const { postComposerService } = useContext(PostComposerStateContext);

  const [url, setUrl] = useState('');

  const handleBlur = () => {
    const normalizedUrl = normalizeUrl(url);
    if (isUrl(URL_TRANSFORMATION(normalizedUrl))) {
      postComposerService.send({
        type: 'ADD_URL_TO_EMBED',
        url: normalizedUrl,
      });
    }
    onHide();
  };

  return (
    <div>
      <StyledInput
        placeholder={t('add_a_link')}
        onChange={(event) => {
          setUrl(event.target.value);
        }}
        icon={<Icon name="link" size="4" />}
        onBlur={handleBlur}
        onKeyDown={(event) => {
          if (event.code === 'Enter') {
            event.preventDefault();
            handleBlur();
          }
        }}
        autoFocus
        value={url || ''}
      />
    </div>
  );
};
