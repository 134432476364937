import { useMemo } from 'react';

class UninitializedError extends Error {
  constructor(message: string | undefined) {
    super(message);
    this.name = 'UninitializedVideoRecorderError';
  }
}

type VideoRecorderService = {
  initializeStream: (
    constraints: MediaStreamConstraints
  ) => Promise<MediaStream>;
  reset: () => void;
  startRecording: (timeslice: number) => void;
  pauseRecording: () => void;
  resumeRecording: () => void;
  stopRecording: () => Promise<void>;
  destroy: () => void;
  dropStream: () => void;
  getData: () => Blob;
  getObjectURL: () => string;
};

type VideoRecorderServiceData = {
  objectURL?: string;
  objectURLSize?: number;
  recorder?: MediaRecorder;
  stream?: MediaStream;
  buffer: Blob[];
};

const VIDEO_BITRATE = 2500000;

/**
 * Polyfill requestIdleCallback
 */
const defer = (fallbackTimeout = 100) =>
  new Promise((resolve) => {
    if (window.requestIdleCallback) {
      return window.requestIdleCallback(resolve);
    }
    return setTimeout(resolve, fallbackTimeout);
  });

/**
 * Service for recording video data
 */
export const useVideoRecorderService: () => VideoRecorderService = () => {
  return useMemo(() => {
    const data: VideoRecorderServiceData = {
      buffer: [],
    };

    /**
     * Open the user's camera and store a reference to the resulting stream
     * This needs to happen before anything else
     */
    const initializeStream = async (
      constraints: MediaStreamConstraints
    ): Promise<MediaStream> => {
      /**
       * If there's already a stream open, make sure to drop it first
       * to avoid orphaned streams
       */
      data.stream && dropStream();

      // Pause all videos before initializing the camera + microphone
      const allVideos = window.document.getElementsByTagName('video');
      const playingVideos = [...allVideos].filter((video) => !video.paused);

      playingVideos.forEach((video) => {
        video.pause();
      });

      data.stream = await navigator.mediaDevices.getUserMedia(constraints);

      playingVideos.forEach((video) => {
        video.play();
      });

      /**
       * We don't specify a container or codec here.
       * This is to delegate the decision to the browser which *should* go with
       * the best hardware accelerated option - on my machine this is matroska & h.264,
       * but we might see webm & vp9 increasingly over time.
       * We may need to revisit this if we need a more specific output.
       */
      data.recorder = new MediaRecorder(data.stream, {
        videoBitsPerSecond: VIDEO_BITRATE,
      });
      data.recorder.ondataavailable = handleDataAvailable;

      return data.stream;
    };

    /**
     * Clear stored video data from memory
     */
    const reset = (): void => {
      data.buffer = [];
    };

    /**
     * Start capturing video
     */
    const startRecording = (timeslice = 1000): void => {
      if (!data.recorder) {
        throw new UninitializedError(
          'Attempted to record an uninitialized video stream'
        );
      }

      data.recorder.start(timeslice);
    };

    /**
     * Callback for MediaRecorder API, stores a chunk of data to memory
     */
    const handleDataAvailable = (event: BlobEvent): void => {
      if (event.data && event.data.size > 0) {
        data.buffer.push(event.data);
      }
    };

    /**
     * Pause a previously started recording
     */
    const pauseRecording = (): void => {
      if (!data.recorder) {
        throw new UninitializedError(
          'Attempted to pause recording of an uninitialized video stream'
        );
      }

      data.recorder.pause();
    };

    /**
     * Resume a previously paused recording
     */
    const resumeRecording = (): void => {
      if (!data.recorder) {
        throw new UninitializedError(
          'Attempted to resume recording of an uninitialized video stream'
        );
      }

      data.recorder.resume();
    };

    /**
     * Stop a previously started/paused recording
     */
    const stopRecording = async (): Promise<void> => {
      if (!data.recorder) {
        throw new UninitializedError(
          'Attempted to stop recording of an uninitialized video stream'
        );
      }

      data.recorder.stop();

      // Delay resolution to make sure the last chunk is captured
      await defer();

      data.stream?.getTracks().forEach((track) => track.stop());
    };

    /**
     * Get raw recorded data
     */
    const getData = (): Blob => {
      return new Blob(data.buffer, { type: data.recorder?.mimeType });
    };

    /**
     * Return an object url for recorded video playback
     * This function is memoized and will invalidate any previously generated object urls
     * if the buffer has changed since last call
     */
    const getObjectURL = (): string => {
      const blob = getData();

      if (data.objectURL && data.objectURLSize === blob.size) {
        return data.objectURL;
      }

      data.objectURL && URL.revokeObjectURL(data.objectURL);
      data.objectURL = URL.createObjectURL(blob);
      data.objectURLSize = blob.size;

      return data.objectURL;
    };

    /**
     * This should be called on unmount to free memory and
     * drop the camera stream
     */
    const destroy = () => {
      data.objectURL && URL.revokeObjectURL(data.objectURL);
      dropStream();
    };

    /**
     * Drop any open mediastream tracks
     */
    const dropStream = () => {
      data.stream?.getTracks().forEach((track) => track.stop());
    };

    return {
      initializeStream,
      reset,
      startRecording,
      pauseRecording,
      resumeRecording,
      stopRecording,
      getData,
      getObjectURL,
      destroy,
      dropStream,
    };
  }, []);
};
