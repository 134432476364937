import * as React from "react";
import { SVGProps } from "react";
const SvgArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99.824a.625.625 0 0 0-.615.634v15.575l-1.85-1.85a.625.625 0 1 0-.884.884l2.917 2.916a.625.625 0 0 0 .884 0l2.917-2.916a.625.625 0 1 0-.884-.884l-1.85 1.85V1.458A.625.625 0 0 0 9.99.824Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowDown;
