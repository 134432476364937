import { useRouter } from 'next/router';
import React from 'react';

import { useHomeFeedQuery } from '../../../../generated/types-and-hooks';
import { POSTS_PER_PAGE } from '../../../config/constants';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { DetailLayoutMobileBar } from '../../common/layout/DetailLayout';
import { TagDetailLayoutControls } from './TagDetailLayoutControls';

export const TagMobileBar = () => {
  const router = useRouter();
  const tag = router.query.tag as string;
  const { organization } = useOrganization();

  const { data: feedData } = useHomeFeedQuery({
    variables: {
      organizationId: organization.id,
      first: POSTS_PER_PAGE,
      tag,
    },
  });

  return (
    <DetailLayoutMobileBar
      numPosts={feedData?.homeFeed?.totalCount}
      item={{
        __typename: 'Tag',
        createdAt: new Date().toDateString(),
        name: `#${tag}`,
        description: '',
      }}
      controls={<TagDetailLayoutControls tag={tag} />}
    />
  );
};
