import * as React from "react";

function SvgDropdownArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.73 6.223a1 1 0 01-1.46 0l-3.752-4c-.6-.639-.146-1.685.73-1.685h7.505c.875 0 1.328 1.046.73 1.685l-3.754 4z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDropdownArrow;
