import * as React from "react";
import { SVGProps } from "react";
const SvgLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2c-4.685 0-8.5 3.815-8.5 8.5 0 2.103.772 4.032 2.04 5.516l.003.004.003.003s3.616 4.127 4.903 5.355a2.258 2.258 0 0 0 3.1 0c1.468-1.4 4.906-5.357 4.906-5.357l.002-.002.003-.003A8.466 8.466 0 0 0 20.5 10.5C20.5 5.815 16.685 2 12 2Zm0 1.5c3.875 0 7 3.125 7 7a6.95 6.95 0 0 1-1.68 4.541c-.008.008-3.525 4.03-4.805 5.252a.731.731 0 0 1-1.03 0c-1.07-1.021-4.794-5.24-4.805-5.252l-.001-.001A6.954 6.954 0 0 1 5 10.5c0-3.875 3.125-7 7-7Zm0 4c-.938 0-1.736.379-2.248.955C9.239 9.032 9 9.771 9 10.5c0 .73.24 1.468.752 2.045.512.576 1.31.955 2.248.955.938 0 1.736-.379 2.248-.955.513-.577.752-1.316.752-2.045 0-.73-.24-1.468-.752-2.045C13.736 7.88 12.938 7.5 12 7.5ZM12 9c.563 0 .89.184 1.127.451s.373.653.373 1.049c0 .396-.136.782-.373 1.049-.238.267-.565.451-1.127.451-.563 0-.89-.184-1.127-.451s-.373-.653-.373-1.049c0-.396.136-.782.373-1.049C11.111 9.184 11.438 9 12 9Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgLocation;
