import { x } from '@xstyled/styled-components';
import { PropsWithChildren } from 'react';

import { Text } from '../../common/components/Text';
import { Tooltip } from '../../common/components/Tooltip';
import { topCenter } from '../../common/utils/position';

export const GroupHeadingTooltip: React.FC<
  PropsWithChildren<{
    maxWidth?: number;
    title: string;
    label?: string;
  }>
> = ({ maxWidth = 320, label, title, children }) => {
  return (
    <Tooltip
      maxWidth={maxWidth}
      position={topCenter}
      aria-label={label || title}
      label={
        <x.div display="flex" flexDirection="column">
          <Text variant="sm-semibold">{title}</Text>
          {label && <Text variant="sm">{label}</Text>}
        </x.div>
      }
    >
      <x.div>{children}</x.div>
    </Tooltip>
  );
};
