import * as React from "react";

function SvgCircleFilledChecked(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm-2-9.414l5.293-5.293 1.414 1.414L10 16.414l-3.707-3.707 1.414-1.414L10 13.586z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCircleFilledChecked;
