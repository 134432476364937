import { getCloudinaryUrl } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { nanoid } from 'nanoid';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import { Image } from '../../../../generated/types-and-hooks';
import { Button } from '../../common/components/Button';
import { FileUploader } from '../../common/components/FileUploader';
import { Icon } from '../../common/components/Icon';
import { CONTENT_WIDTH } from '../../common/layout/Layout';

export const COVER_IMAGE_ASPECT_RATIO = '16 / 9';

export type CoverImageProps = {
  coverImageId?: string | null;
  canEditCover?: boolean;
  loading?: boolean;
  onUploadSuccess?: (image: string) => void;
  onRemoveCover?: () => void;
  showAddCoverButton?: boolean;
  buttonInset?: number;
  variant?: 'placeholder';
  rounded?: boolean;
  backgroundColor?: string;
};

export const CoverImage: React.FC<CoverImageProps> = ({
  coverImageId,
  canEditCover,
  loading,
  onUploadSuccess,
  onRemoveCover,
  showAddCoverButton,
  buttonInset,
  variant,
  rounded,
  backgroundColor = 'brand.50',
}) => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  const handleUploadSuccess = (
    image:
      | Image
      | {
          url: string;
        }
  ) => {
    if ('publicId' in image) {
      setUploading(false);
      onUploadSuccess?.(image.publicId);
    }
  };

  const coverImageUrl = coverImageId
    ? getCloudinaryUrl({
        id: coverImageId,
        width: 900,
      })
    : undefined;

  if (!coverImageId) {
    if (variant === 'placeholder') {
      return (
        <x.div boxSizing="border-box">
          <FileUploader
            name="coverImageId"
            accept="image/*"
            onUploadFailure={() => setUploading(false)}
            onUploadProgress={() => setUploading(true)}
            onUploadSuccess={handleUploadSuccess}
          >
            <x.div
              w="full"
              style={{
                aspectRatio: COVER_IMAGE_ASPECT_RATIO,
              }}
              margin="0 auto"
              backgroundColor={backgroundColor}
              position="relative"
            >
              <x.div
                w="full"
                maxWidth={CONTENT_WIDTH}
                boxSizing="border-box"
                left="50%"
                transform="translateX(-50%)"
                position="absolute"
                pb={4}
                bottom={0}
                spaceX={3}
                display="flex"
                justifyContent="flex-end"
                transitionTimingFunction="ease-in-out"
                transitionDuration="fast"
                px={buttonInset}
              >
                <Button
                  label={t('add_a_cover')}
                  loading={uploading}
                  variant="secondary"
                  // Buttons don't like being in labels, so we need to
                  // use a div here to keep hover states
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  as="div"
                />
              </x.div>
            </x.div>
          </FileUploader>
        </x.div>
      );
    }

    return (
      <x.div
        w="full"
        maxWidth={CONTENT_WIDTH}
        pointerEvents={canEditCover ? 'auto' : 'none'}
        opacity={
          canEditCover && (showAddCoverButton || loading || uploading)
            ? '1'
            : '0'
        }
        margin="3 auto"
      >
        <FileUploader
          name={`coverImageId-${nanoid(3)}`}
          accept="image/*"
          onUploadFailure={() => setUploading(false)}
          onUploadProgress={() => setUploading(true)}
          onUploadSuccess={handleUploadSuccess}
        >
          <Button
            variant="text"
            color="gray.300"
            pointerEvents="none"
            loading={loading || uploading}
            leftElement={<Icon name="image" />}
            label={t('add_a_cover')}
          />
        </FileUploader>
      </x.div>
    );
  }

  return (
    <x.div boxSizing="border-box">
      <x.div
        w="full"
        margin="0 auto"
        style={{
          aspectRatio: COVER_IMAGE_ASPECT_RATIO,
        }}
        position="relative"
        onMouseOver={() => setMouseOver(true)}
        onMouseOut={() => setMouseOver(false)}
      >
        <x.img
          w="100%"
          style={{
            aspectRatio: COVER_IMAGE_ASPECT_RATIO,
          }}
          objectFit="cover"
          src={coverImageUrl}
          borderRadius={rounded ? 'lg lg 0 0' : 0}
          overflow="hidden"
        />
        {canEditCover && (
          <x.div
            w="full"
            maxWidth={CONTENT_WIDTH}
            boxSizing="border-box"
            left="50%"
            transform="translateX(-50%)"
            position="absolute"
            pb={4}
            bottom={0}
            spaceX={3}
            opacity={{ sm: mouseOver || uploading ? '1' : '0' }}
            display="flex"
            pointerEvents={{ sm: mouseOver || uploading ? 'auto' : 'none' }}
            justifyContent="flex-end"
            transitionTimingFunction="ease-in-out"
            transitionDuration="fast"
            px={buttonInset}
          >
            <Button
              label={t('remove_cover')}
              loading={loading}
              variant="secondary-warning"
              onClick={onRemoveCover}
            />
            <FileUploader
              name={`coverImageId-${nanoid(3)}`}
              accept="image/*"
              onUploadFailure={() => setUploading(false)}
              onUploadProgress={() => setUploading(true)}
              onUploadSuccess={handleUploadSuccess}
            >
              <Button
                display={{ _: 'none', sm: 'block' }}
                label={t('add_new_cover')}
                loading={uploading}
                variant="secondary"
                // Buttons don't like being in labels, so we need to
                // use a div here to keep hover states
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                as="div"
              />
            </FileUploader>
          </x.div>
        )}
      </x.div>
    </x.div>
  );
};
