import { MyPlatePlugin } from '@frond/shared';
import isHotkey from 'is-hotkey';
import { Node } from 'slate';

export const createMaxCharactersPlugin = ({
  maxCharacters,
}: {
  maxCharacters: number;
}): MyPlatePlugin => ({
  key: 'max-characters',
  withOverrides: (editor) => {
    const { insertTextData } = editor;

    editor.insertTextData = (dataTransfer) => {
      const content = editor.children.map((n) => Node.string(n)).join('\n');
      const data = dataTransfer.getData('text/plain');
      if (content.length + data.length > maxCharacters) {
        const slicedText = data.substring(
          0,
          maxCharacters - content.length + 1
        );
        const slicedData = new DataTransfer();
        slicedData.setData('text/plain', slicedText);
        return insertTextData(slicedData);
      } else {
        return insertTextData(dataTransfer);
      }
    };

    return editor;
  },
  handlers: {
    onKeyDown: (editor) => {
      return (event) => {
        if (
          !isHotkey('backspace')(event.nativeEvent) &&
          // Allow to select all to be able to delete all text
          !isHotkey('mod+a')(event.nativeEvent) &&
          Node.string(editor.children[0]).length + 1 > maxCharacters
        ) {
          event.preventDefault();
          return false;
        }
      };
    },
  },
});
