import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { Icon } from '../../../../common/components/Icon';
import { Text } from '../../../../common/components/Text';
import {
  ActiveStepsType,
  getStepNum,
  GroupOnboardingSteps,
} from '../../../utils/questions';

export interface StepBreadcrumbsProps {
  activeSteps: ActiveStepsType;
  currentStep: GroupOnboardingSteps;
  onPreviousStepClick: (step: GroupOnboardingSteps) => void;
}

const SingleBreadcrumb: React.FC<{
  title: string;
  stepNum: number;
  active: boolean;
  completed: boolean;
  onStepClick: () => void;
}> = ({ title, stepNum, active, completed, onStepClick }) => (
  <x.button
    display="flex"
    justifyContent="center"
    alignItems="center"
    spaceX="4"
    cursor={!completed ? 'initial' : 'pointer'}
    background="transparent"
    border="none"
    onClick={() => completed && onStepClick()}
  >
    <x.div
      w="7"
      h="7"
      borderRadius="full"
      backgroundColor={active ? 'brand.300' : completed ? 'none' : 'gray.200'}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {completed ? (
        <Icon name="circle-filled-checked" color="gray.500" size="7" />
      ) : (
        <Text variant="sm-semibold" color={active ? 'white' : 'gray.300'}>
          {stepNum}
        </Text>
      )}
    </x.div>
    <Text
      variant={active ? 'md-semibold' : 'md'}
      color={active ? 'brand.300' : completed ? 'gray.500' : 'gray.300'}
    >
      {title}
    </Text>
  </x.button>
);

export const StepBreadcrumbs: React.FC<StepBreadcrumbsProps> = ({
  activeSteps,
  currentStep,
  onPreviousStepClick,
}) => {
  const { t } = useTranslation();

  return (
    <x.div
      display={{ _: 'none', 'md-2': 'flex' }}
      justifyContent="center"
      alignItems="center"
      m="10"
      spaceX="8"
      p="1"
    >
      {activeSteps[GroupOnboardingSteps.ORGANIZATION] && (
        <SingleBreadcrumb
          title={t('community')}
          stepNum={getStepNum(GroupOnboardingSteps.ORGANIZATION, activeSteps)}
          active={currentStep === GroupOnboardingSteps.ORGANIZATION}
          completed={currentStep > GroupOnboardingSteps.ORGANIZATION}
          onStepClick={() =>
            onPreviousStepClick(GroupOnboardingSteps.ORGANIZATION)
          }
        />
      )}
      {activeSteps[GroupOnboardingSteps.TEMPLATES] && (
        <SingleBreadcrumb
          title={t('template')}
          stepNum={getStepNum(GroupOnboardingSteps.TEMPLATES, activeSteps)}
          active={currentStep === GroupOnboardingSteps.TEMPLATES}
          completed={currentStep > GroupOnboardingSteps.TEMPLATES}
          onStepClick={() =>
            onPreviousStepClick(GroupOnboardingSteps.TEMPLATES)
          }
        />
      )}
      {activeSteps[GroupOnboardingSteps.PROFILE] && (
        <SingleBreadcrumb
          title={t('profile')}
          stepNum={getStepNum(GroupOnboardingSteps.PROFILE, activeSteps)}
          active={currentStep === GroupOnboardingSteps.PROFILE}
          completed={currentStep > GroupOnboardingSteps.PROFILE}
          onStepClick={() => onPreviousStepClick(GroupOnboardingSteps.PROFILE)}
        />
      )}
    </x.div>
  );
};
