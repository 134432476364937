import * as React from "react";
import { SVGProps } from "react";
const SvgComment2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 1.666c-4.595 0-8.333 3.738-8.333 8.333 0 1.365.36 2.64.942 3.778l-.903 3.234c-.21.751.532 1.494 1.284 1.284l3.236-.903c1.137.581 2.41.94 3.774.94 4.595 0 8.333-3.738 8.333-8.333 0-4.595-3.738-8.333-8.333-8.333Zm0 1.25a7.074 7.074 0 0 1 7.083 7.083A7.074 7.074 0 0 1 10 17.082a7.04 7.04 0 0 1-3.44-.893.625.625 0 0 0-.473-.056l-3.078.858.86-3.076a.625.625 0 0 0-.056-.473A7.038 7.038 0 0 1 2.917 10 7.074 7.074 0 0 1 10 2.916Zm-3.542 5a.625.625 0 1 0 0 1.25h7.084a.625.625 0 1 0 0-1.25H6.458Zm0 2.916a.624.624 0 1 0 0 1.25h5.417a.624.624 0 1 0 0-1.25H6.458Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgComment2;
