import { PostCreationPolicy } from '../../../../generated/types-and-hooks';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useIsViewerAdmin } from '../../auth/hooks/useViewer';

export const useCanViewerPostToGroup = (
  group?: {
    postCreationPolicy: PostCreationPolicy;
  } | null
) => {
  const { organization } = useOrganization();
  const isAdmin = useIsViewerAdmin(organization);

  if (!group) return false;

  const { postCreationPolicy } = group;
  return postCreationPolicy === PostCreationPolicy.Unrestricted || isAdmin;
};
