import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { Trans, useTranslation } from 'next-i18next';

import { OrganizationByShortIdQuery } from '../../../../generated/types-and-hooks';
import { useViewer } from '../../auth/hooks/useViewer';
import { Button } from '../../common/components/Button';
import { Icon } from '../../common/components/Icon';
import { Text } from '../../common/components/Text';
import { ModalLayout } from '../../common/layout/ModalLayout';

export const RequestToJoinSuccess: React.FC<{
  organization: NonNullable<
    OrganizationByShortIdQuery['organizationByShortId']
  >;
}> = ({ organization }) => {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const commonButtonProps = {
    h: 15,
    mt: 10,
    p: 5,
    boxSizing: 'border-box',
  };
  return (
    <ModalLayout>
      <x.div display="flex" flexDirection="column" alignItems="center">
        <Icon name="add-member" size="12" mb={10} />
        <Text variant="md" px={14}>
          <Trans
            i18nKey="rtj.request_to_join_success"
            ns="common"
            values={{ organizationName: organization.name }}
          >
            Your request has been sent to the admins of the{' '}
            <Text as="span" variant="md-medium">
              {organization.name}
            </Text>
            community
          </Trans>
        </Text>
        {viewer?.organizationMemberships &&
        viewer?.organizationMemberships.length > 0 ? (
          <Button
            variant="secondary"
            label={t('view_your_communities')}
            href={routes.groups.organizations()}
            {...commonButtonProps}
          />
        ) : (
          <>
            <x.div mt={8} spaceY={2}>
              <Text variant="md-semibold">
                {t('rtj.frond_intro_title', { ns: 'common' })}
              </Text>
              <Text variant="md">
                {t('rtj.frond_intro_body', { ns: 'common' })}
              </Text>
            </x.div>
            <x.div display="flex" spaceX={2}>
              <Button
                label={t('rtj.learn_more_about_frond', { ns: 'common' })}
                href="https://frondcom.notion.site/Frond-FAQ-81a633cb29d1484da3d8935522890e89"
                {...commonButtonProps}
              />
              <Button
                label={t('rtj.create_your_own_button', { ns: 'common' })}
                href={routes.groups.organizationsNew()}
                {...commonButtonProps}
                variant="secondary"
              />
            </x.div>
          </>
        )}
      </x.div>
    </ModalLayout>
  );
};
