import * as Popover from '@radix-ui/react-popover';
import * as Select from '@radix-ui/react-select';
import * as Slider from '@radix-ui/react-slider';
import { useSelector } from '@xstate/react';
import { styled, x } from '@xstyled/styled-components';
import {
  getAbsoluteZoom,
  getZoomFactor,
} from 'advanced-cropper/extensions/absolute-zoom';
import { useTranslation } from 'next-i18next';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Icon } from '../../common/components/Icon';
import { Text } from '../../common/components/Text';
import {
  PostCardButton,
  PostMediaCarousel,
  PostMediaContainer,
} from './PostCardMedia';
import { PostCardMediaCarouselControls } from './PostCardMediaCarouselControls';
import { PostComposerStateContext } from './PostComposer';
import { PostComposerMediaItem } from './PostComposerMediaItem';

export const PostComposerMedia = () => {
  const { postComposerService } = useContext(PostComposerStateContext);

  const media = useSelector(
    postComposerService,
    (state) => state.context.post.media
  );

  const currentMediaIndex = useSelector(
    postComposerService,
    (state) => state.context.currentMediaIndex
  );

  const aspectRatio = useSelector(
    postComposerService,
    (state) => state.context.post.aspectRatio
  );

  const isPublishing = useSelector(
    postComposerService,
    (state) => state.context.isPublishing
  );

  const carouselRef = useRef<HTMLDivElement | null>(null);
  const handleScroll = useCallback(() => {
    if (!carouselRef.current) return;
    const index = Math.round(
      carouselRef.current.scrollLeft / carouselRef.current.clientWidth
    );
    postComposerService.send({
      type: 'SET_CURRENT_MEDIA_INDEX',
      mediaIndex: index,
    });
  }, [postComposerService]);

  useEffect(() => {
    const el = carouselRef.current;
    el?.addEventListener('scroll', handleScroll);

    return () => {
      el?.removeEventListener('scroll', handleScroll);
    };
  }, [carouselRef, handleScroll, media]);

  useEffect(() => {
    if (!carouselRef.current) return;

    const index = Math.round(
      carouselRef.current.scrollLeft / carouselRef.current.clientWidth
    );

    if (index !== currentMediaIndex) {
      carouselRef.current.scrollTo({
        left:
          currentMediaIndex * carouselRef.current.getBoundingClientRect().width,
        behavior: Math.abs(index - currentMediaIndex) > 1 ? 'auto' : 'smooth',
      });
    }
  }, [currentMediaIndex]);

  if (media.length === 0) return null;

  return (
    <x.div position="relative">
      <PostMediaCarousel ref={carouselRef}>
        {media.map((media, index) => {
          return (
            <PostMediaContainer key={media.id} aspectRatio={aspectRatio}>
              <PostComposerMediaItem media={media} i={index} />
            </PostMediaContainer>
          );
        })}
      </PostMediaCarousel>
      <x.div
        position="absolute"
        display={isPublishing ? 'none' : 'flex'}
        spaceX={2}
        bottom={24}
      >
        <PostComposerAspectRatioSelect />
        <PostComposerZoom />
      </x.div>
      <x.div
        position="absolute"
        bottom={24}
        right={
          media[currentMediaIndex] && 'playbackId' in media[currentMediaIndex]
            ? 80
            : 0
        }
      >
        <PostCardButton
          onClick={() => {
            postComposerService.send({
              type: 'REMOVE_MEDIA',
              mediaId: media[currentMediaIndex].id,
            });
          }}
          iconName="trash"
          disabled={isPublishing}
        />
      </x.div>

      <PostCardMediaCarouselControls
        variant="composer"
        dots={media.length}
        carouselIndex={currentMediaIndex}
        onClick={(i) => {
          if (!carouselRef.current) return;
          carouselRef.current.scrollTo({
            left: carouselRef.current.getBoundingClientRect().width * i,
            behavior: 'smooth',
          });
        }}
        onPrevClick={() => {
          if (!carouselRef.current) return;
          carouselRef.current.scrollBy({
            left: -carouselRef.current.getBoundingClientRect().width,
            behavior: 'smooth',
          });
        }}
        onNextClick={() => {
          if (!carouselRef.current) return;
          carouselRef.current.scrollBy({
            left: carouselRef.current.getBoundingClientRect().width,
            behavior: 'smooth',
          });
        }}
      />
    </x.div>
  );
};

const StyledSelectViewport = styled(Select.Viewport)`
  background-color: white;
  padding: 3;
  border-radius: md;
  box-shadow: sm;
  box-sizing: border-box;
`;

const StyledSelectItem = styled(Select.Item)`
  cursor: pointer;
  width: 160px;
  padding: 1;

  &[data-state='checked'] {
    p {
      font-weight: 600;
    }
  }

  &[data-highlighted] {
    color: brand.300;
    border-color: brand.300;
  }
`;

const StyledButton = styled.buttonBox`
  &[data-state='open'] {
    background-color: whiteAlpha.450;
    color: brand.300;
  }
`;

const PostComposerAspectRatioSelect = () => {
  const { t } = useTranslation();
  const { postComposerService } = useContext(PostComposerStateContext);

  const aspectRatio = useSelector(
    postComposerService,
    (state) => state.context.post.aspectRatio
  );

  const mediaIndex = useSelector(
    postComposerService,
    (state) => state.context.currentMediaIndex
  );
  const media = useSelector(
    postComposerService,
    (state) => state.context.post.media
  );
  const selectedMedia = media[mediaIndex];

  const [value, setValue] = useState(aspectRatio?.toString() || '1');

  if (!selectedMedia) return null;

  const standardValues = [
    '1',
    '0.8',
    '1.25',
    'aspectRatio' in selectedMedia ? selectedMedia.aspectRatio.toString() : '',
    'aspect' in selectedMedia && selectedMedia.aspect
      ? selectedMedia.aspect.toString()
      : '',
  ];

  const items = [
    {
      value: '1',
      label: '1:1',
      icon: (
        <x.div
          h="15px"
          w="15px"
          border="default"
          borderWidth={1.5}
          borderRadius="sm"
        />
      ),
    },
    {
      value: '0.8',
      label: '4:5',
      icon: (
        <x.div
          h="15px"
          w="11px"
          border="default"
          borderWidth={1.5}
          borderRadius="sm"
        />
      ),
    },
    {
      value: '1.25',
      label: '5:4',
      icon: (
        <x.div
          h="11px"
          w="15px"
          border="default"
          borderWidth={1.5}
          borderRadius="sm"
        />
      ),
    },
    ...('aspectRatio' in selectedMedia
      ? [
          {
            value: selectedMedia.aspectRatio.toString(),
            label: 'Original',
            icon: <Icon name="media" size="5" />,
          },
        ]
      : []),
    ...('aspect' in selectedMedia && selectedMedia.aspect
      ? [
          {
            value: selectedMedia.aspect.toString(),
            label: 'Original',
            icon: <Icon name="media" size="5" />,
          },
        ]
      : []),
    ...('width' in selectedMedia
      ? [
          {
            value: standardValues.includes(aspectRatio?.toString() || 'crop')
              ? 'crop'
              : aspectRatio?.toString() || 'crop',
            label: 'Crop',
            icon: <Icon name="crop" size="5" />,
          },
        ]
      : []),
  ];

  const handleCropItemClick = () => {
    postComposerService.send({
      type: 'START_IMAGE_CROP',
    });
  };
  return (
    <Select.Root
      value={value}
      onValueChange={(val) => {
        if (standardValues.includes(val)) {
          setValue(val);
          postComposerService.send({
            type: 'UPDATE_POST_ASPECT_RATIO',
            aspectRatio: parseFloat(val),
          });
        }
      }}
    >
      <Select.Trigger aria-label={t('aspect_ratio')} asChild>
        <StyledButton
          display="flex"
          alignItems="center"
          justifyContent="center"
          border="none"
          borderRadius="full"
          cursor="pointer"
          w={8}
          h={8}
          type="button"
          backgroundColor={{ _: 'whiteAlpha.400', hover: 'whiteAlpha.450' }}
          transition
          transitionProperty="color"
          transitionDuration="fast"
          transitionTimingFunction="ease-out"
          color={{ _: 'gray.500', hover: 'brand.300' }}
        >
          <Icon name="aspect" size="4" />
        </StyledButton>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content sideOffset={8} position="popper" side="top">
          <StyledSelectViewport>
            <x.div spaceY={1}>
              {items.map((item) => {
                return (
                  <StyledSelectItem
                    // This is a workaround since we can't use onClick
                    {...(item.label === 'Crop'
                      ? {
                          onMouseDown: handleCropItemClick,
                          onTouchStart: handleCropItemClick,
                        }
                      : {})}
                    value={item.value}
                    key={item.value}
                  >
                    <x.div display="flex" alignItems="center" spaceX={3}>
                      <x.div display="flex" justifyContent="center" w={5}>
                        {item.icon}
                      </x.div>
                      <Select.ItemText asChild>
                        <Text>{item.label}</Text>
                      </Select.ItemText>
                    </x.div>
                  </StyledSelectItem>
                );
              })}
            </x.div>
          </StyledSelectViewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

const StyledSlider = styled(Slider.Root)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 200px;
  height: 20px;
`;

const StyledSliderTrack = styled(Slider.Track)`
  background-color: brand.50;
  position: relative;
  flex-grow: 1;
  border-radius: sm;
  height: 2;
`;

const StyledSliderRange = styled(Slider.Range)`
  position: absolute;
  background-color: brand.300;
  border-radius: sm;
  height: 100%;
`;

const StyledSliderThumb = styled(Slider.Thumb)`
  display: block;
  width: 18px;
  height: 18px;
  background-color: brand.300;
  box-shadow: sm;
  border-radius: 10px;

  &:hover {
    background-color: brand.400;
  }

  &:focus {
    outline: none;
    box-shadow: md;
  }
`;

const PostComposerZoom = () => {
  const { postComposerService } = useContext(PostComposerStateContext);

  const mediaIndex = useSelector(
    postComposerService,
    (state) => state.context.currentMediaIndex
  );
  const media = useSelector(
    postComposerService,
    (state) => state.context.post.media
  );
  const selectedMedia = media[mediaIndex];

  const [value, setValue] = useState([0]);

  if (
    !selectedMedia ||
    !('cropperRef' in selectedMedia) ||
    !selectedMedia.cropperRef
  ) {
    return null;
  }

  const state = selectedMedia.cropperRef.getState();

  const settings = selectedMedia.cropperRef.getSettings();

  const absoluteZoom = getAbsoluteZoom(state, settings);

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <StyledButton
          display="flex"
          alignItems="center"
          justifyContent="center"
          border="none"
          borderRadius="full"
          cursor="pointer"
          w={8}
          h={8}
          type="button"
          backgroundColor={{ _: 'whiteAlpha.400', hover: 'whiteAlpha.450' }}
          transition
          transitionProperty="color"
          transitionDuration="fast"
          transitionTimingFunction="ease-out"
          color={{ _: 'gray.500', hover: 'brand.300' }}
        >
          <Icon name="zoom" size="4" />
        </StyledButton>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content sideOffset={8} side="top" align="start">
          <x.div bg="white" p={3} borderRadius="md">
            <StyledSlider
              value={[
                absoluteZoom * 100 !== value[0] ? absoluteZoom * 100 : value[0],
              ]}
              max={100}
              step={1}
              aria-label="Zoom"
              onValueChange={(val) => {
                if (selectedMedia.cropperRef) {
                  const state = selectedMedia.cropperRef.getState();

                  const settings = selectedMedia.cropperRef.getSettings();

                  setValue(val);
                  selectedMedia.cropperRef.zoomImage(
                    getZoomFactor(state, settings, val[0] / 100),
                    {
                      transitions: false,
                    }
                  );
                }
              }}
            >
              <StyledSliderTrack>
                <StyledSliderRange />
              </StyledSliderTrack>
              <StyledSliderThumb />
            </StyledSlider>
          </x.div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
