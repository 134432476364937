import { useSelector } from '@xstate/react';
import React, { useContext } from 'react';

import { useViewer } from '../../../../auth/hooks/useViewer';
import { AppContext } from '../../../../common/machine/appContext';
import { ProfileCommonStep } from '../../common/ProfileCommonStep';

export interface ProfileStepProps {
  onNext: () => void;
}

export const ProfileStep: React.FC<ProfileStepProps> = ({ onNext }) => {
  const { viewer } = useViewer();
  const { appService } = useContext(AppContext);
  const organization = useSelector(
    appService,
    (state) => state.context.organization
  );
  return (
    <ProfileCommonStep
      user={viewer}
      organization={organization}
      onCompleted={onNext}
      dataTest="groups-onboarding-profile"
      context="groups_onboarding"
    />
  );
};
