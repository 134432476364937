import { routes } from '@frond/shared';
import { useRouter } from 'next/router';

import {
  GroupWithMembersFragment,
  refetchGroupQuery,
  refetchGroupsQuery,
  useJoinGroupMutation,
  useRemoveGroupMemberMutation,
} from '../../../../generated/types-and-hooks';
import { GROUPS_PER_PAGE } from '../../../config/constants';
import { useViewer } from '../../auth/hooks/useViewer';
import { useUserCanEditGroup } from '../../questions/utils/questions';
import { GroupsSidebarOrganization } from '../components/GroupsSidebar/GroupsSidebar';

export const useGroupMembership = ({
  organization,
  group,
}: {
  organization: GroupsSidebarOrganization;
  group: Pick<
    GroupWithMembersFragment,
    'isPrivate' | 'id' | 'createdBy' | 'membership'
  >;
}) => {
  const router = useRouter();
  const { viewer: user } = useViewer();
  const userCanEdit = useUserCanEditGroup(group, organization, user);
  const [joinGroup, { loading: joiningGroup }] = useJoinGroupMutation({
    variables: {
      input: {
        groupId: group.id,
      },
    },
    awaitRefetchQueries: true,
    refetchQueries: [refetchGroupQuery({ id: group.id })],
  });

  const [leaveGroup, { loading: leavingGroup }] = useRemoveGroupMemberMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      refetchGroupsQuery({
        organizationId: organization.id,
        first: GROUPS_PER_PAGE - 1,
      }),
      ...(!group.isPrivate ? [refetchGroupQuery({ id: group.id })] : []),
    ],
    fetchPolicy: 'network-only',
    variables: {
      input: {
        groupId: group.id,
        userId: user?.id || '',
      },
    },
    onCompleted: () => {
      if (group.isPrivate) {
        router.push(routes.groups.organization(organization.shortId).groups());
      }
    },
  });

  return {
    userIsMember: !!group.membership,
    userCanEdit,
    joinGroup,
    joiningGroup,
    leaveGroup,
    leavingGroup,
  };
};
