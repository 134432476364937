import * as React from "react";
import { SVGProps } from "react";
const SvgMessages = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.25 2.5A3.262 3.262 0 0 0 2 5.75V16.726c0 .993 1.216 1.611 2.02 1.028l2.027-1.475A3.252 3.252 0 0 0 9.25 19h6.256l4.474 3.254A1.27 1.27 0 0 0 22 21.226V10.25A3.254 3.254 0 0 0 18.75 7H18V5.75a3.262 3.262 0 0 0-3.25-3.25h-9.5Zm0 1.5h9.5c.975 0 1.75.775 1.75 1.75v5.5A1.74 1.74 0 0 1 14.75 13h-6.5a.75.75 0 0 0-.441.144L3.5 16.277V5.75C3.5 4.775 4.275 4 5.25 4Zm1.5 2a.75.75 0 1 0 0 1.5h6.5a.75.75 0 1 0 0-1.5h-6.5ZM18 8.5h.75c.965 0 1.75.785 1.75 1.75v10.527l-4.309-3.133a.752.752 0 0 0-.441-.144h-6.5c-.965 0-1.75-.785-1.75-1.75v-.527l.994-.723h6.256A3.261 3.261 0 0 0 18 11.25V8.5Zm-11.25 1a.75.75 0 1 0 0 1.5h5a.751.751 0 1 0 0-1.5h-5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMessages;
