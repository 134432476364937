import { x } from '@xstyled/styled-components';
import React from 'react';

import { Icon } from '../Icon';

type CommentComposerDeleteButtonProps = {
  onClick: () => void;
};
export const CommentComposerDeleteButton = ({
  onClick,
}: CommentComposerDeleteButtonProps) => {
  return (
    <x.button
      w={5}
      h={5}
      display="flex"
      alignItems="center"
      justifyContent="center"
      top={8}
      right={8}
      position="absolute"
      borderRadius="full"
      borderColor="transparent"
      backgroundColor="rgba(0,0,0, 0.6)"
      cursor="pointer"
      color={{
        _: 'white',
        hover: 'gray.300',
      }}
      onClick={onClick}
    >
      <Icon name="close" size="3" />
    </x.button>
  );
};
