import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useRouter } from 'next/router';
import React, { FC, Fragment } from 'react';

import {
  EventCoreFragment,
  useAddGroupViewMutation,
  usePostsByEventQuery,
  ViewLevels,
  ViewLocations,
} from '../../../../generated/types-and-hooks';
import { POSTS_PER_PAGE } from '../../../config/constants';
import { useOrganization } from '../../auth/hooks/useOrganization';
import { useViewer } from '../../auth/hooks/useViewer';
import { Button } from '../../common/components/Button';
import { Icon } from '../../common/components/Icon';
import { useEffectOnce } from '../../common/hooks/useEffectOnce';
import { DetailLayout } from '../../common/layout/DetailLayout';
import { InfiniteScroll } from '../../common/layout/InfiniteScroll';
import { EventDetailsButton } from '../../posts/components/EventDetailsButton';
import { PostCard, PostCardPlaceholder } from '../../posts/components/PostCard';
import { PostViewMutator } from '../../posts/components/PostViewMutator';
import { PostNavContext } from '../../questions/hooks/useAnswerNavigation';
import { EventAttendButtonButton } from '../components/EventAttendButton';
import { EventNotificationsButton } from '../components/EventNotificationsButton';
import { EventSettingsDropdown } from '../components/EventSettingsDropdown';

export type EventViewProps = {
  event?: EventCoreFragment;
  showLayout?: boolean;
};

const EventDetailLayoutLoading = () => (
  <x.div display="flex" flexDirection="column" pt={8} pb={8} px={6} spaceY={2}>
    <x.div h={6} backgroundColor="gray.100"></x.div>
    <x.div h={6} backgroundColor="gray.100"></x.div>
    <x.div h={6} backgroundColor="gray.100"></x.div>
    <x.div h={6} backgroundColor="gray.100"></x.div>
  </x.div>
);

export const EventView: FC<EventViewProps> = ({ event, showLayout = true }) => {
  const { viewer: user } = useViewer();
  const [mutateAddGroupView] = useAddGroupViewMutation();

  useEffectOnce(
    () => {
      event?.__typename == 'Event' &&
        user &&
        mutateAddGroupView({
          variables: {
            input: {
              viewedId: event.id,
              level: ViewLevels.Detail,
              location: ViewLocations.GroupDetail,
            },
          },
        });
    },
    () => !!event
  );

  const { data, loading, fetchMore } = usePostsByEventQuery({
    variables: {
      eventId: event?.id || '',
      first: POSTS_PER_PAGE,
    },
    skip: !event,
    context: {
      skip: !event,
    },
  });

  return (
    <DetailLayout
      numPosts={event?.numPosts}
      item={event}
      showLayout={showLayout}
      loading={!event ? <EventDetailLayoutLoading /> : undefined}
      controls={event && <EventViewControls event={event} />}
    >
      {!data || !event ? (
        <x.div spaceY={6}>
          {[...Array(POSTS_PER_PAGE)].map((_, i) => (
            <PostCardPlaceholder key={i} />
          ))}
        </x.div>
      ) : (
        <x.div>
          <x.div spaceY={6} h="full">
            <InfiniteScroll
              threshold={100}
              loading={loading}
              hasMore={data.postsByEvent.pageInfo.hasNextPage || false}
              loadMore={() => {
                fetchMore({
                  variables: {
                    after: data.postsByEvent.pageInfo.endCursor,
                  },
                });
              }}
            >
              {data.postsByEvent.edges.map(({ node }) => {
                return (
                  <Fragment key={node.id}>
                    <PostViewMutator
                      post={node}
                      viewLevel={ViewLevels.Detail}
                      viewLocation={ViewLocations.GroupDetail}
                      delay={2500}
                    >
                      <PostCard
                        post={node}
                        navContext={PostNavContext.GROUP}
                        maxComments={3}
                      />
                    </PostViewMutator>
                  </Fragment>
                );
              })}
            </InfiniteScroll>
          </x.div>
        </x.div>
      )}
    </DetailLayout>
  );
};

const EventViewControls: React.FC<{
  event: EventCoreFragment;
}> = ({ event }) => {
  const router = useRouter();
  const { organization } = useOrganization();

  return (
    <x.div display="flex" spaceX={2}>
      <EventAttendButtonButton event={event} />
      {router.asPath ===
        routes.groups.organization(organization.shortId).inbox() && (
        <EventDetailsButton event={event} />
      )}
      <EventNotificationsButton event={event} />
      <EventSettingsDropdown event={event}>
        <Button
          p={2}
          variant="secondary"
          onClick={(e: React.MouseEvent) => e.preventDefault()}
          leftElement={<Icon name="more" />}
        />
      </EventSettingsDropdown>
    </x.div>
  );
};
