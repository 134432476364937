import { x } from '@xstyled/styled-components';
import { FC, PropsWithChildren } from 'react';

export const UserSettingsMenuRow: FC<
  PropsWithChildren<{
    onClick?: () => void;
  }>
> = ({ onClick, children }) => {
  return (
    <x.button
      display="inline-flex"
      spaceX="3"
      alignItems="center"
      onClick={onClick}
      cursor="pointer"
      color={{ _: 'inherit', hover: 'brand.300' }}
      background="transparent"
      border="none"
      p={0}
      m={0}
    >
      {children}
    </x.button>
  );
};
