import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import {
  useDirectMessageNotificationCountsQuery,
  useDraftPostsQuery,
  useNotificationCountsQuery,
  usePostBookmarksQuery,
} from '../../../../../generated/types-and-hooks';
import { POSTS_PER_PAGE } from '../../../../config/constants';
import { useIsViewerAdmin, useViewer } from '../../../auth/hooks/useViewer';
import { userInOrganization } from '../../../auth/utils/permissions';
import { useOrganizationEntitlements } from '../../../organizations/hooks/useOrganizationEntitlements';
import { GroupsSearchModal } from '../GroupsSearch/GroupsSearchModal';
import { GroupsSidebarOrganization } from './GroupsSidebar';
import {
  GroupsSidebarLinkList,
  GroupsSidebarNavigationLink,
} from './GroupsSidebarLink';
import { GroupsSidebarUnreadBadge } from './GroupsSidebarUnreadBadge';

export const GroupsSidebarTopNavigationList: React.FC<{
  organization: GroupsSidebarOrganization;
  onClickTab?: () => void;
}> = ({ organization, onClickTab }) => {
  const { t } = useTranslation();
  const { viewer: user } = useViewer();
  const { data: notificationCountsQuery } = useNotificationCountsQuery({
    variables: { organizationId: organization.id },
    skip: !user,
    context: {
      skip: !user,
    },
  });

  const { data: draftPostsData } = useDraftPostsQuery({
    variables: { organizationId: organization.id, first: 1 },
    skip: !user,
    context: {
      skip: !user,
    },
  });
  const unreadCount = notificationCountsQuery?.notificationCounts.unread;
  const { data: directMessageNotificationCountsQuery } =
    useDirectMessageNotificationCountsQuery({
      variables: {
        organizationId: organization.id,
      },
      skip: !user,
      context: {
        skip: !user,
      },
    });
  const unreadMessagesCount =
    directMessageNotificationCountsQuery?.directMessageNotificationCounts
      .unread;
  const viewerIsOrgAdmin = useIsViewerAdmin(organization);
  const viewerIsInOrganization = userInOrganization(user, organization);
  const [showModal, setShowModal] = useState(false);
  const { features } = useOrganizationEntitlements();
  const { data } = usePostBookmarksQuery({
    variables: {
      organizationId: organization.id,
      first: POSTS_PER_PAGE,
    },
  });

  return (
    <x.div spaceY={{ _: 3, sm: 6 }}>
      <GroupsSidebarLinkList variant="grid">
        <GroupsSidebarNavigationLink
          name={t('home')}
          icon="home"
          selectedIcon="home-filled"
          route={routes.groups.organization(organization.shortId).feed()}
          onClick={onClickTab}
        />
        {data?.postBookmarks?.edges && data?.postBookmarks?.edges.length > 0 ? (
          <GroupsSidebarNavigationLink
            name={t('bookmarks')}
            icon="bookmark"
            selectedIcon="bookmark-filled"
            route={routes.groups.organization(organization.shortId).bookmarks()}
            onClick={onClickTab}
          />
        ) : null}
        <GroupsSidebarNavigationLink
          name={t('search')}
          icon="search"
          selectedIcon="search"
          onClick={() => {
            setShowModal(true);
          }}
        />
        <GroupsSearchModal
          showModal={showModal}
          onDismiss={() => setShowModal(false)}
        />
        {viewerIsInOrganization &&
        user &&
        draftPostsData?.draftPosts &&
        draftPostsData.draftPosts.edges.length > 0 ? (
          <GroupsSidebarNavigationLink
            name={t('drafts')}
            icon="drafts"
            selectedIcon="drafts-filled"
            route={routes.groups.organization(organization.shortId).drafts()}
            onClick={onClickTab}
          />
        ) : null}
        {viewerIsInOrganization && (
          <GroupsSidebarNavigationLink
            name={t('inbox')}
            icon="bell"
            selectedIcon="bell-filled"
            route={routes.groups.organization(organization.shortId).inbox()}
            {...(unreadCount && {
              badge: (
                <GroupsSidebarUnreadBadge>
                  {unreadCount}
                </GroupsSidebarUnreadBadge>
              ),
            })}
            onClick={onClickTab}
          />
        )}
        {viewerIsInOrganization && user ? (
          <GroupsSidebarNavigationLink
            name={t('messages')}
            icon="messages"
            selectedIcon="messages-filled"
            route={routes.groups.organization(organization.shortId).messages()}
            {...(unreadMessagesCount && {
              badge: (
                <GroupsSidebarUnreadBadge>
                  {unreadMessagesCount}
                </GroupsSidebarUnreadBadge>
              ),
            })}
            onClick={onClickTab}
            exact={false}
          />
        ) : null}

        {features.Events && organization.hasEventsEnabled && (
          <GroupsSidebarNavigationLink
            name={t('events')}
            icon="calendar"
            selectedIcon="calendar-filled"
            route={routes.groups.organization(organization.shortId).events()}
            onClick={onClickTab}
          />
        )}

        <GroupsSidebarNavigationLink
          name={t('members')}
          icon="members"
          selectedIcon="members-filled"
          route={routes.groups.organization(organization.shortId).team()}
          onClick={onClickTab}
        />

        {viewerIsOrgAdmin ? (
          <>
            {organization.hasInsightsEnabled && !!features.Insights ? (
              <GroupsSidebarNavigationLink
                name={t('insights')}
                icon="analytics"
                selectedIcon="analytics-filled"
                route={routes.groups
                  .organization(organization.shortId)
                  .insights()}
                onClick={onClickTab}
              />
            ) : null}

            <x.div display={{ _: 'block', sm: 'none' }}>
              <GroupsSidebarNavigationLink
                name={t('settings')}
                icon="settings"
                selectedIcon="settings-filled"
                route={routes.settings
                  .organization(organization.shortId)
                  .details()}
                onClick={onClickTab}
              />
            </x.div>
          </>
        ) : null}
      </GroupsSidebarLinkList>
    </x.div>
  );
};
